import React, { useEffect, useState, lazy } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, useHistory, useRouteMatch, useParams } from 'react-router-dom';
import SubscriptionList from './List';
import SubscriptionDetail from '../Details';
import SubscriptionForm from '../Form';
import { loaderToggle } from '../../../../redux/actions/loaderActions';
import {
  GetOptionAddress,
  GetOptionPersons, GetRelationBillingStylesList,
  GetSubscriptions,
} from '../../../../controllers/relations';
import { GetAddresses, GetPlanSubscriptionLineTypes, GetPersons, GetContractPeriodList, GetStatusList, GetSubscriptionStatuses } from '../../../../controllers/subscriptions';
import { useTranslation } from 'react-i18next';
import {
  hideSidebarMenuSubscriptions, showSidebarMenuSubscriptions, toggleSidebarMenuSubscriptions
} from '../../../../redux/actions/sidebarMenuActions';
import { createSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCross, faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import FilterSearch from '../../../../components/FilterSearch';
import { Helmet } from "react-helmet";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SubscriptionDetails = ({ ...props }) => {
  const params = useParams();
  let { param } = params;

  const matchedRoute = useRouteMatch('/:name/:relationId/subscriptions/:subscrId');
  const { subscription, relation, relationSelected, match, dispatch, solrSearch, history, user, id, titleUpdate, loader } = props;

  const newTitle = props.subscrId ? `${titleUpdate} | ${props.subscrId}` : matchedRoute && matchedRoute.params && matchedRoute.params.subscrId ? `${titleUpdate} | ${matchedRoute.params.subscrId}` : `${titleUpdate}`;
  const [subscrId, setSubscrId] = useState(matchedRoute && matchedRoute.params && matchedRoute.params.subscrId ? matchedRoute.params.subscrId : null);
  const [relationId, setRelationId] = useState(params && params.relationId ? parseInt(params.relationId) : null);
  const [activeTab, setActiveTab] = useState(4);
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState(relation.subscriptionList ? relation.subscriptionList : []);
  const [subscriptionStatuses, setSubscriptionStatuses] = useState([]);
  const [selectedSubscr, setSelectedSubscr] = useState(props.subscrId ? props.subscrId : matchedRoute && matchedRoute.params && matchedRoute.params.subscrId ? matchedRoute.params.subscrId : null);
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dataDetails, setDataDetails] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [isSorted, setIsSorted] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [refreshList, setRefreshList] = useState(null);
  const [subscriptionListUpdatedDetails, setSubscriptionListUpdatedDetails] = useState([]);
  const [listFilterText, setListFilterText] = useState("");
  const [toggleFilterSearch, setToggleFilterSearch] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState(null);

  const reload = (subId, action, resData) => {
    if (!subId || !action) {
      return;
    }

    if (action === 'add' && resData.length > 0) {
      const sortedData = resData.sort((a, b) => b.date > a.date ? 1 : -1);

      dispatch({ type: 'SUBSCRIPTION', payload: { subscriptionList: sortedData } });
      dispatch({ type: 'RELATION', payload: { subscriptionList: sortedData } });

      setSelectedSubscr(subId);
      setData(sortedData);
      setHasLoaded(true);

      setRefreshList(true);

      history.push(`/relations/${relationId}/subscriptions/${subId}`);
    }

    if (action === 'delete' && resData.length > 0) {
      const sortedData = resData.sort((a, b) => b.date > a.date ? 1 : -1);

      sessionStorage.removeItem('lastVisitSubscrId');

      dispatch({ type: 'SUBSCRIPTION', payload: { subscriptionList: sortedData } });
      dispatch({ type: 'RELATION', payload: { subscriptionList: sortedData } });

      setSelectedSubscr(sortedData[0].id);
      setData(sortedData);
      setHasLoaded(true);

      setRefreshList(true);
    }

    if (action === 'update' && resData/* && resData.length > 0*/) {
      const index = data.findIndex(i => i.id === subId),
        updatedSubscriptionList = [...data]; // important to create a copy, otherwise you'll modify state outside of setState call

      updatedSubscriptionList[index] = resData;

      const sortedData = updatedSubscriptionList.sort((a, b) => b.date > a.date ? 1 : -1);

      dispatch({ type: 'SUBSCRIPTION', payload: { subscriptionList: sortedData } });
      dispatch({ type: 'RELATION', payload: { subscriptionList: sortedData } });

      setData(sortedData);
      setIsUpdated(true);
      setHasLoaded(true);
    }
  };

  const toggleSubscriptionsList = () => {
    const isOpen = props.sidebarMenu.isSidebarMenuSubscriptionsOpen;
    if (isOpen) {
      setSidebarMenuOpen(false);
      dispatch(hideSidebarMenuSubscriptions());
    } else {
      setSidebarMenuOpen(true);
      dispatch(showSidebarMenuSubscriptions());
    }
  };

  const toggleForm = () => {
    setToggle(!toggle);
  };

  const toggleFormEdit = () => {
    setToggleEdit(!toggleEdit);
  };

  useEffect(() => {
    if (relation && relation.id && parseInt(relation.id) !== parseInt(relationId)) {
      dispatch({ type: 'RELATION', payload: relation });

      setIsListUpdated(true);

    }
  }, [relationId]);

  useEffect(() => {
    (async () => {
      if (!loader)
        dispatch(loaderToggle(true));

      const [relationBillingStyles, subscriptionsList, statuses] = await Promise.all([
        GetRelationBillingStylesList(),
        GetSubscriptions(null, parseInt(params.relationId)),
        GetSubscriptionStatuses()
      ]);

      if (relationBillingStyles && relationBillingStyles.ok) {
        const { data } = relationBillingStyles;

        let billing_styles_arr = [];

        if (data && data.data && typeof data.data === "object") {
          for (let key of Object.keys(data.data)) {
            billing_styles_arr.push(
              { label: data.data[key], value: parseInt(key) });
          }
        }
      }

      // get list relation subscriptions
      if (subscriptionsList.ok) {
        const { data } = subscriptionsList;
        setData(data);
        setSubscriptionList(data);

        dispatch({ type: 'SUBSCRIPTION', payload: { subscriptionList: data } });
        dispatch({ type: 'RELATION', payload: { subscriptionList: data } });

        if (!hasLoaded) setSelectedSubscr(selectedSubscr ? selectedSubscr : data && data.length > 0 ? data[0].id : null);

      }

      let status_opts = [];
      if (statuses.ok) {
        statuses.data.data.map(({ id, status, label }) => {
          return status_opts.push({
            label: label,
            value: id
          })
        });

        setSubscriptionStatuses(status_opts);
      }
      dispatch(loaderToggle(false));

    })();
  }, []);

  useEffect(() => {
    if (subscriptionListUpdatedDetails) {
      if (data && data.length > 0) {
        data.map((item) => {
          let item_updated_status = subscriptionListUpdatedDetails && subscriptionListUpdatedDetails.subscription_status && subscriptionListUpdatedDetails.subscription_status.label
            ? subscriptionListUpdatedDetails.subscription_status.label
            : '';

          if (item.id === subscriptionListUpdatedDetails.id) {
            item.subscription_start = subscriptionListUpdatedDetails.subscription_start;
            item.subscription_stop = subscriptionListUpdatedDetails.subscription_stop;
            item.status = subscriptionListUpdatedDetails.status;
            item.subscription_status_label = item_updated_status ? item_updated_status : null;
            item.description = subscriptionListUpdatedDetails.description;
            item.costs = subscriptionListUpdatedDetails.costs;
            item.price_excl_vat = subscriptionListUpdatedDetails.price_excl_vat;
            item.price_incl_vat = subscriptionListUpdatedDetails.price_incl_vat;
          }
          return item;
        });

        const sortedData = data;
        dispatch({ type: 'SUBSCRIPTION', payload: { subscriptionList: sortedData } });
        dispatch({ type: 'RELATION', payload: { subscriptionList: sortedData } });
        setData(sortedData);
        setIsSorted(true);

        if (!hasLoaded) setSelectedSubscr(
          selectedSubscr ? selectedSubscr : sortedData[0].id);

      } else {
        setData([]);
      }
    }
  }, [subscriptionListUpdatedDetails, hasLoaded]);

  useEffect(() => {
    if (data && data.length > 0) {
      const sortedData = data.sort((a, b) => b.subscription_start > a.subscription_start ? 1 : -1);
      setSubscriptionList(sortedData);
      setEmptyMessage(null);

      dispatch({ type: 'RELATION', payload: { subscriptionList: sortedData } });

      let subId;
      let lastVisitSubscrId = sessionStorage.getItem('lastVisitSubscrId');
      if (lastVisitSubscrId && data.map((item) => parseInt(item.id) === parseInt(lastVisitSubscrId) && parseInt(item.relation_id) === parseInt(relationId))) {
        subId = parseInt(lastVisitSubscrId);
      } else if (matchedRoute && matchedRoute.params && matchedRoute.params.subscrId && data && data.length > 0 && data.filter(i => parseInt(i.id) === parseInt(matchedRoute.params.subscrId))) {
        subId = parseInt(matchedRoute.params.subscrId);
      } else if (data.filter(i => parseInt(i.id) === parseInt(data[0].id) && parseInt(i.relation_id) === parseInt(relationId))) {
        subId = parseInt(data[0].id);
      }

      if (subId) {
        setSelectedSubscr(subId);
        setSubscrId(subId);
      }
    } else {
      //setTimeout(() => {
      setEmptyMessage("No subscriptions found.");
      //}, 1500);
    }
  }, [data]);

  useEffect(() => {
    setDataDetails(null);

    if (subscriptionList && subscriptionList.length > 0) {
      let dataSubscr;
      subscriptionList.map((item, i) => {
        if (parseInt(item.id) === parseInt(selectedSubscr) && parseInt(item.relation_id) === parseInt(relationId)) {
          dataSubscr = item;
        }
      });
      if (dataSubscr) {

        dataSubscr.subscriptionList = data;
        setDataDetails(dataSubscr);

        dispatch({ type: 'SUBSCRIPTION', payload: dataSubscr });
      }
    }
  }, [selectedSubscr]);

  useEffect(() => {
    if (refreshList === true || solrSearch.solrSearchResultUpdate === true) {
      setData([]);

      let relation_id = relationId;
      if (relationId !== parseInt(params.relationId)) {
        relation_id = parseInt(params.relationId);
      }

      (async () => {
        if (!loader)
          dispatch(loaderToggle(true));

        const [relationBillingStyles, subscriptionsList, statuses] = await Promise.all(
          [
            GetRelationBillingStylesList(),
            GetSubscriptions(null, parseInt(relation_id)),
            GetSubscriptionStatuses()
          ]);

        if (relationBillingStyles && relationBillingStyles.ok) {
          const { data } = relationBillingStyles;

          let billing_styles_arr = [];

          if (data && data.data && typeof data.data === "object") {
            for (let key of Object.keys(data.data)) {
              billing_styles_arr.push(
                { label: data.data[key], value: parseInt(key) });
            }
          }
        }

        // get list relation subscriptions
        if (subscriptionsList.ok) {
          const { data } = subscriptionsList;
          setData(data);

          if (!hasLoaded) setSelectedSubscr(selectedSubscr ? selectedSubscr : data && data.length > 0 ? data[0].id : null);

        }

        let status_opts = [];
        if (statuses.ok) {
          statuses.data.data.map(({ id, status, label }) => {
            return status_opts.push({
              label: label,
              value: id
            })
          });

          setSubscriptionStatuses(status_opts);
        }
        dispatch(loaderToggle(false));

      })();

      //setIsUpdated(true);
      setRefreshList(false);
    }
  }, [refreshList, solrSearch.solrSearchResultUpdate]);

  useEffect(() => {
    console.log("Subscriptions ListDetails - isUpdated ->", isUpdated);
    console.log("Subscriptions ListDetails - isUpdated ->", isUpdated);

    if (isUpdated === true) {
      console.log("Subscriptions ListDetails - subscriptionLine isUpdated !!!!!!! ->", isUpdated);

      //setHasLoaded(true);
      setIsListUpdated(true);
      setIsUpdated(false);
    }

  }, [isUpdated]);

  useEffect(() => {
    if (isListUpdated === true) {
      (async () => {
        const [subscriptionsList] = await Promise.all(
          [
            GetSubscriptions(null, parseInt(relationId))
          ]);

        // get list relation subscriptions
        if (subscriptionsList.ok) {
          const { data } = subscriptionsList;
          setData(data);

          if (!hasLoaded) setSelectedSubscr(selectedSubscr ? selectedSubscr : data && data.length > 0 ? data[0].id : null);
        }
      })();

      setIsUpdated(false);
      setIsListUpdated(false);
    }

  }, [isListUpdated]);

  const refreshSubscriptionList = (e) => {
    if (e) {
      e.preventDefault();
    }

    setEmptyMessage(null);
    setRefreshList(true);
  };

  const handleFilterTextInputChange = (e) => {
    //console.log("handleFilterTextInputChange - e ->", e);
    //console.log("handleFilterTextInputChange - e.target ->", e.target);

    setListFilterText(e.target.value);

    setTimeout(() => {
      e.target.focus();
    }, 100);
  };

  const showFilterSearch = (e) => {
    e.preventDefault();

    setToggleFilterSearch(!toggleFilterSearch)
  }

  if (data === null || (data && data.length === 0)) {
    return (
      <>
        {newTitle &&
          <Helmet>
            <title>{newTitle}</title>
          </Helmet>}
        <Row className="mt-4 subscription-details-container full-height-container">
          <Col className="col-md-3 col-sm-12 col-lg-3 f2x-sbscr-det-cont">
            <div className={`align-content-start all-0 d-flex flex-column flex-list-items ${"sidebarMenu" + (props.sidebarMenu.isSidebarMenuSubscriptionsOpen ? " toggled-list" : "")}`}>
              <div>
                {!toggle && emptyMessage && !loader &&
                  <p className="empty-message">{emptyMessage}</p>
                }
                <OverlayTrigger
                  key={`add-subscription-details-tooltip-overlay`}
                  placement={`bottom`}
                  transition={false}
                  delay={200}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      container="body"
                      id={`tooltip-bottom-add-subscription-details`}
                    >
                      {`Add`}
                    </Tooltip>}
                >
                  <Button color="gray" className="mb-1 btn"
                    onClick={() => toggleForm()}>
                    <FontAwesomeIcon color="gray" icon={faPlus} />
                  </Button>
                </OverlayTrigger>
                {data && data.length > 0 &&
                  <Button color="gray" className="mb-1 btn ml-1" style={{ float: "right" }}
                    onClick={(e) => refreshSubscriptionList(e)}>
                    <FontAwesomeIcon color="gray" icon={faCircleNotch} />
                  </Button>}
              </div>
              {data &&
                <SubscriptionList
                  data={data}
                  relationId={relationId}
                  relation={relation}
                  subscrId={subscrId}
                  setData={setData}
                  selectedSubscr={selectedSubscr}
                  setSelectedSubscr={setSelectedSubscr}
                  setToggleEdit={setToggleEdit}
                  toggleEdit={true}
                  isUpdated={isUpdated}
                  subscriptionList={subscriptionList}
                  subscriptionListUpdatedDetails={subscriptionListUpdatedDetails}
                />}
            </div>
          </Col>
          {toggle && <SubscriptionForm
            show={toggle}
            hide={toggleForm}
            setData={setData}
            setToggle={setToggle}
            id={selectedSubscr}
            setSelectedSubscr={setSelectedSubscr}
            relationId={relationId}
            relation={relation}
            updateDetails={reload}
            subscriptionList={subscriptionList}
          />}
        </Row>
        <div className="invoice-list-btns-small-devices shadow">
          <Button color="primary" className="btn shadow"
            onClick={() => toggleForm()}>Add
          </Button>
          <Button color="primary" className="btn shadow"
            onClick={() => dispatch(toggleSubscriptionsList())}>List
          </Button>
        </div>
      </>)
  } else {
    return (
      <>
        {newTitle &&
          <Helmet>
            <title>{newTitle}</title>
          </Helmet>}
        <Row className="mt-4 subscription-details-container full-height-container">
          <Col className="col-md-3 col-sm-12 col-lg-3 col-12 f2x-sbscr-det-cont">
            <div className={`align-content-start all-0 d-flex flex-column flex-list-items ${"sidebar-menu" + (props.sidebarMenu.isSidebarMenuSubscriptionsOpen ? " toggled" : "")}`}>
              <div className="invoice-list-btns">
                <OverlayTrigger
                  key={`add-subscription-tooltip-overlay`}
                  placement={`bottom`}
                  transition={false}
                  delay={200}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      container="body"
                      id={`tooltip-bottom-add-subscription`}
                    >
                      {`Add`}
                    </Tooltip>}
                >
                  <Button color="gray" className="mb-1 btn"
                    onClick={() => toggleForm()}>
                    <FontAwesomeIcon color="gray" icon={faPlus} />
                  </Button>
                </OverlayTrigger>

                <FilterSearch
                  listFilterText={listFilterText}
                  handleFilterTextInputChange={handleFilterTextInputChange}
                  inputId="filter_subscription_text"
                  inputName="filter_subscription_text"
                  setListFilterText={setListFilterText}
                />

                {data && data.length > 0 &&
                  <OverlayTrigger
                    key={`refresh-subscriptions-tooltip-overlay`}
                    placement={`bottom`}
                    transition={false}
                    delay={200}
                    overlay={
                      <Tooltip
                        className="tooltip"
                        container="body"
                        id={`tooltip-bottom-refresh-subscriptions`}
                      >
                        {`Refresh`}
                      </Tooltip>}
                  >
                    <Button color="gray" className="mb-1 btn ml-1" style={{ float: "right" }} data-label="Refresh" title="Refresh"
                      onClick={(e) => refreshSubscriptionList(e)}>
                      <FontAwesomeIcon color="gray" icon={faCircleNotch} />
                    </Button>
                  </OverlayTrigger>
                }
              </div>
              {data &&
                <SubscriptionList
                  data={data}
                  relationId={relationId}
                  relation={relation}
                  subscrId={subscrId}
                  setData={setData}
                  selectedSubscr={selectedSubscr}
                  setSelectedSubscr={setSelectedSubscr}
                  setToggleEdit={setToggleEdit}
                  toggleEdit={true}
                  subscriptionList={subscriptionList}
                  isUpdated={isUpdated}
                  subscriptionListUpdatedDetails={subscriptionListUpdatedDetails}
                  setSubscriptionListUpdatedDetails={setSubscriptionListUpdatedDetails}
                  subscriptionStatuses={subscriptionStatuses}
                  listFilterText={listFilterText}
                />}
            </div>
          </Col>
          <Col className="col-md-9 col-sm-12 col-lg-9 col-12 f2x-rel-mv-detail subscr d-flex flex-wrap">
            {selectedSubscr && <SubscriptionDetail
              //id={selectedSubscr}
              id={selectedSubscr && !isNaN(selectedSubscr) ? selectedSubscr : props.subscrId}
              subscrId={subscrId}
              setSubscrId={setSubscrId}
              relationId={relationId}
              relation={relation}
              setRelationId={setRelationId}
              selectedSubscr={selectedSubscr}
              setSelectedSubscr={setSelectedSubscr}
              dataDetails={dataDetails}
              subscriptionList={subscriptionList}
              setData={setData}
              setIsUpdated={setIsUpdated}
              setIsListUpdated={setIsListUpdated}
              isUpdated={isUpdated}
              setToggleEdit={toggleFormEdit}
              toggleEdit={toggleEdit}
              updateDetails={reload}
              subscriptionListUpdatedDetails={subscriptionListUpdatedDetails}
              setSubscriptionListUpdatedDetails={setSubscriptionListUpdatedDetails}
              subscriptionStatuses={subscriptionStatuses}
              refreshSubscriptionList={refreshSubscriptionList}
            />}

            {/*!toggle && emptyMessage &&
              <p style={{textAlign:"right", width:"100%"}}><span>{emptyMessage}</span></p>
            */}
          </Col>
          {toggle && <SubscriptionForm
            show={toggle}
            hide={toggleForm}
            setData={setData}
            setToggle={setToggle}
            id={selectedSubscr}
            setSelectedSubscr={setSelectedSubscr}
            relationId={relationId}
            relation={relation}
            updateDetails={reload}
            subscriptionList={subscriptionList}
          />}
        </Row>
        <div className="invoice-list-btns-small-devices shadow">
          <Button color="primary" className="btn shadow"
            onClick={() => toggleForm()}>Add
          </Button>
          <Button color="primary" className="btn shadow"
            onClick={() => toggleSubscriptionsList()}>List
          </Button>
        </div>
      </>)
  }
};

const sidebarMenuSelector = createSelector(
  state => state.sidebarMenu,
  sidebarMenu => sidebarMenu
);

const mapStateToProps = ({ solrSearch, sidebarMenu, relation, dispatch, user, loader }) => ({ solrSearch, sidebarMenu, relation, dispatch, user, loader });

export default withRouter(connect(mapStateToProps)(SubscriptionDetails));
