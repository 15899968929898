import React, { useEffect, useState, useRef } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import {
  GetSubscriptionsSummary, GetSubscriptionStatuses,
} from '../../../controllers/subscriptions';
import SubscriptionForm from './Form';
import JsonDataForm from './json_data/Form';
import ParentTable from '../../../components/ParentTable';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';
import { customFilter } from 'react-bootstrap-table2-filter';
import CustomFilterDateComp
  from '../../../components/filters/CustomFilterDateComp';
import CustomFilterInput from '../../../components/filters/CustomFilterInput';
import CustomFilterSelect from '../../../components/filters/CustomFilterSelect';
import { parseDateToSaveFormatObject } from '../../../redux/actions/formatActions';
import CustomFilterInputComp
  from '../../../components/filters/CustomFilterInputComp';

const dateLocalesPath = {
  'nl': 'nl',
  'en': 'en-GB',
  'us': 'en-US'
};

const debugInfo = false;

const Subscriptions = ({ ...props }) => {
  const location = useLocation();
  const tableName = "Subscriptions";
  const { t } = useTranslation();

  let statusListLoaded = true;

  let [customerNumberFilter, setCustomerNumberFilter] = useState(null);
  let [descriptionFilter, setDescriptionFilter] = useState(null);
  let [startDateFilter, setStartDateFilter] = useState(null);
  let [endDateFilter, setEndDateFilter] = useState(null);
  let [costsFilter, setCostsFilter] = useState(null);
  let [statusFilter, setStatusFilter] = useState(null);

  const [toggleFilterForm, setToggleFilterForm] = useState(false);
  const [collapseState, setCollapseState] = useState("is-hidden");
  //const [collapseState, setCollapseState] = useState(sessionStorage.subscriptionsFilterVisible ? sessionStorage.subscriptionsFilterVisible : "is-hidden");
  const [clearFilters, setClearFilters] = useState(false);

  /* start filters - state variables */
  const [customerNumber, setCustomerNumber] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState(""); // if multiSelect column -> set to [] as initialState
  const [costs, setCosts] = useState("");

  const [sort, setSort] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(100);  
  const [paginationCount, setPaginationCount] = useState(1);

  let [filtersFilter, setFiltersFilter] = useState({});
  let [filters, setFilters] = useState({ filter: null, keyword: "" });

  const [enabledFilter, setEnabledFilter] = useState(props.enabledFilter ? props.enabledFilter : false);
  const clearedItemName = useRef("");
  const [comparatorFilter, setComparatorFilter] = useState({});
  /*subscription_start: ">=",
    subscription_stop: "<=",
    price_excl_vat: ">"*/
  const [searchKeyword, setSearchKeyword] = useState("");

  const [isLoadData, setIsLoadData] = useState(false);
  const [statusOptsData, setStatusOptsData] = useState([]);

  const getLocale = locale => require(`date-fns/locale/${dateLocalesPath[props.i18n.language]}/index.js`); // en-US - en-GB - nl

  const StatusStyle = (cell) => {
    //console.log("StatusStyle - cell ->", cell);
    //const subscription_status = CONSTANTS.subscriptionStatus.map(i => i.id === cell);

    let subscription_status;
    return cell !== null && cell !== undefined && cell ?
      <Badge
        color={cell === 50
          ? 'success'
          : cell === 99
            ? 'danger'
            : cell === 10
              ? 'warning'
              : cell === 30
                ? 'default'
                : cell === 40
                  ? 'default'
                  : cell === 60
                    ? 'warning'
                    : cell === 80
                      ? 'default'
                      : 'info'}
        className="badge-pill mr-1 mb-0 text-uppercase">
        {cell === 50 ? subscription_status = 'ONGOING'
          : cell === 99 ? subscription_status = 'TERMINATED'
            : cell === 10 ? subscription_status = 'NEW'
              : cell === 30 ? subscription_status = 'PROVISIONING'
                : cell === 40 ? subscription_status = 'PLANNED'
                  : cell === 60 ? subscription_status = 'CANCELLED'
                    : cell === 80 ? subscription_status = 'DE-PROVISIONING'
                      : subscription_status = 'CONCEPT'}
      </Badge> : String.fromCharCode(8212)
  };

  const moneyStyle = (cell) => {
    let class_name;
    let floatCell = parseFloat(cell ? cell : null);
    if (floatCell && floatCell < 0) {
      class_name = 'float-negative';
    } else if (floatCell && floatCell > 0) {
      class_name = 'float-positive';
    } else {
      class_name = 'float-null';
    }

    let total = new Intl.NumberFormat('en-US',
      { style: 'currency', currency: 'EUR' }
    ).format(floatCell);

    return cell !== null && cell !== undefined && floatCell ? <span className={class_name}>{total}</span> : String.fromCharCode(8212)
  };

  const formatCurrency = (cell, row) => {
    if (cell || cell === 0) {
      let floatCell = parseFloat(cell);
      let floatStyle = 'float-null';
      let priceStr = t("currency_format", { number: Number(floatCell) });

      if (row.price_incl_vat || row.price_incl_vat === 0) {
        let floatPrice = row.price_incl_vat ? parseFloat(row.price_incl_vat) : 0;
        priceStr = priceStr + ' / ' + t("currency_format", { number: Number(floatPrice) });
      }

      return <span className={floatStyle ? floatStyle : 'float-zero'}>{priceStr}</span>
    }
  };

  const formatDate = (cell) => {
    if (cell) {
      return <span className="date-format">{t("date_format",
        { date: new Date(cell) })}</span>
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  /* Filter */
  const handleFiltering = (e, name, multiSelectable, comparator = null) => {
    if (debugInfo) {
      console.log(
        "SubscriptionsList - handleFiltering - e ->",
        e);
      console.log(
        "SubscriptionsList - handleFiltering - name ->",
        name);
      console.log(
        "SubscriptionsList - handleFiltering - comparator ->",
        comparator);
      console.log(
        "SubscriptionsList - handleFiltering - filters: customerNumberFilter ->",
        customerNumberFilter);
      console.log(
        "SubscriptionsList - handleFiltering - filters: descriptionFilter ->",
        descriptionFilter);
      console.log(
        "SubscriptionsList - handleFiltering - filters: startDateFilter ->",
        startDateFilter);
      console.log(
        "SubscriptionsList - handleFiltering - filters: endDateFilter ->",
        endDateFilter);
      console.log(
        "SubscriptionsList - handleFiltering - filters: costsFilter ->",
        costsFilter);
      console.log(
        "SubscriptionsList - handleFiltering - filters: statusFilter ->",
        statusFilter);
    }

    let filterStoredItem;
    let historyFilter = false;
    // custom comparator
    if (comparator) {
      setComparatorFilter({
        ...comparatorFilter,
        [name]: comparator
      });
    }

    if (!enabledFilter) {
      let filtersObj = sessionStorage.getItem("qStrSubscriptionsFiltersObj");
      if (filtersObj) {
        let parsedFiltersObj = JSON.parse(filtersObj);
        if (parsedFiltersObj && parsedFiltersObj.filter) {
          historyFilter = true;
          // setCollapseState("is-visible");
          setToggleFilterForm(true);

          if (parsedFiltersObj.filter) {
            filtersFilter = parsedFiltersObj.filter;
            let searchedKeyword = sessionStorage.getItem("qStrSubscriptionsKeyword");
            if (searchedKeyword) {
              parsedFiltersObj.keyword = searchedKeyword;
            }
            setFiltersFilter(filtersFilter);
          }
          filters = parsedFiltersObj;
          setFilters(filters);
        }
      }
    }

    if (
      (name && e && (e.value !== "" || e.value === "")) ||
      (name && e === null) ||
      (e && e.key && e.key === "Enter") ||
      (e && multiSelectable && (e.length > 0 || e.length === null || e.length === 0)) ||
      historyFilter
    ) {
      let value = "";
      switch (name) {
        case "customer_number":
          let customer_number = e && e.target && e.target.value
            ? e.target.value
            : !enabledFilter && filtersFilter && filtersFilter["customer_number"] ? filtersFilter["customer_number"] : "";
          if (customer_number === "") {
            customer_number = null;
          }
          value = customer_number;
          setCustomerNumber(value);
          break;
        case "description":
          let description = e && e.target && e.target.value
            ? e.target.value
            : !enabledFilter && filtersFilter && filtersFilter["description"] ? filtersFilter["description"] : "";
          if (description === "") {
            description = null;
          }
          value = description;
          setDescription(value);
          break;
        case "subscription_start":
          if (e && e.target && e.target.attributes && e.target.attributes.name && e.target.attributes.name.value && e.target.attributes.name.value === "filter-date-clear-icon") {
            value = null;
          } else {
            let start_date = e ? e : !enabledFilter && filtersFilter &&
              filtersFilter["subscription_start"]
              ? filtersFilter["subscription_start"]
              : "";

            if (start_date === "") {
              value = null;
            } else {
              let validateDate = parseDateToSaveFormatObject(new Date(start_date));
              if (validateDate === "" && e && e.value) {
                let convertDateArr = e.value.split("-");
                value = convertDateArr[2] + "-" + convertDateArr[1] + "-" + convertDateArr[0];
              } else {
                value = start_date && start_date !== "" ? parseDateToSaveFormatObject(new Date(start_date)) : "";
              }
            }
          }
          setStartDate(value);
          break;
        case "subscription_stop":
          if (e && e.target && e.target.attributes && e.target.attributes.name && e.target.attributes.name.value && e.target.attributes.name.value === "filter-date-clear-icon") {
            value = null;
          } else {
            let end_date = e ? e : !enabledFilter && filtersFilter &&
              filtersFilter["subscription_stop"]
              ? filtersFilter["subscription_stop"]
              : "";

            if (end_date === "") {
              value = null;
            } else {
              let validateDate = parseDateToSaveFormatObject(new Date(end_date));
              if (validateDate === "" && e && e.value) {
                let convertDateArr = e.value.split("-");
                value = convertDateArr[2] + "-" + convertDateArr[1] + "-" + convertDateArr[0];
              } else {
                value = end_date && end_date !== "" ? parseDateToSaveFormatObject(new Date(end_date)) : "";
              }
            }
          }
          setEndDate(value);
          break;
        case "price_excl_vat":
          let costs = e && e.value
            ? e.value
            : e && e.target && e.target.value
              ? e.target.value
              : !enabledFilter && filtersFilter && filtersFilter["price_excl_vat"] ? filtersFilter["price_excl_vat"] : "";
          if (costs === "") {
            costs = null;
          }
          value = costs;
          setCosts(value);
          break;
        case "status":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["status"] ? filtersFilter["status"] : null;
            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setStatus(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setStatus(!enabledFilter && filtersFilter && filtersFilter["status"] ? filtersFilter["status"] : null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["status"] ? filtersFilter["status"] : "";
            if (value === "") {
              value = null;
            }
            setStatus(value);
          }
          break;
        default:
          break;
      }

      if (debugInfo) {
        console.log("SubscriptionsList - handleFiltering - name ->", name);
        console.log("SubscriptionsList - handleFiltering - value ->", value);
      }

      if (filtersFilter) {
        if (filtersFilter[name] && (!value || value === "" || value.length === 0)) {
          delete filtersFilter[name];
        } else {
          if (value) {
            filtersFilter[name] = value;
          }
        }

        if (debugInfo) {
          console.log("SubscriptionsList - handleFiltering - filtersFilter (before setting new filtersFilter) ->", filtersFilter);
        }

        if (Object.entries(filtersFilter).length > 0) {
          setFiltersFilter(filtersFilter);
          filters.filter = filtersFilter;
          setFilters(filters);
          setQueryParameters();
          setIsLoadData(true);
        } else {
          filters.filter = filtersFilter;
          setFilters(filters);
          setQueryParameters();
          setIsLoadData(true);
        }
      }
    }
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  };

  const processMultiValues = (multiValueArr) => {
    let multiValues = [];
    for (let i = 0; i < multiValueArr.length; i++) {
      if (multiValueArr[i] && multiValueArr[i].value) {
        multiValues.push(multiValueArr[i].value);
      }
    }
    return multiValues.join(",");
  };

  const processKey = (key, value, queryFilter, comparatorItems) => {
    switch (key) {
      case "status":
        let multiKeyFilters = processMultiValues(value[key]);
        queryFilter.push(`${key}=[${multiKeyFilters}]`);
        break;
      case "subscription_start":
      case "subscription_stop":
      case "price_excl_vat":
        queryFilter.push(`${key}${comparatorItems && comparatorItems[key] ? comparatorItems[key] : ">="}${value[key]}`);
        break;
      default:
        queryFilter.push(`${key}%${value[key]}`);
        break;
    }
  }

  const setQueryParameters = () => {
    // get history (default) params
    let params = {};
    const historyParamsQueryString = sessionStorage.getItem("qStrSubscriptions");
    if (historyParamsQueryString) {
      // page - offset - count - sort - search
      historyParamsQueryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
        if (key === 'page' || key === 'offset' || key === 'count') {
          params[key] = parseInt(value);
        } else if (key === 'sort') {
          params[key] = value;
        } else if (key === 'search') {
          params[key] = value;
        }
      });
    }

    const savedComparators = sessionStorage.getItem("qStrSubscriptionsComparatorItems");
    const savedComparatorsParsed = savedComparators ? JSON.parse(savedComparators) : null;

    if (debugInfo) {
      console.log("SubscriptionList - setQueryParameters - filters ->", filters);
      console.log("SubscriptionList - setQueryParameters - props ->", props);
      console.log("SubscriptionList - setQueryParameters - historyParamsQueryStr ->", historyParamsQueryString);
      console.log("SubscriptionList - setQueryParameters - params ->", params);
      console.log("SubscriptionList - setQueryParameters - savedComparatorsParsed ->", savedComparatorsParsed);
    }

    let key;
    let value;
    let query = [];
    let searchFilterQuery = {};
    let filterQuery = {};
    let searchFilterStr = "";
    let comparatorItems = comparatorFilter ? comparatorFilter : {};

    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - comparatorItems ->", comparatorItems);
    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - savedComparatorsParsed ->", savedComparatorsParsed);
    if (savedComparatorsParsed && !isEmpty(savedComparatorsParsed)) {
      comparatorItems = { ...comparatorFilter, ...savedComparatorsParsed };
    }
    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - comparatorItems ->", comparatorItems);

    query.push(`count=1`);
    if (!filters) {
      query.push(`search=`);
      query.push(`filter=`);
    }
    query.push(`offset=${params && params.offset ? params.offset : sizePerPage ? sizePerPage : 10}`);
    query.push(`page=${params && params.page ? params.page : page ? page : 1}`);
    query.push(`sort=${params && params.sort ? params.sort : sort ? sort : ""}`);

    // remove filters from session storage
    sessionStorage.removeItem("qStrSubscriptions");
    sessionStorage.removeItem("qStrSubscriptionsFilter");
    sessionStorage.removeItem("qStrSubscriptionsFilterStr");
    sessionStorage.removeItem("qStrSubscriptionsFiltersObj");
    sessionStorage.removeItem("qStrSubscriptionsComparatorItems");
    sessionStorage.removeItem("qStrSubscriptionsKeyword");

    //console.log("SubscriptionList (table) - comparatorFilter ->", comparatorFilter);
    //console.log("SubscriptionList (table) - comparatorFilter - setQueryParameters - savedComparatorsParsed ->", savedComparatorsParsed);

    //if (filters) {
    for (key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (key === "keyword") {
          value = filters[key];
          searchFilterQuery[key] = value && true ? value : "";
          // set search for querystring
          query.push(`search=${filters[key]}`);

        } else if (key === "filter") {
          value = filters[key];


          // set total filter
          let queryKeyFilters = "";
          let queryFilters = "";

          // if value is array - value.length > 0 - there are filters
          if (value && Array.isArray(value)) {
            if (value.length > 0) {
              let queryFilter = [];
              for (let i = 0; i < value.length; i++) {
                for (let key in value[i]) {
                  if (value[i].hasOwnProperty(key)) {
                    processKey(key, value[i], queryFilter, comparatorItems);
                  }
                }
              }
              if (queryFilter.length > 0) {
                queryKeyFilters = queryFilter.join(",");
                queryFilters = queryFilter.join("~");
              }
              searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
              filterQuery['filter'] = queryFilters ? queryFilters : "";
            }
          } else {
            let queryFilter = [];
            for (let name in value) {
              if (value.hasOwnProperty(name)) {
                processKey(name, value, queryFilter, comparatorItems);
              }
            }
            if (queryFilter.length > 0) {
              queryKeyFilters = queryFilter.join(",");
              queryFilters = queryFilter.join("~");
            }
            searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
            filterQuery['filter'] = queryFilters ? queryFilters : "";
          }
          query.push(`filter=${queryKeyFilters}`);
        }
      }
    }
    query.join("&").toString();
    setEnabledFilter(true);

    //} else {
    //  query.join("&").toString();
    //}

    const location_search_query = query;
    let location_search = location_search_query
      ? location_search_query.join("&")
      : "";

    sessionStorage.setItem("qStrSubscriptions", location_search);
    sessionStorage.setItem("qStrSubscriptionsFilter", searchFilterQuery['filter'] && searchFilterQuery['filter'] !== "" ? searchFilterQuery['filter'] : "");
    sessionStorage.setItem("qStrSubscriptionsFilterStr", filterQuery['filter'] && filterQuery['filter'] !== "" ? filterQuery['filter'] : "");
    sessionStorage.setItem("qStrSubscriptionsKeyword", searchFilterQuery['keyword'] ? searchFilterQuery['keyword'] : searchKeyword);
    sessionStorage.setItem("qStrSubscriptionsFiltersObj", JSON.stringify(filters));
    sessionStorage.setItem("qStrSubscriptionsComparatorItems", JSON.stringify(comparatorItems));    
  };

  const statusList = () => {
    (async () => {
      const [statusListItems] = await Promise.all([
        GetSubscriptionStatuses(),
      ]);

      //console.log("Subscription list - statusListItems ->", statusListItems);

      if (statusListItems.ok) {
        let statusOpts = [];
        if (statusListItems.data && statusListItems.data.data) {
          // set status list
          //statusOpts.push({ label: "All", value: "" });
          for (let i = 0; i < statusListItems.data.data.length; i++) {
            statusOpts.push({ label: statusListItems.data.data[i].status, value: statusListItems.data.data[i].id });
          }
          setStatusOptsData(statusOpts);
        }
      }
      statusListLoaded = false;
    })();
  };

  const handleFilterClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    sessionStorage.removeItem("qStrSubscriptions");
    sessionStorage.removeItem("qStrSubscriptionsFilter");
    sessionStorage.removeItem("qStrSubscriptionsFilterStr");
    sessionStorage.removeItem("qStrSubscriptionsKeyword");
    sessionStorage.removeItem("qStrSubscriptionsFiltersObj"); 
    sessionStorage.removeItem("qStrSubscriptionsComparatorItems"); 

    setCustomerNumberFilter([]);
    setDescriptionFilter([]);
    setStartDateFilter([]);
    setEndDateFilter([]);
    setCostsFilter([]);
    setStatusFilter([]);

    setCustomerNumber("");
    setDescription("");
    setStartDate("");
    setEndDate("");
    setCosts("");
    setStatus("");

    setPage(1);
    setPageNum(1);
    setSort("");
    setSizePerPage(100);
    setFiltersFilter({});
    setFilters({ filter: null, keyword: "" });
    setClearFilters(true);
    setPaginationCount(1);
    setEnabledFilter(false);
    setIsLoadData(true);

    setComparatorFilter({
      subscription_start: ">=", subscription_stop: "<=", price_excl_vat: ">"
    });

    setQueryParameters();

    let input = document.getElementById("table-search-form");
    input.value = '';

    sessionStorage.clear(); // This clears all data in sessionStorage
  };

  useEffect(() => {    
    if (clearFilters === true) {
      setPaginationCount(1);
      setClearFilters(false);
      setIsLoadData(true);
    }
  }, [clearFilters]);

  useEffect(() => {    
    if (searchKeyword && searchKeyword !== "") {
      filters.filter = filtersFilter;
      filters.keyword = searchKeyword;
      setFilters(filters);

      sessionStorage.setItem("qStrSubscriptionsKeyword", searchKeyword);

      setPage(1);
      setPageNum(1);
      setQueryParameters();
      setIsLoadData(true);
    }
  }, [searchKeyword]);

  useEffect(() => {    
    if (statusListLoaded) {
      let data = statusList();
    }
  }, []);

  //useEffect(() => {
  //  console.log("table filter list - (updated - check if null or empty) - filters ->", filters);
  //}, [filters]);

  useEffect(() => {    
    //console.log("table filter list - (updated - clearedItemName) - clearedItemName ->", clearedItemName);

    let tableStorageFilterObjId = "qStrSubscriptionsFiltersObj";

    if (clearedItemName && clearedItemName !== "") {
      let name = clearedItemName;
      let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
      //console.log("table filter list - (updated - clearedItemName) - filtersObj ->", filtersObj);

      if (name && filtersObj) {
        let filtersObjParsed = JSON.parse(filtersObj);
        if (filtersObjParsed && filtersObjParsed.filter && filtersObjParsed.filter.length > 0) {
          for (let i = 0; i < filtersObjParsed.filter.length; i++) {
            if (filtersObjParsed.filter[i] && filtersObjParsed.filter[i][name]) {
              filtersObjParsed.filter.splice(i, 1);

              // save filters to session storage (stringify)
              sessionStorage.setItem("qStrSubscriptionsFiltersObj", JSON.stringify(filtersObjParsed));

              if (filtersObjParsed.filter &&
                filtersObjParsed.filter.length > 0) {
                setFiltersFilter(filtersObjParsed.filter);
              } else {
                setFiltersFilter([]);
              }
              setFilters(filtersObjParsed);
              return;
            }
          }
        } else if (filtersObjParsed && filtersObjParsed.filter && filtersObjParsed.filter[name]) {
          delete filtersObjParsed.filter[name];
          // save filters to session storage (stringify)
          sessionStorage.setItem("qStrSubscriptionsFiltersObj", JSON.stringify(filtersObjParsed));
        }
      }
    } else {
      setFiltersFilter({});
      setFilters({ filter: null, keyword: "" });
      sessionStorage.removeItem(tableStorageFilterObjId);
      setQueryParameters();
    }
  }, [clearedItemName]);

  const collapseFilterAction = (e) => {
    if (e) {
      e.preventDefault();
    }

    let filterVisible = sessionStorage.getItem("subscriptionsFilterVisible");
    //console.log("SubscriptionList - filterVisible ->", filterVisible);

    let collapseStatus = toggleFilterForm === true ? "is-hidden" : "is-visible";
    setCollapseState(collapseStatus);
    setToggleFilterForm(!toggleFilterForm);

    // set to sessionStorage
    sessionStorage.setItem("subscriptionsFilterVisible", collapseStatus);
  };

  useEffect(() => {    
    //console.log("SubscriptionList - useEffect");

    let filterVisible = sessionStorage.getItem("subscriptionsFilterVisible");
    //console.log("SubscriptionList - filterVisible ->", filterVisible);
    if (filterVisible === "is-visible") {
      setToggleFilterForm(true);
      //setToggleForm(false);
      setCollapseState("is-visible");

      // set to sessionStorage
      sessionStorage.setItem("subscriptionsFilterVisible", "is-visible");
    } else if (filterVisible === "is-hidden") {
      setToggleFilterForm(false);
      //setToggleForm(false);
      setCollapseState("is-hidden");

      // set to sessionStorage
      sessionStorage.setItem("subscriptionsFilterVisible", "is-hidden");
    } else {
      setToggleFilterForm(false);
      setCollapseState("is-hidden");
      setFilters({ filter: null, keyword: "" });

      sessionStorage.setItem("subscriptionsFilterVisible", "is-hidden");
    }
  }, []);

  useEffect(() => {    
    const { dispatch } = props;
    dispatch({ type: 'RELATION', payload: null });
    dispatch({ type: 'SUBSCRIPTION', payload: null });
    dispatch({ type: 'INVOICE', payload: null });
  }, []);

  useEffect(() => {    
    let currentParams = new URL(document.location).searchParams;
    //console.log("SubscriptionsList - useEffect (params) - currentParams ->", currentParams);
    if (currentParams.size && currentParams.size > 0) {
      //console.log("SubscriptionsList - useEffect (params) - currentParams.size ->", currentParams.size);
      let qStr = currentParams.toString();
      //console.log("SubscriptionsList - useEffect (params) - qStr ->", qStr);
      sessionStorage.setItem("qStrSubscriptions", qStr);
      setQueryParameters();
    }
  }, []);  

  return (
    <div className="wrapper-box-table-filter">
      <h1 className="text-bold mb-3">Subscriptions</h1>
      <ParentTable
        table="Subscriptions"
        keyField="id"
        id="SubscriptionsTable"
        data={GetSubscriptionsSummary}
        columns={[
          {
            dataField: "relation_id",
            text: "#",
            hidden: true,
            attrs: {
              'data-label': 'id'
            }
          },
          {
            dataField: "customer_number",
            filter: customFilter({
              type: "text",
              style: { display: "inline-grid" },
              getFilter: (filter) => {
                setCustomerNumberFilter(filter);
              },
            }),
            filterRenderer: (onFilter, column) => (
              <CustomFilterInput
                onFilter={onFilter}
                column={column}
                handleFiltering={handleFiltering}
                isClearFilter={clearFilters}
                setClearedItemName={(value) => clearedItemName.current = value}
                value={customerNumber ? customerNumber : ""}
                enabledFilter={enabledFilter}
                setEnabledFilter={setEnabledFilter}
                table="Subscriptions"
              />
            ),
            title: true,
            text: "Customer no.",
            sort: true,
            headerStyle: () => {
              return { width: '11%' }
            },
            attrs: {
              'data-label': 'Customer no.'
            }
          },
          {
            dataField: "description",
            filter: customFilter({
              type: "text",
              style: { display: "inline-grid" },
              getFilter: (filter) => {
                setDescriptionFilter(filter);
              },
            }),
            filterRenderer: (onFilter, column) => (
              <CustomFilterInput
                onFilter={onFilter}
                column={column}
                handleFiltering={handleFiltering}
                isClearFilter={clearFilters}
                setClearedItemName={(value) => clearedItemName.current = value}
                value={description ? description : ""}
                enabledFilter={enabledFilter}
                setEnabledFilter={setEnabledFilter}
                table="Subscriptions"
              />
            ),
            title: true,
            text: "Description",
            sort: true,
            classes: 'txt-overflow-hide',
            headerStyle: () => {
              return { width: '25%' }
            },
            attrs: {
              'data-label': 'Description'
            }
          },
          {
            dataField: "subscription_start",
            filter: customFilter({
              type: "date",
              style: { display: "inline-grid" },
              defaultValue: "", // default value
              getFilter: (filter) => {
                startDateFilter(filter);
              },
            }),
            filterRenderer: (onFilter, column) => (
              <CustomFilterDateComp
                onFilter={onFilter}
                column={column}
                handleFiltering={handleFiltering}
                isClearFilter={clearFilters}
                setClearedItemName={(value) => clearedItemName.current = value}
                icon="right"
                value={startDate ? startDate : ""}
                enabledFilter={enabledFilter}
                setEnabledFilter={setEnabledFilter}
                setComparatorFilter={setComparatorFilter}
                comparatorFilter={comparatorFilter}
                defaultComparator={">="}
                table="Subscriptions"
              />
            ),
            title: true,
            text: "Start",
            sort: true,
            align: "left",
            formatter: formatDate,
            classes: 'subscr-date-label',
            headerStyle: () => {
              return { width: '11%', 'textAlign': 'left' }
            },
            attrs: {
              'data-label': 'Start'
            }
          },
          {
            dataField: "subscription_stop",
            filter: customFilter({
              type: "date",
              style: { display: "inline-grid" },
              defaultValue: "", // default value
              getFilter: (filter) => {
                setEndDateFilter(filter);
              },
            }),
            filterRenderer: (onFilter, column) => (
              <CustomFilterDateComp
                onFilter={onFilter}
                column={column}
                handleFiltering={handleFiltering}
                isClearFilter={clearFilters}
                setClearedItemName={(value) => clearedItemName.current = value}
                icon="left"
                value={endDate ? endDate : ""}
                enabledFilter={enabledFilter}
                setEnabledFilter={setEnabledFilter}
                setComparatorFilter={setComparatorFilter}
                comparatorFilter={comparatorFilter}
                defaultComparator={"<="}
                table="Subscriptions"
              />
            ),
            title: true,
            text: "End",
            sort: true,
            align: "left",
            formatter: formatDate,
            classes: 'subscr-date-label',
            headerStyle: () => {
              return { width: '11%', 'textAlign': 'left' }
            },
            attrs: {
              'data-label': 'End'
            }
          },
          {
            dataField: "price_excl_vat",
            filter: customFilter({
              type: "text",
              style: { display: "inline-grid" },
              getFilter: (filter) => {
                setCostsFilter(filter);
              },
            }),
            filterRenderer: (onFilter, column) => (
              <CustomFilterInputComp
                onFilter={onFilter}
                column={column}
                handleFiltering={handleFiltering}
                isClearFilter={clearFilters}
                setClearedItemName={(value) => clearedItemName.current = value}
                value={costs ? costs : ""}
                enabledFilter={enabledFilter}
                setEnabledFilter={setEnabledFilter}
                setComparatorFilter={setComparatorFilter}
                comparatorFilter={comparatorFilter}
                defaultComparator={">"}
                table="Subscriptions"
              />
            ),
            title: true,
            text: "Costs",
            formatter: formatCurrency,
            sort: true,
            classes: 'price-data-right',
            headerStyle: () => {
              return { width: '18%', 'paddingRight': '12px' }
            },
            attrs: {
              'data-label': 'Costs'
            }
          },
          {
            dataField: "status",
            filter: customFilter({
              type: "select",
              style: { display: "inline-grid" },
              getFilter: (filter) => {
                setStatusFilter(filter);
              },
            }),
            filterRenderer: (onFilter, column) => (
              <CustomFilterSelect
                onFilter={onFilter}
                column={column}
                handleFiltering={(e) =>
                  handleFiltering(e, "status", true)
                }
                constantObj={statusOptsData}
                multiSelect={true}
                clearOnEmpty={handleFilterClick}
                isClearFilter={clearFilters}
                setClearedItemName={(value) => clearedItemName.current = value}
                value={status ? status : ""}
                enabledFilter={enabledFilter}
                setEnabledFilter={setEnabledFilter}
                table="Subscriptions"
              />
            ),
            text: "Status",
            formatter: StatusStyle,
            sort: true,
            title: true,
            /*title: function callback(cell, row, rowIndex, colIndex) {
              if (cell === 50) {
                return "Ongoing";
              } else if (cell === 99) {
                return "Terminated";
              } else if (cell === 10) {
                return "New";
              } else if (cell === 30) {
                return "Provisioning";
              } else if (cell === 40) {
                return "Planned";
              } else if (cell === 80) {
                return "De-provisioning";
              } else {
                return "Concept";
              }
            },*/
            align: "center",
            classes: 'inv-col-status-label',
            headerStyle: () => {
              return { width: '22%', 'textAlign': 'center' }
            },
            attrs: {
              'data-label': 'Status'
            }
          }/*, {
                dataField: "action",
                isDummyField: true,
                text: "",
                //formatter: optionMenuFormatter,
                classes: 'action-inv-col',
                headerStyle: () => {
                  return {width: '10%'}
                },
                attrs: {
                  'data-label': 'Actions'
                }
              }*/
        ]}
        headerDropdownOpts={[
          {
            label: "Filter",
            action: "collapseFilterAction",
            icon: <FontAwesomeIcon icon={faFilter} size={`sm`} className={`mr-0`} color={`grey`} />,
            isCustom: true,
            tooltip: true,
            toggleActive: toggleFilterForm ? "active" : ""
          },
          {
            label: "Clear",
            action: "handleFilterClick",
            icon: <FontAwesomeIcon icon={faTrash} size={`sm`} className={`mr-0`} color={`grey`} />,
            isCustom: true,
            tooltip: true
          },
          {
            label: 'Add',
            action: SubscriptionForm
          }
        ]}
        rowDropdownOpts={[{
          action: 'View',
          label: 'View details',
          form: JsonDataForm,
          condition: {
            data: 'json_datas'
          }
        }]}
        remote
        rowAction="link"
        striped={true}
        notLoadedByDefault={false}
        noDataIndication="No subscriptions found."
        bodyClasses="table-filter-body-collapse"
        noHover={false}
        handleFilterClick={handleFilterClick}
        collapseFilterAction={collapseFilterAction}
        filters={filters}
        isLoadData={isLoadData} // isReLoad
        setIsLoadData={setIsLoadData}
        enabledFilter={enabledFilter}
        noSearch={false}
        cacheSearch={true}
        filterSearch={true}
        collapseFilter={collapseState}
        isClearFilter={clearFilters}
        noTopBorder={true}
        classes="table-responsive table-filter-collapse filters mb-0 subscriptions-table filter-tbl-res"
        wrapperClasses="table-subscriptions f2x-custom-table table-filter-search-wrapper"
        setSearchKeyword={setSearchKeyword}
        page={page}
      />
    </div>
  )
};

export default withRouter(connect()(Subscriptions));
