import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
//import {withRouter, useHistory, Link} from 'react-router-dom';
import CONSTANTS from '../services/constants';
import Toast from '../components/Toast';
import Swal from "sweetalert2";
import { GetSearchResult } from '../controllers/search';
import useComponentVisible from '../hooks/useComponentVisible';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { GetPlanLineGroups, GetPlanOffer, GetPlans } from '../controllers/plans';
import {
  GetContractPeriodList, GetOperators, GetPlanSubscriptionLineTypes,
} from '../controllers/subscriptions';
import { faSquareRootAlt, faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  showSearchResults,
  hideSearchResults,
  toggleSearchResults,
} from '../redux/actions/solrSearchActions';
import { createSelector } from 'reselect';

const SolrSearch = ({ ...props }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { t } = useTranslation();

  const { dispatch } = props;

  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [noResultsFound, setNoResultsFound] = useState(null);
  const [searchDropDownOpen, setSearchDropDownOpen] = useState(false);
  const [searchResultsTotal, setSearchResultsTotal] = useState(0);
  const [searchResultsStatistics, setSearchResultsStatistics] = useState(null);

  const jsonEscape = (str) => {
    return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
  };

  const solrSearch = () => {
    (async () => {
      await Promise.all([
        GetSearchResult(inputValue),
      ]).then((r) => {
        const [results] = r;
        //console.log("solrSearch - results ->", results);

        if (results && results.ok) {
          const data = results.data && results.data.data ? results.data.data : null;
          if (data) {
            const stringifyJsonData = JSON.stringify(data);
            const parseJsonData = JSON.parse(jsonEscape(stringifyJsonData));

            if (parseJsonData && parseJsonData.data) {
              const resultData = unescape(parseJsonData.data);
              const parseResultData = JSON.parse(resultData);

              console.log("parseResultData ->", parseResultData);

              if (parseResultData && parseResultData.responseHeader && parseResultData.responseHeader.QTime) {
                setSearchResultsStatistics({ qTime: parseResultData.responseHeader.QTime })
              } else {
                setSearchResultsStatistics({ qTime: "" })
              }

              if (parseResultData && parseResultData.response && parseResultData.response.docs && parseResultData.response.docs.length > 0) {
                setSearchResults(parseResultData.response.docs);
                if (parseResultData.response.numFound) {
                  setSearchResultsTotal(parseResultData.response.numFound);
                }

                setIsComponentVisible(true);
                dispatch(toggleSearchResults());

              } else {
                setSearchResults(parseResultData.response.docs);
                if (parseResultData.response.numFound) {
                  setSearchResultsTotal(parseResultData.response.numFound);
                } else {
                  setSearchResultsTotal(0);
                }

                setIsComponentVisible(true);
                dispatch(toggleSearchResults());

              }
            }
          }
        }
      })
    })();
  };

  const handleSearch = (e) => {
    e.stopPropagation();

    //dispatch(toggleSearchResults());

    console.log("handleSearch - e ->", e);
    console.log("handleSearch - e.key ->", e.key);
    console.log("handleSearch - e.code ->", e.code);
    console.log("handleSearch - inputValue ->", inputValue);

    if (e.key === "Enter") {
      //setSearchResults(null);
      console.log("CustomFilterInput - handleInputFiltering - e ->", e);
      solrSearch();
    }
    return false;
  };

  const handleInputChange = (e) => {
    if (e.key === "Enter") {
      return false;
    }

    console.log("handleInputChange - e ->", e);
    setInputValue(e.target.value);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearchResults(null);
    setSearchResultsTotal(0); // Reset total results count
    setSearchResultsStatistics(null); // Reset statistics

    console.log("handleSearchClick - e ->", e);
    console.log("props.solrSearch - props.solrSearch ->", props.solrSearch);
    solrSearch();
  };

  const clearInputField = (e, column) => {
    e.preventDefault();
    setInputValue("");
    setSearchResults(null);
    setSearchResultsTotal(0); // Reset total results count
    setSearchResultsStatistics(null); // Reset statistics
  };

  const showMoreSearchResults = (e) => {

  };

  const checkResultItemType = (item, i) => {
    if (item && item.id) {
      // split string to check for type
      let itemTypeArr = item.id.split("_");
      if (itemTypeArr && itemTypeArr[0]) {
        switch (itemTypeArr[0]) {
          case "rel":
            return (
              <li className="search-dropdown-content-list-item relation" key={`item-${i}`}>
                <div className="search-dropdown-content-list-item-hover d-flex flex-row">
                  <span key={`type-${i}`} className="badge-pill mr-1 mb-0 text-uppercase badge badge-success">REL</span>
                  <div key={`type-item-${i}`}>
                    {item.number && <span className="search-dropdown-content-list-item-span"><b>Customer nr:</b> {item.number}</span>}
                    {item.person && <span className="search-dropdown-content-list-item-span"><b>Person:</b> {item.person}</span>}
                    {item.url &&
                      <a href={`#${item.url}`} className="btn btn-link">
                        <span className="text-truncate">...{item.url}</span>
                      </a>}
                  </div>
                </div>
              </li>
            );

          case "inv":
            return (
              <li className="search-dropdown-content-list-item invoice" key={`item-${i}`}>
                <div className="search-dropdown-content-list-item-hover d-flex flex-row">
                  <span key={`type-${i}`} className="badge-pill mr-1 mb-0 text-uppercase badge badge-warning">INV</span>
                  <div key={`type-item-${i}`}>
                    {item.number && <span className="search-dropdown-content-list-item-span"><b>Invoice nr:</b> {item.number}</span>}
                    {item.url &&
                      <a href={`#${item.url}`} className="btn btn-link">
                        <span className="text-truncate">...{item.url}</span>
                      </a>}
                  </div>
                </div>
              </li>
            );

          case "sub":
            return (
              <li className="search-dropdown-content-list-item subscription" key={`item-${i}`}>
                <div className="search-dropdown-content-list-item-hover d-flex flex-row">
                  <span key={`type-${i}`} className="badge-pill mr-1 mb-0 text-uppercase badge badge-info">SUB</span>
                  <div key={`type-item-${i}`}>
                    {item.person && <span className="search-dropdown-content-list-item-span"><b>Person:</b> {item.person}</span>}
                    {item.url &&
                      <a href={`#${item.url}`} className="btn btn-link">
                        <span className="text-truncate">...{item.url}</span>
                      </a>}
                  </div>
                </div>
              </li>
            );
        }
      }
    }
  };

  const closeSearchResults = (e) => {
    e.preventDefault();

    setSearchResults(null);
    dispatch(hideSearchResults());
  };

  useEffect(() => {
    console.log("solrSearch - UseEffect - isComponentVisible ->", isComponentVisible);

    if (!isComponentVisible) {
      dispatch(hideSearchResults());

      const el = document.getElementById("solr-search");
      if (el) {
        console.log("el - solr-search ->", el);
      }
    }

  }, [isComponentVisible]);

  return (
    <form className="form-inline my-2 my-lg-0 ml-auto mr-auto" style={{ minWidth: "125px" }} onSubmit={(e) => handleSearchClick(e)}>
      <InputGroup className={`mt-0`}>
        <Input type="search"
          className="f2x-fin-h-text mt-0"
          name={`solr-search`}
          id={`id-input-solr-search`}
          value={inputValue}
          placeholder={'Search...'}
          onChange={handleInputChange}
          autoComplete="off"
        />
        <InputGroupAddon className="multi-btns" addonType="append">
          <button type="submit" className="icon-search" style={{ backgroundColor: "transparent" }}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={faSearch} />
          </button>
          <a href="/#" className="danger icon-delete" onClick={(e) => clearInputField(e)} style={{ backgroundColor: "transparent" }}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
      <div className={`search-dropdown${props.solrSearch.searchDropDownState && props.solrSearch.searchDropDownState === true ? props.solrSearch.searchDropDownStateOpen : props.solrSearch.searchDropDownStateHidden}`} id={`solr-search`}>
        <div className="search-dropdown-content" ref={ref}>
          {isComponentVisible && searchResults && searchResults.length > 0 &&
            (<ul className="search-dropdown-content-list">
              {searchResultsTotal > 0 && searchResultsStatistics && searchResultsStatistics.qTime &&
                <li className="search-results-statistics" key="0-statistics">
                  Found {searchResultsTotal} results in {searchResultsStatistics.qTime}ms
                  <button className="btn btn-danger btn-sm" onClick={(e) => closeSearchResults(e)} style={{ opacity: "0.6" }}>
                    <FontAwesomeIcon className="search-dropdown-close-icon" icon="times" />
                  </button>
                </li>}
              {searchResults.map((item, i) => {
                return checkResultItemType(item, i)
              })}
            </ul>)}
          {isComponentVisible && searchResults && searchResults.length > 0 && searchResultsTotal && searchResultsTotal > 10 &&
            <div className="search-dropdown-more">
              <button className="btn btn-more btn-sm" onClick={(e) => showMoreSearchResults(e)}>
                Show more results
              </button>
            </div>}
          {isComponentVisible && searchResults && searchResults.length === 0 &&
            (<ul className="search-dropdown-content-list">
              <li className="search-dropdown-content-list-item no-search-results">No results found</li>
            </ul>)}
        </div>
      </div>
    </form>
  );
};

const solrSearchSelector = createSelector(
  state => state.solrSearch,
  solrSearch => solrSearch
);

const mapStateToProps = ({ solrSearch, dispatch }) => ({ solrSearch, dispatch });

export default connect(mapStateToProps)(SolrSearch);
