import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    GetAPIwithType = Api.getAPIwithType,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI;

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        return await method ? method(endpoint, params) : await GetAPI(endpoint, params);
    })();
};

const GetBillingRun = (params, id) => {
    return asyncFunc(`billing_runs/${id}`, params)
};

const GetBillingRuns = (params) => {
    return asyncFunc(`billing_runs`, params)
};

const GetAllBillingRuns = () => {
    return asyncFunc(`billing_runs?offset=1000`)
};

const GetBillingRunDates = (params, id) => {
    return asyncFunc('admin_tools/billing_run_dates', params)
};

const GetBillingRunCount = () => {
    return asyncFunc('billing_runs/count')
};

const GetBillingRunList = () => {
    return asyncFunc(`billing_runs/list`)
};

const AddBillingRun = (params) => {
    return asyncFunc(`billing_runs`, params, PostAPI)
};

const UpdateBillingRun = (id, params) => {
    return asyncFunc(`billing_runs/${id}`, params, UpdateAPI)
};

const GetDumpFiles = () => {
    return asyncFunc(`admin_tools/grid_data_dump`, 'blob', GetAPIwithType)
};

export {
    GetBillingRun,
    GetBillingRuns,
    GetBillingRunCount,
    GetBillingRunList,
    AddBillingRun,
    UpdateBillingRun,
    GetBillingRunDates,
    GetAllBillingRuns,
    GetDumpFiles
}
