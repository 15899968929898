import React from "react";
import {Provider} from "react-redux";
import store from "./redux/store/index";
import Routes from "./routes/Routes";

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Routes />
            </Provider>
        )
    }
}

export default App;
