import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI,
    RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    let data = await method ? method(endpoint, params) : GetAPI(endpoint, params);
    return data
  })();
};

/*
status_type_id:
1	invoice
2	payment
3	connection
4	subscription
5	product
6	job
7	billing_run
8	order
 */

const GetStatusListByTypeId = (statusType) => {
  return asyncFunc(`statuses/${statusType}/list`)
};

const GetStatusByTypeId = (id) => {
  return asyncFunc(`statuses?status_type_id=${id}`)
};

export {
  GetStatusByTypeId,
  GetStatusListByTypeId,
}
