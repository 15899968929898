import * as types from "../constants";

export default function reducer(
  state = {
    id: '',
    relationId: '',
    customer_name: '', 
    customer_number: '',
    email: '',
    phone: '',
    fax: '',
    website: '',
    addresses: [],
    relation_id: '',
    relation_type_id: '',
    relation_types: [],
    status: '',
    statuses: [],
    company: '',
    kvk: '',
    vat_no: '',
    payment_condition_id: '',
    paymentConditionOptions: [],
    inv_output_type: '',
    invoiceOutputOptions: [],
    persons: [],
    notes: [],
    subscriptionList: [],
    invoiceList: [],
    addressOpt: null, // added 16-3-2023 by thomb
    personOpt: null, // added 16-3-2023 by thomb
  }
  , actions) {
  const { payload } = actions;
  switch (actions.type) {
    case types.RELATION: 
      return {
        ...state,
        ...payload
      };
    case types.NOTES:
      return {
        ...state,
        notes: payload.notes
      };
    case types.SUBSCRIPTIONS:
      const {subscriptionList} = state;
      return {
        ...state,
        subscriptionList: [...subscriptionList, ...payload.subscriptionList]
      };
    case types.INVOICES:
      const {invoiceList} = state;
      return {
        ...state,
        invoiceList: [...invoiceList, ...payload.invoiceList]
      };
    default:
    return state;
  }
}