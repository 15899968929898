import React from "react";
import ReactQuill, {Quill} from 'react-quill';

const Inline = Quill.import("blots/inline");
const ATTRIBUTES = ["href", "rel", "target", "class"];

class InternalLink extends Inline {
    static create(value) {
        let node = super.create(value);
        node.setAttribute("href", value.href);
        if (value.rel) node.setAttribute("rel", value.rel);
        if (value.target) node.setAttribute("target", value.target);
        if (value.class) node.setAttribute("class", value.class);
        return node;
    }

    static formats(domNode) {
        return ATTRIBUTES.reduce((formats, attribute) => {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
}

InternalLink.blotName = "internal_link";
InternalLink.tagName = "A";

Quill.register({
    "formats/link": InternalLink
});
