import React, {useState, useEffect} from 'react';
import {loaderToggle} from '../../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Badge, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../../services/apiService';
import CONSTANTS from '../../../services/constants';
import CustomDataTable from '../../../components/CustomDataTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../../services/misc';
import {getTableObj} from '../../../services/tableService';

const Api = new ApiService(),
    GetAPI = Api.getAPI;

const ListEmailLogs = ({ id, dispatch, logs }) => {
  const {t} = useTranslation();

  // console.log("ListEmailLogs - logs ->", logs);
  // console.log("ListEmailLogs - id ->", id);

  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(25);

  //const [data, setData] = useState(logs ? logs : []);
  const [data, setData] = useState([]);
  const [date, setDate] = useState('');
  const [message, setMessage] = useState('');
  const [serverIp, setServerIp] = useState('');
  const [recipient, setRecipient] = useState('');
  const [messageId, setMessageId] = useState('');
  const [isLoadData, setLoadData] = useState(true);

  const handleFiltering = (e) => {
    if (e.key === "Enter") {
      let value = e.target && e.target.value ? e.target.value : '';
      let name = e.target && e.target.name ? e.target.name : null;

      switch (name) {
        case 'message':
          setMessage(value);
          break;
        case 'recipient':
          setRecipient(value);
          break;
        case 'date':
          setDate(value);
          break;
        case 'server_ip':
          setServerIp(value);
          break;
        case 'message_id':
            setMessageId(value);
            break;
        default:
          break;
      }      
    }    
  };

  const clearDateSelected = (e) => {
    e.preventDefault();
    setDate('');
  };

  const headerDateFormatter = (column) => {
    return (
        <div className={`activity-logs-subscriptions mw-200`} style={{display: 'flex', flexDirection: 'column'}}>
          <span className="thead-label">{column.text}</span>
          <InputGroup className={`table-search-input`}>
            <DatePicker
                className="form-control f2x-h-text max-width-200"
                name="date"
                dateFormat={t("date_format_raw")}
                autoComplete="off"
                placeholderText="Select date"
                selected={date ? new Date(date) : null}
                onChange={(date) => {
                  setDate(date ? new Date(date) : null)
                }}
                maxDate={new Date()}>
            </DatePicker>
            <InputGroupAddon className="clear-btn" addonType="append">
              <a href="/#" className="danger icon-delete" onClick={clearDateSelected.bind(this)}>
                <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
              </a>
            </InputGroupAddon>
          </InputGroup>
        </div> )
  };

  const formatDate = (cell) => {
    if (cell) {
      return <span className="date-format">{t("date_format", {date: new Date(cell)})}</span>
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  const formatDateTime = (cell) => {
    if (cell) {      
      let date;
      let time;
      let date_time = cell.split(" ");

      if (date_time && date_time.length > 1) {
        date = date_time[0];
        time = date_time[1];

        return <span className="date-format">{date ? `${t("date_format", {date: new Date(date)})}` : ""} {time ? ` ${time}` : ""}</span>
      } else {
        return <span className="null-value">{String.fromCharCode(8212)}</span>
      }
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  const clearInput = (e, id) => {
    e.preventDefault();

    let input = document.getElementById(id);
    input.value = '';

    let elemName = input.name;
    if (elemName === 'message') {
      setMessage('');
    }
    if (elemName === 'message_id') {
      setMessageId('');
    }
    if (elemName === 'recipient') {
      setRecipient('');
    }
    if (elemName === 'server_ip') {
      setServerIp('');
    }
  };

  const headerInputFormatter = (column) => {
    return <div style={{ display: 'flex', flexDirection: 'column' }} className={`activity-logs-subscriptions mw-200`}>
      <span className="thead-label">{column.text}</span>
      <InputGroup className={`table-search-input`}>
        <Input type="text" className="f2x-h-text" name={column.dataField} onKeyPress={(e) => handleFiltering(e)} id={`id-input-email-logs-${column.dataField}`} placeholder={`Search...`} />
        <InputGroupAddon className="clear-btn" addonType="append">
          <a href="/#" className="danger icon-delete" onClick={(e) => clearInput(e, `id-input-email-logs-${column.dataField}`)}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  };

  /*
    email_id: 137522
    id: 576333
    last_error: ""
    last_error_code: 250
    message_id: "250 mail saved\r\n"
    recipient: "bijsteren48@yopmail.com"
    server_ip: "87.98.164.155"
    timestamp: "2023-12-06 09:32:08"
   */
  const columns = [{
    dataField: "timestamp",
    text: 'Date',
    formatter: formatDateTime,
    headerFormatter: headerDateFormatter,
    headerStyle: () => {
      return { width: '10%', minWidth: '140px'}
    },
    attrs: {
      'data-label': 'Date'
    }
  }, {
    dataField: "recipient",
    text: 'Recipient',
    headerFormatter: headerInputFormatter,
    headerStyle: () => {
      return { width: '20%'}
    },
    attrs: {
      'data-label': 'Recipient'
    }
  }, {
    dataField: "message_id",
    text: 'Message ID',
    headerFormatter: headerInputFormatter,
    headerStyle: () => {
      return { width: '50%'}
    },
    attrs: {
      'data-label': 'Message ID'
    }
  }, {
    dataField: "server_ip",
    text: 'Server IP',
    headerFormatter: headerInputFormatter,
    headerStyle: () => {
      return {width: '20%'}
    },
    attrs: {
      'data-label': 'Server IP'
    }
  }];

  const handleTableChange = (type, {page, sizePerPage, sortField, sortOrder, searchText}) => {
    // console.log('handleTableChange');

    const pageNum = page;
    const sortFieldParam = sortField === 'date' ? 'date' : sortField;
    const isSortDesc = sortField && stringFilter(sortOrder, 'desc');
    const sortParam = isSortDesc ? `-${sortFieldParam}` : sortFieldParam;
    const propsObj = getTableObj(type, {searchText, isLoadData, pageNum, page});

    setSizePerPage(sizePerPage ? sizePerPage : 10);
    setPage(propsObj && propsObj.pageNum ? propsObj.pageNum : 1);
    setLoadData(propsObj && propsObj.isLoadData ? propsObj.isLoadData : true);

    let params = [];
    params['sort'] = sortParam;
    params['page'] = propsObj && propsObj.pageNum ? propsObj.pageNum : 1;
    params['filter'] = null;
    params['offset'] = sizePerPage ? sizePerPage : 10;

    setQueryParameters(params);
  };

  const setQueryParameters = (params) => {
    let query = [],
        key,
        value;

    for (key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      if (key === 'page' || key === 'offset') {
        value = parseInt(params[key]);
      } else {
        value = params[key];
      }
      query.push(`${key}=${value}`);
    }

    const tableId = 'qStrEmailLogs';
    localStorage.setItem(tableId, query.join('&'));
  };

  const getQueryParameters = () => {
    const tableId = 'qStrEmailLogs';

    let queryString;
    let param = {};

    if (localStorage.getItem(tableId)) {
      queryString = localStorage.getItem(tableId) && localStorage.getItem(tableId).charAt(0) === '?'
          ? localStorage.getItem(tableId).slice(1)
          : localStorage.getItem(tableId);

      if (queryString) {
        queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
          if (key === 'page' || key === 'offset') {
            param[key] = parseInt(value);
          } else {
            param[key] = value;
          }
        });
      }
    } else {
      param['page'] = page ? page : 1;
      param['offset'] = sizePerPage ? sizePerPage : 10;
    }

    return param;
  };

  useEffect(() => {    
    let param_history = getQueryParameters();

    const param = {
      page: param_history && param_history['page'] ? param_history['page'] : page,
      offset: param_history && param_history['sizePerPage'] ? param_history['sizePerPage'] : sizePerPage,      
      filter: (messageId ? 'message_id%' + messageId : '') + 
          (serverIp ? (messageId ? ',' : '') + 'server_ip%' + serverIp : '') + 
          (recipient ? (messageId || serverIp ? ',' : '') + 'recipient%' + recipient : '') + 
          (date ? (messageId || serverIp || recipient ? ',' : '') + 'timestamp%' + t("search_date_format", {date: new Date(date)}) : ''),
      // date: date ? t("search_date_format", {date: new Date(date)}) : ''
      // message,
      // recipient
    };

    setQueryParameters(param);

    dispatch(loaderToggle(true));
    GetAPI(`emails/${id}/email_logs`, param)
    .then(r => {
      const { data, total } = r.data || {};

      // console.log("email logs - data ->", data);
      // if (data && data.length > 0) {
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].time) {
      //       let timeData = data[i].time.split(".");
      //       console.log("log_activities - timeData ->", timeData);
      //       if (timeData && timeData[0]) {
      //         data[i].time = timeData[0];
      //       }
      //     }
      //   }
      // }

      setData(data || []);
      setTotalSize(total || totalSize);
      dispatch(loaderToggle(false));
    })
    .catch(error => {
      dispatch(loaderToggle(false));
      console.log('Error fetching data: ', error)
    })
  }, [date, page, sizePerPage, message, recipient, serverIp, messageId /*id, page, sizePerPage, date, message, totalSize/*, dispatch*/]);

  return (
      <CustomDataTable
          id="EmailLogs"
          table="email-logs"
          classes="custom-tbl-logs"
          data={data && data.length && data.length > 0 ? data : []}
          remote
          keyField="id"
          columns={columns}
          onTableChange={handleTableChange}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          //expandRow={ detailsExpand }
      />
  )
};

export default connect()(withTranslation()(withRouter(ListEmailLogs)));
