import React from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {addDays} from "date-fns";
import {GetInvoice, GenerateInvoice, SendInvoicEmail, GenerateCreditInvoice, ReCreateInvoice, UpdateInvoice, GetInvoiceState, SendReminder} from '../../../controllers/invoices';
import ButtonSpinner from '../../../components/ButtonSpinner';
import {
    GetOptionAddress,
    GetOptionPersons,
    GetRelationList,
} from '../../../controllers/relations';
import {Badge, Col, Row} from 'reactstrap';
import Details from '../../../components/Details';
import InvoiceLines from './invoice_lines/List';
import PhoneUsage from './phone_usage/PhoneUsage';
import {loaderToggle} from "../../../redux/actions/loaderActions";
import Toast from "../../../components/Toast";
import {validateEmail} from "../../../redux/actions/validateActions";
import Unauthorized from "../../auth/Unauthorized";
import Swal from "sweetalert2";
import CONSTANTS from '../../../services/constants';
import ApiService from '../../../services/apiService';
import Loader from '../../../components/Loader';
import {mvLoaderToggleInv} from "../../../redux/actions/mvLoaderInvoiceActions";
import {
    GetContractPeriodList,
    GetPlanSubscriptionLineTypes, GetSubscriptionStatuses,
} from '../../../controllers/subscriptions';

const Api = new ApiService(),
    GetAPI = Api.getAPI;

const isAfterFinalizingChecker = (data) => {
        return !(data && data.invoice_status && data.invoice_status > 5);
    },
    isAfterCreatingPdfChecker = (data) => {
        return !(data && data.invoice_status && data.invoice_status > 6);
    },
    statusInvoiceIdChecker = (data) => {
        return !!(data && data.status_invoice && data.status_invoice.id && data.status_invoice.id === 0);
    },
    isReminderEnabled = (data) => {
        return !!(data && data.invoice_status > 6 && data.reminder && data.reminder['reminder_status'] && data.reminder['reminder_status'] === 'sent_to_collection_agency');
    },
    isPaidOptEnabled = (data) => {
        if (data && data.invoice_status === 20) {
            return false;
        }

        return !(data && data.reminder);
    },
    isOpenOptEnabled = (data) => {
        return !(data && data.invoice_status === 50)
    },
    isPhoneUsage = (data) => {
        return !(data && data.invoice_no)
    },
    phoneUsageChecker = (data) => {
        // if true no phoneUsage will be shown
        return !(data && data.invoice_no);
    },
    getReminderLabelStatus = (data) => {
        let reminderLabel = 'Send reminder';
        if (data && data.reminder && data.reminder.reminder_status) {
            switch (data.reminder.reminder_status) {
                case 'first_reminder_sent':
                    reminderLabel = 'Send 2nd reminder';
                    break;
                case 'second_reminder_sent':
                    reminderLabel = 'Send warning';
                    break;
                case 'warning_sent':
                    reminderLabel = 'Send final notice';
                    break;
                case 'final_notice_sent':
                    reminderLabel = 'Sent to collection agency';
                    break;
                default:
                    break;
            }
        }
        return reminderLabel;
    };

class InvoiceDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasLoaded: false,
            isInvoiceFinalized: false,
            isLoading: false,
            debugInfo: false
        };
    };

    isInConceptChecker = () => {
        const { size, data, details } = this.state;

        return !(size && parseInt(size) > 0 && details && details.invoice_status === 0) // return false for finalize button to be enabled
    };

    isInvoiceConcept = () => {
        const { data } = this.state;

        let status_invoice = data && data.status_invoice ? data.status_invoice : null;

        // return true if invoice is in concept
        if (status_invoice && status_invoice.status && status_invoice.status.toLowerCase() !== 'concept' && data.invoice_no !== null) {
            return false; // no concept
        } else {
            return true; // invoice is in concept status
        }
    };

    canInvoiceBeFinalized = () => {
        const { data } = this.state;

        // return true if invoice is in concept
        if (data && data.invoice_no === null && data.price) {
            return false;
        } else {
            return true;
        }
    };

    isReminderDisabled = () => {
        const {data, details} = this.state;

        if (details && details.invoice_no === null) {
            return true;
        } else if (details && details.reminder && details.reminder['reminder_status'] && details.reminder['reminder_status'] === 'sent_to_collection_agency') {
            return true;
        } else if (details && details.invoice_status === 50) {
            return true;
        }
        return !!(details && details.invoice_status > 6 && details.reminder && details.reminder['reminder_status'] === 'sent_to_collection_agency');
    };

    isPaidOptDisabled = () => {
        const { data } = this.state;

        return data && !data.reminder || data && !data.reminder['reminder_status']
    };

    isInvoicePaid = () => {
        const { data } = this.state;

        return !!(data.invoice_status && data.invoice_status === 50)
    };

    reminderLabelStatus = (data) => {
        let reminderLabel = 'Send reminder';
        let isConcept = data && data.invoice_no ? false : true;

        if (data && data.reminder && data.reminder.reminder_status) {
            switch (data.reminder.reminder_status) {
                case 'first_reminder_sent':
                    reminderLabel = 'Send 2nd reminder';
                    break;
                case 'second_reminder_sent':
                    reminderLabel = 'Send warning';
                    break;
                case 'warning_sent':
                    reminderLabel = 'Send final notice';
                    break;
                case 'final_notice_sent':
                    reminderLabel = 'Sent to collection agency';
                    break;
                default:
                    //reminderLabel = 'Send reminder';
                    break;
            }
            return reminderLabel;

        } else if (!isConcept) {
            return reminderLabel;
        } else if (isConcept) {
            return null;
        }
        return reminderLabel;
    };

    triggerAction = (action, e) => {
        if (action) {
            this[action](e);
        }
        return false;
    };

    downloadInvoicePDF = (e) => {
        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        (async () => {
            const { id, invoice_filename, invoice_file_exists } = this.state.details;

            if (invoice_file_exists) {
                const { ok, data } = await GenerateInvoice(id);
                if (ok) {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([data]));
                    link.setAttribute('download', invoice_filename);
                    document.body.appendChild(link);
                    link.click();

                    dispatch(loaderToggle(false));
                } else {
                    dispatch(loaderToggle(false));
                }

            } else {
                let details = Object.assign({}, this.state.details);
                const { ok, data } = await GetInvoiceState(id, 'invoice_file_exists');

                if (ok && data && data.data) {
                    details['invoice_file_exists'] = data.data;
                    this.setState({ details });

                    this.downloadInvoicePDF(e);
                } else {
                    await Toast.fire({title: `Invoice PDF not found!`, icon: 'info'});
                }
                dispatch(loaderToggle(false));
            }
        })();
    };

    reCreateInvoicePdf = (e) => {
        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        (async () => {
            const { id } = this.state.details;

            const { ok, data } = await ReCreateInvoice(id);
            if (ok) {
                dispatch(loaderToggle(false));
                let details = Object.assign({}, this.state.details);
                if (data.data['pdf_exists']) {
                    details.invoice_file_exists = true;
                    await Toast.fire({
                        title: 'The invoice PDF has been regenerated.',
                        icon: 'info'
                    })
                } else {
                    details.invoice_file_exists = false;
                    await Toast.fire({
                        title: 'Error regenerating the invoice PDF.',
                        icon: 'error'
                    })
                }
                this.reload();
                this.setState({ details })
            }
            dispatch(loaderToggle(false));

        })();
    };

    sendInvoiceEmail = (e) => {
        const { data, details } = this.state;
        const { dispatch } = this.props;
        const { relation_primary_person_email, id, invoice_person } = details;

        console.log("sendInvoiceEmail - details ->", details);
        console.log("sendInvoiceEmail - invoice_person ->", invoice_person);

        let email_address;
        let email_is_valid = false;
        if (invoice_person && invoice_person.email) {
            const { valid } = dispatch(validateEmail(invoice_person.email));
            email_address = invoice_person.email;
            email_is_valid = valid;
        } else {
            const { valid } = dispatch(validateEmail(relation_primary_person_email));
            email_address = relation_primary_person_email;
            email_is_valid = valid;
        }

        if (email_address && email_is_valid) {
            dispatch(loaderToggle(true));

            Swal.fire({
                customClass: {container: 'has-cancel'},
                title: 'Please confirm',
                text: `Send invoice to email-address: ${email_address}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Confirm'
            }).then(res => {
                if (res.value) {
                    (async () => {
                        const {id, invoice_file_exists} = this.state.details;
                        let details = Object.assign({}, this.state.details);

                        if (invoice_file_exists) {
                            const {ok} = await SendInvoicEmail(id),
                                title = ok ? 'Email with invoice PDF sent!' : 'Invoice email not sent!',
                                icon = ok ? 'success' : 'error';

                            dispatch(loaderToggle(false));

                            await Toast.fire({title, icon});

                        } else {
                            dispatch(loaderToggle(false));

                            await Toast.fire({
                                title: "The email can't be sent, because the invoice file doesn't exist.",
                                icon: 'error'
                            });
                        }
                    })();
                } else {
                    dispatch(loaderToggle(false));
                }
            });

        } else {
            Toast.fire({
                title: "The email can't be sent, because the relation's primary person has an invalid email address or the relation has no primary person.",
                icon: 'error'
            });
        }
    };

    generateCreditInvoice = (e) => {
        const { isLoading } = this.state;
        const { dispatch, history, updateDetails, relation, invoice } = this.props;
        dispatch(loaderToggle(true));

        Swal.fire({
            customClass: { container: 'has-cancel' },
            title: 'Please confirm',
            text: 'Create credit invoice?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then(res => {
            if (res.value) {
                (async () => {
                    this.setState({ isLoading: true });

                    const {id} = this.state.details;
                    const {ok, data} = await GenerateCreditInvoice(id);

                    if (ok) {
                        dispatch(loaderToggle(false));

                        if (data && data.data) {
                            let newInvId = data.data.id;
                            let newDataInvoiceList;

                            if (invoice && invoice.invoiceList &&
                                invoice.invoiceList.length > 0) {
                                newDataInvoiceList = [
                                    ...invoice.invoiceList,
                                    data.data
                                ];
                            }
                            dispatch({type: 'INVOICE', payload: data.data});

                            sessionStorage.setItem('lastVisitInvId', newInvId);

                            if (newDataInvoiceList && newDataInvoiceList.length > 0) {
                                dispatch({type: 'INVOICE', payload: {invoiceList: newDataInvoiceList}});
                            }

                            updateDetails(newInvId, 'add', newDataInvoiceList);
                        }

                        this.setState({ isLoading: false });

                        await Toast.fire({
                            title: "Credit invoice created successfully",
                            icon: 'success'
                        }).then(r => {
                            console.log(r)
                        });
                    }
                })();
            }
            dispatch(loaderToggle(false));

        });
    };

    proccessReminder = () => {
        const { dispatch, updateDetails } = this.props;
        const details = this.state.details;

        (async () => {
            const { ok, data } = await SendReminder(details.id);

            if (ok) {
                let message = '';
                switch (data.data['reminder_status']) {
                    case 'first_reminder_sent':
                        message = 'Reminder was sent successfully.';
                        break;
                    case 'second_reminder_sent':
                        message = 'Second reminder was sent successfully.';
                        break;
                    case 'warning_sent':
                        message = 'Warning was sent successfully.';
                        break;
                    case 'final_notice_sent':
                        message = 'Final notice was sent successfully.';
                        break;
                    case 'sent_to_collection_agency':
                        message = 'Sent to collection agency successfully';
                        break;
                    default:
                        break;
                }

                let newData = Object.assign({}, this.state.data);
                newData.reminder = data.data ? data.data : null;

                this.setState({
                    data: newData,
                    reminder: data.data ? data.data : null
                });

                this.props.updateDetails(newData.id, 'update',newData);

                await this.setState({ reminder: data.data });
                await this.setDropdownItems(newData);
                await Toast.fire({title: message, icon: 'success'});
            }
            dispatch(loaderToggle(false));

        })();
    };

    setInvoiceOpen = () => {
        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        Swal.fire({
            customClass: { container: 'has-cancel' },
            title: 'Please confirm',
            text: 'Are you sure this invoice was not paid?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then(res => {
            if (res.value) {
                const details = this.state.details;
                GetAPI(`reminders/sales_invoices/${details.id}/open`)
                .then(r => {
                    dispatch(loaderToggle(false));

                    if (r.data) {
                        this.setState({ data: Object.assign({}, this.state.data, {reminder: null}) });
                        this.setDropdownItems(this.state.data).then(r => console.log("r ->", r));

                        let newData = Object.assign({}, this.state.data);
                        newData.status_invoice = { id: 20, status: "Open" };

                        this.setState({ data: newData });

                        this.props.updateDetails(newData.id, 'update', newData);

                        this.invoiceListUpdateDetails(this.state.data);

                        Toast.fire({
                            title: 'Invoice has been set back to open.',
                            icon: 'success'
                        }).then(r => { console.log(r) })
                    }
                })
                .catch(error => {
                    dispatch(loaderToggle(false));
                })
            }
            dispatch(loaderToggle(false));

        });
    };

    setInvoicePaid = () => {
        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        Swal.fire({
            customClass: { container: 'has-cancel' },
            title: 'Please confirm',
            text: 'Are you sure this invoice was paid?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then(res => {
            if (res.value) {
                const details = this.state.details;
                GetAPI(`reminders/sales_invoices/${details.id}/paid`)
                .then(r => {
                    dispatch(loaderToggle(false));

                    if (r.data) {
                        this.setState({ data: Object.assign({}, this.state.data, {reminder: null}) });
                        this.setDropdownItems(this.state.data).then(r => console.log("r ->", r));

                        let newData = Object.assign({}, this.state.data);
                        newData.status_invoice = { id: 50, status: "Paid" };

                        this.setState({ data: newData });

                        this.props.updateDetails(newData.id, 'update', newData);

                        this.invoiceListUpdateDetails(this.state.data);

                        Toast.fire({
                            title: 'Invoice has been set to paid.',
                            icon: 'success'
                        }).then(r => { console.log(r) })
                    }
                })
                .catch(error => {
                    dispatch(loaderToggle(false));
                    console.log('Error fetching data: ', error)
                })
            }
            dispatch(loaderToggle(false));

        });
    };

    sendReminderTrigger = () => {
        const { data, details } = this.state;
        const { dispatch } = this.props;
        const { relation_primary_person_email, reminder } = details;
        const { valid } = dispatch(validateEmail(relation_primary_person_email));

        if (relation_primary_person_email && valid) {
            if (details && details.reminder && details.reminder['reminder_status'] === 'final_notice_sent') {
                dispatch(loaderToggle(true));

                Swal.fire({
                    customClass: { container: 'has-cancel', },
                    title: 'Please confirm',
                    text: "Has this invoice been sent to a collection agency?",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm'
                }).then(res => {
                    if (res.value) {
                        dispatch(loaderToggle(true));
                        this.proccessReminder();
                    } else {
                        dispatch(loaderToggle(false));
                    }
                });
                dispatch(loaderToggle(false));

            } else {
                dispatch(loaderToggle(true));

                let reminderTitle = 'Send reminder';
                let reminderStatus = reminder && reminder.reminder_status ? reminder.reminder_status : null;
                let reminderText = `To email-address: ${relation_primary_person_email}`;

                switch (reminderStatus) {
                    case 'first_reminder_sent':
                        reminderTitle = 'Send 2nd reminder';
                        break;
                    case 'second_reminder_sent':
                        reminderTitle = 'Send warning';
                        break;
                    case 'warning_sent':
                        reminderTitle = 'Send final notice';
                        break;
                    case 'final_notice_sent':
                        reminderTitle = 'Sent to collection agency';
                        break;
                    default:
                        reminderTitle = 'Send reminder';
                        break;
                }
                console.log("sendReminderTrigger - reminder ->", reminderTitle);

                Swal.fire({
                    customClass: { container: 'has-cancel', },
                    title: reminderTitle,
                    text: reminderText,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm'
                }).then(res => {
                    if (res.value) {
                        dispatch(loaderToggle(true));
                        this.proccessReminder();
                    } else {
                        dispatch(loaderToggle(false));
                    }
                });
                dispatch(loaderToggle(false));

            }
        } else {
            if (relation_primary_person_email) {
                Toast.fire({
                    title: "The email can’t be sent, because the relation's primary person has an invalid email address.",
                    icon: 'error'
                }).then(r => { console.log(r) })
            } else {
                Toast.fire({
                    title: 'The person selected for billing for this subscription has no email address.',
                    icon: 'error'
                }).then(r => { console.log(r) })
            }
        }
    };

    setDropdownItems = async (data) => {
        if (!data) {
            data = this.state.details;
        }

        let reminderLabel = this.reminderLabelStatus(data);

        let dropdownItems = [
            {
                label: 'Finalize',
                function: 'setFinalizeInvoiceStatus',
                disabled: this.canInvoiceBeFinalized
            }, {
                label: 'View PDF',
                function: 'downloadInvoicePDF',
                disabled: isAfterFinalizingChecker
            }, {
                label: 'Email PDF',
                function: 'sendInvoiceEmail',
                disabled: isAfterFinalizingChecker
            }, {
                label: 'Create credit invoice',
                function: 'generateCreditInvoice',
                disabled: isAfterCreatingPdfChecker
            }, {
                label: await this.reminderLabelStatus(data),
                function: 'sendReminderTrigger',
                disabled: this.isReminderDisabled
            }, {
                label: 'Set to paid',
                function: 'setInvoicePaid',
                disabled: isPaidOptEnabled
            }, {
                label: 'Set to open',
                function: 'setInvoiceOpen',
                disabled: isOpenOptEnabled
            }, {
                label: 'View phone usage',
                function: 'togglePhoneUsage',
                disabled: phoneUsageChecker
            }
        ];

        await this.setState({
            dropdownItems
        });
    };

    viewJournal = () => {
        const { tenant_id, journal } = this.state.details;
        if (tenant_id && journal && journal.id) {
            window.location.href = `/tenants/${tenant_id}/journals/${journal.id}/details`
        } else {
            Toast.fire({title: 'Seems you have no journal yet!', icon: 'error'}).then(r => { console.log(r) })
        }
    };

    togglePhoneUsage = () => {
        this.setState({ phoneUsage: !this.state.phoneUsage });
    };

    setFinalizeInvoiceStatus = () => {
        const { isLoading } = this.state;
        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        // ask confirmation
        Swal.fire({
            customClass: { container: 'has-cancel' },
            title: 'Please confirm',
            text: 'Finalize concept invoice! Are you sure?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(r => {
            dispatch(loaderToggle(true));

            if (r.value && !isLoading) {
                (async () => {
                    this.setState({ isLoading: true });

                    await this.updateInvoiceStatus(1);

                    if (this.state.isInvoiceFinalized === true) {
                        sessionStorage.setItem('lastVisitInvId', null);

                        this.setState({ isInvoiceFinalized: false });
                        this.setState({ isLoading: false });

                        await Toast.fire({
                            title: 'Invoice finalized successfully',
                            icon: 'success'
                        }).then(r => {
                            console.log(r)
                        });
                    } else {
                        dispatch(loaderToggle(false));
                    }
                })();
            } else {
                this.setState({ isLoading: false });
                dispatch(loaderToggle(false));
            }
        });
    };

    syncInvoice = () => {
        (async () => {
            let details = Object.assign({}, this.state.details);
            const { ok, data } = await GetInvoiceState(this.state.details.id, 'all');
            if (ok && data) {
                const dropdownItems = Object.assign([], this.state.dropdownItems),
                    { invoice_file_exists, invoice_status, status_invoice } = data.data;

                details.invoice_file_exists = invoice_file_exists;
                details.invoice_status = invoice_status;
                details.status_invoice = status_invoice;

                this.setState({ details, dropdownItems });
                this.setState({ isLoading: false });

                this.props.updateDetails(data.data.id, 'update', data.data);
            }
        })();
    };

    updateInvoiceStatus = (status) => {
        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        (async () => {
            const { id, relation_id } = this.state.details,
                params = {
                    invoice_status: status,
                    relation_id
                };

            const { ok } = await UpdateInvoice(id, params);
            if (ok) {
                await this.syncInvoice();
                let details = Object.assign({}, this.state.details);

                //details = Object.assign({}, this.state.details);
                details.is_updatable = false;
                details.invoice_file_exists = true;

                this.setState({ details });

                this.props.updateDetails(id, 'update', details);

                this.setState({ isInvoiceFinalized: true });
            }
        })();

        dispatch(loaderToggle(false));

    };

    // reload/refresh invoice & relation data - empty
    reload = (str) => {
        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        const dropdownItems = Object.assign([], this.state.dropdownItems);
        this.setState({
            dropdownItems,
            groups: null
        });

        if (!str) {
            (async () => {
                await this.setState({ size: undefined });
                this.setState({ size: null })
            })();
        }
        dispatch(loaderToggle(false));

    };

    // update invoice due date
    updateDueDate = (id) => {
        setTimeout(() => {
            let details = Object.assign([], this.state.details);
            const paymentConditions = details.tenant.payment_conditions;

            const result = paymentConditions.find(data => parseInt(data.id) === parseInt(id));

            details.due_date = addDays(new Date(details.date), result.net_days);

            this.setState({ details });
        }, 1)
    };

    // to do: check if this gets updates new data
    getUpdates = (id, data, defaultData) => {
        let groups = Object.assign({}, this.state.groups),
            details = Object.assign([], this.state.details),
            newData = Object.assign({}, data),
            persons = [],
            provisioning_addresses = [],
            shipping_addresses = [],
            billing_addresses = [];

        (async () => {
            const { relation, invoice } = this.props;
            const { relationList } = relation;

            if (relationList && relationList.length < 1) {
                const [relations] = await Promise.all([
                    GetRelationList()
                ]);

                if (relations.ok) {
                    const {data} = relations.data;
                    Object.assign(relationList, data)
                }
            }

            await (() => {
                const { personOpt, addressOpt } = invoice;
                if (!!personOpt) {
                    personOpt.map(({ full_name, id }) => {
                        return persons.push({
                            label: full_name,
                            value: id
                        })
                    });

                    groups.billing.details[1].opts = persons;
                    groups.shipping.details[1].opts = persons;

                    newData.invoice_person_id = persons.length === 1 ? persons[0].value : null;
                    newData.billing_person_id = persons.length === 1 ? persons[0].value : null;
                    newData.shipping_person_id = persons.length === 1 ? persons[0].value : null;
                    newData.provisioning_person_id = persons.length === 1 ? persons[0].value : null;
                }

                if (!!addressOpt) {
                    addressOpt.map(({ address_type, full_address, id }) => {
                        if (address_type.type === 'Provisioning' || address_type.type === 'Shipping') {
                            if (!shipping_addresses.find(address => parseInt(address.value) === parseInt(id))) {
                                return shipping_addresses.push({
                                    label: full_address, value: id,
                                })
                            } else if (!provisioning_addresses.find(address => parseInt(address.value) === parseInt(id))) {
                                return provisioning_addresses.push({
                                    label: full_address, value: id,
                                })
                            } else {
                                return false
                            }

                        } else if (address_type.type === 'Billing') {
                            if (!billing_addresses.find(address => parseInt(address.value) === parseInt(id))) {
                                return billing_addresses.push({
                                    label: full_address,
                                    value: id,
                                })
                            } else {
                                return false
                            }

                        } else {
                            return false
                        }
                    });

                    groups.billing.details[0].opts = billing_addresses;
                    groups.shipping.details[0].opts = shipping_addresses;

                    newData.invoice_address_id = billing_addresses.length === 1 ? billing_addresses[0].value : null;
                    newData.billing_address_id = billing_addresses.length === 1 ? billing_addresses[0].value : null;
                    newData.shipping_address_id = shipping_addresses.length === 1 ? shipping_addresses[0].value : null;
                    newData.provisioning_address_id = shipping_addresses.length === 1 ? shipping_addresses[0].value : null
                }

                if (!!relationList) {
                    const getData = relationList;

                    details.relation_id = getData.id;
                    details.payment_condition_id = getData.payment_condition_id;

                    newData.company_name = getData.company_name ? getData.company_name : null;
                    newData.vat_no = getData.vat_no ? getData.vat_no : null;
                    newData.payment_condition_id = getData.payment_condition_id ? getData.payment_condition_id : null
                }
            })();

            if (defaultData) {
                groups.invoice.details[4].defaultData = defaultData
            }

            this.setState({
                groups,
                details,
                persons,
                provisioning_addresses,
                shipping_addresses,
                newData
            })
        })();
    };

    reminderNote = (invoiceData) => {
        let note;

        if (invoiceData.reminder && invoiceData.reminder['reminder_status']) {
            let status = invoiceData.reminder['reminder_status'];
            status = status.charAt(0).toUpperCase() + status.slice(1);
            status = status.replace(/_/g, ' ');
            note = `${status} ${invoiceData.reminder["date"]}`
        }
        return note;
    };

    updateStatusStyle = (data) => {
        if (data && data.status_invoice) {
            const status_invoice = data.status_invoice.status ? data.status_invoice.status : false;
            if (status_invoice) {
                return <Badge
                    color={status_invoice.toLowerCase() === 'concept'
                        ? 'info'
                        : 'warning'}
                    className="badge-pill mr-1 mb-0 text-uppercase">
                    {status_invoice.toUpperCase()}
                </Badge>;
            } else {
                return data.status_invoice;
            }
        }
    };

    formatCurrency = (value) => {
        const {t} = this.props;
        let floatCell = value ? parseFloat(value) : 0;
        let floatStyle = floatCell && parseFloat(floatCell) < 0 ? 'float-negative' : floatCell && parseFloat(floatCell) > 0 ? 'float-positive' : 'float-null';

        return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(parseFloat(value)) })}</span>
    };

    dataLoaded = (data, renderCount) => {
        const {id, dispatch, invoice, relation} = this.props;
        const {personOpt, addressOpt} = relation;
        const salesInvoiceData = data;

        if (data) {
            this.setState({
                //data,
                //reminder: data.reminder, // ? data.reminder : null,
                data: salesInvoiceData,
                reminder: salesInvoiceData.reminder, // ? data.reminder : null,
            });

            if (!renderCount) {
                this.setDropdownItems(data).then(r => {
                    if (r) {
                        console.log("setDropdownItems on func dataLoaded - with !renderCount - r ->", r);
                    }
                });
            }

            let customers = [], persons = [], addresses = [], provisioning_addresses = [], billing_addresses = [], shipping_addresses = [], payment_conditions = [];

            if (salesInvoiceData && salesInvoiceData.tenant && salesInvoiceData.tenant.payment_conditions) {
                salesInvoiceData.tenant.payment_conditions.map(
                    ({id, description, net_days}) => {
                        return payment_conditions.push({
                            label: description, value: id, net_days: net_days
                        })
                    })
            }

            this.setState({payment_conditions});

            (async () => {
                const {dispatch, invoice, relation} = this.props;
                const relationList = relation && relation.relationList
                    ? relation.relationList
                    : [];

                let [person, address] = await Promise.all([
                    GetOptionPersons(null, relation.id),
                    GetOptionAddress(null, relation.id),
                ]);

                await (() => {
                    if (relationList && relationList.length > 0) {
                        relationList.map(
                            ({name, id, customer_number, primary_person_full_name, billing_address}) => {
                                return customers.push({
                                    label: name,
                                    value: id,
                                    customer_number,
                                    primary_person_full_name,
                                    billing_address
                                })
                            });

                        this.setState({customers});
                    }

                    if (person && person.ok) {
                        person.data.data.map(({ full_name, id }) => {
                            return persons.push({
                                label: full_name,
                                value: id,
                            })
                        });

                        this.setState({personData: person.data.data});
                    }

                    if (address && address.ok) {
                        address.data.data.map(({address_type, full_address, id, location_id}) => {
                            return addresses.push({
                                label: full_address, 
                                value: id,
                                location_id: location_id
                            });
                        });

                        address.data.data.map(({address_type, full_address, id, location_id}) => {
                            if (address_type === 'Provisioning') {
                                return provisioning_addresses.push({
                                    label: full_address, 
                                    value: id,
                                    location_id: location_id
                                })
                            } else if (address_type === 'Shipping') {
                                return shipping_addresses.push({
                                    label: full_address, 
                                    value: id,
                                    location_id: location_id
                                })
                            } else if (address_type === 'Billing') {
                                return billing_addresses.push({
                                    label: full_address, 
                                    value: id,
                                    location_id: location_id
                                })
                            } else {
                                return false
                            }
                        });

                        this.setState({addressData: address.data.data});
                    }

                    this.setState({
                        ...this.state,
                        ...{ hasLoaded: true }
                    });
                })();

                let groups = {
                    invoice: {
                        title: 'Invoice details',
                        details: [
                            {
                                label: 'Invoice no.',
                                data: 'invoice_no',
                                type: 'text',
                                style: 'text_style',
                                note: this.reminderNote(salesInvoiceData),
                                disabled: true
                            }, {
                                label: 'Description',
                                data: 'description',
                                type: 'text',
                                disabledIfTrue: statusInvoiceIdChecker,
                                style: 'text_style' // format as string
                            }, {
                                label: 'Invoice date',
                                data: 'date',
                                type: 'datepicker',
                                maxDate: 'due_date',
                                disabledIfTrue: statusInvoiceIdChecker
                            }, {
                                label: 'Due date',
                                data: 'due_date' ,
                                type: 'datepicker',
                                minDate: 'date',
                                disabledIfTrue: statusInvoiceIdChecker
                            }, {
                                label: 'Customer #',
                                data: 'relation_customer_number',
                                type: 'text',
                                disabled: true,
                                opts: customers,
                                isAsync: {
                                    api: GetRelationList,
                                    label: 'name',
                                    value: 'id',
                                    optionLabel: ['customer_number', 'primary_person_full_name', 'billing_address'],
                                    getOptionLabel: ({ customer_number, primary_person_full_name, billing_address }) =>
                                        `${customer_number ? customer_number : String.fromCharCode(8212)}\n${primary_person_full_name ? primary_person_full_name : String.fromCharCode(8212)}\n${billing_address ? billing_address : String.fromCharCode(8212)}`
                                },
                                style: 'text_style',
                                update: this.getUpdates,
                                disabledIfTrue: statusInvoiceIdChecker
                            }, {
                                label: 'Company name',
                                data: 'relation_company_name',
                                type: 'text',
                                disabled: true,
                                style: 'text_string'
                            }, {
                                label: 'VAT number',
                                data: 'relation.vat_no',
                                type: 'text',
                                disabled: true,
                                style: 'text_style'
                            }
                        ]
                    },
                    finance: {
                        title: 'Finance', details: [
                            {
                                label: 'Total excl. /vat% VAT',
                                data: 'price',
                                type: 'text',
                                dynamicLabel: [
                                    {
                                        find: '/vat',
                                        data: 'sales_invoice.vat_percentage',
                                        defaultData: .21,
                                        hasMath: '/vat * 100'
                                    }
                                ],
                                disabled: true
                            }, {
                                label: '/vat% VAT',
                                data: 'price_vat',
                                type: 'text',
                                dynamicLabel: [
                                    {
                                        find: '/vat',
                                        data: 'sales_invoice.vat_percentage',
                                        defaultData: .21,
                                        hasMath: '/vat * 100'
                                    }
                                ],
                                disabled: true
                            }, {
                                label: 'Total incl. VAT',
                                type: 'text',
                                data: 'price_total',
                                disabled: true
                            }, {
                                label: 'Open amount to pay',
                                data: 'open_amt_pay',
                                type: 'text',
                                disabled: true
                            }, {
                                label: 'Payment condition',
                                type: 'select',
                                data: 'payment_condition_id',
                                opts: this.state.payment_conditions,
                                disabledIfTrue: statusInvoiceIdChecker,
                                update: this.updateDueDate
                            }, {
                                label: 'Invoice output',
                                type: 'select',
                                data: 'inv_output_type',
                                opts: [
                                    {
                                        value: 'email', label: 'Email', id: 'email'
                                    }, {
                                        value: 'paper', label: 'Paper', id: 'paper'
                                    }
                                ]
                            }, {
                                label: 'Invoice status',
                                data: 'status_invoice.status',
                                type: 'text',
                                disabled: true,
                                style: 'update_style'
                            }, {
                                label: "Billing run",
                                data: "billing_run_id",
                                //type: "text",
                                disabled: true,
                                style: "text_style",
                                //link: true,
                                type: 'link',
                                link: '/billing-run-overview',
                                var: 'billing_run_id',
                            },
                        ]
                    },
                    billing: {
                        title: 'Billing', details: [
                            {
                                label: 'Address',
                                data: 'invoice_address_id',
                                type: 'select',
                                opts: billing_addresses,
                                disabledIfTrue: statusInvoiceIdChecker
                            }, {
                                label: 'Person',
                                data: 'invoice_person_id',
                                type: 'select',
                                opts: persons,
                                disabledIfTrue: statusInvoiceIdChecker
                            }
                        ]
                    },
                    shipping: {
                        title: 'Shipping', details: [
                            {
                                label: 'Address',
                                data: 'shipping_address_id',
                                type: 'select',
                                opts: [...provisioning_addresses, ...shipping_addresses],
                                disabledIfTrue: statusInvoiceIdChecker
                            }, {
                                label: 'Person',
                                data: 'shipping_person_id',
                                type: 'select',
                                opts: persons,
                                disabledIfTrue: statusInvoiceIdChecker
                            }
                        ]
                    }
                };

                const getRelation = customers.find(customer => parseInt(customer.value) === parseInt(data.relation_id));
                if (!getRelation) {
                    // set data to delete from object
                    const defaultData = {
                        label: data['relation_customer_number'],
                        value: data.relation_id,
                        customer_number: data['relation_customer_number'],
                        primary_person_full_name: data['relation_primary_person'],
                        billing_address: data['relation_primary_address'],
                        billing_person: data['relation_primary_person'],
                        shipping_address: data['relation_primary_address'],
                        shipping_person: data['relation_primary_person']
                    };
                    customers.unshift(defaultData);

                    // set new group data
                    groups.invoice.details[4].opts = customers;
                    groups.invoice.details[4].defaultData = defaultData;
                    this.setState({customers})
                }

                this.setState({
                    groups,
                    details: salesInvoiceData,
                });
            })();
        }
        //dispatch(loaderToggle(false));
    };

    tableLoaded = (size) => {
        this.setState({ size: null });

        (async () => {
            await this.setState({ size });
            await this.setDropdownItems();
        })();

        this.setState({ hasLoaded: true });
    };

    invoiceListUpdateDetails = (invoiceDetailsUpdatedData) => {
        if (invoiceDetailsUpdatedData) {
            this.props.setInvoiceListUpdatedDetails(invoiceDetailsUpdatedData);
        }
    };

    componentDidMount() {
        let { id, match, setSelectedItem, setData, data, relationId, relation, setRelationId } = this.props;

        if (id && match && match.params && match.params.invoiceId && parseInt(id) !== parseInt(match.params.invoiceId)) {
            setSelectedItem(parseInt(match.params.invoiceId));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { id, invoiceId, setInvoiceId, relationId, deleteConceptEnabled, match, invoiceList, updateDetails, setIsUpdated, setData, dataDetails, invoice, relation, setRelationId, selectedItem, setSelectedItem, user, dispatch, data } = this.props;

        //console.log("Invoices Details - (processUpdateApi) - prevProps.invoice ->", prevProps.invoice);
        //console.log("Invoices Details - (processUpdateApi) - invoice ->", invoice);
        //console.log("Invoices Details - (processUpdateApi) - prevProps.dataDetails ->", prevProps.dataDetails);
        //console.log("Invoices Details - (processUpdateApi) - dataDetails ->", dataDetails);
        //console.log("Invoices Details - (processUpdateApi) - data ->", data);

        if (id && this.props.match.params && this.props.match.params.invoiceId && parseInt(this.props.match.params.invoiceId) !== parseInt(id)) {
            //console.log("Invoices Details - (componentDidUpdate) - hieroooo 1");

            setSelectedItem(parseInt(this.props.match.params.invoiceId));
            setRelationId(parseInt(this.props.match.params.relationId));

            this.setState({size: null});

        } else {

            //if (invoice && invoice.price_total && dataDetails && dataDetails.price_total === 0) { //dataDetails.price_total !== invoice.price_total) {
                //console.log("Invoices Details - (componentDidUpdate) - hieroooo 2");
                //invoice.price = dataDetails.price;

                //dispatch({type: 'INVOICE', payload: dataDetails});
                //(async () => {
                //    await
                //this.setState({dataDetails: dataDetails});
                //})();
                //this.setState({hasLoaded: false});
            //}


            if (id && prevProps.id !== id) {
                //console.log("Invoices Details - (componentDidUpdate) - hieroooo 3");

                (async () => {
                    await this.setState({
                        //id: id,
                        //data: dataDetails,
                        size: undefined,
                        dropdownItems: undefined,
                        groups: undefined,
                        details: undefined,
                        hasLoaded: false,
                    });

                    this.setState({size: null});
                })();

            } else {
                //console.log("Invoices Details - (componentDidUpdate) - hieroooo 4"); //selectedItem
                //console.log("Invoices Details - (selectedItem) - hieroooo 4 - selectedItem ->", selectedItem); //selectedItem
                //console.log("Invoices Details - (selectedItem) - hieroooo 4 - id ->", id); //selectedItem

                //setSelectedItem(id);
                //setIsUpdated(true);

                const { hasLoaded } = this.state;
                if (!hasLoaded) {
                    this.setState({hasLoaded: true});
                }
            }
        }
    }

    render() {
        const { size, groups, details, newData, dropdownItems, phoneUsage, hasLoaded, isLoading, debugInfo } = this.state,
            { id, selectedItem, setSelectedItem, deleteConceptEnabled, match, error, invoiceList, updateDetails, isUpdated, setIsUpdated, setIsListUpdated, dataDetails, setData, user, data, relation, relationId, setRelationId, invoiceId, invoice, refreshInvoiceList } = this.props,
            name = user.tenant && user.tenant.name ? user.tenant.name : '',
            path = match.path ? match.path : null;

        //console.log("Invoices Details - (processUpdateApi) - details ->", details);

        // for fade in transition add className to row (subscription-details-container) - panel-fade-in -> Row
        return (
            <React.Fragment>
                {!hasLoaded && <Loader customClass="custom-loader-pos"/>}
                {!error ?
                    <Row style={{display:`${hasLoaded ? 'flex' : 'none'}`}} className={`${hasLoaded ? 'invoice-details-container' : 'invoice-details-container'}`}>
                        <Col className="det-invoice-cont">
                            {/*(size !== undefined && size !== null && dropdownItems) &&
                                size !== undefined && size !== null && dropdownItems && hasLoaded && */
                                /*dropdownItems && hasLoaded &&*/
                                <Details
                                    //title={debugInfo ? `${name} Invoice #${details !== undefined && details.invoice_status === 0 ? 'concept-invoice' : details && details['id'] ? details['id'] : ''} - Relation #${relationId} / #${relation.id}` : `${name} Invoice #${details !== undefined && details.invoice_status === 0 ? 'concept-invoice' : details && details['id'] ? details['id'] : ''}`}
                                    title={debugInfo ? `${name} Invoice #${details !== undefined && details.invoice_status === 0 ? 'concept-invoice' : id ? id : ''} - Relation #${relationId} / #${relation.id}` : `${name} Invoice #${details !== undefined && details.invoice_status === 0 ? 'concept-invoice' : id ? id : ''}`}
                                    getApi={GetInvoice}
                                    updateApi={UpdateInvoice}
                                    id={id} // id // this.props.id ? parseInt(this.props.id) : id
                                    setSelectedItem={setSelectedItem}
                                    relationId={relationId}
                                    setRelationId={setRelationId}
                                    groups={groups}
                                    dropdownItems={dropdownItems}
                                    triggerAction={this.triggerAction}
                                    path={path ? path : ''}
                                    data={data ? data : null}
                                    dataLoaded={this.dataLoaded}
                                    newData={newData ? newData : null}
                                    setIsUpdated={setIsUpdated}
                                    isUpdated={isUpdated}
                                    tableFirst={false} // original is TRUE
                                    noLoader
                                    //mvLoader={mvLoaderToggleInv}
                                    offLoader
                                    name="Invoice"
                                    updateDetails={updateDetails}
                                    deleteConceptEnabled={deleteConceptEnabled}
                                    setData={setData}
                                    isLoading={isLoading}
                                    refreshInvoiceList={refreshInvoiceList}
                                    //details={dataDetails}
                                    dataDetails={dataDetails}
                                    size={size}
                                />}
                            {/*size !== undefined && id &&*/
                                /*size !== undefined && selectedItem &&*/ //selectedItem && selectedItem === id && // id
                                /*details*/ details && hasLoaded && selectedItem &&
                                <InvoiceLines
                                    noLoader
                                    id={id} //id //selectedItem
                                    //updateDetails={this.reload}
                                    updateDetails={updateDetails}
                                    setIsUpdated={setIsUpdated}
                                    isUpdated={isUpdated}
                                    //setData={this.props.setData}
                                    isUpdatable={details !== undefined ? details['is_updatable'] : false}
                                    //isUpdatable={dataDetails !== undefined ? dataDetails['is_updatable'] : false}
                                    tableLoaded={this.tableLoaded}
                                    setIsListUpdated={setIsListUpdated}
                                    //setInvoiceListUpdatedDetails={this.props.setInvoiceListUpdatedDetails}
                                />}
                            {data && phoneUsage !== undefined && phoneUsage
                                ?
                                <PhoneUsage
                                    data={data}
                                    id={id}
                                    hide={this.togglePhoneUsage}
                                />
                                :
                                null}
                        </Col>
                    </Row> :
                    <Unauthorized />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ dispatch, user, loader, error, invoice, subscription, relation }) => ({ dispatch, user, loader, error, invoice, subscription, relation });

export default connect(mapStateToProps)(withTranslation()(withRouter(InvoiceDetails)));
