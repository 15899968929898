import React from "react";
import { Check } from "react-feather";
import { GetPayments } from '../../../controllers/payments';
import ParentTable from '../../../components/ParentTable';

class Payments extends React.Component {
    defaultFormatter = (cell) => {
        return (
            <React.Fragment>
                {cell === 1 &&
                <Check size={18} color="#47bac1" className="check" />
                }
            </React.Fragment>
        )
    };
    render() {
        const { id } = this.props;

        return (
            <ParentTable
                table="RelationPayments"
                id={id}
                data={GetPayments}
                columns={[
                  {
                    dataField: "date",
                    text: "Date",
                    formatter: this.defaultFormatter,
                    sort: true,
                    headerStyle: () => {
                      return { width: '15%'};
                    }
                  }, {
                    dataField: "descr",
                    text: "Description",
                    sort: true,
                    headerStyle: () => {
                        return { width: '25%'};
                    }
                  }, {
                    dataField: "amount",
                    text: "Amount",
                    sort: true,
                    headerStyle: () => {
                        return { width: '15%'};
                    }
                  }, {
                    dataField: "type",
                    text: "Type",
                    sort: true,
                    headerStyle: () => {
                        return { width: '10%'};
                    }
                  }, {
                    dataField: "account_iban",
                    text: "IBAN",
                    sort: true,
                    headerStyle: () => {
                        return { width: '20%'};
                    }
                  }, {
                    dataField: "batch_id",
                    text: "Invoice",
                    sort: true,
                    headerStyle: () => {
                        return { width: '10%'};
                    }
                  }
                ]}
                noSearch={true}
                striped={true}
                notLoadedByDefault={false}
                noDataIndication="No payments found."
                noHover={false}
                noTopBorder={true}
                pageHeadingTitle="Relation"
                classes="table-responsive table-striped"
                wrapperClasses="table-payments f2x-custom-table"
                offLoader
            />
        )
    }
}

export default Payments;