import React from "react";
import { GetMyAccounts } from '../../../controllers/accounts';
import AccountForm from "./Form";
import ParentTable from '../../../components/ParentTable';
import { Helmet } from "react-helmet";

class Accounts extends React.Component {
  render() {
    const { id, tabTitle } = this.props;

    const formatDataDefault = (cell) => {
      if (cell) {
        return cell;
      } else {
        return String.fromCharCode(8212);
      }
    };

    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="Accounts"
          id={id}
          keyField="id"
          classes="table-responsive"
          wrapperClasses="table-tenant-account f2x-custom-table"
          noTopBorder={true}
          data={GetMyAccounts}
          columns={[
            {
              dataField: "description",
              text: "Description",
              sort: true,
              headerStyle: () => {
                return { width: '25%' };
              },
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "type",
              text: "Type",
              sort: true,
              headerStyle: () => {
                return { width: '25%' };
              },
              attrs: {
                'data-label': 'Type'
              }
            },
            {
              dataField: "code",
              text: "Code",
              formatter: formatDataDefault,
              sort: true,
              headerStyle: () => {
                return { width: '15%' };
              },
              attrs: {
                'data-label': 'Code'
              }
            },
            {
              dataField: "parent_id",
              text: "Parent id",
              formatter: formatDataDefault,
              sort: true,
              align: 'center',
              headerStyle: () => {
                return { width: '15%', 'textAlign': 'center' };
              },
              attrs: {
                'data-label': 'Parent id'
              }
            },
            {
              dataField: "export_code",
              text: "Export code",
              formatter: formatDataDefault,
              sort: true,
              headerStyle: () => {
                return { width: '20%' };
              },
              attrs: {
                'data-label': 'Export code'
              }
            }
          ]}
          rowAction="edit"
          rowActionForm={AccountForm}
          headerDropdownOpts={[{
            label: 'Add',
            action: AccountForm
          }]}
        />
      </React.Fragment>
    )
  }

}
export default Accounts;
