// Solr search
export const SOLR_MENU_VISIBILITY_SHOW = "SOLR_MENU_VISIBILITY_SHOW";
export const SOLR_MENU_VISIBILITY_HIDE = "SOLR_MENU_VISIBILITY_HIDE";
export const SOLR_MENU_VISIBILITY_TOGGLE = "SOLR_MENU_VISIBILITY_TOGGLE";
export const SOLR_RESULT_UPDATE = "SOLR_RESULT_UPDATE";

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// SidebarMenu Invoices (option)
export const SIDEBAR_MENU_INVOICES_VISIBILITY_TOGGLE = "SIDEBAR_MENU_INVOICES_VISIBILITY_TOGGLE";
export const SIDEBAR_MENU_INVOICES_VISIBILITY_SHOW = "SIDEBAR_MENU_INVOICES_VISIBILITY_SHOW";
export const SIDEBAR_MENU_INVOICES_VISIBILITY_HIDE = "SIDEBAR_MENU_INVOICES_VISIBILITY_HIDE";

// SidebarMenu Subscriptions (option)
export const SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_TOGGLE = "SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_TOGGLE";
export const SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_SHOW = "SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_SHOW";
export const SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_HIDE = "SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_HIDE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// User
export const USER = "USER";
export const UPDATE_USER = "UPDATE_USER";
export const TENANT_ID = "TENANT_ID";
export const UPDATE_TENANT = "UPDATE_TENANT";

// Tenant
export const TENANTS = "TENANTS";
export const ADD_TENANT = "ADD_TENANT";

// Brands
export const BRAND = "BRAND";
export const UPDATE_THEME = "UPDATE_THEME";

// Loader
export const LOADER_TOGGLE = "LOADER_TOGGLE";

// Error
export const UPDATE_ERROR = "UPDATE_ERROR";

// Validate
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";
export const VALIDATE_IBAN = "VALIDATE_IBAN";
export const VALIDATE_DATE = "VALIDATE_DATE";

// Formatter
export const FORMAT_NUMBER = "FORMAT_NUMBER";
export const FORMAT_SEPARATORS = "FORMAT_SEPARATORS";

// Invoice
export const INVOICE = 'INVOICE';
export const INVOICES = 'INVOICES';

// Relation
export const RELATION = 'RELATION';
export const RELATION_MV_PATH = 'RELATION_MV_PATH';

// Master View specific loader
export const MV_LOADER_TOGGLE = "MV_LOADER_TOGGLE";
export const MV_LOADER_TOGGLE_INVOICES = "MV_LOADER_TOGGLE_INVOICES";

// Subscription
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const SUBSCRIPTIONS = 'SUBSCRIPTIONS';

// Offer prices
export const OFFER_DEFAULT_PRICES = 'OFFER_DEFAULT_PRICES';
export const OFFER_ADDITIONAL_PRICES = 'OFFER_ADDITIONAL_PRICES';

// Notes
export const NOTES = 'NOTES';
