import React, { useState, useEffect } from 'react';
import { Form, TextField, SelectField, ReactSelectField, RadioField, MultiCheckboxField, SingleCheckboxField, SubmitButton } from './FormElements';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Row, Col, Input } from "reactstrap";
import { loaderToggle } from "../../redux/actions/loaderActions";
import { validateDate } from "../../redux/actions/validateActions";
import Toast from "../../components/Toast";
import {withTranslation} from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';
import ReactQuill from 'react-quill';
import ReactJson from 'react-json-view';
import parse from 'html-react-parser';

const FormElementBuilder = ({ ...props }) => {
  let formSchema = props.formSchema ? props.formSchema : {};
  let mainKey = props.mainKey ? props.mainKey : false;

  const [formData, setFormData] = useState({});
  const [validationSchema, setValidationSchema] = useState({});

  const formatTextareaData = (getData) => {
    if (getData && getData !== '') {
      if (typeof getData === 'string') {
        return parse(getData);
      } else {
        return getData;
      }
    }
    return '';
  };

  useEffect(() => {
    initForm(formSchema, mainKey);
  }, []);

  const initForm = (formSchema, mainKey = false) => {
    console.log("FormBuilder - (default)formSchema ->", formSchema);
    console.log("FormBuilder - (default)mainKey ->", mainKey);

    let _formData = {};
    let _validationSchema = {};

    if (mainKey) {
      //let newFormSchema = {[mainKey]: formSchema.replace(/\r?\n|\r/g, " ")};

      let newFormSchema = {[mainKey]: JSON.parse(formSchema)};
      //const listItems = newFormSchema.map((newFormSchema) => console.log(newFormSchema[mainKey]));
      //console.log("FormBuilder - (default)listItems ->", listItems);
      console.log("FormBuilder - (default)newFormSchema ->", newFormSchema);
      //formSchema = JSON.stringify(newFormSchema);

      //const value = formatTextareaData(newFormSchema);
      //formSchema = JSON.stringify(formatTextareaData(newFormSchema));
      formSchema = newFormSchema;
      console.log("FormBuilder - new(default)formSchema ->", formSchema);
    }

    console.log("FormBuilder - (default)formSchema ->", formSchema);

    for (let key of Object.keys(formSchema)) {
      console.log("FormBuilder - (default)formSchema - key ->", key);

      _formData[key] = "";

      if (formSchema[key].type === "text") {
        _validationSchema[key] = Yup.string();
      } else if (formSchema[key].type === "email") {
        _validationSchema[key] = Yup.string().email();
      } else if (formSchema[key].type === "select" || formSchema[key].type === "react-select") {
        _validationSchema[key] = Yup.string().
            oneOf(formSchema[key].options.map(o => o.value.toString()));
      }

      if (formSchema[key].required) {
        if (_validationSchema[key]) _validationSchema[key] = _validationSchema[key].required(
            'Required');
      }
    }

    console.log("FormBuilder - (default)_formData - _formData ->", _formData);

    setFormData(_formData);
    setValidationSchema(Yup.object().shape({ ..._validationSchema }));
  };

  //const getFormElement = (elementName, formSchema) => {
  const getFormElement = (formSchema, label) => {
    console.log("FormBuilder - getFormElement - elementName ->", formSchema);
    console.log("FormBuilder - getFormElement - Object.keys(formSchema).length ->", Object.keys(formSchema).length);
    //console.log("FormBuilder - getFormElement - elementSchema ->", elementSchema);
    //console.log("FormBuilder - getFormElement - elementSchema ->", elementSchema[elementName]);

    if (formSchema && Object.keys(formSchema).length > 0) {
      formSchema = JSON.parse(formSchema);
      console.log("FormBuilder - getFormElement - elementName ->", formSchema);
      //elementSchema = JSON.stringify(formatTextareaData(elementSchema));
      //console.log("FormBuilder - getFormElement - elementSchema ->", elementSchema);

      //console.log("FormBuilder - getFormElement - formSchema[elementName] ->", formSchema.elementName);

      for (let key of Object.keys(formSchema)) {
        console.log("FormBuilder - getFormElement - key ->", key);
        const props = {
          //name: formSchema.label.replace(/ /g, "_").toLowerCase(),
          name: formSchema.id,
          label: formSchema.label,
          options: formSchema.options
        };

        if (formSchema.type === "text" || formSchema.type === "email") {
          return <TextField {...props} />
        }

        if (formSchema.type === "select") {
          return <SelectField {...props} />
        }

        if (formSchema.type === "react-select") {
          return <ReactSelectField {...props} />
        }

        if (formSchema.type === "radio") {
          return <RadioField {...props} />
        }

        if (formSchema.type === "checkbox") {
          return <MultiCheckboxField {...props} />
        }
      }
    }
    return false;
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    console.log(values);
    setSubmitting(false);
  };

  return (
      <div className="form-builder">
        <Form
            enableReinitialize
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
          {/*console.log("FormBuilder - getFormElement - formSchema(before loop) ->", formSchema)*/}
          {/*console.log("FormBuilder - getFormElement - formSchema parsed(before loop) ->", JSON.parse(formSchema))*/}
          {/*Object.keys(formSchema).map((key, ind) => (
              <div key={key}>
                {getFormElement(key, formSchema)}
              </div>
          ))*/}
          {getFormElement(formSchema, mainKey)}
        </Form>
      </div>
  );
};

export default FormElementBuilder;
