import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import hljs from "highlight.js/lib/core";
import InternalLink from "../../../components/quill/QuillAttributesLink";
import ReactQuill, { Quill, Mixin, Toolbar } from "react-quill";
import DatePicker, { registerLocale } from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Label, FormGroup, Form, CustomInput, Input, Row, Col, Table, InputGroup, InputGroupAddon, ButtonGroup } from "reactstrap";
import { PlusSquare, XSquare } from "react-feather";
import parse from "html-react-parser";
import { withTranslation } from 'react-i18next';
import { GetPlanLineGroup, AddPlanLineGroup, UpdatePlanLineGroup } from '../../../controllers/plans';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate } from "../../../redux/actions/validateActions";
import {
  formatNumber, parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import { enGB, enUS, nl } from 'date-fns/locale';
import ToastMulti from '../../../components/ToastMulti';
import PlanPreview from '../plan_preview/List';
import DynamicTextField from '../../../components/DynamicTextAreas';
import { TextField, SelectField, RadioField, MultiCheckboxField, SingleCheckboxField, SubmitButton } from "../../../components/form_builder/FormElements";
import DynamicTextFieldPreview from '../../../components/form_builder/FormElementBuilder';
import { faSearchPlus, faCaretDown, faCaretUp, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageResize from "quill-image-resize-module-react";
import ZoomedHTMLScreen from "../../../components/ZoomedHTMLScreen";

Quill.register('modules/imageResize', ImageResize);

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  },
  clipboard: {
    matchVisual: false
  }
};

const PlanLineGroupForm = ({ ...props }) => {
  const { show, hide, update, loader, selectedData, dispatch, user, tenantId } = props;

  console.log("PlanLineGroups form - selectedData ->", selectedData);

  const [hidePreview, setHidePreview] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [jsonDataDetails, setJsonDataDetails] = useState(null);
  const [zoomModalOpenNested, setZoomModalOpenNested] = useState(false);
  const [zoomedHtml, setZoomedHtml] = useState(null);

  const [planLineGroupsOpts, setPlanLineGroupsOpts] = useState([
    { label: "Radio", value: "radio" },
    { label: "Checkbox", value: "checkbox" },
    { label: "Select", value: "select" },
    { label: "Input", value: "input" },
    { label: "Textarea", value: "textarea" },
    { label: "Button", value: "button" },
    { label: "None", value: "none" },
  ]);

  const [planLineGroup, setPlanLineGroup] = useState(selectedData ? selectedData : {
    header: null,
    description: null,
    text: null,
    json_data: null,
    type: planLineGroupsOpts.filter((item) => item.value === "none"),
    sort: 0,
  }
  );

  const [formType, setFormType] = useState(selectedData ? 'Edit' : 'Add');
  const [formName, setFormName] = useState(`plan line group`);

  let selectRefPlanLineGroup = useRef(null);

  const collapseJsonDataField = (e) => {
    e.preventDefault();

    this.setState({
      collapseJsonField: !this.state.collapseJsonField,
      collapseJsonFieldIcon: !this.state.collapseJsonField === true ? faCaretUp : faCaretDown,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let notificationsToastArr = [];

    if (!planLineGroup.header) {
      notificationsToastArr.push({ title: 'Header field is required!', icon: 'warn' });
    }
    if (planLineGroup.json_data && !isValidJSONString(planLineGroup.json_data)) {
      notificationsToastArr.push({ title: 'JSON data is invalid!', icon: 'warn' });
    }
    if (!planLineGroup.type) {
      notificationsToastArr.push({ title: 'Type field is required!', icon: 'warn' });
    }
    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      if (planLineGroup.type) {
        planLineGroup.type = planLineGroup.type.value;
      }

      if (selectedData) {
        (async () => {
          const { ok } = await UpdatePlanLineGroup(planLineGroup, selectedData.id);
          await handleOk(ok);
        })();
      } else {
        (async () => {
          const { ok } = await AddPlanLineGroup(planLineGroup);
          await handleOk(ok);
        })();
      }
    }
  };

  const handleOk = async (ok, options) => {
    dispatch(loaderToggle(false));

    if (ok) {
      update();
      if (options && options.length > 0) {
        return ToastMulti(options, "container-validation-id");
      } else {
        hide();
      }
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    let selectedData = Object.assign({}, planLineGroup);
    selectedData[name] = value;

    setPlanLineGroup(selectedData);
  };

  const handleSelectChange = (e) => {
    let selectedData = Object.assign({}, planLineGroup);
    selectedData["type"] = e;

    setPlanLineGroup(selectedData);
  };

  const getSelectVal = (name) => {
    if (!planLineGroup[name] && name === "type") {
      return "none";
    } else {
      if (!selectedData[name] && name === "type") {
        return "none";
      } else {
        return selectedData[name];
      }
    }
  };

  const minify = (s) => {
    return s ? s
      .replace(/\>[\r\n ]+\</g, "><")  // Removes new lines and irrelevant spaces which might affect layout, and are better gone
      .replace(/(<.*?>)|\s+/g, (m, $1) => $1 ? $1 : ' ')
      .trim()
      : ""
  }

  const handleQuillChangeTextarea = (value) => {
    let selectedData = Object.assign({}, planLineGroup);
    selectedData.text = minify(value);

    setPlanLineGroup(selectedData);
  };

  const handleTextChange = (e) => {
    let selectedData = Object.assign({}, planLineGroup);
    selectedData["json_data"] = e.target.value;

    setPlanLineGroup(selectedData);
  };
  
  const openZoomModal = (data, e) => {    
    setZoomedHtml(data.text);
    setZoomModalOpenNested(true);
  };

  const closeZoomModal = () => {
    setZoomModalOpenNested(false);
    setZoomedHtml(null);
  };

  const handleZoomedHtmlChange = (e) => {
    setZoomedHtml(e);
  };

  const handleSave = (e) => {    
    let selectedData = Object.assign({}, planLineGroup);
    selectedData.text = zoomedHtml;

    setPlanLineGroup(selectedData);
    closeZoomModal();
  };

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const isValidJSONString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const hidePreviewJsonData = (e) => {
    e.preventDefault();

    setHidePreview(!hidePreview);
  };

  const showPreviewJsonData = (e) => {
    e.preventDefault();

    setShowPreview(!showPreview);
  };

  const validatePreviewJsonData = (e) => {
    const value = e.target.value;

    let notificationsToastArr = [];
    if (value && !isValidJSONString(value)) {
      notificationsToastArr.push({ title: 'JSON data is invalid!', icon: 'warn' });
    } else {
      notificationsToastArr.push({ title: 'JSON data is valid!', icon: 'success' });
    }

    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }
  };

  const renderDynamicData = (data) => {
    if (data)
      return data;
  };

  const renderPreview = () => {
    return (
      <div className="plan-line-group-preview">
        <DynamicTextFieldPreview formSchema={planLineGroup && planLineGroup.json_data ? planLineGroup.json_data : jsonDataDetails ? jsonDataDetails : ""} />
      </div>
    )
  };

  const getPlanTypeByName = (type) => {
    if (!type) {
      return "None";
    } else {
      return planLineGroupsOpts.find(item => item.value === type);
    }
  }

  useEffect(() => {
    console.log("PlanLineGroupForm - planLineGroup ->", planLineGroup);
    console.log("PlanLineGroupForm - selectedData ->", selectedData);

    if (selectedData) {
      setPlanLineGroup(selectedData);
    }
  }, []);

  return (
    <React.Fragment>
      {!loader && <Modal isOpen={show} toggle={hide} centered>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <ModalHeader>{formType} {formName}</ModalHeader>
          <ModalBody className="mt-3 mb-3">
            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Description:</label>
              </Col>
              <Col md={10}>
                <Input
                  id="description"
                  name="description"
                  placeholder="Description"
                  value={planLineGroup && planLineGroup.description ? planLineGroup.description : ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </div>
            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Header:</label>
              </Col>
              <Col md={10}>
                <Input
                  id="header"
                  name="header"
                  placeholder="Header"
                  value={planLineGroup && planLineGroup.header ? planLineGroup.header : ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </div>
            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Text:</label>
              </Col>
              <Col md={10}>
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  id="text"
                  name="text"
                  placeholder="Enter text description"
                  value={planLineGroup && planLineGroup.text ? planLineGroup.text : ""}
                  onChange={(e) => handleQuillChangeTextarea(e)}
                />
                <div style={{ textAlign: "right", marginTop: "0.25rem" }}>
                  <Button color="primary" className="btn-sm" onClick={(e) => openZoomModal(planLineGroup, e)}>
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </Button>
                </div>
              </Col>
            </div>
            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Type:</label>
              </Col>
              <Col md={10}>
                <Select
                  id="type"
                  className="react-select-container"
                  placeholder="Type"
                  classNamePrefix="react-select"
                  options={planLineGroupsOpts}
                  onChange={(e) => handleSelectChange(e)}
                  maxMenuHeight={300}
                  value={getPlanTypeByName(planLineGroup && planLineGroup.type ? planLineGroup.type : false)}
                />
              </Col>
            </div>

            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Json data:</label>
              </Col>
              <Col md={10}>
                {/*<ButtonGroup className="btn-group-textarea-collapse">
                    <Button color="primary" className="btn-sm" onClick={(e) => showPreviewJsonData(e)}>{ !showPreview ? `Show preview` : `Hide preview` }</Button>
                    <Button color="primary" className="btn-sm" disabled={showPreview} onClick={(e) => validatePreviewJsonData(e)}>Validate JSON</Button>
                  </ButtonGroup>*/}
                {!showPreview &&
                  <Input
                    name="json_data"
                    style={{ minHeight: "200px" }}
                    type="textarea"
                    placeholder="Enter JSON data"
                    value={planLineGroup && planLineGroup.json_data ? planLineGroup.json_data : ""}
                    onChange={(e) => handleTextChange(e)}
                  />}
                {showPreview &&
                  <div className="form-element-preview">
                    {planLineGroup.json_data && planLineGroup.json_data !== "" ? (
                      <DynamicTextFieldPreview
                        formSchema={planLineGroup.json_data ? planLineGroup.json_data : ""}
                        mainKey={planLineGroup.description ? planLineGroup.description.replace(/ /g, "_").toLowerCase() : planLineGroup.header ? planLineGroup.header.replace(/ /g, "_").toLowerCase() : false}
                      />
                    ) : (
                      <p>No preview available</p>
                    )}
                  </div>}
              </Col>
            </div>

            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Sort:</label>
              </Col>
              <Col md={10}>
                <Input
                  id="sort"
                  name="sort"
                  placeholder="Sort"
                  value={planLineGroup && planLineGroup.sort ? planLineGroup.sort : ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <span className="btn btn-danger" onClick={hide}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <Button color="primary">
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </ModalFooter>
        </Form>
        <ZoomedHTMLScreen
          isOpen={zoomModalOpenNested}
          toggle={closeZoomModal}
          zoomedHtml={zoomedHtml}
          onChange={handleZoomedHtmlChange}
          onSave={handleSave}
        />
      </Modal>}
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, dispatch, loader }) => ({ tenantId: user.tenant_id, dispatch, loader });

export default withRouter(withTranslation()(connect(mapStateToProps, null, null, { forwardRef: true })(PlanLineGroupForm)));
