import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Input, Label, Row, Col } from "reactstrap";
import { GetMyTenants, AddTenant, GetTenantLists } from '../../controllers/tenants';
import { GetCountries } from '../../controllers/relations';
import { addTenant } from '../../redux/actions/tenantActions';
import { loaderToggle } from '../../redux/actions/loaderActions';
import { validateDate } from "../../redux/actions/validateActions";
import Toast from "../../components/Toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

class TenantForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formType: 'Add',
      formName: 'tenant',
      tenant: {
        use_accounting: 0,
        default_country_id: 155
      },
      default_country_id: {
        'label': 'Netherlands The',
        'value': 155
      },
      use_accounting: {
        'label': "No",
        'value': 0
      }
    }

    this.hasUpdated = React.createRef(false);
  }

  handleSelectChange = (name, data) => {
    let tenant = Object.assign({}, this.state.tenant);

    tenant[name] = data.value;

    this.setState({
      tenant,
      [name]: data
    })
  };

  handleInputChange({ target: { name, value } }) {
    let tenant = Object.assign({}, this.state.tenant);

    tenant[name] = value;

    this.setState({ tenant })
  }

  handleDatePickerChange(name, date) {
    let tenant = Object.assign({}, this.state.tenant);
    const { newDate } = this.props.dispatch(validateDate(date));

    tenant[name] = newDate;

    this.setState({ tenant })
  }

  handleKeyDown = ({ which }, name) => {
    if (which === 9) {
      this.ref[name].setOpen(false);
    }
  };

  handleSubmit(e) {
    e.preventDefault();

    const { dispatch } = this.props,
          { parent_id, tenant } = this.state,
          { name, billing_day } = tenant;

    //Validations
    if (!name) {
      return Toast.fire({ title: 'Name field is required!', icon: 'warning' })
    }

    if (!parent_id) {
      return Toast.fire({ title: 'Parent tenant field is required!', icon: 'warning' })
    }

    if (!billing_day) {
      return Toast.fire({ title: 'Billing day field is required!', icon: 'warning' })
    }

    (async () => {
      dispatch(loaderToggle(true));

      const { ok, data } = await AddTenant(tenant);

      dispatch(loaderToggle(false));

      if (ok) {
        dispatch(addTenant(data.data));
        this.props.history.push({ pathname: `/tenants/${data.data.id}/details` })
      }
    })();
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loaderToggle(true));

    (async () => {

      const [getMyTenants, getCountries] = await Promise.all([
        //GetMyTenants(),
        GetTenantLists(),
        GetCountries()
      ]);

      let tenantOpts = [],
        countryOpts = [{
          label: '—',
          value: ''
        }];

      await (() => {
        if (getMyTenants.ok) {
          const tenants = getMyTenants.data.data;
          tenants.map(({ name, id }) => {
            return tenantOpts.push({
              label: name,
              value: id
            })
          })
        }

        if (getCountries.ok) {
          const countries = getCountries.data.data;
          countries.length > 0 && countries.map(({ name, id }) => {
            return countryOpts.push({
              label: name,
              value: id
            })
          });

          let useAccountingOpts = [{
            value: 1,
            label: "Yes"
          }, {
            value: 0,
            label: "No"
          }];

          this.setState({
            countryOpts,
            useAccountingOpts
          })
        }

      })();

      await this.setState({ tenantOpts });
      dispatch(loaderToggle(false))
    })()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.hasUpdated.current) {      
      this.hasUpdated.current = true;
    }
  }

  render() {
    const { show, hide, loader } = this.props,
      { formType, formName, tenantOpts, parent_id, tenant, useAccountingOpts, use_accounting, default_country_id, countryOpts } = this.state,
      { name, billing_day, billing_schedule, invoice_start_calculation, service_number, service_email, service_url, identifier, relation_id } = tenant;

    return (
      <Modal isOpen={show} toggle={hide} centered className="modal-xl">
        {!loader && <Form onSubmit={(e) => this.handleSubmit(e)}>
          <ModalHeader>{formType} {formName}</ModalHeader>
          <ModalBody className="mt-3 mb-3">
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Name:</label>
              </Col>
              <Col md={9}>
                <Input
                    id="name"
                    name="name"
                    value={name ? name : ''}
                    placeholder="Name"
                    onChange={(e) => this.handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Parent tenant:</label>
              </Col>
              <Col md={9}>
                {tenantOpts && <Select
                    id="parent_id"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={tenantOpts}
                    value={parent_id ? parent_id : null}
                    onChange={this.handleSelectChange.bind(this, 'parent_id')}
                    maxMenuHeight={300}
                />}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label className="col-form-label">Invoice billing day / schedule:</label>
              </Col>
              <Col md={9}>
                <Row className="form-row">
                  <Col md={6} className="pb-2">
                    <Input
                        id="billing_day"
                        name="billing_day"
                        value={billing_day ? billing_day : ''}
                        type="number"
                        inputprops={{ min: "1", max: "31", step: "1" }}
                        placeholder="Day of the month invoicing is done"
                        onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                  <Col md={6} className="pb-2">
                    <Input
                        id="billing_schedule"
                        name="billing_schedule"
                        value={billing_schedule ? billing_schedule : ''}
                        type="number"
                        inputprops={{ min: "1", max: "31", step: "1" }}
                        placeholder="Day of the month invoice calculation starts"
                        onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Invoice start calculation:</label>
              </Col>
              <Col md={9}>
                <DatePicker
                    className="form-control"
                    id="invoice_start_calculation"
                    name="invoice_start_calculation"
                    dateFormat="dd-MM-yyyy"
                    autoComplete="off"
                    placeholderText="Invoice start calculation"
                    selected={invoice_start_calculation ? moment(invoice_start_calculation, 'DD-MM-YYYY')._d : null}
                    onChange={this.handleDatePickerChange.bind(this, 'invoice_start_calculation')}
                    ref="invoice_start_calculation"
                    onKeyDown={(e) => this.handleKeyDown(e, 'invoice_start_calculation')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label className="col-form-label">Use accounting / Identifier:</label>
              </Col>
              <Col md={9}>
                <Row className="form-row">
                  <Col md={6} className="pb-2">
                    {useAccountingOpts && <Select
                        id="use_accounting"
                        name="use_accounting"
                        options={useAccountingOpts}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Use accounting"
                        value={use_accounting ? use_accounting : null}
                        onChange={this.handleSelectChange.bind(this, 'use_accounting')}
                        maxMenuHeight={300}
                    />}
                  </Col>
                  <Col md={6} className="pb-2">
                    <Input
                        id="identifier"
                        name="identifier"
                        placeholder="Identifier"
                        value={identifier ? identifier : ''}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label className="col-form-label">Default country / Service number:</label>
              </Col>
              <Col md={9}>
                <Row className="form-row">
                  <Col md={6} className="pb-2">
                    <Select
                        name="default_country_id"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={countryOpts}
                        placeholder="Default country"
                        value={default_country_id ? default_country_id : null}
                        onChange={this.handleSelectChange.bind(this, 'default_country_id')}
                        maxMenuHeight={300}
                    />
                  </Col>
                  <Col md={6} className="pb-2">
                    <Input
                        id="service_number"
                        name="name"
                        placeholder="Service number"
                        value={service_number ? service_number : ''}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label className="col-form-label">Service email / url:</label>
              </Col>
              <Col md={9}>
                <Row className="form-row">
                  <Col md={6} className="pb-2">
                    <Input
                        id="service_email"
                        name="service_email"
                        placeholder="Service email"
                        value={service_email ? service_email : ''}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                  <Col md={6} className="pb-2">
                    <Input
                        id="service_url"
                        name="service_url"
                        placeholder="Service url"
                        value={service_url ? service_url : ''}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <span className="btn btn-danger" onClick={hide}>
              <FontAwesomeIcon icon={faTimes} />       
            </span>
            <Button color="primary">
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </ModalFooter>
        </Form>
        }
      </Modal>
    )
  }
}

export default withRouter(connect(store => ({
  loader: store.loader
}))(TenantForm));
