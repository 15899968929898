import React from 'react';
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { RemovePDFTemplate } from '../../../../controllers/tenants';
import { loaderToggle } from '../../../../redux/actions/loaderActions';
import Toast from "../../../../components/Toast";

class RemovePDFTemplateForm extends React.Component {
  deleteRow = (rowIndex) => {
    if (rowIndex) {
      let table = document.querySelector('table');
      table.deleteRow((parseInt(rowIndex)+1));
    }
  };

  selectedRow = (rowIndex) => {
    if (rowIndex) {
      let table = document.querySelector('table');
      let table_body = table.children && table.children[1];
      table_body.rows[rowIndex].setAttribute("class","row-to-delete");
    }
  };

  unSelectRow = (rowIndex) => {
    if (rowIndex) {
      let table = document.querySelector('table');
      let table_body = table.children && table.children[1];
      table_body.rows[rowIndex].removeAttribute("class");
    }
  };

  componentDidMount() {
    const { loader, dispatch, hide, update, selectedData } = this.props;

    if (this.props.selectedDataRow) {
      this.selectedRow(this.props.selectedDataRow);
    }

    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this PDF template?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      reverseButtons: true
    }).then(res => {
      if (res.value) {
        if (!loader) {
          (async () => {
            dispatch(loaderToggle(true));

            const { ok } = await RemovePDFTemplate(selectedData.id);
            if (ok) {
              dispatch(loaderToggle(false));
              update();

            } else {
              await Toast.fire({
                title: 'Something went wrong!',
                icon: 'warning'
              })
            }

            dispatch(loaderToggle(false));
            hide();

          })();
        }
      } else {
        this.unSelectRow(this.props.selectedDataRow);
        update();
        hide();

      }
    })
  }

  render() {
    return (
        <React.Fragment>
        </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader,
  user: store.user
}))(RemovePDFTemplateForm);