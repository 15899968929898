import * as React from 'react';
import async from "../components/Async";
import {
    Sliders as SlidersIcon,
    UserCheck as UserCheckIcon,
    Users as UsersIcon,
    Link as LinkIcon,
    Globe as GlobeIcon,
    Star as StarIcon,
    File as FileIcon,
    CreditCard as CreditCardIcon,
    Tag as TagIcon,
    Wifi as WifiIcon,
    Settings as SettingsIcon,
    Activity as ActivityIcon,
    TrendingUp as TrendingUpIcon,
    Power as PowerIcon,
    FilePlus as FilePlusIcon,
    Clipboard as ClipboardIcon,
    ChevronRight as ChevronRightIcon,
    Monitor as MonitorIcon
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import Verify from "../pages/auth/Verify";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import Maintenance from "../pages/auth/Maintenance";

// Tables
import Relations from "../pages/relation/List";
import RelationDetails from "../components/MVDetails";
import Persons from "../pages/relation/persons/List";
import Tenants from "../pages/tenant/List";
import Plans from "../pages/plan/List";
import PlanLineGroups from "../pages/plan/plan_line_groups/List";
import TermsConditions from "../pages/plan/terms_conditions/List";
import Invoices from "../pages/relation/invoices/List";
import Subscriptions from "../pages/relation/subscriptions/List";
import Products from "../pages/product/List";
import Payments from "../pages/relation/payments/List";
import Users from "../pages/users/List";
import UserApiKeys from "../pages/users/Api_keys";
import FinanceInvoices from "../pages/finance/List"
import Provisioning from '../pages/provisioning/List'
import Orders from "../pages/orders/List"
import Tickets from "../pages/tickets/index";

// 3rd Parties
import L2FiberServiceProvider from "../pages/l2fiber/index";

// Pages
import Profile from "../pages/pages/Profile";
import Settings from "../pages/pages/settings/index";
import AdminTools from "../pages/pages/admin_tools/index";
import ActivityLogs from "../pages/pages/activity_logs/Details";
import ActivityLogsFilter from "../pages/pages/activity_logs/ListFilter";
import DownloadDataDump from "../pages/pages/admin_tools/DataDump";

// Templates
import EmailTemplates from "../pages/tenant/templates/Email/List";
import PDFTemplates from "../pages/tenant/templates/PDF/List";

// Details
//import RelationDetails from "../components/MVDetails";
import TenantDetails from "../pages/tenant/Details";
import PlanDetails from "../pages/plan/Details";
import FiscalYearDetails from "../pages/tenant/fiscal_years/Details";
import JournalDetails from "../pages/tenant/journals/Details";

// Billing run Tools
import BillingRunFinance from '../pages/pages/admin_tools/Finance'
import BillingRunOverview from '../pages/pages/admin_tools/BillingRunOverview'
import M7PowerTools from '../pages/pages/admin_tools/M7PowerTools'

// New
import NewInvoice from "../pages/relation/invoices/New";
import NewRelation from "../pages/relation/New";

// AvChecker - Availability Checker
import AvCheck from "../pages/avcheck/List";
import AvCheckEmbed from "../pages/avcheck/ListEmbed";

// Search - Search results from solr apache
import Search from "../pages/search/List";

// Not found
import NotFound from "../layouts/NotFound";

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Route
const authRoute = {
    path: "/auth",
    name: "Auth",
    icon: UsersIcon,
    children: [
        {
            path: "/auth/sign-in",
            name: "Sign In",
            component: SignIn
        },
        {
            path: "/auth/reset-password",
            name: "Reset Password",
            component: ResetPassword
        },
        {
            path: "/auth/verify/:code",
            name: "Create Password",
            component: Verify
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500
        },
        {
            path: "/auth/maintenance",
            name: "Maintenance",
            component: Maintenance
        }
    ]
};

const dashboardRoute = {
    path: "/",
    name: "Dashboard",
    component: Default,
    icon: SlidersIcon,
    containsHome: true,
    children: null
};

const tenantsRoute = {
    path: "/tenants",
    name: "Tenants",
    component: Tenants,
    icon: ChevronRightIcon,
    children: null
};

const tenantDetails = {
    path: '/tenants/:id/details',
    name: "TenantDetails",
    component: TenantDetails,
    children: null,
    exact: true
};

const relationDetailsRoute = {
    path: [
        '/relations/:relationId/:tab',
        '/relations/:relationId/invoices/:invoiceId',
        '/relations/:relationId/subscriptions/:subscrId',
        '/relations/:relationId/emails/:emailId'
    ],
    name: "RelationDetails",
    component: RelationDetails,
    children: null,
    exact: true,
};

const relationsRoute = {
    path: "/relations",
    name: "Relations",
    component: Relations,
    icon: LinkIcon,
    children: null
};

const subscriptionDetailsRoute = {
    path: [
        '/subscriptions/:relationId/subscriptions/:subscrId',
        '/relations/:relationId/subscriptions/:subscrId'
    ],
    name: "SubscriptionDetails",
    component: RelationDetails,
    children: null,
    exact: true,
    strict: true
};

const invoiceDetailsRoute = {
    path: [
        '/invoices/:relationId/invoices/:invoiceId',
        '/relations/:relationId/invoices/:invoiceId'
    ],
    name: "InvoiceDetails",
    component: RelationDetails,
    children: null,
    exact: true,
    strict: true
};

const emailDetailsRoute = {
    path: [
        '/emails/:relationId/emails/:emailId',
        '/relations/:relationId/emails/:emailId'
    ],
    name: "EmailDetails",
    component: RelationDetails,
    children: null,
    exact: true,
    strict: true
};

const relationNewRoute = {
    path: '/relations/new',
    name: "New Relation",
    component: NewRelation,
    children: null
};

const personRoute = {
    path: "/persons",
    name: "Persons",
    component: Persons,
    children: null
};

const paymentRoute = {
    path: "/payments",
    name: "Payments",
    component: Payments,
    children: null
};

const plansRoute = {
    path: "/plans",
    name: "Plans",
    component: Plans,
    icon: StarIcon,
    children: null
};

const planDetailRoute = {
    path: "/plans/:id/details",
    name: "PlanDetails",
    component: PlanDetails,
    children: null,
    isPreviewPage: true,
};

const planLineGroupsRoute = {
    path: "/plan-line-groups",
    name: "Plan Line Groups",
    component: PlanLineGroups,
    children: null
};

const termsConditionsRoute = {
    path: "/terms-conditions",
    name: "Terms and Conditions",
    component: TermsConditions,
    children: null
};

// plans (Global Admin)
const adminPlansRoute = {
    path: "/admin/plans",
    name: "Plans",
    component: Plans,
    icon: ChevronRightIcon,
    group_access_id: [7],
    children: null
};

// plan details (Global Admin)
const adminPlanDetailRoute = {
    path: "/admin/plans/:id/details",
    name: "PlanDetails",
    component: PlanDetails,
    group_access_id: [7],
    children: null,
    icon: ChevronRightIcon,
};

const invoicesRoute = {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    icon: FileIcon,
    children: null
};

const invoiceNewRoute = {
    path: '/invoices/concept',
    name: "New Invoice",
    component: NewInvoice,
    children: null
};

const subscriptionsRoute = {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    icon: CreditCardIcon,
    children: null
};

const ordersRoute = {
    path: "/orders",
    name: "Orders",
    component: Orders,
    icon: FilePlusIcon,
    children: null
};

// AvChecker
const avCheckRoute = {
    path: "/availability-checker",
    name: "Availability check",
    component: AvCheck,
    icon: UserCheckIcon,
    children: null
};

const avCheckEmbedRoute = {
    path: "/ac",
    name: "Availability checker",
    component: AvCheckEmbed,
    icon: null,
    children: null
};

const fiscalYearDetailsRoute = {
    path: "/tenants/:id/fiscalyears/:fyid/details",
    name: "FiscalYearDetails",
    component: FiscalYearDetails,
    children: null
};

const journalDetailsRoute = {
    path: "/tenants/:id/journals/:jid/details",
    name: "JournalDetails",
    component: JournalDetails,
    children: null
};

const tenantEmailTemplatesRoute = {
    path: "/tenants/:id/templates/email-templates',",
    name: "Email templates",
    component: EmailTemplates,
    children: null
};

const tenantPDFTemplatesRoute = {
    path: "/tenants/:id/templates/pdf-templates',",
    name: "PDF templates",
    component: PDFTemplates,
    children: null
};

const SearchRoute = {
    path: "/search",
    name: "Search",
    component: Search,
    children: null
};

const productsRoute = {
    path: "/products",
    name: "Products",
    component: Products,
    icon: TagIcon,
    children: null
};

// products (Global Admin)
const adminProductsRoute = {
    path: "/admin/products",
    name: "Products",
    component: Products,
    icon: ChevronRightIcon,
    group_access_id: [7],
    children: null
};

const usersRoute = {
    path: "/users",
    name: "Users",
    component: Users,
    icon: UsersIcon,
    children: null
};

// users (Global Admin)
const adminUsersRoute = {
    path: "/admin/users",
    name: "Users",
    component: Users,
    icon: ChevronRightIcon,
    group_access_id: [7],
    children: null
};

const usersApiKeysRoute = {
    path: "/admin/users/:id/api-keys",
    name: "UserApiKeys",
    component: UserApiKeys,
    children: null
};

const l2FiberServicesRoute = {
    path: "/l2fiber-interface",
    name: "L2Fiber interface",
    component: L2FiberServiceProvider,
    icon: WifiIcon,
    children: null
};

const financeRoute = {
    path: "/finance",
    name: "Finance",
    component: FinanceInvoices,
    icon: ChevronRightIcon,
};

const provisioningRoute = {
    path: "/provisioning-dashboard",
    name: "Provisioning dashboard",
    component: Provisioning,
    icon: MonitorIcon
};

const billingRunRoute = {
    path: "/billing-run-tools",
    name: "Billing run",
    component: BillingRunFinance,
    icon: ChevronRightIcon,
};

const billingRunOverviewRoute = {
    path: "/billing-run-overview",
    name: "Billing run overview",
    component: BillingRunOverview,
};

/*const m7PowerToolsRoute = {
    path: "/m7powertools",
    name: "M7 power tools",
    component: M7PowerTools,
    icon: ChevronRightIcon,
};*/

const adminMenus = [
    adminPlansRoute, // plansRoute
    adminProductsRoute, // productsRoute
    adminUsersRoute, // usersRoute
    tenantsRoute,
    //productsRoute,
    //usersRoute,
    financeRoute,
    billingRunRoute,
    //m7PowerToolsRoute
];

const adminToolsRoute = {
    path: "/admin_tools",
    name: "Admin tools",
    component: AdminTools,
    icon: SettingsIcon,
    children: adminMenus
};

const profileRoute = {
    path: "/profile",
    name: "Profile",
    component: Profile
};

const settingsRoute = {
    path: "/settings",
    name: "Settings",
    component: Settings
};

const ActivityLogsRoute = {
    path: "/activity_logs",
    name: "Activity logs",
    component: ActivityLogs,
    icon: ActivityIcon
};

const ActivityLogsFilterRoute = {
    path: "/activity_logs_filter",
    name: "Activity logs filter",
    component: ActivityLogsFilter,
};

const TicketsRoute = {
     path: "/tickets",
     name: "Tickets",
     component: Tickets,
     icon: ClipboardIcon
};

const dumpDataRoute = {
    path: '/admin/data_dump',
    name: "Download data dump",
    component: DownloadDataDump,
    children: null
};

const NotFoundRoute = {
    path: "*",
    name: "Not found",
    component: NotFound,
    children: null
};

const mainMenus = () => {
    if (process.env.REACT_APP_ENV === 'production') {
        return [
            relationsRoute,
            subscriptionsRoute,
            invoicesRoute,
            plansRoute,
            productsRoute,
            usersRoute,

            avCheckRoute, // in test
            provisioningRoute, // new

            adminToolsRoute,
            ActivityLogsRoute
        ]
    } else {
        return [
            relationsRoute,
            subscriptionsRoute,
            invoicesRoute,
            plansRoute,
            productsRoute,
            usersRoute,

            avCheckRoute, // in test
            provisioningRoute, // new
            //ordersRoute, // remove on prod release

            adminToolsRoute,
            ActivityLogsRoute
        ]
    }
};

// Dashboard Routes
export const dashboard = [
    dashboardRoute,
    relationsRoute,
    relationDetailsRoute,
    relationNewRoute,
    personRoute,
    paymentRoute,
    tenantsRoute,
    tenantDetails,
    fiscalYearDetailsRoute,
    journalDetailsRoute,
    tenantEmailTemplatesRoute,
    tenantPDFTemplatesRoute,
    plansRoute,
    planDetailRoute,
    adminPlanDetailRoute,
    planLineGroupsRoute,
    termsConditionsRoute,
    invoicesRoute,
    invoiceDetailsRoute,
    invoiceNewRoute,
    subscriptionsRoute,
    subscriptionDetailsRoute,
    emailDetailsRoute,
    productsRoute,
    usersRoute,
    usersApiKeysRoute,
    l2FiberServicesRoute,
    financeRoute,
    billingRunOverviewRoute,
    provisioningRoute,
    adminToolsRoute,
    profileRoute,
    settingsRoute,
    ActivityLogsRoute,
    ActivityLogsFilterRoute,
    ordersRoute,
    TicketsRoute,
    avCheckRoute,
    SearchRoute,
    dumpDataRoute,
    NotFoundRoute,
];

// Auth & custom Routes
export const page = [
    authRoute,
    avCheckEmbedRoute,
];

// Sidebar Routes displayed
export default mainMenus();
