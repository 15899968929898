import * as types from "../constants";

export function brand(brand) {
  return {
    type: types.BRAND,
    payload: brand
  };
}

export function updateTheme(theme) {
  return {
    type: types.UPDATE_THEME,
    payload: theme
  };
}