import React from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import Select from "react-select";
import DatePicker, {registerLocale} from 'react-datepicker';
import NumberFormat from 'react-number-format';
import {withTranslation} from 'react-i18next';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import {Edit2, PlusSquare, Save, Trash} from "react-feather";
import {
    AddSubscriptionLine,
    AddSubscriptionLinePrice,
    GetPlanSubscriptionLineTypes,
    GetSubscriptionLinePricesOptm,
    RemoveSubscriptionLinePrice,
    UpdateSubscriptionLine,
    UpdateSubscriptionLinePrice,
} from '../../../../controllers/subscriptions';
import {GetProduct, GetProductWithPrice, GetTenantProducts, GetAdminProducts, GetProductWithPriceFilter} from '../../../../controllers/products';
import {GetProducts, GetProductsList} from '../../../../controllers/tenants';
import {loaderToggle} from "../../../../redux/actions/loaderActions";
import Toast from "../../../../components/Toast";
import {formatNumber, formatSeparators, parseDateToSaveFormat} from "../../../../redux/actions/formatActions";
import { nl, enGB, enUS } from "date-fns/locale";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import {subscription} from '../../../../redux/actions/subscriptionActions';
import CONSTANTS from '../../../../services/constants';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class SubscriptionLineForm extends React.Component {
    constructor(props) {
        super(props);

        const { selectedData, id/*, updateDetails, updatedItem */} = this.props;
        const fetchingData = false;

        let callDateStartRefInput = React.createRef();
        let callDateStopRefInput = React.createRef();
        let callDateValidFromRefInput = React.createRef();

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'subscription line',
            subscription_id: id,
            //is_invoiced: selectedData && selectedData.is_invoiced ? selectedData.is_invoiced : false,
            disableLineType: false,
            subscription_line: {
                subscription_line_prices: []
            },

            formSubmitted: false
        };
    }

    handleSelectChange = async (name, data) => {
        let subscription_line = Object.assign({}, this.state.subscription_line), //subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices);
            subscription_line_prices = Object.assign([]);

        //subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices),
        console.log('handleSelectChange - this.props ->', this.props);
        console.log('handleSelectChange - name ->', name);
        console.log('handleSelectChange - data ->', data);
        console.log('handleSelectChange - subscription_line_prices ->', subscription_line_prices);

        subscription_line[name] = data.value;

        if (name === 'product') {
            const {user} = this.props;

            let tenant_id = user.tenant_id ? user.tenant_id : null;
            console.log('handleSelectChange - tenant_id ->', tenant_id);
            const tenant = sessionStorage.getItem('tenant');
            if (tenant && !tenant_id) {
                const tenantData = JSON.parse(tenant);
                tenant_id = tenantData.id;
            }

            //subscription_line.subscription_line_prices = [];
            const {label, value, vat_code, product_type_id/*, price*/} = data;
            let price_excl_vat = 0;
            let price_incl_vat = 0;

            console.log('handleSelectChange - vat_code ->', vat_code);
            console.log('handleSelectChange - product_type_id ->', product_type_id);

            //const selected_product = await this.getProduct(value);

            let selected_product;
            //(async () => {
            //const product_data = await GetProductWithPriceFilter(value);
            const product_data = await GetProductWithPrice(value, tenant_id);
            console.log('handleSelectChange - GetProduct - product_data ->', product_data);

            if (product_data && product_data.data) {
                //if (data[0]) {
                console.log('handleSelectChange - GetProduct - product_data.data.data ->', product_data.data.data);
                selected_product = product_data.data.data[0];
                //}
            }
            //})();

            console.log('handleSelectChange - selected_product ->', selected_product);

            /*if (selected_product && selected_product.price_excl_vat) {
                price_excl_vat = selected_product.price_excl_vat ? selected_product.price_excl_vat : 0;
                price_incl_vat = selected_product.price_incl_vat ? selected_product.price_incl_vat : 0;
            }
            console.log('handleSelectChange - price_excl_vat ->', price_excl_vat);
            console.log('handleSelectChange - price_incl_vat ->', price_incl_vat);*/

            if (selected_product && selected_product.price) {
                price_excl_vat = selected_product.price ? selected_product.price : 0;
                price_incl_vat = null;
            }
            console.log('handleSelectChange - price_excl_vat ->', price_excl_vat);

            if (selected_product && selected_product.product_type_id) {
                // set line type && disable line type -> disableLineType
                const product_types = CONSTANTS.PRODUCT_TYPES; // populateLineType
                console.log('handleSelectChange - product_types ->', product_types);

                await this.populateLineType(selected_product.product_type_id);
            } else {
                this.setState({disableLineType: false, subscription_line_type: null});
            }

            subscription_line.description = label;

            if (this.state.formType && this.state.formType === "Add") {
                subscription_line.subscription_start = new Date();
                //subscription_line_prices[0].price_valid_from = new Date();

                //subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices),
            }

            if (vat_code) {
                (async () => {
                    this.setState({vat_code});

                    if (vat_code.vat_percentage) {
                        this.reCalculatePrice(parseFloat(vat_code.vat_percentage), price_excl_vat, price_incl_vat);
                    }

                    //if (product_type_id) {
                    //    this.populateLineType(parseInt(product_type_id));
                    //} else {
                    //    this.setState({disableLineType: false});
                    //}
                })();
            }
        }

        this.setState({
            subscription_line,
            [name]: data
        });
    };

    getTenantProducts = (id) => {
        (async () => {
            const { ok, data } = await GetTenantProducts(id);
            if (ok) {
                console.log('handleSelectChange - GetTenantProducts - data ->', data);
            }
        })();
    };

    getProductById = (id, tenant_id) => {
        (async () => {
            let param = {};
            param.id = id;
            param.tenant_id = tenant_id;

            const { ok, data } = await GetAdminProducts(param);
            if (ok) {
                console.log('handleSelectChange - GetProducts - data ->', data);
            }
        })();
    };

    getProduct = (id) => {
        (async () => {
            const { ok, data } = await GetProductWithPriceFilter(id);
            if (ok && data) {
                console.log('handleSelectChange - GetProduct - data ->', data);
                if (data[0]) {
                    console.log('handleSelectChange - GetProduct - data[0] ->', data[0]);
                    return data[0];
                }
            }
        })();
    };

    getProductWithPrice = (id, tenant_id) => {
        (async () => {
            const { ok, data } = await GetProductWithPrice(id, tenant_id);
            if (ok) {
                console.log('handleSelectChange - GetProductWithPrice - data ->', data);
            }
        })();
    };

    handleInput({ target: { name, value } }) {
        let subscription_line = Object.assign({}, this.state.subscription_line);

        subscription_line[name] = value;

        this.setState({ subscription_line })
    }

    handleDatePickerChange(date, name, i) {
        let subscription_line = Object.assign({}, this.state.subscription_line);
        if (typeof i === 'number') {
            let subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices);

            subscription_line_prices[i][name] = new Date(date);
            subscription_line.subscription_line_prices = subscription_line_prices;

        } else {
            subscription_line[name] = new Date(date);

        }
        this.setState({ subscription_line });
    }

    handleQuillChange(val) {
        this.setState({ description_long: val })
    }

    clearDateSelected(e, name) {
        //console.log("clearDateSelected - e ->", e);
        //console.log("clearDateSelected - name ->", name);

        if (e) {
            e.preventDefault();
        }

        let subscription_line = Object.assign({}, this.state.subscription_line);

        subscription_line[name] = null;

        this.setState({ subscription_line })
    }

    toggleEdit(i) {
        const { subscription_line } = this.state;
        const { subscription_line_prices } = subscription_line;

        subscription_line_prices[i].editing = !subscription_line_prices[i].editing;
        subscription_line.subscription_line_prices = subscription_line_prices;

        this.setState({ subscription_line })
    }

    priceFormatter = (name, i, data) => {
        const { newVal, value } = this.props.dispatch(formatNumber(name, data.floatValue, data.value));
        console.log('priceFormatter - newVal ->', newVal);
        console.log('priceFormatter - value ->', value);
        console.log('priceFormatter - name ->', name);

        let subscription_line = Object.assign({}, this.state.subscription_line),
            subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices), // subscription_line_prices
            //subscription_line_prices = Object.assign([], subscription_line.current_line_price), // subscription_line_prices
            getVat = this.state.product && this.state.product.vat_code ? parseFloat(this.state.product.vat_code.vat_percentage) : 0;
        //subscription_line_prices[i] = [];

        if (name === 'price_excl_vat') {
            if (newVal === null) {
                subscription_line_prices[i].price_incl_vat = "";
                subscription_line_prices[i].fixed_price = "";

            } else if (newVal !== null || newVal === 0 || newVal < 0) {
                const calculated = parseFloat(newVal) + (parseFloat(newVal) * getVat);
                //console.log('priceFormatter calculated', calculated);
                subscription_line_prices[i].formatted_price_incl_vat = calculated; //calculated && this.hasValue(calculated) ? parseFloat(calculated) : 0;
                subscription_line_prices[i].price_incl_vat = calculated; //newVal && parseFloat(newVal) >= 0 ? parseFloat(newVal) + (parseFloat(newVal) * getVat) : 0;
                subscription_line_prices[i].fixed_price = newVal; //newVal && parseFloat(newVal) >= 0 ? parseFloat(newVal) : 0; //checkCalculated
            } else {
                subscription_line_prices[i].formatted_price_incl_vat = null;
                subscription_line_prices[i].formatted_price_excl_vat = null;
                subscription_line_prices[i].price_incl_vat = null;
                subscription_line_prices[i].price_excl_vat = null;
                subscription_line_prices[i].fixed_price = null
            }
        } else if (name === 'price_incl_vat') {
            if (newVal === null) {
                subscription_line_prices[i].price_excl_vat = "";
                subscription_line_prices[i].fixed_price = "";

            } else if (newVal !== null || newVal === 0 || newVal < 0) {
                const calculated = parseFloat(newVal) / (1 + getVat);
                subscription_line_prices[i].formatted_price_excl_vat = calculated; // calculated && this.hasValue(calculated) ? parseFloat(calculated) : 0;
                subscription_line_prices[i].price_excl_vat = newVal ? Number(newVal) / (1 + getVat) : 0; //newVal && parseFloat(newVal) >= 0 ? parseFloat(newVal) / (1 + getVat) : 0;
                subscription_line_prices[i].fixed_price = newVal ? Number(newVal) / (1 + getVat) : 0; //newVal && parseFloat(newVal) >= 0 ? parseFloat(newVal) / (1 + getVat) : 0;
            } else {
                subscription_line_prices[i].formatted_price_excl_vat = null;
                subscription_line_prices[i].formatted_price_incl_vat = null;
                subscription_line_prices[i].price_excl_vat = null;
                subscription_line_prices[i].price_incl_vat = null;
                subscription_line_prices[i].fixed_price = null
            }
        }

        subscription_line_prices[i][`formatted_${name}`] = newVal || newVal !== null || newVal === 0 || true ? newVal : '';
        subscription_line_prices[i][name] = parseFloat(value);
        subscription_line.subscription_line_prices = subscription_line_prices;

        this.setState({ subscription_line })
    };

    populatePrices() {
        let subscription_line = Object.assign({}, this.state.subscription_line),
            subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices);

        subscription_line_prices && subscription_line_prices.length > 0 && subscription_line_prices.map((line_price, i) => {
            return (
                this.priceFormatter(
                    'price_excl_vat', i, {
                        value: line_price && this.hasValue(line_price.price_excl_vat) ? line_price.price_excl_vat : null,
                        floatValue: line_price && this.hasValue(line_price.price_excl_vat) ? parseFloat(line_price.price_excl_vat) : null
                    }),
                    this.priceFormatter(
                        'price_incl_vat', i, {
                            value: line_price && this.hasValue(line_price.price_incl_vat) ? line_price.price_incl_vat : null,
                            floatValue: line_price && this.hasValue(line_price.price_incl_vat) ? parseFloat(line_price.price_incl_vat) : null
                        }),
                    this.priceFormatter(
                        'margin', i, {
                            value: line_price && this.hasValue(line_price.margin) ? line_price.margin : null,
                            floatValue: line_price && this.hasValue(line_price.margin) ? parseFloat(line_price.margin) : null
                        })
            )
        })
    }

    clearPricesData = () => {
        let price_incl_vat = document.getElementById("price_incl_vat");
        console.log("clearPricesData - price_incl_vat ->", price_incl_vat);
        price_incl_vat.value = "";
        price_incl_vat.defaultValue = "";
        let price_excl_vat = document.getElementById("price_excl_vat");
        price_excl_vat.value = "";
        price_excl_vat.defaultValue = "";
        let margin = document.getElementById("margin");
        margin.value = "";
        margin.defaultValue = "";
    };

    reCalculatePrice(vat_percentage, price_excl_vat_param, price_incl_vat_param) {
        let subscription_line = Object.assign({}, this.state.subscription_line),
            subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices);

        console.log("reCalculatePrice - subscription_line ->", subscription_line);
        console.log("reCalculatePrice - subscription_line_prices ->", subscription_line_prices);
        console.log("reCalculatePrice - price_excl_vat_param ->", price_excl_vat_param);
        console.log("reCalculatePrice - price_incl_vat_param ->", price_incl_vat_param);

        const { selectedData } = this.props;
        //let price_incl_vat = document.getElementById("price_incl_vat");
        //console.log("reCalculatePrice after clearPricesData - price_incl_vat ->", price_incl_vat);

        console.log("reCalculatePrice - subscription_line_prices.length ->", subscription_line_prices.length);
        let lastIndex = subscription_line_prices.length - 1;
        console.log("reCalculatePrice - lastIndex ->", lastIndex);

        if (subscription_line_prices && subscription_line_prices.length === 1) {
            subscription_line_prices[0].formatted_price_excl_vat = parseFloat(price_excl_vat_param);
            subscription_line_prices[0].price_excl_vat = parseFloat(price_excl_vat_param);

            let vat_price = parseFloat(price_excl_vat_param) * parseFloat(vat_percentage);
            subscription_line_prices[0].price_incl_vat = parseFloat(price_excl_vat_param) + vat_price;

            subscription_line_prices[0].price_valid_from = new Date();

        } else if (subscription_line_prices && subscription_line_prices.length > 0) {

            const { lastRowIndex } = this.state;

            console.log("reCalculatePrice - lastRowIndex ->", lastRowIndex);

            if (subscription_line_prices[lastRowIndex]) {
                subscription_line_prices[lastRowIndex].formatted_price_excl_vat = parseFloat(price_excl_vat_param);
                subscription_line_prices[lastRowIndex].price_excl_vat = parseFloat(price_excl_vat_param);

                let vat_price = parseFloat(price_excl_vat_param) * parseFloat(vat_percentage);
                subscription_line_prices[lastRowIndex].price_incl_vat = parseFloat(price_excl_vat_param) + vat_price;
                subscription_line_prices[lastRowIndex].price_valid_from = new Date();
            }
        }
        subscription_line.subscription_line_prices = subscription_line_prices;

        this.setState({subscription_line});
        return subscription_line;
    }

    eventFocus(i, { target: { name } }) {
        if (name === 'price_excl_vat') {
            this.toggleOnFocus(i, 'excl_onFocus', true)
        } else {
            this.toggleOnFocus(i, 'incl_onFocus', true)
        }
    }

    eventBlur(i, { target: { name } }) {
        if (name === 'price_excl_vat') {
            this.toggleOnFocus(i, 'excl_onFocus', false)
        } else {
            this.toggleOnFocus(i, 'incl_onFocus', false)
        }
    }

    toggleOnFocus(i, name, bool) {
        let subscription_line = Object.assign({}, this.state.subscription_line),
            subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices);

        subscription_line_prices[i][name] = bool;
        subscription_line.subscription_line_prices = subscription_line_prices;

        this.setState({ subscription_line })
    }

    addRow() {
        //console.log("Subscription lines - add row");

        let subscription_line = Object.assign({}, this.state.subscription_line),
            subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices),
            getLength = subscription_line_prices ? subscription_line_prices.length : 0;

        // new Date()

        subscription_line_prices[getLength] = {
            //price_valid_from: null,
            price_valid_from: new Date(),
            fixed_price: null,
            formatted_price_excl_vat: null,
            price_excl_vat: null,
            formatted_price_incl_vat: null,
            price_incl_vat: null,
            formatted_margin: null,
            margin: null,
            editing: true
        };

        subscription_line.subscription_line_prices = subscription_line_prices;

        this.setState({
            subscription_line,
            lastRowIndex: getLength
        })
    }

    removeRow(i) {
        const {loader, dispatch/*, update, hide*/} = this.props;

        Swal.fire({
            customClass: {
                container: 'has-cancel',
            },
            title: 'Please confirm!',
            text: `Are you sure you want to delete row ${i + 1} of subscription line prices?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then(res => {
            if (res.value) {
                if (!loader) {
                    dispatch(loaderToggle(true));

                    this.removeRowConfirmed(i, true);

                    dispatch(loaderToggle(false));

                    Swal.fire('Success!', `Row ${i + 1} of subscription line prices removed successfully`, 'success').
                        then((r) => { console.log(r) });
                }
            } else {
                dispatch(loaderToggle(false));
            }
        });
    }

    removeRowConfirmed(i, confirmDelete) {
        let subscription_line = Object.assign({}, this.state.subscription_line),
            subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices);

        if (confirmDelete) {
            if (!subscription_line_prices[i].id) {
                subscription_line_prices.splice(i, 1);
                subscription_line.subscription_line_prices = subscription_line_prices;
                this.setState({ subscription_line })
            } else {
                const getPricesWithId = subscription_line_prices && subscription_line_prices.length > 0 ? subscription_line_prices.filter(price => price.id) : null,
                    { dispatch, loader, update } = this.props;

                if (getPricesWithId && getPricesWithId.length && getPricesWithId.length === 1) {
                    return Toast.fire({ title: 'Please add more line prices to delete this!', icon: 'warning' })
                }

                if (!loader) {
                    dispatch(loaderToggle(true));

                    (async () => {
                        const { ok } = await RemoveSubscriptionLinePrice(subscription_line_prices[i].id);

                        dispatch(loaderToggle(false));

                        if (ok) {
                            subscription_line_prices.splice(i, 1);
                            subscription_line.subscription_line_prices = subscription_line_prices;
                            this.setState({ subscription_line });
                            update();
                        }
                    })()
                }
            }
        }
    }

    populateLineType = (id) => {
        const getLineType = this.state.line_types.find(line => parseInt(line.value) === id);

        console.log("Subscription_lines - populateLineType - getLineType ->", getLineType);

        if (getLineType) {
            this.setState({
                subscription_line_type: getLineType,
                disableLineType: getLineType ? true : false
            });

            console.log("Subscription_lines - populateLineType - disableLineType should be true ->", this.state.disableLineType);
        }
    };

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            console.log('keydown 9 pressed');
            this.ref[name].setOpen(false);
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        //console.log("handleSubmit subscriptionLineForm - e ->", e);
        //return false;

        const subscription_line = Object.assign({}, this.state.subscription_line),
            subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices),
            { product, subscription_line_type, subscription_id } = this.state ? this.state : null,
            { description } = subscription_line ? subscription_line : null;
        let { subscription_stop, /*subscription_line_prices,*/ subscription_start } = subscription_line ? subscription_line : null;

        let hasMissingValidFrom = false,
            hasMissingPrice = false;

        subscription_line_prices && subscription_line_prices.map(line => {
            if (!line.price_valid_from) {
                return hasMissingValidFrom = true;
            } else {
                return false;
            }
        });

        subscription_line_prices && subscription_line_prices.map(line => {
            if (!this.hasValue(line.price_excl_vat) && !this.hasValue(line.margin)) {
                return hasMissingPrice = true;
            } else {
                return false;
            }
        });

        if (!product) {
            return Toast.fire({ title: 'Product field is required!', icon: 'warning' })
        }

        if (!description) {
            return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
        }

        if (!subscription_line_type) {
            return Toast.fire({ title: 'Line type field is required!', icon: 'warning' })
        }

        if (!subscription_start) {
            return Toast.fire({ title: 'Start date field is required!', icon: 'warning' })
        }

        if (hasMissingValidFrom) {
            return Toast.fire({ title: 'Valid from field is required!', icon: 'warning' })
        }

        if (hasMissingPrice) {
            return Toast.fire({ title: 'Either price or margin field is required!', icon: 'warning' })
        }

        // set dates in subscription_line_prices to save format
        if (subscription_line_prices && subscription_line_prices.length > 0) {
            for (let i = 0; i < subscription_line_prices.length; i++) {
                let isCalculated = subscription_line_prices[i].calculated;
                subscription_line_prices[i].price_valid_from = parseDateToSaveFormat(subscription_line_prices[i].price_valid_from);
            }
        }

        // set subscription_start & subscription_stop to save format
        subscription_start = subscription_start ? parseDateToSaveFormat(subscription_start): null;
        subscription_stop = subscription_stop ? parseDateToSaveFormat(subscription_stop): null;

        let isCalculatedSubscriptionLine;
        if (this.props.selectedData && this.props.selectedData.id) {
            isCalculatedSubscriptionLine = subscription_line_prices.filter(r => r.calculated === false);
            console.log("SubscriptionLineForm - handleSubmit - (POST or PUT) isCalculatedSubscriptionLine ->", isCalculatedSubscriptionLine);
        }

        const subscriptionLine = {
            subscription_id,
            product_id: product ? product.value : null,
            subscription_line_type: subscription_line_type.value,
            description,
            subscription_start,
            subscription_stop: subscription_stop,
            subscription_line_prices: isCalculatedSubscriptionLine ? isCalculatedSubscriptionLine : subscription_line_prices ? subscription_line_prices : null
        }, { selectedData, dispatch, loader } = this.props ? this.props : {};

        //console.log("SubscriptionLineForm - handleSubmit - (POST or PUT) subscriptionLine ->", subscriptionLine);

        if (!loader) {
            dispatch(loaderToggle(true));

            if (selectedData) {
                (async () => {
                    const { ok, data } = await UpdateSubscriptionLine(selectedData.id, subscriptionLine);
                    this.handleOk(ok, null, selectedData.id, data);
                })();

            } else {
                (async () => {
                    const { ok, data } = await AddSubscriptionLine(subscription_id, subscriptionLine);
                    this.handleOk(ok, null, subscription_id, data);
                })();

            }
        }
    };

    handleOk = (ok, i, id, data) => {
        console.log("subscriptionLine - form - ok ->", ok);
        console.log("subscriptionLine - form - i ->", i);
        console.log("subscriptionLine - form - id ->", id);
        console.log("subscriptionLine - form - data ->", data);

        console.log("subscriptionLine - form - this.props ->", this.props);
        console.log("subscriptionLine - form - this.state ->", this.state);

        const { hide, update, dispatch } = this.props;

        //dispatch(loaderToggle(false));
        let subscription_line = Object.assign({}, this.state.subscription_line);

        if (id) {
            let subscription_line_prices = Object.assign([], subscription_line.subscription_line_prices);

            if (i) {
                subscription_line_prices[i].id = id;
                subscription_line.subscription_line_prices = subscription_line_prices;
            }

            (async () => {
                await this.setState({ subscription_line })
            })();
        }

        if (ok) {
            if (i !== undefined && i !== null) {
                this.toggleEdit(i);
            } else {
                hide();
            }

            //const { newData } = dispatch(subscription(data));
            //console.log('subscriptionLine handleSuccess newData', newData);

            update('sub_line_update'); //handling data reloading
            //update();

            //this.props.reload();
            //update("update"); //handling data reloading
        }

        dispatch(loaderToggle(false));
    };

    handleSave = (i) => {
        const subscription_line_price = Object.assign({}, this.state.subscription_line.subscription_line_prices[i]),
            { id, price_excl_vat, /*price_incl_vat, */margin, price_valid_from } = subscription_line_price,
            { dispatch, loader } = this.props ? this.props : {};

        if (!price_valid_from) {
            return Toast.fire({ title: 'Valid from field is required!', icon: 'warning' })
        }

        if ((price_excl_vat === null || price_excl_vat === undefined || price_excl_vat === '') && (margin === null || margin === '')) {
            return Toast.fire({ title: 'Either price or margin field is required!', icon: 'warning' })
        }

        let line_price = {
            fixed_price: price_excl_vat,
            margin: this.hasValue(margin) ? margin : null,
            price_valid_from: parseDateToSaveFormat(price_valid_from)
        };

        if (!loader) {
            dispatch(loaderToggle(true));

            if (id) {
                (async () => {
                    const { ok } = await UpdateSubscriptionLinePrice(line_price, id);
                    if (ok) {
                        this.handleOk(ok, i);
                    }
                })();

            } else {
                (async () => {
                    const { ok, data } = await AddSubscriptionLinePrice(line_price, this.state.subscription_line.id);
                    if (ok) {
                        this.handleOk(ok, i, data.data.id);
                    }
                })();

            }

            dispatch(loaderToggle(false));
        }
    };

    hasValue = (value) => {
        if (Math.sign(value) || Math.sign(value) === 0 || Math.sign(value) < 0) {
            return true;
        } else {
            return false;
        }
    };

    componentDidMount() {
        const { selectedData, dispatch } = this.props,
            subscription_line = selectedData ? selectedData : null;

        let subscriptionLineObj = Object.assign({}, subscription_line);

        console.log("Subscription_lines - componentDidMount - subscriptionLineObj ->", subscriptionLineObj);
        console.log("Subscription_lines - componentDidMount - this.props ->", this.props);

        if (!this.fetchingData) {
            this.fetchingData = true;

            dispatch(loaderToggle(true));

            (async () => {
                const [product, type, prices] = await Promise.all([
                    //GetProducts(),
                    GetProductsList(),
                    GetPlanSubscriptionLineTypes(),
                    GetSubscriptionLinePricesOptm(subscription_line ? subscription_line.id : null)
                ]);

                //console.log("Subscription_lines - componentDidMount - prices ->", prices);

                let products = [];
                let line_types = [];
                let subscription_line_prices = [];

                await (() => {
                    if (product.ok) {
                        product.data && product.data.data &&
                        product.data.data.map(
                            ({description, id, vat_code, product_type_id, price}) => {
                                return products.push({
                                    label: description,
                                    value: id,
                                    vat_code,
                                    product_type_id,
                                    price: price
                                })
                            });

                        this.setState({products})
                    }

                    if (type.ok) {
                        type.data && type.data.data &&
                        type.data.data.map(({line_type, id}) => {
                            if (line_type === 'YRC' || line_type === 'QRC' ||
                                line_type === 'MRC' || line_type === 'NRC' ||
                                line_type === 'Deposit') {
                                return line_types.push({
                                    label: line_type, value: id
                                })
                            } else {
                                return false;
                            }
                        });

                        this.setState({line_types})
                    }

                    console.log("subscription_line_prices - prices ->", prices);
                    if (prices.ok) {
                        subscription_line_prices = prices.data && prices.data.data ? prices.data.data : [];
                        subscriptionLineObj.subscription_line_prices = subscription_line_prices;
                        this.setState({
                            subscription_line: subscriptionLineObj
                        });
                    }

                    if (selectedData) {
                        const product = products && products.length > 0
                            ? products.find(item => parseInt(item.value) ===
                                parseInt(subscriptionLineObj.product_id))
                            : null;

                        let subscription_line_type = line_types &&
                        line_types.length > 0
                            ? line_types.find(item => parseInt(item.value) ===
                                parseInt(
                                    subscriptionLineObj.subscription_line_type))
                            : null;

                        //console.log("subscription_lines - subscription_line_type - line_types ->", line_types);
                        console.log("subscription_lines - subscription_line_type ->", subscription_line_type);
                        //console.log("subscription_lines - subscription_line_type - product ->", product);

                        if (!subscription_line_type && product && product.product_type_id) {
                            subscription_line_type = line_types &&
                            line_types.length > 0 ? line_types.find(
                                item => parseInt(item.value) ===
                                    parseInt(product.product_type_id)) : null;
                        }

                        //console.log("subscription_lines - subscription_line_type (product_type_id) ->", subscription_line_type);

                        this.setState({
                            subscription_line: subscriptionLineObj,
                            product: product ? product : {},
                            vat_code: product && product.vat_code
                                ? product.vat_code
                                : {},
                            subscription_line_type: subscription_line_type
                                ? subscription_line_type
                                : {},
                            disableLineType: !!(product &&
                                product.product_type_id &&
                                subscription_line_type)
                        });

                        this.populatePrices();

                    } else {
                        let subscription_line = Object.assign({});
                        subscription_line.subscription_start = new Date();

                        this.setState({subscription_line: subscription_line});
                        this.addRow();
                    }

                })();

                this.setState({hasLoaded: true});
                this.fetchingData = false;

            })();

            dispatch(loaderToggle(false));

        }
    }

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    render() {
        //console.log("Subscription_lines - form - render - state ->", this.state);

        const { formType, formName, products, product, line_types, subscription_line_type, vat_code, hasLoaded, disableLineType, subscription_line/*, is_invoiced*/ } = this.state,
            { description, subscription_start, subscription_stop, subscription_line_prices, is_invoiced, current_line_price } = subscription_line,
            { selectedData, show, hide, loader, dispatch } = this.props;

        let { serial } = selectedData ? selectedData : {},
            { json_data } = serial ? serial : {};

        let my_serial = String.fromCharCode(8212);
        let my_mac = String.fromCharCode(8212);
        if (json_data && json_data.serial && (json_data.serial.serial || json_data.serial.mac)) {
            my_serial = json_data.serial && json_data.serial.serial ? json_data.serial.serial : String.fromCharCode(8212);
            my_mac = json_data.serial && json_data.serial.mac ? json_data.serial.mac : String.fromCharCode(8212);
        }

        //console.log("Subscription_lines - form - render - is_invoiced ->", is_invoiced);
        //console.log("Subscription_lines - form - render - disableLineType ->", disableLineType);
        //console.log("Subscription_lines - form - render - subscription_line_type ->", subscription_line_type);
        //console.log("Subscription_lines - form - render - product ->", product);
        //console.log("Subscription_lines - form - render - selectedData ->", selectedData);
        //console.log("Subscription_lines - form - render - serial ->", serial);
        //console.log("Subscription_lines - form - render - json_data ->", json_data);
        //console.log("Subscription_lines - form - render - my_serial ->", my_serial);
        //console.log("Subscription_lines - form - render - my_mac ->", my_mac);

        const {t} = this.props;

        return (
            <React.Fragment>
                { (!loader || hasLoaded) && <Modal className="form-subscription-lines mw-800" isOpen={show} toggle={hide} centered>
                    <Form onSubmit={(e) => this.handleSubmit(e)}>
                        <ModalHeader>
                            <span>{formType} {formName}</span>
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md="11">
                                    <FormGroup className="row align-items-center mb-5px">
                                        <Col xs="5" style={{maxWidth:'193.95px'}}>
                                            <Label className="mb-0" for="product">Product:</Label>
                                        </Col>
                                        <Col xs="7">
                                            <Select
                                                id="product"
                                                name="product"
                                                options={products}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                placeholder="Product"
                                                value={product}
                                                onChange={this.handleSelectChange.bind(this, 'product')}
                                                maxMenuHeight={300}
                                                isDisabled={!!is_invoiced}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row align-items-center mb-5px">
                                        <Col xs="5" style={{maxWidth:'193.95px'}}>
                                            <Label className="mb-0" for="description">Description:</Label>
                                        </Col>
                                        <Col xs="7">
                                            <Input
                                                id="description"
                                                name="description"
                                                placeholder="Description"
                                                value={description ? description : ''}
                                                onChange={(e) => this.handleInput(e)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="7">
                                    <FormGroup className="row align-items-center mb-5px">
                                        <Col xs="5" style={{maxWidth:'193.95px'}}>
                                            <Label className="mb-0" for="subscription_line_type">Line type:</Label>
                                        </Col>
                                        <Col xs="7" data-is-disabled={disableLineType}>
                                            <Select
                                                id="subscription_line_type"
                                                name="subscription_line_type"
                                                options={line_types}
                                                placeholder="Line type"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={subscription_line_type}
                                                onChange={this.handleSelectChange.bind(this, 'subscription_line_type')}
                                                maxMenuHeight={300}
                                                //isDisabled={disableLineType || !!is_invoiced}
                                                isDisabled={!!subscription_line_type || !!is_invoiced}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row align-items-center mb-5px">
                                        <Col xs="5" style={{maxWidth:'193.95px'}}>
                                            <Label className="mb-0" for="subscription_start">Subscription start:</Label>
                                        </Col>
                                        <Col xs="7">
                                            <InputGroup>
                                                <DatePicker
                                                    locale={this.getLocale()}
                                                    id="subscription_start"
                                                    wrapperClassName="w-100"
                                                    className="form-control"
                                                    name="subscription_start"
                                                    dateFormat={t("date_format_raw")}
                                                    autoComplete="off"
                                                    placeholderText="Start"
                                                    selected={subscription_start ? new Date(subscription_start) : null}
                                                    onChange={(e) => this.handleDatePickerChange(e, 'subscription_start')}
                                                    //ref={this.callDateStartRefInput}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    maxDate={subscription_stop ? new Date(subscription_stop) : null}
                                                    readOnly={is_invoiced}
                                                />
                                                { !is_invoiced && <InputGroupAddon className="clear-btn" addonType="append">
                                                    <a href="#" className="danger icon-delete" onClick={(e) => { this.clearDateSelected(e, 'subscription_start')}}>
                                                        <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                                                    </a>
                                                </InputGroupAddon> }
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row align-items-center mb-5px">
                                        <Col xs="5" style={{maxWidth:'193.95px'}}>
                                            <Label className="mb-0" for="subscription_stop">Subscription end:</Label>
                                        </Col>
                                        <Col xs="7">
                                            <InputGroup>
                                                <DatePicker
                                                    locale={this.getLocale()}
                                                    id="subscription_stop"
                                                    wrapperClassName="w-100"
                                                    className="form-control"
                                                    name="subscription_stop"
                                                    dateFormat={t("date_format_raw")}
                                                    autoComplete="off"
                                                    placeholderText="End"
                                                    selected={subscription_stop ? new Date(subscription_stop) : null}
                                                    onChange={(e) => this.handleDatePickerChange(e, 'subscription_stop')}
                                                    ref={this.callDateStopRefInput}
                                                    minDate={subscription_start ? new Date(subscription_start) : null}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                />
                                                <InputGroupAddon className="clear-btn" addonType="append">
                                                    <a href="#" className="danger icon-delete" onClick={(e) => { this.clearDateSelected(e, 'subscription_stop')}}>
                                                        <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                                                    </a>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md="4" className="fs-13">
                                    <div className="selected-none-editable-data mt-4">
                                        {selectedData && hasLoaded &&
                                        <React.Fragment>
                                            <FormGroup className="row align-items-center mb-1">
                                                <Col xs="5">
                                                    <Label className="mb-0">Serial:</Label>
                                                </Col>
                                                <Col xs="7" className="text-ellipsis" title={`${my_serial}`}>
                                                    {my_serial}</Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-1">
                                                <Col xs="5">
                                                    <Label className="mb-0">Mac address:</Label>
                                                </Col>
                                                <Col xs="7" className="text-ellipsis" title={`${my_mac}`}>
                                                    {my_mac}</Col>
                                            </FormGroup>
                                        </React.Fragment>}
                                        {vat_code ?
                                            <FormGroup className="row align-items-center mb-0">
                                                <Col xs="5">
                                                    <Label className="mb-0">VAT:</Label>
                                                </Col>
                                                <Col xs="7" className="text-ellipsis" title={`${vat_code.description
                                                    ? vat_code.description
                                                    : vat_code.vat_percentage
                                                        ? `${parseFloat(vat_code.vat_percentage) * 100} %`
                                                        : String.fromCharCode(8212)}`}>
                                                    {vat_code.description
                                                        ? vat_code.description
                                                        : vat_code.vat_percentage
                                                            ? `${parseFloat(vat_code.vat_percentage) * 100} %`
                                                            : String.fromCharCode(8212)}
                                                </Col>
                                            </FormGroup>
                                            :
                                            <FormGroup className="row align-items-center mb-0">
                                                <Col xs="5">
                                                    <Label className="mb-0">VAT:</Label>
                                                </Col>
                                                <Col xs="7" className="text-ellipsis">
                                                    {String.fromCharCode(8212)}
                                                </Col>
                                            </FormGroup>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col xs="12">
                                    <h5>Subscription line prices</h5>
                                    <Table className="mb-0 table-subscription-line-prices-edit">
                                        <thead>
                                        <tr>
                                            <th data-label="Valid from" style={{ width: '25%' }}>Valid from</th>
                                            <th data-label="Price excl. VAT" style={{ width: '21%' }}>Price excl. VAT</th>
                                            <th data-label="Price incl. VAT" style={{ width: '21%' }}>Price incl. VAT</th>
                                            <th data-label="Margin" style={{ width: '21%' }}>Margin</th>
                                            <th data-label="Unit price" style={{ width: '12%', minWidth: '75px' }}> </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {subscription_line_prices && subscription_line_prices.length > 0 && subscription_line_prices.map(({ price_valid_from, price_excl_vat, price_incl_vat, margin, editing }, i) => (
                                            <tr key={i}>
                                                {!editing
                                                    ? <React.Fragment>
                                                        <td data-label="Valid from">{price_valid_from ? t("date_format", { date: new Date(price_valid_from)}) : String.fromCharCode(8212)}</td>
                                                        <td data-label="Price excl. VAT">{(!this.hasValue(margin, 'margin') || margin === null) && this.hasValue(price_excl_vat, 'price_excl_vat') ? t("currency_format", { number: Number(parseFloat(price_excl_vat)) }) : String.fromCharCode(8212)}</td>
                                                        <td data-label="Price incl. VAT">{this.hasValue(price_incl_vat, 'price_incl_vat') ? t("currency_format", { number: Number(parseFloat(price_incl_vat)) }) : String.fromCharCode(8212)}</td>
                                                        <td data-label="Margin">{this.hasValue(margin, 'margin') && price_excl_vat === null ? `${dispatch(formatSeparators(margin, true)).formatted}` : String.fromCharCode(8212)}</td>
                                                    </React.Fragment>
                                                    : <React.Fragment>
                                                        <td data-label="Valid from">
                                                            <DatePicker
                                                                locale={this.getLocale()}
                                                                wrapperClassName="w-100"
                                                                className="form-control"
                                                                name="price_valid_from"
                                                                dateFormat={t("date_format_raw")}
                                                                autoComplete="off"
                                                                placeholderText="Valid from"
                                                                //selected={subscription_line_prices[i].price_valid_from ? new Date(new Date(subscription_line_prices[i].price_valid_from)) : null}
                                                                selected={subscription_line_prices[i].price_valid_from ? new Date(new Date(subscription_line_prices[i].price_valid_from)) : subscription_start ? new Date(subscription_start) : null}
                                                                onChange={(e) => this.handleDatePickerChange(e, 'price_valid_from', i)}
                                                                //ref={`price_valid_from${i}`}
                                                                //ref={this.callDateValidFromRefInput}
                                                                //minDate={subscription_start ? new Date(new Date(subscription_start)) : new Date()}
                                                                //onKeyDown={(e) => this.handleKeyDown(e, `price_valid_from${i}`)}
                                                                onKeyDown={(e) => e.preventDefault()}
                                                            />
                                                        </td>
                                                        <td data-label="Price excl. VAT">
                                                            {<NumberFormat
                                                                className="form-control"
                                                                id="price_excl_vat"
                                                                name="price_excl_vat"
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                //isAllowed={true}
                                                                thousandsGroupStyle="thousand"
                                                                decimalScale={2}
                                                                value={(this.hasValue(subscription_line_prices[i].price_excl_vat)) || subscription_line_prices[i].price_excl_vat === 0 ? subscription_line_prices[i].price_excl_vat : ""}
                                                                displayType="input"
                                                                placeholder="Price excl. VAT"
                                                                onValueChange={!subscription_line_prices[i]['incl_onFocus'] ? (e) => this.priceFormatter('price_excl_vat', i, e) : () => { }}
                                                                onFocus={(e) => this.eventFocus(i, e)}
                                                                onBlur={(e) => this.eventBlur(i, e)}
                                                                allowLeadingZeros={true}
                                                                disabled={
                                                                    subscription_line_prices[i] && (this.hasValue(subscription_line_prices[i].fixed_price) && this.hasValue(subscription_line_prices[i].margin) && subscription_line_prices[i].fixed_price)
                                                                        ? false
                                                                        : !!(subscription_line_prices[i] && this.hasValue(subscription_line_prices[i].margin) && subscription_line_prices[i].margin && !subscription_line_prices[i].fixed_price) || subscription_line_prices[i].margin === 0}
                                                            />}
                                                        </td>
                                                        <td data-label="Price incl. VAT">
                                                            <NumberFormat
                                                                className="form-control"
                                                                name="price_incl_vat"
                                                                id="price_incl_vat"
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                //isAllowed={true}
                                                                thousandsGroupStyle="thousand"
                                                                decimalScale={2}
                                                                value={subscription_line_prices[i] && this.hasValue(subscription_line_prices[i].price_incl_vat) ? subscription_line_prices[i].price_incl_vat : ""}
                                                                displayType="input"
                                                                placeholder="Price incl. VAT"
                                                                onValueChange={subscription_line_prices[i] && !subscription_line_prices[i]['excl_onFocus'] ? (e) => this.priceFormatter('price_incl_vat', i, e) : () => { }}
                                                                onFocus={(e) => this.eventFocus(i, e)}
                                                                onBlur={(e) => this.eventBlur(i, e)}
                                                                allowLeadingZeros={true}
                                                                disabled={
                                                                    subscription_line_prices[i] && (this.hasValue(subscription_line_prices[i].fixed_price) && this.hasValue(subscription_line_prices[i].margin) && subscription_line_prices[i].fixed_price)
                                                                        ? false
                                                                        : !!(subscription_line_prices[i] && this.hasValue(subscription_line_prices[i].margin) && subscription_line_prices[i].margin && !subscription_line_prices[i].fixed_price) || subscription_line_prices[i].margin === 0}
                                                            />
                                                        </td>
                                                        <td data-label="Margin">
                                                            <InputGroup>
                                                                <NumberFormat
                                                                    className="form-control"
                                                                    name="margin"
                                                                    id="margin"
                                                                    thousandSeparator={false}
                                                                    decimalSeparator=","
                                                                    thousandsGroupStyle="thousand"
                                                                    decimalScale={2}
                                                                    value={this.hasValue(subscription_line_prices[i].margin) ? subscription_line_prices[i].margin : ''}
                                                                    displayType="input"
                                                                    //isAllowed={true}
                                                                    allowNegative={true}
                                                                    placeholder="Margin"
                                                                    onValueChange={(e) => this.priceFormatter('margin', i, e)}
                                                                    allowLeadingZeros={true}
                                                                    disabled={
                                                                        subscription_line_prices[i] && (this.hasValue(subscription_line_prices[i].fixed_price) && this.hasValue(subscription_line_prices[i].margin) && subscription_line_prices[i].margin)
                                                                            ? false
                                                                            : !!(subscription_line_prices[i] && subscription_line_prices[i].fixed_price) || subscription_line_prices[i].fixed_price === 0}
                                                                />
                                                                <InputGroupAddon addonType="append">%</InputGroupAddon>
                                                            </InputGroup>
                                                        </td>
                                                    </React.Fragment>
                                                }
                                                <td className="text-right">
                                                    {selectedData && <React.Fragment>
                                                        {!editing
                                                            ? <Edit2 size="26" className="p-1 hover-pointer" onClick={() => this.toggleEdit(i)} />
                                                            : <Save size="26" className="p-1 hover-pointer" onClick={() => this.handleSave(i)} />
                                                        }
                                                        {subscription_line_prices && subscription_line_prices.length > 1 &&
                                                        <Trash size="26" className="p-1 hover-pointer" onClick={() => this.removeRow(i)} />}
                                                    </React.Fragment>}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </Col>
                                {selectedData && hasLoaded && <Col xs="12" className="d-flex justify-content-end">
                                    <PlusSquare size="26" className="p-1 hover-pointer mr-12px mt-2" onClick={() => this.addRow()} />
                                </Col>}
                            </Row>
                        </ModalBody>
                        <ModalFooter className="justify-content-between">
                            <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                            <Button color="primary">
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(withTranslation()(withRouter(SubscriptionLineForm)));
