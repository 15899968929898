import * as types from "../constants";

export default function reducer(state = null, actions) {
  const { payload, type } = actions
  switch (type) {
    case types.BRAND: 
      return Object.assign({}, payload)
    case types.UPDATE_THEME:
      return Object.assign({}, state, {
        theme: {          
          ...payload
        }
      })
    default:
      return state;
  }
}