import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import AuthService from '../../services/authService';
import ButtonSpinner from '../../components/ButtonSpinner';
import Toast from '../../components/Toast';

const Auth = new AuthService();

class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    handleInputChange({ target: { name, value }}) {
        let user = Object.assign({}, this.state.user);
        user[name] = value;
        this.setState({ user })
    }

    handleSubmit(e) {
        e.preventDefault();

        const { isLoading, user } = this.state;

        if (!isLoading) {
            (async () => {
                this.setState({ isLoading: true });
                
                await Auth.login(user)
                    .then(res => {
                        const query = this.props.location.search.split('redirectUrl=');
                        console.log("Auth login - query ->", query);
                        let path = '/';
                        
                        if (query && query[1]) {
                            console.log("Auth login - query[1] ->", query[1]);
                            path = `${query[1]}`
                        }
                        window.location.href = path;

                    })
                    .catch(({ data, status }) => {
                        const title = status === 503 ? 'Sorry! Maintenance in progress' : data && data.message ? data.message : 'Your username/password is incorrect.',
                            icon = status === 503 ? 'warning' : 'error';
                            
                        Toast.fire({ title, icon });
                        this.setState({ isLoading: false })
                    })
                })().catch(err => {
                    this.setState({ isLoading: false });
                    console.log(err);
                })
        }
    }

    componentDidMount() {
        if (Auth.loggedIn()) {
            this.props.history.replace('/')
        }
    }

    render() {
        const { isLoading, user } = this.state,
            { username, password } = user ? user : {};

        return (
            <React.Fragment>
                <Helmet>
                    <link rel="shortcut icon" type="image/png" href={ `${ process.env.PUBLIC_URL }/favicon.ico` } />
                    <link rel="shortcut icon" sizes="196x196" href={ `${ process.env.PUBLIC_URL }/favicon.ico` } />
                    <link rel="apple-touch-icon" href={ `${ process.env.PUBLIC_URL }/favicon.ico` } />
                </Helmet>
                <Card>
                    <CardBody>
                        <div className="m-sm-4">
                            <Form className="login" onSubmit={(e) => this.handleSubmit(e)} autoComplete="off">
                                <FormGroup>
                                    <Label>Username</Label>
                                    <Input
                                        bsSize="lg"
                                        autoComplete="off"
                                        type="text"
                                        name="username"
                                        value={ username ? username : '' }
                                        placeholder="Enter your username"
                                        onChange={(e) => this.handleInputChange(e)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Password</Label>
                                    <Input
                                        bsSize="lg"
                                        type="password"
                                        autoComplete="off"
                                        name="password"
                                        value={ password ? password : '' }
                                        placeholder="Enter your password"
                                        onChange={(e) => this.handleInputChange(e)}
                                    />
                                    <small className="cont-forgot-btn">
                                        <Link to="/auth/reset-password">Forgot password?</Link>
                                    </small>
                                </FormGroup>
                                <div className="text-center mt-3">
                                    <Button color="primary" size="lg" disabled={ isLoading }>
                                        Sign in
                                        { isLoading && <ButtonSpinner /> }
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

export default SignIn;
