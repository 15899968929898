import React from "react";
import {connect} from "react-redux";
import Swal from "sweetalert2";
import {RemoveProvisioningTemplates} from "../../../controllers/provisioning";
import Toast from "../../../components/Toast";
import {loaderToggle} from "../../../redux/actions/loaderActions";

class RemoveProvisioningTemplateForm extends React.Component {
    componentDidMount() {
        const { loader, selectedData, dispatch, hide, update } = this.props;

        Swal.fire({
            title: 'Please confirm!',
            text: 'Are you sure you want to delete this provisioning template?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            reverseButtons: true
        }).then(res => {
            if (res.value) {
                if (!loader) {
                    dispatch(loaderToggle(true));

                    console.log('RemoveProvisioningTemplateForm - remove - selectedData.id', selectedData.id);

                    (async () => {
                        const { ok } = await RemoveProvisioningTemplates(selectedData.id);
                        if (ok) {
                            dispatch(loaderToggle(false));

                            /*let table = document.querySelector('table');
                            console.log('RemoveProvisioningTemplateForm - remove - table', table);
                            console.log('RemoveProvisioningTemplateForm - remove - selectedDataRow', this.props.selectedDataRow);
                            if (table && this.props.selectedDataRow) {
                                table.deleteRow(this.props.selectedDataRow);
                            }*/

                            update("prov_template_update");

                            await Toast.fire({
                                title: 'Provisioning template deleted successfully',
                                icon: 'success'
                            });

                        } else {
                            await Toast.fire({
                                title: 'Deleted status is not available',
                                icon: 'warning'
                            });
                        }
                        
                        dispatch(loaderToggle(false));
                        hide();

                    })();
                }
            } else {
                hide();
            }
        });
    }

    render() {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(RemoveProvisioningTemplateForm);
