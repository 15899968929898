import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import DatePicker, {registerLocale} from 'react-datepicker';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Form, Input, CustomInput } from "reactstrap";
import { AddAccountingPeriod, UpdateAccountingPeriod } from '../../../../controllers/accounting_periods';
import { loaderToggle } from "../../../../redux/actions/loaderActions";
import { validateDate } from "../../../../redux/actions/validateActions";
import Toast from "../../../../components/Toast";
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../../../redux/actions/formatActions';
import {withTranslation} from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class AccountingPeriodForm extends React.Component {
    constructor(props) {
        super(props);

        //tabs[activeTab].component.props.tenantName
        const { selectedData } = this.props;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'accounting period',
            accounting_period: selectedData ? selectedData : {
                is_closed: false
            }
        }
    }

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    handleInputChange({ target: { name, value }}) {
        let accounting_period = Object.assign({}, this.state.accounting_period);
        
        accounting_period[name] =  value;

        this.setState({ accounting_period })
    }

    handleDatePickerChange(name, date) {
        let accounting_period = Object.assign({}, this.state.accounting_period);
        const { newDate } = this.props.dispatch(validateDate(date));
        
        accounting_period[name] =  newDate;
        
        this.setState({ accounting_period })
    }

    handleCheckChange({ target: { name, checked } }) {  
        let accounting_period = Object.assign({}, this.state.accounting_period);
        
        accounting_period[name] =  checked;

        this.setState({ accounting_period })
    }

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false)
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        let accounting_period = Object.assign({}, this.state.accounting_period);
        const { description, date_from, date_to } = accounting_period,
            { id, id2, selectedData, loader, dispatch } = this.props;

        //Validations
        if (!description) {
            return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
        }

        if (!date_from) {
            return Toast.fire({ title: 'Date from field is required!', icon: 'warning' })
        }

        if (!date_to) {
            return Toast.fire({ title: 'Date to field is required!', icon: 'warning' })
        }

        accounting_period.tenant_id = id;
        accounting_period.fiscal_year_id = id2;
        accounting_period.date_from = parseDateToSaveFormat(date_from);
        accounting_period.date_to = parseDateToSaveFormat(date_to);

        if (!loader) {
            dispatch(loaderToggle(true));
        
                if (selectedData) {
                    (async () => {                        
                        const { ok } = await UpdateAccountingPeriod(selectedData.id, accounting_period);

                        this.handleOk(ok)
                    })()
                } else {
                    (async () => {
                        const { ok } = await AddAccountingPeriod(accounting_period);

                        this.handleOk(ok)
                    })()
                }
            }
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update()
        }
    };

    render() {
        const { show, hide, t } = this.props,
            { formType, formName, accounting_period } = this.state,
            { description, date_from, date_to, is_closed } = accounting_period ? accounting_period : null;

        return (
            <Modal className="form-subscription-lines" isOpen={ show } toggle={ hide } centered>
                <Form onSubmit={ (e) => this.handleSubmit(e) }>
                    <ModalHeader>
                        <span>{ formType } { formName }</span>
                    </ModalHeader>
                    <ModalBody key="0" className="mt-3 mb-3">
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Description:</label>
                            </Col>
                            <Col md={9}>
                                <Input 
                                    name="description" 
                                    placeholder="Description" 
                                    value={ description ? description : '' } 
                                    onChange={ (e) => this.handleInputChange(e) } 
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Date from:</label>
                            </Col>
                            <Col md={9}>
                                <DatePicker
                                    locale={this.getLocale()}
                                    id="date_from"
                                    className="form-control"
                                    name="date_from"
                                    dateFormat={t("date_format_raw")}
                                    autoComplete="off"
                                    placeholderText="Date from"
                                    selected={ date_from ? new Date(date_from) : null }
                                    showMonthDropdown
                                    showYearDropdown
                                    onChange={ this.handleDatePickerChange.bind(this, 'date_from') }
                                    ref="date_from"
                                    onKeyDown={(e) => e.preventDefault()}
                                    maxDate={date_to ? new Date(date_to) : null}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Date to:</label>
                            </Col>
                            <Col md={9}>
                                <DatePicker
                                    locale={this.getLocale()}
                                    id="date_to"
                                    className="form-control"
                                    name="date_to"
                                    dateFormat={t("date_format_raw")}
                                    autoComplete="off"
                                    placeholderText="Date to"
                                    selected={ date_to ? new Date(date_to) : null }
                                    showMonthDropdown
                                    showYearDropdown
                                    onChange={ this.handleDatePickerChange.bind(this, 'date_to') }
                                    ref="date_to"
                                    onKeyDown={(e) => e.preventDefault()}
                                    minDate={date_from ? new Date(date_from) : null}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={{ size: 'auto', offset: 3 }}>
                                <CustomInput 
                                    id="is_closed"
                                    className="mt-1"
                                    type="checkbox"
                                    label="Closed"
                                    name="is_closed"
                                    defaultChecked={is_closed}
                                    onChange={ (e) => { this.handleCheckChange(e) } }
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <Button className="btn btn-danger" onClick={ hide } data-dismiss="modal">
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button color="primary">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = store => ({
    loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(AccountingPeriodForm)));