import React, {useState} from 'react';
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { createSelector } from 'reselect';
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import Idle from "react-idle";
import withAuth from '../services/withAuth';
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Breadcrumbs from "../components/Breadcrumbs";
import IdleComponent from "../components/IdleComponent";
//import useIdle from "../hooks/useIdleTimer";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { GetUser } from '../controllers/users';
import { GetMyTenants, GetBrand, GetTenantLists } from '../controllers/tenants';
import { user } from '../redux/actions/userActions';
import { tenants } from '../redux/actions/tenantActions';
import { brand } from '../redux/actions/brandActions';
import { updateError } from '../redux/actions/errorActions';
import Error from '../pages/auth/Error';
import AuthService from '../services/authService';
import Loader from '../components/Loader';
import Toast from "../components/Toast";
import {toggleSearchResults} from '../redux/actions/solrSearchActions';

const Auth = new AuthService(),
      rootStyle = document.documentElement.style;

class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      children: this.props.children,
      isError: false,
      loading: true,
      breadcrumbs: true,
      pagePath: null,
      minutesBeforeInactive: 30,
    };
  }

  checkHasError = () => {
    if (!Auth.checkIsError()) {
      setTimeout(this.checkHasError, 100);
    } else {
      const error = JSON.parse(sessionStorage.getItem('error'));

      this.setState({
        isError: true,
        error
      })
    }
  };

  handleLeavePage = () => {
    Auth.updateError(false);
  };

  handleIdle = async (idle) => {
    if (idle) {
      //await Toast.fire({title: 'Logged-out because of inactivity.', icon: 'info'}).then();
      Auth.logout('Logged-out because of inactivity.');
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleLeavePage);

    let { history, dispatch } = this.props;

    this.checkHasError();
    this.setState({
      pathname: history.location.pathname,
    });

    const maintenance = localStorage.getItem('maintenance');
    if (maintenance) {
      window.location.href = '/auth/maintenance';

    } else {
        (async () => {
          const id = Auth.getProfile().sub,
              {ok, data} = await GetUser(id);

          if (ok) {
            const datas = data.data, params = {
              page: 0, offset: 10 //0
            };

            dispatch(user(datas));

            const sessionTenant = sessionStorage.getItem('tenant')
                ? JSON.parse(sessionStorage.getItem('tenant'))
                : null, tenant_id = sessionTenant && sessionTenant.id
                ? sessionTenant.id
                : datas.last_tenant_id, [tenant, getBrand] = await Promise.all(
                [
                  //GetMyTenants(params),
                  GetTenantLists(),
                  GetBrand(null, tenant_id)
                ]);

            await (() => {
              if (tenant.ok) {
                const myTenants = tenant.data.data;

                dispatch(tenants(myTenants));

                if (tenant_id && myTenants) {
                  myTenants.map((item, index) => {
                    if (item && item.id === tenant_id) {
                      if (datas) {
                        datas.tenant = item;
                        dispatch(user(datas));
                        return datas;
                      }
                    }
                    return null;
                  })
                }
              }

              if (getBrand.ok) {
                let brandData = Object.assign({}, getBrand.data.data);
                if (brandData && brandData.theme) {
                  brandData.theme = JSON.parse(brandData.theme)
                } else {
                  brandData.theme = this.props.theme.currentTheme
                }

                dispatch(brand(brandData));
              }
            })();
          }

          //await this.setState({loading: false});
          if (this.state.loading === true) {
            this.setState({loading: false});
          }

      })();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage);

    const theme = this.props && this.props.brand && this.props.brand.theme ? this.props.brand.theme : null;
    if (theme) {
      Object.keys(theme).map((key) => {
        return rootStyle.removeProperty(`--${ key }`)
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    window.removeEventListener('beforeunload', this.handleLeavePage);

    let {history, user, brand, dispatch} = this.props;

    const isPlanPreviewEnabled = sessionStorage.getItem('planPreview');

    if (history.location && history.location.pathname) {
      let pathDetails = history.location.pathname.split("/");

      let remove_root_property = true;
      if (pathDetails && pathDetails.length > 0) {
        if (pathDetails[1] &&
            (pathDetails[1] === "plans" || (pathDetails[1] === "admin" && pathDetails[2] === "plans")) &&
            pathDetails[3] && pathDetails[3] === "details") {
          remove_root_property = false;
        }

        let pagePath;
        for (let i = 0; i < pathDetails.length; i++) {
          pagePath = pathDetails[i].charAt(0).toUpperCase() +
              pathDetails[i].slice(1) + " ";
        }

        this.setState({
          pagePath: pagePath,
        });
      }

      if (remove_root_property === true) {
        const targetEl = document.getElementById("root");
        if (targetEl) {
          targetEl.style.removeProperty("width");
        }
      }
    }

    if (this.state.pathname !== history.location.pathname) {
      (async () => {
        if (history.action === 'PUSH') {
          this.props.history.replace({
            search: ''
          })
        }

        await (() => {
          Auth.updateError(false);
          this.setState({
            isError: false,
            pathname: history.location.pathname,
            breadcrumbs: false
          })
        })();

        this.setState({
          breadcrumbs: true,
        });

        this.checkHasError();
        dispatch(updateError(false));

      })();
    }

    if (prevProps.user !== user && user !== null && user.tenant) {
      this.setState({tenantName: user.tenant.name})
    }

    if (prevProps.brand !== brand && brand !== null) {
      Object.keys(brand.theme).map((key) => {
        return rootStyle.setProperty(`--${key}`, brand.theme[key])
      })
    }

    // close search results solr
    const solr_search_open = document.getElementById("solr-search");
    //console.log("Dashboard.js - solr_search_open ->", solr_search_open);
    if (solr_search_open && solr_search_open.classList && solr_search_open.classList.contains("show")) {
      let { dispatch } = this.props;
      dispatch(toggleSearchResults());
    }
  }

  camelizeStr = (str) => {
    str = str.replace('.', '');
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  };

  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  render() {
    const { tenantName, children, error, isError, loading, minutesBeforeInactive, breadcrumbs, pagePath } = this.state,
          { brand, loader } = this.props;

    const tenantStyleIdentifier = tenantName ? '__' + this.camelizeStr(tenantName) : 'noName';
    const titlePage = pagePath ? pagePath : false;
    const title = titlePage ? `GRiD | ${tenantName} | ${titlePage}` : `GRiD | ${tenantName}`;

    return (
      <React.Fragment>
        { !loading ?
          <Wrapper tenantStyle={tenantStyleIdentifier}>
            { tenantName &&
              <Helmet>
                <title>{ title }</title>
              </Helmet>
            }
            <Helmet>
              <meta name="viewport" content="width=device-width, width=320px, user-scalable=no" />
            </Helmet>
            { brand && brand.favicon ?
              <Helmet>
                <link rel="shortcut icon" type="image/png" href={ parse(window.atob(brand.favicon)) } />
                <link rel="shortcut icon" sizes="196x196" href={ parse(window.atob(brand.favicon)) } />
                <link rel="apple-touch-icon" href={ `${ process.env.PUBLIC_URL }/${ parse(window.atob(brand.favicon)) }`} />
              </Helmet> :
              <Helmet>
                <link rel="shortcut icon" type="image/png" href={ `${ process.env.PUBLIC_URL }/favicon.ico` } />
                <link rel="shortcut icon" sizes="196x196" href={ `${ process.env.PUBLIC_URL }/favicon.ico` } />
                <link rel="apple-touch-icon" href={ `${ process.env.PUBLIC_URL }/favicon.ico` } />
              </Helmet>
            }
            
            <Main>
              <Navbar navbarChildRef={ref => (this.navbar = ref)} />

              { breadcrumbs && <Breadcrumbs /> }

              { !isError ?
                <Content>
                  { children }
                </Content> :
                <Content>
                  <Error error={ error } />
                </Content>
              }

              <Footer />
            </Main>
            
            <Sidebar />
            { loader && <Loader /> }
          </Wrapper> : <Loader />
        }
        <IdleComponent handleIdleLogout={this.handleIdle} />
      </React.Fragment>
    )
  }
}

const userSelector = createSelector(
  state => state.user,
  user => user,
);

const tenantsSelector = createSelector(
  state => state.tenants,
  tenants => tenants
);

const brandSelector = createSelector(
  state => state.brand,
  brand => brand
);

const themeSelector = createSelector(
  state => state.theme,
  theme => theme
);

const loaderSelector = createSelector(
  state => state.loader,
  loader => loader
);

const errorSelector = createSelector(
  state => state.error,
  error => error
);

const solrSearchSelector = createSelector(
    state => state.solrSearch,
    solrSearch => solrSearch
);

const mapStateToProps = createSelector(
  userSelector,
  tenantsSelector,
  brandSelector,
  themeSelector,
  loaderSelector,
  errorSelector,
  (user, tenants, brand, theme, loader, error, solrSearch) => ({ user, tenants, brand, theme, loader, error, solrSearch})
);

export default withRouter((withAuth(connect(mapStateToProps)(props => <Dashboard { ...props } />))));
