import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    UpdateAPI = Api.updateAPI,
    GetAPIwithType = Api.getAPIwithType,
    DownloadPreviewAPI = Api.downloadPreviewAPI,
    RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : GetAPI(endpoint, params);
  })();
};

const GetRelEmailsParams = (id, count = 1, offset = 100, page = 1) => {
  return asyncFunc(`emails?count=${count}&offset=${offset}&page=${page}&filter=relation_id=${id}`)
};

const GetRelEmailDetails = (id) => {
  return asyncFunc(`emails/${id}`)
};

const GetRelEmailAttachments = (id) => {
  return asyncFunc(`emails/${id}/email_attachments`)
};

const GetRelEmailLogs = (id) => {
  return asyncFunc(`emails/${id}/email_logs`)
};

const GetAttachmentData = (attachment_id, filename) => {
  return asyncFunc(`/attachments/${attachment_id}/download/${filename}`, 'blob', GetAPIwithType);
};

const GetAttachmentDataWithType = (attachment_id, filename) => {
  return asyncFunc(`/attachments/${attachment_id}/download/${filename}`, 'blob', GetAPIwithType);
};

//downloadPreviewAPI
const DownloadPreviewAttachment = (attachment_id, filename) => {
  return asyncFunc(`/attachments/${attachment_id}/download/${filename}`, filename, DownloadPreviewAPI);
};

export {
  GetRelEmailsParams,
  GetRelEmailDetails,
  GetRelEmailAttachments,
  GetRelEmailLogs,
  GetAttachmentData,
  GetAttachmentDataWithType,
  DownloadPreviewAttachment
};
