import React, {useEffect, useState} from 'react';
import { File } from "react-feather";
import { connect } from 'react-redux'
import {NavLink, useLocation, withRouter} from 'react-router-dom';
import { Card, Row, Spinner, Col, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { loaderToggle } from '../../redux/actions/loaderActions'
import classnames from 'classnames';
import CONSTANTS from '../../services/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
  faHandshake,
  faLayerGroup,
  faListUl,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import {GetAdminPlans} from '../../controllers/plans';

const NavPlanTabs = ({ ...props }) => {
  const location = useLocation();
  const { tabDataFor } = props;

  const [activeTab, setActiveTab] = useState(null);
  const [relationTabSettings, setRelationTabSettings] = useState(1); // relationTab settings => value: 1 = label: "Show tab icon & text" | value: 2 = label: "Show tab icon only" | value: 3 = label: "Show tab text only"

  let tabs;

  if (tabDataFor === "admin") {
    tabs = null /*[
      {
        label: 'Plans',
        link: '/admin/plans',
        id: 'plans',
        icon: faListUl
      }
    ]*/;
  } else {
    tabs = [
      {
        label: 'Plans',
        link: '/plans',
        id: 'plans',
        icon: faListUl
      }, {
        label: 'Plan Line Groups',
        link: '/plan-line-groups',
        id: 'plan-line-groups',
        icon: faLayerGroup
      },
      {
        label: 'Terms and Conditions',
        link: '/terms-conditions',
        id: 'terms-conditions',
        icon: faHandshake
      },
    ];
  }

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (location && location.pathname) {
      const pathname = location.pathname;
      const segments = pathname.split("/").filter(element => element);

      if (segments && segments.length > 0 && segments[0] === 'admin') {
        setActiveTab(0);
      }
    }

    const { user } = props;
    if (user) {
      const { json_data } = user;
      //console.log("useEffect - NavPlanTabs - props.user - json_data ->", json_data);
      if (json_data && json_data !== "") {
        let json_data_user = JSON.parse(json_data);
        //console.log("useEffect - NavPlanTabs - props.user - json_data_user ->", json_data_user);
        if (json_data_user && json_data_user.relation_tabs) {
          setRelationTabSettings(json_data_user.relation_tabs);
        }
      }
    }
  }, []);

  return (
      tabs &&
      <>
        <Nav tabs className="f2x-mv-tabs mb-3 horizontal-tabs scroll w-100" style={{width: "100%", borderBottom: "1px solid #dee2e6"}}>
          { tabs.map(({label, link, icon, id}, i) => {
            return (
                <NavItem key={id} style={{marginBottom: "0px"}}>
                  {relationTabSettings === 1 && 
                  <NavLink
                    to={link}
                    className={`nav-link plans-tab-link ${ classnames({ active: activeTab === i }) }`}
                    onClick={ () => toggleTab(i) }
                  >
                    <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={icon} />
                    <span className="f2x-mv-tabs-list-item-text" style={{display:"none"}}>{label}</span>
                  </NavLink>
                  }
                  
                  {relationTabSettings === 2 && 
                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom-${label.replace(/[^a-z0-9 _-]/gi, '-').toLowerCase()}-li-${id}`}>
                      <div className={`popover-html-contents`}>
                        <span>{label}</span>
                      </div>
                    </Tooltip>}
                  >
                    <NavLink
                        to={link}
                        className={`nav-link plans-tab-link ${ classnames({ active: activeTab === i }) }`}
                        onClick={ () => toggleTab(i) }
                    >
                      <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={icon} />
                      <span className="f2x-mv-tabs-list-item-text" style={{display:"none"}}>{label}</span>
                    </NavLink>
                  </OverlayTrigger>}

                  {relationTabSettings === 3 && 
                  <NavLink
                    to={link}
                    className={`nav-link plans-tab-link ${ classnames({ active: activeTab === i }) }`}
                    onClick={ () => toggleTab(i) }
                  >
                    <span className="f2x-mv-tabs-list-item-text" style={{marginLeft: "0px"}}>{label}</span>
                  </NavLink>
                  }
                </NavItem>
            )
          })}
        </Nav>
      </>
  )
};

const mapStateToProps = ({ dispatch, user }) => ({ dispatch, user });

export default withRouter(connect(mapStateToProps)(NavPlanTabs));
