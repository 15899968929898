import ApiService from "../services/apiService";

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        let data = await method ? method(endpoint, params) : GetAPI(endpoint, params)
        return data
    })()
}

const GetMyNumberRanges = (params, id) => {
    return asyncFunc(`/mynumberranges/${id}`, params)
}

const AddNumberRange = (params) => {
    return asyncFunc(`/number_ranges`, params, PostAPI)
}

const UpdateNumberRange = (id, params) => {
    return asyncFunc(`/number_ranges/${id}`, params, UpdateAPI)
}

export {
    GetMyNumberRanges,
    AddNumberRange,
    UpdateNumberRange
}
