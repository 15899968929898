import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Form, Input, Row, Col } from "reactstrap";
import {
    GetRelationsDependencies,
    AddRelation,
    GetRelationBillingStylesList,
    GetRelationTypes
} from '../../controllers/relations';
import { GetPaymentConditions } from '../../controllers/payment_conditions';
import { loaderToggle } from "../../redux/actions/loaderActions";
import { validateEmail, validateIBAN } from "../../redux/actions/validateActions";
import ToastMulti from "../../components/ToastMulti";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_STATUS = 'ACTIVE';
const DEFAULT_TYPE = 'CUSTOMER';
const statusOpt = [{
    value: 1,
    label: "Active"
}, {
    value: 0,
    label: "Inactive"
}];
const invOutputTypeOpts = [{
    value: 'email',
    label: 'Email'
}, {
    value: 'paper',
    label: 'Paper'
}];

class RelationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            formName: 'relation',
            relation: { status: statusOpt.find(v => v.label.toUpperCase() === DEFAULT_STATUS).value },
            relation_type_id: {
                'label': 'Customer',
                'value': 1
            },
            defaultStatus: statusOpt.find(v => v.label.toUpperCase() === DEFAULT_STATUS)
        }
    }

    handleInputChange({ target: { name, value } }) {
        let relation = Object.assign({}, this.state.relation);

        relation[name] = value;

        this.setState({ relation })
    }

    handleSelectChange(name, data) {
        let relation = Object.assign({}, this.state.relation);

        relation[name] = data.value;

        this.setState({
            relation,
            [name]: data
        })
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch, loader } = this.props,
            { relation } = this.state,
            { email, iban, relation_type_id } = relation,
            { valid } = dispatch(validateEmail(email)),
            IBAN = dispatch(validateIBAN(iban));

        let notificationsToastArr = [];

        if (!relation_type_id) {
            notificationsToastArr.push({
                title: "Type is required!",
                icon: "warn",
            });
        }

        if (!valid) {
            notificationsToastArr.push({
                title: "Please enter a valid email address!",
                icon: "warn",
            });
        }

        if (iban && !IBAN.valid) {
            notificationsToastArr.push({
                title: "IBAN must be valid!",
                icon: "warn",
            });
        }

        if (notificationsToastArr.length > 0) {
            return ToastMulti(notificationsToastArr, "container-validation-id");
        }

        if (!loader) {
            (async () => {
                dispatch(loaderToggle(true));

                const { ok, data } = await AddRelation(relation);

                dispatch(loaderToggle(false));

                if (ok) {
                    this.props.history.push(`/relations/${data.data.id}/details`)
                }
            })()
        }
    }

    componentDidMount() {
        const { dispatch, user } = this.props;

        dispatch(loaderToggle(true));

        (async () => {
            //const { ok, data } = await GetRelationsDependencies();
            const [/*relationDependencies,*/ relationTypes, relationBillingStyles, paymentConditions] = await Promise.all([
                //GetRelationsDependencies(),
                GetRelationTypes(),
                GetRelationBillingStylesList(),
                GetPaymentConditions(null, user.tenant_id)
            ]);

            //console.log("relation details - GetRelationsDependencies - data ->", data);
            //console.log("relation details - relationDependencies ->", relationDependencies);
            console.log("relation details - relationTypes ->", relationTypes);
            console.log("relation details - relationBillingStyles ->", relationBillingStyles);
            console.log("relation details - paymentConditions ->", paymentConditions);

            if (relationTypes.ok && paymentConditions.ok && relationBillingStyles.ok) {
                //const { relation_types, payment_conditions } = relationDependencies.data;
                //console.log("relation details - relation_types ->", relation_types);
                //console.log("relation details - payment_conditions ->", payment_conditions);
                console.log("relation details - relationTypes ->", relationTypes);
                console.log("relation details - paymentConditions ->", paymentConditions);
                console.log("relation details - relationBillingStyles ->", relationBillingStyles);

                let relationTypesOpts = [],
                    paymentConditionsOpts = [];

                //relation_types && relation_types.map(({ type, id }) => {
                relationTypes && relationTypes.data && relationTypes.data.data && relationTypes.data.data.map(({ type, id }) => {
                    return relationTypesOpts.push({
                        label: type,
                        value: id
                    })
                });

                //payment_conditions && payment_conditions.map(({ description, id }) => {
                paymentConditions && paymentConditions.data && paymentConditions.data.data && paymentConditions.data.data.map(({ description, id }) => {
                    return paymentConditionsOpts.push({
                        label: description,
                        value: id
                    })
                });

                this.setState({
                    relationTypesOpts,
                    paymentConditionsOpts,
                    invOutputTypeOpts,
                    relation: Object.assign({}, this.state.relation, { relation_type_id: relationTypesOpts && relationTypesOpts.find(v => v.label.toUpperCase() === DEFAULT_TYPE).value }),
                    defaultType: relationTypesOpts && relationTypesOpts.find(v => v.label.toUpperCase() === DEFAULT_TYPE)
                })
            }

            dispatch(loaderToggle(false))
        })()
    }

    render() {
        const { show, hide, loader } = this.props,
            { formType, formName, status, relationTypesOpts, relation_type_id, paymentConditionsOpts, payment_condition_id, invOutputTypeOpts, inv_output_type, defaultStatus, defaultType } = this.state,
            { company_name, email, credit_limit, bank_account, kvk, fax, website, iban, bic, phone, vat_no/*, info*/ } = this.state.relation;

        return (
            <Modal isOpen={show} toggle={hide} centered className="modal-xl">
                {!loader && <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <ModalHeader>{formType} {formName}</ModalHeader>
                    <ModalBody className="mt-3 mb-3">
                        <Row>
                            <Col md={3}>
                                <label className="col-form-label">Status / Type:</label>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={6} className="pb-2">
                                        <Select
                                            id="status"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Status"
                                            options={statusOpt}
                                            value={status ? status : defaultStatus}
                                            onChange={this.handleSelectChange.bind(this, 'status')}
                                            maxMenuHeight={300}
                                            defaultValue={defaultStatus}
                                        />
                                    </Col>
                                    <Col md={6} className="pb-2">
                                        <Select
                                            id="relation_type_id"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Type"
                                            options={relationTypesOpts}
                                            value={relation_type_id ? relation_type_id : defaultType}
                                            onChange={this.handleSelectChange.bind(this, 'relation_type_id')}
                                            maxMenuHeight={300}
                                            defaultValue={defaultType}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label className="col-form-label">Company name / Email:</label>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="company_name"
                                            name="company_name"
                                            placeholder='Company name'
                                            value={company_name ? company_name : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="email"
                                            name="email"
                                            placeholder='Email'
                                            value={email ? email : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label className="col-form-label">Credit limit / Bank account:</label>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="credit_limit"
                                            name="credit_limit"
                                            placeholder='Credit limit'
                                            value={credit_limit ? credit_limit : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="bank_account"
                                            name="bank_account"
                                            placeholder='Bank account'
                                            value={bank_account ? bank_account : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label className="col-form-label">KVK / Fax:</label>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="kvk"
                                            name="kvk"
                                            placeholder='KVK'
                                            value={kvk ? kvk : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="fax"
                                            name="fax"
                                            placeholder="Fax"
                                            value={fax ? fax : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label className="col-form-label">IBAN:</label>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={12} className="pb-2">
                                        <Input
                                            id="iban"
                                            name="iban"
                                            placeholder='IBAN'
                                            value={iban ? iban : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label className="col-form-label">Website / BIC:</label>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="website"
                                            name="website"
                                            placeholder="Website"
                                            value={website ? website : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="bic"
                                            name="bic"
                                            placeholder="BIC"
                                            value={bic ? bic : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label className="col-form-label">Payment conditions / Invoice output:</label>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={6} className="pb-2">
                                        <Select
                                            id="payment_condition_id"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Payment conditions"
                                            options={paymentConditionsOpts}
                                            value={payment_condition_id ? payment_condition_id : null}
                                            onChange={this.handleSelectChange.bind(this, 'payment_condition_id')}
                                            maxMenuHeight={300}
                                        />
                                    </Col>
                                    <Col md={6} className="pb-2">
                                        <Select
                                            name="inv_output_type"
                                            options={invOutputTypeOpts}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Invoice output"
                                            value={inv_output_type}
                                            onChange={this.handleSelectChange.bind(this, 'inv_output_type')}
                                            maxMenuHeight={300}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label className="col-form-label">Phone / Vat no:</label>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="phone"
                                            name="phone"
                                            placeholder="Phone"
                                            value={phone ? phone : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col md={6} className="pb-2">
                                        <Input
                                            id="vat_no"
                                            name="vat_no"
                                            placeholder='Vat no'
                                            value={vat_no ? vat_no : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <span className="btn btn-danger" onClick={hide}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                        <Button color="primary">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ModalFooter>
                </Form>}
            </Modal>
        )
    }
}

export default connect(store => ({
    loader: store.loader,
    user: store.user
}))(withRouter(RelationForm))
