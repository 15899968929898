import ApiService from "../services/apiService";

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI,
    RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        return await method ? method(endpoint, params) : GetAPI(endpoint, params)
    })()
};

const GetProvisioning = (params) => {
    return asyncFunc(`provisioning_subscriptions`, params)
}

const GetProvisioningCount = () => {
    return asyncFunc(`provisioning_subscriptions_count`)
}

// Provisioning Templates
const GetProvisioningTemplates = () => {
    return asyncFunc(`provisioning_templates`)
}

const GetProvisioningTemplatesByTenantId = (tenant_id) => {
    return asyncFunc(`provisioning_templates?filter=tenant_id=${tenant_id}`)
}

const GetProvisioningTemplatesById = (id) => {
    return asyncFunc(`provisioning_templates/${id}`)
}

const CreateProvisioningTemplates = (params) => {
    return asyncFunc(`provisioning_templates`, params, PostAPI)
};

const UpdateProvisioningTemplates = (id, params) => {
    return asyncFunc(`provisioning_templates/${id}`, params, UpdateAPI)
};

const RemoveProvisioningTemplates = (id) => {
    return asyncFunc(`provisioning_templates/${id}`, null, RemoveAPI)
};

export {
  GetProvisioning,
  GetProvisioningCount,

  GetProvisioningTemplates,
  GetProvisioningTemplatesByTenantId,
  GetProvisioningTemplatesById,
  CreateProvisioningTemplates,
  UpdateProvisioningTemplates,
  RemoveProvisioningTemplates
}