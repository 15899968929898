import React, { useEffect } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {InputGroupAddon, InputGroup} from 'reactstrap';
import queryString from 'query-string';
import {loaderToggle} from '../redux/actions/loaderActions';

const Search = (props) => {
  //const {dispatch} = props;

  console.log('customTable search - props', props);
  //console.log('customTable dispatch', dispatch);
  //console.log('customTable search - props.storageKey', props.storageKey);

  let input;

  const handleEvent = (e) => {
    if (e.key === "Enter") {
      //localStorage.removeItem(props.storageKey);

      if (input.value) {
        //props.setPage(1);
        //props.setKeyword(input.value);
        //props.setSearchKey(input.value);
        //props.onSearch(input.value);

        //dispatch(loaderToggle(false));
      } else {
        if (props.isLoadData) {
          // explicitly set search text to char `*`
          props.onSearch('*');
          //props.setKeyword('');
          //props.setSearchKey('');
          //props.setPage(1);
          //props.isLoadData(true);

          //dispatch(loaderToggle(false));
        }
      }
      //dispatch(loaderToggle(false));

    }
  };

  const clearSearch = (e) => {
    console.log(e);

    input.value = '';
    props.onClear(input.value);
    //props.setPage(1);
    //props.setKeyword('');
    //props.setSearchKey('');
    //props.isLoadData(true);

    //set localStorage - props.storageKey
    if (props.storageKey && props.storageKey !== '') {
      sessionStorage.removeItem(props.storageKey);
    }
  };

  //useEffect(() => {
    //dispatch(loaderToggle(true));
    //const searchParam = queryString.parse(props.history.location.search);
    //const keyword = searchParam.keyword
    //input.value = props.searchKeyword ? props.searchKeyword : '';
    //input.value = keyword; //props.searchKeyword;
    //dispatch(loaderToggle(false));
  //});

  return (
    <div>
      <InputGroup>
        <input
          className="form-control"
          ref={ n => input = n }
          type="text"
          onKeyPress={(e) => handleEvent(e)}
          placeholder="Enter search term"
        />
        <InputGroupAddon className="clear-btn" addonType="append">
          <a className="danger icon-delete" onClick={(e) => clearSearch(e)}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  )
};

export default Search;