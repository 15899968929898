import React, {useState, useRef} from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Row,
  Col,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  NavItem,
  NavLink,
  Nav,
  TabPane, TabContent,
} from 'reactstrap';
import {
  GetProduct,
  AddProduct,
  UpdateProduct,
  GetProductTypeList,
  GetProductsBackendAPIs,
  GetProducts,
  GetProductHierarchy,
  GetProductHierarchyRelationTypes,
  AddProductHierarchy,
  UpdateProductHierarchy,
  RemoveProductHierarchy,
} from '../../../controllers/products';
import {OverlayTrigger, Tooltip, Dropdown} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {
  GetStatusList, RemoveSubscriptionLine,
} from '../../../controllers/subscriptions';
import { GetVatCodes } from '../../../controllers/vat_codes';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate, validateBarcode } from "../../../redux/actions/validateActions";
import {
  formatNumber,
  parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import { Collapse } from "react-bootstrap";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import {
  PlusSquare,
  MinusSquare,
  MoreHorizontal,
  Trash2, CornerDownRight,
} from 'react-feather';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilter,
  faTrash,
  faSortAmountDown,
  faSortAmountUp,
  faSlidersH,
} from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select';
import Swal from "sweetalert2";
import ReactJson from 'react-json-view';

const AddRelatedProduct = ({ ...props }) => {
  const {
    product, // selected (edit) product ID (root)
    update,
    rootFormOpen,
    setRootFormClose,
    rootFormMutualOpen,
    setRootFormMutualOpen,
    rootFormJsonDataOpen,
    setRootFormJsonDataOpen,
    rootFormType,
    productHierarchies,
    productRelatedTo,
    loader,
    dispatch,
    hide,
    updateRelatedChildProducts, // update hierarchy list
    productHierarchyRelationTypesOpts,
    productHierarchyRelationTypesChildOpts,
    productHierarchyRelationTypesMutualOpts,
    productsListOpts,
  } = props;

  const addProductFormRef = useRef(null);
  const [activeProductId, setActiveProductId] = useState(product && product.id ? product.id : null);
  const [relationTypeValue, setRelationTypeValue] = useState("");
  const [relationProductValue, setRelationProductValue] = useState("");
  const [relationTextValue, setRelationTextValue] = useState("");

  // submit form (for root = level 1)
  const addHierarchyRelationFormSubmit = (e, rootFormType) => {
    e.preventDefault();

    let notificationsToastArr = [];
    let valid_selected_product_json_data;

    // check if related product
    if (!relationProductValue) {
      notificationsToastArr.push({ title: 'No related product selected.', icon: 'warn' });
    }
    // check related product type
    if (!relationTypeValue) {
      notificationsToastArr.push({ title: 'No relation type selected.', icon: 'warn' });
    }

    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    let relatedProductId = relationProductValue.value;
    let productRelations = Object.assign({});
    productRelations.id = product && product.id ? product.id : null; // parent is the active product edit ID (product.id)
    productRelations.related_product_id = relatedProductId;
    productRelations.relation_type = relationTypeValue.value;

    if (relatedProductId && (product && product.id)) {
      if (!loader) {
        dispatch(loaderToggle(true));

        (async () => {
          const {ok, data} = await AddProductHierarchy(product.id, relatedProductId, productRelations);
          if (ok) {
            if (data && data.data) {
              await updateRelatedChildProducts();

              // clear item in product dropdown value(s)
              if (addProductFormRef && addProductFormRef.select) {
                addProductFormRef.select.clearValue();
              }

              // clear form
              setRelationTypeValue("");
              setRelationProductValue("");
              //close form
              setRootFormClose();
            }

            notificationsToastArr.push({
              title: "Product hierarchy updated!",
              icon: "success",
            });
          } else {
            notificationsToastArr.push({
              title: "Error saving data",
              icon: "error",
            });
          }

          await handleOk(ok, notificationsToastArr);

        })();

        dispatch(loaderToggle(false));
      }
    }
  };

  const handleOk = async (ok, options) => {
    dispatch(loaderToggle(false));

    if (ok) {
      update();
      if (options && options.length > 0) {
        return ToastMulti(options, "container-validation-id");
      } else {
        hide();
      }
    }
  };

  const isValidJSONString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleTextChange = (e) => {
    setRelationTextValue(e.target.value);
  };

  const handleSelectTypeChange = (e) => {
    setRelationTypeValue({
      label: e.label,
      value: e.value
    });
  };

  const handleSelectProductChange = (e) => {
    setRelationProductValue({
      label: e.label,
      value: e.value
    });
  };

  const loadProducts = (value, callback) => {
    const search = 'search',
        offset = 1000,
        params = { search: value, offset: offset };

    (async () => {
      const { ok, data } = await GetProducts(params);
      if (ok && data) {
        let products = [];
        data.data.map(({ description, id }) => {
          // filter loadProducts without -> activeProductId
          if (activeProductId) {
            if (parseInt(id) !== parseInt(activeProductId)) {
              return products.push({
                label: description,
                value: id
              });
            }
          } else {
            return products.push({
              label: description,
              value: id
            });
          }
        });
        callback(products);
      }
    })();
  };

  return (
    // render (main) add relation form
    <React.Fragment>
      <div className="product-hierarchy mt-2 mb-2" ref={addProductFormRef}>
        {rootFormType === "json_data" ? (
          <Collapse in={rootFormJsonDataOpen}>
            <div id="add-relation-hierarchy" className="form-group row form-hierarchy">
              <div className="add-relation-hierarchy-wrapper root-json-data shadow">
                <div className="form-group mb-1">
                  <Input
                      type="textarea"
                      id={`related_product_json_data`}
                      name={`related_product_json_data`}
                      placeholder={`Add JSON data`}
                      value={relationTextValue ? relationTextValue : ""}
                      onChange={(e) => handleTextChange(e, `related_product_json_data`, relationTextValue)}
                  />
                </div>
                <div className="form-group mb-0 d-flex justify-content-between">
                  <Button
                      type="button"
                      color="danger"
                      className="mt-1 cancel-related-product-btn btn btn-default btn-sm"
                      onClick={(e) => setRootFormJsonDataOpen(e, "json_data")}
                  >
                    Cancel
                  </Button>
                  <Button
                      type="submit"
                      color="primary"
                      className="mt-1 submit-related-product-btn btn btn-default btn-sm text-right"
                      onClick={(e) => addHierarchyRelationFormSubmit(e, rootFormType)}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <div className="icon-wrapper">
                <CornerDownRight
                    color={`grey`}
                    size="18"
                    className="hover-pointer mt-0 mb-1 ml-2 product-border-list-plus"
                />
              </div>
            </div>
          </Collapse>

        ) : (rootFormType === "mutual" ? (
          <Collapse in={rootFormMutualOpen}>
            <div id="add-relation-hierarchy" className="form-group row form-hierarchy">
              <div className="add-relation-hierarchy-wrapper root-add-mutual shadow">
                <div className="form-group mb-1">
                  <AsyncSelect
                      cacheOptions
                      options={productsListOpts}
                      loadOptions={loadProducts}
                      getOptionLabel={({ label, value }) => {
                        return `${label ? label : String.fromCharCode(8212)}`
                      }}
                      id="related_product_id"
                      name="related_product_id"
                      className="react-select-container mb-1"
                      classNamePrefix="react-select"
                      placeholder={`Add product`}
                      value={relationProductValue ? relationProductValue : ""}
                      onChange={(e) => handleSelectProductChange(e, `related_product_id`, relationProductValue)}
                      maxMenuHeight={300}
                  />
                </div>
                <div className="form-group mb-1">
                  <Select
                      id="related_product_type_id"
                      name="related_product_type_id"
                      className="react-select-container mb-1"
                      classNamePrefix="react-select"
                      options={productHierarchyRelationTypesMutualOpts}
                      placeholder={`Select (mutual) relation type`}
                      value={relationTypeValue}
                      onChange={(e) => handleSelectTypeChange(e, `related_product_type_id`, relationTypeValue)}
                      maxMenuHeight={300}
                  />
                </div>
                <div className="form-group mb-0 d-flex justify-content-between">
                  <Button
                      type="button"
                      color="danger"
                      className="mt-1 cancel-related-product-btn btn btn-default btn-sm"
                      onClick={(e) => setRootFormMutualOpen(e, "mutual")}
                  >
                    Cancel
                  </Button>
                  <Button
                      type="submit"
                      color="primary"
                      className="mt-1 submit-related-product-btn btn btn-default btn-sm text-right"
                      onClick={(e) => addHierarchyRelationFormSubmit(e, rootFormType)}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <div className="icon-wrapper">
                <CornerDownRight
                    color={`grey`}
                    size="18"
                    className="hover-pointer mt-0 mb-1 ml-2 product-border-list-plus"
                />
              </div>
            </div>
          </Collapse>

        ) : (
          <Collapse in={rootFormOpen}>
            <div id="add-relation-hierarchy" className="form-group row form-hierarchy">
              <div className="add-relation-hierarchy-wrapper root-add-child shadow">
                <div className="form-group mb-1">
                  <AsyncSelect
                      cacheOptions
                      options={productsListOpts}
                      loadOptions={loadProducts}
                      getOptionLabel={({ label, value }) => {
                        return `${label ? label : String.fromCharCode(8212)}`
                      }}
                      id="related_product_id"
                      name="related_product_id"
                      className="react-select-container mb-1"
                      classNamePrefix="react-select"
                      placeholder={`Add child product`}
                      value={relationProductValue ? relationProductValue : ""}
                      onChange={(e) => handleSelectProductChange(e, `related_product_id`, relationProductValue)}
                      maxMenuHeight={300}
                  />
                </div>
                <div className="form-group mb-1">
                  <Select
                      id="related_product_type_id"
                      name="related_product_type_id"
                      className="react-select-container mb-1"
                      classNamePrefix="react-select"
                      options={productHierarchyRelationTypesChildOpts}
                      placeholder={`Select (parent-child) relation type`}
                      value={relationTypeValue}
                      onChange={(e) => handleSelectTypeChange(e, `related_product_type_id`, relationTypeValue)}
                      maxMenuHeight={300}
                  />
                </div>
                <div className="form-group mb-0 d-flex justify-content-between">
                  <Button
                      type="button"
                      color="danger"
                      className="mt-1 cancel-related-product-btn btn btn-default btn-sm"
                      onClick={(e) => setRootFormClose(e, "child")}
                  >
                    Cancel
                  </Button>
                  <Button
                      type="submit"
                      color="primary"
                      className="mt-1 submit-related-product-btn btn btn-default btn-sm text-right"
                      onClick={(e) => addHierarchyRelationFormSubmit(e, rootFormType)}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <div className="icon-wrapper">
                <CornerDownRight
                    color={`grey`}
                    size="18"
                    className="hover-pointer mt-0 mb-1 ml-2 product-border-list-plus"
                />
              </div>
            </div>
          </Collapse>

        ))}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ dispatch, loader }) => ({ dispatch, loader });
export default connect(mapStateToProps)(AddRelatedProduct);
