import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {GetUser} from '../../controllers/users';
import ParentTable from '../../components/ParentTable';
import {GetApiKeysUser} from '../../controllers/persons';
import AddApiKeysForm from '../users/Add_api_keys';
import ApiKeysForm from '../tenant/api_keys/Form';
import RemoveApiKeysForm from '../tenant/api_keys/Remove';
import {withRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const UserApiKeysList = ({ ...props }) => {
  const { match } = props;
  const { params } = match;
  const { t } = useTranslation();

  const [userData, setUserData] = useState(null);
  const id = params.id ? params.id : null;

  const formatDate = (cell) => {
    return cell ? <span className="date-format">{t("date_format", { date: new Date(cell) })}</span> : String.fromCharCode(8212)
  };

  useEffect(() => {
    if (id) {
      (async () => {
        let user;
        const {ok, data} = await GetUser(id);
        if (ok && data && data.data) {
          user = data.data;
          setUserData(user);
        }
      })();
    }
  }, [id]);

  return (
      <React.Fragment>
        <h1>{ userData && userData.full_name ? userData.full_name : 'User' } - API Keys</h1>
        <ParentTable
            table="ApiKeysUsers"
            id={id}
            keyField="id"
            noSearch={false}
            noTopBorder={true}
            data={GetApiKeysUser}
            columns={[
              {
                dataField: "id",
                text: "ID",
                headerStyle: {width: '15%'},
                sort: true,
                attrs: {
                  'data-label': 'ID'
                }
              }, {
                dataField: "name",
                text: "Name",
                headerStyle: {width: '20%'},
                sort: true,
                attrs: {
                  'data-label': 'Name'
                }
              }, {
                dataField: "key",
                text: "Api key",
                headerStyle: {width: '40%'},
                sort: true,
                attrs: {
                  'data-label': 'Api key'
                }
              }, {
                dataField: "expire_date",
                text: "Expire date",
                formatter: formatDate,
                headerStyle: {width: '15%'},
                sort: true,
                attrs: {
                  'data-label': 'Expire date'
                }
              },
            ]}
            rowAction="edit"
            rowActionForm={ ApiKeysForm }
            headerDropdownOpts={[{
              label: 'Add',
              action: AddApiKeysForm,
              condition: {
                data: 'id',
              }
            }]}
            rowDropdownOpts={[{
              action: 'Edit',
              label: 'Edit',
              form: ApiKeysForm
            }, {
              action: 'Remove',
              label: 'Remove',
              form: RemoveApiKeysForm,
              condition: {
                data: 'id'
              }
            }]}
        />
      </React.Fragment>
  )
};

export default withRouter(connect()(UserApiKeysList));
