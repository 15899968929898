import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ListGroup,
  ListGroupItem,
  Form,
  Col,
  Row,
  Input,
  Button,
  CardBody,
  Card,
  Badge, 
  CustomInput
} from 'reactstrap';
import Select from 'react-select';
import CONSTANTS from '../../../services/constants';
import {
  UpdateRelation,
  AddRelationNotes,
  GetRelationBillingStylesList,
  GetRelationTypes
} from '../../../controllers/relations';
import { validateEmailWithValidator } from '../../../redux/actions/validateActions';
import Toast from '../../../components/Toast';
import NotesTable from '../../../components/NotesTable';
import { formatPhoneNr } from '../../../redux/actions/formatActions';
import { Check, X } from 'react-feather';
import { GetPaymentConditions } from '../../../controllers/payment_conditions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { Edit3 } from "react-feather";
import { Helmet } from "react-helmet";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const RelationDetails = ({ relation, titleUpdate, user, dispatch }) => {
  const { id, notes, persons } = relation;

  const [toggleEdit, setToggleEdit] = useState(false);
  const [customerNo, setCustomerNo] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [website, setWebsite] = useState('');
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [company, setCompany] = useState('');
  const [regNo, setRegNo] = useState('');
  const [vatNo, setVatNo] = useState('');
  const [paymentCond, setPaymentCond] = useState(null);
  const [billingStyle, setBillingStyle] = useState(null);
  const [paymentConditions, setPaymentConditions] = useState(null);
  const [invoiceOutput, setInvoiceOutput] = useState(null);
  const [paymentCondOpts, setPaymentCondOpts] = useState([]);
  const [billingStylesOpts, setBillingStylesOpts] = useState([]);
  const [relationTypeOpts, setRelationTypeOpts] = useState([]);
  const [paymentCondRelationOpts, setPaymentCondRelationOpts] = useState([]);
  const [typeOpts, setTypeOpts] = useState([]);
  const [invOutputOpts, setInvOutputOpts] = useState([]);
  const [isBusinessOpts, setIsBusinessOpts] = useState([]);
  const [isBusiness, setIsBusiness] = useState(null);
  const [notesList, setNotesList] = useState({});

  const [selectDataLoaded, setSelectDataLoaded] = useState(null);

  const customerPersonalData = [];
  if (persons && persons.length > 0) {
    let i;
    for (i = 0; i < persons.length; i++) {
      if (persons[i] && persons[i].primary && persons[i].primary === 1) {
        customerPersonalData['gender'] = persons[i].gender;
        customerPersonalData['birthdate'] = persons[i].birthdate;
        customerPersonalData['phone'] = persons[i].phone;
        customerPersonalData['mobile'] = persons[i].mobile;
      }
      customerPersonalData['billingAddress'] = relation.billing_address ? relation.billing_address : '';
      customerPersonalData['isBusiness'] = relation['is_business'] ? relation['is_business'] : 0;
      customerPersonalData['customerFullName'] = relation.primary_person_full_name ? relation.primary_person_full_name : '';
    }
  }

  const detailsCustGroup = [
    { label: 'Website', value: website, setFunc: setWebsite, id: 'Website' },
    { label: 'Type', value: type, setFunc: setType, opts: relationTypeOpts, id: 'Type' },
    { label: 'Status', value: status, setFunc: setStatus, opts: CONSTANTS['RELATION_STATUSES'], id: 'Status' }
  ];

  const detailsCompGroup = [
    { label: 'Company', value: company, setFunc: setCompany, id: 'Company', disabled: true },
    { label: 'Reg no.', value: regNo, setFunc: setRegNo, id: 'RegNo', disabled: true },
    { label: 'VAT no.', value: vatNo, setFunc: setVatNo, id: 'VatNo', disabled: true },
    { label: 'Payment conditions', value: paymentCond, setFunc: setPaymentCond, opts: paymentCondOpts, id: 'PaymentCond' },
    { label: 'Billing style', value: billingStyle, setFunc: setBillingStyle, opts: billingStylesOpts, id: 'BillingStyle' },
    { label: 'Invoice output', value: invoiceOutput, setFunc: setInvoiceOutput, opts: invOutputOpts, id: 'InvoiceOutput' },
  ];

  const toggleForm = () => {
    setToggleEdit(!toggleEdit)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { valid } = dispatch(validateEmailWithValidator(email));
    if (!email || !valid) {
      return Toast.fire({ title: 'Please enter a valid email Address.', icon: 'warning' })
    }

    const params = {
      company_name: company,
      email,
      fax,
      inv_output_type: invoiceOutput && invoiceOutput.value,
      business_registration_number: regNo,
      payment_condition_id: paymentCond && paymentCond.value,
      phone,
      relation_type_id: type && type.value,
      status: status && status.value,
      vat_no: vatNo,
      website,
      is_business: isBusiness,
      billing_style: billingStyle && billingStyle.value,
    };

    UpdateRelation(id, params)
      .then(r => {
        if (r['data']) {
          const { data } = r['data'];
          dispatch({ type: 'RELATION', payload: data });
          setToggleEdit(!toggleEdit)
        }
      })
      .catch(e => console.log(e))
  };

  useEffect(() => {
    (async () => {
      const [payment_conditions, relationTypes, relation_billing_styles] = await Promise.all([
        GetPaymentConditions(null, user.tenant_id),
        GetRelationTypes(),
        GetRelationBillingStylesList(),
      ]);

      // set relationTypeOpts
      if (relationTypes && relationTypes.data && relationTypes.data.data) {
        let relation_types_arr = [];
        if (relationTypes.data.data.length > 0) {
          for (let i = 0; i < relationTypes.data.data.length; i++) {
            relation_types_arr.push({ label: relationTypes.data.data[i].type, value: relationTypes.data.data[i].id });
          }
          setRelationTypeOpts(relation_types_arr);
        }
      }

      if (payment_conditions && payment_conditions.data && payment_conditions.data.data) { // paymentConditions
        setPaymentCondOpts(payment_conditions.data.data.map(r => ({ label: r.description, value: r.id })));
      }

      if (relation_billing_styles && relation_billing_styles.data && relation_billing_styles.data.data) {
        let billing_styles_arr = [];
        if (typeof relation_billing_styles.data.data === "object") {
          for (let key of Object.keys(relation_billing_styles.data.data)) {
            billing_styles_arr.push({ label: relation_billing_styles.data.data[key], value: parseInt(key) });
          }
        }
        setBillingStylesOpts(billing_styles_arr);
      }
      setSelectDataLoaded(true);

    })();
  }, []); /*user*/ //=> user as dependancy ???

  useEffect(() => {
    if (selectDataLoaded) {
      const { company_name, customer_number, email, phone, fax, payment_conditions, status, relation_type_id, type, relation_types, business_registration_number, vat_no, website, inv_output_type, invoiceOutputOptions, billing_style, relation_billing_styles, payment_condition, payment_condition_id, is_business } = relation;

      let payment_conditions_arr = [];
      if (payment_conditions && payment_conditions.length > 0) {
        for (let i = 0; i < payment_conditions.length; i++) {
          payment_conditions_arr.push({ label: payment_conditions[i].description, value: payment_conditions[i].id });
        }
      }

      setCustomerNo(customer_number || '');
      setEmail(email || '');
      setPhone(phone ? phone : '');
      setFax(fax ? fax : '');
      setWebsite(website || '');
      setType(relation_type_id && relationTypeOpts && relationTypeOpts.length > 0 ? relationTypeOpts.filter(r => parseInt(r.value) === parseInt(relation_type_id))[0] : null);
      setStatus(status || status === 0 ? CONSTANTS['RELATION_STATUSES'].filter(r => parseInt(r.value) === parseInt(status))[0] : null);
      setCompany(company_name || '');
      setRegNo(business_registration_number || '');
      setVatNo(vat_no || '');
      setPaymentCond(payment_condition_id && payment_conditions_arr && payment_conditions_arr.length > 0 ? payment_conditions_arr.find(r => r.value === payment_condition_id) : !!payment_condition && { label: (payment_condition.description ? payment_condition.description : String.fromCharCode(8212)), value: (payment_condition.id && payment_condition.id !== '' ? payment_condition.id : String.fromCharCode(8212)) });
      setPaymentCondOpts(payment_conditions_arr && payment_conditions_arr.length > 0 ? payment_conditions_arr : []);
      setBillingStyle(billing_style && billingStylesOpts && billingStylesOpts.length > 0 ? billingStylesOpts.find(r => parseInt(r.value) === parseInt(billing_style)) : null);
      setInvoiceOutput(inv_output_type ? invoiceOutputOptions.find(r => r.value === inv_output_type) : null);
      setNotesList({ ok: true, data: notes ? notes : [] });
      setTypeOpts(relationTypeOpts && relationTypeOpts.length > 0 ? relationTypeOpts : null);
      setInvOutputOpts(invoiceOutputOptions);

      setIsBusinessOpts(CONSTANTS['RELATION_BUSINESS_STATUSES'].map(r => ({ label: r.label, value: r.value })));
      setIsBusiness(is_business || 0);
    }
  }, [relation, selectDataLoaded/*, notes*/]); //=> notes disabled on 21-08-2024 -> ????

  const inputFormatter = ({ id, value, setFunc, ...props }) => {
    let isDisabled = !!(props && props.disabled);
    if (isBusiness === 1) {
      isDisabled = false;
    }

    return <Input
      id={id}
      type="text"
      disabled={isDisabled}
      value={value ? value : ""}
      onChange={(e) => setFunc(e.target.value)}
    />;
  };

  const checkboxFormatter = ({ id, name, label, value, toggleEdit }) => {
    if (!toggleEdit) {
      if (value === true || value === 1) {
        return (
          <ListGroupItem key={id}>
            <Col className="rel-dt-label col-md-4">{label}</Col>
            <Col className="col-md-8">
              <Check size={18} color="#5fc27e" />
            </Col>
          </ListGroupItem>);
      } else {
        return (
          <ListGroupItem key={id}>
            <Col className="rel-dt-label col-md-4">{label}</Col>
            <Col className="col-md-8">
              <X size={18} color="#f44455" />
            </Col>
          </ListGroupItem>);
      }
    } else {
      return (<ListGroupItem key={id}>
        <Col className="rel-dt-label col-md-4">{label}</Col>
        <Col className="col-md-8">
          <CustomInput
            id={id}
            type="checkbox"
            name={name}
            disabled={!toggleEdit}
            checked={!!value}
            onChange={(e) => handleCheckChangeBusiness(e)}
          />
        </Col>
      </ListGroupItem>);
    }
  };

  const handleCheckChangeBusiness = ({ target: { checked } }) => {
    setIsBusiness(checked ? 1 : 0);
  };

  const selectFormatter = ({ id, value, setFunc, opts }) =>
    <Select
      id={id}
      className="react-select-container"
      classNamePrefix="react-select"
      options={opts}
      value={value || null}
      maxMenuHeight={300}
      onChange={(e) => setFunc(e)}
    />;

  const phoneFormatter = (data) => {
    if (data) {
      return formatPhoneNr(data);
    } else {
      return data;
    }
  };

  const listItemFormatter = ({ label, nonEditable, ...props }) => {
    const propsVal = props && props.value;
    const propsLabel = propsVal && typeof propsVal === 'object' ? props.value.label : propsVal;
    const propsObjVal = propsVal && typeof propsVal === 'object' && props.value.value;

    return (
      <ListGroupItem key={props.id}>
        <Col className="rel-dt-label col-md-4">{label}</Col>
        <Col className="col-md-8">
          {toggleEdit && props.opts && !nonEditable ? selectFormatter({ ...props }) : null}
          {toggleEdit && !props.opts && !nonEditable ? inputFormatter({ ...props }) : null}
          {(!toggleEdit || nonEditable) ? (props.value ?
            (props.id === 'Status') ?
              <Badge color={
                propsObjVal && parseInt(propsObjVal) === 1 ? 'success' :
                  (propsObjVal || propsObjVal === 0 && parseInt(propsObjVal) === 0 ? 'warning' : 'info')
              } className="badge-pill mr-1 mb-1 ja">
                {propsLabel}
              </Badge>
              : propsLabel
            : String.fromCharCode(8212))
            : null}
        </Col>
      </ListGroupItem>
    )
  };

  return (
    <div className="f2x-rel-det-wrap relation-details mt-4">
      {titleUpdate && <Helmet>
        <title>{titleUpdate}</title>
      </Helmet>}
      <Row>
        <Col className="mb-1">
          <h5>Relation #{id} - {customerNo ? customerNo : `...`}</h5>
        </Col>
        <Col className="mb-1">
          {toggleEdit ?
            <OverlayTrigger
              key={`cancel-edit-relation-details-tooltip-overlay`}
              placement={`bottom`}
              transition={false}
              delay={200}
              overlay={
                <Tooltip
                  className="tooltip"
                  container="body"
                  id={`tooltip-bottom-cancel-edit-relation-details`}
                >
                  {`Cancel`}
                </Tooltip>}
            >

              <Button color="gray" className="d-flex ml-auto btn" style={{ height: "31px", alignItems: "center" }} onClick={() => toggleForm()}>
                <FontAwesomeIcon color="grey" icon={faTimes} />
              </Button>
            </OverlayTrigger>
            :
            <OverlayTrigger
              key={`edit-relation-details-tooltip-overlay`}
              placement={`bottom`}
              transition={false}
              delay={200}
              overlay={
                <Tooltip
                  className="tooltip"
                  container="body"
                  id={`tooltip-bottom-edit-relation-details`}
                >
                  {`Edit`}
                </Tooltip>}
            >
              <Button color="gray" className="d-flex ml-auto btn" style={{ height: "31px", alignItems: "center" }} onClick={() => toggleForm()}>
                <Edit3 size={15} color="grey" />
              </Button>
            </OverlayTrigger>
          }
        </Col>
      </Row>
      <Row className="f2x-rel-det-cont">
        <Col className="col-12">
          <Form className="f2x-rel-det-form" onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col className="col-md-6 col-sm-12 col-xs-12 col-12">
                <Card className="mb-20 relation-details-card">
                  <CardBody>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem className="pb-2 pl-0 list-group-item-header">Personal</ListGroupItem>
                      {listItemFormatter({ label: 'Customer no.', value: customerNo, nonEditable: true, id: 'CustomerNo' })}
                      {listItemFormatter({ label: 'Email', value: email, setFunc: setEmail, id: 'Email' })}
                      <ListGroupItem>
                        <Col className="rel-dt-label col-md-4">Phone / fax</Col>
                        <Col className="col-md-8 d-flex align-items-center phone-fax">
                          {toggleEdit ? inputFormatter({ name: 'phone', value: phone, setFunc: setPhone, id: 'Phone' }) : phone ? phoneFormatter(phone) : String.fromCharCode(8212)}
                          <span className="input-divider"> / </span>
                          {toggleEdit ? inputFormatter({ name: 'fax', value: fax, setFunc: setFax, id: 'Fax' }) : fax ? phoneFormatter(fax) : String.fromCharCode(8212)}
                        </Col>
                      </ListGroupItem>
                      {detailsCustGroup.map(r => listItemFormatter({ ...r }))}
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-md-6 col-sm-12 col-xs-12 col-12">
                <Card className="mb-20 relation-details-card">
                  <CardBody className="top-border" style={{ paddingBottom: "5px" }}>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem className="pb-2 pl-0 list-group-item-header">Business</ListGroupItem>
                      {checkboxFormatter({ id: 'is_business', name: 'is_business', label: 'Is business', value: isBusiness, toggleEdit: toggleEdit })}
                      {detailsCompGroup.map(r => listItemFormatter({ ...r }))}
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {toggleEdit &&
              <Row>
                <Col className="col-md-12 d-flex justify-content-between rel-det-btn">
                  <Button className="mr-3" color="danger" onClick={() => toggleForm()}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                  <Button color="primary">
                    <FontAwesomeIcon icon={faSave} />
                  </Button>
                </Col>
              </Row>
            }
          </Form>
        </Col>
      </Row>

      <Row className="mt-4 f2x-det-cont-notes">
        <Col>
          <NotesTable
            id={id}
            getApi={{ ok: true, data: { data: notes } }}
            addApi={AddRelationNotes}
            hasQuill
            disableLoader
            isRawData
          />
        </Col>
      </Row>
    </div>
  )
};

const mapStateToProps = ({ relation, subscription, invoice, dispatch, user }) => ({ relation, subscription, invoice, dispatch, user });

export default withRouter(connect(mapStateToProps)(RelationDetails));
