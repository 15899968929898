import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from "dompurify";
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalFooter, ModalHeader } from 'reactstrap';

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  //console.log("EmailTemplatePreview - afterSanitizeAttributes - node ->", node);
  //console.log("EmailTemplatePreview - afterSanitizeAttributes - node.tagName ->", node.tagName);

  if (node.tagName === 'IMG') {
    //console.log("EmailTemplatePreview - afterSanitizeAttributes - img node ->", node);

    let pattern = "\{\{(.*?)\}\}";
    let replaceStaticUrl = "https://static.f2x.nl";

    //Regex.Matches(input, pattern);

    if (node.getAttribute('src').match(pattern)) {
      //console.log("EmailTemplatePreview - afterSanitizeAttributes - img node found {{}} ->", node);
      //console.log("EmailTemplatePreview - afterSanitizeAttributes - img node found {{}} - getAttribute src ->", node.getAttribute('src'));

      let img_src = node.getAttribute('src');
      //console.log("EmailTemplatePreview - afterSanitizeAttributes - img node found {{}} - img_src ->", img_src);
      let new_img_src = img_src.replace("{{config('app.asset_url')}}", replaceStaticUrl);
      //new_img_src = img_src.replace("?v={{Str::random(6)", "");
      //console.log("EmailTemplatePreview - afterSanitizeAttributes - img node found {{}} - new_img_src ->", new_img_src);
      node.setAttribute('src', new_img_src.replace("}}", "") + `?v=`);
      //{{config('app.asset_url')}}
    }
  }

  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
});

const EmailTemplatePreview = ({show, hide, selectedData, ...props}) => {
  //console.log("EmailTemplatePreview - props ->", props);

  const [templateHtmlSanitized, setTemplateHtmlSanitized] = useState(null);

  useEffect(() => {    
    //console.log("EditTemplatePDF - useEffect - selectedData ->", selectedData);

    let totalHtml = "";
    let totalHtmlSanitized = "";

    if (selectedData) {
      // header
      if (selectedData.body_html) {
        totalHtmlSanitized += DOMPurify.sanitize(selectedData.body_html);
        //totalHtmlSanitized += DOMPurify.sanitize(selectedData.body_html, {SAFE_FOR_TEMPLATES: true});
        totalHtml += selectedData.header_html;
      }
    }
    //console.log("EditTemplatePDF - useEffect - totalHtmlSanitized ->", totalHtmlSanitized);

    setTemplateHtmlSanitized(totalHtmlSanitized);

  }, [selectedData]);

  return (
      <Modal className="modal-xl fullscreen" isOpen={ show } toggle={ hide } fullscreen>
        <Form onSubmit={ (e) => this.handleSubmit(e) }>
          <div className="playground-container">
            <ModalHeader style={{ borderBottom: "none" }}>
              <span>Email preview template</span>
            </ModalHeader>

            <div className="playground-panel" style={{ padding: "1rem" }}>
              {templateHtmlSanitized &&
              <div dangerouslySetInnerHTML={{__html: templateHtmlSanitized}}
                   style={{borderBottom: "none", border: "none"}}/>
              }
            </div>

            <ModalFooter className="justify-content-between mt-0">
              <Button className="btn btn-danger" onClick={ hide } data-dismiss="modal">
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </ModalFooter>
          </div>
        </Form>
      </Modal>
  );
};

export default EmailTemplatePreview;
