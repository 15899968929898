import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input, Row, Col, Table, InputGroupAddon, InputGroup } from "reactstrap";
import { GetPlanSubscriptionLineTypes, GetContractPeriodList, GetStatusList, GetSubscriptionStatuses, AddSubscription, GetNetworkOperatorList } from '../../../controllers/subscriptions';
import { GetRelation, GetRelationList, GetOptionAddress, GetOptionPersons } from '../../../controllers/relations';
import { GetPlanList, GetPlan, GetSinglePlan, GetPlans, GetPlanLines } from '../../../controllers/plans';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate } from "../../../redux/actions/validateActions";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { parseDateToSaveFormat } from '../../../redux/actions/formatActions';
import ButtonSpinner from '../../../components/ButtonSpinner';

class SubscriptionForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formType: 'Add',
            formName: 'subscription',
            status: {
                label: 'CONCEPT / DRAFT',
                value: 20
            },
            subscription: {
                status: 20,
                relation_id: props.relationId ? props.relationId : null
            },
            statusOpts: [],
            subscription_start: '',
            subscription_stop: '',
            billing_start: '',
            wish_date: '',
            cost_center: null,
            updateDetails: {},
            plan_lines: null,
        };
    }

    clearDateSelected(e, name) {
        e.preventDefault();

        let subscription = Object.assign({}, this.state.subscription);
        subscription[name] = null;

        this.setState({ subscription });
    }

    handleInputChange({ target: { name, value } }) {
        let subscription = Object.assign({}, this.state.subscription);
        subscription[name] = value;

        this.setState({ subscription })
    }

    handleSelectChange(name, data) {
        let subscription = Object.assign({}, this.state.subscription);
        subscription[name] = data.value;

        console.log("handleSelectChange - name ->", name);
        console.log("handleSelectChange - data ->", data);

        if (name === 'relation_id') {
            this.getAddressesAndPersons(data.value, 'handleSelectChange');

        } else if (name === 'plan_id') {
            subscription.description = data.label;
            this.getPlanLines(data.value);
        }

        this.setState({
            subscription,
            [name]: data
        })
    }

    handleDatePickerChange = async (name, date) => {
        let subscription = Object.assign({}, this.state.subscription);
        const { newDate } = this.props.dispatch(validateDate(date));

        subscription[name] = newDate;

        await this.setState({ subscription });

        if (name === 'subscription_start') {
            await (async () => {
                const plan_start = this.state.plan_id && this.state.plan_id.plan_start ? this.state.plan_id.plan_start : null;
                await (() => {
                    let subscription = Object.assign({}, this.state.subscription);
                    if (plan_start && (new Date(newDate) < new Date(plan_start))) {
                        subscription.plan_id = null;
                        subscription.description = '';

                        this.setState({
                            subscription,
                            plan_id: null,
                            plan_lines: null
                        });

                        Toast.fire({
                            title: 'The plan you selected is not available on this start date!',
                            icon: 'warning'
                        })
                    }
                })();

                this.reloadPlans(newDate);
            })()
        }
    };

    reloadPlans = (subscription_start) => {
        let plans = Object.assign([], this.state.initPlans);

        if (plans && plans.length && plans.length > 0) {
            plans.map(({ plan_start, plan_stop }, i) => {
                if (new Date(subscription_start) >= new Date(plan_start) && (!plan_stop || (plan_stop && new Date(subscription_start) < new Date(plan_stop)))) {
                    return false
                } else {
                    return plans.splice(i, 1)
                }
            })
        }
        this.setState({ plans });

    };

    getPlanLines(id) {
        console.log("getPlanLines - id ->", id);

        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        (async () => {
            //const { ok, data } = await GetPlan(null, id);
            //const { ok, data } = await GetSinglePlan(null, id);
            const { ok, data } = await GetPlanLines(null, id);
            console.log("getPlanLines - data ->", data);
            if (ok && data && data.data) {
                //const { plan_lines } = data.data;

                this.setState({ plan_lines: data.data })
            }
        })();

        dispatch(loaderToggle(false));
    }

    loadOptions = (value, callback) => {
        const filter = 'filter[keyword]',
            params = { [filter]: value };

        //const search = 'search',
        //params = { [search]: value };

        (async () => {
            const { ok, data } = await GetRelationList(params);
            if (ok) {
                let customers = [];
                data.data.map(({ name, id, customer_number, primary_person_full_name, billing_address }) => {
                    return customers.push({
                        label: name,
                        value: id,
                        customer_number,
                        primary_person_full_name,
                        billing_address
                    })
                });
                callback(customers);

            }
        })()
    };

    handleKeyDown = ({ which }, name) => {
        if (name && which === 9) {
            this[name].setOpen(false);
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        const { subscription } = this.state;
        console.log("handleSubmit - subscription ->", subscription);

        const pageURL = window.location.href;
        const URLSegments = pageURL.match(/(?!:\d+)(\/\w+)(\/\w+)/g);
        const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/'));
        const { dispatch, hide, loader, relation, /*history, */updateDetails, subscriptionList, setSelectedSubscr } = this.props,
            { description, relation_id, subscription_start, type, billing_start, /*provisioning_address, provisioning_address_id, provisioning_person_id, /*provisioning_person, billing_address, billing_address_id, billing_person_id,*/ /*billing_person,*/ plan_id } = subscription, //this.state.subscription,
            { provisioning_person_id, provisioning_address_id, billing_person_id, billing_address_id/* provisioning_person, billing_person, provisioning_address,*/ } = this.state;

        console.log("handleSubmit - this.props ->", this.props);
        console.log("handleSubmit - updateDetails ->", updateDetails);
        //console.log("handleSubmit - provisioning_address ->", provisioning_address);
        console.log("handleSubmit - provisioning_address_id ->", provisioning_address_id);
        //console.log("handleSubmit - provisioning_person ->", provisioning_person);
        console.log("handleSubmit - provisioning_person_id ->", provisioning_person_id);
        //console.log("handleSubmit - billing_address ->", billing_address);
        console.log("handleSubmit - billing_address_id ->", billing_address_id);
        //console.log("handleSubmit - billing_person ->", billing_person);
        console.log("handleSubmit - billing_person_id ->", billing_person_id);

        let notificationsToastArr = [];

        if (!description) {           
            notificationsToastArr.push({
                title: "Description field is required!",
                icon: "warn",
            });

        }
        if (!relation_id) {            
            notificationsToastArr.push({
                title: "Customer # field is required!",
                icon: "warn",
            });

        }
        if (!subscription_start) {            
            notificationsToastArr.push({
                title: "Subscription start field is required!",
                icon: "warn",
            });

        }
        if (!type) {            
            notificationsToastArr.push({
                title: "Subscription type field is required!",
                icon: "warn",
            });
        }
        if (!provisioning_address_id || !provisioning_person_id) {            
            notificationsToastArr.push({
                title: "Please complete provisioning address fields!",
                icon: "warn",
            });

        }
        if (!billing_address_id || !billing_person_id) {
            notificationsToastArr.push({
                title: "Please complete billing address fields!",
                icon: "warn",
            });
        }
        if (plan_id && !(new Date(this.state.plan_id.plan_start) <= new Date(subscription_start))) {
            notificationsToastArr.push({
                title: "The chosen plan is not available on the subscription start date.",
                icon: "warn",
            });
        }

        if (notificationsToastArr.length > 0) {
            return ToastMulti(notificationsToastArr, "container-validation-id");
        }

        //let newSubscription = this.state.subscription;
        if (subscription && subscription.subscription_start) {
            subscription.subscription_start = parseDateToSaveFormat(subscription.subscription_start);
        }
        if (subscription && subscription.subscription_stop) {
            subscription.subscription_stop = parseDateToSaveFormat(subscription.subscription_stop);
        }
        if (!billing_start) {
            subscription.billing_start = null;

        } else {
            if (subscription && subscription.billing_start) {
                subscription.billing_start = parseDateToSaveFormat(
                    subscription.billing_start);
            }
        }
        // wish_date
        if (subscription && subscription.wish_date) {
            subscription.wish_date = parseDateToSaveFormat(
                subscription.wish_date);
        }


        if (provisioning_person_id) {
            subscription.provisioning_person_id = provisioning_person_id;
        }
        if (billing_person_id) {
            subscription.billing_person_id = billing_person_id;
        }
        if (billing_address_id) {
            subscription.billing_address_id = billing_address_id;
        }
        if (provisioning_address_id) {
            subscription.provisioning_address_id = provisioning_address_id;
        }
        //if (provisioning_address) {
        //    newSubscription.provisioning_address = provisioning_address;
        //}
        //console.log("handleSubmit - newSubscription ->", newSubscription);

        //return false;

        const { isLoading } = this.state;
        if (!isLoading && !loader) {
            (async () => {
                dispatch(loaderToggle(true));
                this.setState({ isLoading: true });

                const { ok, data } = await AddSubscription(subscription);
                if (ok && data) {

                    console.log("AddSubscription - data.data ->", data.data);
                    console.log("AddSubscription - this.props ->", this.props);
                    const { subscriptionList } = this.props;

                    //console.log("AddSubscription - relation(subscriptionList) ->", relation.subscriptionList);
                    console.log("AddSubscription - subscriptionList(list) ->", subscriptionList);
                    console.log("AddSubscription - subscription(subscriptionList) ->", subscription.subscriptionList);
                    console.log("AddSubscription - lastURLSegment ->", lastURLSegment);
                    console.log("AddSubscription - URLSegments ->", URLSegments);

                    let newSubscrId = data.data.id;
                    let newData = data.data;
                    let relation_id = this.state.subscription && this.state.subscription.relation_id ? this.state.subscription.relation_id : null;

                    let newDataSubscriptionList;
                    //if (relation.subscriptionList && relation.subscriptionList.length > 0) {
                    if (subscriptionList && subscriptionList.length > 0) {
                        //newDataSubscriptionList = [...relation.subscriptionList, newData];
                        newDataSubscriptionList = [...subscriptionList, newData];
                        //if (URLSegments) {
                        this.props.setData([...subscriptionList, newData]);
                        //}

                        //console.log("AddSubscription - subscriptionList(list) updated after length > 0 ->", newDataSubscriptionList);

                        dispatch({ type: 'RELATION', payload: { subscriptionList: newDataSubscriptionList } });
                        dispatch({ type: 'SUBSCRIPTION', payload: newData });
                        dispatch({ type: 'SUBSCRIPTION', payload: { subscriptionList: newDataSubscriptionList } });

                    } else {
                        newDataSubscriptionList = [];
                        newDataSubscriptionList[0] = newData;

                        console.log("AddSubscription - subscriptionList(list) updated after length === 0 ->", newDataSubscriptionList);

                        dispatch({ type: 'SUBSCRIPTION', payload: { subscriptionList: newDataSubscriptionList } });
                        dispatch({ type: 'SUBSCRIPTION', payload: newData });
                        dispatch({ type: 'RELATION', payload: { subscriptionList: newDataSubscriptionList } });

                    }

                    console.log("AddSubscription - subscriptionList(newDataSubscriptionList) ->", newDataSubscriptionList);
                    console.log("AddSubscription - newSubscrId ->", newSubscrId);
                    this.setState({ subscription });


                    sessionStorage.removeItem('lastVisitSubscrId');

                    dispatch({ type: 'SUBSCRIPTION', payload: subscription });

                    // set localstorage
                    sessionStorage.setItem('lastVisitSubscrId', newSubscrId);

                    //updateDetails(newSubscrId, 'add', newDataSubscriptionList);

                    if (!URLSegments) {
                        //updateDetails(newSubscrId, 'add', newDataSubscriptionList);
                        this.props.history.push(`/relations/${relation_id}/subscriptions/${newSubscrId}`);
                        //    this.props.updateDetails(newSubscrId, 'add', newDataSubscriptionList);
                    } else {
                        setSelectedSubscr(newSubscrId);

                        updateDetails(newSubscrId, 'add', newDataSubscriptionList);
                        //this.props.history.push(`/relations/${relation_id}/subscriptions/${newSubscrId}`);
                    }
                    hide();

                    await Toast.fire({
                        title: 'Subscription has been created.', icon: 'success'
                    }).then(r => {
                        console.log('Error response: ', r);
                    });
                }
            })();
        }

        this.setState({ isLoading: false });
        dispatch(loaderToggle(false));

    }

    getAddressesAndPersons(relationId, from) {
        const { dispatch } = this.props;
        dispatch(loaderToggle(true));

        this.setState({
            provisioning_address: null,
            provisioning_address_id: null,
            provisioning_person: null,
            provisioning_person_id: null,
            billing_address: null,
            billing_address_id: null,
            billing_person: null,
            billing_person_id: null,
        });

        (async () => {
            const [person, address] = await Promise.all([
                GetOptionPersons(null, relationId),
                GetOptionAddress(null, relationId)
            ]);

            //console.log("subscription form - getAddressesAndPersons - person ->", person);
            //console.log("subscription form - getAddressesAndPersons - address ->", address);

            await (async () => {
                if (person.ok) {
                    let persons = [];

                    await person.data.data.map(async ({ full_name, id, primary }) => {
                        await (() => {
                            if (primary) {
                                let subscription = Object.assign({}, this.state.subscription);

                                subscription.provisioning_person = id;
                                subscription.provisioning_person_id = id;
                                subscription.billing_person = id;
                                subscription.billing_person_id = id;

                                //console.log("subscription form - getAddressesAndPersons - subscription ->", subscription);

                                this.setState({
                                    subscription,
                                    provisioning_person: {
                                        label: full_name,
                                        value: id
                                    },
                                    provisioning_person_id: id,
                                    billing_person: {
                                        label: full_name,
                                        value: id
                                    },
                                    billing_person_id: id,
                                })
                            }
                        })();

                        return persons.push({
                            label: full_name,
                            value: id
                        })
                    });

                    await this.setState({ persons });

                    const latestPerson = persons[persons.length - 1];

                    //console.log("subscription form - getAddressesAndPersons - latestPerson ->", latestPerson);
                    //console.log("subscription form - getAddressesAndPersons - this.state ->", this.state);
                    //console.log("subscription form - getAddressesAndPersons - this.state.provisioning_person_id ->", this.state.provisioning_person_id);
                    //console.log("subscription form - getAddressesAndPersons - this.state.provisioning_person ->", this.state.provisioning_person);
                    //console.log("subscription form - getAddressesAndPersons - this.state.billing_person ->", this.state.billing_person);
                    //console.log("subscription form - getAddressesAndPersons - this.state.billing_person_id ->", this.state.billing_person_id);

                    await (() => {
                        if (!this.state.provisioning_person && !this.state.provisioning_person_id) {
                            //if (!this.state.provisioning_person_id) {
                            let subscription = Object.assign({}, this.state.subscription);

                            subscription.provisioning_person = latestPerson ? latestPerson.value : null;
                            subscription.provisioning_person_id = latestPerson ? latestPerson.value : null;

                            this.setState({
                                subscription,
                                provisioning_person: latestPerson,
                                provisioning_person_id: latestPerson ? latestPerson.value : null,
                            })
                        }
                        //})();

                        if (!this.state.billing_person && !this.state.billing_person_id) {
                            //if (!this.state.billing_person_id) {
                            let subscription = Object.assign({}, this.state.subscription);

                            subscription.billing_person = latestPerson ? latestPerson.value : null;
                            subscription.billing_person_id = latestPerson ? latestPerson.value : null;

                            this.setState({
                                subscription,
                                billing_person: latestPerson,
                                billing_person_id: latestPerson ? latestPerson.value : null,
                            })
                        }
                    })();
                }

                //console.log("subscription form - getAddressesAndPersons - this.state ->", this.state);

                if (address.ok) {
                    let provisioning_addresses = [],
                        billing_addresses = [];

                    await address.data.data.map(async ({ address_type, full_address, id, primary }) => {
                        if (address_type === 'Provisioning') {
                            await (() => {
                                if (primary) {
                                    let subscription = Object.assign({}, this.state.subscription);

                                    subscription.provisioning_address = id;
                                    subscription.provisioning_address_id = id;

                                    this.setState({
                                        subscription,
                                        provisioning_address: {
                                            label: full_address,
                                            value: id
                                        },
                                        provisioning_address_id: id,
                                    })
                                }
                            })();

                            return provisioning_addresses.push({
                                label: full_address,
                                value: id,
                            });

                        } else if (address_type === 'Billing') {
                            await (() => {
                                if (primary) {
                                    let subscription = Object.assign({}, this.state.subscription);

                                    subscription.billing_address = id;
                                    subscription.billing_address_id = id;

                                    this.setState({
                                        subscription,
                                        billing_address: {
                                            label: full_address,
                                            value: id
                                        },
                                        billing_address_id: id,
                                    })
                                }
                            })();

                            return billing_addresses.push({
                                label: full_address,
                                value: id,
                            });

                        } else {
                            return false
                        }
                    });

                    await this.setState({
                        provisioning_addresses,
                        billing_addresses
                    });

                    const latestProvisioningAddress = provisioning_addresses[provisioning_addresses.length - 1],
                        latestBillingAddress = billing_addresses[billing_addresses.length - 1];

                    await (() => {
                        if (!this.state.provisioning_address) {
                            let subscription = Object.assign({}, this.state.subscription);

                            subscription.provisioning_address = latestProvisioningAddress ? latestProvisioningAddress.value : null;
                            subscription.provisioning_address_id = latestProvisioningAddress ? latestProvisioningAddress.value : null;

                            this.setState({
                                subscription,
                                provisioning_address: latestProvisioningAddress,
                                provisioning_address_id: latestProvisioningAddress ? latestProvisioningAddress.value : null,
                            })
                        }
                    })();

                    if (!this.state.billing_address) {
                        let subscription = Object.assign({}, this.state.subscription);

                        subscription.billing_address = latestBillingAddress ? latestBillingAddress.value : null;
                        subscription.billing_address_id = latestBillingAddress ? latestBillingAddress.value : null;

                        this.setState({
                            subscription,
                            billing_address: latestBillingAddress,
                            billing_address_id: latestBillingAddress ? latestBillingAddress.value : null,
                        })
                    }
                }
            })();

            dispatch(loaderToggle(false));

        })();
    }

    formatCurrency = (current_line_price) => {
        //console.log("Subscription Form - formatCurrency - current_line_price ->", current_line_price);
        const { t } = this.props;
        if (current_line_price && current_line_price.price_excl_vat && parseFloat(current_line_price.price_excl_vat)) {
            let floatCellExVat = current_line_price.price_excl_vat ? current_line_price.price_excl_vat : 0;
            let floatCellInVat = current_line_price.price_incl_vat ? current_line_price.price_incl_vat : 0;

            return (
                <span className={'float-zero'}>
                    {t("currency_format", { number: Number(parseFloat(floatCellExVat)) }) + " / " + t("currency_format", { number: Number(parseFloat(floatCellInVat)) })}
                </span>
            );
        } else {
            return (
                <span className={'float-zero'}>
                    {t("currency_format", { number: Number(0) }) + " / " + t("currency_format", { number: Number(0) })}
                </span>)
        }
    };

    componentDidMount() {
        const { dispatch } = this.props;

        //console.log("SubscriptionForm - (componentDidMount) this.props ->", this.props);

        (async () => {
            const relationId = this.props.relationId;
            const { lineTypes, statusList, contractPeriods } = this.state.subscription;
            if ((lineTypes && lineTypes.length === 0) || (statusList && statusList.length === 0) || contractPeriods < 1) {
                const [line_types, contract_periods, statuses] = await Promise.all([
                    GetPlanSubscriptionLineTypes(),
                    GetContractPeriodList(),
                    GetSubscriptionStatuses()
                    //GetStatusList(4)
                ]);

                if (line_types.ok) {
                    const { data } = line_types.data;
                    Object.assign(lineTypes, data)
                }

                if (statuses.ok) {
                    const { data } = statuses.data;
                    Object.assign(statusList, data)
                }

                if (contract_periods.ok) {
                    const { data } = contract_periods.data;
                    Object.assign(contractPeriods, data)
                }

                dispatch({ type: 'SUBSCRIPTION', payload: { lineTypes, statusList, contractPeriods } })
            }

            const [relationList, plan, line_type, contract_period, status_list, getNetworkOperators] = await Promise.all([
                GetRelationList(),
                GetPlans(),
                GetPlanSubscriptionLineTypes(),
                GetContractPeriodList(),
                GetSubscriptionStatuses(),
                GetNetworkOperatorList(),
            ]);

            //console.log("SubscriptionForm - (GetRelationList) relationList ->", relationList);
            //console.log("SubscriptionForm - (GetPlanList) plan ->", plan);
            //console.log("SubscriptionForm - status_list ->", status_list);

            await (() => {
                if (relationList && relationList.ok) {
                    let customers = [];

                    relationList.data.data.map(({ name, id, customer_number, primary_person_full_name, billing_address }) => {
                        return customers.push({
                            label: name,
                            value: id,
                            customer_number,
                            primary_person_full_name,
                            billing_address
                        });
                    });

                    this.setState({ customers });

                    if (relationId) {
                        const getCustomer = customers.find(customer => parseInt(customer.value) === parseInt(relationId));

                        if (getCustomer) {
                            let subscription = Object.assign({}, this.state.subscription);

                            subscription.relation_id = getCustomer.value;

                            this.setState({
                                subscription,
                                relation_id: getCustomer
                            });

                            this.getAddressesAndPersons(getCustomer.value, 'getCustomer');

                        } else {
                            (async () => {
                                const { ok, data } = await GetRelation(null, relationId);

                                if (ok) {
                                    let subscription = Object.assign({}, this.state.subscription);
                                    const relation_data = Object.assign({}, data.data);

                                    subscription.relation_id = relationId;

                                    const relation_id = {
                                        label: relation_data.customer_number,
                                        value: relationId,
                                        customer_number: relation_data.customer_number,
                                        primary_person_full_name: relation_data.primary_person_full_name,
                                        billing_address: relation_data.billing_address
                                    };

                                    customers.unshift(relationId);

                                    this.setState({
                                        subscription,
                                        customers,
                                        relationId,
                                        relation_id
                                    });

                                    this.getAddressesAndPersons(relationId, 'getRelation');

                                }
                            })();
                        }
                    }
                }

                if (plan.ok) {
                    let plans = [];
                    plan.data.data.map(({ description, id, plan_start, plan_stop }) => {
                        return plans.push({
                            label: description,
                            value: id,
                            plan_start,
                            plan_stop
                        })
                    });

                    this.setState({
                        plans,
                        initPlans: plans
                    })
                }

                if (line_type.ok) {
                    let subscription = Object.assign({}, this.state.subscription),
                        subscription_types = [];

                    line_type.data.data.map(({ line_type, id }) => {

                        if (line_type === 'YRC' || line_type === 'QRC' || line_type === 'MRC') {
                            if (line_type === 'MRC') {
                                subscription.type = id;
                                this.setState({
                                    type: {
                                        label: line_type,
                                        value: id
                                    }
                                })
                            }

                            return subscription_types.push({
                                label: line_type,
                                value: id
                            })
                        } else {
                            return false
                        }
                    });

                    this.setState({
                        subscription,
                        subscription_types
                    })
                }

                if (contract_period.ok) {
                    let contract_periods = [];

                    contract_period.data.data.map(({ id, period, net_days }) => {
                        return contract_periods.push({
                            label: period,
                            value: id
                        })
                    });

                    this.setState({ contract_periods })
                }

                if (status_list.ok) {
                    let statusOpts = [];

                    status_list.data.data.map(({ id, status, label }) => {
                        return statusOpts.push({
                            label: label,
                            value: id
                        })
                    });

                    this.setState({ statusOpts })
                }

                if (getNetworkOperators.ok) {
                    let networkOperatorOpts = [];

                    getNetworkOperators.data.data.map(({ label, id }) => {
                        return networkOperatorOpts.push({
                            label: label,
                            value: id
                        })
                    });
                    this.setState({ networkOperatorOpts });

                }
            })();
            this.setState({ hasLoaded: true });

        })();
    }

    handleDateData = (data) => {
        return data ? new Date(data) : data
    };

    render() {
        const { show, hide, loader, id } = this.props,
            { formType,
                formName,
                hasLoaded,
                subscription,
                customers,
                relation_id,
                plans,
                plan_id,
                status,
                subscription_status, // was disabled ??
                statusOpts,
                contract_periods,
                contract_period_id,
                subscription_types,
                type,
                persons,
                provisioning_addresses,
                provisioning_address,
                provisioning_address_id,
                provisioning_person,
                provisioning_person_id,
                billing_addresses,
                billing_address,
                billing_address_id,
                billing_person,
                billing_person_id,
                plan_lines,
                networkOperatorOpts,
                network_operator_id,
                isLoading } = this.state,
            { description,
                subscription_start,
                subscription_stop,
                billing_start,
                wish_date,
                cost_center } = subscription;

        //console.log("Subscription Form - subscription ->", subscription);
        console.log("Subscription Form - (this.state) plan_lines ->", plan_lines);
        //console.log("Subscription Form - (was disabled) subscription_status ->", subscription_status);

        return (
            <React.Fragment>
                {(!loader || hasLoaded) &&
                    <Modal isOpen={show} toggle={hide} centered className="modal-lg" >
                        <Form onSubmit={(e) => this.handleSubmit(e)}>
                            <ModalHeader>{formType} {formName}</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md="6">
                                        <div className="pb-2 border-bottom"><b>Subscription details</b></div>
                                        <div className="mt-2 mb-4">
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="description">Description:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Input
                                                        id="description"
                                                        name="description"
                                                        placeholder="Description"
                                                        value={description ? description : ''}
                                                        onChange={(e) => this.handleInputChange(e)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="relation_id">Customer #:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <AsyncSelect
                                                        id="relation_id"
                                                        name="relation_id"
                                                        cacheOptions
                                                        defaultOptions={customers}
                                                        loadOptions={this.loadOptions}
                                                        getOptionLabel={({ customer_number, primary_person_full_name, billing_address }) =>
                                                            `${customer_number ? customer_number : String.fromCharCode(8212)}\n${primary_person_full_name ? primary_person_full_name : String.fromCharCode(8212)}\n${billing_address ? billing_address : String.fromCharCode(8212)}`
                                                        }
                                                        className="react-select-container line-break"
                                                        classNamePrefix="react-select"
                                                        placeholder="Customer #"
                                                        value={relation_id}
                                                        onChange={this.handleSelectChange.bind(this, 'relation_id')}
                                                        maxMenuHeight={220}
                                                        //isDisabled={!!id}
                                                        isDisabled={!!relation_id}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="plan_id">Plan:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Select
                                                        id="plan_id"
                                                        className="react-select-container"
                                                        placeholder="Plan"
                                                        classNamePrefix="react-select"
                                                        options={plans}
                                                        value={plan_id}
                                                        onChange={this.handleSelectChange.bind(this, 'plan_id')}
                                                        maxMenuHeight={300}
                                                        isDisabled={!this.handleDateData(subscription_start)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            {/*<FormGroup className="row align-items-center mb-5px">
                                            <Col xs="5">
                                                <Label className="mb-0" for="network_operator_id">Network operator</Label>
                                            </Col>
                                            <Col xs="7">
                                                <Select
                                                    id="network_operator_id"
                                                    className="react-select-container"
                                                    placeholder="Network operator"
                                                    classNamePrefix="react-select"
                                                    options={networkOperatorOpts}
                                                    value={network_operator_id}
                                                    onChange={this.handleSelectChange.bind(this, 'network_operator_id')}
                                                    maxMenuHeight={300}
                                                />
                                            </Col>
                                        </FormGroup>*/}
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="status">Status:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Select
                                                        id="status"
                                                        className="react-select-container"
                                                        placeholder="Status"
                                                        classNamePrefix="react-select"
                                                        options={statusOpts}
                                                        value={status}
                                                        onChange={this.handleSelectChange.bind(this, 'status')}
                                                        maxMenuHeight={300}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="subscription_start">Subscription start:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <InputGroup>
                                                        <DatePicker
                                                            id="subscription_start"
                                                            wrapperClassName="w-100"
                                                            className="form-control"
                                                            name="subscription_start"
                                                            dateFormat="P"
                                                            autoComplete="off"
                                                            placeholderText="Subscription start"
                                                            selected={this.handleDateData(subscription_start)}
                                                            startDate={this.handleDateData(subscription_start)}
                                                            endDate={this.handleDateData(subscription_stop)}
                                                            onChange={this.handleDatePickerChange.bind(this, 'subscription_start')}
                                                            ref="subscription_start"
                                                            maxDate={this.handleDateData(subscription_stop)}
                                                            onKeyDown={(e) => e.preventDefault()}
                                                        />
                                                        <InputGroupAddon className="clear-btn" addonType="append">
                                                            <a href="#" className="danger icon-delete" onClick={(e) => this.clearDateSelected(e, 'subscription_start')}>
                                                                <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                                                            </a>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="subscription_stop">Subscription end:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <InputGroup>
                                                        <DatePicker
                                                            id="subscription_stop"
                                                            wrapperClassName="w-100"
                                                            className="form-control"
                                                            name="subscription_stop"
                                                            dateFormat="P"
                                                            autoComplete="off"
                                                            placeholderText="Subscription end"
                                                            selected={this.handleDateData(subscription_stop)}
                                                            startDate={this.handleDateData(subscription_start)}
                                                            endDate={this.handleDateData(subscription_stop)}
                                                            onChange={this.handleDatePickerChange.bind(this, 'subscription_stop')}
                                                            ref="subscription_stop"
                                                            minDate={this.handleDateData(subscription_start)}
                                                            onKeyDown={(e) => e.preventDefault()}
                                                        />
                                                        <InputGroupAddon className="clear-btn" addonType="append">
                                                            <a href="#" className="danger icon-delete" onClick={(e) => this.clearDateSelected(e, 'subscription_stop')}>
                                                                <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                                                            </a>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="pb-2 border-bottom"><b>Finance</b></div>
                                        <div className="mt-2 mb-4">
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="contract_period">Contract period:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Select
                                                        id="contract_period_id"
                                                        className="react-select-container"
                                                        placeholder="Contract period"
                                                        classNamePrefix="react-select"
                                                        options={contract_periods}
                                                        value={contract_period_id}
                                                        onChange={this.handleSelectChange.bind(this, 'contract_period_id')}
                                                        maxMenuHeight={300}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="type">Subscription type:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Select
                                                        id="type"
                                                        className="react-select-container"
                                                        placeholder="Subscription type"
                                                        classNamePrefix="react-select"
                                                        options={subscription_types}
                                                        value={type}
                                                        onChange={this.handleSelectChange.bind(this, 'type')}
                                                        maxMenuHeight={300}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="billing_start">Billing start:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <InputGroup>
                                                        <DatePicker
                                                            id="billing_start"
                                                            wrapperClassName="w-100"
                                                            className="form-control"
                                                            name="billing_start"
                                                            dateFormat="P"
                                                            autoComplete="off"
                                                            placeholderText="Billing start"
                                                            selected={this.handleDateData(billing_start)}
                                                            onChange={this.handleDatePickerChange.bind(this, 'billing_start')}
                                                            ref="billing_start"
                                                            minDate={this.handleDateData(subscription_start)}
                                                            onKeyDown={(e) => e.preventDefault()}
                                                        />
                                                        <InputGroupAddon className="clear-btn" addonType="append">
                                                            <a href="#" className="danger icon-delete" onClick={(e) => this.clearDateSelected(e, 'billing_start')}>
                                                                <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                                                            </a>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="wish_date">Wish date:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <InputGroup>
                                                        <DatePicker
                                                            id="wish_date"
                                                            wrapperClassName="w-100"
                                                            className="form-control"
                                                            name="wish_date"
                                                            dateFormat="P"
                                                            autoComplete="off"
                                                            placeholderText="Wish date"
                                                            selected={this.handleDateData(wish_date)}
                                                            onChange={this.handleDatePickerChange.bind(this, 'wish_date')}
                                                            ref="wish_date"
                                                            minDate={addDays(new Date(billing_start), 14)}
                                                            onKeyDown={(e) => e.preventDefault()}
                                                        />
                                                        <InputGroupAddon className="clear-btn" addonType="append">
                                                            <a href="#" className="danger icon-delete" onClick={(e) => this.clearDateSelected(e, 'wish_date')}>
                                                                <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                                                            </a>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="billing_start">Cost center/reference:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Input
                                                        id="cost_center"
                                                        name="cost_center"
                                                        placeholder="Cost center / reference"
                                                        value={cost_center ? cost_center : ''}
                                                        onChange={(e) => this.handleInputChange(e)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                {relation_id && provisioning_addresses && billing_addresses && persons && <Row className="mt-4">
                                    <Col md="6">
                                        <div className="pb-2 border-bottom"><b>Provisioning address</b></div>
                                        <div className="mt-2 mb-4">
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="provisioning_address">Address:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Select
                                                        id="provisioning_address"
                                                        className="react-select-container"
                                                        placeholder="Address"
                                                        classNamePrefix="react-select"
                                                        options={provisioning_addresses}
                                                        value={provisioning_address}
                                                        onChange={this.handleSelectChange.bind(this, 'provisioning_address')}
                                                        maxMenuHeight={300}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="provisioning_person">Person:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Select
                                                        id="provisioning_person"
                                                        className="react-select-container"
                                                        placeholder="Person"
                                                        classNamePrefix="react-select"
                                                        options={persons}
                                                        value={provisioning_person}
                                                        onChange={this.handleSelectChange.bind(this, 'provisioning_person')}
                                                        maxMenuHeight={300}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="pb-2 border-bottom"><b>Billing address</b></div>
                                        <div className="mt-2 mb-4">
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="billing_address">Address:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Select
                                                        id="billing_address"
                                                        className="react-select-container"
                                                        placeholder="Address"
                                                        classNamePrefix="react-select"
                                                        options={billing_addresses}
                                                        value={billing_address}
                                                        onChange={this.handleSelectChange.bind(this, 'billing_address')}
                                                        maxMenuHeight={300}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row align-items-center mb-5px">
                                                <Col xs="5">
                                                    <Label className="mb-0" for="billing_person">Person:</Label>
                                                </Col>
                                                <Col xs="7">
                                                    <Select
                                                        id="billing_person"
                                                        className="react-select-container"
                                                        placeholder="Person"
                                                        classNamePrefix="react-select"
                                                        options={persons}
                                                        value={billing_person}
                                                        onChange={this.handleSelectChange.bind(this, 'billing_person')}
                                                        maxMenuHeight={300}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>}
                                {plan_lines && <Row className="mt-4">
                                    <Col xs="12">
                                        <h5><b>Plan Lines</b></h5>
                                        <Table className={`table-subscription-add border-top`}>
                                            <thead>
                                                <tr>
                                                    <th>Description</th>
                                                    <th>Line type</th>
                                                    <th className={`text-right`}>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {plan_lines.map(({ id, description, type, current_line_price }, i) => (
                                                    <tr key={i}>
                                                        <td data-label="Description">{description ? description : String.fromCharCode(8212)}</td>
                                                        <td data-label="Line type">{type ? type : String.fromCharCode(8212)}</td>
                                                        <td data-label="Price" className={`pr-3 text-right`}>{this.formatCurrency(current_line_price)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>}
                            </ModalBody>
                            <ModalFooter className="justify-content-between">
                                <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button color="primary" disabled={isLoading}>
                                    <FontAwesomeIcon icon={faSave} />
                                    {isLoading && <ButtonSpinner />}
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => ({
    loader: store.loader,
    relation: store.relation,
    subscription: store.subscription
});

export default connect(mapStateToProps)(withTranslation()(withRouter(SubscriptionForm)));
