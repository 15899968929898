import parse from "html-react-parser";
import _ from "lodash";
import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Box } from "react-feather";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Badge, Collapse, DropdownToggle, Input, UncontrolledDropdown } from "reactstrap";
import { createSelector } from "reselect";
import { GetBrand, GetTenantLists, SwitchTenant } from '../controllers/tenants';
import { brand } from '../redux/actions/brandActions';
import { updateTenant, updateUser } from '../redux/actions/userActions';
import routes from "../routes/index";

const SidebarCategory = withRouter(
    ({ name, badgeColor, badgeText, icon: Icon, isOpen, children, onClick, location, to, sidebarCategoryRef }) => {
        const getSidebarItemClass = (path) => {
            return location.pathname.indexOf(path) !== -1 ||
                (location.pathname === "/" && path === "/dashboard")
                ? " active"
                : ""
        };

        return (
            <li className={"sidebar-item sidebar-item-child" + getSidebarItemClass(to)}>
                <span
                    data-toggle="collapse"
                    className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
                    onClick={onClick}
                    aria-expanded={isOpen ? "true" : "false"}
                >
                    <Icon size={18} className="align-middle mr-3" />
                    <span className="align-middle">{name}</span>
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} className="sidebar-badge">
                            {badgeText}
                        </Badge>
                    ) : null}
                </span>
                <Collapse
                    isOpen={isOpen}
                    ref={(el) => { sidebarCategoryRef = el }}
                >
                    <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                        {children}
                    </ul>
                </Collapse>
            </li>
        )
    }
);

const SidebarItem = withRouter(
    ({ name, badgeColor, badgeText, icon: Icon, location, to, brand, sidebarItemRef }) => {

        const getSidebarItemClass = (path) => {
            let basePath = `/${location.pathname.split('/')[1]}`;

            if (basePath === "/admin") {
                basePath = `${location.pathname}`;
            }

            return basePath === path ? " active" : "";
        };

        return (
            <li className={"sidebar-item" + getSidebarItemClass(to)}>
                <NavLink
                    to={to}
                    className="sidebar-link"
                    activeClassName="active"
                >
                    {Icon ? <Icon color={brand.theme.light} size={18} className="align-middle mr-3" /> : null}
                    {name}
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} className="sidebar-badge">
                            {badgeText}
                        </Badge>
                    ) : null}
                </NavLink>
            </li>
        )
    }
);

class Sidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tenant: '',
            tenants: {},
            groups: [0, 1],
            isShow: false,
            togglePreview: false,
            searchTerm: '' // Initialize searchTerm
        };

        this.sidebarCategoryRef = React.createRef();
        this.sidebarItemRef = React.createRef();
        this.tenantMenuRef = React.createRef();

        this.handleClickTenantMenuOutside = this.handleClickTenantMenuOutside.bind(this); 
        //console.log("Sidebar - constructor - props ->", props);
    }

    handleClickTenantMenuOutside (e) {
        // check if event target offsetParent !== this.tenantMenuRef.current.dialog
        if (e && e.target && this.tenantMenuRef && this.tenantMenuRef.current && this.tenantMenuRef.current.dialog && this.tenantMenuRef.current.dialog !== e.target.offsetParent) {
            this.handleClose();
        }
    };

    toggle = index => {
        this.setState(state => ({
            [index]: !state[index]
        }))
    };


    tenantsPreviewAction = () => {
        this.setState({
            togglePreview: !this.state.togglePreview,
        });
    }

    handleClose = (e) => {
        //console.log("Plans - offcanvas handle close");
        //console.log("Plans - offcanvas handle close - togglePreview ->", this.state.togglePreview);

        this.setState({
            togglePreview: false
        });

        const targetEl = document.getElementById("root");
        if (targetEl) {
            targetEl.style.removeProperty("width");
        }
    };


    changeTenant(tenant) {

        this.handleClose();

        const { dispatch, theme } = this.props;

        (async () => {
            const [switchTenant, getBrand] = await Promise.all([
                SwitchTenant(tenant.id),
                GetBrand(null, tenant.id)
            ]);

            //console.log("Sidebar - changeTenant - this.props ->", this.props);
            //console.log("Sidebar - changeTenant - switchTenant ->", switchTenant);
            //console.log("Sidebar - changeTenant - getBrand ->", getBrand);

            if (getBrand.ok) {
                let brandData = Object.assign({}, getBrand.data.data);

                if (brandData && brandData.theme) {
                    brandData.theme = JSON.parse(brandData.theme)
                } else {
                    brandData.theme = theme.currentTheme
                }

                if (brandData.theme) {
                    dispatch(brand(brandData));
                }
            }

            if (switchTenant.ok && getBrand.ok) {
                this.setState({ tenant: tenant.name });

                const locationArr = this.props.location.pathname.split('/'),
                    tenant_id = switchTenant.data.data.id,
                    default_country_id = switchTenant.data.data.default_country_id;

                //clearSessionStorage();

                sessionStorage.removeItem('lastVisitSubscrId');
                sessionStorage.removeItem('lastVisitInvId'); // lastVisitInvoiceId

                sessionStorage.setItem('tenant', JSON.stringify(switchTenant.data.data));
                sessionStorage.setItem('default_country_id', default_country_id ? default_country_id : 155); // default NL -> 155

                // SolrSearch -> id-input-solr-search & DataTables - for clearing input fields (hack)
                let formElements = Array.from(document.querySelectorAll('input'));
                //console.log("Sidebar - changeTenant - formElements ->", formElements);
                if (formElements) {
                    formElements.forEach(
                        input => (input.value = "")
                    );
                }
                // DataTables - toggleHeaderActionBtn-1 - for clearing tables (hack)
                let clearBtnTables = document.getElementById("toggleHeaderActionBtn-1");
                //console.log("Sidebar - changeTenant - clearBtnTables ->", clearBtnTables);
                if (clearBtnTables) {
                    let event;
                    clearBtnTables.onclick = (e) => {
                        event = Object.assign({}, e);
                        event.isTrusted = true; // This is key - React will only terminate the event if !isTrusted !!!
                    };
                    clearBtnTables.click();
                    setTimeout(() => {
                        let key;
                        for (key in clearBtnTables) {
                            if (key.startsWith("__reactEventHandlers")) {
                                clearBtnTables[key].onClick(event);
                            }
                        }
                    }, 1000);
                }

                let newTenant = Object.assign({}, tenant);
                delete newTenant.parent;

                dispatch(updateTenant(newTenant));
                dispatch(updateUser({ tenant_id }));

                //console.log("Sidebar switch tenant - locationArr ->", locationArr);
                //console.log("Sidebar switch tenant - this.props.location.pathname ->", this.props.location.pathname);


                if (this.props.location.pathname === "/") {
                    //window.location.href = `/${this.props.location.pathname.split('/')[1]}`
                    window.location.href = `/`
                    //this.props.history.push(`${this.props.location.pathname}`);
                } else {
                    if (locationArr && locationArr[3] &&
                        (locationArr[3] === "invoices" || locationArr[3] === "subscriptions" ||
                            locationArr[3] === "details" || locationArr[3] === "addresses" || locationArr[3] === "persons" || locationArr[3] === "bank-accounts")) {
                        console.log("Sidebar switch tenant - locationArr[3] ->", locationArr[3]);
                        this.props.history.push("/relations");

                    } else {
                        this.props.history.push(
                            `${this.props.location.pathname}`);
                    }
                }
            }
        })()
    }

    mouseOver = () => {
        //const scrollWidth = window.innerWidth - document.body.clientWidth;
        //document.body.style.paddingRight = `${ scrollWidth }px`;
        document.body.classList.add('hovering-sidebar');
    };

    mouseOut = () => {
        document.body.style.paddingRight = '0';
        document.body.classList.remove('hovering-sidebar');
    };

    getTenantLists = () => {
        (async () => {
            //dispatch(mvLoaderToggle(true));
            await GetTenantLists()
                .then(res => {
                    if (res.ok) {
                        const { data } = res.data;
                        console.log("GetTenantLists - data ->", data);
                        this.setState({ tenants: data });
                    }
                })
                .catch(e => {
                    console.log('Error', e);
                });

            //dispatch(mvLoaderToggle(false));

        })();
    };

    handleSearchChange = (searchTerm) => {
        this.setState({ searchTerm });
    };

    componentDidMount() {
        const { user/*, tenants*/, dispatch } = this.props;
        const sessionTenant = sessionStorage.getItem('tenant') ? JSON.parse(sessionStorage.getItem('tenant')) : null;
        //console.log("Sidebar - user ->", user);
        //this.getTenantLists();    
        (async () => {
            //dispatch(mvLoaderToggle(true));
            await GetTenantLists()
                .then(res => {
                    if (res.ok) {
                        const { data } = res.data;
                        //console.log("GetTenantLists - data ->", data);
                        this.setState({ tenants: data });


                    }
                })
                .catch(e => {
                    console.log('Error', e);
                });

            //dispatch(mvLoaderToggle(false));

        })();

        //const {tenants} = this.state;
        //console.log("Sidebar - tenants ->", tenants);
        //this.setState({ tenants: tenants ? Object.values(tenants) : {} });


        /*if (sessionTenant && tenants) {
            const tenant = Object.values(tenants).find(i => parseInt(i.id) === parseInt(sessionTenant.id));

            this.setState({ tenant: tenant.name });
            if (sessionTenant.parent) {
                delete sessionTenant.parent
            }
            dispatch(updateTenant(sessionTenant));
            dispatch(updateUser({ tenant_id: sessionTenant.id }));

        } else if (user && user.tenant_id && user.tenant && user.tenant.name) {
            const tenant = Object.values(tenants).find(i => parseInt(i.id) === parseInt(user.tenant_id));

            sessionStorage.setItem('tenant', JSON.stringify(tenant));
            this.setState({ tenant: tenant && tenant.name ? tenant.name : '' });

        } else {
            this.setState({ tenant: process.env.REACT_APP_NAME });

        }*/


        /* Open collapse element that matches current url */
        const pathName = this.props.location.pathname;

        routes.forEach((route, index) => {
            const isActive = pathName.indexOf(route.path) === 0;

            //console.log("Sidebar - isActive ->", isActive);

            const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/";

            this.setState(() => ({
                [index]: isActive || isOpen || isHome
            }))
        });

        
        document.addEventListener('click', this.handleClickTenantMenuOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickTenantMenuOutside, true);
    };

    componentDidUpdate(prevProps, prevSate, snapshot) {
        const { tenants, user } = this.props;

        if (prevProps.tenants !== tenants) {
            this.setState({ tenants: Object.values(tenants) })
        }

        if (user && user.tenant && !_.isEqual(prevProps.user.tenant, user.tenant)) {
            this.setState({ tenant: user.tenant.name });

            let tenants = Object.assign({}, this.state.tenants);
            // console.log("componentDidUpdate -> tenants", tenants);

            Object.values(tenants).forEach((tenant, i) => {
                if (parseInt(tenant.id) === parseInt(user.tenant.id)) {
                    tenants[i] = user.tenant
                }
            });

            this.setState({ tenants: Object.values(tenants) });

        }
    }

    render() {
        const { sidebar, brand } = this.props,
            { togglePreview, tenants, searchTerm } = this.state,
            // show process env environment => console.log("process.env.REACT_APP_ENV ->", process.env.REACT_APP_ENV);
            idxPrev = process.env.REACT_APP_ENV === 'production' ? 8 : 8, // was 3 : 7
            idxNext = process.env.REACT_APP_ENV === 'production' ? 7 : 7; // was 2 : 7

        return (
            <React.Fragment>
                {brand &&
                    <nav
                        className={
                            "sidebar" +
                            (!sidebar.isOpen ? " toggled" : "") +
                            (sidebar.isSticky ? " sidebar-sticky" : "")
                        }
                        onMouseOver={() => this.mouseOver()}
                        onMouseOut={() => this.mouseOut()}
                    >
                        <div className="sidebar-content" >
                            <div className="sidebar-brand">
                                <UncontrolledDropdown id="tenant-menu-dd">
                                    <DropdownToggle
                                        nav
                                        onClick={(e) => this.tenantsPreviewAction()}
                                        className="d-flex px-0" >
                                        {brand && brand.logo ?
                                            <img src={parse(window.atob(brand.logo))} alt={`logo`} style={{ maxWidth: "200px", filter: "contrast(0.55)" }} />
                                            :
                                            <>
                                                <Box className="align-middle text-primary mr-2" />
                                                <span className="brand-logo text">
                                                    {this.state.tenant}
                                                </span>
                                            </>
                                        }
                                    </DropdownToggle>

                                    {/* Uncomment <DropdownMenu to show dropdown */}
                                    {/* {this.state.tenants && this.state.tenants.length > 1 &&
                                        <DropdownMenu className="dropdown-menu-md">
                                            <ScrollBar className=""
                                                       options={{ suppressScrollX: true }}>
                                            {
                                                Object.keys(this.state.tenants).map((item, index) =>
                                                    <React.Fragment key={index}>
                                                        {
                                                            this.state.tenants[item].name !== this.state.tenant ?
                                                                <DropdownItem className="py-2" onClick={() => this.changeTenant(this.state.tenants[item])}>
                                                                    {this.state.tenants[item].name}
                                                                </DropdownItem> : null
                                                        }
                                                    </React.Fragment>
                                                )
                                            }
                                            </ScrollBar>
                                        </DropdownMenu>
                                    } */}
                                    {/* Comment <Offcanvas to show dropdown */}

                                    <Offcanvas 
                                        show={togglePreview} 
                                        placement="start" 
                                        scroll={true} 
                                        backdrop={true} 
                                        style={{boxShadow: "0 0.25rem 0.2rem rgba(0, 0, 0, 0.2)"}}
                                        onEscapeKeyDown={this.handleClose}
                                        ref={this.tenantMenuRef}
                                        id="tenantMenuOffcanvas"
                                    >
                                        <Offcanvas.Header 
                                            style={{ padding: "1rem 1.5rem", height: "69px", display: "flex", justifyContent: "flex-end" }}
                                            id="tenantMenuOffcanvasHeader"
                                        >  
                                            {brand && brand.logo ?
                                            <img src={parse(window.atob(brand.logo))} alt={`logo`} style={{ maxHeight: "30px", filter: "none" }} /> :                                        
                                            this.state.tenant ? 
                                                <span>{this.state.tenant}</span> : 
                                                <h4 className='mb-0'>Select tenant:</h4>}
                                            <button
                                                type="button"
                                                className="btn-close ml-auto"
                                                data-bs-dismiss="offcanvas"
                                                aria-label="Close"
                                                onClick={this.handleClose}
                                            >
                                            </button>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body id="tenantMenuOffcanvasBody">
                                            <div className="tenants-preview">

                                                {/* Search input */}
                                                <Input
                                                    type="text"
                                                    className="form-control mt-2 mb-2"
                                                    placeholder="Search tenants..."
                                                    value={this.state.searchTerm ? this.state.searchTerm : ""}
                                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                                />

                                                <ul className="tenants-preview-list">
                                                    {tenants && tenants.length > 1 && // Check if tenants is defined and not empty
                                                        tenants.filter(tenant =>
                                                            tenant.name && tenant.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
                                                        ).map((tenant, index) => (
                                                            this.state.searchTerm.length <= 0 ?
                                                                tenant.name !== this.state.tenant ? (
                                                                    <li key={index} style={{ textIndent: `${tenant.depth ? tenant.depth : 0}em` }}>
                                                                        <a href="#" className='tenants-preview-list__item 1' onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.changeTenant(tenant);
                                                                        }}>
                                                                            {tenant.depth > 0 ? <span className="arrow-icon">{tenant.name}</span> : tenant.name}
                                                                        </a>
                                                                    </li>
                                                                ) : null
                                                                : tenant.name !== this.state.tenant ? (
                                                                    <li key={index}>
                                                                        <a href="#" className='tenants-preview-list__item 2' onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.changeTenant(tenant);
                                                                        }}>
                                                                            {tenant.name}
                                                                        </a>
                                                                    </li>
                                                                ) : null
                                                        ))}

                                                    {tenants && tenants.length > 1 && tenants.filter(tenant =>
                                                        tenant.name && tenant.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
                                                    ).length === 0 && (
                                                            <li>
                                                                <p>No tenants found.</p>
                                                            </li>
                                                        )}
                                                </ul>

                                            </div>
                                        </Offcanvas.Body>

                                    </Offcanvas>

                                </UncontrolledDropdown>
                            </div>

                            {this.state.groups.map((item, i) => {
                                return (
                                    <ul className={`f2x-sidebar sidebar-nav${i > 0 ? ' mt-auto' : ''}`} key={i}>
                                        {routes.map((category, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {i === 0 && index < idxPrev &&
                                                        <SidebarItem
                                                            sidebarItemRef={this.sidebarItemRef}
                                                            name={category.name}
                                                            to={category.path}
                                                            icon={category.icon}
                                                            badgeColor={category.badgeColor}
                                                            badgeText={category.badgeText}
                                                            brand={brand}
                                                        />}
                                                    {i === 1 && index > idxNext ?
                                                        (
                                                            category.children ? (
                                                                <SidebarCategory
                                                                    sidebarCategoryRef={this.sidebarCategoryRef}
                                                                    name={category.name}
                                                                    badgeColor={category.badgeColor}
                                                                    badgeText={category.badgeText}
                                                                    icon={category.icon}
                                                                    to={category.path}
                                                                    isOpen={this.state[index]}
                                                                    onClick={() => this.toggle(index)}
                                                                >
                                                                    {category.children.map((route, index) => (
                                                                        <SidebarItem
                                                                            sidebarCategoryItemRef={this.sidebarItemRef}
                                                                            key={index}
                                                                            name={route.name}
                                                                            to={route.path}
                                                                            icon={route.icon}
                                                                            badgeColor={route.badgeColor}
                                                                            badgeText={route.badgeText}
                                                                            brand={brand}
                                                                        />
                                                                    ))}
                                                                </SidebarCategory>
                                                            ) : (
                                                                <SidebarItem
                                                                    sidebarItemRef={this.sidebarItemRef}
                                                                    name={category.name}
                                                                    to={category.path}
                                                                    icon={category.icon}
                                                                    badgeColor={category.badgeColor}
                                                                    badgeText={category.badgeText}
                                                                    brand={brand}
                                                                />
                                                            )
                                                        )
                                                        :
                                                        null}

                                                    {/* /> } */}
                                                    {/* {category.header && (
                                                            <li className="sidebar-header">{category.header}</li>
                                                        )} */}


                                                </React.Fragment>
                                            )
                                        })}
                                    </ul>
                                )
                            })}

                            {/*!sidebar.isSticky ? (
                    <div className="sidebar-bottom d-none d-lg-block">
                        <div className="media">
                        <img
                            className="rounded-circle mr-3"
                            //src={avatar ? avatar : null}
                            alt="Chris Wood"
                            width="40"
                            height="40"
                        />
                        <div className="media-body">
                            <h5 className="mb-1">Chris Wood</h5>
                            <div>
                            <FontAwesomeIcon
                                icon={faCircle}
                                className="text-success"
                            />{" "}
                            Online
                            </div>
                        </div>
                        </div>
                    </div>
                    ) : null*/}

                        </div>
                    </nav>
                }



            </React.Fragment>
        )
    }
}

const sidebarSelector = createSelector(
    state => state.sidebar,
    sidebar => sidebar
);

const layoutSelector = createSelector(
    state => state.layout,
    layout => layout
);

const userSelector = createSelector(
    state => state.user,
    user => user
);

const tenantsSelector = createSelector(
    state => state.tenants,
    tenants => tenants
);

const brandSelector = createSelector(
    state => state.brand,
    brand => brand
);

const themeSelector = createSelector(
    state => state.theme,
    theme => theme
);

const mapStateToProps = createSelector(
    sidebarSelector,
    layoutSelector,
    userSelector,
    tenantsSelector,
    brandSelector,
    themeSelector,
    (sidebar, layout, user, tenants, brand, theme) => ({ sidebar, layout, user, tenants, brand, theme })
);

export default withRouter(connect(mapStateToProps)(Sidebar));
