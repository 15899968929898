import ApiService from '../services/apiService';

const Api = new ApiService(),
  GetAPI = Api.getAPI,
  PostAPI = Api.postAPI,
  UpdateAPI = Api.updateAPI,
  RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : GetAPI(endpoint, params);
  })();
};

const GetProducts = (params) => {
  return asyncFunc(`products`, params)
};

const GetAdminProducts = (params) => {
  return asyncFunc(`admin/products`, params)
};

const GetTenantProducts = (params) => {
  return asyncFunc(`tenantProducts`, params)
};

const GetProduct = (product_id, params) => {
  return asyncFunc(`products/${product_id}`, params);
};

const GetProductWithPrice = (product_id, tenant_id, params) => {
  return asyncFunc(`products/${product_id}/tenant/${tenant_id}`, params);
};

const GetProductWithPriceFilter = (product_id) => {
  return asyncFunc(`products?filter=id=${product_id}`, null);
};

const GetProductsCount = () => {
  return asyncFunc('products/count');
};

const GetProductTypeList = () => {
  return asyncFunc('product_types/list');
};

// CAN DO:
// implement useEffects(), useState() (on the component), redux-saga on handling states and dispatching actions/functions
const GetProductsBackendAPIs = () => {
 return asyncFunc('products/backend_apis');
};

const AddProduct = (params) => {
  return asyncFunc(`products`, params, PostAPI);
};

const UpdateProduct = (params, id) => {
  return asyncFunc(`products/${id}`, params, UpdateAPI);
};

// Product hierarchies
const GetProductHierarchyRelationTypes = () => {
  return asyncFunc(`product_hierarchy_relation_types`);
};

const GetProductHierarchy = (product_id) => {
  if (product_id)
    return asyncFunc(`products/${product_id}/hierarchies`);
};

const AddProductHierarchy = (product_id, related_product_id, params) => {
  return asyncFunc(`products/${product_id}/hierarchies/${related_product_id}`, params, PostAPI);
};

const UpdateProductHierarchy = (product_id, related_product_id, params) => {
  return asyncFunc(`products/${product_id}/hierarchies/${related_product_id}`, params, UpdateAPI);
};

const RemoveProductHierarchy = (product_id, related_product_id) => {
  return asyncFunc(`products/${product_id}/hierarchies/${related_product_id}`, null, RemoveAPI);
};

export {
  GetProduct,
  GetProductWithPrice,
  GetProductWithPriceFilter,
  GetProducts,
  GetAdminProducts,
  GetTenantProducts,
  GetProductsCount,
  GetProductTypeList,
  GetProductsBackendAPIs,
  AddProduct,
  UpdateProduct,

  GetProductHierarchy,
  GetProductHierarchyRelationTypes,
  AddProductHierarchy,
  UpdateProductHierarchy,
  RemoveProductHierarchy,
}