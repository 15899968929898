import * as types from "../constants";

export default function reducer(state = null, actions) {
  const { payload } = actions;

  switch (actions.type) {
    case types.USER:
      return {
        ...state,
        id: parseInt(payload.id),
        email: payload.username,
        person_id: payload.person_id,
        tenant_id: payload.last_tenant_id,
        username: payload.username,
        json_data: payload.json_data,
        full_name: payload.full_name,
        tenant: payload.tenant
      };
    case types.UPDATE_USER: 
      return {
        ...state,
        ...payload
      };
    case types.UPDATE_TENANT:
      return {
        ...state,
        tenant: payload
      };
    default:
      return state;
  }
}