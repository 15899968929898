import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DatePicker, { registerLocale } from 'react-datepicker';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import {
    Badge, Button, Col, Form, Input, ListGroup, ListGroupItem, Row,
} from 'reactstrap';
import { AddInvoice } from '../../../controllers/invoices';
import {
    GetOptionAddress,
    GetOptionPersons,
    GetRelation,
    GetRelationList,
    GetRelationsDependencies,
} from '../../../controllers/relations';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import { mvLoaderToggleInv } from '../../../redux/actions/mvLoaderInvoiceActions';
import { validateDate } from '../../../redux/actions/validateActions';
import Toast from '../../../components/Toast';
import ToastMulti from '../../../components/ToastMulti';
import { withTranslation } from 'react-i18next';
import { enGB, enUS, nl } from 'date-fns/locale';
import { parseISO, parse, isValid, format, addDays } from 'date-fns';
import {
    addDaysToFormattedDate, parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { X } from 'react-feather';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const locales = { enGB, enUS, nl };

class NewInvoice extends React.Component {
    constructor(props) {
        super(props);

        const fetchingData = false;

        this.state = {
            invoice: {
                date: new Date(),
                due_date: null,
                payment_status: null,
                invoice_status: 0
            },
            tenant_id: null,
            relation_id: null,
            customer_label: null,
            persons: null,
            shipping_addresses: null,
            invoice_addresses: null,
            invoice_address_id: null,
            invoice_person_id: null,
            shipping_address_id: null,
            shipping_person_id: null,
            description: null,
            payment_condition_id: null,
            inv_output_type: null,
            inv_output_types: [{
                value: 'email',
                label: 'Email'
            }, {
                value: 'paper',
                label: 'Paper'
            }],
            payment_conditions: []
        };
    }

    formatPersons() {
        if (this.props.relation && this.props.relation.persons && this.props.relation.persons.length > 0) {
            return this.props.relation.persons.map(d => ({
                "value": d.id, "label": d.full_name
            }));
        }
        return null;
    }

    formatAddresses() {
        if (this.props.relation && this.props.relation.addresses && this.props.relation.addresses.length > 0) {
            return this.props.relation.addresses.map(d => ({
                "value": d.id, "label": d.full_address
            }));
        }
        return null;
    }

    formatPaymentConditions() {
        if (this.props.relation && this.props.relation.payment_conditions && this.props.relation.payment_conditions.length > 0) {
            return this.props.relation.payment_conditions.map(d => ({
                "value": d.id, "label": d.description
            }));
        }
        return [];
    }

    componentDidMount() {
        const id = this.props.relation && this.props.relation.id ? this.props.relation.id : null;

        console.log("New invoice form - (componentDidMount) this.props ->", this.props);

        const pageURL = window.location.href;
        const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
        console.log("New invoice form - (componentDidMount) lastURLSegment ->", lastURLSegment);
        if (lastURLSegment === 'concept') {
            this.getCustomersList();
        } else {
            this.getAddressesAndPersons(id);
        }
    }

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        //require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    toggleLoader(toggle) {
        const { dispatch } = this.props;

        if (this.props.id) {
            dispatch(mvLoaderToggleInv(toggle))
        } else {
            dispatch(loaderToggle(toggle))
        }
    }

    handleInputChange({ target: { name, value } }) {
        let invoice = Object.assign({}, this.state.invoice);

        invoice[name] = value;

        this.setState({
            invoice,
            [name]: value
        })
    }

    handleDatePickerChange(name, date) {
        let invoice = Object.assign({}, this.state.invoice);
        const { newDate } = this.props.dispatch(validateDate(date));

        invoice[name] = newDate;

        const { payment_condition_id } = this.state;

        if (newDate && payment_condition_id !== null && payment_condition_id.net_days !== undefined) {
            invoice.due_date = this.computeDueDate(newDate, payment_condition_id.net_days)
        }

        this.setState({ invoice })
    }

    handleSelectChange = (name, data) => {
        let invoice = Object.assign({}, this.state.invoice);
        invoice[name] = data.value;

        // Set due_date
        if (name === 'payment_condition_id' && data.value !== null) {
            invoice.due_date = this.computeDueDate(invoice.date, data.net_days);
            console.log('invoice.due_date', invoice.due_date);
        }

        this.setState({
            invoice,
            [name]: data
        });

        if (name === 'relation_id') {
            this.setState({
                tenant_id: null,
                invoice_address_id: null,
                invoice_person_id: null,
                billing_address_id: null, // added
                billing_person_id: null, // added
                shipping_address_id: null,
                shipping_person_id: null,
                payment_condition_id: null,
                inv_output_type: null
            });
            this.getAddressesAndPersons(data.value)
        }
    };

    loadOptions = (value, callback) => {
        const filter = 'filter[keyword]',
            params = { [filter]: value };

        const { dispatch } = this.props;
        const id = this.props.relation.id;

        (async () => {
            const { ok, data } = await GetRelationList(params, id);
            console.log(data);

            if (ok) {
                let customers = [];

                await data.data.map(({ name, id, customer_number, primary_person_full_name, billing_address }) => {
                    return customers.push({
                        label: name,
                        value: id,
                        customer_number,
                        primary_person_full_name,
                        billing_address
                    })
                });

                callback(customers);
            }
        })();
    };

    computeDueDate = (invoiceDate, netDays) => {
        const newDueDate = addDays(new Date(invoiceDate), parseInt(netDays));
        console.log('updateDueDate - newDueDate', newDueDate);
        return new Date(newDueDate);
    };

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            console.log('KeyPress 9');
            this.ref[name].setOpen(false);
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        const pageURL = window.location.href;
        const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/'));
        const { invoice, relation_id, invoice_address_id, invoice_person_id, shipping_address_id, shipping_person_id, payment_condition_id } = this.state,
            { date, description, invoiceList } = invoice,
            { dispatch, updateDetails, relation/*, invoiceList*/, setSelectedItem } = this.props;

        let notificationsToastArr = [];

        if (!description) {
            notificationsToastArr.push({
                title: "Description field is required!",
                icon: "warn",
            });
        }

        if (!date) {
            notificationsToastArr.push({
                title: "Invoice date field is required!",
                icon: "warn",
            });
        }

        if (!relation_id) {
            notificationsToastArr.push({
                title: "Customer field is required!",
                icon: "warn",
            });
        }

        if (!payment_condition_id) {
            notificationsToastArr.push({
                title: "Payment condition field is required!",
                icon: "warn",
            });
        }

        if (!invoice_address_id || !invoice_person_id) {
            notificationsToastArr.push({
                title: "Please complete all billing fields!",
                icon: "warn",
            });
        }

        if (!shipping_address_id || !shipping_person_id) {
            notificationsToastArr.push({
                title: "Please complete all shipping fields!",
                icon: "warn",
            });
        }

        if (notificationsToastArr.length > 0) {
            return ToastMulti(notificationsToastArr, "container-validation-id");
        }

        //console.log("New invoice POST data ->", invoice);
        //console.log("New invoice POST date ->", date);
        //console.log("New invoice POST invoice.due_date ->", invoice.due_date);

        // set dates into save format
        if (invoice && invoice.date) {
            invoice.date = parseDateToSaveFormat(new Date(invoice.date));
        }
        if (invoice && invoice.due_date) {
            invoice.due_date = parseDateToSaveFormat(new Date(invoice.due_date));
        } else {
            invoice.due_date = parseDateToSaveFormat(new Date(date));
        }
        invoice.billing_address_id = invoice_address_id.value;
        invoice.invoice_address_id = invoice_address_id.value;
        invoice.billing_address = invoice_address_id;
        invoice.billing_person_id = invoice_person_id.value;
        invoice.invoice_person_id = invoice_person_id.value;
        invoice.billing_person = invoice_person_id;
        invoice.shipping_address_id = shipping_address_id.value;
        invoice.relation_id = relation_id;

        console.log("New invoice POST data ->", invoice);
        console.log("New invoice POST this.props ->", this.props);
        //return false;

        dispatch(loaderToggle(true));

        (async () => {
            const { data } = await AddInvoice(invoice);
            if (data) {
                let newInvId = data.data.id;

                console.log("New invoice RESPONSE data ->", data);
                console.log("New invoice RESPONSE this.props ->", this.props);
                console.log("New invoice RESPONSE relation ->", relation);
                console.log("New invoice RESPONSE lastURLSegment ->", lastURLSegment);

                const { invoiceList } = this.props;

                //const { invoice } = this.props;

                //console.log("New invoice RESPONSE relation(list) ->", relation.invoiceList);
                console.log("New invoice RESPONSE invoiceList(list) ->", invoiceList);
                console.log("New invoice RESPONSE invoice(list) ->", invoice.invoiceList);
                console.log("New invoice RESPONSE this.props.invoice.invoiceList(list) ->", this.props.invoice.invoiceList);

                let newData = data.data;
                let newDataInvoiceList;

                //if (newData && newData.price === null) {
                newData.price = 0;
                newData.price_total = 0;
                newData.price_vat = 0;
                //}

                //if (relation.invoiceList && relation.invoiceList.length > 0) {
                //if (this.props.invoice && this.props.invoice.invoiceList && this.props.invoice.invoiceList.length > 0) {
                if (invoiceList && invoiceList.length > 0) {
                    newDataInvoiceList = [...invoiceList, newData];
                    //this.props.setData([...this.props.invoice.invoiceList, newData]);
                    this.props.setData([...invoiceList, newData]);

                    console.log("New invoice RESPONSE - invoiceList(list) updated after length > 0 ->", newDataInvoiceList);
                    console.log("New invoice RESPONSE - invoiceList(list) updated after length > 0 - newData ->", newData);

                    dispatch({ type: 'RELATION', payload: { invoiceList: newDataInvoiceList } });
                    dispatch({ type: 'INVOICE', payload: newData });
                    dispatch({ type: 'INVOICE', payload: { invoiceList: newDataInvoiceList } });

                } else {
                    newDataInvoiceList = [];
                    newDataInvoiceList[0] = data.data;
                    //newDataInvoiceList = [];

                    dispatch({ type: 'RELATION', payload: { invoiceList: newDataInvoiceList } });
                    dispatch({ type: 'INVOICE', payload: newData });
                    dispatch({ type: 'INVOICE', payload: { invoiceList: newDataInvoiceList } });
                }

                console.log("New invoice RESPONSE newDataInvoiceList ->", newDataInvoiceList);
                console.log("New invoice RESPONSE lastURLSegment ->", lastURLSegment);

                sessionStorage.removeItem('lastVisitInvId');

                //dispatch({type: 'INVOICE', payload: {invoiceList: newDataInvoiceList}});
                //dispatch({type: 'RELATION', payload: {invoiceList: newDataInvoiceList}});

                this.setState({ invoice });

                // set sessionStorage
                sessionStorage.setItem('lastVisitInvId', newInvId);

                if (lastURLSegment && lastURLSegment !== '/concept') {
                    this.props.setToggleForm(false);

                    if (setSelectedItem) {
                        setSelectedItem(newInvId);
                    }
                    //this.props.setData(newDataInvoiceList);

                    updateDetails(newInvId, 'add', newDataInvoiceList);

                } else {
                    //updateDetails(newInvId, 'add', newDataInvoiceList);
                    this.props.history.push(`/relations/${relation_id}/invoices/${newInvId}`);
                }

                await Toast.fire({
                    title: 'Invoice has been created.', icon: 'success'
                }).then(r => { console.log('response after toast alert r ->', r) });
            }
        })();

        dispatch(loaderToggle(false));
    }

    handleCustomer = (customer) => {
        let invoice = Object.assign({}, this.state.invoice),
            payment_condition_id = null,
            inv_output_type = null;

        invoice.due_date = null;

        if (customer.payment_condition_id && customer.payment_condition) {
            let { description, id, net_days } = customer.payment_condition;
            payment_condition_id = {
                'label': description, 'value': id, 'net_days': net_days
            };

            inv_output_type = {
                'label': customer.inv_output_type ? _.capitalize(customer.inv_output_type) : null,
                'value': customer.inv_output_type ? customer.inv_output_type : null,
            };

            invoice.payment_condition_id = customer.payment_condition_id ? customer.payment_condition_id : '';
            invoice.inv_output_type = customer.inv_output_type ? customer.inv_output_type : '';
            invoice.due_date = this.computeDueDate(invoice.date, payment_condition_id ? customer.payment_condition.net_days : 14)
        }

        const { id, company_name, vat_no, customer_number, primary_person_full_name, billing_address } = customer;

        let relation_id = id;
        let customer_label = customer_number + ' - ' + primary_person_full_name + ' - ' + billing_address;
        this.setState({ customer_label });

        this.setState({
            relation_id,
            payment_condition_id,
            inv_output_type,
            company_name,
            vat_no,
            customer_number,
            primary_person_full_name,
            billing_address
        });

        this.setState({ invoice });
    };

    getAddressesAndPersons(id) {
        if (!this.fetchingData) {
            this.fetchingData = true;

            (async () => {
                this.toggleLoader(true);

                let payment_conditions = [];

                const [dependency, relation, person, address] = await Promise.all(
                    [
                        GetRelationsDependencies(null, id),
                        GetRelation(null, id),
                        GetOptionPersons(null, id),
                        GetOptionAddress(null, id)
                    ]);

                await (() => {
                    if (dependency.ok) {
                        const data = dependency.data;

                        data && data.payment_conditions &&
                            data.payment_conditions.map(
                                ({ description, id, net_days }) => {
                                    return payment_conditions.push({
                                        label: description,
                                        value: id,
                                        net_days: net_days
                                    })
                                });

                        this.setState({ payment_conditions })
                    }

                    // getAddressesAndPersons relation data
                    if (relation.ok) {
                        this.handleCustomer(relation.data.data)
                    }

                    // getAddressesAndPersons person
                    if (person.ok) {
                        let persons = [];

                        person.data.data.map(({ full_name, id }) => {
                            return persons.push({
                                label: full_name, value: id
                            })
                        });

                        this.setState({ persons });

                        if (persons && persons.length === 1) {
                            (async () => {
                                await this.handleSelectChange(
                                    'invoice_person_id', persons[0]);
                                await this.handleSelectChange(
                                    'shipping_person_id', persons[0])
                            })();
                        }
                    }

                    // getAddressesAndPersons address
                    if (address.ok) {
                        const data = address.data.data;
                        let shipping_addresses = [], invoice_addresses = [];

                        data && data.map((address) => {
                            if (address.address_type.toLowerCase() ===
                                'provisioning') {
                                return shipping_addresses.push({
                                    label: address['full_address'],
                                    value: address.id
                                })
                            } else if (address.address_type.toLowerCase() ===
                                'billing') {
                                return invoice_addresses.push({
                                    label: address['full_address'],
                                    value: address.id
                                })
                            } else {
                                return false
                            }
                        });

                        this.setState({
                            shipping_addresses, invoice_addresses // => billing
                        });

                        (async () => {
                            await (() => {
                                if (shipping_addresses &&
                                    shipping_addresses.length === 1) {
                                    this.handleSelectChange(
                                        'shipping_address_id',
                                        shipping_addresses[0])
                                }
                                if (invoice_addresses &&
                                    invoice_addresses.length === 1) {
                                    this.handleSelectChange(
                                        'invoice_address_id',
                                        invoice_addresses[0])
                                }
                            })();
                        })();
                    }
                })();

                this.fetchingData = false;
                this.toggleLoader(false);

            })();
        }
    }

    goTo(uri) {
        console.log(uri);
        //window.location.href = uri;
        this.props.history.push(uri);
    }

    getCustomersList() {
        if (!this.fetchingData) {
            this.fetchingData = true;

            let customer_label = null;
            this.setState({ customer_label });

            this.toggleLoader(true);

            (async () => {
                const { ok, data } = await GetRelationList();
                if (ok) {
                    let customers = [];

                    data.data.map(
                        ({ name, id, customer_number, primary_person_full_name, billing_address }) => {
                            return customers.push({
                                label: name,
                                value: id,
                                customer_number,
                                primary_person_full_name,
                                billing_address
                            })
                        });
                    this.setState({ customers })
                }

                this.fetchingData = false;
                this.toggleLoader(false)

            })();
        }
    }

    render() {
        const {
            invoice,
            customer_label,
            customers,
            persons,
            shipping_addresses,
            invoice_addresses,
            payment_conditions,
            payment_condition_id,
            inv_output_type,
            inv_output_types,
            relation_id,
            shipping_person_id,
            invoice_person_id,
            shipping_address_id,
            invoice_address_id,
            description,
            company_name,
            vatNumber } = this.state,
            { date, due_date } = invoice,
            nullValue = String.fromCharCode(8212),
            listGroupItemClass = 'd-flex align-items-center px-0 border-top-0 list-group-item';

        const isMvProps = this.props.id;
        const setToggleForm = this.props.setToggleForm;
        const pageURL = window.location.href;
        const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/'));
        const { t } = this.props;

        console.log("Invoice - new - pageURL ->", pageURL);
        console.log("Invoice - new - lastURLSegment ->", lastURLSegment);
        console.log("Invoice - new - payment_conditions ->", payment_conditions);
        console.log("Invoice - new - payment_condition_id ->", payment_condition_id);

        return (
            <React.Fragment>
                <div className={`f2x-rel-mv-detail`}>
                    {lastURLSegment && lastURLSegment === '/concept' ? <h1 className="text-bold mb-3">Invoices</h1> : ''}
                    <Row className={`d-flex`}>
                        <Col className={`pt-0`}>
                            <h5 className="mb-0 py-0">Create <span className="">#Concept invoice</span></h5>
                        </Col>
                        <Col className={`d-flex text-right align-items-right text-right`}>
                            {lastURLSegment && lastURLSegment === '/concept' ?
                                <Button className="btn ml-auto mb-1" color="gray" onClick={() => this.goTo('/invoices')}>
                                    <X size={15} color="grey" />
                                </Button> :
                                <Button className="btn ml-auto mb-1" color="gray" onClick={() => setToggleForm(false)}>
                                    <X size={15} color="grey" />
                                </Button>
                            }
                        </Col>
                    </Row>

                    <div className={`det-invoice-cont`}>
                        <Form className="f2x-rel-det-form" onSubmit={(e) => this.handleSubmit(e)}>
                            <Row className={`pt-0 mb-0`}>
                                <Col xs="12" md="6" className="mb-4">
                                    <ListGroup className={`block-list`} flush>
                                        <h5 className="pb-0 list-group-sub-heading">Invoice details</h5>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Invoice no.</Col>
                                            <Col xs="6" md="7" lg="8" className="greyed-text"># Invoice number generated after finalizing</Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Description</Col>
                                            <Col xs="6" md="7" lg="8" className="greyed-text">
                                                <Input
                                                    id="description"
                                                    name="description"
                                                    placeholder="Description"
                                                    value={description ? description : ''}
                                                    onChange={this.handleInputChange.bind(this)}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Invoice date</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <DatePicker
                                                    locale={this.getLocale()}
                                                    className="form-control"
                                                    name="date"
                                                    dateFormat={t("date_format_raw")}
                                                    autoComplete="off"
                                                    placeholderText="Invoice Date"
                                                    selected={date ? new Date(date) : null}
                                                    onChange={this.handleDatePickerChange.bind(this, 'date')}
                                                    onKeyDown={(e) => this.handleKeyDown(e, 'date')}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Due date</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <Input
                                                    id="due_date"
                                                    name="due_date"
                                                    placeholder="Due Date"
                                                    value={due_date ? format(new Date(due_date), t("date_format_raw")) : ''}
                                                    //onChange={this.handleInputChange.bind(this, due_date)}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Customer</Col>
                                            <Col xs="6" md="7" lg="8" className="form-label-content customer-select-menu">
                                                {lastURLSegment && lastURLSegment !== '/concept' && customer_label
                                                    ? customer_label
                                                    : <AsyncSelect
                                                        name="relation_id"
                                                        cacheOptions
                                                        defaultOptions={customers}
                                                        loadOptions={this.loadOptions}
                                                        getOptionLabel={({ customer_number, primary_person_full_name, billing_address }) =>
                                                            `${customer_number ? customer_number : String.fromCharCode(8212)}\n${primary_person_full_name ? primary_person_full_name : String.fromCharCode(8212)}\n${billing_address ? billing_address : String.fromCharCode(8212)}`
                                                        }
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        placeholder={customer_label ? customer_label : "Customer"}
                                                        value={relation_id}
                                                        onChange={this.handleSelectChange.bind(this, 'relation_id')}
                                                        maxMenuHeight={220}
                                                    />
                                                }
                                            </Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Company name</Col>
                                            <Col xs="6" md="7" lg="8">{company_name ? company_name : nullValue}</Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">VAT number</Col>
                                            <Col xs="6" md="7" lg="8">{vatNumber ? vatNumber : nullValue}</Col>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>

                                <Col xs="12" md="6" className="mb-4">
                                    <ListGroup className={`block-list`} flush>
                                        <h5 className="pb-0 list-group-sub-heading">Finance</h5>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Total excl. VAT</Col>
                                            <Col xs="6" md="7" lg="8">{nullValue}</Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">VAT</Col>
                                            <Col xs="6" md="7" lg="8">{nullValue}</Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Total incl. VAT</Col>
                                            <Col xs="6" md="7" lg="8">{nullValue}</Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Open amount to pay</Col>
                                            <Col xs="6" md="7" lg="8">{nullValue}</Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Payment condition</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <Select
                                                    name="payment_condition_id"
                                                    options={payment_conditions}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder={relation_id ? "Payment condition" : "Select customer first"}
                                                    value={payment_condition_id}
                                                    onChange={this.handleSelectChange.bind(this, 'payment_condition_id')}
                                                    maxMenuHeight={300}
                                                    isDisabled={!date || !relation_id}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Invoice output</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <Select
                                                    name="inv_output_type"
                                                    options={inv_output_types}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder="Invoice output"
                                                    value={inv_output_type}
                                                    onChange={this.handleSelectChange.bind(this, 'inv_output_type')}
                                                    maxMenuHeight={300}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="form-label">Status invoice</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <Badge color="info" className="badge-pill mr-1 mb-1 fs-12">CONCEPT</Badge>
                                            </Col>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>

                                <Col xs="12" md="6" className="mb-4">
                                    <ListGroup className={`block-list`} flush>
                                        <h5 className="pb-0 list-group-sub-heading">Billing</h5>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="lh-40">Address</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <Select
                                                    name="invoice_address_id"
                                                    options={invoice_addresses}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder="Address"
                                                    value={invoice_address_id}
                                                    onChange={this.handleSelectChange.bind(this, 'invoice_address_id')}
                                                    maxMenuHeight={300}
                                                    isDisabled={!relation_id || !invoice_addresses}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="lh-40">Person</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <Select
                                                    name="invoice_person_id"
                                                    options={persons}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder="Person"
                                                    value={invoice_person_id}
                                                    onChange={this.handleSelectChange.bind(this, 'invoice_person_id')}
                                                    maxMenuHeight={300}
                                                    isDisabled={!relation_id || !persons}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>
                                <Col xs="12" md="6" className="mb-4">
                                    <ListGroup className={`block-list`} flush>
                                        <h5 className="pb-0 list-group-sub-heading">Shipping</h5>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="lh-40">Address</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <Select
                                                    name="shipping_address"
                                                    options={shipping_addresses}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder="Address"
                                                    value={shipping_address_id}
                                                    onChange={this.handleSelectChange.bind(this, 'shipping_address_id')}
                                                    maxMenuHeight={300}
                                                    isDisabled={!relation_id || !shipping_addresses}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                        <ListGroupItem className={listGroupItemClass}>
                                            <Col xs="6" md="5" lg="4" className="lh-40">Person</Col>
                                            <Col xs="6" md="7" lg="8">
                                                <Select
                                                    name="shipping_person_id"
                                                    options={persons}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder="Person"
                                                    value={shipping_person_id}
                                                    onChange={this.handleSelectChange.bind(this, 'shipping_person_id')}
                                                    maxMenuHeight={300}
                                                    isDisabled={!relation_id || !persons}
                                                />
                                            </Col>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>

                                <Col xs="12" className="d-flex justify-content-end pt-0">
                                    <Col xs="auto" className="px-0 pr-3">
                                        {isMvProps && <Button color="danger" onClick={() => setToggleForm(false)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>}
                                    </Col>
                                    <Col xs="auto" className="ml-auto px-0">
                                        <Button color="primary">
                                            <FontAwesomeIcon icon={faSave} />
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => ({
    loader: store.loader,
    relation: store.relation,
    invoice: store.invoice
});

export default connect(mapStateToProps)(withTranslation()(withRouter(NewInvoice)));