import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import {
  Card,
  Row,
  CardBody,
  ListGroup,
  ListGroupItem,
  Col,
  Button,
  Form,
  Input, Label,
} from 'reactstrap';
import ButtonSpinner from "../../components/ButtonSpinner";
import { GetCountries, GetCities } from "../../controllers/relations";
import { AddRelationCS } from "../../controllers/relations";
import { GetPlanList } from "../../controllers/plans";
import {
  GetContractPeriodList,
  GetNetworkOperatorList,
} from "../../controllers/subscriptions";
import { GetOrder } from "../../controllers/orders";
import { loaderToggle } from "../../redux/actions/loaderActions";
import {
  validateDate,
  validateEmail,
  validateIBAN,
} from "../../redux/actions/validateActions";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faList, faHandshake, faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import Toast from "../../components/Toast";
import { nl, enGB, enUS } from "date-fns/locale";
import { parseDateToSaveFormat } from "../../redux/actions/formatActions";
import {DownloadSingleTermsConditionsAttachment} from '../../controllers/terms_conditions'; // import all locales we need

registerLocale("en", enGB);
registerLocale("nl", nl);
registerLocale("us", enUS);

class Overview extends React.Component {
  constructor(props) {
    super(props);

    const {
      clientInlineStyles
    } = props;

    console.log("New AV Check - Overview - props ->", props);
    console.log("New AV Check - Overview - clientInlineStyles ->", clientInlineStyles);

    const { t } = props;

    this.state = {
      terms_conditions: {},
    };
  }

  getLocale = () => {
    const dateLocalesPath = {
      nl: "nl",
      en: "en-GB",
      us: "en-US",
    };

    require(`date-fns/locale/${
        dateLocalesPath[this.props.i18n.language]
    }/index.js`);
  };

  downloadAttachment = (e, termCondition_id, attachment_id, filename) => {
    e.preventDefault();
    console.log("downloadAttachment - termCondition_id ->", termCondition_id);
    console.log("downloadAttachment - attachment_id ->", attachment_id);
    console.log("downloadAttachment - filename ->", filename);
    console.log("downloadAttachment - e ->", e);

    if (termCondition_id && attachment_id && filename) {
      (async () => {
        const { ok, data } = await DownloadSingleTermsConditionsAttachment(termCondition_id, attachment_id, filename);
        console.log("DownloadSingleTermsConditionsAttachment - data ->", data);

      })();
    }
  };

  handleCheckboxChangeTermsConditions = (e, name, id) => {
    const { stepFormData, setStepFormData } = this.props;
    const { terms_conditions } = this.state;

    let stepFormDataObj = Object.assign({}, stepFormData);

    console.log("handleCheckboxChangeTermsConditions - e ->", e);
    console.log("handleCheckboxChangeTermsConditions - name ->", name);
    console.log("handleCheckboxChangeTermsConditions - id ->", id);

    let dataArr = [];
    if (terms_conditions && terms_conditions[name] && terms_conditions[name].length > 0) {
      dataArr = [...terms_conditions[name]];

      if (e.target.checked === true) {
        dataArr = [...terms_conditions[name], id]; // set just the plan_line id instead of the whole object -> lineData
      } else {
        dataArr.splice(terms_conditions[name].indexOf(e.target.value), 1);
      }
    } else {
      if (e.target.checked === true) {
        dataArr.push(id);
      }
    }

    console.log("handleCheckboxChangeTermsConditions - dataArr ->", dataArr);

    terms_conditions[name] = dataArr;
    //stepFormDataObj.terms_conditions = terms_conditions;
    stepFormDataObj.terms_conditions = terms_conditions[name];
    setStepFormData(stepFormDataObj);
  };

  componentDidMount() {
    const { stepFormData, termsConditions } = this.props;

    console.log("Offer - Overview - stepFormData ->", stepFormData);
    console.log("Offer - Overview - termsConditions ->", termsConditions);
  }

  render() {
    const {
          isLoading,
        } = this.state ? this.state : {},
        {
          loader,
          t,
          stepFormData,
          termsConditions,
        } = this.props,
        {
          bank_account,
          subscription,
          relation,
        } = stepFormData;

    //console.log("Birthdate ->", relation.birthdate);
    //console.log("Birthdate ->", relation.birthdate.toDateString());
    //gitconsole.log("Birthdate ->", moment(relation.birthdate).format('DD-MM-YYYY'));

    return (
      <div id="offer-overview">
        <div className="offer-overview">
          <h5 className="pt-1 pb-2 pl-2 offer-sub-heading-overview">Overview</h5>
          <div className="offer-overview-data">
            <h4>Uw gegevens</h4>
            <p>
              <strong>Naam:</strong> { relation.title.label }. { relation.first_name } { relation.middle_name ? relation.middle_name : "" } { relation.last_name }<br />
              <strong>Email:</strong> { relation.email }<br />
              <strong>Phone / Mobile:</strong> { relation.phone } { relation.mobile ? ` / ${relation.mobile}` : "" }<br />
              <strong>Birth date:</strong> { moment(relation.birthdate).format('DD-MM-YYYY') }
            </p>
            <p>
              <strong>Provisioning address:</strong><br />
              { relation.prov_street } { relation.prov_house_number }
              { relation.prov_house_number_letter ? relation.prov_house_number_letter : "" }
              { relation.prov_house_number_suffix ? ` ${relation.prov_house_number_suffix}` : "" }
              { relation.prov_house_number_room ? ` - Room ${relation.prov_house_number_room}` : "" }<br />
              { relation.prov_zipcode }, { relation.prov_city_id.label }<br />
              { relation.prov_country_id.label }
            </p>
            <p>
              <strong>Billing address:</strong><br />
              { relation.billing_street } { relation.billing_house_number }
              { relation.billing_house_number_letter ? relation.billing_house_number_letter : "" }
              { relation.billing_house_number_suffix ? ` ${relation.billing_house_number_suffix}` : "" }
              { relation.billing_house_number_room ? ` - Room ${relation.billing_house_number_room}` : "" }<br />
              { relation.billing_zipcode }, { relation.billing_city_id.label }<br />
              { relation.billing_country_id.label }
            </p>
            <p>
              <strong>IBAN:</strong> { bank_account.iban }<br />
              <strong>Naam rekeninghouder:</strong> { bank_account.account_holder }
            </p>
          </div>
        </div>

        {termsConditions && termsConditions.length > 0 &&
        <div className="offer-overview">
          <h5 className="pt-1 pb-2 pl-2 offer-sub-heading-terms-conditions">Terms & conditions</h5>
          <div className="offer-overview-data">

            <div className={`term-condition-container`}>
              <div
                role="group"
                aria-labelledby={`terms-conditions-radio-group`}
                key={`terms-conditions`}
              >

                {termsConditions.map((termCondition, termConditionIndex) =>
                  <div
                    className="custom-checkbox custom-control custom-controlled-checkbox terms-conditions-data"
                    key={`${termCondition.id}-${termConditionIndex}`}
                  >
                    <Input
                      type="checkbox"
                      name={`term-condition-${termConditionIndex}`}
                      value={termCondition.id}
                      color={`primary`}
                      id={`term-condition-${termCondition.id}-${termConditionIndex}`}
                      data-terms-conditions-id={termCondition.id}
                      defaultChecked={
                        stepFormData &&
                        stepFormData.terms_conditions &&
                        stepFormData.terms_conditions["terms_conditions"] &&
                        stepFormData.terms_conditions["terms_conditions"].includes(termCondition.id)
                            ? true
                            : false
                      }
                      data-is-checked={
                        stepFormData &&
                        stepFormData.terms_conditions &&
                        stepFormData.terms_conditions["terms_conditions"] &&
                        stepFormData.terms_conditions["terms_conditions"].includes(termCondition.id)
                            ? true
                            : false}
                      style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1", lineHeight: "1.15"}}
                      onChange={(e) => this.handleCheckboxChangeTermsConditions(e, "terms_conditions", termCondition.id, null, null)}
                    />
                    <Label
                      check
                      className="custom-controlled-checkbox-label"
                      style={{width: "100%"}}
                    >
                      <span className="plan-offer-line-description">
                        {termCondition.header}
                      </span>
                    </Label>
                    <div className="terms-conditions" style={termCondition.text && termCondition.text !== "" ? {paddingLeft: "25px", marginTop: "20px"} : {paddingLeft: "0", marginTop: "10px"}}>
                      {termCondition.text &&
                      <div
                        dangerouslySetInnerHTML={{ __html: termCondition.text }}
                        key={`term-condition-description-${termConditionIndex}`}
                      />
                      }
                      {termCondition.attachments &&
                      termCondition.attachments.length > 0 &&
                      <ul className="list-files">
                        {console.log("attachments ->", termCondition.attachments)}
                        {termCondition.attachments.map(({ id, text, filename}, attachmentIndex) => {
                          let fileExt = filename.split(".").pop();
                          return (
                              <li key={`uploaded-attachment-${attachmentIndex}`} className={`term-condition-file-extension-${fileExt ? fileExt : "default"}`}>
                                <button
                                    className={`attachments-uploaded-preview-item-download-btn btn-link ${fileExt ? `file-extension-${fileExt}` : `file-extension-default`}`}
                                    key={`terms-conditions-download-${id}`}
                                    onClick={(e) => this.downloadAttachment(e, termCondition.id, id, filename)}
                                >
                                  { filename }
                                </button>
                              </li>
                          )}
                        )}
                      </ul>}
                    </div>
                  </div>)}

              </div>
            </div>

          </div>
        </div>}

      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  loader: store.loader,
  user: store.user,
});

export default connect(mapStateToProps)(
    withTranslation()(withRouter(Overview))
);
