import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ParentTable from '../../../../components/ParentTable';
import { GetTenantEmailTemplates } from '../../../../controllers/tenants';
import EmailTemplateForm from "./Form";
import EmailTemplatePreview from "./Preview";
import RemoveEmailTemplateForm from "./Remove";

class EmailTemplates extends React.Component {
  constructor(props) {
    super(props);
    console.log("EmailTemplates props ->", this.props);
  }

  formatDate = (cell) => {
    if (cell) {
      return <span className="date-format">{this.props.t("date_format",
        { date: new Date(cell) })}</span>
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  render() {
    const { id, tabTitle } = this.props;
    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="EmailTemplates"
          id={id}
          keyField="id"
          include="id"
          noTopBorder={true}
          data={GetTenantEmailTemplates}
          wrapperClasses="table-email-templates"
          columns={[
            {
              dataField: "id",
              text: "ID",
              headerStyle: { width: '0' },
              hidden: true,
            },
            {
              dataField: "type",
              text: "Type",
              headerStyle: { width: '15%' },
              sort: true,
              attrs: {
                'data-label': 'Type'
              }
            },
            {
              dataField: "subject",
              text: "Subject",
              headerStyle: { width: '25%' },
              sort: true,
              attrs: {
                'data-label': 'Subject'
              }
            },
            {
              dataField: "from_name",
              text: "From name",
              headerStyle: { width: '25%' },
              sort: true,
              attrs: {
                'data-label': 'From name'
              }
            },
            {
              dataField: "from_email",
              text: "From email",
              //formatter: this.formatDate,
              headerStyle: { width: '15%' },
              sort: true,
              attrs: {
                'data-label': 'From email'
              }
            },
          ]}
          rowAction="edit"
          rowActionForm={EmailTemplateForm}
          headerDropdownOpts={[{
            label: 'Add',
            action: EmailTemplateForm
          }]}
          rowDropdownOpts={[{
            action: 'Preview template',
            label: 'Preview template',
            form: EmailTemplatePreview
          }, {
            action: 'Edit',
            label: 'Edit',
            form: EmailTemplateForm
          }, /*{
                action: 'Edit HTML',
                label: 'Edit HTML',
                form: EditTemplateEmail
              },*/ {
            action: 'Remove',
            label: 'Remove',
            form: RemoveEmailTemplateForm,
            condition: {
              data: 'id'
            }
          }]}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(connect()(EmailTemplates)));
