import * as types from "../constants";

const initialState = {
  searchDropDownStateHidden: "",
  searchDropDownStateOpen: " show",
  searchDropDownState: false,
  solrSearchResultUpdate: false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SOLR_MENU_VISIBILITY_SHOW:
      return {
        ...state,
        searchDropDownStateOpen: state.searchDropDownStateOpen,
        searchDropDownState: true,
      };
    case types.SOLR_MENU_VISIBILITY_HIDE:
      return {
        ...state,
        searchDropDownStateHidden: state.searchDropDownStateHidden,
        searchDropDownState: false,
      };
    case types.SOLR_MENU_VISIBILITY_TOGGLE:
      return {
        ...state,
        searchDropDownState: !state.searchDropDownState
      };
    case types.SOLR_RESULT_UPDATE:
      return {
        ...state,
        solrSearchResultUpdate: !state.solrSearchResultUpdate,
      };

    default:
      return state;
  }
}