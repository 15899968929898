import * as types from "../constants";
import IBAN from "iban";
import validbarcode from "barcode-validator";
import * as EmailValidator from "email-validator";
import {isNumeric} from "../../services/misc";

export function validateEmail(data) {
  console.log('validateEmail - data ->', data);

  const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
  return {
    type: types.VALIDATE_EMAIL,
    valid: regex.test(data)
  };
}

export function validateEmailWithValidator(data) {
  return {
    type: types.VALIDATE_EMAIL,
    valid: EmailValidator.validate(data)
  };
}

export const validateInt = (data) => {
  return isNumeric(data);
};

export function validateIBAN(data) {
  // if valid return IBAN valid
  return {
    type: types.VALIDATE_IBAN, valid: IBAN.isValid(data)
  };
}

export function validateInputIBAN(data) {
  let validIban = false;
  // IBAN.isValid(data);
  if (IBAN.isValid(data)) {
    validIban = true;
  }
  // IBAN.isValidBBAN(selectedCountry, data)
  if (IBAN.isValidBBAN('NL', data)) {
    validIban = true;
  }
  // if valid return IBAN valid
  if (validIban) {
    return {
      type: types.VALIDATE_IBAN, valid: IBAN.isValid(data)
    };
  }
  return false;
}

export function validateDate(date) {
  console.log('validateActions validateDate date', date);
  let getDate = date ? new Date(date) : null;
  console.log('validateActions validateDate getDate', getDate);

  return {
    type: types.VALIDATE_DATE,
    newDate: getDate
  };
}

export const validateBarcode = (data) => {
  const regex = /^(?=[\da-zA-Z]{13}$)/gi;
  return regex.test(data)
};

export const validateAlphaNum = (data) => {
  return validbarcode(data);
};