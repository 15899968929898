import React from 'react';
//import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import App from './js/components/App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { toast as toastNotifyMulti } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from './serviceWorker';
import './sass/classic.scss';
import 'animate.css';
import {
    faAdjust,
    faCalendar,
    faArrowsAltV,
    faBalanceScale,
    faCoffee,
    faCheckSquare,
    faHandPointer,
    faBriefcase,
    faReceipt,
    faBuilding,
    faCalculator,
    faPortrait,
    faQuestion,
    faInfoCircle,
    faQuestionCircle,
    faUser,
    faStar,
    faLandmark,
    faFemale,
    faMale,
    faTimes,
    faExclamation,
    faExclamationTriangle,
    faHandPointUp,
    faPlusSquare,
    faUserCircle,
    faMinusSquare,
    faEnvelope,
    faDownload,
    faArchive,
    faToolbox,
    faTools,
    faArrowLeft,
    faArrowRight,
    faCaretLeft,
    faCaretRight,
    faLink,
    faSquareRootAlt,
    faDesktop
} from '@fortawesome/free-solid-svg-icons';

library.add(
    fab,
    faCalendar,
    faAdjust,
    faCheckSquare,
    faCoffee,
    faArrowsAltV,
    faBalanceScale,
    faHandPointer,
    faBriefcase,
    faReceipt,
    faBuilding,
    faCalculator,
    faPortrait,
    faQuestion,
    faQuestionCircle,
    faInfoCircle,
    faUser,
    faLandmark,
    faFemale,
    faMale,
    faStar,
    faSquareRootAlt,
    faTimes,
    faExclamation,
    faExclamationTriangle,
    faHandPointUp,
    faPlusSquare,
    faUserCircle,
    faMinusSquare,
    faEnvelope,
    faDownload,
    faArchive,
    faToolbox,
    faTools,
    faArrowLeft,
    faArrowRight,
    faCaretLeft,
    faCaretRight,
    faLink,
    faDesktop
);

toastNotifyMulti.configure({
    autoClose: 18000,
    draggable: false,
    theme: "light",
});

const root = ReactDOMClient.createRoot(document.getElementById('root'));
//const root = document.getElementById('root');
root.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
);
/*
ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </React.StrictMode>,
    root
);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
