import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import { Row, Col, Button, Input } from 'reactstrap';
import { GetInvoices } from '../../../../controllers/relations';
import { GetInvoice } from '../../../../controllers/invoices';
import InvoiceList from './List';
import InvoiceDetail from '../Details';
import InvoiceForm from '../New';
import { loaderToggle } from '../../../../redux/actions/loaderActions';
import { hideSidebarMenuInvoices, showSidebarMenuInvoices } from '../../../../redux/actions/sidebarMenuActions';
import { createSelector } from 'reselect';
import { faCircleNotch, faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterSearch from '../../../../components/FilterSearch';
import { Helmet } from "react-helmet";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const InvoiceDetails = ({ ...props }) => {
  //const history = useHistory();
  const params = useParams();
  let { param } = params;

  const matchedRoute = useRouteMatch('/:name/:relationId/invoices/:invoiceId');

  let { invoice, relation, relationSelected, setRelationSelected, match, history, dispatch, solrSearch, user, id, titleUpdate, loader } = props;

  const newTitle = matchedRoute && matchedRoute.params && matchedRoute.params.invoiceId ? `${titleUpdate} | ${matchedRoute.params.invoiceId}` : `${titleUpdate}`;
  const [invoiceId, setInvoiceId] = useState(matchedRoute && matchedRoute.params && matchedRoute.params.invoiceId ? matchedRoute.params.invoiceId : null);
  const [relationId, setRelationId] = useState(params && params.relationId ? parseInt(params.relationId) : null);
  //const [activeTab, setActiveTab] = useState(5);
  //const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);
  const [invoiceList, setInvoiceList] = useState(relation.invoiceList ? relation.invoiceList : []);
  const [selectedItem, setSelectedItem] = useState(props.invoiceId ? props.invoiceId : matchedRoute && matchedRoute.params && matchedRoute.params.invoiceId ? matchedRoute.params.invoiceId : null);
  const [data, setData] = useState([]);
  const [dataDetails, setDataDetails] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [invoiceLineIsUpdated, setInvoiceLineIsUpdated] = useState(false);
  //const [isSorted, setIsSorted] = useState(false);
  const [refreshList, setRefreshList] = useState(null);
  const [invoiceListUpdatedDetails, setInvoiceListUpdatedDetails] = useState([]);
  const [listFilterText, setListFilterText] = useState("");
  //const [expanded, setExpanded] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState(null);

  const redirect = () => window.location = `/invoices/concept?id=${invoiceId}`;

  const reload = (invId, action, resData, invoice_line_action, table_invoice_lines_data) => {
    //console.log("processUpdateApi - ListDetails (action === 'update') - invId ->", invId);
    //console.log("processUpdateApi - ListDetails (action === 'update') - action ->", action);
    //console.log("processUpdateApi - ListDetails (action === 'update') - resData ->", resData);
    //console.log("processUpdateApi - ListDetails (action === 'update') - data ->", data);
    //console.log("processUpdateApi - ListDetails (action === 'update') - invoice_line_action ->", invoice_line_action);
    //console.log("processUpdateApi - ListDetails (action === 'update') - table_invoice_lines_data ->", table_invoice_lines_data);
    //console.log("processUpdateApi - ListDetails (action === 'update') - props ->", props);
    //console.log("processUpdateApi - ListDetails (action === 'update') - invoice ->", invoice);
    //console.log("processUpdateApi - ListDetails (action === 'update') - invoiceLineIsUpdated ->", invoiceLineIsUpdated);

    if (!invId || !action) {
      return;
    }

    if (!resData) {
      resData = invoiceList;
    }

    if (action === 'add' && resData && resData.length > 0) {
      console.log("Add invoice - resData ->", resData);
      console.log("Add invoice - resData - invId ->", invId);

      let sortedData;
      if (resData.length === 1) {
        sortedData = resData;
      } else {
        sortedData = resData.sort((a, b) => b.date > a.date ? 1 : -1);
      }

      dispatch({ type: 'INVOICE', payload: { invoiceList: sortedData } });
      dispatch({ type: 'RELATION', payload: { invoiceList: sortedData } });

      setData(sortedData);
      setSelectedItem(invId);
      setHasLoaded(true);

      history.push(`/relations/${relationId}/invoices/${invId}`);
    }

    if (action === 'delete' && resData && resData.length > 0) {
      const sortedData = resData;

      sessionStorage.removeItem('lastVisitInvId');

      dispatch({ type: 'INVOICE', payload: { invoiceList: sortedData } });
      dispatch({ type: 'RELATION', payload: { invoiceList: sortedData } });

      setSelectedItem(sortedData[0].id);
      setData(sortedData);
      setHasLoaded(true);

    }

    if (action === 'update' && resData) {
      let index, updatedInvoiceList;
      const invoiceList = data;

      // check if invoice line is updated, added or deleted
      //if (invoice_line_action && invoice_line_action === 'delete') {
      if (invoice_line_action) {

        //console.log("processUpdateApi - ListDetails (action === 'update') - invoice_line_action ->", invoice_line_action);

        // to do get requested invoice from API by invId
        (async () => {

          index = invoiceList.findIndex(i => i.id === invId);
          updatedInvoiceList = [...invoiceList]; // important to create a copy, otherwise you'll modify state outside of setState call

          const { ok, data } = await GetInvoice(null, invId);

          //console.log("processUpdateApi - ListDetails (action === 'update') - dataDetails ->", dataDetails);
          //console.log("processUpdateApi - ListDetails (action === 'update') - data ->", data);
          //console.log("processUpdateApi - ListDetails (action === 'update') - index ->", index);
          //console.log("processUpdateApi - ListDetails (action === 'update') - updatedInvoiceList ->", updatedInvoiceList);
          //console.log("processUpdateApi - ListDetails (action === 'update') - invId ->", invId);
          //console.log("processUpdateApi - ListDetails (action === 'update') - data ->", data);
          //console.log("processUpdateApi - ListDetails (action === 'update') - invoice Line updated - updatedInvoiceList[index] ->", updatedInvoiceList[index]);

          if (ok && data && data.data) {

            dispatch({ type: 'INVOICE', payload: data.data });
            setDataDetails(data.data);

            updatedInvoiceList[index].price = data.data.price ? parseFloat(data.data.price) : 0;
            updatedInvoiceList[index].price_total = data.data.price_total ? parseFloat(data.data.price_total) : 0;
            updatedInvoiceList[index].price_vat = data.data.price_vat ? parseFloat(data.data.price_vat) : 0;

          } else {

            updatedInvoiceList[index].price = 0;
            updatedInvoiceList[index].price_total = 0;
            updatedInvoiceList[index].price_vat = 0;
          }

          //console.log("processUpdateApi - ListDetails (action === 'update') - invoice Line updated - updatedInvoiceList[index] ->", updatedInvoiceList[index]);

        })();
      }

      //console.log("processUpdateApi - ListDetails (action === 'update') - invoice Line updated - updatedInvoiceList ->", updatedInvoiceList);

      if (updatedInvoiceList && updatedInvoiceList.length > 0) {
        const sortedData = updatedInvoiceList.sort(
          (a, b) => b.date > a.date ? 1 : -1);

        dispatch({ type: 'INVOICE', payload: { invoiceList: sortedData } });
        dispatch({ type: 'RELATION', payload: { invoiceList: sortedData } });

        setData(sortedData);
      }

      setHasLoaded(true);
    }
  };

  const toggleInvoicesList = () => {
    if (data && data.length > 0) {
      const isOpen = props.sidebarMenu.isSidebarMenuInvoicesOpen;
      if (isOpen) {
        //setSidebarMenuOpen(false);
        dispatch(hideSidebarMenuInvoices());
      } else {
        //setSidebarMenuOpen(true);
        dispatch(showSidebarMenuInvoices());
      }
    }
  };

  useEffect(() => {
    let relation_id = relationId;

    if (relationId !== parseInt(params.relationId)) {
      setRelationId(parseInt(params.relationId));
      relation_id = parseInt(params.relationId);
    }

    console.log("ListDetails (invoices) - invoiceList ->", invoiceList);
    console.log("ListDetails (invoices) - data ->", data);

    (async () => {
      if (!loader)
        dispatch(loaderToggle(true));

      const [invoiceList] = await Promise.all([
        GetInvoices(null, parseInt(relation_id)),
      ]);

      // get list relation invoices
      if (invoiceList.ok) {
        const { data } = invoiceList.data;
        setData(data);
        setInvoiceList(data);

        if (!hasLoaded) setSelectedItem(selectedItem ? selectedItem : data && data.length > 0 ? data[0].id : null);

      }
      dispatch(loaderToggle(false));

    })();
  }, [user]);

  useEffect(() => {
    if (invoiceListUpdatedDetails && invoiceListUpdatedDetails.length > 0) {
      if (data && data.length > 0) {
        data.map((item) => {
          if (item.id === invoiceListUpdatedDetails.id) {
            item.date = invoiceListUpdatedDetails.date;
            item.status = invoiceListUpdatedDetails.status;
            item.status_invoice.label = invoiceListUpdatedDetails.status_invoice &&
              invoiceListUpdatedDetails.status_invoice.status
              ? invoiceListUpdatedDetails.status_invoice.status
              : '';
            item.invoice_no = invoiceListUpdatedDetails.invoice_no;
            item.price_total = invoiceListUpdatedDetails.price_total;
          }
          return item;
        });

        const sortedData = data.sort((a, b) => b.date > a.date ? 1 : -1);

        dispatch({ type: 'INVOICE', payload: { invoiceList: sortedData } });
        dispatch({ type: 'RELATION', payload: { invoiceList: sortedData } });

        setData(sortedData);
        //setIsSorted(true);

        if (!hasLoaded) setSelectedItem(selectedItem ? selectedItem : sortedData[0].id);

      } else {
        setData([]);
      }
    }
  }, [invoiceListUpdatedDetails]); // invoiceListUpdatedDetails, data, invoiceList, dispatch, hasLoaded, selectedItem

  useEffect(() => {
    if (data && data.length > 0) {
      setInvoiceList(data);

      let invId;
      let lastVisitInvId = sessionStorage.getItem('lastVisitInvId');
      if (lastVisitInvId && data.map((item) => parseInt(item.id) === parseInt(lastVisitInvId) && parseInt(item.relation_id) === parseInt(relationId))) {
        invId = parseInt(lastVisitInvId);
      } else if (matchedRoute && matchedRoute.params && matchedRoute.params.invoiceId && data && data.length > 0 && data.filter(i => parseInt(i.id) === parseInt(matchedRoute.params.invoiceId))) {
        invId = parseInt(matchedRoute.params.invoiceId);
      } else if (data.filter(i => parseInt(i.id) === parseInt(data[0].id) && parseInt(i.relation_id) === parseInt(relationId))) {
        invId = parseInt(data[0].id);
      }

      if (invId) {
        setSelectedItem(invId);
        setInvoiceId(invId);
        setEmptyMessage(null);
      }
    } else {

      sessionStorage.removeItem('lastVisitInvId');

      if (!loader) {
        //setTimeout(() => {
        setEmptyMessage("No invoices found.");
        //}, 1500);
      }
    }
  }, [data]);

  useEffect(() => {
    setDataDetails(null);

    if (invoiceList && invoiceList.length > 0) {
      let dataInv;
      invoiceList.map((item, i) => {
        if (parseInt(item.id) === parseInt(selectedItem) && parseInt(item.relation_id) === parseInt(relationId)) {
          dataInv = item;
        }
      });
      if (dataInv) {
        dataInv.invoiceList = data;
        setDataDetails(dataInv);

        dispatch({ type: 'INVOICE', payload: dataInv });
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (refreshList === true || solrSearch.solrSearchResultUpdate === true) {
      setData([]);

      let relation_id = relationId;
      if (relationId !== parseInt(params.relationId)) {
        setRelationId(parseInt(params.relationId));
        relation_id = parseInt(params.relationId);
      }

      (async () => {
        if (!loader)
          dispatch(loaderToggle(true));

        const [invoiceList] = await Promise.all([
          GetInvoices(null, parseInt(relation_id)),
        ]);

        // get list relation invoices
        if (invoiceList.ok) {
          const { data } = invoiceList.data;
          setData(data);
        }
        dispatch(loaderToggle(false));

      })();

      setRefreshList(false);
    }
  }, [refreshList, solrSearch.solrSearchResultUpdate]);

  useEffect(() => {
    console.log("Invoices ListDetails - isUpdated ->", isUpdated);

    if (isUpdated === true) {
      console.log("Invoices ListDetails - subscriptionLine isUpdated !!!!!!! ->", isUpdated);

      //setHasLoaded(true);
      setIsListUpdated(true);
      setIsUpdated(false);
    }

  }, [isUpdated]);

  useEffect(() => {
    if (isListUpdated === true) {
      const currentDataList = data;

      (async () => {
        const [invoiceList] = await Promise.all([
          GetInvoices(null, parseInt(relationId)),
        ]);

        // get list relation invoices
        if (invoiceList.ok) {
          const { data } = invoiceList.data;
          setInvoiceList(data);
        }
      })();

      setIsUpdated(false);
      setIsListUpdated(false);
    }

  }, [isListUpdated]);

  const refreshInvoiceList = (e) => {
    if (e) {
      e.preventDefault();
    }

    setEmptyMessage(null);
    setRefreshList(true);
  };

  const handleFilterTextInputChange = (e) => {
    //e.preventDefault();
    //console.log("handleFilterTextInputChange - el_id ->", el_id);
    //console.log("handleFilterTextInputChange - e ->", e);
    //console.log("handleFilterTextInputChange - e.target ->", e.target);

    setListFilterText(e.target.value);

    setTimeout(() => {
      e.target.focus();
    }, 100);
    //e.target.focus();
  };

  //console.log("emptyMessage - emptyMessage ->", emptyMessage);
  //console.log("emptyMessage - hasLoaded ->", hasLoaded);

  if (data === null || (data && data.length === 0)) {
    return (
      <>
        {newTitle &&
          <Helmet>
            <title>{newTitle}</title>
          </Helmet>}
        <Row className="mt-4 invoices-details-container full-height-container">
          <Col className="col-md-3 col-lg-3 col-sm-12 f2x-sbscr-det-cont">
            <div className={`align-content-start all-0 d-flex flex-column flex-list-items ${"sidebarMenu" + (props.sidebarMenu.isSidebarMenuInvoicesOpen ? " toggled-list" : "")}`}>
              <div>
              {!toggleForm && emptyMessage && !loader &&
                    <p className="empty-message">{emptyMessage}</p>
                  }
                <OverlayTrigger
                  key={`add-invoices-details-tooltip-overlay`}
                  placement={`bottom`}
                  transition={false}
                  delay={200}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      container="body"
                      id={`tooltip-bottom-add-invoices-details`}
                    >
                      {`Add`}
                    </Tooltip>}
                >    
                  <Button color="gray" className="mb-1 btn"
                    onClick={() => setToggleForm(true)}>
                    <FontAwesomeIcon color="gray" icon={faPlus} />
                  </Button>
                </OverlayTrigger>
                {data && data.length > 0 &&
                  <OverlayTrigger
                    key={`refresh-invoices-details-tooltip-overlay`}
                    placement={`bottom`}
                    transition={false}
                    delay={200}
                    overlay={
                      <Tooltip
                        className="tooltip"
                        container="body"
                        id={`tooltip-bottom-refresh-invoices-details`}
                      >
                        {`Refresh`}
                      </Tooltip>}
                  >
                    <Button color="gray" className="mb-1 btn ml-1" style={{ float: "right" }}
                      onClick={(e) => refreshInvoiceList(e)}>
                      <FontAwesomeIcon color="gray" icon={faCircleNotch} />
                    </Button>
                  </OverlayTrigger>
                }
              </div>
              {data &&
                <InvoiceList
                  className="f2x-ls-det-invoices-list"
                  data={data}
                  relationId={relationId}
                  relation={relation}
                  invoiceId={invoiceId}
                  setData={setData}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  setToggleForm={setToggleForm}
                  isUpdated={isUpdated}
                  invoiceListUpdatedDetails={invoiceListUpdatedDetails}
                  invoiceList={invoiceList}
                  hasLoaded={hasLoaded}
                />}
            </div>
          </Col>
          <Col className="col-md-9 col-lg-9 col-sm-12 f2x-rel-mv-detail invoice d-flex flex-wrap">
            {toggleForm && <InvoiceForm
              id={selectedItem}
              setToggleForm={setToggleForm}
              relationId={relationId}
              relation={relation}
              setData={setData}
              updateDetails={reload}
            />}
          </Col>
        </Row>
        <div className="invoice-list-btns-small-devices shadow">
          <Button color="primary" className="btn shadow" data-label="Add" title="Add"
            onClick={() => setToggleForm(true)}>Add
          </Button>
          <Button color="primary" className="btn shadow" data-label="List" title="List"
            onClick={() => dispatch(toggleInvoicesList())}>List
          </Button>
        </div>
      </>)
  } else {
    return (
      <>
        {newTitle &&
          <Helmet>
            <title>{newTitle}</title>
          </Helmet>}
        <Row className="mt-4 invoices-details-container full-height-container">
          <Col className={`col-md-3 col-lg-3 col-sm-12 col-12 f2x-sbscr-det-cont`}>
            <div className={`align-content-start all-0 d-flex flex-column flex-list-items ${"sidebar-menu" + (props.sidebarMenu.isSidebarMenuInvoicesOpen ? " toggled" : "")}`}>
              <div className="invoice-list-btns">
                <OverlayTrigger
                  key={`add-invoice-tooltip-overlay`}
                  placement={`bottom`}
                  transition={false}
                  delay={200}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      container="body"
                      id={`tooltip-bottom-add-invoice`}
                    >
                      {`Add`}
                    </Tooltip>}
                >
                  <Button color="gray" className="mb-1 btn"
                    onClick={() => setToggleForm(true)}>
                    <FontAwesomeIcon color="gray" icon={faPlus} />
                  </Button>
                </OverlayTrigger>

                <FilterSearch
                  listFilterText={listFilterText}
                  handleFilterTextInputChange={handleFilterTextInputChange}
                  inputId="filter_invoice_text"
                  inputName="filter_invoice_text"
                  setListFilterText={setListFilterText}
                />

                {data && data.length > 0 &&
                  <OverlayTrigger
                    key={`refresh-invoices-tooltip-overlay`}
                    placement={`bottom`}
                    transition={false}
                    delay={200}
                    overlay={
                      <Tooltip
                        className="tooltip"
                        container="body"
                        id={`tooltip-bottom-refresh-invoices`}
                      >
                        {`Refresh`}
                      </Tooltip>}
                  >
                    <Button color="gray" className="mb-1 btn ml-1" style={{ float: "right" }}
                      onClick={(e) => refreshInvoiceList(e)}>
                      <FontAwesomeIcon color="gray" icon={faCircleNotch} />
                    </Button>
                  </OverlayTrigger>
                }
              </div>
              {data &&
                <InvoiceList
                  className="f2x-ls-det-invoices-list"
                  data={data}
                  relationId={relationId}
                  relation={relation}
                  invoiceId={invoiceId}
                  setData={setData}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  setToggleForm={setToggleForm}
                  isUpdated={isUpdated}
                  invoiceListUpdatedDetails={invoiceListUpdatedDetails}
                  invoiceList={invoiceList}
                  hasLoaded={hasLoaded}
                  listFilterText={listFilterText}
                />}
            </div>
          </Col>
          <Col className="col-md-9 col-lg-9 col-sm-12 col-12 f2x-rel-mv-detail invoice d-flex flex-wrap">
            {!toggleForm && selectedItem &&
              <InvoiceDetail
                id={selectedItem && !isNaN(selectedItem) ? selectedItem : props.invoiceId}
                invoiceId={invoiceId}
                setInvoiceId={setInvoiceId}
                relationId={relationId}
                relation={relation}
                setRelationId={setRelationId}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                dataDetails={dataDetails}
                data={data}
                invoiceList={invoiceList}
                setData={setData}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
                setIsListUpdated={setIsListUpdated}
                updateDetails={reload}
                deleteConceptEnabled={true}
                invoiceListUpdatedDetails={invoiceListUpdatedDetails}
                setInvoiceListUpdatedDetails={setInvoiceListUpdatedDetails}
                hasLoaded={hasLoaded}
                refreshInvoiceList={refreshInvoiceList}
                invoiceLineIsUpdated={invoiceLineIsUpdated}
              />}
            {toggleForm &&
              <InvoiceForm
                id={selectedItem}
                setToggleForm={setToggleForm}
                setSelectedItem={setSelectedItem}
                relationId={relationId}
                relation={relation}
                setData={setData}
                invoiceList={invoiceList}
                updateDetails={reload}
              />}

            {/*!toggleForm && emptyMessage &&
              <p style={{textAlign:"right", width:"100%"}}><span>{emptyMessage}</span></p>
            */}
          </Col>
        </Row>
        <div className="invoice-list-btns-small-devices shadow">
          <Button color="primary" className="btn shadow"
            onClick={() => setToggleForm(true)}>Add
          </Button>
          <Button color="primary" className="btn shadow"
            onClick={() => toggleInvoicesList()}>List
          </Button>
        </div>
      </>);
  }
};

const sidebarMenuSelector = createSelector(
  state => state.sidebarMenu,
  sidebarMenu => sidebarMenu
);

const mapStateToProps = ({ solrSearch, sidebarMenu, relation, dispatch, invoice, user, loader }) => ({ solrSearch, sidebarMenu, relation, dispatch, invoice, user, loader });

export default withRouter(connect(mapStateToProps)(InvoiceDetails));
