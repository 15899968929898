import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ParentTable from '../../../../components/ParentTable';
import { GetTenantPDFTemplates } from '../../../../controllers/tenants';
import PDFTemplateForm from "./Form";
import RemovePDFTemplateForm from "./Remove";

class PDFTemplates extends React.Component {
  constructor(props) {
    super(props);
    console.log("PDFTemplates props ->", this.props);
  }

  formatDate = (cell) => {
    if (cell) {
      return <span className="date-format">{this.props.t("date_format",
        { date: new Date(cell) })}</span>
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  render() {
    const { id, tabTitle } = this.props;
    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="PDFTemplates"
          id={id}
          keyField="id"
          include="id"
          noTopBorder={true}
          data={GetTenantPDFTemplates}
          wrapperClasses="table-pdf-templates"
          columns={[
            {
              dataField: "id",
              text: "ID",
              headerStyle: { width: '0' },
              hidden: true,
            },
            {
              dataField: "type",
              text: "Type",
              headerStyle: { width: '15%' },
              sort: true,
              attrs: {
                'data-label': 'Type'
              }
            },
            {
              dataField: "description",
              text: "Description",
              headerStyle: { width: '25%' },
              sort: true,
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "version",
              text: "Version",
              headerStyle: { width: '25%' },
              sort: true,
              attrs: {
                'data-label': 'Version'
              }
            },
            {
              dataField: "notes",
              text: "Notes",
              headerStyle: { width: '15%' },
              sort: true,
              attrs: {
                'data-label': 'Notes'
              }
            },
          ]}
          rowAction="edit"
          rowActionForm={PDFTemplateForm}
          headerDropdownOpts={[{
            label: 'Add',
            action: PDFTemplateForm
          }]}
          rowDropdownOpts={[/*{
                action: 'Preview template',
                label: 'Preview template',
                form: PDFTemplatePreview
              }, */{
              action: 'Edit',
              label: 'Edit',
              form: PDFTemplateForm
            }, /*{
                action: 'Edit HTML',
                label: 'Edit HTML',
                form: EditTemplatePDF
              }, */{
              action: 'Remove',
              label: 'Remove',
              form: RemovePDFTemplateForm,
              condition: {
                data: 'id'
              }
            }]}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(connect()(PDFTemplates)));
