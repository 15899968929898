import React, {useState} from 'react';
import {File} from "react-feather";
import {connect} from 'react-redux'
import {NavLink, withRouter} from "react-router-dom";
import {loaderToggle} from '../../../redux/actions/loaderActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row,
} from 'reactstrap';
import Toast from '../../../components/Toast';
import ApiService from '../../../services/apiService';
import {GetDumpFiles} from '../../../controllers/billing_runs';

const DownloadApi = new ApiService(),
    DownloadAPI = DownloadApi.downloadAPI;

const DataDump = ({ ...props }) => {
  const { dispatch, loader } = props;

  const handleDataDumpDownload = (e) => {
    e.preventDefault();
    console.log("handleDataDumpDownload - downloadAttachment - e ->", e);

    if (!loader) {
      (async () => {
        dispatch(loaderToggle(true));

        const { ok, data, responseUrl } = await GetDumpFiles();

        if (ok && data) {
          const link = document.createElement('a');

          let fileName = "dump_files.zip";
          if (responseUrl) {
            let parts = responseUrl.split('/');
            let lastSegmentFileName = parts.pop() || parts.pop();  // handle potential trailing slash
            fileName = lastSegmentFileName;
          }

          link.href = window.URL.createObjectURL(new Blob([data]));
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          dispatch(loaderToggle(false));
        } else {
          dispatch(loaderToggle(false));
        }

        //const { ok, data } = await DownloadAPI(`/admin_tools/grid_data_dump`, `blob`);
        //const data = await DownloadAPI(`/admin_tools/grid_data_dump`, `blob`);
        //console.log("handleDataDumpDownload - ok ->", ok);
        //console.log("handleDataDumpDownload - data ->", data);

        /*
        if (!data) {
          await Toast.fire(
              {title: 'No data found.', icon: 'error'} ).
              then(r => console.log(r))

          dispatch(loaderToggle(false));
        }
        */

      })();
    }
  };

  return(
      <>
        <h1 className="text-bold mb-3">Data dump files</h1>

        {/* GET DATA DUMP */}
        <Row>
          <Col md="12" xl="12">
            <Card className="border mb-3">
              <CardBody className="p-3">
                <Form>
                  <FormGroup className="row align-items-center">
                    <Col md={2}>
                      <Label>Download file(s)</Label>
                    </Col>
                    <Col md={5}>
                      <Button
                          type="submit"
                          color="primary"
                          className="col-md-5"
                          onClick={(e) => handleDataDumpDownload(e)}
                      >Download</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
  );
};

export default withRouter(connect()(DataDump));
