import React, { useEffect, useState } from 'react';
import {connect, useDispatch} from 'react-redux';
import { switchTenant } from "../hooks/useSwitchTenant";
import {
  withRouter,
  useRouteMatch,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import {
  GetRelation,
  GetRelationsDependencies,
  GetRelationTypes
} from '../controllers/relations'
import {GetPaymentConditions} from '../controllers/payment_conditions'
import {relation} from '../redux/actions/relationActions'
import {subscription} from '../redux/actions/subscriptionActions'
import {invoice} from '../redux/actions/invoiceActions'
import CONSTANTS from '../services/constants'
import MasterView from './MasterView'
import Sidebar from './Sidebar'
import Persons from '../pages/relation/persons/List'
import Addresses from '../pages/relation/addresses/List'
import Emails from '../pages/relation/emails/List'
import BankAccounts from '../pages/relation/bank_accounts/List'
import SubscriptionsListDetails from '../pages/relation/subscriptions/details/ListDetails'
import InvoicesListDetails from '../pages/relation/invoices/details/ListDetails'
import Details from '../pages/relation/details/Form'
import {GetBrand, SwitchTenant} from '../controllers/tenants';
import {clearSessionStorage} from '../services/misc';
import {updateTenant, updateUser} from '../redux/actions/userActions';
import {brand} from '../redux/actions/brandActions';

const MVDetails = ({relation, subscription, invoice, user, sidebar, match, theme, solrSearch, ...props}) => {
  const history = useHistory();
  const relation_ids = sessionStorage.getItem("relation_ids");
  const location = useLocation();
  const dispatch = useDispatch();

  const [relationSelected, setRelationSelected] = useState({});
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [updateRelation, setUpdateRelation] = useState(false);
  const [isSwitchingTenant, setIsSwitchingTenant] = useState(false);
  const [tenant, setTenant] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  let params = useParams();
  //console.log("MVDetails - params ->", params);

  let { relationId, subscrId, invoiceId, emailId, tab } = params;
  if (subscrId && !tab) {
    tab = 'subscriptions';
  }
  if (invoiceId && !tab) {
    tab = 'invoices';
  }
  if (emailId && !tab) {
    tab = 'emails';
  }

  //const [activeTab, setActiveTab] = useState(CONSTANTS.relationDetailsTabs[tab] || CONSTANTS.relationDetailsTabs['details']);
  const [activeTab, setActiveTab] = useState(tab && CONSTANTS.relationDetailsTabs[tab] ? CONSTANTS.relationDetailsTabs[tab] : CONSTANTS.relationDetailsTabs['details']);
  const [tabs, setTabs] = useState([]);
  const [reload, setReload] = useState(false);

  const updateDetails = (str) => {
    setReload(true);
  };

  const getPageTitle = (details, page) => {
    if (details && details.tenant) {
      return `GRiD | ${details && details.tenant && details.tenant.name ? details.tenant.name : " - "} | ${page} | ${details.customer_number ? details.customer_number : ""} | 
      ${details.primary_person_full_name ? details.primary_person_full_name : ""}`;
    } else {
      return `GRiD | ${page}`;
    }
  }

  /*
    Check storage relationId(s) belongs to tenant
   */
  useEffect(() => {
    if (location.pathname) {
      if (relationSelected && relationSelected.tenant && relationSelected.tenant.id) {
        if (user && user.tenant_id) {
          if (localStorage.getItem("relation_ids")) {
            let relation_ids = localStorage.getItem("relation_ids");
            if (Array.isArray(relation_ids) && relation_ids.includes(relationSelected.id)) {
              //switchTenant(relationSelected, user, location, dispatch);

            } else if (parseInt(relationSelected.id) === parseInt(relation_ids)) {
              //if (!isSwitchingTenant) {
                //setIsSwitchingTenant(true);
                //switchTenant(relationSelected, user, location, dispatch);
              //}

            } else {
              if (parseInt(user.tenant_id) !== parseInt(relationSelected.tenant.id)) {
                // redirect - if user is NOT authorized!
                //if (!isSwitchingTenant) {
                  //setIsSwitchingTenant(true);
                  //switchTenant(relationSelected, user, location, dispatch);
                //}

              }
            }
          }
        }
      }
    }
  }, [relationSelected]);

  useEffect(() => {
    // execute on location change
    if (subscrId) {
      sessionStorage.setItem('lastVisitSubscrId', subscrId);
    }
    if (invoiceId) {
      sessionStorage.setItem('lastVisitInvId', invoiceId);
    }

    //console.log("MVDetails - useEffect - location updated ->", location);
    //console.log("MVDetails - useEffect - location () updated ->", location);
    //console.log("MVDetails - activeTab ->", activeTab);
    //console.log("MVDetails - location ->", location); //props
    //console.log("MVDetails - props ->", props); //props
    //console.log("MVDetails - tab ->", tab); //props
    //console.log("MVDetails - CONSTANTS.relationDetailsTabs[tab] ->", CONSTANTS.relationDetailsTabs[tab]); //props
    //console.log("MVDetails - CONSTANTS.relationDetailsTabs[activeTab] ->", CONSTANTS.relationDetailsTabs[activeTab]); //props

    if (tab && CONSTANTS.relationDetailsTabs[tab] !== activeTab) {
      console.log("MVDetails - CONSTANTS.relationDetailsTabs[tab] !== activeTab ->", tab); 
      setActiveTab(CONSTANTS.relationDetailsTabs[tab]);
      setReload(true);
    }
  }, [location]);

  const checkRelationData = (relationData) => {
    console.log("checkRelationData - relationData ->", relationData);
    
    // if relationData.tenant_id !== 1 (=== Root) switch tenant 
    if (relationData && relationData.tenant_id && user.tenant_id && relationData.tenant_id !== 1 && relationData.tenant_id !== user.tenant_id) {
      (async () => {
        const [switchTenant, getBrand] = await Promise.all([
          SwitchTenant(relationData.tenant_id),
          GetBrand(null, relationData.tenant_id)
        ]);

        if (switchTenant.ok && getBrand.ok) {
          const tenant_id = switchTenant.data.data.id;
          const default_country_id = switchTenant.data.data.default_country_id;

          let newTenant = Object.assign({}, switchTenant.data.data);
          delete newTenant.parent;

          dispatch(updateTenant(newTenant));
          dispatch(updateUser({tenant_id}));

          let brandData = Object.assign({}, getBrand.data.data);

          if (brandData && brandData.theme) {
            brandData.theme = JSON.parse(brandData.theme)
          } else {
            brandData.theme = theme.currentTheme
          }

          if (brandData.theme) {
            dispatch(brand(brandData));
          }

          sessionStorage.setItem('tenant', JSON.stringify(switchTenant.data.data));
          sessionStorage.setItem('default_country_id', default_country_id ? default_country_id : 155); // default NL -> 155

          setIsSwitchingTenant(true);
        }
      })();
    } 
  };

  /*useEffect(() => {
    console.log("MVDetails - useEffect - activeTab ->", activeTab); //props tab
    console.log("MVDetails - useEffect - (params) tab ->", tab); //props tab
    console.log("MVDetails - useEffect - (activeTab) location ->", location); //props
    console.log("MVDetails - useEffect - (activeTab) CONSTANTS.relationDetailsTabs ->", CONSTANTS.relationDetailsTabs); //props
  }, [activeTab]);*/

  useEffect(() => {
    (async () => {
      await relation(null);
      await subscription(null);
      await invoice(null);

      const subscriptionDetails = {};
      const invoiceDetails = {};
      const details = {
        invoiceOutputOptions: [
          {
            value: 'email', label: 'Email'
          }, {
            value: 'paper', label: 'Paper'
          }
        ], subscriptionList: [], invoiceList: [],
      };

      Object.assign(subscriptionDetails, {});
      Object.assign(invoiceDetails, {});

      /* TAB DEFAULT - basic api requests */
      const [relationDetails/*, relationDeps, relationTypesData*/] = await Promise.all([
        GetRelation(null, parseInt(relationId)),
        //GetRelationsDependencies(),
        //GetRelationTypes()
      ]);

      //console.log("MVDetails - useEffect - relationTypesData ->", relationTypesData);

      if (relationDetails && relationDetails.ok) {
        const {data} = relationDetails.data;

        await checkRelationData(data);

        Object.assign(details, {...data});
      }

      /*if (relationDeps && relationDeps.ok) {
        const {data} = relationDeps;
        Object.assign(details, {
          relation_types: data && data.relation_types
              ? data.relation_types
              : null
        });
        Object.assign(details, {
          payment_conditions: data && data.payment_conditions
              ? data.payment_conditions
              : null
        });
      }*/

      Object.assign(details, {persons: {}});
      Object.assign(details, {addresses: {}});

      relation(details);

      setTabs([
        {
          title: 'Details',
          key: 0,
          name: 'details',
          component: <Details id={relationId} relation={relation} titleUpdate={getPageTitle(details, 'Details')} />
        }, {
          title: 'Addresses',
          key: 1,
          name: 'addresses',
          component: <Addresses id={relationId} updateDetails={updateDetails} titleUpdate={getPageTitle(details, 'Addresses')} />
        }, {
          title: 'Persons',
          key: 2,
          name: 'persons',
          component: <Persons id={relationId} updateDetails={updateDetails} titleUpdate={getPageTitle(details, 'Persons')} />
        }, {
          title: 'Bank accounts',
          key: 3,
          name: 'bank-accounts',
          component: <BankAccounts id={relationId} titleUpdate={getPageTitle(details, 'Bank accounts')} />
        }, {
          title: 'Subscriptions',
          key: 4,
          name: 'subscriptions',
          component: <SubscriptionsListDetails titleUpdate={getPageTitle(details, 'Subscriptions')} id={relationId} relationSelected={relationSelected} setRelationSelected={setRelationSelected} subscrId={subscrId} relation={relation} />
        }, {
          title: 'Invoices',
          key: 5,
          name: 'invoices',
          component: <InvoicesListDetails titleUpdate={getPageTitle(details, 'Invoices')} id={relationId} relationSelected={relationSelected} setRelationSelected={setRelationSelected} invoiceId={invoiceId} relation={relation} />
        }, {
          title: 'Emails',
          key: 6,
          name: 'emails',
          component: <Emails titleUpdate={getPageTitle(details, 'Emails')} id={relationId} updateDetails={updateDetails} emailId={emailId} relation={relation} />
        }
      ]);
    })();
  }, [relationId, relation, /*activeTab,*/ user, invoiceId, subscrId, emailId]);

  //if (tabs) {
  //  const tab = tabs[activeTab];
  //}

  //console.log("MVDetails - (before return) tab ->", tab); //props tab

  return (
    <Row>
      <Col className="f2x-mv-tabs">
        <MasterView
          relationId={relationId}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabName={tab && tab.name ? tab.name : null}
          tabKey={tab && tab.key ? tab.key : null}
          relationSelected={relationSelected}
          relation={relation}
          isSwitchingTenant={isSwitchingTenant}
        />
      </Col>
    </Row>
  )
};

const mapStateToProps = ({solrSearch, relation, subscription, invoice, user}) => ({solrSearch, relation, subscription, invoice, user});

const bindActionsToDispatch = {
  relation,
  subscription,
  invoice,
};

export default withRouter(connect(mapStateToProps, bindActionsToDispatch)(MVDetails));
