import React from "react";
import { GetMyNumberRanges } from '../../../controllers/number_ranges';
import NumberRangeForm from "./Form";
import ParentTable from '../../../components/ParentTable';
import { Badge } from 'reactstrap';
import { Helmet } from "react-helmet";

class NumberRanges extends React.Component {
  setRandomStyle = (cell, row, rowIndex) => {
    return cell !== null && cell !== undefined && cell !== '' ?
      <Badge color={(cell.toString()) === "1" ? 'success' : (cell.toString()) === "0" ? 'danger' : ''} className="badge-pill mr-1 mb-1 text-uppercase" >
        {cell.toString() === "1" ? 'yes' : 'no'}
      </Badge> : String.fromCharCode(8212)
  };

  render() {
    const { id, tabTitle } = this.props;
    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="NumberRanges"
          id={id}
          noTopBorder={true}
          data={GetMyNumberRanges}
          columns={[
            {
              dataField: "sample_implementation",
              text: "Example",
              sort: false,
              headerStyle: () => {
                return { width: '15%' };
              },
              attrs: {
                'data-label': 'Example'
              }
            },
            {
              dataField: "format",
              text: "Format",
              sort: true,
              headerStyle: () => {
                return { width: '15%' };
              },
              attrs: {
                'data-label': 'Format'
              }
            },
            {
              dataField: "description",
              text: "Description",
              sort: true,
              headerStyle: () => {
                return { width: '20%' };
              },
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "start",
              text: "Start",
              sort: true,
              headerStyle: () => {
                return { width: '12%' };
              },
              attrs: {
                'data-label': 'Start'
              }
            },
            {
              dataField: "end",
              text: "End",
              sort: true,
              headerStyle: () => {
                return { width: '12%' };
              },
              attrs: {
                'data-label': 'End'
              }
            },
            {
              dataField: "randomized",
              text: "Randomized",
              sort: true,
              align: 'center',
              formatter: this.setRandomStyle,
              headerStyle: () => {
                return { width: '11%', align: 'center' };
              },
              attrs: {
                'data-label': 'Randomized'
              }
            },
            {
              dataField: "current",
              text: "Last saved",
              sort: true,
              headerStyle: () => {
                return { width: '15%' };
              },
              attrs: {
                'data-label': 'Last saved'
              }
            }
          ]}
          rowAction="edit"
          rowActionForm={NumberRangeForm}
          headerDropdownOpts={[{
            label: 'Add',
            action: NumberRangeForm
          }]}
        />
      </React.Fragment>
    )
  }
}

export default NumberRanges;
