import React, {useEffect, useState} from "react";
import { GetUsers, GetAdminUsers } from '../../controllers/users';
import UserForm from "./Form";
//import UserApiKeysList from "./Api_keys";
import ParentTable from '../../components/ParentTable';
import RedirectHandler from '../../components/RedirectHandler';
import {useTranslation, withTranslation} from 'react-i18next';
import {useLocation,/*useHistory,*/withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

const IsEmptyFormatter = (cell) => {
  return cell ? cell : String.fromCharCode(8212);
};

const Users = ({ ...props }) => {
  const {t} = useTranslation();
  const location = useLocation();

  const {dispatch, loader, user, tenants} = props;

  const [isAdmin, setIsAdmin] = useState(false);
  const [controllerApi, setControllerApi] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const formatDate = (cell) => {
    return cell ? <span className="date-format">{t("date_format", { date: new Date(cell) })}</span> : String.fromCharCode(8212)
  };

  const formatTenant = (cell) => {
    const tenant = Object.values(tenants).find(i => parseInt(i.id) === parseInt(cell));

    if (tenant) {
      return tenant.name;
    } else {
      return String.fromCharCode(8212);
    }
  };

  const formatUserEnabled = (cell, row) => {
    //console.log("User table list - UserEnabledFormatter - cell ->", cell);

    return cell || cell === 0 ? <div className="link">
      <span className={`status-enabled badge-pill mr-1 mb-0 text-uppercase badge ${cell === 1 ? 'badge-success' : cell === 0 ? 'badge-danger' : ''}`}>{ cell === 1 ? 'enabled' : cell === 0 ? 'disabled' : String.fromCharCode(8212)}</span>
    </div> : <span className="null-value">{String.fromCharCode(8212)}</span>
  };

  useEffect(() => {
    if (location && location.pathname) {
      const pathname = location.pathname;

      let basePath = `${location.pathname.split('/')[1]}`;
      let basePathTo = `${location.pathname.split('/')[2]}`;

      if (basePath && basePath === 'admin' && basePathTo && basePathTo === "users") {
        setIsAdmin(true);
        setControllerApi("admin");
      } else {
        setControllerApi("user");
      }
    } else {
      setControllerApi("user");
    }
  }, []);

  return (
      <>
        <h1 className="text-bold mb-3">Users</h1>
        {controllerApi === "user" &&
        <ParentTable
          table="Users"
          keyField="id"
          data={GetUsers}
          include='person'
          columns={[
            {
              dataField: "username",
              text: "Username",
              sort: true,
              attrs: {
                'data-label': 'Username'
              }
            },
            {
              dataField: "enabled",
              text: "Enabled",
              align: 'center',
              formatter: formatUserEnabled,
              headerStyle: { textAlign: 'center' },
              sort: true,
              attrs: {
                'data-label': 'Enabled'
              }
            },
            {
              dataField: "full_name",
              text: "Full name",
              formatter: IsEmptyFormatter,
              sort: false,
              attrs: {
                'data-label': 'Group'
              }
            }/*,
            {
              dataField: "last_login",
              text: "Last login",
              formatter: formatDate,
              sort: true,
              attrs: {
                'data-label': 'Last login'
              }
            }*/
          ]}
          rowAction= 'edit'
          rowActionForm={UserForm}
          rowDropdownOpts={[{
            action: 'Edit',
            label: 'Edit user',
            form: UserForm
          }, {
            action: 'Api-keys',
            label: 'Api keys',
            form: RedirectHandler
          }]}
          //offLoader={true}
          striped={true}
          notLoadedByDefault={false}
          noSearch={false}
          cacheSearch={true}
          filterSearch={true}
          setSearchKeyword={setSearchKeyword}
          noDataIndication="No users found."
          noHover={false}
          noTopBorder={true}
          classes="table-responsive"
          wrapperClasses="table-users f2x-custom-table"
        />}

        {controllerApi === "admin" &&
        <ParentTable
          table="AdminUsers"
          keyField="id"
          data={GetAdminUsers}
          include='person'
          columns={[
            {
              dataField: "tenant_id",
              text: "Tenant",
              sort: true,
              headerStyle: () => {
                return { width: '13%'};
              },
              formatter: formatTenant,
              attrs: {
                'data-label': 'Tenant'
              }
            },
            {
              dataField: "username",
              text: "Username",
              sort: true,
              attrs: {
                'data-label': 'Username'
              }
            },
            {
              dataField: "enabled",
              text: "Enabled",
              align: 'center',
              formatter: formatUserEnabled,
              headerStyle: { textAlign: 'center' },
              sort: true,
              attrs: {
                'data-label': 'Enabled'
              }
            },
            {
              dataField: "full_name",
              text: "Full name",
              formatter: IsEmptyFormatter,
              sort: false,
              attrs: {
                'data-label': 'Group'
              }
            }/*,
            {
              dataField: "last_login",
              text: "Last login",
              formatter: formatDate,
              sort: true,
              attrs: {
                'data-label': 'Last login'
              }
            }*/
        ]}
          rowAction= 'edit'
          rowActionForm= { UserForm }
          rowDropdownOpts={[{
          action: 'Edit',
          label: 'Edit user',
          form: UserForm
        }, {
          action: 'Api-keys',
          label: 'Api keys',
          form: RedirectHandler
        }]}
        //offLoader={true}
        striped={true}
        noSearch={false}
        cacheSearch={true}
        filterSearch={true}
        setSearchKeyword={setSearchKeyword}
        notLoadedByDefault={false}
        noDataIndication="No users found."
        noHover={false}
        noTopBorder={true}
        classes="table-responsive"
        wrapperClasses="table-users f2x-custom-table"
      />}
    </>
  )
};

const userSelector = createSelector(
    (state) => state.user,
    (user) => user
    ),
    loaderSelector = createSelector(
        (state) => state.loader,
        (loader) => loader
    ),
    tenantsSelector = createSelector(
        (state) => state.tenants,
        (tenants) => tenants
    ),
    mapStateToProps = createSelector(
        userSelector,
        loaderSelector,
        tenantsSelector,
        (user, loader, tenants) => ({ user, loader, tenants })
    );

export default withRouter(withTranslation()(connect(mapStateToProps)(Users)));
