import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { GetLogActivitiesChart } from "../../../controllers/activity_logs";

class ChartActivityLogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: 'chart',
          toolbar: {
            show: true,
            tools: {
              pan: false,
            }
          }
        },
        stroke: {
          curve: "smooth"
        },
        colors: [
          "#fa7f6f",
          "#feef65",
          "#d62628",
          "#3497ff",
          "#2ca02c",
          "#a7abad",
          "#4a4242",
          "#8d6e2a"
        ],
        dataLabels: {
          enabled: true
        },
        xaxis: {
          type: "datetime",
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        }
      },
      dataLogs: []
    }
  }

  componentDidMount() {
    (async () => {
      const { ok, data } = await GetLogActivitiesChart();
      if (ok) {
        this.setState({ dataLogs: data })
      }
    })();
  }

  render() {
    const { options, dataLogs } = this.state;

    return (
        <Chart options={options} series={dataLogs} type="area" height="350" />
    )
  }
}

export default connect(store => ({
  loader: store.loader
}))(ChartActivityLogs)