import React, {useState, useEffect} from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown, Spinner,
} from 'reactstrap';
import { MoreHorizontal } from 'react-feather';

const AsyncGadgetsDropdown = ({ ...props }) => {

  const { rowIndex, filterAsyncDropdownOpts, row, loadingOpts, currentRow, handlerDropdownAction } = props;

  const [list, setList] = useState([]);
  const [noList, setNoList] = useState(false);
  const [isLoadingDropDownItems, setIsLoadingDropDownItems] = useState(false);

  const getGadgets = (e, opts, row, currentRow) => {
    console.log("AsyncGadgetsDropdown - opts ->", opts);
    console.log("AsyncGadgetsDropdown - row ->", row);

    setIsLoadingDropDownItems(true);

    e.preventDefault();
    e.stopPropagation();

    let APIs = [], gadgets = [], rowGadgets = [];
    let id = row.id ? row.id : row.subscription_line_id ? row.subscription_line_id : null; // => ???????? added subscription_line_id for provision dashboard

    if (id) {
      (async () => {
        await opts.map(({get}) => {
          return APIs.push(get(id))
        });

        console.log("AsyncGadgetsDropdown - APIs ->", APIs);

        await Promise.all(APIs).then((result) => {
          let newGadgets = result.map(({ok, data}) => {
            return ok && data && data.data && data.data[0] && data.data[0].data ? gadgets = gadgets.concat(data.data[0].data) : false;
          });

          rowGadgets = gadgets;

          console.log("AsyncGadgetsDropdown - gadgets (dropdown) ->", gadgets);

          if (gadgets && gadgets.length > 0) {
            setTimeout(() => {
              setList(gadgets);
            }, 5);
          } else {
            setNoList(true);
          }
        });
      })();
    }
  };

  useEffect(() => {
    setIsLoadingDropDownItems(false);
  }, [list]);

  return (
      !noList && 
      <UncontrolledDropdown className={`dropdown`}>
        <DropdownToggle tag="a"
                        href="/#"
                        data-toggle="dropdown"
                        aria-haspopup={true}
                        id={`menu-async-toggle-${rowIndex}`}
                        className="px-3 py-2 dropdown-table-actions"
                        aria-expanded={!!loadingOpts}
                        disabled={!list.length === 0}
                        onClick={(e) => getGadgets(e, filterAsyncDropdownOpts, row, rowIndex)}
        >
          {isLoadingDropDownItems && (rowIndex || rowIndex === 0) ?
            <Spinner color="primary" size={`small`} style={{ maxHeight: "18px", maxWidth: "18px" }} /> :
            <MoreHorizontal size={18} />
          }
        </DropdownToggle>
        {!isLoadingDropDownItems && list.length > 0 &&
        <DropdownMenu right={true}
                      positionFixed={true}
                      id={`menu-async-${row.id}-${rowIndex}`}
                      aria-label={`row-${row.id}-${rowIndex}`}
                      aria-labelledby={`menu-async-toggle-${rowIndex}`}
        >
          {list.map((item, key) => {
            return (<DropdownItem className="py-2"
                                  onClick={(e) => handlerDropdownAction(item, row, rowIndex, e)}
                                  key={`${key}-${rowIndex}`}>{item.label}</DropdownItem>
            )})
          }
        </DropdownMenu>}
      </UncontrolledDropdown>
  )
};

export default AsyncGadgetsDropdown;
