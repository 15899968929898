import React, {useState} from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import moment from 'moment';
import _ from "lodash";
import TooltipLabel from '../../components/TooltipLabel';
import NumberFormat from 'react-number-format';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Row,
  Col,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  NavItem,
  NavLink,
  Nav,
  TabPane, TabContent,
} from 'reactstrap';
import {
  GetProduct,
  AddProduct,
  UpdateProduct,
  GetProductTypeList,
  GetProductsBackendAPIs,
  GetProducts,
  GetProductHierarchy,
  GetProductHierarchyRelationTypes,
  AddProductHierarchy,
  UpdateProductHierarchy,
  RemoveProductHierarchy,
} from '../../controllers/products';
import {OverlayTrigger, Tooltip, Dropdown} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {
  GetStatusList, RemoveSubscriptionLine,
} from '../../controllers/subscriptions';
import { GetVatCodes } from '../../controllers/vat_codes';
import { loaderToggle } from "../../redux/actions/loaderActions";
import { validateDate, validateBarcode } from "../../redux/actions/validateActions";
import {
  formatNumber,
  parseDateToSaveFormat,
} from '../../redux/actions/formatActions';
import { Collapse } from "react-bootstrap";
import Toast from "../../components/Toast";
import ToastMulti from "../../components/ToastMulti";
import {PlusSquare, MinusSquare, MoreHorizontal, Trash2} from 'react-feather';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave} from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select';
import {GetUsers} from '../../controllers/users';
import {GetMicroservices} from '../../controllers/microservices';
import Swal from "sweetalert2";
import {withRouter} from 'react-router-dom';
import classnames from 'classnames';
import ReactJson from 'react-json-view';

let debug = true;
let show_debug_info = debug === true ? "inline-block" : "none";

class ProductForm extends React.Component {
  constructor(props, context) {
    super(props);

    this.datePickerField = React.createRef();

    console.log("Product add - constructor - this.props ->", this.props);

    this.state = {
      formType: 'Add',
      formName: 'product',
      product: {
        serialized: false,
        serials: [{ serial: '', mac_address: '' }],
      },
      vatCodes: {},
      vatCode: {},
      statusOpts: [],
      vatCodeOpts: [],
      microservicesOpts: [],
      backendApis: [],
    }
  }

  handleInputChange({ target: { name, value } }) {
    let product = Object.assign({}, this.state.product);
    product[name] = value;

    this.setState({ product });
  }

  handleInputTextAreaChange = (name, data) => {
    let product = Object.assign({}, this.state.product);
    product[name] = data.target.value;

    this.setState({ product });
  };

  handleSelectChange = (name, data) => {
    console.log("Product Add - handleSelectChange - name ->", name);
    console.log("Product Add - handleSelectChange - data ->", data);

    let product = Object.assign({}, this.state.product);
    product[name] = data && data.value === 0 ? 0 : data && data.value ? data.value : null;

    console.log("Product Add - handleSelectChange - product[name] ->", product[name]);

    this.setState({
      product,
      [name]: data
    });
  };

  handleRadioChange = ({ target: { name, value } }) => {
    const product = Object.assign({}, this.state.product);
    product[name] = value;

    this.setState({ product })
  };

  handleCheckChange({ target: { name, checked } }) {
    let product = Object.assign({}, this.state.product);
    product[name] = checked;

    this.setState({ product })
  }

  handleQuillChange(value) {
    let product = Object.assign({}, this.state.product);
    product.description_long = value;

    this.setState({ product })
  }

  handleDatePickerChange(date) {
    let product = Object.assign({}, this.state.product);
    const { newDate } = this.props.dispatch(validateDate(date));
    product.active_from = newDate;

    this.setState({ product })
  }

  priceFormatter(name, data) {
    const { newVal, value } = this.props.dispatch(formatNumber(name, data.floatValue, data.value));

    let product = Object.assign({}, this.state.product);
    product[`formatted_${name}`] = newVal;
    product[name] = value;

    this.setState({ product })
  }

  handleKeyDown = ({ which }, name) => {
    if (which === 9) {
      this.ref[name].setOpen(false)
    }
  };

  handleSubmit(e) {
    e.preventDefault();

    let notificationsToastArr = [];

    const { selectedData, dispatch, loader } = this.props;
    let product = Object.assign({}, this.state.product),
      { product_type_id, status_id, vat_code_id, vendor_partcode, price, description, ean_code, active_from } = product;

    if (!product_type_id) {
      notificationsToastArr.push({ title: 'Product type field is required!', icon: 'warn' });
    }
    if (isNaN(status_id)) {
      notificationsToastArr.push({ title: 'Status field is required!', icon: 'warn' });
    }
    if (!vat_code_id) {
      notificationsToastArr.push({ title: 'VAT code field is required!', icon: 'warn' });
    }
    if (!vendor_partcode) {
      notificationsToastArr.push({ title: 'Vendor part code field is required!', icon: 'warn' });
    }
    if (!price && price !== 0) {
      notificationsToastArr.push({ title: 'Price field is required!', icon: 'warn' });
    }
    if (!description) {
      notificationsToastArr.push({ title: 'Description field is required!', icon: 'warn' });
    }
    //if (ean_code !== '' && ean_code !== null && !validateBarcode(ean_code)) {
    //  notificationsToastArr.push({ title: 'EAN Code is invalid!', icon: 'warn' });
    //}
    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }
    if (!loader) {
      dispatch(loaderToggle(true));

      product.active_from = active_from ? parseDateToSaveFormat(active_from): null;

      (async () => {
        const { ok } = await AddProduct(product);

        this.handleOk(ok)
      })();
    }
  }

  handleOk = async (ok, options) => {
    const { hide, update, dispatch } = this.props;

    dispatch(loaderToggle(false));

    if (ok) {
      update();
      if (options && options.length > 0) {
        return ToastMulti(options, "container-validation-id");
      } else {
        hide();
      }
    }
  };

  componentDidMount() {
    const { selectedData, dispatch } = this.props;
    dispatch(loaderToggle(true));

    console.log("Product add - componentDidMount - selectedData ->", selectedData);

    (async () => {
      dispatch(loaderToggle(true));

      (async () => {
        const [vat_codes, status_list, /*backend_apis,*/microservices, product_types, product_hierarchy_relation_types] = await Promise.all([
          GetVatCodes(),
          GetStatusList(5),
          //GetProductsBackendAPIs(),
          GetMicroservices(),
          GetProductTypeList(),
          GetProductHierarchyRelationTypes(),
        ]);

        // get vat codes
        if (vat_codes.ok) {
          let vatCodeOpts = [];
          vat_codes.data.data && vat_codes.data.data.map(({ description, vat_percentage, id }) => {
            return vatCodeOpts.push({
              label: description ? description : vat_percentage,
              value: id
            })
          });

          // set vat codes
          this.setState({ vatCodeOpts });

          if (selectedData) {
            let product = Object.assign({}, this.state.product);
            const vatCodeId = product && product.vat_code ? product.vat_code.id : null,
              getVatCode = vatCodeId ? vatCodeOpts.find(opt => parseInt(opt.value) === parseInt(vatCodeId)) : null;

            product.vat_code_id = vatCodeId ? vatCodeId : null;

            this.setState({
              product,
              vat_code_id: getVatCode ? getVatCode : null
            })
          }
        }

        // get status list
        if (status_list.ok) {
          let statusOpts = [];
          status_list.data.data.map(({ id, label }) => {
            return statusOpts.push({
              label: label,
              value: id
            })
          });

          this.setState({ statusOpts });

          if (selectedData) {
            let product = Object.assign({}, this.state.product);
            const { status_id } = product;
            const statusOpt = statusOpts.find(opt => parseInt(opt.value) === parseInt(status_id));
            this.setState({
              status_id: statusOpt
            });
          }
        }

        // backend api
        /*const { data } = backend_apis;
        if (data && data.success) {
          const backendApiDefaultObj = [{ label: '--', value: '', isdisabled: false }];
          const backendApiObjs = data.data && data.data.map(({ backend_api, status }) => ({ label: backend_api, value: backend_api, isdisabled: status < 1 }));

          this.setState({ backendApis: backendApiDefaultObj.concat(backendApiObjs) });

          if (selectedData) {
            let product = Object.assign({}, this.state.product);
            const backendApi = product && product.backend_api ? product.backend_api : null,
              getBackendApi = backendApiObjs ? backendApiObjs.find(opt => opt.value === backendApi) : null;

            product.backend_api = getBackendApi ? getBackendApi.value : backendApi;

            this.setState({
              product,
              backend_api: getBackendApi ? getBackendApi : null
            });
          }
        }*/

        // microservices
        console.log("Product add - componentDidMount - microservices ->", microservices);
        if (microservices && microservices.data && microservices.data.success) {
          const microservicesDefaultObj = [{ label: '--', value: '', isdisabled: false }];
          const microservicesObjs = microservices.data.data && microservices.data.data.map(({ id, name, status }) => ({ label: name, value: id, isdisabled: status === false }));

          this.setState({ microservicesOpts: microservicesDefaultObj.concat(microservicesObjs) });

          if (selectedData) {
            let product = Object.assign({}, this.state.product);
            const microservice = product && product.microservice ? product.microservice : null,
                getMicroservice = microservicesObjs ? microservicesObjs.find(opt => opt.value === microservice) : null;

            product.microservice = getMicroservice ? getMicroservice.value : microservice;

            this.setState({
              product,
              microservice: getMicroservice ? getMicroservice : null
            });
          }
          console.log("Product add - componentDidMount - microservicesOpts(state) ->", this.state.microservicesOpts);
          console.log("Product add - componentDidMount - product(state) ->", this.state.product);
        }

        // get product hierarchy types
        let productHierarchyRelationTypesOpts = [];
        if (product_hierarchy_relation_types.ok) {
          product_hierarchy_relation_types.data.data && product_hierarchy_relation_types.data.data.map(({ description, id }) => {
            return productHierarchyRelationTypesOpts.push({
              label: description,
              value: id,
            });
          });
          // set product hierarchy types
          this.setState({ productHierarchyRelationTypesOpts });
        }

        // get product types
        let productTypeOpts = [];
        if (product_types.ok) {
          product_types.data.data && product_types.data.data.map(({name, id}) => {
            return productTypeOpts.push({
              label: name,
              value: id,
            });
          });
          // set product types
          this.setState({productTypeOpts});
        }
      })();

      dispatch(loaderToggle(false));
      this.setState({ hasLoaded: true });

    })();
  }

  render() {
    const { show, hide, update, loader, selectedData, t, dispatch } = this.props,
      {
        hasLoaded,
        formType,
        formName,
        product,
        productTypeOpts,
        product_type_id,
        statusOpts,
        status_id,
        vatCodeOpts,
        vat_code_id,
        microservicesOpts,
        microservice,
        backendApis,
        backend_api,
      } = this.state,
      {
        serialized,
        vendor,
        vendor_partcode,
        active_from,
        formatted_weight,
        ean_code,
        formatted_price,
        description,
        description_long,
        sort,
      } = product;

    console.log("Product add - render - product(state) formatted_price ->", formatted_price);
    console.log("Product add - render - product(state) formatted_weight ->", formatted_weight);
    console.log("Product add - render - product(state) ->", product);

    return (
      <React.Fragment>
        { (!loader || hasLoaded) && <Modal isOpen={show} toggle={hide} centered className="modal-xl">
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <ModalHeader>
              <div className="w-100">
                {formType} {formName} {description ? ` - ${description}` : ""}
              </div>
            </ModalHeader>
            <ModalBody className="mt-3 mb-3">
              <div className="form-row">
                <Col md={12}>
                  <div className="form-row">
                    <Col md={2}>
                      <label className="col-form-label">
                        Product details:
                      </label>
                    </Col>
                    <Col md={10}>
                      <div className="form-row mb-2">
                        <Col>
                          <Input
                            id="description"
                            name="description"
                            className="pr-5 text-truncate"
                            placeholder="Description"
                            title="Description"
                            value={description ? description : ''}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          <TooltipLabel label={`description`} html={`Description`} position={`top`} />
                        </Col>
                        <Col>
                          <Select
                              id="product_type_id"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              placeholder="Product type"
                              title="Product type"
                              options={productTypeOpts}
                              value={product_type_id}
                              onChange={this.handleSelectChange.bind(this, 'product_type_id')}
                          />
                          <TooltipLabel label={`product_type`} html={`Product type`} position={`top`} rightMargin={true} />
                        </Col>
                        <Col>
                          <Input
                              id="sort"
                              name="sort"
                              placeholder="Sort"
                              title="Sort"
                              value={sort ? sort : ''}
                              onChange={(e) => this.handleInputChange(e)}
                          />
                          <TooltipLabel label={`sort`} html={`Sort (order position)`} position={`top`} />
                        </Col>
                      </div>
                      <div className="form-row mb-2">
                        <Col>
                          <Select
                              id="microservice"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              placeholder="Microservice"
                              title="Microservice"
                              options={microservicesOpts}
                              value={microservice}
                              onChange={this.handleSelectChange.bind(this, 'microservice')}
                              isOptionDisabled={(option) => option.isdisabled}
                          />
                          <TooltipLabel label={`microservice`} html={`Microservice`} position={`top`} rightMargin={true} />
                        </Col>
                        <Col>
                          <Select
                              id="backend_api"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              placeholder="Backend API"
                              title="Backend API"
                              options={backendApis}
                              value={backend_api}
                              onChange={this.handleSelectChange.bind(this, 'backend_api')}
                              isOptionDisabled={(option) => option.isdisabled}
                          />
                          <TooltipLabel label={`backend-api`} html={`Backend API`} position={`top`} rightMargin={true} />
                        </Col>
                      </div>
                      <div className="form-row mb-2">
                        <Col>
                          <ReactQuill
                              placeholder='Long description'
                              value={description_long ? description_long : ''}
                              onChange={(e) => this.handleQuillChange(e)}
                          />
                        </Col>
                      </div>
                      <div className="form-row mb-2">
                        <Col>
                          <div className="form-check mt-1 pl-0">
                            <CustomInput
                                id="serialized"
                                type="checkbox"
                                name="serialized"
                                label="Serialized"
                                defaultChecked={serialized && serialized !== '0'}
                                onChange={(e) => this.handleCheckChange(e)}
                            />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </div>

                  <div className="form-row">
                    <Col md={12}>
                      <div className="pb-2 mb-2 border-bottom"> </div>
                      <div className="form-row">
                        <Col md={2}>
                          <label className="col-form-label">
                            Vendor details:
                          </label>
                        </Col>
                        <Col md={10}>
                          <div className="form-row mb-2">
                            <Col>
                              <Input
                                id="vendor"
                                name="vendor"
                                placeholder="Vendor"
                                title="Vendor"
                                value={vendor ? vendor : ''}
                                onChange={(e) => this.handleInputChange(e)}
                              />
                              <TooltipLabel label={`Vendor`} html={`vendor`} position={`top`} rightMargin={false} />
                            </Col>
                            <Col>
                              <Input
                                  id="vendor_partcode"
                                  name="vendor_partcode"
                                  placeholder="Vendor part code"
                                  title="Vendor part code"
                                  value={vendor_partcode ? vendor_partcode : ''}
                                  onChange={(e) => this.handleInputChange(e)}
                              />
                              <TooltipLabel label={`vendor_partcode`} html={`Vendor part code`} position={`top`} rightMargin={false} />
                            </Col>
                          </div>
                          <div className="form-row mb-2">
                            <Col>
                              <Input
                                id="ean_code"
                                name="ean_code"
                                placeholder="Ean code"
                                title="Ean code"
                                value={ean_code ? ean_code : ''}
                                onChange={(e) => this.handleInputChange(e)}
                              />
                              <TooltipLabel label={`ean_code`} html={`Ean code`} position={`top`} rightMargin={false} />
                            </Col>
                            <Col>
                              <NumberFormat
                                className="form-control"
                                thousandSeparator="."
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                decimalScale={2}
                                value={formatted_weight ? formatted_weight : ''}
                                displayType="input"
                                placeholder="Weight"
                                title="Weight"
                                onValueChange={(e) => this.priceFormatter('weight', e)}
                              />
                              <TooltipLabel label={`weight`} html={`Weight`} position={`top`} rightMargin={false} />
                            </Col>
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </div>
                  <div className="form-row">
                    <Col md={12}>
                      <div className="pb-2 mb-2 border-bottom"> </div>
                      <div className="form-row">
                        <Col md={2}>
                          <label className="col-form-label">
                            Tenant specific:
                          </label>
                        </Col>
                        <Col md={10}>
                          <div className="form-row mb-2">
                            <Col>
                              <NumberFormat
                                  className="form-control"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  thousandsGroupStyle="thousand"
                                  decimalScale={2}
                                  value={formatted_price || formatted_price === 0 ? formatted_price : ''}
                                  displayType="input"
                                  placeholder="Price"
                                  title="Price"
                                  onValueChange={(e) => this.priceFormatter('price', e)}
                              />
                              <TooltipLabel label={`price`} html={`Price`} position={`top`} rightMargin={false} />
                            </Col>
                            <Col>
                              <Select
                                  id="vat_code_id"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  placeholder="VAT code"
                                  title="VAT code"
                                  options={vatCodeOpts}
                                  value={vat_code_id}
                                  onChange={this.handleSelectChange.bind(this, 'vat_code_id')}
                              />
                              <TooltipLabel label={`vat_code_id`} html={`VAT code`} position={`top`} rightMargin={true} />
                            </Col>
                          </div>
                          <div className="form-row mb-2">
                            <Col>
                              <Select
                                  id="status_id"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  placeholder="Status"
                                  title="Status"
                                  options={statusOpts}
                                  menuPlacement="top"
                                  value={status_id}
                                  onChange={this.handleSelectChange.bind(this, 'status_id')}
                              />
                              <TooltipLabel label={`status_id`} html={`Status`} position={`top`} rightMargin={true} />
                            </Col>
                            <Col>
                              <DatePicker
                                  id="active_from"
                                  className="form-control"
                                  name="active_from"
                                  placeholderText="Active from"
                                  title="Active from"
                                  dateFormat={t("date_format_raw")}
                                  autoComplete="off"
                                  selected={active_from ? new Date(active_from) : null}
                                  onChange={(e) => this.handleDatePickerChange(e)}
                                  ref={this.datePickerField}
                                  onKeyDown={(e) => this.handleKeyDown(e, 'active_from')}
                              />
                              <TooltipLabel label={`active_from`} html={`Active from`} position={`top`} rightMargin={false} />
                            </Col>
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </div>
                </Col>
              </div>
            </ModalBody>
            <ModalFooter className="justify-content-between">
              <span className="btn btn-danger" onClick={hide}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
              <Button color="primary">
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </ModalFooter>
          </Form>
        </Modal>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = store => ({
  loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(ProductForm)));
