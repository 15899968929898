import React, {useState, useRef, useEffect} from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Row,
  Col,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  NavItem,
  NavLink,
  Nav,
  TabPane,
  TabContent,
  UncontrolledCollapse,
} from 'reactstrap';
import {
  GetProduct,
  AddProduct,
  UpdateProduct,
  GetProductTypeList,
  GetProductsBackendAPIs,
  GetProducts,
  GetProductHierarchy,
  GetProductHierarchyRelationTypes,
  AddProductHierarchy,
  UpdateProductHierarchy,
  RemoveProductHierarchy,
} from '../../../controllers/products';
import {OverlayTrigger, Tooltip, Dropdown} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {
  GetStatusList, RemoveSubscriptionLine,
} from '../../../controllers/subscriptions';
import { GetVatCodes } from '../../../controllers/vat_codes';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate, validateBarcode } from "../../../redux/actions/validateActions";
import {
  formatNumber,
  parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import { Collapse } from "react-bootstrap";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import {
  PlusSquare,
  MinusSquare,
  MoreHorizontal,
  Trash2,
  CornerDownRight,
  Repeat,
} from 'react-feather';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilter,
  faTrash,
  faSortAmountDown,
  faSortAmountUp,
  faSlidersH,
  faArrowsAltH, faTags,
} from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select';
import Swal from "sweetalert2";
import ReactJson from 'react-json-view';
import AddChildRelatedProduct from "./AddChild";
import AddMutualRelatedProduct from "./AddMutual";
import JsonDataRelatedProduct from "./JsonData";
import JsonDataChildRelatedProduct from "./JsonDataChild";
import AddRelatedProduct from './Add';

const ProductChildHierarchyList = ({ ...props }) => {
  const {
    product,
    productHierarchies,
    debug,
    update,
    productsListOpts,
    productRestHierarchiesOpts,
    productHierarchyRelationTypesOpts,
    productHierarchyRelationTypesChildOpts,
    productHierarchyRelationTypesMutualOpts,
    updateRelatedChildProducts,
    productRestHierarchies,
    dispatch,
    loader,
    hide,
    childListOpen,
    setChildListOpen,
  } = props;

  const [addFormCollapse, setAddFormCollapse] = useState([]);
  const [mutualFormCollapse, setMutualFormCollapse] = useState([]);
  const [jsonDataFormCollapse, setJsonDataFormCollapse] = useState([]);
  const [jsonDataChildFormCollapse, setJsonDataChildFormCollapse] = useState([]);
  const [relationTypeSelected, setRelationTypeSelected] = useState(null);

  // onclick open/close root list
  const childCollapse = (e) => {
    e.preventDefault();

    let iconId = e.currentTarget.id;
    // get & copy current state array
    const updatedChildListOpen = [...childListOpen];
    // find collapse id in childListOpen
    let childListItem = childListOpen.findIndex(item => item.collapse_id === iconId);
    if (childListOpen[childListItem]) {
      updatedChildListOpen[childListItem].isOpen = !childListOpen[childListItem].isOpen;
      setChildListOpen(updatedChildListOpen);
    }
  };

  const getChildListCollapseState = (id) => {
    let childListItem = childListOpen.findIndex(item => item.collapse_id === id);
    if (childListOpen[childListItem]) {
      setChildListCollapseIcon(id, childListOpen[childListItem].isOpen);
      return childListOpen[childListItem].isOpen;
    } else {
      return false;
    }
  };

  const setChildListCollapseIcon = (id, isOpen) => {
    const childListItemElem = document.getElementById(id);

    if (isOpen) {
      childListItemElem.style.transform = 'rotate(180deg)';
      childListItemElem.style.color = '#47bac1';
      childListItemElem.classList.add('open');

    } else {
      // get all (other) icon(s) with className - child-list-icon-hierarchy-item
      let icons = document.getElementsByClassName("child-list-icon-hierarchy-item");
      if (icons && icons.length > 0) {
        for (let i = 0; i < icons.length; i++) {
          if (id === icons[i].id && icons[i].classList.contains('open')) {
            icons[i].style.transform = 'rotate(0deg)';
            icons[i].style.color = '#1f1f1f';
            icons[i].classList.remove("open");
          }
        }
      }
    }
  };

  const addChildRelatedProductToHierarchy = (
    e,
    product_id,
    related_product_id,
    related_child_product_id,
    index,
    relationType, // mutually or parent-child type
  ) => {
    setAddFormCollapse([]);
    const collapsibleAddChildId = related_child_product_id ? "dropdown-item-add-" + product_id + "-" + related_product_id +  "-" + related_child_product_id + "-" + index : "dropdown-item-add-" + product_id + "-" + related_product_id + "-" + index;
    const selectedProduct = related_child_product_id ? related_child_product_id : related_product_id;

    let formItem;
    if (addFormCollapse && addFormCollapse.length > 0) {
      formItem = addFormCollapse.find(
          item => item.collapsibleAddChildId === collapsibleAddChildId);
    }
    let addChildCollapse = [];
    addChildCollapse.push({
      selectedProduct: selectedProduct,
      collapsibleAddChildId: collapsibleAddChildId,
      open: formItem && formItem.open ? !formItem.open : true
    });
    setAddFormCollapse(addChildCollapse);
    setJsonDataFormCollapse([]);
    setMutualFormCollapse([]);

    if (relationType) {
      setRelationTypeSelected(relationType);
    }
  };

  const renderAddChildRelatedProductForm = (
    product_id,
    related_product_id,
    related_child_product_id,
    relation_type_id, // selected relation type id
    index,
  ) => {
    return (
      <React.Fragment>
        {/* render collapsible add form */}
        <div className="add-product-child-form">
          <AddChildRelatedProduct
            productId={product_id}
            index={index}
            itemRelatedProductId={related_product_id}
            subItemRelatedProductId={related_child_product_id}
            relationTypeSelected={relationTypeSelected}
            relationTypeId={relation_type_id}
            dispatch={dispatch}
            loader={loader}
            hide={hide}
            update={update}
            debug={debug}
            productsListOpts={productsListOpts}
            productRestHierarchiesOpts={productRestHierarchiesOpts}
            productHierarchyRelationTypesOpts={productHierarchyRelationTypesOpts}
            productHierarchyRelationTypesChildOpts={productHierarchyRelationTypesChildOpts}
            addFormCollapse={addFormCollapse}
            updateRelatedChildProducts={updateRelatedChildProducts}
            setAddFormCollapse={setAddFormCollapse}
            setJsonDataFormCollapse={setJsonDataFormCollapse}
            setMutualFormCollapse={setMutualFormCollapse}
          />
        </div>
      </React.Fragment>
    );
  };

  const addMutualRelatedProductToHierarchy = (
      e,
      product_id,
      related_product_id,
      related_child_product_id,
      index,
      relationType, // mutually or parent-child type
  ) => {
    setMutualFormCollapse([]);
    const collapsibleMutualChildId = related_child_product_id ? "dropdown-item-mutual-" + product_id + "-" + related_product_id +  "-" + related_child_product_id + "-" + index : "dropdown-item-add-" + product_id + "-" + related_product_id + "-" + index;
    const selectedProduct = related_child_product_id ? related_child_product_id : related_product_id;

    let formItem;
    if (mutualFormCollapse && mutualFormCollapse.length > 0) {
      formItem = mutualFormCollapse.find(
          item => item.collapsibleMutualChildId === collapsibleMutualChildId);
    }
    let addMutualCollapse = [];
    addMutualCollapse.push({
      selectedProduct: selectedProduct,
      collapsibleMutualChildId: collapsibleMutualChildId,
      open: formItem && formItem.open ? !formItem.open : true
    });
    setMutualFormCollapse(addMutualCollapse);
    setAddFormCollapse([]);
    setJsonDataFormCollapse([]);

    if (relationType) {
      setRelationTypeSelected(relationType);
    }
  };

  const renderMutualChildRelatedProductForm = (
      product_id,
      related_product_id,
      related_child_product_id,
      relation_type_id, // selected relation type id
      index,
  ) => {
    return (
        <React.Fragment>
          {/* render collapsible add form */}
          <div className="add-product-mutual-form">
            <AddMutualRelatedProduct
                productId={product_id}
                index={index}
                itemRelatedProductId={related_product_id}
                subItemRelatedProductId={related_child_product_id}
                relationTypeSelected={relationTypeSelected}
                relationTypeId={relation_type_id}
                dispatch={dispatch}
                loader={loader}
                hide={hide}
                update={update}
                debug={debug}
                productsListOpts={productsListOpts}
                productRestHierarchiesOpts={productRestHierarchiesOpts}
                productHierarchyRelationTypesOpts={productHierarchyRelationTypesOpts}
                productHierarchyRelationTypesMutualOpts={productHierarchyRelationTypesMutualOpts}
                mutualFormCollapse={mutualFormCollapse}
                updateRelatedChildProducts={updateRelatedChildProducts}
                setAddFormCollapse={setAddFormCollapse}
                setJsonDataFormCollapse={setJsonDataFormCollapse}
                setMutualFormCollapse={setMutualFormCollapse}
            />
          </div>
        </React.Fragment>
    );
  };

  const setJsonDataRelatedProductHierarchy = (
    e,
    product_id,
    related_product_id,
    related_child_product_id,
    index,
  ) => {
    const collapsibleJsonDataChildId = related_child_product_id ? "dropdown-item-json-data-" + product_id + "-" + related_product_id +  "-" + related_child_product_id + "-" + index : "dropdown-item-json-data-" + product_id + "-" + related_product_id + "-" + index;

    let formItem;
    if (jsonDataFormCollapse && jsonDataFormCollapse.length > 0) {
      formItem = jsonDataFormCollapse.find(
          item => item.collapsibleJsonDataChildId === collapsibleJsonDataChildId);
    }

    let jsonDataCollapse = [];
    jsonDataCollapse.push({ collapsibleJsonDataChildId: collapsibleJsonDataChildId, open: formItem && formItem.open ? !formItem.open : true });
    setJsonDataFormCollapse(jsonDataCollapse);
    setJsonDataChildFormCollapse([]);
    setAddFormCollapse([]);
    setMutualFormCollapse([]);
  };

  const renderJsonDataRelatedProductForm = (
      product_id,
      related_product_id,
      related_child_product_id,
      index,
      level,
  ) => {
    let jsonData = "";
    if (productHierarchies && productHierarchies.length > 0) {
      // find by related_product_id
      const childItem = productHierarchies.find(item => item.related_product_id === related_product_id);
      if (childItem) {
        if (related_child_product_id && childItem.children && childItem.children.length > 0) {
          const childItemData = childItem.children.find(item => item.related_product_id === related_child_product_id);
          if (childItemData && childItemData.json_data) {
            if (childItemData.json_data === "") {
              jsonData = "";
            } else {
              jsonData = JSON.stringify(childItemData.json_data);
            }
          }
        }
      }
    }

    return (
      <React.Fragment>
        {/* render json data edit form */}
        <div className="json-data-product-child-form">
          <JsonDataRelatedProduct
            productId={product_id}
            index={index}
            level={level}
            itemRelatedProductId={related_product_id}
            subItemRelatedProductId={related_child_product_id}
            jsonData={jsonData}
            dispatch={props.dispatch}
            loader={props.loader}
            hide={props.hide}
            update={props.update}
            debug={debug}
            jsonDataFormCollapse={jsonDataFormCollapse}
            updateRelatedChildProducts={updateRelatedChildProducts}
            setAddFormCollapse={setAddFormCollapse}
            setJsonDataFormCollapse={setJsonDataFormCollapse}
            setJsonDataChildFormCollapse={setJsonDataChildFormCollapse}
          />
        </div>
      </React.Fragment>
    );
  };

  const removeRelatedProductFromHierarchy = (
      e,
      product_id,
      related_product_id,
      related_child_product_id,
  ) => {
    let parent_id;
    let child_id;
    let notificationsToastArr = [];

    if (!related_child_product_id) {
      parent_id = product_id;
      child_id = related_product_id;
    } else {
      parent_id = related_product_id;
      child_id = related_child_product_id;
    }

    if (!parent_id || !child_id) {
      notificationsToastArr.push({
        title: "Cannot delete item. Missing parent or child ID.",
        icon: "warning",
      });
      // show toast message
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to delete this product relation?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(res => {
      if (res.value) {
        if (!loader) {
          dispatch(loaderToggle(true));

          (async () => {
            const { ok } = await RemoveProductHierarchy(parent_id, child_id);
            if (ok) {
              await updateRelatedChildProducts();

              notificationsToastArr.push({
                title: 'Child relation deleted successfully',
                icon: 'success'
              });

            } else {
              notificationsToastArr.push({
                title: 'Error deleting child relation',
                icon: 'warning'
              });
            }
            await handleOk(ok, notificationsToastArr);

          })();
        }
      }
    });
  };

  const handleOk = async (ok, options) => {
    dispatch(loaderToggle(false));

    if (ok) {
      update();
      if (options && options.length > 0) {
        return ToastMulti(options, "container-validation-id");
      } else {
        hide();
      }
    }
  };

  const renderRelatedProductDropdown = (
    product_id,
    related_product_id,
    related_child_product_id,
    index,
    style = ""
  ) => {
    // set id string
    let itemId;
    let params = {};
    let debugStrId;

    if (!related_child_product_id) {
      itemId = product_id+'-'+related_product_id+'-'+index;
      debugStrId = `ID=${related_product_id}, index=${index}, parent=${product_id}`;
    } else {
      itemId = product_id+'-'+related_product_id+'-'+related_child_product_id+'-'+index;
      debugStrId = `ID=${related_child_product_id}, index=${index}, parent=${related_product_id}`;
    }

    return (
        <React.Fragment>
          { itemId &&
          <UncontrolledDropdown
            className="ml-4 float-right"
            autoclose="true"
            align={style && style === "" ? `end` : style}
          >
            <DropdownToggle
              className="tree-list-item-product-hierarchy"
              tag="a"
              size={'sm'}
              id={`dropdown-related-product-${itemId}`}
            >
              <MoreHorizontal size="18" className="hover-pointer" />
            </DropdownToggle>
            <DropdownMenu
              flip={true}
              right
            >
              <DropdownItem
                id={`dropdown-item-add-${itemId}`}
                data-action="add-parent-child"
                onClick={(e) => addChildRelatedProductToHierarchy(e, product_id, related_product_id, related_child_product_id, index, "child")}
              >
                Add parent-child relation
                <span className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                  ({debugStrId})
                </span>
              </DropdownItem>
              <DropdownItem
                  id={`dropdown-item-mutual-${itemId}`}
                  data-action="add-mutual"
                  onClick={(e) => addMutualRelatedProductToHierarchy(e, product_id, related_product_id, related_child_product_id, index, "mutual")}
              >
                Add mutual relation
                <span className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                  ({debugStrId})
                </span>
              </DropdownItem>
              <DropdownItem
                id={`dropdown-item-json-data-${itemId}`}
                data-action="edit"
                onClick={(e) => setJsonDataRelatedProductHierarchy(e, product_id, related_product_id, related_child_product_id, index)}
              >
                Edit JSON data
                <span className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                  ({debugStrId})
                </span>
              </DropdownItem>
              <DropdownItem
                id={`dropdown-item-remove-${itemId}`}
                onClick={(e) => removeRelatedProductFromHierarchy(e, product_id, related_product_id, related_child_product_id)}
              >
                Remove
                <span className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                  ({debugStrId})
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          }
        </React.Fragment>
    );
  };

  // render mutual icon with tooltip
  const renderRelationType = (
    product_id,
    related_parent_product_id,
    related_child_product_id,
    relation_type,
    index,
    isMutualRelatedToProductId,
    isMutualRelatedToProductDescription,
  ) => {
    console.log("Product - Hierarchy - ChildList - renderRelationType Icon for relation_type ->", relation_type);
    console.log("Product - Hierarchy - ChildList - renderRelationType Icon for product_id ->", product_id);
    console.log("Product - Hierarchy - ChildList - renderRelationType Icon for related_parent_product_id ->", related_parent_product_id);
    console.log("Product - Hierarchy - ChildList - renderRelationType Icon for isMutualRelatedToProductId ->", isMutualRelatedToProductId);
    console.log("Product - Hierarchy - ChildList - renderRelationType Icon for isMutualRelatedToProductDescription ->", isMutualRelatedToProductDescription);
    if (
      relation_type &&
      (relation_type.id === 3 || relation_type.id === 4 || relation_type.id === 5)
    ) {
      // set item id
      let itemTypeId;
      if (!related_child_product_id) {
        itemTypeId = product_id+'-'+related_parent_product_id+'-'+relation_type.id;
      } else {
        itemTypeId = product_id+'-'+related_parent_product_id+'-'+related_child_product_id+'-'+relation_type.id;
      }
      // set product related to ID & description
      let mutualProduct = "";
      if (isMutualRelatedToProductId && isMutualRelatedToProductDescription) {
        mutualProduct = `${isMutualRelatedToProductDescription} (ID: ${isMutualRelatedToProductId})`;
      }
      console.log("Product - Hierarchy - ChildList - renderRelationType Icon for itemTypeId ->", itemTypeId);

      return (
          <OverlayTrigger key={`tooltip-${itemTypeId}-${index}`} placement={`top`} transition={false} overlay={
            <Tooltip id={`tooltip-top`}>
              <div className={`popover-html-contents`}>
                <p>{`This relation type is:`}<br />{`(TYPE ID ${relation_type.id})`}<br /><b>{`${relation_type.description}`}</b><br />{mutualProduct}</p>
              </div>
            </Tooltip>}
          >
            <span className="child-list-icon-hierarchy-type float-right">
              <Repeat
                  size="18"
                  name={`child-relation-type-${itemTypeId}`}
                  id={`child-relation-type-id-${itemTypeId}`}
                  className={`ml-1 mr-1 child-list-icon-hierarchy-type-item`}
                  color={`red`}
                  type="button"
                  style={{display: "inline-block"}}
              />
            </span>
          </OverlayTrigger>
      );
    } //else {
      //return "";
    //}
  };

  // child sub subItem list
  const renderChildrenChildList = (product_id) => {
    // lookup product_id in productList & get related_product_id
    if (product_id && productRestHierarchies.length > 0) {
      // find childrenList
      let relatedChildrenChild = productRestHierarchies.filter(
          itemInArray =>
              itemInArray.product_id === product_id &&
              (itemInArray.relation_type.id === 1 || itemInArray.relation_type.id === 2)
      );
      if (relatedChildrenChild) {
        return (
            <ul className={`tree tree-child parent-child-items`}>{renderTreeItem(relatedChildrenChild)}</ul>
        );
      }

      // find mutualItemsList
      /*let mutualItemsChild = productRestHierarchies.filter(
          itemInArray =>
              itemInArray.product_id === product_id &&
              (itemInArray.relation_type.id === 3 || itemInArray.relation_type.id === 4 || itemInArray.relation_type.id === 5)
      );
      if (mutualItemsChild) {
        return (
            <ul className={`tree tree-child mutual-items`}>{renderTreeItem(mutualItemsChild)}</ul> /* relatedChildrenChild */
        //);
      //}
    }
  };
  // tree Item child sub subItem
  const renderTreeItem = (relatedChildrenChild) => {
    let data = [];
    for (let i = 0; i < relatedChildrenChild.length; i++) {
      let lastItemId = 'last-item-' + relatedChildrenChild[i].related_product_id + '-' + i;
      data.push(
        <li id={lastItemId}>
          <span className="text-truncate" style={{ fontWeight: "normal" }}>
            {relatedChildrenChild[i].related_product_description}
            {/* debug info */}
            <i className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
              (ID ={relatedChildrenChild[i].related_product_id}, TYPE ={relatedChildrenChild[i].relation_type.id})
            </i>
          </span>

          {/* render simple dropdown menu */}
          {renderSimpleRelatedProductDropdown(relatedChildrenChild[i].product_id, relatedChildrenChild[i].related_product_id, i, 3, "end")}

          {/* render mutual icon */}
          {console.log("RenderRelationType for (child - last level) mutualItems ->", relatedChildrenChild[i].mutual_items)}
          {relatedChildrenChild[i].mutual_items ? relatedChildrenChild[i].mutual_items.map((mutualItem, k) => {
            return (
                renderRelationType(product.id, relatedChildrenChild[i].product_id, relatedChildrenChild[i].related_product_id, mutualItem.relation_type, k, mutualItem.related_product_id, mutualItem.related_product_description)
            )
          }) : ""}

          {/* json data form */}
          {renderJsonDataChildRelatedProductForm(relatedChildrenChild[i].product_id, relatedChildrenChild[i].related_product_id, i, 3)}
          {/* render mutual relation icon */}

        </li>);
    }
    return data;
  };
  // renderJsonDataChildRelatedProductForm
  const renderJsonDataChildRelatedProductForm = (
      related_parent_product_id,
      related_child_product_id,
      index,
      level,
  ) => {
    let jsonData = "";
    if (productHierarchies && productHierarchies.length > 0) {
      // find by related_product_id
      const childItem = productHierarchies.find(item => item.related_product_id === related_child_product_id);
      if (childItem) {
        if (related_child_product_id && childItem.children && childItem.children.length > 0) {
          const childItemData = childItem.children.find(item => item.related_product_id === related_child_product_id);
          if (childItemData && childItemData.json_data) {
            if (childItemData.json_data === "") {
              jsonData = "";
            } else {
              jsonData = JSON.stringify(childItemData.json_data);
            }
          }
        }
      }
    }

    return (
        <React.Fragment>
          {/* render json data edit form */}
          <div className="json-data-product-child-form">
            <JsonDataChildRelatedProduct
                index={index}
                level={level}
                itemRelatedProductId={related_child_product_id}
                parentItemRelatedProductId={related_parent_product_id}
                jsonData={jsonData}
                dispatch={props.dispatch}
                loader={props.loader}
                hide={props.hide}
                update={props.update}
                debug={debug}
                jsonDataFormCollapse={jsonDataFormCollapse}
                jsonDataChildFormCollapse={jsonDataChildFormCollapse}
                updateRelatedChildProducts={updateRelatedChildProducts}
                setAddFormCollapse={setAddFormCollapse}
                setJsonDataFormCollapse={setJsonDataFormCollapse}
                setJsonDataChildFormCollapse={setJsonDataChildFormCollapse}
            />
          </div>
        </React.Fragment>
    );
  };
  // renderSimpleRelatedProductDropdown
  const renderSimpleRelatedProductDropdown = (product_id, related_product_id, index, level, style) => {
    let subItemId = product_id + '-' + related_product_id + '-' + index + '-' + level;
    return (
        <UncontrolledDropdown
            className="ml-4 float-right"
            autoclose="true"
            align={style && style === "" ? `end` : style}
        >
          <DropdownToggle
              className="tree-list-item-product-hierarchy"
              tag="a"
              size={'sm'}
              id={`dropdown-related-product-child-${subItemId}`}
          >
            <MoreHorizontal size="18" className="hover-pointer" />
          </DropdownToggle>
          <DropdownMenu
              flip={true}
              right
          >
            <DropdownItem
                id={`dropdown-item-json-data-child-${subItemId}`}
                data-action="edit"
                onClick={(e) => setJsonDataChildRelatedProductHierarchy(e, product_id, related_product_id, index, level)}
            >
              Edit JSON data
            </DropdownItem>
            <DropdownItem
                id={`dropdown-item-remove-child-${subItemId}`}
                onClick={(e) => removeRelatedProductFromHierarchy(e, product_id, related_product_id)}
            >
              Remove
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
    );
  };

  const setJsonDataChildRelatedProductHierarchy = (
      e,
      related_product_id,
      related_child_product_id,
      index,
      level,
  ) => {
    const collapsibleJsonDataChildId = "dropdown-item-json-data-child-" + related_product_id +  "-" + related_child_product_id + "-" + index + "-" + level;

    let formItem;
    if (jsonDataChildFormCollapse && jsonDataChildFormCollapse.length > 0) {
      formItem = jsonDataChildFormCollapse.find(
          item => item.collapsibleJsonDataChildId === collapsibleJsonDataChildId);
    }

    let jsonDataChildCollapse = [];
    jsonDataChildCollapse.push({ collapsibleJsonDataChildId: collapsibleJsonDataChildId, open: formItem && formItem.open ? !formItem.open : true });
    setJsonDataChildFormCollapse(jsonDataChildCollapse);

    setJsonDataFormCollapse([]);
    setAddFormCollapse([]);
    setMutualFormCollapse([]);
  };

  return (
    product && productHierarchies && productHierarchies.length > 0 &&
    productHierarchies.map((item, i) => {
      return (
        <li key={i} id={`${product.id}-${item.related_product_id}-${i}`}>
          {/* render collapse btn to show children */}
          <span className="child-list-icon-hierarchy">
            <FontAwesomeIcon
                icon={faSortAmountDown}
                size={`sm`}
                name={`child-relation-${product.id}-${item.related_product_id}-${i}`}
                id={`child-${product.id}-${item.related_product_id}-${i}`}
                className={`mr-1 child-list-icon-hierarchy-item ${(item.children && item.children.length > 0 || (item.mutual_items && item.mutual_items.length > 0)) ? `has-children closed` : `disabled`}`}
                color={`grey`}
                onClick={(e) => childCollapse(e)}
                type="button"
                style={{ display: "inline-block" }}
            />
          </span>

          <span className="text-truncate">
            {item.related_product_description}
            <i className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
              (ID ={item.related_product_id}, TYPE ={item.relation_type.id})
            </i>
          </span>

          {/* render dropdown menu */}
          {renderRelatedProductDropdown(product.id, item.related_product_id, null, i, "end")}

          {/* render mutual icon */}
          {console.log("RenderRelationType for (child - level 1) mutualItems ->", item.mutual_items)}
          {item.mutual_items ? item.mutual_items.map((mutualItem, k) => {
            return (
                renderRelationType(product.id, item.related_product_id, null, mutualItem.relation_type, k, mutualItem.related_product_id, mutualItem.related_product_description)
            )
          }) : ""}

          {/* add child relation form */}
          {renderAddChildRelatedProductForm(product.id, item.related_product_id, null, item.relation_type.id, i)}
          {/* add mutual relation form */}
          {renderMutualChildRelatedProductForm(product.id, item.related_product_id, null, item.relation_type.id, i)}
          {/* json data form */}
          {renderJsonDataRelatedProductForm(product.id, item.related_product_id, null, i, 1)}

          {item.related_product_id && (item.children && item.children.length > 0 || item.mutual_items && item.mutual_items.length > 0) &&
            <Collapse in={getChildListCollapseState(`child-${product.id}-${item.related_product_id}-${i}`)}>
              <ul className={`tree tree-child`} id={`child-relation-hierarchy-list-${product.id}-${item.related_product_id}-${i}`}>
                {item.children.map((subItem, j) => {
                  return (
                    <li key={`${product.id}-${item.related_product_id}-${i}-${j}`} id={`${product.id}-${item.related_product_id}-${i}-${j}`}>
                      <span
                          className="text-truncate"
                          style={{ fontWeight: "normal" }}
                      >
                        {/* description */}
                        {subItem.related_product_description}
                        {/* debug info */}
                        <i className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                          (ID ={subItem.related_product_id}, TYPE ={subItem.relation_type.id})
                        </i>
                      </span>

                      {/* render dropdown menu */}
                      {renderRelatedProductDropdown(product.id, item.related_product_id, subItem.related_product_id, j, "end")}

                      {/* render mutual icon */}
                      {console.log("RenderRelationType for (child - level 2) mutualItems ->", item.mutual_items)}
                      {item.mutual_items ? item.mutual_items.map((mutualItem, l) => {
                        return (
                            renderRelationType(product.id, item.related_product_id, subItem.related_product_id, mutualItem.relation_type, l, mutualItem.related_product_id, mutualItem.related_product_description)
                        )
                      }) : ""}

                      {/* add child relation form */}
                      {renderAddChildRelatedProductForm(product.id, item.related_product_id, subItem.related_product_id, subItem.relation_type.id, j)}
                      {/* add mutual relation form */}
                      {renderMutualChildRelatedProductForm(product.id, item.related_product_id, subItem.related_product_id, subItem.relation_type.id, j)}
                      {/* json data form */}
                      {renderJsonDataRelatedProductForm(product.id, item.related_product_id, subItem.related_product_id, j, 2)}

                      {/* childrenChildList */}
                      {renderChildrenChildList(subItem.related_product_id)}

                    </li>
                  )
                })}

                {/* here below are the list items that are mutually related  - for now we don't show these items in a list
                {item.mutual_items.map((mutualItem, j) => {
                  return (
                    <li key={`${mutualItem.id}-${mutualItem.related_product_id}-${i}-${j}`} id={`${product.id}-${mutualItem.related_product_id}-${i}-${j}`}>
                      <span
                          className="text-truncate"
                          style={{ fontWeight: "normal" }}
                      >
                        {mutualItem.related_product_description}
                        <i className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                          (ID ={mutualItem.related_product_id}, TYPE ={mutualItem.relation_type.id})
                        </i>
                      </span>

                      {renderRelatedProductDropdown(product.id, item.related_product_id, mutualItem.related_product_id, j, "end")}
                      {renderRelationType(product.id, item.related_product_id, mutualItem.related_product_id, mutualItem.relation_type, j, mutualItem.product_id, mutualItem.product_description)}
                      {renderAddChildRelatedProductForm(product.id, item.related_product_id, mutualItem.related_product_id, mutualItem.relation_type.id, j)}
                      {renderMutualChildRelatedProductForm(product.id, item.related_product_id, mutualItem.related_product_id, mutualItem.relation_type.id, j)}
                      {renderJsonDataRelatedProductForm(product.id, item.related_product_id, mutualItem.related_product_id, j, 2)}
                      {renderChildrenChildList(mutualItem.related_product_id)}

                    </li>
                  )
                })}
                */}

              </ul>
            </Collapse>
          }
        </li>)
      })
  );
};

const mapStateToProps = ({ dispatch, loader }) => ({ dispatch, loader });
export default connect(mapStateToProps)(ProductChildHierarchyList);
