import React, { useRef, useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { IdleTimeOutModal } from "../components/IdleModal";

export default function IdleComponent({ handleIdleLogout }) {
  const idleTimeRef = useRef(null);
  const [showIdleTimerModal, setShowIdleTimerModal] = useState(false);

  const isTest = false;
  const timeout = isTest ? 20_000 : 900_000; // is test 20 seconds - else 15 * 60 === 900 (15 minutes)
  const promptBeforeIdle = isTest ? 8_000 : 120_000; // is test -> 8 || 120 seconds for log out
  // 12 seconds will be 120 seconds
  // ? - ? = 120

  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setShowIdleTimerModal(false);
    handleStillHere();
  };

  const handleLogout = () => {
    setShowIdleTimerModal(false);
    handleIdleLogout(true);
  };

  const onIdle = () => {
    setState('Idle');
    setOpen(false);
    setShowIdleTimerModal(false);
    handleLogout();
  };

  const onPresenceChange = (presence) => {
    //console.log("IdleComponent - onPresenceChange - presence ->", presence);
  };

  const onPrompt = () => {
    setState('Prompted');
    setShowIdleTimerModal(true);
    setOpen(true);
  };

  const onActive = (e) => {
    setState('Active');
    setOpen(false)
  };

  const onAction = (e) => {
    //console.log("IdleComponent - onAction");
  };

  const handleStillHere = () => {
    activate();
  };

  const { getRemainingTime, activate } = useIdleTimer({
    crossTab: true,
    ref: idleTimeRef,
    onIdle,
    onPresenceChange,
    onActive,
    onPrompt,
    onAction,
    timeout,
    promptBeforeIdle,
    throttle: 500
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500);

    return () => {
      clearInterval(interval)
    }
  });

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

  return (
      <IdleTimeOutModal
          showModal={showIdleTimerModal}
          handleClose={handleClose}
          handleLogout={handleLogout}
          remaining={remaining}
          timeTillPrompt={timeTillPrompt}
          seconds={seconds}
      />
  );
};
