import React from "react";
import { connect } from "react-redux";

import { Button, Modal, ModalBody, Row } from "reactstrap";

import { Remove } from '../../../../controllers/vat_codes';
import { loaderToggle } from '../../../../redux/actions/loaderActions';
import Toast from "../../../../components/Toast";

class RemoveVatCodeForm extends React.Component {
    remove(e) {
        e.preventDefault()

        const { dispatch, loader, hide, update, selectedData } = this.props
        dispatch(loaderToggle(true))

        if (!loader) {
            (async () => {
                const { ok } = await Remove(selectedData.id)

                dispatch(loaderToggle(false))

                if (ok) {
                    Toast.fire({ title: 'Removed successfully!', icon: 'success' })
                    hide()
                    update()
                }
            })()
        }
    }

    render() {
        const { show, hide } = this.props

        console.log(this.props.selectedData)

        return (
            <Modal isOpen={show} toggle={hide} centered>
                <ModalBody className="text-center mt-3 mb-3">
                    <p className="lead">Are you sure you want to remove this VAT code?</p>
                    <Row className="justify-content-center">
                        <Button className="mr-3" color="primary" size="lg" onClick={(e) => this.remove(e)}> Yes </Button>
                        <Button className="mr-3" color="danger" size="lg" onClick={hide}> No </Button>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(RemoveVatCodeForm);
