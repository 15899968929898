import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import queryString from "query-string";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  TabContent,
  TabPane, Media,
} from 'reactstrap';
import Breadcrumbs from "../components/Breadcrumbs";
import Unauthorized from "../pages/auth/Unauthorized";
import {Box} from 'react-feather';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';

class DetailsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      breadcrumbs: true,
      tenantFavicon: props.tenantFavicon ? props.tenantFavicon : null,
      tenantTabSettings: 1 // tenantTab settings => value: 1 = label: "Show tab icon & text" | value: 2 = label: "Show tab icon only" | value: 3 = label: "Show tab text only"
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, activeTenant: this.state.tenant_name });
      this.props.history.push({
        search: `?tab=${tab}`
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //if (prevProps !== this.props && window.location.hash !== this.state.path) {
    if (prevProps !== this.props && window.location.pathname !== this.state.path) {
      (async () => {
        await this.setState({
          //path: window.location.hash,
          path: window.location.pathname,
          breadcrumbs: false
        });

        this.setState({ breadcrumbs: true });

      })();
    }
  }

  simulateTenantTabClick = async (el) => {
    let opts = {view: window, bubbles: true, cancelable: true, buttons: 1};
    el.dispatchEvent(new MouseEvent("mousedown", opts));
    await new Promise(r => setTimeout(r, 50));
    el.dispatchEvent(new MouseEvent("mouseup", opts));
    el.dispatchEvent(new MouseEvent("click", opts));
  };

  componentDidMount() {
    console.log("componentDidMount - DetailsPage - this.props ->", this.props);
    console.log("componentDidMount - DetailsPage - this.state ->", this.state);

    //this.setState({ path: window.location.hash });
    this.setState({ path: window.location });

    if (this.props.history && this.props.history.location && this.props.history.location.search) {
      const searchParam = queryString.parse(this.props.history.location.search);

      if (searchParam.tab) {
        let tab = searchParam.tab;

        if (parseInt(tab) >= this.props.tabs.length) {
          tab = 0;
          this.props.history.push({
            search: '?tab=0'
          })
        }
        this.setState({ activeTab: tab });

      }
      this.setState({ activeTab: searchParam.tab ? searchParam.tab : 0 });

    }

    this.unlisten = this.props.history.listen(location => {
      const { history } = this.props;

      if (history.action === 'PUSH') {
        if (location.pathname) {
          this.setState({ locationKeys: location.pathname });
        }
      }

      if (history.action === 'POP') {
        const searchParam = queryString.parse(this.props.history.location.search);
        if (searchParam.tab) {
          let itemClassId = 'f2x-tenant-tab-list-item-' + searchParam.tab;
          let el = document.getElementById(itemClassId);
          if (el) {
            this.simulateTenantTabClick(el).
                then(r => {
                  console.log(
                      'History POP item click invoice list item simulated');
                });
          }
        }
      }
    });

    if (this.props.user) {
      const { json_data } = this.props.user;
      console.log("componentDidMount - DetailsPage - this.props.user - json_data ->", json_data);
      if (json_data && json_data !== "") {
        let json_data_user = JSON.parse(json_data);
        console.log("componentDidMount - DetailsPage - this.props.user - json_data_user ->", json_data_user);
        if (json_data_user && json_data_user.tenant_tabs) {
          this.setState({ tenantTabSettings: json_data_user.tenant_tabs });
        }
      }
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  buttonNavMvLeft = () => {
    document.getElementById('f2x-mv-container').scrollLeft -= 100;
  };

  buttonNavMvRight = () => {
    document.getElementById('f2x-mv-container').scrollLeft += 100;
  };

  render() {
    const { name, tabs, error, tenant_name } = this.props,
          { breadcrumbs, activeTab, tenantFavicon, tenantTabSettings } = this.state;

    const tenantNameStr = tabs && tabs[activeTab] && tabs[activeTab].tenant_name;

    console.log("componentDidMount - DetailsPage - render - tenantTabSettings ->", tenantTabSettings);

    return (
        <Container fluid className="p-0">
          { !error ?
              <React.Fragment>
                <Row>
                  <Col md="12">
                    <div className="f2x-mv-tabs horizontal-tabs f2x-mv-tabs-multi-list" id={tenantNameStr}>
                      <div className="f2x-mv-tabs-list-item list-item-right f2x-innav-title order-last nav-item tenant-list-nav-item">
                        <div className="custom-mv-nav-item ml-auto">
                          <span className="pr-2 customer-heading">Tenant | { tenantNameStr }</span>
                          {tenantFavicon
                            ? <Media className="favicon" object src={ parse(window.atob(tenantFavicon)) } alt='favicon' style={{ maxWidth: "30px", height: "auto", verticalAlign: "top" }} />
                            : <span className="customer-heading-icon"><Box className="align-middle text-primary mb-1" size={36} /></span>}
                        </div>
                      </div>
                      { tabs && tabs.length > 4 ?
                      <div className={`horizontal-tabs f2x-mv-nav-tab-tenant-left-btn d-none`}>
                        <button id="slideLeft" type="button"
                                onClick={(e) => this.buttonNavMvLeft()}
                        >
                          <FontAwesomeIcon className="f2x-header-icon" icon="caret-left" size="lg" /><span>Slide left</span>
                        </button>
                      </div> : '' }
                      <ul 
                        tabs="true" 
                        className={`f2x-mv-tabs-list mv-tabs-list-left mb-0 nav nav-tabs nav-tabs-tenants ${ tabs && tabs.length > 4 ? `slide-btn` : `no-slide-btn`}`} 
                        id={`f2x-mv-container`} 
                        data-tenant={tenantNameStr}
                      >
                        {
                          tabs && tabs.map((item, index) => {
                            return (
                                <li
                                  key={`${item.title.replace(/[^a-z0-9 _-]/gi, '-').toLowerCase()}-li-${parseFloat(index)+1}`}
                                  className={`${classnames({ active: activeTab.toString() === index.toString() })} nav-item`}
                                  style={tenantTabSettings === 1 || tenantTabSettings === 3 ? { width: "unset" } : {}}
                                >
                                  {!tenantTabSettings || tenantTabSettings === 1 &&
                                  <a 
                                    key={`${item.title.replace(/[^a-z0-9 _-]/gi, '-').toLowerCase()}-link-${parseFloat(index)+1}`} 
                                    className={classnames({ active: activeTab.toString() === index.toString() }) + ` nav-link max-width-100 text-truncate`} 
                                    id={item.component.props.clickId} onClick={() => {this.toggle(index.toString())}}
                                    style={{padding: ".5rem 1rem", lineHeight: "22px"}}
                                  >
                                    <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon icon-block" icon={item.component.props.icon} style={{margin: "0 0 0 0"}} />
                                    <span className="tab-title-tenants text-truncate" style={{display: "inline", marginLeft: "4px"}}>{item.title}</span>
                                  </a>
                                  }

                                  {tenantTabSettings === 2 && 
                                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                                    <Tooltip id={`tooltip-bottom-${item.title.replace(/[^a-z0-9 _-]/gi, '-').toLowerCase()}-li-${parseFloat(index)+1}`}>
                                      <div className={`popover-html-contents`}>
                                        <span>{item.title}</span>
                                      </div>
                                    </Tooltip>}
                                  >
                                    <a 
                                      key={`${item.title.replace(/[^a-z0-9 _-]/gi, '-').toLowerCase()}-link-${parseFloat(index)+1}`} 
                                      className={classnames({ active: activeTab.toString() === index.toString() }) + ` nav-link max-width-100 text-truncate`} 
                                      id={item.component.props.clickId} onClick={() => {this.toggle(index.toString())}}
                                    >
                                      <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon icon-block" icon={item.component.props.icon} style={{margin: "0 0 0 0"}} />
                                      <span className="tab-title-tenants text-truncate" style={{display: "none"}}>{item.title}</span>
                                    </a>
                                  </OverlayTrigger>
                                  }

                                  {tenantTabSettings === 3 &&
                                  <a 
                                    key={`${item.title.replace(/[^a-z0-9 _-]/gi, '-').toLowerCase()}-link-${parseFloat(index)+1}`} 
                                    className={classnames({ active: activeTab.toString() === index.toString() }) + ` nav-link max-width-100 text-truncate`} 
                                    id={item.component.props.clickId} onClick={() => {this.toggle(index.toString())}}
                                    style={{padding: ".5rem 1rem", lineHeight: "22px"}}
                                  >
                                    <span className="tab-title-tenants text-truncate" style={{display: "inline"}}>{item.title}</span>
                                  </a>
                                  }
                                </li>
                            )
                          })
                        }
                      </ul>
                      { tabs && tabs.length > 4 ?
                      <div className={`horizontal-tabs f2x-mv-nav-tab-tenant-right-btn d-none`}>
                        <button id="slideRight" type="button"
                                onClick={(e) => this.buttonNavMvRight()}
                        >
                          <span>Slide right</span><FontAwesomeIcon className="f2x-header-icon" icon="caret-right" size="lg" />
                        </button>
                      </div> : '' }
                    </div>
                  </Col>
                  <Col md="12">
                    <TabContent
                      activeTab={activeTab.toString()}
                      className="tab-tenant"
                      id={tenantNameStr}
                    >
                      {
                        tabs && tabs.map((item, index) => {
                          return (
                              <React.Fragment key={ index }>
                                { activeTab.toString() === index.toString() &&
                                <TabPane tabId={ index.toString() }>
                                  { item.component }
                                </TabPane>
                                }
                              </React.Fragment>
                          )
                        })
                      }
                    </TabContent>
                  </Col>
                </Row>
              </React.Fragment> :
              <Unauthorized /> }
        </Container>
    )
  }
}

export default connect(store => ({
  error: store.error, 
  user: store.user
}))(withRouter(DetailsPage));
