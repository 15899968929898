import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Form, Input } from "reactstrap";
import { GetAccountsLists } from '../../../controllers/accounts';
import { AddVatCode, UpdateVatCode } from '../../../controllers/vat_codes';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import Toast from "../../../components/Toast";
import { validateDate } from "../../../redux/actions/validateActions";
import DatePicker, {registerLocale} from 'react-datepicker';
import moment from 'moment';
import { GetTenant } from "../../../controllers/tenants";
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../../redux/actions/formatActions';
import {withTranslation} from 'react-i18next'; // import all locales we need
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class VatCodeForm extends React.Component {
    constructor(props) {
        super(props);

        const { selectedData } = this.props;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'VAT code',
            vat_code: selectedData ? selectedData : {}
        }
    }

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    handleInputChange({ target: { name, value } }) {
        let vat_code = Object.assign({}, this.state.vat_code);

        vat_code[name] = value;

        this.setState({ vat_code })
    }

    handleSelectChange = (name, value) => {
        let vat_code = Object.assign({}, this.state.vat_code);
        vat_code[name] = value.value;

        this.setState({
            vat_code,
            [name]: value
        })
    };

    handleDatePickerChange(name, date) {
        let vat_code = Object.assign({}, this.state.vat_code);
        const { newDate } = this.props.dispatch(validateDate(date));
        vat_code[name] = newDate;
        this.setState({ vat_code })
    }

    hasValue = (value) => {
        return value !== null && value !== undefined && value !== '' ? true : false
    };

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false)
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        const { selectedData, id, dispatch, loader } = this.props,
            { vat_code } = this.state,
            { description, active_from, vat_percentage, account_id, active_to } = vat_code,
            { use_accounting } = this.state.tenant;

        //Validations
        if (!this.hasValue(description)) {
            return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
        }

        if (!this.hasValue(active_from)) {
            return Toast.fire({ title: 'Active from field is required!', icon: 'warning' })
        }

        if (!this.hasValue(vat_percentage)) {
            return Toast.fire({ title: 'VAT percentage field is required!', icon: 'warning' })
        }

        //if (use_accounting && !this.hasValue(account_id)) {
        //    return Toast.fire({ title: 'Account field is required!', icon: 'warning' })
        //}

        if (parseFloat(vat_percentage) > parseFloat(30)) {
            return Toast.fire({ title: 'VAT code percentage must not be higher than 30%!', icon: 'warning' })
        }

        if (this.hasValue(active_to)) {
            const activeTo = moment(active_to, 'DD-MM-YYYY'),
                  activeFrom = moment(active_from, 'DD-MM-YYYY'),
                  diff = activeTo.diff(activeFrom, 'days');

            if (diff < 0) {
                return Toast.fire({ title: 'Invalid active to!', icon: 'warning' })
            }
        }

        if (!loader) {
            dispatch(loaderToggle(true));

            // set vat_code date to save format
            vat_code.active_to = vat_code.active_to ? parseDateToSaveFormat(vat_code.active_to) : null;
            vat_code.active_from = vat_code.active_from ? parseDateToSaveFormat(vat_code.active_from) : null;

            if (selectedData) {
                (async () => {
                    const { ok } = await UpdateVatCode(vat_code.id, vat_code);

                    this.handleOk(ok)
                })()
            } else {
                (async () => {
                    vat_code['tenant_id'] = id;
                    const { ok } = await AddVatCode(vat_code);

                    this.handleOk(ok)
                })()
            }
        }
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update();
        }
    };

    componentDidMount() {
        const { dispatch, id } = this.props;
        dispatch(loaderToggle(true));

        (async () => {
            const tenantRes = await GetTenant({}, id),
                tenant = tenantRes.data.data,
                { use_accounting } = tenant,
                showAccount = use_accounting === 1 ? true : false;

            this.setState({ showAccount });
            this.setState({ tenant });

            if (use_accounting) {
                const { ok, data } = await GetAccountsLists(id);

                if (ok) {
                    const { selectedData } = this.props;
                    let accountOpts = [];

                    data.data.map(({ description, id }) => {
                        return accountOpts.push({
                            label: description,
                            value: id
                        })
                    });

                    this.setState({ accountOpts });

                    if (selectedData) {
                        let vat_code = Object.assign({}, selectedData);
                        const account_id = accountOpts.find(item => parseInt(item.value) === parseInt(vat_code.account_id));

                        this.setState({
                            vat_code,
                            account_id
                        })
                    }
                }
            }

            dispatch(loaderToggle(false));
            this.setState({ hasLoaded: true })
        })()
    }

    render() {
        const { show, hide, loader, t } = this.props,
            { hasLoaded, formType, formName, vat_code, accountOpts, account_id, showAccount } = this.state,
            { description, vat_percentage, active_from, active_to, in_use } = vat_code;

        return (
            <React.Fragment>
                {!loader && hasLoaded &&
                    <Modal className="form-subscription-lines" isOpen={show} toggle={hide} centered>
                        <Form onSubmit={(e) => this.handleSubmit(e)}>
                            <ModalHeader>
                                <span>{formType} {formName}</span>
                            </ModalHeader>
                            <ModalBody key="0" className="mt-3 mb-3">
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">Description:</label>
                                    </Col>
                                    <Col md={9}>
                                        <Input
                                            name="description"
                                            placeholder="Description"
                                            value={this.hasValue(description) ? description : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">VAT percentage:</label>
                                    </Col>
                                    <Col md={9}>
                                        {
                                            !in_use ?
                                                <Input
                                                    name="vat_percentage"
                                                    placeholder="VAT percentage"
                                                    value={this.hasValue(vat_percentage) ? vat_percentage : ''}
                                                    onChange={(e) => this.handleInputChange(e)}
                                                />
                                                :
                                                <div className="mt-1">{vat_percentage}%</div>
                                        }

                                    </Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">Active from:</label>
                                    </Col>
                                    <Col md={9}>
                                        <DatePicker
                                            locale={this.getLocale()}
                                            id="active_from"
                                            className="form-control"
                                            name="active_from"
                                            placeholderText="Active from"
                                            dateFormat="dd-MM-yyyy"
                                            autoComplete="off"
                                            selected={active_from ? new Date(active_from) : null}
                                            //value={dt_of_sgntr ? new Date(dt_of_sgntr) : null}
                                            //selected={active_from ? moment(active_from, 'DD-MM-YYYY')._d : null}
                                            onChange={this.handleDatePickerChange.bind(this, 'active_from')}
                                            ref="active_from"
                                            onKeyDown={(e) => this.handleKeyDown(e, 'active_from')}
                                            //maxDate={active_to ? moment(active_to, 'DD-MM-YYYY')._d : null}
                                            maxDate={active_to ? new Date(active_to, 'DD-MM-YYYY') : null}

                                        />
                                    </Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">Active to:</label>
                                    </Col>
                                    <Col md={9}>
                                        <DatePicker
                                            locale={this.getLocale()}
                                            id="active_to"
                                            className="form-control"
                                            name="active_to"
                                            placeholderText="Active to"
                                            //dateFormat="P"
                                            dateFormat={t("date_format_raw")}
                                            autoComplete="off"
                                            //selected={active_to ? moment(active_to, 'DD-MM-YYYY')._d : null}
                                            selected={active_to ? new Date(active_to) : null}
                                            onChange={this.handleDatePickerChange.bind(this, 'active_to')}
                                            ref="active_to"
                                            onKeyDown={(e) => this.handleKeyDown(e, 'active_to')}
                                            //minDate={active_from ? moment(active_from, 'DD-MM-YYYY')._d : null}
                                            minDate={active_from ? new Date(active_from) : null}
                                    />
                                    </Col>
                                </Row>
                                {showAccount &&
                                    <Row className="pb-2">
                                        <Col md={3}>
                                            <label className="col-form-label">Account:</label>
                                        </Col>
                                        <Col md={9}>
                                            <Select
                                                id="account_id"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                placeholder="Account"
                                                options={accountOpts}
                                                value={account_id ? account_id : null}
                                                onChange={this.handleSelectChange.bind(this, 'account_id')}
                                            />
                                        </Col>
                                    </Row>
                                }
                            </ModalBody>
                            <ModalFooter className="justify-content-between">
                                <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button color="primary">
                                    <FontAwesomeIcon icon={faSave} />
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => ({
    loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(VatCodeForm)));
