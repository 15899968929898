import queryString from "query-string";
import React from 'react';
import { MoreHorizontal } from "react-feather";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import { loaderToggle } from '../redux/actions/loaderActions';
import ApiService from '../services/apiService';
import { stringFilter } from '../services/misc';
import { getTableObj } from '../services/tableService';
import ActionHandler from './ActionHandler';
import AsyncGadgetsDropdown from './AsyncGadgetsDropdown';
import DataTable from './DataTable';

const Api = new ApiService(),
  GetAPI = Api.getAPI;

class ParentTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const fetchingData = false;
    const userJsonData = props.user && props.user.json_data ? JSON.parse(props.user.json_data) : null;
    const userSettingsItemsPerPage = userJsonData && userJsonData.itemsPerPageTables ? parseInt(userJsonData.itemsPerPageTables) : 100; // 100 => default (if no userSettings available)

    this.state = {
      data: [],
      dataFilterSearch: [],
      isOpen: false,
      relation_id: null,
      page: props.page ? props.page : 1,
      pageNum: 1,
      loadingOpts: false,
      sizePerPage: props.sizePerPage ? props.sizePerPage : userSettingsItemsPerPage,
      totalSize: 0,
      paginationCount: 1, // pagination on/off -> 1/0 (1 = calculate pagination - 0 = same page results)
      filters: {
        keyword: '',
        filter: {},
      },
      language: props.language ? props.language : 'nl',
      table: props.table ? props.table : null,
      columns: props.columns ? props.columns : {},
      gadgets: [],
      rowGadgets: [],
      gadgetsDropDownMenu: [],
      gadgetRowId: null,
      rowCount: props.rowCount ?? '',
      sort: props.sort ? props.sort : '',
      sortOrder: props.sortOrder ?? '',
      sortField: props.sortField ?? '',
      defaultSortItem: props.defaultSortItem ? props.defaultSortItem : 'id',
      defaultSortOrder: props.defaultSortOrder ? props.defaultSortOrder : 'desc',
      timePassed: false,
      collapseFilter: props.collapseFilter ? props.collapseFilter : "is-hidden",
    };
  }

  toggleModal = (e, row, index) => {
    const { isOpen, selectedData } = this.state;

    if (!isNaN(index)) {
      this.handleData(e, row, index)
    } else {
      if (isOpen) {

        if (selectedData) {
          // console.log("ParentTable - toggleModal - selectedData ->", selectedData);
          this.setState({ selectedData: null });
        }

        document.body.classList.remove('modal-open');

        const selectedRow = document.getElementsByClassName('selected-row');
        if (selectedRow) {
          for (let i = 0; i < selectedRow.length; i++) {
            selectedRow[i].classList.remove('selected-row')
          }
        }
      }

      this.setState({ isOpen: !isOpen })
    }
  };

  toggleNewModal = (e, row, index) => {
    const { isOpenDropdownForm } = this.state;

    if (!isNaN(index)) {
      this.handleData(e, row, index)
    } else {
      if (isOpenDropdownForm) {
        this.setState({ selectedData: null });
        document.body.classList.remove('modal-open')
      }

      this.setState({ isOpenDropdownForm: !isOpenDropdownForm })
    }
  };

  hideActionHandler = () => {
    this.setState({ dropdownActionData: null })
  };

  // para - opt, e
  handlerHeaderAction = (opt, e, props) => {
    if (opt && opt.isCustom) {
      if (opt.action === "collapseFilterAction") {
        this.props.collapseFilterAction(e);
      }
      if (opt.action === "handleFilterClick") {
        this.props.handleFilterClick(e, props);
      }
    } else {
      if (this.state.isOpenHeaderAction) {
        this.setState({ isOpenHeaderAction: false });
        document.body.classList.remove('modal-open');
      } else {
        this.setState({
          headerAction: opt.action, isOpenHeaderAction: true
        })
      }
    }
  };

  filterCondition = (row, item) => {
    return (!item.condition && !item['showIfMoreThan']) ||
      (item['showIfMoreThan'] ? this.state.totalSize > item['showIfMoreThan'] : null) ||
      (item.condition && ((item.condition.showIfEmpty &&
        (!row[item.condition.data] || row[item.condition.data].length === 0)) ||
        (!item.condition.showIfEmpty && (row[item.condition.data] &&
          (Object.keys(row[item.condition.data]).length || row[item.condition.data].length || (!isNaN(row[item.condition.data]))))))
      )
  };

  dropdownActionsFormatter = (cell, row, rowIndex, formatExtraData = null) => {
    const rowDropdownOpts = this.props.rowDropdownOpts
      ? this.props.rowDropdownOpts
      : null;

    const filterDropdownOpts = rowDropdownOpts && rowDropdownOpts.length > 0
      ? rowDropdownOpts.filter(item => {
        return this.filterCondition(row, item)
      })
      : null;

    const filterChecker = filterDropdownOpts && filterDropdownOpts.length > 0;

    const asyncRowDropdownOpts = this.props.asyncRowDropdownOpts
      ? this.props.asyncRowDropdownOpts
      : null;

    const filterAsyncDropdownOpts = asyncRowDropdownOpts &&
      asyncRowDropdownOpts.length > 0 ? asyncRowDropdownOpts.filter(item => {
        return this.filterCondition(row, item)
      }) : null;

    const filterAsyncChecker = filterAsyncDropdownOpts && filterAsyncDropdownOpts.length > 0;
    const { data, currentRow, loadingOpts } = this.state;

    return (<React.Fragment>
      {filterChecker && <UncontrolledDropdown className={`dropdown`}>
        <DropdownToggle tag="a"
          href="/#"
          id={`menu-toggle-${rowIndex}`}
          data-toggle="dropdown"
          aria-haspopup={true}
          className="px-3 py-2 dropdown-table-actions"
          onClick={(e) => this.stopBubble(e)}>
          <MoreHorizontal size={18} />
        </DropdownToggle>
        <DropdownMenu right={true} positionFixed={true}
          aria-labelledby={`menu-toggle-${rowIndex}`}
          id={`menu-${rowIndex}`}>
          {(filterChecker && filterDropdownOpts) &&
            filterDropdownOpts.map((opt, i) => {
              return <DropdownItem tabIndex="0" className="py-2"
                onClick={(e) => this.handlerClickDropdown(
                  opt.action, opt.form, row, rowIndex,
                  e)} key={i}>{opt.label}</DropdownItem>
            })}
        </DropdownMenu>
      </UncontrolledDropdown>}

      {filterAsyncChecker && <AsyncGadgetsDropdown
        handlerDropdownAction={this.handlerDropdownAction}
        row={row}
        rowIndex={rowIndex}
        filterAsyncDropdownOpts={filterAsyncDropdownOpts}
        loadingOpts={loadingOpts}
      />}
    </React.Fragment>)
  };

  extraDataFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { t } = this.props;

    if (formatExtraData && formatExtraData.length && formatExtraData.length ===
      0) {
      return;
    }

    const dataArray = formatExtraData && formatExtraData.split('>'),
      dataDash = formatExtraData.split('/DASH');

    return <React.Fragment>
      <span>{cell && cell !== '' ? cell : String.fromCharCode(8212)}</span>
      <br />
      <span>
        {dataDash.map((data, index) => {
          const getData = this.getDotNotation(data, row);

          return <React.Fragment key={index}>
            {index > 0 ? ' - ' : ''}{getData && getData !== ''
              ? getData
              : row[dataArray[0]] && row[dataArray[0]].length > 0
                ? row[dataArray[0]].map((array, arrIndex) => {
                  const dataSlash = dataArray[1].split('/SLASH');

                  return <React.Fragment key={arrIndex}>
                    {arrIndex > 0 && <br />}
                    {dataSlash.map((slash, slashIndex) => {
                      return <React.Fragment key={slashIndex}>
                        {slashIndex > 0 ? ' / ' : ''}
                        {slash === 'price_excl_vat' ? `${String.fromCharCode(
                          8364)} ` : ''}
                        {array[slash] !== null && array[slash] !==
                          undefined && array[slash] !== ''
                          ? t("currency_format",
                            { number: Number(parseFloat(array[slash])) })
                          : String.fromCharCode(8212)}
                        {slash === 'margin' &&
                          (array[slash] !== null && array[slash] !==
                            undefined && array[slash] !== '') ? ' %' : ''}
                      </React.Fragment>
                    })}
                  </React.Fragment>
                })
                : String.fromCharCode(8212)}
          </React.Fragment>
        })}
      </span>
    </React.Fragment>
  };

  getDotNotation = (path, obj, val) => {
    let thisPath = path.split('.');

    if (thisPath.length > 1) {
      const [bodyPath] = thisPath.slice(0, -1), [pathTail] = thisPath.slice(-1),
        thisObj = thisPath.slice(0, -1).
          reduce((item, i) => (item === undefined ? undefined : item[i]),
            obj);

      if (thisObj !== undefined && thisObj !== null) {
        if (val) {
          thisObj[pathTail] = val;
          obj[bodyPath] = thisObj;
          return obj
        } else {
          return thisObj[pathTail]
        }
      }
    } else {
      return path.split('.').
        reduce((item, i) => item && item[i] ? item[i] : item[i] === 0
          ? item[i].toString()
          : null, obj)
    }
  };

  headerFormatter = (column, colIndex, components) => {
    const texts = column.text.split('/n');

    return texts.map((text, index) => {
      return <React.Fragment key={index}>
        <span>{text}</span>
        {index + 1 < texts.length && <br />}
      </React.Fragment>
    });
  };

  stopBubble = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleData(e, selectedData, selectedDataRow) {
    e.stopPropagation();

    this.setState({ selectedData, selectedDataRow });
    this.toggleModal();
  }

  handlerClickDropdown = (newAction, dropdownForm, selectedData, selectedDataRow, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      selectedData,
      selectedDataRow,
      newAction,
      dropdownForm,
      loadingOpts: false,
      table: this.props.table,
    });

    this.toggleNewModal();
  };

  handlerDropdownAction = (obj, selectedData, selectedDataRow, e) => {
    e.preventDefault();
    e.stopPropagation();

    const dataObj = Object.assign({}, obj, selectedData);
    this.setState({
      dropdownActionData: {
        dataObj,
        obj,
        selectedData,
        selectedDataRow,
        loadingOpts: false,
      }
    })
  };

  getData = (str, keyword, pageNum) => {
    this.fetchingData = true;

    let storeData = {};

    const { sortField, sortOrder, sizePerPage } = this.state,
      { data,
        filters,
        include,
        dispatch,
        id,
        id2,
        hasLoaded,
        notLoadedByDefault,
        history,
        noSearch,
        cacheSearch,
        filterSearch,
        enabledFilter,
        dataLoaded,
        isRawData,
        rawData,
        noLoader,
        offLoader,
        table,
        isClearFilter,
        relation } = this.props;

    const requestData = data;
    const filter = filters;
    const isDescendingOrder = sortField !== '' && sortOrder === 'desc';
    const getSortField = isDescendingOrder ? `-${sortField}` : sortField;
    const getId = id ? id : null;
    const getId2 = id2 ? id2 : null;

    // set default parameters for query
    let params = {
      page: pageNum ? pageNum : this.state.page ? this.state.page : 1,
      offset: sizePerPage ? sizePerPage : this.state.sizePerPage ? this.state.sizePerPage : 10,
      sort: getSortField ? getSortField : '',
      id: id,
      id2: id2
    };

    (async () => {
      await (() => {
        if (!isRawData) {
          dispatch(loaderToggle(true));
        }

        if (include) {
          params.include = include; // include params in query string
        }

        // active filter search
        if (filterSearch && enabledFilter && filters && filters.filter) {
          params.filter = filters.filter && filters.filter.length > 0 ? filters.filter : "";
          params.search = filters.keyword ? filters.keyword : "";
        } else {
          // keyword search (old style)
          if (keyword || this.state.keyword) {
            params.search = keyword && keyword !== ""
              ? keyword
              : this.state.keyword && this.state.keyword !== ""
                ? this.state.keyword
                : "";
          }
        }
      })();

      if (this.getQueryParameters(params) && this.getQueryParameters(params) !== undefined && this.getQueryParameters(params) !== null) {
        params = this.getQueryParameters(params);
      }

      if (!notLoadedByDefault || ((notLoadedByDefault && ((filters && filters.keyword) || (filters && filters.filter) || history.location.search)))) {
        //if (!offLoader) {
        //  if (!noLoader) {
        //    dispatch(loaderToggle(true))
        //  }
        //}

        let isAddrRel = false;
        if (str !== 'paginate') {
          if ((str !== 'updateaddress' && table === 'RelationAddress') && (relation && relation.address && relation.addresses.length > 0)) {
            isAddrRel = true;
            storeData = { ok: true, data: { data: relation.addresses } }
          } else if ((str !== 'updateperson' && table === 'RelationPersons') && (relation && relation.persons && relation.persons.length > 0)) {
            isAddrRel = true;
            storeData = { ok: true, data: { data: relation.persons } }
          }
        }

        const { ok, data } =
          //isRawData && str !== 'sub_line_update' && rawData
          isRawData && rawData
            ? rawData
            : isAddrRel
              ? storeData
              : await requestData(params, getId, getId2);
        if (ok) {
          const filterKeys = params.filter;
          const keyword = params.search;

          let searchQuery = "";
          if (filterSearch) {
            // set filter in queryStr
            searchQuery = `?${new URLSearchParams({
              search: keyword,
              filter: isClearFilter ? "" : filterKeys && filterKeys !== "" ? filterKeys : "",
              page: params.page,
              sort: params.sort,
              offset: params.offset,
              count: params.count,
            }).toString()}`;

            this.props.history.push({
              pathname: this.props.location.pathname,
              search: decodeURI(searchQuery),
            });

          } else if (cacheSearch) {
            // search keyword only - search in queryStr
            searchQuery = `?${new URLSearchParams({
              search: keyword,
              page: params.page,
              sort: params.sort,
              offset: params.offset,
              count: params.count,
            }).toString()}`;

            this.props.history.push({
              pathname: this.props.location.pathname,
              search: decodeURI(searchQuery),
            });

          }
          // else -> no search - search is empty & no filter= in queryStr
          // do not interfere with dataTables that do not require filter or search

          // populate store(s) - add address from redux store
          if (table === 'RelationAddress' && !isAddrRel) {
            dispatch({ type: 'RELATION', payload: { addresses: data.data } })
          }
          // populate store(s) - add persons from redux store
          if (table === 'RelationPersons' && !isAddrRel) {
            dispatch({ type: 'RELATION', payload: { persons: data.data } })
          }

          if (filterSearch) {
            this.setState({
              data: data && data.data ? data.data : [],
              dataFilterSearch: data && data.data ? data.data : [],
              totalSize: data ? data.total : 0,
              page: params && params.page ? params.page : pageNum ? pageNum : this.state && this.state.page ? this.state.page : 1,
              offset: params && params.offset ? params.offset : sizePerPage ? sizePerPage : this.state && this.state.sizePerPage ? this.state.sizePerPage : 10,
              filter: params && params.filter && params.filter !== "" ? (params.filter).toString() : '',
              search: params && params.keyword ? params.keyword : keyword ? keyword : '',
              sort: params && params.sort ? params.sort : '',
              count: params && params.count ? params.count : this.state.paginationCount ? this.state.paginationCount : 1,
            });
          } else {
            this.setState({
              data: data && data.data ? data.data : [],
              dataFilterSearch: data && data.data ? data.data : [],
              totalSize: data ? data.total : 0,
              page: params && params.page ? params.page : pageNum ? pageNum : this.state && this.state.page ? this.state.page : 1,
              offset: params && params.offset ? params.offset : sizePerPage ? sizePerPage : this.state && this.state.sizePerPage ? this.state.sizePerPage : 10,
              search: params && params.keyword ? params.keyword : keyword ? keyword : '',
              sort: params && params.sort ? params.sort : '',
              count: params && params.count ? params.count : this.state.paginationCount ? this.state.paginationCount : 1,
            });
          }

          if (this.props.updateDetails && str === 'update') {
            this.props.updateDetails(str);
          }

          if (hasLoaded) {
            await hasLoaded(data.data.length);
          }

          if (dataLoaded) {
            await dataLoaded(data.data);
          }
        }
      }

      if (!isRawData) {
        dispatch(loaderToggle(false));
      }
      this.fetchingData = false;

    })();
  };

  handleTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText, data } = newState; 
    if (type === "search" && searchText !== "") {
      let dataStateOrProps = this.state.dataFilterSearch;
      if(this.props.isRawData === true && this.props.rawData.length > 0){        
        dataStateOrProps = this.props.rawData;
      }    
      const filteredData = dataStateOrProps.filter(item =>
        this.props.columns.some(column =>
          (typeof item[column.dataField] === 'string' || typeof item[column.dataField] === 'number') &&
          item[column.dataField].toString().toLowerCase().includes(searchText.toString().toLowerCase())
        )
      );      
      this.setState({ data: filteredData });
    } else {
      const isSortDesc = sortField && stringFilter(sortOrder, 'desc');
      const sortParam = isSortDesc ? sortField : sortField ? `-${sortField}` : "";
      const keyword = this.state.keyword;
      const pageNum = this.state.page;
      const isLoadData = this.state.isLoadData;
      const paginationCountParam = this.state.paginationCount;
      const propsObj = getTableObj(type, { keyword, isLoadData, pageNum, page, searchText, data });

      this.setState({
        page: propsObj && propsObj.pageNum ? propsObj.pageNum : this.state.page,
        offset: sizePerPage ? sizePerPage : 10,
        sizePerPage: sizePerPage ? sizePerPage : 10,
        sort: sortParam ? sortParam : '',
        search: propsObj && propsObj.keyword ? propsObj.keyword : propsObj.keyword,
        isLoadData: propsObj && propsObj.isLoadData ? propsObj.isLoadData : true,
        count: paginationCountParam ? paginationCountParam : paginationCountParam ? paginationCountParam : 1,
      });

      let params = {};
      params.sort = sortParam ? sortParam : '';
      params.page = propsObj && propsObj.pageNum ? propsObj.pageNum : this.state.page;
      params.search = propsObj && propsObj.keyword ? propsObj.keyword : ''; // keyword = search
      params.offset = sizePerPage ? sizePerPage : 10;
      if (type === 'pagination' || type === 'sort') {
        params.count = 0;
        this.setState({ paginationCount: 0 });
      }

      this.setQueryParameters(params);

      if (!this.fetchingData) {
        this.getData('didMount', keyword, params.page);
      }
    }
  };

  getQueryParameters = (param) => {
    const { table, cacheSearch, filterSearch, noSearch, filters, isClearFilter } = this.props;
    const tableId = 'qStr' + this.props.table;
    const tableFilterId = 'qStr' + this.props.table + 'Filter'; // => qStr
    const tableFilterStrId = 'qStr' + this.props.table + 'FilterStr'; // => qStr
    const tableKeywordId = 'qStr' + this.props.table + 'Keyword';
    const tableFiltersObjId = 'qStr' + this.props.table + 'FiltersObj';

    let queryString;
    let queryStringFilter;
    let queryStringKeyword;
    let params = {};

    if (param && param.include) {
      params.include = param.include;
    }

    if (!isClearFilter && sessionStorage.getItem(tableId)) {
      let queryString = sessionStorage.getItem(tableId);

      if (queryString) {
        // page - offset - count - sort - search
        queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
          if (key === 'page' || key === 'offset' || key === 'count') {
            params[key] = parseInt(value);
          } else if (key === 'sort' || key === 'search') {
            if (!this.isStrNull(value)) {
              params[key] = value;
            }
          }
        });

        // filter
        if (filterSearch) {
          queryStringFilter = sessionStorage.getItem(tableFilterId)
            ? sessionStorage.getItem(tableFilterId)
            : null;
          if (queryStringFilter) {
            // set filter parameter
            params.filter = queryStringFilter;
          } else {
            if (sessionStorage.getItem(tableKeywordId)) {
              params["search"] = sessionStorage.getItem(tableKeywordId);
            } else {
              params.filter = "";
            }
          }
        }
      }
    } else {
      if (isClearFilter === true) {
        params.page = 1;// ? this.state.page : 1;
        params.sort = '';
        params.offset = this.state.sizePerPage ? this.state.sizePerPage : 10;
        params.count = 1;
        params.search = "";
        if (filterSearch) {
          params.filter = "";
        }

      } else {

        params.page = this.state.page;// ? this.state.page : 1;
        params.sort = this.state.sort;// ? this.state.sort : '';
        params.offset = this.state.sizePerPage;// ? this.state.sizePerPage : 10;
        params.count = this.state.paginationCount &&
          this.state.paginationCount === 1 ? 0 : this.state.paginationCount &&
            this.state.paginationCount === 1 ? 0 : 1;
        params.search = this.state.keyword;// ? this.state.keyword : "";
        if (filterSearch) {
          params.filter = "";
        }

      }
    }

    return params;
  };

  setQueryParameters = (params, str) => {
    const { table, cacheSearch, filterSearch, noSearch, setSearchKeyword } = this.props;
    let query = [], key, value, filterStr;
    let searchKeyword;

    const tableId = 'qStr' + this.props.table;
    const tableFilterStrId = 'qStr' + this.props.table + "Filter";
    const tableFilterObjId = 'qStr' + this.props.table + "FiltersObj";
    const tableSearchKeyword = 'qStr' + this.props.table + "Keyword";

    if (Object.entries(params).length > 0) {
      for (key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (key === 'page' || key === 'offset' || key === 'count') {
            value = parseInt(params[key]);
            query.push(`${key}=${value}`);
          } else if (key === 'sort') {
            query.push(`sort=${params['sort']}`);
          } else if (filterSearch && key === 'filter') {
            query.push(`filter=${params['filter'] ? params['filter'] : ""}`);
          } else if (cacheSearch && key === 'search') {
            value = params['search'];
            searchKeyword = value;
            query.push(`${key}=${value}`);
          }
        }
      }
    }

    sessionStorage.setItem(tableId, query.join('&'));

    if (setSearchKeyword && searchKeyword && filterSearch && cacheSearch) {
      sessionStorage.setItem(tableSearchKeyword, searchKeyword);
      setSearchKeyword(searchKeyword);
    }
  };

  isStrNull = (str) => {
    let strChunks = str.split("-");
    if (strChunks.length > 0) {
      if (strChunks[0] === null || strChunks[0] === "null") {
        return true;
      }
      if (strChunks.length > 1) {
        if (strChunks[1] === null || strChunks[1] === "null") {
          return true;
        }
      }
    }
    if (str === null || str === undefined) {
      return true;
    }
    return false;
  };

  checkIfStringHasSpecialChar = (_string) => {
    let spChar = "/[!@#$%^&*()_+\-=\[\]{};':\\|,.<>\/?]+/";
    for (let i = 0; i < _string.length; i++) {
      if (spChar.indexOf(_string.charAt(i)) !== -1) {
        return true;
      }
    }
    return false;
  };

  handleDataChange = ({ dataSize }) => {
    this.setState({
      rowCount: dataSize,
      paginationCount: 1,
      page: 1,
    });
  };

  handleFilter = (filters, paramsArr, paramsStr) => {
    const { table, cacheSearch, filterSearch, noSearch, notLoadedByDefault, location, setSearchKeyword, history } = this.props;

    if (!filterSearch) {
      (async () => {
        let newFilters = filters;
        if (this.props.notLoadedByDefault && filters.keyword === '') {
          newFilters.keyword = null
        }
        if (this.props.notLoadedByDefault &&
          (filters.filter === null || Object.entries(filters.filter).length === 0)) {
          newFilters.filter = null;
        }

        await this.setState({
          filters: {
            ...this.state.filters,
            keyword: newFilters.keyword,
          }
        });

        await this.setState({ page: 1, paginationCount: 1 }); // set to page 1 for new search
        this.updateDataTable('didMount');

      })();
    } else {
      (async () => {
        const searchParam = queryString.parse(paramsStr);

        let keyword = searchParam.keyword && searchParam.keyword !== "" ? searchParam.keyword : "";

        const tableId = 'qStr' + this.props.table;
        sessionStorage.removeItem(tableId);

        await this.setState({
          filters: {
            ...this.state.filters,
            keyword: filters.keyword,
          }
        });

        this.setState({ page: 1, paginationCount: 1, keyword: filters.keyword });
        this.setQueryParameters(searchParam);
        this.updateDataTable('didMount');

      })();
    }
  };

  /*
    function updateDataTable
    param str
    param updated_data
    param updated_action (table update = delete || update || add)
   */
  updateDataTable = (str, updated_data, table_row_action) => {
    const { enabledFilter, cacheSearch, filterSearch, noSearch, setSearchKeyword, updatePreview, updateTableRawData, setIsUpdated, updateDetails } = this.props;

    // console.log("ParentTable - updateDataTable - this.props ->", this.props);

    let keyword = null;
    // if no special filter is used - fallback to original search
    if (!filterSearch && !enabledFilter && str === 'didMount' && this.props.location.search && this.props.cacheSearch) {
      const searchParam = queryString.parse(this.props.location.search);
      keyword = searchParam.keyword;
    }

    if (str === "sub_line_update") {
      // console.log("Subscriptions ListDetails (parentTable) - this.props ->", this.props);
      setIsUpdated(true);
      updateTableRawData();
    }

    if (str === "inv_line_update") {
      //setIsUpdated(true);
      updateTableRawData();
      if (updateDetails) {
        updateDetails(this.props.id, 'update', updated_data, table_row_action); // data === table_data
      }
      //setIsUpdated(true); // is causing total render with flipping components
    }

    if (updateTableRawData && str === "plan_line_update") {
      //console.log("ParentTable - updateDataTable - plan_line_update - so update table!!");
      updateTableRawData();
    }

    //prov_template_update
    if (str === "prov_template_update") {
      //console.log("ParentTable - updateDataTable - prov_template_table - so update table!!");
      updateTableRawData();
    }

    if (!this.fetchingData) {
      this.getData(str, keyword);
    }

    if (updatePreview) {
      updatePreview(true);
    }
  };

  removeCurrentRowState = () => {
    if (typeof this.state.currentRow === 'number') {
      const dropdownMenus = document.getElementsByClassName('dropdown-menu show');
      if (dropdownMenus && dropdownMenus.length > 0) {
        for (let i = 0; i <= dropdownMenus.length; i++) {
          if (dropdownMenus[i]) {
            dropdownMenus[i].classList.remove('show')
          }
        }
      }

      const dropdownToggle = document.getElementsByClassName('dropdown show');
      if (dropdownToggle && dropdownToggle.length > 0) {
        for (let i = 0; i <= dropdownToggle.length; i++) {
          if (dropdownToggle[i]) {
            dropdownToggle[i].classList.remove('show')
          }
        }
      }

      this.setState({
        currentRow: null,
        gadgets: [],
        gadgetRowId: null,
        rowGadgets: [],
        loadingOpts: false
      });
    }
  };

  renderColumnState = () => {
    const { rowAction, rowDropdownOpts, asyncRowDropdownOpts, asyncRowDropDownStyle } = this.props,
      { columns } = this.state ? this.state : {};

    if (columns && columns.length > 0) {
      columns.map((column, index) => {
        const { formatExtraData } = column;
        let newCols = Object.assign([], columns);

        if (formatExtraData) {
          newCols[index].formatter = this.extraDataFormatter;
          newCols[index].headerFormatter = this.headerFormatter;
          return this.setState({ columns: newCols })
        } else {
          return false
        }
      });
    }

    if (rowAction === 'edit' || rowDropdownOpts || asyncRowDropdownOpts) {
      let columns = Object.assign([], this.state.columns);
      const getIndex = columns.findIndex(item => item.dataField === 'actions');

      if (getIndex !== -1) {
        columns.splice(getIndex, 1)
      }

      this.setState({
        columns: [
          ...columns, {
            dataField: 'actions',
            text: '',
            formatter: this.dropdownActionsFormatter,
            classes: 'action-inv-col',
            attrs: {
              'data-label': 'Actions'
            },
            ...asyncRowDropDownStyle
          }
        ]
      });
    }
  };

  componentDidMount() {
    document.body.addEventListener('click', this.removeCurrentRowState, false);

    const { cacheSearch, filterSearch, noSearch, table, updatePreview, relation, id, match } = this.props;

    this.setState({ currentRow: null });
    this.setState({
      paginationCount: 1
    });

    // check history
    const queryData = this.getQueryParameters();

    let params = {};
    params.page = queryData && queryData.page ? queryData.page : this.state.page ? this.state.page : 1;
    params.search = queryData && queryData.search ? queryData.search : '';
    if (filterSearch) {
      const tableFilterId = 'qStr' + table + 'Filter'; // => qStr
      let storedFilterStr = sessionStorage.getItem(tableFilterId);
      if (storedFilterStr) {
        params.filter = storedFilterStr;
      } else {
        params.filter = queryData && queryData.filter ? queryData.filter : '';
      }
    }
    params.sort = queryData && queryData.sort ? queryData.sort : '';
    params.offset = queryData && queryData.offset ? queryData.offset : this.state.sizePerPage ? this.state.sizePerPage : 10;
    params.count = this.state.paginationCount;

    this.setState({
      sizePerPage: params.offset
    });

    this.setQueryParameters(params, 'didMount');

    (async () => {
      await this.renderColumnState();
      //this.renderColumnState();
      this.updateDataTable('didMount');
    })();
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.removeCurrentRowState, false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { id, user, columns, data, i18n, isLoadData, setIsLoadData, isReload, filters, enabledFilter, filterSearch, setSearchKeyword, location, table } = this.props;
    let { gadgets } = this.state;

    if (prevState.data !== this.state.data) {
      console.log("componentDidUpdate - prevState - data -> ", this.state.data);
    }

    if (id && prevProps.id !== id) {
      (async () => {
        await this.setState({ data: null, page: this.state.page });
        //this.updateDataTable('didMount');
      })();
    }

    if (filterSearch && prevProps.filters && prevProps.filters !== filters) {
      let filtersFilter = this.props.filters.filter;

      this.setState({
        filters: {
          ...this.state.filters,
          keyword: this.state.filters.keyword,
          filter: filtersFilter,
        }
      });

      this.updateDataTable('didMount');
    }

    if (prevProps.isLoadData && prevProps.isLoadData !== isLoadData) {
      this.updateDataTable('didMount');
    }

    if (user && prevProps.user !== user) {
      (async () => {
        await this.setState({ currentTenant: user.tenant_id, page: this.state.page });
        this.updateDataTable('didMount');
      })();
    }

    if (data && prevProps.data !== data) {
      (async () => {
        await this.setState({ data: data });
        this.updateDataTable('didMount');
      })();
    }

    if (columns && prevProps.columns !== columns) {
      (async () => {
        await this.setState({ columns });
        this.renderColumnState();
      })();
    }

    if (this.state.language && this.state.language !== i18n.language) {
      (async () => {
        await this.setState({ language: i18n.language, data: null });
        this.updateDataTable();
      })();
    }
  }

  render() {
    const { data, selectedData, selectedDataRow, isOpen, page, sizePerPage, totalSize, filters, columns, isOpenHeaderAction, isOpenDropdownForm, newAction, dropdownActionData, gadgets } = this.state,
      { i18n, keyField, id, id2, table, search, rowAction, bodyClasses, headerDropdownOpts, parent, noTopBorder, classes, headerCheckBox, wrapperClasses, noHover, noSearch, cacheSearch, filterSearch, tab, striped, rowEvents, expandRow, isLoadData, collapseFilter, setIsLoadData, enabledFilter, basicSearch } = this.props,
      HeaderAction = this.state.headerAction,
      RowActionForm = this.props.rowActionForm,
      DropdownForm = this.state.dropdownForm;

    return (
      <Container fluid className="p-0">
        {data && columns &&
          <DataTable
            basicSearch={basicSearch}
            table={table ? table : undefined}
            language={i18n && i18n.language ? i18n.language : 'nl'}
            keyField={keyField ? keyField : "id"}
            data={data}
            page={page}
            sizePerPage={sizePerPage ? parseInt(sizePerPage) : 10}
            totalSize={totalSize}
            isLoadData={isLoadData}
            setIsLoadData={setIsLoadData}
            onTableChange={this.handleTableChange}
            columns={columns}
            filters={filters ? filters : null}
            enabledFilter={enabledFilter}
            collapseFilter={collapseFilter}
            handleFilter={this.handleFilter}
            toggleForm={this.toggleModal}
            hasForm={!!RowActionForm}
            headerDropdownOpts={headerDropdownOpts}
            toggleHeaderAction={this.handlerHeaderAction}
            rowAction={rowAction}
            rowEvents={rowEvents}
            expandRow={expandRow}
            parent={!!parent}
            noTopBorder={!!noTopBorder}
            headerCheckBox={headerCheckBox ? headerCheckBox : null}
            wrapperClasses={wrapperClasses}
            noHover={noHover}
            tab={tab}
            striped={striped}
            noSearch={noSearch}
            cacheSearch={cacheSearch}
            filterSearch={filterSearch}
            classes={classes}
            bodyClasses={bodyClasses}
            //noDataIndication="No data found."
            notLoadedByDefault={false}
            setStatus={this.updateDataTable}
            paginationCount={this.state.paginationCount}
            sortItem={this.state.sortOrder ? this.state.sortOrder : ''}
            defaultSortItem={this.state.defaultSortItem ? this.state.defaultSortItem : 'id'}
            defaultSortOrder={this.state.defaultSortOrder ? this.state.defaultSortOrder : 'desc'}
            handleFilterClick={this.props.handleFilterClick}
            collapseFilterAction={this.props.collapseFilterAction}
          />}

        {HeaderAction && isOpenHeaderAction && <HeaderAction
          id={id}
          id2={id2}
          show={isOpenHeaderAction}
          hide={this.handlerHeaderAction}
          update={this.updateDataTable}
        />}

        {RowActionForm && isOpen && <RowActionForm
          id={id}
          id2={id2}
          show={isOpen}
          hide={this.toggleModal}
          selectedData={selectedData}
          selectedDataRow={selectedDataRow}
          update={this.updateDataTable}
        />}

        {DropdownForm && isOpenDropdownForm && <DropdownForm
          id={id}
          show={isOpenDropdownForm}
          hide={this.toggleNewModal}
          rowAction={newAction}
          selectedData={selectedData}
          selectedDataRow={selectedDataRow}
          update={this.updateDataTable}
        />}

        {dropdownActionData && <ActionHandler
          data={dropdownActionData}
          hide={this.hideActionHandler}
          update={this.updateDataTable}
        />}
      </Container>
    )
  }
}

const mapStateToProps = ({ user, relation, loader, keyword }) => ({ user, relation, loader, keyword });

export default withRouter(withTranslation()(connect(mapStateToProps)(ParentTable)));
