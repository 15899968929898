import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  Row,
  Col,
  Input,
  Card,
  Container,
  InputGroup, InputGroupAddon,
} from 'reactstrap';
import {
  Popover,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import AvCheckerFormEmbed from '../../components/AvCheckerFormEmbed';
import {Link, useHistory, withRouter, useParams} from 'react-router-dom';
import {withTranslation, useTranslation} from 'react-i18next';
import useMeasure from "react-use-measure";
import {connect} from 'react-redux';
import Select from 'react-select';
import CONSTANTS from '../../services/constants';
import ApiService from '../../services/apiService';
import {loaderToggle} from '../../redux/actions/loaderActions';
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';

const Api = new ApiService(),
      GetAPI = Api.getAPI;

const AvCheckEmbed = ({ ...props }) => {
  const [ref, {height}] = useMeasure();

  let history = useHistory();
  const {t} = useTranslation();
  const params = useParams();

  let clientApiKey;
  let clientCountryId;
  let clientIsBusiness;
  let clientLanguage;
  let clientDomain;

  let clientZipcode;
  let clientHouseNumber;
  let clientHouseNumberSuffix;

  let acStylesObj = {};

  let searchParams = new URLSearchParams(document.location.search);
  if (searchParams && searchParams.entries()) {
    for (const [key, value] of searchParams.entries()) {
      if (key === "api_key") {
        clientApiKey = value;
      }
      if (key === "country_id") {
        clientCountryId = value;
      }
      if (key === "business") {
        clientIsBusiness = value;
      }
      if (key === "language") {
        clientLanguage = value;
      }
      // address details - zipcode - house_number - house_number_suffix
      if (key === "zipcode") {
        clientZipcode = value;
      }
      if (key === "house_number") {
        clientHouseNumber = value;
      }
      if (key === "house_number_suffix") {
        clientHouseNumberSuffix = value;
      }
      // CSS styles
      if (key === "color") {
        acStylesObj[key] = value;
      }
      if (key === "backgroundColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnPrimaryColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnPrimaryBgColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnSuccessBgColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnSuccessColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnDangerColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnWarningColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnWarningBgColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnDefaultColor") {
        acStylesObj[key] = value;
      }
      if (key === "btnDefaultBgColor") {
        acStylesObj[key] = value;
      }
      if (key === "roundedCorners") {
        acStylesObj[key] = value;
      }
      if (key === "h1_size") {
        acStylesObj[key] = value;
      }
      if (key === "h1_color") {
        acStylesObj[key] = value;
      }
      if (key === "h2_size") {
        acStylesObj[key] = value;
      }
      if (key === "h2_color") {
        acStylesObj[key] = value;
      }
      if (key === "h3_size") {
        acStylesObj[key] = value;
      }
      if (key === "h3_color") {
        acStylesObj[key] = value;
      }
      if (key === "h4_size") {
        acStylesObj[key] = value;
      }
      if (key === "h4_color") {
        acStylesObj[key] = value;
      }
      if (key === "stepsColor") {
        acStylesObj[key] = value;
      }
      if (key === "stepsBackgroundColor") {
        acStylesObj[key] = value;
      }
      if (key === "stepsBackgroundActiveColor") {
        acStylesObj[key] = value;
      }
      if (key === "steps") {
        acStylesObj[key] = value;
      }
      if (key === "domain") {
        clientDomain = value;
      }
    }
  }

  const [apiKey, setApiKey] = useState(clientApiKey ? clientApiKey : null);
  const [countryId, setCountryId] = useState(clientCountryId ? clientCountryId : null);
  const [language, setLanguage] = useState(clientLanguage ? clientLanguage : null);
  const [isBusiness, setIsBusiness] = useState(clientIsBusiness ? clientIsBusiness : null);
  const [checkOtherAddressDisabled, setCheckOtherAddressDisabled] = useState(!clientZipcode && !clientHouseNumber ? false : true);

  // Address details
  const [inputClientZipcode, setInputClientZipcode] = useState(clientZipcode ? clientZipcode : null);
  const [inputClientHouseNumber, setInputClientHouseNumber] = useState(clientHouseNumber ? clientHouseNumber : null);
  const [inputClientHouseNumberSuffix, setInputClientHouseNumberSuffix] = useState(clientHouseNumberSuffix ? clientHouseNumberSuffix : null);

  const [currentStep, setCurrentStep] = useState(1);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [messageData, setMessageData] = useState(null);
  const [frameHeight, setFrameHeight] = useState(height ? height : 320);

  const activateStep = (step) => {
    setCurrentStep(step);
  };

  function getWindowSize() {
    let the_document_el = document.getElementById('ac-wrapper-container');
    if (the_document_el && the_document_el.clientHeight) {
      let parentWindow = window.parent;
      if (parentWindow) {
        try {
          parentWindow.postMessage(the_document_el.clientHeight, "*");
        } catch(e) {
          console.log("postMessage failed - error");
        }
      }
    }

    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  function getElemSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const renderSteps = () => {
    return (
        <div className="d-none d-md-block steps-full">
          <div className="progress-bar-container">
            <div className="steps-container">
              <div className={`step-box${currentStep === 1 ? " active-step" : currentStep > 1 ? " done-step" : ""}`}>
                <div className="step-title">{t("offers.steps_title_1", null)}</div>
                <div className="step-number-box">
                  <div className={`step-progress-line step-progress-line-right${currentStep > 1 ? " step-progress-line-done" : ""}`} style={currentStep > 1 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}> </div>
                  <div className="step-number" style={currentStep === 1 ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : currentStep > 1 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}>
                    <OverlayTrigger placement={`bottom`} transition={false} overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        {t("offers.steps_title_1", null)}
                      </Tooltip>}
                    >
                      <span>1</span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className={`step-box${currentStep === 2 ? " active-step" : currentStep > 2 ? " done-step" : ""}`}>
                <div className="step-title">{t("offers.steps_title_2", null)}</div>
                <div className="step-number-box">
                  <div className={`step-progress-line step-progress-line-left${currentStep >= 2 ? " step-progress-line-done" : ""}`} style={currentStep >= 2 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}> </div>
                  <div className={`step-progress-line step-progress-line-right${currentStep > 2 ? " step-progress-line-done" : ""}`} style={currentStep > 2 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}> </div>
                  <div className="step-number" style={currentStep === 2 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : currentStep > 2 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}>
                    <OverlayTrigger placement={`bottom`} transition={false} overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        {t("offers.steps_title_2", null)}
                      </Tooltip>}
                    >
                      <span>2</span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className={`step-box${currentStep === 3 ? " active-step" : currentStep > 3 ? " done-step" : ""}`}>
                <div className="step-title">{t("offers.steps_title_3", null)}</div>
                <div className="step-number-box">
                  <div className={`step-progress-line step-progress-line-left${currentStep >= 3 ? " step-progress-line-done" : ""}`} style={currentStep >= 3 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}> </div>
                  <div className={`step-progress-line step-progress-line-right${currentStep > 3 ? " step-progress-line-done" : ""}`} style={currentStep > 3 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}> </div>
                  <div className="step-number" style={currentStep === 3 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : currentStep > 3 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}>
                    <OverlayTrigger placement={`bottom`} transition={false} overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        {t("offers.steps_title_3", null)}
                      </Tooltip>}
                    >
                      <span>3</span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className={`step-box${currentStep === 4 ? " active-step" : currentStep > 4 ? " done-step" : ""}`}>
                <div className="step-title">{t("offers.steps_title_4", null)}</div>
                <div className="step-number-box">
                  <div className={`step-progress-line step-progress-line-left${currentStep >= 4 ? " step-progress-line-done" : ""}`} style={currentStep >= 4 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}> </div>
                  <div className={`step-progress-line step-progress-line-right${currentStep > 4 ? " step-progress-line-done" : ""}`} style={currentStep > 4 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}> </div>
                  <div className="step-number" style={currentStep === 4 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : currentStep > 4 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}>
                    <OverlayTrigger placement={`bottom`} transition={false} overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        {t("offers.steps_title_4", null)}
                      </Tooltip>}
                    >
                      <span>4</span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className={`step-box${currentStep === 5 ? " active-step" : ""}`}>
                <div className="step-title">{t("offers.steps_title_5", null)}</div>
                <div className="step-number-box">
                  <div className={`step-progress-line step-progress-line-left${currentStep === 5 ? " step-progress-line-done" : ""}`} style={currentStep === 5 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}> </div>
                  <div className="step-number" style={currentStep === 5 && acStylesObj["stepsBackgroundActiveColor"] ? { backgroundColor: acStylesObj["stepsBackgroundActiveColor"] } : {}}>
                    <OverlayTrigger placement={`bottom`} transition={false} overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        {t("offers.steps_title_5", null)}
                      </Tooltip>}
                    >
                      <span>5</span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  };

  return (
    <div id="ac-wrapper" className={clientDomain ? `${clientDomain}` : {}}>
      <div className="container-fluid" id="ac-wrapper-container" ref={ref} data-iframe-height={height}>
        <div className="row ac-wrapper-container-row">
          <div className="col">
            <h1
              className="text-bold"
              style={ acStylesObj["h1_size"] && acStylesObj["h1_color"] ? { fontSize: acStylesObj["h1_size"], color: acStylesObj["h1_color"], paddingBottom: 0, borderBottom: "none", marginBottom: 0 } : { paddingBottom: 0, borderBottom: "none", marginBottom: 0 }}>
                {t("offers.h1_title", null)}
            </h1>
            <span style={{ display: "none" }}>Height: {height}</span>
          </div>
          {acStylesObj["steps"] && acStylesObj["steps"] === 1 &&
          <div className="col">
            {renderSteps(1)}
          </div>}
        </div>
        <div className="mb-3 row-divider"> </div>
        <AvCheckerFormEmbed
            setActiveStep={activateStep}
            showJsonOutput={false}
            height={height}
            apiKey={apiKey}
            countryId={countryId}
            isBusiness={isBusiness}
            language={language}
            clientInlineStyles={acStylesObj}
            clientZipcode={clientZipcode}
            clientHouseNumber={clientHouseNumber}
            clientHouseNumberSuffix={clientHouseNumberSuffix}
            clientDomain={clientDomain}
            checkOtherAddressDisabled={checkOtherAddressDisabled}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ tenants, user, dispatch }) => ({ tenants, user, dispatch });

export default withRouter(withTranslation()(connect(mapStateToProps)(AvCheckEmbed)));
