import * as types from '../constants';
import {
  addDays, format as formatDate, isDate, isValid, parse, parseISO,
} from 'date-fns';
import {zonedTimeToUtc, format} from 'date-fns-tz';
import {printFormat} from 'iban';
import {parsePhoneNumberFromString as parsePhoneNumber} from 'libphonenumber-js';

//import pkg from 'date-fns-tz';
//const { utcToZonedTime, format, formatInTimeZone } = pkg;


//const { utcToZonedTime, format } = dateFnsTz;
//const zonedTimeToUtc = {};

const timeZones = {
  'nl': 'Europe/Paris', 'en': 'Europe/London', 'us': 'America/Los_Angeles'
};

const dateLocalesDateFormat = {
  'nl': 'dd-MM-yyyy',
  'en': 'MM-dd-yyyy',
  'us': 'MM/dd/yyyy'
};

const dateLocalesPath = {
  'nl': 'nl',
  'en': 'en-GB',
  'us': 'en-US'
};

export function getLocalesFormatByLang(lng) {
  if (lng) {
    return dateLocalesDateFormat[lng];
  }
  return false;
}

export function convertLocalTime(dateString) {
  let dt = new Date(dateString);
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
  return dt;
}

export function parseSavedDateTimeToLocalTimezone(dateTime, lng) {
  const timeZone = lng ? timeZones[lng] : 'Europe/Paris'; // e.g. America/Los_Angeles
  const utcDate = zonedTimeToUtc(dateTime, timeZone); // In June 10am in Los Angeles is 5pm UTC
  // noinspection CommaExpressionJS
  return (utcDate.toISOString(), timeZone); // post 2014-06-25T17:00:00.000Z, America/Los_Angeles
}

export function parseSavedDateTimeToHumanReadableLocalTimezone(dateTime, lng) {
  const timeZone = lng ? timeZones[lng] : 'Europe/Paris'; // e.g. America/Los_Angeles
  return zonedTimeToUtc(dateTime, timeZone); // In June 10am in Los Angeles is 5pm UTC
}

export function toJSONLocal (date) {
  let local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

export function toLocal (date) {
  let local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON();
}

export function parseDateToSaveFormatObject (dateStr) {
  console.log("parseDateToSaveFormatObject - dateStr ->", dateStr);
  console.log("parseDateToSaveFormatObject - dateStr(typeof) ->", typeof(dateStr));
  if (!dateStr || dateStr === "") {
    return "";
  }

  if (dateStr && isNaN(dateStr.getTime())) {
    return "";
  }

  let d;
  d = new Date(dateStr);

  return new Date(
      d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().
      split('T')[0];
}

export function parseDateToSaveFormat (dateStr) {
  console.log("parseDateToSaveFormat - dateStr ->", dateStr);
  console.log("parseDateToSaveFormat - dateStr(typeof) ->", typeof(dateStr));
  if (!dateStr || dateStr === "") {
    return "";
  }

  let d;
  d = new Date(dateStr);

  return new Date(
      d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().
      split('T')[0];
}

export function parseDateTimeToSaveFormat (dateStr) {
  if (dateStr) {
    let sqlDatetimeFromDateStr = new Date(
        new Date(dateStr).getTime() - new Date(dateStr).getTimezoneOffset() *
        60 * 1000).toJSON().slice(0, 19).replace('T', ' ');
    return sqlDatetimeFromDateStr;
  }
}

export function setToSaveDateMySQL (dateStr) {
  return parse(dateStr, 'yyyy-MM-dd', (new Date()));
}

export function getLocaleFormattedDate(date, lng, date_format) {
  if (date && lng) {
    const localesLng = require(
        `date-fns/locale/${dateLocalesPath[lng]}/index.js`);
    const sDate = new Date(date);
    const fDate = formatDate(sDate, date_format ? date_format : dateLocalesDateFormat[lng],
        {locale: localesLng});

    return fDate;
  }
  return false;
}

export function addDaysToFormattedDate(date, lng, days, date_format) {
  if (date && lng && days) {
    const fDate = getLocaleFormattedDate(date, lng, date_format);
    if (isValid(fDate) && parseInt(days) > 0) {
      const fDateDaysAdd = addDays(parseISO(fDate), parseInt(days));
      const fDateDaysAdded = formatDate(fDateDaysAdd, date_format ? date_format : dateLocalesDateFormat[lng]);

      if (isValid(fDateDaysAdded)) {
        return fDateDaysAdded;
      }
    }
  }
  return false;
}

export function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
}

export function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return date;
}

export function formatDateStrToUTC(dateString) {
  const date = new Date(dateString ? dateString : Date.now());
  return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
  );
}

export function formatLocalTimeZone(dateString) {
  if (isDate(dateString)) {
    return parseISO(dateString).toString();
  }
  return false;
}

export function formatSystemDateTimeUTC(dateString) {
  // Parse using location (browser locales) for offset / example format input => '2020-12-03 08:30:00'
  let loc = 'UTC';
  let s   = dateString ? new Date(dateString) : new Date(Date.now());
  let utcDate = zonedTimeToUtc(s, loc);
  return utcDate.toISOString();
}

export function formatNumber(name, data, val) {
  //console.log("priceFormatter - formatNumber - name ->", name);
  console.log("priceFormatter - formatNumber - data ->", data);
  console.log("priceFormatter - formatNumber - val ->", val);

  const getInt = parseInt(data);
  //const getInt = Number(data);
  const getFloat = Number.parseFloat(data);

  console.log("priceFormatter - formatNumber - getInt ->", getInt);
  console.log("priceFormatter - formatNumber - getFloat ->", getFloat);

  let   intLength = getInt.toString().length,
        newVal = (data !== null && data !== undefined) && (data !== '' || data === 0) ? Number.parseFloat(data) : null,
        value = val;
    
  if ((intLength > 7) && (name !== 'margin' && name !== 'quantity')) {
    const val = newVal.toString().split('. ');
    newVal = val[0].substring(0, 7);

    if (val[1]) {
      newVal = `${newVal},${val[1]}`
    }
  } else if (intLength > 6 && name === 'quantity') {
    const val = newVal.toString().split('.');
    newVal = val[0].substring(0, 6);

    if (val[1]) {
        newVal = `${newVal},${val[1]}`
    }
  } else if (name === 'margin') {
    if (newVal > 999.99) {
      newVal = 999.99;
      value = 999.99
    } else if (newVal < -999.99) {
      newVal = -999.99;
      value = -999.99
    }
  }

  if (isNaN(newVal)) newVal = null;
  if (isNaN(value)) value = null;

  return {
    type: types.FORMAT_NUMBER,
    newVal,
    value
  };
}

export function formatSeparators(data, showPercentSign) {
  let is_zero = false;
  let value;

  if (data === 0 || data === "0" || data === "0.00") {
    is_zero = true;
    value = 0;
  } else {
    value = parseFloat(data);
  }

  let formatted;
  if (showPercentSign && is_zero) {
    formatted = '0 %'
  } else {
    if (showPercentSign) {
      formatted = value ? value + ' %' : String.fromCharCode(8212);
    } else {
      formatted = value ? value : String.fromCharCode(8212);
    }
  }

  return {
    type: types.FORMAT_SEPARATORS,
    formatted
  };
}

export function formatIBAN(data) {
  return printFormat(data);
}

export function formatPhoneNr(data) {
  const phoneNumber = parsePhoneNumber(data, 'NL');
  if (phoneNumber) {
    return phoneNumber.formatNational();
  }
}

export const formatAmountLocale = (data, lng) => {
  if (!lng) lng = 'nl';
  if (lng === 'nl') {
    return (parseFloat(data)).toFixed(2).replace(/\./g, ',').replace(/\d(?=(\d{3})+,)/, '$&.');
  } else {
    return parseFloat(data).toFixed(2).replace(/\./g, ',').replace(/\d(?=(\d{3})+,)/, '$&.');
  }
};

export const formatAmount = (data) => data;

export const reverseToDotFormat = (data) => data.replace('.','').replace(',', '.');

export const reverseFormat = (data) => data;
