import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {ListGroup, ListGroupItem, Row, Col} from 'reactstrap';
import {
  withRouter, useHistory, useParams, useLocation,
} from 'react-router-dom';
import {mvLoaderToggleInv} from '../../../../redux/actions/mvLoaderInvoiceActions';
import {simulateTabClick} from "../../../../hooks/useSimulateTabClick";
import ScrollBar from 'react-perfect-scrollbar';
import {useTranslation, withTranslation} from 'react-i18next';
import {mvLoaderToggle} from '../../../../redux/actions/mvLoaderActions';
import {faTags, faBell } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {hideSidebarMenuInvoices} from '../../../../redux/actions/sidebarMenuActions';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {createSelector} from 'reselect';

const InvoiceList = ({
   invoiceId,
   relationId,
   data,
   setData,
   setSelectedItem,
   selectedItem,
   //invoiceListUpdatedDetails,
   invoiceList,
   isUpdated,
   setToggleForm,
   mvloaderInvoice,
   //invoice,
   relation,
   dispatch,
   //solrSearch,
   //hasLoaded,
   listFilterText
   //...props
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const {pathname} = useLocation();
  const params = useParams();
  const {param} = params;

  //console.log("listFilterText ->", listFilterText);

  const [locationKeysLoaded, setLocationKeysLoaded] = useState(true);
  const [active, setActive] = useState(selectedItem ? parseInt(selectedItem) : null); //selectedItem ? selectedItem : invoiceId ? invoiceId : null //selectedItem ? selectedItem : invoiceId
  //const [activeOnly, setActiveOnly] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [debugInfo, setDebugInfo] = useState(false); //process.env.REACT_APP_ENV !== "production"

  const relation_id = relation && relation.id ? relation.id : null;

  let lastVisitInvId = sessionStorage.getItem('lastVisitInvId');

  const setActivatedItem = (id) => {
    if (!isClicked) {
      setIsClicked(true);

      //sessionStorage.removeItem('lastVisitInvId');
      //dispatch({type: 'INVOICE', payload: null});

      sessionStorage.setItem('lastVisitInvId', id);

      dispatch(mvLoaderToggleInv(true));
      dispatch(hideSidebarMenuInvoices());

      if (selectedItem === id) {
        dispatch(mvLoaderToggleInv(false));
        return false;
      }
      setToggleForm(false);
      
      setSelectedItem(id);
      setActive(id);
      dispatch(mvLoaderToggleInv(false));
    }
  };

  useEffect(() => {
    if (selectedItem && relation && relation.invoiceList && relation.invoiceList.length > 0 && relation.invoiceList.some(i => i.id === parseInt(selectedItem))) {
      setData(relation.invoiceList);
      setActive(selectedItem);
      setToggleForm(false);

      // inv-list-item-${selectedItem}
      let focusItem = document.getElementById(`inv-list-item-${selectedItem}`);
      console.log("focusItem ->", focusItem);

    }
  }, [selectedItem]);

  useEffect(() => {
    const dataObj = data && data[0];

    if (isNaN(lastVisitInvId) && isNaN(selectedItem)) {
      if (!dataObj && relation && relation.invoiceList &&
          relation.invoiceList.length > 0) {
        history.push(`/relations/${relationId}/invoices/${parseInt(
            relation.invoiceList[0].id)}/`);

      } else {
        history.push(`/relations/${relationId}/invoices/${dataObj ? parseInt(
            dataObj.id) + "/" : ""}`);
      }

    } else {
      history.push(`/relations/${relationId}/invoices/${lastVisitInvId
          ? lastVisitInvId + '/'
          : selectedItem ? selectedItem + '/' : (dataObj
              ? dataObj.id + '/'
              : '')}`);
    }

    setIsClicked(false);

  }, [active]);

  useEffect(() => {
    // Updated this after error occured when you delete the last invoice & create a new one
    // this resulted in a infinite loop in if statement below when -> invoiceList && invoiceList.length > 0 && data && data.length > 0
    // setInvoiceList(data) is set in ListDetails on row 253
    // Maybe it's better to test if this dependency is actually needed
    if (invoiceList && invoiceList.length > 1 && data && data.length > 1) {
      if (invoiceList === data) {
        console.log("InvoiceListDetails (useEffect) compare - data equals invoiceList");
      } else {
        setData(invoiceList);
      }
    }
  }, [invoiceList]);

  useEffect(() => {
    let mounted = true;

    return history.listen(location => {
      const locationPathName = location.pathname;
      let invItem, classId;

      if (location && location.pathname) {
        invItem = location.pathname.split("/").pop();
        classId = location.pathname.split("/").reverse()[1];
      }

      if (history.action === 'POP') {
        if (invItem && invItem === 'invoices' && classId) {
          let itemClassId = 'inv-list-action-item-' + classId;
          if (document.getElementById('f2x-ls-det-invoices') && document.getElementById('f2x-ls-det-invoices').getElementsByClassName(itemClassId)) {
            let el = document.getElementById('f2x-ls-det-invoices').
                getElementsByClassName(itemClassId)[0];
            if (el) {
              simulateTabClick(el).
                  then(r => {
                    if (mounted) {
                      setLocationKeysLoaded(false);
                    }
                  });
            }
          }
        }
      } else {
        mounted = false;
      }
    });
  }, [locationKeysLoaded]);

  const ItemFormatter = ({id, relation_id, date, status_invoice, status, invoice_status, invoice_no, price, price_total, billing_run_id, isUpdated, reminder}) => {
    //console.log("Invoice list - status_invoice - processUpdateApi ->", date);
    return (
        <>
          <Row className="mb-1">
            <Col className="mv-text-bold text-truncate">
              {t("date_format", {date: date ? new Date(date) : ""})}
            </Col>
            <Col className="text-right">
              {status_invoice && status_invoice.status ? status_invoice.status.toUpperCase() : invoice_status && invoice_status === 20 ? 'OPEN' : invoice_no === null ? 'CONCEPT' : '' }
            </Col>
          </Row>
          <Row>
            <Col xs="5" className="text-truncate">
              {invoice_no === null ? String.fromCharCode(8212) : invoice_no}
              {id && debugInfo === true ? <span style={{ display: "block" }}>#{id} <br /> #{relation_id}</span> : ''}
            </Col>
            <Col className="text-right">
              {t("currency_format", {number: price ? Number(parseFloat(price)) : 0})} / {t("currency_format", {number: price_total ? Number(parseFloat(price_total)) : 0})}
            </Col>
          </Row>
          {(billing_run_id || reminder || status_invoice) &&
          <Row style={{ marginTop: "3px" }}>
            <Col style={{ textAlign: "right" }}>
              {billing_run_id && (
                  <OverlayTrigger placement={`right`} transition={false} overlay={
                    <Tooltip id={`tooltip-right`}>
                      <div className={`popover-html-contents`}>
                        <h3 className="white" color="white">{`Billing run invoice`}</h3>
                        <p>{`This invoice is created by billing run id ${billing_run_id}`}</p>
                      </div>
                    </Tooltip>}
                  >
                    <span
                        className="ml-2 billing-run-icon"
                        id={id}
                    >
                      <FontAwesomeIcon
                          className="f2x-mv-tabs-list-item-icon"
                          icon={faTags}
                      />
                    </span>
                  </OverlayTrigger>
              )}
              {reminder && (
                  <OverlayTrigger placement={`right`} transition={false} overlay={
                    <Tooltip id={`tooltip-right`}>
                      <div className={`popover-html-contents`}>
                        <h3 className="white" color="white">{`Reminder`}</h3>
                        <p>{`${reminder["reminder_status"]} ${t("date_format", {date: new Date(reminder.date)})}`}</p>
                      </div>
                    </Tooltip>}
                  >
                  <span
                      className="ml-2 billing-run-icon"
                      id={id}
                  >
                    <FontAwesomeIcon
                        className="f2x-mv-tabs-list-item-icon"
                        color="red"
                        icon={faBell}
                    />
                  </span>
                  </OverlayTrigger>
              )}
              {/*status_invoice && status_invoice.id && status_invoice.id === 50 && (
                  <OverlayTrigger placement={`right`} transition={false} overlay={
                    <Tooltip id={`tooltip-right`}>
                      <div className={`popover-html-contents`}>
                        <h3 className="white" color="white">{`Invoice status`}</h3>
                        <p>Invoice is paid</p>
                      </div>
                    </Tooltip>}
                  >
                  <span
                      className="ml-2 is-paid-icon"
                      id={id}
                  >
                    <FontAwesomeIcon
                        className="f2x-mv-tabs-list-item-icon"
                        color="green"
                        icon={faCheck}
                        style={{ opacity: "0.4"}}
                    />
                  </span>
                  </OverlayTrigger>
              )*/}
            </Col>
          </Row>}
        </> )
  };

  const focusItem = (el_id) => {
    let focusItem = document.getElementById(`inv-list-item-${el_id}`);
    if (focusItem) {
      focusItem.focus();
    }
  }

  const listFormatter = (item, isActive, index) => {
    if (isActive) {
      return <ListGroupItem style={{paddingBottom: "0.55rem"}} key={item.id} id={`inv-list-item-${item.id}`} tabIndex="-1"
                            className={`${isActive || item.id === parseInt(active) ? 'active' : ''} ${mvloaderInvoice || isClicked
                                ? 'disabled'
                                : ''} inv-list-action-item-${item.id}`}
                            action>
        <ItemFormatter {...item} isUpdated={isUpdated} data-focus={focusItem(item.id)} />
      </ListGroupItem>;
    } else {
      return <ListGroupItem style={{paddingBottom: "0.55rem"}} key={item.id} id={`inv-list-item-${item.id}`} tabIndex="-1"
                            className={`${mvloaderInvoice || isClicked
                                ? 'disabled'
                                : ''} inv-list-action-item-${item.id}`}
                            action onClick={() => setActivatedItem(item.id)}>
        <ItemFormatter {...item} isUpdated={isUpdated}/>
      </ListGroupItem>;
    }
  };

  if (data.length > 0) {
    return (<>
      <ScrollBar className="f2x-ls-det-sb sb-invoices col-row-bottom" options={{suppressScrollX: true}}>
        <ListGroup className={`f2x-ls-det`} id="f2x-ls-det-invoices">
        {data && data.length > 0 && data.filter((f) => {
             const filterTextUpper = listFilterText.toUpperCase();
             const descriptionMatches = f.description && f.description.toUpperCase().includes(filterTextUpper);
             const invoiceNoMatches = f.invoice_no && f.invoice_no.toUpperCase().includes(filterTextUpper);
             const dateMatches = f.date && f.date.includes(listFilterText);
             return descriptionMatches || invoiceNoMatches || dateMatches || listFilterText === '';
            }).map((r, i) => {
              const isActive = selectedItem
                  ? parseInt(selectedItem) === r.id
                  : i === 0;
              return listFormatter(r, isActive, i)
            })
          }
        </ListGroup>
      </ScrollBar>
    </>)
  } else {
    return (<div> </div>);
  }
};

const sidebarMenuSelector = createSelector(
    state => state.sidebarMenu,
    sidebarMenu => sidebarMenu
);

const mapStateToProps = ({solrSearch, sidebarMenu, mvloaderInvoice, dispatch, invoice, relation, loader}) => ({solrSearch, sidebarMenu, mvloaderInvoice, dispatch, invoice, relation, loader});

export default connect(mapStateToProps)(withTranslation()(withRouter(InvoiceList)));
