import React, { useEffect, useState } from "react";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {HelpCircle, Repeat, Info} from 'react-feather';

const TooltipLabel = (props) => {
  let setStyle = "";
  if (props.rightMargin) {
    setStyle = `right: "20px"`;
  }

  return (
    <OverlayTrigger placement={props.position} transition={false}
      overlay={
        <Tooltip id={`label-tooltip-${props.position}-${props.label}`}>
          <div className={`popover-html-contents`}>
            <p>{props.html}</p>
          </div>
        </Tooltip>
      }
    >
      <span className={`label-tooltip`} style={ props.rightMargin ? { right: "42px" } : {}}>
        <Info
            size="16"
            name={`label-tooltip-icon`}
            id={`label-tooltip-icon-${props.label}`}
            type="button"
            style={{display: "inline-block"}}
        />
      </span>
    </OverlayTrigger>
  );
};

export default TooltipLabel;
