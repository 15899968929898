import React from "react";
import { Check } from "react-feather";
import { GetRelPersons } from '../../../controllers/relations';
import { withTranslation } from 'react-i18next';
import PersonForm from "./Form";
import RemovePersonForm from "./remove/Form";
import UserForm from "../../users/Form";
import ResendEmail from "../../auth/ResendEmail";
import DeleteUser from "../../users/delete_users/Form";
import ParentTable from '../../../components/ParentTable';
import CONSTANTS from "../../../services/constants";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import * as types from '../../../redux/constants';
import { formatPhoneNr } from '../../../redux/actions/formatActions';
import { Helmet } from "react-helmet";

class Persons extends React.Component {
  constructor(props) {
    super(props);

    const relation_id = props.id;

    this.state = {
      isOpen: false,
      activeTab: 0,
      sizePerPage: 100
    };
  }

  defaultFormatter = (cell) => {
    return (
      <React.Fragment>
        {(cell === 1 || cell === true) &&
          <Check size={18} color="#5fc27e" className="check" />
        }
      </React.Fragment>
    )
  };

  formatEmpty = (cell) => {
    if (cell) {
      return cell;
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  phoneFormatter = (cell) => {
    if (cell) {
      return formatPhoneNr(cell);
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  formatDate = (cell) => {
    if (cell) {
      return <span className="date-format">{this.props.t("date_format",
        { date: new Date(cell) })}</span>
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  genderFormatter = (cell) => {
    if (cell && cell !== '') {
      if (cell === "M" || cell === "Male") {
        return "Male";
      } else if (cell === "F" || cell === "Female") {
        return "Female";
      } else if (cell === "O" || cell === "Other") {
        return "Other";
      } else {
        return "Other";
      }
    } else {
      return String.fromCharCode(8212);
    }
  };

  titleFormatter = (cell) => {
    if (cell && cell !== '') {
      return cell + ".";
    } else {
      return String.fromCharCode(8212);
    }
  };

  languageFormatter = (cell) => {
    if (cell && cell !== '') {
      switch (cell) {
        case 'en-US':
          return <img src={`/assets/flags/us.png`} width={20} height={20} alt={`US`} className="mr-2 rounded-circle" />;
        case 'en-GB':
          return <img src={`/assets/flags/en.png`} width={20} height={20} alt={`EN`} className="mr-2 rounded-circle" />;
        case 'it-IT':
          return <img src={`/assets/flags/it.png`} width={20} height={20} alt={`IT`} className="mr-2 rounded-circle" />;
        case 'fr-FR':
          return <img src={`/assets/flags/fr.png`} width={20} height={20} alt={`FR`} className="mr-2 rounded-circle" />;
        case 'de-DE':
          return <img src={`/assets/flags/de.png`} width={20} height={20} alt={`DE`} className="mr-2 rounded-circle" />;
        case 'es-ES':
          return <img src={`/assets/flags/es.png`} width={20} height={20} alt={`ES`} className="mr-2 rounded-circle" />;
        case 'nl-BE':
          return <img src={`/assets/flags/be.png`} width={20} height={20} alt={`BE`} className="mr-2 rounded-circle" />;
        case 'nl-NL':
          return <img src={`/assets/flags/nl.png`} width={20} height={20} alt={`NL`} className="mr-2 rounded-circle" />;
        default:
          return cell.toString();
      }
    } else {
      return <img src={`/assets/flags/nl.png`} width={20} height={20} alt={`NL`} className="mr-2 rounded-circle" />;
    }
  }

  genderIconFormatter = (cell) => {
    if (cell && cell !== '') {
      let gender = cell.toLowerCase();
      return (
        <span>
          {cell} &nbsp; <FontAwesomeIcon size="lg" color="#47bac1"
            className="table-icon-gender" icon={gender} />
        </span>
      )
    } else {
      return String.fromCharCode(8212);
    }
  };

  render() {
    const { id, sizePerPage, totalSize, t, titleUpdate, relation } = this.props;

    console.log("RelationDetails - relation ->", relation);

    return (
      <React.Fragment>
        {titleUpdate && <Helmet>
          <title>{titleUpdate}</title>
        </Helmet>}
        <ParentTable
          table="RelationPersons"
          keyField={"id"}
          id={id}
          selectRow={`row-person-${id}`}
          data={GetRelPersons}
          sizePerPage={sizePerPage ? parseInt(sizePerPage) : 100}
          rowClasses={`row-person-${id}`}
          columns={[
            {
              dataField: "title",
              text: "Title",
              sort: false,
              formatter: this.titleFormatter,
              headerStyle: { width: '6%' },
              attrs: {
                'data-label': 'Title'
              }
            },
            {
              dataField: "full_name",
              text: "Name",
              sort: false,
              headerStyle: { width: '12%' },
              attrs: {
                'data-label': 'Name'
              }
            },
            {
              dataField: "gender",
              text: "Gender",
              sort: false,
              formatter: this.genderFormatter,
              headerStyle: { width: '10%' },
              attrs: {
                'data-label': 'Gender'
              }
            },
            {
              dataField: "email",
              text: "Email",
              sort: true,
              headerStyle: { width: '20%' },
              attrs: {
                'data-label': 'Email'
              }
            },
            {
              dataField: "phone",
              text: "Phone",
              sort: true,
              formatter: this.phoneFormatter,
              headerStyle: { width: '10%' },
              attrs: {
                'data-label': 'Phone'
              }
            },
            {
              dataField: "mobile",
              text: "Mobile",
              sort: true,
              formatter: this.phoneFormatter,
              headerStyle: { width: '10%' },
              attrs: {
                'data-label': 'Mobile'
              }
            },
            {
              dataField: "user.username",
              text: "Username",
              sort: true,
              formatter: this.formatEmpty,
              headerStyle: { width: '12%' },
              attrs: {
                'data-label': 'Username'
              }
            },
            /*{
              dataField: "user.last_login",
              text: "Last login",
              sort: false,
              formatter: this.formatDate,
              headerStyle: { width: '7%' },
              attrs: {
                'data-label': 'Last login'
              }
            },*/
            {
              dataField: "primary",
              text: "Primary",
              classes: 'text-center primary-label',
              formatter: this.defaultFormatter,
              sort: true,
              headerStyle: { width: '8%', 'textAlign': 'center' },
              attrs: {
                'data-label': 'Primary'
              }
            },
            {
              dataField: "language.code",
              text: "Language",
              formatter: this.languageFormatter,
              sort: true,
              headerStyle: { width: '7%' },
              attrs: {
                'data-label': 'Language'
              }
            }
          ]}
          headerDropdownOpts={[{
            label: <FontAwesomeIcon color="grey" icon={faPlus} />,
            action: PersonForm
          }]}
          rowAction="edit"
          rowActionForm={PersonForm}
          rowDropdownOpts={[{
            action: 'Edit',
            label: 'Edit person',
            form: PersonForm
          }, {
            action: 'Remove',
            label: 'Remove person',
            form: RemovePersonForm,
            condition: {
              data: 'removable'
            }
          }, {
            action: 'Create',
            label: 'Create user',
            form: UserForm,
            condition: {
              data: 'user', // user object
              showIfEmpty: true // if user object is empty - show create user
            }
          }, {
            action: 'Edit',
            label: 'Edit user',
            form: UserForm,
            condition: {
              data: 'user'
            }
          }, {
            action: 'Resend',
            label: 'Reset password',
            form: ResendEmail,
            condition: {
              data: 'user'
            }
          }, {
            action: 'Delete',
            label: 'Delete user',
            form: DeleteUser,
            condition: {
              data: 'user',
              showIfEmpty: false
            }
          }]}
          wrapperClasses="table-persons"
          tab={CONSTANTS.relationDetailsTabs.persons}
          noSearch
          //offLoader
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(connect()(Persons)));
