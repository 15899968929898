import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Select from "react-select";
import moment from 'moment';
import _ from "lodash";
import NumberFormat from 'react-number-format';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Row,
  Col,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  NavItem,
  NavLink,
  Nav,
  TabPane, TabContent,
} from 'reactstrap';
import {
  GetProduct,
  AddProduct,
  UpdateProduct,
  GetProductTypeList,
  GetProductsBackendAPIs,
  GetProducts,
  GetProductHierarchy,
  GetProductHierarchyRelationTypes,
  AddProductHierarchy,
  UpdateProductHierarchy,
  RemoveProductHierarchy,
} from '../../../controllers/products';
import {OverlayTrigger, Tooltip, Dropdown} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {
  GetStatusList, RemoveSubscriptionLine,
} from '../../../controllers/subscriptions';
import {
  GetProductsList,
} from '../../../controllers/tenants';
import { GetVatCodes } from '../../../controllers/vat_codes';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate, validateBarcode } from "../../../redux/actions/validateActions";
import {
  formatNumber,
  parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import {Collapse} from "react-bootstrap";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import {PlusSquare, MinusSquare, MoreHorizontal, Trash2, CornerDownRight, AlertCircle, CheckCircle} from 'react-feather';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select';
import {GetUsers} from '../../../controllers/users';
import Swal from "sweetalert2";
import {withRouter} from 'react-router-dom';
import classnames from 'classnames';
import ReactJson from 'react-json-view';

import AddRelatedProduct from "./Add";
import ProductChildHierarchyList from "./ChildList";
//import AddChildRelatedProduct from "./AddChild";
//import TreeListRelatedProducts from "./TreeList";

let debug = false;
let show_debug_info = debug === true ? "inline-block" : "none";

const ProductHierarchyList = (props) => {
  const { product, loader, dispatch, hide, update, setProductHierarchy, selectedData } = props;

  const [hierarchies, setHierarchies] = useState([]); // hierarchies (complete list from database)
  const [productHierarchies, setProductHierarchies] = useState([]); // hierarchies (for chosen product id) - level 1
  const [productRelatedHierarchies, setProductRelatedHierarchies] = useState([]); // hierarchies (for related product id) - level 2
  const [productRestHierarchies, setProductRestHierarchies] = useState([]); // rest hierarchies (if not product id)
  const [productRestHierarchiesOpts, setProductRestHierarchiesOpts] = useState([]); // rest hierarchies product list (if not product id) - for dropdown mutual
  const [productRelatedTo, setProductRelatedTo] = useState([]); // hierarchies related to

  const [productHierarchyRelationTypesOpts, setProductHierarchyRelationTypesOpts] = useState([]); // hierarchies relation types
  const [productHierarchyRelationTypesOptsIsLoading, setProductHierarchyRelationTypesOptsIsLoading] = useState(false); // hierarchies relation types
  const [productHierarchyRelationTypesChildOpts, setProductHierarchyRelationTypesChildOpts] = useState([]); // hierarchies relation types
  const [productHierarchyRelationTypesMutualOpts, setProductHierarchyRelationTypesMutualOpts] = useState([]); // hierarchies relation types

  const [productsListOpts, setProductsListOpts] = useState([]); // hierarchies relation types
  const [rootListOpen, setRootListOpen] = useState(true); // show list by default (collapsible root list)

  const [rootFormOpen, setRootFormOpen] = useState(false); // show add (child) product form (collapsible root form)
  const [rootFormMutualOpen, setRootFormMutualOpen] = useState(false); // show add (mutual) product form (collapsible root form)
  const [rootFormJsonDataOpen, setRootFormJsonDataOpen] = useState(false); // show edit json data product form (collapsible root form)
  const [rootFormType, setRootFormType] = useState(null); // set type to show form (collapsible root form) - child | mutual | json_data
  const [childListOpen, setChildListOpen] = useState([]); // show list by default (child root list)

  // onclick open/close root form
  const rootFormAddCollapse = (e, formType) => {
    if (e) {
      e.preventDefault();
    }

    setRootFormOpen(false);
    setRootFormMutualOpen(false);
    setRootFormJsonDataOpen(false);

    // set form type
    if (formType) {
      setRootFormType(formType);
    }
    // set collapse forms
    if (formType === "child") {
      setRootFormOpen(!rootFormOpen); // add child relation
    } else if (formType === "json_data") {
      setRootFormJsonDataOpen(!rootFormJsonDataOpen); // add child relation
    } else if (formType === "mutual") {
      setRootFormMutualOpen(!rootFormMutualOpen); // add child relation
    }
  };

  // collapsible menu for root (selected product ID)
  const renderRootCollapseBtn = () => {
    if (!rootListOpen) {
      return (
        <PlusSquare
            size="18"
            className="hover-pointer mt-0 mb-1 product-border-list-plus"
            onClick={(e) => rootCollapse(e)}
            data-toggle="collapse"
            aria-controls="root-list-product-hierarchy"
            aria-expanded={rootListOpen}
            data-target="#root-list-product-hierarchy"
            type="button"
        />
      )
    } else {
      return (
        <MinusSquare
            size="18"
            className="hover-pointer mt-0 mb-1 product-border-list-plus"
            onClick={(e) => rootCollapse(e)}
            data-toggle="collapse"
            aria-controls="root-list-product-hierarchy"
            aria-expanded={rootListOpen}
            data-target="#root-list-product-hierarchy"
            type="button"
        />
      )
    }
  };
  // onclick open/close root list
  const rootCollapse = (e) => {
    e.preventDefault();
    setRootListOpen(!rootListOpen);
  };

  const updateRelatedChildProducts = async () => {
    //console.log("ProductHierarchyList - updateRelatedChildProducts - product ->", product);
    if (product && product.id) {
      const {ok, data} = await GetProductHierarchy(product.id);
      //console.log("ProductHierarchyList - updateRelatedChildProducts - GetProductHierarchy - ok, data ->", ok, data);
      if (ok && data.data) {
        let productHierarchyData = data.data; // hierarchies database data
        if (productHierarchyData && productHierarchyData.hierarchies && productHierarchyData.hierarchies.length > 0) {
          // set total list
          setHierarchies(productHierarchyData);
          console.log("Product hierarchy - list ->", productHierarchyData.hierarchies);
          // find level 1 by (selected product id) & set the level 1 list
          let selectedHierarchy = productHierarchyData.hierarchies.filter(itemInArray => itemInArray.product_id === product.id && (itemInArray.relation_type.id === 1 || itemInArray.relation_type.id === 2));
          setProductHierarchies(selectedHierarchy);
          setProductHierarchy(selectedHierarchy); // set for json view (tab hierarchy list)
          // find all where product id is not the selected product
          let restHierarchy = productHierarchyData.hierarchies.filter(itemInArray => itemInArray.product_id !== product.id);
          setProductRestHierarchies(restHierarchy);
        }
        if (productHierarchyData && productHierarchyData.related_to) {
          setProductRelatedTo(productHierarchyData.related_to);
        }
      }
    }
  };

  const renderRootDropdown = () => {
    let product_id = product && product.id ? product.id : selectedData && selectedData.id ? selectedData.id : "NULL";
    let debugStrId = `Root ID=${product_id}`;

    return (
        <React.Fragment>
          { product_id &&
          <UncontrolledDropdown
            className="ml-auto float-right"
            autoclose="true"
            style={{ marginRight: "20px"}}
          >
            <DropdownToggle
              className="tree-list-root-product-hierarchy"
              tag="a"
              size={'sm'}
              id={`dropdown-root-product-${product_id}`}
            >
              <MoreHorizontal size="18" className="hover-pointer" />
            </DropdownToggle>
            <DropdownMenu
                flip={true}
                right
            >
              <DropdownItem
                  id={`dropdown-root-add-child-${product_id}`}
                  data-action="add-parent-child"
                  onClick={(e) => rootFormAddCollapse(e, "child")}
              >
                Add parent-child relation
                <span className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                  ({debugStrId})
                </span>
              </DropdownItem>
              <DropdownItem
                  id={`dropdown-root-add-mutual-${product_id}`}
                  data-action="add-mutual"
                  onClick={(e) => rootFormAddCollapse(e, "mutual")}
              >
                Add mutual relation
                <span className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                  ({debugStrId})
                </span>
              </DropdownItem>
              <DropdownItem
                  id={`dropdown-root-json-data-${product_id}`}
                  data-action="edit"
                  onClick={(e) => rootFormAddCollapse(e, "json_data")}
              >
                Edit JSON data
                <span className={`ml-2`} style={{ display: debug, fontWeight: "normal", fontSize: "x-small" }}>
                  ({debugStrId})
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          }
        </React.Fragment>
    );
  };

  useEffect(() => {
    if (productHierarchies && productHierarchies.length > 0 && productRestHierarchies && productRestHierarchies.length > 0) {

      for (let i = 0; i < productHierarchies.length; i++) {
        productHierarchies[i]["children"] = []; // for relation type 1 or 2 = parent-child
        productHierarchies[i]["mutual_items"] = []; // for relation type 3 or 4 or 5 = mutual inc-exc

        let key = productHierarchies[i].product_id + '_' + productHierarchies[i].related_product_id + '_' + i;
        let collapse_key = 'child-' + productHierarchies[i].product_id + '-' + productHierarchies[i].related_product_id + '-' + i;
        // set child list item default state (close = false)
        if (!childListOpen[i]) {
          childListOpen[i] = [];
          childListOpen[i]['id'] = key;
          childListOpen[i]['collapse_id'] = collapse_key;
          childListOpen[i]['isOpen'] = false;
        }

        if (productRestHierarchies && productRestHierarchies.length > 0) {
          // set opts list for mutual products
          let mutualListOpts = [];
          productRestHierarchies.map(({ related_product_description, related_product_id }) => {
            return mutualListOpts.push({
              label: related_product_description,
              value: related_product_id,
            });
          });
          setProductRestHierarchiesOpts(mutualListOpts);

          let relatedHierarchyParentChild = productRestHierarchies.filter(
              itemInArray =>
                  itemInArray.product_id === productHierarchies[i].related_product_id &&
                  (itemInArray.relation_type.id === 1 || itemInArray.relation_type.id === 2)
          );

          // set children relations
          if (relatedHierarchyParentChild) {
            productHierarchies[i]["children"] = relatedHierarchyParentChild;
          }

          let relatedHierarchyMutualItems = productRestHierarchies.filter(
              itemInArray =>
                  itemInArray.product_id === productHierarchies[i].related_product_id &&
                  (itemInArray.relation_type.id === 3 || itemInArray.relation_type.id === 4 || itemInArray.relation_type.id === 5)
          );

          // set mutual relations
          if (relatedHierarchyMutualItems) {
            productHierarchies[i]["mutual_items"] = relatedHierarchyMutualItems;
          }
        }
      }
      setProductHierarchies(productHierarchies);
    }
  }, [productRestHierarchies]);

  useEffect(() => {
    updateRelatedChildProducts().then(r => console.log("ProductHierarchyList - Product selected is loaded with last changes!"));
  }, [product]);

  useEffect( () => {
      (async () => {
        if (!productHierarchyRelationTypesOptsIsLoading) {
          const [product_hierarchy_relation_types, products_list] = await Promise.all(
              [
                GetProductHierarchyRelationTypes(),
                GetProductsList(),
                //GetProducts({offset: 200}),
              ]);

          setProductHierarchyRelationTypesOptsIsLoading(true);

          let productHierarchyRelationTypesOpts = []; // all types
          let productHierarchyRelationTypesChildOpts = []; // parent-child types
          let productHierarchyRelationTypesMutualOpts = []; // mutual types

          if (product_hierarchy_relation_types.ok) {
            //set all types
            product_hierarchy_relation_types.data.data &&
            product_hierarchy_relation_types.data.data.map(
                ({description, id}) => {
                  return productHierarchyRelationTypesOpts.push({
                    label: description, value: id,
                  });
                });
            // set product hierarchy (all) relation types
            setProductHierarchyRelationTypesOpts(
                productHierarchyRelationTypesOpts);

            //set parent-child types
            product_hierarchy_relation_types.data.data &&
            product_hierarchy_relation_types.data.data.map(
                ({description, id}) => {
                  if (id === 1 || id === 2) {
                    return productHierarchyRelationTypesChildOpts.push({
                      label: description, value: id,
                    });
                  }
                });
            // set product hierarchy (parent-child) relation types
            setProductHierarchyRelationTypesChildOpts(
                productHierarchyRelationTypesChildOpts);

            //set mutual types
            product_hierarchy_relation_types.data.data &&
            product_hierarchy_relation_types.data.data.map(
                ({description, id}) => {
                  if (id === 3 || id === 4 || id === 5) {
                    return productHierarchyRelationTypesMutualOpts.push({
                      label: description, value: id,
                    });
                  }
                });
            // set product hierarchy (mutual) relation types
            setProductHierarchyRelationTypesMutualOpts(
                productHierarchyRelationTypesMutualOpts);
          }

          // get all products (select dropdown)
          let productsListOpts = [];
          if (product && product.id && products_list.ok) {
            products_list.data.data &&
            products_list.data.data.map(({description, id}) => {
              // remove current selected product from list
              if (parseInt(id) !== parseInt(product.id)) {
                return productsListOpts.push({
                  label: description ? description : "unknown product",
                  value: id,
                });
              }
            });
            // set the total product list without the current selected product
            setProductsListOpts(productsListOpts);
          }
        }
        setProductHierarchyRelationTypesOptsIsLoading(false);

      })();
  }, []);

  return (
    <div className="product-hierarchy product-hierarchy-list-tree">
      <Row>
        {/* Hierarchy selected product list (children) */}
        <Col className="mb-4 col-divider-hierarchy">
          <div className="tree-view-content-root mb-1">
            {/* render mutual icon */}


            {/* add new product (root) relation */}
            {renderRootDropdown()}
            {/* collapse list */}
            {renderRootCollapseBtn()}

            <span className={`ml-2`} style={{ fontSize: "medium" }}>
              {product && product.description ? product.description : ""}
              <i
                  className={`ml-2`}
                  style={{ display: show_debug_info, fontWeight: "normal", fontSize: "x-small"  }}
              >
                (ID ={product && product.id ? product.id : selectedData && selectedData.id ? selectedData.id : ""})
              </i>
            </span>
          </div>

          {/* form for adding new relations to root level */}
          <div className="add-product-root-form">
            <AddRelatedProduct
              rootFormOpen={rootFormOpen}
              setRootFormClose={rootFormAddCollapse}
              rootFormMutualOpen={rootFormMutualOpen}
              setRootFormMutualOpen={rootFormAddCollapse}
              rootFormJsonDataOpen={rootFormJsonDataOpen}
              setRootFormJsonDataOpen={rootFormAddCollapse}
              productHierarchies={productHierarchies}
              productRelatedTo={productRelatedTo}
              product={product}
              loader={loader}
              dispatch={dispatch}
              hide={hide}
              update={update}
              productsListOpts={productsListOpts}
              productRestHierarchiesOpts={productRestHierarchiesOpts} // product list for mutual relations
              rootFormType={rootFormType}
              productHierarchyRelationTypesOpts={productHierarchyRelationTypesOpts}
              productHierarchyRelationTypesChildOpts={productHierarchyRelationTypesChildOpts}
              productHierarchyRelationTypesMutualOpts={productHierarchyRelationTypesMutualOpts}
              updateRelatedChildProducts={updateRelatedChildProducts}
            />
          </div>

          <div className="tree-view-content-wrapper mt-1">
            {productHierarchies && productHierarchies.length > 0 ? (
              <ul
                className={`tree tree-root collapse${rootListOpen ? ` show` : ``}`}
                id="root-list-product-hierarchy"
              >
                <ProductChildHierarchyList
                  product={product}
                  loader={loader} dispatch={dispatch}
                  hide={hide}
                  update={update}
                  childListOpen={childListOpen}
                  setChildListOpen={setChildListOpen}
                  productHierarchies={productHierarchies}
                  debug={show_debug_info}
                  productsListOpts={productsListOpts}
                  productRestHierarchies={productRestHierarchies}
                  productRestHierarchiesOpts={productRestHierarchiesOpts}
                  productHierarchyRelationTypesOpts={productHierarchyRelationTypesOpts}
                  productHierarchyRelationTypesChildOpts={productHierarchyRelationTypesChildOpts}
                  productHierarchyRelationTypesMutualOpts={productHierarchyRelationTypesMutualOpts}
                  updateRelatedChildProducts={updateRelatedChildProducts}
                />
              </ul>
            ) : (
              <ul
                  className={`tree tree-root collapse${rootListOpen ? ` show` : ``}`}
                  id="root-list-product-hierarchy"
              >
                <li>No products found.</li>
              </ul>
            )}
          </div>
        </Col>


        {/* Selected product is related to (parent) */}
        <Col md="5" xs="auto">
          <p className="mb-1">
            {productRelatedTo && productRelatedTo.length > 0 ? (
              <AlertCircle
                  color={`red`}
                  size="26"
                  className="hover-pointer mt-0 mb-1 mr-2"
              />
            ) : (
              <CheckCircle
                  color={`green`}
                  size="26"
                  className="hover-pointer mt-0 mb-1 mr-2"
              />
            )}
            {productRelatedTo && productRelatedTo.length > 0 ? `Is related to:` : `Is not related to other products.`}
          </p>
          {productRelatedTo && productRelatedTo.length > 0 &&
          <ul className="tree pl-1" style={{ listStyle: "none" }}>
            {productRelatedTo.map((item, i) => {
            return (
            <li key={`related-to-product-${i}`}>
              {/* render related to products */}
              <CornerDownRight
                color={`grey`}
                size="18"
                className="hover-pointer mt-0 mb-1 ml-2 product-border-list-plus"
              />
              <span className="text-truncate ml-2">
                <b>{item.product_description}</b> <small>(ID: {item.product_id})</small>
                <i className={`ml-2`} style={{ display: show_debug_info, fontWeight: "normal", fontSize: "x-small"  }}>(ID ={item.product_id})</i>
                <br />
                <span className={`ml-5`}>
                  <i className={`mr-1`}>as type:</i> {item.relation_type && item.relation_type.description ? item.relation_type.description : ""}
                </span>
              </span>
            </li>
            )})}
          </ul>
          }
        </Col>
      </Row>
      <div className="border-wide"> </div>
      <div className="form-row w-100 modal-like-btns justify-content-between">
        <span className="btn btn-danger" onClick={hide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    </div>
  );
};

export default ProductHierarchyList;
