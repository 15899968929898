import React from 'react';
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { loaderToggle } from '../../../../redux/actions/loaderActions';
import Toast from "../../../../components/Toast";
import {RemoveBillingRunDate} from '../../../../controllers/tenants';
import ApiService from '../../../../services/apiService';

const DownloadApi = new ApiService(),
    DownloadAPI = DownloadApi.downloadAPI;

class DownloadBillingRunForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: null,
      totalSize: null,
      data: null
    };
  }

  componentDidMount() {
    const { loader, dispatch, selectedData } = this.props;
    // check if download file exists
    if (selectedData && selectedData['dd_file'] && selectedData['billing_run_id']) {
      // explode dd_file url to get the filename
      const fileName = selectedData['dd_file'].split("/").pop();
      if (fileName && selectedData['billing_run_id']) {
        const apiDownloadURL = 'tenants/dd_file/'+ selectedData['billing_run_id'];
        if (!loader && apiDownloadURL) {
          dispatch(loaderToggle(true));

          (async () => {
            await DownloadAPI(apiDownloadURL, 'blob', fileName).then(r => {

              this.setState({
                loading: false
              });

              if (r.data) {
                const {data, total} = r.data;
                this.setState({
                  totalSize: total, data: data
                });
              }

            }).catch(error => {
              console.log('Error fetching data - response: ', error);
              this.setState({
                errors: error, loading: false
              });
              Toast.fire({title: error, icon: 'warning'});

            });

          })();
        } else {
          Toast.fire({title: 'File not found', icon: 'warning'}).then(r => console.log('Error response: ', r));

        }
        dispatch(loaderToggle(false));

      } else {
        Toast.fire(
            {title: '`dd_file` or `filename` not found', icon: 'warning'}).
            then(r => console.log('Error response: ', r));

      }
    } else {
      Toast.fire(
          {title: '`dd_file` or `billing_run_id` not found', icon: 'warning'}).
          then(r => console.log('Error response: ', r));

    }
    dispatch(loaderToggle(false));

  }

  render() {
    return (
        <React.Fragment>
        </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader
}))(DownloadBillingRunForm);