import * as types from "../constants";

export default function reducer(state = null, actions) {
  const { payload, type } = actions;
  switch (type) {
    case types.RELATION_MV_PATH: 
      return Object.assign({}, payload);
      default:
      return state;
  }
}