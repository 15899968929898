import React from "react";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";

class Error extends React.Component {
  render() {
    const { error } = this.props

    return(
      <div className="error-page">
        <p className="h1">Oops!</p>
        <h1 className="display-1 font-weight-bold">
          { error && error.status ? error.status : "" }
        </h1>
        <p className="h2 font-weight-normal mt-3 mb-4">
          { error && error.statusText ? error.statusText : "Something went wrong." }
        </p>
        <Link to="/">
          <Button color="primary" size="lg">
            Return to dashboard
          </Button>
        </Link>
      </div>
    )
  }
}

export default Error;
