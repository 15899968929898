import React, {useEffect, useState, useRef} from 'react';
import { connect } from "react-redux";
import ReactDOMServer from 'react-dom/server';
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Row,
    Col,
    Form,
    Input,
    Label,
    CustomInput,
    UncontrolledCollapse,
} from 'reactstrap';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate } from "../../../redux/actions/validateActions";
import Toast from "../../../components/Toast";
import {useTranslation, withTranslation} from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../../redux/actions/formatActions';
import ReactQuill from 'react-quill';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {updateError} from '../../../redux/actions/errorActions';
import FormElementBuilder from '../../../components/form_builder/FormElementBuilder';
import OfferPersonal from '../../../components/offer/Personal';
import OfferBank from '../../../components/offer/Bank';
import {
    faCaretDown,
    faCaretUp,
    faCaretLeft,
    faArrowLeft,
    faArrowCircleDown,
    faArrowCircleUp,
    faAngleLeft,
    faAngleRight,
    faArrowCircleLeft,
} from '@fortawesome/free-solid-svg-icons';
import {DatePickerField} from "../../../components/form_builder/FormElements";
import {ErrorMessage} from "formik";

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const SelectElement = ({...props}) => {
    console.log("SelectElement - props ->", props);

    const { selectedData, dynamicFieldData, setStepFormData } = props;
    console.log("SelectElement - setStepFormData ->", setStepFormData);

    const [elementKey, setElementKey] = useState("");
    const [label, setLabel] = useState("");
    const [options, setOptions] = useState([]);
    const [inlineStyle, setInlineStyle] = useState("");
    const [userInputData, setUserInputData] = useState(selectedData ? selectedData : {});

    const { t } = useTranslation();

    const formatCurrency = (price) => {
        let floatCell = parseFloat(price ? price : 0);
        let floatStyle = floatCell && floatCell < 0 ? 'float-negative' : floatCell && floatCell > 0 ? 'float-positive' : 'float-null';
        return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(floatCell) })}</span>
    };

    const renderInputField = (name, value) => {
        if (name) {
            return (
                <div className={`input-dynamic-data-container mt-2 mb-1 ml-4`}>
                    <input
                        name={name}
                        className="input-dynamic-data form-control"
                        value={userInputData && userInputData[name] ? userInputData[name] : value ? value : ""}
                        onChange={(e) => handleInputChange(e, name)}
                    />
                </div>
            )
        }
        return "";
    };

    const handleInputChange = ({ target: { name, value } }) => {
        console.log("SelectElement - handleInputChange - name ->", name);
        console.log("SelectElement - handleInputChange - value ->", value);

        let selectedData = Object.assign({}, userInputData);
        selectedData[name] = value;

        setUserInputData(selectedData);
    };

    const renderDynamicData = (data) => {
        console.log("SelectElement - renderDynamicData - data ->", data);

        if (data) {
            if (data.element === "input") {
                return renderInputField(data.name, data.value);
            }
        }
    };

    const selectChangeHandler = (e, name, data) => {
        console.log("SelectElement - radioChangeHandler - e ->", e);
        console.log("SelectElement - radioChangeHandler - name ->", name);
        console.log("SelectElement - radioChangeHandler - name ->", data);

        let selectedData = Object.assign({}, userInputData);
        //selectedData[name] = value;

        setUserInputData(selectedData);
    };

    useEffect(() => {
        console.log("SelectElement - useEffect - props ->", props);

        const { name, options, style, label, dynamicFieldData } = props;
        //const { name, options, style, dynamicFieldData } = props;

        if (name && options) {
            setElementKey(name);
            setLabel(label ? label : false);
            setOptions(options ? options : []);
            setInlineStyle(style ? style : "");
        }
    }, []);

    return (
        <div
            role="group"
            aria-labelledby={`${elementKey}-select-group`}
            id={elementKey}
            className={`${inlineStyle}`}
        >
            <div className="group-select">
                {label &&
                <Label className="dynamic-form-element-label">
                    <strong>{label}</strong>
                </Label>
                }
                <Select
                    className="react-select-container mt-0"
                    classNamePrefix="react-select"
                    name={elementKey}
                    id={`id-${elementKey}`}
                    placeholder="Select..."
                    withoutEmptyOption={true}
                    options={options}
                    hideSelectedOptions={true}
                    //value={selectedValue}
                    onChange={(e) => selectChangeHandler(e)}
                    //onChange={(e) => props.onChange(e, elementKey, options)}
                    isMulti={false}
                    isClearable={true}
                />
            </div>
        </div>
    );
}

export default withRouter(connect()(SelectElement));