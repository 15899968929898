import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Swal from "sweetalert2";
import { loaderToggle } from '../../../redux/actions/loaderActions';
import { RemovePlanLine } from '../../../controllers/plans';

class RemovePlanLineForm extends React.Component {
  componentDidMount() {
    const { loader, selectedData, dispatch, update, hide } = this.props;

    Swal.fire({
      customClass: {
        container: 'has-cancel',
      },
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this plan line?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(res => {
        if (res.value) {
            if (!loader) {
              (async () => {
                    dispatch(loaderToggle(true));

                    const getData = await RemovePlanLine(selectedData.id);

                    if (getData.ok) {

                      dispatch(loaderToggle(false));
                      await Swal.fire('Success!', 'Removed successfully',
                          'success');
                      update('plan_line_update');
                      hide()
                    } else {
                      dispatch(loaderToggle(false));
                      hide()
                    }
                })();
            }
        } else {
          dispatch(loaderToggle(false));
          hide();
        }
    })
  }

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader
}))(withRouter(RemovePlanLineForm));
