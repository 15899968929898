import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import Select from 'react-select';
import DatePicker, {registerLocale,setDefaultLocale,getDefaultLocale} from 'react-datepicker';
import { format as formatDate, isDate, subMonths } from "date-fns";
import {
  Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row, Table,
} from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';

import {
  CreatePainDDFile,
  GenerateInvoicesQueueJob, // generate
  GetInvoiceStats,
  GetTenantBillingRunDates,
  SendInvoiceEmailsQueueJob,
  ValidateIBANs,
  GenerateInvoicesConcept // finalize
} from '../../../controllers/tenants';
import {AddBillingRun} from '../../../controllers/billing_runs';
import {loaderToggle} from '../../../redux/actions/loaderActions';
import {validateDate} from '../../../redux/actions/validateActions';
import {ProcessCdr} from '../../../controllers/finance_files';
import {
  formatNumber, parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import Toast from '../../../components/Toast';
import NavTabsFinance from './NavTabsFinance';
import FileUpload from '../../../components/FileUpload';
import ProcessedMessage from '../../../components/ProcessedMessage';
import {withTranslation} from 'react-i18next'; // import all locales we need
import { nl, enGB, enUS } from "date-fns/locale";
import {withRouter} from 'react-router-dom';
import ApiService from '../../../services/apiService';
import {DownloadSingleTermsConditionsAttachment} from '../../../controllers/terms_conditions'; // import all locales we need

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const dateLocales = {
  'nl': nl,
  'en': enGB,
  'us': enUS
};

const dateLocalesPath = {
  'nl': 'nl',
  'en': 'en-GB',
  'us': 'en-US'
};

const Api = new ApiService(),
    GetAPI = Api.getAPI;

const DownloadApi = new ApiService(),
    DownloadAPI = DownloadApi.downloadAPI;

class Finance extends React.Component {
  constructor(props) {
    super(props);

    const { generateInvoiceDateOpts, selectedData } = props;
    const {t} = this.props;

    this.state = {
      tenant: {
        id: '',
        name: '',
      },

      // Add billing run
      billing_run: {
        date: '',
        billing_run_id: ''
      },

      // Generate invoices
      generate_invoice: {
        date: '',
        billing_run_id: ''
      },
      generateInvoiceDateOpts: [],
      generateInvoiceSection: {
        getGenerateInvoiceButton: false,
        sendEmailInvoiceButton: false,
        finalizeInvoiceButton: false,
        generate_invoice_busy: false,
        section_sending: false,
        buttonSection: false,
        changeBilling: true,
        section_generating: false,
        section_finalizing: false,
        section_dd_file: false,
        response: []
      },

      // finalize invoices
      finalize_invoice: {
        date: '',
        billing_run_id: ''
      },
      finalizeInvoiceDateOpts: [],
      finalizeInvoiceSection: {
        getGenerateInvoiceButton: true,
        sendEmailInvoiceButton: false,
        finalizeInvoiceButton: false,
        finalize_invoice_busy: false,
        finalize_invoice_failed: false,
        buttonSection: false,
        changeBilling: true,
        section_sending: false,
        section_generating: false,
        section_finalizing: false,
        section_dd_file: false,
        response: []
      },

      // Send invoice email
      invoice_email: {
        date: '',
        billing_run_id: ''
      },
      invoiceEmailDateOpts: [],
      invoiceEmailSection: {
        getGenerateInvoiceButton: false,
        sendEmailInvoiceButton: false,
        finalizeInvoiceButton: false,
        invoice_email_busy: false,
        invoice_email_failed: false,
        buttonSection: false,
        changeBilling: true,
        section_sending: false,
        section_generating: false,
        section_finalizing: false,
        section_dd_file: false,
        response: []
      },
      invoiceEmailInvoiceStats: {},

      // Check IBAN
      ibanCheck: {
        showButton: true
      },

      // Create direct debit XML file
      invoice: {
        user_id: '',
        date: '',
        billing_run_id: ''
      },
      createPainXMLDateOpts: [],
      createPainXMLSection: { // => download section
        getGenerateInvoiceButton: false,
        buttonSection: false,
        changeBilling: true,
        section_generating: false,
        section_dd_file: false,
        section_stats: false,
        isBusyCreating: false,
        isFailedCreating: false,
        isDoneCreating: false,
        responseDdFileCreate: [],
        responseDdFileFail: [],
        responseDdFileDone: []
      },
      createPainXMLInvoiceStats: {},
      showPainXMLSectionDownload: false,

      totalSize: null,
      data: null
    }
  }

  handleDownload = (e, data) => {
    const { loader, dispatch } = this.props;
    console.log(data);
    if (data && data['id']) {
      const fileName = data['dd_file'].split("/").pop();
      const apiDownloadURL = 'tenants/dd_file/'+ data['id'];
      if (!loader && apiDownloadURL && fileName) {
        dispatch(loaderToggle(true));

        (async () => {
          await DownloadAPI(apiDownloadURL, 'blob', fileName).then(r => {
            if (r.data) {
              const {data, total} = r.data;
              this.setState({
                totalSize: total,
                data: data
              });
            }
          }).catch(error => {
            console.log('Error fetching data - response: ', error);
            Toast.fire({title: error, icon: 'warning'});

          });

        })();
      } else {
        Toast.fire({title: 'File not found', icon: 'warning'}).then(r => console.log('Error response: ', r));

      }
      dispatch(loaderToggle(false));

    } else {
      Toast.fire(
          {title: '`dd_file` or `filename` not found', icon: 'warning'}).
          then(r => console.log('Error response: ', r));

    }
  };

  handleInputChange({ target: { name, value } }) {
    let invoice = Object.assign({}, this.state.invoice);
    invoice[name] = value;
    this.setState({ invoice })
  }

  handleSelectChangeCreatePainXML = (name, value) => {
    let createPainXMLInvoiceStats = Object.assign({}, this.state.createPainXMLInvoiceStats);
    createPainXMLInvoiceStats = {};

    let invoice = Object.assign({}, this.state.invoice);
    invoice.date = value;

    this.setState({
      invoice,
      createPainXMLInvoiceStats
    })
  };

  getLocale = (locale) => {
    const dateLocalesPath = {
      'nl': 'nl',
      'en': 'en-GB',
      'us': 'en-US'
    };

    require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
  };

  handleSelectChangeGenerateInvoice = (name, value) => {
    let generate_invoice = Object.assign({}, this.state.generate_invoice);

    generate_invoice.date = value;
    this.setState({
      generate_invoice
    })
  };

  handleSelectChangeFinalizedInvoice = (name, value) => {
    let finalize_invoice = Object.assign({}, this.state.finalize_invoice);

    finalize_invoice.date = value;
    this.setState({
      finalize_invoice
    })
  };

  handleSelectChangeSendInvoiceEmail = (name, value) => {
    let invoice_email = Object.assign({}, this.state.invoice_email);

    invoice_email.date = value;
    this.setState({
      invoice_email
    })
  };

  handleDatePickerChangeAddBillingRun(name, date) {
    let billing_run = Object.assign({}, this.state.billing_run);
    const { newDate } = this.props.dispatch(validateDate(date));

    billing_run[name] = parseDateToSaveFormat(newDate);
    this.setState({ billing_run })
  }

  handleFormSubmitAddBillingRun(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;

    if (!loader) {
      dispatch(loaderToggle(true));

      (async () => {
        const { date } = this.state.billing_run;
        const { tenant } = this.state;
        const { user } = this.state;
        const params = {
          tenant_id: tenant && tenant.id ? tenant.id : 7, // default 7 => fiber
          date: parseDateToSaveFormat(date),
          status_id: 0
        };

        const { ok, data } = await AddBillingRun(params);
        if (ok && data) {
          let billing_run = Object.assign({}, this.state.billing_run);
          billing_run.date = data.date;
          billing_run.billing_run_id = data.id;

          this.setState({
            billing_run
          })
        }
        this.initData();

      })();
      dispatch(loaderToggle(false));

      Toast.fire({
        title: 'Billing run saved.',
        icon: 'info'
      });
    }
  }

  handleFormSubmitGenerateInvoices(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;

    let generateConceptInvoices = Object.assign({}, this.state.generateConceptInvoices);
    generateConceptInvoices.showButton = false;

    this.setState({
      generateConceptInvoices
    });

    if (!loader) {
      dispatch(loaderToggle(true));
      (async () => {
        const { date, billing_run_id } = this.state.generate_invoice;
        const { tenant } = this.state;
        const { user } = this.state;
        const params = {
          tenant_id: tenant && tenant.id ? tenant.id : 7,
          date: date,
          billing_run_id: billing_run_id,
          type: 1
        };

        const { ok } = await GenerateInvoicesQueueJob(params);
        if (ok) {
          let generateInvoiceSection = Object.assign({}, this.state.generateInvoiceSection);
          generateInvoiceSection.getGenerateInvoiceButton = false;
          generateInvoiceSection.section_sending = true;
          generateInvoiceSection.buttonSection = false;
          generateInvoiceSection.changeBilling = false;
          generateInvoiceSection.section_generating = true;

          this.setState({
            generateInvoiceSection
          });
        }
      })();
      dispatch(loaderToggle(false));
      this.initData();

    }
  }

  handleGenerateConceptInvoices(e) { // => finalize invoices
    e.preventDefault();

    const { dispatch, loader } = this.props;

    if (!loader) {
      dispatch(loaderToggle(true));
      (async () => {
        const { tenant } = this.state;
        const { date, billing_run_id } = this.state.finalize_invoice;

        const params = {
          tenant_id: tenant && tenant.id ? tenant.id : 7,
          date: date.label,
          billing_run_id: billing_run_id,
          type: 1
        };
        const {ok, data} = await GenerateInvoicesConcept(params); // set concept invoices to final status
        if (ok) {
          let finalizeInvoiceSection = Object.assign({}, this.state.finalizeInvoiceSection);
          finalizeInvoiceSection.getGenerateInvoiceButton = false;
          finalizeInvoiceSection.finalizeInvoiceButton = false;
          finalizeInvoiceSection.section_sending = true;
          finalizeInvoiceSection.buttonSection = false;
          finalizeInvoiceSection.changeBilling = false;
          finalizeInvoiceSection.section_generating = true;
          finalizeInvoiceSection.section_finalizing = true;

          this.setState({
            finalizeInvoiceSection
          });
        }
        dispatch(loaderToggle(false));
        this.initData();

      })();
    }
  }

  handleFormSubmitGetInvoiceDetails(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;

    if (!loader) {
      dispatch(loaderToggle(true));

      const { id } = this.state.tenant;
      const { date, billing_run_id } = this.state.invoice_email;

      if (date.label && billing_run_id) {
        (async () => {
          const params = {
            tenant_id: id,
            date: date.label,
            billing_run_id: billing_run_id,
            type: 1
          };

          const { ok, data } = await GetInvoiceStats(params);
          if (ok) {
            let invoiceEmailInvoiceStats;
            invoiceEmailInvoiceStats = data.data;

            let invoiceEmailSection = Object.assign({}, this.state.invoiceEmailSection);
            invoiceEmailSection.getGenerateInvoiceButton = false;
            invoiceEmailSection.section_sending = true;
            invoiceEmailSection.buttonSection = true;
            invoiceEmailSection.changeBilling = true;
            invoiceEmailSection.section_generating = false;
            invoiceEmailSection.sendEmailInvoiceButton = false;

            this.setState({
              invoiceEmailInvoiceStats,
              invoiceEmailSection
            });
          }
          dispatch(loaderToggle(false));

        })();
      } else {
        dispatch(loaderToggle(false));

        Toast.fire({title: 'Please select a billing run first.', icon: 'error'}).then(r => { console.log(r) })
      }
    }
  }

  handleChangeBillingSendInvoiceEmail(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;

    if (!loader) {
      dispatch(loaderToggle(true));
      let invoice_email = Object.assign({}, this.state.invoice_email);
      invoice_email.date = null;

      let invoiceEmailSection = Object.assign({}, this.state.invoiceEmailSection);
      invoiceEmailSection.getGenerateInvoiceButton = true;
      invoiceEmailSection.section_sending = false;
      invoiceEmailSection.buttonSection = false;
      invoiceEmailSection.changeBilling = false;
      invoiceEmailSection.section_generating = false;
      invoiceEmailSection.sendEmailInvoiceButton = true;
      invoiceEmailSection.stats = true;

      this.setState({
        invoice_email,
        invoiceEmailSection
      });
      dispatch(loaderToggle(false));

    }
  }

  handleSendInvoiceEmail(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;
    if (!loader) {
      dispatch(loaderToggle(true));

      const { date, billing_run_id } = this.state.invoice_email;
      const { user_id } = this.state.invoice;
      const { user } = this.state;
      const { tenant } = this.state;

      if (date.label && billing_run_id) {
        (async () => {
          const params = {
            user_id: user_id,
            date: date.label,
            billing_run_id: billing_run_id,
            type: 1
          };
          const {ok} = await SendInvoiceEmailsQueueJob(params);

          if (ok) {
            let invoiceEmailSection = Object.assign({},
                this.state.invoiceEmailSection);
            invoiceEmailSection.getGenerateInvoiceButton = false;
            invoiceEmailSection.section_sending = true;
            invoiceEmailSection.buttonSection = false;
            invoiceEmailSection.changeBilling = false;
            invoiceEmailSection.section_generating = true;

            this.setState({
              invoiceEmailSection
            })
          }
          dispatch(loaderToggle(false));
          this.initData();

        })();
      } else {
        dispatch(loaderToggle(false));

        Toast.fire({title: 'Please select a billing run first.', icon: 'error'}).then(r => { console.log(r) })
      }
    }
  }

  handleIBANCheck(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;

    let ibanCheck = Object.assign({}, this.state.ibanCheck);
    ibanCheck.showButton = false;

    this.setState({
      ibanCheck
    });

    if (!loader) {
      dispatch(loaderToggle(true));

      (async () => {
        const { id, name } = this.state.tenant;
        const { ok, data } = await ValidateIBANs(id);
        if (ok) {
          if (data.size > 0) {
            let filename = _.kebabCase(name) + "-invalid-ibans-" + moment().format("YYYYDDMMHHmmss") + ".csv";

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([data]));
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click()
          } else {
            await Toast.fire({title: 'No invalid IBANs found.', icon: 'info'})
          }
        }
        let ibanCheck = Object.assign({}, this.state.ibanCheck);
        ibanCheck.showButton = true;

        this.setState({
          ibanCheck
        });
        dispatch(loaderToggle(false));

      })();
    }
  }

  handleGetInvoicesCreatePainXML(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;
    if (!loader) {

      const { date, billing_run_id } = this.state.invoice;
      const { user_id } = this.state.invoice;
      const { id } = this.state.tenant;

      dispatch(loaderToggle(true));
      if (billing_run_id) {
        (async () => {
          const params = {
            tenant_id: id,
            type: 1,
            date: date.label,
            billing_run_id: billing_run_id,
            user_id: user_id
          };

          const { ok, data } = await GetInvoiceStats(params);
          if (ok) {
            let createPainXMLInvoiceStats = Object.assign({}, this.state.createPainXMLInvoiceStats);
            createPainXMLInvoiceStats = data.data;

            this.setState({
              createPainXMLInvoiceStats
            });

            let createPainXMLSection = Object.assign({}, this.state.createPainXMLSection);
            createPainXMLSection.getGenerateInvoiceButton = false;
            createPainXMLSection.section_dd_file = true;
            createPainXMLSection.section_stats = true;
            createPainXMLSection.buttonSection = true;
            createPainXMLSection.changeBilling = true;
            createPainXMLSection.section_generating = false;

            this.setState({
              createPainXMLSection
            });
          }
          dispatch(loaderToggle(false));

        })();
      } else {
        dispatch(loaderToggle(false));

        Toast.fire(
            {title: 'Please select a billing run first.', icon: 'error'} ).
            then(r => console.log(r))
      }
    }
  }

  handleGenerateBatchFile(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;
    if (!loader) {
      dispatch(loaderToggle(true));

      (async () => {
        const { date, billing_run_id } = this.state.invoice;
        const { user_id } = this.state.invoice;
        const { id } = this.state.tenant;

        const params = {
          tenant_id: id,
          billing_run_id: billing_run_id,
          user_id: user_id,
          type: 1
        };
        const { ok, data } = await CreatePainDDFile(params);
        if (ok) {
          let createPainXMLSection = Object.assign({}, this.state.createPainXMLSection);
          createPainXMLSection.section_stats = true;
          createPainXMLSection.buttonSection = false;
          createPainXMLSection.changeBilling = false;
          createPainXMLSection.section_dd_file = true;
          createPainXMLSection.section_download = true;
          createPainXMLSection.getGenerateInvoiceButton = false;

          this.setState({
            createPainXMLSection
          });
        }
        dispatch(loaderToggle(false));

      })();
    }
  }

  handlCreateDebitFileChangeBilling(e) {
    e.preventDefault();

    const { loader } = this.props;

    if (!loader) {
      let createPainXMLInvoiceStats = Object.assign({}, this.state.createPainXMLInvoiceStats);
      createPainXMLInvoiceStats = {};

      let createPainXMLSection = Object.assign({}, this.state.createPainXMLSection);
      createPainXMLSection.getGenerateInvoiceButton = true;
      createPainXMLSection.section_sending = false;
      createPainXMLSection.buttonSection = false;
      createPainXMLSection.changeBilling = false;
      createPainXMLSection.section_generating = false;
      createPainXMLSection.section_dd_file = true;
      createPainXMLSection.section_stats = false;

      this.setState({
        createPainXMLInvoiceStats,
        createPainXMLSection
      })
    }
  }

  formatCurrency(float) {
    const {t} = this.props;
    if (float) {
      let floatCell = float ? float : null;
      let floatStyle = floatCell && parseFloat(floatCell) < 0 ? 'float-negative' : floatCell && parseFloat(floatCell) > 0 ? 'float-positive' : 'float-null';
      return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(parseFloat(float)) })}</span>
    } else {
      return <span className={'float-zero'}>{t("currency_format", { number: Number(0) })}</span>
    }
  };

  componentDidMount() {
    this.initData();
  }

  initData() {
    const { dispatch, loader, selectedData } = this.props;

    if (!loader) {
      dispatch(loaderToggle(true));
      (async () => {
        const { user } = this.props;

        let tenant = Object.assign({}, this.state.tenant);
        tenant.id = user && user.tenant ? user.tenant.id : '';
        tenant.name = user && user.tenant ? user.tenant.name : '';

        let billing_run = Object.assign({}, this.state.billing_run);
        billing_run.date = '';
        billing_run.billing_run_id = '';

        this.setState({
          tenant,
          billing_run
        });

        let generateInvoiceDateOpts = [],
            finalizeInvoiceDateOpts = [],
            invoiceEmailDateOpts = [],
            createPainXMLDateOpts = [];

        const [generateBillingRunInvoiceDates,
               isBusyGeneratingInvoiceDates, isFailedGeneratingInvoiceDates, isDoneGeneratingInvoiceDates,
               isBusyFinalizingInvoiceDates, isFailedFinalizingInvoiceDates, isDoneFinalizingInvoiceDates,
               isBusySendingInvoiceEmailDates, isFailedSendingInvoiceEmailDates, generatePainXMLDates,
               isBusyCreatingDdFile, isFailedCreatingDdFile, isDoneCreatingDdFile
        ] = await Promise.all([
          GetTenantBillingRunDates(tenant.id, 0),  // generateInvoiceDates - generateBillingRunInvoiceDates - ready to create concept invoices - fills dropdown with dates in select billing run

          GetTenantBillingRunDates(tenant.id, 10), // isBusyGeneratingInvoiceDates - busy creating concept invoices
          GetTenantBillingRunDates(tenant.id, 11), // isFailedGeneratingInvoiceDates - failed creating concept invoices
          GetTenantBillingRunDates(tenant.id, 12), // isDoneGeneratingInvoiceDates - concept invoices created - ready to finalize

          GetTenantBillingRunDates(tenant.id, 13), // isBusyFinalizingInvoiceDates - is busy finalizing invoices
          GetTenantBillingRunDates(tenant.id, 14), // isFailedFinalizingInvoiceDates - finalizing concept invoices failed
          GetTenantBillingRunDates(tenant.id, 15), // isDoneFinalizingInvoiceDates - ready with finalizing invoices - ready to sent mail

          GetTenantBillingRunDates(tenant.id, 20), // isBusySendingInvoiceEmailDates -> invoices sending / invoices sending
          GetTenantBillingRunDates(tenant.id, 21), // isFailedSendingInvoiceEmailDates - invoices_not_sent / error code -> if invoices are NOT sent
          GetTenantBillingRunDates(tenant.id, 22), // generatePainXMLDates - invoices_sent / success code -> if invoices are sent

          GetTenantBillingRunDates(tenant.id, 30), // isBusyCreatingDdFile - busy creating dd_file
          GetTenantBillingRunDates(tenant.id, 31),  // isFailedCreatingDdFile - failed creating dd_file
          GetTenantBillingRunDates(tenant.id, 32)  // isDoneCreatingDdFile - ready to download dd_file

          //if status 40/100 - dd_debit_file is downloaded -> END
        ]);

        await (() => {
          /* Generating billing run dates from add billing run datepicker - if ok - fills dropdown in - Generate concept invoices - is enabled/available when status === 0 */
          if (generateBillingRunInvoiceDates.ok && generateBillingRunInvoiceDates.data && generateBillingRunInvoiceDates.data.data) {
            let generate_invoice = Object.assign({}, this.state.generate_invoice);

            generate_invoice.date = '';
            generate_invoice.billing_run_id = '';
            generateInvoiceDateOpts = generateBillingRunInvoiceDates.data.data.map(d => ({
              "value" : d.id,
              "label" : d.date
            }));

            if (generateInvoiceDateOpts.length && generateInvoiceDateOpts.length > 0) {
              generate_invoice.date = generateInvoiceDateOpts[0].label;
              generate_invoice.billing_run_id = generateInvoiceDateOpts[0].value;

              this.setState({
                generate_invoice,
                generateInvoiceDateOpts
              });

              let generateInvoiceSection = Object.assign({},
                  this.state.generateInvoiceSection);

              generateInvoiceSection['section_generating'] = false;
              generateInvoiceSection['getGenerateInvoiceButton'] = true;
              generateInvoiceSection['response'] = generateBillingRunInvoiceDates.data.data;

              this.setState({
                generateInvoiceSection
              });
            }
          }
          /* if isBusyGeneratingInvoiceDates - show when status === 10 */
          if (isBusyGeneratingInvoiceDates.ok && isBusyGeneratingInvoiceDates.data && isBusyGeneratingInvoiceDates.data.success && isBusyGeneratingInvoiceDates.data.data && isBusyGeneratingInvoiceDates.data.data.length > 0) {
            let generateInvoiceSection = Object.assign({},
                this.state.generateInvoiceSection);

            generateInvoiceSection['section_generating'] = true;
            generateInvoiceSection['getGenerateInvoiceButton'] = false;
            generateInvoiceSection['response'] = isBusyGeneratingInvoiceDates.data.data;
            generateInvoiceSection['generate_invoice_busy'] = true;

            this.setState({
              generateInvoiceSection
            });
          }
          /* isDoneGeneratingInvoiceDates - Invoices finalized ready - available when status = 12 */
          if (isDoneGeneratingInvoiceDates.ok && isDoneGeneratingInvoiceDates.data && isDoneGeneratingInvoiceDates.data.success && isDoneGeneratingInvoiceDates.data.data && isDoneGeneratingInvoiceDates.data.data.length > 0) {
            let finalize_invoice = Object.assign({}, this.state.finalize_invoice);
            let finalizeInvoiceSection = Object.assign({}, this.state.finalizeInvoiceSection);

            finalize_invoice.date = '';
            finalize_invoice.billing_run_id = '';
            finalizeInvoiceDateOpts = isDoneGeneratingInvoiceDates.data.data.map(d => ({
              "value": d.id,
              "label": d.date
            }));

            if (finalizeInvoiceDateOpts.length) {
              finalize_invoice.date = finalizeInvoiceDateOpts[0].label;
              finalize_invoice.billing_run_id = finalizeInvoiceDateOpts[0].value;

              finalizeInvoiceSection['section_sending'] = false;
              finalizeInvoiceSection['finalizeInvoiceButton'] = true; // finalizeInvoiceButton
              finalizeInvoiceSection['sendEmailInvoiceButton'] = true; // sendEmailInvoiceButton

              this.setState({
                finalize_invoice,
                finalizeInvoiceDateOpts,
                finalizeInvoiceSection
              });
            }
          }

          /* if isBusyGeneratingInvoiceDates - show when status === 13 */
          if (isBusyFinalizingInvoiceDates.ok && isBusyFinalizingInvoiceDates.data && isBusyFinalizingInvoiceDates.data.success && isBusyFinalizingInvoiceDates.data.data && isBusyFinalizingInvoiceDates.data.data.length > 0) {
            let finalizeInvoiceSection = Object.assign({}, this.state.finalizeInvoiceSection);
            finalizeInvoiceSection['section_finalizing']  = true;
            finalizeInvoiceSection['finalize_invoice_busy'] = true;
            finalizeInvoiceSection['response'] = isBusyFinalizingInvoiceDates.data.data;

            this.setState({
              finalizeInvoiceSection
            });
          }
          /* if isFailedGeneratingInvoiceDates - show when status === 14 */
          if (isFailedFinalizingInvoiceDates.ok && isFailedFinalizingInvoiceDates.data && isFailedFinalizingInvoiceDates.data.success && isFailedFinalizingInvoiceDates.data.data && isFailedFinalizingInvoiceDates.data.data.length > 0) {
            let finalizeInvoiceSection = Object.assign({}, this.state.finalizeInvoiceSection);
            finalizeInvoiceSection['section_finalizing']  = true;
            finalizeInvoiceSection['finalize_invoice_failed'] = true;
            finalizeInvoiceSection['response'] = isFailedFinalizingInvoiceDates.data.data;

            this.setState({
              finalizeInvoiceSection
            });
          }
          /* isDoneFinalizingInvoiceDates - Invoices finalized & ready to sent - fills dropdown in send invoice emails - show/enable/set available when status = 15 */
          if (isDoneFinalizingInvoiceDates.ok && isDoneFinalizingInvoiceDates.data &&
              isDoneFinalizingInvoiceDates.data.success &&
              isDoneFinalizingInvoiceDates.data.data &&
              isDoneFinalizingInvoiceDates.data.data.length > 0) {
            let invoice_email = Object.assign({}, this.state.invoice_email);
            invoice_email.date = '';
            invoice_email.billing_run_id = '';
            invoiceEmailDateOpts = isDoneFinalizingInvoiceDates.data.data.map(d => ({
              "value": d.id,
              "label": d.date
            }));

            if (invoiceEmailDateOpts.length && invoiceEmailDateOpts.length > 0) {
              invoice_email.date = invoiceEmailDateOpts[0].label;
              invoice_email.billing_run_id = invoiceEmailDateOpts[0].value;

              this.setState({
                invoice_email,
                invoiceEmailDateOpts
              });
            }

            let invoiceEmailSection = Object.assign({}, this.state.invoiceEmailSection);
            invoiceEmailSection['section_sending'] = false;
            invoiceEmailSection['sendEmailInvoiceButton'] = true;
            invoiceEmailSection['response'] = isDoneFinalizingInvoiceDates.data.data;

            this.setState({
              invoiceEmailSection
            });
          }

          /* isBusySendingInvoiceEmailDates - busy sending email - status = 20 */
          if (isBusySendingInvoiceEmailDates.ok && isBusySendingInvoiceEmailDates.data && isBusySendingInvoiceEmailDates.data.success && isBusySendingInvoiceEmailDates.data.data && isBusySendingInvoiceEmailDates.data.data.length > 0) {
            let invoiceEmailSection = Object.assign({}, this.state.invoiceEmailSection);
            invoiceEmailSection['section_sending'] = true;
            invoiceEmailSection['sendEmailInvoiceButton'] = false;
            invoiceEmailSection['invoice_email_busy'] = true;
            invoiceEmailSection['response'] = isDoneFinalizingInvoiceDates.data.data;

            this.setState({
              invoiceEmailSection
            });
          }
          /* isFailedSendingInvoiceEmailDates - failed sending email - status = 21 */
          if (isFailedSendingInvoiceEmailDates.ok && isFailedSendingInvoiceEmailDates.data && isFailedSendingInvoiceEmailDates.data.success && isFailedSendingInvoiceEmailDates.data.data && isFailedSendingInvoiceEmailDates.data.data.length > 0) {
            let invoiceEmailSection = Object.assign({}, this.state.invoiceEmailSection);
            invoiceEmailSection['section_sending'] = true;
            invoiceEmailSection['sendEmailInvoiceButton'] = false;
            invoiceEmailSection['invoice_email_failed'] = true;
            invoiceEmailSection['response'] = isDoneFinalizingInvoiceDates.data.data;

            this.setState({
              invoiceEmailSection
            });
          }
          /* generatePainXMLDates - is done/finished sending email - Generating XML - status = 22 */
          if (generatePainXMLDates.ok && generatePainXMLDates.data && generatePainXMLDates.data.success && generatePainXMLDates.data.data && generatePainXMLDates.data.data.length > 0) {
            let invoice = Object.assign({}, this.state.invoice);
            invoice.user_id = user ? user.id : '';
            invoice.date = '';
            invoice.billing_run_id = '';

            createPainXMLDateOpts = generatePainXMLDates.data.data.map(d => ({
              "value": d.id,
              "label": d.date
            }));
            if (createPainXMLDateOpts.length) {
              invoice.date = createPainXMLDateOpts[0].label;
              invoice.billing_run_id = createPainXMLDateOpts[0].value;
            }

            let createPainXMLSection = Object.assign({}, this.state.createPainXMLSection);
            createPainXMLSection.getGenerateInvoiceButton = true;
            createPainXMLSection.section_dd_file = false;
            createPainXMLSection.section_stats = false;

            let createPainXMLInvoiceStats = Object.assign({}, this.state.createPainXMLInvoiceStats);

            this.setState({
              invoice,
              createPainXMLDateOpts,
              createPainXMLSection,
              createPainXMLInvoiceStats
            });
          }

          /* isBusyCreatingDdFile - Generating dd_file - busy creating file - status = 30 */
          if (isBusyCreatingDdFile.ok && isBusyCreatingDdFile.data && isBusyCreatingDdFile.data.success && isBusyCreatingDdFile.data.data && isBusyCreatingDdFile.data.data.length > 0) {
            let createPainXMLSection = Object.assign({}, this.state.createPainXMLSection);
            createPainXMLSection.isBusyCreating = true;
            createPainXMLSection.section_dd_file = true;
            createPainXMLSection.responseDdFileCreate = isBusyCreatingDdFile.data.data;
            createPainXMLSection.getGenerateInvoiceButton = false;

            this.setState({
              createPainXMLSection
            });
          }
          /* isFailedCreatingDdFile - failed creating file - status = 31 */
          if (isFailedCreatingDdFile.ok && isFailedCreatingDdFile.data && isFailedCreatingDdFile.data.success && isFailedCreatingDdFile.data.data && isFailedCreatingDdFile.data.data.length > 0) {
            let createPainXMLSection = Object.assign({}, this.state.createPainXMLSection);
            createPainXMLSection.isFailedCreating = true;
            createPainXMLSection.section_dd_file = true;
            createPainXMLSection.responseDdFileFail = isFailedCreatingDdFile.data.data;
            createPainXMLSection.getGenerateInvoiceButton = false;

            this.setState({
              createPainXMLSection
            });
          }
          /* isDoneCreatingDdFile - dd_file ready - make download visible - status = 32 */
          if (isDoneCreatingDdFile.ok && isDoneCreatingDdFile.data && isDoneCreatingDdFile.data.success && isDoneCreatingDdFile.data.data && isDoneCreatingDdFile.data.data.length > 0) {
            let createPainXMLSection = Object.assign({}, this.state.createPainXMLSection);
            createPainXMLSection.isDoneCreating = true;
            createPainXMLSection.section_dd_file = true;
            createPainXMLSection.responseDdFileDone = isDoneCreatingDdFile.data.data;
            createPainXMLSection.getGenerateInvoiceButton = false;

            this.setState({
              createPainXMLSection
            });
          }

          /* download_section - visible by default */
          /* is dd_file downloaded - status = 40 */
          /* finished/closed billing_proces - status = 100 */

        })();
        dispatch(loaderToggle(false));

      })();
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user !== user) {
      this.initData();

      let billing_run = Object.assign({}, this.state.billing_run);
      let invoiceEmailInvoiceStats = Object.assign({}, this.state.invoiceEmailInvoiceStats);
      let invoiceEmailSection = Object.assign({}, this.state.invoiceEmailSection);
      let showPainXMLSectionDownload = Object.assign({}, this.state.showPainXMLSectionDownload);
      let createPainXMLInvoiceStats = Object.assign({}, this.state.createPainXMLInvoiceStats);
      let createPainXMLSection = Object.assign({}, this.state.createPainXMLSection);

      this.setState({
        billing_run,
        invoiceEmailInvoiceStats,
        invoiceEmailSection,
        createPainXMLInvoiceStats,
        createPainXMLSection
      });
    }
  }

  render() {
    const { t, loader } = this.props;

    let {
      tenant,
      billing_run, // Add billing run

      // Generate invoices
      generate_invoice,
      generateInvoiceDateOpts,

      // Generate concept invoices
      generate_concept,
      generateInvoiceSection,

      // Finalize
      finalize_invoice,
      finalizeInvoiceDateOpts,
      finalizeInvoiceSection,

      // Send invoice email
      invoice_email,
      invoiceEmailDateOpts,
      invoiceEmailSection,
      invoiceEmailInvoiceStats,

      // Create & Download Direct Debit XML file
      invoice,
      createPainXMLDateOpts,
      createPainXMLSection,
      createPainXMLInvoiceStats,
      showPainXMLSectionDownload,

      // IBAN check,
      ibanCheck,

      // Data dump download
      accessDataDump
    } = this.state;

    return (
        <div className={`billing-run-tools`}>
          <h1 className="text-bold mb-3">Billing run</h1>
          <NavTabsFinance />
          <React.Fragment>
            <Row>
              <Col md="12" xl="12">
                <Card className="border mb-3">
                  <CardBody className="p-3">
                    <Row>
                      <Col md={2} className="align-items-center">Current tenant</Col>
                      <Col md={5} className="align-items-center">{`${tenant && tenant.id ? tenant.name : ''}`}</Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* UPLOAD CDRs */}
            <Row>
              <Col md="12" xl="12">
                <Card className="border mb-3">
                  <CardHeader>
                    <h4 className="mb-0">Upload and process CDRs</h4>
                  </CardHeader>
                  <CardBody>
                    <FileUpload label="CDR file" btnLabel="Process file" controller={ProcessCdr} MessageComponent={ProcessedMessage} {...this.props} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* ADD BILLING RUN */}
            <Row>
              <Col md="12" xl="12">
                <Card className="border mb-3">
                  <CardHeader>
                    <h4 className="mb-0">Add billing run <span className="float-right small">generate billing run date</span></h4>
                  </CardHeader>
                  <CardBody className="p-3">
                    <Form>
                      <FormGroup className="row align-items-center">
                        <Col md={2}>
                          <Label>Billing run date</Label>
                        </Col>
                        <Col md={5} className="billing-run-checker">
                          <DatePicker
                              className="form-control"
                              name="date"
                              dateFormat={t("date_format_raw")}
                              autoComplete="off"
                              placeholderText="Billing run date"
                              onChange={this.handleDatePickerChangeAddBillingRun.bind(this, 'date')}
                              selected={billing_run && billing_run.date ? new Date(billing_run.date) : null}
                              minDate={subMonths(new Date(), 1)}
                              ref="date"
                              onKeyDown={(e) => e.preventDefault(e)}
                              required
                          />
                        </Col>
                        <Col>
                          <Button type="button" color="primary" className="col-md-5" onClick={(e) => this.handleFormSubmitAddBillingRun(e)}>Save</Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* GENERATE INVOICE - section_generating */}
            <Row>
              <Col md="12" xl="12">
                <Card className="border mb-3">
                  <CardHeader>
                    <h4 className="mb-0">Generate concept invoices <span className="float-right small">If status === 0</span></h4>
                  </CardHeader>
                  <CardBody className="p-3">
                    <Form>
                      <FormGroup className="row align-items-center">
                        <Col md={2}>
                          <Label>Select billing run</Label>
                        </Col>
                        <Col md={5}>
                          <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={generateInvoiceDateOpts}
                              value={generate_invoice.date}
                              onChange={this.handleSelectChangeGenerateInvoice.bind(this, 'date')}
                              maxMenuHeight={300}
                              isDisabled={!generateInvoiceSection.getGenerateInvoiceButton}
                          />
                        </Col>
                        <Col>
                          {!loader && generateInvoiceSection.getGenerateInvoiceButton &&
                          <Button type="submit" color="primary" className="col-md-5" disabled={!generateInvoiceDateOpts.length} onClick={(e) => this.handleFormSubmitGenerateInvoices(e)}>Generate invoices</Button>}
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  {!loader && generateInvoiceSection.section_generating && <React.Fragment>
                    <CardHeader className="border-top border-bottom-0">
                      <h4 className="mb-0 mt-3">Generating invoices</h4>
                    </CardHeader>
                    <CardBody className={`pt-0`}>
                      <p>When done, you will receive an email notification.</p>
                    </CardBody>
                  </React.Fragment>}
                </Card>
              </Col>
            </Row>

            {/* GENERATE INVOICES - FINALIZER - section_finalizing */}
            <Row>
              <Col md="12" xl="12">
                <Card className="border mb-3">
                  <CardHeader>
                    <h4 className="mb-0">Finalize invoices <span className="float-right small">If status === 12</span></h4>
                  </CardHeader>
                  <CardBody className="p-3">
                    <Form>
                      <FormGroup className="row align-items-center">
                        <Col md={2}>
                          <Label>Select billing run</Label>
                        </Col>
                        <Col md={5}>
                          <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={finalizeInvoiceDateOpts}
                              value={finalize_invoice.date}
                              onChange={this.handleSelectChangeFinalizedInvoice.bind(this, 'date')}
                              maxMenuHeight={300}
                              isDisabled={!finalizeInvoiceSection.finalizeInvoiceButton}
                          />
                        </Col>
                        <Col>
                          {!loader && finalizeInvoiceSection.finalizeInvoiceButton &&
                          <Button type="submit" color="primary" disabled={!finalizeInvoiceDateOpts.length} className="col-md-5" onClick={(e) => this.handleGenerateConceptInvoices(e)}>Finalize invoices</Button> /*handleFormSubmitGetInvoiceDetails*/}
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  {!loader && finalizeInvoiceSection.section_finalizing &&
                  <React.Fragment>
                    <CardHeader className="border-top border-bottom-0">
                      {finalizeInvoiceSection.finalize_invoice_busy &&
                      <h4 className="mb-0 mt-3">Busy finalizing invoices</h4>}
                      {finalizeInvoiceSection.finalize_invoice_failed &&
                      <h4 className="mb-0 mt-3">Error finalizing invoices</h4>}
                    </CardHeader>
                    <CardBody className={`pt-0`}>
                      {finalizeInvoiceSection.finalize_invoice_busy &&
                      <p>When done, you will receive an email notification.</p>}
                      {finalizeInvoiceSection.finalize_invoice_failed &&
                      <p>Finalizing invoices failed!</p>}
                    </CardBody>
                  </React.Fragment>}
                </Card>
              </Col>
            </Row>

            {/* SEND INVOICE EMAILS - section_sending */}
            <Row>
              <Col md="12" xl="12">
                <Card className="border mb-3">
                  <CardHeader>
                    <h4 className="mb-0">Send invoice emails <span className="float-right small">If status === 15</span></h4>
                  </CardHeader>
                  <CardBody className="p-3">
                    <Form>
                      <FormGroup className="row align-items-center">
                        <Col md={2}>
                          <Label>Select billing run</Label>
                        </Col>
                        <Col md={5}>
                          <Select
                              id="date"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={invoiceEmailDateOpts}
                              value={invoice_email.date}
                              onChange={this.handleSelectChangeSendInvoiceEmail.bind(this, 'date')}
                              maxMenuHeight={300}
                              isDisabled={!invoiceEmailSection.sendEmailInvoiceButton}
                          />
                        </Col>
                        <Col>
                          {!loader && invoiceEmailSection.sendEmailInvoiceButton &&
                          <Button type="button" color="primary" className="col-md-5" disabled={!invoiceEmailDateOpts.length} onClick={(e) => this.handleFormSubmitGetInvoiceDetails(e)}>Get invoices</Button>}
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  {!loader && invoiceEmailSection.section_sending &&
                  <React.Fragment>
                    <CardHeader className="border-top border-bottom-0">
                      {!invoiceEmailSection.invoice_email_busy && !invoiceEmailSection.invoice_email_failed &&
                      <h4 className="mb-0 mt-3">Found invoices</h4>}
                      {invoiceEmailSection.invoice_email_busy &&
                      <h4 className="mb-0 mt-3">Busy sending invoice email</h4>}
                      {invoiceEmailSection.invoice_email_failed &&
                      <h4 className="mb-0 mt-3">Failed sending invoice email</h4>}
                    </CardHeader>

                    {invoiceEmailSection.invoice_email_busy &&
                    <CardBody className={`pt-0`}>Currently sending invoice emails</CardBody>}
                    {invoiceEmailSection.invoice_email_failed &&
                    <CardBody className={`pt-0`}>Error sending invoice emails</CardBody>}
                    {!invoiceEmailSection.invoice_email_busy && !invoiceEmailSection.invoice_email_failed &&
                    <CardBody className="mt-0 pt-1">
                      <Table
                          className="mb-0 table table-borderless table-billing-run">
                        <tbody>
                        <tr>
                          <td>Invoice count</td>
                          <td>{invoiceEmailInvoiceStats ? invoiceEmailInvoiceStats.invoice_count : ''}</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Customer count</td>
                          <td>{invoiceEmailInvoiceStats ? invoiceEmailInvoiceStats.customer_count : ''}</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <Form className="mt-2 mb-1">
                              {!loader && invoiceEmailSection.buttonSection &&
                              <Button type="button" color="primary" className="mr-2" onClick={(e) => this.handleSendInvoiceEmail(e)}>Send emails</Button>}
                              {!loader && invoiceEmailSection.changeBilling &&
                              <Button type="button" color="gray" onClick={(e) => this.handleChangeBillingSendInvoiceEmail(e)}>Change billing run</Button>}
                            </Form>
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </CardBody>}
                  </React.Fragment>}

                </Card>
              </Col>
            </Row>

            {/* GET INVALID IBANS */}
            <Row>
              <Col md="12" xl="12">
                <Card className="border mb-3">
                  <CardHeader>
                    <h4 className="mb-0">IBAN Check</h4>
                  </CardHeader>
                  <CardBody className="p-3">
                    <Form>
                      <FormGroup className="row align-items-center">
                        <Col md={2}>
                          <Label>Run check</Label>
                        </Col>
                        <Col md={5}>
                          <Button type="submit" color="primary" disabled={!ibanCheck.showButton} className="col-md-5" onClick={(e) => this.handleIBANCheck(e)}>Run IBAN check</Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* GENERATE PAIN DIRECT DEBIT FILE */}
            <Row>
              <Col md="12" xl="12">
                <Card className="border mb-3">
                  <CardHeader>
                    <h4 className="mb-0">Generate direct debit file <span className="float-right small">If status === 22</span></h4>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={(e) => this.handleGetInvoicesCreatePainXML(e)}>
                      <FormGroup className="row align-items-center">
                        <Col md={2}>
                          <Label>Select billing run</Label>
                        </Col>
                        <Col md={5}>
                          <Select
                              id="invoice_date"
                              name="invoice_date"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={createPainXMLDateOpts}
                              value={invoice.date}
                              onChange={this.handleSelectChangeCreatePainXML.bind(this, 'invoice_date')}
                              maxMenuHeight={300}
                              isDisabled={!createPainXMLDateOpts.length || !createPainXMLSection.getGenerateInvoiceButton}
                          />
                        </Col>
                        <Col>
                          {!loader && createPainXMLDateOpts.length > 0 &&
                          <Button type="submit" color="primary" className="col-md-5" disabled={!createPainXMLSection.getGenerateInvoiceButton}>Get invoices</Button>}
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>

                  {!loader && createPainXMLInvoiceStats && createPainXMLSection.section_stats &&
                  <React.Fragment>
                    <CardHeader className="border-top border-bottom-0">
                      <h4 className="mb-0 mt-3 mb-1">Invoices stats</h4>
                    </CardHeader>
                    <CardBody className="mt-0 pt-0">
                      <Table className="mb-0 table table-borderless table-billing-run">
                        <tbody>
                          <tr>
                            <td>Invoice count</td>
                            <td>{createPainXMLInvoiceStats ? createPainXMLInvoiceStats.invoice_count : ''}</td>
                            <td>Average amount</td>
                            <td>{createPainXMLInvoiceStats ? this.formatCurrency(createPainXMLInvoiceStats.average_invoice_total) : ''}</td>
                          </tr>
                          <tr>
                            <td>Customer count</td>
                            <td>{createPainXMLInvoiceStats ? createPainXMLInvoiceStats.customer_count : ''}</td>
                            <td>Highest amount</td>
                            <td>{createPainXMLInvoiceStats ? this.formatCurrency(createPainXMLInvoiceStats.max_invoice_total) : ''}</td>
                          </tr>
                          <tr>
                            <td>Total amount incl VAT</td>
                            <td>{createPainXMLInvoiceStats ? this.formatCurrency(createPainXMLInvoiceStats.total_incl_vat) : ''}</td>
                            <td>Lowest amount</td>
                            <td>{createPainXMLInvoiceStats ? this.formatCurrency(createPainXMLInvoiceStats.min_invoice_total) : ''}</td>
                          </tr>
                          <tr>
                            <td>Total amount excl VAT</td>
                            <td>{createPainXMLInvoiceStats ? this.formatCurrency(createPainXMLInvoiceStats.total_excl_vat) : ''}</td>
                            <td>First time invoice count</td>
                            <td>{createPainXMLInvoiceStats ? createPainXMLInvoiceStats.first_invoice_count : ''}</td>
                          </tr>
                          <tr>
                          <td colSpan="4">
                            <Form className="mt-2 mb-1">
                              {createPainXMLSection.buttonSection &&
                              <Button type="button" color="primary" className="mr-2" onClick={(e) => this.handleGenerateBatchFile(e)}>Generate batch file</Button>}
                              {createPainXMLSection.changeBilling &&
                              <Button type="button" color="gray" onClick={(e) => this.handlCreateDebitFileChangeBilling(e)}>Change billing run</Button>}
                            </Form>
                          </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </React.Fragment>
                  }

                  {!loader && createPainXMLSection.section_dd_file && (createPainXMLSection.responseDdFileCreate.length > 0 || createPainXMLSection.responseDdFileFail.length > 0 || createPainXMLSection.responseDdFileDone.length > 0) &&
                  <React.Fragment>
                    <CardHeader className="border-top border-bottom-0">
                      <h4 className="mb-0 mt-3 mb-1">Direct debit status</h4>
                    </CardHeader>
                    <CardBody className="mt-0 pt-0">
                    {createPainXMLSection.isBusyCreating && createPainXMLSection.responseDdFileCreate && createPainXMLSection.responseDdFileCreate.length > 0 &&
                      createPainXMLSection.responseDdFileCreate.map(function(name, index) {
                        return <div className={`is-busy-creating-dd-file w-100`} key={`is-busy-creating-dd-file-${index}`}>
                            <div className={`key-status float-left w-50`}>Busy creating direct debit file for billing run</div>
                            <div className={`key-value float-left w-50`}><b>{ name.date } - (ID: { name.billing_run_id })</b></div>
                        </div>
                      })}

                    {createPainXMLSection.isFailedCreating && createPainXMLSection.responseDdFileFail && createPainXMLSection.responseDdFileFail.length > 0 &&
                      createPainXMLSection.responseDdFileFail.map(function(name, index) {
                        return <div className={`is-failed-creating-dd-file w-100`} key={`is-failed-creating-dd-file-${index}`}>
                            <div className={`key-status float-left w-50`}>Failed creating direct debit file for billing run</div>
                            <div className={`key-value float-left w-50`}><b>{ name.date } - (ID: { name.billing_run_id })</b></div>
                        </div>
                      })}

                    {createPainXMLSection.isDoneCreating && createPainXMLSection.responseDdFileDone && createPainXMLSection.responseDdFileDone.length > 0 &&
                      createPainXMLSection.responseDdFileDone.map((name, index) => {
                        return <div className={`row pb-0 pt-1 pl-0 pr-0 is-done-creating-dd-file`} key={`is-done-creating-dd-file-${index}`}>
                            <div className={`key-status col-md-2`}>Billing run finished</div>
                            <div className={`col-md-5`}>
                              <button className="col-md-5 btn btn-primary" onClick={(e) => this.handleDownload(e, name)} disabled={!name.dd_file}>
                                { name.date } - (ID: { name.id })
                              </button>
                            </div>
                          </div>
                      })}

                    </CardBody>
                  </React.Fragment>}

                </Card>
              </Col>
            </Row>

          </React.Fragment>
        </div>
    )
  }
}

const userSelector = createSelector(
state => state.user,
user => user
),
loaderSelector = createSelector(
    state => state.loader,
    loader => loader
),
mapStateToProps = createSelector(
    userSelector,
    loaderSelector,
    (user, loader) => ({ user, loader })
);

export default connect(mapStateToProps)(withTranslation()(withRouter(Finance)));