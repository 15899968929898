import React, {useEffect, useState} from 'react';
import JsonPrettify from "json-stringify-pretty-compact";
import ProvisioningTemplateForm from "./Form";
import ProvisioningTemplateJsonForm from "./FormJson";
import RemoveProvisioningTemplateForm from "./Remove";
import {useTranslation} from 'react-i18next';
import JSONPretty from 'react-json-pretty';
import ReactJson from 'react-json-view';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Badge} from "reactstrap";
import XMLPrettify from "xml-formatter";
import ParentTable from '../../../components/ParentTable';
import {loaderToggle} from '../../../redux/actions/loaderActions';
import {GetProvisioningTemplatesByTenantId} from '../../../controllers/provisioning';
import {GetProduct} from '../../../controllers/products';

const JsonOptions = { indent: 4 },
  XmlOptions = {
    indentation: '   ',
    stripComments: true,
    collapseContent: true,
    lineSeparator: '\n'
  };

const ProvisioningTemplates = ({ ...props }) => {
  const tableName = "ProvisioningTemplatesList";
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const removeXML = (json) => {
    const xmlData = json.Xml ? json.Xml : null;
    if (xmlData) {
      delete json.Xml;
    }
    return (
      <>
        <div>{JsonPrettify(json, JsonOptions)}</div>
        {xmlData && <div>{XMLPrettify(xmlData, XmlOptions)}</div>}
      </>
    );
  };

  const formatTenant = (cell) => {
    const { tenants } = props;
    const tenant = Object.values(tenants).find(i => parseInt(i.id) === parseInt(cell));

    if (tenant) {
      return tenant.name;
    } else {
      return String.fromCharCode(8212);
    }
  };

  const Prettify = (cell, row, rowIndex, formatExtraData) => {
    if (cell) {
      console.log("Prettify - cell ->", cell);
      let parsedJSON = JSON.parse(cell);
      console.log("Prettify - parsedJSON ->", parsedJSON);

      const xml = Object.keys(cell).filter(data => data === 'Xml');
      console.log("Prettify - xml ->", xml);

      return (<React.Fragment>
        {parsedJSON && <React.Fragment>
          {removeXML(parsedJSON)}
        </React.Fragment>}
      </React.Fragment>)
    }
  };

  const detailsExpand = {
    'renderer': (row) => {
      console.log("Prettify - row ->", row);
      if (row && row.template) {
        let parsedJSON = JSON.parse(row.template);
        console.log("Prettify - parsedJSON ->", parsedJSON);
        return (
        <ReactJson
          name="Template"
          src={parsedJSON}
          enableClipboard={false}
          style={{ fontFamily: "inherit", backgroundColor: "transparent", textAlign: "left" }}
        />)
      }
      return '';
    }
  };

  const validateJsonToJsonObj = (jsonStr) => {
    try {
      JSON.parse(jsonStr);
    } catch (e) {
      return false;
    }
    return <JSONPretty id="json-pretty" data={JSON.parse(jsonStr)}> </JSONPretty>
  };

  const getData = () => {
    const { dispatch, user, loader, tenantId } = props;
    (async () => {
      dispatch(loaderToggle(true));

      await GetProvisioningTemplatesByTenantId(tenantId)
      .then(async res => {
        if (res.ok) {
          const { data } = res;

          if (data.data && data.data.length > 0) {
            for (let i = 0; i < data.data.length; i++) {
              if (data.data[i].product_id) {
                data.data[i].product_description = "";

                await GetProduct(data.data[i].product_id)
                .then(prod => {
                  if (prod.ok && prod.data) {
                    console.log("ProvisioningTemplatesList - GetProduct - prod.data ->", prod.data);
                    data.data[i].product_description = prod.data.data[0].description;
                  }
                })
                .catch(e => {
                  console.log('Error', e);
                });
              }
            }
          }
          setData(data.data);
        }
      })
      .catch(e => {
        console.log('Error', e);
      });
      dispatch(loaderToggle(false));
  
    })();     
  }

  useEffect(() => {
    getData();
  }, []);

  const updateDataTable = () => {
    getData();
  }

  useEffect(() => {
    console.log("ProvisioningTemplatesList - data ->", data);
  }, [data]);

  return (
    <ParentTable
      basicSearch={true}
      table="ProvisioningTemplatesList"
      id="provisioning_templates_list"
      keyField="id"
      remote
      data={data}
      noTopBorder={true}
      rawData={data}
      isRawData={true}
      updateTableRawData={updateDataTable}
      classes="custom-tbl-logs"
      columns={[
        {
          dataField: "id",
          text: "ID",
          headerStyle: { width: '0' },
          hidden: true,
        },
        {
          dataField: "tenant_id",
          text: "Tenant",
          formatter: formatTenant,
          headerStyle: { width: '8%' },
          sort: false,
          attrs: {
            'data-label': 'Tenant ID'
          },
          hidden: true,
        },
        {
          dataField: "product_description",
          text: "Product",
          headerStyle: { width: '15%' },
          sort: false,
          attrs: {
            'data-label': 'Product'
          }
        },
        {
          dataField: "template",
          text: "Template",
          formatter: Prettify,
          classes: "f2x-details-link",
          headerStyle: { width: '45%' },
          style: { marginRight: '15px' },
          sort: false,
          attrs: {
            'data-label': 'Template'
          }
        },
        {
          dataField: "function",
          text: "Function",
          headerStyle: { width: '12%' },
          sort: false,
          attrs: {
            'data-label': 'Function'
          }
        },
        {
          dataField: "name",
          text: "Name",
          headerStyle: { width: '14%' },
          sort: false,
          attrs: {
            'data-label': 'Name'
          }
        }
      ]}
      headerDropdownOpts={[{
        label: 'Add',
        action: ProvisioningTemplateForm
      }]}
      rowAction=""
      rowActionForm={null}
      rowDropdownOpts={[
        {
          action: 'Edit',
          label: 'Edit',
          form: ProvisioningTemplateForm
        },
        {
          action: 'Edit',
          label: 'Edit JSON template',
          form: ProvisioningTemplateJsonForm
        },
        {
          action: 'Delete',
          label: 'Remove',
          form: RemoveProvisioningTemplateForm
        }
      ]}
      wrapperClasses="table-email-templates"
      striped={true}
      notLoadedByDefault={false}
      noHover={false}
      expandRow={detailsExpand}
    />)
};

const mapStateToProps = ({dispatch, loader, user, tenants}) => ({dispatch, loader, user, tenants});

export default withRouter(connect(mapStateToProps)(ProvisioningTemplates));
