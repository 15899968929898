import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    UpdateAPI = Api.updateAPI,
    RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : GetAPI(endpoint, params);
  })();
};

const GetMicroservices = (params) => {
  return asyncFunc(`microservices`, params);
};

const GetMicroservice = (journal, params) => {
  return asyncFunc(`microservices/${journal}`, params);
};

const UpdateMicroservice = (params, journal) => {
  return asyncFunc(`microservices/${journal}`, params, UpdateAPI);
};

const RemoveMicroservice = (journal) => {
  return asyncFunc(`microservices/${journal}`, null, RemoveAPI);
};

export {
  GetMicroservices,
  GetMicroservice,
  UpdateMicroservice,
  RemoveMicroservice,
};
