import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import {AddPlan, GetPlanLines, DuplicatePlan} from '../../controllers/plans';
import {withRouter} from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import {loaderToggle} from '../../redux/actions/loaderActions';
import {validateDate} from "../../redux/actions/validateActions";
import CONSTANTS from '../../services/constants';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import {useTranslation, withTranslation} from 'react-i18next';
import {nl, enGB, enUS} from "date-fns/locale";

const DuplicatePlanOptions = ({...props}) => {
    console.log("DuplicatePlanOptions - props ->", props);

    const { t } = useTranslation();
    const [startDate, setStartDate] = useState('');

    const getLocale = (locale) => {
        const dateLocalesPath = {
          'nl': 'nl',
          'en': 'en-GB',
          'us': 'en-US'
        };
    };

    const handleDatePickerChange = (e, name) => {
        console.log("DuplicatePlanForm - DuplicatePlan - handleDatePickerChange - name ->", name);
        console.log("DuplicatePlanForm - DuplicatePlan - handleDatePickerChange - e ->", e);
    
        const {newDate} = props.dispatch(validateDate(e));
        console.log("DuplicatePlanForm - DuplicatePlan - handleDatePickerChange - newDate ->", newDate);
    
        setStartDate(newDate);
    
        const {duplicateOptions} = props;
        console.log("DuplicatePlanForm - DuplicatePlan - handleDatePickerChange - duplicateOptions ->", duplicateOptions);

        if (duplicateOptions) {
            duplicateOptions({plan_start:newDate});
        }
        // get input
        //let custom_input = CustomSwal.getInput();
        //console.log("DuplicatePlanForm - DuplicatePlan - handleDatePickerChange - CustomSwal.getInput() - custom_input ->", custom_input);
    
        let start_date_input = document.getElementById("duplicate-plan-start-date");
        console.log("DuplicatePlanForm - DuplicatePlan - handleDatePickerChange - getElementById - start_date_input ->", start_date_input);
        if (start_date_input) {
          start_date_input.value = "";
          start_date_input.value = new Date(newDate);
        }
    }

    return (
        <>
            <div 
                style={{
                    lineHeight: "2.3",
                    padding: "0",
                    fontWeight: "bold",
                    textAlign: "left",
                    display: "none"
                }}
            >
                Select a start date
            </div>
            <div>
                <DatePicker
                    locale={getLocale()}
                    className="form-control"
                    wrapperClassName="w-100"
                    name="start_date"
                    id="duplicate-plan-start-date"
                    dateFormat={t("date_format_raw")}
                    autoComplete="off"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    placeholderText={`Select a start date (optional)`}
                    scrollableYearDropdown={true}
                    selected={startDate ? new Date(startDate) : ""}
                    onChange={(e) => handleDatePickerChange(e, 'start_date')}
                    popperClassName="sweet-alert-custom"
                    popperPlacement="top-start"
                    popperModifiers={[
                        {
                            name: "offset",
                            options: {
                            offset: [5, 10],
                            },
                        },
                        {
                            name: "preventOverflow",
                            options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                            },
                        },
                    ]}
                />
            </div>
        </>
        
    )
}

export default DuplicatePlanOptions;
