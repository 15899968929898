import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from 'moment';

import { CardHeader, CardBody, Button, Form, FormGroup, Label, Input } from "reactstrap";

import DataTable from '../../components/DataTable';
import { Addresses } from '../../controllers/l2fiber';

import { loaderToggle } from '../../redux/actions/loaderActions';
import { validateDate } from "../../redux/actions/validateActions";

class AddressUpdates extends React.Component {
    constructor() {
        super()

        this.state = { data: {}}
    }

    handleInputChange({ target: { name, value }}) {
        let data = Object.assign({}, this.state.data)

        data[name] = value

        this.setState({ data })
    }

    handleDatePickerChange(name, date) {
        let data = Object.assign({}, this.state.data)
        const { newDate } = this.props.dispatch(validateDate(date))
        
        data[name] = newDate

        this.setState({ data })
    }

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.refs[name].setOpen(false)
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        const { dispatch, loader} = this.props
        
        if (!loader) {
            dispatch(loaderToggle(true));
        
            (async () => {
                const { ok, data } = await Addresses(this.state.data)

                if (ok) {
                    await data.map((item, key)=> {
                        return data[key].id = key
                    })

                    this.setState({ addresses: data })
                }
                
                dispatch(loaderToggle(false))
            })()
        }
    }

    render() {
        const { addresses, data } = this.state,
            { changedSince, offset, limit } = data

        return (
            <React.Fragment>
                <CardHeader>
                    <h4 className="mb-0">Address updates</h4>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={(e) => this.handleSubmit(e)}>
                        <FormGroup>
                            <Label>Changed since</Label>
                            <DatePicker
                                id="changedSince"
                                wrapperClassName="w-100"
                                className="form-control form-control-lg"
                                name="changedSince"
                                dateFormat="dd-MM-yyyy"
                                autoComplete="off"
                                placeholderText="Changed since"
                                selected={changedSince ? moment(changedSince, 'DD-MM-YYYY')._d : null}
                                onChange={this.handleDatePickerChange.bind(this, 'changedSince')}
                                ref="changedSince"
                                onKeyDown={(e) => this.handleKeyDown(e, 'changedSince')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Offset</Label>
                            <Input
                                bsSize="lg"
                                type="number"
                                name="offset"
                                value={offset ? offset : '' }
                                onChange={(e) => this.handleInputChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Limit</Label>
                            <Input
                                bsSize="lg"
                                type="number"
                                name="limit"
                                value={limit ? limit : ''}
                                onChange={(e) => this.handleInputChange(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button color="primary">Save</Button>
                        </FormGroup>
                    </Form>
                </CardBody>

                <CardBody>
                    { !this.props.loader && addresses && 
                        <DataTable
                            table="Addresses"
                            data={addresses}
                            columns={[{
                                dataField: "publicId",
                                text: "Public id",
                                sort: true
                            }, {
                                dataField: "number",
                                text: "Number",
                                sort: true
                            }, {
                                dataField: "room",
                                text: "Room",
                                sort: true
                            }, {
                                dataField: "street",
                                text: "Street",
                                sort: true
                            }, {
                                dataField: "postalCode",
                                text: "Postal code",
                                sort: true
                            }, {
                                dataField: "addressStatus",
                                text: "Status",
                                sort: true
                            }]}
                            search={true}
                            pagination={true}
                        />
                    }
                </CardBody>
            </React.Fragment>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(AddressUpdates);
