import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import {useHistory, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NavTabsFinance from './NavTabsFinance';
import {
  GetAllTenantBillingRunDates, RemoveBillingRunDate, GetAllTenantBillingRunDatesSummary
} from '../../../controllers/tenants';
import CustomDataTable from '../../../components/CustomDataTable';
import ParentTable from '../../../components/ParentTable';
import {
  Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import queryString from 'query-string';
import ApiService from '../../../services/apiService';
import {loaderToggle} from '../../../redux/actions/loaderActions';
import {stringFilter} from '../../../services/misc';
import {getTableObj} from '../../../services/tableService';
import CONSTANTS from '../../../services/constants';
import {MoreHorizontal} from 'react-feather';
import Toast from '../../../components/Toast';
import RemoveBillingRunForm from "./remove/Form";
import DownloadBillingRunForm from "./download/Download";

const Api = new ApiService(),
    GetAPI = Api.getAPI;

const DownloadApi = new ApiService(),
    DownloadAPI = DownloadApi.downloadAPI;

const BillingRunOverview = ({ user, loader, dispatch }) => {
  let history = useHistory();

  const [data, setData] = useState([]);
  const [sort, setSort] = useState(null);
  const [pageNum, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [rowCount, setRowCount] = useState('');
  const [totalSize, setTotalSize] = useState(0);
  const [keyword, setKeyword] = useState(queryString.parse(history.location.search).searchKey);
  const [isLoadData, setLoadData] = useState(true);
  const [reLoadData, setReLoadData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [file, setFile] = useState(false);
  const [isRemovable, setIsRemovable] = useState(null);
  const [isRemovableData, setIsRemovableData] = useState({});

  const rowEditable = [];

  const { t } = useTranslation();

  const formatDate = (cell) => {
    if (cell)
      return <span className="date-format">{t("date_format", { date: new Date(cell) })}</span>
  };

  const formatDownload = (cell) => {
    if (cell) {
      return <a href={`${cell}`} className={`download-btn`}>Download</a>
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  /*const handlerDownloadFile = (downloadLink) => {
    // ('handlerDownloadFile - downloadLink', downloadLink)
    if (downloadLink && downloadLink['download_link']) {
      const relativeURL = new URL(downloadLink['download_link']);
      const fileName = downloadLink.date ? downloadLink.date + '.xml' : 'dd-file.xml';

      if (relativeURL && relativeURL.pathname) {
        const apiDownloadURL = relativeURL.pathname.replace('api/', '');
        DownloadAPI(apiDownloadURL, 'blob', fileName).then(r => {
          setLoading(false);
          if (r.data) {
            const {data, total} = r.data;
            setTotalSize(total);
            setData(data);
          }
        }).catch(error => {
          console.log('Error fetching data: ', error);
          setErrors(error);
          setLoading(false);
        });
        dispatch(loaderToggle(false));

      }
    }
  };*/

  const formatCurrency = (cell) => {
    if (cell) {
      let floatCell = cell ? cell : null;
      let floatStyle = floatCell && parseFloat(floatCell) < 0 ? 'float-negative' : floatCell && parseFloat(floatCell) > 0 ? 'float-positive' : 'float-null';
      return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(parseFloat(cell)) })}</span>
    } else {
      return <span className={'float-zero'}>{t("currency_format", { number: Number(0) })}</span>
    }
  };

  /*const optionMenuFormatter = (cell, row, rowIndex) => {
    let canDeleteFile = false;
    let canDownloadFile = false;

    if (cell) {
      let { download_link, status, status_label } = row;
      if (status && download_link && download_link !== undefined) {
        canDownloadFile = true;

        if (status === 100) {
          canDeleteFile = true;
        }

        return (<UncontrolledDropdown>
              <DropdownToggle nav className="px-3 py-2 dropdown-table-actions">
                <MoreHorizontal size={18}/>
              </DropdownToggle>
              <DropdownMenu right>
                {canDownloadFile &&
                <DropdownItem className="py-2" onClick={(e) => handlerDownloadFile(row, rowIndex, e)}
                              key={`download-dd-file-${rowIndex}`} download>Download direct debit file</DropdownItem>}
                {canDeleteFile &&
                <DropdownItem className="py-2 disabled" disabled
                              onClick={(e) => handlerRemoveFile(row, rowIndex, e)} key={`remove-dd-file-${rowIndex}`}>Delete direct debit file(s)</DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>)
      }
    } else {
      if (row) {
        let {status} = row;
        if (status === 0 || status === 11 || status === 12) {
          return (<UncontrolledDropdown>
            <DropdownToggle nav className="px-3 py-2 dropdown-table-actions">
              <MoreHorizontal size={18}/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="py-2"
                            //onClick={(e) => handlerRemoveBillingRun(row, rowIndex, e)}
                            key={`remove-billing-run-${rowIndex}`}>Delete billing run</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>)
        }
      }
    }
  };*/

  const formatStatus = (cell, row, rowIndex) => {
    console.log('formatStatus - row - cell ->', row, cell);
    if (cell && cell === 0) {
      //setIsRemovable(true);
      return true;
    }
  };

  const isDataRemovable = (cell, row, rowIndex) => {
    console.log('isRemovable cell, row, rowIndex ->', cell, row, rowIndex);
  };

  const doesFileExist = (urlToFile) => {
    let xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, true);
    xhr.send();

    return xhr.status != "404";
  };

  const handleTableChange = (type, {page, sizePerPage, sortField, sortOrder, searchText, data}) => {
    //const sortFieldParam = sortField === 'date' ? 'date' : sortField;
    //const sortFieldParam = sortField ? sortField : '';
    const isSortDesc = sortField && stringFilter(sortOrder, 'desc');
    const sortParam = isSortDesc ? `-${sortField}` : sortField;
    const propsObj = getTableObj(type, {keyword, isLoadData, pageNum, page});

    setSizePerPage(sizePerPage ? sizePerPage : 10);
    setSort(sortParam);
    setKeyword(propsObj && propsObj.keyword ? propsObj.keyword : '');
    setPage(propsObj && propsObj.pageNum ? propsObj.pageNum : 1);
    setLoadData(propsObj && propsObj.isLoadData ? propsObj.isLoadData : true);

    let params = [];
    params['sort'] = sortParam;
    params['page'] = propsObj && propsObj.pageNum ? propsObj.pageNum : 1;
    params['search'] = propsObj && propsObj.keyword ? propsObj.keyword : '';
    params['offset'] = sizePerPage ? sizePerPage : 10;

    // set localstorage
    setQueryParameters(params);
  };

  const loadData = (isLoad) => {
    // ('loadData isLoad', isLoad)
    if (!isLoadData || !pageNum) {
      setLoadData(isLoad)
    }

    // if localstorage
    const invoicesListQuery = localStorage.getItem('billingRunDatesQueryPage');
    setPage(pageNum ? pageNum : 1);
    const params = getQueryParameters();

    if (params) {
      setQueryParameters(params);
      history.replace({
        state: {
          sort: sort,
          page: pageNum ? pageNum : 1,
          sizePerPage: sizePerPage ? sizePerPage : 10
        }
      });
    }
  };

  const setQueryParameters = (params) => {
    if (!params) {
      params = [];
      params['sort'] = sort ? sort : '-billing_run_id';
      params['sortItem'] = sort ? sort : '';
      params['page'] = pageNum ? parseInt(pageNum) : 1;
      params['offset'] = sizePerPage ? parseInt(sizePerPage) : 10;
    }

    let query = [],
        key,
        value;

    for (key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      if (key === 'page' || key === 'offset') {
        value = parseInt(params[key]);
      } else {
        value = params[key];
      }
      query.push(`${key}=${value}`);
    }

    history.location.search = query.join('&');
    localStorage.setItem('billingRunDatesQueryPage', query.join('&'));
    setLoadData(true);
  };

  const getQueryParameters = () => {
    let queryString;
    let params = [];
    if (!localStorage.getItem('billingRunDatesQueryPage') || localStorage.getItem('billingRunDatesQueryPage') === undefined) {
      queryString = history.location && history.location.search &&
      history.location.search.charAt(0) === '?'
          ? history.location.search.slice(1)
          : history.location.search;

    } else {
      queryString = localStorage.getItem('billingRunDatesQueryPage') && localStorage.getItem('billingRunDatesQueryPage').charAt(0) === '?'
          ? localStorage.getItem('billingRunDatesQueryPage').slice(1)
          : localStorage.getItem('billingRunDatesQueryPage');
    }

    if (queryString) {
      queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
        params[key] = value;
      });
    }

    return params;
  };

  const actionsFormatter = (cell, row) => {
    if (row.status > 0) {
      return true;
    } else {
      return false;
    }
  };

  const is_removable = (cell, row) => {
    return row.status_id === 0 || row.status_id === 11 || row.status_id === 12;
  };

  const getOptions = (cell, row) => {
    console.log('getOptions - setIsRemovableData - cell', cell);
    console.log('getOptions - setIsRemovableData - row', row);
    console.log('getOptions - setIsRemovableData - isRemovableData', isRemovableData);
  };

  return (
      <div className={`billing-run-tools`}>
        <h1 className="text-bold mb-3">Billing run</h1>
        <NavTabsFinance />
        <React.Fragment>
          <ParentTable
              id="billing_run_dates"
              table="billing_run_dates"
              classes="custom-tbl-invoice mb-3"
              options={getOptions}
              data={GetAllTenantBillingRunDatesSummary}
              keyField={"billing_run_id"}
              remote
              columns={[{
                dataField: "billing_run_id",
                text: '#ID',
                headerStyle: () => {
                  return { width: '7%'}
                },
                sort: true,
                attrs: {
                  'data-label': 'ID'
                }
              }, {
                dataField: "date",
                text: 'Date',
                headerStyle: () => {
                  return { width: '10%'}
                },
                formatter: formatDate,
                sort: true,
                attrs: {
                  'data-label': 'Date'
                }
              }, {
                dataField: "status_id",
                text: 'Status code',
                headerStyle: () => {
                  return {width: '10%'}
                },
                editable: (cell, row, rowIndex, colIndex) => {
                  if (cell === 0 || cell === 11 || cell === 12) {
                    console.log('true');
                    return true;
                  } else {
                    console.log('false');
                    return false;
                  }
                },
                sort: true,
                attrs: {
                  'data-label': 'Status code'
                }
              }, {
                dataField: "is_removable",
                text: 'Removable',
                hidden: true,
                formatter: is_removable,
                attrs: {
                  'data-label': 'Removable'
                }
              }, {
                dataField: "status_label",
                text: 'Status label',
                sort: true,
                headerStyle: () => {
                  return { width: '10%' }
                },
                attrs: {
                  'data-label': 'Status label'
                }
              }, {
                dataField: "sales_invoice_count",
                text: 'Invoices',
                align: 'center',
                classes: 'inv-count',
                sort: true,
                headerStyle: () => {
                  return { width: '10%', textAlign: 'center' }
                },
                attrs: {
                  'data-label': 'Invoices'
                }
              }, {
                dataField: "price_sum",
                text: 'Price',
                formatter: formatCurrency,
                align: 'right',
                sort: true,
                headerStyle: () => {
                  return { width: '15%', textAlign: 'right' }
                },
                attrs: {
                  'data-label': 'Price'
                }
              }, {
                dataField: "price_vat_sum",
                text: 'VAT',
                formatter: formatCurrency,
                align: 'right',
                sort: true,
                headerStyle: () => {
                  return { width: '15%', textAlign: 'right' }
                },
                attrs: {
                  'data-label': 'VAT'
                }
              },  {
                dataField: "price_total_sum",
                text: 'Total',
                formatter: formatCurrency,
                align: 'right',
                sort: true,
                headerStyle: () => {
                  return { width: '15%', textAlign: 'right' }
                },
                attrs: {
                  'data-label': 'Total'
                }
              }]}
              page={pageNum ? parseInt(pageNum) : 1}
              sizePerPage={sizePerPage ? parseInt(sizePerPage) : 10}
              totalSize={totalSize ? parseInt(totalSize) : 10}
              //sortItem={sort ? sort : 'date'}
              sort={'-billing_run_id'}
              noSearch
              noHover={true}
              onTableChange={handleTableChange}
              wrapperClasses="table-billing-run-overview f2x-custom-table"
              rowAction="delete"
              rowDropdownOpts={[{
                action: 'Remove',
                label: 'Remove billing run',
                form: RemoveBillingRunForm,
                  condition: {
                    data: 'is_removable'
                  }
                }, {
                action: 'Download',
                label: 'Download direct debit file',
                form: DownloadBillingRunForm,
                condition: {
                  data: 'dd_file'
                }
              }]}
          />
        </React.Fragment>
      </div>
  )
};

export default withRouter(withTranslation()(connect()(BillingRunOverview)));
