import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Map, Marker, Popup, NavigationControl } from 'react-map-gl';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import mapboxgl, { accessToken } from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import { CalcRouteDirection } from './CalcRouteDirection';
import { Button, Input, Label } from "reactstrap";
import Toast from '../../components/Toast';

// Define your Mapbox access token here
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic3RhbmxleWJhdGVzd2FyIiwiYSI6ImNsdnFvejJxdzBkZjkya21zdWZ1dXF6NGoifQ.O0tzH8bpKmS2ulMoRRQBfQ';
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const InitMap = ({ ...props }) => {
    console.log("InitMap - props ->", props);
    //const mapContainer = useRef(null);    
    const width = '100%';
    const map_height = 400; // default
    const map_zoom = 11;
    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

    const [navigateToAddressBtnDisabled, setNavigateToAddressBtnDisabled] = useState(false);
    const [enableRoute, setEnableRoute] = useState(true);
    const [locationLng, setLocationLng] = useState(null);
    const [locationLat, setLocationLat] = useState(null);
    const [userAddress, setUserAddress] = useState("");
    const [map, setMap] = useState(null);
    const [height, setHeight] = useState(map_height);
    const [zoom, setZoom] = useState(map_zoom);
    const [mapBox, setMapBox] = useState(null);

    const [marker, setMarker] = useState({
        latitude: null, //viewport.latitude, 
        longitude: null, //viewport.longitude
    });
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupInfo, setPopupInfo] = useState({
        latitude: null,
        longitude: null,
        address: {
            'street': null,
            'house_number': null,
            'zip_code': null,
            'city_name': null
        }
    });

    const handleUserAddressChange = (e) => {
        setUserAddress(e.target.value);
    }

    const navigateToAddress = (e) => {
        e.preventDefault(); 

        const { locationAddress } = props;
        if(!userAddress) {            
            return Toast.fire({ title: 'Direction field is required!', icon: 'warning' })
        } else {
            setNavigateToAddressBtnDisabled(true);
            CalcRouteDirection({ locationLng, locationLat }, userAddress, userAddress, map, mapBox, locationAddress);
        }
        //console.log("InitMap - navigateToAddress - props ->", props);
        //console.log("InitMap - navigateToAddress - CalcRouteDirection ->", CalcRouteDirection);
        //console.log("InitMap - navigateToAddress - map ->", map);
    }

    function handleMarkerClick() {
        const { lat, lng, locationAddress, selectedData } = props;
        if (locationAddress) {
            // console.log("InitMap - useEffect - locationAddress ->", locationAddress); 
            // console.log("InitMap - useEffect - lat ->", lat); 
            // console.log("InitMap - useEffect - lng ->", lng); 
            //console.log("InitMap - useEffect - refs ->", refs); 

            setLocationLat(lat);
            setLocationLng(lng);

            setPopupInfo({
                latitude: lat,
                longitude: lng,
                address: {
                    'street': locationAddress.street1 ? locationAddress.street1 : "",
                    'house_number': locationAddress.house_number ? locationAddress.house_number : "",
                    'zip_code': locationAddress.zipcode ? locationAddress.zipcode : "",
                    'city_name': selectedData && selectedData.city_name ? selectedData.city_name : ""
                }
            });

            setPopupOpen(enableRoute);
        }        
    }

    const closePopupInfo = () => {
        setPopupInfo({
            latitude: null,
            longitude: null,
            address: {
                'street': "",
                'house_number': "",
                'zip_code': "",
                'city_name': ""
            }
        })
        setPopupOpen(false);
    }

    useEffect(() => {
        const { lat, lng, locationAddress, selectedData, mapHeight, mapZoom, enableRoute = true } = props; 
        
        console.log("InitMap - useEffect - props ->", props);
        // check height
        if (mapHeight) {
            setHeight(mapHeight);
        }

        if(mapZoom) {
            setZoom(mapZoom)
        }
        
        setEnableRoute(enableRoute);
        if (locationAddress) {
            console.log("InitMap - useEffect - locationAddress ->", locationAddress);
            console.log("InitMap - useEffect - lat ->", lat);
            console.log("InitMap - useEffect - lng ->", lng);
            //console.log("InitMap - useEffect - refs ->", refs); 

            setLocationLat(lat);
            setLocationLng(lng);

            setPopupInfo({
                latitude: lat,
                longitude: lng,
                address: {
                    'street': locationAddress.street1 ? locationAddress.street1 : "",
                    'house_number': locationAddress.house_number ? locationAddress.house_number : "",
                    'zip_code': locationAddress.zipcode ? locationAddress.zipcode : "",
                    'city_name': selectedData && selectedData.city_name ? selectedData.city_name : ""
                }
            })
        }
    }, []);

    return (
        <>
            {locationLng && locationLat &&
                <Map
                    mapboxAccessToken={mapboxgl.accessToken}
                    initialViewState={{
                        longitude: locationLng,
                        latitude: locationLat,
                        zoom: zoom
                    }}
                    style={{
                        width: width,
                        height: height
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    //onViewportChange={(viewport) =>
                        //setMarker({
                        //    latitude: viewport.latitude,
                        //    longitude: viewport.longitude
                        //})
                    //}
                    ref={(mapComponent) => {
                        console.log("InitMap - mapComponent ->", mapComponent);
                        let mymap = mapComponent && mapComponent.getMap();
                        if (mymap) {
                            console.log("InitMap - mymap ->", mymap);
                            //console.log("InitMap - mapboxgl ->", mapboxgl);
                            //console.log("InitMap - Map ->", Map);
                            setMap(mymap);
                            //setMapBox(Map);
                            return mymap;
                        }
                    }}
                >
                    {popupOpen && popupInfo && (
                        <Popup
                            style={{ maxWidth: "300px", width: "300px" }}
                            latitude={popupInfo.latitude}
                            longitude={popupInfo.longitude}
                            closeButton={true}
                            closeOnClick={false}
                            onClose={closePopupInfo}
                        >
                            <div>
                                <h3>Directions to address</h3>
                                <p>
                                    {popupInfo && popupInfo.address ? popupInfo.address.street : ""} {popupInfo && popupInfo.address ? popupInfo.address.house_number : ""} <br />
                                    {popupInfo && popupInfo.address ? popupInfo.address.zip_code : ""} {popupInfo && popupInfo.address ? popupInfo.address.city_name : ""}
                                </p>
                                <div className='mb-1' style={{ alignItems: "center" }}>
                                    <Label style={{marginBottom: "0", widht: "100%" }}>Enter address from &nbsp;</Label>
                                    <Input
                                        bsSize="sm"
                                        id="fromAddress"
                                        type="text"
                                        value={userAddress}
                                        onChange={handleUserAddressChange}
                                        placeholder='e.g. Transistorstraat 7, 1322 CJ Almere'
                                        style={{ widht: "100%" }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        disabled={navigateToAddressBtnDisabled}
                                        color="primary"
                                        size="sm"
                                        onClick={(e) => navigateToAddress(e)}>
                                            Get directions
                                    </Button>
                                </div>
                            </div>
                        </Popup>
                    )}
                    <Marker
                        latitude={locationLat}
                        longitude={locationLng}
                        offsetTop={-20}
                        offsetLeft={-10}
                        onClick={handleMarkerClick}
                    />
                    <NavigationControl />
                </Map>}
        </>
    );
};

export default InitMap;
