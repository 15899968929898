import { faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { customFilter } from 'react-bootstrap-table2-filter';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import CustomFilterDateComp from '../../components/filters/CustomFilterDateComp';
import CustomFilterInput from '../../components/filters/CustomFilterInput';
import CustomFilterInputComp from '../../components/filters/CustomFilterInputComp';
import CustomFilterSelect from '../../components/filters/CustomFilterSelect';
import ParentTable from '../../components/ParentTable';
import { GetOperators } from '../../controllers/networks';
import { GetAdminPlans, GetPlans } from '../../controllers/plans';
import { parseDateToSaveFormatObject } from '../../redux/actions/formatActions';
import DuplicatePlanForm from './DuplicatePlan';
import PlanForm from './Form';
import NavPlanTabs from './NavPlanTabs';
import RemovePlanForm from './RemovePlan';

const Plans = ({ ...props }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { dispatch, loader, user, tenants } = props;
  const tableName = "Plans";
  const tableNameUser = "PlansUser";
  const tableNameAdmin = "PlansAdmin";
  const userJsonData = props.user && props.user.json_data ? JSON.parse(props.user.json_data) : null;
  const userSettingsItemsPerPage = userJsonData && userJsonData.itemsPerPageTables ? parseInt(userJsonData.itemsPerPageTables) : 100; // 100 => default (if no userSettings available)
  const [toggleFilterForm, setToggleFilterForm] = useState(false);  
  const [collapseStatePlansUser, setCollapseStatePlansUser] = useState("is-hidden");
  const [collapseStatePlansAdmin, setCollapseStatePlansAdmin] = useState("is-hidden");
  const [clearFilters, setClearFilters] = useState(false);

  let [tenantIdFilter, setTenantIdFilter] = useState(null);
  let [descriptionFilter, setDescriptionFilter] = useState(null);
  let [planStartFilter, setPlanStartFilter] = useState(null);
  let [planStopFilter, setPlanStopFilter] = useState(null);
  let [priorityFilter, setPriorityFilter] = useState(null);
  let [costsFilter, setCostsFilter] = useState(null);
  let [operatorL1Filter, setOperatorL1Filter] = useState([]); // if multiSelect column -> set to [] as initialState
  let [operatorL2Filter, setOperatorL2Filter] = useState([]); // if multiSelect column -> set to [] as initialState
  let [operatorL3Filter, setOperatorL3Filter] = useState([]); // if multiSelect column -> set to [] as initialState

  /* start filters - state variables */
  const [data, setData] = useState([]);
  const [tenantId, setTenantId] = useState("");
  const [description, setDescription] = useState("");
  const [planStart, setPlanStart] = useState("");
  const [planStop, setPlanStop] = useState("");
  const [priority, setPriority] = useState("");
  const [costs, setCosts] = useState("");
  const [operatorL1, setOperatorL1] = useState("");
  const [operatorL2, setOperatorL2] = useState("");
  const [operatorL3, setOperatorL3] = useState("");
  const [sort, setSort] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(userSettingsItemsPerPage);
  const [paginationCount, setPaginationCount] = useState(1);

  let [filtersFilter, setFiltersFilter] = useState({});
  let [filters, setFilters] = useState({ filter: null, keyword: "" });

  const [enabledFilter, setEnabledFilter] = useState(props.enabledFilter ? props.enabledFilter : false);
  const clearedItemName = useRef("");
  const [comparatorFilter, setComparatorFilter] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoadData, setIsLoadData] = useState(false);  
  const [isAdmin, setIsAdmin] = useState(false);

  let basePath = `${location.pathname.split('/')[1]}`;
  
  const [controllerApi, setControllerApi] = useState(basePath && basePath === 'admin' ? "admin" : "user");
  const [tenantsList, setTenantsList] = useState(null);
  const [networkOperators, setNetworkOperators] = useState(null);
  const [networkOperatorsL1, setNetworkOperatorsL1] = useState(null);
  const [networkOperatorsL2, setNetworkOperatorsL2] = useState(null);
  const [networkOperatorsL3, setNetworkOperatorsL3] = useState(null);

  const formatTenant = (cell) => {
    const tenant = Object.values(tenants).find(i => parseInt(i.id) === parseInt(cell));
    if (tenant) {
      return tenant.name;
    }
    return String.fromCharCode(8212);
  };

  const formatCurrency = (cell) => {
    let floatCell = parseFloat(cell ? cell : 0);
    let floatStyle = floatCell && floatCell < 0 ? 'float-negative' : floatCell && floatCell > 0 ? 'float-positive' : 'float-null';
    return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(floatCell) })}</span>
  };

  const formatDate = (cell) => {
    if (cell) {
      return <span className="date-format">{t("date_format",
        { date: new Date(cell) })}</span>
    } else {
      return String.fromCharCode(8212);
    }
  };

  const formatNetworkOperator = (cell) => {
    if (cell && networkOperators && networkOperators.length > 0) {
      let operator = networkOperators.find(item => item.value === cell);
      if (operator && operator.label) {
        return operator.label;
      } else {
        return String.fromCharCode(8212);
      }
    } else {
      return String.fromCharCode(8212);
    }
  };

  const formatPriority = (cell) => {
    if (cell) {
      return cell;
    }
    return String.fromCharCode(8212);
  };

  /* Filter */
  const handleFiltering = (e, name, multiSelectable, comparator = null) => {
    //console.log("PlansList (table) - handleFiltering -> check (before setting filter)");
    //console.log("PlansList (table) - handleFiltering - comparatorFilter - comparator ->", comparator);

    let filterStoredItem;
    let historyFilter = false;
    // custom comparator
    if (comparator) {
      setComparatorFilter({
        ...comparatorFilter,
        [name]: comparator
      });
    }

    if (!enabledFilter) {
      let filtersObj = sessionStorage.getItem(controllerApi === "admin" ? "qStrPlansAdminFiltersObj" : "qStrPlansUserFiltersObj");
      if (filtersObj) {
        let parsedFiltersObj = JSON.parse(filtersObj);
        if (parsedFiltersObj && parsedFiltersObj.filter) {
          historyFilter = true;
          //setCollapseState("is-visible");
          setToggleFilterForm(true);

          if (parsedFiltersObj.filter) {
            filtersFilter = parsedFiltersObj.filter;
            let searchedKeyword = sessionStorage.getItem(controllerApi === "admin" ? "qStrPlansAdminKeyword" : "qStrPlansUserKeyword");
            if (searchedKeyword) {
              parsedFiltersObj.keyword = searchedKeyword;
            }
            setFiltersFilter(filtersFilter);
          }
          filters = parsedFiltersObj;
          setFilters(filters);
        }
      }
    }

    if (
      (name && e && (e.value !== "" || e.value === "")) ||
      (name && e === null) ||
      (e && e.key && e.key === "Enter") ||
      (e && multiSelectable && (e.length > 0 || e.length === null || e.length === 0)) ||
      historyFilter
    ) {
      let value = "";
      switch (name) {
        case "tenant_id":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["tenant_id"]
              ? filtersFilter["tenant_id"]
              : null;

            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setTenantId(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setTenantId(!enabledFilter && filtersFilter && filtersFilter["tenant_id"] ? filtersFilter["tenant_id"] : null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["tenant_id"] ? filtersFilter["tenant_id"] : "";
            if (value === "") {
              value = null;
            }
            setTenantId(value);
          }
          break;
        case "description":
          let description = e && e.target && e.target.value
            ? e.target.value
            : !enabledFilter && filtersFilter && filtersFilter["description"] ? filtersFilter["description"] : "";
          if (description === "") {
            description = null;
          }
          value = description;
          setDescription(value);
          break;

        case "plan_start":
          if (e && e.target && e.target.attributes && e.target.attributes.name && e.target.attributes.name.value && e.target.attributes.name.value === "filter-date-clear-icon") {
            value = null;
          } else {
            let plan_start = e ? e : !enabledFilter && filtersFilter &&
              filtersFilter["plan_start"]
              ? filtersFilter["plan_start"]
              : "";

            if (plan_start === "") {
              value = null;
            } else {
              let validateDate = parseDateToSaveFormatObject(new Date(plan_start));
              if (validateDate === "" && e && e.value) {
                let convertDateArr = e.value.split("-");
                value = convertDateArr[2] + "-" + convertDateArr[1] + "-" + convertDateArr[0];
              } else {
                value = plan_start && plan_start !== "" ? parseDateToSaveFormatObject(new Date(plan_start)) : "";
              }
            }
          }
          setPlanStart(value);
          break;
        case "plan_stop":
          if (e && e.target && e.target.attributes && e.target.attributes.name && e.target.attributes.name.value && e.target.attributes.name.value === "filter-date-clear-icon") {
            value = null;
          } else {
            let plan_stop = e ? e : !enabledFilter && filtersFilter &&
              filtersFilter["plan_stop"]
              ? filtersFilter["plan_stop"]
              : "";

            if (plan_stop === "") {
              value = null;
            } else {
              let validateDate = parseDateToSaveFormatObject(new Date(plan_stop));
              if (validateDate === "" && e && e.value) {
                let convertDateArr = e.value.split("-");
                value = convertDateArr[2] + "-" + convertDateArr[1] + "-" + convertDateArr[0];
              } else {
                value = plan_stop && plan_stop !== "" ? parseDateToSaveFormatObject(new Date(plan_stop)) : "";
              }
            }
          }
          setPlanStop(value);
          break;
        case "priority":
          let priority = e && e.target && e.target.value
            ? e.target.value
            : !enabledFilter && filtersFilter && filtersFilter["priority"] ? filtersFilter["priority"] : "";
          if (priority === "") {
            priority = null;
          }
          value = priority;
          setPriority(value);
          break;
        case "costs":
          //console.log("costs - e ->", e);
          let costs = e && e.target && e.target.value
            ? e.target.value
            : e && e.value
              ? e.value
              : !enabledFilter && filtersFilter && filtersFilter["costs"] ? filtersFilter["costs"] : "";
          if (costs === "") {
            costs = null;
          }
          value = costs;
          setCosts(value);
          break;
        case "operator_L1_id":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["operator_L1_id"]
              ? filtersFilter["operator_L1_id"]
              : null;

            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setOperatorL1(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setOperatorL1(!enabledFilter && filtersFilter && filtersFilter["operator_L1_id"] ? filtersFilter["operator_L1_id"] : null);
              //setOperatorL1(null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["operator_L1_id"] ? filtersFilter["operator_L1_id"] : "";
            if (value === "") {
              value = null;
            }
            setOperatorL1(value);
          }
          break;
        case "operator_L2_id":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["operator_L2_id"]
              ? filtersFilter["operator_L2_id"]
              : null;

            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setOperatorL2(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setOperatorL2(!enabledFilter && filtersFilter && filtersFilter["operator_L2_id"] ? filtersFilter["operator_L2_id"] : null);
              //setOperatorL2(null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["operator_L2_id"] ? filtersFilter["operator_L2_id"] : "";
            if (value === "") {
              value = null;
            }
            setOperatorL2(value);
          }
          break;
        case "operator_L3_id":
          //console.log();
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["operator_L3_id"]
              ? filtersFilter["operator_L3_id"]
              : null;

            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setOperatorL3(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setOperatorL3(!enabledFilter && filtersFilter && filtersFilter["operator_L3_id"] ? filtersFilter["operator_L3_id"] : null);
              //setOperatorL3(null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["operator_L3_id"] ? filtersFilter["operator_L3_id"] : "";
            if (value === "") {
              value = null;
            }
            setOperatorL3(value);
          }
          break;

        default:
          break;
      }

      if (filtersFilter) {
        if (filtersFilter[name] && (!value || value === "" || value.length === 0)) {
          delete filtersFilter[name];
        } else {
          if (value) {
            filtersFilter[name] = value;
          }
        }

        if (Object.entries(filtersFilter).length > 0) {
          setFiltersFilter(filtersFilter);
          filters.filter = filtersFilter;
          setFilters(filters);
          setQueryParameters();
          setIsLoadData(true);
        } else {
          filters.filter = filtersFilter;
          setFilters(filters);
          setQueryParameters();
          setIsLoadData(true);
        }
      }
    }
  };

  /* Clear filter */
  const handleFilterClick = (e, props) => {
    if (e) {
      e.preventDefault();
    }

    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdmin" : "qStrPlansUser"); // qStrPlans
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminFilter" : "qStrPlansUserFilter"); // qStrPlansFilter
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminFilterStr" : "qStrPlansUserFilterStr"); // qStrPlansFilterStr
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminFiltersObj" : "qStrPlansUserFiltersObj"); // qStrPlansFiltersObj
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminKeyword" : "qStrPlansUserKeyword"); // qStrPlansKeyword
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminComparatorItems" : "qStrPlansUserComparatorItems"); // qStrPlansComparatorItems

    setTenantIdFilter([]);
    setDescriptionFilter([]);
    setPlanStartFilter([]);
    setPlanStopFilter([]);
    setPriorityFilter([]);
    setCostsFilter([]);
    setOperatorL1Filter([]);
    setOperatorL2Filter([]);
    setOperatorL3Filter([]);

    setTenantId("");
    setDescription("");
    setPlanStart("");
    setPlanStop("");
    setPriority("");
    setCosts("");
    setOperatorL1("");
    setOperatorL2("");
    setOperatorL3("");

    setPage(1);
    setSizePerPage(100); // default (new)
    setFiltersFilter({});
    setFilters({ filter: null, keyword: "" });
    setClearFilters(true);
    setPaginationCount(1);
    setEnabledFilter(false);
    setIsLoadData(true);

    setQueryParameters();

    let input = document.getElementById("table-search-form");
    input.value = '';
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  };

  useEffect(() => {
    if (location && location.pathname) {
      const pathname = location.pathname;

      let basePath = `${location.pathname.split('/')[1]}`;
      let basePathTo = `${location.pathname.split('/')[2]}`;

      if (basePath && basePath === 'admin' && basePathTo && basePathTo === "plans") {
        setIsAdmin(true);
        setControllerApi("admin");
      } else {
        setControllerApi("user");
      }
    } else {
      setControllerApi("user");
    }
  }, []);

  const processMultiValues = (multiValueArr) => {
    let multiValues = [];
    for (let i = 0; i < multiValueArr.length; i++) {
      if (multiValueArr[i] && multiValueArr[i].value) {
        multiValues.push(multiValueArr[i].value);
      }
    }
    return multiValues.join(",");
  };

  const processKey = (key, value, queryFilter, comparatorItems) => {
    switch (key) {
      case "tenant_id":
      case "operator_L1_id":
      case "operator_L2_id":
      case "operator_L3_id":
        let multiKeyFilters = processMultiValues(value[key]);
        queryFilter.push(`${key}=[${multiKeyFilters}]`);
        break;
      case "plan_start":
      case "plan_stop":
      case "costs":
        queryFilter.push(`${key}${comparatorItems && comparatorItems[key] ? comparatorItems[key] : ">="}${value[key]}`);
        break;
      default:
        queryFilter.push(`${key}%${value[key]}`);
        break;
    }
  }

  const setQueryParameters = () => {
    //console.log("PlansList (table) - setQueryParameters - history_param ->", history_param);
    // get history (default) params
    let params = {};
    let historyParamsQueryString;

    if (controllerApi) {
      historyParamsQueryString = sessionStorage.getItem(controllerApi === "admin" ? "qStrPlansAdmin" : "qStrPlansUser");
    } else {
      let basePath = `${location.pathname.split('/')[1]}`;
      if (basePath && basePath === 'admin') {
        historyParamsQueryString = sessionStorage.getItem("qStrPlansAdmin");
      } else {
        historyParamsQueryString = sessionStorage.getItem("qStrPlansUser");
      }
    }

    //const historyParamsQueryString = sessionStorage.getItem(controllerApi === "admin" ? "qStrPlansAdmin" : "qStrPlansUser");
    //console.log("PlansList (table) - setQueryParameters - controllerApi ->", controllerApi);
    //console.log("PlansList (table) - setQueryParameters - historyParamsQueryString ->", historyParamsQueryString);

    if (historyParamsQueryString) {
      // page - offset - count - sort - search
      historyParamsQueryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
        if (key === 'page' || key === 'offset' || key === 'count') {
          params[key] = parseInt(value);
        } else if (key === 'sort') {
          params[key] = value;
        } else if (key === 'search') {
          params[key] = value;
        } else if (key === 'filter') {
          params[key] = value;
        }
      });
    }

    //console.log("PlansList (table) - setQueryParameters - params ->", params);
    //console.log("PlansList (table) - setQueryParameters - filters ->", filters);

    const savedComparators = sessionStorage.getItem(controllerApi === "admin" ? "qStrPlansAdminComparatorItems" : "qStrPlansUserComparatorItems");
    const savedComparatorsParsed = savedComparators ? JSON.parse(savedComparators) : null;

    let key;
    let value;
    let query = [];
    let searchFilterQuery = {};
    let filterQuery = {};
    let searchFilterStr = "";
    let comparatorItems = comparatorFilter ? comparatorFilter : {};

    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - comparatorItems ->", comparatorItems);
    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - savedComparatorsParsed ->", savedComparatorsParsed);
    if (savedComparatorsParsed && !isEmpty(savedComparatorsParsed)) {
      comparatorItems = { ...comparatorFilter, ...savedComparatorsParsed };
    }
    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - comparatorItems ->", comparatorItems);

    query.push(`count=1`);
    if (!filters) {
      query.push(`search=`);
      query.push(`filter=`);
    }
    query.push(`offset=${params && params.offset ? params.offset : sizePerPage ? sizePerPage : 10}`);
    query.push(`page=${params && params.page ? params.page : page ? page : 1}`);
    query.push(`sort=${params && params.sort ? params.sort : sort ? sort : ""}`);

    // remove filters from session storage
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdmin" : "qStrPlansUser");
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminFilter" : "qStrPlansUserFilter");
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminFilterStr" : "qStrPlansUserFilterStr");
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminFiltersObj" : "qStrPlansUserFiltersObj");
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminComparatorItems" : "qStrPlansUserComparatorItems");
    sessionStorage.removeItem(controllerApi === "admin" ? "qStrPlansAdminKeyword" : "qStrPlansUserKeyword");

    //if (filters && (filters.filter || filters.keyword)) {
    for (key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (key === "keyword") {
          value = filters[key];
          searchFilterQuery[key] = value && true ? value : "";
          // set search for querystring
          query.push(`search=${filters[key]}`);

        } else if (key === "filter") {
          value = filters[key];


          // set total filter
          let queryKeyFilters = "";
          let queryFilters = "";

          // if value is array - value.length > 0 - there are filters
          if (value && Array.isArray(value)) {
            if (value.length > 0) {
              let queryFilter = [];
              for (let i = 0; i < value.length; i++) {
                for (let key in value[i]) {
                  if (value[i].hasOwnProperty(key)) {
                    processKey(key, value[i], queryFilter, comparatorItems);
                  }
                }
              }
              if (queryFilter.length > 0) {
                queryKeyFilters = queryFilter.join(",");
                queryFilters = queryFilter.join("~");
              }
              searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
              filterQuery['filter'] = queryFilters ? queryFilters : "";
            }
          } else {
            let queryFilter = [];
            for (let name in value) {
              if (value.hasOwnProperty(name)) {
                processKey(name, value, queryFilter, comparatorItems);
              }
            }
            if (queryFilter.length > 0) {
              queryKeyFilters = queryFilter.join(",");
              queryFilters = queryFilter.join("~");
            }
            searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
            filterQuery['filter'] = queryFilters ? queryFilters : "";
          }
          query.push(`filter=${queryKeyFilters}`);
        }
      }
    }
    query.join("&").toString();
    setEnabledFilter(true);

    const location_search_query = query;
    let location_search = location_search_query
      ? location_search_query.join("&")
      : "";

    sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdmin" : "qStrPlansUser", location_search);
    sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdminFilter" : "qStrPlansUserFilter", searchFilterQuery['filter'] && searchFilterQuery['filter'] !== "" ? searchFilterQuery['filter'] : "");
    sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdminFilterStr" : "qStrPlansUserFilterStr", filterQuery['filter'] && filterQuery['filter'] !== "" ? filterQuery['filter'] : "");
    sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdminKeyword" : "qStrPlansUserKeyword", searchFilterQuery['keyword'] ? searchFilterQuery['keyword'] : searchKeyword);
    sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdminFiltersObj" : "qStrPlansUserFiltersObj", JSON.stringify(filters));
    sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdminComparatorItems" : "qStrPlansUserComparatorItems", JSON.stringify(comparatorItems));
  };

  useEffect(() => {
    if (clearFilters) {
      setPaginationCount(1);
      setClearFilters(false);
      setIsLoadData(true);
    }
  }, [clearFilters]);

  useEffect(() => {
    if (searchKeyword && searchKeyword !== "") {
      filters.filter = filtersFilter;
      filters.keyword = searchKeyword;
      setFilters(filters);

      sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdminKeyword" : "qStrPlansUserKeyword", searchKeyword);

      setPage(1);
      setPageNum(1);
      setQueryParameters();
      setIsLoadData(true);
    }
  }, [searchKeyword]);

  useEffect(() => {
    (async () => {
      await Promise.all([
        GetOperators(),
      ]).then((r) => {
        const [networkOperatorsData] = r;
        //console.log("PlansList - networkOperatorsData ->", networkOperatorsData);

        let resData = [];
        let operatorsL1 = [];
        let operatorsL2 = [];
        let operatorsL3 = [];

        if (networkOperatorsData && networkOperatorsData.data && networkOperatorsData.data.data) {
          for (let i = 0; i < networkOperatorsData.data.data.length; i++) {
            resData.push({ label: networkOperatorsData.data.data[i].name, value: networkOperatorsData.data.data[i].id });
          }

          networkOperatorsData.data.data.map(({ id, name, L1, L2, L3, provisioning_api, microservice_id }) => {
            if (L1 === 1) {
              return operatorsL1.push({
                label: name,
                value: id,
              });
            }
          });

          networkOperatorsData.data.data.map(({ id, name, L1, L2, L3, provisioning_api, microservice_id }) => {
            if (L2 === 1) {
              return operatorsL2.push({
                label: name,
                value: id,
              });
            }
          });

          networkOperatorsData.data.data.map(({ id, name, L1, L2, L3, provisioning_api, microservice_id }) => {
            if (L3 === 1) {
              return operatorsL3.push({
                label: name,
                value: id,
              });
            }
          });
        }

        setNetworkOperators(resData);
        setNetworkOperatorsL1(operatorsL1);
        setNetworkOperatorsL2(operatorsL2);
        setNetworkOperatorsL3(operatorsL3);
      });
    })();
  }, []);

  useEffect(() => {
    const { tenants } = props;
    //console.log("table plans list - (props - tenants) ->", tenants);

    let tenantsArr = [];
    if (tenants && !isEmpty(tenants)) {
      let key;
      for (key in tenants) {
        if (tenants.hasOwnProperty(key)) {
          //console.log("table plans list - (props - tenants[key]) ->", tenants[key]);
          tenantsArr.push({ value: tenants[key].id, label: tenants[key].name });
        }
      }
      //console.log("table plans list - (props - tenantsArr) ->", tenantsArr);
      setTenantsList(tenantsArr);
    }

  }, []);

  useEffect(() => {
    //console.log("table filter list - (updated - clearedItemName) - clearedItemName ->", clearedItemName);

    let tableStorageFilterObjId = controllerApi === "admin" ? "qStrPlansAdminFiltersObj" : "qStrPlansUserFiltersObj";

    if (clearedItemName && clearedItemName !== "") {
      let name = clearedItemName;
      let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
      //console.log("table filter list - (updated - clearedItemName) - filtersObj ->", filtersObj);

      if (name && filtersObj) {
        let filtersObjParsed = JSON.parse(filtersObj);
        if (filtersObjParsed && filtersObjParsed.filter && filtersObjParsed.filter.length > 0) {
          for (let i = 0; i < filtersObjParsed.filter.length; i++) {
            if (filtersObjParsed.filter[i] && filtersObjParsed.filter[i][name]) {
              filtersObjParsed.filter.splice(i, 1);

              // save filters to session storage (stringify)
              sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdminFiltersObj" : "qStrPlansUserFiltersObj",
                JSON.stringify(filtersObjParsed));

              if (filtersObjParsed.filter &&
                filtersObjParsed.filter.length > 0) {
                setFiltersFilter(filtersObjParsed.filter);
              } else {
                setFiltersFilter([]);
              }
              setFilters(filtersObjParsed);
              return;
            }
          }
        } else if (filtersObjParsed && filtersObjParsed.filter && filtersObjParsed.filter[name]) {
          delete filtersObjParsed.filter[name];
          // save filters to session storage (stringify)
          sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdminFiltersObj" : "qStrPlansUserFiltersObj",
            JSON.stringify(filtersObjParsed));
        }
      }
    } else {
      setFiltersFilter({});
      setFilters({ filter: null, keyword: "" });
      sessionStorage.removeItem(tableStorageFilterObjId);
      setQueryParameters();
    }
  }, [clearedItemName]);

  const collapseFilterAction = (e) => {
    if (e) {
      e.preventDefault();
    }

    let filterVisible = sessionStorage.getItem(controllerApi === "admin" ? "plansAdminFilterVisible" : "plansUserFilterVisible");
    //console.log("PlansList - filterVisible ->", filterVisible);
    //console.log("PlansList - filterVisible - toggleFilterForm ->", toggleFilterForm);

    if (controllerApi === "admin") {
      let collapseStatus = toggleFilterForm === true ? "is-hidden" : "is-visible";
      setCollapseStatePlansAdmin(collapseStatus);
      sessionStorage.setItem("plansAdminFilterVisible", collapseStatus);
    }

    if (controllerApi === "user") {
      let collapseStatus = toggleFilterForm === true ? "is-hidden" : "is-visible";
      setCollapseStatePlansUser(collapseStatus);
      sessionStorage.setItem("plansUserFilterVisible", collapseStatus);
    }

    setToggleFilterForm(!toggleFilterForm);
  };

  useEffect(() => {
    //console.log("PlansList - useEffect - controllerApi ->", controllerApi);

    let filterVisible = sessionStorage.getItem(controllerApi === "admin" ? "plansAdminFilterVisible" : "plansUserFilterVisible");
    if (filterVisible === "is-visible") {
      setToggleFilterForm(true);

      if (controllerApi === "admin") {
        setCollapseStatePlansAdmin("is-visible");
      }
      if (controllerApi === "user") {
        setCollapseStatePlansUser("is-visible");
      }
      sessionStorage.setItem(controllerApi === "admin" ? "plansAdminFilterVisible" : "plansUserFilterVisible", "is-visible");

    } else if (filterVisible === "is-hidden") {
      setToggleFilterForm(false);

      if (controllerApi === "admin") {
        setCollapseStatePlansAdmin("is-hidden");
      }
      if (controllerApi === "user") {
        setCollapseStatePlansUser("is-hidden");
      }
      sessionStorage.setItem(controllerApi === "admin" ? "plansAdminFilterVisible" : "plansUserFilterVisible", "is-hidden");

    } else {
      setToggleFilterForm(false);

      if (controllerApi === "admin") {
        setCollapseStatePlansAdmin("is-hidden");
      }
      if (controllerApi === "user") {
        setCollapseStatePlansUser("is-hidden");
      }

      setFilters({ filter: null, keyword: "" });
      sessionStorage.setItem(controllerApi === "admin" ? "plansAdminFilterVisible" : "plansUserFilterVisible", "is-hidden");
    }
  }, [controllerApi]);

  useEffect(() => {
    let currentParams = new URL(document.location).searchParams;
    //console.log("PlansList - useEffect (params) - currentParams ->", currentParams);
    if (currentParams.size && currentParams.size > 0) {
      //console.log("PlansList - useEffect (params) - currentParams.size ->", currentParams.size);
      let qStr = currentParams.toString();
      //console.log("PlansList - useEffect (params) - qStr ->", qStr);
      sessionStorage.setItem(controllerApi === "admin" ? "qStrPlansAdmin" : "qStrPlansUser", qStr);
      setQueryParameters();
    }
  }, []);

  return (
    <div className="wrapper-box-table-filter">
      <h1 className="text-bold mb-3">Plans</h1>

      {controllerApi === "user" &&
        <NavPlanTabs tabDataFor={controllerApi} />}

      {controllerApi === "user" &&
        <ParentTable
          table="PlansUser"
          id="PlansUserTable"
          keyField="id"
          data={GetPlans}
          remote
          include='parent'
          columns={[
            {
              dataField: "description",
              filter: customFilter({
                type: "text",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setDescriptionFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterInput
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={description ? description : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansUser"
                />
              ),
              title: true,
              text: "Description",
              sort: true,
              headerStyle: () => {
                return { width: '26%' };
              },
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "plan_start",
              filter: customFilter({
                type: "date",
                style: { display: "inline-grid" },
                defaultValue: "", // default value
                getFilter: (filter) => {
                  setPlanStartFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterDateComp
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  icon="left"
                  value={planStart ? planStart : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  setComparatorFilter={setComparatorFilter}
                  comparatorFilter={comparatorFilter}
                  defaultComparator={">="}
                  table="PlansUser"
                />
              ),
              title: true,
              text: "Start",
              sort: true,
              align: "left",
              formatter: formatDate,
              headerStyle: () => {
                return { width: '12%', 'textAlign': 'left' };
              },
              attrs: {
                'data-label': 'Start'
              }
            },
            {
              dataField: "plan_stop",
              filter: customFilter({
                type: "date",
                style: { display: "inline-grid" },
                defaultValue: "", // default value
                getFilter: (filter) => {
                  setPlanStopFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterDateComp
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  icon="left"
                  value={planStop ? planStop : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  setComparatorFilter={setComparatorFilter}
                  comparatorFilter={comparatorFilter}
                  defaultComparator={"<="}
                  table="PlansUser"
                />
              ),
              title: true,
              text: "End",
              sort: true,
              formatter: formatDate,
              headerStyle: () => {
                return { width: '12%' };
              },
              attrs: {
                'data-label': 'End'
              }
            },
            {
              dataField: "priority",
              filter: customFilter({
                type: "text",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setPriorityFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterInput
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={priority ? priority : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansUser"
                />
              ),
              title: true,
              text: "Priority",
              sort: true,
              align: "center",
              formatter: formatPriority,
              headerStyle: () => {
                return { width: '6%', 'textAlign': 'center' };
              },
              attrs: {
                'data-label': 'Priority'
              }
            },
            {
              dataField: "costs",
              filter: customFilter({
                type: "text",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setCostsFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterInputComp
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={costs ? costs : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  setComparatorFilter={setComparatorFilter}
                  comparatorFilter={comparatorFilter}
                  defaultComparator={">"}
                  table="PlansUser"
                />
              ),
              title: true,
              text: "Costs",
              sort: true,
              formatter: formatCurrency,
              //align: "right",
              headerStyle: () => {
                return { width: '10%'/*, 'textAlign': 'right', 'paddingRight': '12px'*/ };
              },
              attrs: {
                'data-label': 'Costs'
              }
            },
            {
              dataField: "operator_L1_id",
              filter: customFilter({
                type: "select",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setOperatorL1Filter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterSelect
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={(e) =>
                    handleFiltering(e, "operator_L1_id", true)
                  }
                  constantObj={networkOperatorsL1}
                  multiSelect={true}
                  clearOnEmpty={handleFilterClick}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={operatorL1 ? operatorL1 : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansUser"
                />
              ),
              title: true,
              text: "L1",
              sort: true,
              classes: 'inv-col-status-label',
              formatter: formatNetworkOperator,
              headerStyle: () => {
                return { width: '10%' };
              },
              attrs: {
                'data-label': 'L1'
              }
            },
            {
              dataField: "operator_L2_id",
              filter: customFilter({
                type: "select",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setOperatorL2Filter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterSelect
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={(e) =>
                    handleFiltering(e, "operator_L2_id", true)
                  }
                  constantObj={networkOperatorsL2}
                  multiSelect={true}
                  clearOnEmpty={handleFilterClick}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={operatorL2 ? operatorL2 : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansUser"
                />
              ),
              title: true,
              text: "L2",
              sort: true,
              classes: 'inv-col-status-label',
              formatter: formatNetworkOperator,
              headerStyle: () => {
                return { width: '10%' };
              },
              attrs: {
                'data-label': 'L2'
              }
            },
            {
              dataField: "operator_L3_id",
              filter: customFilter({
                type: "select",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setOperatorL3Filter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterSelect
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={(e) =>
                    handleFiltering(e, "operator_L3_id", true)
                  }
                  constantObj={networkOperatorsL3}
                  multiSelect={true}
                  clearOnEmpty={handleFilterClick}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={operatorL3 ? operatorL3 : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansUser"
                />
              ),
              title: true,
              text: "L3",
              sort: true,
              classes: 'inv-col-status-label',
              formatter: formatNetworkOperator,
              headerStyle: () => {
                return { width: '10%' };
              },
              attrs: {
                'data-label': 'L3'
              }
            }
          ]}
          rowAction="link"
          headerDropdownOpts={[
            {
              label: "Filter",
              action: "collapseFilterAction",
              icon: <FontAwesomeIcon icon={faFilter} size={`sm`} className={`mr-0`} color={`grey`} />,
              isCustom: true,
              tooltip: true,
              toggleActive: toggleFilterForm ? "active" : ""
            },
            {
              label: "Clear",
              action: "handleFilterClick",
              icon: <FontAwesomeIcon icon={faTrash} size={`sm`} className={`mr-0`} color={`grey`} />,
              isCustom: true,
              tooltip: true
            },
            {
              label: 'Add',
              action: PlanForm
            }
          ]}
          rowDropdownOpts={[
            {
              action: 'Remove',
              label: 'Remove',
              form: RemovePlanForm
            },
            {
              action: 'Duplicate',
              label: 'Duplicate',
              form: DuplicatePlanForm
            }
          ]}
          notLoadedByDefault={false}
          offLoader={false}
          striped={true}
          noDataIndication="No plans found."
          noHover={false}
          noTopBorder={true}
          handleFilterClick={handleFilterClick}
          collapseFilterAction={collapseFilterAction}
          filters={filters}
          noSearch={false}
          cacheSearch={true}
          filterSearch={true}
          enabledFilter={enabledFilter}
          isLoadData={isLoadData}
          setIsLoadData={setIsLoadData}
          collapseFilter={collapseStatePlansUser}
          isClearFilter={clearFilters}
          bodyClasses="table-filter-body-collapse"
          classes="table-responsive table-filter-collapse filters mb-0 plans-table filter-tbl-res"
          wrapperClasses="table-plans f2x-custom-table table-filter-search-wrapper"
          page={page}
        />}

      {controllerApi === "admin" &&
        <ParentTable
          table="PlansAdmin"
          id="PlansAdminTable"
          keyField="id"
          data={GetAdminPlans}
          remote
          include='parent'
          columns={[
            {
              dataField: "tenant_id",
              filter: customFilter({
                type: "select",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setTenantIdFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterSelect
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={(e) =>
                    handleFiltering(e, "tenant_id", true)
                  }
                  constantObj={tenantsList}
                  multiSelect={true}
                  clearOnEmpty={handleFilterClick}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={tenantId ? tenantId : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "Tenant",
              sort: true,
              headerStyle: () => {
                return { width: '10%' };
              },
              formatter: formatTenant,
              attrs: {
                'data-label': 'Tenant'
              }
            },
            {
              dataField: "description",
              filter: customFilter({
                type: "text",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setDescriptionFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterInput
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={description ? description : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "Description",
              sort: true,
              headerStyle: () => {
                return { width: '18%' };
              },
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "plan_start",
              filter: customFilter({
                type: "date",
                style: { display: "inline-grid" },
                defaultValue: "", // default value
                getFilter: (filter) => {
                  setPlanStartFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterDateComp
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  icon="left"
                  value={planStart ? planStart : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  setComparatorFilter={setComparatorFilter}
                  comparatorFilter={comparatorFilter}
                  defaultComparator={">="}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "Start",
              sort: true,
              align: "left",
              formatter: formatDate,
              headerStyle: () => {
                return { width: '8%', 'textAlign': 'left' };
              },
              attrs: {
                'data-label': 'Start'
              }
            },
            {
              dataField: "plan_stop",
              filter: customFilter({
                type: "date",
                style: { display: "inline-grid" },
                defaultValue: "", // default value
                getFilter: (filter) => {
                  setPlanStopFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterDateComp
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  icon="left"
                  value={planStop ? planStop : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  setComparatorFilter={setComparatorFilter}
                  comparatorFilter={comparatorFilter}
                  defaultComparator={"<="}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "End",
              sort: true,
              formatter: formatDate,
              headerStyle: () => {
                return { width: '8%', 'textAlign': 'left' };
              },
              attrs: {
                'data-label': 'End'
              }
            },
            {
              dataField: "priority",
              filter: customFilter({
                type: "text",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setPriorityFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterInput
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={priority ? priority : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "Priority",
              sort: true,
              align: "center",
              formatter: formatPriority,
              headerStyle: () => {
                return { width: '6%', 'textAlign': 'center' };
              },
              attrs: {
                'data-label': 'Priority'
              }
            },
            {
              dataField: "costs",
              filter: customFilter({
                type: "text",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setCostsFilter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterInputComp
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={handleFiltering}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={costs ? costs : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  setComparatorFilter={setComparatorFilter}
                  comparatorFilter={comparatorFilter}
                  defaultComparator={">"}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "Costs",
              sort: true,
              formatter: formatCurrency,
              //align: "right",
              headerStyle: () => {
                return { width: '10%'/*, 'textAlign': 'right', 'paddingRight': '12px'*/ };
              },
              attrs: {
                'data-label': 'Costs'
              }
            },
            {
              dataField: "operator_L1_id",
              filter: customFilter({
                type: "select",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setOperatorL1Filter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterSelect
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={(e) =>
                    handleFiltering(e, "operator_L1_id", true)
                  }
                  constantObj={networkOperatorsL1}
                  multiSelect={true}
                  clearOnEmpty={handleFilterClick}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={operatorL1 ? operatorL1 : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "L1",
              sort: true,
              classes: 'inv-col-status-label',
              formatter: formatNetworkOperator,
              headerStyle: () => {
                return { width: '12%' };
              },
              attrs: {
                'data-label': 'L1'
              }
            },
            {
              dataField: "operator_L2_id",
              filter: customFilter({
                type: "select",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setOperatorL2Filter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterSelect
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={(e) =>
                    handleFiltering(e, "operator_L2_id", true)
                  }
                  constantObj={networkOperatorsL2}
                  multiSelect={true}
                  clearOnEmpty={handleFilterClick}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={operatorL2 ? operatorL2 : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "L2",
              sort: true,
              classes: 'inv-col-status-label',
              formatter: formatNetworkOperator,
              headerStyle: () => {
                return { width: '12%' };
              },
              attrs: {
                'data-label': 'L2'
              }
            },
            {
              dataField: "operator_L3_id",
              filter: customFilter({
                type: "select",
                style: { display: "inline-grid" },
                getFilter: (filter) => {
                  setOperatorL3Filter(filter);
                },
              }),
              filterRenderer: (onFilter, column) => (
                <CustomFilterSelect
                  onFilter={onFilter}
                  column={column}
                  handleFiltering={(e) =>
                    handleFiltering(e, "operator_L3_id", true)
                  }
                  constantObj={networkOperatorsL3}
                  multiSelect={true}
                  clearOnEmpty={handleFilterClick}
                  isClearFilter={clearFilters}
                  setClearedItemName={(value) => clearedItemName.current = value}
                  value={operatorL3 ? operatorL3 : ""}
                  enabledFilter={enabledFilter}
                  setEnabledFilter={setEnabledFilter}
                  table="PlansAdmin"
                />
              ),
              title: true,
              text: "L3",
              sort: true,
              classes: 'inv-col-status-label',
              formatter: formatNetworkOperator,
              headerStyle: () => {
                return { width: '12%' };
              },
              attrs: {
                'data-label': 'L3'
              }
            }
          ]}
          rowAction="link"
          headerDropdownOpts={[
            {
              label: "Filter",
              action: "collapseFilterAction",
              icon: <FontAwesomeIcon icon={faFilter} size={`sm`} className={`mr-0`} color={`grey`} />,
              isCustom: true,
              tooltip: true,
              toggleActive: toggleFilterForm ? "active" : ""
            },
            {
              label: "Clear",
              action: "handleFilterClick",
              icon: <FontAwesomeIcon icon={faTrash} size={`sm`} className={`mr-0`} color={`grey`} />,
              isCustom: true,
              tooltip: true
            },
            {
              label: 'Add',
              action: PlanForm
            }
          ]}
          rowDropdownOpts={[
            {
              action: 'Remove',
              label: 'Remove',
              form: RemovePlanForm
            },
            {
              action: 'Duplicate',
              label: 'Duplicate',
              form: DuplicatePlanForm
            }
          ]}
          notLoadedByDefault={false}
          offLoader={false}
          striped={true}
          noDataIndication="No plans found."
          noHover={false}
          noTopBorder={true}
          handleFilterClick={handleFilterClick}
          collapseFilterAction={collapseFilterAction}
          filters={filters}
          noSearch={false}
          cacheSearch={true}
          filterSearch={true}
          enabledFilter={enabledFilter}
          isLoadData={isLoadData}
          setIsLoadData={setIsLoadData}
          collapseFilter={collapseStatePlansAdmin}
          isClearFilter={clearFilters}
          bodyClasses="table-filter-body-collapse"
          classes="table-responsive table-filter-collapse filters mb-0"
          wrapperClasses="table-plans f2x-custom-table table-filter-search-wrapper"
          page={page}
        />}
    </div>
  )
};

const userSelector = createSelector(
  (state) => state.user,
  (user) => user
),
  loaderSelector = createSelector(
    (state) => state.loader,
    (loader) => loader
  ),
  tenantsSelector = createSelector(
    (state) => state.tenants,
    (tenants) => tenants
  ),
  mapStateToProps = createSelector(
    userSelector,
    loaderSelector,
    tenantsSelector,
    (user, loader, tenants) => ({ user, loader, tenants })
  );

export default withRouter(withTranslation()(connect(mapStateToProps)(Plans)));
