import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import {registerLocale} from 'react-datepicker';
import {CustomInput, Form, Input, Label} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {enGB, enUS, nl} from 'date-fns/locale';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RadioElement from './radio';
import SelectElement from './select';
import OfferPersonal from '../../../components/offer/Personal';
import OfferBank from '../../../components/offer/Bank';
import Overview from '../../../components/offer/Overview';
import {faArrowCircleLeft, faCartPlus} from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const Html = ({...props}) => {
  console.log("Html - props ->", props);

  const title = props.title;
  const description = props.description;
  const groups = props.groups;
  const price = props.price ? props.price : 0;
  const contractPeriods = props.contractPeriods ? props.contractPeriods : false;
  const wishDate = props.wishDate ? props.wishDate : false;

  const [buttonGroupClass, setButtonGroupClass] = useState("button-collapse-action");
  const [additionalCosts, setAdditionalCosts] = useState(0);

  const buttonContainerEl = useRef(null);

  console.log("Html - groups ->", groups);
  console.log("Html - price ->", price);

  const { t } = useTranslation();

  const [stepForm, setStepForm] = useState(1);
  const [stepFormData, setStepFormData] = useState({});
  const [stepFormButtonText, setStepFormButtonText] = useState(<FontAwesomeIcon icon={faCartPlus} />);

  const formatCurrency = (price) => {
    let floatCell = parseFloat(price ? price : 0);
    let floatStyle = floatCell && floatCell < 0 ? 'float-negative' : floatCell && floatCell > 0 ? 'float-positive' : 'float-null';
    return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(floatCell) })}</span>
  };

  const RenderPlanLinesInGroupSelect = (plan_lines, name, jsonDataText, description, group_id) => {
    let system_element_name = name ? name.replace(/ /g,"_").toLowerCase() : "no-name";
    let system_group_description = description ? description.replace(/ /g,"_").toLowerCase() : "no-description";
    console.log("RenderPlanLinesInGroupSelect - jsonDataText ->", jsonDataText);
    console.log("RenderPlanLinesInGroupSelect - description ->", description);
    console.log("RenderPlanLinesInGroupSelect - group_id ->", group_id);

    if (jsonDataText) {
      let jsonDataTextParsed = JSON.parse(jsonDataText);
      console.log("RenderPlanLinesInGroupSelect - jsonDataTextParsed ->", jsonDataTextParsed);

      let label = jsonDataTextParsed.label ? jsonDataTextParsed.label : "no-label";
      let options = jsonDataTextParsed.options ? jsonDataTextParsed.options : [];

      return (
          <div role="group" aria-labelledby={`${system_group_description}-select-group`}>
            <div className="group-select">
              {label &&
              <Label className="dynamic-form-element-label">
                <strong>{label}</strong>
              </Label>
              }
              <Select
                  className="react-select-container mt-0"
                  classNamePrefix="react-select"
                  name={name}
                  id={`id-${system_group_description}`}
                  placeholder="Select..."
                  withoutEmptyOption={true}
                  options={options}
                  hideSelectedOptions={true}
                  isMulti={false}
                  isClearable={true}
                  onChange={(e) => handleSelectChange(e, system_group_description)}
              />
            </div>
          </div>
      );
    }
  };

  const RenderPlanLinesInGroupCheckbox = (plan_lines, name, jsonDataText, description, group_id) => {
    let system_element_name = name ? name.replace(/ /g,"_").toLowerCase() : "no-name";
    let system_group_description = description ? description.replace(/ /g,"_").toLowerCase() : "no-description";
    //console.log("RenderPlanLinesInGroupCheckbox - jsonDataText ->", jsonDataText);
    //console.log("RenderPlanLinesInGroupCheckbox - description ->", description);
    //console.log("RenderPlanLinesInGroupCheckbox - group_id ->", group_id);

    if (jsonDataText) {
      let jsonDataTextParsed = JSON.parse(jsonDataText);
      //console.log("RenderPlanLinesInGroupCheckbox - jsonDataTextParsed ->", jsonDataTextParsed);

      let id = system_element_name;
      if (jsonDataTextParsed && jsonDataTextParsed.func && jsonDataTextParsed.func === "collapse" && jsonDataTextParsed.toggleId) {
        id = jsonDataTextParsed.toggleId;
      }

      let selected = [];
      if (stepFormData && stepFormData[system_group_description] && stepFormData[system_group_description].length > 0) {
        for (let i = 0; i < stepFormData[system_group_description].length; i++) {
          selected.push(stepFormData[system_group_description][i].id);
        }
      }

      return (
          <div
              role="group"
              aria-labelledby={`${system_group_description}-radio-group`}
              key={`${system_group_description}`}
          >
            {plan_lines.map((line, index) =>
                <div
                    className="custom-checkbox custom-control custom-controlled-checkbox"
                    key={`${system_group_description}-${index}`}
                >
                  <Input
                      type="checkbox"
                      name={system_group_description}
                      value={line.id}
                      color={`primary`}
                      id={`${group_id}-${line.id}-${index}`}
                      data-line-id={line.id}
                      defaultChecked={selected.includes(line.id)}
                      data-is-checked={selected.includes(line.id)}
                      style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1"}}
                      onChange={(e) => handleCheckboxChange(e, system_group_description, line.id, line)}
                  />
                  <Label
                      check
                      className="custom-controlled-checkbox-label"
                      style={{width: "100%"}}
                  >
                    <span className="plan-offer-line-description"
                          style={{width: "75%", display: "inline-block"}}>{line.description}</span>
                    {line.price
                        ? <span className="plan-offer-line-price"
                                style={{float: "right"}}>{formatCurrency(line.price)}</span>
                        : <span className="plan-offer-line-price" style={{float: "right"}}>GRATIS</span>}
                  </Label>
                </div>)
            }
          </div>
      );
    }
  };

  const removeHTML = (str) => {
    if (str && str !== "") {
      let htmlStr = str.replace(/<[^>]+>/ig, "");
      //console.log("HTML - removeHTML - htmlStr ->", htmlStr);
      return htmlStr;
    }
    return str;
  };

  const RenderPlanLinesInGroupRadio = (plan_lines, name, jsonDataText, description, group_id) => {
    let system_element_name = name ? name.replace(/ /g,"_").toLowerCase() : "no-name";
    let system_group_description = description ? description.replace(/ /g,"_").toLowerCase() : "no-description";
    //console.log("RenderPlanLinesInGroupRadio - jsonDataText ->", jsonDataText);
    //console.log("RenderPlanLinesInGroupRadio - description ->", description);
    //console.log("RenderPlanLinesInGroupRadio - group_id ->", group_id);

    if (jsonDataText) {
      let jsonDataTextParsed;
      jsonDataTextParsed = JSON.parse(jsonDataText);
      //console.log("RenderPlanLinesInGroupRadio - jsonDataTextParsed ->", jsonDataTextParsed);

      return (
          <div
              role="group"
              aria-labelledby={`${system_group_description}-radio-group`}
              key={`${system_group_description}`}
              id={jsonDataTextParsed && jsonDataTextParsed.id ? jsonDataTextParsed.id : system_element_name}
              className={`${jsonDataTextParsed && jsonDataTextParsed.style ? jsonDataTextParsed.style : ""}`}
          >
            {plan_lines.map((line, index) =>
                <div
                    key={`${system_group_description}-${index}`}
                    className="custom-radio custom-control custom-controlled-radio"
                    style={{paddingLeft: 0}}
                >
                  <CustomInput
                      className="mb-1"
                      id={`${group_id}-${line.id}-${index}`}
                      data-line-id={line.id}
                      type="radio"
                      name={name}
                      label={line.description_long ? removeHTML(line.description_long) : line.description}
                      value={line.id}
                      style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1", maxWidth: "75%"}}
                      onChange={(e) => handleRadioChange(e)}
                  />

                  {line.price
                      ? <span className="plan-offer-line-price" style={{
                        position: "absolute",
                        right: 0,
                        maxWidth: "25%",
                        top: 0
                      }}>{formatCurrency(line.price)}</span>
                      : <span className="plan-offer-line-price"
                              style={{position: "absolute", right: 0, maxWidth: "25%", top: 0}}>GRATIS</span>}
                </div>
            )}
          </div>
      )
    }
  };

  const renderPlanLineJSON = (json_data) => {
    //console.log("Html - renderPlanLineJSON - json_data ->", json_data);

    if (Array.isArray(json_data)) {
      if (json_data.length === 1) {
        let jsonDataPlanLineParsed = JSON.parse(json_data[0]);
        //console.log("Html - renderPlanLineJSON - jsonDataPlanLineParsed ->", jsonDataPlanLineParsed);

        for (let key of Object.keys(jsonDataPlanLineParsed)) {
          //console.log("Html - renderPlanLineJSON - jsonDataPlanLineParsed - key ->", key);

          // render radio element
          if (jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].type === "radio") {
            //console.log("Html - renderPlanLineJSON - jsonDataPlanLineParsed - radio options ->", jsonDataPlanLineParsed[key].options);
            return (
                <RadioElement
                    name={key}
                    options={jsonDataPlanLineParsed[key].options}
                    style={jsonDataPlanLineParsed[key].style ? jsonDataPlanLineParsed[key].style : ""}
                    //setStepFormData={setStepFormData}
                    stepFormData={stepFormData}
                    onChange={(e) => handleDynamicFieldData(e)}
                />
            )
          }

          // render checkbox element

          // render input element

          // render react select
          if (jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].type === "react-select") {
            //console.log("Html - renderPlanLineJSON - jsonDataPlanLineParsed - react-select options ->", jsonDataPlanLineParsed[key].options);

            return (
                <SelectElement
                    name={key}
                    label={jsonDataPlanLineParsed[key].label}
                    options={jsonDataPlanLineParsed[key].options}
                    style={jsonDataPlanLineParsed[key].style ? jsonDataPlanLineParsed[key].style : ""}
                    //setStepFormData={setStepFormData}
                    stepFormData={stepFormData}
                    onChange={(e) => handleDynamicFieldData(e)}
                />
            )
          }
        }
      }
    }
  };

  const RenderPlanLinesInGroupButton = (plan_lines, name, jsonDataText, description, group_id) => {
    let system_element_name = name.replace(/ /g,"_").toLowerCase();
    //console.log("RenderPlanLinesInGroupButton - jsonDataText ->", jsonDataText);
    //console.log("RenderPlanLinesInGroupButton - plan_lines ->", plan_lines);
    //console.log("RenderPlanLinesInGroupButton - name ->", name);
    //console.log("RenderPlanLinesInGroupButton - description ->", description);
    //console.log("RenderPlanLinesInGroupButton - group_id ->", group_id);

    if (jsonDataText) {
      let jsonDataTextParsed = JSON.parse(jsonDataText);
      //console.log("RenderPlanLinesInGroupButton - jsonDataTextParsed ->", jsonDataTextParsed);

      if (jsonDataTextParsed && jsonDataTextParsed.func && jsonDataTextParsed.func === "collapse") {
        let target_id = jsonDataTextParsed.target;

        return (
          <div
            role="group"
            className="group-button-container"
          >
            {plan_lines.map((line, index) =>
              <div className={`group-button-dynamic`}>
                <div
                  className={`group-button ${buttonGroupClass}`}
                  dangerouslySetInnerHTML={{__html: line.description_long}}
                  key={`${group_id}-${index}`}
                  id={`${group_id}-${line.id}-${index}`}
                  ref={buttonContainerEl}
                  onClick={(e) => clickButtonCollapseHandler(e, target_id ? target_id : null, line.description_long)}
                />
                <div
                    key={`json-data-${name}-${index}`}
                    className={`group-button-json-data-container`}
                >
                  { renderPlanLineJSON(line.json_data) }
                </div>
              </div>
            )}
          </div>
        );
      }

      if (jsonDataTextParsed && jsonDataTextParsed.func && jsonDataTextParsed.func === "add") {
        let target_id = jsonDataTextParsed.target;

        return (
          <div role="group" className="group-button-container">
            {plan_lines.map((line, index) =>
              <div
                className={`group-button ${buttonGroupClass}`}
                dangerouslySetInnerHTML={{__html: line.description_long}}
                key={`${group_id}-${index}`}
                ref={buttonContainerEl}
                onClick={(e) => clickButtonAddHandler(e, target_id ? target_id : null)}
              />
            )}
          </div>
        );
      }
    }
  };

  const checkGroupJsonData = (groupTxt) => {
    if (groupTxt) {
      let jsonDataTextParsed;
      jsonDataTextParsed = JSON.parse(groupTxt);

      if (jsonDataTextParsed && jsonDataTextParsed.style && jsonDataTextParsed.style === "hidden") {
        return "hidden";
      } else if (jsonDataTextParsed && jsonDataTextParsed.header_class) {
        return jsonDataTextParsed.header_class;
      }
    }
    return "";
  };

  /*
    Collapse (target) element
   */
  const clickButtonCollapseHandler = (e, targetId, html) => {
    e.preventDefault();

    let href, elemId, multiTarget = false;
    if (html && html !== "") {
      href = html.match(/(#)([^\s]+(?="))/g);
      if (Array.isArray(href)) {
        for (let i = 0; i < href.length; i++) {
          elemId = href[i].replace("#", "");
        }

        if (elemId && Array.isArray(targetId) && targetId.includes(elemId)) {
          multiTarget = true;
        }
      }
    }
    //console.log("clickButtonCollapseHandler - multiTarget ->", multiTarget);
    //console.log("clickButtonCollapseHandler - href (targetId) ->", href);
    //console.log("clickButtonCollapseHandler - elemId (targetId) ->", elemId);
    //console.log("clickButtonCollapseHandler - e - targetId ->", e, targetId);

    if (Array.isArray(targetId) && multiTarget === true) {
      for (let i = 0; i < targetId.length; i++) {
        let targetEl = document.getElementById(targetId[i]);
        //console.log("clickButtonCollapseHandler (target = Array) - targetEl[i] ->", targetEl[i]);

        if (targetEl && targetEl.className && targetEl.className === "hidden") {
          let parentEl = targetEl.parentElement;
          //console.log("clickButtonCollapseHandler - parentEl (target = Array) ->", parentEl);
          let children = parentEl.children;
          if (children && children.length > 0) {
            for (let i = 0; i < children.length; i++) {
              children[i].classList.remove("hidden");
              children[i].classList.add("not-hidden");
            }
          }
        } else if (targetEl && targetEl.className && targetEl.className === "not-hidden") {
          let parentEl = targetEl.parentElement;
          let children = parentEl.children;
          if (children && children.length > 0) {
            for (let i = 0; i < children.length; i++) {
              children[i].classList.remove("not-hidden");
              children[i].classList.add("hidden");
            }
          }
        }
      }

    } else {
      let targetEl = document.getElementById(targetId);
      //console.log("clickButtonCollapseHandler - targetEl ->", targetEl);
      if (targetEl === null) {
        targetEl = document.getElementById(elemId);
      }
      //console.log("clickButtonCollapseHandler - targetEl(elemId) ->", targetEl);

      if (targetEl && targetEl.className && targetEl.className === "hidden") {
        let parentEl = targetEl.parentElement;
        //console.log("clickButtonCollapseHandler - parentEl ->", parentEl);
        let children = parentEl.children;
        if (children && children.length > 0) {
          for (let i = 0; i < children.length; i++) {
            children[i].classList.remove("hidden");
            children[i].classList.add("not-hidden");
          }
        }
      } else if (targetEl && targetEl.className && targetEl.className === "not-hidden") {
        let parentEl = targetEl.parentElement;
        let children = parentEl.children;
        if (children && children.length > 0) {
          for (let i = 0; i < children.length; i++) {
            children[i].classList.remove("not-hidden");
            children[i].classList.add("hidden");
          }
        }
      }
    }
    return false;
  };

  /*
    Add dynamic element
   */
  const clickButtonAddHandler = (e, targetId) => {
    e.preventDefault();
    //console.log("clickButtonAddHandler - e - targetId ->", e, targetId);

    const targetEl = document.getElementById(targetId);
    //console.log("clickButtonAddHandler - targetEl ->", targetEl);
  };

  const goToPreviousStep = (e, step) => {
    e.preventDefault();
    //console.log("goToNextStep - step ->", step);

    if (step === 2) {
      setStepForm(1);
      setStepFormButtonText("Bestellen");
    } else if (step === 3) {
      setStepForm(2);
      setStepFormButtonText("Ga verder");
    } else if (step === 4) {
      setStepForm(3);
      setStepFormButtonText("Ga verder");
    } else if (step === 5) {
      setStepForm(4);
      setStepFormButtonText("Bevestigen");
    }
  };

  const goToNextStep = (e, step) => {
    e.preventDefault();
    //console.log("goToNextStep - step ->", step);

    if (step === 1) {
      setStepForm(2);
      setStepFormButtonText("Ga verder");
    } else if (step === 2) {
      setStepForm(3);
      setStepFormButtonText("Ga verder");
    } else if (step === 3) {
      setStepForm(4);
      setStepFormButtonText("Ga verder");
    } else if (step === 4) {
      setStepForm(5);
      setStepFormButtonText("Bevestigen");
    }
  };

  const formatHeader = (str, json_data) => {
    let jsonDataParsed;
    if (json_data) {
      jsonDataParsed = JSON.parse(json_data);
    }

    let strArr = str.split('|');
    if (strArr && strArr.length > 1) {
      let newStr = <span>{strArr[0]}<i>{strArr[1]}</i></span>;
      //console.log("formatHeader - newStr ->", newStr);
      return newStr;
    }
    return str
  };

  const renderStaticWishDate = (data) => {
    //console.log("Html - renderStaticWishDate - data ->", data);
    // minimum_days_delayed
    // suggested_selected_date

    let staticJson = {
      "type": "radio",
      "label": "Levering",
      "required": true,
      "style": "",
      "options": [
        {
          "label": "z.s.m.",
          "value": "z.s.m.",
          "name": "wish_date",
          "price": false,
          "data": null,
        },
        {
          "label": "Anders",
          "value": "anders",
          "name": "wish_date",
          "price": false,
          "data": {
            "func": "toggle",
            "element": "datepicker",
            "format": "dd-MM-yyyy",
            "minDate": 14,
            "name": "other_wish_date",
          }
        }
      ]
    };

    //console.log("Html - renderStaticWishDate - staticJson ->", staticJson);

    return (
        <div
            className="pt-2 px-2 mb-3 plan-offer-groups plan-offer-wish-date plan-offer-form w-100"
            key={`provisioning-wish-date`}
        >
          <h5 className="plan-offer-group-header prov-date-icon">Levering</h5>
          <RadioElement
              name={staticJson.label}
              options={staticJson.options}
              style={staticJson.style ? staticJson.style : ""}
              onChange={(e) => handleDynamicFieldData(e)}
          />
        </div>
    );
  };

  const renderStaticPeriod = (data) => {
    //console.log("Html - renderStaticPeriod - data ->", data);

    let periods = [];
    if (data) {
      let jsonPeriodDataParsed = JSON.parse(data);
      jsonPeriodDataParsed.map(({id, period, type, net_days}) => {
        return periods.push({
          label: period, value: id,
        });
      });
    }

    let staticJson = {
      "name": "contract_period",
      "type": "react-select",
      "label": "Contractsduur",
      "required": true,
      "options": periods,
      "style": "",
    };

    //console.log("Html - renderStaticPeriod - staticJson ->", staticJson);

    return (
        <div
            className="pt-2 px-2 mb-3 plan-offer-groups plan-offer-contract-period plan-offer-form w-100"
            key={`contract-period`}
        >
          <h5 className="plan-offer-group-header contract-period-icon">Contractsduur</h5>
          <SelectElement
              name={staticJson.name}
              label={false}
              options={staticJson.options}
              style={staticJson.style}
              onChange={(e) => handleDynamicFieldData(e)}
          />
        </div>
    );
  };

  /*
    Form handling
  */
  const handleInputChange = ({ target: { name, value } }) => {
    //console.log("Html - handleInputChange - name ->", name);
    //console.log("Html - handleInputChange - value ->", value);

    let selectedData = Object.assign({}, stepFormData);
    selectedData[name] = value;
    setStepFormData(selectedData);
  };

  const handleSelectChange = (e, name, lineData) => {
    //console.log("Html - handleSelectChange - e ->", e);
    //console.log("Html - handleSelectChange - name ->", name);
    //console.log("Html - handleSelectChange - lineData ->", lineData);

    let selectedData = Object.assign({}, stepFormData);
    selectedData[name] = e.value;
    setStepFormData(selectedData);
  };

  //const handleCheckboxChange = ({ target: { name, checked, value } }) => {
  const handleCheckboxChange = (e, name, line_id, lineData) => {
    //console.log("Html - handleCheckboxChange - e ->", e);
    //console.log("Html - handleCheckboxChange - e.target.checked ->", e.target.checked);
    //console.log("Html - handleCheckboxChange - e.target.value ->", e.target.value);
    //console.log("Html - handleCheckboxChange - name ->", name);
    //console.log("Html - handleCheckboxChange - isDefaultChecked ->", isDefaultChecked);

    let selectedData = Object.assign({}, stepFormData);
    //console.log("Html - handleCheckboxChange - selectedData(stepFormData) ->", selectedData);

    let dataArr = [];
    if (selectedData[name] && selectedData[name].length > 0) {
      //console.log("Html - handleCheckboxChange - selectedData(name) already exists ->", selectedData[name]);
      dataArr = [...selectedData[name]];

      if (e.target.checked === true) {
        dataArr = [...selectedData[name], lineData];
      } else {
        dataArr.splice(selectedData[name].indexOf(e.target.value), 1);
      }
    } else {
      if (e.target.checked === true) {
        //dataArr = [lineData];
        dataArr.push(lineData);
      }
    }

    //console.log("Html - handleCheckboxChange - dataArr ->", dataArr);

    selectedData[name] = dataArr; //[lineData]; //e.target.checked;
    setStepFormData(selectedData);
  };

  //const handleRadioChange = ({ target: { name, checked, value } }) => {
  const handleRadioChange = (e, name, lineData) => {
    //console.log("Html - handleRadioChange - e ->", e);
    //console.log("Html - handleRadioChange - name ->", name);
    //console.log("Html - handleRadioChange - lineData ->", lineData);

    let selectedData = Object.assign({}, stepFormData);
    //console.log("Html - handleRadioChange - selectedData(stepFormData) ->", selectedData);

    selectedData[name] = lineData;
    setStepFormData(selectedData);
  };

  const handleDatePickerChange = (e, name) => {
    console.log("Html - handleDatePickerChange - e ->", e);
    console.log("Html - handleDatePickerChange - name ->", name);
  };

  const handleDynamicFieldData = (data, name, type) => {
    console.log("Html - handleDynamicFieldData - (returned)data ->", data);
    console.log("Html - handleDynamicFieldData - (returned)name ->", name);
    console.log("Html - handleDynamicFieldData - (returned)type ->", type);
  };

  const radioChangeHandler = (e, name, data) => {
    console.log("Html - RadioElement - radioChangeHandler - e ->", e);
    console.log("Html - RadioElement - radioChangeHandler - name ->", name);
    console.log("Html - RadioElement - radioChangeHandler - name ->", data);

    //const { selectedData, dynamicFieldData } = props;
    //dynamicFieldData(data, name);
  };

  const handleSubmit = (e) => {
    console.log("Html - handleSubmit - e ->", e);
  };

  const getStepFormData = () => {
    // get form data from session storage
    let offerFormDataPreview = sessionStorage.getItem("offerFormDataPreview");
    if (offerFormDataPreview) {
      return JSON.parse(offerFormDataPreview);
    }
    return false;
  };

  const saveStepFormData = (data) => {
    // save form data to session storage
    console.log("Html - saveStepFormData - data ->", data);
    if (data) {
      sessionStorage.setItem("offerFormDataPreview", JSON.stringify(data));
    }
  };

  useEffect(() => {
    if (groups) {
      console.log("useEffect - groups ->", groups);
      let sortedGroups = groups.sort(function (a, b) {
        return a.sort - b.sort;
      });
      console.log("useEffect - sortedGroups ->", sortedGroups);
    }
  }, [groups]);

  useEffect(() => {
    console.log("useEffect - stepFormData ->", stepFormData);

    if (stepFormData) {
      const savedData = saveStepFormData(stepFormData);
      console.log("useEffect - savedData ->", savedData);
    }
  }, [stepFormData]);

  useEffect(() => {
    const savedData = getStepFormData();
    if (savedData) {
      console.log("useEffect - savedData ->", savedData);
      setStepFormData(savedData);
    }
    console.log("useEffect - stepFormData ->", stepFormData);
  }, []);

  return (
      <Form className="plan-offer-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="plan-offer d-flex align-items-start flex-column">
          {stepForm === 1 && description && description !== "" &&
          <div className="pt-2 px-2 mb-2 plan-offer-header">
            <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          }

          {stepForm === 2 &&
          <div className="w-100 pt-2 px-2 mb-0">
            <h3 className="mb-3">
              <a href="#" className="mr-2 mt-n2" onClick={(e) => goToPreviousStep(e, stepForm)}>
                <FontAwesomeIcon color="primary" name="step-back-icon" className="step-back-icon" icon={faArrowCircleLeft} />
              </a>
              <span>Kies uw opties:</span>
            </h3>
          </div>
          }

          {stepForm === 2 && groups && groups.length > 0 &&
          Object.values(groups).map((group, groupIndex) => {
            return (
                <div className="pt-2 px-2 mb-3 plan-offer-groups plan-offer-form w-100" data-group-id={group.id ? group.id : groupIndex}>
                  <React.Fragment>
                    {group.header &&
                    <h5
                        key={`header-${groupIndex}`}
                        className={`plan-offer-group-header ${checkGroupJsonData(group.json_data)}`}
                    >
                      {formatHeader(group.header, group.json_data)}
                    </h5>}

                    {group.description &&
                    <div
                        dangerouslySetInnerHTML={{ __html: group.text }}
                        key={`description-${groupIndex}`}
                        className={`plan-offer-group-description ${checkGroupJsonData(group.json_data)}`}
                    />}

                    {group.type && group.type === "radio" && group.plan_lines && group.plan_lines.length > 0 &&
                    RenderPlanLinesInGroupRadio(group.plan_lines, group.header, group.json_data, group.description, group.id)
                    }

                    {group.type && group.type === "checkbox" && group.plan_lines && group.plan_lines.length > 0 &&
                    RenderPlanLinesInGroupCheckbox(group.plan_lines, group.header, group.json_data, group.description, group.id)
                    }

                    {group.type && group.type === "select" && group.plan_lines && group.plan_lines.length > 0 &&
                    RenderPlanLinesInGroupSelect(group.plan_lines, group.header, group.json_data, group.description, group.id)
                    }

                    {group.type && group.type === "button" && group.plan_lines && group.plan_lines.length > 0 &&
                    RenderPlanLinesInGroupButton(group.plan_lines, group.header, group.json_data, group.description, group.id)
                    }
                  </React.Fragment>
                </div>
            )
          })
          }

          {stepForm === 2 &&
          <div className="default-static-options w-100">
            {renderStaticPeriod(contractPeriods)}
            {renderStaticWishDate(wishDate)}
          </div>
          }

          {/* Personal information (static) */}
          {stepForm === 3 &&
          <div className="w-100 pt-2 px-2 mb-4">
            <h3 className="mb-4">
              <a href="#" className="mr-2 mt-n2" onClick={(e) => goToPreviousStep(e, stepForm)}>
                <FontAwesomeIcon color="primary" name="step-back-icon" className="step-back-icon" icon={faArrowCircleLeft} />
              </a>
              <span>Uw gegevens:</span>
            </h3>
            <OfferPersonal
                stepFormData={stepFormData}
            />
          </div>
          }

          {/* Bank information (static) */}
          {stepForm === 4 &&
          <div className="w-100 pt-2 px-2 mb-4">
            <h3 className="mb-4">
              <a href="#" className="mr-2 mt-n2" onClick={(e) => goToPreviousStep(e, stepForm)}>
                <FontAwesomeIcon color="primary" name="step-back-icon" className="step-back-icon" icon={faArrowCircleLeft} />
              </a>
              <span>Uw bank gegevens:</span>
            </h3>
            <OfferBank
                stepFormData={stepFormData}
            />
          </div>
          }

          {/* Overview information - terms & conditions */}
          {stepForm === 5 &&
          <div className="w-100 pt-2 px-2 mb-4">
            <h3 className="mb-4">
              <a href="#" className="mr-2 mt-n2" onClick={(e) => goToPreviousStep(e, stepForm)}>
                <FontAwesomeIcon color="primary" name="step-back-icon" className="step-back-icon" icon={faArrowCircleLeft} />
              </a>
              <span>Overzicht:</span>
            </h3>
            <Overview
                stepFormData={stepFormData}
            />
          </div>
          }

          <div className="plan-offer-footer">
            <div className="plan-offer-price">
              {formatCurrency(price)} <span className="text">p/mnd</span>
            </div>
            <div className="plan-offer-button">
              <button
                  className="btn btn-block plan-offer-button-order"
                  //onClick={(e) => goToNextStep(e, stepForm)}
                  onClick={(e) => e.preventDefault()}
              >
                {stepFormButtonText}
              </button>
            </div>
          </div>

        </div>
      </Form>
  );
};

export default withRouter(connect()(Html));
