import { html } from '@codemirror/lang-html';
import { faSave, faSearchPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeMirror from '@uiw/react-codemirror';
import { enGB, enUS, nl } from "date-fns/locale";
import React from "react";
import { registerLocale } from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Toast from "../../../../components/Toast";
import { CreateEmailTemplate, UpdateEmailTemplate } from '../../../../controllers/tenants';
import { GetUsers } from '../../../../controllers/users';
import { loaderToggle } from "../../../../redux/actions/loaderActions";
import { validateDate } from "../../../../redux/actions/validateActions";
import ZoomedHTMLScreen from "./ZoomedHTMLScreen";

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class EmailTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    const { selectedData } = this.props;
    this.state = {
      formType: selectedData ? 'Edit' : 'Add',
      formName: ' | Email template',
      tenant_id: props.id ? props.id : null,
      email_template: selectedData ? selectedData : {},
      email_template_original: selectedData ? selectedData : {},
      zoomModalOpenNested: false,
      zoomedHtml: "",
      saveClicked: false
    };
  }

  openZoomModal = (html, e) => {
    e.stopPropagation(); // Prevent event propagation to the parent modal        
    this.setState({
      zoomModalOpenNested: true,
      zoomedHtml: html,
    });
  };

  closeZoomModal = () => {
    this.setState({
      zoomModalOpenNested: false,
      zoomedHtml: "",
    });
  };

  getLocale = (locale) => {
    const dateLocalesPath = {
      'nl': 'nl',
      'en': 'en-GB',
      'us': 'en-US'
    };

    require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
  };

  handleInputChange({ target: { name, value } }) {
    let email_template = Object.assign({}, this.state.email_template);
    email_template[name] = value;

    this.setState({ email_template });
  }

  handleTextareaChange = (e, name) => {
    // console.log('handleTextareaChange - e ->', e);
    // console.log('handleTextareaChange - value ->', name);

    let email_template = Object.assign({}, this.state.email_template);
    email_template[name] = e;

    this.setState({ email_template });
  };

  handleDatePickerChange(name, date) {
    let email_template = Object.assign({}, this.state.email_template);
    const { newDate } = this.props.dispatch(validateDate(date));

    email_template[name] = newDate;

    this.setState({ email_template })
  }

  handleSelectChange(name, data) {
    // console.log("name, data ->", name, data);
    let email_template = Object.assign({}, this.state.email_template);

    email_template[name] = data.value;

    this.setState({
      email_template,
      [name]: data
    });
  }

  handleKeyDown = (e, name) => {
    e.preventDefault();
  };

  handleSubmit(e) {
    e.preventDefault();

    let email_template = Object.assign({}, this.state.email_template);
    const { selectedData, loader, dispatch, id } = this.props;
    const { tenant_id, type, description, from_name, from_email, subject, body_html } = email_template;

    email_template['tenant_id'] = id;

    // console.log("Email template form - handleSubmit - selectedData ->", selectedData);

    //const { userData } = this.state;
    //console.log("EmailTemplates form - useData ->", userData);

    //Validations
    if (!type) {
      return Toast.fire({ title: 'Type field is required!', icon: 'warning' })
    }

    //if (!description) {
    //  return Toast.fire({ title: 'Description from field is required!', icon: 'warning' })
    //}

    if (!from_name) {
      return Toast.fire({ title: 'From name to field is required!', icon: 'warning' })
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      if (selectedData) {
        (async () => {
          const { ok } = await UpdateEmailTemplate(email_template, selectedData.id);
          this.handleOk(ok);
        })()
      } else {
        (async () => {
          const { ok } = await CreateEmailTemplate(email_template);
          this.handleOk(ok);
        })();
      }
    }
  }

  handleOk = (ok) => {
    const { hide, update, dispatch } = this.props;

    dispatch(loaderToggle(false));

    if (ok) {
      hide();
      update();
    }
  };

  loadUsers = (value, callback) => {
    const params = { search: value };

    (async () => {
      const { ok, data } = await GetUsers(params);
      if (ok) {
        let users = [];
        data.data.map(({ username, id }) => {
          return users.push({
            label: username,
            value: id
          })
        });
        callback(users);
      }
    })();
  };

  showEmailTemplatePreview = (e) => {
    // console.log("showEmailTemplatePreview - e ->", e);
  };

  /*loadUser = (id) => {
    console.log("id isset - id ->", id);
    console.log("id isset - userData ->", this.state.userData);

    if (id && this.state.userData && this.state.userData.id && parseInt(this.state.userData.id) === parseInt(id)) {
      console.log("username isset - username ->", this.state.userData.username);
      return { value: this.state.userData.id, label: this.state.userData.username };
    }
  };*/

  componentDidMount() {
    const { selectedData, dispatch } = this.props;

    dispatch(loaderToggle(true));
    (async () => {
      dispatch(loaderToggle(false));
      this.setState({ hasLoaded: true });

    })();
  }

  handleZoomedHtmlChange = (html) => {
    this.setState({ zoomedHtml: html });
    console.log("handleZoomedHtmlChange - saveClicked", this.state.saveClicked);
  }


  handleSave = () => {
    const { zoomedHtml, email_template } = this.state;

    this.setState({ saveClicked: true }, () => {
      console.log("handleSave - saveClicked", this.state.saveClicked);
      let updatedEmailTemplate = Object.assign({}, email_template);
      updatedEmailTemplate['body_html'] = zoomedHtml;

      this.setState({ email_template: updatedEmailTemplate }, () => {
        this.closeZoomModal();
      });
    });
  }


  render() {
    const { show, hide, selectedData } = this.props,
      { zoomedHtml, zoomModalOpenNested, formType, formName, users, email_template, email_template_original } = this.state,
      { id, type, description, tenant_id, from_name, from_email, subject, body_html } = email_template ? email_template : null;

    // console.log("Api keys form - render - email_template ->", email_template);
    //console.log("Api keys form - render - userData ->", userData);

    return (
      <Modal isOpen={show} toggle={hide} centered className="modal-xl">
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          <ModalHeader>
            <span>{formType} {formName}</span>
          </ModalHeader>
          <ModalBody key="0" className="mt-3 mb-3">
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Type:</label>
              </Col>
              <Col md={9}>
                <Input
                  name="type"
                  placeholder="Type"
                  value={type ? type : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Description:</label>
              </Col>
              <Col md={9}>
                <Input
                  name="description"
                  placeholder="Description"
                  value={description ? description : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Subject:</label>
              </Col>
              <Col md={9}>
                <Input
                  name="subject"
                  placeholder="Subject"
                  value={subject ? subject : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">From name:</label>
              </Col>
              <Col md={9}>
                <Input
                  className="form-control"
                  name="from_name"
                  placeholder="From name"
                  onChange={(e) => this.handleInputChange(e)}
                  value={from_name ? from_name : ''}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">From email:</label>
              </Col>
              <Col md={9}>
                <Input
                  className="form-control"
                  name="from_email"
                  placeholder="From email"
                  onChange={(e) => this.handleInputChange(e)}
                  value={from_email ? from_email : ''}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Html:</label>
              </Col>
              <Col md={9}>
                <div className="code-mirror-container">
                  <CodeMirror
                    value={body_html ? body_html : ''}
                    style={{ minHeight: "220px", border: "1px solid #ced4da", fontSize: "11px", resize: "vertical" }}
                    height="240px"
                    name="body_html"
                    extensions={[html({ jsx: false })]}
                    onChange={(e) => this.handleTextareaChange(e, "body_html")}
                  //onChange={(e) => this.handleInputChange(e)}
                  />
                </div>
                <div style={{ textAlign: "right", marginTop: "0.5rem" }}>
                  <Button color="primary" className="btn-sm" onClick={(e) => this.openZoomModal(body_html, e)}>
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-between mt-4">
            <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <Button color="primary">
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </ModalFooter>
        </Form>
        <ZoomedHTMLScreen
          isOpen={zoomModalOpenNested}
          toggle={this.closeZoomModal}
          zoomedHtml={zoomedHtml}
          onChange={this.handleZoomedHtmlChange}
          onSave={this.handleSave}
          originalHtml={email_template_original}
          updatedHtml={email_template}
        />
      </Modal>
    )
  }
}

const mapStateToProps = store => ({
  loader: store.loader,
  user: store.user
});

export default connect(mapStateToProps)(withTranslation()(withRouter(EmailTemplateForm)));
