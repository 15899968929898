import React from "react";
import classnames from "classnames";

import { Card, Col, Container, ListGroup, ListGroupItem, Row, TabContent, TabPane } from "reactstrap";

import Finance from './Finance';
import M7PowerTools from './M7PowerTools'

class Settings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '0',
    };

    this.tabs = [{
      title: 'Finance',
      component: <Finance />
    }, {
      title: 'M7 Powertools',
      component: <M7PowerTools />
    }];

    this.toggle = this.toggle.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  render() {
    const { activeTab } = this.state;

    return(
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Admin tools</h1>

        <Row>
          <Col md="3" xl="2">
            <Card>
              <ListGroup tabs="true">
                { this.tabs.map((item, index) => {
                    return (
                      <ListGroupItem 
                        className={ classnames({ active: activeTab === index.toString() }) }
                        onClick={() => { this.toggle(index.toString()) }}
                        key={ index }
                      >
                        { item.title }
                      </ListGroupItem>
                    )
                  })
                }
              </ListGroup>
            </Card>
          </Col>
          <Col md="9" xl="10">
            <TabContent activeTab={ activeTab }>
              { this.tabs.map((item, index) => {
                  return (
                      <React.Fragment key={ index }>
                      { activeTab.toString() === index.toString() &&                       
                          <TabPane tabId={ index.toString() } >
                            <Card className="border-0"> { item.component } </Card>
                          </TabPane>
                      }
                      </React.Fragment>
                  )
                })
              }              
            </TabContent>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Settings;
