import React from 'react';
import {withRouter} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Row, Col } from "reactstrap";
import Search from './Search';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

const CustomDataTable = ({
                           data, sortItem, page, setPage, filters, filter, tab, columns, notLoadedByDefault, pagination, filterPosition, showTotal, sizePerPage, headerDropdownOpts, noTopBorder, rowClasses,
                           onTableChange, handleDataChange, totalSize, search, setSearchKey, searchKeyword, storageKey, setKeyword, headerFormatter, HeaderMenuFormatter, rowEvents, rowAction, hover, classes, remote, hidePageListOnlyOnePage,
                           isLoadData, expandRow, headerWrapperClasses, noHover, headerCheckBox, wrapperClasses, noSearch, defaultSortItem, defaultSortOrder, history, dispatch, id, keyField, customScrollBar }) => {

  if (page === undefined) {
    page = 1;
  }
  if (sizePerPage === undefined) {
    sizePerPage = 10;
  }
  if (hidePageListOnlyOnePage === undefined) {
    hidePageListOnlyOnePage = true;
  }

  const noDataFound = () => {
    return 'No data found.';
  };

  const afterFilter = (newResult, newFilters) => {
    console.log('afterFilter - newResult - newFilters: ');
    console.log(newResult);
    console.log(newFilters);
  };

  return (
      <ToolkitProvider
          data={data}
          columns={columns}
          search
          keyField={keyField}
          loading={ true }
      >
        {
          (toolkitprops) => {
            return (
                <>
                  { search || headerFormatter ?
                      <Row className="card-header card-header-primary custom-tbl-header">
                        <Col className="col-md-6">
                          {search ? <Search
                              //searchKeyword={searchKeyword}
                              {...toolkitprops.searchProps}
                              //history={history}
                              //dispatch={dispatch}
                              //setPage={setPage}
                              //setKeyword={setKeyword}
                              //storageKey={storageKey}
                              //setSearchKey={setSearchKey}
                              //isLoadData={isLoadData}
                          /> : null}
                        </Col>
                        <Col className="col-md-6">
                          {headerFormatter ? <HeaderMenuFormatter /> : null}
                        </Col>
                      </Row>
                      :
                      null
                  }
                  <BootstrapTable
                      { ...toolkitprops.baseProps }
                      classes={classes}
                      bootstrap4={true}
                      expandRow={ expandRow }
                      filters={filter !== undefined && filter ? filter : null}
                      filter={filterFactory({afterFilter})}
                      filterPosition="top"
                      onTableChange={onTableChange ? onTableChange : null}
                      onDataSizeChange={handleDataChange ? handleDataChange : null}
                      hover={hover}
                      loading={ true }
                      noDataIndication={ noDataFound }
                      bordered={false}
                      wrapperClasses={`table-responsive${wrapperClasses ? ` ${wrapperClasses}` : ''}${noHover ? ' no-hover' : ''}`}
                      remote
                      pagination={paginationFactory({page, sizePerPage, totalSize, hidePageListOnlyOnePage, sizePerPageList: [10, 25, 50, 100, 200]})}
                      striped={true}
                      rowEvents={rowEvents}
                      rowClasses={rowClasses}
                      headerWrapperClasses={headerWrapperClasses}
                  />
                </>
            )
          }
        }
      </ToolkitProvider>
  )
};

const mapStateToProps = ({user, loader, keyword}) => ({user, loader, keyword});

export default withRouter(withTranslation()(connect(mapStateToProps)(CustomDataTable)));
