import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import DOMPurify from "dompurify";
import { GetRelEmail } from '../../../controllers/relations';
import { GetRelEmailAttachments, GetRelEmailLogs, GetAttachmentData, DownloadPreviewAttachment, GetAttachmentDataWithType } from '../../../controllers/emails';
import { ListGroup, ListGroupItem, Row, Col, Button, ButtonGroup } from 'reactstrap';
import {
  withRouter, useHistory, useRouteMatch, useParams, useLocation,
} from 'react-router-dom';
import { mvLoaderToggleInv } from '../../../redux/actions/mvLoaderInvoiceActions';
import { simulateTabClick } from "../../../hooks/useSimulateTabClick";
import ScrollBar from 'react-perfect-scrollbar';
import { useTranslation, withTranslation } from 'react-i18next';
import { mvLoaderToggle } from '../../../redux/actions/mvLoaderActions';
import Toast from '../../../components/Toast';
import PDFViewer from '../../../components/PDFViewer';
import {
  faEnvelope,
  faPaperclip,
  faChevronLeft,
  faChevronRight,
  faSearchMinus,
  faSearchPlus,
  faPrint,
  faDownload,
  faReceipt,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  hideSidebarMenuInvoices,
  toggleSidebarMenuInvoices
} from '../../../redux/actions/sidebarMenuActions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { createSelector } from 'reselect';
import ListEmailLogs from './ListEmailLogs';
import ModalWrapper from '../../../components/ModalWrapper';
import { Cpu } from 'react-feather';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import printJS from 'print-js'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const mediaTypes = {
  'pdf': 'application/pdf',
  'jpg': 'image/jpeg',
  'png': 'image/png, text/plain',
  'word': 'application/msword',
  'excel': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
};

const optionsCreatePDF = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
  //cMapUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  //standardFontDataUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
};

const maxWidtModal = 800;
const testBase64Str = "";

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  //console.log("afterSanitizeAttributes - node ->", node);

  if (
    node.tagName === 'use' &&
    node.hasAttribute('href') &&
    !node.getAttribute('href').match(/^#/)
  ) {
    node.remove();
  }

  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
});

const ListEmailDetails = ({ emailDetails, lastVisitEmailId, history, dispatch, ...props }) => {
  //console.log("ListEmailDetails - emailDetails ->", emailDetails);
  //console.log("ListEmailDetails - lastVisitEmailId ->", lastVisitEmailId);

  const [emailDetailsData, setEmailDetailsData] = useState(null);
  const [emailBodyHtml, setEmailBodyHtml] = useState(null);
  const [emailLogsData, setEmailLogsData] = useState(null);
  const [emailLogsModal, setEmailLogsModal] = useState(false);

  const [emailAttachmentData, setEmailAttachmentData] = useState(null);
  const [emailAttachmentModal, setEmailAttachmentModal] = useState(false);
  const [emailAttachmentsTotal, setEmailAttachmentsTotal] = useState(0);
  const [emailAttachmentLinks, setEmailAttachmentLinks] = useState(null);
  const [emailAttachmentDocument, setEmailAttachmentDocument] = useState(null);
  const [pdfBase64, setPdfBase64] = useState(null);
  const [testEmailAttachmentDocument, setTestEmailAttachmentDocument] = useState(null);

  const file = useMemo(() => ({ emailAttachmentDocument }), [emailAttachmentDocument]);

  const [numPagesAttachment, setNumPagesAttachment] = useState(null);
  const [pageNumberAttachment, setPageNumberAttachment] = useState(1);
  const [containerWidth, setContainerWidth] = useState();
  const [filePath, setFilePath] = useState(null);

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1); // start on first page
  const [loading, setLoading] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);
  const [canvasLoadedWidth, setCanvasLoadedWidth] = useState(800);
  const [canvasLoadedHeight, setCanvasLoadedHeight] = useState(null);
  const [scale, setScale] = useState(0.5);
  const [selectedViewFilename, setSelectedViewFilename] = useState(null);
  const [selectedViewFileId, setSelectedViewFileId] = useState(null);

  const buttonsVisible = false;

  useEffect(() => {
    let links = [];

    // get email details
    if (lastVisitEmailId) {
      (async () => {
        // email details
        const [emailData, attachmentData] = await Promise.all([
          GetRelEmail(lastVisitEmailId),
          GetRelEmailAttachments(lastVisitEmailId),
        ]);
        //console.log("ListEmailDetails - useEffect - emailData ->", emailData);
        //console.log("ListEmailDetails - useEffect - attachmentData ->", attachmentData);

        let sales_invoice_id;
        // get list relation invoices
        if (emailData.ok) {
          //console.log("ListEmailDetails - useEffect - emailData.data ->", emailData.data);
          //console.log("ListEmailDetails - useEffect - emailData.data.data.body_html ->", emailData.data.data.body_html);

          setEmailDetailsData(emailData.data.data);

          sales_invoice_id = emailData.data.data.sales_invoice_id;

          const sanitizedHtmlContent = DOMPurify.sanitize(emailData.data.data.body_html);
          setEmailBodyHtml(sanitizedHtmlContent);
        }

        if (attachmentData.ok) {
          //console.log("ListEmailDetails - useEffect - emailAttachmentData ->", attachmentData.data);
          //console.log("ListEmailDetails - useEffect - emailAttachmentsTotal ->", attachmentData.data.total);

          if (attachmentData.data && attachmentData.data.total && attachmentData.data.total > 0) {
            //console.log("ListEmailDetails - useEffect - emailAttachmentData ->", "Yeahhhh we found more then 0 attachments");
            //console.log("ListEmailDetails - useEffect - emailAttachmentData - data ->", attachmentData.data.data);

            attachmentData.data.data.map((detail, detailIndex) => {
              (async () => {
                links.push({
                  id: detail.id,
                  filename: detail.filename,
                  file: "",
                  base64Str: "data:application/pdf;base64," + "",
                  blobData: ""
                });

                setEmailAttachmentLinks(links);

              })();
            });

            setEmailAttachmentData(attachmentData.data);
            setEmailAttachmentsTotal(attachmentData.data.total);

          } else {
            //renderAttachments(null);
            setEmailAttachmentData([]);
            setEmailAttachmentsTotal(0);
            setEmailAttachmentLinks(null);
          }

          //console.log("ListEmailDetails - useEffect - emailAttachmentData - GetAttachmentData - links ->", links);

        }
      })();
    }
  }, [emailDetails, lastVisitEmailId]);

  //useEffect(() => {
  //console.log("ListEmailDetails - useEffect - emailAttachmentData - GetAttachmentData - emailAttachmentLinks ->", emailAttachmentLinks);

  //if (emailAttachmentLinks) {
  //console.log("ListEmailDetails - useEffect - emailAttachmentData - GetAttachmentData - emailAttachmentLinks ->", emailAttachmentLinks);
  //}
  //}, [emailAttachmentLinks]);

  const renderAttachments = (links, id = null, filename = null, base64Str = null) => {
    //console.log("ListEmailDetails - renderAttachments - links ->", links);
    //console.log("ListEmailDetails - renderAttachments - id ->", id);
    //console.log("ListEmailDetails - renderAttachments - filename ->", filename);
    //console.log("ListEmailDetails - renderAttachments - base64Str ->", base64Str);

    if (links) {
      return (
        <React.Fragment>
          {links.map((detail, detailIndex) => {
            return (
              <ListGroupItem key={detailIndex} className={`d-flex align-items-center`} style={{ paddingBottom: "4px", borderBottom: "0px", backgroundColor: "transparent", padding: "4x 4px" }}>
                <div data-attachment-id={detail.id} data-file={detail.filename} onClick={(e) => viewAttachment(e, detail.id, detail.filename)} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon color="gray" icon={faPaperclip} className={`mr-2`} />
                  {detail.filename}
                  {buttonsVisible && <ButtonGroup className='ml-4'>
                    <Button className='btn btn-gray' color="grey" onClick={(e) => downloadAttachment(e, detail.id, detail.filename)}>
                      Download
                    </Button>
                    <Button className='btn btn-gray' color="grey" onClick={(e) => viewAttachment(e, detail.id, detail.filename)}>
                      View
                    </Button>
                  </ButtonGroup>}
                </div>
              </ListGroupItem>)
          })}
        </React.Fragment>)
    } else {
      return <p style={{ marginBottom: 0 }}>No attachments found.</p>
    }
  };

  const downloadAttachment = (e, attachment_id, filename) => {
    e.preventDefault();
    //console.log("ListEmailDetails - downloadAttachment - attachment_id ->", attachment_id);
    //console.log("ListEmailDetails - downloadAttachment - filename ->", filename);
    //console.log("ListEmailDetails - downloadAttachment - e ->", e);

    (async () => {
      let { ok, data } = await GetAttachmentData(attachment_id, filename);
      if (ok) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data]));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
    })();
  };

  const viewAttachment = (e, attachment_id, filename) => {
    e.preventDefault();
    //console.log("ListEmailDetails - viewAttachment - attachment_id ->", attachment_id);
    //console.log("ListEmailDetails - viewAttachment - filename ->", filename);
    //console.log("ListEmailDetails - viewAttachment - e ->", e);

    (async () => {
      let { ok, data } = await GetAttachmentData(attachment_id, filename);
      if (ok) {
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - ok ->", ok);
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - data ->", data);
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - mediaTypes ->", mediaTypes);

        let bloblData = new Blob([data], { type: 'application/pdf' });
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - bloblData ->", bloblData);

        let url = window.URL.createObjectURL(bloblData);
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - url ->", url);

        let bloblDataTxt = await bloblData.text();
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - bloblDataTxt ->", bloblDataTxt);

        let bloblDataStream = bloblData.stream();
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - bloblDataStream ->", bloblDataStream);

        let bloblDataStreamGetGetReader = await bloblDataStream.getReader().read();
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - bloblDataStreamGetGetReader ->", bloblDataStreamGetGetReader);

        let bloblDataStreamGetGetReaderValue = bloblDataStreamGetGetReader.value;
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - bloblDataStreamGetGetReaderValue ->", bloblDataStreamGetGetReaderValue);

        let base64Data = encodeBase64(bloblDataTxt);
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - base64Data (file) ->", base64Data);

        let blobBase64String = "data:application/pdf;base64," + base64Data;
        //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - blobBase64String (string) ->", blobBase64String);

        const reader = new FileReader();
        reader.onload = (e) => {
          //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - FileReader - e ->", e);
          //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - FileReader - e.target ->", e.target);
          //console.log("ListEmailDetails - viewAttachment - GetAttachmentData - FileReader - reader.result.split(',')[1] ->", reader.result.split(',')[1]);

          setPdfBase64(reader.result.split(',')[1]); // Extract base64 string
          setSelectedViewFilename(filename);
          setSelectedViewFileId(attachment_id);
          setEmailAttachmentDocument("data:application/pdf;base64," + reader.result.split(',')[1]);
        };
        reader.readAsDataURL(bloblData);

        toggleEmailAttachment();
      }
    })();
  };

  function onDocumentLoadSuccess({ numPages }) {
    //console.log("ListEmailDetails - onDocumentLoadSuccess - numPages ->", numPages);
    setNumPagesAttachment(numPages);
    setNumPages(numPages);
  }

  function onPageLoadSuccess() {
    //console.log("ListEmailDetails - onPageLoadSuccess - window.innerWidth ->", window.innerWidth);
    setPageWidth(window.innerWidth);

    const canvas = document.getElementsByTagName("canvas");
    //console.log("ListEmailDetails - onPageLoadSuccess - canvas ->", canvas);
    //console.log("ListEmailDetails - onPageLoadSuccess - canvas - canvas[0].width ->", canvas[0].width);
    //console.log("ListEmailDetails - onPageLoadSuccess - canvas - canvas[0].offsetWidth ->", canvas[0].offsetWidth);

    if (canvas && canvas[0] && canvas[0].offsetWidth) {
      let newCanvasWidth = canvas[0].offsetWidth + 40;
      //console.log("ListEmailDetails - onPageLoadSuccess - newCanvasWidth ->", newCanvasWidth);
      setCanvasLoadedWidth(newCanvasWidth);
    }
    if (canvas && canvas[0] && canvas[0].offsetHeight) {
      let newCanvasHeight = canvas[0].offsetHeight + 64;
      //console.log("ListEmailDetails - onPageLoadSuccess - newCanvasHeight ->", newCanvasHeight);
      setCanvasLoadedHeight(newCanvasHeight);
    }
    setLoading(false);
  }

  const encodeBase64 = (data) => {
    const buf = Buffer.from(data, 'utf-8').toString();
    //console.log("ListEmailDetails - encodeBase64 - buf ->", buf);
    return Buffer.from(data).toString('base64');
  };

  const toggleEmailLogs = () => {
    //console.log("ListEmailDetails - toggleEmailLogs - emailLogsModal ->", emailLogsModal);
    setEmailLogsModal(!emailLogsModal);
  };

  const toggleEmailAttachment = () => {
    //console.log("ListEmailDetails - toggleEmailAttachment - emailAttachmentModal ->", emailAttachmentModal);
    setEmailAttachmentModal(!emailAttachmentModal);
  };

  const handleWheelEvent = (e) => {
    e.preventDefault();
    //console.log("ListEmailDetails - handleWheelEvent - e ->", e);
    //console.log("ListEmailDetails - handleWheelEvent - e.nativeEvent.wheelDelta ->", e.nativeEvent.wheelDelta);
    if (e.nativeEvent.wheelDelta < 0 && pageNumber && pageNumber < numPages) {
      //scrolldown
      setPageNumber(pageNumber + 1);
    } else {
      //scrollup
      if (e.nativeEvent.wheelDelta > 0 && pageNumber && pageNumber > 1 && pageNumber <= numPages) {
        setPageNumber(pageNumber - 1);
      }
    }
  }

  function goToNextPage() {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  function goToPreviousPage() {
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  }

  function decreasePageScale() {
    //console.log("ListEmailDetails - decreasePageScale - scale (current) ->", scale);
    if (scale > 0.6)
      setScale(scale - 0.2);
  }

  function increasePageScale() {
    //console.log("ListEmailDetails - increasePageScale - scale (current) ->", scale);
    setScale(scale + 0.2);
  }

  function printPDF() {
    //console.log("ListEmailDetails - printPDF");
    const canvas = document.getElementsByTagName("canvas");
    //console.log("ListEmailDetails - printPDF - canvas ->", canvas);
    let printDocumentPDF = pdfBase64;
    printJS({ printable: printDocumentPDF, type: 'pdf', base64: true });
  }

  const goToInvoice = (e, invID) => {
    e.preventDefault();

    if (invID) {
      history.push(`/relations/${emailDetailsData.relation_id}/invoices/${invID}/`);
    }
  };

  return (
    <React.Fragment>
      <Row className={`d-flex`}>
        <Col className={`pt-0`}>
          <h5 className="mb-0 py-0">Email {lastVisitEmailId ? `#${lastVisitEmailId}` : `not found`}</h5>
        </Col>
        <Col className={`justify-content-end text-right align-items-right`}>
          <OverlayTrigger
            key={`email-logs-tooltip-overlay`}
            placement={`bottom`}
            transition={false}
            delay={200}
            overlay={
              <Tooltip
                className="tooltip"
                container="body"
                id={`tooltip-bottom-email-logs`}
              >
                {`Email logs`}
              </Tooltip>}
          >
            <Button
              disabled={false}
              className="btn ml-1 mb-1 text-truncate"
              color="gray"
              id={`email-logs`}
              onClick={toggleEmailLogs}>
              <Cpu size={15} color="grey" />
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>

      <Row className={`pt-0 mb-0`}>
        <Col xs="12" sm="12" md="6" className="col-12">
          <ListGroup className={`block-list`} flush>
            <h5 className={`pb-0 list-group-sub-heading`} style={{
              paddingTop: "0",
              lineHeight: "2.3",
              fontWeight: "bolder",
              margin: "0 2px 5px",
              fontSize: "smaller",
              borderBottomWidth: "0",
              textAlign: "left",
              width: "auto",
              display: "block",
              opacity: "0.4"
            }}>Email details</h5>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>ID #</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center`}>{emailDetailsData && emailDetailsData.id ? emailDetailsData.id : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>TO name</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center text-truncate`}>{emailDetailsData && emailDetailsData.to_name ? emailDetailsData.to_name : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>CC name</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center text-truncate`}>{emailDetailsData && emailDetailsData.cc_name ? emailDetailsData.cc_name : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>From name</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center text-truncate`}>{emailDetailsData && emailDetailsData.from_name ? emailDetailsData.from_name : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>Company</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center text-truncate`}>{emailDetailsData && emailDetailsData.company_name ? emailDetailsData.company_name : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>BCC email</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center text-truncate`}>{emailDetailsData && emailDetailsData.bcc_email ? emailDetailsData.bcc_email : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>Subject</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center`}>{emailDetailsData && emailDetailsData.subject ? emailDetailsData.subject : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
          </ListGroup>
        </Col>

        <Col xs="12" sm="12" md="6" className="col-12">
          <ListGroup className={`block-list`} flush>
            <h5 className={`pb-0 list-group-sub-heading`} style={{
              paddingTop: "0",
              lineHeight: "2.3",
              fontWeight: "bolder",
              margin: "0 2px 5px",
              fontSize: "smaller",
              borderBottomWidth: "0",
              textAlign: "left",
              width: "auto",
              display: "block",
              opacity: "0.4"
            }}>Other details</h5>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>Invoice ID #</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center`}>
                {emailDetailsData && emailDetailsData.sales_invoice_id ? 
                  <a href="#" className='text-link' onClick={(e) => goToInvoice(e, emailDetailsData.sales_invoice_id)}>{emailDetailsData.sales_invoice_id}</a> : 
                  String.fromCharCode(8212)
                }
              </Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>TO email</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center text-truncate`}>{emailDetailsData && emailDetailsData.to_email ? emailDetailsData.to_email : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>CC email</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center text-truncate`}>{emailDetailsData && emailDetailsData.cc_email ? emailDetailsData.cc_email : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>From email</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center text-truncate`}>{emailDetailsData && emailDetailsData.from_email ? emailDetailsData.from_email : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>Customer nr.</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center`}>{emailDetailsData && emailDetailsData.customer_number ? emailDetailsData.customer_number : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>Template ID #</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center`}>{emailDetailsData && emailDetailsData.email_template_id ? emailDetailsData.email_template_id : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
            <ListGroupItem className={`d-flex align-items-center px-0`}>
              <Col xs="6" md="5" lg="4" className={`form-label`}>Relation ID #</Col>
              <Col xs="6" md="7" lg="8" className={`form-label-content none-edit d-flex align-items-center`}>{emailDetailsData && emailDetailsData.relation_id ? emailDetailsData.relation_id : String.fromCharCode(8212)}</Col>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>

      <Row className={`pt-0 mt-4`}>
        <Col xs="12" sm="12" md="12" className="col-12">
          <ListGroup className={`block-list`} flush>
            <h5 className="pb-0 list-group-sub-heading">Attachment(s)</h5>
            {/*console.log("ListEmailDetails - render - emailAttachmentLinks ->", emailAttachmentLinks)*/}
            {renderAttachments(emailAttachmentLinks)}
          </ListGroup>
        </Col>
      </Row>

      <Row className={`mt-4 f2x-det-cont-notes`}>
        <Col>
          <div className={`p-0`}>
            <div className={`customer-notes`}>
              <Row>
                <Col><b>Email preview</b></Col>
                <Col className="text-right">
                  {/*<Button
                      disabled={false}
                      className="btn ml-1 text-truncate"
                      color="gray"
                      id={`email-logs`}
                      onClick={toggleEmailLogs}>
                    <FontAwesomeIcon color="gray" icon={faCalendar}/>
                  </Button>*/}
                  <OverlayTrigger
                    key={`email-attachments-tooltip-overlay`}
                    placement={`bottom`}
                    transition={false}
                    delay={200}
                    overlay={
                      <Tooltip
                        className="tooltip"
                        container="body"
                        id={`tooltip-bottom-email-attachments`}
                      >
                        {`Email attachments`}
                      </Tooltip>}
                  >
                    <Button
                      style={{ width: "34.9px", padding: "0.25rem 0.6rem", visibility: "hidden" }}
                      disabled={false}
                      className="btn ml-1"
                      color="gray"
                      id={`email-attachments`}
                      onClick={(e) => history.push('#')}>
                      <FontAwesomeIcon color="gray" icon={faEnvelope} />
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </div>
            <div className={`body-customer-notes email-preview shadow-sm`} style={{ borderBottom: "none", border: "none" }}>
              <div className={`mt-1 mb-0 card`}>
                <div className={`card-body email-preview`} style={{ backgroundColor: "white" }}>
                  {emailBodyHtml &&
                    <div dangerouslySetInnerHTML={{ __html: emailBodyHtml }}
                      style={{ borderBottom: "none", border: "none" }} />
                  }

                  {!emailBodyHtml &&
                    <p style={{ marginBottom: 0 }}>No email found.</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* modal email logs */}
      {emailLogsModal &&
        <ModalWrapper title={`Email Log #${lastVisitEmailId}`} className="f2x-modal-logs" show={emailLogsModal} hide={toggleEmailLogs}>
          <ListEmailLogs logs={emailLogsData} id={lastVisitEmailId} dispatch={dispatch} />
        </ModalWrapper>
      }

      {/* modal email attachments */}
      {emailAttachmentModal &&
        <ModalWrapper className="f2x-modal-logs email-tab-view" show={emailAttachmentModal} hide={toggleEmailAttachment} style={{ margin: "30px auto", overflow: "hidden", width: `${canvasLoadedWidth}px`, minHeight: canvasLoadedHeight ? `${canvasLoadedHeight}px` : "100%" }}>
          <div hidden={loading} className="flex items-center" onWheel={(e) => handleWheelEvent(e)}>
            <div className={`d-flex items-center justify-between w-full absolute z-10 px-0`} style={{ paddingBottom: "10px", borderBottom: "1px solid #d1d1d1" }}>
              <div style={{ display: "inherit" }}>
                <button
                  onClick={goToPreviousPage}
                  title="Previous page"
                  color="gray"
                  disabled={pageNumber <= 1}
                  className="btn btn-gray btn-sm px-2 py-24 text-gray-400 hover:text-gray-50 focus:z-20"
                >
                  <span className="sr-only">Previous</span>
                  <FontAwesomeIcon color="gray" icon={faChevronLeft} className="h-10 w-10" aria-hidden="true" />
                </button>
                <button
                  onClick={goToNextPage}
                  title="Next page"
                  color="gray"
                  disabled={pageNumber >= numPages}
                  className="btn btn-gray btn-sm px-2 py-24 text-gray-400 hover:text-gray-50 focus:z-20"
                >
                  <span className="sr-only">Next</span>
                  <FontAwesomeIcon color="gray" icon={faChevronRight} className="h-10 w-10" aria-hidden="true" />
                </button>
              </div>
              <div className="text-truncate">
                <p style={{ marginBottom: 0, lineHeight: "28px", marginLeft: "15px", marginRight: "15px", fontSize: "small", overflow: "hidden" }} title={`Page ${pageNumber} of ${numPages}`}>Page {pageNumber} of {numPages}</p>
              </div>
              <div style={{ display: "inherit" }}>
                <button
                  onClick={decreasePageScale}
                  title="Zoom out"
                  color="gray"
                  disabled={scale <= 0.5}
                  className="btn btn-gray btn-sm px-2 py-24 text-gray-400 hover:text-gray-50 focus:z-20"
                >
                  <span className="sr-only">decrease scale</span>
                  <FontAwesomeIcon color="gray" icon={faSearchMinus} className="h-10 w-10" aria-hidden="true" />
                </button>
                <button
                  onClick={increasePageScale}
                  title="Zoom in"
                  color="gray"
                  disabled={scale >= 1.0}
                  className="btn btn-gray btn-sm px-2 py-24 text-gray-400 hover:text-gray-50 focus:z-20"
                >
                  <span className="sr-only">increase scale</span>
                  <FontAwesomeIcon color="gray" icon={faSearchPlus} className="h-10 w-10" aria-hidden="true" />
                </button>
              </div>
              <div style={{ display: "inherit" }}>
                <button
                  onClick={printPDF}
                  title="Print"
                  style={{ marginLeft: "5px" }}
                  color="gray"
                  className="btn btn-gray btn-sm px-2 py-24 text-gray-400 hover:text-gray-50 focus:z-20"
                >
                  <span className="sr-only">print PDF</span>
                  <FontAwesomeIcon color="gray" icon={faPrint} className="h-10 w-10" aria-hidden="true" />
                </button>
                <button
                  title="Download"
                  color="gray"
                  className="btn btn-gray btn-sm px-2 py-24 text-gray-400 hover:text-gray-50 focus:z-20"
                  onClick={(e) => downloadAttachment(e, selectedViewFileId, selectedViewFilename)}
                >
                  <span className="sr-only">Download PDF</span>
                  <FontAwesomeIcon color="gray" icon={faDownload} className="h-10 w-10" aria-hidden="true" />
                </button>
              </div>
              <div className="text-truncate"><p style={{ marginBottom: 0, lineHeight: "28px", marginLeft: "15px", marginRight: "15px", fontSize: "small" }} title={selectedViewFilename}>{selectedViewFilename}</p></div>
            </div>
            <div className="h-full d-flex justify-center mx-auto">
              <Document
                file={emailAttachmentDocument}
                data={emailAttachmentDocument}
                options={optionsCreatePDF}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(error) => console.log(error)}
              >
                <Page
                  key={pageNumber}
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  onLoadError={(error) => console.log(error)}
                  onLoadSuccess={onPageLoadSuccess}
                  onRenderError={() => setLoading(false)}
                  width={Math.max(pageWidth * 0.8, 390)}
                  scale={scale}
                />
              </Document>
            </div>
          </div>
          {/*<PDFViewer data={emailAttachmentDocument} />*/}
        </ModalWrapper>
      }

    </React.Fragment>
  );
};

const sidebarMenuSelector = createSelector(
  state => state.sidebarMenu,
  sidebarMenu => sidebarMenu
);

const mapStateToProps = ({ solrSearch, sidebarMenu, mvloaderInvoice, dispatch, relation }) => ({ solrSearch, sidebarMenu, mvloaderInvoice, dispatch, relation });

export default connect(mapStateToProps)(withTranslation()(withRouter(ListEmailDetails)));
