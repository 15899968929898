import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem, Row, Col, Button, Input } from 'reactstrap';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { mvLoaderToggleInv } from '../../../redux/actions/mvLoaderInvoiceActions';
import ScrollBar from 'react-perfect-scrollbar';
import { useTranslation, withTranslation } from 'react-i18next';
import { mvLoaderToggle } from '../../../redux/actions/mvLoaderActions';
import { faTags, faCaretUp, faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hideSidebarMenuInvoices } from '../../../redux/actions/sidebarMenuActions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { createSelector } from 'reselect';
import FilterSearch from '../../../components/FilterSearch';

const EmailList = ({
  onScroll,
  Id,
  relationId,
  data,
  setData,
  setSelectedItem,
  selectedItem,
  setLastVisitEmailId,
  invoiceListUpdatedDetails,
  emailList,

  isUpdated,
  setToggleForm,
  mvloaderInvoice,
  invoice,

  setActiveTab,
  relation,
  dispatch,
  solrSearch,
  hasLoaded,
  ...props
}) => {

  //console.log("ListEmails - props ->", props);
  //console.log("ListEmails - emailList ->", emailList);
  //console.log("ListEmails - selectedItem ->", selectedItem);

  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const params = useParams();
  const { param } = params;

  const [locationKeysLoaded, setLocationKeysLoaded] = useState(true);
  //const [active, setActive] = useState(selectedItem ? parseInt(selectedItem) : null);
  const [active, setActive] = useState(null);
  const [activeOnly, setActiveOnly] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [debugInfo, setDebugInfo] = useState(false);
  const [listFilterText, setListFilterText] = useState("");
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const relation_id = relation && relation.id ? relation.id : null;

  const navButtonsListEnabled = "hidden"; // visible -or- hidden

  //let lastVisitEmailId = sessionStorage.getItem('lastVisitEmailId');
  //console.log("ListEmails - lastVisitEmailId ->", lastVisitEmailId);

  const setActivatedItem = (id) => {
    if (!isClicked) {
      setIsClicked(true);

      sessionStorage.removeItem('lastVisitEmailId');
      //dispatch({type: 'INVOICE', payload: null});

      sessionStorage.setItem('lastVisitEmailId', id);

      dispatch(mvLoaderToggle(true));
      dispatch(hideSidebarMenuInvoices());

      if (selectedItem === id) {
        dispatch(mvLoaderToggleInv(false));
        return false;
      }

      setLastVisitEmailId(id);
      setSelectedItem(id);
      setActive(id);
      dispatch(mvLoaderToggleInv(false));
    }
  };

  const toInvoice = (e, id, href) => {
    //console.log("toInvoice click - e ->", e);
    //console.log("toInvoice click - id ->", id);

    e.preventDefault();
    e.stopPropagation();

    setIsClicked(false);
    //setActiveTab(5);

    if (href) {
      history.push(href);
    }
  };

  useEffect(() => {
    const dataObj = data && data[0];

    let lastVisitEmailId = sessionStorage.getItem('lastVisitEmailId');
    //console.log("ListEmails - useEffect(active) - lastVisitEmailId ->", lastVisitEmailId);

    if (isNaN(lastVisitEmailId) && isNaN(selectedItem)) {
      history.push(`/relations/${relationId}/emails/${dataObj ? parseInt(
        dataObj.id) + "/" : ""}`);

    } else {
      history.push(`/relations/${relationId}/emails/${lastVisitEmailId
        ? lastVisitEmailId + '/'
        : selectedItem ? selectedItem + '/' : (dataObj
          ? dataObj.id + '/'
          : '')}`);
    }

    setIsClicked(false);

  }, [active]);

  useEffect(() => {
    const filtered = emailList.filter((f) => {
      const filterTextUpper = listFilterText.toUpperCase();
      const subjectMatches = f.subject && f.subject.toUpperCase().includes(filterTextUpper);
      const toEmailMatches = f.to_email && f.to_email.toUpperCase().includes(filterTextUpper);
      const createdAtMatches = f.created_at && f.created_at.toUpperCase().includes(filterTextUpper);
      return subjectMatches || toEmailMatches || createdAtMatches || listFilterText === '';
    });

    setFilteredEmails(filtered);
    setTotalSize(filtered.length);
  }, [emailList, listFilterText]);

  const handleFilterTextInputChange = (e) => {
    setListFilterText(e.target.value);

    setTimeout(() => {
      e.target.focus();
    }, 100);
  };

  const ItemFormatter = ({ id, relation_id, tenant_id, customer_number, person_id, email_template_id, sales_invoice_id, invoice_no, subject, created_at, to_email, from_email, to_name, from_name, updated_at }) => {
    let href = "/relations/" + relation_id + "/invoices/" + sales_invoice_id + "/";

    return (
      <>
        <Row className="mb-1">
          <Col className="mv-text-bold text-truncate">
            <span title={`To: ${to_email}`}>{to_email}</span>
          </Col>
          <Col className="text-right text-truncate">
            <span title={t("date_time_format", { date: created_at ? new Date(created_at) : "" })}>{t("date_time_format", { date: created_at ? new Date(created_at) : "" })}</span>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="text-truncate">
            <OverlayTrigger placement={`bottom`} transition={false} overlay={
              <Tooltip id={`tooltip-right-${sales_invoice_id}-${id}`}>
                <div className={`popover-html-contents`}>
                  <h3 className="white" color="white">Subject:</h3>
                  <p>{subject}</p>
                </div>
              </Tooltip>}
            >
              <span id={`subject-${sales_invoice_id}-${id}`}>{subject}</span>
            </OverlayTrigger>
          </Col>
        </Row>
        {(subject === false) &&
          <Row style={{ marginTop: "3px" }}>
            <Col style={{ textAlign: "right" }}>
              {to_email && (
                <OverlayTrigger placement={`right`} transition={false} overlay={
                  <Tooltip id={`tooltip-right`}>
                    <div className={`popover-html-contents`}>
                      <h3 className="white" color="white">Email To/From:</h3>
                      <p>To: {`${to_email}`} - {`${to_name}`}<br />From: {`${from_email}`} - {`${from_name}`}</p>
                    </div>
                  </Tooltip>}
                >
                  <span
                    className="ml-2 billing-run-icon"
                    id={`email-to-${sales_invoice_id}`}
                  >
                    <FontAwesomeIcon
                      className="f2x-mv-tabs-list-item-icon"
                      color="grey"
                      icon={faTags}
                    />
                  </span>
                </OverlayTrigger>
              )}
            </Col>
          </Row>}
      </>)
  };

  const focusItem = (el_id) => {
    let focusItem = document.getElementById(`email-list-item-${el_id}`);
    if (focusItem) {
      focusItem.focus();
    }
  }

  const listFormatter = (item, isActive) => {
    if (isActive) {
      return <ListGroupItem style={{ paddingBottom: "0.55rem" }} key={item.id} id={`email-list-item-${item.id}`} tabIndex="-1" 
        className={`${isActive || item.id === parseInt(active) ? 'active' : ''} ${mvloaderInvoice || isClicked
          ? 'disabled'
          : ''} inv-list-action-item-${item.id}`}
        action>

        <ItemFormatter {...item} isUpdated={isUpdated} data-focus={focusItem(item.id)} />
      </ListGroupItem>;
    } else {
      return <ListGroupItem style={{ paddingBottom: "0.55rem" }} key={item.id} id={`email-list-item-${item.id}`} tabIndex="-1" 
        className={`${mvloaderInvoice || isClicked
          ? 'disabled'
          : ''} inv-list-action-item-${item.id}`}
        action
        onClick={() => setActivatedItem(item.id)}>
        <ItemFormatter {...item} isUpdated={isUpdated} />
      </ListGroupItem>;
    }
  };

  function handleScroll() {
    const emailListEl = document.getElementById("scrollEmailList");
    if (emailListEl.scrollHeight - emailListEl.scrollTop !== emailListEl.clientHeight) return;
    setIsFetching(true);
  }

  function fetchMoreListItems() {
    onScroll();
    setIsFetching(false);
  }

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  }, [isFetching]);

  useEffect(() => {
    const el = document.getElementById("scrollEmailList");
    el.addEventListener('scroll', handleScroll);
  }, []);


  if (emailList && emailList.length > 0) {
    return (
      <>
        <div className={`align-content-start all-0 d-flex flex-column flex-list-items`}>
          <div className="invoice-list-btns">
            <span className="filter-list-counter">{totalSize !== 1 ? `${totalSize} emails` : `${totalSize} email` } </span>
            
            <FilterSearch
              listFilterText={listFilterText}
              handleFilterTextInputChange={handleFilterTextInputChange}
              inputId="filter_email_text"
              inputName="filter_email_text"
              setListFilterText={setListFilterText}
              setFilteredEmails={setFilteredEmails}
              emailList={emailList}
            />

            <Button color="gray" className="mb-1 btn ml-1" style={{ float: "right", visibility: navButtonsListEnabled }}>
              <FontAwesomeIcon color="gray" icon={faCaretUp} />
            </Button>
            <Button color="gray" className="mb-1 btn ml-1" style={{ float: "right", visibility: navButtonsListEnabled }}>
              <FontAwesomeIcon color="gray" icon={faCaretDown} />
            </Button>
          </div>
          <ScrollBar id="scrollEmailList" className="f2x-ls-det-sb sb-invoices col-row-bottom"
            options={{ suppressScrollX: true }}>
            <ListGroup className={`f2x-ls-det`} id="f2x-ls-det-invoices">
              {filteredEmails.map((r, i) => {
                const isActive = selectedItem ? parseInt(selectedItem) === r.id : i === 0;
                return listFormatter(r, isActive, i);
              })}
            </ListGroup>
          </ScrollBar>          
        </div>
      </>
    )
  } else {
    return (<div><span style={{ fontSize: "smaller", opacity: "0.25" }}>{totalSize} emails</span></div>);
  }
};

const sidebarMenuSelector = createSelector(
  state => state.sidebarMenu,
  sidebarMenu => sidebarMenu
);

const mapStateToProps = ({ solrSearch, sidebarMenu, mvloaderInvoice, dispatch, relation }) => ({ solrSearch, sidebarMenu, mvloaderInvoice, dispatch, relation });

export default connect(mapStateToProps)(withTranslation()(withRouter(EmailList)));
