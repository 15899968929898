import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { UpdateUser } from "../../../controllers/users";
import { GetPersons, UpdatePerson } from "../../../controllers/persons";
import { updateUser as updateStateUser } from "../../../redux/actions/userActions";
import { loaderToggle } from "../../../redux/actions/loaderActions";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";

const PrivateInfo = ({ ...props }) => {
  const { dispatch, loader, user, person } = props;

  const [personData, setPersonData] = useState([]);
  console.log(personData);

  const handleInputChange = ({ target: { name, value } }) => {
    let person = Object.assign({}, personData);
    person[name] = value;
    setPersonData(person);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("handleSubmit - personData ->", personData);
    const { first_name, last_name, phone, mobile } = personData;

    let notificationsToastArr = [];
    if (!first_name) {
      notificationsToastArr.push({
        title: "First name field is required!",
        icon: "warn",
      });
    }
    if (!last_name) {
      notificationsToastArr.push({
        title: "Last name is required!",
        icon: "warn",
      });
    }
    if (!phone) {
      notificationsToastArr.push({
        title: "Phone field is required!",
        icon: "warn",
      });
    }
    if (!mobile) {
      notificationsToastArr.push({
        title: "Mobile field is required!",
        icon: "warn",
      });
    }
    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      // create array for showing multiple toast alerts
      let toastContainerPersonUpdate = [];
      // Update user data - email (username)
      (async () => {
        const { ok, data } = await UpdatePerson(personData.id, personData);
        console.log("UpdatePerson - ok - data ->", ok, data);

        if (ok) {
          toastContainerPersonUpdate.push({
            title: "Private info has been updated!",
            icon: "success",
          });
        } else {
          toastContainerPersonUpdate.push({
            title: "Private info not updated!",
            icon: "error",
          });
        }
        await handleOk(toastContainerPersonUpdate);
      })();
    }
  };

  const handleOk = async (options) => {
    dispatch(loaderToggle(false));

    if (options && options.length > 0) {
      return ToastMulti(options, "container-validation-id");
    }
    return false;
  };

  useEffect(() => {
    if (user) {
      let { person_id } = user;

      if (person_id) {
        (async () => {
          await Promise.all([GetPersons(person_id)]).then((r) => {
            const [data] = r;
            if (data && data.data && data.data.data) {
              const person = [];
              person.push(data.data.data);
              setPersonData(person[0]);
            }
          });
        })();
      }
    }
  }, []);

  return (
      <React.Fragment>
        <div className={`f2x-rel-mv-detail`}>
          <Form className="f2x-rel-det-form" onSubmit={(e) => handleSubmit(e)}>
            <div className="relation-details-card">
              <ListGroup className={`block-list list-group`} flush>
                <h5 className="pb-0 list-group-sub-heading" style={{
                  paddingTop: "0",
                  lineHeight: "2.3",
                  fontWeight: "bolder",
                  margin: "0 2px 5px",
                  fontSize: "smaller",
                  textAlign: "left",
                  width: "auto",
                  display: "block",
                  opacity: "0.4"
                }}
                >
                  Private settings
                </h5>
                <ListGroupItem
                    className={`px-0 p-0`}
                >
                  <Col className="rel-dt-label col-lg-4 col-md-4 col-sm-3 col-xs-12">Full name</Col>
                  <Col className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                    <div className="form-row">
                      <Col className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <Input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={
                              personData && personData.first_name
                                  ? personData.first_name
                                  : ""
                            }
                            onChange={(e) => handleInputChange(e)}
                        />
                      </Col>
                      <Col className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <Input
                            type="text"
                            id="middle_name"
                            name="middle_name"
                            value={
                              personData && personData.middle_name
                                  ? personData.middle_name
                                  : ""
                            }
                            onChange={(e) => handleInputChange(e)}
                        />
                      </Col>
                      <div className="w-100 d-block d-md-none"> </div>
                      <Col className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <Input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={
                              personData && personData.last_name
                                  ? personData.last_name
                                  : ""
                            }
                            onChange={(e) => handleInputChange(e)}
                        />
                      </Col>
                    </div>
                  </Col>
                </ListGroupItem>
                <ListGroupItem
                    className={`px-0 p-0 d-print-inline-block`}
                >
                  <Col className="rel-dt-label col-lg-4 col-md-4 col-sm-3 col-xs-12">Email</Col>
                  <Col className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                    <Input
                        type="email"
                        name="email"
                        value={
                          personData && personData.email ? personData.email : ""
                        }
                        onChange={(e) => handleInputChange(e)}
                    />
                  </Col>
                </ListGroupItem>
                <ListGroupItem
                    className={`px-0 p-0 d-print-inline-block`}
                >
                  <Col className="rel-dt-label col-lg-4 col-md-4 col-sm-3 col-xs-12">Phone</Col>
                  <Col className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                    <Input
                        type="phone"
                        name="phone"
                        value={
                          personData && personData.phone ? personData.phone : ""
                        }
                        onChange={(e) => handleInputChange(e)}
                    />
                  </Col>
                </ListGroupItem>
                <ListGroupItem
                    className={`px-0 p-0 d-print-inline-block`}
                >
                  <Col className="rel-dt-label col-lg-4 col-md-4 col-sm-3 col-xs-12">Mobile</Col>
                  <Col className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                    <Input
                        type="phone"
                        name="mobile"
                        value={
                          personData && personData.mobile
                              ? personData.mobile
                              : ""
                        }
                        onChange={(e) => handleInputChange(e)}
                    />
                  </Col>
                </ListGroupItem>
              </ListGroup>
            </div>
            <div className="d-flex justify-content-end pt-0 pb-0 px-0 mt-4 save-cancel-buttons">
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </React.Fragment>
  );
};

const userSelector = createSelector(
    (state) => state.user,
    (user) => user
    ),
    loaderSelector = createSelector(
        (state) => state.loader,
        (loader) => loader
    ),
    mapStateToProps = createSelector(
        userSelector,
        loaderSelector,
        (user, loader) => ({ user, loader })
    );

export default connect(mapStateToProps)(PrivateInfo);
