import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { GetMyFiscalYears } from '../../../controllers/fiscal_years';
import FiscalYearForm from "./Form";
import ParentTable from '../../../components/ParentTable';
import { withRouter } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { Helmet } from "react-helmet";

class FiscalYears extends React.Component {
  formatDate = (cell) => {
    if (cell)
      return <span className="date-format">{this.props.t("date_format", { date: new Date(cell) })}</span>
  };

  setRandomStyle = (cell, row, rowIndex) => {
    return cell !== null && cell !== undefined && cell !== '' ?
      <Badge color={(cell.toString()) === "1" ? 'success' : (cell.toString()) === "0" ? 'danger' : ''} className="badge-pill mr-1 mb-1 text-uppercase" >
        {cell.toString() === "1" ? 'yes' : 'no'}
      </Badge> : String.fromCharCode(8212)
  };

  render() {
    const { id, tabTitle } = this.props;

    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="FiscalYears"
          keyField="id"
          id={id}
          noTopBorder={true}
          data={GetMyFiscalYears}
          columns={[
            {
              dataField: "description",
              text: "Description",
              sort: true,
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "date_from",
              text: "Date from",
              sort: true,
              formatter: this.formatDate,
              attrs: {
                'data-label': 'Date from'
              }
            },
            {
              dataField: "date_to",
              text: "Date to",
              sort: true,
              formatter: this.formatDate,
              attrs: {
                'data-label': 'Date to'
              }
            },
            {
              dataField: "is_closed",
              text: "Closed",
              sort: true,
              align: "center",
              headerStyle: () => {
                return { 'textAlign': 'center' }
              },
              formatter: this.setRandomStyle,
              attrs: {
                'data-label': 'Closed'
              }
            }
          ]}
          rowAction="link"
          headerDropdownOpts={[{
            label: 'Add',
            action: FiscalYearForm
          }]}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation('translations')(connect()(FiscalYears)));
