import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI;
    //PostAPI = Api.postAPI,
    //UpdateAPI = Api.updateAPI

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        let data = await method ? method(endpoint, params) : GetAPI(endpoint, params);
        return data
    })()
};

const GetOrders = (params) => {
    return asyncFunc(`ext/orders`, params)
};

const GetOrder = (id) => {
    return asyncFunc(`ext/orders/${id}`)
};

export { GetOrders, GetOrder }
