import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UploadPostAPIwithCustomHeader = Api.uploadPostAPIwithCustomHeader,
    UpdateAPI = Api.updateAPI,
    RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : await GetAPI(endpoint, params);
  })();
};

const GetAllAttachments = (params) => {
  return asyncFunc(`/attachments`, params);
};

const GetSingleAttachment = (id, params) => {
  return asyncFunc(`/attachments/${id}`, params);
};

const AddAttachments = (params) => {
  return asyncFunc(`/attachments`, params, PostAPI);
};

const AddAttachmentsWithContentType = (params) => {
  return asyncFunc(`/attachments`, params, UploadPostAPIwithCustomHeader);
};

const UpdateAttachments = (id, params) => {
  return asyncFunc(`/attachments/${id}`, params, PostAPI);
};

const RemoveAttachments = (id) => {
  return asyncFunc(`/attachments/${id}`, null, RemoveAPI);
};

export {
  GetAllAttachments,
  GetSingleAttachment,
  AddAttachments,
  UpdateAttachments,
  RemoveAttachments,
  AddAttachmentsWithContentType,
}
