import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {GetUser, UpdateUser} from "../../../controllers/users";
import {loaderToggle} from "../../../redux/actions/loaderActions";
import {updateUser} from '../../../redux/actions/userActions';
import {createSelector} from "reselect";
import {Form, Col, ListGroup, ListGroupItem, CustomInput, Button} from 'reactstrap';
import ToastMulti from "../../../components/ToastMulti";
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {nl,enGB,enUS} from "date-fns/locale";
import Select from 'react-select';

registerLocale('nl', nl);
registerLocale('en', enGB);
registerLocale('us', enUS);

setDefaultLocale('nl');

const
    itemsPerPageTablesOpts = [
      { value: 10, label: "10 items" },
      { value: 25, label: "25 items" },
      { value: 50, label: "50 items" },
      { value: 100, label: "100 items" },
      { value: 200, label: "200 items" },
    ];

const
    debugOpts = [
      { value: 0, label: "No" },
      { value: 1, label: "Yes" },
    ];

  const
    relationTabOpts = [
      { value: 1, label: "Show tab icon & text" },
      { value: 2, label: "Show tab icon only" },
      { value: 3, label: "Show tab text only" },
    ];

  const
    tenantTabOpts = [
      { value: 1, label: "Show tab icon & text" },
      { value: 2, label: "Show tab icon only" },
      { value: 3, label: "Show tab text only" },
    ];

const UserSettings = ({ ...props }) => {
  const {dispatch, loader} = props;

  const [language, setLanguage] = useState(props.i18n.language ? props.i18n.language : "nl");
  const [userSettingsData, setUserSettingsData] = useState({
    itemsPerPageTables: 100,
    debugInfo: 0,
    tenant_tabs: 2,
    relation_tabs: 2,
    language: "nl"
  }); // => default

  const onLanguageHandle = (event) => {
    let newLang = event.target.value ? event.target.value : event.target.id ? event.target.id : 'nl';
    setLanguage(newLang);
    setDefaultLocale(newLang);
    props.i18n.changeLanguage(newLang).then(r => console.log(r));
  };

  const renderRadioButtons = () => {
    return (
      <React.Fragment>
        <div className="nav-lang-switch" role="group" aria-labelledby="language-switch-group" id="language-switch">
          <div className="custom-radio custom-control custom-controlled-radio" key={`language-us`} style={{ paddingLeft: "3px", marginBottom: "0.25rem" }}>
            <CustomInput
              name="language"
              onChange={(e) => onLanguageHandle(e)}
              value="us"
              id="language-us"
              defaultChecked={language && language === 'us' ? true : false}
              type="radio"
              label="United States"
              style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1"}}
            />
            <span className="ml-2" style={{ position: "absolute", right: "0", top: "0" }}>
              <img src={`/assets/flags/us.png`} width={20} alt={`US`} className="mr-2" />
            </span>
          </div>
          <div className="custom-radio custom-control custom-controlled-radio" key={`language-en`} style={{ paddingLeft: "3px", marginBottom: "0.25rem" }}>
            <CustomInput
              name="language"
              onChange={(e) => onLanguageHandle(e)}
              value="en"
              id="language-en"
              defaultChecked={language && language === 'en' ? true : false}
              type="radio"
              label="English"
              style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1"}}
            />
            <span className="ml-2" style={{ position: "absolute", right: "0", top: "0" }}>
              <img src={`/assets/flags/en.png`} width={20} alt={`EN`} className="mr-2" />
            </span>
          </div>
          <div className="custom-radio custom-control custom-controlled-radio" key={`language-nl`} style={{ paddingLeft: "3px", marginBottom: "0.25rem" }}>
            <CustomInput
              name="language"
              value="nl"
              id="language-nl"
              defaultChecked={language && language === 'nl' ? true : false}
              type="radio"
              label="Nederlands"
              onChange={(e) => onLanguageHandle(e)}
              style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1"}}
            />
            <span className="ml-2" style={{ position: "absolute", right: "0", top: "0" }}>
              <img src={`/assets/flags/nl.png`} width={20} alt={`NL`} className="mr-2" />
            </span>
          </div>
        </div>
      </React.Fragment>
    )
  };

  const handleSelectChange = (e, name) => {
    let userSettings = Object.assign({}, userSettingsData);
    userSettings[name] = e.value;
    setUserSettingsData(userSettings);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Settings user - handleSubmit - userSettingsData ->", userSettingsData);

    let toastContainerUserUpdate = [];

    const { user } = props;
    console.log("Settings user - handleSubmit - user ->", user);

    let userData = Object.assign({}, user);
    userData.json_data = JSON.stringify(userSettingsData);

    console.log("Settings user - handleSubmit - user (PUT) ->", userData);
    console.log("Settings user - handleSubmit - userData.id ->", userData.id);

    let newUserData = {};
    newUserData.json_data = JSON.stringify(userSettingsData);
    console.log("Settings user - handleSubmit - newUserData ->", newUserData);

    (async () => {
      const { ok, data } = await UpdateUser(newUserData, user.id);
      console.log("UpdateUser - ok ->", ok);
      console.log("UpdateUser - data ->", data);

      //dispatch(updateUser(data.data));
      dispatch(updateUser({json_data: newUserData.json_data}));

      if (ok) {
        toastContainerUserUpdate.push({
          title: "User (json) data has been updated!",
          icon: "success",
        });
      } else {
        toastContainerUserUpdate.push({
          title: "User (json) data not updated!",
          icon: "error",
        });
      }

      await handleOk(toastContainerUserUpdate);

    })();
  };

  const handleOk = async (options) => {
    dispatch(loaderToggle(false));

    const { user } = props;
    console.log("Settings user - handleOk - user ->", user);

    // set json data in session storage 
    

    if (options && options.length > 0) {
      return ToastMulti(options, "container-validation-id");
    }
    return false;
  };

  //userSettingsData
  useEffect(() => {
    console.log("Settings user - useEffect - userSettingsData (updated) ->", userSettingsData);
  }, [userSettingsData]);

  useEffect(() => {
    // check json_data user - session storage
    const { user } = props;
    console.log("Settings user - useEffect - user (current) ->", user);

    if (user && user.json_data) {
      console.log("Settings user - useEffect - user json_data (current) ->", user.json_data);

      // set userSettingsData
      let json_data = JSON.parse(user.json_data);
      console.log("Settings user - useEffect - user json_data (PARSE) ->", json_data);

      setUserSettingsData(json_data);
    }
  }, []);

  return (
      <React.Fragment>
        <div className={`f2x-rel-mv-detail`}>
          <Form className="f2x-rel-det-form" onSubmit={(e) => handleSubmit(e)}>
            <div className="relation-details-card">
              <ListGroup className={`block-list list-group`} flush>
                <h5 className="pb-0 list-group-sub-heading" style={{
                  paddingTop: "0",
                  lineHeight: "2.3",
                  fontWeight: "bolder",
                  margin: "0 2px 5px",
                  fontSize: "smaller",
                  textAlign: "left",
                  width: "auto",
                  display: "block",
                  opacity: "0.4"
                }}
                >
                  Language settings
                </h5>
                <ListGroupItem className={`px-0 p-0`}>
                  <Col className="rel-dt-label col-lg-4 col-md-4 col-sm-3 col-xs-12">Language</Col>
                  <Col className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                    <div className="form-row mt-2 mb-1">
                      <Col className="col-12">
                        {renderRadioButtons()}
                      </Col>
                    </div>
                  </Col>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Form>
        </div>

        <div className={`f2x-rel-mv-detail mt-3`}>
          <Form className="f2x-rel-det-form" onSubmit={(e) => handleSubmit(e)}>
            <div className="relation-details-card">
              <ListGroup className={`block-list list-group`} flush>
                <h5 className="pb-0 list-group-sub-heading" style={{
                  paddingTop: "0",
                  lineHeight: "2.3",
                  fontWeight: "bolder",
                  margin: "0 2px 5px",
                  fontSize: "smaller",
                  textAlign: "left",
                  width: "auto",
                  display: "block",
                  opacity: "0.4"
                }}
                >
                  Local user settings
                </h5>
                <ListGroupItem className={`d-flex align-items-center px-0 p-0`}>
                  <Col className="rel-dt-label col-md-4">Tabs (relations)</Col>
                  <Col className="col-md-8">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={relationTabOpts}
                        name="relation_tabs"
                        value={relationTabOpts.filter((item) => item.value === userSettingsData["relation_tabs"])}
                        onChange={(e) => handleSelectChange(e, "relation_tabs")}
                        maxMenuHeight={300}
                        isDisabled={false}
                    />
                  </Col>
                </ListGroupItem>
                <ListGroupItem className={`d-flex align-items-center px-0 p-0`}>
                  <Col className="rel-dt-label col-md-4">Tabs (tenants)</Col>
                  <Col className="col-md-8">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={tenantTabOpts}
                        name="tenant_tabs"
                        value={tenantTabOpts.filter((item) => item.value === userSettingsData["tenant_tabs"])}
                        onChange={(e) => handleSelectChange(e, "tenant_tabs")}
                        maxMenuHeight={300}
                        isDisabled={false}
                    />
                  </Col>
                </ListGroupItem>
                <ListGroupItem className={`d-flex align-items-center px-0 p-0`}>
                  <Col className="rel-dt-label col-md-4">Items per page (tables default)</Col>
                  <Col className="col-md-8">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={itemsPerPageTablesOpts}
                        value={itemsPerPageTablesOpts.filter((item) => item.value === userSettingsData["itemsPerPageTables"])}
                        onChange={(e) => handleSelectChange(e, "itemsPerPageTables")}
                        maxMenuHeight={300}
                        isDisabled={false}
                    />
                  </Col>
                </ListGroupItem>
                <ListGroupItem className={`d-flex align-items-center px-0 p-0`}>
                  <Col className="rel-dt-label col-md-4">Show debug info</Col>
                  <Col className="col-md-8">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={debugOpts}
                        value={debugOpts.filter((item) => parseInt(item.value) === parseInt(userSettingsData["debugInfo"]))}
                        onChange={(e) => handleSelectChange(e, "debugInfo")}
                        isDisabled={false}
                    />
                  </Col>
                </ListGroupItem>
              </ListGroup>
            </div>
            <div className="d-flex justify-content-end pt-0 pb-0 px-0 mt-4 save-cancel-buttons">
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </React.Fragment>
  );
};

const userSelector = createSelector(
    (state) => state.user,
    (user) => user
    ),
    loaderSelector = createSelector(
        (state) => state.loader,
        (loader) => loader
    ),
    mapStateToProps = createSelector(
        userSelector,
        loaderSelector,
        (user, loader) => ({ user, loader })
    );

export default connect(mapStateToProps)(withTranslation('common')(withRouter(UserSettings)));
