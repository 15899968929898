import React, { useEffect, useState } from "react";
import useOuterClick from "../hooks/useOuterClick";

const Content = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const innerRef = useOuterClick(() => {
    setIsOpen(false);
  });

  return (
      <div
        ref={innerRef}
        className={`content settings js-settings${isOpen ? " open" : ""}`}
      >
        { props.children }
      </div>
  );
};

export default Content;
