import * as types from "../constants";

export default function reducer(state = null, actions) {
  const { payload } = actions;

  switch (actions.type) {
    case types.TENANTS: 
      return {
        ...state,
        ...payload
      };
    case types.ADD_TENANT:
      let newPayload = Object.assign({}, payload),
          parent = Object.values(state).find(i => parseInt(i.id) === parseInt(payload.parent_id)),
          thisParent = Object.assign({}, parent);

      const count = Object.keys(state).length;

      if (thisParent.parent) {
        delete thisParent.parent
      }

      newPayload.parent = thisParent;
      return {
        ...state,
        [count]: { ...newPayload }
      };
    default:
      return state;
  }
}