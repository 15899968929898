import React, { useEffect, useState, useRef } from 'react';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { GetRelationsSummary } from '../../controllers/relations';
import RelationForm from './Form';
import NewRelation from "./New";
import ParentTable from '../../components/ParentTable';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { customFilter } from 'react-bootstrap-table2-filter';
import CustomFilterInput from '../../components/filters/CustomFilterInput';

const redirect = () => {
    return window.location = '/relations/new'
};

const CustomerFormatter = (cell, row) => {
    return cell ? <div className="link"><span id={`customerFormat__` + row.id}>{cell}</span></div> :
        <span className="null-value">{String.fromCharCode(8212)}</span>
};

const PersonFormatter = (cell, row) => {
    return cell ? <div className="link"><span id={`personFormat__` + row.id}>{cell}</span></div> :
        <span className="null-value">{String.fromCharCode(8212)}</span>
};

const CompanyFormatter = (cell, row) => {
    return cell ? <div className="link"><span id={`companyFormat__` + row.id}>{cell}</span></div> :
        <span className="null-value">{String.fromCharCode(8212)}</span>
};

const AddressFormatter = (cell, row) => {
    return cell ? <div className="link d-inline-block text-truncate"><span id={`addressFormat__` + row.id}>{cell}</span></div> :
        <span className="null-value">{String.fromCharCode(8212)}</span>
};

const SubscriptionFormatter = (cell, row) => {
    return cell ? <div className="link">
        <span
            className={`status-circle ${row.subscription_status === 50
                ? 'success'
                : row.subscription_status === 99
                    ? 'danger'
                    : row.subscription_status === 10
                        ? 'warning'
                        : row.subscription_status === 30
                            ? 'default'
                            : row.subscription_status === 40
                                ? 'default'
                                : row.subscription_status === 80
                                    ? 'default'
                                    : 'info'}`}> </span>
        <span className="d-block" title={row.subscription_description}>{row.subscription_description}</span>
    </div> : <span className="null-value">{String.fromCharCode(8212)}</span>
};

const Relations = ({ ...props }) => {
    let history = useHistory();

    //console.log("Relations - dataTable - (props) ->", props);

    const params = useParams();
    const location = props.location;
    const { t, i18n } = useTranslation();
    const tableName = "Relations";

    let [customerNumberFilter, setCustomerNumberFilter] = useState(null);
    let [personFilter, setPersonFilter] = useState(null);
    let [companyFilter, setCompanyFilter] = useState(null);
    let [addressFilter, setAddressFilter] = useState(null);
    let [subscriptionFilter, setSubscriptionFilter] = useState(null);
    let [lastInvoiceFilter, setLastInvoiceFilter] = useState(null);

    const [toggleForm, setToggleForm] = useState(false);
    const [toggleFilterForm, setToggleFilterForm] = useState(false);
    const [collapseState, setCollapseState] = useState("is-hidden");
    const [clearFilters, setClearFilters] = useState(false);
    const [historyFilter, setHistoryFilter] = useState(true);

    const [data, setData] = useState([]);
    const [customerNumber, setCustomerNumber] = useState("");
    const [person, setPerson] = useState("");
    const [company, setCompany] = useState("");
    const [address, setAddress] = useState("");
    const [subscription, setSubscription] = useState("");
    const [lastInvoice, setLastInvoice] = useState("");

    const [sort, setSort] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(100);        
    const [paginationCount, setPaginationCount] = useState(1);

    let [filtersFilter, setFiltersFilter] = useState({});
    let [filters, setFilters] = useState({ filter: null, keyword: "" });

    const [enabledFilter, setEnabledFilter] = useState(props.enabledFilter ? props.enabledFilter : false);
    const clearedItemName = useRef("");
    const [isLoadData, setIsLoadData] = useState(null);
    const [comparatorFilter, setComparatorFilter] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState("");

    const HeaderMenuFormatter = () => {
        setToggleForm(!toggleForm);
        return toggleForm;
    };

    const InvoiceFormatter = (cell, row) => {
        if (row) {
            let invoice_no = row.sales_invoice_invoice_number ? row.sales_invoice_invoice_number : false;
            let date = row.sales_invoice_date ? t("date_format", { date: new Date(row.sales_invoice_date) }) : false;
            let price_excl_vat = row.sales_invoice_excl_price ? t("currency_format", { number: Number(parseFloat(row.sales_invoice_excl_price)) }) : t("currency_format", { number: Number(0) });
            let price_incl_vat = row.sales_invoice_incl_price ? t("currency_format", { number: Number(parseFloat(row.sales_invoice_incl_price)) }) : 0;
            return invoice_no && date
                ? <div className="link d-inline-block text-truncate">{`${invoice_no} | ${date} | ${price_excl_vat} / ${price_incl_vat}`}</div>
                : !invoice_no && !date
                    ? <span className="null-value">{String.fromCharCode(8212)}</span>
                    : !invoice_no && date
                        ? <div className="link d-inline-block text-truncate">{String.fromCharCode(8212) + ' | ' + `${date} | ${price_excl_vat} / ${price_incl_vat}`}</div>
                        : invoice_no && !date
                            ? <div className="link d-inline-block text-truncate">{String.fromCharCode(8212) + ' | ' + `${price_excl_vat} / ${price_incl_vat}`}</div>
                            : <span className="null-value">{String.fromCharCode(8212)}</span>

        } else {
            return <div className="link d-inline-block text-truncate null-value">{String.fromCharCode(8212)}</div>
        }
    };

    /* Filter */
    const handleFiltering = (e, name, multiSelectable, comparator = null) => {
        // console.log("RelationsList (table) - handleFiltering -> check (before setting filter)");
        // console.log("RelationsList (table) - handleFiltering - enabledFilter ->", enabledFilter);
        // console.log("RelationsList (table) - handleFiltering - name ->", name);
        // console.log("RelationsList (table) - handleFiltering - e ->", e);

        let filterStoredItem;
        // custom comparator
        if (comparator) {
            setComparatorFilter(comparator);
        }

        if (!enabledFilter) {
            let searchedKeyword = sessionStorage.getItem("qStrRelationsKeyword");
            let filtersObj = sessionStorage.getItem("qStrRelationsFiltersObj");
            if (filtersObj) {
                let parsedFiltersObj = JSON.parse(filtersObj);
                if (parsedFiltersObj && parsedFiltersObj.filter) {
                    setHistoryFilter(true);
                    setCollapseState("is-visible");
                    setToggleFilterForm(true);

                    if (parsedFiltersObj.filter) {
                        filtersFilter = parsedFiltersObj.filter;
                        let searchedKeyword = sessionStorage.getItem("qStrRelationsKeyword");
                        if (searchedKeyword) {
                            parsedFiltersObj.keyword = searchedKeyword;
                        }
                        setFiltersFilter(filtersFilter);
                    }
                    filters = parsedFiltersObj;
                    setFilters(filters);
                }
            } else if (searchedKeyword) {
                setHistoryFilter(true);
                filters.keyword = searchedKeyword;
                setFilters(filters);
            }
        }

        if (
            (name && e && (e.value !== "" || e.value === "")) ||
            (name && e === null) ||
            (e && e.key && e.key === "Enter") ||
            (e && multiSelectable && (e.length > 0 || e.length === null || e.length === 0)) ||
            historyFilter
        ) {
            let value = "";
            switch (name) {
                case "customer_number":
                    let customer_number = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["customer_number"] ? filtersFilter["customer_number"] : "";
                    if (customer_number === "") {
                        customer_number = null;
                    }
                    value = customer_number;
                    setCustomerNumber(value);
                    break;
                case "full_name":
                    let person = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["full_name"] ? filtersFilter["full_name"] : "";
                    if (person === "") {
                        person = null;
                    }
                    value = person;
                    setPerson(value);
                    break;
                case "company_name":
                    let company = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["company_name"] ? filtersFilter["company_name"] : "";
                    if (company === "") {
                        company = null;
                    }
                    value = company;
                    setCompany(value);
                    break;
                case "full_address":
                    let address = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["full_address"] ? filtersFilter["full_address"] : "";
                    if (address === "") {
                        address = null;
                    }
                    value = address;
                    setAddress(value);
                    break;
                case "subscription_description":
                    let subscription_description = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["subscription_description"] ? filtersFilter["subscription_description"] : "";
                    if (subscription_description === "") {
                        subscription_description = null;
                    }
                    value = subscription_description;
                    setSubscription(value);
                    break;
                case "last_invoice":
                    let last_invoice = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["last_invoice"] ? filtersFilter["last_invoice"] : "";
                    if (last_invoice === "") {
                        last_invoice = null;
                    }
                    value = last_invoice;
                    setLastInvoice(value);
                    break;
                default:
                    break;
            }

            if (filtersFilter) {
                if (filtersFilter[name] && (!value || value === "" || value.length === 0)) {
                    delete filtersFilter[name];
                } else {
                    if (value) {
                        filtersFilter[name] = value;
                    }
                }

                // console.log("RelationsList - handleFiltering - filtersFilter (before setting new filtersFilter) ->", filtersFilter);

                if (Object.entries(filtersFilter).length > 0) {
                    setFiltersFilter(filtersFilter);
                    filters.filter = filtersFilter;
                    setFilters(filters);
                    setQueryParameters();
                    setIsLoadData(true);
                } else {
                    filters.filter = filtersFilter;
                    setFilters(filters);
                    setQueryParameters();
                    setIsLoadData(true);
                }
            }
        }
    };

    const setQueryParameters = () => {
        // console.log("RelationsList - setQueryParameters - filters ->", filters);
        // console.log("RelationsList - setQueryParameters - props ->", props);
        // console.log("RelationsList - setQueryParameters - page ->", page);

        // get history (default) params
        let params = {};
        const historyParamsQueryString = sessionStorage.getItem("qStrRelations");
        // console.log("RelationsList - handleFiltering - setQueryParameters - historyParamsQueryString ->", historyParamsQueryString);

        if (historyParamsQueryString) {
            // page - offset - count - sort - search
            historyParamsQueryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
                if (key === 'page' || key === 'offset' || key === 'count') {
                    params[key] = parseInt(value);
                } else if (key === 'sort') {
                    params[key] = value;
                } else if (key === 'search') {
                    params[key] = value;
                }
            });
        }

        // console.log("RelationsList - handleFiltering - setQueryParameters - params ->", params);
        // console.log("RelationsList - handleFiltering - setQueryParameters - filters ->", filters);

        let key;
        let value;
        let query = [];
        let searchFilterQuery = {};
        let filterQuery = {};
        let searchFilterStr = "";
        let comparatorItems = {};

        query.push(`count=1`);
        if (!filters) {
            query.push(`search=`);
            query.push(`filter=`);
        }
        query.push(`offset=${params && params.offset ? params.offset : sizePerPage ? sizePerPage : 10}`);
        query.push(`page=${params && params.page ? params.page : page ? page : 1}`);
        query.push(`sort=${params && params.sort ? params.sort : sort ? sort : ""}`);

        // remove filters from session storage
        sessionStorage.removeItem("qStrRelations");
        sessionStorage.removeItem("qStrRelationsFilter");
        sessionStorage.removeItem("qStrRelationsFilterStr");
        sessionStorage.removeItem("qStrRelationsFiltersObj");
        sessionStorage.removeItem("qStrRelationsKeyword");

        //if (filters) {
        for (key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (key === "keyword") {
                    value = filters["keyword"];
                    searchFilterQuery[key] = value && true && value !== "" && value !== null ? value : "";
                    // set search for querystring
                    query.push(`search=${searchFilterQuery[key]}`);

                } else if (key === "filter") {
                    value = filters["filter"];

                    // set total filter
                    let queryKeyFilters = "";
                    let queryFilters = "";
                    // if value is array - value.length > 0 - there are filters
                    if (value && Array.isArray(value)) {

                        //console.log("RelationsList - handleFiltering - setQueryParameters - value (isArray) ->", value);

                        // value holds the key & value(s) for the total filter query
                        if (value.length > 0) {
                            let queryFilter = [];
                            for (let i = 0; i <= value.length; i++) {
                                // all possible filters
                                for (let key in value[i]) {
                                    if (value[i].hasOwnProperty(key)) {
                                        queryFilter.push(`${key}%${value[i][key]}`);
                                    }
                                }
                            }
                            if (queryFilter.length > 0) {
                                queryKeyFilters = queryFilter.join(",");
                                queryFilters = queryFilter.join("~");
                            }
                            searchFilterQuery[key] = queryKeyFilters ? queryKeyFilters : "";
                            filterQuery[key] = queryFilters ? queryFilters : "";
                        }
                    } else {
                        //console.log("RelationsList - handleFiltering - setQueryParameters - value (when value is not an array) ->", value);

                        let name;
                        let queryFilter = [];

                        for (name in value) {
                            if (value.hasOwnProperty(name)) {
                                //console.log("RelationsList - handleFiltering - setQueryParameters - value (name) ->", name);
                                //console.log("RelationsList - handleFiltering - setQueryParameters - value[name] ->", value[name]);

                                queryFilter.push(
                                    `${name}%${value[name]}`);
                            }

                            //console.log("RelationsList - handleFiltering - setQueryParameters - queryFilter ->", queryFilter);
                            if (queryFilter.length > 0) {
                                queryKeyFilters = queryFilter.join(",");
                                queryFilters = queryFilter.join("~");
                            }
                            searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
                            filterQuery['filter'] = queryFilters ? queryFilters : "";
                        }
                    }
                    query.push(`filter=${queryKeyFilters}`);
                }
            }
        }
        query.join("&").toString();
        setEnabledFilter(true);

        //} else {
        //    query.join("&").toString();
        //}

        const location_search_query = query;
        //console.log("RelationsList - setQueryParameters - handleFiltering - location_search_query (=== query) ->", location_search_query);
        let location_search = location_search_query ? location_search_query.join("&") : "";

        sessionStorage.setItem("qStrRelations", location_search);
        sessionStorage.setItem("qStrRelationsFilter", searchFilterQuery['filter'] && searchFilterQuery['filter'] !== "" ? searchFilterQuery['filter'] : "");
        sessionStorage.setItem("qStrRelationsFilterStr", filterQuery['filter'] && filterQuery['filter'] !== "" ? filterQuery['filter'] : "");
        sessionStorage.setItem("qStrRelationsKeyword", searchFilterQuery['keyword'] ? searchFilterQuery['keyword'] : searchKeyword);
        sessionStorage.setItem("qStrRelationsFiltersObj", JSON.stringify(filters));
        //sessionStorage.setItem("qStrRelationsComparatorItems", JSON.stringify(comparatorItems));
    };

    const handleFilterClick = (e) => {
        if (e) {
            e.preventDefault();
        }

        sessionStorage.removeItem("qStrRelations");
        sessionStorage.removeItem("qStrRelationsFilter");
        sessionStorage.removeItem("qStrRelationsFilterStr");
        sessionStorage.removeItem("qStrRelationsKeyword");
        sessionStorage.removeItem("qStrRelationsFiltersObj");


        setCustomerNumberFilter([]);
        setPersonFilter([]);
        setCompanyFilter([]);
        setAddressFilter([]);
        setSubscriptionFilter([]);
        setLastInvoiceFilter([]);

        setCustomerNumber("");
        setPerson("");
        setCompany("");
        setAddress("");
        setSubscription("");
        setLastInvoice("");

        setPage(1);
        setPageNum(1);
        setSort("");
        setSizePerPage(100);
        setFiltersFilter({});
        setFilters({ filter: null, keyword: "" });
        setClearFilters(true);
        setPaginationCount(1);

        setQueryParameters();
        setIsLoadData(true);

        let input = document.getElementById("table-search-form");
        input.value = '';

        sessionStorage.clear(); // This clears all data in sessionStorage
    };

    useEffect(() => {        
        if (clearedItemName && clearedItemName !== "") {
            let name = clearedItemName;
            let tableStorageFilterObjId = "qStrRelationsFiltersObj";
            let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
            if (name && filtersObj) {
                let filtersObjParsed = JSON.parse(filtersObj);
                if (filtersObjParsed && filtersObjParsed.filter &&
                    filtersObjParsed.filter.length > 0) {
                    for (let i = 0; i < filtersObjParsed.filter.length; i++) {
                        if (filtersObjParsed.filter[i] &&
                            filtersObjParsed.filter[i][name]) {
                            filtersObjParsed.filter.splice(i, 1);

                            // save filters to session storage (stringify)
                            sessionStorage.setItem("qStrRelationsFiltersObj",
                                JSON.stringify(filtersObjParsed));

                            if (filtersObjParsed.filter &&
                                filtersObjParsed.filter.length > 0) {
                                setFiltersFilter(filtersObjParsed.filter);
                            } else {
                                setFiltersFilter({});
                            }
                            setFilters(filtersObjParsed);
                            return;
                        }
                    }
                }
            }
        }
    }, [clearedItemName]);

    const collapseFilterAction = (e) => {
        if (e) {
            e.preventDefault();
        }

        let filterVisible = sessionStorage.getItem("relationsFilterVisible");
        //console.log("RelationsList - filterVisible ->", filterVisible);

        let collapseStatus = toggleFilterForm === true ? "is-hidden" : "is-visible";
        setCollapseState(collapseStatus);
        setToggleFilterForm(!toggleFilterForm);
        setToggleForm(false);

        // set to sessionStorage
        sessionStorage.setItem("relationsFilterVisible", collapseStatus);
    };

    useEffect(() => {        
        let filterVisible = sessionStorage.getItem("relationsFilterVisible");
        //console.log("RelationsList - filterVisible ->", filterVisible);
        if (filterVisible === "is-visible") {
            setToggleFilterForm(true);
            setToggleForm(false);
            setCollapseState("is-visible");

            // set to sessionStorage
            sessionStorage.setItem("relationsFilterVisible", "is-visible");
        }
    }, []);

    useEffect(() => {        
        let searchedKeyword = sessionStorage.getItem("qStrRelationsKeyword");
        if (searchedKeyword) {
            setHistoryFilter(true);
            handleFiltering();
        }
    }, []);

    useEffect(() => {        
        //console.log("RelationsList - useEffect - filters.keyword - searchKeyword ->", searchKeyword);
        if (searchKeyword && searchKeyword !== "") {
            filters.filter = filtersFilter;
            filters.keyword = searchKeyword;
            setFilters(filters);

            sessionStorage.setItem("qStrRelationsKeyword", searchKeyword);

            setPage(1);
            setPageNum(1);
            setQueryParameters();
            setIsLoadData(true);
        }
    }, [searchKeyword]);

    useEffect(() => {        
        const { dispatch } = props;
        dispatch({ type: 'RELATION', payload: null });
        dispatch({ type: 'SUBSCRIPTION', payload: null });
        dispatch({ type: 'INVOICE', payload: null });
    }, []);

    useEffect(() => {        
        let currentParams = new URL(document.location).searchParams;
        //console.log("RelationsList - useEffect (params) - currentParams ->", currentParams);
        let filter = currentParams.get("filter"); // is there a filter param
        if (filter) {
            // console.log("RelationsList - useEffect (params) - currentParams - filter ->", filter);
        }

        if (currentParams.size && currentParams.size > 0) {
            //console.log("RelationsList - useEffect (params) - currentParams.size ->", currentParams.size);
            let qStr = currentParams.toString();
            //console.log("RelationsList - useEffect (params) - qStr ->", qStr);
            sessionStorage.setItem("qStrRelations", qStr);
            setQueryParameters();
        }
    }, []);    

    return (
        <>
            {toggleForm &&
                <NewRelation
                    setToggleForm={setToggleForm}
                    selectedRelation={false}
                    title={true}
                    redirect={"relations-list"}
                />
            }

            {!toggleForm &&
                <div className="wrapper-box-table-filter">
                    <h1 className="text-bold mb-3">Relations</h1>
                    <ParentTable
                        table="Relations"
                        keyField="id"
                        id="RelationsTable"
                        data={GetRelationsSummary}
                        columns={[
                            {
                                dataField: "id",
                                text: "#",
                                align: 'left',
                                hidden: true,
                                headerStyle: { width: '10%' },
                                attrs: {
                                    'data-label': 'id'
                                }
                            }, {
                                dataField: "customer_number",
                                filter: customFilter({
                                    type: "text",
                                    style: { display: "inline-grid" },
                                    getFilter: (filter) => {
                                        setCustomerNumberFilter(filter);
                                    },
                                }),
                                filterRenderer: (onFilter, column) => (
                                    <CustomFilterInput
                                        onFilter={onFilter}
                                        column={column}
                                        handleFiltering={handleFiltering}
                                        setClearedItemName={(value) => clearedItemName.current = value}
                                        isClearFilter={clearFilters}
                                        value={customerNumber ? customerNumber : ""}
                                        enabledFilter={enabledFilter}
                                        setEnabledFilter={setEnabledFilter}
                                        table="Relations"
                                    />
                                ),
                                title: true,
                                text: "Customer no.",
                                formatter: CustomerFormatter,
                                sort: true,
                                headerTitle: true,
                                headerStyle: { width: '9%' },
                                attrs: {
                                    'data-label': 'Customer no.'
                                }
                            }, {
                                dataField: "full_name",
                                filter: customFilter({
                                    type: "text",
                                    style: { display: "inline-grid" },
                                    getFilter: (filter) => {
                                        setPersonFilter(filter);
                                    },
                                }),
                                filterRenderer: (onFilter, column) => (
                                    <CustomFilterInput
                                        onFilter={onFilter}
                                        column={column}
                                        handleFiltering={handleFiltering}
                                        setClearedItemName={(value) => clearedItemName.current = value}
                                        isClearFilter={clearFilters}
                                        value={person ? person : ""}
                                        enabledFilter={enabledFilter}
                                        setEnabledFilter={setEnabledFilter}
                                        table="Relations"
                                    />
                                ),
                                title: true,
                                text: "Person",
                                formatter: PersonFormatter,
                                sort: true,
                                headerTitle: true,
                                headerStyle: { width: '14%' },
                                attrs: {
                                    'data-label': 'Person'
                                }
                            }, {
                                dataField: "company_name",
                                filter: customFilter({
                                    type: "text",
                                    style: { display: "inline-grid" },
                                    getFilter: (filter) => {
                                        setCompanyFilter(filter);
                                    },
                                }),
                                filterRenderer: (onFilter, column) => (
                                    <CustomFilterInput
                                        onFilter={onFilter}
                                        column={column}
                                        handleFiltering={handleFiltering}
                                        setClearedItemName={(value) => clearedItemName.current = value}
                                        isClearFilter={clearFilters}
                                        value={company ? company : ""}
                                        enabledFilter={enabledFilter}
                                        setEnabledFilter={setEnabledFilter}
                                        table="Relations"
                                    />
                                ),
                                title: true,
                                text: "Company",
                                formatter: CompanyFormatter,
                                sort: true,
                                headerTitle: true,
                                headerStyle: { width: '14%' },
                                attrs: {
                                    'data-label': 'Company'
                                }
                            }, {
                                dataField: "full_address",
                                filter: customFilter({
                                    type: "text",
                                    style: { display: "inline-grid" },
                                    getFilter: (filter) => {
                                        setAddressFilter(filter);
                                    },
                                }),
                                filterRenderer: (onFilter, column) => (
                                    <CustomFilterInput
                                        onFilter={onFilter}
                                        column={column}
                                        handleFiltering={handleFiltering}
                                        setClearedItemName={(value) => clearedItemName.current = value}
                                        isClearFilter={clearFilters}
                                        value={address ? address : ""}
                                        enabledFilter={enabledFilter}
                                        setEnabledFilter={setEnabledFilter}
                                        table="Relations"
                                    />
                                ),
                                title: true,
                                text: "Address",
                                formatter: AddressFormatter,
                                sort: true,
                                classes: 'txt-overflow-hide',
                                headerTitle: true,
                                headerStyle: { width: '19%' },
                                attrs: {
                                    'data-label': 'Address'
                                }
                            }, {
                                dataField: "subscription_description",
                                filter: customFilter({
                                    type: "text",
                                    style: { display: "inline-grid" },
                                    getFilter: (filter) => {
                                        setSubscriptionFilter(filter);
                                    },
                                }),
                                filterRenderer: (onFilter, column) => (
                                    <CustomFilterInput
                                        onFilter={onFilter}
                                        column={column}
                                        handleFiltering={handleFiltering}
                                        setClearedItemName={(value) => clearedItemName.current = value}
                                        isClearFilter={clearFilters}
                                        value={subscription ? subscription : ""}
                                        enabledFilter={enabledFilter}
                                        setEnabledFilter={setEnabledFilter}
                                        table="Relations"
                                    />
                                ),
                                title: true,
                                text: "Subscription",
                                formatter: SubscriptionFormatter,
                                sort: true,
                                classes: 'txt-overflow-hide',
                                headerTitle: true,
                                headerStyle: { width: '18%' },
                                attrs: {
                                    'data-label': 'Subscription'
                                }
                            }, {
                                dataField: "last_invoice",
                                filter: customFilter({
                                    type: "text",
                                    style: { display: "inline-grid" },
                                    getFilter: (filter) => {
                                        setLastInvoiceFilter(filter);
                                    },
                                }),
                                filterRenderer: (onFilter, column) => (
                                    <CustomFilterInput
                                        onFilter={onFilter}
                                        column={column}
                                        handleFiltering={handleFiltering}
                                        setClearedItemName={(value) => clearedItemName.current = value}
                                        isClearFilter={clearFilters}
                                        value={lastInvoice ? lastInvoice : ""}
                                        enabledFilter={enabledFilter}
                                        setEnabledFilter={setEnabledFilter}
                                        table="Relations"
                                    />
                                ),
                                title: true,
                                text: "Last Invoice",
                                formatter: InvoiceFormatter,
                                sort: false,
                                classes: 'txt-overflow-hide',
                                headerTitle: true,
                                headerStyle: { width: "16%", textAlign: "right" },
                                attrs: {
                                    'data-label': 'Last Invoice'
                                }
                            }
                        ]}
                        headerDropdownOpts={[
                            {
                                label: "Filter",
                                action: "collapseFilterAction",
                                icon: <FontAwesomeIcon icon={faFilter} size={`sm`} className={`mr-0`} color={`grey`} />,
                                isCustom: true,
                                tooltip: true,                                
                                toggleActive: toggleFilterForm ? "active" : ""
                             },
                            {
                                label: "Clear",
                                action: "handleFilterClick",
                                icon: <FontAwesomeIcon icon={faTrash} size={`sm`} className={`mr-0`} color={`grey`} />,
                                isCustom: true,
                                tooltip: true
                            },
                            {
                                label: "Add C+S",
                                action: HeaderMenuFormatter,
                                icon: <FontAwesomeIcon icon={faPlusCircle} size={`sm`} className={`mr-0`} color={`grey`} />,
                                tooltip: true
                            },
                            {
                                label: "Add",
                                action: RelationForm,
                            },
                        ]}
                        remote
                        rowAction="link"
                        striped={true}
                        bodyClasses="table-filter-body-collapse"
                        wrapperClasses="table-relations f2x-custom-table table-filter-search-wrapper"
                        notLoadedByDefault={false}
                        noDataIndication="No relations found."
                        noHover={false}
                        handleFilterClick={handleFilterClick}
                        collapseFilterAction={collapseFilterAction}
                        filters={filters}
                        enabledFilter={enabledFilter}
                        noSearch={false}
                        cacheSearch={true}
                        filterSearch={true}
                        isLoadData={isLoadData} // isReLoad
                        setIsLoadData={setIsLoadData}
                        collapseFilter={collapseState}
                        isClearFilter={clearFilters}
                        noTopBorder={true}
                        classes="table-responsive table-filter-collapse filters mb-0 relations-table filter-tbl-res"
                        pageHeadingTitle="Relations"
                        defaultSortItem={'customer_number'}
                        defaultSortOrder={'desc'}
                        setSearchKeyword={setSearchKeyword}
                        page={page}
                    />
                </div>
            }
        </>
    )
};

export default withRouter(connect()(Relations));
