import React from "react";
import { GetFiscalYear, UpdateFiscalYear } from '../../../controllers/fiscal_years';
import AccountingPeriods from "./accounting_periods/List";
import DetailsPage from "../../../layouts/DetailsPage";
import Details from "../../../components/Details";
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';

class FiscalYearDetail extends React.Component {
  constructor(props) {
    super(props);

    const tenant_name_title = localStorage.getItem('selected_tenant_title_name');
    const { id, fyid } = this.props.match.params;

    this.state = {
      tabs: [{
        title: 'Details',
        tenant_name: tenant_name_title,
        component: <Details
          getApi={GetFiscalYear}
          icon={`info-circle`}
          updateApi={UpdateFiscalYear}
          id={fyid}
          details={[{
            label: 'Description',
            data: 'description',
            type: 'text',
            style: 'text_style'
          }, {
            label: 'Date from',
            data: 'date_from',
            maxDate: 'date_to',
            type: 'datepicker',
          }, {
            label: 'Date to',
            data: 'date_to',
            minDate: 'date_from',
            type: 'datepicker'
          },{
            label: 'Closed',
            data: 'is_closed',
            type: 'checkbox'
          }]} />
      }, {
        title: 'Accounting periods',
        tenant_name: tenant_name_title,
        component: <AccountingPeriods
                    icon={`calendar`}
                    tenant_id={id}
                    fiscal_year_id={fyid} />
      }]
    }
  }

  render() {
    const { tabs } = this.state;

    return (
      <React.Fragment>
        { tabs &&
          <DetailsPage
            name="Fiscal year"
            tabs={tabs}
          />
        }
      </React.Fragment>
    )
  }
}

export default connect()(withTranslation()(withRouter(FiscalYearDetail)));
