import { faFilter, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JsonPrettify from "json-stringify-pretty-compact";
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import {
  customFilter
} from "react-bootstrap-table2-filter";
import { useTranslation } from 'react-i18next';
import JSONPretty from 'react-json-pretty';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Badge } from "reactstrap";
import XMLPrettify from "xml-formatter";
import CustomFilterDate from "../../../components/filters/CustomFilterDate";
import CustomFilterInput from "../../../components/filters/CustomFilterInput";
import CustomFilterSelect from "../../../components/filters/CustomFilterSelect";
import ParentTable from '../../../components/ParentTable';
import { GetLogActivities } from '../../../controllers/activity_logs';
import CONSTANTS from "../../../services/constants";

const JsonOptions = { indent: 4 },
  XmlOptions = {
    indentation: '   ',
    stripComments: true,
    collapseContent: true,
    lineSeparator: '\n'
  };

const ActivityLogs = ({ ...props }) => {
  //console.log("ActivityLogs - default list - props ->", props);
  //console.log("ActivityLogs - default list - props.filters ->", props.filters);

  let history = useHistory();

  const location = props.location;
  const tableName = "ActivityLogsList";
  const { t } = useTranslation();

  let [dataCustomFilter, setDataCustomFilter] = useState(null);
  let [messageFilter, setMessageFilter] = useState(null);
  let [usernameFilter, setUsernameFilter] = useState(null);
  let [severityFilter, setSeverityFilter] = useState(null);
  let [datesFilter, setDatesFilter] = useState(null);

  const [toggleFilterForm, setToggleFilterForm] = useState(false);
  const [collapseState, setCollapseState] = useState("is-hidden");

  /* start filters - state variables */
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [json_data, setJson_data] = useState("");
  const [username, setUsername] = useState("");
  const [hp_timestamp, setHp_timestamp] = useState("");
  const [severity, setSeverity] = useState([]); // if multiSelect column -> set to [] as initialState
  const [clearFilters, setClearFilters] = useState(false);
  
  const clearedItemName = useRef("");
  const [comparatorFilter, setComparatorFilter] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [sort, setSort] = useState("-hp_timestamp"); // default sort on date -> hp_timestamp
  const [pageNum, setPageNum] = useState(1);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(100);

  const [paginationCount, setPaginationCount] = useState(1);
  const [enabledFilter, setEnabledFilter] = useState(props.enabledFilter ? props.enabledFilter : false);

  let [filtersFilter, setFiltersFilter] = useState({});
  let [filters, setFilters] = useState({ filter: null, keyword: "" });

  const [params, setParams] = useState([]);
  const [isLoadData, setIsLoadData] = useState(null);

  const handleFiltering = (e, name, multiSelectable, comparator = null) => {
    let filterStoredItem;
    let historyFilter = false;
    // custom comparator
    if (comparator) {
      setComparatorFilter(comparator);
    }

    if (!enabledFilter) {
      let filtersObj = sessionStorage.getItem("qStrActivityLogsListFiltersObj");
      if (filtersObj) {
        let parsedFiltersObj = JSON.parse(filtersObj);
        if (parsedFiltersObj && parsedFiltersObj.filter) {
          historyFilter = true;
          // setCollapseState("is-visible");
          setToggleFilterForm(true);

          if (parsedFiltersObj.filter) {
            filtersFilter = parsedFiltersObj.filter;
            let searchedKeyword = sessionStorage.getItem("qStrActivityLogsListKeyword");
            if (searchedKeyword) {
              parsedFiltersObj.keyword = searchedKeyword;
            }
            setFiltersFilter(filtersFilter);
          }
          filters = parsedFiltersObj;
          setFilters(filters);
        }
      }
    }

    if (
      (name && e && (e.value !== "" || e.value === "")) ||
      (name && e === null) ||
      (e && e.key && e.key === "Enter") ||
      (e && multiSelectable && (e.length > 0 || e.length === null || e.length === 0)) ||
      historyFilter
    ) {
      //console.log("ActivityLogs - handleFiltering - e ->", e);

      let value = "";
      switch (name) {
        case "message":
          let message = e && e.target && e.target.value ? e.target.value : !enabledFilter && filtersFilter && filtersFilter["message"] ? filtersFilter["message"] : "";
          if (message === "") {
            message = "";
          }
          value = message;
          setMessage(value);
          break;
        case "hp_timestamp":
          if (e === "" || e === null) {
            value = !enabledFilter && filtersFilter && filtersFilter["hp_timestamp"] ? filtersFilter["hp_timestamp"] : "";
          } else {
            value = ReverseHighPrecisionTimestampFormatter(
              new Date(e)
            ).toString();
          }
          setHp_timestamp(value);
          break;
        case "json_data":
          let json_data = e && e.target && e.target.value ? e.target.value : !enabledFilter && filtersFilter && filtersFilter["json_data"] ? filtersFilter["json_data"] : "";
          if (json_data === "") {
            json_data = "";
          }
          value = json_data;
          setJson_data(value);
          break;
        case "severity":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["severity"] ? filtersFilter["severity"] : null;
            //console.log("ActivityLogs - handleFiltering - CustomFilterSelect - invoice_status_label - value ->", value);
            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setSeverity(selectedValues);
            } else {
              setSeverity(filtersFilter && filtersFilter["severity"] ? filtersFilter["severity"] : null);
            }
          } else {
            value = e && e.value ? e.value : "";
            if (value === "") {
              value = null;
            }
            setSeverity(value);
          }
          break;
        case "username":
          let username = e && e.target && e.target.value ? e.target.value : !enabledFilter && filtersFilter && filtersFilter["username"] ? filtersFilter["username"] : "";
          if (username === "") {
            username = "";
          }
          value = username;
          setUsername(value);
          break;
        default:
          break;
      }

      if (filtersFilter) {
        if (filtersFilter[name] && (!value || value === "" || value.length === 0)) {
          delete filtersFilter[name];
        } else {
          if (value) {
            filtersFilter[name] = value;
          }
        }

        //console.log("ActivityLogs - handleFiltering - filtersFilter (before setting new filtersFilter) ->", filtersFilter);

        if (Object.entries(filtersFilter).length > 0) {
          setFiltersFilter(filtersFilter);
          filters.filter = filtersFilter;
          setFilters(filters);
          setQueryParameters();
          setIsLoadData(true);
        } else {
          filters.filter = filtersFilter;
          setFilters(filters);
          setQueryParameters();
          setIsLoadData(true);
        }
      }
    }
  };

  const HighPrecisionTimestampFormatter = (cell, row) => {
    return cell ? (
      <span id={row.id} data-cell={cell}>
        {/*moment.unix(cell / 1000).format("DD-MM-YYYY HH:mm:ss.SSS")*/}
        {moment.unix(cell / 1000).format("DD-MM-YYYY HH:mm:ss")}
      </span>
    ) : (
      <span className="null-value">{String.fromCharCode(8212)}</span>
    );
  };

  const ReverseHighPrecisionTimestampFormatter = (date) => {
    //console.log("ActivityLogs - setQueryParameters - ReverseHighPrecisionTimestampFormatter - date ->", date);
    if (date) {
      let ts = moment(date, "DD-MM-YYYY HH:mm:ss").valueOf();
      return ts;
    }
    return null;
  };

  const removeXML = (json) => {
    const xmlData = json.Xml ? json.Xml : null;
    if (xmlData) {
      delete json.Xml;
    }
    return (
      <>
        <div>{JsonPrettify(json, JsonOptions)}</div>
        {xmlData && <div>{XMLPrettify(xmlData, XmlOptions)}</div>}
      </>
    );
  };

  const SeverityStyle = (cell) => {
    return <Badge className={`badge-pill severity ${cell}`}>{cell}</Badge>
  };

  const breakStyle = (cell, row, rowIndex, formatExtraData) => {
    if (cell && row && row.ip) {
      const ip = row.ip;
      return <span className="username-ip-format">
        <span className="activity-logs-username">{cell}</span>
        <span className="activity-logs-ip">{ip}</span>
      </span>;
    } else {
      return <div className="username-ip-format">{cell}</div>;
    }
  };

  const messageStyle = (cell, row) => {
    if (cell && row && row.method) {
      const method = row.method;
      return <span className="message-format">
        <span className="activity-logs-message">{cell}</span>
        <span className="activity-logs-method"><b>{method}</b></span>
      </span>;
    } else {
      return <div className="username-ip-format">{cell}</div>;
    }
  };

  const Prettify = (cell, row, rowIndex, formatExtraData) => {
    if (cell) {
      const xml = Object.keys(cell).filter(data => data === 'Xml');
      return (<React.Fragment>
        {cell && <React.Fragment>
          {cell.Xml && xml.length > 0 &&
            <div>{validateJsonToJsonObj(cell.Xml)}</div>}
          {removeXML(cell)}
        </React.Fragment>}
      </React.Fragment>)
    }
  };

  const detailsExpand = {
    'renderer': (row) => {
      if (row) {
        return (<ReactJson
          src={row}
          enableClipboard={false}
          style={{ fontFamily: "inherit", backgroundColor: "transparent", textAlign: "left" }}
        />)
      }
      return '';
    }
  };

  const validateJsonToJsonObj = (jsonStr) => {
    try {
      JSON.parse(jsonStr);
    } catch (e) {
      return false;
    }
    return <JSONPretty id="json-pretty" data={JSON.parse(jsonStr)}> </JSONPretty>
  };

  /* Filter */
  const handleFilterClick = (e) => {
    if (e) {
      console.log("ClearBtn - handleFilterClick - e ->", e);
      e.preventDefault();
    }

    sessionStorage.removeItem("qStrActivityLogsList");
    sessionStorage.removeItem("qStrActivityLogsListFilter");
    sessionStorage.removeItem("qStrActivityLogsListFilterStr");
    sessionStorage.removeItem("qStrActivityLogsListKeyword");
    sessionStorage.removeItem("qStrActivityLogsListFiltersObj");

    setDataCustomFilter([]);
    setMessageFilter([]);
    setUsernameFilter([]);
    setSeverityFilter([]);
    setDatesFilter([]);

    setMessage("");
    setUsername("");
    setHp_timestamp("");
    setSeverity("");

    setPage(1);
    setSizePerPage(100);
    setFiltersFilter({});
    setFilters({ filter: null, keyword: "" });
    setClearFilters(true);
    setPaginationCount(1);
    setEnabledFilter(false);
    setIsLoadData(true);

    setQueryParameters();
  };


  const processMultiValues = (multiValueArr) => {
    let multiValues = [];
    for (let i = 0; i < multiValueArr.length; i++) {
      if (multiValueArr[i] && multiValueArr[i].value) {
        multiValues.push(multiValueArr[i].value);
      }
    }
    return multiValues.join(",");
  };

  const processKey = (key, value, queryFilter, comparatorItems) => {
    switch (key) {
      case "severity":
        let multiKeyFilters = processMultiValues(value[key]);
        queryFilter.push(`${key}=[${multiKeyFilters}]`);
        break;
      case "hp_timestamp":
        //queryFilter.push(`${key}>=${value[key][0]},${key}<=${value[key][1]}`);
        queryFilter.push(`${key}>${value[key]}`);
        break;
      default:
        queryFilter.push(`${key}%${value[key]}`);
        break;
    }
  }


  const setQueryParameters = () => {
    // get history (default) params
    let params = {};
    const historyParamsQueryString = sessionStorage.getItem("qStrActivityLogsList");
    if (historyParamsQueryString) {
      // page - offset - count - sort - search
      historyParamsQueryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
        if (key === 'page' || key === 'offset' || key === 'count') {
          params[key] = parseInt(value);
        } else if (key === 'sort') {
          params[key] = value;
        } else if (key === 'search') {
          params[key] = value;
        }
      });
    }

    let key;
    let value;
    let query = [];
    let searchFilterQuery = {};
    let filterQuery = {};
    let searchFilterStr = "";
    let comparatorItems = {};

    query.push(`count=1`);
    if (!filters) {
      query.push(`search=`);
      query.push(`filter=`);
    }
    query.push(`offset=${params && params.offset ? params.offset : sizePerPage ? sizePerPage : 10}`);
    query.push(`page=${params && params.page ? params.page : page ? page : 1}`);
    query.push(`sort=${params && params.sort ? params.sort : sort ? sort : ""}`);

    // remove filters from session storage
    sessionStorage.removeItem("qStrActivityLogsList");
    sessionStorage.removeItem("qStrActivityLogsListFilter");
    sessionStorage.removeItem("qStrActivityLogsListFilterStr");
    sessionStorage.removeItem("qStrActivityLogsListFiltersObj");
    sessionStorage.removeItem("qStrActivityLogsListComparatorItems");
    sessionStorage.removeItem("qStrActivityLogsListKeyword");

    if (filters) {
      for (key in filters) {
        if (filters.hasOwnProperty(key)) {

          if (key === "keyword") {
            value = filters[key];
            searchFilterQuery[key] = value && true ? value : "";
            // set search for querystring
            query.push(`search=${filters[key]}`);

          } else if (key === "filter") {
            value = filters[key];

            // set total filter
            let queryKeyFilters = "";
            let queryFilters = "";

            // if value is array - value.length > 0 - there are filters
            if (value && Array.isArray(value)) {
              if (value.length > 0) {
                let queryFilter = [];
                for (let i = 0; i < value.length; i++) {
                  for (let key in value[i]) {
                    if (value[i].hasOwnProperty(key)) {
                      processKey(key, value[i], queryFilter, comparatorItems);
                    }
                  }
                }
                if (queryFilter.length > 0) {
                  queryKeyFilters = queryFilter.join(",");
                  queryFilters = queryFilter.join("~");
                }
                searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
                filterQuery['filter'] = queryFilters ? queryFilters : "";
              }
            } else {
              let queryFilter = [];
              for (let name in value) {
                if (value.hasOwnProperty(name)) {
                  processKey(name, value, queryFilter, comparatorItems);
                }
              }
              if (queryFilter.length > 0) {
                queryKeyFilters = queryFilter.join(",");
                queryFilters = queryFilter.join("~");
              }
              searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
              filterQuery['filter'] = queryFilters ? queryFilters : "";
            }
            query.push(`filter=${queryKeyFilters}`);
          }
        }
      }
      query.join("&").toString();
      setEnabledFilter(true);

    } else {
      query.join("&").toString();
    }

    const location_search_query = query;
    //console.log("ActivityLogs - setQueryParameters - handleFiltering - location_search_query (=== query) ->", location_search_query);
    let location_search = location_search_query ? location_search_query.join("&") : "";

    sessionStorage.setItem("qStrActivityLogsList", location_search);
    sessionStorage.setItem("qStrActivityLogsListFilter", searchFilterQuery['filter'] && searchFilterQuery['filter'] !== "" ? searchFilterQuery['filter'] : "");
    sessionStorage.setItem("qStrActivityLogsListFilterStr", filterQuery['filter'] && filterQuery['filter'] !== "" ? filterQuery['filter'] : "");
    sessionStorage.setItem("qStrActivityLogsListKeyword", searchFilterQuery['keyword'] ? searchFilterQuery['keyword'] : searchKeyword);
    sessionStorage.setItem("qStrActivityLogsListFiltersObj", JSON.stringify(filters));
  };

  const collapseFilterAction = (e) => {
    if (e) {
      e.preventDefault();
    }

    let filterVisible = sessionStorage.getItem("activityLogsFilterVisible");
    // console.log(`ActivityLogsList - filterVisble -> ${filterVisible}`)

    let collapseStatus = toggleFilterForm === true ? "is-hidden" : "is-visible";
    setCollapseState(collapseStatus);
    setToggleFilterForm(!toggleFilterForm);

    sessionStorage.setItem("activityLogsFilterVisible", collapseStatus);
  };

  useEffect(() => {
    if (searchKeyword && searchKeyword !== "") {
      filters.filter = filtersFilter;
      filters.keyword = searchKeyword;
      setFilters(filters);

      sessionStorage.setItem("qStrInvoicesKeyword", searchKeyword);

      setPage(1);
      setPageNum(1);
      setQueryParameters();
      setIsLoadData(true);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (clearFilters === true) {
      setPaginationCount(1);
      setClearFilters(false);
    }
  }, [clearFilters]);

  useEffect(() => {
    //console.log("table filter list - (updated - clearedItemName) - clearedItemName ->", clearedItemName);

    let tableStorageFilterObjId = "qStr" + tableName + "FiltersObj";

    if (clearedItemName && clearedItemName !== "") {
      let name = clearedItemName;
      let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
      //console.log("table filter list - (updated - clearedItemName) - filtersObj ->", filtersObj);

      if (name && filtersObj) {
        let filtersObjParsed = JSON.parse(filtersObj);
        if (filtersObjParsed && filtersObjParsed.filter &&
          filtersObjParsed.filter.length > 0) {

          //console.log("ActivityLogs - clearedItemName - filtersObjParsed has values - check(name) - name ->", name);

          for (let i = 0; i < filtersObjParsed.filter.length; i++) {
            if (filtersObjParsed.filter[i] && filtersObjParsed.filter[i][name]) {
              filtersObjParsed.filter.splice(i, 1);

              // save filters to session storage (stringify)
              sessionStorage.setItem(tableStorageFilterObjId, JSON.stringify(filtersObjParsed));

              if (filtersObjParsed.filter &&
                filtersObjParsed.filter.length > 0) {
                setFiltersFilter(filtersObjParsed.filter);
              } else {
                setFiltersFilter({});
              }
              setFilters(filtersObjParsed);
              return;
            }
          }
        }
      }
    } else {
      setFiltersFilter({});
      setFilters({ filter: null, keyword: "" });
      sessionStorage.removeItem(tableStorageFilterObjId);
      setQueryParameters();
    }
  }, [clearedItemName]);

  useEffect(() => {
    let filterVisible = sessionStorage.getItem("activityLogsFilterVisible");
    if (filterVisible === "is-visible") {
      setToggleFilterForm(true);
      setCollapseState("is-visible");

      sessionStorage.setItem("activityLogsFilterVisible", "is-visible");

    } else if (filterVisible === "is-hidden") {
      setToggleFilterForm(false);
      setCollapseState("is-hidden");

      sessionStorage.setItem("activityLogsFilterVisible", "is-hidden");

    } else {
      setToggleFilterForm(false);
      setCollapseState("is-hidden");
      setFilters({ filter: null, keyword: "" });

      sessionStorage.setItem("activityLogsFilterVisible", "is-hidden");
    }
  }, []);

  return (
    <ParentTable
      table="ActivityLogsList"
      id="activity_logs_list"
      classes="finance-table table-activity-logs table-activity-logs-filter react-bootstrap-table table-filter-collapse filters mb-0 activity-logs-table filter-tbl-res"
      keyField="id"
      remote
      data={GetLogActivities}
      columns={[
        {
          dataField: "hp_timestamp",
          filter: customFilter({
            type: "date",
            style: { display: "inline-grid" },
            defaultValue: { date: new Date() }, //default value
            getFilter: (filter) => {
              setDatesFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterDate
              onFilter={onFilter}
              column={column}
              handleFiltering={handleFiltering}
              isClearFilter={clearFilters}
              icon={false}
              value={hp_timestamp ? hp_timestamp : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              setClearedItemName={(value) => clearedItemName.current = value}
              table="ActivityLogsList"
            />
          ),
          formatter: HighPrecisionTimestampFormatter,
          text: "Timestamp",
          align: "left",
          headerStyle: () => {
            return { width: "15%" };
          },
          attrs: {
            "data-label": "Timestamp",
          },
          title: true,
          sort: true,
          headerSortingClasses: (sort, opts) => {
            return "sorted-column";
          },
        },
        {
          dataField: "message",
          text: "Message",
          classes: "last-cell activity-logs-message",
          filter: customFilter({
            type: "text",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setMessageFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterInput
              onFilter={onFilter}
              column={column}
              handleFiltering={handleFiltering}
              isClearFilter={clearFilters}
              value={message ? message : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              setClearedItemName={(value) => clearedItemName.current = value}
              table="ActivityLogsList"
            />
          ),
          headerStyle: () => {
            return { width: "15%" };
          },
          attrs: {
            "data-label": "Message",
          },
          title: true,
          sort: true,
          headerSortingClasses: (sort, opts) => {
            console.log(
              "headerSortingClasses - sort - opts ->",
              sort,
              opts
            );
            return "sorted-column";
          },
        },
        {
          dataField: "json_data",
          text: "Data",
          classes: "f2x-details-link",
          filter: customFilter({
            type: "text",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setDataCustomFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterInput
              onFilter={onFilter}
              column={column}
              handleFiltering={handleFiltering}
              isClearFilter={clearFilters}
              value={json_data ? json_data : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              setClearedItemName={(value) => clearedItemName.current = value}
              table="ActivityLogsList"
            />
          ),
          formatter: Prettify,
          headerStyle: () => {
            return { width: "25%" };
          },
          attrs: {
            "data-label": "Data",
          },
          title: true,
          sort: true,
          headerSortingClasses: (sort, opts) => {
            return "sorted-column";
          },
        },
        {
          dataField: "severity",
          text: "Severity",
          filter: customFilter({
            type: "select",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setSeverityFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterSelect
              onFilter={onFilter}
              column={column}
              handleFiltering={(e) =>
                handleFiltering(e, "severity", true)
              }
              constantObj={CONSTANTS.SUBSCRIPTION_LOG_SEVERITY}
              multiSelect={true}
              clearOnEmpty={handleFilterClick}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              value={severity ? severity : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table="ActivityLogsList"
            />
          ),
          formatter: SeverityStyle,
          headerStyle: () => {
            return { width: "20%", minWidth: "200px" };
          },
          attrs: {
            "data-label": "Severity",
          },
          align: "center",
          title: true,
          sort: true,
          headerSortingClasses: (sort, opts) => {
            return "sorted-column";
          },
        },
        {
          dataField: "username",
          text: "Username / IP",
          filter: customFilter({
            type: "text",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setUsernameFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterInput
              onFilter={onFilter}
              column={column}
              handleFiltering={handleFiltering}
              setClearedItemName={(value) => clearedItemName.current = value}
              isClearFilter={clearFilters}
              value={username ? username : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table="ActivityLogsList"
            />
          ),
          formatter: breakStyle,
          sort: false,
          classes: "last-cell activity-logs-username-ip",
          headerStyle: () => {
            return {
              width: "15%",
              minWidth: "100px",
              textAlign: "right",
            };
          },
          attrs: {
            "data-label": "Username / IP",
          },
        },
      ]}
      headerDropdownOpts={[
        {
          label: "Filter",
          action: "collapseFilterAction",
          icon: <FontAwesomeIcon icon={faFilter} size={`sm`} className={`mr-0`} color={`grey`} />,
          isCustom: true,
          tooltip: true,
          toggleActive: toggleFilterForm ? "active" : ""
        },
        {
          label: "Clear",
          action: "handleFilterClick",
          icon: <FontAwesomeIcon icon={faTrash} size={`sm`} className={`mr-0`} color={`grey`} />,
          isCustom: true,
          tooltip: true
        },
      ]}
      wrapperClasses="table-activity-logs table-filter-search-wrapper f2x-custom-table"
      striped={true}
      rowAction=""
      notLoadedByDefault={false}
      noHover={false}
      handleFilterClick={handleFilterClick}
      collapseFilterAction={collapseFilterAction}
      noTopBorder={true}
      bodyClasses="table-filter-body-collapse"
      expandRow={detailsExpand}
      filters={filters}
      enabledFilter={enabledFilter}
      isLoadData={isLoadData}
      setIsLoadData={setIsLoadData}
      noSearch={false}
      cacheSearch={true}
      filterSearch={true}
      collapseFilter={collapseState}
      setSearchKeyword={setSearchKeyword}
      page={page}
    />)
};

export default withRouter(connect()(ActivityLogs));
