import React, {useState, useEffect} from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {connect} from "react-redux";
import classnames from 'classnames';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  withRouter
} from 'react-router-dom';

const TabHeader = ({className, tabs, activatedTab, toggleTab, customNavItem, customClassName, ...props}) => {
  const icons = [];
  icons['Details'] = 'question-circle';
  icons['Addresses'] = 'building';
  icons['Persons'] = 'portrait';
  icons['Emails'] = 'envelope';
  icons['Bank accounts'] = 'calculator';
  icons['Subscriptions'] = 'briefcase';
  icons['Invoices'] = 'receipt';

  const [relationTabSettings, setRelationTabSettings] = useState(1); // relationTab settings => value: 1 = label: "Show tab icon & text" | value: 2 = label: "Show tab icon only" | value: 3 = label: "Show tab text only"

  const buttonNavMvLeft = () => {
    document.getElementById('f2x-mv-container').scrollLeft -= 100;
  };

  const buttonNavMvRight = () => {
    document.getElementById('f2x-mv-container').scrollLeft += 100;
  };

  useEffect(() => {
    const { user } = props;
    if (user) {
      const { json_data } = user;
      console.log("useEffect - TabHeader - props.user - json_data ->", json_data);
      if (json_data && json_data !== "") {
        let json_data_user = JSON.parse(json_data);
        console.log("useEffect - TabHeader - props.user - json_data_user ->", json_data_user);
        if (json_data_user && json_data_user.relation_tabs) {
          setRelationTabSettings(json_data_user.relation_tabs);
        }
      }
    }
  }, []);

  return (
      <>
        <div className={`mv-tabs-navigation`}>
          {customNavItem &&
          <div key="custom_nav" className="f2x-mv-tabs-list-item list-item-right ml-auto horizontal-tabs title-logo">
            <div className="custom-mv-nav-item" key="custom">
              {customNavItem}
            </div>
          </div>
          }
          <div className={`horizontal-tabs f2x-mv-nav-tab-left-btn d-none`}>
            <button id="slideLeft" type="button"
                    onClick={(e) => buttonNavMvLeft()}
            >
              <FontAwesomeIcon className="f2x-header-icon" icon="caret-left" size="lg" />
              <span>Slide left</span>
            </button>
          </div>
          <Nav tabs className={`horizontal-tabs scroll ${className}`} id={`f2x-mv-container`}>
            { tabs.map(({title, name}, i) => {
              return (
                <NavItem 
                  key={i} 
                  className="f2x-mv-tabs-list-item list-item-left"
                  style={relationTabSettings === 1 || relationTabSettings === 3 ? { width: "unset" } : {}}
                >
                  {!relationTabSettings || relationTabSettings === 1 &&
                  <NavLink
                    id={`tab-item-${name}`}
                    className={ classnames({ active: activatedTab === i }) }
                    onClick={ (e) => toggleTab(e, i) }
                    style={{padding: ".5rem 1rem", lineHeight: "22px"}}
                  >
                    <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={icons[title]} />
                    <span className="f2x-mv-tabs-list-item-text" style={{display: "inline", marginLeft: "4px"}}>{title}</span>
                  </NavLink>
                  }

                  {relationTabSettings === 2 &&
                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom-${title.toLowerCase()}`}>
                      <div className={`popover-html-contents`}>
                        <span>{title}</span>
                      </div>
                    </Tooltip>}
                  >
                    <NavLink
                      id={`tab-item-${name}`}
                      className={ classnames({ active: activatedTab === i }) }
                      onClick={ (e) => toggleTab(e, i) }
                    >
                      <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={icons[title]} />
                      <span className="f2x-mv-tabs-list-item-text" style={{display: "none"}}>{title}</span>
                    </NavLink>
                  </OverlayTrigger>
                  }

                  {relationTabSettings === 3 &&
                  <NavLink
                    id={`tab-item-${name}`}
                    className={ classnames({ active: activatedTab === i }) }
                    onClick={ (e) => toggleTab(e, i) }
                    style={{padding: ".5rem 1rem", lineHeight: "22px"}}
                  >
                    <span className="f2x-mv-tabs-list-item-text" style={{display: "inline", marginLeft: "0px"}}>{title}</span>
                  </NavLink>
                  }
                </NavItem>
              )
            })}
          </Nav>
          <div className={`horizontal-tabs f2x-mv-nav-tab-right-btn d-none`}>
            <button id="slideRight" type="button"
                    onClick={(e) => buttonNavMvRight()}
            >
              <span>Slide right</span>
              <FontAwesomeIcon className="f2x-header-icon" icon="caret-right" size="lg" />
            </button>
          </div>
        </div>
      </>
  )
};

//export default withRouter(TabHeader);
export default connect(store => ({
  user: store.user
}))(withRouter(TabHeader));
