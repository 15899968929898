//import ApiService from '../services/apiService';

//const Api = new ApiService(),
    //GetAPI = Api.getAPI;

const GetPayments = (id) => {
    return [];
};

export {
    // Payments
    GetPayments
}