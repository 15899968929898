import * as types from "../constants";

export function relation(relation) {
  return {
    type: types.RELATION,
    payload: relation
  };
}

export const relationNotes = (data) => ({
  type: types.NOTES,
  data
});