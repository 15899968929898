import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import {html as htmlBeautify} from 'js-beautify';

class ZoomedHTMLScreen extends React.Component {

  constructor(props) {
    super(props);
    this.handleHtmlChange = this.handleHtmlChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }
  
  handleHtmlChange = (html) => {
    const { onChange, fieldName } = this.props;
    onChange(html, fieldName); // Pass field name along with the new HTML
  }
  
  handleSave = () => {
    const { onSave } = this.props;
    onSave(); // Trigger the onSave function passed from the parent component
  }

  //componentDidMount() {
  //  console.log("ZoomedHTMLScreen - this.props ->", this.props);
  //}

  render() {
    const { isOpen, toggle, zoomedHtml } = this.props;   
    return (
      <Modal isOpen={isOpen} toggle={toggle} centered className="modal-xl fullscreen">
        <ModalHeader>Edit HTML</ModalHeader>
        <ModalBody style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
          <div className="code-mirror-container">
            <CodeMirror
                // value={ zoomedHtml ? zoomedHtml : '' }
                value={zoomedHtml ? htmlBeautify(zoomedHtml, { indent_size: 2, space_in_empty_paren: true }) : ''}
                name="zoomedHtml"
                extensions={[html({ jsx: false })]}                        
                onChange={this.handleHtmlChange}
            />
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button className="btn btn-danger" onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button color="primary" onClick={this.handleSave}>
            <FontAwesomeIcon icon={faSave} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ZoomedHTMLScreen;
