import React from "react";
import {connect} from "react-redux";
import Select from "react-select";
import DatePicker, {registerLocale} from 'react-datepicker';
import {Link, withRouter} from 'react-router-dom';
import {Button, Modal, Alert, ModalBody, ModalFooter, ModalHeader, Form, Input, CustomInput, FormGroup, Col, Row, Label} from "reactstrap";
import { 
    GetPersonDependencies, 
    AddPerson, 
    UpdatePerson, 
    PersonCheckEmail, 
    LinkPerson, 
    AddLanguage,
    UpdateLanguage,
    GetOptionLanguage,
    GetOptionLanguages,
    GetCountries 
} from "../../../controllers/relations";
import { 
    GetPersonTypes
} from "../../../controllers/persons";
import {loaderToggle} from '../../../redux/actions/loaderActions';
import {validateDate} from "../../../redux/actions/validateActions";
import Toast from "../../../components/Toast";
import {withTranslation} from 'react-i18next';
import {nl, enGB, enUS} from "date-fns/locale";
import {parseDateToSaveFormat} from '../../../redux/actions/formatActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

let checkEmail;

const statusOpts = [
    { value: "1", label: "Pending" },
    { value: "2", label: "New" },
    { value: "3", label: "Active" },
    { value: "4", label: "Suspended" },
    { value: "5", label: "Deleted" }
], genderOpts = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" }
], genderOptsAlt = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" }
], languageOpts = [
    { value: "nl", label: "Dutch NL (nl)" },
    { value: "en-US", label: "English US (en-US)" },
    { value: "en-GB", label: "English UK (en-GB)" }
];

let titleOpts = {
    'All': [
        {value: "Dhr", label: "Dhr"},
        {value: "Mevr", label: "Mevr"},
        {value: "Dhr/Mevr", label: "Dhr/Mevr"},
        {value: "Mr.", label: "Mr."},
        {value: "Mrs.", label: "Mrs."},
        {value: "Ms.", label: "Ms."},
        {value: "Mr./Mrs./Ms.", label: "Mr./Mrs./Ms."}
    ],
    'Male': [
        {value: "Dhr", label: "Dhr"},
        {value: "Mr.", label: "Mr."}
    ],
    'M': [
        {value: "Dhr", label: "Dhr"},
        {value: "Mr.", label: "Mr."}
    ],
    'Female': [
        {value: "Mevr", label: "Mevr"},
        {value: "Mrs.", label: "Mrs."},
        {value: "Ms.", label: "Ms."}
    ],
    'F': [
        {value: "Mevr", label: "Mevr"},
        {value: "Mrs.", label: "Mrs."},
        {value: "Ms.", label: "Ms."}
    ],
    'Other': [
        {value: "Dhr/Mevr", label: "Dhr/Mevr"},
        {value: "Mr./Mrs./Ms.", label: "Mr./Mrs./Ms."}
    ],
    'O': [
        {value: "Dhr/Mevr", label: "Dhr/Mevr"},
        {value: "Mr./Mrs./Ms.", label: "Mr./Mrs./Ms."}
    ]
};

class PersonForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formType: props.selectedData ? 'Edit' : 'Add',
            formName: 'person',
            person: props.selectedData ? props.selectedData : {},
            defaultPrimary: !!(props.selectedData && props.selectedData.primary),
            disableAll: true,
            isValidEmail: false,
            titleOpts: {
                'All': titleOpts['All'],
                'Male': titleOpts['Male'],
                'Female': titleOpts['Female'],
                'Other': titleOpts['Other']
            },
            checkedItems: new Map()
        };

        this.toggle = this.toggle.bind(this);
        this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
        this.handlePrimaryCheckChange = this.handlePrimaryCheckChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    toggle() {
        this.props.hide();
    }

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    handleSelectChange(name, data) {
        let person = Object.assign({}, this.state.person);

        if ('name' === 'gender') {
            this.setState({ keyOpt: data.label });
            this.setState({ titleOpts: titleOpts[data.label] });
            this.setState({ title: null });
        }

        person[name] = data.value;

        this.setState({
            person, [name]: data
        })
    }

    handleDatePickerChange(date) {
        let person = Object.assign({}, this.state.person);
        const { newDate } = this.props.dispatch(validateDate(date));

        person.birthdate = newDate;

        this.setState({ person })
    }

    handleInputChange({ target: { name, value } }) {
        let person = Object.assign({}, this.state.person);

        person[name] = value.replace(/^\s+|\s+$|\s+(?=\s)/g, " ");

        this.setState({ person });

        if (name === 'email') {
            this.setState({
                multiplePersonDatas: null,
                updatePersonEmail: null,
                disableAll: true
            })
        }
    }

    handleEmailInputChange = ({ target: { name, value } }) => {
        const person = Object.assign({}, this.state.person),
              emailIsValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              result = emailIsValid.test(value);

        person.email = value;
        this.setState({ person });

        /*if (checkEmail) {
            clearTimeout(checkEmail)
        }

        checkEmail = setTimeout(() => {
            if (!value) {
                Toast.fire({
                    title: 'Email address field is required!',
                     icon: 'warning'
                }).then(r => console.log(r));
                this.setState({ disableAll: true })
            } else if (result === false) {
                Toast.fire({
                    title: 'Please enter a valid email address!',
                     icon: 'warning'
                }).then(r => console.log(r));
                this.setState({ disableAll: true })
            } else if (value !== this.state.initEmail) {
                this.checkEmail(value)
            } else if (value && this.props.selectedData) {
                this.setState({ disableAll: false })
            }
        }, 1000)*/
    };

    isValidEmail = (email) => {
        // Regular expression for validating email format
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(email);
    }

    handlePrimaryCheckChange(e) {
        const name = e.target.name;
        const checked = e.target.checked;
        const person = Object.assign({}, this.state.person);

        person[name] = checked && checked !== '' ? checked : !person[name] ? !person[name] : false;
        this.setState({ person });
    }

    handleCheckChange({ target: { name, checked, value } }) {
        let person = Object.assign({}, this.state.person);

        person[name] = checked;
        this.setState({ person })
    }

    linkToPerson(inx, e) {
        e.preventDefault();

        (async () => {
            const { multiplePersonDatas } = this.state,
                  { hide, update } = this.props;
            let personData = multiplePersonDatas[inx];

            personData.status = 1;

            const { ok } = await LinkPerson(personData);

            if (ok) {
                await Toast.fire(
                    {title: 'Person linked successfully!', icon: 'success'});
                hide();
                update();

                this.setState({
                    disableAll: true,
                    multiplePersonDatas: null
                })
            }
        })()
    }

    removeConfirmation() {
        this.setState({
            multiplePersonDatas: null,
            updatePersonEmail: null,
            disableAll: false
        })
    }

    checkEmail(email) {
        const { id, selectedData } = this.props;

        let params = {
            email,
            relation_id: id
        };

        (async () => {
            const { ok, data } = await PersonCheckEmail(params);
            if (ok) {
                const datas = data.data;

                if (datas) {
                    let multiplePersonDatas = [],
                        updatePersonEmail = [];

                    datas.map(({ relation_id, full_name, person_id, status }) => {
                        updatePersonEmail.push({
                            relation_id
                        });

                        return multiplePersonDatas.push({
                            full_name,
                            person_id,
                            status,
                            relation_id: id
                        })
                    });

                    if (datas.length > 0 && !selectedData) {
                        this.setState({
                            multiplePersonDatas,
                            disableAll: true
                        })
                    } else if (datas.length > 0 && selectedData) {
                        this.setState({
                            updatePersonEmail,
                            disableAll: true
                        })
                    } else {
                        this.setState({ disableAll: false })
                    }
                }

            } else {
                this.setState({ disableAll: true })
            }

        })();
    }

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false);
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        const { selectedData, id, dispatch, loader } = this.props,
              { person_type_id, status, person, title, gender, language_id } = this.state;
        const { first_name, last_name, birthdate, language, primary } = person;

        console.log("Persons form - language - person ->", person);
        //console.log("Persons form - gender ->", gender);
        //console.log("Persons form - language_id ->", language_id);
        console.log("Persons form - language ->", language);

        if (!status) {
            return Toast.fire({ title: 'Status field is required!', icon: 'warning' })
        }

        if (!person_type_id) {
            return Toast.fire({ title: 'Type field is required!', icon: 'warning' })
        }

        if (!title) {
            return Toast.fire({ title: 'Title field is required!', icon: 'warning' })
        }

        if (!first_name || (first_name && first_name.trim() === '')) {
            return Toast.fire({ title: 'First name field is required!', icon: 'warning' })
        }

        if (!last_name || (last_name && last_name.trim() === '')) {
            return Toast.fire({ title: 'Last name field is required!', icon: 'warning' })
        }

        if (!birthdate) {
            return Toast.fire({ title: 'Date of birth field is required!', icon: 'warning' })
        }

        if (!language) {
            person.language = 1;
            person.language_id = 1;
            //return Toast.fire({ title: 'Language field is required!', icon: 'warning' })
        } else {
            if (language && language.value) {
                person.language = language.value;
                person.language_id = language.value;
            } else {
                person.language = language;
                person.language_id = language;
            }
        }

        if (!loader) {
            dispatch(loaderToggle(true));
            let person = Object.assign({}, this.state.person);
            person.relation_id = parseInt(id);

            // set date
            person.birthdate = parseDateToSaveFormat(new Date(person.birthdate));

            let primary_selected = person.primary && (person.primary === 1 || person.primary === true) ? true : false;
            person.primary = primary_selected;

            if (person.gender && person.gender === "Male") {
                person.gender = "M";
            } else if (person.gender && person.gender === "Female") {
                person.gender = "F";
            } else if (person.gender && person.gender === "Other") {
                person.gender = "O";
            }

            if (selectedData) {
                (async () => {
                    const { ok } = await UpdatePerson(selectedData.id, person, id);
                    this.handleOk(ok, null, 'updated')
                })();

            } else {
                (async () => {
                    person.relation_id = id;
                    const { ok, data } = await AddPerson(person);
                    dispatch({type: 'RELATION', payload: {persons: data.data}});
                    this.handleOk(ok, data);
                })();

            }
        }
    }

    handleOk = (ok, data, method) => {
        const { hide, update, dispatch } = this.props;
        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update('updateperson');
        }
    };

    componentDidMount() {
        const { dispatch, selectedData, data } = this.props;
        dispatch(loaderToggle(true));

        if (selectedData && selectedData.email) {
            this.setState({
                initEmail: selectedData.email,
                disableAll: false
            });
        }

        (async () => {
            const [languagesOptionsData, personTypesData, countriesData] = await Promise.all([
                GetOptionLanguages(),
                GetPersonTypes(),
                GetCountries()
              ]);

            //console.log("persons form - selectedData ->", selectedData);
            //console.log("persons form - personDependenciesData ->", personDependenciesData);
            //console.log("persons form - language - languagesOptionsData ->", languagesOptionsData);
            //console.log("persons form - personTypesData ->", personTypesData);
            //console.log("persons form - countriesData ->", countriesData);
            //const { ok, data } = await GetPersonDependencies();

            await (() => {
                let languageOptions = [];
                let personTypeOpts = [];

                if (languagesOptionsData) {
                    // set languageOptions
                    const languages = languagesOptionsData.data && languagesOptionsData.data.data ? languagesOptionsData.data.data : [];
                    
                    languages.length > 0 && languages.map(({ id, code, label }) => {
                        return languageOptions.push({
                            label: label + " " + code,
                            value: id
                        })
                    });

                    console.log("persons form - language - languageOptions ->", languageOptions);
                    this.setState({ languageOptions });
                }

                if (personTypesData) {
                    // set personTypeOptions
                    const person_types = personTypesData.data && personTypesData.data.data ? personTypesData.data.data : [];
                    console.log("persons form - person_types ->", person_types);

                    person_types.length > 0 && person_types.map(({ type, id }) => {
                        return personTypeOpts.push({
                            label: type,
                            value: id
                        })
                    });

                    console.log("persons form - person_types - personTypeOpts ->", personTypeOpts);
                    this.setState({ personTypeOpts });
                }

                this.setState({ titleOpts: titleOpts });

                if (selectedData) {
                    console.log("Persons form - selectedData ->", selectedData);

                    let { person_type_id, status, title, gender, language, primary } = selectedData;

                    let person = Object.assign({}, this.state.person);

                    if (person_type_id) {
                        const getVal = personTypeOpts.find(item => parseInt(item.value) === parseInt(person_type_id));
                        this.setState({ person_type_id: getVal ? getVal : null });
                    }

                    if (status) {
                        const getVal = statusOpts.find(item => parseInt(item.value) === parseInt(status));
                        this.setState({ status: getVal ? getVal : null });
                    }

                    if (gender) {
                        let getVal;
                        getVal = genderOpts.find(item => item.value.toLowerCase() === gender.toLowerCase());
                        if (!getVal) {
                            getVal = genderOptsAlt.find(item => item.value.toLowerCase() === gender.toLowerCase());
                        }
                        this.setState({ gender: getVal ? getVal : null });
                    }

                    if (title) {
                        let keyOpt = 'All';
                        if (gender) {
                            const getGenderVal = genderOpts.find(
                                item => item.value.toLowerCase() === gender.toLowerCase());

                            if (getGenderVal) {
                                keyOpt = getGenderVal && getGenderVal.value
                                    ? getGenderVal.value
                                    : 'All';
                            }
                        }
                        this.setState({ keyOpt: keyOpt });

                        const getVal = titleOpts[keyOpt].find(item => item.value.toLowerCase() === title.toLowerCase());
                        this.setState({ title: getVal ? getVal : null });
                    }

                    let getLangVal;
                    if (selectedData.language && selectedData.language.id) {
                        getLangVal = languageOptions.find(item => item.value === selectedData.language.id);
                        this.setState({ 
                            language: getLangVal ? getLangVal : null 
                        });
                        person['language'] = getLangVal;
                        person['language_id'] = getLangVal && getLangVal.value ? getLangVal.value : null;

                    } else {
                        this.setState({ 
                            language: { value: 1, label: "Dutch NL (nl)" } 
                        });
                        person['language'] = getLangVal;
                        person['language_id'] = 1;
                    }

                    if (primary) {
                        if (primary && (primary === 1 || primary === true)) {
                            primary = true;
                        } else {
                            primary = false;
                        }
                        person['primary'] = primary;
                    } else {
                        person['primary'] = false;
                    }

                    console.log("persons form - person(object) - person ->", person);
                    this.setState({ person });
                }
            })();
            dispatch(loaderToggle(false));

        })();
    }

    render() {
        const { loader, show, hide, selectedData, t } = this.props,
              { formType, formName, person, status, personTypeOpts, person_type_id, disableAll, multiplePersonDatas, updatePersonEmail, /*keyOpt,*/ gender, title, language, languageOptions, titleOpts, defaultPrimary } = this.state,
              { first_name, middle_name, last_name, birthdate, email, phone, mobile, primary } = person;

        return (
            <Modal isOpen={show} toggle={hide} centered className="modal-xl">
                {!loader && <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <ModalHeader>{formType} {formName}</ModalHeader>
                    <ModalBody className="mt-3 mb-3">

                        {multiplePersonDatas && email &&
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Alert
                                        color="warning"
                                        dismissible
                                        className="d-flex align-items-start flex-column shadow"
                                        style={{border: "1px solid #e7b100"}}
                                    >
                                        <h4 className="alert-heading" style={{color:"#503d00"}}><b>Warning</b></h4>
                                        <p>
                                            This email already exists in a different relation.<br />
                                            Do you want to link this email to an existing person?
                                            <Link to="#" onClick={(e) => this.removeConfirmation(e)} className="m-2"><span className="alert-link d-inline-block">[ No ]</span></Link>
                                        </p>
                                        <p className="mb-0">If yes, select which person:</p>
                                        <ul className="mb-1">
                                            {multiplePersonDatas.map((person, index) => (
                                                <li key={index}>
                                                    {person.full_name}
                                                    <Link to="#" onClick={(e) => this.linkToPerson(index, e)} className="m-2"><span className="alert-link d-inline-block">[ Yes ]</span></Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </Alert>
                                </Col>
                            </Row>
                        </React.Fragment>
                        }

                        {updatePersonEmail && email ?
                            <React.Fragment>
                                <Row>
                                    <Col>
                                        <Alert
                                            color="warning"
                                            dismissible
                                            className="d-flex align-items-start flex-column"
                                            style={{border: "1px solid #e7b100"}}
                                        >
                                            <h4 className="alert-heading" style={{color:"#503d00"}}><b>Warning</b></h4>
                                            <p>This person's email is already existing with the following relation id:</p>
                                            {updatePersonEmail.map((person, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 ? ',' : ''}
                                                    <span>{` ${person.relation_id} `}</span>
                                                </React.Fragment>
                                            ))}
                                            <p>
                                                Do you want to continue?
                                                <Link to="#" onClick={(e) => this.removeConfirmation(e)}> Yes </Link> or
                                                <Link to="#" onClick={hide}> No </Link>
                                            </p>
                                        </Alert>
                                    </Col>
                                </Row>
                            </React.Fragment> : ''
                        }

                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">Email:</span>
                            </Col>
                            <Col md={9} className="pb-2">
                                {/*<Input
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    value={email ? email : ''}
                                    onChange={this.handleEmailInputChange}
                                />*/}
                                <Input
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    value={email ? email : ''}
                                    onChange={this.handleEmailInputChange}
                                    onKeyDown={(e) => {
                                        const { value } = e.target;
                                        if (e.key === 'Tab' && this.isValidEmail(email)) {
                                            // Allow Tab key only if the email is valid
                                            return;
                                        }
                                        if (e.key === 'Tab' && !email) {
                                            e.preventDefault(); 
                                            // Prevent tabbing if email is not provided
                                            Toast.fire({
                                                title: 'Email address field is required!',
                                                icon: 'warning'
                                            }).then(r => console.log(r));
                                            return;
                                        }
                                    }}
                                    onBlur={(e) => {
                                        const { value } = e.target;
                                        if (!value) {
                                            Toast.fire({
                                                title: 'Email address field is required!',
                                                icon: 'warning'
                                            }).then(r => console.log(r));
                                            this.setState({ disableAll: true });
                                        } else if (!this.isValidEmail(value)) {
                                            Toast.fire({
                                                title: 'Please enter a valid email address!',
                                                icon: 'warning'
                                            }).then(r => console.log(r));
                                            this.setState({ disableAll: true });
                                        } else {
                                            this.setState({ disableAll: false });
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        clearTimeout(this.timeout); // Clear any existing timeout
                                        const { value } = e.target;
                                        if (e.key !== 'Tab' && value && value.length === 2) {
                                            this.timeout = setTimeout(() => {
                                                if (value && !this.isValidEmail(value)) {
                                                    Toast.fire({
                                                        title: 'Please enter a valid email address!',
                                                        icon: 'warning'
                                                    }).then(r => console.log(r));
                                                    this.setState({ disableAll: true });
                                                } else {
                                                    this.setState({ disableAll: false });
                                                }
                                            }, 2000); // Validate every 2 seconds
                                        }
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">Status / Type / Gender:</span>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col md={4} className="pb-2">
                                        <Select
                                            id="status"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Status"
                                            options={statusOpts}
                                            value={status ? status : null}
                                            onChange={this.handleSelectChange.bind(this, 'status')}
                                            maxMenuHeight={300}
                                            isDisabled={disableAll}
                                        />
                                    </Col>
                                    <Col md={4} className="pb-2">
                                        <Select
                                            id="person_type_id"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Type"
                                            options={personTypeOpts}
                                            value={person_type_id ? person_type_id : null}
                                            onChange={this.handleSelectChange.bind(this, 'person_type_id')}
                                            maxMenuHeight={300}
                                            isDisabled={disableAll}
                                        />
                                    </Col>
                                    <Col md={4} className="pb-2">
                                        <Select
                                            id="gender"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Gender"
                                            options={genderOpts}
                                            value={gender ? gender : null}
                                            onChange={this.handleSelectChange.bind(this, 'gender')}
                                            maxMenuHeight={300}
                                            isDisabled={disableAll}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">Title / Name:</span>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col xs={12} sm={3} md={3} lg={3} className="pb-2">
                                        <Select
                                            id="title"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Title"
                                            options={titleOpts[gender && gender.value ? gender.value : 'All']}
                                            value={title ? title : null}
                                            onChange={this.handleSelectChange.bind(this, 'title')}
                                            maxMenuHeight={300}
                                            isDisabled={disableAll}
                                        />
                                    </Col>
                                    <Col xs={12} sm={3} md={3} lg={3} className="pb-2">
                                        <Input
                                            id="first_name"
                                            name="first_name"
                                            placeholder="First name"
                                            value={first_name ? first_name : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={disableAll}
                                        />
                                    </Col>
                                    <Col xs={12} sm={3} md={3} lg={3} className="pb-2">
                                        <Input
                                            id="middle_name"
                                            name="middle_name"
                                            placeholder="Middle name"
                                            value={middle_name ? middle_name : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={disableAll}
                                        />
                                    </Col>
                                    <Col xs={12} sm={3} md={3} lg={3} className="pb-2">
                                        <Input
                                            id="last_name"
                                            name="last_name"
                                            placeholder="Last name"
                                            value={last_name ? last_name : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={disableAll}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">Phone / Mobile:</span>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <Input
                                            id="phone"
                                            name="phone"
                                            placeholder="Phone"
                                            value={phone ? phone : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={disableAll}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <Input
                                            id="mobile"
                                            name="mobile"
                                            placeholder="Mobile"
                                            value={mobile ? mobile : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={disableAll}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">Date of birth / Language:</span>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <DatePicker
                                            locale={this.getLocale()}
                                            id="birthdate"
                                            wrapperClassName="w-100"
                                            className="form-control"
                                            name="birthdate"
                                            placeholderText="Date of birth"
                                            dateFormat={t("date_format_raw")}
                                            autoComplete="off"
                                            dateFormatCalendar="MMMM"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            maxDate={new Date()}
                                            yearDropdownItemNumber={150}
                                            scrollableYearDropdown={true}
                                            selected={birthdate ? new Date(birthdate) : null}
                                            value={birthdate ? new Date(birthdate) : null}
                                            onChange={(e) => this.handleDatePickerChange(e)}
                                            disabled={disableAll}
                                            onKeyDown={(e) => this.handleKeyDown(e, 'birthdate')}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <Select
                                            id="language"
                                            //id="language_id"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Language"
                                            //options={languageOpts}
                                            options={languageOptions}
                                            value={language}
                                            defaultValue="nl"
                                            onChange={this.handleSelectChange.bind(this, 'language')}
                                            //onChange={this.handleSelectChange.bind(this, 'language_id')}
                                            maxMenuHeight={300}
                                            isDisabled={disableAll}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={3} className="pb-2 pt-1">
                                <span className="label">Primary person:</span>
                            </Col>
                            <Col className="pb-2">
                            {
                                selectedData
                                ?
                                    <CustomInput
                                        id="primary"
                                        type="checkbox"
                                        className="mt-1"
                                        name="primary"
                                        label="Primary person"
                                        defaultChecked={primary}
                                        onChange={(e) => this.handleCheckChange(e)}
                                        disabled={defaultPrimary}
                                    />
                                :

                                    this.state.formType !== 'edit'
                                        ?
                                            <div className="custom-checkbox custom-control custom-controlled-checkbox mt-1">
                                                <Input
                                                    className=""
                                                    type="checkbox"
                                                    color={`primary`}
                                                    name="primary"
                                                    id="primary"
                                                    value={primary && primary !== '0'}
                                                    onChange={(e) => this.handlePrimaryCheckChange(e)} // handlePrimaryCheckChange
                                                    disabled={disableAll}
                                                />
                                                <Label className="custom-controlled-checkbox-label" for="primary" check>Save as Primary person</Label>
                                            </div>
                                        :
                                            this.state.formType === 'edit'
                                                ?
                                                    <CustomInput
                                                        id="primary"
                                                        className="mt-1"
                                                        type="checkbox"
                                                        name="primary"
                                                        label="Primary person"
                                                        defaultChecked={primary && primary !== '0'}
                                                        onChange={(e) => this.handleCheckChange(e)}
                                                        disabled={disableAll}
                                                    />
                                                :
                                                    ''
                            }
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <span className="btn btn-danger" onClick={hide}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                        <Button color="primary" disabled={disableAll}>
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ModalFooter>
                </Form>}
            </Modal>
        )
    }
}

const mapStateToProps = store => ({
    loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(PersonForm)));
