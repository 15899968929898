import React from "react";
import {connect} from "react-redux";
import {RemovePlan} from '../../controllers/plans';
import {withRouter} from 'react-router-dom';
import Swal from "sweetalert2";
import {loaderToggle} from '../../redux/actions/loaderActions';

class RemovePlanForm extends React.Component {
  componentDidMount() {
    const { loader, selectedData, dispatch, update, hide } = this.props;

    Swal.fire({
      customClass: { container: 'has-cancel', },
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this plan?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(res => {
      console.log("RemovePlanForm - RemovePlan - res ->", res);

      if (res.value) {
        if (!loader) {
          (async () => {
            dispatch(loaderToggle(true));

            const removedData = await RemovePlan(selectedData.id);
            console.log("RemovePlanForm - RemovePlan - removedData ->", removedData);
            //console.log("RemovePlanForm - RemovePlan - message ->", message);
            //console.log("RemovePlanForm - RemovePlan - data ->", data);
            if (!removedData) {

              let returnedMessage = 'Failed to remove plan';
              //if (message) {
              //  returnedMessage = message;
              //}

              dispatch(loaderToggle(false));
              await Swal.fire('Error!', returnedMessage, 'error');
              hide();

            } else {
              dispatch(loaderToggle(false));
              await Swal.fire('Success!', 'Removed successfully', 'success');
              update();
              hide();

            }

          })();
        }
      } else {
        dispatch(loaderToggle(false));
        hide();

      }
    })
  }

  render() {
    return (
        <React.Fragment>
        </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader
}))(withRouter(RemovePlanForm));
