import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Form, Input} from "reactstrap";
import {UpdateProvisioningTemplates} from '../../../controllers/provisioning';
import XMLPrettify from "xml-formatter";
import JSONPretty from 'react-json-pretty';
import JsonPrettify from "json-stringify-pretty-compact";
import ReactJson from 'react-json-view';
import {loaderToggle} from "../../../redux/actions/loaderActions";
import {validateDate} from "../../../redux/actions/validateActions";
import Toast from "../../../components/Toast";
import {parseDateToSaveFormat} from '../../../redux/actions/formatActions';
import {withTranslation} from 'react-i18next';
import {nl, enGB, enUS} from "date-fns/locale";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faSave} from '@fortawesome/free-solid-svg-icons';

const JsonOptions = { indent: 4 },
      XmlOptions = {
        indentation: '   ',
        stripComments: true,
        collapseContent: false,
        lineSeparator: '\n'
      };

const ProvisioningTemplateJsonForm = ({ ...props }) => {
    console.log("ProvisioningTemplateJsonForm - props ->", props);
    const {show, hide, selectedData} = props;

    const formType = selectedData ? 'Edit' : 'Add';
    const formName = "JSON provisioning template";

    const [jsonData, setJsonData] = useState(null);

    const Prettify = (json_data) => {
        if (json_data) {
          console.log("ProvisioningTemplateJsonForm - Prettify - json_data ->", json_data);
          let parsedJSON = JSON.parse(json_data);
          console.log("ProvisioningTemplateJsonForm - Prettify - parsedJSON ->", parsedJSON);
    
          const xml = Object.keys(json_data).filter(data => data === 'Xml');
          console.log("ProvisioningTemplateJsonForm - Prettify - xml ->", xml);
    
          return (<React.Fragment>
            {parsedJSON && <React.Fragment>
              {removeXML(parsedJSON)}
            </React.Fragment>}
          </React.Fragment>)
        }
    };

    const removeXML = (json) => {
        const xmlData = json.Xml ? json.Xml : null;
        if (xmlData) {
          delete json.Xml;
        }
        return (
          <>
            <div>{JsonPrettify(json, JsonOptions)}</div>
            {xmlData && <div>{XMLPrettify(xmlData, XmlOptions)}</div>}
          </>
        );
    };

    const editJsonData = (edit) => {
        console.log("ProvisioningTemplateJsonForm - editJsonData - edit ->", edit);
        let params = {};
        if (edit && edit.updated_src) {
            params.template = JSON.stringify(edit.updated_src);
            console.log("ProvisioningTemplateJsonForm - editJsonData - params ->", params);
            submitJsonData(params);
        }
    }

    const submitJsonData = (params) => {
        const { dispatch, loader } = props;
        if (!loader) {
            dispatch(loaderToggle(true));
      
            if (params && params.template) {
              (async () => {
                const {ok, data} = await UpdateProvisioningTemplates(selectedData.id, params);
                handleOk(ok, data);
              })();
            } else {
              dispatch(loaderToggle(false));
            }
        }
    }

    const handleOk = (ok, data) => {
        const { dispatch, update } = props;
        dispatch(loaderToggle(false));

        if (ok) {
          update("prov_template_update");

          if (data && data.message) {
            console.log("handleOk - data ->", data);
            return Toast.fire({ title: data.message, icon: 'success' })
          }
        }
        hide();
    }

    useEffect(() => {
        const { dispatch, user, loader, tenantId, selectedData } = props;

        if (selectedData && selectedData.template) {
            let parsedJSON = JSON.parse(selectedData.template);
            console.log("ProvisioningTemplateJsonForm - Prettify - parsedJSON ->", parsedJSON);
            setJsonData(parsedJSON);
        }

    }, []);

    return (
        <Modal className="form-provisioning-template json modal-xl" isOpen={show} toggle={hide} centered>
            <ModalHeader>
              <span>{formType} {formName}</span>
            </ModalHeader>
            <ModalBody key="0" className="mt-3 mb-3">
                {jsonData && 
                <ReactJson
                    name="Template"
                    displayObjectSize={true}
                    displayDataTypes={true}
                    iconStyle="square"
                    src={jsonData}
                    enableClipboard={true}
                    collapsed={false}
                    onEdit={editJsonData}
                    style={{ fontFamily: "inherit", backgroundColor: "transparent", textAlign: "left" }}
                />
                }
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = ({dispatch, loader, user, tenants}) => ({dispatch, loader, user, tenants});

export default withRouter(connect(mapStateToProps)(ProvisioningTemplateJsonForm));
