import React, { useState } from "react";
//import { Link } from "react-router-dom";
import AuthService from '../../services/authService';
//import Toast from '../../components/Toast';
import ButtonSpinner from '../../components/ButtonSpinner';
import Swal from "sweetalert2";
import ToastMulti from "../../components/ToastMulti";
import { Button, Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import {Link} from 'react-router-dom';

const Auth = new AuthService();

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyPress = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let notificationsToastArr = [];
    if (!username) {
      notificationsToastArr.push({
        title: "Username field is required!",
        icon: "warn",
      });
    }

    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    if (!isLoading) {
      setIsLoading(true)
    }

    const promptMessage = ({ title, text, icon, status }) => {
      Swal.fire({
        title,
        text,
        icon,
        showCancelButton: false,
        confirmButtonText: 'Ok',
      }).then(res => {
        if (status === 200) {
          window.location.href = '/'
        }
      })
    };

    Auth.forgotPassword({ username })
      .then(res => {
        const param = {
          title: 'Email sent successfully',
          //text: `An email with a link to reset your password was sent to ${email}`,
          text: `An email with a link to reset your password was sent to your email-address`,
          icon: 'success',
          status: 200
        };
        promptMessage(param)
      })
      .catch(({ data, status }) => {
        const title = 'Email was not sent';
        const text = status === 503 ? 'Sorry! Maintenance in progress' : data && data.message ? data.message : 'Sorry, we could not send the password reset email. Please contact your administrator for support.',
          icon = status === 503 ? 'warning' : 'error';

        const param = {
          title,
          text,
          icon,
          status: status
        };
        promptMessage(param);
        setIsLoading(false);
      })
  };

  const backButton = (e) => {
    e.preventDefault();
    window.history.go(-1);
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead">Enter your username to reset your password.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form onSubmit={(e) => handleSubmit(e)} onKeyDown={handleKeyPress}>
              <FormGroup>
                <Label>Username</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="username"
                  placeholder="Enter your username"
                  autoComplete="off"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <small className="cont-login-btn" style={{textAlign: "right", width: "100%", float: "right", margin: "0"}}>
                  <button className="btn btn-link small px-0" onClick={(e) => backButton(e)}>Sign in</button>
                </small>
              </FormGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg">
                  Reset password
                    {isLoading && <ButtonSpinner />}
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
