import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import sidebarMenu from "./sidebarMenuReducers";
import solrSearch from "./solrSearchReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import user from "./userReducer";
import tenants from "./tenantReducer";
import brand from "./brandReducer";
import loader from "./loaderReducer";
import error from "./errorReducer";
import relation from './relationReducer'
import mvloader from './mvLoaderReducer'
import mvloaderInvoice from './mvLoaderInvoiceReducer'
import mvHashPath from './mvHashPathReducer'
import subscription from './subscriptionReducer'
import invoice from './invoiceReducer'

export default combineReducers({
  sidebar,
  sidebarMenu,
  solrSearch,
  layout,
  theme,
  user,
  tenants,
  brand,
  loader,
  error,
  relation,
  mvloader,
  mvloaderInvoice,
  mvHashPath,
  subscription,
  invoice,
});
