import React, { useState, useEffect } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';
import {getTableObj} from '../../services/tableService';

const CustomFilterSelect = ({ column, onFilter, handleFiltering, constantObj }) => {
  const { t } = useTranslation();

  const [invoiceStatus, setInvoiceStatus] = useState('');
  const invoiceStatusesOpt = [
    { label: 'All', value: "" },
    { label: '1st reminder', value: 'first_reminder_sent' },
    { label: '2nd reminder', value: 'second_reminder_sent' },
    { label: 'Warning', value: 'warning_sent' },
    { label: 'Final notice', value: 'final_notice_sent' },
    { label: 'Coll. agency', value: 'sent_to_collection_agency' }
  ];

  useEffect(() => {
    handleFiltering(invoiceStatus, column.dataField);
  }, [invoiceStatus]);

  return [
    <div key={`select-${column.dataField}`} className="custom-finance-select-container" style={{display: 'flex', flexDirection: 'column'}}>
      <Select
          className="react-select-container custom-finance-select mt-0"
          classNamePrefix="react-select"
          name={column.dataField}
          id="invoiceStatus"
          placeholder="Select..."
          options={constantObj}
          value={invoiceStatus}
          onChange={(data) => setInvoiceStatus(data)}
      />
    </div>
  ];
};

export default withRouter(withTranslation('translations')(connect()(CustomFilterSelect)));