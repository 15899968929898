import ApiService from "../services/apiService";

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI;

const apiIncludes = 'fiscal-year';

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        return await method ? method(endpoint, params) : await GetAPI(endpoint, params);
    })();
};

const GetMyAccountingPeriods = (params, id, fiscalYearId) => {
    return asyncFunc(`/myaccountingperiods/${id}/${fiscalYearId}?include=${apiIncludes}`, params);
};

const AddAccountingPeriod = (params) => {
    return asyncFunc(`/accounting_periods`, params, PostAPI);
};

const UpdateAccountingPeriod = (id, params) => {
    return asyncFunc(`/accounting_periods/${id}`, params, UpdateAPI);
};

export {
    GetMyAccountingPeriods,
    AddAccountingPeriod,
    UpdateAccountingPeriod
}
