import React from "react";
import { Check } from "react-feather";
import {GetAddress, GetRelAddress} from '../../../controllers/relations';
import AddressForm from "./Form";
import ParentTable from '../../../components/ParentTable';
import CONSTANTS from "../../../services/constants";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";

const checkIcon = (cell) => {
  return (
      <React.Fragment>
        {(cell === 1 || cell === true) &&
        <Check size={18} color="#5fc27e" className="check" />
        }
      </React.Fragment>
  )
};

const formatEmpty = (cell) => {
  if (cell) {
    return cell;
  } else {
    return <span className="null-value">{String.fromCharCode(8212)}</span>
  }
};

class Addresses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      activeTab: 0,
      sizePerPage: 100
    };
  }

  render() {
    const {id, sizePerPage, titleUpdate} = this.props;

    return (
      <React.Fragment>
        {titleUpdate && <Helmet>
          <title>{titleUpdate}</title>
        </Helmet>}
        <ParentTable
          table="RelationAddresses"
          keyField="id"
          id={id}
          sizePerPage={sizePerPage ? parseInt(sizePerPage) : 100}
          data={GetAddress}
          selectedDataRow={null}
          remote
          columns={[
            {
              dataField: "address_type",
              text: "Type",
              sort: false,
              headerStyle: () => {
                return { width: '10%' };
              },
              attrs: {
                'data-label': 'Type'
              }
            }, {
              dataField: "primary",
              text: "Primary",
              formatter: checkIcon,
              sort: true,
              classes: 'text-center primary-label',
              headerStyle: () => {
                  return { width: '10%', 'textAlign': 'center' };
              },
              attrs: {
                'data-label': 'Primary'
              }
            }, {
              dataField: "street1",
              text: "Street",
              sort: true,
              headerStyle: () => {
                  return { width: '14%' };
              },
              attrs: {
                'data-label': 'Street'
              }
            }, {
              dataField: "house_number",
              text: "House no.",
              sort: true,
              headerStyle: () => {
                  return { width: '9%' };
              },
              attrs: {
                'data-label': 'House no.'
              }
            }, {
              dataField: "house_letter",
              text: "Letter",
              sort: true,
              formatter: formatEmpty,
              headerStyle: () => {
                  return { width: '7%' };
              },
              attrs: {
                'data-label': 'House letter'
              }
            }, {
              dataField: "house_number_suffix",
              text: "Suffix",
              sort: true,
              formatter: formatEmpty,
              headerStyle: () => {
                  return { width: '8%' };
              },
              attrs: {
                'data-label': 'Suffix'
              }
            }, {
              dataField: "room",
              text: "Room",
              sort: true,
              formatter: formatEmpty,
              headerStyle: () => {
                  return { width: '7%' };
              },
              attrs: {
                'data-label': 'Room'
              }
            }, {
              dataField: "zipcode",
              text: "Zipcode",
              sort: true,
              headerStyle: () => {
                  return { width: '10%' };
              },
              attrs: {
                'data-label': 'Zipcode'
              }
            }, {
              dataField: "city_name",
              text: "City",
              sort: false,
              headerStyle: () => {
                  return { width: '13%' };
              },
              attrs: {
                'data-label': 'City'
              }
            }, {
              dataField: "country_name",
              text: "Country",
              sort: false,
              headerStyle: () => {
                return { width: '13%' };
              },
              attrs: {
                'data-label': 'Country'
              }
            }
          ]}
          rowAction="edit"
          rowActionForm={AddressForm}
          headerDropdownOpts={[{
            label: <FontAwesomeIcon color="grey" icon={faPlus} />,
            action: AddressForm
          }]}
          noSearch={true}
          cacheSearch={false}
          filterSearch={false}
          wrapperClasses="table-addresses"
          //offLoader
          pageHeadingTitle="Relation"
          tab={CONSTANTS.relationDetailsTabs.addresses}
        />
      </React.Fragment>
    )
  }
}

export default Addresses;
