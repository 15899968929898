import ApiService from '../services/apiService';

const Api = new ApiService(),
  GetAPI = Api.getAPI,
  GetAPIwithType = Api.getAPIwithType,
  PostAPI = Api.postAPI,
  UpdateAPI = Api.updateAPI,
  RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    let data = await method ? method(endpoint, params) : await GetAPI(endpoint, params);
    return data
  })()
};

const GetInvoices = (params) => {
  return asyncFunc(`sales_invoices`, params)
};

const GetInvoicesRelation = (params) => {
  console.log("Invoices controller - GetInvoicesRelation - params ->",  params);
  return asyncFunc(`sales_invoices/summary${params}`)
};

const GetInvoicesSummary = (params) => {
  return asyncFunc(`sales_invoices/summary`, params)
};

const GetInvoicesCount = () => {
  return asyncFunc(`sales_invoices/count`)
};

const GetInvoice = (params, id) => {
  return asyncFunc(`sales_invoices/${id}`)
};

const GenerateInvoice = (id) => {
  return asyncFunc(`subscriptions/invoice/${id}`, 'blob', GetAPIwithType)
};

// invoice attachments - sales_invoices/{salesInvoice}/attachments
const GetInvoiceAttachments = (id) => {
  return asyncFunc(`sales_invoices/${id}/attachments`)
};

const ReCreateInvoice = (id) => {
  return asyncFunc(`subscriptions/recreate_invoice/${id}`)
};

const GetInvoiceState = (id, state) => {
  return asyncFunc(`sales_invoices/${id}/${state}`)
};

const GenerateCreditInvoice = (id) => {
  return asyncFunc(`sales_invoices/credit/${id}`)
};

const GenerateSubscriptionInvoice = (id) => {
  return asyncFunc(`sales_invoices/subscription/${id}`)
};

const SendInvoicEmail = (id) => {
  return asyncFunc(`sales_invoices/email/${id}`)
};

const AddInvoice = (params) => {
  return asyncFunc(`sales_invoices`, params, PostAPI)
};

const UpdateInvoice = (id, params) => {
  return asyncFunc(`sales_invoices/${id}`, params, UpdateAPI)
};

const RemoveInvoice = (id) => {
  return asyncFunc(`sales_invoices/${id}`, null, RemoveAPI)
};

const GetInvoiceLines = (params, id) => {
  return asyncFunc(`sales_invoices/${id}/sales_invoice_lines`, params)
};

const AddInvoiceLine = (params) => {
  return asyncFunc(`sales_invoice_lines`, params, PostAPI)
};

const UpdateInvoiceLine = (id, params) => {
  return asyncFunc(`sales_invoice_lines/${id}`, params, UpdateAPI)
};

const RemoveInvoiceLine = (id) => {
  return asyncFunc(`sales_invoice_lines/${id}`, null, RemoveAPI)
};

const GetInvoiceLineGadgets = (id) => {
  return asyncFunc(`sales_invoice_lines/${id}/gadgets`)
};

const GetInvoiceLineTypes = (params) => {
  return asyncFunc(`sales_invoice_line_types`, params)
};

const GetInvoiceGadgets = (id) => {
  return asyncFunc(`sales_invoices/${id}/gadgets`)
};

const SendReminder = (id) => {
  return asyncFunc(`sales_invoices/${id}/send/reminder`)
};

const GetCdrUsageCosts = (id, params) => {
  return asyncFunc(`sales_invoices/${id}/cdr_usage_costs`, params)
};

export {
  //Invoices
  GetInvoices,
  GetInvoicesRelation,
  GetInvoicesSummary,
  GetInvoicesCount,
  GetInvoice,
  GenerateInvoice,
  ReCreateInvoice,
  GetInvoiceState,
  GenerateCreditInvoice,
  GenerateSubscriptionInvoice,
  GetInvoiceAttachments,
  SendInvoicEmail,
  AddInvoice,
  UpdateInvoice,

  //Invoice Lines
  GetInvoiceLines,
  AddInvoiceLine,
  RemoveInvoice,
  UpdateInvoiceLine,
  RemoveInvoiceLine,
  GetInvoiceLineGadgets,
  GetInvoiceLineTypes,

  //Reminders
  SendReminder,

  //CDR
  GetCdrUsageCosts,
  GetInvoiceGadgets
}