import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { Button, Card, CardBody, Form, FormGroup, Label, Input, Spinner } from "reactstrap";

import { loaderToggle } from "../../redux/actions/loaderActions";
import Toast from "../../components/Toast";
import Loader from '../../components/Loader';
import { isInvalidChars } from '../../services/misc'

axios.defaults.baseURL = process.env.REACT_APP_API;

class Verify extends React.Component {
  constructor() {
    super();

    this.state = {
      checking: true,
      verified: false,
      isInvalid: false,
      resetSuccess: false,
      user: {}
    }
  }

  handleInputChange({ target: { name, value }}) {
    const user = Object.assign({}, this.state.user);
    if(name === 'password') {
      user['isPasswordValid'] = !isInvalidChars(value);
      user['isPasswordValidlength'] = value.length >= 12
    }
    this.setState({ [name]: value, user })
  }

  handleSubmit(e) {
    e.preventDefault();
    
    const { dispatch, loader } = this.props,
      { password, password_confirmation, user } = this.state,
      { isPasswordValid, isPasswordValidlength } = user;

    if (password && !isPasswordValidlength) {
      return Toast.fire({ title: 'The new password is too short. Please use at least 12 characters.', icon: 'warning' })
    }

    if (password && !isPasswordValid) {
      return Toast.fire({ title: 'The password is not strong enough. The password must contain at least 1 capital letter (A-Z), 1 lowercase letter (a-z), 1 digit (0-9) and 1 special character (! @ # $ % ^ & * ?)', icon: 'warning' })
    }

    if (password !== password_confirmation) {
      user['isPasswordConfirmValid'] = false;
      this.setState({ user });
      return Toast.fire({ title: 'Passwords do not match!', icon: 'error' })
    } else {
      user['isPasswordConfirmValid'] = true;
      this.setState({ user })
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      axios.post(`user/${ this.props.match.params.code }/password`, { password, password_confirmation })
        .then(res => {
            dispatch(loaderToggle(false));
            Toast.fire({
              title: 'You have successfully created your password!',
               icon: 'success'
            }).then(r => console.log(r));

        this.setState({ resetSuccess: true })

            //window.location.href = `/auth/sign-in`
        })
        .catch(err => {
          dispatch(loaderToggle(false));
          const { data } = err.response;
          Toast.fire({
            title: data && data.message
                ? data.message
                : 'Something went wrong!', icon: 'error'
          }).then(r => console.log(r))
        })
    }
  }

  generateCode(e) {
    e.preventDefault();

    const { dispatch, loader } = this.props;

    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to generate new verfication code?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(res => {
      if (res.value) {
        if (!loader) {
          dispatch(loaderToggle(true));

          axios.get(`user/${ this.props.match.params.code }/reset`)
            .then(() => {
                dispatch(loaderToggle(false));
                Toast.fire({
                  title: 'Newly generated verification code has been sent to your email!',
                   icon: 'success'
                }).then(r => console.log(r))
            })
            .catch(() => {
              dispatch(loaderToggle(false));
              Toast.fire({
                title: 'It seems that your verification code is invalid!',
                 icon: 'error'
              }).then(r => console.log(r))
            })
        }
      }
    })
  }

  componentDidMount() {    
    axios.get(`verify/${ this.props.match.params.code }`)
      .then(() => {
          this.setState({ 
            checking: false,
            verified: true
          })
      })
      .catch(({ response: { status } }) => {
        if (status === 404) {
          this.setState({ isInvalid: true })
        }

        this.setState({ checking: false })
      })
  }
  
  render() {
    const { checking, verified, isInvalid, password, password_confirmation, user, resetSuccess } = this.state,
      { isPasswordValid, isPasswordConfirmValid } = user,
      { loader } = this.props;

    return(
      <React.Fragment>
        <div className="text-center mt-4">
          <React.Fragment>
            <h1 className="h2">
              { !checking && verified ? 'Create new password' : checking ? 'Checking verification code...' : 'Oops!' }
            </h1>
            { checking ?
              <Spinner color="primary" /> :
              <p className="lead">
                { verified ? 'Enter your desired password.' : `Your verification code is ${ isInvalid ? 'invalid' : 'expired' }.` }
              </p>
            }

            { !checking && !verified && !isInvalid && 
              <Link to="/" className="mt-3" onClick={ (e) => this.generateCode(e) }> Generate new verification code?</Link> 
            }
          </React.Fragment>
        </div>

        { !checking && verified && !resetSuccess &&
          <Card>
            <CardBody>
              <div className="m-sm-4">
                <Form onSubmit={ (e) => this.handleSubmit(e) }>
                  <React.Fragment>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input
                        bsSize="lg"
                        type="password"
                        name="password"
                        value={ password ? password : '' }
                        placeholder="Enter your password"
                        onChange={(e) => this.handleInputChange(e)}
                        className={ !isPasswordValid && password ? 'f2x-pword-err' : '' }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Confirm password</Label>
                      <Input
                        bsSize="lg"
                        type="password"
                        name="password_confirmation"
                        value={ password_confirmation ? password_confirmation : '' }
                        placeholder="Confirm your password"
                        onChange={(e) => this.handleInputChange(e)}
                        className={ !isPasswordConfirmValid && password_confirmation ? 'f2x-pword-err' : '' }
                      />
                    </FormGroup>
                  </React.Fragment> 
                  <div className="text-center mt-3">
                    <Button color="primary" size="lg">Save</Button>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Card>
        }

        {resetSuccess === true &&
          <div className="text-center mt-3">
            <Link to="/auth/sign-in" className="mt-3">Click here to sign in</Link>
          </div>
        }

        { loader && <Loader /> }
      </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader
}))(Verify);
