import React from "react";
import { Spinner } from "reactstrap";

const ButtonSpinner = () => (
  <div className="holder-spinner">
      <Spinner className="form-spinner" size="sm" />
  </div>
);

export default ButtonSpinner;
