import { faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { customFilter } from 'react-bootstrap-table2-filter';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Badge } from 'reactstrap';
import ParentTable from '../../components/ParentTable';
import CustomFilterDateComp from '../../components/filters/CustomFilterDateComp';
import CustomFilterInput from '../../components/filters/CustomFilterInput';
import CustomFilterSelect from '../../components/filters/CustomFilterSelect';
import { GetMicroservices } from '../../controllers/microservices';
import { GetGadgets, GetPlanSubscriptionLineTypes, GetSubscriptionLineStatuses, GetSubscriptionsLinesSummary, GetSubscriptionStatuses } from '../../controllers/subscriptions';
import { GetTenantLists } from '../../controllers/tenants';
import { formatAmountLocale, parseDateToSaveFormatObject } from '../../redux/actions/formatActions';
import Prices from '../relation/subscriptions/subscription_lines/Prices';
import Statuses from '../relation/subscriptions/subscription_lines/Statuses';

const debugInfo = false;

function SubscriptionLines({ ...props }) {
  const tableName = "SubscriptionsLinesSummary";

  const qStrSubscriptionsLinesSummaryFiltersObj = "qStrSubscriptionsLinesSummaryFiltersObj";
  const qStrSubscriptionsLinesSummary = "qStrSubscriptionsLinesSummary";
  const qStrSubscriptionsLinesSummaryFilter = "qStrSubscriptionsLinesSummaryFilter";
  const qStrSubscriptionsLinesSummaryFilterStr = "qStrSubscriptionsLinesSummaryFilterStr";
  const qStrSubscriptionsLinesSummaryComparatorItems = "qStrSubscriptionsLinesSummaryComparatorItems";
  const qStrSubscriptionsLinesSummaryKeyword = "qStrSubscriptionsLinesSummaryKeyword";
  const subscriptionsLinesSummaryFilterVisible = "subscriptionsLinesSummaryFilterVisible";
  //console.log("SubscriptionLines - props ->", props);

  const { t } = useTranslation();
  const { dataPrices, subscription, relation, setIsUpdated, dispatch, id, subscription_line_id, isUpdated, isDisabled, tenants } = props;

  //console.log("SubscriptionLines - tenants ->", tenants);

  const [isLoadData, setIsLoadData] = useState(false);
  const [lineTypes, setLineTypes] = useState(null);
  const [tenantsData, setTenantsData] = useState(null);
  const [lineStatuses, setLineStatuses] = useState(null);
  const [subStatuses, setSubStatuses] = useState(null);
  const [microserviceNameData, setMicroserviceNameData] = useState(null);
  const [tenantsLookUp, setTenantsLookUp] = useState(null);

  let [descriptionFilter, setDescriptionFilter] = useState(null);
  let [startDateFilter, setStartDateFilter] = useState(null);
  let [endDateFilter, setEndDateFilter] = useState(null);
  let [tenantFilter, setTenantFilter] = useState(null);
  let [subscriptionLineStatusesFilter, setSubscriptionLineStatusesFilter] = useState(null);
  let [subscriptionStatusesFilter, setSubscriptionStatusesFilter] = useState(null);
  let [typeFilter, setTypeFilter] = useState(null);
  let [addressFilter, setAddressFilter] = useState(null);
  let [statusFilter, setStatusFilter] = useState(null);
  let [microserviceNameFilter, setMicroserviceNameFilter] = useState(null);

  const [toggleFilterForm, setToggleFilterForm] = useState(false);
  const [collapseState, setCollapseState] = useState("is-hidden");
  const [clearFilters, setClearFilters] = useState(false);

  /* start filters - state variables */
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tenantId, setTenantId] = useState(""); // if multiSelect column -> set to [] as initialState  
  const [subscriptionLineStatus, setSubscriptionLineStatus] = useState("");
  const [subscriptionLineType, setSubscriptionLineType] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [microserviceName, setMicroserviceName] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");

  const [sort, setSort] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(100);
  const [paginationCount, setPaginationCount] = useState(1);

  let [filtersFilter, setFiltersFilter] = useState({});
  let [filters, setFilters] = useState({ filter: null, keyword: "" });

  const [comparatorFilter, setComparatorFilter] = useState({});
  const [enabledFilter, setEnabledFilter] = useState(props.enabledFilter ? props.enabledFilter : false);
  const clearedItemName = useRef("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [subscriptionLineStatuses, setSubscriptionLineStatuses] = useState([]);

  /* Filter */
  const handleFiltering = (e, name, multiSelectable, comparator = null) => {
    if (debugInfo) {
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - e ->",
        e);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - name ->",
        name);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - comparator ->",
        comparator);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - filters: descriptionFilter ->",
        descriptionFilter);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - filters: startDateFilter ->",
        startDateFilter);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - filters: endDateFilter ->",
        endDateFilter);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - filters: tenantFilter ->",
        tenantFilter);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - filters: typeFilter ->",
        typeFilter);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - filters: addressFilter ->",
        addressFilter);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - filters: statusFilter ->",
        statusFilter);
      console.log(
        "SubscriptionsLinesSummary - handleFiltering - filters: microserviceNameFilter ->",
        microserviceNameFilter);
    }

    let historyFilter = false;
    // custom comparator
    if (comparator) {
      setComparatorFilter({
        ...comparatorFilter,
        [name]: comparator
      });
    }

    if (!enabledFilter) {
      let filtersObj = sessionStorage.getItem(qStrSubscriptionsLinesSummaryFiltersObj);
      if (filtersObj) {
        let parsedFiltersObj = JSON.parse(filtersObj);
        if (parsedFiltersObj && parsedFiltersObj.filter) {
          historyFilter = true;
          // setCollapseState("is-visible");
          setToggleFilterForm(true);

          if (parsedFiltersObj.filter) {
            filtersFilter = parsedFiltersObj.filter;
            let searchedKeyword = sessionStorage.getItem(qStrSubscriptionsLinesSummaryKeyword);
            if (searchedKeyword) {
              parsedFiltersObj.keyword = searchedKeyword;
            }
            setFiltersFilter(filtersFilter);
          }
          filters = parsedFiltersObj;
          setFilters(filters);
        }
      }
    }

    if (
      (name && e && (e.value !== "" || e.value === "")) ||
      (name && e === null) ||
      (e && e.key && e.key === "Enter") ||
      (e && multiSelectable && (e.length > 0 || e.length === null || e.length === 0)) ||
      historyFilter
    ) {
      let value = "";
      switch (name) {
        case "description":
          let description = e && e.target && e.target.value
            ? e.target.value
            : !enabledFilter && filtersFilter && filtersFilter["description"] ? filtersFilter["description"] : "";
          if (description === "") {
            description = null;
          }
          value = description;
          setDescription(value);
          break;
        case "full_address":
          let address = e && e.target && e.target.value
            ? e.target.value
            : !enabledFilter && filtersFilter && filtersFilter["full_address"] ? filtersFilter["full_address"] : "";
          if (address === "") {
            address = null;
          }
          value = address;
          setAddress(value);
          break;
        case "subscription_start":
          if (e && e.target && e.target.attributes && e.target.attributes.name && e.target.attributes.name.value && e.target.attributes.name.value === "filter-date-clear-icon") {
            value = null;
          } else {
            let start_date = e ? e : !enabledFilter && filtersFilter &&
              filtersFilter["subscription_start"]
              ? filtersFilter["subscription_start"]
              : "";

            if (start_date === "") {
              value = null;
            } else {
              let validateDate = parseDateToSaveFormatObject(new Date(start_date));
              if (validateDate === "" && e && e.value) {
                let convertDateArr = e.value.split("-");
                value = convertDateArr[2] + "-" + convertDateArr[1] + "-" + convertDateArr[0];
              } else {
                value = start_date && start_date !== "" ? parseDateToSaveFormatObject(new Date(start_date)) : "";
              }
            }
          }
          setStartDate(value);
          break;
        case "subscription_stop":
          if (e && e.target && e.target.attributes && e.target.attributes.name && e.target.attributes.name.value && e.target.attributes.name.value === "filter-date-clear-icon") {
            value = null;
          } else {
            let end_date = e ? e : !enabledFilter && filtersFilter &&
              filtersFilter["subscription_stop"]
              ? filtersFilter["subscription_stop"]
              : "";

            if (end_date === "") {
              value = null;
            } else {
              let validateDate = parseDateToSaveFormatObject(new Date(end_date));
              if (validateDate === "" && e && e.value) {
                let convertDateArr = e.value.split("-");
                value = convertDateArr[2] + "-" + convertDateArr[1] + "-" + convertDateArr[0];
              } else {
                value = end_date && end_date !== "" ? parseDateToSaveFormatObject(new Date(end_date)) : "";
              }
            }
          }
          setEndDate(value);
          break;
        case "tenant_id":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["tenant_id"] ? filtersFilter["tenant_id"] : null;
            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setTenantId(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setTenantId(!enabledFilter && filtersFilter && filtersFilter["tenant_id"] ? filtersFilter["tenant_id"] : null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["tenant_id"] ? filtersFilter["tenant_id"] : "";
            if (value === "") {
              value = null;
            }
            setTenantId(value);
          }
          break;
        case "microservice_name":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["microservice_name"] ? filtersFilter["microservice_name"] : null;
            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setMicroserviceName(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setMicroserviceName(!enabledFilter && filtersFilter && filtersFilter["microservice_name"] ? filtersFilter["microservice_name"] : null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["microservice_name"] ? filtersFilter["microservice_name"] : "";
            if (value === "") {
              value = null;
            }
            setMicroserviceName(value);
          }
          break;
        case "subscription_line_type":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["subscription_line_type"] ? filtersFilter["subscription_line_type"] : null;
            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setSubscriptionLineType(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setSubscriptionLineType(!enabledFilter && filtersFilter && filtersFilter["subscription_line_type"] ? filtersFilter["subscription_line_type"] : null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["subscription_line_type"] ? filtersFilter["subscription_line_type"] : "";
            if (value === "") {
              value = null;
            }
            setSubscriptionLineType(value);
          }
          break;
        case "status":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["status"] ? filtersFilter["status"] : null;
            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setSubscriptionLineStatus(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setSubscriptionLineStatus(!enabledFilter && filtersFilter && filtersFilter["status"] ? filtersFilter["status"] : null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["status"] ? filtersFilter["status"] : "";
            if (value === "") {
              value = null;
            }
            setSubscriptionLineStatus(value);
          }
          break;
        case "subscription_status":
          if (multiSelectable) {
            value = e ? e : !enabledFilter && filtersFilter && filtersFilter["subscription_status"] ? filtersFilter["subscription_status"] : null;
            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value && value[i].label) {
                  selectedValues.push({ label: value[i].label, value: value[i].value });
                }
              }
              setSubscriptionStatus(selectedValues && selectedValues.length > 0 ? selectedValues : null);
            } else {
              setSubscriptionStatus(!enabledFilter && filtersFilter && filtersFilter["subscription_status"] ? filtersFilter["subscription_status"] : null);
            }
          } else {
            value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["subscription_status"] ? filtersFilter["subscription_status"] : "";
            if (value === "") {
              value = null;
            }
            setSubscriptionStatus(value);
          }
          break;
        default:
          break;
      }

      if (debugInfo) {
        console.log("SubscriptionsLinesSummary - handleFiltering - name ->", name);
        console.log("SubscriptionsLinesSummary - handleFiltering - value ->", value);
      }

      if (filtersFilter) {
        if (filtersFilter[name] && (!value || value === "" || value.length === 0)) {
          delete filtersFilter[name];
        } else {
          if (value) {
            filtersFilter[name] = value;
          }
        }

        if (debugInfo) {
          console.log("SubscriptionsLinesSummary - handleFiltering - filtersFilter (before setting new filtersFilter) ->", filtersFilter);
        }

        if (Object.entries(filtersFilter).length > 0) {
          setFiltersFilter(filtersFilter);
          filters.filter = filtersFilter;
          setFilters(filters);
          setQueryParameters();
          setIsLoadData(true);
        } else {
          filters.filter = filtersFilter;
          setFilters(filters);
          setQueryParameters();
          setIsLoadData(true);
        }
      }
    }
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  };

  const processMultiValues = (multiValueArr) => {
    let multiValues = [];
    for (let i = 0; i < multiValueArr.length; i++) {
      if (multiValueArr[i] && multiValueArr[i].value) {
        multiValues.push(multiValueArr[i].value);
      }
    }
    return multiValues.join(",");
  };
  
  const processKey = (key, value, queryFilter, comparatorItems) => {
    switch (key) {
      case "status":
      case "subscription_status":
      case "microservice_name":
      case "subscription_line_type":
      case "tenant_id":
        let multiKeyFilters = processMultiValues(value[key]);
        queryFilter.push(`${key}=[${multiKeyFilters}]`);
        break;
      case "subscription_start":
      case "subscription_stop":
        queryFilter.push(`${key}${comparatorItems && comparatorItems[key] ? comparatorItems[key] : "<="}${value[key]}`);
        break;
      default:
        queryFilter.push(`${key}%${value[key]}`);
        break;
    }
  }


  const setQueryParameters = () => {
    // get history (default) params
    let params = {};
    const historyParamsQueryString = sessionStorage.getItem(qStrSubscriptionsLinesSummary);
    if (historyParamsQueryString) {
      // page - offset - count - sort - search
      historyParamsQueryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
        if (key === 'page' || key === 'offset' || key === 'count') {
          params[key] = parseInt(value);
        } else if (key === 'sort') {
          params[key] = value;
        } else if (key === 'search') {
          params[key] = value;
        } else if (key === 'filter') {
          params[key] = value;
        } 
      });
    }

    const savedComparators = sessionStorage.getItem(qStrSubscriptionsLinesSummaryComparatorItems);
    const savedComparatorsParsed = savedComparators ? JSON.parse(savedComparators) : null;

    let key;
    let value;
    let query = [];
    let searchFilterQuery = {};
    let filterQuery = {};
    let comparatorItems = comparatorFilter ? comparatorFilter : {};

    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - comparatorItems ->", comparatorItems);
    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - savedComparatorsParsed ->", savedComparatorsParsed);
    if (savedComparatorsParsed && !isEmpty(savedComparatorsParsed)) {
      comparatorItems = { ...comparatorFilter, ...savedComparatorsParsed };
    }
    //console.log("PlansList (table) - comparatorFilter - setQueryParameters - comparatorItems ->", comparatorItems);

    query.push(`count=1`);
    if (!filters) {
      query.push(`search=`);
      query.push(`filter=`);
    }
    query.push(`offset=${params && params.offset ? params.offset : sizePerPage ? sizePerPage : 10}`);
    query.push(`page=${params && params.page ? params.page : page ? page : 1}`);
    query.push(`sort=${params && params.sort ? params.sort : sort ? sort : ""}`);

    // remove filters from session storage
    sessionStorage.removeItem(qStrSubscriptionsLinesSummary);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryFilter);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryFilterStr);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryFiltersObj);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryComparatorItems);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryKeyword);

    //console.log("SubscriptionList (table) - comparatorFilter ->", comparatorFilter);
    //console.log("SubscriptionList (table) - comparatorFilter - setQueryParameters - savedComparatorsParsed ->", savedComparatorsParsed);

    //if (filters) {
    for (key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (key === "keyword") {
          value = filters[key];
          searchFilterQuery[key] = value && true ? value : "";
          // set search for querystring
          query.push(`search=${filters[key]}`);

        } else if (key === "filter") {
          value = filters[key];

          // set total filter
          let queryKeyFilters = "";
          let queryFilters = "";

          // if value is array - value.length > 0 - there are filters
          if (value && Array.isArray(value)) {
            if (value.length > 0) {
              let queryFilter = [];
              for (let i = 0; i < value.length; i++) {
                for (let key in value[i]) {
                  if (value[i].hasOwnProperty(key)) {
                    processKey(key, value[i], queryFilter, comparatorItems);
                  }
                }
              }
              if (queryFilter.length > 0) {
                queryKeyFilters = queryFilter.join(",");
                queryFilters = queryFilter.join("~");
              }
              searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
              filterQuery['filter'] = queryFilters ? queryFilters : "";
            }
          } else {
            let queryFilter = [];
            for (let name in value) {
              if (value.hasOwnProperty(name)) {
                processKey(name, value, queryFilter, comparatorItems);
              }
            }
            if (queryFilter.length > 0) {
              queryKeyFilters = queryFilter.join(",");
              queryFilters = queryFilter.join("~");
            }
            searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
            filterQuery['filter'] = queryFilters ? queryFilters : "";
          }
          query.push(`filter=${queryKeyFilters}`);
        }
      }
    }
    query.join("&").toString();
    setEnabledFilter(true);

    //} else {
    //  query.join("&").toString();
    //}

    const location_search_query = query;
    let location_search = location_search_query
      ? location_search_query.join("&")
      : "";

    sessionStorage.setItem(qStrSubscriptionsLinesSummary, location_search);
    sessionStorage.setItem(qStrSubscriptionsLinesSummaryFilter, searchFilterQuery['filter'] && searchFilterQuery['filter'] !== "" ? searchFilterQuery['filter'] : "");
    sessionStorage.setItem(qStrSubscriptionsLinesSummaryFilterStr, filterQuery['filter'] && filterQuery['filter'] !== "" ? filterQuery['filter'] : "");
    sessionStorage.setItem(qStrSubscriptionsLinesSummaryKeyword, searchFilterQuery['keyword'] ? searchFilterQuery['keyword'] : searchKeyword);
    sessionStorage.setItem(qStrSubscriptionsLinesSummaryFiltersObj, JSON.stringify(filters));
    sessionStorage.setItem(qStrSubscriptionsLinesSummaryComparatorItems, JSON.stringify(comparatorItems));    
  };

  useEffect(() => {
    if (clearFilters) {
      setPaginationCount(1);
      setClearFilters(false);
      setIsLoadData(true);
    }
  }, [clearFilters]);

  const handleFilterClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    sessionStorage.removeItem(qStrSubscriptionsLinesSummary);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryFilter);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryFilterStr);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryKeyword);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryFiltersObj);
    sessionStorage.removeItem(qStrSubscriptionsLinesSummaryComparatorItems);

    setDescriptionFilter([]);
    setStartDateFilter([]);
    setEndDateFilter([]);
    setTenantFilter([]);
    setSubscriptionLineStatusesFilter([]);
    setTypeFilter([]);
    setAddressFilter([]);
    setStatusFilter([]);
    setMicroserviceNameFilter([]);
    setSubscriptionStatusesFilter([]);

    setDescription("");
    setStartDate("");
    setEndDate("");
    setTenantId("");
    setStatus("");
    setSubscriptionLineType("");
    setSubscriptionLineStatus("");
    setSubscriptionStatus("");
    setAddress("");
    setMicroserviceName("");

    setPage(1);
    setPageNum(1);
    setSort("");
    setSizePerPage(100);
    setFiltersFilter({});
    setFilters({ filter: null, keyword: "" });
    setClearFilters(true);
    setPaginationCount(1);
    setEnabledFilter(false);
    setIsLoadData(true);

    setComparatorFilter({
      subscription_start: ">=", subscription_stop: "<=", price_excl_vat: ">"
    });

    setQueryParameters();

    let input = document.getElementById("table-search-form");
    input.value = '';

    sessionStorage.clear(); // This clears all data in sessionStorage
  };

  const formatLineType = (cell) => {
    if (lineTypes && cell) {
      //console.log("formatLineType - lineTypes ->", lineTypes);
      //console.log("formatLineType - cell ->", cell);

      const lineTypeItem = lineTypes.filter(i => parseInt(i.value) === parseInt(cell));
      //console.log("formatLineType - lineTypeItem ->", lineTypeItem);
      if (lineTypeItem && lineTypeItem[0].label) {
        return lineTypeItem[0].label;
      } else {
        return String.fromCharCode(8212);
      }
    } else {
      return String.fromCharCode(8212);
    }
  };

  const formatLineStatus = (cell) => {
    if (cell) {
      return <Statuses id={cell} urgentModalStatus={setUrgentModalStatus} table={tableName} />
    }
  };

  const formatTenant = (cell) => {
    if (tenantsLookUp && cell) {
      const tenant = Object.values(tenantsLookUp).find(i => parseInt(i.id) === parseInt(cell));
      //console.log("formatTenant - tenant ->", tenant);
      if (tenant) {
        return tenant.name;
      } else {
        return String.fromCharCode(8212);
      }
    } else {
      return String.fromCharCode(8212);
    }
  };

  const priceFormatter = (cell, row, rowIndex) => {
    const { id } = props;
    return <Prices id={id} rowId={row.id} t={t} lang={props.i18n.language} />
  };

  const formatCurrency = (cell, row) => {
    let line_price_excl_vat = 0;
    let line_price_incl_vat = 0;

    const { current_line_price } = row;
    if (current_line_price) {
      if (current_line_price.price_excl_vat) {
        line_price_excl_vat = current_line_price.price_excl_vat;
      }

      if (current_line_price.price_incl_vat) {
        line_price_incl_vat = current_line_price.price_incl_vat;
      }
    }

    return formatTotalVatStr(line_price_excl_vat, line_price_incl_vat);
  };

  const formatTotalVatStr = (line_price_excl_vat, line_price_incl_vat) => {
    return <span>{t("currency_format", { number: Number(line_price_excl_vat) }) + ' / ' + t("currency_format", { number: Number(line_price_incl_vat) })}</span>
  };

  const formatDate = (cell) => {
    if (cell) {
      return <span className="date-format" style={{ paddingLeft: "0px" }}>{t("date_format", { date: new Date(cell) })}</span>
    } else {
      return <span>{String.fromCharCode(8212)}</span>
    }
  };

  const StatusStyle = (cell) => {
    let subscription_status;
    return cell !== null && cell !== undefined && cell ?
      <Badge
        color={cell === 50
          ? 'success'
          : cell === 99
            ? 'danger'
            : cell === 10
              ? 'warning'
              : cell === 30
                ? 'default'
                : cell === 40
                  ? 'default'
                  : cell === 60
                    ? 'warning'
                    : cell === 80
                      ? 'default'
                      : 'info'}
        className="badge-pill mr-1 mb-0 text-uppercase">
        {cell === 50 ? subscription_status = 'ONGOING'
          : cell === 99 ? subscription_status = 'TERMINATED'
            : cell === 10 ? subscription_status = 'NEW'
              : cell === 30 ? subscription_status = 'PROVISIONING'
                : cell === 40 ? subscription_status = 'PLANNED'
                  : cell === 60 ? subscription_status = 'CANCELLED'
                    : cell === 80 ? subscription_status = 'DE-PROVISIONING'
                      : subscription_status = 'CONCEPT'}
      </Badge> : String.fromCharCode(8212)
  };

  const validFormatter = (cell, row, rowIndex) => {
    const line_prices = props.dataPrices && props.dataPrices.data && props.dataPrices.data.data ? props.dataPrices.data.data : [];
    if (line_prices && line_prices.length > 0) {
      return line_prices && line_prices.length > 0 &&
        line_prices.map(({ price_valid_from, subscription_line_id }, index) => {
          if (subscription_line_id === row.id) {
            return renderData(price_valid_from,
              index + 1 < line_prices.length, index)
          }
          return null;
        });
    } else {
      return <span>{String.fromCharCode(8212)}</span>
    }
  };

  const renderData = (data, bool, index) => {
    return <React.Fragment key={index}>
      <span className={`subscr-dates`}><span>{t("date_format", { date: new Date(data) })}</span></span>
      {bool && <br />}
    </React.Fragment>
  };

  const renderPriceData = (price_excl_vat, price_incl_vat, bool, index, row_id) => {
    const price_incl_tax = price_incl_vat ? price_incl_vat : 0;
    const price_excl_tax = price_excl_vat ? price_excl_vat : 0;

    return (
      <React.Fragment key={index}>
        <span id={`line-price-${row_id}`} className={`subscr-prices`}>
          <span className={`subscr-price-${index}`}>{t("currency_format", { number: Number(price_excl_tax) })}</span> / <span>{formatAmountLocale(price_incl_tax, props.i18n.language)}</span>
        </span>
        {bool && <br />}
      </React.Fragment>
    );
  };

  const colspanForLastRow = (cell, row, rowIndex, colIndex) => {
    return { colSpan: `2`, 'data-label': 'Price' }
  };

  const hiddenColForLastRow = (cell, row, rowIndex, colIndex) => {
    return { hidden: true, 'data-label': 'Valid from' }
  };

  const setUrgentModalStatus = (status) => {
    //console.log("Subscriptionline - status ->", status);
    // setState({ statusUrgentModalOpen: status });
    //console.log("open modal");
  }

  const getPlanSubscriptionLineTypes = () => {
    const { dispatch } = props;

    (async () => {
      //dispatch(mvLoaderToggle(true));
      await GetPlanSubscriptionLineTypes()
        .then(res => {
          if (res.ok) {
            const { data } = res.data;
            let line_types = [];
            for (let i = 0; i < data.length; i++) {
              line_types.push({
                value: data[i].id,
                label: data[i].line_type,
              });
            }
            setLineTypes(line_types);
          }
        })
        .catch(e => {
          console.log('Error', e);
        });
    })();
  }

  const getMicroservices = () => {
    (async () => {
      await GetMicroservices()
        .then(res => {
          if (res.ok) {
            const { data } = res.data;
            let microservices_name = [];
            for (let i = 0; i < data.length; i++) {
              microservices_name.push({
                value: data[i].name,
                label: data[i].name,
                //description: data[i].description,
              });
            }
            setMicroserviceNameData(microservices_name);
          }
        })
        .catch(e => {
          console.log('Error', e);
        });

    })();
  }

  const getSubscriptionLineStatuses = () => {
    (async () => {
      await GetSubscriptionLineStatuses()
        .then(res => {
          if (res.ok) {
            const { data } = res.data;
            let statuses = [];
            for (let i = 0; i < data.length; i++) {
              statuses.push({
                value: data[i],
                label: data[i],
              });
            }
            setLineStatuses(statuses);
          }
        })
        .catch(e => {
          console.log('Error', e);
        });
    })();
  }

  const getSubscriptionStatuses = () => {
    (async () => {
      await GetSubscriptionStatuses()
        .then(res => {
          if (res.ok) {
            const { data } = res.data;
            let sub_statuses = [];
            for (let i = 0; i < data.length; i++) {
              sub_statuses.push({
                value: data[i].id,
                label: data[i].status,
              });
            }
            setSubStatuses(sub_statuses);
          }
        })
        .catch(e => {
          console.log('Error', e);
        });
    })();
  }

  const getGetSubscriptionLineStatuses = () => {
    const { dispatch } = props;

    (async () => {
      await GetSubscriptionLineStatuses()
        .then(res => {
          if (res.ok) {
            const { data } = res;
            setSubscriptionLineStatuses(data.data)
          }
        })
        .catch(e => {
          console.log('Error', e);
        });

    })();
  };

  const getTenantLists = () => {
    const { dispatch } = props;

    (async () => {
      await GetTenantLists()
        .then(res => {
          if (res.ok) {
            const { data } = res.data;
            let tenantLookUp = [];
            let tenantOptsData = [];
            if (data && data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                tenantOptsData.push({
                  value: data[i].id,
                  label: data[i].name
                });
                tenantLookUp.push({
                  id: data[i].id,
                  name: data[i].name
                });
              }
            }
            // create tenant dropdown menu
            setTenantsData(tenantOptsData);
            // create tenant array for lookup
            setTenantsLookUp(tenantLookUp);
          }
        })
        .catch(e => {
          console.log('Error', e);
        });

    })();
  };

  const collapseFilterAction = (e) => {
    if (e) {
      e.preventDefault();
    }

    let filterVisible = sessionStorage.getItem(subscriptionsLinesSummaryFilterVisible);
    //console.log("SubscriptionList - filterVisible ->", filterVisible);

    let collapseStatus = toggleFilterForm === true ? "is-hidden" : "is-visible";
    setCollapseState(collapseStatus);
    setToggleFilterForm(!toggleFilterForm);

    // set to sessionStorage
    sessionStorage.setItem(subscriptionsLinesSummaryFilterVisible, collapseStatus);
  };

  useEffect(() => {    
    if (searchKeyword && searchKeyword !== "") {
      filters.filter = filtersFilter;
      filters.keyword = searchKeyword;
      setFilters(filters);

      sessionStorage.setItem(qStrSubscriptionsLinesSummaryKeyword, searchKeyword);

      setPage(1);
      setPageNum(1);
      setQueryParameters();
      setIsLoadData(true);
    }
  }, [searchKeyword]);

  useEffect(() => {    
    getPlanSubscriptionLineTypes();
    getGetSubscriptionLineStatuses();
    getTenantLists();
    getSubscriptionLineStatuses();
    getMicroservices();
    getSubscriptionStatuses();
  }, []);


  useEffect(() => {
    let tableStorageFilterObjId = qStrSubscriptionsLinesSummaryFiltersObj;

    if (clearedItemName && clearedItemName.current !== "") {
      let name = clearedItemName.current;
      let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);

      if (name && filtersObj) {
        let filtersObjParsed = JSON.parse(filtersObj);
        if (filtersObjParsed && filtersObjParsed.filter && filtersObjParsed.filter.length > 0) {
          for (let i = 0; i < filtersObjParsed.filter.length; i++) {
            if (filtersObjParsed.filter[i] && filtersObjParsed.filter[i][name]) {
              filtersObjParsed.filter.splice(i, 1);

              // save filters to session storage (stringify)
              sessionStorage.setItem(qStrSubscriptionsLinesSummaryFiltersObj, JSON.stringify(filtersObjParsed));

              if (filtersObjParsed.filter &&
                filtersObjParsed.filter.length > 0) {
                setFiltersFilter(filtersObjParsed.filter);
              } else {
                setFiltersFilter([]);
              }
              setFilters(filtersObjParsed);
              return;
            }
          }
        } else if (filtersObjParsed && filtersObjParsed.filter && filtersObjParsed.filter[name]) {
          delete filtersObjParsed.filter[name];
          // save filters to session storage (stringify)
          sessionStorage.setItem(qStrSubscriptionsLinesSummaryFiltersObj, JSON.stringify(filtersObjParsed));
        }
      }
    } else {
      setFiltersFilter({});
      setFilters({ filter: null, keyword: "" });
      sessionStorage.removeItem(tableStorageFilterObjId);
      setQueryParameters();
    }
  }, [clearedItemName]);

  useEffect(() => {
    let filterVisible = sessionStorage.getItem(subscriptionsLinesSummaryFilterVisible);
    if (filterVisible === "is-visible") {
      setToggleFilterForm(true);
      setCollapseState("is-visible");

      sessionStorage.setItem(subscriptionsLinesSummaryFilterVisible, "is-visible");
    } else if (filterVisible === "is-hidden") {
      setToggleFilterForm(false);
      setCollapseState("is-hidden");

      sessionStorage.setItem(subscriptionsLinesSummaryFilterVisible, "is-hidden");
    } else {
      setToggleFilterForm(false);
      setCollapseState("is-hidden");
      setFilters({ filter: null, keyword: "" });

      sessionStorage.setItem(subscriptionsLinesSummaryFilterVisible, "is-hidden");
    }
  }, []);

  useEffect(() => {    
    let currentParams = new URL(document.location).searchParams;
    if (currentParams.size && currentParams.size > 0) {
      let qStr = currentParams.toString();
      sessionStorage.setItem(qStrSubscriptionsLinesSummary, qStr);
      setQueryParameters();
    }
  }, []);
  

  return (
    <div className="wrapper-box-table-filter">
      <h1 className="text-bold mb-3">Provisioning dashboard</h1>
      <ParentTable
        id={"SubscriptionLinesSummaryTable"}
        keyField={"subscription_line_id"}
        table={tableName}
        language={props.i18n && props.i18n.language ? props.i18n.language : 'nl'}
        data={GetSubscriptionsLinesSummary}
        isLoadData={isLoadData}
        columns={[{
          dataField: "subscription_line_id",
          text: "#",
          formatter: formatLineStatus,
          headerStyle: () => {
            return { width: '5%', textAlign: 'center', padding: '0.75rem 0.25rem' }
          },
          attrs: {
            'data-label': 'Line Status',
          }
        }, {
          dataField: "status",
          filter: customFilter({
            type: "select",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setSubscriptionLineStatusesFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterSelect
              onFilter={onFilter}
              column={column}
              handleFiltering={(e) =>
                handleFiltering(e, "status", true)
              }
              constantObj={lineStatuses ? lineStatuses : []}
              multiSelect={true}
              clearOnEmpty={handleFilterClick}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              value={subscriptionLineStatus ? subscriptionLineStatus : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table={tableName}
            />
          ),
          text: "Line status",
          sort: true,
          title: true,
          headerStyle: { width: '8%' },
          attrs: {
            'data-label': 'Line Status',
          }
        }, {
          dataField: "tenant_id",
          filter: customFilter({
            type: "select",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setTenantFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterSelect
              onFilter={onFilter}
              column={column}
              handleFiltering={(e) =>
                handleFiltering(e, "tenant_id", true)
              }
              constantObj={tenantsData ? tenantsData : []}
              multiSelect={true}
              clearOnEmpty={handleFilterClick}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              value={tenantId ? tenantId : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table={tableName}
            />
          ),
          text: "Tenant",
          sort: true,
          title: true,
          formatter: formatTenant,
          headerStyle: { width: '8%' },
          attrs: {
            'data-label': 'Tenant',
          }
        }, {
          dataField: "microservice_name",
          filter: customFilter({
            type: "select",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setMicroserviceNameFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterSelect
              onFilter={onFilter}
              column={column}
              handleFiltering={(e) =>
                handleFiltering(e, "microservice_name", true)
              }
              constantObj={microserviceNameData ? microserviceNameData : []}
              multiSelect={true}
              clearOnEmpty={handleFilterClick}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              value={microserviceName ? microserviceName : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table={tableName}
            />
          ),
          text: "Microservice name",
          sort: true,
          title: true,
          headerStyle: { width: '8%' },
          attrs: {
            'data-label': 'Microservice Name',
          }
        }, {
          dataField: "description",
          filter: customFilter({
            type: "text",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setDescriptionFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterInput
              onFilter={onFilter}
              column={column}
              handleFiltering={handleFiltering}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              value={description ? description : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table={tableName}
            />
          ),
          title: true,
          text: "Description",
          sort: true,
          headerStyle: { width: '18%' },
          attrs: {
            'data-label': 'Description',
          }
        }, {
          dataField: "subscription_line_type",
          filter: customFilter({
            type: "select",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setTypeFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterSelect
              onFilter={onFilter}
              column={column}
              handleFiltering={(e) =>
                handleFiltering(e, "subscription_line_type", true)
              }
              constantObj={lineTypes ? lineTypes : []}
              multiSelect={true}
              clearOnEmpty={handleFilterClick}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              value={subscriptionLineType ? subscriptionLineType : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table={tableName}
            />
          ),
          text: "Type",
          sort: true,
          formatter: formatLineType,
          headerStyle: { width: '8%' },
          attrs: {
            'data-label': 'Type',
          }
        }, {
          dataField: "subscription_start",
          filter: customFilter({
            type: "date",
            style: { display: "inline-grid" },
            defaultValue: "", // default value
            getFilter: (filter) => {
              startDateFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterDateComp
              onFilter={onFilter}
              column={column}
              handleFiltering={handleFiltering}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              icon="right"
              value={startDate ? startDate : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              setComparatorFilter={setComparatorFilter}
              comparatorFilter={comparatorFilter}
              defaultComparator={">="}
              table={tableName}
            />
          ),
          title: true,
          text: "Start",
          sort: true,
          formatter: formatDate,
          headerStyle: () => {
            return { width: '8%', 'textAlign': 'left' }
          },
          attrs: {
            'data-label': 'Start',
          }
        }, {
          dataField: "subscription_stop",
          filter: customFilter({
            type: "date",
            style: { display: "inline-grid" },
            defaultValue: "", // default value
            getFilter: (filter) => {
              setEndDateFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterDateComp
              onFilter={onFilter}
              column={column}
              handleFiltering={handleFiltering}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              icon="left"
              value={endDate ? endDate : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              setComparatorFilter={setComparatorFilter}
              comparatorFilter={comparatorFilter}
              defaultComparator={"<="}
              table={tableName}
            />
          ),
          title: true,
          text: "End",
          sort: true,
          formatter: formatDate,
          headerStyle: () => {
            return { width: '8%', 'textAlign': 'left' }
          },
          attrs: {
            'data-label': 'End',
          }
        }, {
          dataField: "full_address",
          filter: customFilter({
            type: "text",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setAddressFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterInput
              onFilter={onFilter}
              column={column}
              handleFiltering={handleFiltering}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              value={address ? address : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table={tableName}
            />
          ),
          title: true,
          text: "Address",
          sort: true,
          headerStyle: { width: '18%' },
          attrs: {
            'data-label': 'Address',
          }
        }, {
          dataField: "subscription_status",
          filter: customFilter({
            type: "select",
            style: { display: "inline-grid" },
            getFilter: (filter) => {
              setSubscriptionStatusesFilter(filter);
            },
          }),
          filterRenderer: (onFilter, column) => (
            <CustomFilterSelect
              onFilter={onFilter}
              column={column}
              handleFiltering={(e) =>
                handleFiltering(e, "subscription_status", true)
              }
              constantObj={subStatuses ? subStatuses : []}
              multiSelect={true}
              clearOnEmpty={handleFilterClick}
              isClearFilter={clearFilters}
              setClearedItemName={(value) => clearedItemName.current = value}
              value={subscriptionStatus ? subscriptionStatus : ""}
              enabledFilter={enabledFilter}
              setEnabledFilter={setEnabledFilter}
              table={tableName}
            />
          ),
          text: "Sub status",
          sort: true,
          title: true,
          align: "center",
          formatter: StatusStyle,
          headerStyle: { width: '8%' },
          attrs: {
            'data-label': 'Sub Status ',
          }
        }
        ]}
        headerDropdownOpts={[
          {
            label: "Filter",
            action: "collapseFilterAction",
            icon: <FontAwesomeIcon icon={faFilter} size={`sm`} className={`mr-0`} color={`grey`} />,
            isCustom: true,
            tooltip: true,
            toggleActive: toggleFilterForm ? "active" : ""
          },
          {
            label: "Clear",
            action: "handleFilterClick",
            icon: <FontAwesomeIcon icon={faTrash} size={`sm`} className={`mr-0`} color={`grey`} />,
            isCustom: true,
            tooltip: true
          }
        ]}
        asyncRowDropdownOpts={!isDisabled && [{
          get: GetGadgets,
          //condition: {
          //  data: 'microservice_name'
          //}
        }]}
        //rowAction={statusUrgentModalOpen || (!isDisabled && 'edit')}
        // rowAction={statusUrgentModalOpen}
        //rowAction="link"
        rowAction="link"
        //rowActionForm={!isDisabled && SubscriptionLineForm}
        // rowActionForm={false}
        striped={true}
        handleFilterClick={handleFilterClick}
        collapseFilterAction={collapseFilterAction}
        filters={filters}
        cacheSearch={true}
        noSearch={false}
        collapseFilter={collapseState}
        isClearFilter={clearFilters}
        noTopBorder={true}
        wrapperClasses="table-subscription-lines-summary f2x-custom-table table-filter-search-wrapper"
        classes="table-responsive table-filter-collapse filters mb-0 subscriptions-lines-summary-table filter-tbl-res"
        //dataLoaded={dataLoaded}
        notLoadedByDefault={false}
        noDataIndication="No subscriptions lines summary found."
        //isRawData
        //updateTableRawData={updateSubLineDataTable}
        noHover={false}
        //offLoader
        remote
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
        filterSearch={true}
        page={page}
        bodyClasses="table-filter-body-collapse"
        setIsLoadData={setIsLoadData}
        enabledFilter={enabledFilter}
        setSearchKeyword={setSearchKeyword}
      />
    </div>
  );
}

export default withRouter(connect()(SubscriptionLines));
