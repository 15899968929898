import React from 'react';
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import {createBrowserHistory, createMemoryHistory, createHashHistory} from 'history';
import {
  dashboard as dashboardRoutes,
  page as pageRoutes
} from "./index";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import ScrollToTop from "../components/ScrollToTop";

const ChildRoutes = ({ layout: Layout, routes }) => {
  return (
      <Layout>
        <Switch>
          {routes.map((category, index) => category.children ? (
              category.children.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  to={route.path}
                  exact
                  component={route.component}
                  access={route.group_access_id ? route.group_access_id : false}
                  replace={route.path === window.location.hash}
                />
              ))) : (
                <Route
                  key={index}
                  path={category.path}
                  to={category.path}
                  exact
                  component={category.component}
                  access={category.group_access_id ? category.group_access_id : false}
                  replace={category.path === window.location.hash}
                />
              ))}

        </Switch>
      </Layout>);
};

const browserHistory = createBrowserHistory({ forceRefresh: true });

const Routes = ({to, location}) => {
  const history = useHistory();

  return (
      <React.Fragment>
        <Router history={browserHistory}>
          <ScrollToTop>
            <Switch>
              <Route
                  path="/auth/*"
                  exact
                  strict
                  replace
                  component={() => (<ChildRoutes layout={AuthLayout} routes={pageRoutes}/>)}
              />
              <Route
                  path="/*"
                  exact
                  replace
                  component={() => (<ChildRoutes layout={DashboardLayout} routes={dashboardRoutes}/>)}
              />
            </Switch>
          </ScrollToTop>
        </Router>
      </React.Fragment>
  );
};

export default Routes;
