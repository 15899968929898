import React, {useState} from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import {
  GetProduct,
  AddProduct,
  UpdateProduct,
  GetProductTypeList,
  GetProductsBackendAPIs,
  GetProducts,
  GetProductHierarchy,
  GetProductHierarchyRelationTypes,
  AddProductHierarchy,
  UpdateProductHierarchy,
  RemoveProductHierarchy,
} from '../../../controllers/products';
import {OverlayTrigger, Tooltip, Dropdown} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { Collapse } from "react-bootstrap";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import {PlusSquare, MinusSquare, MoreHorizontal, Trash2} from 'react-feather';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select';
import Swal from "sweetalert2";
import ReactJson from 'react-json-view';

const TreeListRelatedProducts = ({ ...props }) => {
  const {dispatch, loader, productHierarchy, hide, show} = props;

  const jsonViewSelect = (data) => {
    console.log("jsonViewSelect - select data ->", data);
  };

  const jsonViewAdd = (data) => {
    console.log("jsonViewAdd - add data ->", data);
  };

  const jsonViewEdit = (data) => {
    console.log("jsonViewEdit - edit data ->", data);
  };

  const jsonViewDelete = (data) => {
    console.log("jsonViewDelete - delete data ->", data);
  };

  return (
    <div className="mt-4 mb-0 tab-json-data">
      <ul className="px-2 tree-view-data">
        <ReactJson
          src={productHierarchy}
          enableClipboard={false}
          style={{ fontFamily: "inherit", backgroundColor: "transparent" }}
        />
      </ul>
      <div className="border-wide"> </div>
      <div className="form-row w-100 modal-like-btns justify-content-between">
        <span className="btn btn-danger" onClick={hide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    </div>
  );

};

export default TreeListRelatedProducts;