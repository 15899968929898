import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import defaultImage from '../../assets/default/default.jpg';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import {registerLocale} from 'react-datepicker';
import {CustomInput, Form, Input, Label} from 'reactstrap';
import {useTranslation, withTranslation} from 'react-i18next';
import {CreatePlanOfferOrder, CreatePublicPlanOfferOrder} from '../../controllers/plans';
import {enGB, enUS, nl} from 'date-fns/locale';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RadioElement from './radio';
import SelectElement from './select';
import OfferPersonal from '../../components/offer/Personal';
import Carousel from '../../components/Carousel';
import OfferBank from '../../components/offer/Bank';
import Overview from '../../components/offer/Overview';
import {faArrowCircleLeft, faHome, faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {
  validateDate, validateEmail, validateIBAN,
} from '../../redux/actions/validateActions';
import Toast from '../Toast';
import {loaderToggle} from '../../redux/actions/loaderActions';
import {DownloadSingleTermsConditionsAttachment} from '../../controllers/terms_conditions';
import {getActiveElement} from 'formik';
import ToastMulti from '../ToastMulti';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const titleOpts = [
  { value: "Dhr", label: "Dhr" },
  { value: "Mevr", label: "Mevr" },
  { value: "Dhr/Mevr", label: "Dhr/Mevr" },
];

const lineTypeOpts = [
  { line_type: "nrc", line_type_text: "Eenmalig", line_type_id: 2 },
  { line_type: "mrc", line_type_text: "Per maand", line_type_id: 3 },
  { line_type: "qrc", line_type_text: "Per kwartaal", line_type_id: 4 },
  { line_type: "yrc", line_type_text: "Per jaar", line_type_id: 5 },
  { line_type: "deposit", line_type_text: "Deposit", line_type_id: 6 },
];

const HtmlOffers = ({...props}) => {
  const {
    planData,
    setResIsUpdated,
    resIsUpdated,
    setAvResponse,
    setResUpdate,
    defaultCountry,
    countryOpts,
    cityOpts,
    dispatch,
    loader,
    setActiveStep,
    apiKey,
    isBusiness,
    inputAddressDetails,
    checkOtherAddressDisabled,
    clientDomain,
    clientInlineStyles,
  } = props;

  const [debugInfo, setDebugInfo] = useState(false);
  const [htmlArr, setHtmlArr] = useState(null);
  const [mandatoryFields, setMandatoryFields] = useState({});
  const [show, setShow] = useState(4); /* carousel default 4 items */
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [planDataChoice, setPlanDataChoice] = useState(null);
  const [stepFormData, setStepFormData] = useState({
    subscription: {},
    relation: {},
    bank_account: {},
    location: {},
    offer: {},
    terms_conditions: {},
  });
  const [subscription, setSubscription] = useState({});
  const [connection, setConnection] = useState({});
  const [formattedAddress, setFormattedAddress] = useState(false);
  const [addressStr, setAddressStr] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [zipCodeStr, setZipCodeStr] = useState(null);
  const [houseNumberStr, setHouseNumberStr] = useState(null);
  const [houseNumberLetterStr, setHouseNumberLetterStr] = useState(null);
  const [houseNumberSuffixStr, setHouseNumberSuffixStr] = useState(null);
  const [streetStr, setStreetStr] = useState(null);
  const [wishDate, setWishDate] = useState(null);
  const [contractPeriod, setContractPeriod] = useState(null);
  const [buttonGroupClass, setButtonGroupClass] = useState("button-collapse-action");
  const [buttonGroupsState, setButtonGroupsState] = useState({}); // state for plan line groups button
  const [inLineStyleVisible, setInLineStyleVisible] = useState(false);
  const [dependencies, setDependencies] = useState({}); // state for visibilities => sets visibilty for container_group_id - when target has class hidden || not-hidden
  const [additionalCosts, setAdditionalCosts] = useState(0); // non recurring costs (eenmalige kosten)
  const [defaultPriceTotal, setDefaultPriceTotal] = useState({});
  const [additionalCostsData, setAdditionalCostsData] = useState({}); // non recurring costs for each item (eenmalige kosten)
  const [pricesIncludedVat, setPricesIncludedVat] = useState(!isBusiness); // will be set in formSnippet as a variable
  const [pricesIncludedVatStr, setPricesIncludedVatStr] = useState(isBusiness ? "price_excl" : "price_incl"); // price_incl || price_excl
  const [stepForm, setStepForm] = useState(1);
  const [stepFormDataValidated, setStepFormDataValidated] = useState(false);
  const [stepFormButtonText, setStepFormButtonText] = useState("Ja, dit wil ik"); // Bestellen | Ja, dit wil ik

  const buttonContainerEl = useRef(null);
  const {t} = useTranslation();

  const summaryLineTypeOpts = (type_id = null) => {
    let LineTypeOpts = [];

    LineTypeOpts[0] = [];
    LineTypeOpts[0].visible_key_arr = 0;
    LineTypeOpts[0].line_type = "mrc";
    LineTypeOpts[0].line_type_text = "Per maand";
    LineTypeOpts[0].line_type_id = 3;
    LineTypeOpts[0].title = "Abonnement";
    LineTypeOpts[0].title_lang = t("offers.overview_header_mrc", null);
    LineTypeOpts[0].summary_total_title = t("offers.overview_monthly", null);
    LineTypeOpts[0].period = t("offers.subscription_period_short", null);

    if (type_id && type_id === 3) {
      return LineTypeOpts[0].line_type;
    }

    LineTypeOpts[1] = [];
    LineTypeOpts[1].visible_key_arr = 1;
    LineTypeOpts[1].line_type = "nrc";
    LineTypeOpts[1].line_type_text = "Eenmalig";
    LineTypeOpts[1].line_type_id = 2;
    LineTypeOpts[1].title = "Eenmalig";
    LineTypeOpts[1].title_lang = t("offers.overview_header_nrc", null);
    LineTypeOpts[1].summary_total_title = t("offers.option_txt_nrc", null);
    LineTypeOpts[1].period = null;

    if (type_id && type_id === 2) {
      return LineTypeOpts[1].line_type;
    }

    LineTypeOpts[2] = [];
    LineTypeOpts[2].visible_key_arr = 2;
    LineTypeOpts[2].line_type = "deposit";
    LineTypeOpts[2].line_type_text = "Deposit";
    LineTypeOpts[2].line_type_id = 6;
    LineTypeOpts[2].title = "Deposit";
    LineTypeOpts[2].title_lang = t("offers.overview_header_deposit", null);
    LineTypeOpts[2].summary_total_title = t("offers.overview_deposit", null);
    LineTypeOpts[2].period = null;

    if (type_id && type_id === 6) {
      return LineTypeOpts[2].line_type;
    }

    return LineTypeOpts;
  };

  const checkGroupVisibility = (groups , line_type_id) => {
    let isVisible = [];

    if (groups && groups.length > 0 && line_type_id) {
      isVisible[0] = []; // mrc
      isVisible[1] = []; // nrc
      isVisible[2] = []; // deposit

      // check mandatory plan lines in group
      Object.values(groups).map((group) => {
        let system_group_description = group.description ? group.description.replace(/ /g,"_").toLowerCase() : "no-description";

        group.plan_lines.map((plan_line, i) => {
          if (plan_line.line_type && plan_line.line_type === 3) {
            isVisible[0].push({
              plan_line_id: plan_line.id,
              plan_line_description: plan_line.description,
              plan_line_mandatory: plan_line.mandatory,
              visible: true
            });
          }
          if (plan_line.line_type && plan_line.line_type === 2) {
            if (stepFormData.subscription && Object.keys(stepFormData.subscription).length > 0) {
              const subscriptionObj = stepFormData.subscription;
              let key;
              for (key in subscriptionObj) {
                if (key === system_group_description) {
                  isVisible[1].push({
                    plan_line_id: plan_line.id,
                    plan_line_description: plan_line.description,
                    plan_line_mandatory: plan_line.mandatory,
                    visible: true
                  });
                }
              }
            }
          }
          if (plan_line.line_type && plan_line.line_type === 6) {
            if (stepFormData.subscription && Object.keys(stepFormData.subscription).length > 0) {
              const subscriptionObj = stepFormData.subscription;
              let key;
              for (key in subscriptionObj) {
                if (key === system_group_description) {
                  isVisible[2].push({
                    plan_line_id: plan_line.id,
                    plan_line_description: plan_line.description,
                    plan_line_mandatory: plan_line.mandatory,
                    visible: true
                  });
                }
              }
            }
          }
        });
      });
    }
    return isVisible;
  };

  const addDefaultCosts = (name, dataPriceTotal, type) => {
    const lineTypeData = formatPlanLineType(dataPriceTotal.line_type, false);
    let dataPriceTotalByType = [];

    if (name && dataPriceTotal) {
      let stepFormDataObj = Object.assign({}, stepFormData);

      let dataArr = [];
      dataPriceTotalByType[lineTypeData.line_type] = dataPriceTotal;

      if (!additionalCostsData[name]) {
        dataArr.push(dataPriceTotal.line_id);

        let additionalCostsDataObj = Object.assign({}, additionalCostsData);
        additionalCostsDataObj[name] = dataPriceTotalByType;
        setAdditionalCostsData(additionalCostsDataObj);
        subscription[name] = dataArr;
        stepFormDataObj.subscription = subscription;
        setStepFormData(stepFormDataObj);

      } else {
        if (subscription[name] && type === "multi") {
          if (subscription && subscription[name] && subscription[name].length > 0) {
            if (!subscription[name].includes(dataPriceTotal.line_id)) {
              dataArr = [...subscription[name], dataPriceTotal.line_id]; // set just the plan_line id instead of the whole object -> lineData

              let additionalCostsDataObj = Object.assign({}, additionalCostsData[name]);
              if (!additionalCostsDataObj[lineTypeData.line_type]) {
                additionalCostsDataObj[lineTypeData.line_type] = dataPriceTotalByType[lineTypeData.line_type];

              } else {
                let add_price_incl = dataPriceTotal.total_price_incl ? parseFloat(dataPriceTotalByType[lineTypeData.line_type].total_price_incl) : 0;
                additionalCostsDataObj[lineTypeData.line_type].total_price_incl += add_price_incl;
                let add_price_excl = dataPriceTotal.total_price_excl ? parseFloat(dataPriceTotalByType[lineTypeData.line_type].total_price_excl) : 0;
                additionalCostsDataObj[lineTypeData.line_type].total_price_excl += add_price_excl;

              }

              additionalCostsData[name] = additionalCostsDataObj;
              setAdditionalCostsData(additionalCostsData);
              subscription[name] = dataArr;
              stepFormDataObj.subscription = subscription;
              setStepFormData(stepFormDataObj);
            }
          }
        }
      }
    }
  };

  const formatOfferDescription = (html) => {
    if (html) {
      const imgTags = html.match(/<img [^>]*src="[^"]*"[^>]*>/gm);

      if (imgTags) {
        return (<div className="description"
                     dangerouslySetInnerHTML={{__html: html}} />);

      } else {
        const headerTags = html.match(/(<h[0-9]>.*?<\/h[0-9]>)+/);
        if (headerTags) {
          return (<div className="description"
                       dangerouslySetInnerHTML={{__html: html}} />);

        } else {
          const strippedString = html.replace(/(<([^>]+)>)/gi, "");
          return (<div className="description">
                    <p className="default-no-image-container">
                      <img src={defaultImage} className="default-image"
                           alt="default image"/>
                    </p>
                    <h2>{strippedString}</h2>
                  </div>)

        }
      }
    }
    return "";
  };

  const formatAddress = (addressObj) => {
    let address = [];
    let strHouseNumber =
        addressObj["house_letter"] && addressObj["house_number_suffix"]
            ? addressObj["house_number"] + addressObj["house_letter"] + addressObj["house_number_suffix"]
            : addressObj["house_letter"]
            ? addressObj["house_number"] + addressObj["house_letter"]
            : addressObj["house_number"];

    let city_name = "";
    if (addressObj["city"] && addressObj["city"]["name"]) {
      city_name = addressObj["city"]["name"];
    }

    let country_name = "";
    if (addressObj["country"] && addressObj["country"]["name"]) {
      country_name = addressObj["country"]["name"];
    }

    address.push({
      addressStr: addressObj["street1"] + " " + strHouseNumber + ", " + addressObj["zipcode"] + " " + city_name,
      streetStr: addressObj["street1"],
      houseNumberStr: addressObj["house_number"],
      houseNumberLetterStr: addressObj["house_letter"],
      houseNumberSuffixStr: addressObj["house_number_suffix"],
      zipCodeStr: addressObj["zipcode"],
      city_id: addressObj["city_id"],
      city_name: city_name,
      country_id: addressObj["country_id"],
      country_name: country_name,
    });
    return address;
  };

  /*
    Total default costs by type
   */
  const formatTotalPlanLineDefaultCosts = (group, plan_line_type) => {
    let floatCellExclVat = 0;
    let floatCellInclVat = 0;

    if (group && group.type === "none" && group.plan_lines && group.plan_lines.length > 0) {
      group.plan_lines.map((plan_line) => {
        if (plan_line.line_type === plan_line_type /*&& plan_line["price_excl"] && parseFloat(plan_line["price_excl"]) >= 0 && plan_line["price_incl"] && parseFloat(plan_line["price_incl"]) >= 0*/) {
          floatCellInclVat = parseFloat(plan_line["price_incl"]);
          floatCellExclVat = parseFloat(plan_line["price_excl"]);
        }
      });
    } else if (group && group.type === "none" && group.items && group.items.length > 0) {
      group.items.map((plan_line) => {
        if (plan_line.line_type === plan_line_type /*&& plan_line["price_excl"] && parseFloat(plan_line["price_excl"]) >= 0 && plan_line["price_incl"] && parseFloat(plan_line["price_incl"]) >= 0*/) {
          floatCellInclVat = parseFloat(plan_line["price_incl_vat"]);
          floatCellExclVat = parseFloat(plan_line["price_excl_vat"]);
        }
      });
    }

    if (pricesIncludedVat) {
      return parseFloat(floatCellInclVat);
    } else {
      return parseFloat(floatCellExclVat);
    }
  };

  const formatTotalPlanLineCurrency = (type) => {
    console.log("formatTotalPlanLineCurrency - type ->", type);

    let totalCostsDataArr = {};
    totalCostsDataArr[type] = {
      price_total_excl_vat: 0,
      price_total_incl_vat: 0,
      line_type: null,
      line_type_id: null,
    };

    switch (type) {
      case 2:
        if (Object.keys(additionalCostsData).length > 0) {
          let key;
          for (key in additionalCostsData) {

            if (additionalCostsData[key] &&
                additionalCostsData[key].total_price_excl &&
                additionalCostsData[key].total_price_incl &&
                additionalCostsData[key].line_type === type)
            {
              totalCostsDataArr[type].price_total_excl_vat += additionalCostsData[key].total_price_excl;
              totalCostsDataArr[type].price_total_incl_vat += additionalCostsData[key].total_price_incl;
              totalCostsDataArr[type].line_type = "nrc";
              totalCostsDataArr[type].line_type_id = 2;

            } else if (typeof additionalCostsData[key] === 'object') {

              let child_type;
              let child_key;
              let childAdditionalCostsData = additionalCostsData[key];
              for (child_key in childAdditionalCostsData) {

                if (childAdditionalCostsData.hasOwnProperty(child_key)) {

                  if (childAdditionalCostsData[child_key] &&
                      childAdditionalCostsData[child_key].total_price_excl &&
                      childAdditionalCostsData[child_key].total_price_incl &&
                      childAdditionalCostsData[child_key].line_type === type) {

                    totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key].total_price_excl;
                    totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key].total_price_incl;
                    totalCostsDataArr[type].line_type = "nrc";
                    totalCostsDataArr[type].line_type_id = 2;

                  } else if (typeof childAdditionalCostsData[child_key] === 'object') {

                    let sub_child_key;
                    for (sub_child_key in childAdditionalCostsData[child_key]) {

                      if (childAdditionalCostsData[child_key].hasOwnProperty(sub_child_key) &&
                          childAdditionalCostsData[child_key][sub_child_key] &&
                          childAdditionalCostsData[child_key][sub_child_key].total_price_excl &&
                          childAdditionalCostsData[child_key][sub_child_key].total_price_incl &&
                          childAdditionalCostsData[child_key][sub_child_key].line_type ===
                          type) {

                        totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key][sub_child_key].total_price_excl;
                        totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key][sub_child_key].total_price_incl;
                        totalCostsDataArr[type].line_type = "nrc";
                        totalCostsDataArr[type].line_type_id = 2;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        break;

      case 3:
        if (Object.keys(additionalCostsData).length > 0) {
          let key;
          for (key in additionalCostsData) {

            if (additionalCostsData[key] &&
                additionalCostsData[key].total_price_excl &&
                additionalCostsData[key].total_price_incl &&
                additionalCostsData[key].line_type === type)
            {
              totalCostsDataArr[type].price_total_excl_vat += additionalCostsData[key].total_price_excl;
              totalCostsDataArr[type].price_total_incl_vat += additionalCostsData[key].total_price_incl;
              totalCostsDataArr[type].line_type = "mrc";
              totalCostsDataArr[type].line_type_id = 3;

            } else if (typeof additionalCostsData[key] === 'object') {

              let child_key;
              let childAdditionalCostsData = additionalCostsData[key];
              for (child_key in childAdditionalCostsData) {

                if (childAdditionalCostsData.hasOwnProperty(child_key)) {
                  if (childAdditionalCostsData[child_key] &&
                      childAdditionalCostsData[child_key].total_price_excl &&
                      childAdditionalCostsData[child_key].total_price_incl &&
                      childAdditionalCostsData[child_key].line_type === type) {
                    totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key].total_price_excl;
                    totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key].total_price_incl;
                    totalCostsDataArr[type].line_type = "mrc";
                    totalCostsDataArr[type].line_type_id = 3;

                  } else if (typeof childAdditionalCostsData[child_key] === 'object') {

                    let sub_child_key;
                    for (sub_child_key in childAdditionalCostsData[child_key]) {
                      if (childAdditionalCostsData[child_key].hasOwnProperty(sub_child_key) &&
                          childAdditionalCostsData[child_key][sub_child_key] &&
                          childAdditionalCostsData[child_key][sub_child_key].total_price_excl &&
                          childAdditionalCostsData[child_key][sub_child_key].total_price_incl &&
                          childAdditionalCostsData[child_key][sub_child_key].line_type ===
                          type) {

                        totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key][sub_child_key].total_price_excl;
                        totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key][sub_child_key].total_price_incl;
                        totalCostsDataArr[type].line_type = "nrc";
                        totalCostsDataArr[type].line_type_id = 3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        break;

      case 4:
        if (Object.keys(additionalCostsData).length > 0) {
          let key;
          for (key in additionalCostsData) {
            if (additionalCostsData[key] &&
                additionalCostsData[key].total_price_excl &&
                additionalCostsData[key].total_price_incl &&
                additionalCostsData[key].line_type === type)
            {
              totalCostsDataArr[type].price_total_excl_vat += additionalCostsData[key].total_price_excl;
              totalCostsDataArr[type].price_total_incl_vat += additionalCostsData[key].total_price_incl;
              totalCostsDataArr[type].line_type = "qrc";
              totalCostsDataArr[type].line_type_id = 4;
            } else if (typeof additionalCostsData[key] === 'object') {
              let child_key;
              let childAdditionalCostsData = additionalCostsData[key];
              for (child_key in childAdditionalCostsData) {
                if (childAdditionalCostsData.hasOwnProperty(child_key)) {
                  if (childAdditionalCostsData[child_key] &&
                      childAdditionalCostsData[child_key].total_price_excl &&
                      childAdditionalCostsData[child_key].total_price_incl &&
                      childAdditionalCostsData[child_key].line_type === type) {
                    totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key].total_price_excl;
                    totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key].total_price_incl;
                    totalCostsDataArr[type].line_type = "qrc";
                    totalCostsDataArr[type].line_type_id = 4;
                  } else if (typeof childAdditionalCostsData[child_key] === 'object') {

                    let sub_child_key;
                    for (sub_child_key in childAdditionalCostsData[child_key]) {

                      if (childAdditionalCostsData[child_key].hasOwnProperty(sub_child_key) &&
                          childAdditionalCostsData[child_key][sub_child_key] &&
                          childAdditionalCostsData[child_key][sub_child_key].total_price_excl &&
                          childAdditionalCostsData[child_key][sub_child_key].total_price_incl &&
                          childAdditionalCostsData[child_key][sub_child_key].line_type ===
                          type) {

                        totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key][sub_child_key].total_price_excl;
                        totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key][sub_child_key].total_price_incl;
                        totalCostsDataArr[type].line_type = "qrc";
                        totalCostsDataArr[type].line_type_id = 4;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        break;

      case 5:
        if (Object.keys(additionalCostsData).length > 0) {
          let key;
          for (key in additionalCostsData) {
            if (additionalCostsData[key] &&
                additionalCostsData[key].total_price_excl &&
                additionalCostsData[key].total_price_incl &&
                additionalCostsData[key].line_type === type)
            {
              totalCostsDataArr[type].price_total_excl_vat += additionalCostsData[key].total_price_excl;
              totalCostsDataArr[type].price_total_incl_vat += additionalCostsData[key].total_price_incl;
              totalCostsDataArr[type].line_type = "yrc";
              totalCostsDataArr[type].line_type_id = 5;
            } else if (typeof additionalCostsData[key] === 'object') {
              let child_key;
              let childAdditionalCostsData = additionalCostsData[key];
              for (child_key in childAdditionalCostsData) {
                if (childAdditionalCostsData.hasOwnProperty(child_key)) {
                  if (childAdditionalCostsData[child_key] &&
                      childAdditionalCostsData[child_key].total_price_excl &&
                      childAdditionalCostsData[child_key].total_price_incl &&
                      childAdditionalCostsData[child_key].line_type === type) {
                    totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key].total_price_excl;
                    totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key].total_price_incl;
                    totalCostsDataArr[type].line_type = "yrc";
                    totalCostsDataArr[type].line_type_id = 5;
                  } else if (typeof childAdditionalCostsData[child_key] === 'object') {

                    let sub_child_key;
                    for (sub_child_key in childAdditionalCostsData[child_key]) {

                      if (childAdditionalCostsData[child_key].hasOwnProperty(sub_child_key) &&
                          childAdditionalCostsData[child_key][sub_child_key] &&
                          childAdditionalCostsData[child_key][sub_child_key].total_price_excl &&
                          childAdditionalCostsData[child_key][sub_child_key].total_price_incl &&
                          childAdditionalCostsData[child_key][sub_child_key].line_type ===
                          type) {

                        totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key][sub_child_key].total_price_excl;
                        totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key][sub_child_key].total_price_incl;
                        totalCostsDataArr[type].line_type = "yrc";
                        totalCostsDataArr[type].line_type_id = 5;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        break;

      case 6:
        if (Object.keys(additionalCostsData).length > 0) {
          let key;
          for (key in additionalCostsData) {
            if (additionalCostsData[key] &&
                additionalCostsData[key].total_price_excl &&
                additionalCostsData[key].total_price_incl &&
                additionalCostsData[key].line_type === type)
            {
              totalCostsDataArr[type].price_total_excl_vat += additionalCostsData[key].total_price_excl;
              totalCostsDataArr[type].price_total_incl_vat += additionalCostsData[key].total_price_incl;
              totalCostsDataArr[type].line_type = "deposit";
              totalCostsDataArr[type].line_type_id = 6;
            } else if (typeof additionalCostsData[key] === 'object') {
              let child_key;
              let childAdditionalCostsData = additionalCostsData[key];
              for (child_key in childAdditionalCostsData) {
                if (childAdditionalCostsData.hasOwnProperty(child_key)) {
                  if (childAdditionalCostsData[child_key] &&
                      childAdditionalCostsData[child_key].total_price_excl &&
                      childAdditionalCostsData[child_key].total_price_incl &&
                      childAdditionalCostsData[child_key].line_type === type) {
                    totalCostsDataArr[type].price_total_excl_vat += childAdditionalCostsData[child_key].total_price_excl;
                    totalCostsDataArr[type].price_total_incl_vat += childAdditionalCostsData[child_key].total_price_incl;
                    totalCostsDataArr[type].line_type = "deposit";
                    totalCostsDataArr[type].line_type_id = 6;
                  }
                }
              }
            }
          }
        }
        break;

      default:
        totalCostsDataArr = {};
        break;
    }

    let floatCell = 0;
    if (totalCostsDataArr && totalCostsDataArr[type] && totalCostsDataArr[type].price_total_excl_vat && totalCostsDataArr[type].price_total_incl_vat) {
      if (pricesIncludedVat) {
        floatCell = parseFloat(totalCostsDataArr[type].price_total_incl_vat);
      } else {
        floatCell = parseFloat(totalCostsDataArr[type].price_total_excl_vat);
      }
    }
    return t("currency_format", { number: Number(floatCell) });
  };

  const formatPlanLineType = (lineTypeId, returnLineType = "txt") => {
    if (lineTypeId) {
      let lineTypeData = {};

      switch (lineTypeId) {
        case 2:
          lineTypeData.line_type = "nrc";
          lineTypeData.line_type_text = "Eenmalig";
          lineTypeData.line_type_id = 2;
          break;

        case 3:
          lineTypeData.line_type = "mrc";
          lineTypeData.line_type_text = "Per maand";
          lineTypeData.line_type_id = 3;
          break;

        case 4:
          lineTypeData.line_type = "qrc";
          lineTypeData.line_type_text = "Per kwartaal";
          lineTypeData.line_type_id = 4;
          break;

        case 5:
          lineTypeData.line_type = "yrc";
          lineTypeData.line_type_text = "Per jaar";
          lineTypeData.line_type_id = 5;
          break;

        case 6:
          lineTypeData.line_type = "deposit";
          lineTypeData.line_type_text = "Deposit";
          lineTypeData.line_type_id = 6;
          break;

        default:
          lineTypeData.line_type = null;
          lineTypeData.line_type_text = null;
          lineTypeData.line_type_id = null;
          break;
      }

      if (returnLineType === "txt") {
        return lineTypeData.line_type_text;
      } else if (returnLineType === "key") {
        return lineTypeData.line_type;
      } else {
        return lineTypeData;
      }
    }
    return null;
  };

  const formatTotalPlanLineTypeSummary = (group, plan_line_type) => {
    let groupItemIsMandatory;
    let groupDataArr = {};
    let groupDataArrItems = [];
    groupDataArr[group.id] = {};

    if (group.json_data) {
      let jsonDataTextParsed = JSON.parse(group.json_data);
      groupItemIsMandatory = jsonDataTextParsed.mandatory && jsonDataTextParsed.mandatory === "no" ? "isNm" : jsonDataTextParsed.mandatory && jsonDataTextParsed.mandatory === "yes" ? "ism" : null;
    }

    if (group.plan_lines && group.plan_lines.length > 0) {
      group.plan_lines.map(({line_type, price_excl, price_incl, mandatory, id, description, json_data}) => {
        if (line_type === plan_line_type) {
          groupDataArr[group.id].header = group.header;
          groupDataArr[group.id].group_id = group.id;
          groupDataArr[group.id].type = group.type;
          groupDataArr[group.id].plan_line_type = group.plan_line_type;
          groupDataArrItems.push({
            id: id,
            description: description,
            price_excl_vat: price_excl,
            price_incl_vat: price_incl,
            line_type: line_type,
            group_id: group.id,
            group_header: group.header,
            is_mandatory: mandatory,
            header: description,
            json_data: json_data,
          });
          groupDataArr[group.id].items = groupDataArrItems;
          groupDataArr[group.id].groupIsMandatory = groupItemIsMandatory;
        }
      });

      console.log("formatTotalPlanLineTypeSummary - groupDataArr ->", groupDataArr);
      return groupDataArr;
    }
    return null;
  };

  // check parent child relation for displaying tree
  const checkTotalPlanLineParentChildRelation = (plan_item) => {
    let additionalKeyItem;
    let additionalKeyItemLabel;
    let additionalParentKeyItem;
    let totalPlanLineParentChildRelation = [];
    let parentObj;

    const subscriptionObj = stepFormData.subscription;

    if (plan_item.json_data && plan_item.json_data[0]) {

      let additionalKeyItemArr = Object.keys(plan_item.json_data[0]);
      additionalKeyItem = additionalKeyItemArr && additionalKeyItemArr.length > 0 ? additionalKeyItemArr[0] : null;
      if (additionalKeyItem && plan_item.json_data[0][additionalKeyItem] && plan_item.json_data[0][additionalKeyItem].parent && plan_item.json_data[0][additionalKeyItem].parent.name) {

        parentObj = {};
        additionalKeyItemLabel = plan_item.json_data[0][additionalKeyItem].parent.label;
        let additionalKeyItemName = plan_item.json_data[0][additionalKeyItem].parent.name;
        let additionalKeyItemKeyChildKeyName = plan_item.json_data[0][additionalKeyItem].parent.key_name;
        let price_excl_vat = plan_item.json_data[0][additionalKeyItem].price_excl;
        let price_incl_vat = plan_item.json_data[0][additionalKeyItem].price_incl;
        let system_element_name = plan_item.description.replace(/ /g,"_").toLowerCase();

        parentObj.description = plan_item.description;
        parentObj.line_type = plan_item.line_type;
        parentObj.price_excl_vat = plan_item.is_mandatory ? price_excl_vat : 0;
        parentObj.price_incl_vat = plan_item.is_mandatory ? price_incl_vat : 0;
        parentObj.label = additionalKeyItemLabel;
        parentObj.name = additionalKeyItemName;
        parentObj.key_name = additionalKeyItemKeyChildKeyName;
        parentObj.system_elem_name = additionalKeyItemName;
        parentObj.style = false;
        parentObj.display = true;
        parentObj.is_mandatory = plan_item.is_mandatory;
        parentObj.id = plan_item.json_data[0][additionalKeyItem].parent.id;

        parentObj.child = {};
        parentObj.child.description = plan_item.description;
        parentObj.child.price_excl_vat = 0;
        parentObj.child.price_incl_vat = 0;
        parentObj.child.line_type = plan_item.line_type;
        parentObj.child.is_mandatory = plan_item.is_mandatory;
        parentObj.child.id = plan_item.id;
        parentObj.child.key_name = system_element_name;
        parentObj.child.system_elem_name = system_element_name;

      } else if (additionalKeyItem && plan_item.json_data[0][additionalKeyItem] && plan_item.json_data[0][additionalKeyItem].type && plan_item.json_data[0][additionalKeyItem].type === "txt") {

        let system_element_name = plan_item.description.replace(/ /g,"_").toLowerCase();

        parentObj = {};
        parentObj.description = plan_item.description;
        parentObj.line_type = plan_item.line_type;
        parentObj.price_excl_vat = 0;
        parentObj.price_incl_vat = 0;
        parentObj.label = plan_item.description;
        parentObj.name = system_element_name;
        parentObj.key_name = system_element_name;
        parentObj.system_elem_name = system_element_name;
        parentObj.style = "display: none";
        parentObj.display = false;
        parentObj.is_mandatory = plan_item.is_mandatory;
        parentObj.id = plan_item.id;

        parentObj.child = null;

      }

      console.log("checkTotalPlanLineParentChildRelation (check parent child relation) - plan_item - parentObj ->", parentObj);
      //console.log("checkTotalPlanLineParentChildRelation - additionalKeyItem ->", additionalKeyItem);
      //console.log("checkTotalPlanLineParentChildRelation - additionalParentKeyItem ->", additionalParentKeyItem);
    }
    return parentObj;

  };

  // calculate additional costs by plan line for children
  const checkTotalPlanLineChildAdditionalCostsCurrency = (group, plan_line_type, plan_item, is_mandatory = false) => {
    console.log("checkTotalPlanLineChildAdditionalCostsCurrency - key - additionalCostsData ->", additionalCostsData);
    console.log("checkTotalPlanLineChildAdditionalCostsCurrency - stepFormData  ->", stepFormData);
    console.log("checkTotalPlanLineChildAdditionalCostsCurrency - plan_item ->", plan_item);
    console.log("checkTotalPlanLineChildAdditionalCostsCurrency - group ->", group);
    console.log("checkTotalPlanLineChildAdditionalCostsCurrency - plan_line_type ->", plan_line_type);

    let additionalKeyItem;
    let additionalParentKeyItem;
    if (plan_item.json_data && plan_item.json_data[0]) {
      //let jsonDataTextParsed = JSON.parse(plan_item.json_data[0]);
      let additionalKeyItemArr = Object.keys(plan_item.json_data[0]);
      additionalKeyItem = additionalKeyItemArr && additionalKeyItemArr.length > 0 ? additionalKeyItemArr[0] : null;
      if (additionalKeyItem && plan_item.json_data[0][additionalKeyItem] && plan_item.json_data[0][additionalKeyItem].parent && plan_item.json_data[0][additionalKeyItem].parent.name) {
        additionalParentKeyItem = plan_item.json_data[0][additionalKeyItem].parent.name;
      }
      additionalKeyItem = additionalKeyItemArr && additionalKeyItemArr.length > 0 ? additionalKeyItemArr[0] : null;
    }
    console.log("checkTotalPlanLineChildAdditionalCostsCurrency - additionalKeyItem ->", additionalKeyItem);
    console.log("checkTotalPlanLineChildAdditionalCostsCurrency - additionalParentKeyItem ->", additionalParentKeyItem);

    let floatCell = 0;
    let lineTypeTxtKey = formatPlanLineType(plan_line_type, "key");
    if (stepFormData.subscription && Object.keys(stepFormData.subscription).length > 0) {

      //console.log("checkTotalPlanLineAdditionalCostsCurrency - stepFormData.subscription found ->", stepFormData.subscription);
      const subscriptionObj = stepFormData.subscription;
      console.log(
          "checkTotalPlanLineChildAdditionalCostsCurrency - subscriptionObj ->",
          subscriptionObj);

      let key;
      for (key in subscriptionObj) {
        console.log("checkTotalPlanLineChildAdditionalCostsCurrency - key ->", key);

        const subscriptionItemObj = subscriptionObj[key];
        if (additionalKeyItem && subscriptionItemObj && subscriptionItemObj[additionalKeyItem] && subscriptionItemObj[additionalKeyItem].child_id &&
            subscriptionItemObj[additionalKeyItem].child_id_description &&
            subscriptionItemObj[subscriptionItemObj[additionalKeyItem].child_id_description]) {

          console.log(
              "checkTotalPlanLineChildAdditionalCostsCurrency - key - found - subscriptionItemObj[subscriptionItemObj[additionalKeyItem].child_id_description] ->",
              subscriptionItemObj[subscriptionItemObj[additionalKeyItem].child_id_description]);

          if (pricesIncludedVat) {
            floatCell = parseFloat(
                subscriptionItemObj[subscriptionItemObj[additionalKeyItem].child_id_description].price_incl);
          } else {
            floatCell = parseFloat(
                subscriptionItemObj[subscriptionItemObj[additionalKeyItem].child_id_description].price_excl);
          }
        }
      }
    }

    return debugInfo
        ?
        t("currency_format", {number: Number(floatCell)}) + ` (${pricesIncludedVat ? "in" : "ex"} ${is_mandatory ? "ism" : "isNm"}) `
        :
        t("currency_format", {number: Number(floatCell)});

  };

  // calculate additional costs by plan line
  const checkTotalPlanLineAdditionalCostsCurrency = (group, plan_line_type, plan_item, is_mandatory = false) => {
    let additionalKeyItem;
    let additionalParentKeyItem;
    if (plan_item.json_data && plan_item.json_data[0]) {
      let additionalKeyItemArr = Object.keys(plan_item.json_data[0]);
      additionalKeyItem = additionalKeyItemArr && additionalKeyItemArr.length > 0 ? additionalKeyItemArr[0] : null;
      if (additionalKeyItem && plan_item.json_data[0][additionalKeyItem] && plan_item.json_data[0][additionalKeyItem].parent && plan_item.json_data[0][additionalKeyItem].parent.name) {
        additionalParentKeyItem = plan_item.json_data[0][additionalKeyItem].parent.name;
      }
      additionalKeyItem = additionalKeyItemArr && additionalKeyItemArr.length > 0 ? additionalKeyItemArr[0] : null;
    }

    let lineTypeTxtKey = formatPlanLineType(plan_line_type, "key");
    if (stepFormData.subscription && Object.keys(stepFormData.subscription).length > 0) {
      let floatCell = 0;

      const subscriptionObj = stepFormData.subscription;

      let key;
      for (key in subscriptionObj) {
        const subscriptionItemObj = subscriptionObj[key];

        if (Array.isArray(subscriptionItemObj) && subscriptionItemObj.includes(plan_item.id)) {
          if (pricesIncludedVat) {
            floatCell = parseFloat(plan_item.price_incl_vat);
          } else {
            floatCell = parseFloat(plan_item.price_excl_vat);
          }

        } else if (additionalKeyItem && subscriptionItemObj && subscriptionItemObj[additionalKeyItem]) {
          if (pricesIncludedVat) {
            floatCell = parseFloat(
                subscriptionItemObj[additionalKeyItem].price_incl);
          } else {
            floatCell = parseFloat(
                subscriptionItemObj[additionalKeyItem].price_excl);
          }
        }
      }
      return debugInfo
          ?
          t("currency_format", {number: Number(floatCell)}) + ` (${pricesIncludedVat ? "in" : "ex"} ${is_mandatory ? "ism" : "isNm"}) `
          :
          t("currency_format", {number: Number(floatCell)});

    } else {
      const defaultCosts = formatTotalPlanLineDefaultCosts(group, plan_line_type);

      if (defaultCosts === 0 || defaultCosts > 0) {
        return t("currency_format", { number: Number(defaultCosts) });
      }
    }
  };

  // calculate additional costs by plan line
  const formatTotalPlanLineAdditionalCostsCurrency = (group, plan_line_type) => {
    let lineTypeTxtKey = formatPlanLineType(plan_line_type, "key");

    if (group && group.group_id && group.type !== "none") {
      let floatCell = 0;
      if (Object.keys(additionalCostsData).length > 0) {
        let key;
        for (key in additionalCostsData) {
          if (additionalCostsData[key] && additionalCostsData[key].group_id && additionalCostsData[key].group_id === group.group_id) {
            if (pricesIncludedVat) {
              floatCell = parseFloat(additionalCostsData[key].total_price_incl);
            } else {
              floatCell = parseFloat(additionalCostsData[key].total_price_excl);
            }
            return debugInfo
                ? t("currency_format", {number: Number(floatCell)}) + ` (${pricesIncludedVat ? "in" : "ex"}) `
                : t("currency_format", {number: Number(floatCell)});

          } else if (additionalCostsData[key] && additionalCostsData[key][lineTypeTxtKey] && additionalCostsData[key][lineTypeTxtKey] && additionalCostsData[key][lineTypeTxtKey].group_id && additionalCostsData[key][lineTypeTxtKey].group_id === group.group_id) {
            if (pricesIncludedVat) {
              floatCell = parseFloat(additionalCostsData[key][lineTypeTxtKey].total_price_incl);
            } else {
              floatCell = parseFloat(additionalCostsData[key][lineTypeTxtKey].total_price_excl);
            }
            return debugInfo
              ? t("currency_format", {number: Number(floatCell)}) + ` (${pricesIncludedVat ? "in" : "ex"}) `
              : t("currency_format", {number: Number(floatCell)});

          }
        }
      } else if (group && group.items && group.items.length > 0) {
        group.items.map((item) => {
          if (pricesIncludedVat) {
            floatCell = parseFloat(item.price_incl_vat);
            return t("currency_format", {number: Number(floatCell)});
          } else {
            floatCell = parseFloat(item.price_excl_vat);
          }
          return t("currency_format", {number: Number(floatCell)}) + ` (${pricesIncludedVat ? "in" : "ex"}) `;

        });
      }

    } else {
      const defaultCosts = formatTotalPlanLineDefaultCosts(group, plan_line_type);

      if (defaultCosts === 0) {
        return debugInfo
          ? `GRATIS (=def(0))`
          : `GRATIS`;

      } else if (defaultCosts > 0) {
        return debugInfo
            ? t("currency_format", { number: Number(defaultCosts) }) + " (= def(>0))"
            : t("currency_format", { number: Number(defaultCosts) });
      }
    }
    return debugInfo
      ? t("currency_format", { number: Number(0) }) + " (= def)"
      : t("currency_format", { number: Number(0) });

  };

  // calculate additional costs by group
  const formatTotalPlanLineGroupAdditionalCostsCurrency = (group, plan_line_type) => {
    if (group && group.id && group.type !== "none") {
      let floatCell = 0;
      if (Object.keys(additionalCostsData).length > 0) {
        let key;
        for (key in additionalCostsData) {
          if (additionalCostsData[key] && additionalCostsData[key].group_id && additionalCostsData[key].group_id === group.id) {
            if (additionalCostsData[key].total_price_excl && additionalCostsData[key].total_price_incl) {
              if (pricesIncludedVat) {
                floatCell = parseFloat(additionalCostsData[key].total_price_incl);
              } else {
                floatCell = parseFloat(additionalCostsData[key].total_price_excl);
              }
            }
            return t("currency_format", {number: Number(floatCell)});
          }
        }
      }
      return t("currency_format", {number: Number(0)});
    } else {
      const defaultCosts = formatTotalPlanLineDefaultCosts(group, plan_line_type);
      if (defaultCosts === 0 || defaultCosts > 0) {
        return t("currency_format", { number: Number(defaultCosts) });
      }
    }
    return "";
  };

  const formatPlanLineCurrency = (price_excl_vat, price_incl_vat) => {
    let price;
    if (pricesIncludedVat) {
      price = price_incl_vat;
    } else {
      price = price_excl_vat;
    }

    let floatCell = parseFloat(price ? price : 0);
    let floatStyle = floatCell && floatCell < 0 ? 'float-negative' : floatCell && floatCell > 0 ? 'float-positive' : 'float-null';
    return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(floatCell) })}</span>
  };

  const number_test = (n) => {
    // if false it's a decimal else it's a whole number
    let result = (n - Math.floor(n)) !== 0;
    return !result;
  };

  const formatCurrency = (priceObj) => {
    if (priceObj.plan_type) {
      let price_excl_vat;
      let price_incl_vat;

      switch (priceObj.plan_type) {
        case 2:
          price_excl_vat = priceObj.minimum_nrc_excl;
          price_incl_vat = priceObj.minimum_nrc_incl;
          break;
        case 3:
          price_excl_vat = priceObj.minimum_mrc_excl;
          price_incl_vat = priceObj.minimum_mrc_incl;
          break;
        case 4:
          price_excl_vat = priceObj.minimum_qrc_excl;
          price_incl_vat = priceObj.minimum_qrc_incl;
          break;
        case 5:
          price_excl_vat = priceObj.minimum_yrc_excl;
          price_incl_vat = priceObj.minimum_yrc_incl;
          break;
        case 6:
          price_excl_vat = priceObj.minimum_deposit_excl;
          price_incl_vat = priceObj.minimum_deposit_incl;
          break;
        default:
          price_excl_vat = 0;
          price_incl_vat = 0;
          break;
      }

      let floatCell;
      if (pricesIncludedVat) {
        floatCell = parseFloat(price_incl_vat ? price_incl_vat : 0);
      } else {
        floatCell = parseFloat(price_excl_vat ? price_excl_vat : 0);
      }

      let floatStyle = floatCell && floatCell < 0 ? 'float-negative' : floatCell && floatCell > 0 ? 'float-positive' : 'float-null';
      return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(floatCell) })}</span>
    }
    return <span className={'float-zero'}>{t("currency_format", { number: Number(0) })}</span>
  };

  const RenderPlanLinesInGroupSelect = (plan_lines, name, jsonDataGroup, description, group_id) => {
    let system_element_name = name ? name.replace(/ /g,"_").toLowerCase() : "no-name";
    let system_group_description = description ? description.replace(/ /g,"_").toLowerCase() : "no-description";

    if (jsonDataGroup) {
      let label = "";
      let options = [];

      let jsonDataGroupParsed = JSON.parse(jsonDataGroup);

      // check options in group
      if (jsonDataGroupParsed && jsonDataGroupParsed.options) {
        label = jsonDataGroupParsed.label
            ? jsonDataGroupParsed.label
            : "no-label";
        options = jsonDataGroupParsed.options
            ? jsonDataGroupParsed.options
            : [];

      } else if (plan_lines && plan_lines.length === 1) {
        // check options in plan_line(s)
        let plan_line_data = plan_lines[0];
        if (plan_line_data && Object.keys(plan_line_data).length > 0) {
          let key;
          for (key in plan_line_data) {
            if (key === "json_data") {
              let jsonDataPlanLine = plan_line_data[key];
              if (jsonDataPlanLine && jsonDataPlanLine[0] && jsonDataPlanLine[0].options) {
                options = jsonDataPlanLine[0].options;
              }
            }
          }
        }
      }

      let select_options_arr = [];
      // set options with optional prices & type
      if (options && options.length > 0) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].label && options[i].line_type && options[i].price_excl && options[i].price_incl) {
            if (pricesIncludedVat) {
              select_options_arr.push({
                label: options[i].label + ` (+ ${t("currency_format", {number: Number(parseFloat(options[i].price_incl))})} p/mnd)`,
                key: options[i].key, // === required in json data
                value: options[i].value, // === required in json data
                line_type: options[i].line_type, // === required in json data
                price_incl: options[i].price_incl, // === required in json data else set to 0 (zero)
                price_excl: options[i].price_excl, // === required in json data else set to 0 (zero)
                plan_line_id: options[i].plan_line_id, // === required in json data
              });

            } else {
              select_options_arr.push({
                label: options[i].label + ` (+ ${t("currency_format", {number: Number(parseFloat(options[i].price_excl))})} p/mnd)`,
                key: options[i].key, // === required in json data
                value: options[i].value, // === required in json data
                line_type: options[i].line_type, // === required in json data
                price_incl: options[i].price_incl, // === required in json data else set to 0 (zero)
                price_excl: options[i].price_excl, // === required in json data else set to 0 (zero)
                plan_line_id: options[i].plan_line_id, // === required in json data
              });
            }
          }
        }
      }

      return (
          <div role="group" aria-labelledby={`${system_group_description}-select-group`}>
            <div className="group-select">
              {label &&
              <Label className="dynamic-form-element-label">
                <strong>{label}</strong>
              </Label>
              }
              <Select
                  className="react-select-container mt-0"
                  classNamePrefix="react-select"
                  name={name}
                  id={`id-${system_group_description}`}
                  placeholder="Select..."
                  withoutEmptyOption={true}
                  options={select_options_arr}
                  hideSelectedOptions={true}
                  isMulti={false}
                  value={
                    stepFormData &&
                    stepFormData.subscription &&
                    stepFormData.subscription[system_group_description] &&
                    stepFormData.subscription[system_group_description]
                        ? stepFormData.subscription[system_group_description]
                        : ""
                  }
                  isClearable={true}
                  onChange={(e) => handleSelectChange(e, system_group_description, select_options_arr, group_id)}
              />
            </div>
          </div>
      );
    }
  };

  const RenderPlanLinesInGroupNoneVisible = (plan_lines, name, jsonDataText, description, group_id) => {
    let system_element_name = name ? name.replace(/ /g,"_").toLowerCase() : "no-name";
    let system_group_description = description ? description.replace(/ /g,"_").toLowerCase() : "no-description";

    plan_lines.map((line, index) => {
      //if (line.mandatory && line.mandatory === true) {
      if (line.mandatory === true) {
        let dataPriceTotal = {
          total_price_excl: parseFloat(line.price_excl),
          total_price_incl: parseFloat(line.price_incl),
          line_type: line.line_type,
          line_id: line.id,
          group_id: group_id,
        };

        // Multi if there are multiple options possible
        addDefaultCosts(system_group_description, dataPriceTotal, "single");
      }
    });
  };

  const RenderPlanLinesInGroupCheckbox = (plan_lines, name, jsonDataText, description, group_id) => {
    let system_element_name = name ? name.replace(/ /g,"_").toLowerCase() : "no-name";
    let system_group_description = description ? description.replace(/ /g,"_").toLowerCase() : "no-description";

    if (jsonDataText) {
      let dataPriceTotal;
      let jsonDataTextParsed = JSON.parse(jsonDataText);
      let id = system_element_name;

      if (jsonDataTextParsed && jsonDataTextParsed.func && jsonDataTextParsed.func === "collapse" && jsonDataTextParsed.toggleId) {
        id = jsonDataTextParsed.toggleId;
      }

      return (
          <div
              role="group"
              aria-labelledby={`${system_group_description}-radio-group`}
              key={`${system_group_description}`}
              data-key={`${system_group_description}`}
          >
            {plan_lines.map((line, index) => {
              if (line.mandatory === true) {
                let dataPriceTotal = {
                  total_price_excl: parseFloat(line.price_excl),
                  total_price_incl: parseFloat(line.price_incl),
                  line_type: line.line_type,
                  line_id: line.id,
                  group_id: group_id,
                };
                // Multi if there are multiple options possible
                addDefaultCosts(system_group_description, dataPriceTotal,"multi");
              }

              return (
                  <div
                      className="custom-checkbox custom-control custom-controlled-checkbox"
                      key={`${system_group_description}-${index}`}
                      data-key={`${system_group_description}-${index}`}
                  >
                    <Input
                        type="checkbox"
                        name={system_group_description}
                        value={line.id}
                        color={`primary`}
                        id={`${group_id}-${line.id}-${index}`}
                        data-line-id={line.id}
                        data-is-mandatory={!line.mandatory ? "not-required" : "required"}
                        disabled={!line.mandatory ? false : true}
                        defaultChecked={
                          (stepFormData &&
                          stepFormData.subscription &&
                          stepFormData.subscription[system_group_description] &&
                          stepFormData.subscription[system_group_description].includes(line.id)
                              ? true
                              : false) || line.mandatory === true
                        }
                        data-is-checked={
                          stepFormData &&
                          stepFormData.subscription &&
                          stepFormData.subscription[system_group_description] &&
                          stepFormData.subscription[system_group_description].includes(line.id)
                              ? true
                              : false}
                        style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1"}}
                        onChange={(e) => handleCheckboxChange(e, system_group_description, line.id, line, group_id)}
                    />
                    <Label
                        check
                        className="custom-controlled-checkbox-label"
                        style={{width: "100%"}}
                    >
                    <span className="plan-offer-line-description"
                          style={{width: "75%", display: "inline-block"}}>{line.description}</span>
                      {line["price_excl"] && line["price_incl"]
                          ? <span className="plan-offer-line-price"
                                  style={{float: "right"}}>{formatPlanLineCurrency(line["price_excl"], line["price_incl"])}</span>
                          : <span className="plan-offer-line-price" style={{float: "right"}}>GRATIS</span>}
                    </Label>
                  </div>
              )})
            }
          </div>
      );
    }
  };

  const removeHTML = (str) => {
    if (str && str !== "") {
      return str.replace(/<[^>]+>/ig, "");
    }
    return str;
  };

  /*
    Formats given string name to lowercase
   */
  const formatName = (str) => {
    let strArr = str.split('|');
    if (strArr && strArr.length > 1) {
      return strArr[0].toLowerCase();
    }
    return str
  };

  const RenderPlanLinesInGroupRadio = (plan_lines, name, jsonDataText, description, group_id) => {
    let system_element_name = name ? name.replace(/ /g,"_").toLowerCase() : "no-name";
    let system_group_description = description ? description.replace(/ /g,"_").toLowerCase() : "no-description";

    if (jsonDataText) {
      let jsonDataTextParsed;
      jsonDataTextParsed = JSON.parse(jsonDataText);

      let visibility = jsonDataTextParsed && jsonDataTextParsed.style ? jsonDataTextParsed.style : "";
      if (jsonDataTextParsed.parent) {
        if (jsonDataTextParsed.style_dependency_value && stepFormData &&
            stepFormData.subscription &&
            stepFormData.subscription[jsonDataTextParsed.parent] &&
            stepFormData.subscription[jsonDataTextParsed.parent][jsonDataTextParsed.style_dependency_value])
        {
          visibility = "not-hidden";
        }
      } else {
        if (jsonDataTextParsed.style_dependency_value && stepFormData &&
            stepFormData.subscription &&
            stepFormData.subscription[jsonDataTextParsed.style_dependency_value])
        {
          visibility = "not-hidden";
        }
      }

      return (
          <div
              role="group"
              aria-labelledby={`${system_group_description}-radio-group`}
              key={`${system_group_description}`}
              id={jsonDataTextParsed && jsonDataTextParsed.id ? jsonDataTextParsed.id : system_element_name}
              className={`${visibility}`}
          >
            {plan_lines.map((line, index) =>
                <div
                    key={`${system_group_description}-${index}`}
                    className="custom-radio custom-control custom-controlled-radio"
                    style={{paddingLeft: 0}}
                >
                  <CustomInput
                      className="mb-1"
                      id={`${group_id}-${line.id}-${index}`}
                      data-line-id={line.id}
                      type="radio"
                      name={name ? formatName(name) : system_group_description}
                      label={line.description_long ? removeHTML(line.description_long) : line.description}
                      value={line.id}
                      defaultChecked={
                        stepFormData && stepFormData.subscription &&
                        stepFormData.subscription[system_group_description] ===
                        line.id
                      }
                      data-is-checked={
                        stepFormData && stepFormData.subscription &&
                        stepFormData.subscription[system_group_description] ===
                        line.id
                      }
                      style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1", maxWidth: "75%"}}
                      onChange={(e) => handleRadioChange(e, system_group_description, line, group_id)}
                  />

                  {line["price_excl"] && line["price_incl"]
                      ? <span className="plan-offer-line-price" style={{
                        position: "absolute",
                        right: 0,
                        maxWidth: "25%",
                        top: 0
                      }}>{formatPlanLineCurrency(line["price_excl"], line["price_incl"])}</span>
                      : <span
                          className="plan-offer-line-price"
                          style={{position: "absolute", right: 0, maxWidth: "25%", top: 0}}
                      >
                  GRATIS
                  </span>
                  }
                </div>
            )}
          </div>
      )
    }
  };

  const renderPlanLineJSON = (line_data, name, type, targetKey, inLineStyle, group_id, system_elem_name, parent = null) => {
    /* type => element type; button - radio - select - checkbox | targetKey => parents element attribute ID */
    if (line_data && line_data.json_data && Array.isArray(line_data.json_data)) {
      if (line_data.json_data.length === 1) {

        let jsonDataPlanLineParsed;
        // check json data for text or object
        if (typeof line_data.json_data === "object") {
          jsonDataPlanLineParsed = line_data.json_data[0];
        } else {
          jsonDataPlanLineParsed = JSON.parse(line_data.json_data[0]);
        }

        for (let key of Object.keys(jsonDataPlanLineParsed)) {
          let parent_child = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].options && jsonDataPlanLineParsed[key].options[0] && jsonDataPlanLineParsed[key].options[0].name ? jsonDataPlanLineParsed[key].options[0].name : null;
          let isDefaultChecked;
          if (parent && parent.name && parent_child) {
            let checked_option = stepFormData && stepFormData.subscription && stepFormData.subscription[parent.name] && stepFormData.subscription[parent.name][parent_child] ? stepFormData.subscription[parent.name][parent_child] : false;
            let checked_option_value = stepFormData && stepFormData.subscription && stepFormData.subscription[parent.name] && stepFormData.subscription[parent.name][parent_child] && stepFormData.subscription[parent.name][parent_child].value ? stepFormData.subscription[parent.name][parent_child].value : false;
            let option_checked = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].options.findIndex(option => option.value === checked_option_value);
            isDefaultChecked = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].options[option_checked];

          } else {
            isDefaultChecked = stepFormData && stepFormData.subscription && stepFormData.subscription[key] ? stepFormData.subscription[key] : false;
          }

          // render radio element
          if (jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].type === "radio") {
            return (
                <RadioElement
                    name={key}
                    parent={parent ? parent : null}
                    options={jsonDataPlanLineParsed[key].options}
                    style={
                      type && type === "button" && inLineStyle && inLineStyle !== ""
                          ? inLineStyle
                          : jsonDataPlanLineParsed[key].style
                          ? jsonDataPlanLineParsed[key].style
                          : ""
                    }
                    inLineStyle={inLineStyleVisible}
                    typeElement={type ? type : null}
                    buttonGroupCollapseState={buttonGroupsState}
                    setStepFormData={setStepFormData}
                    stepFormData={stepFormData}
                    additionalCostsData={additionalCostsData}
                    setAdditionalCostsData={setAdditionalCostsData}
                    subscription={subscription}
                    parentContainerId={targetKey}
                    groupId={group_id} // system group id
                    groupName={system_elem_name} // system element (group) name
                    lineData={line_data}
                    pricesIncludedVat={pricesIncludedVat}
                    defaultChecked={isDefaultChecked}
                    dataChecked={isDefaultChecked}
                    handleDynamicFieldData={handleDynamicFieldData}
                />
            )
          }

          // render checkbox element - TODO
          // render input element - TODO

          // render react select
          if (jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].type === "react-select") {
            return (
                <SelectElement
                    name={key}
                    label={jsonDataPlanLineParsed[key].label}
                    options={jsonDataPlanLineParsed[key].options}
                    style={jsonDataPlanLineParsed[key].style ? jsonDataPlanLineParsed[key].style : ""}
                    stepFormData={stepFormData}
                    subscription={subscription}
                    defaultChecked={
                      stepFormData &&
                      stepFormData.subscription &&
                      stepFormData.subscription[key]
                          ? stepFormData.subscription[key]
                          : false
                    }
                    dataChecked={
                      stepFormData &&
                      stepFormData.subscription &&
                      stepFormData.subscription[key]
                          ? stepFormData.subscription[key]
                          : false
                    }
                    onChange={(e) => handleDynamicFieldData(e, key, "select")}
                />
            )
          }
        }
      }
    }
  };

  const removeFromSubscription = (e, item) => {
    console.log("removeFromSubscription - e ->", e);
    console.log("removeFromSubscription - item ->", item);
  };

  const RenderPlanLinesInGroupButton = (plan_lines, name, jsonDataText, description, group_id) => {
    let system_element_name = description.replace(/ /g,"_").toLowerCase();

    if (jsonDataText) {
      let jsonDataTextParsed = JSON.parse(jsonDataText);
      if (jsonDataTextParsed && jsonDataTextParsed.func && jsonDataTextParsed.func === "collapse") {
        let target_id = jsonDataTextParsed.target; // target id(s)
        let jsonDataPlanLineParsed;

        return (
            <div
                role="group"
                className={`group-button-container`}
                key={`${system_element_name}`}
                data-key={`${system_element_name}`}
                data-id={`${system_element_name}`}
            >
              {plan_lines.map((line, index) => {
                if (line.json_data && line.json_data[0]) {
                  // check json data for text or object
                  if (typeof line.json_data[0] === "object") {
                    jsonDataPlanLineParsed = line.json_data[0];
                  } else {
                    jsonDataPlanLineParsed = JSON.parse(line.json_data[0]);
                  }

                  let targetKey;
                  let targetParentId;
                  let targetKeyStyle;
                  let isDependent;
                  let isDisabled = ``;
                  let cursor;
                  let isDependentFrom;
                  let inLineStyle = ``;
                  let price_excl = null;
                  let price_incl = null;
                  let line_type = null;
                  let collapseStateTargetVisible = false;
                  let parent;
                  let element_type;

                  if (jsonDataPlanLineParsed && typeof jsonDataPlanLineParsed === "object") {
                    for (let key of Object.keys(jsonDataPlanLineParsed)) {
                      targetKey = key;
                      targetKeyStyle = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].style ? jsonDataPlanLineParsed[key].style : "";
                      inLineStyle = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].style ? jsonDataPlanLineParsed[key].style : "";
                      price_excl = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].price_excl ? jsonDataPlanLineParsed[key].price_excl : 0; // was 0
                      price_incl = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].price_incl ? jsonDataPlanLineParsed[key].price_incl : 0; // was 0
                      line_type = jsonDataPlanLineParsed[key] &&  jsonDataPlanLineParsed[key].line_type ? jsonDataPlanLineParsed[key].line_type : null;
                      element_type = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].type ? jsonDataPlanLineParsed[key].type : null;
                      targetParentId = key ? `container_button_group_${key}` : null;
                      parent = jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key].parent ? jsonDataPlanLineParsed[key].parent : null;

                      // if enabled_dependency_value
                      if (jsonDataPlanLineParsed[key] && jsonDataPlanLineParsed[key]["dependency"]) {
                        isDependent = true;
                        isDependentFrom = jsonDataPlanLineParsed[key]["dependency"];
                        isDisabled = "-disabled";

                        if (jsonDataPlanLineParsed[key] &&
                            jsonDataPlanLineParsed[key]["enabled_dependency_value"] &&
                            buttonGroupsState &&
                            buttonGroupsState[jsonDataPlanLineParsed[key]["enabled_dependency_value"]]) {
                          isDisabled = "";
                        }
                      }
                    }
                  }

                  return (
                      <div
                          className={`group-button-dynamic`}
                          key={`${system_element_name}-${index}`}
                          data-key={`${system_element_name}-${index}`}
                          data-parentid={parent && parent.id ? parent.id : ""}
                          data-parentname={parent && parent.name ? parent.name : ""}
                          style={element_type && element_type === "txt" && line.description_long && line.description_long !== "" ? {marginBottom:"10px"} : {}}
                      >
                        <div
                            style={{
                              maxWidth: "75%",
                              cursor: isDisabled !== "" ? "not-allowed" : "pointer"
                            }}
                            className={`closed group-button ${buttonGroupClass}${isDisabled}`}
                            dangerouslySetInnerHTML={{__html: line.description_long}}
                            key={`${group_id}-${index}`}
                            id={`${group_id}-${line.id}-${index}`}
                            ref={buttonContainerEl}
                            onClick={(e) => clickButtonCollapseHandler(
                                e,
                                target_id ? target_id : null,
                                line.description_long,
                                targetKey,
                                line,
                                group_id,
                                isDependent,
                                isDependentFrom,
                                `${group_id}-${line.id}-${index}`,
                                system_element_name,
                                parent ? parent : null
                            )}
                        />

                        {line.price_incl && line.price_excl
                            ? <span
                                key={`${group_id}-${index}-price`}
                                className="plan-offer-line-price"
                                style={{
                                  float: "right",
                                  position: "relative",
                                  maxWidth: "25%",
                                  right: 0,
                                  top: "-22px",
                                  textAlign: "right",
                                }}
                            >{formatPlanLineType(line.line_type ? line.line_type : false, "txt")} &nbsp; {formatPlanLineCurrency(line.price_excl, line.price_incl)}</span>
                            : element_type && element_type === "txt"
                              ? ""
                              :
                              <span
                                key={`${group_id}-${index}-price`}
                                className="plan-offer-line-price"
                                style={{
                                  float: "right",
                                  position: "relative",
                                  maxWidth: "25%",
                                  right: 0,
                                  top: "-22px",
                                  textAlign: "right",
                                }}
                            >GRATIS</span>
                        }

                        <div
                            key={`json-data-${name}-${index}`}
                            className={`group-button-json-data-container`}
                            id={targetParentId}
                        >
                          {renderPlanLineJSON(line, name, "button", targetKey, inLineStyle, group_id, system_element_name, parent)}
                        </div>
                      </div>)
                }})
              }
            </div>
        )
      }

      if (jsonDataTextParsed && jsonDataTextParsed.func && jsonDataTextParsed.func === "add") {
        let target_id = jsonDataTextParsed.target;

        return (
            <div role="group" className="group-button-container">
              {plan_lines.map((line, index) =>
                  <div
                      className={`group-button ${buttonGroupClass}`}
                      dangerouslySetInnerHTML={{__html: line.description_long}}
                      key={`${group_id}-${index}`}
                      ref={buttonContainerEl}
                      onClick={(e) => clickButtonAddHandler(e, target_id ? target_id : null)}
                  />
              )}
            </div>
        );
      }
    }
  };

  const checkGroupJsonDataId = (groupTxt, groupIndex) => {
    if (groupTxt) {
      let jsonDataTextParsed;
      jsonDataTextParsed = JSON.parse(groupTxt);

      if (jsonDataTextParsed && jsonDataTextParsed["container_group_id"]) {
        return `${jsonDataTextParsed["container_group_id"]}`;
      }
    }
    return `group-id-${groupIndex}`;
  };

  const checkGroupJsonData = (groupTxt) => {
    if (groupTxt) {
      let jsonDataTextParsed;
      jsonDataTextParsed = JSON.parse(groupTxt);

      if (jsonDataTextParsed && jsonDataTextParsed["header_class"]) {
        if (jsonDataTextParsed.style && jsonDataTextParsed.style === "hidden") {
          if (jsonDataTextParsed.parent) {
            if (jsonDataTextParsed.style_dependency_value && stepFormData &&
                stepFormData.subscription &&
                stepFormData.subscription[jsonDataTextParsed.parent] &&
                stepFormData.subscription[jsonDataTextParsed.parent][jsonDataTextParsed.style_dependency_value])
            {
              return `not-hidden ${jsonDataTextParsed["header_class"]}`;
            }
          } else {
            if (jsonDataTextParsed.style_dependency_value &&
                stepFormData &&
                stepFormData.subscription &&
                stepFormData.subscription[jsonDataTextParsed.style_dependency_value])
            {
              return `not-hidden ${jsonDataTextParsed["header_class"]}`;
            }
          }
          return `hidden ${jsonDataTextParsed["header_class"]}`;
        }
        return jsonDataTextParsed["header_class"];
      }
    }
    return "";
  };

  /*
    Collapse (target) button element
   */
  const clickButtonCollapseHandler = (e, targetId, html, targetKey, lineData, group_id, isDependent, isDependentFrom, button_id, group_name, parent) => {
    e.preventDefault();

    // disable click if there is a dependency(from)
    if (group_name) {
      if (isDependent && isDependentFrom && !subscription[group_name]) {
        return false;
      } else {
        if (button_id && isDependent && isDependentFrom && subscription[group_name] && subscription[group_name][isDependentFrom]) {
          const el_button_id = document.getElementById(button_id);
          if (el_button_id) {
            el_button_id.classList.replace("button-collapse-action-disabled", "button-collapse-action");
          }
        }
      }
    } else {
      if (isDependent && isDependentFrom && !subscription[isDependentFrom]) {
        return false;
      } else {
        if (button_id && isDependent && isDependentFrom && subscription[isDependentFrom]) {
          const el_button_id = document.getElementById(button_id);
          if (el_button_id) {
            el_button_id.classList.replace("button-collapse-action-disabled", "button-collapse-action");
          }
        }
      }
    }

    // set collapse state for button -> if exists in buttonGroupState -> else set the first click
    if (targetKey && targetKey in buttonGroupsState) {
      // set button state
      buttonGroupsState[targetKey] = !buttonGroupsState[targetKey];
      setButtonGroupsState(buttonGroupsState);

      // set open to close || close to open icon
      if (button_id) {
        const el_button_id = document.getElementById(button_id);
        if (el_button_id && el_button_id.classList && el_button_id.classList.contains("open")) {
          el_button_id.classList.replace("open", "closed");
        } else if (el_button_id && el_button_id.classList && el_button_id.classList.contains("closed")) {
          el_button_id.classList.replace("closed", "open");
        }
      }

    } else {
      buttonGroupsState[targetKey] = true; // default is hidden - so set the first click to open => true
      setButtonGroupsState(buttonGroupsState);

      // set open to close || close to open icon
      if (button_id) {
        const el_button_id = document.getElementById(button_id);
        if (el_button_id && el_button_id.classList && el_button_id.classList.contains("closed")) {
          el_button_id.classList.replace("closed", "open");
        } else if (el_button_id && el_button_id.classList && el_button_id.classList.contains("open")) {
          el_button_id.classList.replace("open", "closed");
        }
      }
    }

    // handle collapse element(s)
    let href, elemId, multiTarget = false;
    if (html && html !== "") {
      href = html.match(/(#)([^\s]+(?="))/g);
      if (Array.isArray(href)) {
        for (let i = 0; i < href.length; i++) {
          elemId = href[i].replace("#", "");
        }

        if (elemId && Array.isArray(targetId) && targetId.includes(elemId)) {
          multiTarget = true;
        }
      }
    }

    if (Array.isArray(targetId) && multiTarget === true) {
      for (let i = 0; i < targetId.length; i++) {
        let targetEl = document.getElementById(targetId[i]);

        if (targetEl && targetId[i]) {
          if (targetEl && targetEl.classList && targetEl.classList.contains("hidden")) {
            targetEl.classList.replace("hidden", "not-hidden");

            let children = targetEl.children;
            if (children && children.length > 0) {
              for (let i = 0; i < children.length; i++) {
                children[i].classList.replace("hidden", "not-hidden");
              }
            }

          } else if (targetEl && targetEl.classList && targetEl.classList.contains("not-hidden")) {
            targetEl.classList.replace("not-hidden", "hidden");

            let children = targetEl.children;
            if (children && children.length > 0) {
              for (let i = 0; i < children.length; i++) {
                children[i].classList.replace("not-hidden", "hidden");
              }
            }
          }

        } else {

          if (targetEl && targetEl.className && targetEl.className === "hidden") {
            let parentEl = targetEl.parentElement;
            let children = parentEl.children;
            if (children && children.length > 0) {
              for (let i = 0; i < children.length; i++) {
                children[i].classList.replace("hidden", "not-hidden");
              }
            }
          } else if (targetEl && targetEl.className && targetEl.className === "not-hidden") {
            let parentEl = targetEl.parentElement;
            let children = parentEl.children;
            if (children && children.length > 0) {
              for (let i = 0; i < children.length; i++) {
                children[i].classList.replace("not-hidden", "hidden");
              }
            }
          }
        }
      }

    } else {
      let targetEl = document.getElementById(targetId);
      if (targetEl === null) {
        targetEl = document.getElementById(elemId);
      }

      if (targetEl && targetEl.className && targetEl.className === "hidden") {
        let parentEl = targetEl.parentElement;
        let children = parentEl.children;
        if (children && children.length > 0) {
          for (let i = 0; i < children.length; i++) {
            children[i].classList.replace("hidden", "not-hidden");
          }
        }
      } else if (targetEl && targetEl.className && targetEl.className === "not-hidden") {
        let parentEl = targetEl.parentElement;
        let children = parentEl.children;
        if (children && children.length > 0) {
          for (let i = 0; i < children.length; i++) {
            children[i].classList.replace("not-hidden", "hidden");
          }
        }
      }
    }

    // check buttonGroupState to set subscription & additional costs
    if (group_name && targetKey && buttonGroupsState[targetKey]) {
      let child_id_description = lineData.description ? lineData.description.replace(/ /g,"_").toLowerCase() : "no-description";

      let subscriptionDataObj;
      if (subscription[group_name]) {
        let subscriptionDataObj = Object.assign({}, subscription);
        subscriptionDataObj[group_name][targetKey] = {};
        subscriptionDataObj[group_name][targetKey].child_id = lineData.id;
        subscriptionDataObj[group_name][targetKey].child_id_description = child_id_description;
        subscriptionDataObj[group_name][targetKey].parent_id = lineData.json_data && lineData.json_data[0] && lineData.json_data[0][targetKey] && lineData.json_data[0][targetKey].parent && lineData.json_data[0][targetKey].parent.id ? lineData.json_data[0][targetKey].parent.id : null;
        subscriptionDataObj[group_name][targetKey].parent_label = lineData.json_data && lineData.json_data[0] && lineData.json_data[0][targetKey] && lineData.json_data[0][targetKey].parent && lineData.json_data[0][targetKey].parent.label ? lineData.json_data[0][targetKey].parent.label : null;
        subscriptionDataObj[group_name][targetKey].price_excl = lineData.price_excl;
        subscriptionDataObj[group_name][targetKey].price_incl = lineData.price_incl;
        subscriptionDataObj[group_name][targetKey].line_type = lineData.line_type;
        subscriptionDataObj[group_name][targetKey].name = targetKey;
        subscriptionDataObj[group_name][targetKey].group_name = group_name;
        subscriptionDataObj[group_name][targetKey].group_id = group_id;

        stepFormData.subscription = subscriptionDataObj;
        setStepFormData(stepFormData);

      } else {
        let subscriptionDataObj = Object.assign({}, subscription);
        subscriptionDataObj[group_name] = {};
        subscriptionDataObj[group_name][targetKey] = {};
        subscriptionDataObj[group_name][targetKey].child_id = lineData.id;
        subscriptionDataObj[group_name][targetKey].child_id_description = child_id_description;
        subscriptionDataObj[group_name][targetKey].parent_id = lineData.json_data && lineData.json_data[0] && lineData.json_data[0][targetKey] && lineData.json_data[0][targetKey].parent && lineData.json_data[0][targetKey].parent.id ? lineData.json_data[0][targetKey].parent.id : null;
        subscriptionDataObj[group_name][targetKey].parent_label = lineData.json_data && lineData.json_data[0] && lineData.json_data[0][targetKey] && lineData.json_data[0][targetKey].parent && lineData.json_data[0][targetKey].parent.label ? lineData.json_data[0][targetKey].parent.label : null;
        subscriptionDataObj[group_name][targetKey].price_excl = lineData.price_excl;
        subscriptionDataObj[group_name][targetKey].price_incl = lineData.price_incl;
        subscriptionDataObj[group_name][targetKey].line_type = lineData.line_type;
        subscriptionDataObj[group_name][targetKey].name = targetKey;
        subscriptionDataObj[group_name][targetKey].group_name = group_name;
        subscriptionDataObj[group_name][targetKey].group_id = group_id;

        stepFormData.subscription = subscriptionDataObj;
        setStepFormData(stepFormData);
      }

      // set additional costs
      if (stepFormData.subscription && stepFormData.subscription[group_name] && stepFormData.subscription[group_name][targetKey]) {
        let targetData = stepFormData.subscription[group_name][targetKey];
        let additionalCostsDataObj = Object.assign({}, additionalCostsData);
        let additionalCostsDataGroupObj = Object.assign({}, additionalCostsDataObj[group_name]);
        let lineTypeTxtKey = formatPlanLineType(targetData.line_type, "key");

        if (additionalCostsData[group_name] && additionalCostsData[group_name][targetKey] && additionalCostsData[group_name][targetKey][lineTypeTxtKey]) {
          additionalCostsData[group_name][targetKey][lineTypeTxtKey] = {
            total_price_excl: parseFloat(targetData.price_excl),
            total_price_incl: parseFloat(targetData.price_incl),
            line_type: targetData.line_type, // id
            line_id: targetData.id,
            group_id: group_id,
          };
          additionalCostsDataObj = additionalCostsData;

        } else {
          additionalCostsDataGroupObj[targetKey] = {};
          additionalCostsDataGroupObj[targetKey][lineTypeTxtKey] = {
            total_price_excl: parseFloat(targetData.price_excl),
            total_price_incl: parseFloat(targetData.price_incl),
            line_type: targetData.line_type,
            line_id: targetData.id,
            group_id: group_id,
          };
          additionalCostsDataObj[group_name] = additionalCostsDataGroupObj;
        }
        setAdditionalCostsData(additionalCostsDataObj);
      }
    } else {
      // remove items from subscription - group_name && targetKey
      if (stepFormData.subscription && stepFormData.subscription[group_name][targetKey]) {
        // remove
        delete stepFormData.subscription[group_name][targetKey];
      }
    }

    // check button state for removing items from subscription
    if (lineData && lineData.json_data && lineData.json_data[0]) {
      let jsonDataObj = lineData.json_data[0];
      if (Object.keys(jsonDataObj).length > 0) {
        const searchKey = targetKey;

        let key;
        for (key in jsonDataObj) {
          if (jsonDataObj.hasOwnProperty(key)) {
            if (key === searchKey) {
              if (jsonDataObj[key] && jsonDataObj[key].options && jsonDataObj[key].options.length > 0) {
                let optionName = jsonDataObj[key].options[0] && jsonDataObj[key].options[0].name ? jsonDataObj[key].options[0].name : null;
                let disable_dependency = jsonDataObj[key] && jsonDataObj[key]["disable_dependency"] ? jsonDataObj[key]["disable_dependency"] : false;
                let disable_dependencies = jsonDataObj[key] && jsonDataObj[key]["disable_dependencies"] ? jsonDataObj[key]["disable_dependencies"] : false;
                let subscription_dependency = jsonDataObj[key] && jsonDataObj[key]["subscription_dependency"] ? jsonDataObj[key]["subscription_dependency"] : false;
                let subscription_dependencies = jsonDataObj[key] && jsonDataObj[key]["subscription_dependencies"] ? jsonDataObj[key]["subscription_dependencies"] : false;

                if (subscription[group_name] &&
                    subscription[group_name][optionName]) {
                  if (buttonGroupsState && buttonGroupsState[key] === true) {
                    return false;

                  } else {
                    let stepFormDataObj = Object.assign({}, stepFormData);
                    if (stepFormDataObj.subscription && stepFormDataObj.subscription[group_name][optionName]) {
                      delete stepFormDataObj.subscription[group_name][optionName];

                      if (subscription_dependencies && subscription_dependencies.length > 0) {
                        for (let s = 0; s < subscription_dependencies.length; s++) {
                          if (stepFormDataObj.subscription[group_name][subscription_dependencies[s]]) {
                            delete stepFormDataObj.subscription[group_name][subscription_dependencies[s]];
                          } else if (stepFormDataObj.subscription[subscription_dependencies[s]]) {
                            delete stepFormDataObj.subscription[subscription_dependencies[s]];
                          }
                        }
                      } else if (subscription_dependency && stepFormDataObj.subscription[subscription_dependency]) {
                        delete stepFormDataObj.subscription[subscription_dependency];
                      }

                      if (stepFormDataObj.subscription[group_name] && Object.keys(stepFormDataObj.subscription[group_name]).length === 0) {
                        delete stepFormDataObj.subscription[group_name];
                      }
                      setStepFormData(stepFormDataObj);
                    }

                    let additionalCostsDataObj = Object.assign({}, additionalCostsData);
                    if (additionalCostsDataObj[group_name] && additionalCostsDataObj[group_name][optionName]) {
                      delete additionalCostsDataObj[group_name][optionName];

                      if (subscription_dependencies && subscription_dependencies.length > 0) {
                        for (let a = 0; a < subscription_dependencies.length; a++) {
                          if (additionalCostsDataObj[group_name][subscription_dependencies[a]]) {
                            delete additionalCostsDataObj[group_name][subscription_dependencies[a]];
                          } else if (additionalCostsDataObj[subscription_dependencies[a]]) {
                            delete additionalCostsDataObj[subscription_dependencies[a]];
                          }
                        }
                      } else if (subscription_dependency && additionalCostsDataObj[subscription_dependency]) {
                        delete additionalCostsDataObj[subscription_dependency];
                      }

                      if (additionalCostsDataObj[group_name] && Object.keys(additionalCostsDataObj[group_name]).length === 0) {
                        delete additionalCostsDataObj[group_name];
                      }
                      setAdditionalCostsData(additionalCostsDataObj);
                    }

                    let childNodes = document.getElementById(key);
                    if (childNodes) {
                      let inputs = childNodes.getElementsByTagName('input');
                      if (inputs) {
                        for (let index = 0; index < inputs.length; ++index) {
                          inputs[index].checked = false;
                          inputs[index].value = "";
                        }
                      }
                    }

                    // set styling - visibility
                    if (disable_dependencies && disable_dependencies.length > 0) {
                      for (let i = 0; i < targetId.length; i++) {
                        let targetEl = document.getElementById(targetId[i]);
                        if (targetEl) {
                          if (targetEl.classList && targetEl.classList.contains("not-hidden")) {
                            targetEl.classList.replace("not-hidden", "hidden");
                          }

                          let parentEl = targetEl.parentElement;
                          let children = targetEl.children;
                          if (children && children.length > 0) {
                            for (let i = 0; i < children.length; i++) {
                              children[i].classList.replace("not-hidden", "hidden");
                            }
                          }

                          let dependencyNodeInputs = targetEl.getElementsByTagName('input');
                          if (dependencyNodeInputs) {
                            for (let index = 0; index < dependencyNodeInputs.length; ++index) {
                              dependencyNodeInputs[index].checked = false;
                              dependencyNodeInputs[index].value = "";
                            }
                          }
                        }
                      }
                    }

                    if (disable_dependency) {
                      let dependencyNode = document.getElementById(disable_dependency);
                      if (dependencyNode && dependencyNode.className && dependencyNode.className === "not-hidden") {
                        let parentEl = dependencyNode.parentElement;
                        let children = parentEl.children;
                        if (children && children.length > 0) {
                          for (let i = 0; i < children.length; i++) {
                            children[i].classList.replace("not-hidden", "hidden");
                          }
                        }

                        let dependencyNodeInputs = dependencyNode.getElementsByTagName('input');
                        if (dependencyNodeInputs) {
                          for (let index = 0; index < dependencyNodeInputs.length; ++index) {
                            dependencyNodeInputs[index].checked = false;
                            dependencyNodeInputs[index].value = "";
                          }
                        }
                      }
                    }

                  }
                } else {
                  console.log("RenderPlanLinesInGroupButton - clickButtonCollapseHandler - subscription -> group_name -> optionName -> NOT found");
                }
              }
            }
          }
        }
      }
    }
  };

  /*
    Add dynamic element
   */
  const clickButtonAddHandler = (e, targetId) => {
    e.preventDefault();

    const targetEl = document.getElementById(targetId);
  };

  const goToPreviousStep = (e, step, newStepFormData) => {
    e.preventDefault();

    // for use embedded code
    if (document.getElementById('anchor-ac-iframe')) {
      document.getElementById('anchor-ac-iframe').
          scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }

    if (step === 2) {
      setStepForm(1);
      setStepFormButtonText("Ja, dit wil ik");
      setActiveStep(2);

      let stepFormDataObj = Object.assign({}, stepFormData);
      setSubscription({});
      stepFormDataObj.subscription = {};
      setStepFormData(stepFormDataObj);
      setButtonGroupsState({});
      setAdditionalCostsData({});

    } else if (step === 3) {
      setStepForm(2);
      setStepFormButtonText("Ga verder");
      setActiveStep(2);
      setStepFormData(stepFormData);

    } else if (step === 4) {
      setStepForm(3);
      setStepFormButtonText("Ga verder");
      setActiveStep(3);
      setStepFormData(stepFormData);

    } else if (step === 5) {
      setStepForm(4);
      setStepFormButtonText("Bevestigen");
      setActiveStep(4);
      setStepFormData(stepFormData);

    }
  };

  const errorValidationHandler = (notificationsArr) => {
    let parentWindow = window.parent;
    let messageData = {};
    messageData.type = "Toast";
    messageData.message = notificationsArr;

    try {
      parentWindow.postMessage(messageData,"*");
    } catch(e) {
      console.log("errorValidationHandler - postMessage failed - error");
    }
    return false;
  };

  const goToNextStep = (e, step) => {
    e.preventDefault();

    // for use embedded code
    if (document.getElementById('anchor-ac-iframe')) {
      document.getElementById('anchor-ac-iframe').
          scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }

    let notificationsToastArr = [];

    // clear plan_line from subscription
    if (stepFormData.subscription && stepFormData.subscription.plan_lines) {
      delete stepFormData.subscription.plan_lines;
    }

    if (step === 1) {
      setStepForm(2);
      setStepFormButtonText("Ga verder");
      setActiveStep(2);

    } else if (step === 2) {

      // validate subscription data
      if (!stepFormData.subscription) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Required fields are missing!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "Required fields are missing!", icon: "warning"});

        }
      }

      const { subscription } = stepFormData,
          {
            // contract_period
            contract_period,
            // wish_date
            wish_date,
            other_wish_date,
          } = subscription;

      if (!contract_period) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Contract period field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "Contract period field is required!", icon: "warning"});

        }
      }

      if (!wish_date) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Wish date field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "Wish date field is required!", icon: "warning"});
        }
      }

      if (wish_date && wish_date === "anders" && !other_wish_date) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Preferred wish date field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "Preferred wish date field is required!", icon: "warning"});
        }
      }

      setStepForm(3);
      setStepFormButtonText("Ga verder");
      setActiveStep(3);

    } else if (step === 3) {

      // check business data
      if (stepFormData.relation) {
        if (!stepFormData.relation.company_name) {
          if (isBusiness && parseInt(isBusiness) === 1) {
            if (apiKey) {
              notificationsToastArr.push({
                title: "Company name field is required!",
                icon: "warn",
              });
              return errorValidationHandler(notificationsToastArr);

            } else {
              return Toast.fire({title: "Company name field is required!", icon: "warning"});
            }

          } else {
            stepFormData.relation.company_name = "";
          }
        }
        if (!stepFormData.relation.reg_no) {
          if (isBusiness && parseInt(isBusiness) === 1) {
            if (apiKey) {
              notificationsToastArr.push({
                title: "Business registration number field is required!",
                icon: "warn",
              });
              return errorValidationHandler(notificationsToastArr);

            } else {
              return Toast.fire({title: "Business registration number field is required!", icon: "warning"});
            }

          } else {
            stepFormData.relation.reg_no = "";
          }
        }
        if (!stepFormData.relation.vat_no) {
          if (isBusiness && parseInt(isBusiness) === 1) {
            if (apiKey) {
              notificationsToastArr.push({
                title: "Business VAT number field is required!",
                icon: "warn",
              });
              return errorValidationHandler(notificationsToastArr);

            } else {
              return Toast.fire({title: "Business VAT number field is required!", icon: "warning"});
            }

          } else {
            stepFormData.relation.vat_no = "";
          }
        }
      }

      // validate personal data
      if (!stepFormData.relation) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Required fields are missing!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "Required fields are missing!", icon: "warning"});
        }
      }

      const { relation } = stepFormData,
          {
            // personal
            title,
            first_name,
            last_name,
            birthdate,
            phone,
            mobile,
            email,
            // provisioning address
            prov_street,
            prov_house_number,
            prov_zipcode,
            prov_city_id,
            prov_country_id,
            // billing address
            billing_street,
            billing_house_number,
            billing_zipcode,
            billing_city_id,
            billing_country_id,
          } = relation,
          { valid } = dispatch(validateEmail(email));

      if (!title) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Title field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "Title field is required!", icon: "warning"});
        }
      }

      if (!first_name) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "First name field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "First name field is required!", icon: "warning"});
        }
      }

      if (!last_name) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Last name field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "Last name field is required!", icon: "warning"});
        }
      }

      // provisioning address
      if (!prov_street) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Provisioning address street field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Provisioning address street field is required!",
            icon: "warning",
          });
        }
      }

      if (!prov_house_number) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Provisioning address house no. field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Provisioning address house no. field is required!",
            icon: "warning",
          });
        }
      }

      if (!prov_zipcode) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Provisioning address postal code field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Provisioning address postal code field is required!",
            icon: "warning",
          });
        }
      }

      if (!prov_city_id) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Provisioning address city field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Provisioning address city field is required!",
            icon: "warning"
          });
        }
      }

      if (!prov_country_id) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Provisioning address country field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Provisioning address country field is required!",
            icon: "warning",
          });
        }
      }

      // billing address
      if (!billing_street) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Billing address street field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Billing address street field is required!", icon: "warning",
          });
        }
      }

      if (!billing_house_number) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Billing address house no. field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Billing address house no. field is required!",
            icon: "warning",
          });
        }
      }

      if (!billing_zipcode) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Billing address postal code field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Billing address postal code field is required!",
            icon: "warning",
          });
        }
      }

      if (!billing_city_id) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Billing address city field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Billing address city field is required!",
            icon: "warning"
          });
        }
      }

      if (!billing_country_id) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Billing address country field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Billing address country field is required!",
            icon: "warning",
          });
        }
      }

      if (!phone && !mobile) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "At least phone or mobile field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire(
              {title: "At least phone or mobile field is required!", icon: "warning"});
        }
      }

      /*if (!phone) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Phone field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire(
              {title: "Phone field is required!", icon: "warning"});
        }
      }*/

      if (!valid) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Please enter a valid email address!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Please enter a valid email address!", icon: "warning",
          });
        }
      }

      setStepForm(4);
      setStepFormButtonText("Ga verder");
      setActiveStep(4);

    } else if (step === 4) {

      // validate bank data
      if (!stepFormData.bank_account) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Required fields are missing!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire(
              {title: "Required fields are missing!", icon: "warning"});
        }
      }

      const { bank_account } = stepFormData,
          {
            // bank
            iban,
            account_holder,
          } = bank_account,
          IBAN = dispatch(validateIBAN(iban));

      if (!iban) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "IBAN field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire(
              {title: "IBAN field is required!", icon: "warning"});
        }
      }

      if (iban && !IBAN.valid) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "IBAN must be valid!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({title: "IBAN must be valid!", icon: "warning"});
        }
      }

      if (!account_holder) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Account holder field is required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Account holder field is required!", icon: "warning",
          });
        }
      }

      setStepForm(5);
      setStepFormButtonText("Bevestigen");
      setActiveStep(5);

    } else if (step === 5) {
      let can_submit = false;

      if (Object.keys(stepFormData.terms_conditions).length === 0 && planDataChoice.terms_and_conditions  && planDataChoice.terms_and_conditions .length > 0) {
        if (apiKey) {
          notificationsToastArr.push({
            title: "Terms & conditions fields are required!",
            icon: "warn",
          });
          return errorValidationHandler(notificationsToastArr);

        } else {
          return Toast.fire({
            title: "Terms & conditions fields are required!", icon: "warning",
          });
        }
      } else if (stepFormData.terms_conditions) {
        for (let i = 0; i < planDataChoice.terms_and_conditions .length; i++) {
          if (!stepFormData.terms_conditions.includes(planDataChoice.terms_and_conditions [i].id)) {
            if (apiKey) {
              notificationsToastArr.push({
                title: "All terms & conditions fields are required!",
                icon: "warn",
              });
              return errorValidationHandler(notificationsToastArr);

            } else {
              return Toast.fire({
                title: "All terms & conditions fields are required!",
                icon: "warning",
              });
            }
          }
        }
      }

      // format offer subscription object - start
      if (stepFormData.subscription) {
        let subscription = stepFormData.subscription;
        let plan_line = [];
        let contract_period = [];
        let wish_date = [];

        if (subscription.contract_period) {
          contract_period.push({ contract_period: subscription.contract_period.value });
          setContractPeriod(subscription.contract_period);
          delete subscription.contract_period;

        } else if (contractPeriod && contractPeriod.value) {
          contract_period.push({ contract_period: contractPeriod.value });
          setContractPeriod(subscription.contract_period);
        }

        if (subscription.wish_date) {
          wish_date.push({ wish_date: subscription.wish_date });
          setWishDate(subscription.wish_date);
          delete subscription.wish_date;

        } else if (wishDate) {
          wish_date.push({ wish_date: wishDate });
          setWishDate(subscription.wish_date);
        }

        let key;
        for (key in subscription) {
          if (subscription[key]) {
            if (Number(subscription[key])) {
              if (Array.isArray(subscription[key]) && subscription[key].length === 1) {
                plan_line.push({
                  id: subscription[key][0],
                  description: key,
                  data: {}
                });

              } else {
                plan_line.push({
                  id: subscription[key],
                  description: key,
                  data: {}
                });

              }
            } else if (Array.isArray(subscription[key])) {
              for (let i = 0; i < subscription[key].length; i++) {
                plan_line.push({
                  id: subscription[key][i],
                  description: key,
                  data: {}
                });
              }

            } else if (typeof subscription[key] === 'object') {
              if (subscription[key].plan_line_id) {
                let dataArr = {};
                if (subscription[key].quantity) {
                  dataArr.quantity = subscription[key].quantity;
                }
                if (subscription[key].value) {
                  dataArr.quantity = subscription[key].value;
                }
                plan_line.push({
                  id: subscription[key].plan_line_id,
                  description: key,
                  data: dataArr
                });

              } else {
                // check for sub objects
                let subKey;
                let subSubscription = subscription[key];
                for (subKey in subSubscription) {
                  if (subSubscription.hasOwnProperty(subKey)) {
                    // we have a sub object
                    if (typeof subSubscription[subKey] === 'object') {
                      let dataArr = {};
                      if (subSubscription[subKey] && subSubscription[subKey].child_id) {
                        // check for child_id_description
                        let childKeyName = subSubscription[subKey].child_id_description;
                        if (subSubscription[childKeyName] && subSubscription[childKeyName].id) {
                          dataArr.action = subSubscription[childKeyName].value;
                          dataArr.value = subSubscription[childKeyName].input_value ? subSubscription[childKeyName].input_value : "";

                          plan_line.push({
                            id: subSubscription[childKeyName].id,
                            description: subSubscription[childKeyName].name,
                            data: dataArr
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        let stepFormDataObj = Object.assign({}, stepFormData);
        subscription['plan_lines'] = plan_line;
        subscription['contract_period'] = contract_period && contract_period[0] && contract_period[0].contract_period ? contract_period[0].contract_period : [];
        subscription['wish_date'] = wish_date && wish_date[0] && wish_date[0].wish_date ? wish_date[0].wish_date : [];
        stepFormDataObj.subscription = subscription;
        setStepFormData(stepFormDataObj);
      }
      // format offer subscription object - end

      // submit data
      handleSubmit(e);
    }
  };

  const formatHeader = (str, json_data) => {
    let jsonDataParsed;
    if (json_data) {
      jsonDataParsed = JSON.parse(json_data);
    }

    let strArr = str.split('|');
    if (strArr && strArr.length > 1) {
      return <span>{strArr[0]}<i>{strArr[1]}</i></span>;
    }
    return str;
  };

  const formatHeaderSummary = (str, groupData) => {
    let jsonDataGroupParsed;
    if (groupData && groupData.json_data) {
      jsonDataGroupParsed = JSON.parse(groupData.json_data);
    }

    if (jsonDataGroupParsed && jsonDataGroupParsed["parent_plan_line_group"] && jsonDataGroupParsed["parent_plan_line_group"].id) {
      let strArr = str.split('|');
      if (strArr && strArr.length > 1) {
        return <span className="pl-1">- {strArr[0]}</span>;
      }
      return <span className="pl-1">- {str}</span>;

    } else {
      let strArr = str.split('|');
      if (strArr && strArr.length > 1) {
        return <span>{strArr[0]}</span>;
      }
      return str;

    }
  };

  const renderStaticWishDate = (data) => {
    // minimum_days_delayed
    // suggested_selected_date

    let wish_date_label_asap = t("offers.wish_date_label_asap", null);
    let wish_date_value_asap = new Date().toISOString().slice(0, 10); //new Date(newDate).toISOString(); //.slice(0, 10);
    let wish_date_label_other = t("offers.wish_date_label_other", null);
    let wish_date_value_other = t("offers.wish_date_value_other", null);

    let staticJson = {
      "type": "radio",
      "label": "Levering (wish_date)",
      "id": "wish_date",
      "name": "wish_date",
      "required": true,
      "mandatory": true,
      "style": "",
      "options": [
        {
          "label": wish_date_label_asap,
          "value": wish_date_value_asap,
          "name": "wish_date",
          "price": false,
          "price_excl": false,
          "price_incl": false,
          "data": null
        },
        {
          "label": wish_date_label_other,
          "value": wish_date_value_other,
          "name": "wish_date",
          "price": false,
          "price_excl": false,
          "price_incl": false,
          "data": {
            "name": "other_wish_date",
            "value": "",
            "element": "datepicker",
            "label": "",
            "format": "dd-MM-yyyy",
            "minDate": 14,
            "json_data": data ? data : null,
            "placeholder": "Select wish date",
            "style": "disabled",
            "style_dependency_key": "wish_date",
            "style_dependency_value": "anders",
          }
        }
      ]
    };

    return (
        <div
            className="pt-2 mb-3 plan-offer-groups plan-offer-wish-date w-100"
            key={`provisioning-wish-date`}
        >
          <h5 className="plan-offer-group-header plan-offer-group-header prov-date-icon">Levering</h5>
          <div className="plan-offer-wish-date-data pb-4">
            {<RadioElement
                name="wish_date"
                options={staticJson.options}
                style={staticJson.style ? staticJson.style : ""}
                setStepFormData={setStepFormData}
                stepFormData={stepFormData}
                stepForm={stepForm}
                dispatch={dispatch}
                subscription={subscription}
                defaultChecked={
                  stepFormData &&
                  stepFormData.subscription &&
                  stepFormData.subscription["wish_date"]
                      ? stepFormData.subscription["wish_date"]
                      : false
                }
                selectedWishDate={wishDate}
                dataChecked={
                  stepFormData &&
                  stepFormData.subscription &&
                  stepFormData.subscription["wish_date"]
                      ? stepFormData.subscription["wish_date"]
                      : false
                }
                handleDynamicFieldData={handleDynamicFieldData}
            />}
          </div>
        </div>
    );
  };

  const renderStaticPeriod = (data) => {
    let periods = [];
    if (data) {
      let jsonPeriodDataParsed = JSON.parse(data);
      jsonPeriodDataParsed.map(({id, period, type, net_days}) => {
        return periods.push({
          label: period,
          value: id,
          price_excl: false,
          price_incl: false,
        });
      });
    }

    let staticJson = {
      "name": "contract_period",
      "type": "react-select",
      "label": "Contractsduur",
      "required": true,
      "options": periods,
      "style": "",
    };

    return (
        <div
            className="pt-2 mb-3 plan-offer-groups plan-offer-contract-period w-100"
            key={`contract-period`}
        >
          <h5 className="plan-offer-group-header plan-offer-contract-period-header contract-period-icon">Contractsduur</h5>
          <div className="plan-offer-contract-period-data pb-4">
            <SelectElement
                name="contract_period"
                label={false}
                options={staticJson.options}
                style={staticJson.style}
                setStepFormData={setStepFormData}
                stepFormData={stepFormData}
                dispatch={dispatch}
                selectedContractPeriod={contractPeriod}
                onChange={(e) => handleDynamicFieldData(e, "contract_period", "select")}
            />
          </div>
        </div>
    );
  };

  const renderConnections = (connections) => {
    return (
        <div className="default-static-options w-100">
          <div className="mb-3 plan-offer-groups plan-offer-connections plan-offer-form w-100">
            <h5 className="plan-offer-group-header power-icon">Connections</h5>
            <div className="plan-offer-connections-data pb-4">
              <div
                  role="group"
                  aria-labelledby={`connections-radio-group`}
                  id={`connections-offer`}
              >
                {connections.map((connection, index) =>
                    <div
                        key={`${formatName(connection.name)}-${index}`}
                        className="custom-radio custom-control custom-controlled-radio"
                        style={{paddingLeft: 0}}
                    >
                      <CustomInput
                          className="mb-1"
                          id={`connection-${index}`}
                          type="radio"
                          name={connection.name ? formatName(connection.name) : `connection-${index}`}
                          label={`${connection.name} ${connection.room ? " - Room " + connection.room : ""}`}
                          value={connection.name}
                          defaultChecked={
                            stepFormData && stepFormData.connection &&
                            stepFormData.connection[formatName(connection.name)] ===
                            formatName(connection.name)
                          }
                          data-is-checked={
                            stepFormData && stepFormData.subscription &&
                            stepFormData.subscription[formatName(connection.name)] ===
                            formatName(connection.name)
                          }
                          style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1", maxWidth: "75%"}}
                          onChange={(e) => handleConnectionChange(e, connection, null, null)}
                      />
                    </div>
                )}
              </div>
            </div>
          </div>
        </div>
    )
  };

  /*
    Form handling
  */
  const handleInputChange = ({ target: { name, value } }) => {
    let stepFormDataObj = Object.assign({}, stepFormData);
    subscription[name] = value;
    stepFormDataObj.subscription = subscription;
    setStepFormData(stepFormDataObj);
  };

  const handleSelectChange = (e, name, lineData, group_id) => {
    let stepFormDataObj = Object.assign({}, stepFormData);
    let additionalCostsDataObj = Object.assign({}, additionalCostsData);
    let dataArrPrice = [];
    let dataPriceTotal = {};

    if (e && e.value) {
      let lineTypeTxtKey = formatPlanLineType(e.line_type, "key");

      additionalCostsDataObj[name] = {};
      additionalCostsDataObj[name][lineTypeTxtKey] = {
        total_price_excl: e.price_excl,
        total_price_incl: e.price_incl,
        line_type: e.line_type,
        group_id: group_id,
        line_id: e.value ? parseInt(e.value) : null
      };
      setAdditionalCostsData(additionalCostsDataObj);

    } else {
      additionalCostsDataObj[name] = {
        total_price_excl: 0,
        total_price_incl: 0,
        line_type: null,
        group_id: group_id,
        line_id: null
      };
      setAdditionalCostsData(additionalCostsDataObj);
    }

    let dataValue = [];
    dataValue.push(e && e.value ? parseInt(e.value) : e && e.key ? e.key : "");

    subscription[name] = e ? e : null; //dataValue;
    stepFormDataObj.subscription = subscription;
    setStepFormData(stepFormDataObj);
  };

  const handleCheckboxChange = (e, name, line_id, lineData, group_id) => {
    let stepFormDataObj = Object.assign({}, stepFormData);
    let dataArr = [];

    // price data array
    let additionalCostsDataObj = Object.assign({}, additionalCostsData);
    let add_price = 0; // for free (= GRATIS)
    let total_price_excl = 0; // for free (= GRATIS)
    let total_price_incl = 0; // for free (= GRATIS)
    let dataPriceArr = [];
    let dataPriceTotal = {};

    let lineTypeTxtKey = formatPlanLineType(lineData.line_type, "key");
    if (subscription && subscription[name] && subscription[name].length > 0) {
      dataArr = [...subscription[name]];

      if (e.target.checked === true) {
        dataArr = [...subscription[name], line_id]; // set just the plan_line id instead of the whole object -> lineData
        if (additionalCostsDataObj[name][lineTypeTxtKey]) {
          dataPriceTotal[lineTypeTxtKey] = {
            total_price_excl: parseFloat(additionalCostsDataObj[name][lineTypeTxtKey].total_price_excl) + parseFloat(lineData.price_excl),
            total_price_incl: parseFloat(additionalCostsDataObj[name][lineTypeTxtKey].total_price_incl) + parseFloat(lineData.price_incl),
            line_type: lineData.line_type,
            line_id: lineData.id,
            group_id: group_id,
          };
        } else {
          // added for NRC on 17-2-2023
          dataPriceTotal[lineTypeTxtKey] = {
            total_price_excl: parseFloat(lineData.price_excl),
            total_price_incl: parseFloat(lineData.price_incl),
            line_type: lineData.line_type,
            line_id: lineData.id,
            group_id: group_id,
          };
        }

      } else {
        dataArr.splice(subscription[name].indexOf(e.target.value), 1);

        if (additionalCostsDataObj[name][lineTypeTxtKey]) {
          dataPriceTotal[lineTypeTxtKey] = {
            total_price_excl: parseFloat(additionalCostsDataObj[name][lineTypeTxtKey].total_price_excl) - parseFloat(lineData.price_excl),
            total_price_incl: parseFloat(additionalCostsDataObj[name][lineTypeTxtKey].total_price_incl) - parseFloat(lineData.price_incl),
            line_type: lineData.line_type,
            line_id: lineData.id,
            group_id: group_id,
          };
        }
      }
    } else {
      if (e && e.target && e.target.checked === true) {
        dataArr.push(line_id);

        if (additionalCostsDataObj[name][lineTypeTxtKey]) {
          dataPriceTotal[lineTypeTxtKey] = {
            total_price_excl: parseFloat(lineData.price_excl),
            total_price_incl: parseFloat(lineData.price_incl),
            line_type: lineData.line_type,
            line_id: lineData.id,
            group_id: group_id,
          };
        }
      }
    }

    additionalCostsDataObj[name][lineTypeTxtKey] = dataPriceTotal[lineTypeTxtKey];
    setAdditionalCostsData(additionalCostsDataObj);
    subscription[name] = dataArr;
    stepFormDataObj.subscription = subscription;
    setStepFormData(stepFormDataObj);
  };

  const removeAdditionalCostsItem = (item) => {
    setAdditionalCostsData((prevState) =>
        prevState.filter((prevItem) => prevItem !== item)
    );
  };

  const handleConnectionChange = (e, connectionData) => {
    if (connectionData) {
      let stepFormDataObj = Object.assign({}, stepFormData);
      stepFormDataObj.connection = connectionData;
      setStepFormData(stepFormDataObj);
    }
  };

  const handleRadioChange = (e, name, lineData, group_id) => {
    let stepFormDataObj = Object.assign({}, stepFormData);
    if (lineData) {
      let lineTypeTxtKey = formatPlanLineType(lineData.line_type, "key");
      let additionalCostsDataObj = Object.assign({}, additionalCostsData);
      additionalCostsDataObj[name] = {};
      additionalCostsDataObj[name][lineTypeTxtKey] = {
        total_price_excl: lineData.price_excl,
        total_price_incl: lineData.price_incl,
        line_type: lineData.line_type,
        group_id: group_id,
        line_id: lineData.id
      };
      setAdditionalCostsData(additionalCostsDataObj);

    } else {
      let additionalCostsDataObj = Object.assign({}, additionalCostsData);
      additionalCostsDataObj[name] = {
        total_price_excl: 0,
        total_price_incl: 0,
        line_type: null,
        group_id: group_id,
        line_id: null
      };
      setAdditionalCostsData(additionalCostsDataObj);
    }

    subscription[name] = lineData && lineData.id ? lineData.id : null;
    stepFormDataObj.subscription = subscription;
    setStepFormData(stepFormDataObj);
  };

  const handleDatePickerChange = (e, name) => {
    console.log("HtmlOffers - handleDatePickerChange - e ->", e);
    console.log("HtmlOffers - handleDatePickerChange - name ->", name);
  };

  const handleStaticFieldData = (data, name, type) => {
    let stepFormDataObj = Object.assign({}, stepFormData);

    if (type === "datepicker") {
      const {newDate} = dispatch(validateDate(data));
      subscription[name] = newDate;
      stepFormDataObj.subscription = subscription;
      setStepFormData(stepFormDataObj);

    } else if (type === "radio") {
      if (name === "wish_date") {
        let el_custom_wish_date = document.getElementById("radio_element_wish_date");
        // get element by id - custom_wish_date
        if (data.target.value && data.target.value === "anders" && el_custom_wish_date) {
          // remove disabled attribute
          el_custom_wish_date.style.cssText = "";
        } else if (data.target.value && data.target.value === "z.s.m." && el_custom_wish_date) {
          // remove disabled attribute
          el_custom_wish_date.style.cssText = "display:none";
          el_custom_wish_date.value = "";
        }
      }

      subscription[name] = data.target.value;
      stepFormDataObj.subscription = subscription;
      setStepFormData(stepFormDataObj);

    } else if (type === "select") {
      subscription[name] = data;
      stepFormDataObj.subscription = subscription;
      setStepFormData(stepFormDataObj);

    } else if (type === "input") {
      subscription[name] = data.target.value;
      stepFormDataObj.subscription = subscription;
      setStepFormData(stepFormDataObj);

    }
  };

  const handleDynamicFieldData = (data, name, type, options, group_id, group_name, line_data, parent) => {
    const stepFormDataObj = Object.assign({}, stepFormData);

    if (type === "datepicker") {
      const newDate = new Date(data).toISOString().slice(0, 10);
      subscription[name] = newDate;
      stepFormDataObj.subscription = subscription;
      setStepFormData(stepFormDataObj);

    } else if (type === "select") {
      if (name !== "wish_date" && name !== "contract_period") {
        if (options && options["price_incl"] && options["price_excl"]) {
          let additionalCostsDataObj = Object.assign({}, additionalCostsData);
          additionalCostsDataObj[name] = {
            total_price_excl: options.price_excl,
            total_price_incl: options.price_incl,
            line_type: options.line_type,
            line_id: line_data && line_data.id ? line_data.id : null,
            group_id: group_id ? group_id : null,
          };
          setAdditionalCostsData(additionalCostsDataObj);

        } else {
          let additionalCostsDataObj = Object.assign({}, additionalCostsData);
          additionalCostsDataObj[name] = {
            total_price_excl: 0,
            total_price_incl: 0,
            line_type: null,
            line_id: line_data && line_data.id ? line_data.id : null,
            group_id: group_id ? group_id : null,
          };
          setAdditionalCostsData(additionalCostsDataObj);
        }
      }

      subscription[name] = data;
      stepFormDataObj.subscription = subscription;
      setStepFormData(stepFormDataObj);

    } else if (type === "radio") {
      if (name !== "wish_date" && name !== "contract_period") {
        if (options && options.name /*&& options["price_incl"] && options["price_excl"]*/) {
          let additionalCostsDataObj = Object.assign({}, additionalCostsData);

          if (parent && parent.name && group_name && group_name === parent.name) {
            let additionalCostsDataGroupObj = Object.assign({}, additionalCostsDataObj[group_name]);
            let lineTypeTxtKey = formatPlanLineType(options.line_type, "key");

            if (additionalCostsDataObj[name] && additionalCostsDataObj[name][lineTypeTxtKey]) {
              additionalCostsDataGroupObj[name][lineTypeTxtKey] = {
                total_price_excl: parseFloat(options.price_excl),
                total_price_incl: parseFloat(options.price_incl),
                line_type: options.line_type, // id
                line_id: line_data && line_data.id ? line_data.id : null,
                group_id: group_id,
                name: options.name,
                label: options.label,
                selected_value: options.value
              };
              additionalCostsDataObj[group_name] = additionalCostsDataGroupObj;

            } else {

              additionalCostsDataGroupObj[name] = {};
              additionalCostsDataGroupObj[name][lineTypeTxtKey] = {
                total_price_excl: parseFloat(options.price_excl),
                total_price_incl: parseFloat(options.price_incl),
                line_type: options.line_type,
                line_id: line_data && line_data.id ? line_data.id : null,
                group_id: group_id ? group_id : null,
                name: options.name,
                label: options.label,
                selected_value: options.value
              };
              additionalCostsDataObj[group_name] = additionalCostsDataGroupObj;
            }

          } else {
            let lineTypeTxtKey = formatPlanLineType(options.line_type, "key");

            additionalCostsDataObj[name] = {};
            additionalCostsDataObj[name][lineTypeTxtKey] = {
              total_price_excl: options.price_excl,
              total_price_incl: options.price_incl,
              line_type: options.line_type,
              line_id: line_data && line_data.id ? line_data.id : null,
              group_id: group_id ? group_id : null,
              name: options.name,
              label: options.label,
              selected_value: options.value
            };
          }
          setAdditionalCostsData(additionalCostsDataObj);

        } else {
          let additionalCostsDataObj = Object.assign({}, additionalCostsData);

          if (group_name) {
            let additionalCostsDataGroupObj = Object.assign({}, additionalCostsDataObj[group_name]);
            additionalCostsDataGroupObj[name] = {};
            additionalCostsDataGroupObj[name][line_data.line_type] = {
              total_price_excl: 0,
              total_price_incl: 0,
              line_type: line_data.line_type,
              line_id: line_data && line_data.id ? line_data.id : null,
              group_id: group_id ? group_id : null,
              name: name,
            };
            additionalCostsDataObj[group_name] = additionalCostsDataGroupObj;

          } else {
            additionalCostsDataObj[name] = {};
            additionalCostsDataObj[name][line_data.line_type] = {
              total_price_excl: 0,
              total_price_incl: 0,
              line_type: line_data.line_type,
              line_id: line_data && line_data.id ? line_data.id : null,
              group_id: group_id ? group_id : null,
              name: name,
            };
          }
          setAdditionalCostsData(additionalCostsDataObj);

        }
      } else {
        if (name === "wish_date") {
          let el_custom_wish_date = document.getElementById("other_wish_date-id");
          // get element by id - custom_wish_date
          if (data.target.value && data.target.value === "anders" && el_custom_wish_date) {
            // remove disabled attribute
            el_custom_wish_date.style.cssText = "";
          } else if (el_custom_wish_date) {
            // remove disabled attribute
            el_custom_wish_date.value = null;
            el_custom_wish_date.selected = null;
          }
        }
      }

      // check if nested options - there is a parent -> group_name
      if (group_name) {
        let groupLineData = {};
        groupLineData.id = line_data && line_data.id ? line_data.id : null;
        groupLineData.data = {};
        groupLineData.data.description = name;
        groupLineData.data.group_name = group_name;
        groupLineData.data.value = data.target.value;

        let subscriptionDataObj;
        if (stepFormData.subscription[group_name]) {
          subscriptionDataObj = {...stepFormData.subscription[group_name]};
        } else {
          subscriptionDataObj = Object.assign({}, subscription[group_name]);
        }

        subscriptionDataObj[name] = {};
        subscriptionDataObj[name].value = data.target.value; // = name
        subscriptionDataObj[name].id = groupLineData.id;
        subscriptionDataObj[name].group_name = group_name;
        subscriptionDataObj[name].parent_name = group_name;
        subscriptionDataObj[name].name = name;
        subscriptionDataObj[name].system_elem_name = name;
        subscriptionDataObj[name].price_excl = options.price_excl ? options.price_excl : 0;
        subscriptionDataObj[name].price_incl = options.price_incl ? options.price_incl : 0;

        subscription[group_name] = subscriptionDataObj;
        stepFormDataObj.subscription = subscription;
        setStepFormData(stepFormDataObj);

      } else {
        subscription[name] = data.target.value;
        stepFormDataObj.subscription = subscription;
        setStepFormData(stepFormDataObj);
      }

    } else if (type === "input") {
      // check if nested options - there is a parent -> group_name
      if (group_name) {
        const subscriptionDataObj = Object.assign({}, subscription[group_name]);
        if (subscriptionDataObj && subscriptionDataObj[name] && subscriptionDataObj[name].value && options && options.name && subscriptionDataObj[name].value === options.name) {
          // set input_value
          subscriptionDataObj[name].input_value = data.target.value;
          subscription[group_name] = subscriptionDataObj;
          stepFormDataObj.subscription = subscription;
          setStepFormData(stepFormDataObj);

        } else {
          subscriptionDataObj[name] = data.target.value;
          subscription[group_name] = subscriptionDataObj;
          stepFormDataObj.subscription = subscription;
          setStepFormData(stepFormDataObj);
        }
      } else {
        subscription[name] = data.target.value;
        stepFormDataObj.subscription = subscription;
        setStepFormData(stepFormDataObj);
      }
    }
  };

  const radioChangeHandler = (e, name, data) => {
    let stepFormDataObj = Object.assign({}, stepFormData);

    subscription[name] = e.target.value;
    stepFormDataObj.subscription = subscription;
    setStepFormData(stepFormDataObj);
  };

  const handleSubmitOffer = (e, index) => {
    e.preventDefault();

    if (index || index === 0) {
      let stepFormDataObj = Object.assign({}, stepFormData);
      stepFormDataObj.offer = { offer_id: planData[index].offer_id };
      setStepFormData(stepFormDataObj);

      setPlanDataChoice(planData[index]);
      setStepForm(2);
      setStepFormButtonText("Ga verder");
      setActiveStep(2);

      // for use embedded code
      if (document.getElementById('anchor-ac-iframe')) {
        document.getElementById('anchor-ac-iframe').
            scrollIntoView(
                {behavior: "smooth", block: "start", inline: "start"});
      }
    }
  };

  const handleSubmit = (e) => {
    // Create/Order PlanOffer
    if (!loader) {
      dispatch(loaderToggle(true));

      if (stepFormData.bank_account) {
        stepFormData.bank_account.bic = "";
      }

      (async () => {
        let notificationsToastArr = [];
        const { ok, data } = !apiKey ? await CreatePlanOfferOrder(stepFormData) : await CreatePublicPlanOfferOrder(stepFormData, apiKey);

        dispatch(loaderToggle(false));

        if (ok) {
          setAvResponse(null);
          setResUpdate(false);
          setActiveStep(1);

          if (apiKey) {
            notificationsToastArr.push({
              title: "Order created successfully",
              icon: "warn",
            });
            return errorValidationHandler(notificationsToastArr);

          } else {
            return Toast.fire({
              title: data && data.message
                  ? data.message
                  : "Converted order into subscription successfully",
              icon: "success",
            });
          }
        } else {
          console.log("HtmlOffers - handleSubmit - error!");
        }
      })();
    }
    dispatch(loaderToggle(false));

  };

  const getStepFormData = () => {
    // get form data from session storage
    let offerFormDataPreview = sessionStorage.getItem("offerFormDataPreview");
    if (offerFormDataPreview) {
      return JSON.parse(offerFormDataPreview);
    }
    return false;
  };

  const saveStepFormData = (data) => {
    // save form data to session storage
    if (data) {
      sessionStorage.setItem("offerFormDataPreview", JSON.stringify(data));
    }
  };

  const checkOtherAddress = (e) => {
    e.preventDefault();

    setAvResponse(null);
    setResUpdate(false);
    setActiveStep(1);
  };

  const sortAlphabetically = (ascending) => {
    return function (a, b) {
      // equal items sort equally
      if (a.priority === b.priority) {
        return 0;
      }
      // nulls sort after anything else
      if (a.priority === null) {
        return 1;
      }
      if (b.priority === null) {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      if (ascending) {
        return a.priority < b.priority ? -1 : 1;
      }
      // if descending, highest sorts first
      return a.priority < b.priority ? 1 : -1;
    };
  };

  /* Debug Info */
  useEffect(() => {
    if (debugInfo) {
      if (stepFormData) {
        console.log("UseEffect - handleDynamicFieldData - RenderPlanLinesInGroupButton - stepFormData ->", stepFormData);
        console.log("UseEffect - handleDynamicFieldData - RenderPlanLinesInGroupButton - additionalCostsData ->", additionalCostsData);
      }
      if (additionalCostsData) {
        console.log("UseEffect - additionalCostsData ->", additionalCostsData);
      }
      if (buttonGroupsState) {
        console.log("UseEffect - buttonGroupsState ->", buttonGroupsState);
      }
      if (mandatoryFields) {
        console.log("UseEffect - mandatoryFields ->", mandatoryFields);
      }
    }
  }, [stepFormData, additionalCostsData, buttonGroupsState, mandatoryFields]);

  useEffect(() => {
    setHtmlArr(planData);
    setResIsUpdated(false);

    const {dispatch, loader} = props;

    if (!loader) {
      dispatch(loaderToggle(true));

      if (planData && planData.length > 1) {
        planData.sort(sortAlphabetically(true));
      }

      if (!formattedAddress && planData && planData[0] &&
          planData[0].location) {

        let stepFormDataObj = Object.assign({}, stepFormData);
        stepFormDataObj.location = planData[0].location;
        setStepFormData(stepFormDataObj);

        const address = formatAddress(planData[0].location);
        if (address && address[0]) {
          setAddressStr(
              address[0].addressStr ? address[0].addressStr : address[0].street
                  ? address[0].street
                  : "");
          setCityId(address[0].city_id ? address[0].city_id : address[0].city
              ? address[0].city
              : "");
          setZipCodeStr(
              address[0].zipCodeStr ? address[0].zipCodeStr : address[0].zipcode
                  ? address[0].zipcode
                  : "");
          setHouseNumberStr(address[0].houseNumberStr
              ? address[0].houseNumberStr
              : address[0].house_number ? address[0].house_number : "");
          setHouseNumberLetterStr(address[0].houseNumberLetterStr
              ? address[0].houseNumberLetterStr
              : address[0].house_number_letter
                  ? address[0].house_number_letter
                  : "");
          setHouseNumberSuffixStr(address[0].houseNumberSuffixStr
              ? address[0].houseNumberSuffixStr
              : address[0].house_number_suffix
                  ? address[0].house_number_suffix
                  : "");
          setStreetStr(
              address[0].streetStr ? address[0].streetStr : address[0].street
                  ? address[0].street
                  : "");
          setFormattedAddress(true);
        }
      }
    }
    dispatch(loaderToggle(false));

  }, [planData, resIsUpdated]);

  useEffect(() => {
    if (windowSize) {
      if (windowSize.innerWidth > 0 && windowSize.innerWidth < 678) {
        setShow(1);
      }
      else
      if (windowSize.innerWidth > 678 && windowSize.innerWidth < 1100) {
        setShow(2);
      }
      else
      if (windowSize.innerWidth > 1100 && windowSize.innerWidth < 1382) {
        setShow(3);
      }
      else
      if (windowSize.innerWidth > 1382 && windowSize.innerWidth < 1846) {
        setShow(4);
      }
      else
      if (windowSize.innerWidth > 1846 && windowSize.innerWidth < 2760) {
        setShow(5);
      }
      else if (windowSize.innerWidth > 2760) {
        setShow(6);
      }
    }
  }, [windowSize]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  return (
      <div className={clientDomain ? `offers-grid ${clientDomain}` : `offers-grid`}>
        <span style={debugInfo
            ? {display: "inline-block"}
            : {display: "none"}}>Width: {windowSize.innerWidth} &nbsp;|&nbsp; Prices are {pricesIncludedVat ? `incl.` : `excl.`} VAT</span>
        {stepForm === 1 && planData && Array.isArray(planData) && planData[0] &&
        <div className="container px-0 mt-4">
          <div className="row">
            {!checkOtherAddressDisabled &&
            <div className={`col-5`}>
              <div className="w-100 mb-0" style={{ marginLeft: "5px" }}>
                <h3 className="mb-0" style={ clientInlineStyles && clientInlineStyles["h3_size"] && clientInlineStyles["h3_color"] ? { fontSize: clientInlineStyles["h3_size"], color: clientInlineStyles["h3_color"] } : {}}>
                  <a href="#" className="mr-2 mt-n2" onClick={(e) => checkOtherAddress(e)}>
                    <FontAwesomeIcon
                        color="primary"
                        name="step-back-icon"
                        className="step-back-icon"
                        icon={faArrowCircleLeft}
                        style={clientInlineStyles && clientInlineStyles["btnPrimaryBgColor"] && clientInlineStyles["btnPrimaryColor"] ? { color: clientInlineStyles["btnPrimaryBgColor"], backgroundColor: clientInlineStyles["btnPrimaryColor"], borderColor: clientInlineStyles["btnPrimaryBgColor"]} : {}}
                    />
                  </a>
                  <span>{t("offers.back_btn_txt", null)}</span>
                </h3>
              </div>
            </div>}
            <div className={!checkOtherAddressDisabled ? `col-7`: `col-12`}>
              <div className={!checkOtherAddressDisabled ? `offer-input-address text-right text-truncate` : `offer-input-address text-center text-truncate`} style={!checkOtherAddressDisabled ? {marginRight: "5px"} : {paddingRight:"0"}}>
                {/* address */}
                <FontAwesomeIcon color="grey" name="address-icon" className="address-icon mr-2" icon={faHome} />
                {addressStr}
              </div>
            </div>
          </div>
        </div>
        }

        <div className="container px-0 mt-4" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            {stepForm === 1 && planData && Array.isArray(planData) &&
            <div className="col-12">
              <div className="offer-carousel">
                <Carousel show={show}>
                  {planData.map(({
                                   plan_description_long,
                                   address,
                                   offer_id,
                                   priority,
                                   json_data,
                                   plan_type,
                                   groups,
                                   wishDate,
                                   termsConditions,
                                   connections,
                                   contractPeriods,
                                   minimum_deposit,
                                   minimum_mrc_excl,
                                   minimum_mrc_incl,
                                   minimum_nrc_excl,
                                   minimum_nrc_incl,
                                   minimum_qrc_excl,
                                   minimum_qrc_incl,
                                   minimum_yrc_excl,
                                   minimum_yrc_incl,
                                 }, index) => (
                      <div className="col-auto" key={`offer-carousel-item-${index}`}>
                        <Form className="plan-offer-form" data-offer-index={index} onSubmit={(e) => handleSubmitOffer(e, index)}>
                          <div className="card plan-offer d-flex align-items-start flex-column" style={{overflow: "hidden", margin: "0 auto"}}>
                            {stepForm === 1 && plan_description_long && plan_description_long !== "" &&
                            <div className="pt-2 px-2 mb-2 plan-offer-header card-header">
                              {formatOfferDescription(plan_description_long)}
                            </div>
                            }
                            <div className="card-body" style={{ height: 0, padding: 0 }}> </div>
                            <div className="card-footer plan-offer-footer w-100">
                              <div className="plan-offer-price">
                                {formatCurrency({
                                  minimum_mrc_excl: minimum_mrc_excl,
                                  minimum_mrc_incl: minimum_mrc_incl,
                                  minimum_nrc_excl: minimum_nrc_excl,
                                  minimum_nrc_incl: minimum_nrc_incl,
                                  minimum_qrc_excl: minimum_qrc_excl,
                                  minimum_qrc_incl: minimum_qrc_incl,
                                  minimum_yrc_excl: minimum_yrc_excl,
                                  minimum_yrc_incl: minimum_yrc_incl,
                                  plan_type: plan_type,
                                })} <span className="text text-period">p/mnd</span>
                                <div className="text-center" style={{ display: "none" }}>
                                  {pricesIncludedVat ? `(incl. VAT)` : `(excl. VAT)`}
                                  <br />
                                  (priority: {priority ? priority : `null`})
                                </div>
                              </div>
                              <div className="plan-offer-button">
                                <button className="btn btn-block plan-offer-button-order">
                                  {stepFormButtonText}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                  ))}
                </Carousel>
              </div>
            </div>
            }

            {planDataChoice && stepForm !== 1 &&
            <div className={`col-md-8`} style={{ height: "100%" }}>
              <Form id="offer-plan-form" className="plan-offer-form" data-offer-index={planDataChoice.offer_id} onSubmit={(e) => handleSubmit(e)}>
                {stepForm === 2 &&
                <div className="w-100 mb-0">
                  <h3 className="mb-4" style={ clientInlineStyles && clientInlineStyles["h3_size"] && clientInlineStyles["h3_color"] ? { fontSize: clientInlineStyles["h3_size"], color: clientInlineStyles["h3_color"] } : {}}>
                    <a href="#" className="mr-2 mt-n2"
                       onClick={(e) => goToPreviousStep(e, stepForm, stepFormData)}>
                      <FontAwesomeIcon color="primary"
                                       name="step-back-icon"
                                       className="step-back-icon"
                                       icon={faArrowCircleLeft}
                                       style={clientInlineStyles && clientInlineStyles["btnPrimaryBgColor"] && clientInlineStyles["btnPrimaryColor"] ? { color: clientInlineStyles["btnPrimaryBgColor"], backgroundColor: clientInlineStyles["btnPrimaryColor"], borderColor: clientInlineStyles["btnPrimaryBgColor"]} : {}}
                      />
                    </a>
                    <span>{t("offers.choose_options", null)}:</span>
                  </h3>
                </div>
                }

                {stepForm === 2 && planDataChoice["connections"] && planDataChoice["connections"].length > 1 &&
                renderConnections(planDataChoice["connections"])
                }

                {stepForm === 2 && planDataChoice.groups && planDataChoice.groups.length > 0 &&
                Object.values(planDataChoice.groups).map((group, groupIndex) => {
                  if (group.id > 0) {
                    return (
                        <div
                            className={`plan-offer-groups plan-offer-group ${checkGroupJsonData(group.json_data)} plan-offer-group-index-${groupIndex} w-100`}
                            data-group-index={groupIndex}
                            data-group-id={group.id ? group.id : groupIndex}
                            id={`${checkGroupJsonDataId(group.json_data, groupIndex)}`}
                            key={`${group.id}`}
                            data-key={`${group.id}`}
                        >
                          <React.Fragment>
                            {group.header &&
                            <h5
                                key={`header-${groupIndex}`}
                                className={`plan-offer-group-header ${checkGroupJsonData(group.json_data)}`}
                            >
                              {formatHeader(group.header, group.json_data)}
                            </h5>
                            }

                            <div className={`plan-offer-group-description ${checkGroupJsonData(group.json_data)}`}>
                              {group.text &&
                              <div
                                  dangerouslySetInnerHTML={{ __html: group.text }}
                                  key={`description-${groupIndex}`}
                              />
                              }

                              {group.type && group.type === "none" && group.plan_lines && group.plan_lines.length > 0 &&
                              RenderPlanLinesInGroupNoneVisible(group.plan_lines, group.header, group.json_data, group.description, group.id)
                              }

                              {group.type && group.type === "radio" && group.plan_lines && group.plan_lines.length > 0 &&
                              RenderPlanLinesInGroupRadio(group.plan_lines, group.header, group.json_data, group.description, group.id)
                              }

                              {group.type && group.type === "checkbox" && group.plan_lines && group.plan_lines.length > 0 &&
                              RenderPlanLinesInGroupCheckbox(group.plan_lines, group.header, group.json_data, group.description, group.id)
                              }

                              {group.type && group.type === "select" && group.plan_lines && group.plan_lines.length > 0 &&
                              RenderPlanLinesInGroupSelect(group.plan_lines, group.header, group.json_data, group.description, group.id)
                              }

                              {group.type && group.type === "button" && group.plan_lines && group.plan_lines.length > 0 &&
                              RenderPlanLinesInGroupButton(group.plan_lines, group.header, group.json_data, group.description, group.id)
                              }
                            </div>
                          </React.Fragment>
                        </div>
                    )
                  }
                })
                }

                {/* Default options - contract periods & wish date */}
                {stepForm === 2 &&
                <div className="default-static-options w-100">
                  {renderStaticPeriod(
                      planDataChoice && planDataChoice["contract_periods"] ? planDataChoice["contract_periods"] : false
                  )}
                  {renderStaticWishDate(
                      planDataChoice && planDataChoice.wish_date ? planDataChoice.wish_date : false
                  )}
                </div>
                }

                {/* Personal information (static) */}
                {stepForm === 3 &&
                <div className="w-100 mb-0">
                  <h3 className="mb-4" style={ clientInlineStyles && clientInlineStyles["h3_size"] && clientInlineStyles["h3_color"] ? { fontSize: clientInlineStyles["h3_size"], color: clientInlineStyles["h3_color"] } : {}}>
                    <a href="#" className="mr-2 mt-n2" onClick={(e) => goToPreviousStep(e, stepForm)}>
                      <FontAwesomeIcon
                          color="primary"
                          name="step-back-icon"
                          className="step-back-icon" icon={faArrowCircleLeft}
                          style={clientInlineStyles && clientInlineStyles["btnPrimaryBgColor"] && clientInlineStyles["btnPrimaryColor"] ? { color: clientInlineStyles["btnPrimaryBgColor"], backgroundColor: clientInlineStyles["btnPrimaryColor"], borderColor: clientInlineStyles["btnPrimaryBgColor"]} : {}}
                      />
                    </a>
                    <span>{t("offers.header_user_data", null)}:</span>
                  </h3>
                  <OfferPersonal
                      stepForm={stepForm}
                      stepFormData={stepFormData}
                      setStepFormData={setStepFormData}
                      setStepFormDataValidated={setStepFormDataValidated}
                      stepFormDataValidated={stepFormDataValidated}
                      defaultCountry={defaultCountry}
                      planData={planData}
                      provisioningAdress={addressStr}
                      provisioningCityId={cityId}
                      cityOpts={cityOpts}
                      countryOpts={countryOpts}
                      zipCodeStr={zipCodeStr}
                      houseNumberStr={houseNumberStr}
                      houseNumberLetterStr={houseNumberLetterStr}
                      houseNumberSuffixStr={houseNumberSuffixStr}
                      streetStr={streetStr}
                      apiKey={apiKey ? apiKey : null}
                      isBusiness={isBusiness}
                  />
                </div>
                }

                {/* Bank information (static) */}
                {stepForm === 4 &&
                <div className="w-100 mb-0">
                  <h3 className="mb-4" style={ clientInlineStyles && clientInlineStyles["h3_size"] && clientInlineStyles["h3_color"] ? { fontSize: clientInlineStyles["h3_size"], color: clientInlineStyles["h3_color"] } : {}}>
                    <a href="#" className="mr-2 mt-n2" onClick={(e) => goToPreviousStep(e, stepForm)}>
                      <FontAwesomeIcon
                          color="primary"
                          name="step-back-icon"
                          className="step-back-icon"
                          icon={faArrowCircleLeft}
                          style={clientInlineStyles && clientInlineStyles["btnPrimaryBgColor"] && clientInlineStyles["btnPrimaryColor"] ? { color: clientInlineStyles["btnPrimaryBgColor"], backgroundColor: clientInlineStyles["btnPrimaryColor"], borderColor: clientInlineStyles["btnPrimaryBgColor"]} : {}}
                      />
                    </a>
                    <span>{t("offers.header_bank_data", null)}:</span>
                  </h3>
                  <OfferBank
                      stepFormData={stepFormData}
                      setStepFormData={setStepFormData}
                  />
                </div>
                }

                {/* Overview information - terms & conditions */}
                {stepForm === 5 &&
                <div className="w-100 mb-0">
                  <h3 className="mb-4" style={ clientInlineStyles && clientInlineStyles["h3_size"] && clientInlineStyles["h3_color"] ? { fontSize: clientInlineStyles["h3_size"], color: clientInlineStyles["h3_color"] } : {}}>
                    <a href="#" className="mr-2 mt-n2" onClick={(e) => goToPreviousStep(e, stepForm)}>
                      <FontAwesomeIcon
                          color="primary"
                          name="step-back-icon"
                          className="step-back-icon"
                          icon={faArrowCircleLeft}
                          style={clientInlineStyles && clientInlineStyles["btnPrimaryBgColor"] && clientInlineStyles["btnPrimaryColor"] ? { color: clientInlineStyles["btnPrimaryBgColor"], backgroundColor: clientInlineStyles["btnPrimaryColor"], borderColor: clientInlineStyles["btnPrimaryBgColor"]} : {}}
                      />
                    </a>
                    <span>{t("offers.header_overview_data", null)}:</span>
                  </h3>
                  <Overview
                      stepFormData={stepFormData}
                      setStepFormData={setStepFormData}
                      termsConditions={planDataChoice && planDataChoice.terms_and_conditions ? planDataChoice.terms_and_conditions : false}
                      clientInlineStyles={clientInlineStyles ? clientInlineStyles : null}
                  />
                </div>
                }

                {stepForm !== 1 &&
                <div className="d-flex justify-content-between plan-offer-button">
                  <button
                      className="btn plan-offer-button-back"
                      onClick={(e) => goToPreviousStep(e, stepForm)}
                  >
                    <FontAwesomeIcon color="grey" name="step-previous-icon" className="step-previous-icon mr-2" icon={faArrowLeft} />
                    Terug
                  </button>
                  <button
                      className="btn plan-offer-button-order"
                      onClick={(e) => goToNextStep(e, stepForm, stepFormData)}
                  >
                    {stepFormButtonText}
                    <FontAwesomeIcon color="primary" name="step-next-icon" className="step-next-icon ml-2" icon={faArrowRight} />
                  </button>
                </div>
                }

              </Form>
            </div>
            }

            {/* Overview & Summary */}
            {stepForm !== 1 &&
            <div className={`col-md-4 d-flex flex-column sticky-top right-column`} style={{ flexGrow: 1, alignSelf: "flex-start" }}>
              <div className="wrapper-offer-summary">
                <div className="plan-offer-summary">
                  {/* loop through data for types -> 3 (=MRC) - 2 (=NRC) - 6 (=Deposit)  */}
                  {summaryLineTypeOpts().map((typeOpt, typeOptIndex) => {
                    if (planDataChoice.groups && planDataChoice.groups.length > 0) {
                      let isVisible = checkGroupVisibility(planDataChoice.groups, typeOpt.line_type_id);
                      console.log("plan-offer-summary - checkGroupVisibility - isVisible ->", isVisible);

                      if (isVisible && isVisible[typeOpt.visible_key_arr] && isVisible[typeOpt.visible_key_arr].length > 0) {
                        return (
                        <div
                          className={`plan-offer-price${typeOptIndex === 0 ? " mt-0" : ""}`}
                          key={`plan-offer-price-${typeOptIndex}`}
                        >
                        <h5>
                          {typeOpt.title_lang}
                          {typeOpt.period && <span className="small-header">{typeOpt.period}</span>}
                        </h5>
                        {planDataChoice.groups && planDataChoice.groups.length >
                        0 && Object.values(planDataChoice.groups).
                            map((group, groupIndex) => {
                              console.log("plan-offer-summary - planDataChoice.groups - group ->", group);

                              let dataLineType = formatTotalPlanLineTypeSummary(
                                  group, typeOpt.line_type_id);
                              if (dataLineType && dataLineType[group.id] &&
                                  dataLineType[group.id].group_id &&
                                  dataLineType[group.id].type !== "checkbox" &&
                                  dataLineType[group.id].type !== "button") {

                                console.log("dataLineType - dataLineType[group.id] ->", dataLineType[group.id]);

                                return (<div
                                    className="plan-offer-summary-description row"
                                    key={`summary-type-${dataLineType[group.id].group_id}`}
                                >
                                  <div className="col-7">
                                    {dataLineType[group.id].header &&
                                    formatHeaderSummary(
                                        dataLineType[group.id].header, group)} <i
                                      style={debugInfo
                                          ? {display: "inline-block"}
                                          : {display: "none"}}>
                                    {`(GroupID: ${dataLineType[group.id].group_id} - type !== checkbox || button${dataLineType[group.id].groupIsMandatory
                                        ? ` - ${dataLineType[group.id].groupIsMandatory}`
                                        : ""})`}</i>
                                  </div>
                                  <div className="col-5 text-right">
                                    <span
                                        id={`plan-line-costs-${dataLineType[group.id].group_id}`}
                                        data-type={dataLineType[group.id].type}
                                    >
                                      {formatTotalPlanLineAdditionalCostsCurrency(
                                          dataLineType[group.id],
                                          typeOpt.line_type_id)}
                                      {/*formatTotalPlanLineGroupAdditionalCostsCurrency(dataLineType[group.id], typeOpt.line_type_id)*/}
                                    </span>
                                  </div>
                                </div>)
                              } else if (dataLineType &&
                                  dataLineType[group.id] &&
                                  dataLineType[group.id].group_id) {
                                return (<div
                                        className="plan-offer-summary-description row"
                                        key={`summary-type-${dataLineType[group.id].group_id}`}
                                    >
                                      <div className="col-12">
                                    <span
                                        className="plan-offer-summary-group-header">
                                      {dataLineType[group.id].header &&
                                      formatHeaderSummary(
                                          dataLineType[group.id].header)} <i
                                        style={debugInfo
                                            ? {display: "inline-block"}
                                            : {display: "none"}}>
                                      {`(GroupID: ${dataLineType[group.id].group_id} - type === checkbox || button${dataLineType[group.id].groupIsMandatory
                                          ? ` - ${dataLineType[group.id].groupIsMandatory}`
                                          : ""})`}</i>
                                    </span>
                                        {dataLineType[group.id].items &&
                                        dataLineType[group.id].items.length >
                                        0 && dataLineType[group.id].items.map(
                                            (item, itemIndex) => {
                                              console.log(
                                                  "plan-offer-summary - group items - item ->",
                                                  item);
                                              {/* Check parent child relation for item - Show sub items for group */
                                              }
                                              let parentChildRelation = checkTotalPlanLineParentChildRelation(
                                                  item);
                                              if (parentChildRelation) {
                                                console.log(
                                                    "plan-offer-summary - group items - item - parentChildRelation ->",
                                                    parentChildRelation);
                                                if (parentChildRelation.display) {
                                                  return (<div
                                                          key={`summary-type-item-${itemIndex}`}
                                                      >
                                                        <div
                                                            className="d-flex justify-content-between">
                                                          <span className="pl-1"
                                                                data-parent={parentChildRelation.key_name}>- {parentChildRelation.label}
                                                            <i style={debugInfo
                                                                ? {display: "inline-block"}
                                                                : {display: "none"}}>(ID: {parentChildRelation.id} ParentKey: {parentChildRelation.key_name})</i></span>
                                                          {parentChildRelation.is_mandatory
                                                              ? (
                                                                  <span>{pricesIncludedVat
                                                                      ? (parseFloat(
                                                                              parentChildRelation.price_incl_vat) ===
                                                                          0
                                                                              ? `GRATIS`
                                                                              : debugInfo
                                                                                  ? t(
                                                                                  "currency_format",
                                                                                  {
                                                                                    number: Number(
                                                                                        parentChildRelation.price_incl_vat)
                                                                                  }) +
                                                                                  " (in ism)"
                                                                                  : t("currency_format",
                                                                                      {
                                                                                        number: Number(
                                                                                            parentChildRelation.price_incl_vat)
                                                                                      }))
                                                                      : (parseFloat(
                                                                              parentChildRelation.price_excl_vat) ===
                                                                          0
                                                                              ? `GRATIS`
                                                                              : debugInfo
                                                                                  ? t(
                                                                                  "currency_format",
                                                                                  {
                                                                                    number: Number(
                                                                                        parentChildRelation.price_excl_vat)
                                                                                  }) +
                                                                                  " (ex ism)"
                                                                                  : t("currency_format",
                                                                                      {
                                                                                        number: Number(
                                                                                            parentChildRelation.price_excl_vat)
                                                                                      }))}
                                                </span>)
                                                              : (<span>
                                                  {checkTotalPlanLineAdditionalCostsCurrency(
                                                      dataLineType[group.id],
                                                      typeOpt.line_type_id,
                                                      item, false)}
                                                </span>)}
                                                        </div>
                                                        {parentChildRelation.child &&
                                                        <div
                                                            className="d-flex justify-content-between">
                                                          {console.log(
                                                              "plan-offer-summary - group items - item - parentChildRelation.child (checkTotalPlanLineChildAdditionalCostsCurrency) ->",
                                                              parentChildRelation.child)}
                                                          <span
                                                              className="pl-3">- {parentChildRelation.child.description}
                                                            <i style={debugInfo
                                                                ? {display: "inline-block"}
                                                                : {display: "none"}}>(ID: {parentChildRelation.child.id}, AdditionalKey: {parentChildRelation.child.key_name})</i></span>
                                                          {parentChildRelation.child.is_mandatory
                                                              ? (
                                                                  <span>{pricesIncludedVat
                                                                      ? (parseFloat(
                                                                              parentChildRelation.child.price_incl_vat) ===
                                                                          0
                                                                              ? `GRATIS`
                                                                              : debugInfo
                                                                                  ? t(
                                                                                  "currency_format",
                                                                                  {
                                                                                    number: Number(
                                                                                        parentChildRelation.child.price_incl_vat)
                                                                                  }) +
                                                                                  " (in ism)"
                                                                                  : t("currency_format",
                                                                                      {
                                                                                        number: Number(
                                                                                            parentChildRelation.child.price_incl_vat)
                                                                                      }))
                                                                      : (parseFloat(
                                                                              parentChildRelation.child.price_excl_vat) ===
                                                                          0
                                                                              ? `GRATIS`
                                                                              : debugInfo
                                                                                  ? t(
                                                                                  "currency_format",
                                                                                  {
                                                                                    number: Number(
                                                                                        parentChildRelation.child.price_excl_vat)
                                                                                  }) +
                                                                                  " (ex ism)"
                                                                                  : t("currency_format",
                                                                                      {
                                                                                        number: Number(
                                                                                            parentChildRelation.child.price_excl_vat)
                                                                                      }))}
                                                  </span>)
                                                              : (<span>
                                                    {checkTotalPlanLineChildAdditionalCostsCurrency(
                                                        dataLineType[group.id],
                                                        typeOpt.line_type_id,
                                                        item, false)}
                                                  </span>)}
                                                        </div>}
                                                      </div>)
                                                }

                                              } else {
                                                return (<div
                                                        className="d-flex justify-content-between"
                                                        key={`summary-type-item-${itemIndex}`}
                                                    >
                                                      <span
                                                          className="pl-1">- {item.description}
                                                        <i style={debugInfo
                                                            ? {display: "inline-block"}
                                                            : {display: "none"}}>({item.id})</i></span>
                                                      {item.is_mandatory ? (
                                                          <span>{pricesIncludedVat
                                                              ? (parseFloat(
                                                                      item.price_incl_vat) ===
                                                                  0
                                                                      ? `GRATIS`
                                                                      : debugInfo
                                                                          ? t(
                                                                          "currency_format",
                                                                          {
                                                                            number: Number(
                                                                                item.price_incl_vat)
                                                                          }) +
                                                                          " (in ism)"
                                                                          : t("currency_format",
                                                                              {
                                                                                number: Number(
                                                                                    item.price_incl_vat)
                                                                              }))
                                                              : (parseFloat(
                                                                      item.price_excl_vat) ===
                                                                  0
                                                                      ? `GRATIS`
                                                                      : debugInfo
                                                                          ? t(
                                                                          "currency_format",
                                                                          {
                                                                            number: Number(
                                                                                item.price_excl_vat)
                                                                          }) +
                                                                          " (ex ism)"
                                                                          : t("currency_format",
                                                                              {
                                                                                number: Number(
                                                                                    item.price_excl_vat)
                                                                              }))}
                                            </span>) : (<span>
                                              {/* Check if item is added - else show 0,00 */}
                                                            {/*pricesIncludedVat ? (
                                                  parseFloat(item.price_incl_vat) === 0 ? `GRATIS` :
                                                  t("currency_format", {number: Number(item.price_incl_vat)}) + " (in isNm) "
                                              ) : (
                                                  parseFloat(item.price_excl_vat) === 0 ? `GRATIS` :
                                                  t("currency_format", {number: Number(item.price_excl_vat)}) + " (ex isNm) "
                                              )*/}
                                                            {/*<i style={{ display: "none" }}>{formatTotalPlanLineAdditionalCostsCurrency(dataLineType[group.id], typeOpt.line_type_id, item.id, false)}</i>*/}
                                                            {checkTotalPlanLineAdditionalCostsCurrency(
                                                                dataLineType[group.id],
                                                                typeOpt.line_type_id,
                                                                item, false)}
                                                            {/*item.price_incl_vat && parseFloat(item.price_incl_vat) > 0 && item.price_excl_vat && parseFloat(item.price_excl_vat) > 0 ? (
                                                 formatTotalPlanLineAdditionalCostsCurrency(dataLineType[group.id], typeOpt.line_type_id)
                                            ) : (
                                                `GRATIS (non mandatory)`
                                            )*/}
                                            </span>)}
                                                    </div>)
                                              }
                                            })}
                                      </div>
                                    </div>)
                              }
                            })}
                        </div>)
                      }
                  }})}
                  <div className="plan-offer-price">
                    <h5>{t("offers.overview_total", null)}</h5>
                    {summaryLineTypeOpts().map((summaryTypeOpt, summaryTypeOptIndex) => {
                      return (
                          <div className="row" key={`summary-type-${summaryTypeOptIndex}`}>
                            <div className="col-6">
                              <span className="text text-left">{summaryTypeOpt.summary_total_title}</span>
                            </div>
                            <div className="col-6 text-right">
                              {/* formatTotalPlanLineTypeSummary by line type id */}
                              {formatTotalPlanLineCurrency(summaryTypeOpt.line_type_id)}
                            </div>
                          </div>
                      )
                    })}
                  </div>
                </div>

                {!apiKey &&
                <div className="plan-offer-summary mt-3">
                  <h5>Offer data</h5>
                  <div className="plan-offer-summary-description text-truncate" title={planDataChoice.offer_id}>
                    {planDataChoice.offer_id}
                  </div>
                  <div className="plan-offer-summary-description mt-2">
                    <button color="primary" className="btn btn-primary mr-1 mb-1" onClick={(e) => setPricesIncludedVat(!pricesIncludedVat)}>
                      {pricesIncludedVat === false ? `Set incl. VAT` : `Set excl. VAT`}
                    </button>
                    <button color="primary" className="btn btn-primary mr-1 mb-1" onClick={(e) => setDebugInfo(!debugInfo)}>
                      {debugInfo === false ? `Show debug ON` : `Hide debug OFF`}
                    </button>
                  </div>
                </div>}

              </div>
            </div>
            }

          </div>
        </div>
      </div>
  );
};

const mapStateToProps = ({dispatch, loader}) => ({dispatch, loader});

export default withRouter(withTranslation()(connect(mapStateToProps)(HtmlOffers)));
