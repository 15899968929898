import ApiService from '../services/apiService';

const Api = new ApiService(),
      GetAPI = Api.getAPI,
      PostAPI = Api.postAPI
      //UploadPostAPI = Api.uploadPostAPI;

/*const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        return await method ? method(endpoint, params) : GetAPI(endpoint,
            params);
    })();
};*/

const asyncFuncPost = (endpoint, params, method) => {
    return (async () => {
        return await method ? method(endpoint, params) : PostAPI(endpoint,
            params);
    })();
};

const ProcessCdr = (params) => {
    console.log('ProcessCdr params', params);
    return asyncFuncPost(`admin_tools/job/cdr`, params, PostAPI)
};

export {
    ProcessCdr
}