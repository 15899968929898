import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, CustomInput, Form, Input } from "reactstrap";
import { AddNumberRange, UpdateNumberRange } from '../../../controllers/number_ranges';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import Toast from "../../../components/Toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

const numberRangeTypeOpts = [
        { value: "invoice_no", label: "Invoice number" },
        { value: "customer_number", label: "Customer number" },
        { value: "journal_no", label: "Journal number" },
        { value: "entry_no", label: "Entry number" },
        { value: "subscription_no", label: "Subscription number" }
    ];

class NumberRangeForm extends React.Component {
    constructor(props) {
        super(props);

        const { selectedData } = this.props;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'number range',
            number_range: selectedData ? selectedData : {
                randomized: false
            }
        }
    }

    handleInputChange({ target: { name, value }}) {
        let number_range = Object.assign({}, this.state.number_range);
        
        number_range[name] =  value;

        this.setState({ number_range })
    }

    handleSelectChange(name, data) {
        let number_range = Object.assign({}, this.state.number_range);

        number_range[name] =  data.value;
        
        this.setState({
            number_range,
            [name]: data
        })
    }

    handleCheckChange({ target: { name, checked } }) {  
        let number_range = Object.assign({}, this.state.number_range);
        
        number_range[name] =  checked;

        this.setState({ number_range })
    }

    handleSubmit(e) {
        e.preventDefault();

        let number_range = Object.assign({}, this.state.number_range);
        const { description, format, type, start, end, current } = number_range,
            { id, selectedData, loader, dispatch } = this.props;

        //Validations
        if (!description) {
            return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
        }

        if (!format) {
            return Toast.fire({ title: 'Format field is required!', icon: 'warning' })
        }

        if (!type) {
            return Toast.fire({ title: 'Type field is required!', icon: 'warning' })
        }

        if (!start) {
            return Toast.fire({ title: 'Start field is required!', icon: 'warning' })
        }

        if (!end) {
            return Toast.fire({ title: 'End field is required!', icon: 'warning' })
        }

        number_range.tenant_id = id;
        number_range.current = parseInt(current);

        if (!loader) {
            dispatch(loaderToggle(true));

            if (selectedData) {
    
                (async () => {                    
                    const { ok } = await UpdateNumberRange(number_range.id, number_range);

                    this.handleOk(ok)
                })()
            } else {
                (async () => {
                    const { ok } = await AddNumberRange(number_range);

                    this.handleOk(ok)
                })()
            }
        }        
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update()
        }
    };

    componentDidMount() {
        const { selectedData } = this.props;

        if (this.props.selectedData) {
            this.setState({ type: numberRangeTypeOpts.find(type => type.value === selectedData.type)})
        }
    }

    render() {
        const { show, hide } = this.props,
            { formType, formName, number_range, type } = this.state,
            { description, format, start, end, randomized, current } = number_range ? number_range : null;

        return (
            <Modal className="form-subscription-lines" isOpen={ show } toggle={ hide } centered>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <ModalHeader>
                        <span>{ formType } { formName }</span>
                    </ModalHeader>
                    <ModalBody key="0" className="mt-3 mb-3">
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Description:</label>
                            </Col>
                            <Col md={9}>
                                <Input 
                                    name="description" 
                                    placeholder="Description" 
                                    value={ description ? description : '' } 
                                    onChange={ (e) => this.handleInputChange(e) } 
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Format:</label>
                            </Col>
                            <Col md={9}>
                                <Input 
                                    name="format" 
                                    placeholder="Format" 
                                    value={ format ? format : '' } 
                                    onChange={ (e) => this.handleInputChange(e) } 
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Type:</label>
                            </Col>
                            <Col md={9}>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={ numberRangeTypeOpts }
                                    value={ type ? type : null } 
                                    onChange={(e) => this.handleSelectChange('type', e)}
                                    placeholder="Type"
                                    maxMenuHeight={300}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Start:</label>
                            </Col>
                            <Col md={9}>
                                <Input 
                                    name="start" 
                                    placeholder="Start" 
                                    value={ start ? start : '' } 
                                    onChange={ (e) => this.handleInputChange(e) } 
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">End:</label>
                            </Col>
                            <Col md={9}>
                                <Input 
                                    name="end" 
                                    placeholder="End" 
                                    value={ end ? end : '' } 
                                    onChange={ (e) => this.handleInputChange(e) } 
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Last saved (nr):</label>
                            </Col>
                            <Col md={9}>
                                <Input
                                    name="current"
                                    placeholder="Last saved number"
                                    value={ current ? current : '' }
                                    onChange={ (e) => this.handleInputChange(e) }
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={{ size: 'auto', offset: 3 }}>
                                <CustomInput 
                                    id="randomized"
                                    type="checkbox"
                                    label="Randomized"
                                    className="mt-1"
                                    name="randomized"
                                    defaultChecked={ randomized && randomized === '1' }
                                    onChange={ (e) => { this.handleCheckChange(e) } }
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <Button className="btn btn-danger" onClick={ hide } data-dismiss="modal">
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button color="primary">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal >
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(withRouter(NumberRangeForm));
