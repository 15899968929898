import React from "react";
import {connect} from "react-redux";
import {DelegatePlan} from '../../controllers/plans';
import {withRouter} from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import {loaderToggle} from '../../redux/actions/loaderActions';
import DelegatePlanOptions from "./DelegatePlanOptions";
import {registerLocale} from "react-datepicker";
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';
import {withTranslation} from 'react-i18next';
import {nl, enGB, enUS} from "date-fns/locale";

const CustomSwal = withReactContent(Swal);

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class DelegatePlanForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        start_date: null,
        price_margin: null,
        tenant: null,
        tenant_name: null
      }
    }
  }

  delegateOptions = (values) => {
    const {options} = this.state;

    if (values) {
      let optionsData = Object.assign({}, options);
      
      if (values.plan_start) {
        optionsData.start_date = values.plan_start;
      }
      if (values.margin || values.margin === 0) {
        optionsData.price_margin = values.margin;
      }
      if (values.tenant) {
        optionsData.tenant = values.tenant;
      }
      if (values.tenant_name) {
        optionsData.tenant_name = values.tenant_name;
      }

      this.setState({
        options: optionsData
      });
    }
  };

  componentDidMount() {
    const {loader, selectedData, dispatch, user, hide} = this.props;
    
    CustomSwal.fire({
      customClass: { container: 'has-cancel', },
      title: 'Delegate plan?',
      text: 'Are you sure you want to delegate this plan?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      preConfirm: async (data) => {
        const {options} = this.state;
        if (!options.tenant) {
          return CustomSwal.showValidationMessage(`Child tenant is required`);
        } else if (!options.price_margin) {
          return CustomSwal.showValidationMessage(`Price margin is required`);
        } else {
          return options;
        }
      },
      html: <DelegatePlanOptions dispatch={dispatch} delegateOptions={this.delegateOptions} user={user} />

    }).then(res => {
      if (res.isConfirmed) {
        if (!loader) {
          (async () => {
            dispatch(loaderToggle(true));

            let params = {};
            let sub_tenant;
            let sub_tenant_name;

            const {options} = this.state;        
            if (options && options.start_date) {
              params.plan_start = parseDateToSaveFormat(options.start_date);
            }
            if (options && options.price_margin) {
              params.margin = options.price_margin;
            } 
            if (options && options.tenant) {
              sub_tenant = options.tenant;
            } 
            if (options && options.tenant_name) {
              sub_tenant_name = options.tenant_name;
            } 
            
            if (selectedData && selectedData.id) {              
              const {ok, data, message} = await DelegatePlan(selectedData.id, sub_tenant, params);

              let status_header;
              let status;
              let msg;
              if (ok) {
                if (data && data.message) {
                  msg = sub_tenant_name ? `${data.message} for ${sub_tenant_name}` : data.message;
                } else {
                  msg = sub_tenant_name ? `Plan delegated for ${sub_tenant_name}` : `Plan delegated`;
                }
                status = 'success';
                status_header = 'Success';

                //  maybe do a tenant switch & then redirect to delegated plan?
                //  history.push({ pathname: `/plans/${ data.data.id }/details`});
                //  if (togglePlanLinesAfterDuplicate) {
                //    this.props.togglePlanLinesAfterDuplicate();
                //  }

              } else {
                if (message) {
                  msg = message;
                } else {
                  msg = sub_tenant_name ? `Plan could not be delegated for ${sub_tenant_name}` : `Plan delegated`;
                }
                status = 'warning';
                status_header = 'Warning';
              }

              dispatch(loaderToggle(false));
              await Swal.fire(status_header, msg, status);
              hide();

            } else {
              dispatch(loaderToggle(false));
              await Swal.fire('Error', 'Copy failed', 'error');
              hide();

            }
          })();
        }
      } else {
        dispatch(loaderToggle(false));
        hide();

      }
    })
  }

  render() {
    return (
        <React.Fragment>
        </React.Fragment>
    )
  }
}

const mapStateToProps = store => ({
  loader: store.loader,
  user: store.user
});

export default connect(mapStateToProps)(withTranslation()(withRouter(DelegatePlanForm)));
