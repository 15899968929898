import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import InputMask from 'react-input-mask';
import DatePicker, {registerLocale} from 'react-datepicker';
import {
  Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';
import {AddApiKeysPerson} from '../../controllers/persons';
import {loaderToggle} from '../../redux/actions/loaderActions';
import {validateDate} from '../../redux/actions/validateActions';
import Toast from '../../components/Toast';
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';
import {withTranslation} from 'react-i18next';
import {enGB, enUS, nl} from 'date-fns/locale';
import {GetUser} from '../../controllers/users';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class AddApiKeysUserForm extends React.Component {
  constructor(props) {
    super(props);

    console.log("AddApiKeysUserForm - props ->", props);

    const { selectedData, userData, id } = this.props;
    console.log(selectedData, userData, id);

    this.state = {
      formType: 'Add',
      formName: 'api key',
      user: [],
      userData: userData ? userData : {},
      api_keys: selectedData ? selectedData : {},
    }
  }

  getLocale = (locale) => {
    const dateLocalesPath = {
      'nl': 'nl',
      'en': 'en-GB',
      'us': 'en-US'
    };

    require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
  };

  handleInputChange({ target: { name, value }}) {
    let api_keys = Object.assign({}, this.state.api_keys);

    api_keys[name] =  value;

    this.setState({ api_keys });
  }

  handleIPChange({ target: { name, value }}) {
    let api_keys = Object.assign({}, this.state.api_keys);

    api_keys[name] =  value;

    this.setState({ api_keys })
  }

  handleDatePickerChange(name, date) {
    let api_keys = Object.assign({}, this.state.api_keys);
    const { newDate } = this.props.dispatch(validateDate(date));

    api_keys[name] =  newDate;

    this.setState({ api_keys })
  }

  handleSelectChange(name, data) {
    console.log("name, data ->", name, data);
    let api_keys = Object.assign({}, this.state.api_keys);

    api_keys[name] = data.value;

    this.setState({
      api_keys,
      [name]: data
    });
  }

  handleKeyDown = (e, name) => {
    e.preventDefault();
  };

  handleSubmit(e) {
    e.preventDefault();

    let api_keys = Object.assign({}, this.state.api_keys);
    const { loader, dispatch, id } = this.props;
    const { name, ip_address, expire_date } = api_keys;

    //Validations
    if (!id) {
      return Toast.fire({ title: 'User (ID) field is required!', icon: 'warning' })
    }

    if (!name) {
      return Toast.fire({ title: 'Name from field is required!', icon: 'warning' })
    }

    if (!ip_address) {
      return Toast.fire({ title: 'IP address to field is required!', icon: 'warning' })
    }

    // update expiry date
    api_keys.user_id = id ? id : null;
    api_keys.expire_date = expire_date ? parseDateToSaveFormat(expire_date) : '';

    if (!loader) {
      dispatch(loaderToggle(true));
      (async () => {
        const {ok} = await AddApiKeysPerson(api_keys);
        this.handleOk(ok);
      })();
      dispatch(loaderToggle(false));
    }
  }

  handleOk = (ok) => {
    const { hide, update, dispatch } = this.props;

    dispatch(loaderToggle(false));

    if (ok) {
      hide();
      update();
    }
  };

  loadUser = (id) => {
    console.log("id isset - id ->", id);
    console.log("id isset - userData ->", this.state.userData);

    if (id && this.state.userData && this.state.userData.id && parseInt(this.state.userData.id) === parseInt(id)) {
      console.log("username isset - username ->", this.state.userData.username);
      return { value: this.state.userData.id, label: this.state.userData.username };
    }
  };

  componentDidMount() {
    const { dispatch, id } = this.props;
    console.log("Api keys form - componentDidMount - this.props ->", this.props);

    dispatch(loaderToggle(true));
    (async () => {
      const { ok, data } = await GetUser(id);
      if (ok && data && data.data) {
        let user = [];
        user.label = data.data.username;
        user.value = data.data.id;

        console.log("Api keys form - componentDidMount - users ->", user);
        this.setState({ user });

        let userData = data.data;
        this.setState({ user_id: userData.id });
        this.setState({ userData: userData });
      }

      dispatch(loaderToggle(false));
      this.setState({ hasLoaded: true });
    })();
  }

  render() {
    const { show, hide, id } = this.props,
        { formType, formName, api_keys, userData } = this.state,
        { user_id, name, ip_address, description, expire_date } = api_keys ? api_keys : null;

    console.log("Api keys form - render - api_keys ->", api_keys);
    console.log("Api keys form - render - userData ->", userData);

    return (
        <Modal className="form-subscription-lines" isOpen={ show } toggle={ hide } centered>
          <Form onSubmit={ (e) => this.handleSubmit(e) }>
            <ModalHeader>
              <span>{ formType } { formName }</span>
            </ModalHeader>
            <ModalBody key="0" className="mt-3 mb-3">
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">User:</label>
                </Col>
                <Col md={9}>
                  <AsyncSelect
                    getOptionLabel={({ label, value }) =>
                        `${value && label ? value + ' - ' + label : String.fromCharCode(8212)}`
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={this.loadUser(id ? id : user_id)}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">Name:</label>
                </Col>
                <Col md={9}>
                  <Input
                      name="name"
                      placeholder="Name"
                      value={ name ? name : '' }
                      onChange={ (e) => this.handleInputChange(e) }
                  />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">Description:</label>
                </Col>
                <Col md={9}>
                  <Input
                      name="description"
                      placeholder="Description"
                      value={ description ? description : '' }
                      onChange={ (e) => this.handleInputChange(e) }
                  />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">IP Address:</label>
                </Col>
                <Col md={9}>
                  <InputMask
                      className="form-control"
                      //mask="999.999.999.999"
                      name="ip_address"
                      placeholder="IP address"
                      onChange={ (e) => this.handleIPChange(e) }
                      value={ ip_address ? ip_address : '' }
                  />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">Date:</label>
                </Col>
                <Col md={9}>
                  <DatePicker
                      id="date"
                      className="form-control"
                      name="expire_date"
                      dateFormat="P"
                      autoComplete="off"
                      placeholderText="Expire date"
                      selected={ expire_date ? new Date(expire_date) : null }
                      showMonthDropdown
                      showYearDropdown
                      onChange={ this.handleDatePickerChange.bind(this, 'expire_date') }
                      ref="expire_date"
                      onKeyDown={ (e) => e.preventDefault() }
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-between mt-4">
              <Button className="btn btn-danger" onClick={ hide } data-dismiss="modal">
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <Button color="primary">
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
    )
  }
}

const mapStateToProps = store => ({
  loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(AddApiKeysUserForm)));