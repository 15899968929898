import React, {useState, useRef, useEffect} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {withRouter} from 'react-router-dom';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledDropdown,
  Form,
  ListGroup,
  Button, Spinner,
} from 'reactstrap';
import {CheckAvailability, CheckPublicAvailability, GetPublicCountries, GetPublicCountryCities} from '../controllers/av_check';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {loaderToggle} from "../redux/actions/loaderActions";
import ToastMulti from "./ToastMulti";
import Toast from "./Toast";
import {UpdatePerson} from '../controllers/persons';
import HtmlOffers from './avcheck/html';
import ReactJson from 'react-json-view';
import axios from 'axios';
import {GetCities, GetCountries, GetCountryCities} from '../controllers/relations';
import Select from 'react-select';
import Carousel from './Carousel';
import {faArrowCircleLeft, faHome, faMapPin, faMapMarker, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

const AvCheckerFormEmbed = ({ ...props }) => {
  const { t } = useTranslation();

  const {
    dispatch,
    loader,
    setActiveStep,
    showJsonOutput,
    height,
    apiKey,
    countryId,
    language,
    isBusiness,
    clientInlineStyles,
    clientZipcode,
    clientHouseNumber,
    clientHouseNumberSuffix,
    clientDomain,
    checkOtherAddressDisabled,
  } = props;

  /*
  console.log("AvCheckerFormEmbed - props - clientZipcode ->", clientZipcode);
  console.log("AvCheckerFormEmbed - props - clientHouseNumber ->", clientHouseNumber);
  console.log("AvCheckerFormEmbed - props - clientHouseNumberSuffix ->", clientHouseNumberSuffix);
  console.log("AvCheckerFormEmbed - props - clientDomain ->", clientDomain);
  console.log("AvCheckerFormEmbed - props - checkOtherAddressDisabled ->", checkOtherAddressDisabled);
  console.log("AvCheckerFormEmbed - props - apiKey ->", apiKey);
  console.log("AvCheckerFormEmbed - props - clientInlineStyles ->", clientInlineStyles);
  console.log("AvCheckerFormEmbed - props - language ->", language);
  console.log("AvCheckerFormEmbed - props - isBusiness ->", isBusiness);
  console.log("AvCheckerFormEmbed - props - countryId ->", countryId);
  console.log("AvCheckerFormEmbed - props - height ->", height);
  */

  const socketUrl = process.env.REACT_APP_ENV === "production" ? "wss://ac.f2x.nl" : "wss://staging-ac.f2x.nl"; // staging - "wss://staging-ac.f2x.nl" | production - "wss://ac.f2x.nl"  => process.env.REACT_APP_AV_SOCKET_API

  const [inputAddressDetails, setInputAddressDetails] = useState(!!(clientZipcode && clientHouseNumber && countryId));
  const [inputZipCodeValue, setInputZipCodeValue] = useState("");
  const [inputHouseNumberValue, setInputHouseNumberValue] = useState("");
  const [inputHouseNumberExtValue, setInputHouseNumberExtValue] = useState("");

  const [countryOpts, setCountryOpts] = useState([]);
  const [cityOpts, setCityOpts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectCountryDisabled, setSelectCountryDisabled] = useState(false);

  const [avResponse, setAvResponse] = useState(null);
  const [resUpdate, setResUpdate] = useState(false);
  const [resIsUpdated, setResIsUpdated] = useState(false);
  const [frameHeight, setFrameHeight] = useState(height);

  const [show, setShow] = useState(4); /* carousel default 4 items */
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [locationPicker, setLocationPicker] = useState(false);
  const [locationOpts, setLocationOpts] = useState([]);

  const [isPaused, setPause] = useState(false);
  const [incomingData, setIncomingData] = useState(null);
  const [isRequestingData, setIsRequestingData] = useState(null);
  const [isReceivingData, setIsReceivingData] = useState(null);

  const getCountries = async () => {
    const [
      getCountries,
    ] = await Promise.all([
      GetPublicCountries(apiKey),
    ]);

    await (() => {
      if (getCountries.ok) {
        const countries = getCountries.data.data ? getCountries.data.data : [];
        let countryOpts = [];

        countries.length > 0 && countries.map(({name, id}) => {
          return countryOpts.push({
            label: name, value: id,
          });
        });

        // set country list
        setCountryOpts(countryOpts);
        // get default country ID
        if (countryId) {
          const default_country = countryOpts.find(item => parseInt(item.value) === parseInt(countryId));
          // set default selected country
          setSelectedCountry(default_country);
          // get selected country cities
          getCountryCities(countryId);
          // set country select disabled
          setSelectCountryDisabled(true);
        }
      }
    })();
  };

  const getCountryCities = async (country_id) => {
    const [
      getCities,
    ] = await Promise.all([
      GetPublicCountryCities(apiKey, country_id),
    ]);

    await (() => {
      if (getCities.ok) {
        const cities = getCities.data.data ? getCities.data.data : [];
        let cityOpts = [];

        cities.length > 0 && cities.map(({name, id}) => {
          return cityOpts.push({
            label: name, value: id,
          });
        });

        // set cities list
        setCityOpts(cityOpts);
      }
    })();
  };

  const handleInputZipCodeChange = (e) => {
    setInputZipCodeValue(e.target.value);
  };

  const handleInputHouseNumberChange = (e) => {
    setInputHouseNumberValue(e.target.value);
  };

  const handleInputHouseNumberExtChange = (e) => {
    setInputHouseNumberExtValue(e.target.value);
  };

  const handleInputCountryChange = (e) => {
    setSelectedCountry(e);
  };

  const clearInputField = (e, id) => {
    e.preventDefault();

    let input = document.getElementById(id);
    input.value = '';

    if (id === "av-check-zip-code-id") {
      setInputZipCodeValue("");
    } else if (id === "av-check-house-number-id") {
      setInputHouseNumberValue("");
    } else if (id === "av-check-house-number-ext-id") {
      setInputHouseNumberExtValue("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let notificationsToastArr = [];
    if (!inputZipCodeValue) {
      notificationsToastArr.push({
        title: "Zip code field is required!",
        icon: "warn",
        plugin: "ToastMulti"
      });
    }
    if (!inputHouseNumberValue) {
      notificationsToastArr.push({
        title: "House number field is required!",
        icon: "warn",
        plugin: "ToastMulti"
      });
    }

    if (notificationsToastArr.length > 0) {
      let parentWindow = window.parent;
      if (parentWindow && apiKey) {
        let messageData = {};
        messageData.type = "Toast";
        messageData.message = notificationsToastArr;

        try {
          parentWindow.postMessage(messageData,"*");
        } catch(e) {
          console.log("postMessage failed - error");
        }
        return false;

      } else {
        return ToastMulti(notificationsToastArr, "container-validation-id");
      }
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      let params = {};
      params.zipcode = inputZipCodeValue;
      params.house_number = inputHouseNumberValue;
      params.house_number_suffix = inputHouseNumberExtValue;
      params.country_id = selectedCountry.value;
      params.api_key = apiKey;

      console.log("params ->", params);

      checkOffers(params);
    }
  };

  // check for offer data
  const checkOffers = (params) => {
    console.log("AvCheckerFormEmbed (public) - checkOffers - params ->", params);

    let notificationsToastArr = [];
    let toastContainerResponseUpdate = [];

    // Check Availability
    (async () => {
      const { ok, data } = await CheckPublicAvailability(params);

      console.log("AvCheckerFormEmbed - ok ->", ok);
      console.log("AvCheckerFormEmbed - data ->", data);

      if (ok && data && data.message && data.message === "Please select a location") {
        await handleLocations(data);

      } else {
        let json_data;
        if (ok && data && data.data && data.data.messagesSent && data.data.messagesSent === 0) {
          if (apiKey) {
            notificationsToastArr.push({
              title: "No offers found",
              icon: "info",
            });
            return errorValidationHandler(notificationsToastArr);

          } else {
            // show error message
            Toast.fire({
              title: "No offers found", icon: 'error'
            }).then(r => console.log("No offers found"));
          }
        } else if (ok && data && data.data && data.data.json_data) {
          json_data = JSON.parse(data.data.json_data);

        }
        await handleOk(toastContainerResponseUpdate, json_data);

      }
    })();
  };

  // handleLocations
  const handleLocations = async (res) => {
    if (res && res.data && res.data.length > 0) {
      return renderLocationPicker(res.data);
    }
  };

  const renderLocationPicker = (locations) => {
    console.log("AvCheckerFormEmbed - renderLocationPicker - locations ->", locations);

    setLocationPicker(true);
    setLocationOpts(locations);

    dispatch(loaderToggle(false));
  };

  const handleSubmitLocation = (e, index) => {
    e.preventDefault();

    console.log("AvCheckerFormEmbed - handleSubmitLocation - e ->", e);
    console.log("AvCheckerFormEmbed - handleSubmitLocation - index ->", index);

    if (locationOpts && locationOpts[index]) {
      console.log("AvCheckerFormEmbed - handleSubmitLocation - locationOpts ->", locationOpts[index]);

      let params = {};
      params.zipcode = locationOpts[index].zipcode;
      params.house_number = locationOpts[index].house_number;
      params.house_number_suffix = locationOpts[index].house_letter;
      params.country_id = selectedCountry.value;
      params.location_id = locationOpts[index].id;
      params.api_key = apiKey;

      console.log("AvCheckerFormEmbed - params ->", params);

      return checkOffers(params);
    }
  };

  const checkOtherAddress = (e) => {
    e.preventDefault();
    console.log("AvCheckerFormEmbed - checkOtherAddress");

    setInputAddressDetails(false);
    setLocationPicker(false);
    setAvResponse(null);
  };

  // handleOk for handling offers
  const handleOk = async (options, res) => {
    if (options && options.length > 0) {
      return ToastMulti(options, "container-validation-id");
    }

    if (res) {
      setAvResponse(res);
      setActiveStep(2);

      if (res.ac_request_id) {
        getRequest(res.ac_request_id);
      }
    }

    dispatch(loaderToggle(false));

    return false;
  };

  const getRequest = (request_id) => {
    (async () => {
      dispatch(loaderToggle(true));

      const messages = [];
      const notificationsToastArr = [];
      const ws = new WebSocket(`${socketUrl}/?request_id=${request_id}`);

      setIsRequestingData(true);

      console.log("AvCheckerEmbed - ws getRequest - ws ->", ws);

      ws.onopen = (e) => {
        console.log("ws opened");
        console.log("ws opened - e ->", e);
      };

      ws.onmessage = (e) => {
        setIncomingData(true);
        setIsRequestingData(false);

        console.log("AvCheckerEmbed - ws - e ->", e);

        if (e && e.data && e.data === "Invalid request") {
          setAvResponse(null);

          if (apiKey) {
            notificationsToastArr.push({
              title: "No offer found",
              icon: "info",
            });
            return errorValidationHandler(notificationsToastArr);

          } else {
            // show error message
            Toast.fire({
              title: e.data, icon: 'error'
            }).then(r => console.log(e.data));
          }
        } else {
          const dataFromServer = JSON.parse(e.data);

          if (dataFromServer && dataFromServer.offer_id) {
            messages.push(dataFromServer);
            setResIsUpdated(true);
            setAvResponse(messages);

          } else {
            console.log("AvCheckerEmbed - ws - dataFromServer = empty");
            setAvResponse(false);

          }
        }

        dispatch(loaderToggle(false));
      };

      ws.onclose = (e) => {
        console.log("AvCheckerEmbed - ws closed");
        console.log("AvCheckerEmbed - ws closed - messages ->", messages);
        console.log("AvCheckerEmbed - ws closed - avResponse ->", avResponse);
        console.log("AvCheckerEmbed - ws closed - inputAddressDetails ->", inputAddressDetails);
        console.log("AvCheckerEmbed - ws closed - locationPicker ->", locationPicker);

        if (messages.length === 0 && !avResponse) {
          setIsRequestingData(false);
          setInputAddressDetails(false);
          setLocationPicker(false);
          setAvResponse(null);
        }
      };

      let counter = 0;
      const intervalId = setInterval(() => {
        console.log("counter - Checking offers");
        counter += 1;

        if (messages.length > 0) {
          console.log("counter (1) - Offer(s) found", counter);
          clearInterval(intervalId);

        } else if (counter === 3 && messages.length > 0) {
          console.log("counter - Offer(s) found", counter);
          clearInterval(intervalId);

        } else if (counter === 3 && messages.length === 0) {
          console.log("No offer found");
          clearInterval(intervalId);

          setIsRequestingData(false);
          setInputAddressDetails(false);
          setLocationPicker(false);
          setAvResponse(null);

          if (apiKey) {
            notificationsToastArr.push({
              title: "No offer found",
              icon: "info",
            });
            return errorValidationHandler(notificationsToastArr);

          } else {
            // show error message
            Toast.fire({
              title: "No offer found", icon: 'info'
            }).then(r => console.log("counter - No offer found"));
          }
        }
      }, 6000); // every 6 seconds

    })();
  };

  const errorValidationHandler = (notificationsArr) => {
    let parentWindow = window.parent;
    let messageData = {};
    messageData.type = "Toast";
    messageData.message = notificationsArr;

    try {
      parentWindow.postMessage(messageData,"*");
    } catch(e) {
      console.log("errorValidationHandler - postMessage failed - error");
    }
    return false;
  };

  useEffect(() => {
    console.log("AvCheckerFormEmbed - useEffect - addressDetails");
    if (inputAddressDetails) {
      console.log("AvCheckerFormEmbed - useEffect - found addressDetails - inputAddressDetails ->", inputAddressDetails);
      console.log("AvCheckerFormEmbed - useEffect - found addressDetails - clientZipcode ->", clientZipcode);
      console.log("AvCheckerFormEmbed - useEffect - found addressDetails - clientHouseNumber ->", clientHouseNumber);
      console.log("AvCheckerFormEmbed - useEffect - found addressDetails - clientHouseNumberSuffix ->", clientHouseNumberSuffix);
      console.log("AvCheckerFormEmbed - useEffect - found addressDetails - countryId ->", countryId);

      // set input fields & checkOffers
      let params = {};
      params.zipcode = clientZipcode;
      params.house_number = clientHouseNumber;
      params.house_number_suffix = clientHouseNumberSuffix ? clientHouseNumberSuffix : "";
      params.country_id = countryId;
      params.api_key = apiKey;

      console.log("AvCheckerFormEmbed - useEffect - found addressDetails - params ->", params);

      checkOffers(params);
    }
  }, [inputAddressDetails]);

  useEffect(() => {
    getCountries().then((r) => {
      console.log("AvCheckerFormEmbed - useEffect - GetCountries - r ->", r);
    });
  }, []);

  useEffect(() => {
    if (avResponse && avResponse.length > 0) {
      setResUpdate(true);
      setAvResponse(avResponse);
    }
  }, [resIsUpdated]);

  useEffect(() => {
    if (windowSize) {
      if (windowSize.innerWidth > 0 && windowSize.innerWidth < 678) {
        setShow(1);
      }
      else
      if (windowSize.innerWidth > 678 && windowSize.innerWidth < 1100) {
        setShow(2);
      }
      else
      if (windowSize.innerWidth > 1100 && windowSize.innerWidth < 1382) {
        setShow(3);
      }
      else
      if (windowSize.innerWidth > 1382 && windowSize.innerWidth < 1846) {
        setShow(4);
      }
      else
      if (windowSize.innerWidth > 1846 && windowSize.innerWidth < 2760) {
        setShow(5);
      }
      else if (windowSize.innerWidth > 2760) {
        setShow(6);
      }
    }
  }, [windowSize]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  return (
      <React.Fragment>

        {!inputAddressDetails && !locationPicker && !avResponse &&
        <div className={clientDomain ? `f2x-rel-mv-detail f2x-av-check ${clientDomain}` : `f2x-rel-mv-detail f2x-av-check`}>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row className={`d-flex`}>
              <Col md={12}>
                <ListGroup className={`block-list`} flush>
                  <FormGroup className={clientInlineStyles && clientInlineStyles["roundedCorners"] ? `input-corners-${clientInlineStyles["roundedCorners"]} mb-2` : `mb-2`}>
                    <InputGroup className={`custom-finance-select mt-0`}>
                      <Input type="text"
                             className="f2x-fin-h-text mt-0 input-comp-filter"
                             name={`av-check-zip-code`}
                             id={`av-check-zip-code-id`}
                             key={`av-check-zip-code-key`}
                             value={inputZipCodeValue}
                             tabIndex='1'
                             placeholder={t("offers.form_ph_zip_code", null)}
                             onChange={handleInputZipCodeChange}
                      />
                      <InputGroupAddon className="clear-btn clear-filter" addonType="append">
                        <a href="/#" className="danger icon-delete" onClick={(e) => clearInputField(e, `av-check-zip-code-id`)}>
                          <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                        </a>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </ListGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ListGroup className={`block-list`} flush>
                  <FormGroup className={clientInlineStyles && clientInlineStyles["roundedCorners"] ? `input-corners-${clientInlineStyles["roundedCorners"]} mb-2` : `mb-2`}>
                    <InputGroup className={`custom-finance-select mt-0`}>
                      <Input type="text"
                             className="f2x-fin-h-text mt-0 input-comp-filter"
                             name={`av-check-house-number`}
                             id={`av-check-house-number-id`}
                             key={`av-check-house-number-key`}
                             tabIndex='2'
                             value={inputHouseNumberValue}
                             placeholder={t("offers.form_ph_house_number", null)}
                             onChange={handleInputHouseNumberChange}
                      />
                      <InputGroupAddon className="clear-btn clear-filter" addonType="append">
                        <a href="/#" className="danger icon-delete" onClick={(e) => clearInputField(e, `av-check-house-number-id`)}>
                          <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                        </a>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </ListGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ListGroup className={`block-list`} flush>
                  <FormGroup className={clientInlineStyles && clientInlineStyles["roundedCorners"] ? `input-corners-${clientInlineStyles["roundedCorners"]} mb-2` : `mb-2`}>
                    <InputGroup className={`custom-finance-select mt-0`}>
                      <Input type="text"
                             className="f2x-fin-h-text mt-0 input-comp-filter"
                             name={`av-check-house-number-ext`}
                             id={`av-check-house-number-ext-id`}
                             key={`av-check-house-number-ext-key`}
                             value={inputHouseNumberExtValue}
                             placeholder={t("offers.form_ph_extension", null)}
                             tabIndex='3'
                             onChange={handleInputHouseNumberExtChange}
                             onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }}
                      />
                      <InputGroupAddon className="clear-btn clear-filter" addonType="append">
                        <a href="/#" className="danger icon-delete" onClick={(e) => clearInputField(e, `av-check-house-number-ext-id`)}>
                          <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                        </a>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </ListGroup>
              </Col>
            </Row>
            {!countryId &&
            <Row>
              <Col md={12}>
                <ListGroup className={`block-list`} flush>
                  <FormGroup className={clientInlineStyles && clientInlineStyles["roundedCorners"] ? `input-corners-${clientInlineStyles["roundedCorners"]} mb-2` : `mb-2`}>
                    <InputGroup className={`custom-finance-select mt-0`}>
                      <Select
                          className="react-select-container f2x-fin-h-text input-comp-filter w-100"
                          classNamePrefix="react-select"
                          placeholder={t("offers.form_ph_country", null)}
                          name={`av-check-country`}
                          id={`av-check-country-id`}
                          key={`av-check-house-country-key`}
                          options={countryOpts}
                          tabIndex='4'
                          value={selectedCountry}
                          onChange={(e) => handleInputCountryChange(e)}
                          maxMenuHeight={300}
                      />
                    </InputGroup>
                  </FormGroup>
                </ListGroup>
              </Col>
            </Row>}
            <Row>
              <Col xs="auto" className={clientInlineStyles && clientInlineStyles["roundedCorners"] ? `input-corners-${clientInlineStyles["roundedCorners"]} mr-auto mt-2` : `mr-auto mt-2`}>
                <Button
                  color="primary"
                  style={clientInlineStyles && clientInlineStyles["btnPrimaryBgColor"] && clientInlineStyles["btnPrimaryColor"] ? { color: clientInlineStyles["btnPrimaryColor"], backgroundColor: clientInlineStyles["btnPrimaryBgColor"], borderColor: clientInlineStyles["btnPrimaryBgColor"], lineHeight: "1.2" }  : {}}
                >
                  Check
                </Button>
              </Col>
            </Row>
          </Form>
        </div>}

        {isRequestingData === true &&
        <div className={clientDomain ? `holder-spinner text-center ${clientDomain}` : `holder-spinner text-center`} style={{ marginTop: "3.25rem" }}>
          <Spinner className="form-spinner" color="default" size="sm" style={{ opacity: "0.25" }} />
        </div>}

        {avResponse &&
        <div key={`response-offer-html-data`} className={clientDomain ? `av-check-response ${clientDomain}` : `av-check-response`}>
          <HtmlOffers
              planData={avResponse}
              updated={resUpdate}
              setResIsUpdated={setResIsUpdated}
              resIsUpdated={resIsUpdated}
              setAvResponse={setAvResponse}
              setResUpdate={setResUpdate}
              defaultCountry={selectedCountry}
              countryOpts={countryOpts}
              cityOpts={cityOpts}
              setActiveStep={setActiveStep}
              apiKey={apiKey}
              isBusiness={isBusiness}
              checkOtherAddressDisabled={checkOtherAddressDisabled}
              clientDomain={clientDomain}
              clientInlineStyles={clientInlineStyles ? clientInlineStyles : null}
          />
        </div>}

        {!avResponse && locationPicker && locationOpts.length > 0 &&
        <div className={clientDomain ? `locations-picker ${clientDomain}` : `locations-picker`}>
          <div className="container px-0 mt-4">
            <div className="row">
              {!checkOtherAddressDisabled &&
              <div className={`col-5`}>
                <div className="w-100 mb-0">
                  <h3 className="mb-0" style={ clientInlineStyles && clientInlineStyles["h3_size"] && clientInlineStyles["h3_color"] ? { fontSize: clientInlineStyles["h3_size"], color: clientInlineStyles["h3_color"] } : {}}>
                    <a href="#" className="mr-2 mt-n2" onClick={(e) => checkOtherAddress(e)}>
                      <FontAwesomeIcon
                        color="primary"
                        name="step-back-icon"
                        className="step-back-icon"
                        icon={faArrowCircleLeft}
                        style={clientInlineStyles && clientInlineStyles["btnPrimaryBgColor"] && clientInlineStyles["btnPrimaryColor"] ? { color: clientInlineStyles["btnPrimaryBgColor"], backgroundColor: clientInlineStyles["btnPrimaryColor"], borderColor: clientInlineStyles["btnPrimaryBgColor"]} : {}}
                       />
                    </a>
                    <span>{t("offers.back_btn_txt", null)}</span>
                  </h3>
                </div>
              </div>}
              <div className={!checkOtherAddressDisabled ? `col-7` : `col-12`}>
                <div className={`offer-input-address ${!checkOtherAddressDisabled ? `text-right` : `text-left`} text-truncate`}>
                  <FontAwesomeIcon color="grey" name="address-icon" className="address-icon mr-2" icon={faHome} />
                  {t("offers.pick_location_txt", null)}
                </div>
              </div>
            </div>
          </div>

          <div className="container px-0 mt-4">
            <div className="row">
              <div className={`col-12`}>
                <div className="locations-carousel">
                  <Carousel show={show}>
                    {locationOpts.map(({
                                         house_letter,
                                         house_number,
                                         house_number_suffix,
                                         id,
                                         latitude,
                                         longitude,
                                         rdxCoordinate,
                                         rdyCoordinate,
                                         room,
                                         street1,
                                         street2,
                                         zipcode,
                                         city,
                                       }, index) => (
                        <div className="col-auto" key={`location-carousel-item-${index}`}>
                          <Form className="location-form" data-offer-index={index} onSubmit={(e) => handleSubmitLocation(e, index)}>
                            <div className="card d-flex align-items-start flex-column" style={{overflow: "hidden", margin: "0 auto"}}>
                              <div className="pt-2 px-2 mb-2 mt-2 location-header card-header w-100 text-center">
                                <FontAwesomeIcon color="grey" name="address-icon" className="address-icon mr-2 mb-2" size={`2x`} icon={faMapMarkerAlt} />
                                <span style={{ display: "block" }}>{street1} {house_number} {house_letter ? house_letter : ""} {house_number_suffix ? house_number_suffix : ""}</span>
                                <span style={{ display: "block" }}>{zipcode} {city && city.name ? city.name : ""}</span>
                              </div>
                              <div className="card-body" style={{ height: 0, padding: 0 }}> </div>
                              <div className="card-footer location-footer w-100">
                                <div className="location-button">
                                  <button type="submit" className="btn btn-block location-button-select">
                                    Selecteer
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        }

        {/* JSON Output */}
        {avResponse && showJsonOutput &&
        <div key={`response-json-data`} className={`av-check-response`}>
          <ul className="mt-5 px-0 tree-view-data">
            <ReactJson
                src={avResponse}
                enableClipboard={false}
                style={{fontFamily: "inherit", backgroundColor: "transparent"}}
                collapsed={true}
            />
          </ul>
        </div>
        }
        {/* JSON Output */}

      </React.Fragment>
  );
};

const mapStateToProps = ({dispatch, loader}) => ({dispatch, loader});

export default withRouter(withTranslation()(connect(mapStateToProps)(AvCheckerFormEmbed)));
