import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import {AddPlan, GetPlanLines, DuplicatePlan} from '../../controllers/plans';
import {withRouter} from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import {loaderToggle} from '../../redux/actions/loaderActions';
import {validateDate} from "../../redux/actions/validateActions";
import CONSTANTS from '../../services/constants';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import {useTranslation, withTranslation} from 'react-i18next';
import {nl, enGB, enUS} from "date-fns/locale";
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {GetMyTenants, GetTenantLists} from '../../controllers/tenants';

const DelegatePlanOptions = ({...props}) => {
    console.log("DelegatePlanOptions - props ->", props);

    const {t} = useTranslation();
    const [startDate, setStartDate] = useState('');
    const [margin, setMargin] = useState('');
    const [childTenant, setChildTenant] = useState('');
    const [tenantOpts, setTenantOpts] = useState([]);
    const [delegateOpts, setDelegateOpts] = useState({});

    const getLocale = (locale) => {
        const dateLocalesPath = {
          'nl': 'nl',
          'en': 'en-GB',
          'us': 'en-US'
        };
    };

    const handleSelectChange = (e, data) => {
        console.log("DelegatePlanForm - DelegatePlan - handleSelectChange - e ->", e);
        console.log("DelegatePlanForm - DelegatePlan - handleSelectChange - data ->", data);
        setChildTenant(e);

        const {delegateOptions} = props;
        console.log("DelegatePlanForm - DelegatePlan - handleInputChange - delegateOptions ->", delegateOptions);

        if (delegateOptions) {
            delegateOptions({tenant:e.value, tenant_name:e.label});
        }
    };

    const handleInputChange = (e, data) => {
        console.log("DelegatePlanForm - DelegatePlan - handleInputChange - e ->", e);
        console.log("DelegatePlanForm - DelegatePlan - handleInputChange - e.target.value ->", e.target.value);
        console.log("DelegatePlanForm - DelegatePlan - handleInputChange - data ->", data);

        let margin_val;
        if (!e.target.value || e.target.value === "") {
            margin_val = 0;
        } else {
            margin_val = e.target.value; 
        }

        setMargin(margin_val);

        //swal2-validation-message
        //let inputValidationMessage = document.getElementById("swal2-validation-message");
        //console.log("DelegatePlanForm - DelegatePlan - handleInputChange - inputValidationMessage ->", inputValidationMessage);
        //if (e.target.value !== "" && inputValidationMessage && inputValidationMessage.innerText && inputValidationMessage.innerText === "Price margin is required") {
        //    inputValidationMessage.remove();
        //}

        const {delegateOptions} = props;
        console.log("DelegatePlanForm - DelegatePlan - handleInputChange - delegateOptions ->", delegateOptions);

        if (delegateOptions) {
            delegateOptions({margin:margin_val});
        }


    };

    const handleDatePickerChange = (e, name) => {
        console.log("DelegatePlanForm - DelegatePlan - handleDatePickerChange - name ->", name);
        console.log("DelegatePlanForm - DelegatePlan - handleDatePickerChange - e ->", e);
    
        const {newDate} = props.dispatch(validateDate(e));
        console.log("DelegatePlanForm - DelegatePlan - handleDatePickerChange - newDate ->", newDate);
    
        setStartDate(newDate);
    
        const {delegateOptions} = props;
        console.log("DelegatePlanForm - DelegatePlan - handleDatePickerChange - delegateOptions ->", delegateOptions);

        if (delegateOptions) {
            delegateOptions({plan_start:newDate});
        }
        
        let start_date_input = document.getElementById("delegate-plan-start-date");
        console.log("DelegatePlanForm - DelegatePlan - handleDatePickerChange - getElementById - start_date_input ->", start_date_input);
        if (start_date_input) {
          start_date_input.value = "";
          start_date_input.value = new Date(newDate);
        }
    }

    useEffect(() => {
        (async () => {
            const [getMyTenants] = await Promise.all([
                GetTenantLists()
                //GetMyTenants()
            ]);

            const { user } = props;
            console.log("getMyTenants - user ->", user);
    
            if (getMyTenants.ok) {
                getMyTenants.data.data.map(({ name, id, parent_id }) => {
                    if (parent_id === user.tenant_id) {
                        return tenantOpts.push({
                            label: name,
                            value: id
                        });
                    }
                });
                setTenantOpts(tenantOpts);
            }
        })();
    }, []);

    return (
        <div style={{fontSize:"0.875rem"}} className="delegate-options">
            <div style={{marginBottom:"0.25rem"}}>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select child tenant"
                    name="tenant"
                    options={tenantOpts}
                    value={childTenant ? childTenant : null}
                    onChange={(e) => handleSelectChange(e, 'childTenant')}
                    maxMenuHeight={280} /* {60} */
                    //required={"required"}
                    menuPlacement="top"
                    //defaultMenuIsOpen={true}
                    menuPosition="fixed"
                />
            </div>
            <div style={{marginBottom:"0.25rem"}}>
                <DatePicker
                    locale={getLocale()}
                    className="form-control"
                    wrapperClassName="w-100"
                    name="start_date"
                    id="delegate-plan-start-date"
                    dateFormat={t("date_format_raw")}
                    autoComplete="off"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    placeholderText={`Select a start date (optional)`}
                    scrollableYearDropdown={true}
                    selected={startDate ? new Date(startDate) : ""}
                    onChange={(e) => handleDatePickerChange(e, 'start_date')}
                    popperClassName="sweet-alert-custom"
                    popperPlacement="top-start"
                    popperModifiers={[
                        {
                            name: "offset",
                            options: {
                            offset: [5, 10],
                            },
                        },
                        {
                            name: "preventOverflow",
                            options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                            },
                        },
                    ]}
                />
            </div>
            <div>
                <InputGroup>
                    <Input
                        name="margin"
                        placeholder="Enter a margin value"
                        value={margin ? margin : ""}
                        type="text"
                        onChange={(e) => handleInputChange(e, 'margin')}
                        required={"required"}
                        onKeyDown={(e) => {
                            console.log("e.key ->", e.key);
                            if (!/[0-9]|,|Backspace|Tab|Enter|Delete|ArrowLeft|ArrowRight/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                    />
                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                </InputGroup>
            </div>
        </div>
        
    )
}

export default DelegatePlanOptions;
