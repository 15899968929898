import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

class ZoomedHTMLScreen extends React.Component {

  constructor(props) {
    super(props);
    this.handleHtmlChange = this.handleHtmlChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }
  
  handleHtmlChange = (html) => {
    const { onChange, fieldName } = this.props;
    onChange(html, fieldName); // Pass field name along with the new HTML
  }
  
  handleSave = () => {
    const {onSave} = this.props;
    onSave(); // Trigger the onSave function passed from the parent component
  }

  handleCancel = () => {
    const {originalHtml, fieldName, zoomedHtml, updatedHtml, onChange, onSave} = this.props;
    // console.log("PDF Template - handleCancel - originalHtml ->", originalHtml);
    // console.log("PDF Template - handleCancel - fieldName ->", fieldName);
    // console.log("PDF Template - handleCancel - zoomedHtml ->", zoomedHtml);
    // console.log("PDF Template - handleCancel - updatedHtml ->", updatedHtml);
    // console.log("PDF Template - handleCancel - updatedHtml ->", updatedHtml);

    if (updatedHtml && updatedHtml[fieldName] && zoomedHtml !== updatedHtml[fieldName]) {
      // ask confirmation discard
      Swal.fire({
        title: '',
        text: 'Update changes?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(r => {
          console.log("PDF Template - handleCancel - r ->", r);

          if (r.value) {
            console.log("PDF Template - handleCancel - r.value ->", r.value);
            onSave();
          } else {
            onChange(originalHtml[fieldName], fieldName);
            this.props.toggle();
          }

      });
    } else {
      this.props.toggle();
    }
  }

  render() {
    const { isOpen, toggle, zoomedHtml } = this.props;   
    return (
      <Modal isOpen={isOpen} toggle={toggle} centered className="modal-xl fullscreen"  backdrop={'static'} keyboard={ false }>
        <ModalHeader>Edit HTML</ModalHeader>
        <ModalBody style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
          <div className="code-mirror-container">
            <CodeMirror
                value={ zoomedHtml ? zoomedHtml : '' }                                                
                name="zoomedHtml"
                extensions={[html({ jsx: false })]}                        
                onChange={this.handleHtmlChange}
            />
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button className="btn btn-danger" onClick={this.handleCancel}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button color="primary" onClick={this.handleSave}>
            <FontAwesomeIcon icon={faSave} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ZoomedHTMLScreen;
