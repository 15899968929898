import React, { useState, useEffect } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon, UncontrolledDropdown,
} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {parseDateToSaveFormat, parseDateToSaveFormatObject} from '../../redux/actions/formatActions';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';
import {getTableObj} from '../../services/tableService';
import {
  faArrowLeft,
  faArrowRight,
  faCaretLeft,
  faCaretRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const CustomFilterDateComp = ({
  column,
  onFilter,
  handleFiltering,
  setClearedItemName,
  isClearFilter,
  value,
  enabledFilter,
  setEnabledFilter,
  setComparatorFilter,
  comparatorFilter,
  defaultComparator,
  table,
  icon = false,
  customId = false,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(defaultComparator);

  const clearInputField = (e, column) => {
    e.preventDefault();
    setDate("");
    setSelectedValue("");
    setEnabledFilter(true);
    setClearedItemName(column);
    handleFiltering(e, column, false, toggleDropdown);
  };

  const updateToggleDropdown = (e, comparator) => {
    if (comparator) {
      let tableStoredComparators = "qStr"+table+"ComparatorItems";
      let currentComperators = sessionStorage.getItem(tableStoredComparators);
      let comperatorObject = currentComperators ? JSON.parse(currentComperators) : {};
      // console.log("updateToggleDropdown: ", comperatorObject);
      comperatorObject[column.dataField] = comparator;
      let updatedComperators = JSON.stringify(comperatorObject);
      sessionStorage.setItem(tableStoredComparators, updatedComperators);
      // console.log("updateToggleDropdown: ", sessionStorage.getItem(tableStoredComparators));
      setToggleDropdown(comparator);
    }
  };

  const getStorageValue = () => {
    let name = column.dataField;
    let storedValue;
    let tableStorageFilterObjId = "qStr"+table+"FiltersObj";
    let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
    if (filtersObj) {
      let filtersObjParsed = JSON.parse(filtersObj);
      if (filtersObjParsed && filtersObjParsed.filter &&
          filtersObjParsed.filter.length > 0)
      {
        for (let i = 0; i < filtersObjParsed.filter.length; i++) {
          if (filtersObjParsed.filter[i] &&
              filtersObjParsed.filter[i][name]) {
            storedValue = filtersObjParsed.filter[i][name];
            return storedValue;
          }
        }
      } else {
        if (filtersObjParsed && filtersObjParsed.filter) {
          let key;
          for (key in filtersObjParsed.filter) {
            if (filtersObjParsed.filter.hasOwnProperty(key) && key === name) {
              storedValue = filtersObjParsed.filter[name];
              return storedValue;
            }
          }
        }
      }
    }
    return false;
  };

  const getStoredComparator = () => {
    let tableStoredComparators = "qStr"+table+"ComparatorItems";
    let comparatorFilter = sessionStorage.getItem(tableStoredComparators);
    if (comparatorFilter) {
      let comparatorFilterParsed = JSON.parse(comparatorFilter);
      if (comparatorFilterParsed) {
        let key;
        for (key in comparatorFilterParsed) {
          if (comparatorFilterParsed.hasOwnProperty(key) && key === column.dataField) {
            return comparatorFilterParsed[column.dataField];
          }
        }
      }
    }
    return false;
  };

  const setStoredComparator = () => {
    let tableStoredComparators = "qStr"+table+"ComparatorItems";
    const savedComparators = sessionStorage.getItem(tableStoredComparators);
    const savedComparatorsParsed = savedComparators ? JSON.parse(savedComparators) : {};
    if (toggleDropdown) {
      if (Object.entries(savedComparatorsParsed).length > 0) {
        Object.assign(savedComparatorsParsed, {[column.dataField]: toggleDropdown});  // single-value
      } else {
        savedComparatorsParsed[column.dataField] = toggleDropdown;
      }
      sessionStorage.setItem(tableStoredComparators, JSON.stringify(savedComparatorsParsed));
    }
  };

  useEffect(() => {
    if (!toggleDropdown) {
      setToggleDropdown(defaultComparator);
      setStoredComparator(toggleDropdown);
    }

    handleFiltering(date, column.dataField, false, toggleDropdown);
  }, [date]);

  useEffect(() => {
    if (isClearFilter === true) {
      setDate("");
      setSelectedValue("");
    }
  }, [isClearFilter]);

  useEffect(() => {
    if (!enabledFilter && !selectedValue && value && value !== "") {
      let valueData = column.dataField === "hp_timestamp" ? parseInt(value) : new Date(value);
      if (valueData) {
        setSelectedValue(valueData);
      }
    }
  }, [value]);

  useEffect(() => {
    let storedValue = getStorageValue();
    if (storedValue) {
      setSelectedValue(new Date(storedValue));
    }

    let comparatorItem = getStoredComparator();
    if (comparatorItem) {
      setToggleDropdown(comparatorItem);
    } else {
      setStoredComparator();
    }
  }, []);

  useEffect(() => {
    if (toggleDropdown) {
      //(async () => {
      //console.log("CustomFilterDateComp - useEffect - toggleDropdown ->", toggleDropdown);
      //console.log("CustomFilterDateComp - useEffect - column.dataField ->", column.dataField);

      // id => id-input-${column.dataField}
      let elem = document.getElementById(`id-input-date-${column.dataField}`);
      //console.log("CustomFilterDateComp - useEffect - elem ->", elem);
      if (elem && elem.value) {
        //console.log("CustomFilterDateComp - useEffect - elem.value ->", elem.value);

        let dateObj = parseDateToSaveFormat();

        handleFiltering(elem, column.dataField, false, toggleDropdown);
      }
      //})();
    }
  }, [toggleDropdown]);

  return [
    <div key={`select-date-${column.dataField}`} className={`custom-finance-select-container custom-filter-item custom-filter-item-comp mt-0`} style={{display: 'flex', flexDirection: 'column'}}>
      <InputGroup className={`custom-finance-select mt-0`}>
        <UncontrolledDropdown id={`menu-date-comp-filter-${column.dataField}`} className={`menu-comp-filter`}>
          <DropdownToggle id={`menu-date-comp-filter-toggle-${column.dataField}`} data-toggle="dropdown" className={`menu-comp-filter-toggler`} style={{fontSize: "12px"}}>
            {toggleDropdown ? toggleDropdown : defaultComparator}
          </DropdownToggle>
          <DropdownMenu id={`menu-comp-${column.dataField}`} aria-labelledby={`menu-comp-filter-toggle`} style={{ maxWidth: "4rem", minWidth: "inherit", top: "38px" }}>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `>`)}>{`>`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `<`)}>{`<`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `>=`)}>{`>=`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `<=`)}>{`<=`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `=`)}>{`=`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `%`)}>{`%`}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <DatePicker
            className="form-control f2x-h-text input-icon"
            name={column.dataField}
            id={`id-input-date-${column.dataField}`}
            dateFormat={t("date_format_raw")}
            autoComplete="off"
            placeholderText="Select date"
            selected={selectedValue ? selectedValue : date ? new Date(date) : null}
            onChange={(date) => {
                setDate(date ? new Date(date) : null);
              }
            }
            //maxDate={new Date()}
            style={{ paddingLeft: "30px" }}
        >
        </DatePicker>
        <InputGroupAddon className="clear-btn" addonType="append">
          <a href="/#" name="filter-date-clear-btn" className="danger icon-delete" onClick={(e) => clearInputField(e, column.dataField)}>
            <FontAwesomeIcon name="filter-date-clear-icon" className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  ];
};

export default CustomFilterDateComp;
