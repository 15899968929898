import React, {useState} from 'react';
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import TreeListRelatedProducts from './hierarchy/TreeList';
import ProductForm from './edit/Form';
import ProductHierarchyList from './hierarchy/List';

let debug = true;

const HierarchyTabs = ({ ...props }) => {
  console.log("HierarchyTabs - props ->", props);

  const { show, hide, update, loader, selectedData, dispatch } = props;

  const product = selectedData ? selectedData : null;
  const [activeTab, setActiveTab] = useState('tab-edit-product');
  const [formType, setFormType] = useState(selectedData ? 'Edit' : 'Add');
  const [formName, setFormName] = useState(selectedData && selectedData.description ? `product | ${selectedData.description}` : 'product');
  const [productHierarchy, setProductHierarchy] = useState([]);

  const toggleHierarchyTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      const product_name = product && product.description ? product.description : "";

      if (tab === "tab-edit-hierarchy") {
        setFormType("Edit");
        setFormName(`product hierarchy | ${product_name}`);
      } else if (tab === "tab-show-list") {
        setFormType("View");
        setFormName(`product hierarchy list`);
      } else {
        setFormType("Edit");
        setFormName(`product | ${product_name}`);
      }
    }
  };

  return (
      <React.Fragment>
        { !loader &&
        <Modal isOpen={show} toggle={hide} centered className="modal-xl">
          <ModalHeader>
            <span>
              {formType} {formName}
            </span>
          </ModalHeader>
          <ModalBody className="mt-3">
            <Row className={`mb-4`}>
              <Col md={12}>
                <Nav tabs>
                  <NavItem key={`tab-edit-product`} className={`mr-1`}>
                    <NavLink
                        className={classnames({ active: activeTab === 'tab-edit-product' })}
                        onClick={() => {toggleHierarchyTab('tab-edit-product')}}
                    >
                      Edit
                    </NavLink>
                  </NavItem>
                  <NavItem key={`tab-edit-hierarchy`} className={`mr-1`}>
                    <NavLink
                        className={classnames({ active: activeTab === 'tab-edit-hierarchy' })}
                        onClick={() => {toggleHierarchyTab('tab-edit-hierarchy')}}
                    >
                      Hierarchy
                    </NavLink>
                  </NavItem>
                  <NavItem key={`tab-show-list`} className={`mr-1`}>
                    <NavLink
                        className={classnames({ active: activeTab === 'tab-show-list' })}
                        onClick={() => {toggleHierarchyTab('tab-show-list')}}
                    >
                      Product hierarchy list
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>

            {/*<div className="vertical-border-separator"> </div>*/}
            <div className="product-hierarchy-container pl-2">
              <TabContent activeTab={activeTab}>
                {/* product edit */}
                <TabPane key={`tab-edit-product`} id="tab-edit-product" tabId="tab-edit-product">
                  <ProductForm
                      selectedData={props.selectedData}
                      hide={hide}
                      show={show}
                      update={update}
                      product={product}
                  />
                </TabPane>
                {/* product hierarchy */}
                <TabPane key={`tab-edit-hierarchy`} id="tab-edit-hierarchy" tabId="tab-edit-hierarchy">
                  <ProductHierarchyList
                      product={product}
                      hide={hide}
                      show={show}
                      dispatch={dispatch}
                      update={update}
                      setProductHierarchy={setProductHierarchy}
                      selectedData={props.selectedData}
                  />
                </TabPane>
                {/* product hierarchy list */}
                <TabPane key={`tab-show-list`} id="tab-show-list" tabId="tab-show-list">
                  <TreeListRelatedProducts
                      productHierarchy={productHierarchy ? productHierarchy : ""}
                      product={product}
                      hide={hide}
                      show={show}
                  />
                </TabPane>
              </TabContent>
            </div>

          </ModalBody>
        </Modal>}
      </React.Fragment>
  );
};

export default HierarchyTabs;
