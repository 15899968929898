import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import DatePicker, {registerLocale} from 'react-datepicker';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Form, Input } from "reactstrap";
import { AddApiKeysPerson, UpdateApiKeysPerson } from '../../../controllers/persons';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate } from "../../../redux/actions/validateActions";
import Toast from "../../../components/Toast";
import { parseDateToSaveFormat } from '../../../redux/actions/formatActions';
import { withTranslation } from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale";
import {GetUsers, GetUser, GetUsersApiCompatible} from '../../../controllers/users';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class ApiKeysForm extends React.Component {
  constructor(props) {
    super(props);

    const { selectedData } = this.props;

    this.state = {
      formType: selectedData ? 'Edit' : 'Add',
      formName: 'api key',
      users: [],
      userData: [],
      api_keys: selectedData ? selectedData : {},
    }
  }

  getLocale = (locale) => {
    const dateLocalesPath = {
      'nl': 'nl',
      'en': 'en-GB',
      'us': 'en-US'
    };

    require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
  };

  handleInputChange({ target: { name, value }}) {
    let api_keys = Object.assign({}, this.state.api_keys);

    api_keys[name] =  value;

    this.setState({ api_keys });
  }

  checkIP(ipStr) {
    //const regex = /((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))){4}\/(?:\d|[12]\d|3[01])$/gm;
    const regexCidr = /^(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))((\.(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))){3})\/(([0-9])|([12][0-9])|(3[0-2]))$/gm;
    //const ipStr = `107.8.109.110/24`;
    let m;
    if ((m = regexCidr.exec(ipStr)) !== null) {
      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        console.log(`Found match, group ${groupIndex}: ${match}`);
      });
      console.log(`Found matched IP, group`);
      return true;
    } else {
      return false;
    }
  };

  handleIPChange({ target: { name, value }}) {
    let api_keys = Object.assign({}, this.state.api_keys);
    api_keys[name] =  value;

    this.setState({ api_keys })
  }

  handleDatePickerChange(name, date) {
    let api_keys = Object.assign({}, this.state.api_keys);
    const { newDate } = this.props.dispatch(validateDate(date));

    api_keys[name] =  newDate;

    this.setState({ api_keys })
  }

  handleSelectChange(name, data) {
    console.log("name, data ->", name, data);
    let api_keys = Object.assign({}, this.state.api_keys);

    api_keys[name] = data.value;

    this.setState({
      api_keys,
      [name]: data
    });
  }

  handleKeyDown = (e, name) => {
    e.preventDefault();
  };

  handleSubmit(e) {
    e.preventDefault();

    let api_keys = Object.assign({}, this.state.api_keys);
    const { selectedData, loader, dispatch } = this.props;
    const { user_id, name, ip_address, expire_date } = api_keys;

    const { userData } = this.state;
    console.log("Apikeys form - useData ->", userData);

    //Validations
    if (!user_id) {
      return Toast.fire({ title: 'User field is required!', icon: 'warning' })
    }

    if (!name) {
      return Toast.fire({ title: 'Name from field is required!', icon: 'warning' })
    }

    if (!ip_address) {
      return Toast.fire({ title: 'IP address to field is required!', icon: 'warning' })
    } //else if (!this.checkIP(ip_address)) {
      //return Toast.fire({ title: 'IP address is not valid!', icon: 'warning' })
    //}

    // update expiry date
    api_keys.expire_date = expire_date ? parseDateToSaveFormat(expire_date) : '';
    // add tenant id so backend can check
    if (userData && userData.last_tenant_id) {
      api_keys.tenant_id = userData.last_tenant_id;
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      if (selectedData) {
        (async () => {
          const { ok } = await UpdateApiKeysPerson(selectedData.id, api_keys);
          this.handleOk(ok);
        })()
      } else {
        (async () => {
          const {ok} = await AddApiKeysPerson(api_keys);
          this.handleOk(ok);
        })();
      }
    }
  }

  handleOk = (ok) => {
    const { hide, update, dispatch } = this.props;

    dispatch(loaderToggle(false));

    if (ok) {
      hide();
      update();
    }
  };

  loadUsers = (value, callback) => {
    const params = { search: value };

    (async () => {
      const { ok, data } = await GetUsers(params);
      if (ok) {
        let users = [];
        data.data.map(({ username, id }) => {
          return users.push({
            label: username,
            value: id
          })
        });
        callback(users);
      }
    })();
  };

  loadUser = (id) => {
    console.log("id isset - id ->", id);
    console.log("id isset - userData ->", this.state.userData);

    if (id && this.state.userData && this.state.userData.id && parseInt(this.state.userData.id) === parseInt(id)) {
      console.log("username isset - username ->", this.state.userData.username);
      return { value: this.state.userData.id, label: this.state.userData.username };
    }
  };

  componentDidMount() {
    const { selectedData, dispatch, match } = this.props; // id = tenant_id

    console.log("Tenant api keys form - this.props ->", this.props);

    const tenant_id = match && match.params && match.params.id ? match.params.id : null;

    console.log("Tenant api keys form - selectedData ->", selectedData);

    dispatch(loaderToggle(true));
    (async () => {
      //const params = this.state.formType === "Edit" ? { offset: 10 } : {};
      const params = tenant_id ? { tenant_id: tenant_id } : {};
      //const { ok, data } = await GetUsers(params);
      const { ok, data } = await GetUsersApiCompatible(params);
      if (ok && data && data.data) {
        let users = [];

        data.data.map(({ username, id }) => {
          return users.push({
            label: username,
            value: id
          })
        });

        console.log("Api keys form - componentDidMount - users ->", users);
        console.log("Api keys form - componentDidMount - selectedData ->", selectedData);
        this.setState({ users });
      }

      if (selectedData && selectedData.user_id) {
        const { ok, data } = await GetUser(selectedData.user_id);
        if (ok) {
          console.log("Api keys form - componentDidMount - GetUser - data ->", data);
          let userData = [];

          if (data && data.data) {
            userData = data.data;

            this.setState({ user_id: userData.id });
            this.setState({ userData: userData });
          }
        }
      }

      dispatch(loaderToggle(false));
      this.setState({ hasLoaded: true });

    })();
  }

  render() {
    const { show, hide, selectedData } = this.props,
          { formType, formName, users, api_keys, userData } = this.state,
          { user_id, name, ip_address, description, expire_date } = api_keys ? api_keys : null;

    console.log("Api keys form - render - api_keys ->", api_keys);
    console.log("Api keys form - render - userData ->", userData);

    return (
        <Modal className="form-subscription-lines" isOpen={ show } toggle={ hide } centered>
          <Form onSubmit={ (e) => this.handleSubmit(e) }>
            <ModalHeader>
              <span>{ formType } { formName }</span>
            </ModalHeader>
            <ModalBody key="0" className="mt-3 mb-3">
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">User:</label>
                </Col>
                <Col md={9}>
                  <AsyncSelect
                      cacheOptions
                      defaultOptions={users}
                      loadOptions={this.loadUsers}
                      getOptionLabel={({ label, value }) =>
                          `${value && label ? value + ' - ' + label : String.fromCharCode(8212)}`
                      }
                      className="react-select-container"
                      classNamePrefix="react-select"
                      value={this.loadUser(user_id)}
                      onChange={ this.handleSelectChange.bind(this, 'user_id') }
                      maxMenuHeight={300}
                      isDisabled={!!selectedData}
                  />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">Name:</label>
                </Col>
                <Col md={9}>
                  <Input
                      name="name"
                      placeholder="Name"
                      value={ name ? name : '' }
                      onChange={ (e) => this.handleInputChange(e) }
                  />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">Description:</label>
                </Col>
                <Col md={9}>
                  <Input
                      name="description"
                      placeholder="Description"
                      value={ description ? description : '' }
                      onChange={ (e) => this.handleInputChange(e) }
                  />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">IP Address:</label>
                </Col>
                <Col md={9}>
                  <Input
                      className="form-control"
                      //mask="999.999.999.999"
                      name="ip_address"
                      placeholder="IP address"
                      onChange={(e) => this.handleIPChange(e)}
                      value={ ip_address ? ip_address : '' }
                  />
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={3}>
                  <label className="col-form-label">Date:</label>
                </Col>
                <Col md={9}>
                  <DatePicker
                      id="date"
                      className="form-control"
                      name="expire_date"
                      dateFormat="P"
                      autoComplete="off"
                      placeholderText="Expire date"
                      selected={ expire_date ? new Date(expire_date) : null }
                      showMonthDropdown
                      showYearDropdown
                      onChange={ this.handleDatePickerChange.bind(this, 'expire_date') }
                      //ref="expire_date"
                      onKeyDown={ (e) => e.preventDefault() }
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-between mt-4">
              <Button className="btn btn-danger" onClick={ hide } data-dismiss="modal">
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <Button color="primary">
              <FontAwesomeIcon icon={faSave} />
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
    )
  }
}

const mapStateToProps = store => ({
  loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(ApiKeysForm)));
