import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker, {registerLocale} from 'react-datepicker';
import moment from 'moment';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    Input,
    FormGroup,
    Col,
    CustomInput, Row,
} from 'reactstrap';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import { validateInputIBAN, validateIBAN, validateDate } from '../../../redux/actions/validateActions';
import { AddBankAccount, UpdateBankAccount, NextMndtId } from "../../../controllers/relations";
import Toast from "../../../components/Toast";
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../../redux/actions/formatActions'; // import all locales we need
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const statusOpt = [{
    value: 1,
    label: 'Active'
}, {
    value: 0,
    label: 'Inactive'
}];

class BankAccountsForm extends React.Component {
    constructor(props) {
        super(props);

        const {selectedData} = this.props;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'bank account',
            bank_account: selectedData ? selectedData : {
                status: 1,
                dd_default: 1,
                dt_of_sgntr: moment()
            },
            ibanInputDisabled: '',
            dd_defaultDisabled: '',
            status: {
                value: 1, label: 'Active'
            }
        };
    }

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    handleInputChange({ target: { name, value } }) {
        let bank_account = Object.assign({}, this.state.bank_account);
        bank_account[name] = value.replace(/^\s+|\s+$|\s+(?=\s)/g, " ");
        this.setState({ bank_account });
    }

    handleInputIBANCheck({ target: { name, value } }) {
        const ALLOWED_CHARS = new RegExp("^[a-zA-Z0-9]+$");
        const IBAN_input = document.querySelector("#iban");
        IBAN_input.value = value.toUpperCase();

        if (!ALLOWED_CHARS.test(value)) {
          IBAN_input.value = value.replace(/[^0-9a-zA-Z]+/g,'');
        }
    }

    handleInputIBANChange({ target: { name, value } }) {
        let bank_account = Object.assign({}, this.state.bank_account);
        bank_account[name] = value;
        this.setState({ bank_account });
    }

    handleSelectChange(name, data) {
        let bank_account = Object.assign({}, this.state.bank_account);
        let isChecked = bank_account['dd_default'];

        if (name === 'status') {
            switch(data.value) {
                case 0:
                    this.setState({
                        ibanInputDisabled: 'disabled',
                        dd_defaultDisabled: 'disabled'
                    });
                    this.unCheck();
                    break;
                case 1:
                    this.setState({
                        ibanInputDisabled: '',
                        dd_defaultDisabled: ''
                    });
                    break;
                default:
                    break;
            }
        }

        bank_account[name] = data.value;
        this.setState({
            bank_account,
            [name]: data
        })
    }

    unCheck() {
        let bank_account = Object.assign({}, this.state.bank_account);
        let field = document.getElementById("dd_default");
        field.checked = false;

        bank_account.dd_default = 0;
        this.setState({ bank_account })
    }

    handleCheckChange({ target: { name, checked }}) {
        let bank_account = Object.assign({}, this.state.bank_account);
        bank_account[name] = checked;
        this.setState({ bank_account })
    }

    handleDatePickerChange(date) {
        let bank_account = Object.assign({}, this.state.bank_account);
        const { newDate } = this.props.dispatch(validateDate(date));

        bank_account.dt_of_sgntr = newDate;

        this.setState({ bank_account })
    }

    handleKeyDown = (e, name) => {
        e.preventDefault();
    };

    handleSubmit(e) {
        e.preventDefault();

        let bank_account = Object.assign({}, this.state.bank_account);
        const { selectedData, id, dispatch, loader } = this.props,
            { iban, description, mndt_id, dt_of_sgntr, dd_default, status } = bank_account,
            IBAN = dispatch(validateIBAN(iban));

        // Validations
        if (iban && !IBAN.valid) {
            return Toast.fire({ title: 'IBAN must be valid!', icon: 'warning' })
        }

        if (!description || (description && description.trim() === '')) {
            return Toast.fire({ title: 'Account holder field is required!', icon: 'warning' })
        }

        if (!mndt_id) {
            return Toast.fire({ title: 'Mandate identification field is required!', icon: 'warning' })
        }

        if (!iban || (iban && iban.trim() === '')) {
            return Toast.fire({ title: 'IBAN field is required!', icon: 'warning' })
        }

        if (!dt_of_sgntr) {
            return Toast.fire({ title: 'Date of signature field is required!', icon: 'warning' })
        }

        if (!loader) {
            dispatch(loaderToggle(true));

            // check status & set status label & direct debit
            if (status === 0) { // inactive
                bank_account.dd_default = 0;
            }
            // set date
            bank_account.dt_of_sgntr = parseDateToSaveFormat(new Date(bank_account.dt_of_sgntr));

            if (selectedData) {
                (async () => {
                    const { ok } = await UpdateBankAccount(id, selectedData.id, bank_account);

                    this.handleOk(ok)
                })()
            } else {
                (async () => {
                    const { ok } = await AddBankAccount(id, bank_account);

                    this.handleOk(ok)
                })()
            }
        }
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update();
        }
    };

    componentDidMount() {
        const { selectedData } = this.props;

        if (selectedData) {
            let bank_account = Object.assign({}, this.state.bank_account);

            const getStatus = statusOpt.find(status => parseInt(status.value) === parseInt(selectedData.status));
            bank_account.status = getStatus;
            if (getStatus && getStatus.value === 0) {
                bank_account.dd_defaultDisabled = 'disabled';
                bank_account.ibanInputDisabled = 'disabled';
                this.setState({ dd_defaultDisabled: 'disabled', ibanInputDisabled: 'disabled' });
            }
            this.setState({ status: getStatus ? getStatus : null })
        }

        if (this.state.bank_account.id === undefined) {
            (async () => {
                const { ok, data } = await NextMndtId(this.props.id);

                if (ok) {
                    let bank_account = Object.assign({}, this.state.bank_account);
                    bank_account.mndt_id = data.data;
                    this.setState({ bank_account })
                }
            })()
        }
    }

    render() {
        const { loader, show, hide } = this.props,
            { formType, formName, bank_account, status } = this.state,
            { description, iban, bic, bank_name, mndt_id, dd_default, dt_of_sgntr } = bank_account;

        return (
            <Modal isOpen={show} toggle={hide} centered className="modal-xl">
                { !loader && <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <ModalHeader>{formType} {formName}</ModalHeader>
                    <ModalBody className="mt-3 mb-3">
                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">Mandate ID / Direct debit:</span>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col className="pb-2">
                                        <Input
                                            id="mndt_id"
                                            name="mndt_id"
                                            placeholder="Mandate identification"
                                            value={mndt_id ? mndt_id : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col className="pb-2">
                                        <label className="mb-0 mt-1 ml-2">
                                            <CustomInput
                                                id="dd_default"
                                                type="checkbox"
                                                name="dd_default"
                                                label="Direct debit"
                                                className="dd_default_checkbox"
                                                defaultChecked={dd_default ? true : dd_default === undefined ? true : false}
                                                disabled={this.state.dd_defaultDisabled}
                                                onChange={(e) => this.handleCheckChange(e)}
                                            />
                                        </label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">Account holder / Bank name:</span>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <Input
                                            id="description"
                                            name="description"
                                            placeholder="Account holder"
                                            value={description ? description : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <Input
                                            id="bank_name"
                                            name="bank_name"
                                            placeholder="Bank name"
                                            value={bank_name ? bank_name : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">IBAN / BIC:</span>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <Input
                                            id="iban"
                                            name="iban"
                                            placeholder='IBAN'
                                            value={iban ? iban.toUpperCase() : ''}
                                            disabled={ this.state.ibanInputDisabled }
                                            onKeyUp={(e) => this.handleInputIBANCheck(e)}
                                            onChange={(e) => this.handleInputIBANChange(e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <Input
                                            id="bic"
                                            name="bic"
                                            placeholder="BIC"
                                            value={bic ? bic : ''}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={3} className="pb-2">
                                <span className="label">Status / Date of signature:</span>
                            </Col>
                            <Col md={9}>
                                <Row className="form-row">
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <Select
                                            id="status"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Status"
                                            options={statusOpt}
                                            value={status ? status : null}
                                            onChange={this.handleSelectChange.bind(this, 'status')}
                                            maxMenuHeight={300}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                                        <DatePicker
                                            id="dt_of_sgntr"
                                            className="form-control"
                                            name="dt_of_sgntr"
                                            placeholderText="Date of signature"
                                            dateFormat="P"
                                            autoComplete="off"
                                            dateFormatCalendar="MMMM"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown={true}
                                            selected={dt_of_sgntr ? new Date(dt_of_sgntr) : null}
                                            value={dt_of_sgntr ? new Date(dt_of_sgntr) : null}
                                            onChange={(e) => this.handleDatePickerChange(e)}
                                            ref="dt_of_sgntr"
                                            onKeyDown={(e) => this.handleKeyDown(e, 'dt_of_sgntr')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <span className="btn btn-danger" onClick={hide}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                        <Button color="primary">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ModalFooter>
                </Form> }
            </Modal>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(BankAccountsForm);