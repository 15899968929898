import React from "react";
import { GetMyEntries } from '../../../../controllers/entries';
import JournalForm from "./Form";
import ParentTable from '../../../../components/ParentTable';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Check} from 'react-feather';

class Entries extends React.Component {
  percentageFormatter = (cell) => {
      if (cell) {
        return (<React.Fragment>
          {cell} %
        </React.Fragment>)
      } else {
        return <span>{String.fromCharCode(8212)}</span>
      }
  };

  formatDate = (cell) => {
    if (cell)
      return <span className="date-format">{this.props.t("date_format", { date: new Date(cell) })}</span>
  };

  defaultFormatter = (cell) => {
    return (
        <React.Fragment>
          {cell === 1 &&
          <Check size={18} color="#47bac1" className="check" />
          }
        </React.Fragment>
    )
  };

  formatCurrency = (cell) => {
    const {t} = this.props;
    if (cell) {
      let floatCell = cell ? cell : null;
      let floatStyle = floatCell && parseFloat(floatCell) < 0
          ? 'float-negative'
          : floatCell && parseFloat(floatCell) > 0
              ? 'float-positive'
              : 'float-null';
      return <span className={floatStyle ? floatStyle : 'float-zero'}>{t(
          "currency_format", {number: Number(parseFloat(cell))})}</span>
    } else {
      return <span className={'float-zero'}>{t("currency_format",
          {number: Number(0)})}</span>
    }
  };

  render() {
    const {journal_id} = this.props;
     return(
      <React.Fragment>
        <ParentTable
          table="Entries"
          id={journal_id}
          keyField={journal_id}
          noTopBorder={true}
          data={ GetMyEntries }
          columns={[
            {
              dataField: "entry_no",
              text: "Entry no.",
              sort: true,
              attrs: {
                'data-label': 'Entry no.'
              }
            },
            {
              dataField: "account.description",
              text: "Account",
              sort: false,
              attrs: {
                'data-label': 'Account'
              }
            },
            {
              dataField: "account.code",
              text: "Account code",
              sort: false,
              attrs: {
                'data-label': 'Account code'
              }
            },
            {
              dataField: "description",
              text: "Description",
              sort: true,
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "rounded_debit",
              text: "Debit",
              sort: false,
              formatter: this.formatCurrency,
              attrs: {
                'data-label': 'Debit'
              }
            },
            {
              dataField: "rounded_credit",
              text: "Credit",
              sort: false,
              formatter: this.formatCurrency,
              attrs: {
                'data-label': 'Credit'
              }
            },
            {
              dataField: "vat_amount",
              text: "VAT",
              sort: false,
              formatter: this.formatCurrency,
              attrs: {
                'data-label': 'VAT'
              }
            },
            {
              dataField: "date",
              text: "Date",
              sort: true,
              formatter: this.formatDate,
              attrs: {
                'data-label': 'Date'
              }
            },
          ]}
          rowAction="edit"
          rowActionForm={ JournalForm }
          headerDropdownOpts={[{
            label: 'Add',
            action: JournalForm
          }]}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation('translations')(connect()(Entries)));
