import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { createSelector } from 'reselect';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  Col,
  CustomInput, Row,
} from 'reactstrap';
import { AddUser, UpdateUser, GetUser } from "../../controllers/users";
import { GetPersons } from "../../controllers/persons";
import { GetGroupList } from "../../controllers/tenants";
import { loaderToggle } from "../../redux/actions/loaderActions";
import Toast from "../../components/Toast";
import {
  GetInvoices, GetRelation, GetRelationsDependencies, GetSubscriptions,
} from '../../controllers/relations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

const CUSTOMER_RELATION_TYPE = 1;
const typeOpts = [
  { value: 0, label: "-" },
  { value: 1, label: "Customer" },
  { value: 2, label: "Employee" }
];

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    const { selectedData } = this.props;
    const relation_id = selectedData && selectedData.id ? selectedData.id : null;

    this.state = {
      formType: selectedData && (selectedData.username || (selectedData.user && selectedData.user.username)) ? 'Edit' : 'Create',
      formName: 'user',
      user: {},
      relation_id: relation_id
    }
  }

  handleSelectChange(name, data) {
    let user = Object.assign({}, this.state.user);

    user[name] = data;

    this.setState({ user })
  }

  handleInputChange({ target: { name, value } }) {
    let user = Object.assign({}, this.state.user);

    user[name] = value;

    this.setState({ user })
  }

  handleCheckChange({ target: { name, checked } }) {
    let user = Object.assign({}, this.state.user);
    if (name === 'enabled') {
      user[name] = checked
    } else {
      user[name] = checked ? 1 : 2;
    }

    this.setState({ user })
  }

  handleSubmit(e) {
    e.preventDefault();

    let user = Object.assign({}, this.state.user);
    const {selectedData, dispatch, loader/*, relation_type*/} = this.props,
          {username/*, groups*/} = user;

    console.log('userForm handleSubmit user', user);
    console.log('userForm handleSubmit formType', this.state.formType);
    console.log('userForm handleSubmit relation_id', this.state.relation_id);
    console.log('userForm handleSubmit selectedData', selectedData);

    //return false;

    //Validations
    if (!username) {
      return Toast.fire({ title: 'Username field is required!', icon: 'warning' })
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      if (selectedData && this.state.formType === "Edit") {
        (async () => {
          let id = selectedData && selectedData.user && selectedData.user.id ? selectedData.user.id : selectedData.id;

          user.enabled = user.enabled === true ? 1 : 0;

          const { ok } = await UpdateUser(user, id);
          this.handleOk(ok);

        })();

      } else {
        (async () => {

          let user = Object.assign({}, this.state.user);
          //const relation_id = this.state.relation_id;
          //if (this.state.relation_id) {
            //user.relation_id = this.state.relation_id;
          //}

          const { ok } = await AddUser(user);
          this.handleOk(ok);

        })();
      }
    }
  }

  handleOk = (ok) => {
    const { hide, update, dispatch } = this.props;

    dispatch(loaderToggle(false));

    if (ok) {
      hide();
      update('updateperson');
    }
  };

  componentDidMount() {
    const { dispatch, selectedData, id } = this.props;

    console.log("Users form - id (this.props) ->", id);
    console.log("Users form - selectedData (this.props) ->", selectedData);
    console.log("Users form - this.props ->", this.props);

    // if user found continue else close modal screen & return to list
    if (selectedData) {

      const { user: { tenant_id } } = this.props;

      console.log("Users form - tenant_id ->", tenant_id);

      let user_id; // = selectedData.user && selectedData.user.id ? selectedData.user.id : null;
      let person_id; // = selectedData.person_id ? selectedData.person_id : null;

      if (selectedData.user && selectedData.user.id) {
        user_id = selectedData.user.id;
      } else if (selectedData.person_id) {
        user_id = selectedData.id;
      }

      if (selectedData.user && selectedData.user.person_id) {
        person_id = selectedData.user.person_id;
      } //else {
        //user_id = selectedData.id;
      //}


      //if ()


      console.log("Users form - user_id ->", user_id);
      console.log("Users form - person_id ->", person_id);

      dispatch(loaderToggle(true));

      (async () => {
        let groupOpts = [];
        let personData;
        let userData;

        // user form in relations persons tab
        if (user_id && person_id) {
          const [users, person, groupsList] = await Promise.all([
            GetUser(user_id),
            GetPersons(person_id),
            GetGroupList(null, tenant_id)
          ]);

          if (groupsList.ok) {
            console.log("Users form - groupsList ->", groupsList);

            const {data} = groupsList;
            data.data && data.data.map(({name, id}) => {
              return groupOpts.push({
                label: name, value: id
              })
            });
          }

          console.log("Users form - groupOpts ->", groupOpts);
          this.setState({ groupOpts });

          if (users.ok) {
            console.log("Users form - users ->", users);
            const {data} = users;
            console.log("Users form - users - data ->", data);
            userData = data.data ? data.data : null;
          }

          if (person.ok) {
            console.log("Users form - person ->", person);
            const {data} = person;
            console.log("Users form - person - data ->", data);
            personData = data.data ? data.data : null;
          }

        // user form in users table page
        } else if (user_id) {
          const [users, groupsList] = await Promise.all([
            GetUser(user_id),
            GetGroupList(null, tenant_id)
          ]);

          if (groupsList.ok) {
            console.log("Users form - groupsList ->", groupsList);

            const {data} = groupsList;
            data.data && data.data.map(({name, id}) => {
              return groupOpts.push({
                label: name, value: id
              })
            });
          }

          console.log("Users form - groupOpts ->", groupOpts);
          this.setState({groupOpts});

          if (users.ok) {
            console.log("Users form - users ->", users);
            const {data} = users;
            console.log("Users form - users - data ->", data);
            userData = data.data ? data.data : null;
          }
        }

        console.log("Users form - userData ->", userData);
        console.log("Users form - personData ->", personData);

        if (userData && personData) {

          // user form in relations persons tab
          const type = personData && personData.relation_type === CUSTOMER_RELATION_TYPE
                ? CUSTOMER_RELATION_TYPE
                : (personData.relation_type ? personData.relation_type : 0);
          const email = userData.id && personData.email
              ? personData.email
              : null;
          const username = userData.id && userData.username
              ? userData.username
              : null;

          this.setState({
            user: {
              id: userData.id,
              person_id: personData.id ? personData.id : null,
              username: username ? username : email,
              enabled: userData.id && userData.enabled ? userData.enabled : 0,
              groups: personData.id && personData.user && personData.user.groups && personData.user.groups.name && personData.user.groups.id ? {
                label: personData.user.groups.name,
                value: personData.user.groups.id
              } : null,
              type,
            }
          });

        } else {

          // user form in users table page
          const type = selectedData && selectedData.type
              ? selectedData.type
              : selectedData.person && selectedData.person.relation_type ===
              CUSTOMER_RELATION_TYPE
                  ? CUSTOMER_RELATION_TYPE
                  : (selectedData.relation_type
                      ? selectedData.relation_type
                      : 0);

          const username = userData && userData.id && userData.username ? userData.username : null;

          this.setState({
            user: {
              person_id: selectedData.id,
              username: username
                  ? username
                  : selectedData.email, //email: selectedData.email,
              enabled: selectedData.enabled ? selectedData.enabled : 0,
              groups: userData && userData.groups && groupOpts && groupOpts.length > 0
                  ? groupOpts.find(item => parseInt(item.value) === parseInt(userData.groups.id))
                  : null,
              type,
            }
          })
        }

        this.setState({hasLoaded: true});
        dispatch(loaderToggle(false));

      })();
    }
  }

  render() {
    const { show, hide, loader, selectedData } = this.props,
      { formType, formName, hasLoaded, user, groupOpts } = this.state,
      { username, email, groups, type, enabled } = user;

    return (
      <React.Fragment>
        {(!loader || hasLoaded) &&
          <Modal isOpen={show} toggle={hide} centered>
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <ModalHeader>{formType} {formName}</ModalHeader>
              <ModalBody className="mt-3 mb-3">
                <Row className="pb-2">
                  <Col md={3}>
                    <label className="col-form-label">Username:</label>
                  </Col>
                  <Col>
                    <Input
                        id="username"
                        name="username"
                        placeholder="Username"
                        value={username ? username : ''}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                  {selectedData && selectedData.person && <Col>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={email ? email : ''}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={!!(selectedData && selectedData.person)}
                    />
                  </Col>}
                </Row>

                <Row className={`${type === 1 && 'f2x-hide'} pb-2`}>
                  <Col md={3}>
                    <label className="col-form-label">Groups:</label>
                  </Col>
                  <Col md={9}>
                    <Select
                      id="groups"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Groups"
                      options={groupOpts}
                      value={groups}
                      onChange={this.handleSelectChange.bind(this, 'groups')}
                      maxMenuHeight={300}
                      isDisabled={!(username && type !== 1)}
                    />
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col md={3}>
                    <label className="col-form-label">Type:</label>
                  </Col>
                  <Col md={9}>
                    <Select
                      id="type"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Type"
                      options={typeOpts}
                      value={typeOpts.find(r => r.value === type)}
                      onChange={this.handleSelectChange.bind(this, 'type')}
                      maxMenuHeight={300}
                      isDisabled={type === 1}
                    />
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col md={{ size: 'auto', offset: 3 }}>
                    <CustomInput
                      id="enabled"
                      className="mt-1"
                      type="checkbox"
                      name="enabled"
                      label="Enabled"
                      defaultChecked={enabled === 1}
                      onChange={(e) => this.handleCheckChange(e)}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="justify-content-between">
                <span className="btn btn-danger" onClick={hide}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <Button color="primary">
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              </ModalFooter>
            </Form>
          </Modal>}
      </React.Fragment>
    )
  }
}

const userSelector = createSelector(
  state => state.user,
  user => user
);

const loaderSelector = createSelector(
  state => state.loader,
  loader => loader
);

const mapStateToProps = createSelector(
  userSelector,
  loaderSelector,
  (user, loader) => ({
    user,
    loader
  })
);

export default connect(mapStateToProps)(UserForm);
