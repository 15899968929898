import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    GetPublicAPI = Api.getPublicAPI;
    //PostPublicAPI = Api.PostPublicAPI // TO DO

const CheckAvailability = (params) => {
  return (async () => {
    return await PostAPI(`availability-check`, params);
  })();
};

const CheckPublicAvailability = (params) => {
  return (async () => {
    return await PostAPI(`public/availability-check`, params);
  })();
};

const GetPublicCountryCities = (api_key, id) => {
  return (async () => {
    return await GetPublicAPI(`public/availability-check/countries/${id}/cities?api_key=${api_key}`);
  })();
};

const GetPublicCountries = (api_key) => {
  return (async () => {
    return await GetPublicAPI(`public/availability-check/countries?api_key=${api_key}`);
  })();
};

const GetPublicLocationByParams = (zip_code, house_number, api_key) => {
  return (async () => {
    return await GetPublicAPI(`public/postcode_check?zipcode=${zip_code}&house_number=${house_number}&api_key=${api_key}`);
  })();
};

export {
  CheckAvailability,
  CheckPublicAvailability,
  GetPublicCountryCities,
  GetPublicCountries,
  GetPublicLocationByParams,
}