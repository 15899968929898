import { html } from '@codemirror/lang-html';
import { EditorView } from "@codemirror/view";
import { faSave, faSearchPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeMirror from '@uiw/react-codemirror';
import { enGB, enUS, nl } from "date-fns/locale";
import React from "react";
import { registerLocale } from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Toast from "../../../../components/Toast";
import { CreatePDFTemplate, UpdatePDFTemplate } from '../../../../controllers/tenants';
import { GetUsers } from '../../../../controllers/users';
import { loaderToggle } from "../../../../redux/actions/loaderActions";
import { validateDate } from "../../../../redux/actions/validateActions";
import ZoomedHTMLScreen from "./ZoomedHTMLScreen";

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class PDFTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("PDFTemplateForm - props ->", props);
    const { selectedData } = this.props;
    this.state = {
      formType: selectedData ? 'Edit' : 'Add',
      formName: ' | PDF template',
      tenant_id: props.id ? props.id : null,
      pdf_template: selectedData ? selectedData : {},
      pdf_template_original: selectedData ? selectedData : {},
      zoomModalOpenNested: false,
      zoomedHtml: "",
      saveClicked: false
    }
  }

  openZoomModal = (html, fieldName, e) => {
    console.log("openZoomModal - fieldName ->", fieldName);
    e.stopPropagation(); // Prevent event propagation to the parent modal        
    this.setState({
      zoomModalOpenNested: true,
      zoomedHtml: html,
      zoomedField: fieldName // Store the field name to identify later
    });
  };

  closeZoomModal = () => {
    this.setState({
      zoomModalOpenNested: false,
      zoomedHtml: "",
    });
  };

  getLocale = (locale) => {
    const dateLocalesPath = {
      'nl': 'nl',
      'en': 'en-GB',
      'us': 'en-US'
    };

    //require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
  };

  handleInputChange({ target: { name, value } }) {
    let pdf_template = Object.assign({}, this.state.pdf_template);
    pdf_template[name] = value;
    this.setState({ pdf_template });
  }

  handleTextareaChange = (e, name) => {
    console.log('handleTextareaChange - e ->', e);
    console.log('handleTextareaChange - value ->', name);
    let pdf_template = Object.assign({}, this.state.pdf_template);
    pdf_template[name] = e;
    this.setState({ pdf_template });
  };

  handleDatePickerChange(name, date) {
    let pdf_template = Object.assign({}, this.state.pdf_template);
    const { newDate } = this.props.dispatch(validateDate(date));
    pdf_template[name] = newDate;
    this.setState({ pdf_template })
  }

  handleSelectChange(name, data) {
    console.log("name, data ->", name, data);
    let pdf_template = Object.assign({}, this.state.pdf_template);
    pdf_template[name] = data.value;
    this.setState({
      pdf_template,
      [name]: data
    });
  }

  handleKeyDown = (e, name) => {
    e.preventDefault();
  };

  remove_linebreaks(str) {
    return str.replace(/[\r\n]+/gm, "");
  }

  handleSubmit(e) {
    e.preventDefault();
    let pdf_template = Object.assign({}, this.state.pdf_template);
    const { selectedData, loader, dispatch, id } = this.props;
    const { tenant_id, type, description, header_html, main_html, footer_html } = pdf_template;
    console.log("PDF template form - handleSubmit - id ->", id);
    console.log("PDF template form - handleSubmit - selectedData ->", selectedData);
    console.log("PDF template form - handleSubmit - pdf_template ->", pdf_template);

    //return false;

    //Validations
    if (!type) {
      return Toast.fire({ title: 'Type field is required!', icon: 'warning' })
    }

    //if (!description) {
    //  return Toast.fire({ title: 'Description from field is required!', icon: 'warning' })
    //}

    if (!loader) {
      dispatch(loaderToggle(true));

      if (selectedData) {
        (async () => {
          const { ok } = await UpdatePDFTemplate(pdf_template, selectedData.id);
          this.handleOk(ok);
        })()
      } else {
        (async () => {
          const { ok } = await CreatePDFTemplate(pdf_template);
          this.handleOk(ok);
        })();
      }
    }
  }

  handleOk = (ok) => {
    const { hide, update, dispatch } = this.props;

    dispatch(loaderToggle(false));

    if (ok) {
      hide();
      update();
    }
  };

  loadUsers = (value, callback) => {
    const params = { search: value };

    (async () => {
      const { ok, data } = await GetUsers(params);
      if (ok) {
        let users = [];
        data.data.map(({ username, id }) => {
          return users.push({
            label: username,
            value: id
          })
        });
        callback(users);
      }
    })();
  };

  showEmailTemplatePreview = (e) => {
    console.log("showEmailTemplatePreview - e ->", e);
  };

  /*loadUser = (id) => {
    console.log("id isset - id ->", id);
    console.log("id isset - userData ->", this.state.userData);

    if (id && this.state.userData && this.state.userData.id && parseInt(this.state.userData.id) === parseInt(id)) {
      console.log("username isset - username ->", this.state.userData.username);
      return { value: this.state.userData.id, label: this.state.userData.username };
    }
  };*/

  componentDidMount() {
    const { selectedData, dispatch } = this.props;

    dispatch(loaderToggle(true));
    (async () => {
      /*const { ok, data } = await GetUsers();
      if (ok && data && data.data) {
        let users = [];

        data.data.map(({ username, id }) => {
          return users.push({
            label: username,
            value: id
          })
        });

        console.log("Api keys form - componentDidMount - users ->", users);
        console.log("Api keys form - componentDidMount - selectedData ->", selectedData);
        this.setState({ users });
      }

      if (selectedData && selectedData.user_id) {
        const { ok, data } = await GetUser(selectedData.user_id);
        if (ok) {
          console.log("Api keys form - componentDidMount - GetUser - data ->", data);
          let userData = [];

          if (data && data.data) {
            userData = data.data;

            this.setState({ user_id: userData.id });
            this.setState({ userData: userData });
          }
        }
      }*/

      dispatch(loaderToggle(false));
      this.setState({ hasLoaded: true });

    })();
  }

  handleZoomedHtmlChange = (html) => {
    console.log("handleZoomedHtmlChange -> html", html);    
    const { zoomedHtml } = this.state;
    this.setState({ zoomedHtml: html });
  };
  
  handleSave = () => {
    const { zoomedHtml } = this.state;  
    this.setState((prevState) => ({
      pdf_template: {
        ...prevState.pdf_template,
        // Assuming 'fieldName' is a placeholder for the actual field name
        // Replace 'fieldName' with the actual key you wish to update in 'pdf_template'
        fieldName: zoomedHtml,
      },
      saveClicked: true,
    }), () => {
      this.closeZoomModal();
    });
    console.log("handleSave -> zoomedHtml", zoomedHtml);
  };

  render() {
    const { show, hide, selectedData } = this.props,
      { zoomedHtml, zoomModalOpenNested, zoomedField, formType, formName, users, pdf_template, pdf_template_original } = this.state,
      { id, type, description, tenant_id, version, notes, header_html, main_html, footer_html } = pdf_template ? pdf_template : null;

    console.log("PDF form - render - pdf_template ->", pdf_template);
    //console.log("Api keys form - render - userData ->", userData);

    return (
      <Modal className="modal-xl" isOpen={show} toggle={hide} centered>
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          <ModalHeader>
            <span>{formType} {formName}</span>
          </ModalHeader>
          <ModalBody key="0" className="mt-3 mb-3">
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Type:</label>
              </Col>
              <Col md={9}>
                <Input
                  name="type"
                  placeholder="Type"
                  value={type ? type : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Description:</label>
              </Col>
              <Col md={9}>
                <Input
                  name="description"
                  placeholder="Description"
                  value={description ? description : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Version:</label>
              </Col>
              <Col md={9}>
                <Input
                  name="version"
                  placeholder="Version"
                  value={version ? version : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Notes:</label>
              </Col>
              <Col md={9}>
                <Input
                  className="form-control"
                  name="notes"
                  placeholder="Notes"
                  onChange={(e) => this.handleInputChange(e)}
                  value={notes ? notes : ''}
                />
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Header:</label>
              </Col>
              <Col md={9}>
                <div className="code-mirror-container">
                  <CodeMirror
                    // value={ header_html ? header_html : '' }
                    value={pdf_template.header_html || ''} // Ensure this points to the updated state
                    style={{ minHeight: "220px", border: "1px solid #ced4da", fontSize: "11px", resize: "vertical" }}
                    height="240px"
                    name="header_html"
                    extensions={[html({ jsx: false }), EditorView.lineWrapping]}
                    onChange={(e) => this.handleTextareaChange(e, "header_html")}
                  />
                </div>
                <div style={{ textAlign: "right", marginTop: "0.5rem" }}>
                  <Button color="primary" className="btn-sm" onClick={(e) => this.openZoomModal(header_html, 'header_html', e)}>
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </Button>
                </div>
                {/*<Input
                    type="textarea"
                    name="header_html"
                    style={{ minHeight: "220px" }}
                    value={ header_html ? header_html : '' }
                    onChange={(e) => this.handleInputChange(e)}
                  />*/}
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Main:</label>
              </Col>
              <Col md={9}>
                <div className="code-mirror-container">
                  <CodeMirror
                    // value={ main_html ? main_html : '' }
                    value={pdf_template.main_html || ''} // Ensure this points to the updated state
                    style={{ minHeight: "220px", border: "1px solid #ced4da", fontSize: "11px", resize: "vertical" }}
                    height="240px"
                    name="main_html"
                    extensions={[html({ jsx: false })]}
                    onChange={(e) => this.handleTextareaChange(e, "main_html")}
                  />
                </div>
                <div style={{ textAlign: "right", marginTop: "0.5rem" }}>
                  <Button color="primary" className="btn-sm" onClick={(e) => this.openZoomModal(main_html, 'main_html', e)}>
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </Button>
                </div>
                {/*<Input
                      type="textarea"
                      name="main_html"
                      style={{ minHeight: "220px" }}
                      value={ main_html ? main_html : '' }
                      onChange={(e) => this.handleInputChange(e)}
                  />*/}
              </Col>
            </Row>
            <Row className="pb-2">
              <Col md={3}>
                <label className="col-form-label">Footer:</label>
              </Col>
              <Col md={9}>
                <div className="code-mirror-container">
                  <CodeMirror
                    value={pdf_template.footer_html || ''} // Ensure this points to the updated state                    
                    style={{ minHeight: "220px", border: "1px solid #ced4da", fontSize: "11px", resize: "vertical" }}
                    height="240px"
                    name="footer_html"
                    extensions={[html({ jsx: false })]}
                    onChange={(e) => this.handleTextareaChange(e, "footer_html")}
                  />
                </div>
                <div style={{ textAlign: "right", marginTop: "0.5rem" }}>
                  <Button color="primary" className="btn-sm" onClick={(e) => this.openZoomModal(footer_html, 'footer_html', e)}>
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </Button>
                </div>
                {/*<Input
                      type="textarea"
                      name="footer_html"
                      style={{ minHeight: "220px" }}
                      value={ footer_html ? footer_html : '' }
                      onChange={(e) => this.handleInputChange(e)}
                  />*/}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-between mt-4">
            <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <Button color="primary">
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </ModalFooter>
        </Form>
        <ZoomedHTMLScreen
          isOpen={zoomModalOpenNested}
          toggle={this.closeZoomModal}
          zoomedHtml={zoomedHtml}
          fieldName={this.state.zoomedField} // Pass the current field name
          //onChange={this.handleZoomedHtmlChange}
          //onChange={(e) => this.handleZoomedHtmlChange(e, zoomedField)} //this.handleTextareaChange(e, "header_html")
          onChange={(e) => this.handleTextareaChange(e, zoomedField)}
          onSave={this.handleSave}
          originalHtml={pdf_template_original}
          updatedHtml={pdf_template}
        />
      </Modal>
    )
  }
}

const mapStateToProps = store => ({
  loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(PDFTemplateForm)));
