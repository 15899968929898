import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import {
    Card,
    Row,
    CardBody,
    ListGroup,
    ListGroupItem,
    Col,
    Button,
    Form,
    Input,
} from "reactstrap";
import ButtonSpinner from "../../components/ButtonSpinner";
import { GetCountries, GetCountryCities } from "../../controllers/relations";
import { AddRelationCS } from "../../controllers/relations";
import { GetPlanList, GetPlans } from "../../controllers/plans";
import {
    GetContractPeriodList,
    GetNetworkOperatorList,
} from "../../controllers/subscriptions";
import { GetOrder } from "../../controllers/orders";
import { loaderToggle } from "../../redux/actions/loaderActions";
import {
    validateDate,
    validateEmail,
    validateIBAN,
} from "../../redux/actions/validateActions";
import ToastMulti from "../../components/ToastMulti";
import { nl, enGB, enUS } from "date-fns/locale";
import { parseDateToSaveFormat } from "../../redux/actions/formatActions"; // import all locales we need
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale("en", enGB);
registerLocale("nl", nl);
registerLocale("us", enUS);

const titleOpts = [
    { value: "Dhr", label: "Dhr" },
    { value: "Mevr", label: "Mevr" },
    { value: "Dhr/Mevr", label: "Dhr/Mevr" },
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Mr./Mrs./Ms.", label: "Mr./Mrs./Ms." },
];

class NewRelation extends React.Component {
    constructor(props) {
        super(props);

        console.log("NewRelation - props ->", props);
        const fetchingData = false;

        const { t } = props;
        const { newDate } = this.props.dispatch(
            validateDate(moment(new Date(), t("date_format_raw")).add(14, "days")._d)
        );

        this.state = {
            newDate: newDate,
            relation: {},
        };
    }

    getLocale = () => {
        const dateLocalesPath = {
            nl: "nl",
            en: "en-GB",
            us: "en-US",
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]
            }/index.js`);
    };

    handleInputChange({ target: { name, value } }) {
        let relation = Object.assign({}, this.state.relation);

        relation[name] = value;

        this.setState({ relation });
    }

    handleDatePickerChange(name, date) {
        let relation = Object.assign({}, this.state.relation);
        const { newDate } = this.props.dispatch(validateDate(date));

        relation[name] = newDate;

        this.setState({ relation });
    }

    handleSelectChange = (name, data) => {
        let relation = Object.assign({}, this.state.relation);

        relation[name] = data.value;

        this.setState({
            relation,
            [name]: data,
        });
    };

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false); // refs
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch, loader, history } = this.props,
            { isLoading, relation } = this.state,
            {
                title,
                first_name,
                last_name,
                birthdate,
                street1,
                house_number,
                zipcode,
                city_id,
                country_id,
                phone,
                email,
                iban,
                account_holder,
                plan,
                wish_date,
                contract_period,
                network_operator,
            } = relation,
            { valid } = dispatch(validateEmail(email)),
            IBAN = dispatch(validateIBAN(iban));
        let notificationsToastArr = [];

        if (!title) {
            notificationsToastArr.push({
                title: "Title field is required!",
                icon: "warn",
            });
        }

        if (!first_name) {
            notificationsToastArr.push({
                title: "First name field is required!",
                icon: "warn",
            });
        }

        if (!last_name) {
            notificationsToastArr.push({
                title: "Last name field is required!",
                icon: "warn",
            });
        }

        if (!birthdate) {
            notificationsToastArr.push({
                title: "Date of birth field is required!",
                icon: "warn",
            });
        }

        if (!street1) {
            notificationsToastArr.push({
                title: "Street field is required!",
                icon: "warn",
            });
        }

        if (!house_number) {
            notificationsToastArr.push({
                title: "House no. field is required!",
                icon: "warn",
            });
        }

        if (!zipcode) {
            notificationsToastArr.push({
                title: "Postal code field is required!",
                icon: "warn",
            });
        }

        if (!city_id) {
            notificationsToastArr.push({
                title: "City field is required!",
                icon: "warn",
            });
        }

        if (!country_id) {
            notificationsToastArr.push({
                title: "Country field is required!",
                icon: "warn",
            });
        }

        if (!phone) {
            notificationsToastArr.push({
                title: "Phone field is required!",
                icon: "warn",
            });
        }

        if (!valid) {
            notificationsToastArr.push({
                title: "Please enter a valid email address!",
                icon: "warn",
            });
        }

        if (!iban) {
            notificationsToastArr.push({
                title: "IBAN field is required!",
                icon: "warn",
            });
        }

        if (iban && !IBAN.valid) {
            notificationsToastArr.push({
                title: "IBAN must be valid!",
                icon: "warn",
            });
        }

        if (!account_holder) {
            notificationsToastArr.push({
                title: "Account holder field is required!",
                icon: "warn",
            });
        }

        if (!plan) {
            notificationsToastArr.push({
                title: "Plan field is required!",
                icon: "warn",
            });
        }

        if (!contract_period) {
            notificationsToastArr.push({
                title: "Contract period field is required!",
                icon: "warn",
            });
        }

        if (!network_operator) {
            notificationsToastArr.push({
                title: "Network operator field is required!",
                icon: "warn",
            });
        }

        if (notificationsToastArr.length > 0) {
            return ToastMulti(notificationsToastArr, "container-validation-id");
        }

        relation.birthdate = parseDateToSaveFormat(birthdate);
        relation.wish_date = parseDateToSaveFormat(wish_date);
        relation.billing_start = null;
        relation.subscription_start = parseDateToSaveFormat(wish_date);

        if (!loader || isLoading) {
            (async () => {
                this.setState({ isLoading: true });

                dispatch(loaderToggle(true));
                const { ok, data } = await AddRelationCS(relation);
                if (ok) {
                    this.setState({ isLoading: false });
                    dispatch(loaderToggle(false));

                    // set localstorage
                    sessionStorage.setItem("lastVisitSubscrId", data.data.subscription_id);

                    history.push({
                        pathname: `/relations/${data.data.id}/subscriptions/${data.data.subscription_id}`,
                    });
                } else {
                    this.setState({ isLoading: false });
                    dispatch(loaderToggle(false));
                }
            })();
        }
    }

    getCities(id) {
        const { dispatch } = this.props;

        dispatch(loaderToggle(true));

        (async () => {
            const { ok, data } = await GetCountryCities(id);

            await (() => {
                if (ok) {
                    const cities = data.data ? data.data : [];
                    let cityOpts = [];

                    cities.length > 0 &&
                        cities.map(({ name, id }) => {
                            return cityOpts.push({
                                label: name,
                                value: id,
                            });
                        });

                    this.setState({ cityOpts });

                    // set selected city based off order data
                    const { order } = this.state;
                    if (order) {
                        const { address } = order.data;
                        const city_id = cityOpts.find(
                            (item) => item.label === address.city
                        );
                        if (city_id) {
                            let relation = Object.assign({}, this.state.relation);
                            relation.city_id = city_id.value;

                            this.setState({
                                relation,
                                city_id,
                            });
                        }
                    }
                }
            })();

            dispatch(loaderToggle(false));
        })();
    }

    goTo = () => {
        if (this.props.setToggleForm) {
            this.props.setToggleForm(false);
        }
        return false;
    };

    componentDidMount() {
        const { dispatch, user, location } = this.props;

        if (!this.fetchingData) {
            this.fetchingData = true;

            (async () => {
                const params = new URLSearchParams(location.search),
                    getOrderId = params.get("order_id");
                dispatch(loaderToggle(true));

                const [
                    getOrderData, getCountries, getPlans, getPeriods, getNetworkOperators,
                ] = await Promise.all([
                    getOrderId ? GetOrder(getOrderId) : null,
                    GetCountries(),
                    GetPlans(),
                    GetContractPeriodList(),
                    GetNetworkOperatorList(),
                ]);

                await (() => {
                    if (getOrderData && getOrderData.ok) {
                        const {
                            customer, address, bank, contact, contract, delivery, product,
                        } = getOrderData.data.data.data;
                        console.log(contract);
                        console.log(product);

                        let relation = Object.assign({}, this.state.relation);

                        const title = titleOpts.find(
                            (item) => item.label === customer.name.title);
                        console.error(title);

                        relation.title = customer.name.title;
                        relation.first_name = customer.name.first;
                        relation.middle_name = customer.name.middle;
                        relation.last_name = customer.name.last;
                        relation.birthdate = moment(
                            customer["birth_date"] ? new Date(
                                customer["birth_date"]) : new Date(
                                    "1970-01-01"), "DD-MM-YYYY");

                        relation.street1 = address["street"];
                        relation.house_number = address["house_no"];
                        relation.house_number_suffix = address["house_no_suffix"];
                        relation.room = address.room;
                        relation.zipcode = address["postal_code"];

                        relation.phone = contact.phone;
                        relation.mobile = contact.mobile;
                        relation.email = contact.email;

                        relation.iban = bank.iban;
                        relation.account_holder = bank["holder"];

                        let param_date = moment(
                            delivery.wish_date.indexOf("ASAP") !== -1
                                ? new Date()
                                : new Date(delivery.wish_date), "DD-MM-YYYY");
                        relation.subscription_start = param_date;
                        relation.billing_start = param_date;
                        relation.wish_date = param_date;
                        relation.order_id = getOrderId;

                        this.setState({
                            order: getOrderData.data.data,
                            order_id: getOrderId,
                            relation,
                            title,
                        });
                    }

                    if (getCountries.ok) {
                        const countries = getCountries.data.data
                            ? getCountries.data.data
                            : [];
                        let countryOpts = [];

                        countries.length > 0 && countries.map(({ name, id }) => {
                            return countryOpts.push({
                                label: name, value: id,
                            });
                        });

                        this.setState({ countryOpts });

                        if (user && user.tenant &&
                            user.tenant.default_country_id) {
                            const getId = user.tenant.default_country_id,
                                getCountry = countryOpts.find(
                                    (country) => parseInt(country.value) ===
                                        parseInt(getId));
                            let relation = Object.assign({},
                                this.state.relation);

                            relation.country_id = getCountry
                                ? getCountry.value
                                : null;

                            this.setState({
                                country_id: getCountry ? getCountry : null,
                                relation,
                            });
                            this.getCities(getId);
                        }
                    }

                    if (getPlans.ok) {
                        let planOpts = [];

                        console.log("getPlans - getPlans.data.data ->",
                            getPlans.data.data);

                        getPlans.data.data.map(
                            ({ description, id, plan_start }) => {
                                if (moment(plan_start, "DD-MM-YYYY")._d <=
                                    moment(this.state.newDate,
                                        "DD-MM-YYYY")._d) {
                                    return planOpts.push({
                                        label: description, value: id,
                                    });
                                } else {
                                    return false;
                                }
                            });

                        // set selected plan based off order data
                        const { order } = this.state;
                        if (order) {
                            const { product } = order.data;
                            const plan = planOpts.find(
                                (item) => item.label.indexOf(
                                    product.package) !== -1);
                            if (plan) {
                                let relation = Object.assign({},
                                    this.state.relation);
                                relation.plan = plan.value;
                                this.setState({
                                    relation, plan,
                                });
                            }
                        }
                        this.setState({ planOpts });
                    }

                    if (getPeriods.ok) {
                        let periodOpts = [];

                        getPeriods.data.data.map(({ period, id }) => {
                            return periodOpts.push({
                                label: period, value: id,
                            });
                        });

                        // set selected period_id based off order data
                        const { order } = this.state;
                        if (order) {
                            const { contract } = order.data;
                            const contract_period = periodOpts.find(
                                (item) => parseInt(item.value) ===
                                    parseInt(contract.period.match(/\d/)[0]));
                            if (contract_period) {
                                let relation = Object.assign({},
                                    this.state.relation);
                                relation.contract_period = contract_period.value;
                                this.setState({
                                    relation, contract_period,
                                });
                            }
                        }
                        this.setState({ periodOpts });
                    }

                    if (getNetworkOperators.ok) {
                        let networkOperatorOpts = [];

                        getNetworkOperators.data.data.map(({ label, id }) => {
                            return networkOperatorOpts.push({
                                label: label, value: id,
                            });
                        });
                        this.setState({ networkOperatorOpts });
                    }
                })();

                this.fetchingData = false;

            })();

            dispatch(loaderToggle(false));

        }
    }

    render() {
        const {
            isLoading,
            relation,
            cityOpts,
            countryOpts,
            planOpts,
            periodOpts,
            networkOperatorOpts,
            title,
            city_id,
            country_id,
            plan,
            contract_period,
            network_operator,
            order_id,
        } = this.state ? this.state : {},
            {
                first_name,
                middle_name,
                last_name,
                birthdate,
                street1,
                house_number,
                house_number_suffix,
                room,
                zipcode,
                phone,
                mobile,
                email,
                iban,
                account_holder,
                wish_date,
            } = relation ? relation : {},
            { loader, t } = this.props,
            listGroupItemClass = "d-flex align-items-center px-0 py-0";

        console.log("relation new render order_id", order_id);

        const pageURL = window.location.href;
        const lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);

        return (
            <>
                <h1 className="h3 text-bold mb-3">Relations</h1>
                <Row className="d-flex justify-content-between align-items-center">
                    <Col xs="6">
                        <h5 className="mb-0 py-2 page-title">
                            Add customer with subscription
                        </h5>
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end text-right">
                        {lastURLSegment && lastURLSegment === "new" ? (
                            <Button
                                className="btn ml-auto mb-1"
                                color="gray"
                                onClick={() => this.goTo()}
                            >
                                {" "}
                                <FontAwesomeIcon icon={faTimes} />{" "}
                            </Button>
                        ) : (
                            <Button
                                className="btn ml-auto mb-1"
                                color="gray"
                                onClick={() => this.props.setToggleForm(false)}
                            >
                                {" "}
                                <FontAwesomeIcon icon={faTimes} />{" "}
                            </Button>
                        )}
                    </Col>
                </Row>

                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <Card className="mb-0 card-add-relation border-top">
                        <CardBody className="pb-1" style={{ paddingRight: "0.75rem" }}>
                            <Col xs="12" className="mb-4">
                                <ListGroup flush className="border-bottom pb-0">
                                    <h5 className="pt-2 page-head">Personal details</h5>
                                    <ListGroupItem className={listGroupItemClass} style={{ borderTop: "1px solid rgba(0, 0, 0, 0.125)" }}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">Name</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Select
                                                            id="title"
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            placeholder="Title"
                                                            options={titleOpts}
                                                            value={title ? title : null}
                                                            onChange={this.handleSelectChange.bind(
                                                                this,
                                                                "title"
                                                            )}
                                                            maxMenuHeight={300}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="first_name"
                                                            name="first_name"
                                                            placeholder="First name"
                                                            value={first_name ? first_name : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="middle_name"
                                                            name="middle_name"
                                                            placeholder="Middle name"
                                                            value={middle_name ? middle_name : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="last_name"
                                                            name="last_name"
                                                            placeholder="Last name"
                                                            value={last_name ? last_name : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className={listGroupItemClass}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">Date of birth</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <DatePicker
                                                            locale={this.getLocale()}
                                                            id="birthdate"
                                                            wrapperClassName="w-100"
                                                            className="form-control"
                                                            name="birthdate"
                                                            placeholderText="Date"
                                                            dateFormat={t("date_format_raw")}
                                                            autoComplete="off"
                                                            dateFormatCalendar="MMMM"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            maxDate={new Date()}
                                                            yearDropdownItemNumber={150}
                                                            scrollableYearDropdown={true}
                                                            selected={
                                                                birthdate
                                                                    ? moment(birthdate, "DD-MM-YYYY")._d
                                                                    : null
                                                            }
                                                            onChange={this.handleDatePickerChange.bind(
                                                                this,
                                                                "birthdate"
                                                            )}
                                                            ref="birthdate"
                                                            onKeyDown={(e) =>
                                                                this.handleKeyDown(e, "birthdate")
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>

                            <Col xs="12" className="mb-4">
                                <ListGroup flush className="border-bottom pb-0">
                                    <h5 className="pt-2 page-head">Address details</h5>
                                    <ListGroupItem className={listGroupItemClass} style={{ borderTop: "1px solid rgba(0, 0, 0, 0.125)" }}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-38">
                                                <span className="label">Address</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="street1"
                                                            name="street1"
                                                            placeholder="Street"
                                                            value={street1 ? street1 : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="house_number"
                                                            name="house_number"
                                                            placeholder="House no."
                                                            value={house_number ? house_number : ""}
                                                            maxLength="10"
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="house_number_suffix"
                                                            name="house_number_suffix"
                                                            placeholder="Suffix"
                                                            value={
                                                                house_number_suffix ? house_number_suffix : ""
                                                            }
                                                            maxLength="10"
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="room"
                                                            name="room"
                                                            placeholder="Room"
                                                            value={room ? room : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className={listGroupItemClass}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                {" "}
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Input
                                                            id="zipcode"
                                                            name="zipcode"
                                                            placeholder="Postal code"
                                                            value={zipcode ? zipcode : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            placeholder={
                                                                country_id && !loader
                                                                    ? "City"
                                                                    : "Select country first"
                                                            }
                                                            options={cityOpts}
                                                            value={city_id ? city_id : null}
                                                            onChange={this.handleSelectChange.bind(
                                                                this,
                                                                "city_id"
                                                            )}
                                                            maxMenuHeight={300}
                                                            isDisabled={!country_id && !loader}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className={listGroupItemClass}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                {" "}
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            placeholder="Country"
                                                            options={countryOpts}
                                                            value={country_id ? country_id : null}
                                                            onChange={this.handleSelectChange.bind(
                                                                this,
                                                                "country_id"
                                                            )}
                                                            maxMenuHeight={300}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>

                            <Col xs="12" className="mb-4">
                                <ListGroup flush className="border-bottom pb-0">
                                    <h5 className="pt-2 page-head">Contact details</h5>
                                    <ListGroupItem className={listGroupItemClass} style={{ borderTop: "1px solid rgba(0, 0, 0, 0.125)" }}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-38">
                                                <span className="label">Phone / mobile</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="phone"
                                                            name="phone"
                                                            placeholder="Phone"
                                                            value={phone ? phone : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="3" lg="3" className="pb-1">
                                                        <Input
                                                            id="mobile"
                                                            name="mobile"
                                                            placeholder="Mobile"
                                                            value={mobile ? mobile : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className={listGroupItemClass}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">Email address</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            placeholder="Email"
                                                            value={email ? email : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>

                            <Col xs="12" className="mb-4">
                                <ListGroup flush className="border-bottom pb-0">
                                    <h5 className="pt-2 page-head">Payment details</h5>
                                    <ListGroupItem className={listGroupItemClass} style={{ borderTop: "1px solid rgba(0, 0, 0, 0.125)" }}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">IBAN</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Input
                                                            id="iban"
                                                            name="iban"
                                                            placeholder="IBAN bank account number"
                                                            value={iban ? iban : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className={listGroupItemClass}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">Account holder</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Input
                                                            id="account_holder"
                                                            name="account_holder"
                                                            placeholder="Account holder"
                                                            value={account_holder ? account_holder : ""}
                                                            onChange={(e) => this.handleInputChange(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>

                            <Col xs="12" className="mb-4">
                                <ListGroup flush className="border-bottom pb-0">
                                    <h5 className="pt-2 page-head">Subscription details</h5>
                                    <ListGroupItem className={listGroupItemClass} style={{ borderTop: "1px solid rgba(0, 0, 0, 0.125)" }}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">Plan</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Select
                                                            id="plan"
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            placeholder="Plan"
                                                            options={planOpts}
                                                            value={plan ? plan : null}
                                                            onChange={this.handleSelectChange.bind(
                                                                this,
                                                                "plan"
                                                            )}
                                                            maxMenuHeight={300}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className={listGroupItemClass}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">Contract period</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Select
                                                            id="contract_period"
                                                            className="react-select-container"
                                                            placeholder="Contract period"
                                                            classNamePrefix="react-select"
                                                            options={periodOpts}
                                                            value={contract_period}
                                                            onChange={this.handleSelectChange.bind(
                                                                this,
                                                                "contract_period"
                                                            )}
                                                            maxMenuHeight={300}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className={listGroupItemClass}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">Network operator</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <Select
                                                            id="network_operator"
                                                            className="react-select-container"
                                                            placeholder="Network operator"
                                                            classNamePrefix="react-select"
                                                            options={networkOperatorOpts}
                                                            value={network_operator}
                                                            onChange={this.handleSelectChange.bind(
                                                                this,
                                                                "network_operator"
                                                            )}
                                                            maxMenuHeight={300}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className={listGroupItemClass}>
                                        <Row className="w-100 form-row">
                                            <Col xs="12" sm="3" md="4" lg="3" className="lh-40">
                                                <span className="label">Wish date</span>
                                            </Col>
                                            <Col xs="12" sm="9" md="8" lg="9" className="pb-0 pt-1">
                                                <Row className="g-1 form-row">
                                                    <Col xs="12" sm="6" md="6" lg="6" className="pb-1">
                                                        <DatePicker
                                                            locale={this.getLocale()}
                                                            id="wish_date"
                                                            wrapperClassName="w-100"
                                                            className="form-control"
                                                            name="wish_date"
                                                            placeholderText="Wish date"
                                                            dateFormat={t("date_format_raw")}
                                                            autoComplete="off"
                                                            selected={wish_date ? new Date(wish_date) : null}
                                                            onChange={this.handleDatePickerChange.bind(
                                                                this,
                                                                "wish_date"
                                                            )}
                                                            ref="wish_date"
                                                            onKeyDown={(e) =>
                                                                this.handleKeyDown(e, "wish_date")
                                                            }
                                                            minDate={moment().add(2, "w")._d}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>
                        </CardBody>
                    </Card>
                    <div className="d-flex justify-content-end pt-0 pb-0 px-0 mt-4">
                        <Button color="primary" disabled={isLoading}>
                            <FontAwesomeIcon icon={faSave} />
                            {isLoading && <ButtonSpinner />}
                        </Button>
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (store) => ({
    loader: store.loader,
    user: store.user,
});

export default connect(mapStateToProps)(
    withTranslation()(withRouter(NewRelation))
);
