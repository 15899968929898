import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from 'moment';

import { CardHeader, CardBody, Button, Form, FormGroup, Label, Input, CustomInput } from "reactstrap";

import { RegisterAddressConnection, CheckAddressConnectionRegistration, ActivateAddressOnt } from '../../controllers/l2fiber';
import { loaderToggle } from "../../redux/actions/loaderActions";
import { validateDate } from "../../redux/actions/validateActions";

class RequestConnection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      bandwidths: [{ 
        value: 500, 
        label: 500
      }, { 
        value: 1000, 
        label: 1000
      }],
      bandwidth: {
        value: 500,
        label: 500
      },
      connection: {
        addressPublicId: '',
        customerId: '',
        bandwidth: 500,
        hasIpTv: false,
        hasCaTv: false,
        option82Label: '',      
        requestedActivationDate: '',
        terminationDate: ''
      }
    }
  }

  handleInputChange({ target: { name, value }}) {
    let connection = Object.assign({}, this.state.connection)

    connection[name] = value

    this.setState({ connection })
  }

  handleSelectChange(name, data) {
    let connection = Object.assign({}, this.state.connection)

    connection[name] = data.value

    this.setState({
      connection,
      [name]: data
    })
  }

  handleDatePickerChange(name, date) {
    let connection = Object.assign({}, this.state.connection)
    const { newDate } = this.props.dispatch(validateDate(date))

    connection[name] = newDate

    this.setState({ connection })
  }

  handleKeyDown = ({ which }, name) => {
      if (which === 9) {
          this.refs[name].setOpen(false)
      }
  }

  handleSubmit(e) {
    e.preventDefault()

    let connection = Object.assign({}, this.state.connection)
    const { addressPublicId } = connection,
          { dispatch, loader } = this.props
    connection.method = 'POST'
      
    if (!loader) {
      dispatch(loaderToggle(true));

      (async () => {
        const register = await RegisterAddressConnection(connection)
        
        dispatch(loaderToggle(false))

        if (register.ok) {
          // Succesfully registered the Address
          let params = { 
            method: 'GET',
            addressPublicId
          }

          // Check Address Registration Status
          dispatch(loaderToggle(true))
          const check = await CheckAddressConnectionRegistration(params)
          dispatch(loaderToggle(false))
          
          // Succesfully received Address Registration status
          if (check.ok) {
            let params = { 
              action: 'Activate',
              method: 'POST',
              addressPublicId,
              ontSerial: check.data.ontSerial,
              ontDeviceType: check.data.ontDeviceType
            }
            
            dispatch(loaderToggle(true))
            const { ok, data } = await ActivateAddressOnt(params)
            dispatch(loaderToggle(false))

            if (ok) {
              alert(JSON.stringify(data))
            }
          }
        }
      })()      
    }
  }

  render() {
    const { connection, bandwidths, bandwidth } = this.state,
          { addressPublicId, customerId, option82Label, requestedActivationDate, terminationDate } = connection

    return (
      <React.Fragment>
          <CardHeader>
              <h4 className="mb-0">Request connection</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <FormGroup>
                <Label>Address public id</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="addressPublicId"
                  value={ addressPublicId }
                  onChange={(e) => this.handleInputChange(e)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Customer id</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="customerId"
                  value={ customerId }
                  onChange={(e) => this.handleInputChange(e)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Bandwidth (kb/s)</Label>
                <Select
                  id="bandwidth"
                  name="bandwidth"
                  className="react-select-container react-select-lg"
                  classNamePrefix="react-select"
                  options={ bandwidths }
                  value={ bandwidth ? bandwidth : null }
                  onChange={this.handleSelectChange.bind(this, 'bandwidth')}
                  maxMenuHeight={300}
                />
              </FormGroup>
              <FormGroup>
                <Label>Has ip tv</Label>
                <CustomInput
                  bsSize="lg"
                  id="hasIpTvTrue"
                  type="radio"
                  name="hasIpTv"
                  label="True"
                  value="true"
                  onChange={(e) => this.handleInputChange(e)}
                />
                <CustomInput
                  bsSize="lg"
                  id="hasIpTvFalse"
                  type="radio"
                  name="hasIpTv"
                  label="False"
                  value="false"
                  defaultChecked={ true }
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Has ca tv</Label>
                <CustomInput
                  bsSize="lg"
                  id="hasCaTvTrue"
                  type="radio"
                  name="hasCaTv"
                  label="True"
                  value="true"
                  onChange={(e) => this.handleInputChange(e)}
                />
                <CustomInput
                  bsSize="lg"
                  id="hasCaTvFalse"
                  type="radio"
                  name="hasCaTv"
                  label="False"
                  value="false"
                  defaultChecked={ true }
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Option 82 label</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="option82Label"
                  value={ option82Label }
                  onChange={(e) => this.handleInputChange(e)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Requested activation date</Label>
                <DatePicker
                  wrapperClassName="w-100"
                  className="form-control form-control-lg"
                  name="requestedActivationDate"
                  dateFormat="MM-dd-yyyy"
                  autoComplete="off"
                  placeholderText="Requested activation date"
                  selected={ requestedActivationDate ? moment(requestedActivationDate, 'DD-MM-YYYY')._d : null }
                  onChange={this.handleDatePickerChange.bind(this, 'requestedActivationDate')}
                  ref="requestedActivationDate"
                  onKeyDown={(e) => this.handleKeyDown(e, 'requestedActivationDate')}
                />
              </FormGroup>
              <FormGroup>
                <Label>Termination date</Label>
                <DatePicker
                  wrapperClassName="w-100"
                  className="form-control form-control-lg"
                  name="terminationDate"
                  dateFormat="MM-dd-yyyy"
                  autoComplete="off"
                  placeholderText="Termination date"
                  selected={ terminationDate ? moment(terminationDate, 'DD-MM-YYYY')._d : null }
                  onChange={this.handleDatePickerChange.bind(this, 'terminationDate')}
                  ref="terminationDate"
                  onKeyDown={(e) => this.handleKeyDown(e, 'terminationDate')}
                />
              </FormGroup>
              <FormGroup>
                <Button color="primary">Save</Button>
              </FormGroup>
            </Form>
          </CardBody>
      </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader
}))(RequestConnection);
