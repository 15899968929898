import React, { createRef } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import DatePicker, { registerLocale } from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/github.css';
import ReactQuill, { Quill, Mixin, Toolbar } from "react-quill";
import parse from "html-react-parser";
import { Link, withRouter } from 'react-router-dom';
import _ from "lodash";
import { evaluate } from "mathjs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from "sweetalert2";
import {
    Label,
    Badge,
    Button,
    Row,
    Col,
    CustomInput,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    FormGroup,
    ListGroup,
    ListGroupItem,
    UncontrolledDropdown,
    ButtonGroup, Collapse,
} from 'reactstrap';
import {
    Check,
    MoreHorizontal,
    X,
    Trash2,
    Key,
    Edit3,
    Eye,
    Mail,
    Bell,
    FileMinus,
    Download,
    PhoneCall,
    RotateCcw,
    DollarSign,
    Archive,
    PlusCircle,
    Cpu,
    FileText,
    Copy
} from 'react-feather';
import { faSearchPlus, faMapMarkerAlt, faTimes, faSave, faCopy } from "@fortawesome/free-solid-svg-icons";
import { loaderToggle } from "../redux/actions/loaderActions";
import { updateError } from "../redux/actions/errorActions";
import { validateDate, validateEmail, validateIBAN } from "../redux/actions/validateActions";
import Toast from "./Toast";
import NotesTable from "./NotesTable";
import ModalWrapper from './ModalWrapper'
import {
    formatSystemDateTimeUTC,
    parseDateToSaveFormat,
} from '../redux/actions/formatActions';
import { RemoveInvoice } from '../controllers/invoices';
import { nl, enGB, enUS } from "date-fns/locale";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DynamicTextField from './DynamicTextAreas';
import DynamicTextFieldPreview from './form_builder/FormElementBuilder';
import ImageResize from 'quill-image-resize-module-react';
import { GetRelation, GetLocationById } from '../controllers/relations';
import { searchResultsUpdate } from '../redux/actions/solrSearchActions';
import InitMap from './mapbox/InitMap';
import { Helmet } from "react-helmet";
import ZoomedHTMLScreen from "./ZoomedHTMLScreen";

Quill.register('modules/imageResize', ImageResize);

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const dateLocales = {
    'nl': nl,
    'en': enGB,
    'us': enUS,
};

const dateLocalesPath = {
    'nl': 'nl',
    'en': 'en-GB',
    'us': 'en-US',
};

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    },
    clipboard: {
        matchVisual: false
    }
};

class Details extends React.Component {
    constructor(props) {
        super(props);
        // console.log("Details - props ->", props);
        this._isComponentMounted = false; // is component mounted?
        this._isComponentUpdating = false; // is component updating?
        const isRenderingView = false; // view is busy rendering

        this.state = {
            details_id: null,
            columns: [{ count: 1 }, { count: 2 }],
            onEdit: false,
            loading: true,
            language: this.state && this.state.language ? this.state.language : "nl",
            dynamicFormData: null,
            showPreviewJsonExtra: false, // preview json_data
            buttonIcons: true, // if true show icon buttons - if false show text buttons
            locationModalOpen: false,
            zoomModalOpenNested: false,
            zoomedHtml: "",
            saveClicked: false
        };

        this.toggleForm = this.toggleForm.bind(this);
        this.selectDataFormatter = this.selectDataFormatter.bind(this);
        this.getDotNotation = this.getDotNotation.bind(this);
        this.handleQuillChange = this.handleQuillChange.bind(this);
    }

    openZoomModal = (html, e) => {
        //const { zoomModalOpenNested } = this.state;
        //console.log("click openZoomModal - zoomModalOpenNested ->", zoomModalOpenNested);

        e.preventDefault();
        e.stopPropagation(); // Prevent event propagation to the parent modal 

        this.setState({
            zoomModalOpenNested: true,
            zoomedHtml: html,
        });
    };

    closeZoomModal = () => {
        this.setState({
            zoomModalOpenNested: false,
            zoomedHtml: "",
        });
    };

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    deleteConcept(e, bool) {
        const { invoiceList, dispatch, setSelectedItem, relation, invoice, history } = this.props;
        const invId = this.props.id;

        // ask confirmation to delete concept invoice
        if (invId) {
            Swal.fire({
                title: '',
                text: 'Invoice concept will be deleted! Are you sure?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then(r => {
                dispatch(loaderToggle(true));

                let stop = null;
                if (r.value) {
                    stop = 0;
                    (async () => {
                        const { ok, data, message } = await RemoveInvoice(invId);
                        if (ok && data) {
                            sessionStorage.removeItem('lastVisitInvId');

                            let newDataInvoiceList;

                            const { invoiceList, setSelectedItem, setData, refreshInvoiceList } = this.props;

                            if (data.data && data.data.length === 0) {
                                newDataInvoiceList = [];

                                setData(null);

                                history.push(`/relations/${relation.id}/invoices`);

                            } else {

                                if (data.data && data.data.length > 0) {
                                    let invoiceId = data.data[0].id;

                                    newDataInvoiceList = data.data;

                                    dispatch({ type: 'INVOICE', payload: data.data[0] });

                                    setData(newDataInvoiceList);
                                    setSelectedItem(invoiceId);

                                    this.props.updateDetails(invId, 'delete', newDataInvoiceList);

                                    history.push(`/relations/${relation.id}/invoices/${invoiceId}`);

                                    if (refreshInvoiceList) {
                                        refreshInvoiceList();
                                    }

                                } else {
                                    newDataInvoiceList = [];

                                    this.props.updateDetails(invId, 'delete', newDataInvoiceList);
                                }
                            }

                            dispatch(loaderToggle(false));

                            await Toast.fire({
                                title: 'Invoice successfully deleted.',
                                icon: 'success'
                            });

                        } else {
                            dispatch(loaderToggle(false));

                            let message_title = 'Error deleting invoice.';
                            if (message && message !== "") {
                                message_title = message;
                            }
                            await Toast.fire({
                                title: message_title,
                                icon: 'warning'
                            });

                        }

                    })();
                } else {
                    stop = 1;
                    dispatch(loaderToggle(false));

                }
            });
        }
    }

    goTo(uri) {
        window.location.href = uri;
    }

    toggleEdit(e, bool) {
        if (this.state.onEdit && !bool) {
            this.setState({
                data: Object.assign({}, this.state.prev),
                inputData: Object.assign({}, this.state.prevInput)
            })
        } else {
            this.setState({
                prev: Object.assign({}, this.state.data),
                prevInput: Object.assign({}, this.state.inputData)
            })
        }

        this.setState({ onEdit: !this.state.onEdit });
    }

    toggleForm() {
        this.props.toggleForm();
    }

    handleInputChange({ target: { name, value } }) {
        let data = Object.assign({}, this.state.data),
            inputData = Object.assign({}, this.state.inputData);

        if (name.split('.').length > 1) {
            const newInputDataValue = this.getDotNotation(name, inputData, value);

            data = this.getDotNotation(name, data, value);
            inputData = newInputDataValue;
        } else {
            data[name] = value;
            inputData[name] = value;
        }

        this.setState({
            data,
            inputData
        })
    }

    handleCheckChange({ target: { checked } }, name) {
        let data = Object.assign({}, this.state.data),
            inputData = Object.assign({}, this.state.inputData);

        if (name.split('.').length > 1) {
            const newInputDataValue = this.getDotNotation(name, inputData, checked);

            data = this.getDotNotation(name, data, checked);
            inputData = newInputDataValue;
        } else {
            data[name] = checked;
            inputData[name] = checked;
        }

        this.setState({
            data,
            inputData
        })
    }

    handleSelectChange(name, update, link, value) {
        let data = Object.assign({}, this.state.data),
            inputData = Object.assign({}, this.state.inputData),
            innerValue = value && value.value ? value.value : null;

        if (name.split('.').length > 1) {
            const newInputDataValue = this.getDotNotation(name, inputData, value);

            data = this.getDotNotation(name, data, innerValue);
            inputData = newInputDataValue;

            if (link) {
                data = this.getDotNotation(link, data, value.label);
            }
        } else {
            data[name] = innerValue;
            inputData[name] = value;

            if (link) {
                data[link] = value.label;
            }
        }

        if (name === "payment_condition_id") {
            const reformattedDate = moment(inputData.date).format('DD-MM-YYYY');
            let split_date = reformattedDate.split('-');

            const new_due_date = moment(`${split_date[2]}-${split_date[1]}-${split_date[0]}`).add(value.net_days, 'days');

            data.due_date = new_due_date.format('DD-MM-YYYY');
            inputData.due_date = new_due_date
        }

        this.setState({
            data,
            inputData
        });

        if (update !== undefined) {
            update(value.value, data, value)
        }
    }

    handleDatePickerChange(name, date) {
        let data = Object.assign({}, this.state.data),
            inputData = Object.assign({}, this.state.inputData);

        const { newDate } = this.props.dispatch(validateDate(date));

        if (name.split('.').length > 1) {
            let newDataValue, newInputDataValue;
            newDataValue = this.getDotNotation(name, data, newDate);
            newInputDataValue = this.getDotNotation(name, inputData, newDate);

            data = newDataValue;
            inputData = newInputDataValue
        } else {
            data[name] = newDate;
            inputData[name] = newDate;
        }

        if (name === "date" && inputData && inputData.payment_condition_id && inputData.payment_condition_id.net_days) {
            let new_due_date = moment().add(2, 'w');
            data.due_date = new_due_date;
            inputData.due_date = new_due_date
        }

        if (this.props.name === 'Subscription' && name === 'subscription_start') {
            data.billing_start = newDate;
            inputData.billing_start = moment(newDate)._d
        }

        this.setState({
            data,
            inputData
        })
    }

    minify(s) {
        return s ? s
          .replace(/\>[\r\n ]+\</g, "><")  // Removes new lines and irrelevant spaces which might affect layout, and are better gone
          .replace(/(<.*?>)|\s+/g, (m, $1) => $1 ? $1 : ' ')
          .trim()
          : ""
    }

    handleQuillChangeTextarea(val) {
        let data = Object.assign({}, this.state.data),
            inputData = Object.assign({}, this.state.inputData);

        console.log("handleQuillChangeTextarea - val ->", val);

        let minified = this.minify(val);
        console.log("handleQuillChangeTextarea - val (minified) ->", minified);

        data.description_long = this.minify(val);
        inputData.description_long = this.minify(val);

        this.setState({ data, inputData });
    }


    handleChangeJSONTextarea(e, name) {
        const val = e.target.value;

        let data = Object.assign({}, this.state.data),
            inputData = Object.assign({}, this.state.inputData);

        data[name] = val;
        inputData[name] = val;

        this.setState({ data, inputData })
    }

    showPreviewJsonTextarea(e) {
        this.setState({ showPreviewJsonExtra: !this.state.showPreviewJsonExtra });
    }

    validatePreviewJsonTextarea(e) {
        console.log("validatePreviewJsonTextarea - e ->", e);
    }

    handleQuillChange(val) {
        let data = Object.assign({}, this.state.data),
            inputData = Object.assign({}, this.state.inputData);

        const name = this.props['quill'];
        if (name && name.split('.').length > 1) {
            const newInputDataValue = this.getDotNotation(name, inputData, val);

            data = this.getDotNotation(name, data, val);
            inputData = newInputDataValue
        } else {
            data[name] = val;
            inputData[name] = val
        }

        this.setState({
            data,
            inputData
        })
    }

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false)
        }
    };

    alertFooterExplaination = () => {
        //let html;

        let html = `${
        <div className="subscr-date-update">
            <OverlayTrigger
                key="subscr-tooltip-overlay-cancel"
                placement="top"
                transition={false}
                delay={200}
                overlay={
                    <Tooltip id="tooltip-top-subscr-cancel">
                        Discard changes - nothing will be updated
                    </Tooltip>}
            >
                <Badge color="grey" size={18}>cancel</Badge>
            </OverlayTrigger>    
            <OverlayTrigger
                key="subscr-tooltip-overlay-no"
                placement="top"
                transition={false}
                delay={200}
                overlay={
                    <Tooltip id="tooltip-top-subscr-no">
                        Only subscription start date will be updated
                    </Tooltip>}
            >
                <Badge color="red" size={18}>no</Badge>
            </OverlayTrigger>    
            <OverlayTrigger
                key="subscr-tooltip-overlay-yes"
                placement="top"
                transition={false}
                delay={200}
                overlay={
                    <Tooltip id="tooltip-top-subscr-yes">
                        Subscription start date & all subscription lines start dates will be updated
                    </Tooltip>}
            >
                <Badge color="primary" size={18}>yes</Badge>
            </OverlayTrigger>    
        </div>}`;

        console.log("html ->", html);
        return html;
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch, name, updatePreview } = this.props,
            iban = Object.keys(this.state.inputData).filter(data => data === 'iban'),
            email = Object.keys(this.state.inputData).filter(data => data === 'email'),
            { valid } = dispatch(validateEmail(this.state.data.email)),
            IBAN = dispatch(validateIBAN(this.state.data.iban));

        if (iban.length > 0 && this.state.data.iban && !IBAN.valid) {
            return Toast.fire({ title: 'IBAN must be valid.!', icon: 'warning' })
        }

        if (email.length > 0 && !valid) {
            return Toast.fire({ title: 'Please enter a valid email Address.', icon: 'warning' })
        }

        if (name === 'Subscription') {
            // check if start or end date is updated - parseDateToSaveFormat
            let prevStartDate = this.state.prev.subscription_start ? parseDateToSaveFormat(this.state.prev.subscription_start) : null;
            let currentStartDate = this.state.data.subscription_start ? parseDateToSaveFormat(this.state.data.subscription_start) : null;
            let prevStopDate = this.state.prev.subscription_stop ? parseDateToSaveFormat(this.state.prev.subscription_stop) : null;
            let currentStopDate = this.state.data.subscription_stop ? parseDateToSaveFormat(this.state.data.subscription_stop) : null;

            let description = this.state.data.description,
                billing_start = this.state.data.billing_start,
                subscription_start = this.state.data.subscription_start,
                status = this.state.data.status,
                subscription_stop = this.state.data.subscription_stop,
                provisioning_address = this.state.data.provisioning_address_id,
                provisioning_address_id = this.state.data.provisioning_address_id,
                provisioning_person = this.state.data.provisioning_person_id,
                billing_address = this.state.data.billing_address_id,
                billing_address_id = this.state.data.billing_address_id,
                billing_person = this.state.data.billing_person_id,
                contract_period_id = this.state.data.contract_period_id,
                isSubscriptionStopChanged = currentStopDate && prevStopDate && currentStopDate !== prevStopDate,
                isSubscriptionStartChanged = currentStartDate && prevStartDate && currentStartDate !== prevStartDate,
                isStatusRequired = (isSubscriptionStartChanged && this.state.data.status === 1 && !billing_start),
                isFormValid = isStatusRequired ? description && subscription_start && provisioning_address && provisioning_person && billing_address && billing_person && billing_start : description && subscription_start && provisioning_address && provisioning_person && billing_address && billing_person,
                errorFields = [],
                alertText = '';

            const subscriptionStartChangedPrompt = 'Do you want to set this start date on all subscription lines?';
            const subscriptionStopChangedPrompt = `Would you like to apply this end date to all subscription lines (which don't have an end date yet)?`;
            const subscriptionStartAndStopChangedPrompt = `Would you like to apply this start date & end date (which don't have an end date yet) to all subscription lines?`;

            if (isFormValid) {
                const { line_count_no_stop, subscription_stop, subscription_start } = this.state.data;
                const datesEmpty = !subscription_stop;
                const isStopChanged = (line_count_no_stop > 0 && !!subscription_stop) || (!datesEmpty && isSubscriptionStopChanged) || (currentStopDate !== prevStopDate);

                let start, stop;
                // If start date && stop date is updated
                if (isStopChanged && isSubscriptionStartChanged) {
                    // Ask to change subscription_start dates
                    Swal.fire({
                        title: '',
                        text: subscriptionStartAndStopChangedPrompt,
                        icon: 'question',
                        showConfirmButton: true,
                        showDenyButton: true,
                        showCancelButton: true,
                        cancelButtonText: `Cancel`,
                        cancelButtonAriaLabel: "CANCEL | Exit without saving",
                        denyButtonText: `No`,
                        denyButtonAriaLabel: "NO | Has no effect on subscription lines",
                        confirmButtonText: `Yes`,
                        confirmButtonAriaLabel: "YES | Effects all subscription lines",
                        reverseButtons: true,
                        customClass: {
                            cancelButton: 'swal2-cancel-quit tool-tip-swal',
                            confirmButton: 'swal2-submit-confirm tool-tip-swal',
                            denyButton: 'swal2-deny-confirm tool-tip-swal',
                        },
                        //footer: this.alertFooterExplaination()
                    })
                        .then(res => {
                            let inputData = Object.assign({}, this.state.inputData),
                                data = Object.assign({}, this.state.data);

                            // if ok - update start & end
                            if (res.isConfirmed) {
                                stop = 1;
                                start = 1;

                                inputData['update_line_start'] = start && start === 1 ? { "label": null, "value": 1 } : null;
                                inputData['update_line_stop'] = stop && stop === 1 ? { "label": null, "value": 1 } : null;

                                data['update_line_start'] = start;
                                data['update_line_stop'] = stop;

                                this.setState({
                                    data,
                                    inputData
                                });

                                this.processUpdateApi(true, start, stop);

                            } else if (res.isDenied) {
                                data['update_line_start'] = null;
                                data['update_line_stop'] = null;

                                this.setState({
                                    data,
                                    inputData
                                });

                                this.processUpdateApi(true);

                            } else {
                                return false;
                            }
                        });

                    // If start date or stop date is updated
                } else if (isStopChanged || isSubscriptionStartChanged) {
                    let alertText = '';
                    if (isStopChanged) {
                        alertText = subscriptionStopChangedPrompt;
                    }
                    if (isSubscriptionStartChanged) {
                        alertText = subscriptionStartChangedPrompt;
                    }

                    // Ask to change subscription_stop dates
                    Swal.fire({
                        title: '',
                        text: alertText,
                        icon: 'question',
                        showConfirmButton: true,
                        showDenyButton: true,
                        showCancelButton: true,
                        cancelButtonText: `Cancel`,
                        cancelButtonAriaLabel: "CANCEL | Exit without saving",
                        denyButtonText: `No`,
                        denyButtonAriaLabel: "NO | Has no effect on subscription lines",
                        confirmButtonText: `Yes`,
                        confirmButtonAriaLabel: "YES | Effects all subscription lines",
                        reverseButtons: true,
                        customClass: {
                            cancelButton: 'swal2-cancel-quit tool-tip-swal',
                            confirmButton: 'swal2-submit-confirm tool-tip-swal',
                            denyButton: 'swal2-deny-confirm tool-tip-swal',
                        },
                        //footer: this.alertFooterExplaination()
                    }).then(res => {
                        let inputData = Object.assign({}, this.state.inputData),
                            data = Object.assign({}, this.state.data);

                        if (res.isConfirmed) {
                            if (isStopChanged) {
                                stop = 1;
                            }
                            if (isSubscriptionStartChanged) {
                                start = 1;
                            }

                            inputData['update_line_start'] = start && start === 1 ? { "label": null, "value": 1 } : null;
                            inputData['update_line_stop'] = stop && stop === 1 ? { "label": null, "value": 1 } : null;

                            data['update_line_start'] = start;
                            data['update_line_stop'] = stop;

                            this.setState({
                                data,
                                inputData
                            });

                            this.processUpdateApi(true, start, stop);

                        } else if (res.isDenied) {
                            data['update_line_start'] = null;
                            data['update_line_stop'] = null;

                            this.processUpdateApi(true);

                        } else {
                            return false;
                        }
                    });

                } else {
                    // just update the form without changing subscription lines dates
                    let inputData = Object.assign({}, this.state.inputData),
                        data = Object.assign({}, this.state.data);

                    data['update_line_start'] = null;
                    data['update_line_stop'] = null;

                    this.setState({ data });
                    this.processUpdateApi(true);
                }
            } else {

                if (isStatusRequired && !billing_start) errorFields.push('billing start');
                if (!description) errorFields.push('description');
                if (!subscription_start) errorFields.push('subscription start');
                if (!provisioning_address) errorFields.push('provisioning address');
                if (!billing_address) errorFields.push('billing address');
                if (!provisioning_person) errorFields.push('provisioning person');
                if (!billing_person) errorFields.push('billing person');

                let singularPlural = errorFields.length > 1 ? ' ' : ' a ',
                    lastErrorField,
                    errorFieldsMessage;

                if (errorFields.length > 1) {
                    lastErrorField = errorFields.pop();
                    errorFieldsMessage = errorFields.join(', ');
                    errorFieldsMessage += ' and ' + lastErrorField
                } else errorFieldsMessage = errorFields.join('');

                return Toast.fire({ title: 'Please enter' + singularPlural + 'valid ' + errorFieldsMessage + '.', icon: 'warning' })
            }
        } else {
            this.processUpdateApi(false);
        }
    }

    processUpdateApi = (reload, start = null, stop = null) => {
        let { id, name, dispatch, updateApi, setIsUpdated, renderDynamicData, updatePreview, match, invoice, updateDetails } = this.props;
        let submitData = this.selectDataFormatter();

        if (submitData && name === "Subscription") {
            submitData.update_line_start = start;
            submitData.update_line_stop = stop;
        }

        //console.log("processUpdateApi - this.props ->", this.props);

        dispatch(loaderToggle(true));

        let updateId = id;
        if (isNaN(updateId) && name === "Invoice") {
            updateId = invoice.id;
        }

        (async () => {
            const { ok, data } = await updateApi(updateId, submitData);
            if (ok) {
                const inputData = Object.assign({}, this.state.inputData);

                //console.log("processUpdateApi - data ->", data.data);

                this.setState({ prevInput: inputData });
                this.toggleEdit(null, true);

                if (name === 'Tenant') {
                    const tenant = data.data;
                }

                if (name === 'Subscription') {
                    dispatch({ type: 'SUBSCRIPTION', payload: data.data.data });
                    //this.props.updateDetails(data.data.data.id, 'update', data.data.data);
                    this.updateSubscriptionLines();
                }

                if (name === 'Invoice') {
                    //dispatch({type: 'INVOICE', payload: data.data});
                    this.props.updateDetails(data.data.id, 'update', data.data);
                    //this.updateSubscriptionLines();
                }

                if (updatePreview) {
                    updatePreview(true);
                }
            }
            dispatch(loaderToggle(false));

        })();
    };

    updateSubscriptionLines = () => {
        this.setState({
            size: undefined
        });

        this.props.setIsUpdated(true);
    };

    loadOptions = ({ label, value, api, optionLabel }, val, callback) => {
        const filter = 'filter[keyword]',
            params = { [filter]: val };

        (async () => {
            const { ok, data } = await api(params);

            if (ok) {
                let options = [];

                const formatData = (item) => {
                    let newData = {
                        label: item[label],
                        value: item[value]
                    };

                    optionLabel && optionLabel.map(label => {
                        return newData[label] = item[label];
                    });

                    return newData;
                };

                await data.data.map(item => {
                    return options.push(formatData(item));
                });

                callback(options);
            }

        })();
    };

    selectDataFormatter() {
        const inputData = Object.assign({}, this.state.inputData);
        const relation_id = this.props.relation.id;

        let { details, groups } = this.props,
            data = Object.assign({}, this.state.data),
            detail = '';

        if (groups) {
            details = [];

            Object.values(groups).map(group => {
                return group.details.map(detail => {
                    return details.push(detail);
                })
            })
        }

        const detailFormatter = (item, index) => {
            const path = item.data;

            if (item.type === 'select' && this.getDotNotation(path, inputData) !== null && this.getDotNotation(path, inputData) !== undefined) {
                if (path.split('.').length === 1) {
                    data[path] = inputData[path].value;
                } else {
                    const selectedData = this.getDotNotation(path, inputData).value || this.getDotNotation(path, inputData).label;
                    data = this.getDotNotation(path, data, selectedData);
                }
            }

            if (item.type === 'datepicker') {
                if (inputData[path]) {
                    data[path] = parseDateToSaveFormat(inputData[path]); // set to YYYY-MM-DD
                }
            }

            // create here - todo: multiDetail input group

            return detail = index > 0 ? `${detail},${path}` : path;
        };

        const multiDataDetailFormatter = (datas, index) => {
            datas.map((item, index2) => {
                return detailFormatter(item, index + index2)
            })
        };

        details.map((item, index) => {
            if (!item.multiData) {
                return detailFormatter(item, index)
            } else {
                return multiDataDetailFormatter(item.datas, index)
            }
        });

        detail = detail.split(',');

        let dataHolder = '',
            newData = Object.assign({}, data);

        for (let [resItem] of Object.entries(data)) {
            details.forEach(item => {
                const path = item.data,
                    pathLength = path ? path.split('.') : null;

                if (item.disabled && dataHolder.split(',').indexOf(path) < 0) {
                    delete newData[path];
                    dataHolder = `${dataHolder},${path}`
                } else if (pathLength && ((!this.props['quill'] && detail.indexOf(resItem) < 0) || ((this.props['quill'] && resItem !== this.props['quill']) && detail.indexOf(resItem) < 0))) {
                    if (pathLength.length === 1) {
                        delete newData[resItem]
                    } else {
                        if (dataHolder.split(',').indexOf(pathLength[0]) < 0) {
                            delete newData[pathLength[0]];
                            dataHolder = `${dataHolder},${pathLength[0]}`
                        }
                    }
                }
            })
        }

        this.setState({ data });

        // check for relation_id in newData
        const checkRelationId = Object.keys(newData).indexOf('relation_id') !== -1;
        if (!checkRelationId) {
            newData['relation_id'] = relation_id;
        }

        return newData;
    }

    getDotNotation(path, obj, val) {
        if (val !== null && val !== undefined) {
            return _.set(obj, path, val);
        } else {
            return _.get(obj, path);
        }
    }

    formatTextareaData = (getData) => {
        if (getData && getData !== '') {
            if (typeof getData === 'string') {
                return parse(getData);
            } else {
                return '';
            }
        }
        return '';
    };

    formatAsText = (getData) => {
        if (getData && getData !== '') {
            if (typeof getData === 'string') {
                return getData;
            } else {
                return '';
            }
        }
        return '';
    };

    formatLink = (item) => {
        return item.link.replace(':var', this.getDotNotation(item.var, this.state.data))
    };

    formatMultiData = (item) => {
        const { t } = this.props;
        let { multiData, datas } = item,
            getData = Object.assign({}, this.state.data);

        datas.map(item => {
            let thisData = this.getDotNotation(item.data, getData);
            if (item.type && item.type === 'datepicker') {
                thisData = thisData ? t("date_format", { date: new Date(thisData) }) : String.fromCharCode(8212);

            } else if (item.type && item.type === 'checkbox') {

            } else if (item.type && item.type === 'radio') {

            } else {
                thisData = thisData ? thisData : String.fromCharCode(8212);
            }
            return multiData = multiData.replace(item.data, thisData)
        });

        return multiData
    };

    /*
        Format subscription status badge
        param - value -> selected object
     */
    formatSubscriptionStatus = (value, data) => {
        const statusLabels = [];
        statusLabels[10] = 'NEW';
        statusLabels[20] = 'CONCEPT';
        statusLabels[30] = 'PROVISIONING';
        statusLabels[40] = 'PLANNED';
        statusLabels[50] = 'ONGOING';
        statusLabels[60] = 'CANCELLED';
        statusLabels[80] = 'DE-PROVISIONING';
        statusLabels[99] = 'TERMINATED';

        let label = data ? statusLabels[data] : statusLabels[20];

        return <Badge
            color={data === 50
                ? 'success'
                : data === 99
                    ? 'cancelled'
                    : data === 60
                        ? 'warning'
                        : data === 10
                            ? 'warning'
                            : data === 30
                                ? 'default'
                                : data === 40
                                    ? 'default'
                                    : data === 80
                                        ? 'default'
                                        : 'info'}
            className="badge-pill mr-1 mb-1"
        >
            {label}
        </Badge>
    };

    handleZoomedHtmlChange = (html) => {
        this.setState({ zoomedHtml: html });
    }

    handleSave = () => {
        const { zoomedHtml } = this.state;

        this.setState({ saveClicked: true }, () => {
            this.handleQuillChangeTextarea(zoomedHtml);
            this.closeZoomModal();
        });
    }

    renderList = (item) => {
        const { zoomModalOpenNested, zoomedHtml, data, inputData, onEdit } = this.state,
            path = item.data,
            getData = this.getDotNotation(path, data),
            getInputData = this.getDotNotation(path, inputData),
            { groups } = this.props,
            disabledIfTrue = item.disabledIfTrue && item.disabledIfTrue(data),
            note = item.note;

        const { t } = this.props;

        //console.log("renderList - item ->", item);
        //console.log("renderList - getData ->", getData);

        return (
            !item.isBlank &&
            <ListGroupItem className={`d-flex align-items-center px-0${groups ? ' border-top-0' : ''}${item.isBlank ? ' b-0' : ''}`}>
                <React.Fragment>

                    <Col xs="6" md="5" lg="4" className={`form-label`}>{this.renderLabel(item)}</Col>
                    <Col xs="6" md="7" lg="8" className={`form-label-content ${item.addClass ? item.addClass : ''}${onEdit ? '' : 'd-flex align-items-center justify-content-between none-edit '}${item.multiData ? ' d-flex align-items-center' : ''}`}>
                        {   // If of ( onEdit && !item.disabled )
                            onEdit && !item.disabled && !disabledIfTrue
                                ? !item.multiData
                                    // if !multiData
                                    ? this.renderInputs(item, getInputData, path)
                                    : this.renderMultiData(item.datas)
                                : item.type === 'select' && item.style && item.style === "update_subscription_status_style"
                                    ? this.formatSubscriptionStatus(item, getData)
                                    // ElseIf of DataValue
                                    : item.type === 'select' && getData !== null && getData !== undefined
                                        ? item.isLink
                                            ? <Link to={this.formatLink(item.isLink)} className="switch-inverse font-weight-bold">{this.getDotNotation(item.isLink.linkData, data)}</Link>
                                            : path.split('.').length === 1 && item.opts && item.opts instanceof Array && item.opts.length && item.opts.length > 0 && item.opts[item.opts.findIndex(i => i.value && !!isNaN(i.value) ? i.value.toString() : '' === getData.toString())] !== undefined
                                                ? item.opts && item.opts.length > 0 ? item.opts[item.opts.findIndex(i => i.value.toString() === getData.toString())].label : ''
                                                : getInputData && getInputData.label
                                                    ? getInputData.label
                                                    : String.fromCharCode(8212)
                                        // ElseIf of DataValue
                                        : item.type === 'checkbox' ? getData ? <Check size={18} color="primary" /> : <X size={18} color="#f44455" />
                                            // ElseIf of DataValue
                                            : item.type === 'datepicker' ? this.formatDate(getData) : item.type === 'textarea'
                                                ? this.formatTextareaData(getData)
                                                // ElseIf of DataValue
                                                : item.multiData
                                                    ? this.formatMultiData(item)
                                                    // Else of DataValue
                                                    : getData
                                                        ? isNaN(getData)
                                                            // if (style)
                                                            ? this.checkStyleFormat(item, getData)
                                                            // Else of isNaN(getData)
                                                            : Number.isInteger(Number(getData))
                                                                ? this.formatCurrency(getData, item) //Number(getData)
                                                                // Else of isInteger/isFloat
                                                                : this.formatCurrency(getData, item)
                                                        // Else getData
                                                        : String.fromCharCode(8212)
                        }

                        {!onEdit && !item.disabled
                            ? ""
                            : item.type === 'textarea' && item.data === 'description_long'
                                ?
                                <div style={{ textAlign: "right", marginTop: "0.25rem" }}>
                                    {/* {<Button color="primary" onClick={(e) => this.openZoomModal(footer_html, 'footer_html', e)}></Button>}                                 */}
                                    <Button className="btn-sm" color="primary" onClick={(e) => this.openZoomModal(getInputData, e)}>
                                        <FontAwesomeIcon icon={faSearchPlus} />
                                    </Button>
                                </div>
                                : ""
                        }

                        {(item.data === "provisioning_address_id" && item.label === "Address" && !onEdit) || (item.data === "billing_address_id" && item.label === "Address" && !onEdit)
                            ?
                            this.checkLocationId(item, getData)
                            : ""
                        }

                        {this.renderNote(onEdit, note)}
                    </Col>
                </React.Fragment>
                {/*<ZoomedHTMLScreen
                    isOpen={zoomModalOpenNested}
                    toggle={this.closeZoomModal}
                    zoomedHtml={zoomedHtml}
                    onChange={this.handleZoomedHtmlChange}
                    onSave={this.handleSave}
                />*/}
            </ListGroupItem>
        )
    };

    checkLocationId = (obj, getData) => {
        // console.log("addresses location obj - checkLocationId - obj ->", obj);
        // console.log("addresses location obj - checkLocationId - getData ->", getData);
        //let location = item.opts && item.opts.length > 0 && item.opts.map((i) => i.location_id);
        //console.log("addresses location obj - checkLocationId - location ->", location);

        let foundItem;
        let locationId;
        // obj contains opts with all addresses
        if (obj && obj.opts && obj.opts.length > 0) {
            foundItem = obj.opts.findIndex(i => i.value === getData);
            if (obj.opts[foundItem]) {
                locationId = obj.opts[foundItem].location_id;
            }
        }

        if (locationId) {
            return (
                <button onClick={(e) => this.toggleMapBoxFromLocationId(e, obj, getData, locationId)} style={{ background: "none", border: "none" }} className="btn" type="button" id="map-pin">
                    <FontAwesomeIcon className="map-pin-icon" icon={faMapMarkerAlt} />
                </button>
            );
        }
    }

    locationModalClose = () => {
        this.setState({
            locationAddress: null, // set location data
            locationModalOpen: false
        });
    }

    toggleMapBoxFromLocationId = (e, obj, data, location) => {
        e.preventDefault();
        // console.log("addresses location obj - toggleMapBoxFromLocationId - e ->", e);
        // console.log("addresses location obj - toggleMapBoxFromLocationId - obj ->", obj);
        // console.log("addresses location obj - toggleMapBoxFromLocationId - data ->", data);

        let foundItem;
        let locationId;

        if (!location) {
            // obj contains opts with all addresses
            if (obj && obj.opts && obj.opts.length > 0) {
                foundItem = obj.opts.findIndex(i => i.value === data);
                if (obj.opts[foundItem]) {
                    locationId = obj.opts[foundItem].location_id;
                }
            }
        } else {
            locationId = location;
        }

        // console.log("addresses location obj - toggleMapBoxFromLocationId - foundItem ->", foundItem);
        // console.log("addresses location obj - toggleMapBoxFromLocationId - locationId ->", locationId);

        if (locationId) {
            this.getLocationData(locationId);
        }
    }

    getLocationData = (id) => {
        (async () => {
            const { ok, data } = await GetLocationById(id);
            if (ok && data.data) {
                // console.log("addresses location obj - toggleMapBoxFromLocationId - GetLocationById - data ->", data);
                // console.log("addresses location obj - toggleMapBoxFromLocationId - GetLocationById - data.data ->", data.data);

                this.setState({
                    locationAddress: data.data, // set location data
                    locationModalOpen: true
                });
            }
        })();
    }

    checkStyleFormat = (obj, data) => {
        // update_style -> status style
        if (data && obj && obj.style && obj.style === 'update_style') {
            return <Badge
                color={data.toLowerCase() === 'concept'
                    ? 'info'
                    : data.toLowerCase() === 'paid' ? 'success'
                        : 'warning'}
                className="badge-pill mr-1 mb-0 text-uppercase">
                {data.toUpperCase()}
            </Badge>;
        } else if (data && obj && obj.style && obj.style === 'text_string') {
            return data.toString();
        }
        return data;
    };

    formatCurrency = (value, obj) => {
        if (obj && obj.style && obj.style === 'text_style') {
            if (obj.extra_data && obj.extra_data.label) {
                //console.log("Comp details - extra_data ->", obj.extra_data.label);
                return <span className="non-edit-text">{obj.extra_data.label}</span>
            } else {
                return <span className="non-edit-text">{value}</span>
            }
        } else {
            const { t } = this.props;
            let floatCell = value ? value : null;
            let floatStyle = floatCell && parseFloat(floatCell) < 0
                ? 'float-negative'
                : floatCell && parseFloat(floatCell) > 0
                    ? 'float-positive'
                    : 'float-null';

            if (obj && obj.price_incl && obj.price_excl) {
                const { data } = this.state;

                if (obj.price_incl && obj.price_excl) {
                    let price_incl_formatted = t("currency_format", { number: Number(parseFloat(data[obj.price_incl])) });
                    let price_incl = +(data[obj.price_incl]);
                    let price_excl_formatted = t("currency_format", { number: Number(parseFloat(data[obj.price_excl])) });
                    let price_excl = +(data[obj.price_excl]);

                    return <span className={floatStyle ? floatStyle : 'float-zero'}>
                        {`${price_excl_formatted} / ${price_incl_formatted}`}
                    </span>;
                }
            }
            return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(parseFloat(value)) })}</span>
        }
    };

    formatDate = (dateString) => {
        const { t } = this.props;
        if (dateString) {
            return <span className="date-format">{t("date_format", { date: new Date(dateString) })}</span>
        } else {
            return <span>{String.fromCharCode(8212)}</span>
        }
    };

    renderMultiData = (datas) => {
        return datas.map((item, index) => {
            const { inputData } = this.state,
                path = item.data,
                getInputData = this.getDotNotation(path, inputData);

            return (
                <React.Fragment key={index}>
                    {index > 0 ? <span className="multi-input"> / </span> : ''}
                    <div className="multi-input-field">
                        {this.renderInputs(item, getInputData, path)}
                    </div>
                </React.Fragment>
            )
        })
    };

    renderInputs = (item, getInputData, path) => {
        let language = this.props.i18n.language;
        const { t, i18n } = this.props;

        return (
            <React.Fragment>
                {   // If of DataType
                    item.type === 'select'
                        ? item.isAsync
                            ? <AsyncSelect
                                className="react-select-container line-break"
                                classNamePrefix="react-select"
                                cacheOptions
                                defaultOptions={item.opts}
                                loadOptions={this.loadOptions.bind(this, item.isAsync)}
                                getOptionLabel={item.isAsync.getOptionLabel ? item.isAsync.getOptionLabel : () => { }}
                                value={getInputData}
                                onChange={this.handleSelectChange.bind(this, path, item.update, item.isLink ? item.isLink.linkData : undefined)}
                                maxMenuHeight={item.isAsync.getOptionLabel ? 220 : 300}
                                isClearable={item.isClearable ? item.isClearable : false}
                            />
                            : <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={item.opts}
                                value={getInputData}
                                onChange={this.handleSelectChange.bind(this, path, item.update, item.isLink ? item.isLink.linkData : undefined)}
                                maxMenuHeight={300}
                                isClearable={item.isClearable ? item.isClearable : false}
                                placeholder="Select..."
                            />
                        // ElseIf of DataType
                        : item.type === 'datepicker'
                            ? <DatePicker
                                locale={this.getLocale()}
                                className="form-control"
                                name={path}
                                dateFormat={t("date_format_raw")}
                                autoComplete="off"
                                selected={getInputData ? new Date(getInputData) : null}
                                onChange={this.handleDatePickerChange.bind(this, path)}
                                onKeyDown={(e) => this.handleKeyDown(e, path)}
                                minDate={item.minDate ? moment(item.minDate.indexOf('raw:') >= 0 ? item.minDate.split(':')[1] : this.getDotNotation(item.minDate, this.state.inputData))._d : null}
                                maxDate={item.maxDate ? moment(this.getDotNotation(item.maxDate, this.state.inputData))._d : null}
                            />
                            // ElseIf of DataType
                            : item.type === 'checkbox'
                                ? <CustomInput
                                    id={path}
                                    type="checkbox"
                                    name={path}
                                    defaultChecked={!!(getInputData || parseInt(getInputData) === 1)}
                                    onChange={(e) => this.handleCheckChange(e, path)}
                                />
                                // ElseIf of DataType
                                : item.type === 'radio'
                                    ? <div role="group" aria-labelledby={`${path}-radio-group`}>
                                        {item.opts.map((option, index) =>
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input type="radio" name={path} value={option.value} />{' '}
                                                    {option.value}
                                                </Label>
                                            </FormGroup>)
                                        }
                                    </div>

                                    // ElseIf of DataType
                                    : item.type === 'multi_checkbox'
                                        ? <div role="group" aria-labelledby={`${path}-radio-group`}>
                                            {item.opts.map((option, index) =>
                                                <div className="custom-checkbox custom-control custom-controlled-checkbox">
                                                    <Input
                                                        type="checkbox"
                                                        name={path}
                                                        value={option.value}
                                                        color={`primary`}
                                                        id={path}
                                                        disabled={false}
                                                        style={{ marginLeft: "0", display: "inherit", opacity: "0" }}
                                                    />
                                                    <Label className="custom-controlled-checkbox-label w-100">
                                                        {option.value}
                                                        {option.extra
                                                            ? option.extra.map((item, key) =>
                                                                <div key={key} className="float-right">
                                                                    <span>L1: {item.L1}</span>
                                                                    <span> | </span>
                                                                    <span>L2: {item.L2}</span>
                                                                    <span> | </span>
                                                                    <span>L3: {item.L3}</span>
                                                                </div>
                                                            )
                                                            : ""
                                                        }
                                                    </Label>
                                                </div>)
                                            }
                                        </div>

                                        // ElseIf of DataType
                                        : item.type === 'textarea'
                                            ? <ReactQuill
                                                modules={modules}
                                                theme="snow"
                                                id={path}
                                                name={path}
                                                value={getInputData ? getInputData : ''}
                                                onChange={(e) => this.handleQuillChangeTextarea(e)}
                                            />
                                            // ElseIf of DataType
                                            : item.type === 'textarea_plain'
                                                ? <Input
                                                    type="textarea"
                                                    id={path}
                                                    style={{ minHeight: "100px" }}
                                                    name={path}
                                                    value={getInputData ? getInputData : ''}
                                                    placeholder="Enter text"
                                                    onChange={(e) => this.handleChangeJSONTextarea(e)}
                                                />
                                                // ElseIf of DataType
                                                : item.type === 'textarea_json'
                                                    ? item.extra && item.extra.validator && item.extra.preview_json
                                                        ? (
                                                            <div>
                                                                <ButtonGroup className="btn-group-textarea-collapse">
                                                                    <Button color="primary" className="btn-sm" onClick={(e) => this.showPreviewJsonTextarea(e)}>{!this.state.showPreviewJsonExtra ? `Show preview` : `Hide preview`}</Button>
                                                                    <Button color="primary" className="btn-sm" disabled={this.state.showPreviewJsonExtra} onClick={(e) => this.validatePreviewJsonTextarea(e)}>Validate JSON</Button>
                                                                </ButtonGroup>
                                                                <div>
                                                                    {!this.state.showPreviewJsonExtra &&
                                                                        <Input
                                                                            type="textarea"
                                                                            id={path}
                                                                            style={{ minHeight: "100px", height: "90%" }}
                                                                            name={path}
                                                                            value={getInputData ? getInputData : ''}
                                                                            placeholder="JSON data"
                                                                            onChange={(e) => this.handleChangeJSONTextarea(e, path)}
                                                                        />}
                                                                    {this.state.showPreviewJsonExtra &&
                                                                        <div className="form-element-preview">
                                                                            {getInputData ? (
                                                                                <DynamicTextFieldPreview
                                                                                    formSchema={getInputData ? getInputData : ''}
                                                                                    mainKey={path}
                                                                                />) : (
                                                                                <p style={{ marginBottom: "10px" }}>No preview available</p>)}
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <Input
                                                                type="textarea"
                                                                id={path}
                                                                style={{ minHeight: "100px" }}
                                                                name={path}
                                                                value={getInputData ? getInputData : ''}
                                                                placeholder="JSON data format"
                                                                onChange={(e) => this.handleChangeJSONTextarea(e, path)}
                                                            />
                                                        )
                                                    // Else of DataType
                                                    : <Input
                                                        type={item.type}
                                                        name={path}
                                                        readOnly={item.readonly === true}
                                                        value={item.extra_data && item.extra_data.label ? item.extra_data.label : getInputData ? getInputData : ''}
                                                        onChange={this.handleInputChange.bind(this)}
                                                    />
                }
            </React.Fragment>
        )
    };

    renderLabel(item) {
        const { label, dynamicLabel } = item;

        if (item && dynamicLabel) {
            let newLabel = label;

            dynamicLabel.map(dynamic => {
                const { find, data, defaultData, hasMath } = dynamic;
                let getData = this.getDotNotation(data, this.state.data);

                getData = getData ? getData : defaultData;

                if (hasMath) {
                    const equation = hasMath.replace(find, getData);
                    getData = evaluate(equation)
                }

                newLabel = newLabel.replace(find, getData);

                return newLabel
            });

            return newLabel
        } else {
            return item.label
        }
    }

    renderNote = (onEdit, note) => {
        // check which reminder
        let firstWord = '?';
        if (!onEdit && note) {
            firstWord = note.replace(/ .*/, '');
            let noteDate = note.match(/\d{4}([\/.-])\d{2}\1\d{2}/g);
            if (noteDate) {
                const { t } = this.props;
                let lngDateNote = t("date_format", { date: new Date(noteDate) });
                note = note.replace(noteDate, ': ' + lngDateNote);
            }
        }
        let notify_badge = firstWord && firstWord.toLowerCase() === 'first' ? '1' : firstWord && firstWord.toLowerCase() === 'second' ? '2' : firstWord &&
            firstWord.toLowerCase() === 'warning' ? '3' : firstWord.toLowerCase() === 'final' ? '4' : '?';
        let note_content = <div className={`popover-html-contents`}><h3>{`Reminder note`}</h3><p>{note}</p></div>;

        return !onEdit && note ?
            //return note ?
            <div className="popover-inline-detail float-right">
                <FontAwesomeIcon className="reminder-icon" icon="envelope" />
                <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom`}>
                        {note_content}
                    </Tooltip>}
                >
                    <span className="popover-inline-detail-item-badge badge status-circle danger">
                        <span>{notify_badge}</span>
                    </span>
                </OverlayTrigger>
            </div> : ''
    };

    formatData = (data) => {
        let { groups, details, dispatch, dropdownItems, match, name, id } = this.props;

        let params = match.params;

        if (name === "Subscription" && data && data.id !== parseInt(params.subscrId)) {
            dispatch({ type: 'SUBSCRIPTION', payload: null });

            this.setState({
                data: null
            });

            this.renderView();
            return false;
        }

        this.setState({
            data: Object.assign({}, data),
            inputData: Object.assign({}, data)
        });

        let inputData = Object.assign({}, data);

        if (groups) {
            details = [];

            Object.values(groups).map(group => {
                return group.details.map(detail => {
                    return details.push(detail);
                })
            })
        }

        if (details !== undefined) {
            details.forEach(item => {

                const path = item.data ? item.data.split('.') : null;
                let getInputData = this.getDotNotation(item.data, inputData);

                const { t } = this.props;

                if (item.type === 'select' && getInputData !== null && getInputData !== undefined) {
                    if (path.length > 1) {
                        let selectedData = item.opts.find(
                            i => i.value.toString() ===
                                getInputData.toString());

                        inputData = this.getDotNotation(item.data, inputData, item.defaultData ? item.defaultData : selectedData ? selectedData : [{
                            value: null,
                            label: 'select'
                        }]);

                    } else {
                        if (item.opts && item.opts.length && item.opts.length > 0 && inputData && item.data && inputData[item.data]) {
                            let selectedData = '';
                            if (typeof item.opt === 'string') {
                                selectedData = item.opt;
                            } else {
                                selectedData = item.opts.find(opt => opt.value === inputData[item.data] ? inputData[item.data] : 0);
                            }

                            inputData[item.data] = item.defaultData
                                ? item.defaultData
                                : selectedData ? selectedData : [
                                    {
                                        value: null,
                                        label: ''
                                    }
                                ];
                        }
                    }

                } else if (item.type === 'datepicker') {
                    const language = this.state.language && this.state.language ? this.state.language : 'nl';
                    if (path && path.length > 1) {
                        if (getInputData) {
                            const val = formatSystemDateTimeUTC(getInputData);
                            inputData = this.getDotNotation(item.data,
                                inputData, val);

                        } else {
                            inputData[item.data] = this.getDotNotation(
                                item.data, inputData, null);
                        }
                    } else {
                        if (inputData[item.data]) {
                            inputData[item.data] = formatSystemDateTimeUTC(inputData[item.data]);
                        } else {
                            inputData[item.data] = null
                        }
                    }
                } else if (item.type === 'text' && getInputData === null) {
                    if (path.length > 1) {
                        inputData = this.getDotNotation(item.data,
                            inputData, '');
                    } else {
                        inputData[item.data] = '';
                    }
                }
            });
        }

        if (dropdownItems && dropdownItems.length > 0) {
            let newDropdownItems = [];

            dropdownItems.map(item => {
                if (data && item && item.disabled instanceof Function) {
                    let isDisabledResult = item.disabled(data);
                }

                // if reminder label is null - set the default reminder label
                if (data && item && item.function === "sendReminderTrigger" && !item.label) {
                    item.label = "Send reminder";
                }

                return ((item && item.disabled && (item.disabled instanceof Function) && !item.disabled(data))) || item && item.enabled
                    ? newDropdownItems.push(item)
                    : false
            });

            this.setState({ newDropdownItems })
        }

        this.setState({ inputData });

        dispatch(loaderToggle(false))
    };

    renderView = (renderCount, relation_id) => {
        const { getApi, include, dispatch, dataLoaded, details, groups, offLoader, match, relation, name, invoiceList, subscriptionList, relationId, history, table, tableFirst, location } = this.props;
        const { details_id } = this.state;

        this.isRenderingView = true;

        let id = this.props.id;
        let item = name ? name.toLowerCase() : null;

        const lastVisitSubscrId = sessionStorage.getItem('lastVisitSubscrId');
        const lastVisitInvoiceId = sessionStorage.getItem('lastVisitInvId');

        const showLoader = !offLoader && setInterval(() => {
            dispatch(loaderToggle(true))
        }, 0);

        if (typeof getApi === 'function') {
            (async () => {
                const { ok, data } = await getApi({ include }, id);

                //console.log("renderView - data ->", data);
                //console.log("renderView - name ->", name);
                //console.log("renderView - dataLoaded ->", dataLoaded);

                if (ok) {
                    let details_data = Object.assign({}, data.data);

                    if (data.data && name === "Invoice") {
                        //console.log("renderView - data.data.price ->", data.data.price);
                        if (!details_data.price) {
                            details_data.price = 0;
                            details_data.price_total = 0;
                            details_data.price_vat = 0;
                        }
                    }
                    //console.log("renderView - details_data (2) ->", details_data);

                    this.setState({
                        data: details_data
                    });

                    if (item) {
                        this.setState({
                            [item]: details_data
                        });
                    }

                    if (dataLoaded) {
                        dataLoaded(details_data, renderCount)
                    }

                    this.setState({
                        updatable: true
                    });

                    if (details_data && details_data.is_updatable !== undefined) {
                        await this.setState({
                            updatable: details_data.is_updatable
                        })
                    }

                    if (details || groups) {
                        this.formatData(details_data);
                    }

                } else {
                    dispatch(loaderToggle(false));
                    dispatch(updateError(true));

                }

                this.isRenderingView = false;
                clearInterval(showLoader);

            })();

        } else {
            dispatch(loaderToggle(false));

            clearInterval(showLoader);
            this.formatData(getApi);
        }
    };

    refreshRelation = (relation_id) => {
        (async () => {
            const details = {
                invoiceOutputOptions: [
                    {
                        value: 'email', label: 'Email'
                    }, {
                        value: 'paper', label: 'Paper'
                    }
                ], subscriptionList: [], invoiceList: [],
            };

            const [relationDetails] = await Promise.all([
                GetRelation(null, parseInt(relation_id))
            ]);

            const { dispatch } = this.props;
            dispatch({ type: 'RELATION', payload: relationDetails });

        })();
    };

    componentDidMount() {
        const { relation, relationId, dispatch, location, setRelationId, setIsUpdated, id, setData, history, refreshSubscriptionList } = this.props;

        this.setState({
            details_id: id,
        });

        if (relation.id !== relationId) {
            dispatch({ type: 'RELATION', payload: null });
            dispatch({ type: 'SUBSCRIPTION', payload: null });
            dispatch({ type: 'INVOICE', payload: null });

            if (refreshSubscriptionList) {
                refreshSubscriptionList();
            }

            dispatch(searchResultsUpdate(true));
        }

        this._isComponentMounted = true;

        if (!this.isRenderingView) {
            (async () => {
                await this.renderView();
            })();
        }
    }

    componentWillUnmount() {
        this._isComponentMounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { id, details, dataDetails, groups, newData, dropdownItems, size, tableFirst, relationId, setRelationId, relation, dispatch, isUpdated, invoice, name, setIsUpdated, invoiceLineIsUpdated } = this.props;
        const { data } = this.state;

        //console.log("Component details - componentDidUpdate (processUpdateApi) - size ->", size);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - data ->", data);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - prevState.data ->", prevState.data);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - details ->", details);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - dataDetails ->", dataDetails);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - dropdownItems ->", dropdownItems);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - invoice ->", invoice);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - prevProps ->", prevProps);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - prevState ->", prevState);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - isUpdated ->", isUpdated);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - invoiceLineIsUpdated ->", invoiceLineIsUpdated);
        //console.log("Component details - componentDidUpdate (processUpdateApi) - this.props ->", this.props);

        if (name && name === "Invoice" && invoice && prevProps.invoice !== invoice) {
            //console.log("Component details - componentDidUpdate (processUpdateApi) - invoice ->", invoice);

            const { newDropdownItems } = this.state;
            //console.log("Component details - componentDidUpdate (processUpdateApi) - newDropdownItems ->", newDropdownItems);
            //console.log("Component details - componentDidUpdate (processUpdateApi) - dropdownItems ->", dropdownItems);

            if (!invoice.price) {
                //console.log("Component details - componentDidUpdate (processUpdateApi) - remove finalize button from dropdownItems ->", dropdownItems);
                //console.log("Component details - componentDidUpdate (processUpdateApi) - remove finalize button from newDropdownItems ->", newDropdownItems);

                //this.setState({ newDropdownItems: [] });
                //let finalizeBtn = newDropdownItems.findIndex(i => i.label === "Finalize");
                //if (finalizeBtn) {
                //    console.log("Component details - componentDidUpdate (processUpdateApi) - finalizeBtn ->", finalizeBtn);
                //}
                //this.formatData(invoice, true);

            } //else {
                //console.log("Component details - componentDidUpdate (processUpdateApi) - add finalize button to newDropdownItems ->", newDropdownItems);
                //console.log("Component details - componentDidUpdate (processUpdateApi) - add finalize button to dropdownItems ->", dropdownItems);
                //this.formatData(invoice, false);
            //}

            this.formatData(invoice);

        }

        if (isUpdated && isUpdated === true) {
            this.renderView(); // causes duplicate request - TO DO -> check effect functions
        }

        if (id && prevProps.id !== id && !tableFirst && data && id !== data.id) {
            this.setState({
                inputData: null,
                onEdit: false
            });

            if (!this.isRenderingView) {
                this.renderView(); // causes duplicate request - TO DO -> check effect functions
            }
        }

        if (details && !_.isEqual(prevProps.details, details)) {
            //this.formatData(this.state.data);
            this.formatData(data);
        }

        if (groups && !_.isEqual(prevProps.groups, groups)) {
            if (newData && prevProps.newData !== newData) {
                this.formatData(newData)
            } else {
                this.formatData(data); // => this.state.data || ()
            }
        }

        if (dropdownItems && prevProps.dropdownItems !== dropdownItems && groups !== undefined) {
            const renderCount = this.state.renderCount ? this.state.renderCount + 1 : 1;

            this.setState({
                inputData: null,
                onEdit: false,
                renderCount
            });

            if (!this.isRenderingView) {
                this.renderView(renderCount);
            }
        }
    }

    renderDynamicData = (data) => {
        if (data)
            return data;
    };

    styleButtonIcons = (e) => {
        this.setState({
            buttonIcons: !this.state.buttonIcons
        });
    };

    formatLocationTitle = (location_address) => {
        const { locationAddress } = this.state;
        if (!locationAddress) return '';
        const { street1, house_number, house_letter, house_number_suffix, zipcode, city } = locationAddress;
        const addressParts = [street1, house_number, house_letter, house_number_suffix].filter(part => part).join(' ');
        let fullTitle = zipcode ? `${addressParts}, ${zipcode}` : addressParts;
        fullTitle += city && city.name ? ` ${city.name}` : ''; // Append city name after zipcode
        return fullTitle;
    }

    render() {
        const { id, columns, data, inputData, onEdit, updatable, newDropdownItems, buttonIcons, locationAddress, locationModalOpen, zoomModalOpenNested,
            zoomedHtml } = this.state;
        let {
            title,
            details,
            groups,
            quill,
            updateApi,
            notes,
            deleteConceptEnabled,
            dataPrices,
            planPreview, // enable preview btn
            planPreviewAction,
            planPreviewDisabled,
            dynamicFields, // enable dynamic fields btn
            dynamicFieldsAction,
            dynamicFieldsVisible,
            dynamicDefaultSchema,
            duplicatePlan,
            duplicatePlanAction,
            delegatePlan,
            delegatePlanAction,
            dropdownItems,
            tabTitle
        } = this.props;

        //console.log("render - zoomModalOpenNested ->", zoomModalOpenNested);
        //console.log("render - zoomedHtml ->", zoomedHtml);

        return (
            <React.Fragment>
                {tabTitle &&
                    <Helmet>
                        <title>{tabTitle}</title>
                    </Helmet>
                }
                {(details || groups) && inputData &&
                    <div className={`f2x-rel-mv-detail`}>
                        <Row className={`d-flex`}>
                            <Col className={`pt-0`}>
                                {title && <h5 className="mb-0 py-0">{title}</h5>}
                            </Col>
                            <Col className={`d-flex justify-content-end text-right align-items-right`}>
                                {updatable && updateApi &&
                                    <OverlayTrigger
                                        key={`invoice-tooltip-overlay-${onEdit ? 'cancel' : 'edit'}`}
                                        placement={`bottom`}
                                        transition={false}
                                        delay={200}
                                        overlay={
                                            <Tooltip id={`tooltip-bottom-${onEdit ? 'cancel' : 'edit'}`}>
                                                {onEdit ? 'Cancel' : 'Edit'}
                                            </Tooltip>}
                                    >
                                        <Button
                                            disabled={this.props.isLoading}
                                            data-label={onEdit ? 'Cancel' : 'Edit'}
                                            className="btn ml-auto mb-1 text-truncate"
                                            color="gray"
                                            onClick={(e) => this.toggleEdit(e, false)}
                                        >
                                            {buttonIcons === false ? onEdit ? 'Cancel' : 'Edit' : onEdit ? <X size={15} color="grey" /> : <Edit3 size={15} color="grey" />}
                                        </Button>
                                    </OverlayTrigger>
                                }

                                {updatable && updateApi && deleteConceptEnabled &&
                                    <OverlayTrigger
                                        key={`invoice-tooltip-overlay-delete`}
                                        placement={`bottom-start`}
                                        transition={false}
                                        delay={200}
                                        overlay={
                                            <Tooltip id={`tooltip-bottom-delete`}>
                                                {'Delete'}
                                            </Tooltip>}
                                    >
                                        <Button disabled={this.props.isLoading} data-label="Delete" className="btn ml-1 mb-1 text-truncate" color="gray" onClick={(e) => this.deleteConcept(e, false)}>
                                            {buttonIcons === false ? 'Delete' : <Trash2 size={15} color="grey" />}
                                        </Button>
                                    </OverlayTrigger>
                                }

                                {onEdit && dynamicFields && dynamicFields === true &&
                                    <Button disabled={this.props.isLoading} data-label="Fields" className="btn ml-1 mb-1 text-truncate" color="gray" onClick={(e) => dynamicFieldsAction()}>
                                        {buttonIcons === false ? 'Fields' : <Key size={15} color="grey" />}
                                    </Button>}

                                {duplicatePlan && duplicatePlan === true &&
                                    <OverlayTrigger
                                        key={`plan-tooltip-overlay-duplicate`}
                                        placement={`bottom-start`}
                                        transition={false}
                                        delay={200}
                                        overlay={
                                            <Tooltip id={`tooltip-bottom-duplicate-plan`}>
                                                {'Duplicate plan'}
                                            </Tooltip>}
                                    >
                                        <Button disabled={this.props.isLoading} data-label="Duplicate plan" className="btn ml-1 mb-1 text-truncate" color="gray" onClick={(e) => duplicatePlanAction(e)}>
                                            {buttonIcons === false ? 'Duplicate' : <Copy size={15} color="grey" />}
                                        </Button>
                                    </OverlayTrigger>
                                }

                                {delegatePlan && delegatePlan === true &&
                                    <OverlayTrigger
                                        key={`plan-tooltip-overlay-delegate`}
                                        placement={`bottom-start`}
                                        transition={false}
                                        delay={200}
                                        overlay={
                                            <Tooltip id={`tooltip-bottom-delegate-plan`}>
                                                {'Delegate plan'}
                                            </Tooltip>}
                                    >
                                        <Button disabled={this.props.isLoading} data-label="Delegate plan" className="btn ml-1 mb-1 text-truncate" color="gray" onClick={(e) => delegatePlanAction(e)}>
                                            {buttonIcons === false ? 'Delegate' : <FontAwesomeIcon icon={faCopy} color="grey" />}
                                        </Button>
                                    </OverlayTrigger>
                                }

                                {planPreview && planPreview === true &&
                                    <Button disabled={this.props.isLoading || planPreviewDisabled} data-label="Preview" title="Preview" className="btn ml-1 mb-1 text-truncate" color="gray" onClick={(e) => planPreviewAction()}>
                                        {buttonIcons === false ? 'Preview' : <Eye size={15} color="grey" />}
                                    </Button>}

                                {buttonIcons === true && newDropdownItems && newDropdownItems.length > 0 && newDropdownItems.map((item, i) => {
                                    return ((updatable && updateApi) || (!(updatable && updateApi)))
                                        ?
                                        <OverlayTrigger
                                            key={`invoice-tooltip-overlay-${item.label ? item.label.toLowerCase() : i}`}
                                            placement={`bottom`}
                                            transition={false}
                                            delay={200}
                                            overlay={
                                                <Tooltip
                                                    className="tooltip"
                                                    container="body"
                                                    id={`tooltip-bottom-${item.label ? item.label.toLowerCase() : i}`}
                                                >
                                                    {item.label}
                                                </Tooltip>}
                                        >
                                            <Button
                                                disabled={this.props.isLoading}
                                                data-label={item.label}
                                                //title={item.label}
                                                data-function={item.function}
                                                className="btn ml-1 mb-1 text-truncate"
                                                color="gray"
                                                onClick={(e) => this.props.triggerAction(item.function, data, e)}
                                                key={i}
                                            >
                                                {item.label === "View PDF"
                                                    ? <Download size={15} color="grey" />
                                                    : item.label === "Email PDF"
                                                        ? <Mail size={15} color="grey" />
                                                        : item.label === "Create credit invoice"
                                                            ? <FileMinus size={15} color="grey" />
                                                            : item.label === "Send reminder" ||
                                                                item.label === "Send 2nd reminder" ||
                                                                item.label === "Send warning" ||
                                                                item.label === "Send final notice" ||
                                                                item.label === "Sent to collection agency"
                                                                ? <Bell size={15} color="grey" />
                                                                : item.label === "View phone usage"
                                                                    ? <PhoneCall size={15} color="grey" />
                                                                    : item.label === "Set to paid"
                                                                        ? <DollarSign size={15} color="grey" />
                                                                        : item.label === "Set to open"
                                                                            ? <RotateCcw size={15} color="grey" />
                                                                            : item.label === "Finalize"
                                                                                ? <Archive size={15} color="grey" />
                                                                                : item.label === "Create concept invoice"
                                                                                    ? <PlusCircle size={15} color="grey" />
                                                                                    : item.label === "View logs"
                                                                                        ? <Cpu size={15} color="grey" />
                                                                                        : item.label === "View details"
                                                                                            ? <FileText size={15} color="grey" />
                                                                                            : item.label === "Delete"
                                                                                                ? <Trash2 size={15} color="grey" />
                                                                                                : item.label
                                                }
                                            </Button>
                                        </OverlayTrigger>
                                        : null
                                })}

                                {buttonIcons === false && newDropdownItems && newDropdownItems.length > 0 && newDropdownItems.map((item, i) => {
                                    return ((updatable && updateApi && i < 2) || (!(updatable && updateApi) && i < 3))
                                        ? <Button disabled={this.props.isLoading} data-label={item.label} className="btn ml-1 mb-1 text-truncate" color="gray" onClick={(e) => this.props.triggerAction(item.function, data, e)} key={i}>
                                            {item.label}
                                        </Button>
                                        : null
                                })}
                                {buttonIcons === false && ((updatable && updateApi && newDropdownItems && newDropdownItems.length > 2) || (!(updatable && updateApi) && newDropdownItems && newDropdownItems.length > 3)) &&
                                    <UncontrolledDropdown className={`f2x-mv-menu-detail`}>
                                        <DropdownToggle nav className="px-3 py-0 ml-1">
                                            <MoreHorizontal size={18} />
                                        </DropdownToggle>
                                        <DropdownMenu right={true}>
                                            {newDropdownItems.map((item, i) => {
                                                return ((updatable && updateApi && i > 1) || (!(updatable && updateApi) && i > 2)) &&
                                                    <DropdownItem className="py-2" data-label={item.label} onClick={(e) => this.props.triggerAction(item.function, data, e)} key={i}>
                                                        {item.label}
                                                    </DropdownItem>
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                            </Col>
                        </Row>

                        <Form className="f2x-rel-det-form 222" onSubmit={(e) => this.handleSubmit(e)}>
                            <Row className={`pt-0 mb-0`}>
                                {details
                                    ? columns.map((item1, index1) => {
                                        return (
                                            <Col xs="12" sm="12" md="6" key={index1} className="col-12">
                                                <ListGroup className={`block-list`} flush>
                                                    {
                                                        details.map((item2, index2) => {
                                                            return (
                                                                <React.Fragment key={index2}>
                                                                    {index1 === 0 && index2 < Math.ceil(details.length / 2)
                                                                        ? this.renderList(item2)
                                                                        : index1 === 1 && index2 >= Math.ceil(details.length / 2)
                                                                            ? this.renderList(item2)
                                                                            : null
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </ListGroup>
                                            </Col>
                                        )
                                    }) :
                                    groups && Object.values(groups).map((group, groupIndex) => {
                                        return (
                                            <Col xs="12" sm="12" md="6" className={group.title === 'Shipping' ? "mb-4" : group.title === 'Billing' ? "mb-4" : "mb-4"} key={groupIndex}>
                                                <ListGroup className={`block-list`} flush>
                                                    {<React.Fragment>
                                                        <h5 className="pb-0 list-group-sub-heading">{group.title}</h5>
                                                        {group.details.map((detail, detailIndex) => {
                                                            return (
                                                                <React.Fragment key={detailIndex}>
                                                                    {this.renderList(detail)}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                        }
                                                    </React.Fragment>
                                                    }
                                                </ListGroup>
                                            </Col>
                                        )
                                    })}

                                {/* Dynamic fields */}
                                {dynamicFieldsVisible && dynamicFieldsVisible === true &&
                                    <DynamicTextField
                                        defaultSchema={dynamicDefaultSchema}
                                        dynamicData={this.renderDynamicData}
                                    />
                                }

                                {onEdit &&
                                    <Col xs="12" className="d-flex justify-content-between pb-4 save-cancel-buttons">
                                        <Col xs="auto" className="px-0">
                                            <Button color="danger" onClick={(e) => this.toggleEdit(e, false)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                        </Col>
                                        <Col xs="auto" className="px-0">
                                            <Button color="primary">
                                                <FontAwesomeIcon icon={faSave} />
                                            </Button>
                                        </Col>
                                    </Col>
                                }
                            </Row>
                        </Form>
                    </div>
                }
                <>
                    {notes &&
                        <NotesTable
                            id={id}
                            getApi={notes.getApi}
                            addApi={notes.addApi}
                            updateNoteApi={notes.updateNoteApi}
                            hasQuill={!!quill}
                        />
                    }
                    {quill &&
                        <Col xs="12">
                            <ReactQuill
                                placeholder={`${quill}...`}
                                value={inputData[quill]}
                                onChange={this.handleQuillChange}
                                readOnly={!onEdit}
                            />
                        </Col>
                    }
                </>

                {zoomModalOpenNested && 
                <ZoomedHTMLScreen
                    isOpen={zoomModalOpenNested}
                    toggle={this.closeZoomModal}
                    zoomedHtml={zoomedHtml ? zoomedHtml : ""}
                    onChange={this.handleZoomedHtmlChange}
                    onSave={this.handleSave}
                />}

                {/*console.log("locationAddress ->", locationAddress)*/}
                {locationAddress && locationAddress.latitude && locationAddress.longitude &&
                    <ModalWrapper
                        title={this.formatLocationTitle(locationAddress)}
                        //title={locationAddress} 
                        overflow={true}
                        className="f2x-modal-logs f2x-modal-subscriptions modal-xl fullscreen"
                        show={locationModalOpen}
                        style={{ width: '100vw', height: '100vh' }}
                        hide={this.locationModalClose}>
                        <InitMap
                            lat={locationAddress.latitude}
                            lng={locationAddress.longitude}
                            selectedData={null}
                            locationAddress={locationAddress}
                            enableRoute={false}
                            mapHeight={800}
                            mapZoom={11}
                        />
                    </ModalWrapper>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = store => ({
    loader: store.loader,
    user: store.user,
    relation: store.relation,
    subscription: store.subscription,
    invoice: store.invoice
});

export default withRouter(withTranslation()(connect(mapStateToProps)(Details)));
