import React from "react";
import { GetMyAccountingPeriods } from '../../../../controllers/accounting_periods';
import AccountingPeriodForm from "./Form";
import ParentTable from '../../../../components/ParentTable';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

class accounting_periods extends React.Component {
  formatDate = (cell) => {
    if (cell)
      return <span className="date-format">{this.props.t("date_format", { date: new Date(cell) })}</span>
  };

  render() {
    const {tenant_id, fiscal_year_id} = this.props;
     return(
      <React.Fragment>
        <ParentTable
          table="accounting_periods"
          id={ tenant_id }
          id2={ fiscal_year_id }
          noTopBorder={true}
          data={ GetMyAccountingPeriods }
          columns={[
            {
              dataField: "description",
              text: "Description",
              sort: false
            },
            {
              dataField: "date_from",
              text: "Date from",
              formatter: this.formatDate,
              sort: false
            },
            {
              dataField: "date_to",
              text: "Date to",
              formatter: this.formatDate,
              sort: false
            },
            {
              dataField: "is_closed",
              text: "Closed",
              sort: false,
              align: "center",
              headerStyle: () => {
                return { 'textAlign': 'center' }
              },
              formatter: (cell, row) => cell === 1 ? "yes" : "no"
            },
          ]}
          rowAction="edit"
          rowActionForm={ AccountingPeriodForm }
          headerDropdownOpts={[{
            label: 'Add',
            action: AccountingPeriodForm
          }]}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation('translations')(connect()(accounting_periods)));
