import React from "react";
import { withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import {
  ChevronRight,
  Hash
} from 'react-feather';

class Breadcrumbs extends React.Component {
  formatLink(index) {
    const { path } = this.state;
    let createPath = '';

    if (path && path[index] && isNaN(parseInt(path[index]))) {
      for (let i = 0; i < index; i++) {
        if (i % 2 === 0) {
          createPath = `${createPath}/${path[i]}/${path[index - (index - 1) +
          i]}`
        }
      }
      return `${ createPath }/details`;
    }
  }

  componentDidMount() {
    //let url = window.location.hash;
    let url = window.location.pathname;

    //console.log("BreadCrumbs - url ->", url);

    //let cleanPath = url.split('?');
    //if (cleanPath && cleanPath.length > 0) {
    //  url = cleanPath[0];
    //}
    let path = url.split('/');
    //let path0 = url.split('/');

    //console.log("BreadCrumbs - path ->", path);

    path = path.splice(1, path.length);
    //path0 = path0.splice(0, path0.length);

    //console.log("BreadCrumbs - path ->", path);

    this.setState({ path });
    
    if (path[0] === 'tenants') {
      const tenant = Object.values(this.props.tenants).find(tenant => parseInt(tenant.id) === parseInt(path[1]));
      
      if (tenant) {
        this.setState({ tenant_name: tenant.name });
      }
    }
  }

  goToUrl = (e) => {
    this.props.history.push(`/dashboard`);
  };

  render() {
    const { state } = this;
    return(
      <div className="c-subheader px-4 nav-breadcrumb">
        <Row className="m-0 w-100 align-items-center capitalized">
          <Hash size={ 12 } className="mr-1 primary-color" />
          { state && state.path &&
            state.path.map((item, index) => {
              return index === 0 ?
                <Link to={ `/${ item }` } key={ index }>{ item }</Link> :
                index % 2 !== 0 ?
                  <React.Fragment key={ index }>
                    <ChevronRight size={ 12 } className="mx-2" />
                    { index < Math.floor(state.path.length / 2)
                      ? <Link to={ () => this.formatLink(index) } key={ index }>{ state.tenant_name && index === 1 ? state.tenant_name : item } </Link>
                      : state.tenant_name && index === 1 ? state.tenant_name : item
                    }
                  </React.Fragment> : null
            })
          }

          <div className="ml-auto link-to-dashboard mr-3">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href="#" onClick={(e) => this.goToUrl.bind(this)} className="d-inline-block nav-link">
                  <ChevronRight size={ 12 } className="mx-2" /> Dashboard
                </a>
              </li>
            </ul>
          </div>
        </Row>
      </div>
    )
  }
}

export default withRouter(connect(store => ({
  tenants: store.tenants
}))(Breadcrumbs));
