import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    UpdateAPI = Api.updateAPI,
    RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : GetAPI(endpoint, params);
  })();
};

const GetNetworkOperators = (params) => {
  return asyncFunc(`network_operators`, params);
};

const GetOperators = (params) => {
  return asyncFunc(`operators`, params);
};

export {
  GetNetworkOperators,
  GetOperators,
};
