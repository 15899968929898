import React from 'react';
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  useFormikContext,
  useField,
  useFormik
} from 'formik';
import { Col, Container, Row, FormGroup, Label, CustomInput } from "reactstrap";
import {
  Card,
  Dropdown,
  FormControl,
  DropdownButton,
  SplitButton,
  InputGroup,
  Button,
} from "react-bootstrap";
import DatePicker from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import {addDays} from "date-fns";

export function Form(props) {
  return (
    <Formik
      {...props}
    >
      <FormikForm className="needs-validation" novalidate="">
        {props.children}
      </FormikForm>
    </Formik>
  );
}

export function TextField(props) {
  const { name, label, placeholder, ...rest } = props;

  return (
    <FormGroup>
      {label &&
      <Label className="dynamic-form-element-label">{label}</Label>}
      <Field
        className="form-control"
        type="text"
        name={name}
        id={name}
        placeholder={placeholder || ""}
        {...rest}
      />
      <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
    </FormGroup>
  );
}

export function SelectField (props) {
  const { name, label, options } = props;

  return (
    <FormGroup className="row">
      <Col>
      {label &&
      <Label className="dynamic-form-element-label">{label}</Label>}
      <Field
        as="select"
        id={name}
        className="form-control"
        name={name}
      >
        <option value="" >Select...</option>
        {options.map((option, index) => <option value={option.value} label={option.label || option.value} />)}
      </Field>
      <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
      </Col>
    </FormGroup>
  );
}

export function ReactSelectField (props) {
  const { name, label, options } = props;

  return (
      <FormGroup className="row">
        <Col>
          {label &&
          <Label className="dynamic-form-element-label">{label}</Label>}
          <Select
              className="react-select-container mt-0"
              classNamePrefix="react-select"
              name={name}
              id={`id-${name}`}
              placeholder="Select..."
              withoutEmptyOption={true}
              options={options}
              hideSelectedOptions={true}
              //value={selectedValue}
              //onChange={(data, triggeredAction) => changeData(data, triggeredAction)}
              isMulti={false}
              isClearable={true}
          />
          <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </Col>
      </FormGroup>
  );
}

export function DatePickerField(props) {
  console.log("FormElements - DatePickerField - props ->", props);

  const defaultData = props && props.format ? props.format : "";
  const name = props && props.name ? props.name : "date";
  const minDate = props && props.minDate ? parseInt(props.minDate) : null;
  const format = props && props.format ? props.format : "dd-MM-yyyy";

  const handleDatePickerChange = () => {

  };

  return (
      <FormGroup className="row mt-2">
        <Col>
          <DatePicker
              className="form-control"
              wrapperClassName="w-100"
              name={name}
              dateFormat={format}
              autoComplete="off"
              placeholderText="Select..."
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown={true}
              selected={ new Date() }
              onChange={(date) => {
                new Date(date)
              }}
              onKeyDown={(e) => e.preventDefault()}
              minDate={ minDate ? addDays(new Date(), minDate) : "" }
          />
        </Col>
      </FormGroup>
  );
}

export function RadioField(props) {
  console.log("RadioField - props ->", props);
  const { name, label, options } = props;

  const functionChange = (e, func) => {
    console.log("functionChange - e ->", e);
    console.log("functionChange - func ->", func);

    if (e && e.target && e.target.value) {
      console.log("functionChange - e ->", e.target.value);
      return e.target.value;
    }
  };

  return (
      <FormGroup className="row">
        <Col>
          {label &&
          <div id={`${name}-radio-group`}>
            <Label className="dynamic-form-element-label">{label}</Label>
          </div>}
          <div role="group" aria-labelledby={`${name}-radio-group`}>
            {options.map((option, index) =>
                <div key={`${name}-${index}`}>
                  <CustomInput
                    className="mb-1"
                    id={`${name}-${index}`}
                    type="radio"
                    name={name}
                    label={option.value}
                    value={option.value}
                    onChange={(e) => functionChange(e, option.function)}
                  />
                  {option.func && option.func.element && option.func.element === "datepicker" &&
                    <DatePickerField props={option.func} />
                  }
                  {option.info &&
                  <div className="dynamic-form-element-info" dangerouslySetInnerHTML={{ __html: option.info }} key={`${name}-${index}-info`} />
                  }
                </div>
               )
            }
          </div>
          <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </Col>
      </FormGroup>
  );
}

export function MultiCheckboxField(props) {
  const { name, label, options } = props;

  return (
      <>
        {label &&
        <div id={`${name}-checkbox-group`}>
          <Label className="dynamic-form-element-label">{label}</Label>
        </div>}
        <div role="group" aria-labelledby={`${name}-checkbox-group`}>
          {options.map((option, index) =>
              <label>
                <Field
                    type="checkbox"
                    name={name}
                    value={option.value}
                />
                {option.value}
              </label>)
          }
        </div>
        <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
      </>
  );
}

export function SingleCheckboxField(props) {
  const { name, label, option } = props;

  return (
      <>
        <label>
          <Field
              type="checkbox"
              name={name}
              value={option.value}
          />
          {option.value}
        </label>)
        <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
      </>
  );
}

export function SubmitButton(props) {
  const { title, ...rest } = props;
  const { isSubmitting } = useFormikContext();

  return (
    <button type="submit" {...rest} disabled={isSubmitting}>{title}</button>
  );
}
