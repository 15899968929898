import React from "react";
import { GetMyJournals } from '../../../controllers/journals';
import ParentTable from '../../../components/ParentTable';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

class Journals extends React.Component {
  formatDate = (cell) => {
    if (cell)
      return <span className="date-format">{this.props.t("date_format", { date: new Date(cell) })}</span>
  };

  formatCurrency = (cell) => {
    const { t } = this.props;
    if (cell) {
      let floatCell = cell ? cell : null;
      let floatStyle = floatCell && parseFloat(floatCell) < 0
        ? 'float-negative'
        : floatCell && parseFloat(floatCell) > 0
          ? 'float-positive'
          : 'float-null';
      return <span className={floatStyle ? floatStyle : 'float-zero'}>{t(
        "currency_format", { number: Number(parseFloat(cell)) })}</span>
    } else {
      return <span className={'float-zero'}>{t("currency_format",
        { number: Number(0) })}</span>
    }
  };

  render() {
    const { id, tabTitle } = this.props;
    console.log("DataTable - rowAction = link - this.props ->", this.props);

    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="Journals"
          keyField="invoice_id"
          id={id}
          noTopBorder={true}
          data={GetMyJournals}
          columns={[
            {
              dataField: "journal_no",
              text: "Journal no.",
              sort: true,
              attrs: {
                'data-label': 'Journal no.'
              }
            },
            {
              //dataField: "sales_invoice.invoice_no", // is missing!!
              dataField: "invoice_id",
              text: "Invoice no",
              sort: false,
              attrs: {
                'data-label': 'Invoice no'
              }
            },
            {
              dataField: "date",
              text: "Date",
              sort: true,
              formatter: this.formatDate,
              attrs: {
                'data-label': 'Date'
              }
            },
            {
              dataField: "outstanding_balance",
              text: "Balance",
              sort: true,
              formatter: this.formatCurrency,
              attrs: {
                'data-label': 'Balance'
              }
            },
          ]}
          //rowAction="link"
          wrapperClasses="table-journals f2x-custom-table"
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation('translations')(connect()(Journals)));
