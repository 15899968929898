import React from "react";
import { connect } from "react-redux";

import { CardHeader, CardBody, Button, Form, FormGroup, Label, Input, Table } from "reactstrap";

import { GetAddressAvailability } from '../../controllers/l2fiber';

import { loaderToggle } from '../../redux/actions/loaderActions';

class AddressAvailability extends React.Component {
  constructor() {
    super()

    this.state = {}
  }

  handleInputChange({ target: { name, value }}) {
    let address = Object.assign({}, this.state.address)

    address[name] = value

    this.setState({ address })
  }

  handleSubmit(e) {
    e.preventDefault();
    
    (async () => {
      const { dispatch } = this.props    
      dispatch(loaderToggle(true))

      const { ok, data } = await GetAddressAvailability(this.state.address)

      if (ok) {
        this.setState({ result: {
          status: data.status,
          alternatives: data.alternatives !== null ? data.alternatives.join(', ') : ''
        }})
      }
        
      dispatch(loaderToggle(false))
    })()
  }

  render() {
    const { loader } = this.props,
          { address, result } = this.state,
          { postalCode, streetNr, streetNrAddition, room } = address ? address : {},
          { status, alternatives } = result ? result : {}
    return (
      <React.Fragment>
          <CardHeader>
              <h4 className="mb-0">Address availability</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={(e) => this.handleSubmit(e)}>

              <FormGroup>
                <Label>Postal code</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="postalCode"
                  value={postalCode ? postalCode : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Street no.</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="streetNr"
                  value={streetNr ? streetNr : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Street no. addition</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="streetNrAddition"
                  value={streetNrAddition ? streetNrAddition : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Room</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="room"
                  value={room ? room : ''}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Button color="primary">Save</Button>
              </FormGroup>
            </Form>
          </CardBody>
          { !loader && result && <React.Fragment>
            <CardHeader>
                <h4 className="mb-0">Availability</h4>
            </CardHeader>
            <CardBody>
              <Table className="mb-0">
                  <tbody>
                    <tr>
                      <td>Status: </td>
                      <td>{status}</td>
                    </tr>
                    <tr>
                      <td>Alternatives: </td>
                      <td>{alternatives}</td>
                    </tr>
                  </tbody>
                </Table>          
            </CardBody>
          </React.Fragment> }
      </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader
}))(AddressAvailability)
