import * as types from "../constants";

export function user(user) {
  return {
    type: types.USER,
    payload: user
  };
}

export function updateUser(user) {
  return {
    type: types.UPDATE_USER,
    payload: user
  };
}

export function updateTenant(tenant) {
  return {
    type: types.UPDATE_TENANT,
    payload: tenant
  };
}