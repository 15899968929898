import React from "react";
import axios from "axios";
import AuthService from "./authService";
import Toast from "../components/Toast";
import { Document } from 'react-pdf'

const Auth = new AuthService(),
    CancelToken = axios.CancelToken;

let cancel;
let source = axios.CancelToken.source();

// New Endpoints' Base URL here
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.timeout = 3 * 60000; // global timeout of 3 * 10000 = 30s

// abort duplicate request
const service = axios;
// abort duplicate request
let pending = {};
const removePending = (config, f) => {
    if (config && config.url) {
        // make sure the url is same for both request and response
        if (config.url in pending) {
            if (f) {
                f() // abort the request
            } else {
                delete pending[config.url]
            }
        } else {
            if (f) {
                pending[config.url] = f // store the cancel function
            }
        }
    } else {
        pending = {};
    }
};

// axios interceptors
/*service.interceptors.request.use(config => {
    if (config) {
        // you can apply cancel token to all or specific requests
        // e.g. except config.method == 'options'
        config.cancelToken = new CancelToken((c) => {
            removePending(config, c)
        });
        return config;
    }
}, error => {
    return Promise.reject(error).then(r => console.log(r));
});
service.interceptors.response.use(response => {
        if (response) {
            removePending(response);
            // return response
            return response;
        }
    },
    error => {
        if (error) {
            removePending(error);
            if (!axios.isCancel(error)) {
                return Promise.reject(error);
            } else {
                // return empty object for aborted request
                return Promise.resolve({});
            }
        }
    }
);
service.clear = () => {
    Object.keys(pending).map(e => {
        if (pending[e]) {
            pending[e]();
            delete pending[e]
        }
    })
};*/

// axios interceptors
axios.interceptors.request.use( x => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    x.meta = x.meta || {};
    x.meta.requestStartedAt = new Date().getTime();
    return x;
});
axios.interceptors.response.use(x => {
    if (x && x.config && x.config.url) {
        //console.log(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`);
        return x;
    }
});

class ApiService extends React.Component {
    constructor(props) {
        //console.log("ApiService - constructor - props ->", props);

        super(props);
    }

    formatter = (ok, { data, headers, request }) => {
        //console.log("ApiService - formatter - ok ->", ok);
        //console.log("ApiService - formatter - data ->", data);
        //console.log("ApiService - formatter - headers ->", headers);
        //console.log("ApiService - formatter - request ->", request);

        let responseUrl = request && request.responseURL ? request.responseURL : "";

        if ((ok || !ok) && data) {
            return {ok, data, responseUrl, headers, request};
        } else {
            return false;
        }
    };

    getAPI = (url, data) => {
        this.checkToken();

        return new Promise((resolve) => {
            data && cancel && cancel();

            axios.get(url, {
                params: data,
                cancelToken: source.token
            })
            .then(res => {
                //console.log("apiService - getAPI - url ->", url);

                return resolve(this.formatter(true, res));
            })
            .catch(err => {
                if (err !== undefined) {
                    //console.log("apiService - getAPI - catch - err ->", err);

                    if (!axios.isCancel(err)) {
                        this.handleError(err, resolve)
                    }
                    return resolve(this.formatter(false, err));
                }
            })
        })
    };

    getAPIwithCustomHeader = (url, data) => {
        this.checkToken();

        //console.log("apiService - getAPIwithCustomHeader - url ->", url);
        //console.log("apiService - getAPIwithCustomHeader - data ->", data);

        return new Promise((resolve) => {
            data && cancel && cancel();

            axios.get(url,{
                headers: {
                    'tenant': `${data}`
                }
            })
            .then(res => {
                return resolve(this.formatter(true, res));
            })
            .catch(err => {
                if (err !== undefined) {
                    if (!axios.isCancel(err)) {
                        this.handleCustomError(err, resolve)
                    }
                    return resolve(this.formatter(false, err));
                }
            })
        })
    };

    getPublicAPI = (url, data) => {
        this.checkToken();

        return new Promise((resolve) => {
            data && cancel && cancel();

            axios.get(url, {
                params: data,
                cancelToken: source.token
            })
            .then(res => {
                return resolve(this.formatter(true, res));
            })
            .catch(err => {
                if (err !== undefined) {
                    //console.log("apiService - getAPI - catch - err ->", err);

                    if (!axios.isCancel(err)) {
                        this.handlePublicError(err, resolve);
                    }
                    return resolve(this.formatter(false, err));
                } else {

                }
            })
        })
    };

    postAPI = (url, data) => {
        this.checkToken();

        return new Promise((resolve) => {
            axios.post(url, data, {
            })
            .then(res => {
                resolve(this.formatter(true, res))
            })
            .catch(err => {
                this.handleError(err);
                resolve(this.formatter(false, err))
            })
        })
    };

    uploadPostAPI = (url, data) => {
        this.checkToken();

        return new Promise((resolve) => {
            console.log(resolve);
            axios.post(url, data,{
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                resolve(this.formatter(true, res))
            })
            .catch(err => {
                console.log(err);
                this.handleError(err);
                resolve(this.formatter(false, err))
            })
        })
    };

    uploadPostAPIwithCustomHeader = (url, data) => {
        this.checkToken();

        return new Promise((resolve) => {
            console.log(resolve);
            axios.post(url, data,{
                headers: {
                    'Content-Type': "multipart/form-data"
                }
            })
            .then(res => {
                console.log(res);
                resolve(this.formatter(true, res))
            })
            .catch(err => {
                console.log(err);
                this.handleError(err);
                resolve(this.formatter(false, err))
            })
        })
    };

    updateAPI = (url, data) => {
        this.checkToken();

        return new Promise((resolve) => {
            axios.put(url, data)
            .then(res => {
                resolve(this.formatter(true, res))
            })
            .catch(err => {
                this.handleError(err);
                resolve(this.formatter(false, err))
            })
        })
    };

    removeAPI = (url) => {
        this.checkToken();

        return new Promise((resolve, reject) => {
            axios.delete(url)
            .then(res => {
                resolve(this.formatter(true, res))
            })
            .catch(err => {
                this.handleError(err);
                resolve(this.formatter(false, err))
            })
        })
    };

    downloadAPI = (url, responseType, fileName) => {
        this.checkToken();

        if (!responseType) {
            responseType = 'blob';
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {
                responseType: responseType,
                timeout: 30000,
                headers: {
                    'Content-Type': 'application/pdf',
                },
            })
            .then((res) => {
                const link = document.createElement('a');
                link.href = res.request.responseURL;
                link.setAttribute('download', "");
                document.body.appendChild(link);
                link.click();

            })
            .catch(err => {
                //console.log('downloadAPI - err ->', err);
                this.handleError(err);
                resolve(this.formatter(false, err))

            })
        })
    };

    downloadPreviewAPI = (url, responseType, fileName) => {
        this.checkToken();

        if (!responseType) {
            responseType = 'blob';
        }

        return new Promise((resolve, reject) => {
            axios.get(url, {
                responseType: responseType,
                timeout: 30000,
                headers: {
                    'Content-Type': 'application/pdf',
                },
            })
            .then((res) => {
                //const fileURL = URL.createObjectURL(res.data);
                //let newWin = window.open(fileURL, "pdf show", "width=800,height=600");
                //newWin.focus();

                //let blob = new Blob([data], {type: 'application/pdf'})
                //let url = window.URL.createObjectURL(blob)

                //console.log("ListEmailDetails - viewAttachment - downloadPreviewAPI - res ->", res);
                //console.log("ListEmailDetails - viewAttachment - downloadPreviewAPI - res.data ->", res.data);

                //return (
                //    <Document file={res.data}>
                //        <Page />
                //    </Document>
                //);
                return res;
                //const link = document.createElement('a');
                //link.href = res.request.responseURL;
                //link.setAttribute('target', "self");
                //document.body.appendChild(link);
                //link.click();

            })
            .catch(err => {
                //console.log('downloadAPI - err ->', err);
                this.handleError(err);
                resolve(this.formatter(false, err))

            })
        })
    };

    getAPIwithType = (url, responseType) => {
        this.checkToken();

        return new Promise((resolve) => {
            axios({
                url,
                method: 'GET',
                responseType
            })
            .then(res => {
                console.log("getAPIwithType - res ->", res);
                resolve(this.formatter(true, res, res.headers, res.request))
            })
            .catch(err => {
                this.handleError(err);
                resolve(this.formatter(false, err))
            })
        })
    };

    getAPIWithError = (url, data) => {
        return new Promise((resolve) => {
            axios.get(url, {
                params: data,
                cancelToken: source.token
            }).then(res => {
                resolve(this.formatter(true, res))
            })
            .catch(err => {
                let data = err.response.data,
                    errorMessage = data.message.match(/{(.*)message(.*):"(.*)"}/);

                if (errorMessage !== null && errorMessage.length) {
                    let message = errorMessage[errorMessage.length - 1];
                    alert(message)
                } else {
                    alert(data.message)
                }
                resolve(this.formatter(false, err))
            })
        })
    };

    checkToken() {
        if (Auth.getToken() === null) {
            setTimeout(this.checkToken, 1)
        } else {
            const commons = {
                'Authorization': `Bearer ${Auth.getToken()}`,
                'tz' : new Intl.DateTimeFormat().resolvedOptions().timeZone
            };

            let tenant = sessionStorage.getItem('tenant');
            if (tenant !== undefined && tenant !== 'undefined') {
                const decodedTenant = JSON.parse(tenant);

                if (decodedTenant) {
                    commons['tenant'] = decodedTenant.id
                }
            }
            let socket_id = sessionStorage.getItem('socket_id');
            if (undefined !== socket_id) commons['socketid'] = socket_id;

            axios.defaults.headers.common = commons;
        }
    }

    handleError(err, resolve) {
        if (err) {
            console.log("handleError - err ->", err);

            if (err === "TypeError: _ref is undefined") {
                return false;
            }

            const {response} = err;
            //console.log("handleError - response ->", response);

            if (response && (response.status === 403)) {
                Toast.fire({title: 'Forbidden!', icon: 'info'}).then(r => console.log('Forbidden!'));
                return false;

            } else if (response && (response.status === 200 || response.status === 401)) {
                Auth.updateError(true, response);

                if (!Auth.loggedIn() || response.status === 401) {
                    Toast.fire({title: 'Session expired!', icon: 'info'}).
                        then(r => console.log('Session expired!'));
                    Auth.logout();
                }

            } else if (response && response.status === 404) {
                if (response.data && response.data.message) {
                    if (response.data.message === "No location was found") {
                        return response.data.message;
                    } else {
                        Toast.fire({title: response.data.message, icon: 'info'}).
                            then(r => console.log(response.data.message));
                    }
                } else {
                    Toast.fire({title: 'Not found!', icon: 'info'}).
                        then(r => console.log('Not found!'));
                }

            } else if (response && response.status === 503) {
                localStorage.setItem('maintenance', true);
                window.location.href = '/auth/maintenance';

            } else if (response) {
                if (err === "TypeError: _ref is undefined") {
                    return false;
                }
                //console.log("ApiService err ->", err);

                let msg = err;
                if (response !== undefined) {
                    const data = response.data,
                        error = undefined !== data ? data.error : undefined;

                    msg = undefined !== error ? error.message : undefined !==
                    data ? data.message : response.message
                }

                if (msg === "No location was found") {
                    //console.log("ApiService err ->", "No location was found");
                    return false;

                } else {
                    console.log("ApiService msg ->", msg);

                    // show general message
                    Toast.fire({
                        title: msg
                            ? msg
                            : "There's seem to be in error on your request",
                        icon: 'error'
                    }).
                        then(r => console.log(
                            "There's seem to be in error on your request"));
                }
            }
        }
    }

    handlePublicError(err, resolve) {
        if (err) {
            //console.log("handleError - err ->", err);
            const {response} = err;
            //console.log("handleError - response ->", response);

            if (response &&
                (response.status === 200 || response.status === 401)) {
                Auth.updateError(true, response);

                if (!Auth.loggedIn() || response.status === 401) {
                    Toast.fire({title: 'Unauthorized! API key is not valid or expired.', icon: 'info'}).then(r => console.log('Unauthorized! API key is not valid or expired.'));
                    //Auth.logout();
                }

            } else if (response && response.status === 404) {
                if (response.data && response.data.message) {
                    Toast.fire({title: response.data.message, icon: 'info'}).
                        then(r => console.log(response.data.message));

                } else {
                    Toast.fire({title: 'Not found!', icon: 'info'}).
                        then(r => console.log('Not found!'));
                }

            } else if (response && response.status === 403) {
                Toast.fire({title: 'Forbidden!', icon: 'info'}).then(r => console.log('Forbidden!'));

            } else if (response && response.status === 503) {
                Toast.fire({title: 'Service Unavailable!', icon: 'info'}).then(r => console.log('Service Unavailable!'));

            } else {
                let msg = err;
                if (response !== undefined) {
                    const data = response.data,
                        error = undefined !== data ? data.error : undefined;

                    msg = undefined !== error ? error.message : undefined !==
                    data ? data.message : response.message
                }

                // show general message
                Toast.fire({
                    title: msg
                        ? msg
                        : "There's seem to be in error on your request",
                    icon: 'error'
                }).
                    then(r => console.log(
                        "There's seem to be in error on your request"));
            }
        }
    }

    handleCustomError(err, resolve) {
        if (err) {
            console.log("apiService - handleCustomError - err ->", err);
            const {response} = err;
            if (response && response.status === 403) {
                console.log("apiService - handleCustomError - response.status ->", response.status);
            }
        }
    }

    //componentWillUnmount() {
     //   if (source) {
     //       source.cancel("Component got unmounted so cancel request");
     //   }
    //}
}

export default ApiService;
