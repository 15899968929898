import React from "react";
import { PhotoshopPicker } from 'react-color';

import { Modal } from "reactstrap";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      color: this.props.defaultColor
    }
  }

  handleChange = (color, e) => {
    this.setState({ color: color.hex })
  };

  pushColor = () => {
    this.props.changeComplete(this.props.name.replace(/\s/g, '').toLowerCase(), this.state.color)
  };

  render() {
    const { name, show, hide } = this.props,
          { color } = this.state;
    
    return (
      <Modal className="modal-color-picker" isOpen={ show } toggle={ hide } centered>
        <PhotoshopPicker
          header={ `${ name } Color` }
          color={ color }
          onChange={ this.handleChange }
          onAccept={ this.pushColor }
          onCancel={ hide }
        />
      </Modal>
    )
  }
}

export default ColorPicker;
