import React, { useEffect, useState } from "react";
import XMLPrettify from "xml-formatter";
import filterFactory, {
  selectFilter,
  textFilter,
  dateFilter,
  customFilter,
} from "react-bootstrap-table2-filter";
import { Popover, Tooltip, OverlayTrigger } from "react-bootstrap";
import {Link, useHistory, useLocation, withRouter} from 'react-router-dom';
import { withTranslation, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import JsonPrettify from "json-stringify-pretty-compact";
import moment from "moment";
import { getTableObj } from "../../../services/tableService";
import CustomDataTable from "../../../components/CustomDataTable";
import CustomFilterDate from "../../../components/filters/CustomFilterDate";
import CustomFilterSelect from "../../../components/filters/CustomFilterSelect";
import CustomFilterInput from "../../../components/filters/CustomFilterInput";
import CONSTANTS from "../../../services/constants";
import ApiService from "../../../services/apiService";
import {
  Badge, Card, CardHeader, CardBody, Container, Row, Col, Input, InputGroup, InputGroupText,
} from 'reactstrap';
import ReactJson from "react-json-view";
import history from "history/browser";
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { stringFilter } from "../../../services/misc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { parseDateToSaveFormat } from "../../../redux/actions/formatActions";

const JsonOptions = { indent: 4 },
    XmlOptions = {
      indentation: "   ",
      stripComments: false,
      collapseContent: true,
      lineSeparator: "\n",
    };

const Api = new ApiService(),
    GetAPI = Api.getAPI;

const ActivityLogsFilter = ({ dispatch, ...props }) => {
  console.log("ActivityLogsFilter - props ->", props);
  let history = useHistory();

  let dataCustomFilter;
  let messageFilter;
  let usernameFilter;
  let severityFilter;
  let datesFilter;

  const location = props.location; //useLocation();
  const { t } = useTranslation();
  const [data, setData] = useState([]); // api data

  const [message, setMessage] = useState("");
  const [json_data, setJson_data] = useState("");
  const [username, setUsername] = useState("");

  // if multiSelect column -> set to [] as initialState
  const [severity, setSeverity] = useState([]);
  const [hp_timestamp, setHp_timestamp] = useState("");

  const [sort, setSort] = useState("hp_timestamp"); // default sort on date -> hp_timestamp
  const [pageNum, setPageNum] = useState(1);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [rowCount, setRowCount] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [paginationCount, setPaginationCount] = useState(1);
  const [filters, setFilters] = useState([]);
  const [isLoadData, setLoadData] = useState(true);

  const handleFiltering = (e, name, multiSelectable) => {
    console.log("handleFiltering - e - name ->", e, name);
    if (
        (name && e && (e.value || e.value === "")) ||
        (e && e.key && e.key === "Enter") ||
        (name === "hp_timestamp" &&
            (e !== "" || e === null || e === undefined)) ||
        (e && multiSelectable && e.length > 0)
    ) {
      let value = "";
      switch (name) {
        case "message":
          let message = e && e.target && e.target.value ? e.target.value : "";
          if (message === "") {
            message = null;
          }
          value = message;
          setMessage(message);
          break;
        case "hp_timestamp":
          if (e === "" || e === null) {
            value = null;
          } else {
            // set search to get dates closest to
            value = ReverseHighPrecisionTimestampFormatter(
                new Date(e)
            ).toString();
          }
          setHp_timestamp(value);
          break;
        case "json_data":
          let json_data = e && e.target && e.target.value ? e.target.value : "";
          if (json_data === "") {
            json_data = null;
          }
          value = json_data;
          setJson_data(json_data);
          break;
        case "severity":
          console.log("Filter - severity - multiSelect ->", multiSelectable);
          if (multiSelectable) {
            value = e;
            console.log("Filter - severity - value ->", value);
            if (value && value.length > 0) {
              let selectedValues = [];
              for (let i = 0; i <= value.length; i++) {
                if (value[i] && value[i].value) {
                  selectedValues.push(value[i].value);
                }
              }
              console.log("Filter - severity - selected ->", selectedValues);
              setSeverity(selectedValues);
            }
          } else {
            value = e && e.value ? e.value : "";
            if (value === "") {
              value = null;
            }
            setSeverity(value);
          }
          break;
        case "username":
          let username = e && e.target && e.target.value ? e.target.value : "";
          if (username === "") {
            username = null;
          }
          value = username;
          setUsername(username);
          break;
        default:
          break;
      }

      if (name && filters && filters.indexOf(name) !== -1) {
        console.log("Value name exists!", name);
      } else {
        console.log("Value does not exists!");
      }

      if (filters && filters[name]) {
        if (value === "" || !value) {
          delete filters[name];
        } else {
          if (filters[name] && false) {
            delete filters[name];
          } else {
            filters[name] = value;
          }
        }
      } else {
        if (value) {
          filters[name] = value;
        }
      }

      console.log("filters", filters);
      setFilters(filters);
    }
  };

  const clearInput = (e, id) => {
    e.preventDefault();

    let input = document.getElementById(id);
    input.value = "";

    let elemName = input.name;
    if (elemName === "message") {
      setMessage("");
    }
    if (elemName === "json_data") {
      setJson_data("");
    }
    if (elemName === "username") {
      setUsername("");
    }
  };

  const headerInputFormatter = (column) => {
    return (
        <div
            style={{ display: "flex", flexDirection: "column" }}
            className={`activity-logs-list mw-200`}
        >
          <span className="thead-label">{column.text}</span>
          <InputGroup className={`table-search-input`}>
            <Input
                type="text"
                className="f2x-h-text"
                name={column.dataField}
                onKeyPress={(e) => handleFiltering(e)}
                id={`id-input-activity-logs-list-${column.dataField}`}
                placeholder={`Search...`}
            />
            <InputGroupText className="clear-btn">
              <a
                  href="/#"
                  className="danger icon-delete"
                  onClick={(e) =>
                      clearInput(e, `id-input-activity-logs-list-${column.dataField}`)
                  }
              >
                <FontAwesomeIcon
                    className="f2x-mv-tabs-list-item-icon"
                    icon={faTimes}
                />
              </a>
            </InputGroupText>
          </InputGroup>
        </div>
    );
  };

  const clearDateSelected = (e) => {
    e.preventDefault();
    setHp_timestamp("");
  };

  const headerDateFormatter = (column) => {
    return (
        <div
            className={`activity-logs-list mw-200`}
            style={{ display: "flex", flexDirection: "column" }}
        >
          <span className="thead-label">{column.text}</span>
          <InputGroup className={`table-search-input`}>
            <DatePicker
                className="form-control f2x-h-text max-width-200"
                name="date"
                dateFormat={t("date_format_raw")}
                autoComplete="off"
                placeholderText="Select date"
                selected={hp_timestamp ? new Date(hp_timestamp) : null}
                onChange={(date) => {
                  setHp_timestamp(date ? new Date(date) : null);
                }}
                maxDate={new Date()}
            >
              {" "}
            </DatePicker>
            <InputGroupText className="clear-btn">
              <a
                  href="/#"
                  className="danger icon-delete"
                  onClick={clearDateSelected.bind(this)}
              >
                <FontAwesomeIcon
                    className="f2x-mv-tabs-list-item-icon"
                    icon={faTimes}
                />
              </a>
            </InputGroupText>
          </InputGroup>
        </div>
    );
  };

  const headerSeverityFormatter = (column) => (
      <div
          className={`activity-logs-list`}
          style={{ display: "flex", flexDirection: "column" }}
      >
        <span className="thead-label">{column.text}</span>
        <Select
            id="severity"
            placeholder="Select..."
            options={CONSTANTS.SUBSCRIPTION_LOG_SEVERITY}
            value={severity}
            onChange={(data) => setSeverity(data)}
            className="react-select-container"
            classNamePrefix="react-select"
        />
      </div>
  );

  const severityFormatter = (cell, row) => {
    const styleClass = `f2x-opt-${cell.toLowerCase()}`;
    return <span className={`${styleClass} f2x-logs-sev`}>{cell}</span>;
  };

  const loadData = (isLoad) => {
    console.log('loadData isLoad', isLoad);
    if (!isLoadData || !page) {
      setLoadData(isLoad)
    }

    const activityLogsListFilterQueryPage = sessionStorage.getItem('ActivityLogsListFilterQueryPage');
    setPage(page ? page : 1);
    const params = getQueryParameters();
    if (params) {
      setQueryParameters(params);

      history.replace({
        state: {
          sort: sort ? sort : '',
          page: page ? page : 1,
          sizePerPage: sizePerPage ? sizePerPage : 10,
          filter: ''
        }
      });
    }
  };

  const getQuery = () => {
    return null;
    // use this getQuery to get the search for text string search from history
    // Use the URLSearchParams API to extract the query parameters
    // useLocation().search will have the query parameters eg: ?foo=bar&a=b
    //if (location && location.search && location.search !== "") {
    //return new URLSearchParams(location.search);
    //}
    //return null;
  };

  const HighPrecisionTimestampFormatter = (cell, row) => {
    console.log("HighPrecisionTimestampFormatter - cell ->", cell);
    return cell ? (
        <span id={row.id} data-cell={cell}>
        {moment.unix(cell / 1000).format("DD-MM-YYYY HH:mm:ss.SSS")}
      </span>
    ) : (
        <span className="null-value">{String.fromCharCode(8212)}</span>
    );
  };

  const ReverseHighPrecisionTimestampFormatter = (date) => {
    console.log("ReverseHighPrecisionTimestampFormatter - date ->", date);
    if (date) {
      let ts = moment(date, "DD-MM-YYYY HH:mm:ss.SSS").valueOf();
      console.log(
          "ReverseHighPrecisionTimestampFormatter - hpDateTimestamp ->",
          ts
      );
      return ts;
    }
    return null;
  };

  const removeXML = (json) => {
    const xmlData = json.Xml ? json.Xml : null;
    delete json.Xml;
    return (
        <>
          <div>{JsonPrettify(json, JsonOptions)}</div>
          {xmlData && <div>{XMLPrettify(xmlData, XmlOptions)}</div>}
        </>
    );
  };

  const SeverityStyle = (cell) => {
    return <Badge style={{maxWidth: "140px", margin: "0 auto"}} className={`badge-pill severity ${cell}`}>{cell}</Badge>;
  };

  const breakStyle = (cell, row, rowIndex, formatExtraData) => {
    if (cell && row && row.ip) {
      const ip = row.ip;
      return (
          <span className="username-ip-format">
          <span className="activity-logs-username">{cell}</span>
          <span className="activity-logs-ip">{ip}</span>
        </span>
      );
    } else {
      return <div className="username-ip-format">{cell}</div>;
    }
  };

  const messageStyle = (cell, row) => {
    if (cell && row && row.method) {
      const method = row.method;
      return (
          <span className="message-format">
          <span className="activity-logs-message">{cell}</span>
          <span className="activity-logs-method">
            <b>{method}</b>
          </span>
        </span>
      );
    } else {
      return <div className="username-ip-format">{cell}</div>;
    }
  };

  const Prettify = (cell, row, rowIndex, formatExtraData) => {
    const xml = Object.keys(cell).filter((data) => data === "Xml");
    return (
        <React.Fragment>
          {cell && (
              <React.Fragment>
                {cell.Xml && xml.length > 0 && (
                    <div>{XMLPrettify(cell.Xml, XmlOptions)}</div>
                )}
                {removeXML(cell)}
              </React.Fragment>
          )}
        </React.Fragment>
    );
  };

  const detailsExpand = {
    renderer: (row) => {
      if (row) {
        return (
            <ReactJson
                src={row}
                enableClipboard={false}
                style={{
                  fontFamily: "inherit",
                  backgroundColor: "transparent",
                  textAlign: "left",
                }}
            />
        );
      }
      return "";
    },
  };

  const headerResetFormatter = () => {
    return (
        <button className="btn btn-gray float-right" onClick={handleClick}>
          Clear filters
        </button>
    );
  };

  const handleDataChange = ({dataSize}) => {
    setRowCount(dataSize);
  };

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
    console.log("handleTableChange");

    const pageNum = page;
    const sortFieldParam = sortField === "date" ? "date" : sortField;
    const isSortDesc = sortField && stringFilter(sortOrder, "desc");
    const sortParam = isSortDesc ? `-${sortFieldParam}` : sortFieldParam;
    const propsObj = getTableObj(type, {
      searchText,
      isLoadData,
      pageNum,
      page,
    });

    setSizePerPage(sizePerPage ? sizePerPage : 10);
    setSort(sortParam);
    setPage(propsObj && propsObj.pageNum ? propsObj.pageNum : 1);
    setLoadData(propsObj && propsObj.isLoadData ? propsObj.isLoadData : true);

    let params = [];
    params["sort"] = sortParam;
    params["page"] = propsObj && propsObj.pageNum ? propsObj.pageNum : 1;
    params["filter"] = null;
    params["offset"] = sizePerPage ? sizePerPage : 10;
    if (type === "pagination") {
      params["count"] = 0;
      setPaginationCount(0);
    }

    setQueryParameters(params);
  };

  useEffect(() => {
    dispatch(loaderToggle(true));

    console.log("ListFilter - filters ->", filters);
    const queryParams = getQueryParameters();

    let queryFilter = [];
    for (let key in filters) {
      console.log("ListFilter - queryFilter - key ->", key);
      if (filters.hasOwnProperty(key)) {
        if (
            key === "hp_timestamp" ||
            key === "json_data" ||
            key === "severity" ||
            key === "username" ||
            key === "message"
        ) {
          if (key === "severity") {
            let multiValues = [];
            for (let i = 0; i <= filters[key].length; i++) {
              if (filters[key][i] && filters[key][i].value) {
                multiValues.push(filters[key][i].value);
              }
            }
            let multiKeyFilters = multiValues.join(",");
            queryFilter.push(`${key}=[${multiKeyFilters}]`);
          } else if (key === "hp_timestamp") {
            queryFilter.push(`${key}>${filters[key]}`);
          } else {
            queryFilter.push(`${key}%${filters[key]}`);
          }
        } else {
          queryFilter.push(`${key}=${filters[key]}`);
        }
      }
    }
    queryFilter.join(",");
    //queryFilter.join("&");
    console.log("queryFilter ->", queryFilter);

    //let param_history = getQueryParameters();
    /*const param = {
      page:
          param_history && param_history["page"] ? param_history["page"] : page,
      offset:
          param_history && param_history["sizePerPage"]
              ? param_history["sizePerPage"]
              : sizePerPage,
      count: paginationCount,
      filter:
          queryFilter && queryFilter.length > 0
              ? queryFilter.join(",").toString()
              : "",
    };*/

    const param = {
      page: page ? parseInt(page) : 1,
      offset: sizePerPage ? parseInt(sizePerPage) : 10,
      sort: sort ? sort : '',
      filter: queryFilter && queryFilter.length > 0 ? queryFilter.toString() : '',
      search: "",
    };

    console.log("param ->", param);
    setQueryParameters(param); // save param

    GetAPI("activity_logs", param)
    .then((r) => {
      dispatch(loaderToggle(false));
      if (r.data) {
        const { data, total } = r.data || {};
        console.log("GetAPI - data ->", data);
        let listData = data;
        setData(listData || []);
        setTotalSize(total || totalSize);
        //dispatch(loaderToggle(false));
      }
    })
    .catch((error) => {
      dispatch(loaderToggle(false));
      console.log("Error fetching data: ", error);
    });
  }, [
    hp_timestamp,
    message,
    json_data,
    severity,
    username,
    //sort,
    //page,
    //sizePerPage,
    //totalSize,
    //isLoadData,
    dispatch,
    //filters,
  ]);

  const setQueryParameters = (params) => {
    if (!params) {
      params = [];
      params["sort"] = sort ? sort : "";
      params["page"] = page ? parseInt(page) : 1;
      params["offset"] = sizePerPage ? parseInt(sizePerPage) : 10;
      //params["filter"] = "";
      //params["search"] = "";
    }

    let query = [],
        searchFilterQuery = {},
        key,
        value;

    for (key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      if (key === "page" || key === "offset" || key === "count") {
        value = parseInt(params[key]);
        searchFilterQuery[key] = value;
      } else {
        value = params[key];
        searchFilterQuery[key] = value;
      }
      query.push(`${key}=${value}`);
    }
    //query.join("&");

    console.log("ListFilter - searchFilterQuery ->", searchFilterQuery);
    console.log("ListFilter - location ->", location);
    console.log("ListFilter - location.pathname ->", location.pathname);
    //const location_search_query = getQuery();

    const location_search_query = query;
    console.log("ListFilter - location_search_query ->", location_search_query);
    let location_search = location_search_query
        ? location_search_query.join("&")
        : "";
    console.log("ListFilter - location_search ->", location_search);
    sessionStorage.setItem("ActivityLogsListFilterQueryPage", location_search);

    const searchQuery = `?${new URLSearchParams({
      search: searchFilterQuery,
      filter: "",
      page: params['page'],
      sort: params['sort'],
      offset: params['offset'],
      count: params['count'],
    })}`; //.toString()}`; // search keyword only

    history.replace({
      pathname: `/activity_logs_filter`,
      search: `?${new URLSearchParams(searchFilterQuery)}`,
    });

    setLoadData(true);
  };

  const getQueryParameters = () => {
    const tableId = "ActivityLogsListFilterQueryPage";

    let queryString;
    let param = {};

    if (sessionStorage.getItem(tableId)) {
      queryString =
          sessionStorage.getItem(tableId) &&
          sessionStorage.getItem(tableId).charAt(0) === "?"
              ? sessionStorage.getItem(tableId).slice(1)
              : sessionStorage.getItem(tableId);

      if (queryString) {
        queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
          if (key === "page" || key === "offset" || key === "count") {
            param[key] = parseInt(value);
          } else {
            param[key] = value;
          }
        });

        if (param) {
          setPage(param["page"] ? param["page"] : page ? page : 1);
          setSizePerPage(
              param["offset"] ? param["offset"] : sizePerPage ? sizePerPage : 10
          );
          setPaginationCount(paginationCount ? paginationCount : 1);
        }
      }
    } else {
      param["page"] = page ? page : 1;
      param["offset"] = sizePerPage ? sizePerPage : 10;
      param["count"] = paginationCount ? paginationCount : 1;
      //param["filter"] = "";
      //param["search"] = "";
    }

    return param;
  };

  const handleClick = () => {
    dataCustomFilter = [];
    messageFilter = [];
    usernameFilter = [];
    severityFilter = [];
    datesFilter = [];
  };

  const closeFilterFormat = () => {
    //e.preventDefault();

    history.push({
      pathname: `/activity_logs`,
    });

    return null;
    //return props.setToggleForm(false);
  };

  return (
      <div className="no-pagination-details">
        <h1 className="text-bold mb-3">Activity logs</h1>
        <Card className="mt-1 mb-0 card-bordered-table">
          {/*<Container fluid className="p-0">
        <Card className={'mt-1 mb-0 card-bordered-table'}>*}
          {/*<CardHeader className="card-header-primary">
            <Row>
              <Col>
                <div className="d-flex ml-auto justify-content-end header-opts">
                  <button
                      className="btn btn text-truncate btn btn-gray"
                      onClick={(e) => closeFilterFormat(e)}
                  >
                    Close filters
                  </button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="action-link">*/}
            <CustomDataTable
                table="Activity logs list filter"
                id="activity_logs_list_filter"
                classes="finance-table table-activity-logs table-activity-logs-filter react-bootstrap-table filters mb-0"
                keyField="id"
                remote
                data={data}
                columns={[
                  {
                    dataField: "hp_timestamp",
                    filter: customFilter({
                      type: "date",
                      style: { display: "inline-grid" },
                      defaultValue: { date: new Date() }, //default value
                      getFilter: (filter) => {
                        console.log("datesFilter - getFilter - filter", filter);
                        datesFilter = filter;
                      },
                    }),
                    filterRenderer: (onFilter, column) => (
                        <CustomFilterDate
                            onFilter={onFilter}
                            column={column}
                            handleFiltering={handleFiltering}
                        />
                    ),
                    formatter: HighPrecisionTimestampFormatter,
                    text: "Timestamp",
                    align: "left",
                    headerStyle: () => {
                      return { width: "15%" };
                    },
                    attrs: {
                      "data-label": "Timestamp",
                    },
                    title: true,
                    sort: true,
                    headerSortingClasses: (sort, opts) => {
                      console.log(
                          "headerSortingClasses - sort - opts ->",
                          sort,
                          opts
                      );
                      return "sorted-column";
                    },
                  },
                  {
                    dataField: "message",
                    text: "Message",
                    classes: "last-cell activity-logs-message",
                    filter: customFilter({
                      type: "text",
                      style: { display: "inline-grid" },
                      getFilter: (filter) => {
                        console.log("messageFilter - getFilter - filter", filter);
                        messageFilter = filter;
                      },
                    }),
                    filterRenderer: (onFilter, column) => (
                        <CustomFilterInput
                            onFilter={onFilter}
                            column={column}
                            handleFiltering={handleFiltering}
                            clearInput={clearInput}
                        />
                    ),
                    headerStyle: () => {
                      return { width: "15%" };
                    },
                    attrs: {
                      "data-label": "Message",
                    },
                    title: true,
                    sort: true,
                    headerSortingClasses: (sort, opts) => {
                      console.log(
                          "headerSortingClasses - sort - opts ->",
                          sort,
                          opts
                      );
                      return "sorted-column";
                    },
                  },
                  {
                    dataField: "json_data",
                    text: "Data",
                    classes: "f2x-details-link",
                    filter: customFilter({
                      type: "text",
                      style: { display: "inline-grid" },
                      getFilter: (filter) => {
                        console.log(
                            "dataCustomFilter - getFilter - filter",
                            filter
                        );
                        dataCustomFilter = filter;
                      },
                    }),
                    filterRenderer: (onFilter, column) => (
                        <CustomFilterInput
                            onFilter={onFilter}
                            column={column}
                            handleFiltering={handleFiltering}
                            clearInput={clearInput}
                        />
                    ),
                    formatter: Prettify,
                    headerStyle: () => {
                      return { width: "35%" };
                    },
                    attrs: {
                      "data-label": "Data",
                    },
                    title: true,
                    sort: true,
                    headerSortingClasses: (sort, opts) => {
                      console.log(
                          "headerSortingClasses - sort - opts ->",
                          sort,
                          opts
                      );
                      return "sorted-column";
                    },
                  },
                  {
                    dataField: "severity",
                    text: "Severity",
                    filter: customFilter({
                      type: "select",
                      style: { display: "inline-grid" },
                      getFilter: (filter) => {
                        console.log("severityFilter - getFilter - filter", filter);
                        severityFilter = filter;
                      },
                    }),
                    filterRenderer: (onFilter, column) => (
                        <CustomFilterSelect
                            onFilter={onFilter}
                            column={column}
                            handleFiltering={(e) =>
                                handleFiltering(e, "severity", true)
                            }
                            constantObj={CONSTANTS.SUBSCRIPTION_LOG_SEVERITY}
                            multiSelect={true}
                        />
                    ),
                    formatter: SeverityStyle,
                    headerStyle: () => {
                      return { width: "20%" };
                    },
                    attrs: {
                      "data-label": "Severity",
                    },
                    align: "center",
                    title: true,
                    sort: true,
                    headerSortingClasses: (sort, opts) => {
                      console.log(
                          "headerSortingClasses - sort - opts ->",
                          sort,
                          opts
                      );
                      return "sorted-column";
                    },
                  },
                  {
                    dataField: "username",
                    text: "Username / IP",
                    filter: customFilter({
                      type: "text",
                      style: { display: "inline-grid" },
                      getFilter: (filter) => {
                        console.log("usernameFilter - getFilter - filter", filter);
                        usernameFilter = filter;
                      },
                    }),
                    filterRenderer: (onFilter, column) => (
                        <CustomFilterInput
                            onFilter={onFilter}
                            column={column}
                            handleFiltering={handleFiltering}
                            clearInput={clearInput}
                        />
                    ),
                    formatter: breakStyle,
                    sort: false,
                    classes: "last-cell activity-logs-username-ip",
                    headerStyle: () => {
                      return {
                        width: "15%",
                        minWidth: "100px",
                        textAlign: "right",
                      };
                    },
                    attrs: {
                      "data-label": "Username / IP",
                    },
                  },
                ]}
                headerDropdownOpts={[
                  {
                    label: "Hide filters", action: closeFilterFormat,
                  },
                ]}
                page={page ? parseInt(page) : 1}
                sizePerPage={sizePerPage ? parseInt(sizePerPage) : 10}
                totalSize={totalSize ? parseInt(totalSize) : 10}
                sortItem={sort}
                filter={filters}
                search={true}
                expandRow={detailsExpand}
                onTableChange={handleTableChange}
            />
          {/*</CardBody>*/}
        </Card>
        {/*</Container>*/}
      </div>
  );
};

export default withRouter(withTranslation()(connect()(ActivityLogsFilter)));
