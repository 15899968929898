import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI;

const apiIncludes = 'relation,sales-invoice-line,account,accounting-period'

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : GetAPI(endpoint, params)
  })()
};

const GetSearchResult = (search_value, params) => {
  console.log("GetSearchResult - search_value ->", search_value);
  console.log("GetSearchResult - params ->", params);

  return asyncFunc(`/search/query?q=${search_value}${params ? params : ""}`)
};

export {
  GetSearchResult
}
