import React from "react";
import classnames from "classnames";
import { Card, Col, Container, ListGroup, ListGroupItem, Row, TabContent, TabPane } from "reactstrap";
import PublicInfo from './PublicInfo';
import PrivateInfo from './PrivateInfo';
import UserSettings from './Settings';
import Security from './Security';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCog,
  faUserShield,
  faUserLock,
  faTags,
  faCogs,
} from '@fortawesome/free-solid-svg-icons';
import LayoutSettings from "../../../components/Settings";

class Settings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '0',
    };

    this.tabs = [{
      title: 'Public info',
      icon: <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={faUserCog} size={`sm`} />,
      component: <PublicInfo />
    }, {
      title: 'Private info',
      icon: <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={faUserLock} size={`sm`} />,
      component: <PrivateInfo />
    }, {
      title: 'Security',
      icon: <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={faUserShield} size={`sm`} />,
      component: <Security />
    }, {
      title: 'Local settings',
      icon: <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={faCogs} size={`sm`} />,
      component: <UserSettings />
    }];

    this.toggle = this.toggle.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  render() {
    const { activeTab } = this.state;

    return(
        <Container fluid className="p-0">
          <h1 className="text-bold mb-3">Settings</h1>

          <Row>
            <div className="col-3">
              <Card className="settings-user-nav" style={{minWidth: "52px"}}>
                <ListGroup tabs="true">
                  { this.tabs.map((item, index) => {
                    return (
                        <ListGroupItem
                            className={`text-truncate ${ classnames({ active: activeTab === index.toString() }) }`}
                            onClick={() => { this.toggle(index.toString()) }}
                            key={ index } style={{minWidth: "45px"}}
                        >
                          <span className="user-settings-icon">{ item.icon }</span>
                          <span className="user-settings-text ml-2 d-none d-sm-inline-block">
                            <span className={`text-truncate`}>{ item.title }</span>
                          </span>
                        </ListGroupItem>
                    )
                  })
                  }
                </ListGroup>
              </Card>
            </div>
            <div className="col-9">
              <TabContent activeTab={ activeTab }>
                { this.tabs.map((item, index) => {
                  return (
                      <React.Fragment key={ index }>
                        { activeTab.toString() === index.toString() &&
                        <TabPane tabId={ index.toString() }>
                          <div> { item.component } </div>
                        </TabPane>
                        }
                      </React.Fragment>
                  )
                })
                }
              </TabContent>
            </div>
          </Row>
          {/*<LayoutSettings />*/}
        </Container>
    )
  }
}

export default Settings;
