import ApiService from "../services/apiService";

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI;

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        let data = await method ? method(endpoint, params) : GetAPI(endpoint, params);
        return data
    })()
};

const GetMyAccounts = (params, id) => {
    return asyncFunc(`/myaccounts/${id}`, params)
};

const GetAccountsLists = (id) => {
    return asyncFunc(`/accounts/list/${id}`)
};

const AddAccount = (params) => {
    return asyncFunc(`/accounts`, params, PostAPI)
};

const UpdateAccount = (id, params) => {
    return asyncFunc(`/accounts/${id}`, params, UpdateAPI)
};

export {
    GetMyAccounts,
    AddAccount,
    UpdateAccount,
    GetAccountsLists
}
