import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverHeader, PopoverBody, UncontrolledPopover, Alert } from 'reactstrap';
import { withTranslation } from "react-i18next";
import { format } from 'date-fns';
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import {
    Card,
    Row,
    CardBody,
    ListGroup,
    ListGroupItem,
    Col,
    Button,
    Form,
    Input, CustomInput,
} from 'reactstrap';
import ButtonSpinner from "../../components/ButtonSpinner";
import { GetCountries, GetCities, GetCountryCities, GetLocationByParams } from "../../controllers/relations";
import { GetPublicLocationByParams, GetPublicCountries, GetPublicCountryCities} from '../../controllers/av_check';
import { getBrowserLocales, getBrowserLocale } from "../../services/misc";
import { AddRelationCS } from "../../controllers/relations";
import { GetPlanList } from "../../controllers/plans";
import {
    GetContractPeriodList,
    GetNetworkOperatorList,
} from "../../controllers/subscriptions";
import { GetOrder } from "../../controllers/orders";
import { loaderToggle } from "../../redux/actions/loaderActions";
import {
    validateDate,
    validateEmail,
    validateIBAN,
} from "../../redux/actions/validateActions";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCaretDown,
    faCaretUp,
    faCaretLeft,
    faArrowLeft,
    faArrowCircleDown,
    faArrowCircleUp,
    faMinusCircle,
    faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import Toast from "../../components/Toast";
import { nl, enGB, enUS } from "date-fns/locale";
import { parseDateToSaveFormat } from "../../redux/actions/formatActions";
import {GetPaymentConditions} from '../../controllers/payment_conditions'; // import all locales we need

registerLocale("en", enGB);
registerLocale("nl", nl);
registerLocale("us", enUS);

const titleOpts = [
    { value: "Dhr", label: "Dhr" },
    { value: "Mevr", label: "Mevr" },
    { value: "Dhr/Mevr", label: "Dhr/Mevr" },
];

const languageOpts = [
    { value: "nl-NL", label: "Dutch", browser_locale: "nl" },
    { value: "en-GB", label: "English", browser_locale: "en" },
];

const genderOpts = [
    { value: "m", label: "Male" },
    { value: "f", label: "Female" },
    { value: "o", label: "Other" },
];

class OfferPersonal extends React.Component {
    constructor(props) {
        super(props);

        console.log("Personal - personal data - props ->", props);
        //console.log("Personal - personal data - getBrowserLocales ->", getBrowserLocales()); // get all locales
        //console.log("Personal - personal data - getBrowserLocale ->", getBrowserLocale()); // get client system language

        const browserLocale = getBrowserLocale();
        const { t, defaultCountry, planData, apiKey } = props;

        this.state = {
            relation: props.stepFormData && props.stepFormData.relation ? props.stepFormData.relation : {
                business_details: false,
                billing_address: false,
            },
            billing_address_icon: faPlusCircle,
            billing_address_selected: false,
            stepFormData: props.stepFormData,
            planData: [],
            provisioningAddress: props.provisioningAddress,
            provisioningCityId: props.provisioningCityId,
            cityOpts: props.cityOpts,
            countryOpts: props.countryOpts,
            stepForm: props.stepForm,
            browser_locale: browserLocale,
            isBusiness: props.isBusiness ? parseInt(props.isBusiness) : 0,
            locationModal: false,
            locationModalContent: [],
            locationAlert: false,
            locationAlertContent: [],
        };
    }

    getLocale = () => {
        const dateLocalesPath = {
            nl: "nl",
            en: "en-GB",
            us: "en-US",
        };

        require(`date-fns/locale/${
            dateLocalesPath[this.props.i18n.language]
        }/index.js`);
    };

    handleInputChange({ target: { name, value } }) {
        let relation = Object.assign({}, this.state.relation);

        if (
            name === "billing_zipcode" ||
            name === "billing_house_number" ||
            name === "billing_house_number_letter" ||
            name === "billing_house_number_suffix" ||
            name === "billing_house_number_room")
        {
            let params = {};
            let relation = Object.assign({}, this.state.relation);
            console.log("handleInputChange - relation ->", relation);

            if (name === "billing_zipcode" && value) {
                params.billing_zipcode = value;
                if (relation.billing_house_number) {
                    params.billing_house_number = relation.billing_house_number;
                }
            }
            if (name === "billing_house_number" && value) {
                params.billing_house_number = value;
                if (relation.billing_zipcode) {
                    params.billing_zipcode = relation.billing_zipcode;
                }
            }
            if (name === "billing_house_number_letter" && value) {
                params.billing_house_number_letter = value;
                if (relation.billing_zipcode && relation.billing_house_number) {
                    params.billing_zipcode = relation.billing_zipcode;
                    params.billing_house_number = relation.billing_house_number;
                }
            }
            if (name === "billing_house_number_suffix" && value) {
                params.billing_house_number_suffix = value;
                if (relation.billing_zipcode && relation.billing_house_number && relation.billing_house_number_letter) {
                    params.billing_zipcode = relation.billing_zipcode;
                    params.billing_house_number = relation.billing_house_number;
                    params.billing_house_number_letter = relation.billing_house_number_letter;
                }
            }
            if (name === "billing_house_number_room" && value) {
                params.billing_house_number_room = value;
                if (relation.billing_zipcode && relation.billing_house_number && relation.billing_house_number_letter) {
                    params.billing_zipcode = relation.billing_zipcode;
                    params.billing_house_number = relation.billing_house_number;
                    params.billing_house_number_letter = relation.billing_house_number_letter;
                }
            }
            console.log("handleInputChange - params ->", params);

            //if (params.billing_zipcode && params.billing_house_number) {
            if (params) {
                let param = {};
                param.zipcode = params.billing_zipcode;
                param.house_number = params.billing_house_number;
                //param.house_number_suffix = params.billing_house_number_suffix;

                this.checkLocation(params);
            }
        }

        relation[name] = value;

        this.setState({ relation });

        let stepFormData = Object.assign({}, this.state.stepFormData);
        stepFormData.relation = relation;

        const { setStepFormData } = this.props;
        if (setStepFormData) {
            setStepFormData(stepFormData);
        }
    }

    handleDatePickerChange(name, date) {
        let relation = Object.assign({}, this.state.relation);
        const { newDate } = this.props.dispatch(validateDate(date));

        relation[name] = format(new Date(newDate), "yyyy-MM-dd");

        this.setState({ relation });

        let stepFormData = Object.assign({}, this.state.stepFormData);
        stepFormData.relation = relation;

        const { setStepFormData } = this.props;
        if (setStepFormData) {
            setStepFormData(stepFormData);
        }
    }

    handleSelectChange = async (name, data) => {
        // on change billing_country_id -> set billing_city_id
        if (name && name === "billing_country_id" && data && data.value) {
            // get cities from billing_country_id
            await this.getCities(data.value);

            const {cityOpts} = this.state;
            if (cityOpts && cityOpts.length > 0) {
                let relation = Object.assign({}, this.state.relation);

                relation.billing_street = null;
                relation.billing_house_number = null;
                relation.billing_housenumber_suffix = null;
                relation.billing_zipcode = null;
                relation.billing_city_id = null;
                relation.billing_country_id = data;

                this.setState({
                    relation
                });

                const {setStepFormData} = this.props;
                if (setStepFormData) {
                    //let stepFormData = Object.assign({}, stepFormData);
                    let stepFormData = Object.assign({}, this.state.stepFormData);
                    stepFormData.relation = relation;

                    setStepFormData(stepFormData);
                }
            }

        } else if (name && name === "billing_city_id" && data && data.value) {
            // set billing city from cityOpts
            const {cityOpts} = this.state;
            if (cityOpts && cityOpts.length > 0) {
                const billing_city_id = cityOpts.find((item) => item.value === data.value);
                if (billing_city_id) {
                    let relation = Object.assign({}, this.state.relation);

                    relation.billing_street = null;
                    relation.billing_house_number = null;
                    relation.billing_housenumber_suffix = null;
                    relation.billing_zipcode = null;
                    relation.billing_city_id = data;

                    this.setState({
                        relation
                    });

                    const {setStepFormData} = this.props;
                    if (setStepFormData) {
                        //let stepFormData = Object.assign({}, stepFormData);
                        let stepFormData = Object.assign({}, this.state.stepFormData);
                        stepFormData.relation = relation;

                        setStepFormData(stepFormData);
                    }
                }
            }

        } else {
            let relation = Object.assign({}, this.state.relation);
            relation[name] = data ? data : null;

            this.setState({
                relation
            });

            const { setStepFormData } = this.props;
            if (setStepFormData) {
                let stepFormData = Object.assign({}, this.state.stepFormData);
                stepFormData.relation = relation;
                setStepFormData(stepFormData);
            }
        }
    };

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false); // refs
        }
    };

    clickButtonCollapseBillingAddress = (e, target_id, billing_address) => {
        this.setState({
            billing_address_icon: !!billing_address === false ? faMinusCircle : faPlusCircle,
            billing_address_selected: !!billing_address === false ? true : false,
        });

        const { relation, billing_address_selected } = this.state;
        relation["billing_address"] = !!billing_address === false ? true : false;

        this.setState({
            relation,
        });

        if (!!billing_address === true) {
            console.log("Personal - clickButtonCollapseBillingAddress - other billing address is set to false, so set provisioning address as billing address!");

            let relation = Object.assign({},
                this.state.relation);

            relation.billing_street = relation.prov_street ? relation.prov_street : null;
            relation.billing_house_number = relation.prov_house_number ? relation.prov_house_number : null;
            relation.billing_house_number_suffix = relation.prov_house_number_suffix ? relation.prov_house_number_suffix : null;
            relation.billing_house_number_letter = relation.prov_house_number_letter ? relation.prov_house_number_letter : null;
            relation.billing_house_number_room = relation.prov_house_number_room ? relation.prov_house_number_room : null;
            relation.billing_zipcode = relation.prov_zipcode ? relation.prov_zipcode : null;
            relation.billing_city_id = relation.prov_city_id ? relation.prov_city_id : null;
            relation.billing_country_id = relation.prov_country_id ? relation.prov_country_id : null;

            this.setState({
                relation
            });

            const { setStepFormData } = this.props;
            if (setStepFormData) {
                let stepFormData = Object.assign({}, this.state.stepFormData);
                stepFormData.relation = relation;
                setStepFormData(stepFormData);
            }
        }
    };

    getCities(id, returnData = false) {
        const { dispatch } = this.props;

        dispatch(loaderToggle(true));

        (async () => {
            const { ok, data } = this.props.apiKey ? await GetPublicCountryCities(this.props.apiKey, id) : await GetCountryCities(id);

            await (() => {
                if (ok) {
                    const cities = data.data ? data.data : [];
                    let cityOpts = [];

                    cities.length > 0 &&
                    cities.map(({ name, id }) => {
                        return cityOpts.push({
                            label: name,
                            value: id,
                        });
                    });

                    this.setState({ cityOpts });

                    if (returnData) {
                        return cityOpts;
                    }
                }
            })();

            dispatch(loaderToggle(false));

        })();
    }

    componentDidMount() {
        const { stepFormData, setStepFormData } = this.props;

        if (stepFormData && stepFormData.relation && Object.keys(stepFormData.relation).length > 0) {
            console.log("Personal - compDidMount - stepFormData.relation ->", stepFormData.relation);

            if (setStepFormData) {
                setStepFormData(stepFormData);
            }

            //if (stepFormData.relation && stepFormData.relation.business_details_on) {
            //    this.setState({
            //        business_details: stepFormData.relation.business_details_on,
            //    });
            //}

        } else {
            (async () => {
                const {dispatch, user, location, stepFormData, defaultCountry, planData, provisioningCityId, stepForm} = this.props;

                console.log("Personal - compDidMount - defaultCountry ->", defaultCountry);

                dispatch(loaderToggle(true));

                const [getCountries] = this.props.apiKey ? await Promise.all([GetPublicCountries(this.props.apiKey)]) : await Promise.all([GetCountries()]);

                await (() => {
                    if (getCountries.ok) {
                        const countries = getCountries.data.data
                            ? getCountries.data.data
                            : [];
                        let countryOpts = [];

                        countries.length > 0 && countries.map(({name, id}) => {
                            return countryOpts.push({
                                label: name, value: id,
                            });
                        });

                        this.setState({countryOpts});

                        if (defaultCountry) {
                            let getCity;
                            const getId = defaultCountry && defaultCountry.value
                                ? defaultCountry.value
                                : 155;
                            const getCountry = countryOpts.find(
                                (country) => parseInt(country.value) ===
                                    parseInt(getId));

                            const {provisioningCityId, cityOpts, streetStr, zipCodeStr, houseNumberStr, houseNumberSuffixStr, houseNumberLetterStr} = this.props;

                            // lookup city
                            if (provisioningCityId && cityOpts) {
                                getCity = cityOpts.find(
                                    (city) => parseInt(city.value) ===
                                        parseInt(provisioningCityId));
                            }

                            // check connection for room
                            let houseNumberRoomStr;
                            if (stepFormData && stepFormData.connection && stepFormData.connection.room) {
                                console.log("Personal data - stepFormData.connection.room ->", stepFormData.connection.room);
                                houseNumberRoomStr = stepFormData.connection.room;
                            }
                            // check location for location_id
                            let prov_location_id;
                            if (stepFormData && stepFormData.location && stepFormData.location.id) {
                                console.log("Personal data - stepFormData.location.id ->", stepFormData.location.id);
                                prov_location_id = stepFormData.location.id;
                            }
                            // check browser locale
                            let client_language_locales;
                            if (this.state.browser_locale) {
                                const langOpt = languageOpts.find(
                                    (item) => item.browser_locale === this.state.browser_locale);

                                console.log("componentDidMount - client_language_locales - langOpt ->", langOpt);
                                client_language_locales = langOpt;
                            }

                            let relation = Object.assign({},
                                this.state.relation);

                            relation.country_id = getCountry ? getCountry.value : null;
                            relation.prov_street = streetStr ? streetStr : null;
                            relation.prov_house_number = houseNumberStr ? houseNumberStr : null;
                            relation.prov_house_number_suffix = houseNumberSuffixStr ? houseNumberSuffixStr : null;
                            relation.prov_house_number_letter = houseNumberLetterStr ? houseNumberLetterStr : null;
                            relation.prov_house_number_room = houseNumberRoomStr ? houseNumberRoomStr : null;
                            relation.prov_zipcode = zipCodeStr ? zipCodeStr : null;
                            relation.prov_city_id = getCity ? getCity : null;
                            relation.prov_country_id = getCountry ? getCountry : null;
                            relation.prov_location_id = prov_location_id ? prov_location_id : null;
                            relation.billing_street = streetStr ? streetStr : null;
                            relation.billing_house_number = houseNumberStr ? houseNumberStr : null;
                            relation.billing_house_number_suffix = houseNumberSuffixStr ? houseNumberSuffixStr : null;
                            relation.billing_house_number_letter = houseNumberLetterStr ? houseNumberLetterStr : null;
                            relation.billing_house_number_room = houseNumberRoomStr ? houseNumberRoomStr : null;
                            relation.billing_zipcode = zipCodeStr ? zipCodeStr : null;
                            relation.billing_city_id = getCity ? getCity : null;
                            relation.billing_country_id = getCountry ? getCountry : null;
                            relation.billing_location_id = prov_location_id ? prov_location_id : null;
                            relation.language_id = client_language_locales ? client_language_locales : null;

                            console.log("componentDidMount - relation ->", relation);

                            this.setState({
                                prov_city_opts: cityOpts,
                                relation
                            });
                        }
                    }
                })();

                dispatch(loaderToggle(false));

            })();
        }
    }

    validateZipcode = (zipcode) => {
        let regex = /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i;
        const res = regex.test(zipcode);
        console.log("validateZipcode - res ->", res);
        return res;
    };

    toggleLocationModal = () => {
      this.setState({
          locationModal: !this.state.locationModal,
      });
    };

    selectLocation = (e, i) => {
        console.log("selectLocation - e ->", e);

        if (this.state.locationAlertContent && this.state.locationAlertContent[i]) {
            console.log("selectLocation - locationAlertContent[i] ->", this.state.locationAlertContent[i]);

            const locationData = this.state.locationAlertContent[i];
            console.log("selected - location.data - locationData ->", locationData);

            let relation = Object.assign({},
                this.state.relation);

            relation.billing_street = locationData.street1 ? locationData.street1 : null;
            relation.billing_house_number = locationData.house_number ? locationData.house_number : null;
            relation.billing_house_number_suffix = locationData.house_number_suffix ? locationData.house_number_suffix : null;
            relation.billing_house_number_letter = locationData.house_letter ? locationData.house_letter : null;
            relation.billing_house_number_room = locationData.room ? locationData.room : null;
            relation.billing_zipcode = locationData.zipcode ? locationData.zipcode : null;
            relation.billing_zipcode = locationData.city_name ? locationData.city_name : null;
            // set city
            if (locationData.city && locationData.city.id && locationData.city.name) {
                let cityOpt = {};
                cityOpt.value = locationData.city.id;
                cityOpt.label = locationData.city.name;
                relation.billing_city_id = cityOpt;
            } else {
                relation.billing_city_id = locationData.city_id
                    ? locationData.city_id
                    : null;
            }
            // set country
            if (locationData.country && locationData.country.id && locationData.country.name) {
                let countryOpt = {};
                countryOpt.value = locationData.country.id;
                countryOpt.label = locationData.country.name;
                relation.billing_country_id = countryOpt;
            } else {
                relation.billing_country_id = locationData.country_id
                    ? locationData.country_id
                    : null;
            }
            relation.billing_location_id = locationData.id ? locationData.id : null;

            this.setState({
                relation,
                locationAlertContent: [],
            });
        }
    };

    checkLocation = (params) => {
        console.log("checkLocation - paramObj ->", params);
        // Expects params: zipcode, house_number, house_number_suffix, house_letter, and room
        let queryString;

        if (this.props.apiKey) {
            params.api_key = this.props.apiKey;
        }

        if (params.billing_zipcode && params.billing_house_number) {
            if (this.validateZipcode(params.billing_zipcode)) {
                queryString = Object.keys(params).
                    map(key => key + '=' + params[key]).
                    join('&');
            }
        }
        console.log("checkLocation - queryString ->", queryString);
        console.log("checkLocation - params ->", params);

        if (queryString) {
            (async () => {
                const [location] = this.props.apiKey
                    ? await Promise.all([GetPublicLocationByParams(params.billing_zipcode, params.billing_house_number, params.api_key)])
                    : await Promise.all([GetLocationByParams(params.billing_zipcode, params.billing_house_number)]);
                console.log("checkLocation - location ->", location);

                if (location && location.data && location.data.message && location.data.message === "Please select a location") {
                    console.log("checkLocation - location.data.message ->",  location.data.message);

                    if (location.data.data && location.data.data.length > 0) {
                        // show alert
                        this.setState({
                            locationAlertContent: location.data.data,
                        });
                    }

                } else if (location && location.data && location.data.data) {
                    console.log("checkLocation - location.data.data ->", location.data.data);
                    let relation = Object.assign({},
                        this.state.relation);

                    relation.billing_street = location.data.data.street1 ? location.data.data.street1 : null;
                    relation.billing_house_number = location.data.data.house_number ? location.data.data.house_number : null;
                    relation.billing_house_number_suffix = location.data.data.house_number_suffix ? location.data.data.house_number_suffix : null;
                    relation.billing_house_number_letter = location.data.data.house_letter ? location.data.data.house_letter : null;
                    relation.billing_house_number_room = location.data.data.room ? location.data.data.room : null;
                    relation.billing_zipcode = location.data.data.zipcode ? location.data.data.zipcode : null;
                    relation.billing_zipcode = location.data.data.city_name ? location.data.data.city_name : null;
                    // set city
                    if (location.data.data.city && location.data.data.city.id && location.data.data.city.name) {
                        let cityOpt = {};
                        cityOpt.value = location.data.data.city.id;
                        cityOpt.label = location.data.data.city.name;
                        relation.billing_city_id = cityOpt;
                    } else {
                        relation.billing_city_id = location.data.data.city_id
                            ? location.data.data.city_id
                            : null;
                    }
                    // set country
                    if (location.data.data.country && location.data.data.country.id && location.data.data.country.name) {
                        let countryOpt = {};
                        countryOpt.value = location.data.data.country.id;
                        countryOpt.label = location.data.data.country.name;
                        relation.billing_country_id = countryOpt;
                    } else {
                        relation.billing_country_id = location.data.data.country_id
                            ? location.data.data.country_id
                            : null;
                    }
                    relation.billing_location_id = location.data.data.id ? location.data.data.id : null;

                    this.setState({
                        relation
                    });
                }
            })();
        }
    };

    handleBusinessCheckChange = (e) => {
        let relation = Object.assign({}, this.state.relation);
        relation["business_details"] = !relation.business_details;

        this.setState({
            relation
        });

        //this.setState({
        //    business_details: !this.state.business_details,
        //});
    };

    render() {
        const {
                isLoading,
                relation,
                isBusiness,
                cityOpts,
                prov_city_opts,
                countryOpts,
                billing_address_selected,
                locationModal,
                locationPopover,
                locationAlertContent,
            } = this.state ? this.state : {},
            {
                gender,
                title,
                first_name,
                middle_name,
                last_name,
                birthdate,
                language_id,
                is_business,
                business_details,
                company_name,
                vat_no,
                reg_no,
                prov_street,
                prov_house_number,
                prov_house_number_letter,
                prov_house_number_suffix,
                prov_house_number_room,
                prov_zipcode,
                prov_city_id,
                prov_country_id,
                prov_address,
                billing_address,
                billing_street,
                billing_house_number,
                billing_house_number_letter,
                billing_house_number_suffix,
                billing_house_number_room,
                billing_zipcode,
                billing_city_id,
                billing_country_id,
                phone,
                mobile,
                email,
            } = relation ? relation : {},
            { loader, t } = this.props;

        console.log("Personal - props ->", this.props);
        console.log("Personal - relation ->", relation);
        console.log("Personal - prov_street ->", prov_street);
        console.log("Personal - business_details ->", business_details);
        console.log("Personal - billing_address ->", billing_address);
        console.log("Personal - billing_address_selected ->", billing_address_selected);
        console.log("Personal - is_business ->", is_business);
        console.log("Personal - isBusiness ->", isBusiness);

        return (
            <>
                {isBusiness === 1 &&
                <div className="offer-business pt-1" key="offer-business-details" id="offer-business-details">
                    <h5 className="pb-2 pl-2 offer-sub-heading-business">
                        Business details
                    </h5>
                    <div id="offer-business-data-form" className="offer-business-data-form">
                        <Row>
                            <Col>
                                <span className="label">Company name</span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs="12" sm="12" md="12" lg="12" className="pb-0 pt-1">
                                <Row className="g-1 mb-2">
                                    <Col>
                                        <Input
                                            id="company_name"
                                            name="company_name"
                                            placeholder="Company name"
                                            value={company_name ? company_name : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className="label">Reg no.</span>
                            </Col>
                            <Col className="pl-1">
                                <span className="label">Vat no.</span>
                            </Col>
                        </Row>
                        <Row className="mb-0">
                            <Col xs="12" sm="12" md="12" lg="12" className="pb-0 pt-1">
                                <div className="form-row g-1 mb-0">
                                    <Col className="pb-1">
                                        <Input
                                            id="reg_no"
                                            name="reg_no"
                                            placeholder="Registration number"
                                            value={reg_no ? reg_no : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col className="pb-1">
                                        <Input
                                            id="vat_no"
                                            name="vat_no"
                                            placeholder="VAT number"
                                            value={vat_no ? vat_no : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                }

                <div className="offer-personal" key="offer-personal-details">
                    <h5 className="pt-1 pb-2 pl-2 offer-sub-heading-personal">
                        Personal details
                    </h5>
                    <div id="offer-personal-data-form" className="offer-personal-data-form">
                        <Row>
                            <Col>
                                <span className="label">Gender</span>
                            </Col>
                            <Col className="pl-1">
                                <span className="label">Title</span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs="12" sm="12" md="12" lg="12" className="pb-0 pt-1">
                                <div className="form-row g-1 mb-2">
                                    <Col className="pb-1">
                                        <Select
                                            id="gender"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Gender"
                                            options={genderOpts}
                                            value={gender ? gender : null}
                                            onChange={this.handleSelectChange.bind(
                                                this,
                                                "gender"
                                            )}
                                            maxMenuHeight={300}
                                        />
                                    </Col>
                                    <Col className="pb-1">
                                        <Select
                                            id="title"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Title"
                                            options={titleOpts}
                                            value={title ? title : null}
                                            onChange={this.handleSelectChange.bind(
                                                this,
                                                "title"
                                            )}
                                            maxMenuHeight={300}
                                        />
                                    </Col>
                                </div>
                                <div className="form-row">
                                    <Col xs="12" sm="12" md="12" lg="12" className="pb-1">
                                        <span className="label">Name</span>
                                    </Col>
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-1">
                                        <Input
                                            id="first_name"
                                            name="first_name"
                                            placeholder="First name"
                                            value={first_name ? first_name : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col xs="12" sm="3" md="3" lg="3" className="pb-1">
                                        <Input
                                            id="middle_name"
                                            name="middle_name"
                                            placeholder="Middle name"
                                            value={middle_name ? middle_name : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col xs="12" sm="5" md="5" lg="5" className="pb-1">
                                        <Input
                                            id="last_name"
                                            name="last_name"
                                            placeholder="Last name"
                                            value={last_name ? last_name : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className="label">Date of birth</span>
                            </Col>
                            <Col className="pl-1">
                                <span className="label">Language</span>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="pb-0 pt-1">
                                <div className="form-row g-1">
                                    <Col className="pb-1">
                                        <DatePicker
                                            locale={this.getLocale()}
                                            id="birthdate"
                                            wrapperClassName="w-100"
                                            className="form-control"
                                            name="birthdate"
                                            placeholderText="Date of birth"
                                            dateFormat={t("date_format_raw")}
                                            autoComplete="off"
                                            dateFormatCalendar="MMMM"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            maxDate={new Date()}
                                            yearDropdownItemNumber={150}
                                            scrollableYearDropdown={true}
                                            selected={birthdate ? new Date(birthdate) : ""}
                                            onChange={this.handleDatePickerChange.bind(
                                                this,
                                                "birthdate"
                                            )}
                                            onKeyDown={(e) =>
                                                this.handleKeyDown(e, "birthdate")
                                            }
                                        />
                                    </Col>
                                    <Col className="pb-1">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Language"
                                            options={languageOpts}
                                            value={language_id ? language_id : null}
                                            onChange={this.handleSelectChange.bind(
                                                this,
                                                "language_id"
                                            )}
                                            maxMenuHeight={300}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        {isBusiness === 0 &&
                        <Row>
                            <Col>
                                <CustomInput
                                    id="is_business"
                                    className="mt-0"
                                    type="checkbox"
                                    name="is_business"
                                    label="Business"
                                    //defaultChecked={is_business}
                                    defaultChecked={business_details}
                                    //defaultChecked={is_business && is_business !== '0'}
                                    //defaultChecked={this.state.business_details && this.state.business_details === true}
                                    onChange={(e) => this.handleBusinessCheckChange(
                                        e)}
                                />
                            </Col>
                        </Row>}
                    </div>

                    {isBusiness === 0 && business_details && business_details === true &&
                    <div className="offer-business pt-1" key="offer-business-details" id="offer-business-details">
                        <h5 className="pb-2 pl-2 offer-sub-heading-business">
                            Business details
                        </h5>
                        <div id="offer-business-data-form" className="offer-business-data-form">
                            <Row>
                                <Col>
                                    <span className="label">Company name</span>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col xs="12" sm="12" md="12" lg="12" className="pb-0 pt-1">
                                    <Row className="g-1 mb-2">
                                        <Col>
                                            <Input
                                                id="company_name"
                                                name="company_name"
                                                placeholder="Company name"
                                                value={company_name ? company_name : ""}
                                                onChange={(e) => this.handleInputChange(e)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="label">Reg no.</span>
                                </Col>
                                <Col className="pl-1">
                                    <span className="label">Vat no.</span>
                                </Col>
                            </Row>
                            <Row className="mb-0">
                                <Col xs="12" sm="12" md="12" lg="12" className="pb-0 pt-1">
                                    <div className="form-row g-1 mb-0">
                                        <Col className="pb-1">
                                            <Input
                                                id="reg_no"
                                                name="reg_no"
                                                placeholder="Registration number"
                                                value={reg_no ? reg_no : ""}
                                                onChange={(e) => this.handleInputChange(e)}
                                            />
                                        </Col>
                                        <Col className="pb-1">
                                            <Input
                                                id="vat_no"
                                                name="vat_no"
                                                placeholder="VAT number"
                                                value={vat_no ? vat_no : ""}
                                                onChange={(e) => this.handleInputChange(e)}
                                            />
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    }

                    <h5 className="pt-1 pb-2 pl-2 offer-sub-heading-address">
                        Address details
                    </h5>
                    <div id="offer-personal-provisioning-address" className="offer-personal-provisioning-address" style={this.state.billing_address && this.state.billing_address === true ? { marginBottom: "-25px" } : {}}>
                        <Row>
                            <Col>
                                <span className="label">Provisioning address</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pb-0">
                                <div className="form-row g-1">
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-0 pt-1">
                                        <Input
                                            id="prov_street"
                                            name="prov_street"
                                            placeholder="Street"
                                            value={prov_street ? prov_street : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={!!prov_street}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" className="pb-0 pt-1">
                                        <Input
                                            id="prov_house_number"
                                            name="prov_house_number"
                                            placeholder="House no."
                                            value={prov_house_number ? prov_house_number : ""}
                                            maxLength="10"
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={!!prov_house_number}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" className="pb-0 pt-1">
                                        <Input
                                            id="prov_house_number_letter"
                                            name="prov_house_number_letter"
                                            placeholder="House letter"
                                            value={prov_house_number_letter ? prov_house_number_letter : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={!!prov_street}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" className="pb-0 pt-1">
                                        <Input
                                            id="prov_house_number_suffix"
                                            name="prov_house_number_suffix"
                                            placeholder="Suffix"
                                            value={
                                                prov_house_number_suffix ? prov_house_number_suffix : ""
                                            }
                                            maxLength="10"
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={!!prov_house_number || !!prov_house_number_suffix}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" className="pb-0 pt-1">
                                        <Input
                                            id="prov_house_number_room"
                                            name="prov_house_number_room"
                                            placeholder="Room"
                                            value={
                                                prov_house_number_room ? prov_house_number_room : ""
                                            }
                                            maxLength="10"
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={!!prov_house_number || !!prov_house_number}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pb-0 pt-1">
                                <div className="form-row g-1">
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-1">
                                        <Input
                                            id="prov_zipcode"
                                            name="prov_zipcode"
                                            placeholder="Postal code"
                                            value={prov_zipcode ? prov_zipcode : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                            disabled={!!prov_zipcode}
                                        />
                                    </Col>
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-1">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder={
                                                prov_country_id && !loader
                                                    ? "City"
                                                    : "Select country first"
                                            }
                                            options={prov_city_opts}
                                            value={prov_city_id ? prov_city_id : null}
                                            onChange={this.handleSelectChange.bind(this, "prov_city_id")}
                                            maxMenuHeight={300}
                                            isDisabled={!!prov_city_id}
                                        />
                                    </Col>
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-1">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Country"
                                            options={countryOpts}
                                            value={prov_country_id ? prov_country_id : null}
                                            onChange={this.handleSelectChange.bind(
                                                this,
                                                "prov_country_id"
                                            )}
                                            maxMenuHeight={300}
                                            isDisabled={!!prov_country_id}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <div className="form-row mb-0 mt-3">
                            <Col xs="12" sm="12" md="12" lg="12" className="pb-1">
                                <a
                                    className={`group-button offer-btn-link-billing-address`}
                                    key={`btn-other-billing-address`}
                                    onClick={(e) => this.clickButtonCollapseBillingAddress(e, "personal-billing-address", billing_address)}
                                >
                                    <FontAwesomeIcon name="step-back-icon" icon={this.state.billing_address_icon} />
                                    <span className="ml-1">Other billing address</span>
                                </a>
                            </Col>
                        </div>
                    </div>

                    {billing_address && billing_address === true &&
                    <div id="offer-personal-billing-address" className="offer-personal-billing-address">
                        {locationAlertContent && locationAlertContent.length > 0 &&
                        <Row>
                            <Col>
                                <Alert color="warning" style={{ flexDirection: "column" }}>
                                    Multiple locations found:<br />
                                    {
                                        locationAlertContent.map((item, i) => {
                                            return (
                                                <div
                                                    key={`location-alert-item-${i}`}
                                                    style={{ display: "block" }}
                                                >
                                                    - Select{' '}
                                                    <a
                                                        className="alert-link"
                                                        onClick={(e) => this.selectLocation(e, i)}
                                                        rel="noreferrer"
                                                        key={`location-alert-item-link-${i}`}
                                                    >
                                                        {item.street1} {item.house_number} {item.house_number_suffix ? item.house_number_suffix : ""}
                                                    </a> as your billing location.
                                                    <br />
                                                </div>
                                            )
                                        })
                                    }
                                </Alert>
                            </Col>
                        </Row>
                        }
                        <Row>
                            <Col>
                                <span className="label">Billing address</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pb-0">
                                <div className="form-row g-1">
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-0 pt-1">
                                        <Input
                                            id="billing_street"
                                            name="billing_street"
                                            placeholder="Street"
                                            value={billing_street ? billing_street : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" className="pb-0 pt-1">
                                        <Input
                                            id="billing_house_number"
                                            name="billing_house_number"
                                            placeholder="House no."
                                            value={billing_house_number ? billing_house_number : ""}
                                            maxLength="10"
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" className="pb-0 pt-1">
                                        <Input
                                            id="billing_house_number_letter"
                                            name="billing_house_number_letter"
                                            placeholder="House letter"
                                            value={billing_house_number_letter ? billing_house_number_letter : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" className="pb-0 pt-1">
                                        <Input
                                            id="billing_house_number_suffix"
                                            name="billing_house_number_suffix"
                                            placeholder="Suffix"
                                            value={
                                                billing_house_number_suffix ? billing_house_number_suffix : ""
                                            }
                                            maxLength="10"
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col xs="12" sm="2" md="2" lg="2" className="pb-0 pt-1">
                                        <Input
                                            id="billing_house_number_room"
                                            name="billing_house_number_room"
                                            placeholder="Room"
                                            value={
                                                billing_house_number_room ? billing_house_number_room : ""
                                            }
                                            maxLength="10"
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pb-0 pt-1">
                                <div className="form-row">
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-1">
                                        <Input
                                            id="billing_zipcode"
                                            name="billing_zipcode"
                                            placeholder="Postal code"
                                            value={billing_zipcode ? billing_zipcode : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-1">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder={
                                                billing_country_id && !loader
                                                    ? "City"
                                                    : "Select country first"
                                            }
                                            options={cityOpts}
                                            value={billing_city_id ? billing_city_id : null}
                                            onChange={this.handleSelectChange.bind(this, "billing_city_id")}
                                            maxMenuHeight={300}
                                            //isDisabled={!billing_country_id && !loader}
                                        />
                                    </Col>
                                    <Col xs="12" sm="4" md="4" lg="4" className="pb-1">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Country"
                                            options={countryOpts}
                                            value={billing_country_id ? billing_country_id : null}
                                            onChange={this.handleSelectChange.bind(
                                                this,
                                                "billing_country_id"
                                            )}
                                            maxMenuHeight={300}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    }

                    <h5 className="pt-1 pb-2 pl-2 offer-sub-heading-contact">
                        Contact details
                    </h5>
                    <div id="offer-personal-contact-details" className="offer-personal-contact-details">
                        <Row>
                            <Col>
                                <span className="label">Phone</span>
                            </Col>
                            <Col className="pl-1">
                                <span className="label">Mobile</span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="pb-0">
                                <div className="form-row g-1">
                                    <Col className="pb-0 pt-1">
                                        <Input
                                            id="phone"
                                            name="phone"
                                            placeholder="Phone"
                                            value={phone ? phone : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                    <Col className="pb-0 pt-1">
                                        <Input
                                            id="mobile"
                                            name="mobile"
                                            placeholder="Mobile"
                                            value={mobile ? mobile : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-0">
                            <Col>
                                <span className="label">Email address</span>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="12" className="pb-0 pt-1">
                                <Row className="g-1">
                                    <Col xs="12" sm="12" md="12" lg="12" className="pb-1">
                                        <Input
                                            id="email"
                                            name="email"
                                            placeholder="Email"
                                            value={email ? email : ""}
                                            onChange={(e) => this.handleInputChange(e)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (store) => ({
    loader: store.loader,
    user: store.user,
});

export default connect(mapStateToProps)(
    withTranslation()(withRouter(OfferPersonal))
);
