import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    GetAPIwithType = Api.getAPIwithType,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI,
    RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : GetAPI(endpoint, params)
  })()
};

const GetSubscriptions = (params) => {
  return asyncFunc(`subscriptions`, params)
};

const GetSubscriptionsRelation = (params) => {
  return asyncFunc(`subscriptions/summary${params}`)
};

const GetSubscriptionsSummary = (params) => {
  return asyncFunc(`subscriptions/summary`, params)
};

const GetSubscriptionsLinesSummary = (params) => {
  return asyncFunc(`subscription_lines/summary`, params)
};

const GetSubscription = (params, id) => {
  return asyncFunc(`subscriptions/${id}`)
};

const GetSubscriptionsCount = () => {
  return asyncFunc('subscriptions/count')
};

const GetLatestSubscriptions = () => {
  return asyncFunc('subscriptions/latest')
};

const AddSubscription = (params) => {
  return asyncFunc(`subscriptions`, params, PostAPI)
};

const UpdateSubscription = (id, params) => {
  return asyncFunc(`subscriptions/${id}`, params, UpdateAPI)
};

const RemoveSubscription = (id) => {
  return asyncFunc(`subscriptions/${id}`, null, RemoveAPI)
};

const GetSubscriptionLines = (params, id) => {
  return asyncFunc(`subscriptions/${id}/subscription_lines`, params)
};

const GetSubscriptionLine = (params, id) => {
  return asyncFunc(`subscription_lines/${id}`)
};

const AddSubscriptionLine = (id, params) => {
  return asyncFunc(`subscription_lines/${id}`, params, PostAPI)
};

const UpdateSubscriptionLine = (id, params) => {
  return asyncFunc(`subscription_lines/${id}`, params, UpdateAPI)
};

const RemoveSubscriptionLine = (id) => {
  return asyncFunc(`subscription_lines/${id}`, null, RemoveAPI)
};

const GetSubscriptionLineStatus = (id) => {
  return asyncFunc(`subscription_lines/${id}/status`)
};

const GetSubscriptionLineStatuses = () => {
  return asyncFunc('subscription_line_statuses')
};

const GetSubscriptionLinePrices = (params, id, id2) => {
  //return asyncFunc(`subscription/${id}/subscription_lines/${id2}/subscription_line_prices/`)
  return asyncFunc(`subscription_lines/${id2}/subscription_line_prices/`)
};

const GetSubscriptionLinePricesOptm = (param) => {
  if (!param) return { 'ok': false };
  //return asyncFunc(`prices/${param}/subscription_lines`)
  return asyncFunc(`subscription_lines/${param}/subscription_line_prices/`)
};

const GetSubscriptionLinePricesFormats = (id) => {
  if (!id) return { 'ok': false, 'error': 'No subscription line ID in param' };
  return asyncFunc(`prices/${id}/subscription_lines`)
};

const AddSubscriptionLinePrice = (params, id) => {
  return asyncFunc(`subscription_lines/${id}/subscription_line_prices`, params, PostAPI)
};

const UpdateSubscriptionLinePrice = (params, id) => {
  return asyncFunc(`subscription_line_prices/${id}`, params, UpdateAPI)
};

const RemoveSubscriptionLinePrice = (id) => {
  return asyncFunc(`subscription_line_prices/${id}`, null, RemoveAPI)
};

const GetPlanSubscriptionLineTypes = () => {
  return asyncFunc(`plan_subscription_line_types`)
};

const GetPersons = (params, id) => {
  return asyncFunc(`subscriptions/${id}/persons`, params)
};

const GetAddresses = (params, id) => {
  return asyncFunc(`subscriptions/${id}/addresses`, params)
};

const GetGadgets = (id) => {
  return asyncFunc(`subscription_lines/${id}/gadgets`)
};

const GetContractPeriodList = (params) => {
  return asyncFunc(`contract_periods/list`)
};

const GetNetworkOperatorList = (params) => {
  return asyncFunc(`network_operators/list`);
};

const GetNetworkOperators = () => {
  return asyncFunc(`network_operators`);
};

const GetOperators = () => {
  return asyncFunc(`operators`);
};

const GetStatusList = (statusTypeId) => {
  return asyncFunc(`statuses/${statusTypeId}/list`)
};

const GetSubscriptionStatuses = () => {
  return asyncFunc(`subscription_statuses`);
};

const GetSubscriptionPrices = (id) => {
  return asyncFunc(`prices/${id}/subscription`)
};

const GetSubscriptionPowerTools = (params) => {
  return asyncFunc(`power_tools/fixSubscription`, params, PostAPI)
};

export {
  //Subscriptions
  GetSubscriptions,
  GetSubscriptionsRelation,
  GetSubscriptionsSummary,
  GetSubscriptionsLinesSummary,
  GetSubscription,
  GetSubscriptionsCount,
  GetLatestSubscriptions,
  AddSubscription,
  UpdateSubscription,
  RemoveSubscription,

  //Subscription Lines
  GetSubscriptionLines,
  GetSubscriptionLine,
  AddSubscriptionLine,
  UpdateSubscriptionLine,
  RemoveSubscriptionLine,
  GetSubscriptionLineStatus,

  //Subscription Line Prices
  GetSubscriptionLinePricesOptm,
  GetSubscriptionLinePrices,
  GetSubscriptionLinePricesFormats,
  AddSubscriptionLinePrice,
  UpdateSubscriptionLinePrice,
  RemoveSubscriptionLinePrice,

  //Others
  GetPlanSubscriptionLineTypes,
  GetPersons,
  GetAddresses,
  GetGadgets,
  GetContractPeriodList,
  GetStatusList,
  GetSubscriptionStatuses,
  GetNetworkOperatorList,
  GetNetworkOperators,
  GetOperators,
  GetSubscriptionPrices,
  GetSubscriptionPowerTools,
  GetSubscriptionLineStatuses
}