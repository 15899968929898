import axios from "axios";
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.js';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css'; //mapbox-gl-directions.css
import mapboxgl, { accessToken } from "mapbox-gl";

// Define your Mapbox access token here
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic3RhbmxleWJhdGVzd2FyIiwiYSI6ImNsdnFvejJxdzBkZjkya21zdWZ1dXF6NGoifQ.O0tzH8bpKmS2ulMoRRQBfQ';
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const directions = new MapboxDirections({
  accessToken: mapboxgl.accessToken,
  unit: 'metric',
  profile: 'mapbox/driving',
  controls: { instructions: true }
});

export const CalcRouteDirection = async (origin, destination, mapComponent, mapBox, map, locationAddress) => {
  console.log("InitMap - CalcRouteDirection -> clicked!");

  try {
    if (mapBox) {
      console.log('InitMap - CalcRouteDirection - origin ->', origin); // Log the map instance for debugging
      console.log('InitMap - CalcRouteDirection - destination ->', destination); // Log the map instance for debugging
      console.log('InitMap - CalcRouteDirection - mapComponent instance ->', mapComponent); // Log the map instance for debugging
      console.log('InitMap - CalcRouteDirection - mapBox instance ->', mapBox); // Log the map instance for debugging
      console.log('InitMap - CalcRouteDirection - map instance ->', map); // Log the map instance for debugging    
      console.log('InitMap - locationAddress', locationAddress);
      console.log('InitMap - test');


      //const map = mapBox;
      //console.log('InitMap - CalcRouteDirection - map (getMap) ->', map); // Log the map instance for debugging

      const { locationLat, locationLng } = origin;

      // Encode the destination address
      const encodedDestination = encodeURIComponent(destination)

      // Log the Geocoding API request
      console.log("InitMap - CalcRouteDirection - Geocoding API Request:", `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedDestination}.json`);

      // Get the coordinates for the origin and destination using Mapbox Geocoding API
      const geocodingResponse = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedDestination}.json`,
        {
          params: {
            access_token: mapboxgl.accessToken,
            limit: 1,
          },
        }
      );

      // Log the Geocoding API response
      console.log("InitMap - CalcRouteDirection - Geocoding API Response:", geocodingResponse.data);
      console.log("InitMap - CalcRouteDirection - accesToken", mapboxgl.accessToken);

      const originCoordinates = origin;
      const destinationCoordinates = geocodingResponse.data.features[0].geometry.coordinates;

      console.log("InitMap - CalcRouteDirection - originCoordinates - data -> ", originCoordinates);
      console.log("InitMap - CalcRouteDirection - destinationCoordinates - data ->", destinationCoordinates);

      // Calculate the route using Mapbox Directions API
      const response = await axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${locationLng},${locationLat};${destinationCoordinates[0]},${destinationCoordinates[1]}`,
        {
          params: {
            access_token: mapboxgl.accessToken,
            steps: true,
            geometries: "geojson",
          },
        }
      );

      const routeGeometry = response.data.routes[0].geometry;

      console.log("InitMap - CalcRouteDirection - This is response ->", response);
      console.log("InitMap - CalcRouteDirection - This is map ->", map);

      let checkMapLayer = mapBox.getLayer('route');
      console.log("InitMap - CalcRouteDirection - checkMapLayer ->", checkMapLayer);
      if (mapBox.getLayer('route')) {
        mapBox.removeLayer("route");
      }
      if (mapBox.getSource('route')) {
        mapBox.removeSource("route");
      }

      const marker = new mapboxgl.Marker()
        .setLngLat([destinationCoordinates[0], destinationCoordinates[1]])
        .addTo(mapBox); // add the marker to the map

      // Add the route layer to the map
      mapBox.addLayer({
        id: 'route',
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: routeGeometry,
          },
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#3887be',
          'line-width': 5,
        },
      });

      mapBox.addControl(directions, 'top-left');

      // Set origin and destination coordinates in the inputs
      const originInput = document.querySelector('#mapbox-directions-origin-input input');
      const destinationInput = document.querySelector('#mapbox-directions-destination-input input');

      // Set the value of originInput and destinationInput
      originInput.value = destination;
      destinationInput.value = `${locationAddress.street1} ${locationAddress.house_number} ${locationAddress.zipcode}`;

      // Trigger route calculation to show instructions
      directions.setOrigin(originInput.value);
      directions.setDestination(destinationInput.value);
      // directions.query();

      mapBox.flyTo({
        center: [destinationCoordinates[0], destinationCoordinates[1]],
        //zoom: 11,
        //speed: 0.2,
        curve: 1,
        easing(t) {
          return t;
        }
      });

      // Ensure the map is re-rendered
      mapBox.resize();

      // Check if the response contains routes
      if (response.data.routes.length > 0) {
        // Extract route information from the response
        const route = response.data.routes[0];
        const { distance, duration, geometry } = route;

        console.log("InitMap - CalcRouteDirection - Directions API Response:", response.data);
        console.log("InitMap - CalcRouteDirection - Route Geometry:", geometry);
        // Return the route information
        return {
          distance,
          duration,
          geometry,
        };

      } else {
        // No routes found
        throw new Error("InitMap - CalcRouteDirection - No routes found");
      }
    }

  } catch (error) {
    console.error("InitMap - CalcRouteDirection - Error calculating route direction:", error);
    throw error;
  }
};
