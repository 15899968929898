import React from "react";
import { Check } from "react-feather";
import BankAccountsForm from "./Form";
import { GetBankAccounts } from '../../../controllers/relations';
import ParentTable from '../../../components/ParentTable';
import CONSTANTS from "../../../services/constants";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {formatIBAN} from '../../../redux/actions/formatActions';
import {Badge} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";

class BankAccount extends React.Component {
    defaultFormatter = (cell) => {
        return (
            <React.Fragment>
                {cell === 1 &&
                    <Check size={18} color="#5fc27e" className="check" />
                }
            </React.Fragment>
        )
    };

    formatNrIBAN = (cell) => {
      if (cell) {
        const iBan = formatIBAN(cell);
        return iBan;
      }
    };

    formatDate = (cell) => {
      if (cell)
        return <span className="date-format">{this.props.t("date_format", { date: new Date(cell) })}</span>
    };

    formatEmpty = (cell) => {
      if (cell) {
        return cell;
      } else {
        return <span className="null-value">{String.fromCharCode(8212)}</span>
      }
    };

    formatStatus = (cell) => {
      return cell !== null && cell !== undefined && cell !== '' ?
          <Badge color={ (cell.toString()) === "Active" ? 'success' : 'warning' } className="badge-pill mr-1 mb-1 text-uppercase" >
            { cell.toString() }
          </Badge> : String.fromCharCode(8212)
    };

    render() {
        const { id, titleUpdate } = this.props;

        return (
            <React.Fragment>
              {titleUpdate && <Helmet>
                <title>{titleUpdate}</title>
              </Helmet>}
              <ParentTable
                id={id}
                table="RelationBankAccounts"
                data={GetBankAccounts}
                columns={[
                    {
                      dataField: "iban",
                      text: "IBAN",
                      sort: true,
                      formatter: this.formatNrIBAN,
                      headerStyle: () => {
                          return { 'minWidth': '200px', width: '25%' }
                      },
                      attrs: {
                        'data-label': 'IBAN'
                      }
                    }, {
                      dataField: "description",
                      text: "Account holder",
                      sort: true,
                      formatter: this.formatEmpty,
                      headerStyle: () => {
                          return { width: '23%'};
                      },
                      attrs: {
                        'data-label': 'Account holder'
                      }
                    }, {
                      dataField: "dd_default",
                      text: "Direct debit",
                      textAlign: "center",
                      formatter: this.defaultFormatter,
                      sort: true,
                      classes: 'text-center primary-label',
                      headerStyle: () => {
                          return { width: '10%', 'textAlign': 'center' }
                      },
                      attrs: {
                        'data-label': 'Direct debit'
                      }
                    }, {
                      dataField: "status_label",
                      text: "Status",
                      sort: false,
                      formatter: this.formatStatus,
                      headerStyle: () => {
                          return { width: '12%'}
                      },
                      attrs: {
                        'data-label': 'Status'
                      }
                    }, {
                      dataField: "mndt_id",
                      text: "Mandate",
                      sort: false,
                      headerStyle: () => {
                          return { width: '13%'}
                      },
                      attrs: {
                        'data-label': 'Mandate'
                      }
                    }, {
                      dataField: "dt_of_sgntr",
                      text: "Date of signature",
                      sort: false,
                      formatter: this.formatDate,
                      headerStyle: () => {
                          return { width: '17%'};
                      },
                      attrs: {
                        'data-label': 'Date of signature'
                      }
                    }
                ]}
                rowAction="edit"
                rowActionForm={BankAccountsForm}
                headerDropdownOpts={[{
                    label: <FontAwesomeIcon color="grey" icon={faPlus} />,
                    action: BankAccountsForm
                }]}
                noSearch
                wrapperClasses="table-bank-accounts"
                pageHeadingTitle="Relation"
                tab={CONSTANTS.relationDetailsTabs["bank-accounts"]}
                //offLoader
              />
          </React.Fragment>
        )
    }
}

export default withRouter(withTranslation('translations')(connect()(BankAccount)));