import React, {useState, useRef, useEffect} from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Row,
  Col,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  NavItem,
  NavLink,
  Nav,
  TabPane,
  TabContent, UncontrolledCollapse,
} from 'reactstrap';
import {
  GetProducts,
  AddProductHierarchy,
  UpdateProductHierarchy,
} from '../../../controllers/products';
import {OverlayTrigger, Tooltip, Dropdown} from 'react-bootstrap';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate, validateBarcode } from "../../../redux/actions/validateActions";
import {
  formatNumber,
  parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import { Collapse } from "react-bootstrap";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import {
  PlusSquare,
  MinusSquare,
  MoreHorizontal,
  Trash2, CornerDownRight,
} from 'react-feather';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilter,
  faTrash,
  faSortAmountDown,
  faSortAmountUp,
  faSlidersH,
} from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select';
import Swal from "sweetalert2";

const AddChildRelatedProduct = ({ ...props }) => {
  const {
    productId, // main product id
    index,
    dispatch,
    loader,
    hide,
    update,
    debug,
    itemRelatedProductId,
    subItemRelatedProductId,
    relationTypeId,
    productsListOpts,
    productRestHierarchiesOpts,
    productHierarchyRelationTypesOpts,
    productHierarchyRelationTypesChildOpts,
    addFormCollapse,
    updateRelatedChildProducts,
    relationTypeSelected,
    setAddFormCollapse,
    setJsonDataFormCollapse,
  } = props;

  const addChildProductFormRef = useRef(null);
  const addChildProductFormSelectChildRef = useRef(null);
  const addChildProductFormSelectTypeRef = useRef(null);
  const collapsibleAddChildId = subItemRelatedProductId ? "dropdown-item-add-" + productId + "-" + itemRelatedProductId +  "-" + subItemRelatedProductId + "-" + index : "dropdown-item-add-" + productId + "-" + itemRelatedProductId + "-" + index;
  const addChildFormId = subItemRelatedProductId ? productId + "_" + itemRelatedProductId + "_" + subItemRelatedProductId + "_" + index : productId + "_" + itemRelatedProductId + "_" + index;
  const relationTypeData = {};

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [relateToSelectedProduct, setRelateToSelectedProduct] = useState(null);

  const [addFormCollapsed, setAddFormCollapsed] = useState(false);
  const [relationTypeValue, setRelationTypeValue] = useState(null);
  const [relationTypeOpts, setRelationTypeOpts] = useState([]); // productsListOpts (all products) || productRestHierarchiesOpts
  const [relationProductValue, setRelationProductValue] = useState(null);

  const handleSelectTypeChange = (e) => {
    if (e.value > 2) {
      setRelationTypeOpts(productRestHierarchiesOpts);
    } else {
      setRelationTypeOpts(productsListOpts);
    }

    setRelationTypeValue({
      label: e.label,
      value: e.value
    });
  };

  const handleSelectProductChange = (e) => {
    setRelationProductValue({
      label: e.label,
      value: e.value
    });
  };

  const loadProducts = (value, callback) => {
    const search = 'search',
        offset = 1000,
        params = { search: value, offset: offset };

    (async () => {
      const { ok, data } = await GetProducts(params);
      if (ok && data) {
        let products = [];
        data.data.map(({ description, id }) => {
          // filter loadProducts without -> activeProductId & activeProductChildId
          if (itemRelatedProductId && subItemRelatedProductId) {
            if (parseInt(id) !== parseInt(itemRelatedProductId) && parseInt(id) !== parseInt(subItemRelatedProductId)) {
              return products.push({
                label: description,
                value: id
              });
            }
          } else if (itemRelatedProductId && !subItemRelatedProductId) {
            if (parseInt(id) !== parseInt(itemRelatedProductId)) {
              return products.push({
                label: description,
                value: id
              });
            }
          } else {
            return products.push({
              label: description,
              value: id
            });
          }
        });
        callback(products);
      }
    })();
  };

  const addChildProductToHierarchyRelation = (e) => {
    let notificationsToastArr = [];

    // check if related product - relationProductValue
    if (!relationProductValue) {
      notificationsToastArr.push({ title: 'No related product selected.', icon: 'warn' });
    }
    // check related product type - relationTypeValue
    if (!relationTypeValue) {
      notificationsToastArr.push({ title: 'No relation type selected.', icon: 'warn' });
    }
    // show validation errors
    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    let productRelations = Object.assign({});
    let relatedProductId = relationProductValue.value;
    productRelations.id = subItemRelatedProductId ? subItemRelatedProductId : itemRelatedProductId ? itemRelatedProductId : productId; // parent is the active product ID (activeProductId)
    productRelations.related_product_id = relatedProductId;
    productRelations.relation_type = relationTypeValue.value;

    if (!loader) {
      dispatch(loaderToggle(true));
      (async () => {
        const res = await AddProductHierarchy(productRelations.id, productRelations.related_product_id, productRelations);
        if (res && res.ok) {
          //console.log("addProductHierarchyRelation - after ok");
          await updateRelatedChildProducts();

          setRelationTypeValue("");
          setRelationProductValue("");

          notificationsToastArr.push({
            title: res && res.data && res.data.message ? res.data.message : "Product hierarchy updated!",
            icon: "success",
          });
        } else {
          notificationsToastArr.push({
            title: "Error saving data",
            icon: "error",
          });
        }
        await handleOk(notificationsToastArr);

      })();
    }
  };

  const handleOk = async (options) => {
    dispatch(loaderToggle(false));

    const { update } = props;

    if (options && options.length > 0) {
      update();
      return ToastMulti(options, "container-validation-id");
    }
    return false;
  };

  const getFormCollapseState = (formId) => {
    if (addFormCollapse && addFormCollapse.length > 0) {
      const formItem = addFormCollapse.find(
          item => item.collapsibleAddChildId === formId);

      if (formItem) {
        return formItem.open;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (relationTypeSelected) {
      if (relationTypeSelected === "mutual") {
        setRelationTypeOpts(productRestHierarchiesOpts);
      } else {
        setRelationTypeOpts(productsListOpts);
      }
    }
  }, [relationTypeSelected]);

  return (
      <React.Fragment>
        <div className="product-hierarchy" ref={addChildProductFormRef}>
          <Collapse in={getFormCollapseState(collapsibleAddChildId)}>
            <div className="form-group row mt-1 mb-2 form-hierarchy add-child-relation-hierarchy">
              <div className="add-relation-hierarchy-wrapper shadow">
                <span style={{ display: debug, fontSize: "x-small" }}>
                  Form for ProductID {productId} |
                  ItemID {itemRelatedProductId} | SubItemID {subItemRelatedProductId ? subItemRelatedProductId : "Not set"} |
                  FormID = {addChildFormId}
                </span>
                <div className="form-group mb-1">
                  <AsyncSelect
                    ref={addChildProductFormSelectChildRef}
                    cacheOptions
                    options={relationTypeOpts} // productsListOpts
                    loadOptions={loadProducts}
                    getOptionLabel={({ label, value }) => {
                      return `${label ? label : String.fromCharCode(8212)}`
                    }}
                    id={`new_product_id-${addChildFormId}`}
                    name={`new_product_id-${addChildFormId}`}
                    className="react-select-container mb-1"
                    classNamePrefix="react-select"
                    placeholder={`Add child product`}
                    value={relationProductValue ? relationProductValue : ""}
                    onChange={(e) => handleSelectProductChange(e)}
                    maxMenuHeight={300}
                  />
                </div>
                <div className="form-group mb-1">
                  <Select
                    ref={addChildProductFormSelectTypeRef}
                    id={`new_product_relation_type-${addChildFormId}`}
                    name={`new_product_relation_type-${addChildFormId}`}
                    className="react-select-container mb-1"
                    classNamePrefix="react-select"
                    options={productHierarchyRelationTypesChildOpts} // productHierarchyRelationTypesOpts
                    placeholder={`Select (parent-child) relation type`}
                    value={relationTypeValue}
                    onChange={(e) => handleSelectTypeChange(e)}
                    maxMenuHeight={300}
                  />
                </div>
                <div className="form-group mb-0 d-flex justify-content-between">
                  <Button
                      type="button"
                      color="danger"
                      className="mt-1 cancel-related-child-product-btn btn btn-default btn-sm"
                      onClick={(e) => setAddFormCollapse([])}
                  >
                    Cancel
                  </Button>
                  <Button
                      className="mt-1 submit-related-child-product-btn btn btn-default btn-sm"
                      onClick={(e) => addChildProductToHierarchyRelation(e)}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <div className="icon-wrapper-children">
                <CornerDownRight
                    color={`grey`}
                    size="18"
                    className="hover-pointer mt-0 mb-1 ml-2 product-border-list-plus"
                />
              </div>
            </div>
          </Collapse>
        </div>
      </React.Fragment>
  )
};

const mapStateToProps = ({ dispatch, loader }) => ({ dispatch, loader });
export default connect(mapStateToProps)(AddChildRelatedProduct);
