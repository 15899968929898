import React from "react";
import { connect } from "react-redux";
import { DeleteUser } from '../../../controllers/users';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import Toast from "../../../components/Toast";
import Swal from "sweetalert2";

class DeleteUserForm extends React.Component {
    componentDidMount() {
        const { dispatch, loader, selectedData: { user : { id }}, hide, update } = this.props;

        Swal.fire({
            title: 'Please confirm!',
            text: 'Are you sure you want to delete this persons user login?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            reverseButtons: true
        }).then(res => {
            if (res.value) {
                if (!loader) {
                    (async () => {
                        dispatch(loaderToggle(true));

                        const { ok } = await DeleteUser(id);
                        if (ok) {
                            dispatch(loaderToggle(false));
                            update('updateperson');

                        } else {
                            await Toast.fire({
                                title: 'Something went wrong!',
                                icon: 'warning'
                            })
                        }

                        dispatch(loaderToggle(false));
                        hide();

                    })();
                }
            } else {
                update('updateperson');
                hide();
            }
        })
    }

    render() {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(DeleteUserForm);
