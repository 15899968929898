import React from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import Swal from "sweetalert2";
import {loaderToggle} from '../../../redux/actions/loaderActions';
import {RemoveTermCondition} from '../../../controllers/terms_conditions';
import {withTranslation} from 'react-i18next';

class RemoveTermConditionForm extends React.Component {
  componentDidMount() {
    const { loader, selectedData, dispatch, update, hide } = this.props;

    Swal.fire({
      customClass: {
        container: 'has-cancel',
      },
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this term and condition?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(res => {
      if (res.value) {
        if (!loader) {
          (async () => {
            dispatch(loaderToggle(true));

            const getData = await RemoveTermCondition(selectedData.id);
            console.log("getData ->", getData);
            if (getData.ok) {

              dispatch(loaderToggle(false));
              await Swal.fire('Success!', 'Removed successfully',
                  'success');
              update();
              hide();
            } else {
              dispatch(loaderToggle(false));
              hide();
            }
          })();
        }
      } else {
        hide();
      }
    })
  }

  render() {
    return (
        <React.Fragment>
        </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(connect()(RemoveTermConditionForm)));
