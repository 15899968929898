import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI,
    DownloadAPI = Api.downloadAPI,
    DownloadPreviewAPI = Api.downloadPreviewAPI,
    RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : await GetAPI(endpoint, params);
  })();
};

const GetTermsConditions = (params) => {
  return asyncFunc(`/terms_conditions`, params);
};

const GetTermCondition = (params, id) => {
  return asyncFunc(`/terms_conditions/${id}`, params);
};

const AddTermCondition = (params) => {
  return asyncFunc(`/terms_conditions`, params, PostAPI);
};

const UpdateTermCondition = (id, params) => {
  return asyncFunc(`/terms_conditions/${id}`, params, UpdateAPI);
};

const RemoveTermCondition = (id) => {
  return asyncFunc(`/terms_conditions/${id}`, null, RemoveAPI);
};

const GetTermConditionAttachments = (terms_conditions_id, params) => {
  return asyncFunc(`/terms_conditions/${terms_conditions_id}/attachments`, params);
};

const DownloadSingleTermsConditionsAttachment = (terms_conditions_id, attachment_id, filename) => {
  return asyncFunc(`/terms_conditions/${terms_conditions_id}/attachments/${attachment_id}/${filename}`, filename, DownloadAPI);
};

//downloadPreviewAPI
const DownloadPreviewSingleTermsConditionsAttachment = (terms_conditions_id, attachment_id, filename) => {
  return asyncFunc(`/terms_conditions/${terms_conditions_id}/attachments/${attachment_id}/${filename}`, filename, DownloadPreviewAPI);
};

export {
  GetTermsConditions,
  GetTermCondition,
  AddTermCondition,
  UpdateTermCondition,
  RemoveTermCondition,

  GetTermConditionAttachments,
  DownloadSingleTermsConditionsAttachment,
  DownloadPreviewSingleTermsConditionsAttachment
}
