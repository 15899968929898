import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI,
    RemoveAPI = Api.removeAPI,
    GetAPIwithType = Api.getAPIwithType;

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        return await method ? method(endpoint, params) : await GetAPI(endpoint,
            params)
    })()
};

const GetMyTenants = (params) => {
    return asyncFunc(`mytenants`, params)
};

const GetTenant = (params, id) => {
    return asyncFunc(`tenants/${id}`, params)
};

const GetTenantUsers = (params, id) => {
    return asyncFunc(`users/tenant/${id}`, params)
};

const GetTenantsCount = () => {
    return asyncFunc('tenants/count')
};

const GetTenantLists = () => {
    return asyncFunc(`tenants/list`)
};

const GetTenantBillingRunDates = (id, statusId) => {
    return asyncFunc(`admin_tools/billing_run_dates/${statusId}`)
};

const GetAllTenantBillingRunDates = (params) => {
    if (params) {
        if (params.page && !params.sort) {
            params.sort = '-date'
        }
    }
    return asyncFunc(`admin_tools/billing_run_dates`, params)
};

const GetAllTenantBillingRunDatesSummary = (params) => {
    return asyncFunc(`billing_runs/summary`, params)
};

const RemoveBillingRunDate = (id) => {
    return asyncFunc(`/billing_runs/${id}`, null, RemoveAPI)
};

const GetInvoiceStats = (params) => {
    return asyncFunc(`tenants/invoice_stats`, params, PostAPI)
};

const CreatePainDDFile = (params) => {
    return asyncFunc(`tenants/create_pain_dd_file`, params, PostAPI)
};

const GenerateInvoicesQueueJob = (params) => {
    return asyncFunc(`admin_tools/generate_invoices`, params, PostAPI)
};

const SendInvoiceEmailsQueueJob = (params) => {
    return asyncFunc(`admin_tools/send_invoice_emails`, params, PostAPI)
};

const ValidateIBANs = (tenantId) => {
    return asyncFunc(`tenants/finance/validate_ibans/${tenantId}`, 'blob', GetAPIwithType)
};

const GenerateInvoicesConcept = (params) => {
    return asyncFunc(`/admin_tools/finalize_invoices`, params, PostAPI)
};

const AddTenant = (params) => {
    return asyncFunc(`tenants`, params, PostAPI)
};

const UpdateTenant = (id, params) => {
    return asyncFunc(`tenants/${id}`, params, UpdateAPI)
};

const GetGroupDependencies = () => {
    return asyncFunc(`dependencies/groups`)
};

const GetGroupList = (params, id) => {
    return asyncFunc(`tenants/${id}/groups`, params)
};

const SwitchTenant = (id) => {
    return asyncFunc(`tenants/${id}/switch`)
};

const AddGroup = (params, id) => {
    return asyncFunc(`tenants/${id}/createGroup`, params, PostAPI)
};

const UpdateGroup = (params, id, id2) => {
    return asyncFunc(`tenants/${id}/groups/${id2}`, params, UpdateAPI)
};

const GetBrand = (params, id) => {
    return asyncFunc(`brands/tenant/${id}`, params)
};

const AddBrand = (params) => {
    return asyncFunc(`brands`, params, PostAPI)
};

const UpdateBrand = (params, id) => {
    return asyncFunc(`brands/${id}`, params, UpdateAPI)
};

const GetProductsList = (params) => {
    return asyncFunc(`products/list`, params)
};

const GetProducts = (params) => {
    return asyncFunc(`tenants/products`, params)
};

/* Email tenant templates - email_templates/tenant/{tenant_id} */
const GetTenantEmailTemplates = (params, id) => {    
    return asyncFunc(`email_templates/tenant/${id}`, params)
};

const GetTenantEmailTemplate = (id) => {
    return asyncFunc(`email_templates/${id}`)
};

const CreateTenantEmailTemplate = (params) => {
    return asyncFunc(`email_templates`, params, PostAPI)
};

const UpdateTenantEmailTemplate = (params, id) => {
    return asyncFunc(`email_templates/${id}`, params, UpdateAPI)
};

const RemoveTenantEmailTemplate = (params, id) => {
    return asyncFunc(`email_templates/${id}`, params, RemoveAPI)
};

/* Email templates */
const GetEmailTemplates = (params, id) => {    
    return asyncFunc(`tenants/${id}/email_templates`, params)
};

const GetEmailTemplate = (params, id) => {
    return asyncFunc(`email_templates/${id}`)
};

const CreateEmailTemplate = (params) => {
    return asyncFunc(`email_templates`, params, PostAPI)
};

const UpdateEmailTemplate = (params, id) => {    
    return asyncFunc(`email_templates/${id}`, params, UpdateAPI)
};

const RemoveEmailTemplate = (id) => {
    return asyncFunc(`email_templates/${id}`, null, RemoveAPI)
};

/* PDF tenant templates - pdf_templates/tenant/{tenant_id} */
const GetTenantPDFTemplates = (params, id) => {
    return asyncFunc(`pdf_templates/tenant/${id}`, params)
};

const GetTenantPDFTemplate = (id) => {
    return asyncFunc(`pdf_templates/${id}`)
};

const CreateTenantPDFTemplate = (params) => {
    return asyncFunc(`pdf_templates`, params, PostAPI)
};

const UpdateTenantPDFTemplate = (params, id) => {
    return asyncFunc(`pdf_templates/${id}`, params, UpdateAPI)
};

const RemoveTenantPDFTemplate = (id) => {
    return asyncFunc(`pdf_templates/${id}`, null, RemoveAPI)
};

/* PDF templates */
const GetPDFTemplates = () => {
    return asyncFunc(`pdf_templates`)
};

const GetPDFTemplate = (id) => {
    console.log("GetPDFTemplate - id ->", id);
    return asyncFunc(`pdf_templates/${id}`)
};

const CreatePDFTemplate = (params) => {
    return asyncFunc(`pdf_templates`, params, PostAPI)
};

const UpdatePDFTemplate = (params, id) => {
    return asyncFunc(`pdf_templates/${id}`, params, UpdateAPI)
};

const RemovePDFTemplate = (id) => {
    return asyncFunc(`pdf_templates/${id}`, null, RemoveAPI)
};

export {
    // Tenants
    GetMyTenants,
    GetTenant,
    GetTenantsCount,
    GetTenantLists,
    GetTenantUsers,
    SwitchTenant,
    AddTenant,
    UpdateTenant,

    // Billing Run
    GetInvoiceStats,
    GetTenantBillingRunDates,
    GetAllTenantBillingRunDates,
    GetAllTenantBillingRunDatesSummary,
    RemoveBillingRunDate,
    CreatePainDDFile,
    GenerateInvoicesQueueJob,
    SendInvoiceEmailsQueueJob,
    ValidateIBANs,
    GenerateInvoicesConcept,

    // Groups
    GetGroupDependencies,
    GetGroupList,
    AddGroup,
    UpdateGroup,

    // Brands
    GetBrand,
    AddBrand,
    UpdateBrand,

    // Others
    GetProducts,
    GetProductsList,

    // Email Templates
    GetEmailTemplate,
    GetEmailTemplates,
    CreateEmailTemplate,
    UpdateEmailTemplate,
    RemoveEmailTemplate,
    // Tenant Email Templates
    GetTenantEmailTemplates,
    GetTenantEmailTemplate,
    CreateTenantEmailTemplate,
    UpdateTenantEmailTemplate,
    RemoveTenantEmailTemplate,

    // PDF Templates
    GetPDFTemplates,
    GetPDFTemplate,
    CreatePDFTemplate,
    UpdatePDFTemplate,
    RemovePDFTemplate,
    // Tenant PDF Templates
    GetTenantPDFTemplates,
    GetTenantPDFTemplate,
    CreateTenantPDFTemplate,
    UpdateTenantPDFTemplate,
    RemoveTenantPDFTemplate,
}
