import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/github.css';
import ReactQuill, { Quill } from 'react-quill';
import DatePicker, { registerLocale } from 'react-datepicker';
import NumberFormat from 'react-number-format';
import {
  Button,
  ButtonGroup,
  Col,
  Collapse,
  CustomInput,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { Edit2, PlusSquare, Save, Trash } from 'react-feather';
import { faSearchPlus, faCaretDown, faCaretUp, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  AddPlanLine,
  GetPlanLine,
  GetPlanLineGroups,
  GetPlanLineTermsConditions,
  UpdatePlanLine,
  AddPlanLineSinglePrice,

  GetPlanLinePricesOpts,
  AddPlanLinePrices,
  GetPlanLinePrices,
  RemovePlanLinePrice,
  UpdatePlanLinePrices,
} from '../../../controllers/plans';
import {
  GetProductHierarchy, GetProductWithPrice,/*, GetProducts*/
} from '../../../controllers/products';
import { GetProducts, GetProductsList } from '../../../controllers/tenants';
import { GetTermsConditions } from '../../../controllers/terms_conditions';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import {
  formatNumber, formatSeparators, parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import { enGB, enUS, nl } from 'date-fns/locale';
import Swal from 'sweetalert2';
import DynamicTextFieldPreview
  from '../../../components/form_builder/FormElementBuilder';
import ToastMulti from '../../../components/ToastMulti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageResize from 'quill-image-resize-module-react';
import Toast from '../../../components/Toast';
import {
  GetPlanSubscriptionLineTypes, RemoveSubscriptionLinePrice,
} from '../../../controllers/subscriptions';
import CONSTANTS from '../../../services/constants';
import ZoomedHTMLScreen from '../../../components/ZoomedHTMLScreen';

Quill.register('modules/imageResize', ImageResize);

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  },
  clipboard: {
    matchVisual: false
  }
};

class PlanLineForm extends React.Component {
  constructor(props) {
    super(props);

    console.log("PlanLineForm - this.props ->", this.props);
    const fetchingData = false;

    this.input = React.createRef();

    this.state = {
      formType: this.props.selectedData ? 'Edit' : 'Add',
      formName: 'plan line',
      showPreview: false,
      collapseJsonFieldIcon: faCaretDown,
      collapseJsonField: false,
      toggleQuillSource: false,
      toggleQuillSourceButtonText: "Show source",
      disableLineType: false,
      plan_line: {},
      selectedProductHierarchy: [],
      save_disabled: true,
      is_fetching_data: false,
      can_change_product: null,
      zoomModalOpenNested: false,
      zoomedHtml: ""
    };
  }

  openZoomModal = (html, e) => {
    e.stopPropagation(); // Prevent event propagation to the parent modal        
    this.setState({
      zoomModalOpenNested: true,
      zoomedHtml: html
    });
  };

  closeZoomModal = () => {
    this.setState({
      zoomModalOpenNested: false,
      zoomedHtml: "",
    });
  };

  getLocale = (locale) => {
    const dateLocalesPath = {
      'nl': 'nl',
      'en': 'en-GB',
      'us': 'en-US'
    };

    require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
  };

  handleInputChange({ target: { name, value } }, index) {
    let plan_line = Object.assign([], this.state.plan_line);

    plan_line[name] = value;

    this.setState({ plan_line })
  }

  handleSelectChange = async (data, name) => {
    let plan_line = Object.assign({}, this.state.plan_line);

    if (name === 'plan_line_terms_conditions') {
      let terms_conditions = [];
      if (data && data.length > 0) {
        let length = data.length;
        for (let i = 0; i < length; i++) {
          terms_conditions.push(data[i].value);
        }
      }
      plan_line["terms_conditions"] = terms_conditions;

      this.setState({
        plan_line,
        ["plan_line_terms_conditions"]: terms_conditions,
        ["terms_conditions"]: terms_conditions,
      });
    } 

    if (name === 'product') {
      const { user } = this.props;

      let tenant_id = user.tenant_id ? user.tenant_id : null;
      console.log('extendSelectedProduct - tenant_id ->', tenant_id);
      const tenant = sessionStorage.getItem('tenant');
      if (tenant && !tenant_id) {
        const tenantData = JSON.parse(tenant);
        tenant_id = tenantData.id;
      }

      //subscription_line.subscription_line_prices = [];
      const { label, value, vat_code, product_type_id/*, price*/ } = data;
      let price_excl_vat = 0;
      let price_incl_vat = 0;

      //console.log('handleSelectChange - vat_code ->', vat_code);
      //console.log('handleSelectChange - product_type_id ->', product_type_id);

      let selected_product;
      //(async () => {
      //const product_data = await GetProductWithPriceFilter(value);
      const product_data = await GetProductWithPrice(value, tenant_id);
      //console.log('handleSelectChange - GetProduct - product_data ->', product_data);

      if (product_data && product_data.data) {
        //if (data[0]) {
        //console.log('handleSelectChange - GetProduct - product_data.data.data ->', product_data.data.data);
        selected_product = product_data.data.data[0];
        //}
      }
      //})();

      //console.log('handleSelectChange - selected_product ->', selected_product);

      /*if (selected_product && selected_product.price_excl_vat) {
          price_excl_vat = selected_product.price_excl_vat ? selected_product.price_excl_vat : 0;
          price_incl_vat = selected_product.price_incl_vat ? selected_product.price_incl_vat : 0;
      }
      console.log('handleSelectChange - price_excl_vat ->', price_excl_vat);
      console.log('handleSelectChange - price_incl_vat ->', price_incl_vat);*/

      if (selected_product && selected_product.price) {
        price_excl_vat = selected_product.price ? selected_product.price : 0;
        price_incl_vat = null;
      }
      console.log('handleSelectChange - price_excl_vat ->', price_excl_vat);

      if (selected_product && selected_product.product_type_id) {
        // set line type && disable line type -> disableLineType
        const product_types = CONSTANTS.PRODUCT_TYPES; // populateLineType
        console.log('handleSelectChange - product_types ->', product_types);

        await this.populateLineType(selected_product.product_type_id);
      } else {
        this.setState({ disableLineType: false, subscription_line_type: null });
      }

      plan_line.description = label;

      if (this.state.formType && this.state.formType === "Add") {
        plan_line.plan_start = new Date();
      }

      if (vat_code) {
        (async () => {
          this.setState({ vat_code });

          if (vat_code.vat_percentage) {
            this.reCalculatePrice(parseFloat(vat_code.vat_percentage), price_excl_vat, price_incl_vat);
          }

          //if (product_type_id) {
          //    this.populateLineType(parseInt(product_type_id));
          //} else {
          //    this.setState({disableLineType: false});
          //}
        })();
      }
    }

    this.setState({
      plan_line,
      [name]: data,
    });
    //}
  };

  populateLineType = (id) => {
    const getLineType = this.state.plan_line_types.find(line => parseInt(line.value) === id);

    if (getLineType) {
      this.setState({
        plan_line_type: getLineType,
        disableLineType: true
      })
    }
  }

  handleQuillChangeTextarea(val) {
    let plan_line = Object.assign([], this.state.plan_line);

    plan_line["description_long"] = val;

    this.setState({ plan_line });
  }

  handleTextChange = (e) => {
    let plan_line = Object.assign([], this.state.plan_line);

    plan_line["json_data"] = e.target.value;

    this.setState({ plan_line });
  };

  handleCheckChange({ target: { name, checked } }) {
    console.log("handleCheckChange - name ->", name);
    console.log("handleCheckChange - checked ->", checked);

    let plan_line = Object.assign([], this.state.plan_line);

    plan_line[name] = checked ? 1 : 0;

    this.setState({ plan_line });
  }

  handleDatePickerChange(date, name, i) {
    let plan_line = Object.assign({}, this.state.plan_line);
    if (typeof i === 'number') {
      let plan_line_prices = Object.assign([], plan_line.plan_line_prices);

      plan_line_prices[i][name] = date ? new Date(date) : null;
      plan_line.plan_line_prices = plan_line_prices;

    } else {
      plan_line[name] = date ? new Date(date) : null;

    }
    this.setState({ plan_line });
  }

  toggleEdit(i) {
    let plan_line = Object.assign({}, this.state.plan_line),
      plan_line_prices = Object.assign([], plan_line.plan_line_prices);

    plan_line_prices[i].editing = !plan_line_prices[i].editing;
    plan_line.plan_line_prices = plan_line_prices;

    this.setState({ plan_line })
  }

  priceFormatter(name, i, data) {
    const { newVal, value } = this.props.dispatch(formatNumber(name, data.floatValue, data.value));

    let plan_line = Object.assign({}, this.state.plan_line),
      plan_line_prices = Object.assign([], plan_line.plan_line_prices),
      getVat = this.state.product && this.state.product.vat_code ? parseFloat(this.state.product.vat_code.vat_percentage) : 0;

    if (name === 'price_excl_vat') {
      if (newVal !== null || newVal === 0 || newVal < 0) {
        const calculated = parseFloat(newVal) + (parseFloat(newVal) * parseFloat(getVat));
        console.log('priceFormatter calculated', calculated);
        plan_line_prices[i].formatted_price_incl_vat = calculated; //calculated && this.hasValue(calculated) ? parseFloat(calculated) : 0;
        plan_line_prices[i].price_incl_vat = calculated; //newVal && parseFloat(newVal) >= 0 ? parseFloat(newVal) + (parseFloat(newVal) * getVat) : 0;
        plan_line_prices[i].fixed_price = newVal; //newVal && parseFloat(newVal) >= 0 ? parseFloat(newVal) : 0; //checkCalculated
      } else {
        plan_line_prices[i].formatted_price_incl_vat = null;
        plan_line_prices[i].price_incl_vat = null;
        plan_line_prices[i].fixed_price = null
      }
    } else if (name === 'price_incl_vat') {
      if (newVal !== null || newVal === 0 || newVal < 0) {
        const calculated = parseFloat(newVal) / (1 + getVat);
        plan_line_prices[i].formatted_price_excl_vat = calculated; // calculated && this.hasValue(calculated) ? parseFloat(calculated) : 0;
        plan_line_prices[i].price_excl_vat = newVal ? Number(newVal) / (1 + getVat) : 0; //newVal && parseFloat(newVal) >= 0 ? parseFloat(newVal) / (1 + getVat) : 0;
        plan_line_prices[i].fixed_price = newVal ? Number(newVal) / (1 + getVat) : 0; //newVal && parseFloat(newVal) >= 0 ? parseFloat(newVal) / (1 + getVat) : 0;
      } else {
        plan_line_prices[i].formatted_price_excl_vat = null;
        plan_line_prices[i].price_excl_vat = null;
        plan_line_prices[i].fixed_price = null
      }
    }

    plan_line_prices[i][`formatted_${name}`] = newVal || newVal !== null || newVal === 0 || true ? newVal : '';
    plan_line_prices[i][name] = parseFloat(value);
    plan_line.plan_line_prices = plan_line_prices;

    this.setState({ plan_line });
  }

  reCalculatePrice(vat_percentage, price_excl_vat_param, price_incl_vat_param) {
    let plan_line = Object.assign({}, this.state.plan_line),
      plan_line_prices = Object.assign([], plan_line.plan_line_prices);

    console.log("reCalculatePrice - plan_line ->", plan_line);
    console.log("reCalculatePrice - plan_line_prices ->", plan_line_prices);
    console.log("reCalculatePrice - price_excl_vat_param ->", price_excl_vat_param);
    console.log("reCalculatePrice - price_incl_vat_param ->", price_incl_vat_param);

    let lastIndex = plan_line_prices.length - 1;
    console.log("reCalculatePrice - lastIndex ->", lastIndex);

    if (plan_line_prices && plan_line_prices.length === 1) {
      plan_line_prices[0].formatted_price_excl_vat = parseFloat(price_excl_vat_param);
      plan_line_prices[0].price_excl_vat = parseFloat(price_excl_vat_param);

      let vat_price = parseFloat(price_excl_vat_param) * parseFloat(vat_percentage);
      //plan_line_prices[0].fixed_price = null;
      plan_line_prices[0].price_incl_vat = parseFloat(price_excl_vat_param) + vat_price;
      plan_line_prices[0].price_valid_from = new Date();

    } else if (plan_line_prices && plan_line_prices.length > 0) {
      const { lastRowIndex } = this.state;

      console.log("reCalculatePrice - lastRowIndex ->", lastRowIndex);
      console.log("reCalculatePrice - lastIndex ->", lastIndex);

      if (plan_line_prices[lastIndex]) {
        plan_line_prices[lastIndex].formatted_price_excl_vat = parseFloat(price_excl_vat_param);
        plan_line_prices[lastIndex].price_excl_vat = parseFloat(price_excl_vat_param);

        let vat_price = parseFloat(price_excl_vat_param) * parseFloat(vat_percentage);
        //plan_line_prices[0].fixed_price = null;
        plan_line_prices[lastIndex].price_incl_vat = parseFloat(price_excl_vat_param) + vat_price;
        plan_line_prices[lastIndex].price_valid_from = new Date();

      } else {
        let price = price_excl_vat_param;

        plan_line_prices.map(({ price_incl_vat, price_excl_vat, fixed_price }, i) => {
          plan_line_prices[i].formatted_price_excl_vat = null;
          plan_line_prices[i].price_excl_vat = null;
          plan_line_prices[i].fixed_price = null;
          plan_line_prices[i].valid_from = null;

          const { selectedData } = this.props;

          if (selectedData === undefined && (price || price === null)) {
            if (price === null) {
              plan_line_prices[i].formatted_price_excl_vat = null;
              plan_line_prices[i].price_excl_vat = null;
              plan_line_prices[i].price_valid_from = null;

              plan_line.plan_line_prices = plan_line_prices;
              return this.setState({ plan_line });

            } else {
              plan_line_prices[i].formatted_price_excl_vat = parseFloat(price);
              plan_line_prices[i].price_excl_vat = parseFloat(price);
              plan_line_prices[i].price_valid_from = new Date(plan_line.plan_start);

              plan_line.plan_line_prices = plan_line_prices;
              return this.setState({ plan_line });
            }

          } else if (price_excl_vat || price_excl_vat === 0) {
            plan_line_prices[i].formatted_price_excl_vat = parseFloat(price_excl_vat) > 0 ? parseFloat(price_excl_vat) : 0;
            plan_line_prices[i].price_excl_vat = parseFloat(price_excl_vat) > 0 ? parseFloat(price_excl_vat) : 0;
            plan_line.plan_line_prices = plan_line_prices;

            return this.setState({ plan_line });

          } else if (price_incl_vat) {
            plan_line_prices[i].formatted_price_excl_vat = price_incl_vat && parseFloat(price_incl_vat) > 0 ? parseFloat(price_incl_vat) / (1 + vat_percentage) : 0;
            plan_line_prices[i].price_excl_vat = price_incl_vat && parseFloat(price_incl_vat) > 0 ? parseFloat(price_incl_vat) / (1 + vat_percentage) : 0;
            plan_line.plan_line_prices = plan_line_prices;

            return this.setState({ plan_line });

          } else {
            return false;

          }
        });
      }
    }
  }

  handleKeyDown = ({ which }, name) => {
    if (which === 9) {
      this.ref[name].setOpen(false)
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const plan_line = Object.assign({}, this.state.plan_line),
      { product, plan_line_type, plan_line_group, plan_line_terms_conditions, terms_conditions } = this.state ? this.state : null,
      { description, description_long, plan_id, json_data, mandatory_line } = plan_line ? plan_line : null;
    let { plan_stop, plan_line_prices, plan_start } = plan_line ? plan_line : null;

    let hasMissingValidFrom = false,
      hasMissingPrice = false;

    plan_line_prices && plan_line_prices.map(line => {
      if (!line.price_valid_from) {
        return hasMissingValidFrom = true;
      } else {
        return false;
      }
    });

    plan_line_prices && plan_line_prices.map(line => {
      if (!this.hasValue(line.price_excl_vat) && !this.hasValue(line.margin)) {
        return hasMissingPrice = true;
      } else {
        return false;
      }
    });

    if (!product) {
      return Toast.fire({ title: 'Product field is required!', icon: 'warning' })
    }

    if (!description) {
      return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
    }

    if (!plan_line_type) {
      return Toast.fire({ title: 'Plan line type field is required!', icon: 'warning' })
    }

    if (!plan_start) {
      return Toast.fire({ title: 'Plan line start date field is required!', icon: 'warning' })
    }

    if (hasMissingValidFrom) {
      return Toast.fire({ title: 'Valid from field is required!', icon: 'warning' })
    }

    if (hasMissingPrice) {
      return Toast.fire({ title: 'Either price or margin field is required!', icon: 'warning' })
    }

    // set dates in plan_line_prices to save format
    if (plan_line_prices && plan_line_prices.length > 0) {
      for (let i = 0; i < plan_line_prices.length; i++) {
        plan_line_prices[i].price_valid_from = parseDateToSaveFormat(plan_line_prices[i].price_valid_from);
      }
    }
    // set plan_line_start & plan_line_stop to save format
    plan_start = plan_start ? parseDateToSaveFormat(plan_start) : null;
    plan_stop = plan_stop ? parseDateToSaveFormat(plan_stop) : null;

    const planLine = {
      plan_id,
      product_id: product ? product.value : null,
      plan_line_type: plan_line_type.value,
      plan_line_group_id: plan_line_group && plan_line_group.value ? plan_line_group.value : null,
      description: description ? description : null,
      description_long: description_long ? description_long : null,
      plan_start: plan_start,
      json_data: json_data ? json_data : null,
      plan_stop: plan_stop,
      plan_line_prices: plan_line_prices ? plan_line_prices : null,
      terms_conditions: terms_conditions ? terms_conditions : null,
      mandatory_line: mandatory_line,
    }, { selectedData, dispatch, id, hide, update, loader } = this.props ? this.props : {};

    //console.log("handleSubmit - planLine ->", planLine);
    //console.log("handleSubmit - selectedData ->", selectedData);
    //console.log("handleSubmit - id ->", id);

    (async () => {
      dispatch(loaderToggle(true));

      const endpointPlanLine = selectedData
        ? await UpdatePlanLine(selectedData.id, planLine)
        : await AddPlanLine(id, planLine),
        { ok } = await endpointPlanLine;

      if (ok) {
        hide();
        update('plan_line_update');
      }

      dispatch(loaderToggle(false));

    })();
  };

  handleOk = (ok, i, id) => {
    const { hide, update, dispatch } = this.props;

    dispatch(loaderToggle(false));
    let plan_line = Object.assign({}, this.state.plan_line);

    if (id) {
      let plan_line_prices = Object.assign([], plan_line.plan_line_prices);

      plan_line_prices[i].id = id;
      plan_line.plan_line_prices = plan_line_prices;

      this.setState({ plan_line })
    }

    if (ok) {
      if (i !== undefined && i !== null) {
        this.toggleEdit(i);
      }

      update('plan_line_update');
    }
  };

  handleSaveHTML = (html) => {
    let plan_line = Object.assign([], this.state.plan_line);
    plan_line["description_long"] = this.state.zoomedHtml;
    this.setState({ plan_line });
    this.closeZoomModal();
  }

  handleZoomedHtmlChange = (html) => {
    this.setState({
      zoomedHtml: html
    })
  }

  handleSave(i) {
    const plan_line_price = Object.assign({}, this.state.plan_line.plan_line_prices[i]),
      { id, price_excl_vat, /*price_incl_vat, */margin, price_valid_from } = plan_line_price,
      { dispatch, loader } = this.props ? this.props : {};

    if (!price_valid_from) {
      return Toast.fire({ title: 'Valid from field is required!', icon: 'warning' })
    }

    if ((price_excl_vat === null || price_excl_vat === undefined || price_excl_vat === '') && (margin === null || margin === '')) {
      return Toast.fire({ title: 'Either price or margin field is required!', icon: 'warning' })
    }

    let line_price = {
      fixed_price: price_excl_vat,
      margin: this.hasValue(margin) ? margin : null,
      price_valid_from: parseDateToSaveFormat(price_valid_from)
    };

    if (!loader) {
      dispatch(loaderToggle(true));

      if (id) {
        (async () => {
          const { ok } = await UpdatePlanLinePrices(line_price, id);
          if (ok) {
            this.handleOk(ok, i);
          }
        })();

      } else {
        (async () => {
          const { ok, data } = await AddPlanLinePrices(line_price, this.state.plan_line.id);
          if (ok) {
            this.handleOk(ok, i, data.data.id);
          }
        })();

      }

      dispatch(loaderToggle(false));
    }
  }

  addRow = () => {
    console.log("Plan lines - add row");

    let plan_line = Object.assign({}, this.state.plan_line),
      plan_line_prices = Object.assign([], plan_line.plan_line_prices),
      getLength = plan_line_prices ? plan_line_prices.length : 0;

    plan_line_prices[getLength] = {
      //price_valid_from: null,
      price_valid_from: new Date(),
      fixed_price: null,
      formatted_price_excl_vat: null,
      price_excl_vat: null,
      formatted_price_incl_vat: null,
      price_incl_vat: null,
      formatted_margin: null,
      margin: null,
      editing: true
    };

    plan_line.plan_line_prices = plan_line_prices;

    this.setState({
      plan_line,
      lastRowIndex: getLength
    });
  };

  removePlanLinePriceRow(i) {
    const { loader, selectedData, dispatch/*, update, hide*/ } = this.props;

    Swal.fire({
      customClass: {
        container: 'has-cancel',
      },
      title: 'Please confirm!',
      text: `Are you sure you want to delete row ${i + 1} of plan line prices?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(res => {
      if (res.value) {
        if (!loader) {

          dispatch(loaderToggle(true));

          this.removeRowConfirmed(i, true);

          dispatch(loaderToggle(false));

          Swal.fire('Success!', 'Removed successfully', 'success').
            then((r) => { console.log(r) });
        }
      } else {
        dispatch(loaderToggle(false));
      }
    });
  }

  removeRowConfirmed(i, confirmDelete) {
    let plan_line = Object.assign({}, this.state.plan_line),
      plan_line_prices = Object.assign([], plan_line.plan_line_prices);

    if (confirmDelete) {
      if (!plan_line_prices[i].id) {
        plan_line_prices.splice(i, 1);
        plan_line.plan_line_prices = plan_line_prices;
        this.setState({ plan_line })
      } else {
        const getPricesWithId = plan_line_prices && plan_line_prices.length > 0 ? plan_line_prices.filter(price => price.id) : null,
          { dispatch, loader, update } = this.props;

        if (getPricesWithId && getPricesWithId.length && getPricesWithId.length === 1) {
          return Toast.fire({ title: 'Please add more line prices to delete this!', icon: 'warning' })
        }

        if (!loader) {
          dispatch(loaderToggle(true));

          (async () => {
            const { ok } = await RemovePlanLinePrice(plan_line_prices[i].id);

            dispatch(loaderToggle(false));

            if (ok) {
              plan_line_prices.splice(i, 1);
              plan_line.plan_line_prices = plan_line_prices;
              this.setState({ plan_line });
              update();
            }
          })();
        }
      }
    }
  }

  getJsonData = (index, name) => {
    if (!this.state[`${name}${index}`]) {
      return "";
    }

    return this.state[`${name}${index}`];
  };

  eventFocus(i, { target: { name } }) {
    if (name === 'price_excl_vat') {
      this.toggleOnFocus(i, 'excl_onFocus', true)
    } else {
      this.toggleOnFocus(i, 'incl_onFocus', true)
    }
  }

  eventBlur(i, { target: { name } }) {
    if (name === 'price_excl_vat') {
      this.toggleOnFocus(i, 'excl_onFocus', false)
    } else {
      this.toggleOnFocus(i, 'incl_onFocus', false)
    }
  }

  toggleOnFocus(i, name, bool) {
    let plan_line = Object.assign({}, this.state.plan_line),
      plan_line_prices = Object.assign([], plan_line.plan_line_prices);

    plan_line_prices[i][name] = bool;
    plan_line.plan_line_prices = plan_line_prices;

    this.setState({ plan_line })
  }

  hasValue = (value) => {
    console.log("plan_lines form - hasValue - value ->", value);
    if (Math.sign(value) || Math.sign(value) === 0 || Math.sign(value) < 0) {
      console.log("plan_lines form - hasValue -> TRUE");
      return true;
    } else {
      console.log("plan_lines form - hasValue -> FALSE");
      return false;
    }
  };

  collapseJsonDataField = (e) => {
    e.preventDefault();

    this.setState({
      collapseJsonField: !this.state.collapseJsonField,
      collapseJsonFieldIcon: !this.state.collapseJsonField === true ? faCaretUp : faCaretDown,
    });
  };

  showPreviewJsonData = (e) => {
    e.preventDefault();

    this.setState({ showPreview: !this.state.showPreview });
  };

  validatePreviewJsonData = (e) => {
    const value = e.target.value;

    let notificationsToastArr = [];
    if (value && !this.isValidJSONString(value)) {
      notificationsToastArr.push({ title: 'JSON data is invalid!', icon: 'warn' });
    } else {
      if (!value || value === "") {
        notificationsToastArr.push({ title: 'JSON data is empty!', icon: 'warn' });
      } else {
        notificationsToastArr.push(
          { title: 'JSON data is valid!', icon: 'success' });
      }
    }

    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }
  };

  isValidJSONString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  getProductHierarchyByProductId = (product_id) => {
    (async () => {
      const [productHierarchies] = await Promise.all([
        GetProductHierarchy(product_id),
      ]);

      await (() => {
        if (productHierarchies.ok) {
          let productHierarchy = [];

          productHierarchies.data && productHierarchies.data.data &&
            productHierarchies.data.data.map(({ description, id, vat_code, product_type_id, price }) => {
              return productHierarchy.push({
                label: description,
                value: id,
                vat_code,
                product_type_id,
                price: price
              });
            });

          this.setState({ productHierarchy });
        }
      })();
    })();
  };

  componentDidMount() {
    let { dispatch, match, selectedData, id, plan_line } = this.props;
    //plan_line = selectedData ? selectedData : null;

    //let planLineObj = Object.assign({}, this.state.plan_line);
    let planLineObj = Object.assign({}, plan_line);

    console.log("planLines form - componentDidMount - planLineObj ->", planLineObj);
    console.log("planLines form - componentDidMount - selectedData ->", selectedData);

    let plan_line_id = selectedData ? selectedData.id : null;
    let plan_id = id;

    if (selectedData && selectedData.child_of) {
      this.setState({
        can_change_product: true
      });
    }

    if (!this.fetchingData) {
      this.fetchingData = true;

      dispatch(loaderToggle(true));

      (async () => {

        const [planLineData, planLinePrices, planLineTypes, productsData, planLineTermsConditions, termsConditions, planLineGroups] =
          this.props.selectedData ?
            await Promise.all(
              [
                GetPlanLine(null, plan_line_id),
                GetPlanLinePricesOpts(plan_line_id),
                GetPlanSubscriptionLineTypes(),
                GetProductsList(),
                GetPlanLineTermsConditions(plan_line_id),
                GetTermsConditions(),
                GetPlanLineGroups(),
              ])
            :
            await Promise.all(
              [
                GetPlanLine(null, plan_line_id),
                null,
                GetPlanSubscriptionLineTypes(),
                GetProductsList(),
                GetPlanLineTermsConditions(plan_line_id),
                GetTermsConditions(),
                GetPlanLineGroups(),
              ]);

        let products = [];
        let plan_line_types = [];
        let plan_line_terms_conditions = [];
        let plan_line_groups = [];
        let plan_line_prices = [];

        console.log("plan_lines - form - componentDidMount - planLineData ->", planLineData);
        console.log("plan_lines - form - componentDidMount - planLinePrices ->", planLinePrices);
        console.log("plan_lines - form - componentDidMount - planLineTermsConditions ->", planLineTermsConditions);

        await (() => {
          // get products
          if (productsData.ok) {
            productsData.data && productsData.data.data &&
              productsData.data.data.map(
                ({ description, id, vat_code, product_type_id, price }) => {
                  return products.push({
                    label: description,
                    value: id,
                    vat_code,
                    product_type_id,
                    price: price
                  })
                });

            this.setState({ products })
          }

          // get & set extra plan line data
          if (planLineData && planLineData.ok && planLineData.data && planLineData.data.data) {

            //let planLineObj = Object.assign({});
            //planLineObj = planLineData.data.data;

            console.log(
              "plan_lines - form - componentDidMount - plan_line ->",
              planLineData.data.data);

            planLineObj = planLineData.data.data;
            //plan_line.plan_line_prices = planLineData.data.data.plan_line_prices;
            //plan_line.terms_conditions = [];

            this.setState({ plan_line: planLineObj });
          }

          if (planLinePrices && planLinePrices.ok) {

            let emptyObj = {
              "id": id,
              "plan_line_id": plan_line_id,
              "parent_plan_line_id": null,
              "fixed_price": 0,
              "margin": null,
              "price_valid_from": new Date(),
              "price_incl_vat": 0,
              "price_excl_vat": 0,
              "calculated": false,
              "has_parent": false
            };

            let emptyPricesArr = [];
            emptyPricesArr.push(emptyObj);
            console.log("plan_lines - form - componentDidMount - emptyPricesArr ->", emptyPricesArr);

            let plan_line_prices = planLinePrices.data && planLinePrices.data.data && planLinePrices.data.data.length > 0 ? planLinePrices.data.data : emptyPricesArr;
            planLineObj.plan_line_prices = plan_line_prices;

            this.setState({
              plan_line: planLineObj
            });

            /*
            //plan_line_prices = planLinePrices.data && planLinePrices.data.data && planLinePrices.data.data.data ? planLinePrices.data.data.data : [];

            //subscriptionLineObj.subscription_line_prices = prices.data && prices.data.data && prices.data.data.data ? prices.data.data.data : [];
            //planLineObj.subscription_line_prices = plan_line_prices;
            //this.setState({
            //plan_line: planLineObj
            //});
            //}
            // set products array for select dropdown
            /*if (productsData && productsData.ok) {
              let products = [];

              productsData.data && productsData.data.data &&
              productsData.data.data.map(({ description, id, vat_code, product_type_id, price }) => {
                return products.push({
                  label: description,
                  value: id,
                  vat_code,
                  product_type_id,
                  price: price
                });
              });

              this.setState({ products });*/
          }

          // set (plan) line_types
          if (planLineTypes && planLineTypes.ok) {
            planLineTypes.data && planLineTypes.data.data &&
              planLineTypes.data.data.map(({ line_type, id }) => {
                //if (line_type === 'YRC' || line_type === 'QRC' || line_type === 'MRC' || line_type === 'NRC' || line_type === 'Deposit') {
                return plan_line_types.push({
                  label: line_type, value: id
                });
                //} else {
                //  return false;
                //}
              });

            this.setState({ plan_line_types });
          }

          // set selected plan line terms & conditions
          if (planLineTermsConditions && planLineTermsConditions.ok) {
            const { data } = planLineTermsConditions.data;

            console.log("plan_lines - form - componentDidMount - selected plan line terms & conditions - data ->", data);

            data && data.data && data.data.length > 0 &&
              data.data.map(({ header, id }) => {
                return plan_line_terms_conditions.push({
                  label: header, value: id,
                });
              });

            console.log("plan_lines - form - componentDidMount - selected plan line terms & conditions - plan_line_terms_conditions ->", plan_line_terms_conditions);

            this.setState({ plan_line_terms_conditions });
          }

          // set all terms & conditions
          if (termsConditions && termsConditions.ok) {
            let all_terms_conditions = [];

            termsConditions.data && termsConditions.data.data && termsConditions.data.data.length > 0 &&
              termsConditions.data.data.map(({ header, id }) => {
                return all_terms_conditions.push({
                  label: header, value: id,
                });
              });

            this.setState({ all_terms_conditions });
          }

          // set plan line groups
          if (planLineGroups.ok) {
            planLineGroups.data && planLineGroups.data.data && planLineGroups.data.data.length > 0 &&
              planLineGroups.data.data.map(({ id, header, description }) => {
                plan_line_groups.push({
                  label: description, // + ` (${id})`,
                  value: id,
                });
              });

            this.setState({ plan_line_groups });
          }
          //})();

          console.log("plan_lines - form - componentDidMount - plan_line_terms_conditions ->", plan_line_terms_conditions);

          //await (() => {
          if (selectedData) {

            console.log(
              "plan_lines - form - componentDidMount - (if selectedData) planLineObj ->",
              planLineObj);

            // if the parent plan line is filled - you cannot update!!!!!!
            // only: long description - plan line group - terms and conditions - plan line prices

            const mainSystemKey = planLineObj.description
              ? planLineObj.description.replace(/ /g, "_").toLowerCase()
              : null;

            const plan_line_type = plan_line_types && plan_line_types.length > 0
              ? plan_line_types.find(item => parseInt(item.value) ===
                parseInt(planLineObj.plan_line_type))
              : null;
            const plan_line_group = plan_line_groups &&
              plan_line_groups.length > 0 ? plan_line_groups.find(
                item => parseInt(item.value) ===
                  parseInt(planLineObj.plan_line_group_id)) : null;

            if (plan_line_terms_conditions &&
              plan_line_terms_conditions.length > 0) {
              planLineObj.terms_conditions = plan_line_terms_conditions;
            }

            this.setState({
              plan_line: planLineObj,
              mainSystemKey: mainSystemKey, // needed for JSON key
              vat_code: planLineObj.product && planLineObj.product.vat_code
                ? planLineObj.product.vat_code
                : {},
              plan_line_type: plan_line_type,
              parent_plan_line_id: planLineObj.parent_plan_line_id
                ? planLineObj.parent_plan_line_id
                : null,
              plan_line_group: plan_line_group,
              plan_line_terms_conditions: plan_line_terms_conditions,
            });

            this.populatePrices();

          } else {
            let plan_line = Object.assign({}, this.state.plan_line);
            plan_line.plan_line_start = new Date();

            this.setState({ plan_line });
            this.addRow(null);
          }

        })();

        this.setState({ hasLoaded: true });
        this.fetchingData = false;

      })();

      dispatch(loaderToggle(false));

    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("PlanLines list table (plan_lines/form) - componentDidUpdate - props ->", this.props);
    console.log("PlanLines list table (plan_lines/form) - componentDidUpdate - prevProps ->", prevProps);

    console.log("PlanLines list table (plan_lines/form) - componentDidUpdate - state ->", this.state);
    console.log("PlanLines list table (plan_lines/form) - componentDidUpdate - prevState ->", prevState);

    const { plan_line } = this.state;
    const { product } = plan_line;

    if (product && !this.state.product) {
      console.log("PlanLines list table (plan_lines/form) - componentDidUpdate - product ->", product);
      console.log("PlanLines list table (plan_lines/form) - componentDidUpdate - this.state.product ->", this.state.product);

      //if (!this.state.product) {
      this.setState({
        product: {
          label: product.description,
          price: product.price ? product.price : null,
          product_type_id: product.product_type_id ? product.product_type_id : null,
          value: product.id,
          vat_code: product.vat_code ? product.vat_code : {}
        }
      });
      //}
      /*
        set product in state as:
        {
          label: "Glasvezelaansluiting"
          price: null
          product_type_id: null
          value: 3​
          vat_code: Object { vat_percentage: 0.21, description: "BTW 21%" }
        }

        not as:
        {
          description: "Glasvezelaansluiting"
          description_long: null
          ean_code: null
          id: 3
          price: null
          product_type_id: null
          serialized: null
          status_id: null​​
          vat_code: { vat_percentage, description, active_from }
        }
     */

      //let productData = {};
      //productData.description =

      //this.setState({product: productData });
    }
  };

  populatePrices() {
    let plan_line = Object.assign({}, this.state.plan_line),
      plan_line_prices = Object.assign([], plan_line.plan_line_prices);

    plan_line_prices && plan_line_prices.length > 0 && plan_line_prices.map((line_price, i) => {
      return (
        this.priceFormatter('price_excl_vat', i, {
          value: line_price && this.hasValue(line_price.price_excl_vat) ? line_price.price_excl_vat : null,
          floatValue: line_price && this.hasValue(line_price.price_excl_vat) ? parseFloat(line_price.price_excl_vat) : null
        }),
        this.priceFormatter('price_incl_vat', i, {
          value: line_price && this.hasValue(line_price.price_incl_vat) ? line_price.price_incl_vat : null,
          floatValue: line_price && this.hasValue(line_price.price_incl_vat) ? parseFloat(line_price.price_incl_vat) : null
        }),
        this.priceFormatter('margin', i, {
          value: line_price && this.hasValue(line_price.margin) ? line_price.margin : null,
          floatValue: line_price && this.hasValue(line_price.margin) ? parseFloat(line_price.margin) : null
        })
      )
    })
  }

  loadHierarchyProducts = (value, callback) => {
    const search = 'search',
      offset = 1000,
      params = { search: value, offset: offset };

    let selected_product_id = this.props.selectedData && this.props.selectedData.product ? this.props.selectedData.product.id : null;

    (async () => {
      const { ok, data } = await GetProductHierarchy(selected_product_id);
      if (ok && data) {
        let products = [];
        data.data.map(({ description, id }) => {
          if (selected_product_id) {
            if (parseInt(id) !== parseInt(selected_product_id)) {
              return products.push({
                label: description,
                value: id
              });
            }
          } else {
            return products.push({
              label: description,
              value: id
            });
          }
        });
        callback(products);
      }
    })();
  };

  getOptionValue = (option) => option.id;

  getOptionLabel = (option) => option.name;

  loadOptions = async (value, callback) => {
    const search = 'search',
      offset = 1000,
      params = { search: value, offset: offset };

    //const { ok, data } = await GetProducts(params);
    const { ok, data } = await GetProductsList(); //params
    if (ok && data) {
      console.log("loadOptions - ok ->", ok);
      console.log("loadOptions - data ->", data);
      let products = [];
      data.data.map(({ description, id, vat_code, product_type_id, price }) => {
        /*if (product) {
          if (parseInt(id) !== parseInt(product)) {
            return products.push({
              label: description, value: id
            });
          }
        } else {*/
        return products.push({
          label: description,
          value: id,
          vat_code,
          product_type_id,
          price: price
        });
        //}
      });
      //callback(products);

      console.log("planLines form - loadOptions - products ->", products);

      this.setState({
        products,
        save_disabled: false,
      });

      //const product = products.length > 0 && this.state.plan_line && this.state.plan_line.product ? products.find(item => parseInt(item.value) === parseInt(this.state.plan_line.product.id)) : null;
      const product = products.length > 0 && this.state.product ? products.find(item => parseInt(item.value) === parseInt(this.state.product.id)) : null;

      //console.log("planLines form - loadOptions - products ->", products);
      console.log("planLines form - loadOptions - product ->", product);

      /*
        description: "Glasvezelaansluiting"
        description_long: null
        ean_code: null
        id: 3
        price: null
        product_type_id: null
        serialized: null
        status_id: null​​
        vat_code: { vat_percentage, description, active_from }
       */

      if (product) {
        this.setState({
          //product: product,
          product,
        });
      }

      return products;
      //const product = products && products.length > 0 && plan_line.product ? products.find(item => parseInt(item.value) === parseInt(plan_line.product.id)) : null;
    }
    /*const response = await fetch(
        `${this.state.searchApiUrl}?search=${inputValue}&limit=${
            this.state.limit
        }`
    );
    const json = await response.json();
    return json.results;*/
  };

  loadProducts2 = (value, callback) => {
    console.log("planLines form - AddSubPlanLineForm - loadProducts - value ->", value);
    console.log("planLines form - AddSubPlanLineForm - loadProducts - callback ->", callback);
    console.log("planLines form - AddSubPlanLineForm - loadProducts - callback ->", callback);

    const search = 'search',
      offset = 1000,
      params = { search: value, offset: offset };

    let product_id = this.props.selectedData ? this.props.selectedData.product_id : null;

    (async () => {
      //const { ok, data } = await GetProductHierarchy(product_id);
      const { ok, data } = await GetProducts(params);
      if (ok && data) {
        let products = [];
        data.data.map(({ description, id }) => {
          if (product_id) {
            if (parseInt(id) !== parseInt(product_id)) {
              return products.push({
                label: description,
                value: id
              });
            }
          } else {
            return products.push({
              label: description,
              value: id
            });
          }
        });
        callback(products);
      }
    })();
  };

  /*loadProducts = (value, callback) => {
    console.log("FormSubLine - loadProducts - value ->", value);
    console.log("FormSubLine - loadProducts - callback ->", callback);

    const search = 'search',
        offset = 1000,
        params = { search: value, offset: offset };

    let product_id = this.props.selectedData ? this.props.selectedData.product_id : null;

    (async () => {
      //const { ok, data } = await GetProductHierarchy(product_id);
      const { ok, data } = await GetProducts(params);
      if (ok && data) {
        let products = [];
        data.data.map(({ description, id }) => {
          if (product_id) {
            if (parseInt(id) !== parseInt(product_id)) {
              return products.push({
                label: description,
                value: id
              });
            }
          } else {
            return products.push({
              label: description,
              value: id
            });
          }
        });
        callback(products);
      }
    })();
  };*/

  render() {
    const {
      all_terms_conditions,
      disableLineType,
      can_change_product,
      formName,
      formType,
      hasLoaded,
      mainSystemKey,
      plan_line_types,
      plan_line_type,
      plan_lines,
      plan_line_groups,
      plan_line_group,
      parent_plan_line_id,
      plan_line_terms_conditions,
      product,
      products,
      save_disabled,
      toggleQuillSource,
      toggleQuillSourceButtonText,
      vat_code,
      zoomModalOpenNested,
      zoomedHtml
    } = this.state,
      { description, description_long, plan_start, plan_stop, plan_line_prices, line_type, json_data, mandatory_line } = this.state.plan_line ? this.state.plan_line : {},
      { selectedData, show, hide, loader, dispatch, t, defaultOptions } = this.props;

    console.log("planLines form - render - this.state ->", this.state);
    console.log("planLines form - render - product ->", product);
    console.log("planLines form - render - plan_line_prices ->", plan_line_prices);

    return (
      <React.Fragment>
        {(!loader || hasLoaded) && <Modal className="form-plan-lines mw-800" isOpen={show} toggle={hide} centered>
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <ModalHeader className="modal-header-plan-lines">
              <span className="d-flex justify-content-between">
                <span>{formType} {formName}</span>
              </span>
            </ModalHeader>

            <ModalBody>
              <div className="form-row w-100">
                <Col md={12}>
                  <div className="form-row mb-2">
                    <Col md={4}>
                      <Label className="mb-0" for="product_id">Product:</Label>
                    </Col>
                    <Col md={8}>
                      {/*<AsyncSelect
                          //cacheOptions
                          //getOptionValue={this.getOptionValue}
                          //getOptionLabel={this.getOptionLabel}
                          defaultOptions
                          //options={products}
                          //options={this.state.products ? this.state.products : []}
                          loadOptions={this.loadOptions}
                          //getOptionLabel={({ label, value }) => {
                          //  return `${label ? label : String.fromCharCode(8212)}`
                          //}}
                          id="product"
                          name="product"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder={`Product`}
                          //value={this.getSelectVal(0, 'product_id')}
                          value={product}
                          onChange={(e) => this.handleSelectChange(e, 'product')}
                          maxMenuHeight={300}
                      />*/}
                      <Select
                        id="product"
                        name="product"
                        options={products}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Product"
                        value={product}
                        //onChange={this.handleSelectChange.bind(this, 'product')}
                        onChange={(e) => this.handleSelectChange(e, 'product')}
                        maxMenuHeight={300}
                        isDisabled={!!parent_plan_line_id || can_change_product}
                      />
                      {/*<Select
                        id="product"
                        name="product"
                        options={products}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Product"
                        value={product}
                        onChange={(e) => this.handleSelectChange(e, 'product')}
                        maxMenuHeight={300}
                        isDisabled={!!parent_plan_line_id}
                      />*/}
                    </Col>
                  </div>
                  <div className="form-row mb-2">
                    <Col md={4}>
                      <Label className="mb-0" for={`plan_line_description`}>Short description:</Label>
                    </Col>
                    <Col md={8}>
                      <Input
                        id={`plan_line_description`}
                        name="description"
                        value={description ? description : ""}
                        ref={this.input}
                        onChange={(e) => { this.handleInputChange(e) }}
                        placeholder="Short description"
                      />
                    </Col>
                  </div>
                  <div className="form-row mb-2">
                    <Col md={4}>
                      <Label className="mb-0" for="description_long">Long description:</Label>
                    </Col>
                    <Col md={8}>
                      <ReactQuill
                        modules={modules}
                        theme="snow"
                        placeholder="Long description"
                        name="description_long"
                        value={description_long || ""}
                        onChange={(e) => this.handleQuillChangeTextarea(e)}
                      />
                      <div style={{ textAlign: "right", marginTop: "0.25rem" }}>
                        <Button color="primary" className="btn-sm" onClick={(e) => this.openZoomModal(description_long, e)}>
                          <FontAwesomeIcon icon={faSearchPlus} />
                        </Button>
                      </div>
                    </Col>
                  </div>

                  <div className="form-row mb-2">
                    <Col md={4}>
                      <label className="col-form-label">JSON data:</label>
                    </Col>
                    <Col md={8}>
                      <ButtonGroup className="btn-group-textarea-collapse">
                        {/*<Button color="primary" className="btn-sm" onClick={(e) => this.showPreviewJsonData(e)}>{ !this.state.showPreview ? `Show preview` : `Hide preview` }</Button>
                        <Button color="primary" className="btn-sm" disabled={this.state.showPreview} onClick={(e) => this.validatePreviewJsonData(e)}>Validate JSON</Button>*/}
                        <Button color="primary" className="btn-sm" onClick={(e) => this.collapseJsonDataField(e)}>
                          <FontAwesomeIcon color="white" name="collapse-icon" className="align-middle mt-n1" icon={this.state.collapseJsonFieldIcon} />
                        </Button>
                      </ButtonGroup>
                      <Collapse isOpen={this.state.collapseJsonField}>
                        {!this.state.showPreview &&
                          <Input
                            name="json_data"
                            style={{ minHeight: "100px" }}
                            type="textarea"
                            placeholder="Enter JSON data"
                            value={json_data ? json_data : ""}
                            onChange={(e) => this.handleTextChange(e)}
                          />}
                        {this.state.showPreview &&
                          <div className="form-element-preview">
                            {json_data ? (
                              <DynamicTextFieldPreview
                                formSchema={json_data}
                                mainKey={mainSystemKey ? mainSystemKey : false}
                              />) : (
                              <p>No preview available</p>
                            )}
                          </div>}
                      </Collapse>
                    </Col>
                  </div>

                  <div className="form-row mb-2">
                    <Col md={4}>
                      <Label className="mb-0" for="plan_line_type">Line type:</Label>
                    </Col>
                    <Col md={8}>
                      <Select
                        id="plan_line_type"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={plan_line_types}
                        value={plan_line_type}
                        onChange={(e) => this.handleSelectChange(e, 'plan_line_type')}
                        maxMenuHeight={300}
                        isClearable={true}
                        isDisabled={!!parent_plan_line_id}
                      />
                    </Col>
                  </div>

                  {/*
                  <div className="form-row mb-2">
                    <Col md={4}>
                      <Label className="mb-0" for="parent_plan_line_id">Parent plan line:</Label>
                    </Col>
                    <Col md={8}>
                      <Select
                        id="parent_plan_line_id"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={plan_line_types}
                        value={''}
                        onChange={(e) => this.handleSelectChange(e, 'parent_plan_line')}
                        maxMenuHeight={300}
                        isDisabled={true}
                        isClearable={true}
                      />
                    </Col>
                  </div>
                  */}

                  <div className="form-row mb-2">
                    <Col md={4}>
                      <Label className="mb-0" for="parent_plan_line_id">Terms and conditions:</Label>
                    </Col>
                    <Col md={8}>
                      <Select
                        id="terms_conditions"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={all_terms_conditions}
                        value={plan_line_terms_conditions}
                        onChange={(e) => this.handleSelectChange(e, 'plan_line_terms_conditions')}
                        maxMenuHeight={300}
                        isMulti={true}
                        isClearable={true}
                      />
                    </Col>
                  </div>

                  <div className="form-row mb-2">
                    <Col md={4}>
                      <Label className="mb-0">Valid from / until:</Label>
                    </Col>
                    <Col md={4}>
                      <DatePicker
                        locale={this.getLocale()}
                        id={`plan_line_start`}
                        wrapperClassName="w-100"
                        className="form-control"
                        name="plan_start"
                        dateFormat={t("date_format_raw")}
                        autoComplete="off"
                        selected={plan_start ? new Date(plan_start) : null}
                        onChange={(e) => this.handleDatePickerChange(e, 'plan_start')}
                        //ref={(el) => {this.datePickerFieldRef = el}}
                        onKeyDown={(e) => this.handleKeyDown(e, `plan_start`)}
                        placeholderText="Select date from"
                        maxDate={plan_stop ? new Date(plan_stop) : null}
                        isClearable={!parent_plan_line_id}
                        readOnly={!!parent_plan_line_id}
                      />
                    </Col>
                    <Col md={4}>
                      <DatePicker
                        locale={this.getLocale()}
                        id={`plan_line_stop`}
                        wrapperClassName="w-100"
                        className="form-control"
                        name="plan_stop"
                        dateFormat={t("date_format_raw")}
                        autoComplete="off"
                        selected={plan_stop ? new Date(plan_stop) : null}
                        onChange={(e) => this.handleDatePickerChange(e, 'plan_stop')}
                        //ref={(el) => {this.datePickerFieldRef = el}}
                        onKeyDown={(e) => this.handleKeyDown(e, `plan_stop`)}
                        placeholderText="Select date until"
                        minDate={plan_start ? new Date(plan_start) : null}
                        isClearable={!parent_plan_line_id}
                        readOnly={!!parent_plan_line_id}
                      />
                    </Col>
                  </div>
                  <div className="form-row mb-3">
                    <Col md={4}>
                      <Label className="mb-0" for="plan_line_group_id">Plan line group:</Label>
                    </Col>
                    <Col md={8}>
                      <Select
                        id={`plan_line_group`}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={plan_line_groups}
                        placeholderText="Select date from"
                        value={plan_line_group}
                        onChange={(e) => this.handleSelectChange(e, 'plan_line_group')}
                        maxMenuHeight={300}
                        isClearable={true}
                      />
                    </Col>
                  </div>
                  <div className="form-row mb-2">
                    <Col md={4}>
                      <Label for="mandatory_plan_line"> </Label>
                    </Col>
                    <Col md={8}>
                      <CustomInput
                        id={`plan_line_mandatory`}
                        type="checkbox"
                        name="mandatory_line"
                        checked={mandatory_line && mandatory_line === 1}
                        label="Mandatory line"
                        onChange={(e) => { this.handleCheckChange(e) }}
                      />
                    </Col>
                  </div>
                </Col>
              </div>

              <Row className="w-100 mt-4 no-gutters">
                <Col xs="12">
                  <h5>Plan line prices</h5>
                  <Table className="mb-0 table-plan-line-prices-edit">
                    <thead>
                      <tr>
                        <th data-label="Valid from" style={{ width: '25%' }}>Valid from</th>
                        <th data-label="Price excl. VAT" style={{ width: '21%' }}>Price excl. VAT</th>
                        <th data-label="Price incl. VAT" style={{ width: '21%' }}>Price incl. VAT</th>
                        <th data-label="Margin" style={{ width: '21%' }}>Margin</th>
                        <th data-label="Unit price" style={{ width: '12%', minWidth: '75px' }}> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {plan_line_prices && plan_line_prices.length > 0 && plan_line_prices.map(({ price_valid_from, price_excl_vat, price_incl_vat, margin, editing }, i) => (
                        <tr key={i}>
                          {!editing
                            ? <React.Fragment>
                              <td data-label="Valid from">{price_valid_from ? t("date_format", { date: new Date(price_valid_from) }) : String.fromCharCode(8212)}</td>
                              <td data-label="Price excl. VAT">{(!this.hasValue(margin, 'margin') || margin === null) && this.hasValue(price_excl_vat, 'price_excl_vat') ? t("currency_format", { number: Number(parseFloat(price_excl_vat)) }) : String.fromCharCode(8212)}</td>
                              <td data-label="Price incl. VAT">{this.hasValue(price_incl_vat, 'price_incl_vat') ? t("currency_format", { number: Number(parseFloat(price_incl_vat)) }) : String.fromCharCode(8212)}</td>
                              <td data-label="Margin">{this.hasValue(margin, 'margin') && price_excl_vat === null ? `${dispatch(formatSeparators(margin, true)).formatted}` : String.fromCharCode(8212)}</td>
                            </React.Fragment>
                            : <React.Fragment>
                              <td data-label="Valid from">
                                <DatePicker
                                  locale={this.getLocale()}
                                  wrapperClassName="w-100"
                                  className="form-control"
                                  name="price_valid_from"
                                  dateFormat={t("date_format_raw")}
                                  autoComplete="off"
                                  placeholderText="Valid from"
                                  //selected={plan_line_prices[i] && plan_line_prices[i].price_valid_from ? new Date(plan_line_prices[i].price_valid_from) : null}
                                  selected={plan_line_prices[i] && plan_line_prices[i].price_valid_from ? new Date(new Date(plan_line_prices[i].price_valid_from)) : plan_start ? new Date(plan_start) : null}
                                  //onChange={this.handleDatePickerChange.bind(this, 'price_valid_from', i)}
                                  onChange={(e) => this.handleDatePickerChange(e, 'price_valid_from', i)}
                                  //ref={this.datePickerField}
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              </td>
                              <td data-label="Price excl. VAT">
                                {/*console.log("planLines form - (edit) plan_line_prices[i] ->", plan_line_prices[i])*/}
                                {<NumberFormat
                                  className="form-control"
                                  name="price_excl_vat"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  thousandsGroupStyle="thousand"
                                  decimalScale={2}
                                  //value={(plan_line_prices[i] && this.hasValue(plan_line_prices[i].formatted_price_excl_vat)) || plan_line_prices[i].formatted_price_excl_vat === 0 ? plan_line_prices[i].formatted_price_excl_vat : null}
                                  //value={plan_line_prices[i] && this.hasValue(plan_line_prices[i].formatted_price_excl_vat) ? plan_line_prices[i].formatted_price_excl_vat : null}
                                  value={plan_line_prices[i] && this.hasValue(plan_line_prices[i].price_excl_vat) ? plan_line_prices[i].price_excl_vat : null}
                                  //value={33}
                                  displayType="input"
                                  placeholder="Price excl. VAT"
                                  onValueChange={plan_line_prices[i] && !plan_line_prices[i]["incl_onFocus"] ? (e) => this.priceFormatter('price_excl_vat', i, e) : () => { }}
                                  onFocus={(e) => this.eventFocus(i, e)}
                                  onBlur={(e) => this.eventBlur(i, e)}
                                  allowLeadingZeros={true}
                                  disabled={
                                    plan_line_prices[i] && (this.hasValue(plan_line_prices[i].fixed_price) && this.hasValue(plan_line_prices[i].formatted_margin) && plan_line_prices[i].fixed_price)
                                      ? false
                                      : !!(plan_line_prices[i] && this.hasValue(plan_line_prices[i].formatted_margin) && plan_line_prices[i].formatted_margin && !plan_line_prices[i].fixed_price) || plan_line_prices[i].formatted_margin === 0
                                  }
                                />}
                              </td>
                              <td data-label="Price incl. VAT">
                                <NumberFormat
                                  className="form-control"
                                  name="price_incl_vat"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  thousandsGroupStyle="thousand"
                                  decimalScale={2}
                                  //value={plan_line_prices[i] && this.hasValue(plan_line_prices[i].formatted_price_incl_vat) ? plan_line_prices[i].formatted_price_incl_vat : null}
                                  value={plan_line_prices[i] && this.hasValue(plan_line_prices[i].price_incl_vat) ? plan_line_prices[i].price_incl_vat : null}
                                  displayType="input"
                                  placeholder="Price incl. VAT"
                                  onValueChange={plan_line_prices[i] && !plan_line_prices[i]["excl_onFocus"] ? (e) => this.priceFormatter('price_incl_vat', i, e) : (e) => { }}
                                  onFocus={(e) => this.eventFocus(i, e)}
                                  onBlur={(e) => this.eventBlur(i, e)}
                                  allowLeadingZeros={true}
                                  disabled={
                                    plan_line_prices[i] && (this.hasValue(plan_line_prices[i].fixed_price) && this.hasValue(plan_line_prices[i].formatted_margin) && plan_line_prices[i].fixed_price)
                                      ? false
                                      : !!(plan_line_prices[i] && this.hasValue(plan_line_prices[i].formatted_margin) && plan_line_prices[i].formatted_margin && !plan_line_prices[i].fixed_price) || plan_line_prices[i].formatted_margin === 0
                                  }
                                />
                              </td>
                              <td data-label="Margin">
                                <InputGroup>
                                  <NumberFormat
                                    className="form-control"
                                    name="margin"
                                    thousandSeparator={false}
                                    decimalSeparator=","
                                    thousandsGroupStyle="thousand"
                                    decimalScale={2}
                                    value={plan_line_prices[i] && this.hasValue(plan_line_prices[i].margin) ? plan_line_prices[i].margin : null}
                                    displayType="input"
                                    allowNegative={true}
                                    placeholder="Margin"
                                    onValueChange={(e) => this.priceFormatter('margin', i, e)}
                                    allowLeadingZeros={true}
                                    disabled={
                                      plan_line_prices[i] && (this.hasValue(plan_line_prices[i].fixed_price) && this.hasValue(plan_line_prices[i].formatted_margin) && plan_line_prices[i].formatted_margin)
                                        ? false
                                        : !!(plan_line_prices[i] && plan_line_prices[i].fixed_price) || plan_line_prices[i].fixed_price === 0
                                    }
                                  />
                                  <InputGroupAddon addonType="append">%</InputGroupAddon>
                                </InputGroup>
                              </td>
                            </React.Fragment>
                          }
                          <td className="text-right">
                            {selectedData &&
                              <React.Fragment>
                                {!editing
                                  ? <Edit2 size="26" className="p-1 hover-pointer" onClick={() => this.toggleEdit(i)} />
                                  : <Save size="26" className="p-1 hover-pointer" onClick={() => this.handleSave(i)} />
                                }
                                {plan_line_prices && plan_line_prices.length > 1 &&
                                  <Trash size="26" className="p-1 hover-pointer" onClick={() => this.removePlanLinePriceRow(i)} />}
                              </React.Fragment>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                {selectedData && hasLoaded && <Col xs="12" className="d-flex justify-content-end">
                  <PlusSquare size="26" className="p-1 hover-pointer mr-12px mt-2" onClick={() => this.addRow()} />
                </Col>}
              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-between">
              <span className="btn btn-danger" onClick={hide}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
              {/*<Button color="primary" type="submit" disabled={save_disabled}>Save</Button>*/}
              <Button color="primary" type="submit">
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </ModalFooter>
          </Form>
          <ZoomedHTMLScreen
            isOpen={zoomModalOpenNested}
            toggle={this.closeZoomModal}
            zoomedHtml={zoomedHtml}
            onChange={this.handleZoomedHtmlChange}
            onSave={this.handleSaveHTML}
          />
        </Modal>}
      </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader, 
  user: store.user
}))(withTranslation()(withRouter(PlanLineForm)));
