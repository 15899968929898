import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import languageEN from './locate/en/translate.json';
import languageNL from './locate/nl/translate.json';
import languageUS from './locate/us/translate.json';
import { format as formatDate, isDate } from "date-fns";
import { nl, enGB, enUS } from "date-fns/locale";

const languages = [
  'nl',
  'en',
  'us'
];

// date fns locales
const localesDateFns = {
  nl: nl,
  en: enGB,
  us: enUS
};

const currencies = {
  'nl': 'EUR',
  'en': 'GBP',
  'us': 'USD'
};

i18n.use(XHR).use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en: languageEN, nl: languageNL, us: languageUS
  }, /* default language when load the website in browser */
  lng: "nl", /* When react i18next not finding any language to as default in browser */
  fallbackLng: "nl", /* debugger For Development environment */
  debug: false,
  whitelist: languages,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
    format: (value, format, lng, options) => {
      if (isDate(value)) {
        const locale = localesDateFns[lng];

        if (format === "short")
          return formatDate(value, "P", { locale: locale });
        if (format === "long" || format === "formatLong")
          return formatDate(value, "PPPP", { locale: locale });

        return formatDate(value, format ? format : "P", { locale: locale });
      }

      if (format === 'uppercase') return value.toUpperCase();

      if (typeof value === "number") return new Intl.NumberFormat(format, {
        style: "currency", currency: currencies[lng]
      }).format(value);

      return value;
    }
  },
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
}).then(r => {
  console.log('i18n response: ', r);
});

// Keep the language in sync with i18ne xt
// by listening to the change language event.
i18n.on('languageChanged', newlocale => {
  console.log('languageChanged to: ', newlocale);
  console.log('languageChanged locale is set to: ', localesDateFns[newlocale]);
});

export default i18n;