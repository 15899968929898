import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {
  GetGadgets, 
  GetSubscriptionLinePrices, 
  GetSubscriptionLines
} from '../../../../controllers/subscriptions';
import SubscriptionLineForm from './Form';
import Prices from './Prices';
import Statuses from './Statuses';
import ParentTable from '../../../../components/ParentTable';
import {formatAmountLocale} from '../../../../redux/actions/formatActions';
import {mvLoaderToggle} from '../../../../redux/actions/mvLoaderActions';
import {loaderToggle} from '../../../../redux/actions/loaderActions';
import { row } from 'mathjs';

class SubscriptionLines extends React.Component {
  constructor(props) {
    super(props);

    const { dataPrices, subscription, relation, setIsUpdated } = props;

    this.state = {
      isLoadData: false,
      sizePerPage: 50,
      updatedListData: null,
      data: [],
      isSubscriptionLineUpdated: null,
      subscriptionList: [],
      subscription: subscription ? subscription : null,
      subscrLinesPrices: [],
      dataPrices: [],
      statusUrgentModalOpen: false,
      //subscriptionLineStatusUpdated: false,
      //subscriptionLineStatus: [],

      columns: [{
        dataField: "id",
        text: "#",
        formatter: this.formatLineStatus,
        headerStyle: { width: '5%', textAlign: 'center' },
        attrs: {
          'data-label': 'Status',
        }
      }, {
        dataField: "description",
        text: "Description",
        headerStyle: { width: '15%' },
        attrs: {
          'data-label': 'Description',
        }
      }, {
        dataField: "line_type.line_type",
        text: "Type",
        headerStyle: { width: '10%' },
        attrs: {
          'data-label': 'Type',
        }
      }, {
        dataField: "subscription_start",
        text: "Start",
        formatter: this.formatDate,
        headerStyle: { width: '12%' },
        attrs: {
          'data-label': 'Start',
        }
      }, {
        dataField: "subscription_stop",
        text: "End",
        formatter: this.formatDate,
        headerStyle: { width: '12%' },
        attrs: {
          'data-label': 'End',
        }
      }, {
        dataField: "current_line_price.price_excl_vat",
        text: "Price",
        align: "left",
        formatter: this.formatCurrency,
        headerStyle: () => {
          return { width: '20%', textAlign: 'left' };
        },
        attrs: {
          'data-label': 'Price',
        }
      }, {
        dataField: "current_line_price.price_valid_from",
        text: "Valid from",
        formatter: this.formatDate,
        headerStyle: { width: '20%' },
        attrs: {
          'data-label': 'Valid from',
        }
      }]
    }
  }

  updateDetails = (str) => {
    const {id, setIsUpdated} = this.props;
    let res = str === 'sub_line_update' ? this.props.updateDetails(id) : false;

    this.setState({ isSubscriptionLineUpdated: true });
    setIsUpdated(true);
  };

  getLinePrices = (id, row_id) => {
    (async () => {
      const [subscriptionLinesPrices] = await Promise.all(
          [
            GetSubscriptionLinePrices(null, id, row_id)
          ]);

      if (subscriptionLinesPrices.ok) {
        const {data} = subscriptionLinesPrices.data.data;

        let subscrLinesPrices = [];
        if (data && data.length > 0) {
           data.map(
              ({price_incl_vat, price_excl_vat, subscription_line_id}, index) => {
                if (subscription_line_id === row_id) {
                  return this.renderPriceData(price_excl_vat, price_incl_vat, index + 1 < data.length, index, row_id);
                }
              }
          );
        }
      }
    })();
  };

  formatLineStatus = (cell) => {
    //console.log("subscriptionLinesStatus - formatLineStatus - cell ->", cell);
    //console.log("subscriptionLinesStatus - formatLineStatus - row ->", row);

    return <Statuses id={cell} urgentModalStatus={this.setUrgentModalStatus} table="Subscription-lines" />
  }

  priceFormatter = (cell, row, rowIndex) => {
    const {id, t} = this.props;
    return <Prices id={id} rowId={row.id} t={t} lang={this.props.i18n.language} />
  };

  formatCurrency = (cell, row) => {
    let line_price_excl_vat = 0;
    let line_price_incl_vat = 0;

    const { current_line_price } = row;
    if (current_line_price) {
      if (current_line_price.price_excl_vat) {
        line_price_excl_vat = current_line_price.price_excl_vat;
      }

      if (current_line_price.price_incl_vat) {
        line_price_incl_vat = current_line_price.price_incl_vat;
      }
    }

    return this.formatTotalVatStr(line_price_excl_vat, line_price_incl_vat);
  };

  formatTotalVatStr = (line_price_excl_vat, line_price_incl_vat) => {
    const {t} = this.props;
    return <span>{t("currency_format", {number: Number(line_price_excl_vat)}) +' / '+ t("currency_format", {number: Number(line_price_incl_vat)})}</span>
  };

  formatDate = (cell) => {
    const {t} = this.props;
    if (cell) {
      return <span className="date-format" style={{ paddingLeft: "0px" }}>{t("date_format", { date: new Date(cell) })}</span>
    } else {
      return <span>{String.fromCharCode(8212)}</span>
    }
  };

  validFormatter = (cell, row, rowIndex) => {
    const line_prices = this.props.dataPrices && this.props.dataPrices.data && this.props.dataPrices.data.data ? this.props.dataPrices.data.data : [];
    if (line_prices && line_prices.length > 0) {
      return line_prices && line_prices.length > 0 &&
          line_prices.map(({price_valid_from, subscription_line_id}, index) => {
            if (subscription_line_id === row.id) {
              return this.renderData(price_valid_from,
                  index + 1 < line_prices.length, index)
            }
            return null;
          });
    } else {
      return <span>{String.fromCharCode(8212)}</span>
    }
  };

  renderData = (data, bool, index) => {
    const {t} = this.props;
    return <React.Fragment key={index}>
      <span className={`subscr-dates`}><span>{t("date_format", { date: new Date(data) })}</span></span>
      {bool && <br />}
    </React.Fragment>
  };

  renderPriceData = (price_excl_vat, price_incl_vat, bool, index, row_id) => {
    const {t} = this.props;
    const price_incl_tax = price_incl_vat ? price_incl_vat : 0;
    const price_excl_tax = price_excl_vat ? price_excl_vat : 0;

    return <React.Fragment key={index}>
      <span id={`line-price-${row_id}`} className={`subscr-prices`}>
        <span className={`subscr-price-${index}`}>{t("currency_format", { number: Number(price_excl_tax) })}</span> / <span>{formatAmountLocale(price_incl_tax, this.props.i18n.language)}</span>
      </span>
      {bool && <br />}
    </React.Fragment>
  };

  colspanForLastRow = (cell, row, rowIndex, colIndex) => {
    return { colSpan: `2`, 'data-label': 'Price' }
  };
  hiddenColForLastRow = (cell, row, rowIndex, colIndex) => {
    return { hidden: true, 'data-label': 'Valid from' }
  };

  toggleOlderPrices = async ({ target: { checked } }) => {
    this.setState({ columns: null });

    if (checked) {
      let columns = [{
        dataField: "id",
        text: "#",
        formatter: this.formatLineStatus,
        headerStyle: { width: '5%', textAlign: 'center' },
        attrs: {
          'data-label': 'Status',
        }
      }, {
        dataField: "description",
        text: "Description",
        headerStyle: { width: '15%' },
        attrs: {
          'data-label': 'Description',
        }
      }, {
        dataField: "line_type.line_type",
        text: "Type",
        headerStyle: { width: '10%' },
        attrs: {
          'data-label': 'Type',
        }
      }, {
        dataField: "subscription_start",
        text: "Start",
        formatter: this.formatDate,
        headerStyle: { width: '12%' },
        attrs: {
          'data-label': 'Start',
        }
      }, {
        dataField: "subscription_stop",
        text: "End",
        formatter: this.formatDate,
        headerStyle: { width: '12%' },
        attrs: {
          'data-label': 'End',
        }
      }, {
        dataField: "null1",
        text: "Price",
        formatter: this.priceFormatter,
        align: "left",
        classes: 'last-cell subscription-line-prices-formatter',
        headerStyle: () => {
          return { width: '160px', textAlign: 'left' };
        },
        attrs: this.colspanForLastRow
      }, {
        dataField: "null2",
        text: "Valid from",
        //formatter: this.validFormatter,
        classes: 'last-cell subscription-valid-from-formatter',
        attrs: this.hiddenColForLastRow,
        headerStyle: { width: '140px' }
      }];

      this.setState({columns: columns});
    } else {
      let columns = [{
        dataField: "id",
        text: "#",
        formatter: this.formatLineStatus,
        headerStyle: { width: '5%', textAlign: 'center' },
        attrs: {
          'data-label': 'Status',
        }
      }, {
        dataField: "description",
        text: "Description",
        headerStyle: { width: '15%' },
        attrs: {
          'data-label': 'Description',
        }
      }, {
        dataField: "line_type.line_type",
        text: "Type",
        headerStyle: { width: '10%' },
        attrs: {
          'data-label': 'Type',
        }
      }, {
        dataField: "subscription_start",
        text: "Start",
        formatter: this.formatDate,
        headerStyle: { width: '12%' },
        attrs: {
          'data-label': 'Start',
        }
      }, {
        dataField: "subscription_stop",
        text: "End",
        formatter: this.formatDate,
        headerStyle: { width: '12%' },
        attrs: {
          'data-label': 'End',
        }
      }, {
        dataField: "current_line_price.price_excl_vat",
        text: "Price",
        formatter: this.formatCurrency,
        align: "left",
        headerStyle: () => {
          return { width: '160px', textAlign: 'left' };
        },
        attrs: {
          'data-label': 'Price',
        }
      }, {
        dataField: "current_line_price.price_valid_from",
        text: "Valid from",
        formatter: this.formatDate,
        headerStyle: { width: '140px' },
        attrs: {
          'data-label': 'Valid from',
        }
      }];

      this.setState({columns: columns});
    }
  };

  setUrgentModalStatus = (status) => {
    //console.log("Subscriptionline - status ->", status);
    this.setState({statusUrgentModalOpen: status});
  }

  dataLoaded = (data) => {
    (async () => {
      if (data) {
        let subscription_line_prices = [];
        Object.values(data).map((v, i) => {

          if (subscription_line_prices &&
              (subscription_line_prices[i] ===
                  undefined)) {

              subscription_line_prices[i] = {
                'id': this.props.id,
                'subscription_line_id': v.id,
                'margin': v.margin,
                'fixed_price': v.current_line_price.price_excl_vat,
                'parent_plan_line_id': v.parent_plan_line_id,
                'price_excl_vat': v.current_line_price.price_excl_vat,
                'price_incl_vat': v.current_line_price.price_incl_vat,
                'price_valid_from': v.current_line_price.price_valid_from,
                'prices': []
              }
          }
          return subscription_line_prices;
        });

        this.setState({
          subscrLinesPrices: subscription_line_prices,
          subscrPrices: this.props.subscription && this.props.subscription.subscrPrices ? this.props.subscription.subscrPrices : [],
          subscription: this.props.subscription ? this.props.subscription : null,
          hasLoaded: true,
          isLoadData: true,
          isSubscriptionLineUpdated: false
        });
      }
    })();

    this.props.setIsListUpdated(true);
  };

  componentDidMount() {
    const { subscription } = this.props;

    this.setState({
      isSubscriptionLineUpdated: false,
      subscriptionList: subscription.subscriptionList,
    });

    const { updatedListData } = this.state;
    if (!updatedListData) {
        this.updateSubLineDataTable();
    }

  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, subscription, isUpdated, id, setIsUpdated, tableLoaded } = this.props;
    const { subscrLinesPrices, isSubscriptionLineUpdated, columns, data } = this.state;

    //console.log("Subscriptions ListDetails - componentDidUpdate - subscriptionLine isUpdated !!!!!!! ->", isUpdated);
    //console.log("Subscriptions ListDetails - componentDidUpdate - subscriptionLineStatus isUpdated !!!!!!! ->", subscriptionLineStatus);

    if (isUpdated) {
      this.getData();
      setIsUpdated(true); //false
    }

    if (prevState.subscrLinesPrices && prevState.subscrLinesPrices.length > 0 && prevState.subscrLinesPrices !== subscrLinesPrices) {
      // subscrLinesPrices are updated
      this.props.updateDetails();
    }
  };

  updateSubLineDataTable = () => {
    this.getData();
  };

  getData = () => {
    const { dispatch, id, loader } = this.props;
    const { sizePerPage } = this.state;

    let param = {
      page: 1,
      offset: sizePerPage ? sizePerPage : 50
    };

    (async () => {
      //dispatch(mvLoaderToggle(true));
      //if (!loader) {
        dispatch(loaderToggle(true));
      //}

      await GetSubscriptionLines(param, id)
      .then(res => {
        if (res.ok) {
          const { data } = res;

          this.setState({
            updatedListData: data.data,
            data: data.data
          });
        }
      })
      .catch(e => {
        console.log('Error', e);
      });

      //dispatch(mvLoaderToggle(false));
      dispatch(loaderToggle(false));

    })();
  };

  render() {
    const { id, /*data,*/ isDisabled, isLoadData, isUpdated, setIsUpdated } = this.props,
          { data, columns, sizePerPage, updatedListData, statusUrgentModalOpen } = this.state;

    //console.log("Subscriptions ListDetails - render - data ->", data);
    //console.log("Subscriptions ListDetails - render - statusUrgentModalOpen ->", statusUrgentModalOpen);

    return (
      <React.Fragment>
        <ParentTable
          id={id}
          keyField={"id"}
          remote
          table="Subscription-lines"
          language={this.props.i18n && this.props.i18n.language ? this.props.i18n.language : 'nl'}
          sizePerPage={sizePerPage} //50
          classes="custom-tbl-invoice-ls table table-responsive"
          //data={updatedListData && updatedListData.length > 0 ? updatedListData : GetSubscriptionLines}
          data={data}
          rawData={data}
          isLoadData={isLoadData ? isLoadData : false}
          selectRow={`row-subscription-line-${id}`}
          include="product"
          columns={columns}
          headerCheckBox={{
            label: 'Show all prices',
            function: this.toggleOlderPrices
          }}
          headerDropdownOpts={!isDisabled && [{
            label: 'Add',
            action: SubscriptionLineForm
          }]}
          asyncRowDropdownOpts={!isDisabled && [{
            get: GetGadgets,
            condition: {
              data: 'has_gadget'
            }
          }]}
          //rowAction={!isDisabled && 'edit'} // => original
          rowAction={statusUrgentModalOpen || (!isDisabled && 'edit')} // => if statusUrgentModalOpen -> modals are disabled
          rowActionForm={!isDisabled && SubscriptionLineForm}
          cacheSearch={false}
          noSearch={true}
          wrapperClasses="table-subscription-lines"
          dataLoaded={this.dataLoaded}
          notLoadedByDefault={false}
          noDataIndication="No subscription lines found."
          isRawData
          updateTableRawData={this.updateSubLineDataTable}
          noHover={isDisabled}
          //offLoader
          //noLoader={false}
          //offLoader={false}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({dispatch, subscription, relation, loader}) => ({dispatch, subscription, relation, loader});

export default withRouter(withTranslation()(connect(mapStateToProps)(SubscriptionLines)));
