import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Details from "../../components/Details";
import { GetCountries, GetRelation, GetRelationsTenant } from '../../controllers/relations';
import { GetBrand, GetTenant, GetTenantLists, GetTenantUsers, UpdateTenant } from '../../controllers/tenants';
import DetailsPage from "../../layouts/DetailsPage";
import Accounts from './accounts/List';
import ApiKeys from './api_keys/List';
import Branding from './branding/Branding';
import FiscalYears from './fiscal_years/List';
import Groups from './groups/List';
import Journals from './journals/List';
import NumberRanges from './number_ranges/List';
import PaymentCondition from './payment_condition/List';
import EmailTemplates from './templates/Email/List';
import PDFTemplates from './templates/PDF/List';
import ProvisioningTemplates from './provisioning/List';
import VatCodes from './vat_code/List';

class TenantDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locationKeys: [],
            tenant_name: null,
            tenant_relation: null,
            tabs: {},
            tenant_favicon: null,
            brand: {}
        };
    }

    getUpdates = (id, data, defaultData) => {
      console.log("Tenant details - getUpdates - id ->", id);
      console.log("Tenant details - getUpdates - data ->", data);
      console.log("Tenant details - getUpdates - defaultData ->", defaultData);
    };

    getRelationById = (relation_id) => {
        if (relation_id) {
            (async () => {
                const [getRelation] = await Promise.all([
                    GetRelation(null, relation_id)
                ]);

                //console.log("Tenant details - getRelationById - relation_id ->", relation_id);
                //console.log("Tenant details - getRelationById - getRelation ->", getRelation);

                await (async () => {
                    if (getRelation.ok) {
                        let res = getRelation.data.data;
                        const {relation} = res;
                        //console.log("Tenant details - getRelationById - getRelation - res ->", res);
                        //console.log("Tenant details - getRelationById - getRelation - relation ->", relation);

                        let selectedRelation = [
                            {
                                label: relation_id + " | " +
                                    relation.customer_name + " | " +
                                    res.billing_address,
                                value: relation.id, //relation.id,
                                //relation.primary_person_full_name
                            }
                        ];

                        await this.setState(
                            {tenant_relation: selectedRelation});

                        //return selectedRelation;
                    }
                })();
            })();
        }
    };

    getParentTenantRelationsByParentId = (parent_id) => {
        if (parent_id) {
            (async () => {
                const [getRelationsList] = await Promise.all([
                    GetTenantUsers(null, parent_id)
                ]);

               // console.log("Tenant details - getRelationById - parent_id ->", parent_id);
               // console.log("Tenant details - getRelationById - getRelationsList ->", getRelationsList);

                await (() => {
                    if (getRelationsList.ok) {
                        let relationsList = [];
                        getRelationsList.data.data.map(({name, primary_person_full_name, id, customer_number}) => {
                            return relationsList.push({
                                label: name,
                                value: id,
                            })
                        });
                        this.setState({ relationsList });
                    }
                })();
            })();
        }
    };

    componentDidMount() {
        //console.log("Tenant details - props ->", this.props);

        (async () => {
            const { id } = this.props.match.params;

            const [getTenants, getCountries, getBrand/*, getRelationsList, getTenantRelations*/] = await Promise.all([
                GetTenantLists(),
                GetCountries(),
                GetBrand(null, id),
                //GetRelationList()
            ]);

            // console.log("Tenant details - getRelationsList ->", getRelationsList);

            let tenantOpts = [],
                selectedTenant = [],
                relation_tenant,
                relation_tenant_selected = [],
                relationsList = [],
                selectedRelationInList,
                countryOpts = [{
                    label: '—',
                    value: ''
                }];

            await (async () => {
                if (getTenants.ok) {
                    const tenants = getTenants.data.data;
                    tenants.map(({name, id}) => {
                        if (parseInt(this.props.match.params.id) !==
                            parseInt(id)) {
                            return tenantOpts.push({
                                label: name, value: id
                            })
                        } else {
                            return false
                        }
                    });

                    tenants.map(({name, id, relation_id, parent_id}) => {
                        if (parseInt(this.props.match.params.id) ===
                            parseInt(id)) {
                            selectedTenant.label = name;
                            selectedTenant.id = id;
                            selectedTenant.relation_id = relation_id;
                            selectedTenant.parent_id = parent_id;
                            return selectedTenant;
                        } else {
                            return false;
                        }
                    });
                }

                //console.log("Tenant details - selectedTenant ->", selectedTenant);

                if (selectedTenant && selectedTenant.relation_id) {
                    const [getRelation] = await Promise.all([
                        GetRelation(null, selectedTenant.relation_id)
                    ]);

                    await (() => {
                        if (getRelation.ok) {
                            let res = getRelation.data.data;
                            //console.log("Tenant details - res ->", res);

                            const { relation } = res;

                            let address = res.billing_address ? res.billing_address : String.fromCharCode(8212);
                            let relation_name = relation && relation.customer_name ? relation.customer_name : String.fromCharCode(8212);
                            let company_name = res.company_name ? res.company_name : String.fromCharCode(8212);
                            let relation_customer_ids = `Relation ID: ${selectedTenant.relation_id} | Customer NR: ${res.customer_number}`;

                            relation_tenant = {
                                label: relation_customer_ids + " | " + company_name + " | " + relation_name,
                                value: relation.id,
                            };
                            relation_tenant_selected = [{
                                label: relation_customer_ids + " | " + company_name + " | " + relation_name,
                                value: relation.id,
                            }];
                        }
                    })();
                }

                if (selectedTenant && selectedTenant.parent_id) {
                    (async () => {
                        const [getRelationsTenantList] = await Promise.all([
                            GetRelationsTenant(selectedTenant.parent_id)
                        ]);

                        //console.log("Tenant details - getRelationById - parent_id ->", selectedTenant.parent_id);
                        //console.log("Tenant details - getRelationById - getRelationsTenantList ->", getRelationsTenantList);

                        await (() => {
                            if (getRelationsTenantList.ok) {
                                let relationsList = [];
                                getRelationsTenantList.data.data.map(({name, primary_person_full_name, id, customer_number}) => {
                                    return relationsList.push({
                                        label: name,
                                        value: id,
                                    })
                                });
                                //console.log("Tenant details - getRelationById - getRelationsTenantList ->", relationsList);
                            }
                        })();
                    })();
                }

                if (getCountries.ok) {
                    const countries = getCountries.data.data;
                    countries.length > 0 && countries.map(({name, id}) => {
                        return countryOpts.push({
                            label: name,
                            value: id
                        })
                    });

                    this.setState({countryOpts});
                }

                if (getBrand.ok) {
                    let brandData = getBrand.data.data;

                    //console.log("Tenant details - brandData ->", brandData);
                    if (brandData && brandData.favicon && brandData.favicon !==
                        "") {
                        this.setState({tenant_favicon: brandData.favicon});
                    }
                }
            })();

            const tenantDetails = [{
                label: 'Name',
                data: 'name',
                type: 'text'
            }, {
                label: 'Parent tenant',
                data: 'parent_id',
                type: 'select',
                opts: tenantOpts
            }, {
                label: 'Relation ID',
                data: 'relation_id',
                //type: 'select',
                type: 'text',
                style: 'text_style',
                extra_data: relation_tenant,
                opts: relationsList,
                readonly: true,
                isAsync: {
                    api: GetRelationsTenant,
                    label: 'name',
                    value: 'id',
                    optionLabel: ['name'],
                    getOptionLabel: ({ id, name, company_name, primary_person_full_name }) => {
                        //console.log("tenantDetails - getOptionLabel - id ->", id);
                        //console.log("tenantDetails - getOptionLabel - name ->", name);
                        //console.log("tenantDetails - getOptionLabel - company_name ->", company_name);
                        //console.log("tenantDetails - getOptionLabel - primary_person_full_name ->", primary_person_full_name);
                        return `${id ? id : String.fromCharCode(8212)}${primary_person_full_name ? " | " + primary_person_full_name : String.fromCharCode(8212)}`
                    }
                },
                update: this.getUpdates,
            }, {
                label: 'Default Country',
                data: 'default_country_id',
                type: 'select',
                opts: countryOpts,
                isClearable: true
            }, {
                label: 'Service url',
                data: 'service_url',
                type: 'text'
            }, {
                label: 'Service number',
                data: 'service_number',
                type: 'text'
            }, {
                label: 'Service email',
                data: 'service_email',
                type: 'text'
            }, {
                label: 'Billing day',
                data: 'billing_day',
                type: 'text',
                style: 'text_style'
            }, {
                label: 'Billing schedule',
                data: 'billing_schedule',
                type: 'text',
                style: 'text_style'
            }, {
                label: 'Name for invoicing',
                data: 'invoicing_name',
                type: 'text',
                style: 'text_style'
            }, {
                label: 'Invoice start calculation',
                data: 'invoice_start_calculation',
                type: 'datepicker'
            }, {
                label: 'Use accounting',
                data: 'use_accounting',
                type: 'select',
                opts: [{
                    value: 1,
                    label: "Yes"
                }, {
                    value: 0,
                    label: "No"
                }]
            }, {
                label: 'Identifier',
                data: 'identifier',
                type: 'text'
            }];

            await this.setState({
                tenantOpts,
                details: tenantDetails,
            });

            let tenant_name = selectedTenant.label;

            localStorage.setItem('selected_tenant_title_name', tenant_name);

            //console.log("Tenant details - selectedTenant ->", selectedTenant);
            //console.log("Tenant details - tenantDetails ->", tenantDetails);
            //console.log("Tenant details - tenantDetails ->", tenantDetails);

            this.setState({
                tabs: [{
                    title: 'Details',
                    tenant_name: tenant_name,
                    component: <Details
                                name="Tenant"
                                getApi={GetTenant}
                                updateApi={UpdateTenant}
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-0`}
                                include='parent'
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | Details`}
                                icon={`info-circle`}
                                details={tenantDetails}
                    />
                }, {
                    title: 'Number ranges',
                    tenant_name: tenant_name,
                    component: <NumberRanges
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-1`}
                                icon={`adjust`}
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | Number ranges`}
                    />
                }, {
                    title: 'Payment conditions',
                    tenant_name: tenant_name,
                    component: <PaymentCondition
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-2`}
                                icon={`hand-point-up`}
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | Payment conditions`}
                    />
                }, {
                    title: 'Accounts',
                    tenant_name: tenant_name,
                    component: <Accounts
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-3`}
                                icon={`question-circle`}
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | Accounts`}
                    />
                }, {
                    title: 'VAT codes',
                    tenant_name: tenant_name,
                    component: <VatCodes
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-4`}
                                icon={`balance-scale`}
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | VAT codes`}
                    />
                }, {
                    title: 'Fiscal years',
                    tenant_name: tenant_name,
                    component: <FiscalYears
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-5`}
                                icon={`calendar`}
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | Fiscal years`}
                    />
                }, {
                    title: 'Journals',
                    tenant_name: tenant_name,
                    component: <Journals
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-6`}
                                icon={`archive`}
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | Journals`}
                    />
                }, {
                    title: 'Groups',
                    tenant_name: tenant_name,
                    component: <Groups
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-7`}
                                icon={`user-circle`}
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | Groups`}
                    />
                }, {
                    title: 'Branding',
                    tenant_name: tenant_name,
                    component: <Branding
                                id={id}
                                clickId={`f2x-tenant-tab-list-item-8`}
                                icon={`star`}
                                tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                                title={`Tenant details ${tenant_name}`}
                                tabTitle={`GRiD | ${tenant_name} | Branding`}
                    />
                }, {
                    title: 'Api Keys',
                    tenant_name: tenant_name,
                    component: <ApiKeys
                        id={id}
                        clickId={`f2x-tenant-tab-list-item-9`}
                        icon={`link`}
                        tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                        title={`Tenant details ${tenant_name}`}
                        tabTitle={`GRiD | ${tenant_name} | Api Keys`}
                    />
                }, {
                    title: 'Email templates',
                    tenant_name: tenant_name,
                    component: <EmailTemplates
                        id={id}
                        clickId={`f2x-tenant-tab-list-item-10`}
                        icon={`portrait`}
                        tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                        title={`Tenant details ${tenant_name}`}
                        tabTitle={`GRiD | ${tenant_name} | Email templates`}
                    />
                }, {
                    title: 'PDF templates',
                    tenant_name: tenant_name,
                    component: <PDFTemplates
                        id={id}
                        clickId={`f2x-tenant-tab-list-item-11`}
                        icon={`info-circle`}
                        tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                        title={`Tenant details ${tenant_name}`}
                        tabTitle={`GRiD | ${tenant_name} | PDF templates`}
                    />
                }, {
                    title: 'Provisioning templates',
                    tenant_name: tenant_name,
                    component: <ProvisioningTemplates
                        id={id}
                        clickId={`f2x-tenant-tab-list-item-12`}
                        icon={`desktop`}
                        tenantId={selectedTenant && selectedTenant.id ? selectedTenant.id : null}
                        title={`Tenant details ${tenant_name}`}
                        tabTitle={`GRiD | ${tenant_name} | Provisioning templates`}
                    />
                }]
            })
        })();
    }

    componentDidUpdate(prevProps) {
        let { id } = this.props.match.params;
        id = parseInt(id);
        const { details } = this.state;

        //console.log("Tenant details - selectedTenant ->", details);
    }

    render() {
        const { tenantOpts, tabs, tenant_favicon, relationsList, tenant_relation } = this.state;
        //console.log("Tenant details - relationsList ->", relationsList);
        //console.log("Tenant details - tenant_relation ->", tenant_relation);

        return (
            <React.Fragment>
                {tenantOpts && tabs &&
                <DetailsPage
                    name="Tenant"
                    tabs={tabs}
                    id="f2x-tenant-tabs"
                    tenantFavicon={tenant_favicon}
                    tenantRelation={tenant_relation}
                />
                }
            </React.Fragment>
        )
    }
}

export default connect()(withTranslation()(withRouter(TenantDetails)));
