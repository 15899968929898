import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Label } from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

class SerialsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formType: this.props.rowAction,
            formName: 'serials',
            serial: null,
            mac_address: null,
            product_description: null,
        };

        this.toggle = this.toggle.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    toggle() {
        this.props.hide()
    }

    update(val) {
        this.props.update(val)
    }

    handleFileChange(e) {
        const serials = e.target,
            { name, value } = serials;
            serials[name] = value;

        console.log(serials[name]);
        this.setState({
            serials
        })
    }

    handleSubmit() {}

    componentDidMount() {
        if (this.props.selectedData.id) {
            console.log('props', this.props.selectedData);
            this.setState({
                serial: this.props.selectedData.id.serial,
                mac_address: this.props.selectedData.id.mac_address,
                product_description: this.props.selectedData.description,
            })
        }
    }

    render() {
        return (
            <Modal isOpen={ this.props.show } toggle={ this.toggle } centered>
                <AvForm onSubmit={this.handleSubmit}>
                    <ModalHeader>
                        <span className={`text-truncate`}>{this.state.formType} {this.state.formName} {this.state.product_description ? ` | ${this.state.product_description}` : ""}</span>
                    </ModalHeader>
                    <ModalBody className="mt-3 mb-3">
                        <div className="form-row">
                            <Col md={2}>
                                <label className="col-form-label">Serials:</label>
                            </Col>
                            <Col md={5}>
                                <Label className="btn btn-secondary btn-block">
                                    Upload serials
                                    <AvInput
                                        type="file"
                                        name="serial"
                                        value= {this.state.serial}
                                        onChange={ (e) => this.handleFileChange(e) }
                                        hidden
                                    />
                                </Label>
                            </Col>
                            <Col md={5}>
                                <Label className="btn btn-secondary btn-block">
                                    Upload mac address
                                    <AvInput
                                    type="file"
                                    name="mac_address"
                                    value= {this.state.mac_address}
                                    onChange={ (e) => this.handleFileChange(e) }
                                    hidden
                                    />
                                </Label>
                            </Col>
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <span className="btn btn-danger" onClick={this.toggle}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                        <Button color="primary">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        );
    }
}

export default connect(store => ({
    loader: store.loader
  }))(SerialsForm);
