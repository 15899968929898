import React, {useEffect, useState} from 'react';
import {GetPlanLinePrices, GetPlanLine, GetSinglePlanLinePrices, GetSinglePlanLinePricesOpts} from '../../../controllers/plans';
import {formatAmountLocale} from '../../../redux/actions/formatActions';
import {Modal, ModalHeader, ModalBody, Row, Col, Table} from "reactstrap";

const Prices = (props) => {
  //console.log("planLines - Prices - props ->", props);

  const [priceList, setPriceList] = useState([]);
  const [noList, setNoList] = useState(false);
  const [isLoadingPricesItems, setIsLoadingPricesItems] = useState(false);

  const t = props.t;
  const lang = props.lang;

  useEffect(() => {
    const {id, rowId} = props;

    setIsLoadingPricesItems(true);

    (async () => {
      const [planLinesPrices] = await Promise.all(
          [
            //GetPlanLinePrices(id, rowId)
            //GetSinglePlanLinePrices(rowId)
            GetSinglePlanLinePricesOpts(rowId)
          ]);

      //console.log(`planLines list - getLinePrices - id ->`, id);
      //console.log(`planLines list - getLinePrices - row_id ->`, rowId);
      console.log(`planLines list - getLinePrices - planLinesPrices ->`, planLinesPrices);

      if (planLinesPrices.ok) {
        const {data} = planLinesPrices.data;
        console.log(`planLines list - getLinePrices - data ->`, data);
        //console.log(`planLines list - getLinePrices - data.plan_line_prices ->`, data.plan_line_prices);

        //if (data && data.plan_line_prices && data.plan_line_prices.length > 0) {
        if (data && data.length > 0) {
          //console.log("planLines - Prices - priceList (rowId) ->", rowId, data.plan_line_prices);
          setPriceList(data);
        } else if (data && data.length === 0) {

          // add empty item
          let emptyArr = [];
          emptyArr.push({ id: rowId, price_valid_from: new Date(), price_excl_vat: 0, price_incl_vat: 0 });

          setPriceList(emptyArr);

        } else {
          setNoList(true);
        }
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoadingPricesItems(false);
  }, [priceList]);

  return (
      priceList && priceList.length > 0 ?
        priceList.map((item, key) =>
        <div key={item.id}>
          <Row id={`line-price-${item.id}`} className={`plan-prices`}>
            <Col>
              {/*<span className={`subscr-price-${key}`}>{t("currency_format", { number: Number(item.price_excl_vat) })}</span> / <span>{formatAmountLocale(item.price_incl_vat, lang)}</span>*/}
              <span className={`plan-line-price-${item.id}`}>{t("currency_format", { number: Number(item.price_excl_vat) })}</span> / <span>{t("currency_format", { number: Number(item.price_incl_vat) })}</span>
            </Col>
            <Col className="pl-0">
              <span className={`plan-line-date-${item.id}`}>
                <span>{t("date_format", { date: new Date(item.price_valid_from) })}</span>
              </span>
            </Col>
          </Row>
        </div>
      ) :
      null
  );
};

export default Prices;
