import CONSTANTS from '../services/constants'

export const invoiceStatusObj = (value) => CONSTANTS.invoiceStatuses.find(r => r.value === value);
export const subscriptionStatusObj = (value) => CONSTANTS.subscriptionStatus.find(r => r.value === value);

export const stringFilter = (str, qry) => qry.split(' ').every(r => str.toLowerCase().includes(r.toLowerCase()));

export const isNumeric = (d) => {
  const regex = /^[\d]+$/gi;
  return regex.test(d);
};

// reversed validation x))
export const isInvalidChars = (d) => {
  const regex = /^(.|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/gm;
  return regex.test(d);
};

export function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
      navigator.languages === undefined
          ? [navigator.language]
          : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
  });
}

export function getBrowserLocale(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocale = navigator.language;
  console.log("browserLocale - navigator.language ->", navigator.language);

  if (!browserLocale) {
    return undefined;
  }

  return browserLocale;
}

/*
 Clear local storage
 param - exclude => is expected to be an array of keys you wish to exclude from being deleted
 */
export function clearLocalStorage(exclude) {
  for (let i = 0; i < localStorage.length; i++){
    let key = localStorage.key(i);

    if (exclude && exclude.indexOf(key) === -1) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }
}

/*
 Clear session storage
 param - exclude => is expected to be an array of keys you wish to exclude from being deleted
 */
export function clearSessionStorage(exclude) {
  console.log("clearSessionStorage - exclude ->", exclude);
  console.log("clearSessionStorage - sessionStorage ->", sessionStorage);
  console.log("clearSessionStorage - sessionStorage.length ->", sessionStorage.length);

  //sessionStorage.clear();

  for (let i = 0; i < sessionStorage.length; i++){
    let key = sessionStorage.key(i);

    if (exclude && exclude.indexOf(key) === -1) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }
}

/*
 Clear all session storage
 */
export function clearCompleteSessionStorage() {
  console.log("clearSessionStorage - sessionStorage ->", sessionStorage);
  sessionStorage.clear();
}