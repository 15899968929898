import React from "react";
import { withTranslation } from 'react-i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { connect } from "react-redux";
import { User, BarChart2, Layers } from "react-feather";
import { Link, withRouter } from "react-router-dom";
import { createSelector } from 'reselect';
import { Collapse, Navbar, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { updateError } from "../redux/actions/errorActions";
import AuthService from '../../components/services/authService';
import SolrSearch from './SolrSearch';
import SolrSearchExpand from './SolrSearchExpand';
import { nl, enGB, enUS } from "date-fns/locale";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

registerLocale('nl', nl);
registerLocale('en', enGB);
registerLocale('us', enUS);

setDefaultLocale('nl');

const Auth = new AuthService();
const isDisabled = false; // my account button disabled

class NavbarComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      language: this.state && this.state.language ? this.state.language : "nl",
      my_account: [],
    };
  }

  onLanguageHandle = (event) => {
    //console.log(event);
    let newLang = event.target.value ? event.target.value : event.target.id ? event.target.id : 'nl';
    this.setState({ language: newLang });
    setDefaultLocale(newLang);
    this.props.i18n.changeLanguage(newLang).then(r => console.log(r));
  };

  renderDropdownButtons = () => {
    return (
      <UncontrolledDropdown nav inNavbar id={`nav-menu-language`}>
        {this.state.language &&
          <React.Fragment>
            <span className="d-inline-block">
              <DropdownToggle className="d-inline-block dropdown-language" nav caret id={`menu-language-toggle`} data-toggle="dropdown">
                {this.state.language === 'nl' &&
                  <img src={`/assets/flags/nl.png`} width={20} alt={this.state.language} />}
                {this.state.language === 'en' &&
                  <img src={`/assets/flags/en.png`} width={20} alt={this.state.language} />}
                {this.state.language === 'us' &&
                  <img src={`/assets/flags/us.png`} width={20} alt={this.state.language} />}
              </DropdownToggle>
            </span>
            <DropdownMenu right id={`menu-language`} aria-labelledby={`menu-language-toggle`}>
              {this.state.language === 'us' &&
                <DropdownItem name="language" id={`en`} onClick={(e) => this.onLanguageHandle(e)}><img src={`/assets/flags/en.png`} width={20} alt={`EN`} /> &nbsp; English</DropdownItem>}
              {this.state.language === 'us' &&
                <DropdownItem name="language" id={`nl`} onClick={(e) => this.onLanguageHandle(e)}><img src={`/assets/flags/nl.png`} width={20} alt={`NL`} /> &nbsp; Nederlands </DropdownItem>}

              {this.state.language === 'en' &&
                <DropdownItem name="language" id={`nl`} onClick={(e) => this.onLanguageHandle(e)}><img src={`/assets/flags/nl.png`} width={20} alt={`NL`} /> &nbsp; Nederlands </DropdownItem>}
              {this.state.language === 'en' &&
                <DropdownItem name="language" id={`us`} onClick={(e) => this.onLanguageHandle(e)}><img src={`/assets/flags/us.png`} width={20} alt={`US`} /> &nbsp; United States </DropdownItem>}

              {this.state.language === 'nl' &&
                <DropdownItem name="language" id={`en`} onClick={(e) => this.onLanguageHandle(e)}><img src={`/assets/flags/en.png`} width={20} alt={`EN`} /> &nbsp; English </DropdownItem>}
              {this.state.language === 'nl' &&
                <DropdownItem name="language" id={`us`} onClick={(e) => this.onLanguageHandle(e)}><img src={`/assets/flags/us.png`} width={20} alt={`US`} /> &nbsp; United States </DropdownItem>
              }
            </DropdownMenu>
          </React.Fragment>}
      </UncontrolledDropdown>
    )
  };

  renderRadioButtons = () => {
    return (
      <NavItem>
        <div className="nav-lang-switch">
          <input
            checked={this.state.language === 'us'}
            name="language"
            onChange={(e) => this.onLanguageHandle(e)}
            value="us"
            type="radio" /> &nbsp;United States &nbsp;
          <input
            checked={this.state.language === 'en'}
            name="language"
            onChange={(e) => this.onLanguageHandle(e)}
            value="en"
            type="radio" /> &nbsp;English &nbsp;
          <input name="language"
            value="nl"
            checked={this.state.language === 'nl'}
            type="radio"
            onChange={(e) => this.onLanguageHandle(e)} /> &nbsp;Nederlands &nbsp;
        </div>
      </NavItem>
    )
  };

  handleLogout = () => {
    this.props.dispatch(updateError(false));
    Auth.logout();
    document.title = process.env.REACT_APP_NAME
  };

  goToAccount = (e, account) => {
    e.preventDefault();

    console.log("NavBar - goToAccount - click account ->", account);
    console.log("NavBar - goToAccount - this.props ->", this.props);

    this.props.history.push(`/relations/${account}/details`);
    //window.location = `/relations/${account}/details`;

  };

  goToSettings = (e) => {
    // "/settings"
    this.props.history.push(`/settings`);
  };

  componentDidMount() {
    const { navbarChildRef } = this.props;
    navbarChildRef(this);

    const { user } = this.props;

    if (localStorage.getItem("relation_ids")) {
      let relationIdsArr = [];

      const relation_ids = localStorage.getItem("relation_ids");
      //console.log("NavBar - (session) relation_ids ->", relation_ids);
      relationIdsArr.push(relation_ids);
      //console.log("NavBar - state relationIdsArr ->", relationIdsArr);

      this.setState({ my_account: relationIdsArr });
    }

    this.setState({ username: user && user.full_name ? user.full_name : user.username ? user.username : '' });
  }

  componentWillUnmount() {
    const { navbarChildRef } = this.props;
    navbarChildRef(undefined);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user !== user) {
      this.setState({ username: user.full_name ? user.full_name : user.username })
    }
  }

  render() {
    const { username, my_account } = this.state ? this.state : {};

    return (
      <Navbar
        expand style={{ maxHeight: "69px" }}
      >
        <Nav className="mr-auto align-items-center" navbar>
          <span className="sidebar-toggle d-flex mr-3" onClick={() => this.props.dispatch(toggleSidebar())}>
            <i className="hamburger align-self-center" />
          </span>
          {/* solr search */}
          <NavItem className="mr-1 ml-3">
            <SolrSearchExpand />
          </NavItem>
        </Nav>
        <Nav className="ml-auto mt-1" navbar>
          <NavItem className="mr-3 ml-3">
            <Link to="/" style={{ lineHeight: "32px" }} title={`Dashboard`}>
              <BarChart2 className="d-inline-block align-middle text-primary" />
            </Link>
          </NavItem>
          {this.renderDropdownButtons()}
          <UncontrolledDropdown nav inNavbar id={`menu-top-user`}>
            {username &&
              <React.Fragment>
                <span className="d-inline-block text-truncate" title="Settings">
                  <span>Hi,</span>
                  <DropdownToggle className="d-inline-block dropdown-profile" id="menu-top-user-toggle" nav caret data-toggle="dropdown">
                    {username}
                  </DropdownToggle>
                </span>
                <DropdownMenu align="end" aria-labelledby="menu-top-user-toggle">
                  <DropdownItem onClick={(e) => this.goToSettings(e)}>
                    Settings
                  </DropdownItem>
                  {my_account && my_account.length > 0 && my_account.map((account, i) => {
                    if (my_account.length === 1) {
                      return (
                        <DropdownItem className={isDisabled ? `disabled` : ""} key={i} onClick={(e) => this.goToAccount(e, account)} disabled={isDisabled}>{`My account`}</DropdownItem>
                      )
                    } else {
                      return (<DropdownItem key={i}
                        className={isDisabled ? `disabled` : ""}
                        onClick={(e) => this.goToAccount(e, account)}
                      >{`My account ${i}`}</DropdownItem>)
                    }
                  })}
                  <DropdownItem onClick={this.handleLogout}>Sign out</DropdownItem>
                </DropdownMenu>
              </React.Fragment>}
          </UncontrolledDropdown>
        </Nav>

      </Navbar>
    )
  }
}

const appSelector = createSelector(
  state => state.app,
  app => app
);

const userSelector = createSelector(
  state => state.user,
  user => user
);

const errorSelector = createSelector(
  state => state.error,
  error => error
);

const mapStateToProps = createSelector(
  appSelector,
  userSelector,
  errorSelector,
  (app, user, error) => ({
    app,
    user,
    error
  })
);

export default connect(mapStateToProps)(withTranslation('common')(withRouter(NavbarComponent)));
