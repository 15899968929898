import React from "react";
import { GetApiKeysTenant } from '../../../controllers/persons';
import ApiKeysForm from "./Form";
import RemoveApiKeysForm from "./Remove";
import ParentTable from '../../../components/ParentTable';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

class ApiKeys extends React.Component {
  constructor(props) {
    super(props);

    console.log("Api Keys props ->", this.props);
  }

  formatDate = (cell) => {
    if (cell) {
      return <span className="date-format">{this.props.t("date_format",
        { date: new Date(cell) })}</span>
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  render() {
    const { id, tabTitle } = this.props;
    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="ApiKeys"
          id={id}
          noTopBorder={true}
          data={GetApiKeysTenant}
          columns={[
            {
              dataField: "user_id",
              text: "User ID",
              headerStyle: { width: '10%' },
              sort: true,
              attrs: {
                'data-label': 'User ID'
              }
            },
            {
              dataField: "name",
              text: "Name",
              headerStyle: { width: '15%' },
              sort: true,
              attrs: {
                'data-label': 'Name'
              }
            },
            {
              dataField: "key",
              text: "Api key",
              headerStyle: { width: '50%' },
              sort: true,
              attrs: {
                'data-label': 'Api key'
              }
            },
            {
              dataField: "expire_date",
              text: "Expire date",
              formatter: this.formatDate,
              headerStyle: { width: '15%' },
              sort: true,
              attrs: {
                'data-label': 'Expire date'
              }
            },
          ]}
          rowAction="edit"
          rowActionForm={ApiKeysForm}
          headerDropdownOpts={[{
            label: 'Add',
            action: ApiKeysForm
          }]}
          rowDropdownOpts={[{
            action: 'Edit',
            label: 'Edit',
            form: ApiKeysForm
          }, {
            action: 'Remove',
            label: 'Remove',
            form: RemoveApiKeysForm,
            condition: {
              data: 'id'
            }
          }]}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(connect()(ApiKeys)));
