import { faFilter, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { customFilter } from 'react-bootstrap-table2-filter';
import { MoreHorizontal } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import {
    Badge, Button, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import CustomFilterDateRange from '../../../components/filters/CustomFilterDateRange';
import CustomFilterInput from '../../../components/filters/CustomFilterInput';
import CustomFilterInputComp from '../../../components/filters/CustomFilterInputComp';
import CustomFilterSelect from '../../../components/filters/CustomFilterSelect';
import OptionOpen from '../../../components/OptionOpen';
import OptionPaid from '../../../components/OptionPaid';
import OptionReminderStatus from '../../../components/OptionReminderStatus';
import OptionSendInvoiceEmail from '../../../components/OptionSendInvoiceEmail';
import ParentTable from '../../../components/ParentTable';
import Toast from '../../../components/Toast';
import { GetAllBillingRuns } from '../../../controllers/billing_runs';
import { GetInvoicesSummary, SendInvoicEmail } from '../../../controllers/invoices';
import { GetStatusByTypeId } from '../../../controllers/statuses';
import { parseDateToSaveFormat } from '../../../redux/actions/formatActions';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import ApiService from '../../../services/apiService';
import CONSTANTS from '../../../services/constants';
import { invoiceStatusObj } from '../../../services/misc';

const Api = new ApiService(),
    GetAPI = Api.getAPI;

const dateLocalesPath = {
    'nl': 'nl',
    'en': 'en-GB',
    'us': 'en-US'
};

//const redirect = () => {
//    return window.location.href = '/invoices/concept';
//};

const Invoices = ({ ...props }) => {
    let history = useHistory();

    const tableName = "Invoices";

    const [toggleFilterForm, setToggleFilterForm] = useState(false);
    const [collapseState, setCollapseState] = useState("is-hidden");
    const [clearFilters, setClearFilters] = useState(false);

    let [customerNumberFilter, setCustomerNumberFilter] = useState(null);
    let [invoiceNumberFilter, setInvoiceNumberFilter] = useState(null);
    let [dateFilter, setDateFilter] = useState(null);
    let [customerFilter, setCustomerFilter] = useState(null);
    let [addressFilter, setAddressFilter] = useState(null);
    let [priceFilter, setPriceFilter] = useState(null);
    let [statusFilter, setStatusFilter] = useState(null); // if multiSelect column -> set to [] as initialState
    let [billingRunFilter, setBillingRunFilter] = useState(null); // if multiSelect column -> set to [] as initialState

    /* start filters - state variables */
    const [data, setData] = useState([]);
    const [customerNumber, setCustomerNumber] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [customer, setCustomer] = useState("");
    const [price, setPrice] = useState("");
    const [date, setDate] = useState("");
    const [address, setAddress] = useState("");
    const [status, setStatus] = useState('');
    const [billingRun, setBillingRun] = useState('');
    const [invoiceId, setInvoiceId] = useState('');

    const [sort, setSort] = useState("");
    const [pageNum, setPageNum] = useState(1);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(100);    
    const [paginationCount, setPaginationCount] = useState(1);

    let [filtersFilter, setFiltersFilter] = useState({});
    let [filters, setFilters] = useState({ filter: null, keyword: "" });

    const [params, setParams] = useState([]);
    const [enabledFilter, setEnabledFilter] = useState(props.enabledFilter ? props.enabledFilter : false);
    const clearedItemName = useRef("");
    const [comparatorFilter, setComparatorFilter] = useState({});
    const [searchKeyword, setSearchKeyword] = useState("");

    const [isLoadData, setIsLoadData] = useState(false);
    const [isReLoad, setIsReLoad] = useState(false);
    const [billingRunsData, setBillingRunsData] = useState([]);
    const [statusOptsData, setStatusOptsData] = useState([]);

    const getLocale = locale => require(`date-fns/locale/${dateLocalesPath[props.i18n.language]}/index.js`); // en-US - en-GB - nl

    const { t } = useTranslation();
    const { id, dispatch } = props;
    const regexp = /\bdropdown|\bsvg|\bdropdown-table-actions/gmi;

    const redirect = () => {
        history.push('/invoices/concept');
    };

    const StatusStyle = (cell, row) => {
        if (cell) {
            return <Badge
                color={(cell.toString().toLowerCase()) === 'open' || (cell.toString().toLowerCase()) === 'finalizing'
                    ? 'warning' : (cell.toString().toLowerCase()) === 'invoice processing'
                        ? 'primary'
                        : (cell.toString().toLowerCase()) === 'paid' ? 'success' : 'info'} className="badge-pill mr-1 mb-0 text-uppercase">{cell.toString().toUpperCase()}
            </Badge>;
        }
        return String.fromCharCode(8212);
    };

    const formatCurrency = (cell, row) => {
        if (cell) {
            let price_excl_vat = cell ? t("currency_format", { number: Number(parseFloat(cell)) }) : t("currency_format", { number: Number(parseFloat(0)) });
            let price_incl_vat = row.price_incl_vat ? t("currency_format", { number: Number(parseFloat(row.price_incl_vat)) }) : t("currency_format", { number: Number(parseFloat(0)) });

            return <span className='float-price'>{price_excl_vat} / {price_incl_vat}</span>
        }
        return <span className={`float-zero`}>{t("currency_format", { number: Number(0) })} / {t("currency_format", { number: Number(0) })}</span>
    };

    const getBillingRunsData = async () => {
        let params = [];
        params.page = 1;
        params.offset = 1000;
        params.count = 0;

        await GetAllBillingRuns(params)
            .then(res => {
                if (res.ok) {
                    const { data } = res;
                    let resData = [];
                    if (data && data.data) {
                        //resData.push({ label: "All", value: "" });
                        for (let i = 0; i < data.data.length; i++) {
                            resData.push({ label: data.data[i].id, value: data.data[i].id });
                        }
                    }
                    setBillingRunsData(resData);
                }
            })
            .catch(e => {
                console.log('Error', e);
            });
    };

    const BillingRunStyle = (cell) => {
        if (cell) {
            return <span>{cell}</span>;
        }
        return String.fromCharCode(8212);
    };

    const formatDate = (cell) => {
        if (cell) {
            return <span className="date-format">{t("date_format",
                { date: new Date(cell) })}</span>
        } else {
            return <span className="null-value">{String.fromCharCode(8212)}</span>
        }
    };

    const conceptInvNoFormatter = (cell) => {
        return cell !== null && cell !== undefined ? cell : String.fromCharCode(8212)
    };

    const formatCustomerAddress = (cell) => {
        if (cell) {
            return cell;
        } else {
            return <span className="null-value">{String.fromCharCode(8212)}</span>
        }
    };

    const formatCustomerPerson = (cell) => {
        if (cell) {
            return cell;
        } else {
            return <span className="null-value">{String.fromCharCode(8212)}</span>
        }
    };

    const HeaderMenuFormatter = () =>
        <Button color="gray" onClick={() => redirect()} className="float-right">Create Invoice</Button>;

    const updateStatus = async (id) => {
        dispatch(loaderToggle(true));

        await GetAPI(`sales_invoices/${id}/send/reminder`)
            .then(r => {
                if (r.data) {
                    const { data } = r.data;

                    setStatus(data['reminder_status']);
                    setInvoiceId(id);
                    setIsReLoad(true);
                    setIsLoadData(true);

                    Toast.fire({ title: invoiceStatusObj(data['reminder_status']).successMsg, icon: 'success' }).then(r => { console.log(r) });
                }
                dispatch(loaderToggle(false));

            })
            .catch((error) => {
                console.log('Error fetching data: ', error);
            });

        dispatch(loaderToggle(false));
        //setIsLoadData(false);

    };

    const updateStatusToOpen = async (id) => {
        dispatch(loaderToggle(true));

        await GetAPI(`reminders/sales_invoices/${id}/open`)
            .then(r => {
                console.log(r);
                const { data } = r.data;

                if (data) {
                    setStatus(data['reminder_status']);
                    setInvoiceId(id);
                    setIsReLoad(true);
                    setIsLoadData(true);

                    Toast.fire({ title: 'Invoice has been set to open.', icon: 'success' }).then(r => { console.log(r) });
                } else {
                    setInvoiceId(id);
                    setIsReLoad(true);
                    setIsLoadData(true);

                    Toast.fire({ title: 'Invoice has been set to open.', icon: 'success' }).then(r => { console.log(r) });
                }

                dispatch(loaderToggle(false));

            })
            .catch(error => {
                console.log('Error fetching data: ', error);
            });
        dispatch(loaderToggle(false));
        //setIsLoadData(false);
    };

    const updateStatusToPaid = async (id) => {
        dispatch(loaderToggle(true));

        await GetAPI(`reminders/sales_invoices/${id}/paid`)
            .then(r => {
                console.log(r);
                const { data } = r.data;

                if (data) {
                    setStatus(data['reminder_status']);
                    setInvoiceId(id);
                    setIsReLoad(true);
                    setIsLoadData(true);

                    Toast.fire({ title: 'Invoice has been set to paid.', icon: 'success' }).then(r => { console.log(r) });
                } else {
                    setInvoiceId(id);
                    setIsReLoad(true);
                    setIsLoadData(true);

                    Toast.fire({ title: 'Invoice has been set to paid.', icon: 'success' }).then(r => { console.log(r) });
                }

                dispatch(loaderToggle(false));

            })
            .catch(error => {
                console.log('Error fetching data: ', error);
            });
        dispatch(loaderToggle(false));
        //setIsLoadData(false);
    };

    // send invoice email
    const sendInvoiceEmail = async (id) => {
        await SendInvoicEmail(id)
            .then(r => {
                const { ok, data } = r;
                if (ok) {
                    setIsLoadData(true);

                    Toast.fire({ title: data.message, icon: 'success' }).then(r => { console.log(r) });
                }
                dispatch(loaderToggle(false));

            })
            .catch(error => {
                console.log('Error fetching data: ', error)
            });
        dispatch(loaderToggle(false));
        setIsLoadData(false);
    };

    const optionMenuFormatter = (cell, row, rowIndex) => {
        //console.log("optionMenuFormatter - props ->", props);

        const { id, sales_invoice_reminder, invoice_status, invoice_status_label, invoice_no } = row;
        const isHidden = (sales_invoice_reminder === CONSTANTS.COLL_AGENCY || invoice_no === null);
        const isFirstReminderSent = (sales_invoice_reminder === CONSTANTS.FIRST_REMINDER_SENT);
        const isNotPaid = (invoice_status === 20); // status open
        const isPaid = (invoice_status === 50); // status paid
        const reminderProps = { updateStatus, row };
        const paidStatProps = { updateStatusToPaid, row };
        const openStatProps = { updateStatusToOpen, row };
        const sendInvoiceProps = { sendInvoiceEmail, row };

        return !isHidden || sales_invoice_reminder ?
            <UncontrolledDropdown>
                <DropdownToggle nav className="px-3 py-2 dropdown-table-actions" onClick={(e) => stopBubble(e)}>
                    <MoreHorizontal size={18} />
                </DropdownToggle>
                <DropdownMenu right>
                    <OptionSendInvoiceEmail {...sendInvoiceProps} />
                    {!isHidden && !isPaid && <OptionReminderStatus {...reminderProps} />}
                    {isNotPaid && <OptionPaid setIsLoadData {...paidStatProps} />}
                    {isPaid && <OptionOpen {...openStatProps} />}
                </DropdownMenu>
            </UncontrolledDropdown>
            :
            null
    };

    const stopBubble = async (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const decodeURLParams = (search) => {
        const hashes = search.slice(search.indexOf("?") + 1).split(",");
        return hashes.reduce((params, hash) => {
            const split = hash.indexOf("=");
            const key = hash.slice(0, split);
            const val = hash.slice(split + 1);
            return Object.assign(params, { [key]: decodeURIComponent(val) });
        }, {});
    };

    const statusList = () => {
        (async () => {
            const [statusListItems] = await Promise.all([
                GetStatusByTypeId(1),
            ]);
            // get vat codes
            if (statusListItems.ok) {
                let statusOpts = [];
                if (statusListItems.data && statusListItems.data.data) {
                    // set status list
                    //statusOpts.push({ label: "All", value: null });
                    for (let i = 0; i < statusListItems.data.data.length; i++) {
                        statusOpts.push({ label: statusListItems.data.data[i].label, value: statusListItems.data.data[i].status });
                    }
                    setStatusOptsData(statusOpts);
                }
            }
        })();
    };

    /* Filter */
    const handleFiltering = (e, name, multiSelectable, comparator = null) => {
        //console.log("InvoicesList (table) - handleFiltering -> check (before setting filter)");

        let filterStoredItem;
        let historyFilter = false;
        // custom comparator
        if (comparator) {
            setComparatorFilter({
                [name]: comparator
            });
        }

        if (!enabledFilter) {
            let filtersObj = sessionStorage.getItem("qStrInvoicesFiltersObj");
            if (filtersObj) {
                let parsedFiltersObj = JSON.parse(filtersObj);
                if (parsedFiltersObj && parsedFiltersObj.filter) {
                    historyFilter = true;
                    // setCollapseState("is-visible");
                    setToggleFilterForm(true);

                    if (parsedFiltersObj.filter) {
                        filtersFilter = parsedFiltersObj.filter;
                        let searchedKeyword = sessionStorage.getItem("qStrInvoicesKeyword");
                        if (searchedKeyword) {
                            parsedFiltersObj.keyword = searchedKeyword;
                        }
                        setFiltersFilter(filtersFilter);
                    }
                    filters = parsedFiltersObj;
                    setFilters(filters);
                }
            }
        }

        if (
            (name && e && (e.value !== "" || e.value === "")) ||
            (name && e === null) ||
            (e && e.key && e.key === "Enter") ||
            (e && multiSelectable && (e.length > 0 || e.length === null || e.length === 0)) ||
            historyFilter
        ) {
            let value = "";
            switch (name) {
                case "customer_number":
                    let customer_number = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["customer_number"] ? filtersFilter["customer_number"] : "";
                    if (customer_number === "") {
                        customer_number = null;
                    }
                    value = customer_number;
                    setCustomerNumber(value);
                    break;
                case "invoice_no":
                    let description = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["invoice_no"] ? filtersFilter["invoice_no"] : "";
                    if (description === "") {
                        description = null;
                    }
                    value = description;
                    setInvoiceNumber(value);
                    break;
                case "date": // date range
                    if (e && e.length && e.length > 0) {
                        let selectedValues = [];
                        for (let i = 0; i <= e.length; i++) {
                            if (e[i] && (e[i] !== "" || e[i] !== undefined || e[i] !== null)) {
                                selectedValues.push(
                                    parseDateToSaveFormat(new Date(e[i])));
                            }
                        }
                        value = selectedValues && selectedValues.length > 0
                            ? selectedValues
                            : null;
                        setDate(value);
                    } else {
                        value = !enabledFilter && filtersFilter && filtersFilter["date"] ? filtersFilter["date"] : null;
                        setDate(value);
                    }
                    break;
                case "full_name":
                    let customer = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["full_name"] ? filtersFilter["full_name"] : "";
                    if (customer === "") {
                        customer = null;
                    }
                    value = customer;
                    setCustomer(value);
                    break;
                case "full_address":
                    let address = e && e.target && e.target.value
                        ? e.target.value
                        : !enabledFilter && filtersFilter && filtersFilter["full_address"] ? filtersFilter["full_address"] : "";
                    if (address === "") {
                        address = null;
                    }
                    value = address;
                    setAddress(value);
                    break;
                case "price_excl_vat":
                    let price = e && e.value
                        ? e.value
                        : e && e.target && e.target.value
                            ? e.target.value
                            : !enabledFilter && filtersFilter && filtersFilter["price_excl_vat"] ? filtersFilter["price_excl_vat"] : "";
                    if (price === "") {
                        price = null;
                    }
                    value = price;
                    setPrice(value);
                    break;
                case "invoice_status_label":
                    if (multiSelectable) {
                        value = e ? e : !enabledFilter && filtersFilter && filtersFilter["invoice_status_label"]
                            ? filtersFilter["invoice_status_label"]
                            : null;

                        if (value && value.length > 0) {
                            let selectedValues = [];
                            for (let i = 0; i <= value.length; i++) {
                                if (value[i] && value[i].value && value[i].label) {
                                    selectedValues.push({ label: value[i].label, value: value[i].value });
                                }
                            }
                            setStatus(selectedValues && selectedValues.length > 0 ? selectedValues : null);
                        } else {
                            //setStatus(!enabledFilter && filtersFilter && filtersFilter["invoice_status_label"] ? filtersFilter["invoice_status_label"] : null);
                            setStatus(null);
                        }
                    } else {
                        value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["invoice_status_label"] ? filtersFilter["invoice_status_label"] : "";
                        if (value === "") {
                            value = null;
                        }
                        setStatus(value);
                    }
                    break;
                case "billing_run_id":
                    if (multiSelectable) {
                        value = e ? e : !enabledFilter && filtersFilter && filtersFilter["billing_run_id"]
                            ? filtersFilter["billing_run_id"]
                            : null;

                        if (value && value.length > 0) {
                            let selectedValues = [];
                            for (let i = 0; i <= value.length; i++) {
                                if (value[i] && value[i].value && value[i].label) {
                                    selectedValues.push({ label: value[i].label, value: value[i].value });
                                }
                            }
                            setBillingRun(selectedValues && selectedValues.length > 0 ? selectedValues : null);
                        } else {
                            //setBillingRun(!enabledFilter && filtersFilter && filtersFilter["billing_run_id"] ? filtersFilter["billing_run_id"] : null);
                            setBillingRun(null);
                        }
                    } else {
                        value = e && e.value ? e.value : !enabledFilter && filtersFilter && filtersFilter["billing_run_id"] ? filtersFilter["billing_run_id"] : "";
                        if (value === "") {
                            value = null;
                        }
                        setBillingRun(value);
                    }
                    break;
                default:
                    break;
            }

            if (filtersFilter) {
                if (filtersFilter[name] && (!value || value === "" || value.length === 0)) {
                    delete filtersFilter[name];
                } else {
                    if (value) {
                        filtersFilter[name] = value;
                    }
                }

                if (Object.entries(filtersFilter).length > 0) {
                    setFiltersFilter(filtersFilter);
                    filters.filter = filtersFilter;
                    setFilters(filters);
                    setQueryParameters();
                    setIsLoadData(true);
                } else {
                    filters.filter = filtersFilter;
                    setFilters(filters);
                    setQueryParameters();
                    setIsLoadData(true);
                }
            }
        }
    };

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };

    const processMultiValues = (multiValueArr) => {
        let multiValues = [];
        for (let i = 0; i < multiValueArr.length; i++) {
            if (multiValueArr[i] && multiValueArr[i].value) {
                multiValues.push(multiValueArr[i].value);
            }
        }
        return multiValues.join(",");
    };

    const processKey = (key, value, queryFilter, comparatorItems) => {
        switch (key) {
            case "invoice_status_label":
            case "billing_run_id":
                let multiKeyFilters = processMultiValues(value[key]);
                queryFilter.push(`${key}=[${multiKeyFilters}]`);
                break;
            case "date":
                queryFilter.push(`${key}>=${value[key][0]},${key}<=${value[key][1]}`);
                break;
            case "price_excl_vat":
                queryFilter.push(`${key}${comparatorItems && comparatorItems[key] ? comparatorItems[key] : ">="}${value[key]}`);
                break;
            default:
                queryFilter.push(`${key}%${value[key]}`);
                break;
        }
    }

    const setQueryParameters = () => {
        // get history (default) params
        let params = {};
        const historyParamsQueryString = sessionStorage.getItem("qStrInvoices");
        if (historyParamsQueryString) {
            // page - offset - count - sort - search
            historyParamsQueryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
                if (key === 'page' || key === 'offset' || key === 'count') {
                    params[key] = parseInt(value);
                } else if (key === 'sort') {
                    params[key] = value;
                } else if (key === 'search') {
                    params[key] = value;
                }
            });
        }

        const savedComparators = sessionStorage.getItem("qStrInvoicesComparatorItems");
        const savedComparatorsParsed = savedComparators ? JSON.parse(savedComparators) : null;

        let key;
        let value;
        let query = [];
        let searchFilterQuery = {};
        let filterQuery = {};
        let searchFilterStr = "";
        let comparatorItems = comparatorFilter ? comparatorFilter : {};

        //console.log("PlansList (table) - comparatorFilter - setQueryParameters - comparatorItems ->", comparatorItems);
        //console.log("PlansList (table) - comparatorFilter - setQueryParameters - savedComparatorsParsed ->", savedComparatorsParsed);
        if (savedComparatorsParsed && !isEmpty(savedComparatorsParsed)) {
            comparatorItems = { ...comparatorFilter, ...savedComparatorsParsed };
        }
        //console.log("PlansList (table) - comparatorFilter - setQueryParameters - comparatorItems ->", comparatorItems);

        query.push(`count=1`);
        if (!filters) {
            query.push(`search=`);
            query.push(`filter=`);
        }
        query.push(`offset=${params && params.offset ? params.offset : sizePerPage ? sizePerPage : 10}`);
        query.push(`page=${params && params.page ? params.page : page ? page : 1}`);
        query.push(`sort=${params && params.sort ? params.sort : sort ? sort : ""}`);

        // remove filters from session storage
        sessionStorage.removeItem("qStrInvoices");
        sessionStorage.removeItem("qStrInvoicesFilter");
        sessionStorage.removeItem("qStrInvoicesFilterStr");
        sessionStorage.removeItem("qStrInvoicesFiltersObj");
        sessionStorage.removeItem("qStrInvoicesComparatorItems");
        sessionStorage.removeItem("qStrInvoicesKeyword");

        //if (filters && (filters.filter || filters.keyword)) {
        for (key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (key === "keyword") {
                    value = filters[key];
                    searchFilterQuery[key] = value && true ? value : "";
                    // set search for querystring
                    query.push(`search=${filters[key]}`);

                } else if (key === "filter") {
                    value = filters[key];


                    // set total filter
                    let queryKeyFilters = "";
                    let queryFilters = "";

                    // if value is array - value.length > 0 - there are filters
                    if (value && Array.isArray(value)) {
                        if (value.length > 0) {
                            let queryFilter = [];
                            for (let i = 0; i < value.length; i++) {
                                for (let key in value[i]) {
                                    if (value[i].hasOwnProperty(key)) {
                                        processKey(key, value[i], queryFilter, comparatorItems);
                                    }
                                }
                            }
                            if (queryFilter.length > 0) {
                                queryKeyFilters = queryFilter.join(",");
                                queryFilters = queryFilter.join("~");
                            }
                            searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
                            filterQuery['filter'] = queryFilters ? queryFilters : "";
                        }
                    } else {
                        let queryFilter = [];
                        for (let name in value) {
                            if (value.hasOwnProperty(name)) {
                                processKey(name, value, queryFilter, comparatorItems);
                            }
                        }
                        if (queryFilter.length > 0) {
                            queryKeyFilters = queryFilter.join(",");
                            queryFilters = queryFilter.join("~");
                        }
                        searchFilterQuery['filter'] = queryKeyFilters ? queryKeyFilters : "";
                        filterQuery['filter'] = queryFilters ? queryFilters : "";
                    }
                    query.push(`filter=${queryKeyFilters}`);
                }
            }
        }
        query.join("&").toString();
        setEnabledFilter(true);

        //} else {
        //    query.join("&").toString();
        //}

        const location_search_query = query;
        let location_search = location_search_query
            ? location_search_query.join("&")
            : "";

        sessionStorage.setItem("qStrInvoices", location_search);
        sessionStorage.setItem("qStrInvoicesFilter", searchFilterQuery['filter'] && searchFilterQuery['filter'] !== "" ? searchFilterQuery['filter'] : "");
        sessionStorage.setItem("qStrInvoicesFilterStr", filterQuery['filter'] && filterQuery['filter'] !== "" ? filterQuery['filter'] : "");
        sessionStorage.setItem("qStrInvoicesKeyword", searchFilterQuery['keyword'] ? searchFilterQuery['keyword'] : searchKeyword);
        sessionStorage.setItem("qStrInvoicesFiltersObj", JSON.stringify(filters));
        sessionStorage.setItem("qStrInvoicesComparatorItems", JSON.stringify(comparatorItems));
    };

    /* Clear filter */
    const handleFilterClick = (e, props) => {
        if (e) {
            e.preventDefault();
        }

        sessionStorage.removeItem("qStrInvoices");
        sessionStorage.removeItem("qStrInvoicesFilter");
        sessionStorage.removeItem("qStrInvoicesFilterStr");
        sessionStorage.removeItem("qStrInvoicesKeyword");
        sessionStorage.removeItem("qStrInvoicesFiltersObj");
        sessionStorage.removeItem("qStrInvoicesComparatorItems");

        setCustomerNumberFilter([]);
        setInvoiceNumberFilter([]);
        setDateFilter([]);
        setPriceFilter([]);
        setCustomerFilter([]);
        setAddressFilter([]);
        setStatusFilter([]);
        setBillingRunFilter([]);

        setCustomerNumber("");
        setInvoiceNumber("");
        setDate("");
        setCustomer("");
        setAddress("");
        setPrice("");
        setStatus("");
        setBillingRun("");

        setPage(1);
        setSizePerPage(100); // default (new)
        setFiltersFilter({});
        setFilters({ filter: null, keyword: "" });
        setClearFilters(true);
        setPaginationCount(1);
        setEnabledFilter(false);
        setIsLoadData(true);

        setQueryParameters();

        let input = document.getElementById("table-search-form");
        input.value = '';

        sessionStorage.clear(); // This clears all data in sessionStorage
    };

    useEffect(() => {
        if (clearFilters) {
            setPaginationCount(1);
            setClearFilters(false);
            setIsLoadData(true);
        }
    }, [clearFilters]);

    useEffect(() => {
        if (searchKeyword && searchKeyword !== "") {
            filters.filter = filtersFilter;
            filters.keyword = searchKeyword;
            setFilters(filters);

            sessionStorage.setItem("qStrInvoicesKeyword", searchKeyword);

            setPage(1);
            setPageNum(1);
            setQueryParameters();
            setIsLoadData(true);
        }
    }, [searchKeyword]);

    useEffect(() => {
        let data = getBillingRunsData();
        if (data) {
            setBillingRunsData(data);
        }
    }, [billingRunFilter]);

    useEffect(() => {
        let data = statusList();
    }, []);

    //useEffect(() => {
    //    console.log("table filter list - (updated - check if null or empty) - filters ->", filters);
    //}, [filters]);

    useEffect(() => {
        //console.log("table filter list - (updated - clearedItemName) - clearedItemName ->", clearedItemName);        
        let tableStorageFilterObjId = "qStrInvoicesFiltersObj";

        if (clearedItemName && clearedItemName !== "") {
            let name = clearedItemName;
            let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
            //console.log("table filter list - (updated - clearedItemName) - filtersObj ->", filtersObj);

            if (name && filtersObj) {
                let filtersObjParsed = JSON.parse(filtersObj);
                if (filtersObjParsed && filtersObjParsed.filter && filtersObjParsed.filter.length > 0) {
                    for (let i = 0; i < filtersObjParsed.filter.length; i++) {
                        if (filtersObjParsed.filter[i] && filtersObjParsed.filter[i][name]) {
                            filtersObjParsed.filter.splice(i, 1);

                            // save filters to session storage (stringify)
                            sessionStorage.setItem("qStrInvoicesFiltersObj",
                                JSON.stringify(filtersObjParsed));

                            if (filtersObjParsed.filter &&
                                filtersObjParsed.filter.length > 0) {
                                setFiltersFilter(filtersObjParsed.filter);
                            } else {
                                setFiltersFilter([]);
                            }
                            setFilters(filtersObjParsed);
                            return;
                        }
                    }
                } else if (filtersObjParsed && filtersObjParsed.filter && filtersObjParsed.filter[name]) {
                    delete filtersObjParsed.filter[name];
                    // save filters to session storage (stringify)
                    sessionStorage.setItem("qStrInvoicesFiltersObj",
                        JSON.stringify(filtersObjParsed));
                }
            }
        } else {
            setFiltersFilter({});
            setFilters({ filter: null, keyword: "" });
            sessionStorage.removeItem(tableStorageFilterObjId);
            setQueryParameters();
        }
    }, [clearedItemName]);

    const collapseFilterAction = (e) => {
        if (e) {
            e.preventDefault();
        }

        let filterVisible = sessionStorage.getItem("invoicesFilterVisible");
        //console.log("InvoicesList - filterVisible ->", filterVisible);

        let collapseStatus = toggleFilterForm === true ? "is-hidden" : "is-visible";

        setCollapseState(collapseStatus);
        setToggleFilterForm(!toggleFilterForm);

        sessionStorage.setItem("invoicesFilterVisible", collapseStatus);
    };

    useEffect(() => {
        //console.log("InvoicesList - useEffect");        

        let filterVisible = sessionStorage.getItem("invoicesFilterVisible");
        //console.log("InvoicesList - filterVisible ->", filterVisible);
        if (filterVisible === "is-visible") {
            setToggleFilterForm(true);
            setCollapseState("is-visible");

            sessionStorage.setItem("invoicesFilterVisible", "is-visible");

        } else if (filterVisible === "is-hidden") {
            setToggleFilterForm(false);
            setCollapseState("is-hidden");

            sessionStorage.setItem("invoicesFilterVisible", "is-hidden");

        } else {
            setToggleFilterForm(false);
            setCollapseState("is-hidden");
            setFilters({ filter: null, keyword: "" });

            sessionStorage.setItem("invoicesFilterVisible", "is-hidden");
        }
    }, []);

    useEffect(() => {        
        const { dispatch } = props;
        dispatch({ type: 'RELATION', payload: null });
        dispatch({ type: 'SUBSCRIPTION', payload: null });
        dispatch({ type: 'INVOICE', payload: null });
    }, []);

    useEffect(() => {        
        let currentParams = new URL(document.location).searchParams;
        //console.log("InvoicesList - useEffect (params) - currentParams ->", currentParams);
        if (currentParams.size && currentParams.size > 0) {
            //console.log("InvoicesList - useEffect (params) - currentParams.size ->", currentParams.size);
            let qStr = currentParams.toString();
            //console.log("InvoicesList - useEffect (params) - qStr ->", qStr);
            sessionStorage.setItem("qStrInvoices", qStr);
            setQueryParameters();
        }
    }, []);    

    return (
        <div className="wrapper-box-table-filter">
            <h1 className="text-bold mb-3">Invoices</h1>
            <ParentTable
                table="Invoices"
                //language={props.i18n && props.i18n.language ? props.i18n.language : 'nl'}
                id="InvoicesTable"
                keyField="id"
                data={GetInvoicesSummary}
                rowAction="link"
                remote
                columns={[
                    {
                        dataField: "id",
                        text: "#",
                        hidden: true,
                        attrs: {
                            'data-label': 'id'
                        }
                    }, {
                        dataField: "customer_number",
                        filter: customFilter({
                            type: "text",
                            style: { display: "inline-grid" },
                            getFilter: (filter) => {
                                setCustomerNumberFilter(filter);
                            },
                        }),
                        filterRenderer: (onFilter, column) => (
                            <CustomFilterInput
                                onFilter={onFilter}
                                column={column}
                                handleFiltering={handleFiltering}
                                isClearFilter={clearFilters}
                                setClearedItemName={(value) => clearedItemName.current = value}
                                value={customerNumber ? customerNumber : ""}
                                enabledFilter={enabledFilter}
                                setEnabledFilter={setEnabledFilter}
                                table="Invoices"
                            />
                        ),
                        title: true,
                        text: "Customer no.",
                        sort: true,
                        headerStyle: () => {
                            return { width: '10%' };
                        },
                        attrs: {
                            'data-label': 'Customer no.'
                        }
                    }, {
                        dataField: "invoice_no",
                        filter: customFilter({
                            type: "text",
                            style: { display: "inline-grid" },
                            getFilter: (filter) => {
                                setInvoiceNumberFilter = filter;
                            },
                        }),
                        filterRenderer: (onFilter, column) => (
                            <CustomFilterInput
                                onFilter={onFilter}
                                column={column}
                                handleFiltering={handleFiltering}
                                isClearFilter={clearFilters}
                                setClearedItemName={(value) => clearedItemName.current = value}
                                value={invoiceNumber ? invoiceNumber : ""}
                                enabledFilter={enabledFilter}
                                setEnabledFilter={setEnabledFilter}
                                table="Invoices"
                            />
                        ),
                        title: true,
                        text: "Invoice no.",
                        sort: true,
                        formatter: conceptInvNoFormatter,
                        headerStyle: () => {
                            return { width: '12%' };
                        },
                        attrs: {
                            'data-label': 'Invoice no.'
                        }
                    }, {
                        dataField: "date",
                        filter: customFilter({
                            type: "date",
                            style: { display: "inline-grid" },
                            getFilter: (filter) => {
                                setDateFilter(filter);
                            },
                        }),
                        filterRenderer: (onFilter, column) => (
                            <CustomFilterDateRange
                                onFilter={onFilter}
                                column={column}
                                handleFiltering={handleFiltering}
                                isClearFilter={clearFilters}
                                setClearedItemName={(value) => clearedItemName.current = value}
                                value={date ? date : ""}
                                enabledFilter={enabledFilter}
                                setEnabledFilter={setEnabledFilter}
                                table="Invoices"
                            />
                        ),
                        title: true,
                        text: "Invoice date",
                        sort: true,
                        formatter: formatDate,
                        headerStyle: () => {
                            return { width: '10%' };
                        },
                        attrs: {
                            'data-label': 'Invoice date'
                        }
                    }, {
                        dataField: "full_name",
                        filter: customFilter({
                            type: "text",
                            style: { display: "inline-grid" },
                            getFilter: (filter) => {
                                setCustomerFilter(filter);
                            },
                        }),
                        filterRenderer: (onFilter, column) => (
                            <CustomFilterInput
                                onFilter={onFilter}
                                column={column}
                                handleFiltering={handleFiltering}
                                setClearedItemName={(value) => clearedItemName.current = value}
                                isClearFilter={clearFilters}
                                value={customer ? customer : ""}
                                enabledFilter={enabledFilter}
                                setEnabledFilter={setEnabledFilter}
                                table="Invoices"
                            />
                        ),
                        title: true,
                        text: "Customer",
                        formatter: formatCustomerPerson,
                        sort: true,
                        headerStyle: () => {
                            return { width: '12%' };
                        },
                        attrs: {
                            'data-label': 'Customer'
                        }
                    }, {
                        dataField: "full_address",
                        filter: customFilter({
                            type: "text",
                            style: { display: "inline-grid" },
                            getFilter: (filter) => {
                                setAddressFilter(filter);
                            },
                        }),
                        filterRenderer: (onFilter, column) => (
                            <CustomFilterInput
                                onFilter={onFilter}
                                column={column}
                                handleFiltering={handleFiltering}
                                setClearedItemName={(value) => clearedItemName.current = value}
                                isClearFilter={clearFilters}
                                value={address ? address : ""}
                                enabledFilter={enabledFilter}
                                setEnabledFilter={setEnabledFilter}
                                table="Invoices"
                            />
                        ),
                        title: true,
                        text: "Address",
                        sort: true,
                        formatter: formatCustomerAddress,
                        classes: 'txt-overflow-hide',
                        headerStyle: () => {
                            return { width: '15%' };
                        },
                        attrs: {
                            'data-label': 'Address'
                        }
                    }, {
                        dataField: "price_excl_vat",
                        filter: customFilter({
                            type: "text",
                            style: { display: "inline-grid" },
                            getFilter: (filter) => {
                                setPriceFilter(filter);
                            },
                        }),
                        filterRenderer: (onFilter, column) => (
                            <CustomFilterInputComp
                                onFilter={onFilter}
                                column={column}
                                handleFiltering={handleFiltering}
                                isClearFilter={clearFilters}
                                setClearedItemName={(value) => clearedItemName.current = value}
                                value={price ? price : ""}
                                enabledFilter={enabledFilter}
                                setEnabledFilter={setEnabledFilter}
                                setComparatorFilter={setComparatorFilter}
                                comparatorFilter={comparatorFilter}
                                table="Invoices"
                                defaultComparator={">"}
                            />
                        ),
                        title: true,
                        text: "Price excl/incl VAT",
                        sort: true,
                        formatter: formatCurrency,
                        headerStyle: () => {
                            return { width: '13%' };
                        },
                        attrs: {
                            'data-label': 'Price excl/incl VAT'
                        }
                    }, {
                        dataField: "invoice_status_label",
                        filter: customFilter({
                            type: "select",
                            style: { display: "inline-grid" },
                            withoutEmptyNumberOption: true,
                            getFilter: (filter) => {
                                setStatusFilter(filter);
                            },
                        }),
                        filterRenderer: (onFilter, column) => (
                            <CustomFilterSelect
                                onFilter={onFilter}
                                column={column}
                                handleFiltering={(e) =>
                                    handleFiltering(e, "invoice_status_label", true)
                                }
                                constantObj={statusOptsData}
                                multiSelect={true}
                                clearOnEmpty={handleFilterClick}
                                isClearFilter={clearFilters}
                                setClearedItemName={(value) => clearedItemName.current = value}
                                value={status ? status : ""}
                                enabledFilter={enabledFilter}
                                setEnabledFilter={setEnabledFilter}
                                table="Invoices"
                            />
                        ),
                        title: true,
                        text: "Invoice status",
                        formatter: StatusStyle,
                        sort: true,
                        align: "center",
                        classes: 'inv-col-status-label',
                        headerStyle: () => {
                            return { 'textAlign': 'center', width: '15%' };
                        },
                        attrs: {
                            'data-label': 'Invoice status'
                        }
                    }, {
                        dataField: "billing_run_id",
                        filter: customFilter({
                            type: "select",
                            style: { display: "inline-grid" },
                            getFilter: (filter) => {
                                setBillingRunFilter(filter);
                            },
                        }),
                        filterRenderer: (onFilter, column) => (
                            <CustomFilterSelect
                                onFilter={onFilter}
                                column={column}
                                handleFiltering={(e) =>
                                    handleFiltering(e, "billing_run_id", true)
                                }
                                constantObj={billingRunsData}
                                multiSelect={true}
                                clearOnEmpty={handleFilterClick}
                                isClearFilter={clearFilters}
                                setClearedItemName={(value) => clearedItemName.current = value}
                                value={billingRun ? billingRun : ""}
                                enabledFilter={enabledFilter}
                                setEnabledFilter={setEnabledFilter}
                                table="Invoices"
                            />
                        ),
                        title: true,
                        text: "Billing run",
                        formatter: BillingRunStyle,
                        sort: true,
                        align: "center",
                        classes: "inv-col-status-label",
                        headerStyle: () => {
                            return { textAlign: "center", width: "6%" };
                        },
                        attrs: {
                            "data-label": "Billing run",
                        },
                    }, {
                        dataField: "action",
                        isDummyField: true,
                        text: "",
                        formatter: optionMenuFormatter,
                        classes: 'action-inv-col',
                        headerStyle: () => {
                            return { width: '10%' }
                        },
                        attrs: {
                            'data-label': 'Actions'
                        }
                    }
                ]}
                headerDropdownOpts={[
                    {
                        label: "Filter",
                        action: "collapseFilterAction",
                        icon: <FontAwesomeIcon icon={faFilter} size={`sm`} className={`mr-0`} color={`grey`} />,
                        isCustom: true,
                        tooltip: true,
                        toggleActive: toggleFilterForm ? "active" : ""
                    },
                    {
                        label: "Clear",
                        action: "handleFilterClick",
                        icon: <FontAwesomeIcon icon={faTrash} size={`sm`} className={`mr-0`} color={`grey`} />,
                        isCustom: true,
                        tooltip: true
                    },
                    {
                        label: 'Create invoice',
                        action: redirect,
                        icon: <FontAwesomeIcon icon={faPlusCircle} size={`sm`} className={`mr-0`} color={`grey`} />,
                        tooltip: true
                    }
                ]}
                striped={true}
                notLoadedByDefault={false}
                noDataIndication="No invoices found."
                bodyClasses="table-filter-body-collapse"
                noHover={false}
                handleFilterClick={handleFilterClick}
                collapseFilterAction={collapseFilterAction}
                filters={filters}
                noSearch={false}
                cacheSearch={true}
                filterSearch={true}
                enabledFilter={enabledFilter}
                isLoadData={isLoadData} // isReLoad
                setIsLoadData={setIsLoadData}
                collapseFilter={collapseState}
                isClearFilter={clearFilters}
                noTopBorder={true}
                classes="table-responsive table-filter-collapse filters mb-0 invoices-table filter-tbl-res"
                wrapperClasses="table-invoices f2x-custom-table table-filter-search-wrapper"
                setSearchKeyword={setSearchKeyword}
                page={page}
            />
        </div>
    )
};

export default withRouter(connect()(Invoices));
