import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {
  GetPlanLineGroups,
  GetPlanOffer,
  GetPlan,
} from '../../../controllers/plans';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Row, Col, Form, Input } from "reactstrap";
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate } from "../../../redux/actions/validateActions";
import Toast from "../../../components/Toast";
import {withTranslation} from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../../redux/actions/formatActions';
import ReactQuill from 'react-quill';
import ReactJson from 'react-json-view';
import {updateError} from '../../../redux/actions/errorActions';
import Html from "./html";
import {
  GetContractPeriodList, GetOperators, GetPlanSubscriptionLineTypes,
} from '../../../controllers/subscriptions';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const PlanPreview = ({...props}) => {
  console.log("PlanPreview - props ->", props);

  const dataLoaded = props.dataLoaded;
  const details = props.details;
  const getApi = props.getApi;
  const id = props.id;
  const tenantId = props.tenantId;
  const dispatch = props.dispatch;
  const previewCodeToggle = props.togglePreviewCode;
  const updatePreview = props.updatePreview;

  const [title, setTitle] = useState(props.title);
  const [description, setDescription] = useState(props.description);
  const [groups, setGroups] = useState(props.groups);
  const [contractPeriods, setContractPeriods] = useState(props.contractPeriod);
  const [termsConditions, setTermsConditions] = useState(props.termsConditions);
  const [wishDate, setWishDate] = useState(props.wishDate);
  const [planOfferPrice, setPlanOfferPrice] = useState(props.planOfferPrice);
  const [jsonDetails, setJsonDetails] = useState(null);
  const [jsonDetailsData, setJsonDetailsData] = useState(null);
  const [htmlPreview, setHtmlPreview] = useState(null);

  const updatedPreview = props.updatedPreview;

  console.log("PlanPreview - planOfferPrice ->", planOfferPrice);

  const setData = () => {
    if (details) {
      if (typeof getApi === 'function') {
        (async () => {
          dispatch(loaderToggle(true));

          const {ok, data} = await getApi(null, id);
          if (ok && data && data.data) {
            console.log(
                "PlanPreview - (in useEffect)getApi - getApi - data(data.data) ->",
                data.data);
            console.log(
                "PlanPreview - (in useEffect)getApi - getApi - details ->",
                details);
            const detailsData = data.data;

            let detailsValues = [];
            details.map(({label, data, type}) => {
              if (data && detailsData[data]) {
                detailsValues.push({
                  label: label,
                  value: detailsData[data],
                  id: data,
                  name: data,
                  key: data,
                  type: type,
                });

                setJsonDetailsData(detailsValues);
              }
            });
          }
          dispatch(loaderToggle(false));

        })();
      }
    } else {
      console.log("PlanPreview - details is undefined!");
      updatedPreview(true);
    }
  };

  const getData = () => {
    if (id) {
      (async () => {
        dispatch(loaderToggle(true));

        const {user} = props;

        let tenant_id = tenantId;
        if (!tenant_id) {
          
          tenant_id = user && user.tenant_id ? user.tenant_id : null;

          const tenant = sessionStorage.getItem('tenant');
          if (!tenant_id && tenant) {
            let parsedTenantData = JSON.parse(tenant);
            console.log("PlanPreview - (in getData) - parsedTenantData ->", parsedTenantData);
            tenant_id = parsedTenantData.id;
          } 
        }

        await Promise.all([
          GetPlan(null, id),
          GetPlanOffer(tenant_id, id),
        ]).then((r) => {
          const [plan, planOffer] = r;

          if (plan && planOffer) {
            if (plan.data && plan.data.data) {
              setTitle(plan.data.data.description ? plan.data.data.description : "");
              setDescription(plan.data.data.description_long ? plan.data.data.description_long : "");
            }

            if (planOffer.data && planOffer.data.data) {
              setGroups(planOffer.data.data.groups ? planOffer.data.data.groups : "");
              setContractPeriods(planOffer.data.data.contract_periods ? planOffer.data.data.contract_periods : "");
              setWishDate(planOffer.data.data.wish_date ? planOffer.data.data.wish_date : "");
              setPlanOfferPrice(planOffer.data.data.minimum_price ? planOffer.data.data.minimum_price : "");
            }
          }

          updatedPreview(false);

        });

        dispatch(loaderToggle(false));

      })();
    }
  };

  useEffect(() => {
    console.log("PlanPreview - (in useEffect) - updatePreview ->", updatePreview);
    if (updatePreview === true) {
      getData();
    }
  }, [updatePreview]);

  useEffect(() => {
    setData();
  }, []);

  return (
      <React.Fragment>
        {!previewCodeToggle && /*jsonDetailsData &&*/
        <Html
          title={title}
          description={description}
          price={planOfferPrice}
          groups={groups}
          contractPeriods={contractPeriods}
          wishDate={wishDate}
        />
        }

        {previewCodeToggle && jsonDetailsData &&
        <ul className="pt-2 pb-2 px-2 tree-view-data">
          <ReactJson
                src={jsonDetailsData}
                enableClipboard={false}
                style={{ fontFamily: "inherit", backgroundColor: "transparent" }}
            />
        </ul>
        }
      </React.Fragment>
  );
};

const mapStateToProps = ({ dispatch, user }) => ({ dispatch, user });

export default withRouter(connect(mapStateToProps)(PlanPreview));
