import React, { useState, useEffect } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';
import {getTableObj} from '../../services/tableService';

const CustomFilterDate = ({ column, onFilter, handleFiltering }) => {
  const { t } = useTranslation();

  const [date, setDate] = useState('');

  const clearDateSelected = (e) => {
    e.preventDefault();
    setDate(null);
  };

  useEffect(() => {
    handleFiltering(date, column.dataField);
  }, [date]);

  return [
    <div key={`select-date-${column.dataField}`} className={`custom-finance-select-container mt-0`} style={{display: 'flex', flexDirection: 'column'}}>
      <InputGroup className={`custom-finance-select mt-0`}>
        <DatePicker
            className="form-control f2x-h-text"
            name={column.dataField}
            dateFormat={t("date_format_raw")}
            autoComplete="off"
            placeholderText="Select date"
            selected={date ? new Date(date) : null}
            onChange={(date) => {
              setDate(date ? new Date(date) : null)
            }}
            //value={date ? new Date(date) : new Date()}
            maxDate={new Date()}>
        </DatePicker>
        <InputGroupAddon className="clear-btn" addonType="append">
          <a href="/#" className="danger icon-delete" onClick={clearDateSelected.bind(this)}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  ];
};

export default withRouter(withTranslation('translations')(connect()(CustomFilterDate)));
