import ApiService from '../services/apiService';

const Api = new ApiService(),
  GetAPI = Api.getAPI,
  PostAPI = Api.postAPI,
  UpdateAPI = Api.updateAPI,
  RemoveAPI = Api.removeAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    let data = await method ? method(endpoint, params) : GetAPI(endpoint, params);
    return data
  })()
};

export const GetVatCodes = (params) => {
  return asyncFunc(`vat_codes/list`, params)
};

export const TenantVatCodes = (params, tenant) => {
  return asyncFunc(`tenants/${tenant}/vatcodes`, params)
};

export const GetSingleVatCode = (id, params) => {
  return asyncFunc(`/vat_codes/${id}`, params)
};

export const AddVatCode = (params) => {
  return asyncFunc(`/vat_codes`, params, PostAPI)
};

export const UpdateVatCode = (id, params) => {
  return asyncFunc(`/vat_codes/${id}`, params, UpdateAPI)
};

export const Remove = (id) => {
  return (async () => {
    let data = await RemoveAPI(`vat_codes/${id}`);
    return data
  })()
};
