import React, { useState, useEffect } from 'react';
import { Form, TextField, SelectField, ReactSelectField, RadioField, MultiCheckboxField, SingleCheckboxField, SubmitButton } from './FormElements';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Row, Col, Input } from "reactstrap";
import { loaderToggle } from "../../redux/actions/loaderActions";
import { validateDate } from "../../redux/actions/validateActions";
import Toast from "../../components/Toast";
import {withTranslation} from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';
import ReactQuill from 'react-quill';
import ReactJson from 'react-json-view';
import parse from 'html-react-parser';

const FormBuilder = ({ ...props }) => {
  const formSchema = props.formSchema ? props.formSchema : {};
  const mainKey = props.mainKey ? props.mainKey : false;
  const [formData, setFormData] = useState({});
  const [validationSchema, setValidationSchema] = useState({});

  const formatTextareaData = (getData) => {
    if (getData && getData !== '') {
      if (typeof getData === 'string') {
        return parse(getData);
      } else {
        return getData;
      }
    }
    return '';
  };

  useEffect(() => {
    initForm(formSchema);
  }, []);

  const initForm = (formSchema, mainKey = false) => {
    console.log("FormBuilder - (default)formSchema ->", formSchema);
    console.log("FormBuilder - (default)mainKey ->", mainKey);

    let _formData = {};
    let _validationSchema = {};

    for (let key of Object.keys(formSchema)) {
      console.log("FormBuilder - (default)formSchema - key ->", key);

      _formData[key] = "";

      if (formSchema[key].type === "text") {
        _validationSchema[key] = Yup.string();
      } else if(formSchema[key].type === "email") {
        _validationSchema[key] = Yup.string().email();
      } else if(formSchema[key].type === "select") {
        _validationSchema[key] = Yup.string().oneOf(formSchema[key].options.map(o => o.value.toString()));
      }

      if (formSchema[key].required) {
        if (_validationSchema[key])
          _validationSchema[key] = _validationSchema[key].required('Required');
      }
    }

    setFormData(_formData);
    setValidationSchema(Yup.object().shape({ ..._validationSchema }));
  };

  const getFormElement = (elementName, elementSchema) => {
    console.log("FormBuilder - getFormElement - elementName ->", elementName);
    console.log("FormBuilder - getFormElement - elementSchema ->", elementSchema);
    const props = {
      name: elementName,
      label: elementSchema.label,
      options: elementSchema.options
    };

    if (elementSchema.type === "text" || elementSchema.type === "email") {
      return <TextField {...props} />
    }

    if (elementSchema.type === "select") {
      return <SelectField {...props} />
    }

    if (elementSchema.type === "react-select") {
      return <ReactSelectField {...props} />
    }

    if (elementSchema.type === "radio") {
      return <RadioField {...props} />
    }

    if (elementSchema.type === "checkbox") {
      return <MultiCheckboxField {...props} />
    }
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    console.log(values);
    setSubmitting(false);
  };

  return (
      <div className="form-builder">
        <Form
            enableReinitialize
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
          {Object.keys(formSchema).map( (key, ind) => (
              <div key={key}>
                {getFormElement(key, formSchema[key])}
              </div>
          ))}
        </Form>
      </div>
  );
};

export default FormBuilder;
