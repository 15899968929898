import React from "react";
import { connect } from "react-redux";

const Wrapper = ({ layout, children, tenantStyle }) => (
  <div className={ tenantStyle + " wrapper flex-row-reverse" + (layout.isBoxed ? " wrapper-boxed" : "")}>
    {children}
  </div>
);

export default connect(store => ({
  layout: store.layout
}))(Wrapper);
