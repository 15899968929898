import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import parse from "html-react-parser";
import { Button, Card, CardBody, Container, Row, Col, Label, Media } from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { AddBrand, GetBrand, UpdateBrand } from "../../../controllers/tenants";
import { brand as Brand, updateTheme } from "../../../redux/actions/brandActions";
import { loaderToggle } from "../../../redux/actions/loaderActions";
import ColorPicker from "../../../components/ColorPicker";
import Toast from "../../../components/Toast";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

const colors = [
  'Primary',
  'Secondary',
  'Tertiary',
  'Success',
  'Info',
  'Warning',
  'Danger',
  'Light',
  'Dark',
  'Text light',
  'Text dark'
],
  theme = [{
    component: 'Navbar',
    colors: [
      'Background',
      'Toggle',
      'Toggle hovered',
      'Text',
      'Text user',
      'Border bottom'
    ]
  }, {
    component: 'Sidebar',
    colors: [
      'Background',
      'Links',
      'Link selected',
      'Text tenant'
    ]
  }, {
    component: 'Footer',
    colors: [
      'Background',
      'Text',
      'Border top'
    ]
  }, {
    component: 'Body',
    colors: [
      'Background'
    ]
  }],
  defaultColor = '#e8e8e8';

class Branding extends React.Component {
  constructor(props) {
    super(props);

    this.toolTipItem = React.createRef();
    this.toolTipGlobalItem = React.createRef();

    this.state = {
      brand: {
        theme: {},
        logo: null,
        favicon: null
      },
      savedLogo: null,
      showColorPicker: false,
      defaultColor
    }
  }

  toggleColorPicker = () => {
    this.setState({ showColorPicker: !this.state.showColorPicker })
  };

  handleColorPickerNameChange = (name, e) => {
    const formattedName = this.state.brand.theme[name.replace(/\s/g, '').toLowerCase()];
    this.setState({
      colorPickerName: name,
      defaultColor: formattedName ? formattedName : defaultColor
    });

    this.toggleColorPicker()
  };

  changeColor = (name, color) => {
    let brand = Object.assign({}, this.state.brand);
    brand.theme[name] = color;

    this.props.dispatch(updateTheme(brand.theme));

    this.setState({
      brand,
      showColorPicker: false
    })
  };

  encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
  };

  base64_encode(s) {
    return btoa(unescape(encodeURIComponent(s)));
  };

  decodeBase64 = (data) => {
    return Buffer.from(data, 'base64').toString();
  };

  base64_decode(s) {
    return decodeURIComponent(escape(atob(s)));
  };

  convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleFileChange = async (e) => {
    const elem = e.target,
      file = elem.files[0],
      { name } = elem;

    let reader = new FileReader();

    if (name === 'logo') {
      if (file.type === 'image/svg+xml') {
        console.log("branding - file type === PNG");

        const brand = Object.assign({}, this.state.brand);
        const base64 = await this.convertToBase64(file);
        console.log("Branding - base64 (logo) ->", base64);

        let encodeBase64Str = this.base64_encode(base64);
        console.log("Branding - encodeBase64Str (logo) ->", encodeBase64Str);
        let decodeBase64Str = this.base64_decode(encodeBase64Str);
        console.log("Branding - decodeBase64Str (logo) ->", decodeBase64Str);

        brand[name] = encodeBase64Str;

        this.setState({ brand });

      } else if (file.type === 'image/png') {
        console.log("branding - file type === PNG");

        const brand = Object.assign({}, this.state.brand);
        const base64 = await this.convertToBase64(file);
        console.log("Branding - base64 (logo) ->", base64);

        let encodeBase64Str = this.base64_encode(base64);
        console.log("Branding - encodeBase64Str (logo) ->", encodeBase64Str);
        let decodeBase64Str = this.base64_decode(encodeBase64Str);
        console.log("Branding - decodeBase64Str (logo) ->", decodeBase64Str);

        brand[name] = encodeBase64Str;

        this.setState({ brand });

      } else {
        await Toast.fire(
          { title: 'Only SVG or PNG files are allowed!', icon: 'warning' });

      }
    } else {
      if (file) {
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          if (img.naturalWidth < 50 || img.naturalHeight < 50) {
            Toast.fire({ title: 'File dimensions must be at least 50x50!', icon: 'warning' });

          } else {
            if (file.type === 'image/png') {
              reader.readAsDataURL(file);

              reader.onload = () => {
                const res = reader.result,
                  brand = Object.assign({}, this.state.brand);

                brand[name] = window.btoa(res);

                this.setState({ brand })
              };
              reader.onerror = function (err) {
                console.log(err)
              }
            } else {
              Toast.fire({ title: 'Only PNG files are allowed!', icon: 'warning' });

            }
          }
        }
      }
    }
  };

  handleSubmit() {
    const { brand } = this.state,
      { dispatch, loader, id } = this.props,
      brandData = Object.assign({}, brand);

    console.log("Branding - brandData ->", brandData);

    brandData.theme = JSON.stringify(brandData.theme);

    if (!loader) {
      dispatch(loaderToggle(true));

      if (brand && brand.id) {
        (async () => {
          const { ok, data } = await UpdateBrand(brandData, brand.id);
          this.handleOk(ok, data);

        })()
      } else {
        (async () => {
          brandData.tenant_id = id;
          const { ok, data } = await AddBrand(brandData);
          this.handleOk(ok, data);

        })();
      }
    }
  }

  handleOk = (ok, data) => {
    const { dispatch, id, user } = this.props;

    dispatch(loaderToggle(false));

    if (ok) {
      let brand = Object.assign({}, data.data);

      if (parseInt(id) === parseInt(user.tenant_id)) {
        brand.theme = JSON.parse(brand.theme);
        dispatch(Brand(brand))
      }

      Toast.fire({ title: 'Branding has been updated!', icon: 'success' });

    }
  };

  setDefaultTheme = (e) => {
    e.preventDefault();

    const { dispatch } = this.props;

    Swal.fire({
      title: '',
      text: 'Are you sure you want to set this theme to default?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(r => {
      console.log("Branding - setDefaultTheme - r ->", r);
      dispatch(loaderToggle(true));

      let stop = null;
      if (r.value) {
        stop = 0;

        let brand = Object.assign({}, this.state.brand);
        brand.theme = this.props.theme.currentTheme;

        console.log("Branding - brand theme - currentTheme - brand.theme ->", brand.theme);

        dispatch(updateTheme(brand.theme));
        this.setState({ brand });

        dispatch(loaderToggle(false));

        Toast.fire({ title: 'Branding current theme is now default!', icon: 'success' });

      } else {
        stop = 1;
        dispatch(loaderToggle(false));
      }
    });
  };

  clearImage = (e, name) => {
    const brand = Object.assign({}, this.state.brand);
    brand[name] = null;

    this.setState({ brand });
  };

  componentDidMount() {
    //this.toolTipGlobalItem.current.focus();
    //this.toolTipItem.current.focus();

    const { id, theme, brand, dispatch } = this.props,
      currentTheme = theme.currentTheme,
      brandTheme = brand.theme;

    dispatch(loaderToggle(true));

    (async () => {
      const { ok, data } = await GetBrand(null, id);

      await (() => {
        if (ok) {
          const datas = data.data;

          console.log("Branding - datas ->", datas);

          //console.log("Branding - parsed brand.logo ->", parse(window.atob(this.decodeBase64(brand.logo))))

          let stateBrand = Object.assign({}, this.state.brand);

          if (datas && datas.logo) {
            //let savedLogo = this.decodeBase64(datas.logo);
            let savedLogo = datas.logo;
            console.log("Branding - savedLogo ->", savedLogo);
            let encodedSavedLogo = this.base64_decode(savedLogo);
            let encodedBase64SavedLogo = window.atob(savedLogo);
            console.log("Branding - (decoded) savedLogo ->", encodedSavedLogo);

            this.setState({ savedLogo: encodedBase64SavedLogo });
          }

          if (datas && datas.theme) {
            datas.theme = JSON.parse(datas.theme);
            stateBrand = { ...datas }
          } else {
            if (brand && brandTheme) {
              stateBrand = {
                ...datas,
                theme: {
                  ...brandTheme
                }
              }
            } else {
              stateBrand = {
                ...datas,
                theme: {
                  ...currentTheme
                }
              }
            }
          }

          this.setState({ brand: stateBrand });
          //dispatch(Brand(stateBrand));
        }
      })();

      dispatch(loaderToggle(false));
    })()
  }

  render() {
    const { brand, colorPickerName, showColorPicker, savedLogo } = this.state;
    const { tabTitle } = this.props;

    console.log("Branding - (render) brand ->", brand);
    if (brand && brand.logo) {
      let brand_logo = this.base64_decode(brand.logo);
      console.log("Branding - (render) brand_logo ->", brand_logo);
    }
    console.log("Branding - (render) savedLogo ->", savedLogo);
    if (savedLogo) {
      //let brand_logo = this.base64_decode(brand.logo);
      console.log("Branding - (render) savedLogo ->", savedLogo);
    }

    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <AvForm onSubmit={(e) => this.handleSubmit(e)}>
          <Card className="bt-1 mb-2">
            <CardBody>
              <Container>
                <Row className="p-2">
                  <Col md={3}>Logo:</Col>
                  <Col md={9}>
                    <Label className={`brand-logo mb-0${(!brand || !brand.logo) ? ' btn btn-secondary' : ''}`}>
                      {brand && brand.logo ?
                        <img src={parse(window.atob(brand.logo))} style={{ maxWidth: "200px", height: "auto" }} alt="logo" />
                        : 'SVG or PNG...'
                      }
                      <AvInput
                        type="file"
                        name="logo"
                        onChange={(e) => this.handleFileChange(e)}
                        hidden
                      />
                    </Label>
                    {brand && brand.logo ?
                      <button
                        className="ml-2 danger icon-delete"
                        onClick={(e) => this.clearImage(e, "logo")}
                        title="Remove logo"
                        style={{ background: "none", border: "none", color: "#f44455", marginTop: "auto", display: "inline-block", verticalAlign: "bottom" }}
                      >
                        <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                      </button> : ""}
                  </Col>
                  <Col md={3} className="mt-3">Favicon:</Col>
                  <Col md={9} className="mt-3 row align-items-center">
                    <Col className="col-auto">
                      <Label className={`mb-0${(!brand || !brand.favicon) ? ' btn btn-secondary' : ''}`}>
                        {brand && brand.favicon ?
                          <Media className="favicon" object src={parse(window.atob(brand.favicon))} alt='favicon' style={{ maxWidth: "50px", height: "auto" }} /> : 'PNG 50x50...'
                        }
                        <AvInput
                          type="file"
                          name="favicon"
                          onChange={(e) => this.handleFileChange(e)}
                          hidden
                        />
                      </Label>
                      {brand && brand.favicon ?
                        <button
                          className="ml-2 danger icon-delete"
                          onClick={(e) => this.clearImage(e, "favicon")}
                          title="Remove favicon"
                          style={{ background: "none", border: "none", color: "#f44455", marginTop: "auto", display: "inline-block", verticalAlign: "bottom" }}
                        >
                          <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                        </button> : ""}
                    </Col>
                  </Col>
                  <Col md={3} className="mt-3">Theme:</Col>
                  <Col md={9} className="mt-3 row align-items-center">
                    <Col xs={12}>
                      <Row>
                        <Col xs={2}>Global: </Col>
                        <Col xs={10}>
                          <div style={{ overFlow: "hidden" }} ref={this.toolTipGlobalItem}>
                            {colors.map((color, index) => {
                              const formattedColor = brand.theme[color.replace(/\s/g, '').toLowerCase()];
                              const htmlPopoverColor = <div className={`popover-html-contents`}><p>{color}</p></div>;
                              return (
                                <OverlayTrigger
                                  //container={this.toolTipGlobalItem.current}
                                  key={`${index}-info-color`}
                                  id={`${index}-info-color`}
                                  transition={true}
                                  placement={`top`}
                                  overlay={
                                    <Tooltip
                                      id={`tooltip-top-${index}`}
                                    >
                                      {htmlPopoverColor}
                                    </Tooltip>}
                                >
                                  <div
                                    className="btn btn-color-picker"
                                    style={{ backgroundColor: formattedColor ? formattedColor : defaultColor }}
                                    onClick={(e) => this.handleColorPickerNameChange(color, e)}
                                    title={color}
                                    key={index}>
                                  </div>
                                </OverlayTrigger>
                              )
                            })}
                          </div>
                        </Col>
                      </Row>
                      {theme.map((item, index) => {
                        return (
                          <Row className="mt-3" key={index}>
                            <Col xs={2}> {item.component}: </Col>
                            <Col xs={10}>
                              <div style={{ overFlow: "hidden" }} ref={this.toolTipItem}>
                                {item.colors.map((color, i) => {
                                  const name = `${item.component} ${color}`,
                                    formattedName = brand.theme[name.replace(/\s/g, '').toLowerCase()];
                                  const htmlPopoverHtmlContent = <div className={`popover-html-contents`}><p>{name}</p></div>;
                                  return (
                                    <OverlayTrigger
                                      container={this.toolTipItem.current}
                                      key={`${index}-info-color-${i}`}
                                      id={`${index}-info-color-${i}`}
                                      transition={true}
                                      placement={`top`}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-top-${i}`}
                                        >
                                          {htmlPopoverHtmlContent}
                                        </Tooltip>}
                                    >
                                      <div
                                        className="btn btn-color-picker"
                                        style={{ backgroundColor: formattedName ? formattedName : defaultColor }}
                                        onClick={(e) => this.handleColorPickerNameChange(name, e)}
                                        title={name}
                                        key={i}>
                                      </div>
                                    </OverlayTrigger>
                                  )
                                })}
                              </div>
                            </Col>
                          </Row>
                        )
                      })}
                      <Row className="mt-3">
                        <a href="/" className="ml-3" onClick={(e) => this.setDefaultTheme(e)}>Set as default</a>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
          <Row>
            <Col xs="auto" className="mt-3 ml-auto col-auto">
              <Button color="primary">
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </Col>
          </Row>
        </AvForm>

        {showColorPicker &&
          <ColorPicker
            name={colorPickerName}
            defaultColor={this.state.defaultColor}
            show={showColorPicker}
            hide={this.toggleColorPicker}
            changeComplete={this.changeColor}
          />
        }
      </React.Fragment>
    )
  }
}

const userSelector = createSelector(
  state => state.user,
  user => user
);

const brandSelector = createSelector(
  state => state.brand,
  brand => brand
);

const themeSelector = createSelector(
  state => state.theme,
  theme => theme
);

const loaderSelector = createSelector(
  state => state.loader,
  loader => loader
);

const mapStateToProps = createSelector(
  userSelector,
  brandSelector,
  themeSelector,
  loaderSelector,
  (user, brand, theme, loader) => ({ user, brand, theme, loader })
);

export default connect(mapStateToProps)(withRouter(Branding));
