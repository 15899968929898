import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Form, Input} from "reactstrap";

import { AddAccount, UpdateAccount, GetAccountsLists } from '../../../controllers/accounts';  
import { loaderToggle } from "../../../redux/actions/loaderActions";
import Toast from "../../../components/Toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

class AccountForm extends React.Component {
    constructor(props) {
        super(props);

        const { selectedData } = this.props;
        const fetchingData = false;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'Account',
            account: selectedData ? selectedData : {}
        }
    }

    handleInputChange({ target: { name, value }}) {
        let account = Object.assign({}, this.state.account);

        account[name] = value;

        this.setState({ account })
    }

    handleSelectChange = (name, value) => {
        let account = Object.assign({}, this.state.account);

        account[name] = value.value;

        this.setState({
            account,
            [name]: value
        })
    };

    hasValue = (value) => {
        return value !== null && value !== undefined && value !== '' ? true : false
    };

    handleSubmit(e) {
        e.preventDefault();

        const { selectedData, id, dispatch, loader } = this.props,
            { description, type, code } = this.state.account;

        //Validations
        if (!this.hasValue(description)) {
            Toast.fire({ title: 'Description field is required!', icon: 'warning' })
        }

        if (!this.hasValue(type)) {
            Toast.fire({ title: 'Type field is required!', icon: 'warning' })
        }

        if (!this.hasValue(code)) {
            Toast.fire({ title: 'Code field is required!', icon: 'warning' })
        }

        let account = Object.assign({}, this.state.account);
        account.tenant_id = id;
        
        if (!loader) {
            dispatch(loaderToggle(true));

            if (selectedData) {
                (async () => {                    
                    const { ok } = await UpdateAccount(account.id, account);

                    this.handleOk(ok)
                })()
            } else {
                (async () => {
                    const { ok } = await AddAccount(account);

                    this.handleOk(ok)
                })()
            }
        }
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update()
        }
    };

    componentDidMount() {
        const { dispatch } = this.props;

        if (!this.fetchingData) {
            this.fetchingData = true;

            dispatch(loaderToggle(true));

            (async () => {
                const {ok, data} = await GetAccountsLists(this.props.id);

                if (ok) {
                    const {selectedData} = this.props;
                    let parentOpts = [];

                    data.data.map(({description, id}) => {
                        return parentOpts.push({
                            label: description, value: id
                        })
                    });

                    this.setState({parentOpts});

                    if (selectedData) {
                        let account = Object.assign({}, selectedData);
                        const parent_id = parentOpts.find(
                            item => parseInt(item.value) ===
                                parseInt(account.parent_id));

                        this.setState({
                            account, parent_id
                        })
                    }
                }

                this.setState({hasLoaded: true});
                this.fetchingData = false;

            })();

            dispatch(loaderToggle(false));

        }
    }

    render() {
        const { show, hide, loader } = this.props,
            { hasLoaded, formType, formName, account, parentOpts, parent_id } = this.state,
            { description, type, code, export_code } = account;

        return (
            <React.Fragment>
                { !loader && hasLoaded &&
                    <Modal className="form-subscription-lines" isOpen={show} toggle={hide} centered>
                        <Form onSubmit={(e) => this.handleSubmit(e)}>
                            <ModalHeader>
                                <span>{ formType } { formName }</span>
                            </ModalHeader>
                            <ModalBody key="0" className="mt-3 mb-3">
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">Description:</label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            name="description" 
                                            placeholder="Description" 
                                            value={ this.hasValue(description) ? description : '' } 
                                            onChange={ (e) => this.handleInputChange(e) } 
                                        />
                                    </Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">Type:</label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            name="type" 
                                            placeholder="Format" 
                                            value={ this.hasValue(type) ? type : '' } 
                                            onChange={ (e) => this.handleInputChange(e) } 
                                        />
                                    </Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">Code:</label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            name="code" 
                                            placeholder="Code" 
                                            value={ this.hasValue(code) ? code : '' } 
                                            onChange={ (e) => this.handleInputChange(e) } 
                                        />
                                    </Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">Parent:</label>
                                    </Col>
                                    <Col md={9}>
                                        <Select
                                            id="parent_id"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Parent id"
                                            options={parentOpts}
                                            value={parent_id ? parent_id : null}
                                            onChange={this.handleSelectChange.bind(this, 'parent_id')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col md={3}>
                                        <label className="col-form-label">Export code:</label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            name="export_code" 
                                            placeholder="Export code" 
                                            value={ this.hasValue(export_code) ? export_code : '' } 
                                            onChange={ (e) => this.handleInputChange(e) } 
                                        />
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter className="justify-content-between">
                                <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button color="primary">
                                    <FontAwesomeIcon icon={faSave} />
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(withRouter(AccountForm))
