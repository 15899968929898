import React, {useEffect, useState} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

const Search = ({ ...props }) => {
  let history = useHistory();

  return (
      <div>
        <h1 className="text-bold mb-3">Search results</h1>
        <div className="search-results-container">
          <p>No results found</p>
        </div>
      </div>
  );
};

const mapStateToProps = ({ tenants, user, dispatch }) => ({ tenants, user, dispatch });

export default withRouter(withTranslation()(connect(mapStateToProps)(Search)));
