import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

function useMyDropZone() {
  const onDrop = useCallback((acceptedFiles) => {
    console.log("useMyDropZone - acceptedFiles ->", acceptedFiles);
    // Do something with the files
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
  )
}

export default useMyDropZone;
