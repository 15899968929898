import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, Row, Col, Input, Button } from 'reactstrap';
import InputMask from 'react-input-mask';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import { validateEmail } from '../../../redux/actions/validateActions';
import Swal from "sweetalert2";
import ApiService from '../../../services/apiService';
import { isNumeric } from '../../../services/misc';
import { GetSubscriptionPowerTools } from '../../../controllers/subscriptions';
import Toast from '../../../components/Toast';

const Api = new ApiService(),
    PostAPI = Api.postAPI;

const M7PowerTools = ({ dispatch }) => {
  const [subscrId, setSubscrId] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const [custNumDeprov, setCustNumDeprov] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [customerNumberSubscr, setCustomerNumberSubscr] = useState('');
  const [emailSubscr, setEmailSubscr] = useState('');
  const [passwordSubscr, setPasswordSubscr] = useState('');

  const handleSubscrSubmit = () => {
    if(subscrId) {
      dispatch(loaderToggle(true));
      PostAPI('power_tools/validateSubscription', {subscription_id: subscrId})
      .then(res => {
        const {ok} = res;
        if (ok) {
          const param = {
            apiEndpoint: 'power_tools/resetM7',
            param: {subscription_id: subscrId},
            setClearFunc: setSubscrId,
            confirmTitle: 'Confirm JSON data removal',
            confirmMsg: `Are you sure you want to remove JSON data for subscription ${subscrId}?`,
            successMsg: 'JSON data was successfully removed.',
          };
          confirmAction(param)
        } else {
          dispatch(loaderToggle(false));
          clearFields({setClearFunc: setSubscrId})
        }
      })
      .catch(e => {
        dispatch(loaderToggle(false));
        console.log('Error validating subscription:', e);
        clearFields({setClearFunc: setSubscrId})
      })
    }
  };

  const handleCustomerSubmit = () => {
    if(customerNumber) {
      dispatch(loaderToggle(true));
      const param = {
        apiEndpoint: 'power_tools/closeAccount',
        param: {customer_number: customerNumber},
        setClearFunc: setCustomerNumber,
        confirmTitle: 'Confirm close account',
        confirmMsg: `Are you sure you want to close the M7 account for customer ${customerNumber}?`,
        successMsg: 'Account was successfully closed.',
      };
      confirmAction(param)
    }
  };

  const handleCustomerDeprovisionSubmit = () => {
    if(custNumDeprov && macAddress) {
      dispatch(loaderToggle(true));
      const param = {
        apiEndpoint: 'power_tools/deprovisionStb',
        param: {customer_number: custNumDeprov, smartcard: macAddress},
        setClearFunc: setCustNumDeprov,
        setClearFuncExt: setMacAddress,
        confirmTitle: 'Confirm deprovisioning',
        confirmMsg: `Are you sure you want to deprovision the settop box ${macAddress}?`,
        successMsg: 'Settop box was successfully deprovisioned.',
      };
      confirmAction(param)
    }
  };

  const handleUpdateSubscrSubmit = () => {

    if (!subscriptionId) {
      Toast.fire({ title: 'Subscription Id cannot be empty.', icon: 'warning' })
    }

    const { valid } = dispatch(validateEmail(emailSubscr));

    if (!valid) {
      Toast.fire({ title: 'Invalid email address.', icon: 'warning' })
    }

    if (!passwordSubscr) {
      Toast.fire({ title: 'Password cannot be empty.', icon: 'warning' })
    }

    if (customerNumberSubscr && emailSubscr && valid && subscriptionId) {
      dispatch(loaderToggle(true));

      const params = {
        subscription_id: subscriptionId, 
        customer_number: customerNumberSubscr, 
        email: emailSubscr, 
        password: passwordSubscr,
      };

      GetSubscriptionPowerTools(params)
      .then(res => {
        const {ok} = res;
        if (ok) {
          Toast.fire({ title: 'Subscription updated successfully', icon: 'success' });
          Swal
          .fire({
            title: 'Subscription updated successfully',
            text: 'The subscription’s data has been updated, so the subscription appears to be provisioned.',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        }
        setCustomerNumberSubscr('');
        setEmailSubscr('');
        setPasswordSubscr('');
        setSubscriptionId('');
        dispatch(loaderToggle(false))
      })
      .catch(e => {
        console.log('error updating api:', e);
        dispatch(loaderToggle(false));
        setCustomerNumberSubscr('');
        setEmailSubscr('');
        setPasswordSubscr('');
        setSubscriptionId('')
      })
    }
  };

  const clearFields = ({setClearFunc, setClearFuncExt}) => {
    setClearFunc('');
    if(setClearFuncExt) {
      setClearFuncExt('')
    }
  };
  const confirmAction = ({setClearFunc, setClearFuncExt, confirmTitle, confirmMsg, successMsg, apiEndpoint, param}) => {
    dispatch(loaderToggle(false));
    Swal
    .fire({
      customClass: { container: 'has-cancel', },
      title: confirmTitle,
      text: confirmMsg,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
    .then(res => {
      if (res.value) {
        if (apiEndpoint) {
          dispatch(loaderToggle(true));
          PostAPI(apiEndpoint, param)
          .then(res => {
            const {ok} = res;
            if (ok) {
              Toast.fire({ title: successMsg, icon: 'success' })
            }
            clearFields({setClearFunc, setClearFuncExt});
            dispatch(loaderToggle(false))
          })
          .catch(e => {
            console.log('error updating api:', e);
            dispatch(loaderToggle(false));
            clearFields({setClearFunc, setClearFuncExt})
          })
        } else {
          clearFields({setClearFunc, setClearFuncExt});
          Toast.fire({ title: successMsg, icon: 'success' })
        }
      }
    })
  };

  const validateInput = (val, setValFunc) => {
    if (isNumeric(val) || val === '') 
      setValFunc(val)
  };

  return (
      <>
        <h1 className="text-bold mb-3">M7 power tools</h1>
        <Row className="f2x-m7-ptools-wrapper">
          <Col className="col-md-12 mb-3">
            <Card className="mb-0">
              <CardHeader>
                <h4 className="mb-0">Reset M7 status for subscription </h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="col-md-2 ptools-label">Subscription ID</Col>
                  <Col className="col-md-5">
                    <Input
                      value={subscrId}
                      onChange={(e) => validateInput(e.target.value, setSubscrId)}
                    />
                  </Col>
                  <Col><Button color="primary" className="col-md-5" onClick={handleSubscrSubmit}>Clear M7 data</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col className="col-md-12 mb-3">
            <Card className="mb-0">
              <CardHeader>
                <h4 className="mb-0">CloseAccount for subscription</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="col-md-2 ptools-label">CD Customer number</Col>
                  <Col className="col-md-5">
                    <Input
                      value={customerNumber}
                      onChange={(e) => validateInput(e.target.value, setCustomerNumber)}
                    />
                    </Col>
                  <Col><Button color="primary" className="col-md-5" onClick={handleCustomerSubmit}>Send API request</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col className="col-md-12 mb-3">
            <Card className="mb-0">
              <CardHeader>
                <h4 className="mb-0">Deprovision settop box</h4>
              </CardHeader>
              <CardBody>
                <Row className="pb-3">
                  <Col className="col-md-2 ptools-label">CD Customer number</Col>
                  <Col className="col-md-5">
                    <Input
                      value={custNumDeprov}
                      onChange={(e) => validateInput(e.target.value, setCustNumDeprov)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-2 ptools-label">MAC address</Col>
                  <Col className="col-md-5">
                      <InputMask mask="**:**:**:**:**:**" value={macAddress} onChange={(e) => setMacAddress(e.target.value)}>
                        <Input type="text" name="mac" value={macAddress}/>
                      </InputMask>
                  </Col>
                  <Col><Button color="primary" className="col-md-5" onClick={handleCustomerDeprovisionSubmit}>Send API request</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col className="col-md-12 mb-3">
            <Card className="mb-0">
              <CardHeader>
                <h4 className="mb-0">Set new settings on subscription</h4>
              </CardHeader>
              <CardBody>
                <Row className="pb-3">
                  <Col className="col-md-2 ptools-label">Subscription ID</Col>
                  <Col className="col-md-5">
                    <Input
                      value={subscriptionId}
                      onChange={(e) => validateInput(e.target.value, setSubscriptionId)}
                    />
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col className="col-md-2 ptools-label">CD customer number</Col>
                  <Col className="col-md-5">
                    <Input
                      value={customerNumberSubscr}
                      onChange={(e) => validateInput(e.target.value, setCustomerNumberSubscr)}
                    />
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col className="col-md-2 ptools-label">CD email address</Col>
                  <Col className="col-md-5">
                    <Input
                      value={emailSubscr}
                      onChange={(e) => setEmailSubscr(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col className="col-md-2 ptools-label">CD password</Col>
                  <Col className="col-md-5">
                    <Input
                      value={passwordSubscr}
                      type="password"
                      onChange={(e) => setPasswordSubscr(e.target.value)}

                    />
                  </Col>
                  <Col><Button color="primary" className="col-md-5" onClick={handleUpdateSubscrSubmit}>Update subscription</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </>
  )
};
const mapStateToProps = ({ dispatch }) => ({ dispatch });

export default withRouter(connect(mapStateToProps)(M7PowerTools))