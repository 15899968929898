import React, {useEffect, useState} from 'react';
import { Badge } from "reactstrap";
import { GetProducts, GetTenantProducts, GetAdminProducts } from '../../controllers/products';
import ProductForm from "./Add";
import HierarchyTabs from "./Tabs";
import ParentTable from '../../components/ParentTable';
import CONSTANTS from '../../services/constants';
import SerialsForm from "./serials/Form";
import {useTranslation, withTranslation} from 'react-i18next';
import {createSelector} from 'reselect';
import {useLocation, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const Products = ({ ...props }) => {
  const {t} = useTranslation();
  const location = useLocation();

  const {dispatch, loader, user, tenants} = props;

  const [isAdmin, setIsAdmin] = useState(false);
  const [controllerApi, setControllerApi] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const formatTenant = (cell) => {
    const tenant = Object.values(tenants).find(i => parseInt(i.id) === parseInt(cell));

    if (tenant) {
      return tenant.name;
    } else {
      return String.fromCharCode(8212);
    }
  };

  const formatStatusStyle = (cell, row, rowIndex, formatExtraData) => {
    //console.log("Product list - formatStatusStyle - cell ->", cell);
    //console.log("Product list - formatStatusStyle - row ->", row);

    if (row.status) {
      //console.log("Product list - formatStatusStyle - row.status ->", row.status);

      return (
        <Badge
          color={(row.status.label && row.status.label.toString()) === "ACTIVE"
              ? 'success'
              : (row.status.label && row.status.label.toString()) === "UNAVAILABLE" ? 'danger' : 'warning'}
          className="badge-pill mr-1 mb-1 text-uppercase"
        >
          {row.status.label ? row.status.label.toString() : `DISABLED`}
        </Badge>)
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  const textEmptyFormatter = (cell, row) => {
    return cell ? cell : <span className="null-value">{String.fromCharCode(8212)}</span>
  };

  const formatProductType = (cell, row) => {
    if (cell) {
      const product_type = CONSTANTS['PRODUCT_TYPES'].find(r => r.id === cell);
      //console.log("Product list - formatProductType - product_type ->", product_type);
      if (product_type && product_type.type) {
        return product_type.type;
      } else {
        return <span className="null-value">{String.fromCharCode(8212)}</span>
      }
    }
    return <span className="null-value">{String.fromCharCode(8212)}</span>
  };

  const formatCurrency = (cell, row) => {
    if (cell) {
      //console.log("Product list - formatCurrency - row ->", row);

      let floatCell = cell ? cell : null;
      let floatStyle = floatCell && parseFloat(floatCell) < 0 ? 'float-negative' : floatCell && parseFloat(floatCell) > 0 ? 'float-positive' : 'float-null';

      if (row.price_incl_vat) {
        return (
          <span className={floatStyle ? floatStyle : 'float-zero'}>
            {t("currency_format", { number: Number(parseFloat(cell)) })} / {t("currency_format", { number: Number(parseFloat(row.price_incl_vat)) })}
          </span>)
      } else {
        return (
          <span className={floatStyle ? floatStyle : 'float-zero'}>
            {t("currency_format", { number: Number(parseFloat(cell)) })}
          </span>)
      }
    } else {
      return (
          <span className={'float-zero'}>
            {t("currency_format", { number: Number(0) })} / {t("currency_format", { number: Number(0) })}
          </span>)
    }
  };

  useEffect(() => {
    if (location && location.pathname) {
      const pathname = location.pathname;

      let basePath = `${location.pathname.split('/')[1]}`;
      let basePathTo = `${location.pathname.split('/')[2]}`;

      if (basePath && basePath === 'admin' && basePathTo && basePathTo === "products") {
        setIsAdmin(true);
        setControllerApi("admin");
      } else {
        setControllerApi("user");
      }
    } else {
      setControllerApi("user");
    }
  }, []);

  return (
      <>
        <h1 className="text-bold mb-3">Products</h1>

        {controllerApi === "user" &&
        <ParentTable
          table="Products"
          keyField="id"
          data={GetProducts}
          include="product-type"
          columns={[
            {
              dataField: "description",
              text: "Description",
              sort: true,
              attrs: {
                'data-label': 'Description'
              },
              headerStyle: () => {
                return { width: '30%' }
              }
            }/*, {
                dataField: "backend_api",
                text: "Backend API",
                sort: false,
                formatter: textEmptyFormatter,
                attrs: {
                  'data-label': 'Backend API'
                }
              }*/, {
              dataField: "product_type_id",
              text: "Product type",
              sort: false,
              formatter: formatProductType,
              attrs: {
                'data-label': 'Product type'
              }
            }, {
              dataField: "price_excl_vat",
              text: "Price",
              sort: false,
              align: 'right',
              formatter: formatCurrency,
              headerStyle: () => {
                return { width: '20%', 'textAlign': 'right' }
              },
              attrs: {
                'data-label': 'Price'
              }
            }, {
              dataField: "product_status.label",
              text: "Status",
              align: "center",
              headerStyle: () => {
                return { 'textAlign': 'center', width: '20%' }
              },
              formatter: formatStatusStyle,
              sort: false,
              attrs: {
                'data-label': 'Status'
              }
            }
          ]}
          headerDropdownOpts={[{
            label: 'Add',
            action: ProductForm // => ProductForm (add)
          }]}
          rowAction="edit"
          rowActionForm={HierarchyTabs}
          rowDropdownOpts={[{
            action: 'Edit',
            label: 'Edit product',
            form: HierarchyTabs, // => ProductForm (edit)
          }, {
            action: 'Add',
            label: 'Add serials',
            form: SerialsForm,
            condition: {
              data: 'serials',
              showIfEmpty: true
            }
          }, {
            action: 'Edit',
            label: 'Edit serials',
            form: SerialsForm,
            condition: {
              data: 'serials'
            }
          }]}
          notLoadedByDefault={false}
          offLoader={false}
          striped={true}
          noSearch={false}
          cacheSearch={true}
          filterSearch={true}
          setSearchKeyword={setSearchKeyword}
          noDataIndication="No subscriptions found."
          noHover={false}
          noTopBorder={true}
          classes="table-responsive"
          wrapperClasses="table-product f2x-custom-table"
        />
        }

        {controllerApi === "admin" &&
        <ParentTable
            table="AdminProducts"
            keyField="uid"
            data={GetAdminProducts}
            //include="tenant"
            include="product-type"
            columns={[
              {
                dataField: "tenant_id",
                text: "Tenant",
                sort: true,
                headerStyle: () => {
                  return { width: '15%'};
                },
                formatter: formatTenant,
                attrs: {
                  'data-label': 'Tenant'
                }
              },
              {
                dataField: "description",
                text: "Description",
                sort: true,
                attrs: {
                  'data-label': 'Description'
                },
                headerStyle: () => {
                  return { width: '25%' }
                }
              }/*, {
                dataField: "backend_api",
                text: "Backend API",
                sort: false,
                formatter: textEmptyFormatter,
                attrs: {
                  'data-label': 'Backend API'
                }
              }*/, {
                dataField: "product_type_id",
                text: "Product type",
                sort: false,
                formatter: formatProductType,
                attrs: {
                  'data-label': 'Product type'
                }
              }, {
                dataField: "price_excl_vat",
                text: "Price",
                sort: false,
                align: 'right',
                formatter: formatCurrency,
                headerStyle: () => {
                  return { width: '20%', 'textAlign': 'right' }
                },
                attrs: {
                  'data-label': 'Price'
                }
              }, {
                dataField: "product_status.label",
                text: "Status",
                align: "center",
                headerStyle: () => {
                  return { 'textAlign': 'center', width: '20%' }
                },
                formatter: formatStatusStyle,
                sort: false,
                attrs: {
                  'data-label': 'Status'
                }
              }
            ]}
            headerDropdownOpts={[{
              label: 'Add',
              action: ProductForm // => ProductForm (add)
            }]}
            rowAction="edit"
            rowActionForm={HierarchyTabs}
            rowDropdownOpts={[{
              action: 'Edit',
              label: 'Edit product',
              form: HierarchyTabs, // => ProductForm (edit)
            }, {
              action: 'Add',
              label: 'Add serials',
              form: SerialsForm,
              condition: {
                data: 'serials',
                showIfEmpty: true
              }
            }, {
              action: 'Edit',
              label: 'Edit serials',
              form: SerialsForm,
              condition: {
                data: 'serials'
              }
            }]}
            notLoadedByDefault={false}
            offLoader={false}
            striped={true}
            noSearch={false}
            cacheSearch={true}
            filterSearch={true}
            setSearchKeyword={setSearchKeyword}
            noDataIndication="No subscriptions found."
            noHover={false}
            noTopBorder={true}
            classes="table-responsive"
            wrapperClasses="table-product f2x-custom-table"
          />
        }
    </>
  )
};

const userSelector = createSelector(
    (state) => state.user,
    (user) => user
    ),
    loaderSelector = createSelector(
        (state) => state.loader,
        (loader) => loader
    ),
    tenantsSelector = createSelector(
        (state) => state.tenants,
        (tenants) => tenants
    ),
    mapStateToProps = createSelector(
        userSelector,
        loaderSelector,
        tenantsSelector,
        (user, loader, tenants) => ({ user, loader, tenants })
    );

export default withRouter(withTranslation()(connect(mapStateToProps)(Products)));
