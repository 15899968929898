import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI,
    RemoveAPI = Api.removeAPI;

const GetPersonsCount = () => {
    return (async () => {
        return await GetAPI('persons/count')
    })();
};

const UpdatePerson = (id, params) => {
    return (async () => {
        return await Api.updateAPI(`persons/${id}`, params);
    })();
};

const GetPersons = (id) => {
    return (async () => {
        return await GetAPI(`persons/${id}`)
    })();
};

const DeletePerson = (relation_id, id) => {
    return (async () => {
        return await Api.removeAPI(`relations/${relation_id}/persons/${id}`)
    })();
};

const GetPersonTypesList = () => {
    return (async () => {
        return await GetAPI('person_types/list')
    })();
};

const GetPersonTypes = () => {
    return (async () => {
        return await GetAPI('person_types')
    })();
};

const GetApiKeys = () => {
    return (async () => {
        return await GetAPI(`api_keys`)
    })();
};

const GetApiKeysTenant = (params, tenant) => {
    return (async () => {
        return await GetAPI(`api_keys/tenant/${tenant}`, params)
    })();
};

const GetApiKeysUser = (params, user) => {
    return (async () => {
        return await GetAPI(`api_keys/user/${user}`, params)
    })();
};

const GetApiKeysPerson = (id) => {
    return (async () => {
        return await GetAPI(`api_keys/${id}`)
    })();
};

const AddApiKeysPerson = (params) => {
    return (async () => {
        return await PostAPI(`api_keys`, params)
    })();
};

const UpdateApiKeysPerson = (id, params) => {
    return (async () => {
        return await UpdateAPI(`api_keys/${id}`, params)
    })();
};

const DeleteApiKeysPerson = (id) => {
    return (async () => {
        return await RemoveAPI(`api_keys/${id}`)
    })();
};

export {
    GetPersonsCount,
    UpdatePerson,
    DeletePerson,
    GetPersons,
    GetPersonTypes,
    GetPersonTypesList,

    GetApiKeys,
    GetApiKeysTenant,
    GetApiKeysUser,
    GetApiKeysPerson,
    AddApiKeysPerson,
    UpdateApiKeysPerson,
    DeleteApiKeysPerson
}