import React, {useState, useEffect} from 'react';
import { File } from "react-feather";
import { connect } from 'react-redux'
import { NavLink, withRouter } from "react-router-dom";
import { Card, Row, Spinner, Col, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import classnames from 'classnames';
import BillingRunOverview from './BillingRunOverview';
import Finance from './Finance';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const NavTabsFinance = ({...props}) => {
  const [activeTab, setActiveTab] = useState('billing-run-tools');
  const [relationTabSettings, setRelationTabSettings] = useState(1); // relationTab settings => value: 1 = label: "Show tab icon & text" | value: 2 = label: "Show tab icon only" | value: 3 = label: "Show tab text only"

  const tabs = [
    {label: 'Tools', link: '/billing-run-tools', id: 'billing-run-tools', icon: 'tools'},
    {label: 'Overview', link: '/billing-run-overview', id: 'billing-run-overview', icon: 'archive'}
  ];

  useEffect(() => {
    const { user } = props;
    if (user) {
      const { json_data } = user;
      //console.log("useEffect - NavTabsFinance - props.user - json_data ->", json_data);
      if (json_data && json_data !== "") {
        let json_data_user = JSON.parse(json_data);
        //console.log("useEffect - NavTabsFinance - props.user - json_data_user ->", json_data_user);
        if (json_data_user && json_data_user.relation_tabs) {
          setRelationTabSettings(json_data_user.relation_tabs);
        }
      }
    }
  }, []);

  return (
      <>
        <Nav tabs className="f2x-mv-tabs mb-3" style={{borderBottom: "1px solid #dee2e6"}}>
          { tabs.map(({label, link, icon, id}) => {
            return (
                <NavItem key={id} style={{marginBottom: "0px"}}>
                  {relationTabSettings === 1 && 
                  <NavLink
                    to={link}
                    className="nav-link billing-run-tab-link"
                  >
                    <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={icon} />
                    <span className="f2x-mv-tabs-list-item-text">{label}</span>
                  </NavLink>}

                  {relationTabSettings === 2 && 
                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom-${label.replace(/[^a-z0-9 _-]/gi, '-').toLowerCase()}-li-${id}`}>
                      <div className={`popover-html-contents`}>
                        <span>{label}</span>
                      </div>
                    </Tooltip>}
                  >
                    <NavLink
                      to={link}
                      className="nav-link billing-run-tab-link"
                    >
                      <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={icon} />
                    </NavLink>
                  </OverlayTrigger>
                  }

                  {relationTabSettings === 3 && 
                  <NavLink
                    to={link}
                    className="nav-link billing-run-tab-link"
                  >
                    <span className="f2x-mv-tabs-list-item-text" style={{marginLeft: "0px"}}>{label}</span>
                  </NavLink>}
                </NavItem>
            )
          })}
        </Nav>
      </>
  )
};

const mapStateToProps = ({ dispatch, user }) => ({ dispatch, user });

export default withRouter(connect(mapStateToProps)(NavTabsFinance));
