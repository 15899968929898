import React, { useEffect, useState } from 'react';
import { Check, Edit3, X } from 'react-feather';
import { GetRelEmail, GetRelEmails } from '../../../controllers/relations';
import CONSTANTS from "../../../services/constants";
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button, Col, ListGroup, Row } from 'reactstrap';
import EmailList from './ListEmails';
import EmailDetails from './ListEmailDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCircleNotch, faHotTub, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";

const Emails = ({ id, relation, emailId, loader, dispatch, titleUpdate, ...props }) => {
  //console.log("Emails List - id ->", id);
  //console.log("Emails List - emailId ->", emailId);
  //console.log("Emails List - relation ->", relation);
  //console.log("Emails List - props ->", props);

  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const params = useParams();
  const { param } = params;
  const newTitle = emailId ? `${titleUpdate} | ${emailId}` : `${titleUpdate}`;

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(100);
  const [totalSize, setTotalSize] = useState(0);
  const [lastVisitEmailId, setLastVisitEmailId] = useState(emailId ? emailId : null);
  const [salesInvoiceId, setSalesInvoiceId] = useState(null);
  const [emailList, setEmailList] = useState(null);
  const [emailDetails, setEmailDetails] = useState(null);
  const [data, setData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(emailId ? emailId : null);
  const [emptyMessage, setEmptyMessage] = useState(null);
  const [offset, setOffset] = useState(0);

  const getRelEmails = async () => {
    //const { dispatch, id, disableLoader } = this.props;

    let param = {
      offset: offset + 20
    };

    setOffset(param.offset);

    (async () => {
      if (!loader)
        dispatch(loaderToggle(true));

      await GetRelEmails(id, param)
        .then(res => {
          //console.log("Emails relation - getRelEmails - res ->", res);

          if (res.ok) {
            const { data } = res;

            //console.log("Emails relation - getRelEmails - res - data ->", data);

            if (data && data.message === "Emails summary not found.") {
              //console.log("Emails relation - getRelEmails - res - data - data.message ->", data.message);

              /*setData([]);
              setEmailList([]);
              setTotalSize(0);
              setLastVisitEmailId(null);
              setSelectedItem(null);
              setSalesInvoiceId(null);
              setEmailDetails(null);*/
              //setTimeout(() => {
              setEmptyMessage("No emails found.");
              //}, 1500);

            } else {

              setData(data && data.data ? data.data : []);
              setEmailList(data && data.data ? data.data : []);
              setTotalSize(data && data.total ? data.total : 0);
              setEmailDetails(null);

              // get id from session storage - lastVisitEmailId
              const sessionsLastVisitEmailId = sessionStorage.getItem('lastVisitEmailId');
              if (sessionsLastVisitEmailId) {
                //console.log("Emails List - GetRelEmails - sessionsLastVisitEmailId ->", sessionsLastVisitEmailId);

                const findEmail = data.data.find(item => parseInt(item.id) === parseInt(sessionsLastVisitEmailId));
                //console.log("Emails List - GetRelEmails - sessionsLastVisitEmailId - findEmail ->", findEmail);

                if (findEmail && findEmail.id) {
                  setLastVisitEmailId(findEmail.id);
                  setSelectedItem(findEmail.id);
                  setSalesInvoiceId(findEmail.sales_invoice_id);
                  setEmailDetails(findEmail);
                } else {
                  setLastVisitEmailId(data && data.data && data.data[0] ? data.data[0].id : null);
                  setSelectedItem(data && data.data && data.data[0] ? data.data[0].id : null);
                  setSalesInvoiceId(data && data.data && data.data[0] ? data.data[0].sales_invoice_id : null);
                  setEmailDetails(data && data.data && data.data[0] ? data.data[0] : null);
                }

              } else {
                setLastVisitEmailId(data && data.data && data.data[0] ? data.data[0].id : null);
                setSelectedItem(data && data.data && data.data[0] ? data.data[0].id : null);
                setSalesInvoiceId(data && data.data && data.data[0] ? data.data[0].sales_invoice_id : null);
                setEmailDetails(data && data.data && data.data[0] ? data.data[0] : null);
              }
            }
          }
        })
        .catch(e => {
          console.log('Error', e);
        });

      dispatch(loaderToggle(false));

    })();
  };

  useEffect(() => {
    //to do check given emailId with session storage emailId
    const sessionsLastVisitEmailId = sessionStorage.getItem('lastVisitEmailId');
    if (sessionsLastVisitEmailId && emailId) {
      //console.log("Emails List - useEffect - sessionsLastVisitEmailId ->", sessionsLastVisitEmailId);
      //console.log("Emails List - useEffect - emailId ->", emailId);
      //console.log("Emails List - useEffect - params ->", params);
      //console.log("Emails List - useEffect - relation ->", relation);

      if (parseFloat(sessionsLastVisitEmailId) !== emailId) {
        //console.log("Emails List - useEffect - sessionsLastVisitEmailId !== emailId!!!!!!!!!!!!!");
        // set new session storage
        sessionStorage.setItem('lastVisitEmailId', emailId);
      }
    }

  }, []);

  useEffect(() => {
    // get email details
    if (!emailList) {
      getRelEmails();
    }
  }, []);

  return (
    <React.Fragment>
      {newTitle &&
        <Helmet>
          <title>{newTitle}</title>
        </Helmet>}
      {emptyMessage && !loader ?
        <Row className="mt-4 invoices-details-container full-height-container">
          <Col>
            <p className="empty-message">{emptyMessage}</p>
          </Col>
        </Row>
        :
        <Row className="mt-4 invoices-details-container full-height-container">
          <Col className="col-md-3 col-lg-3 col-sm-12 f2x-sbscr-det-cont">
            {emailList &&
              <EmailList
                onScroll={getRelEmails}
                emailList={emailList}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                setLastVisitEmailId={setLastVisitEmailId}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                totalSize={totalSize}
                relationId={relation.id}
              />
              }              
          </Col>
          <Col className="col-md-9 col-lg-9 col-sm-12 col-12 f2x-rel-mv-detail invoice d-flex flex-wrap">
            <div className={`align-content-start all-0 d-flex flex-column flex-list-items w-100`}>
              <div className={`f2x-rel-mv-detail`}>
                <div className={`f2x-rel-det-form `}>
                  {emailDetails &&
                    <EmailDetails emailDetails={emailDetails} lastVisitEmailId={lastVisitEmailId} history={history} />}
                </div>
              </div>
            </div>
          </Col>
        </Row>}
    </React.Fragment>
  )
};

const mapStateToProps = ({ dispatch, relation, user, loader }) => ({ dispatch, relation, user, loader });

export default connect(mapStateToProps)(withTranslation()(withRouter(Emails)));
