import React, { useState, useEffect } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';

const CustomFilterInput = ({ column, onFilter, handleFiltering, clearInput }) => {
  const { t } = useTranslation();

  return [
    <div key={`input-${column.dataField}`} style={{display: 'flex', flexDirection: 'column'}}>
      <InputGroup className={`custom-finance-select mt-0`}>
        <Input type="text"
               className="f2x-fin-h-text mt-0"
               name={column.dataField}
               id={`id-input-${column.dataField}`}
               key={`key-input-${column.dataField}`}
               defaultValue={``}
               placeholder={column.filter && column.filter.props && column.filter.props.placeholder ? column.filter.props.placeholder : 'Search...'}
               onKeyPress={(e) => handleFiltering(e, column.dataField)} />
        <InputGroupAddon className="clear-btn clear-filter" addonType="append">
          <a href="/#" className="danger icon-delete" onClick={(e) => clearInput(e, `id-input-${column.dataField}`)}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  ];
};

export default withRouter(withTranslation('translations')(connect()(CustomFilterInput)));
