import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({showModal, handleClose, handleLogout, remaining, timeTillPrompt, seconds}) => {
  return (
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">You Have Been Idle!</h5>
        </Modal.Header>
        <Modal.Body>You Will Get Timed Out in {remaining} second(s).<br />You want to stay?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Stay logged in
          </Button>
        </Modal.Footer>
      </Modal>
  )
};