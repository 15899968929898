import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const ModalWrapper = ({show, hide, title, children, ...props}) => {
  const CloseIsDisabled = props.disabled ? props.disabled : false; 
  const refName = title ? title : props.name ? props.name : ""+"RefNode";
  const nodeRef = React.useRef(refName);
  const styling = props.overflow ? {overflow: "hidden", marginBottom: "1rem"} : {};

  return (
    <Modal
      {...props}
      ref={nodeRef}
      innerRef={nodeRef}
      isOpen={show}
      toggle={hide}
      centered
    >
      {title &&
      <ModalHeader toggle={hide}><span style={{fontWeight:"normal"}}>{title}</span></ModalHeader>}
      <ModalBody style={styling}>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button 
          className="modal-activity-logs" 
          color="danger" 
          onClick={hide} 
          disabled={CloseIsDisabled ? true : false}
          name={props.name ? props.name : "close-button"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </ModalFooter>
    </Modal>
  )
};

export default ModalWrapper;
