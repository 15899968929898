import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {NavLink, useLocation, withRouter} from 'react-router-dom';
import {
  GetPlanLineGroups,
  GetPlanLineGroup,
  AddPlanLineGroup,
  UpdatePlanLineGroup,
  RemovePlanLineGroup,
  GetPlans,
} from '../../../controllers/plans';
import { Card, Row, Spinner, Col, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import classnames from 'classnames';
import NavPlanTabs from '../NavPlanTabs';
import PlanLineGroupForm from "./Form";
import RemovePlanLineGroupForm from "./FormRemove";
import {useTranslation, withTranslation} from 'react-i18next';
import PlanForm from '../Form';
import RemovePlanForm from '../RemovePlan';
import ParentTable from '../../../components/ParentTable';
import TermConditionForm from '../terms_conditions/Form';
import parse from 'html-react-parser';

const PlanLineGroups = ({ ...props }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { dispatch, loader, user } = props;

  const [isAdmin, setIsAdmin] = useState(false);
  const [controllerApi, setControllerApi] = useState(null);

  const dataFormatter = (cell, row, rowIndex) => {
    return (<div>{parse(cell)}</div>);
  };

  useEffect(() => {
    if (location && location.pathname) {
      const pathname = location.pathname;

      let basePath = `${location.pathname.split('/')[1]}`;
      let basePathTo = `${location.pathname.split('/')[2]}`;

      if (basePath && basePath === 'admin' && basePathTo && basePathTo === "plans") {
        setIsAdmin(true);
        setControllerApi("admin");
      } else {
        setControllerApi("user");
      }
    } else {
      setControllerApi("user");
    }
  }, []);

  return(
    <>
      <h1 className="text-bold mb-3">Plan Line Groups</h1>
      <NavPlanTabs />
      <ParentTable
          table="planLineGroups"
          keyField="id"
          data={GetPlanLineGroups}
          include='parent'
          cacheSearch={true}
          columns={[
            {
              dataField: "description",
              text: "Description",
              sort: true,
              //formatter: dataFormatter,
              //classes: "txt-overflow-hide plan-line-groups-description",
              classes: "txt-overflow-hide",
              headerStyle: () => {
                return { width: '20%'};
              },
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "header",
              text: "Header",
              sort: true,
              headerStyle: () => {
                  return { width: '30%'};
              },
              classes: "txt-overflow-hide",
              attrs: {
                  'data-label': 'Header'
              }
            },
            {
              dataField: "sort",
              text: "Sort",
              sort: true,
              align: "center",
              headerStyle: () => {
                return { width: '10%', 'textAlign': 'center' };
              },
              attrs: {
                'data-label': 'Sort'
              }
            },
            {
              dataField: "type",
              text: "Type",
              sort: true,
              align: "center",
              headerStyle: () => {
                return { width: '14%', 'textAlign': 'center', 'paddingRight': '12px' };
              },
              attrs: {
                'data-label': 'Type'
              }
            },
          ]}
          rowAction="edit"
          rowActionForm={ PlanLineGroupForm }
          headerDropdownOpts={[{
            label: 'Add',
            action: PlanLineGroupForm
          }]}
          rowDropdownOpts={[{
            action: 'Remove',
            label: 'Remove',
            form: RemovePlanLineGroupForm
          }]}
          notLoadedByDefault={false}
          offLoader={false}
          striped={true}
          noDataIndication="No plan line groups found."
          noHover={false}
          noTopBorder={true}
          classes="table-responsive"
          wrapperClasses="table-plans f2x-custom-table"
      />
    </>
  )
};

export default withRouter(withTranslation()(connect()(PlanLineGroups)));
