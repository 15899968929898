import React from 'react';
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { DeletePerson } from '../../../../controllers/persons';
import { loaderToggle } from '../../../../redux/actions/loaderActions';
import Toast from "../../../../components/Toast";

class RemovePersonForm extends React.Component {
    deleteRow = (rowIndex) => {
        if (rowIndex) {
            let table = document.querySelector('table');
            table.deleteRow((parseInt(rowIndex)+1));
        }
    };

    selectedRow = (rowIndex) => {
        if (rowIndex) {
            let table = document.querySelector('table');
            let table_body = table.children && table.children[1];
            table_body.rows[rowIndex].setAttribute("class","row-to-delete");
        }
    };

    unSelectRow = (rowIndex) => {
        if (rowIndex) {
            let table = document.querySelector('table');
            let table_body = table.children && table.children[1];
            table_body.rows[rowIndex].removeAttribute("class");
        }
    };

    componentDidMount() {
        const { loader, dispatch, hide, update, selectedData, relation } = this.props;

        if (this.props.selectedDataRow) {
            this.selectedRow(this.props.selectedDataRow);
        }

        Swal.fire({
            title: 'Please confirm!',
            text: 'Are you sure you want to remove this person?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            reverseButtons: true
        }).then(res => {
            if (res.value) {
                if (!loader) {
                    (async () => {
                        dispatch(loaderToggle(true));

                        const { ok } = await DeletePerson(relation.id, selectedData.id);
                        if (ok) {
                            dispatch(loaderToggle(false));
                            update('updateperson');

                        } else {
                            await Toast.fire({
                                title: 'Something went wrong!',
                                icon: 'warning'
                            })
                        }

                        dispatch(loaderToggle(false));
                        hide();

                    })();
                }
            } else {
                this.unSelectRow(this.props.selectedDataRow);
                update('updateperson');
                hide();

            }
        })
    }

    render() {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }
}

export default connect(store => ({
    loader: store.loader,
    relation: store.relation
}))(RemovePersonForm);