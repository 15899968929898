import React from "react";
import {connect} from "react-redux";
import {
  GetPlan,
  UpdatePlan,
  GetDependenciesPlans,
  GetPlans,
  GetPlanLineGroups,
  GetPlanOffer,
  GetPlanOffers,
} from '../../controllers/plans';
import { GetNetworkOperatorList, GetNetworkOperators, GetOperators } from '../../controllers/subscriptions';
import Details from "../../components/Details";
import { Form, TextField, SelectField, RadioField, MultiCheckboxField, SingleCheckboxField, SubmitButton } from "../../components/form_builder/FormElements";
import FormBuilder from "../../components/form_builder/FormBuilder";
import DynamicTextField from "../../components/DynamicTextAreas";
import PlanLines from "./plan_lines/List";
import PlanPreview from "./plan_preview/List";
import DuplicatePlanForm from './DuplicatePlan';
import DelegatePlanForm from './DelegatePlan';
import Unauthorized from "../auth/Unauthorized";
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import NavPlanTabs from './NavPlanTabs';
import {Row, Col, Button, Input} from 'reactstrap';
import { Offcanvas } from "react-bootstrap";
import {
  GetContractPeriodList, GetPlanSubscriptionLineTypes, GetStatusList,
} from '../../controllers/subscriptions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlink,
  faLink,
  faCalendarAlt,
  faLock,
  faLockOpen,
  faCode,
  faEye,
  faCheck,
  faCircleNotch,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { ThumbsDown } from "react-feather";

class PlanDetail extends React.Component {
  constructor(props) {
    super(props);

    //console.log("Plan details - props ->", props);

    this.state = {
      togglePreview: false,
      togglePreviewDisabled: false,
      togglePinPreview: false,
      togglePinPreviewText: "",
      togglePinPreviewIcon: faLockOpen,
      toggleDynamicFields: true,
      updatePreview: false,
      controllerApi: "user",
      planTypeOpts: [],
      isPreviewPage: true,
      duplicatePlanFormOpen: false,
      delegatePlanFormOpen: false
    }
  }

  togglePlanDynamicFields = () => {
    this.setState({
      toggleDynamicFields: !this.state.toggleDynamicFields,
    });
  };

  toggleDuplicatePlan = (e) => {
    //console.log("Plans - toggleDuplicatePlan - e ->", e);
    //let form = DuplicatePlanForm;
    //console.log("Plans - toggleDuplicatePlan - form ->", form);
    this.setState({
      duplicatePlanFormOpen: !this.state.duplicatePlanFormOpen
    });
  };

  toggleDelegatePlan = (e) => {
    this.setState({
      delegatePlanFormOpen: !this.state.delegatePlanFormOpen
    });
  };

  togglePlanPreview = () => {
    this.setState({
      togglePreview: !this.state.togglePreview,
    });

    const targetEl = document.getElementById("root");
    //console.log("Plans - togglePlanPreview - targetEl ->", targetEl);
    //console.log("Plans - togglePlanPreview - this.state.togglePinPreview ->", this.state.togglePreview);

    if (targetEl && this.state.togglePreview === true) {
      targetEl.style.removeProperty("width");
    }
  };

  togglePlanPinPreview = (e) => {
    e.preventDefault();

    this.setState({
      togglePinPreview: !this.state.togglePinPreview,
      togglePreviewDisabled: !this.state.togglePreviewDisabled,
      togglePinPreviewIcon: !this.state.togglePinPreview ? faLock : faLockOpen,
    });

    //console.log("Plans - togglePlanPreview - this.state.togglePinPreview ->", this.state.togglePinPreview);

    const targetEl = document.getElementById("root");
    if (targetEl && !this.state.togglePinPreview === true) {
      targetEl.style.setProperty("width", "calc(100% - 400px)");
    } else if (targetEl && !this.state.togglePinPreview === false) {
      targetEl.style.removeProperty("width");
    }

    // set session storage
    sessionStorage.setItem("planPreview", !this.state.togglePinPreview);
  };

  handleClose = (e) => {
    //console.log("Plans - offcanvas handle close");
    //console.log("Plans - offcanvas handle close - togglePreview ->", this.state.togglePreview);

    this.setState({
      togglePreview: false,
      togglePinPreview: false,
      togglePreviewDisabled: false,
      togglePinPreviewIcon: faLockOpen,
    });

    const targetEl = document.getElementById("root");
    if (targetEl) {
      targetEl.style.removeProperty("width");
    }
  };

  getTenantId = () => {
    let tenant_id;

    const tenant = sessionStorage.getItem('tenant');
    if (tenant) {
      let parsedTenantData = JSON.parse(tenant);
      tenant_id = parsedTenantData.id;
    }

    return tenant_id;
  };

  dataLoaded = (data) => {
    if (data) {
      const plan = data;

      this.setState({
        plan,
        plan_lines: plan.plan_lines,
        title: plan.description,
      });

      (async () => {
        await Promise.all([
          //GetDependenciesPlans(this.props.match.params.id),
          //GetPlan(this.props.match.params.id),
          GetPlans(),
          GetContractPeriodList(),
          GetOperators(),
          GetPlanSubscriptionLineTypes(),
          GetPlanLineGroups(),
          GetPlanOffer(plan.tenant_id, this.props.match.params.id),
        ]).then((r) => {
          const [/*dependenciesPlans,*/ plans, contractPeriods, operators, planLineTypes, planLineGroups, planOffer] = r;

          //console.log("Plans details - planLineGroups ->", planLineGroups);
          //console.log("plansDetails - dependenciesPlans ->", dependenciesPlans);
          //console.log("contractPeriods ->", contractPeriods);
          //console.log("operators ->", operators);
          //console.log("planLineTypes ->", planLineTypes);
          //console.log("Plans details - planOffer ->", planOffer);

          const { plan } = this.state;
          console.log("Plans details - plan ->", plan);

          let planOpts = [];
          if (plans && plans.data) {
            if (plans.data && plans.data.data) {
              plans.data.data.map(({description, id, plan_lines}) => {
                const isCurrent = plan && plan.id ? parseInt(plan.id) ===
                    parseInt(id) : null;

                if (!isCurrent) {
                  return planOpts.push({
                    label: description, value: id,
                  });
                } else {
                  return false;
                }
              });
            }
          }

          let planTypeOpts = [];
          if (planLineTypes && planLineTypes.data && planLineTypes.data.data) {
            planLineTypes.data.data.map(({id, line_type, description}) => {
              return planTypeOpts.push({
                label: line_type,
                value: id,
              });
            });

            this.setState({
              planTypeOpts: planTypeOpts,
            });
          }

          let contractPeriodsList = [];
          if (contractPeriods && contractPeriods.data && contractPeriods.data.data) {
            contractPeriods.data.data.map(({id, period, net_days}) => {
              return contractPeriodsList.push({
                label: period, value: period, key: id,
              })
            });
          }

          let planLineGroupsJsonData = {};
          if (planLineGroups && planLineGroups.data && planLineGroups.data.data) {
            console.log("Plan details - planLineGroups ->", planLineGroups.data.data);
            planLineGroups.data.data.map(({id, description, header, json_data, text, type}) => {
              //planLineGroupsJsonData[header.replace(/ /g,"_").toLowerCase()] = JSON.parse(json_data);
              planLineGroupsJsonData[description.replace(/ /g,"_").toLowerCase()] = JSON.parse(json_data);
            });
          }

          let planLineGroupsOpts = [];
          planLineGroupsOpts.push({ label: "None", value: null });

          if (planLineGroups && planLineGroups.data && planLineGroups.data.data) {
            planLineGroups.data.data.map(({id, header}) => {
              return planLineGroupsOpts.push({
                label: header,
                value: id,
              })
            });

            this.setState({
              planLineGroupsOpts: planLineGroupsOpts,
            });
          }

          let operatorsList = [];
          let operatorsL1 = [];
          let operatorsL2 = [];
          let operatorsL3 = [];
          if (operators && operators.data && operators.data.data) {
            operators.data.data.map(({id, name, L1, L2, L3, provisioning_api, microservice_id}) => {
              if (L1 === 1) {
                return operatorsL1.push({
                  label: name,
                  value: id,
                });
              }
            });

            operators.data.data.map(({id, name, L1, L2, L3, provisioning_api, microservice_id}) => {
              if (L2 === 1) {
                return operatorsL2.push({
                  label: name,
                  value: id,
                });
              }
            });

            operators.data.data.map(({id, name, L1, L2, L3, provisioning_api, microservice_id}) => {
              if (L3 === 1) {
                return operatorsL3.push({
                  label: name,
                  value: id,
                });
              }
            });

            operators.data.data.map(({id, name, L1, L2, L3, provisioning_api, microservice_id}) => {
              return operatorsList.push({
                label: name,
                value: id,
                extra: [{
                  L1: L1,
                  L2: L2,
                  L3: L3,
                }]
              })
            });
          }

          const staticDetails = [
            {
              label: 'Description',
              data: 'description',
              type: 'text'
            },
            {
              label: 'Long description',
              data: 'description_long',
              type: 'textarea'
            },
            {
              label: 'Plan type',
              data: 'plan_type',
              type: 'select',
              opts: planTypeOpts,
            },
            {
              label: 'Plan start / end',
              multiData: 'plan_start / plan_stop',
              datas: [
                {
                  data: 'plan_start',
                  type: 'datepicker',
                  maxDate: 'plan_stop'
                },
                {
                  data: 'plan_stop',
                  type: 'datepicker',
                  minDate: 'plan_start'
                }
              ]
            },
            {
              label: 'Priority',
              data: 'priority',
              type: 'number',
              style: "text_style",
            },
            {
              label: 'Parent plan',
              data: 'parent_plan',
              type: 'select',
              opts: planOpts,
              disabledIfTrue: this.isParentPlanNonEditable,
            },
            {
              label: 'L1',
              data: 'operator_L1_id',
              type: 'select',
              opts: operatorsL1,
              value: 'operator_L1_id',
            },
            {
              label: 'L2',
              data: 'operator_L2_id',
              type: 'select',
              opts: operatorsL2,
              value: 'operator_L1_id',
            },
            {
              label: 'L3',
              data: 'operator_L3_id',
              type: 'select',
              opts: operatorsL3,
              value: 'operator_L1_id',
            },
            {
              label: 'JSON data',
              data: 'json_data',
              type: 'textarea_json',
              extra: {
                validator: true,
                preview_json: true,
              },
            },
            {
              label: 'Wish date',
              data: 'wish_date',
              type: 'textarea_json',
              //extra: {
              //  validator: true,
              //  preview_json: true,
              //},
            },
            {
              label: 'Contract period',
              data: 'contract_period',
              type: 'textarea_json',
              //extra: {
              //  validator: true,
              //  preview_json: true,
              //},
            },
          ];

          // form builder
          const dynamicFormDetails = [
            {
              label: 'Wish date',
              multiData: 'default / other',
              datas: [
                {
                  data: 'wish_date',
                  label: 'z.s.m.',
                  type: 'radio',
                  maxDate: '',
                  datas: [],
                },
                {
                  data: 'wish_date',
                  label: 'other',
                  type: 'radio',
                  datas: [
                    {
                      data: 'other',
                      type: 'datepicker',
                      minDate: '',
                    }
                  ],
                }
              ],
            },
            {
              label: 'Contract period',
              data: 'contract_period',
              type: 'react-select',
              opts: contractPeriodsList
            },
          ];

          // example form builder
          /*const formSchema = {
            period: {
              type: "react-select",
              label: "Contract period",
              required: false,
              options: contractPeriodsList,
            },
            wish_date: {
              type: "radio",
              label: "Wish date",
              required: true,
              options: [
                {
                  label: "z.s.m.",
                  value: "z.s.m.",
                  name: "wish_date",
                  function: null,
                },
                {
                  label: "Anders",
                  value: "anders",
                  name: "wish_date",
                  function: {
                    "func": "toggle",
                    "element": "datepicker",
                    "format": "dd-MM-yyyy",
                    "minDate": 14,
                    "name": "other_wish_date",
                  },
                }
              ]
            }
          };*/
          let formSchema = planLineGroupsJsonData;
          let groups;
          let planOfferPrice = 0;

          //console.log("Plan details - planOffer ->", planOffer);

          if (planOffer && planOffer.data && planOffer.data.data && planOffer.data.data.groups) {
            groups = planOffer.data.data.groups;
          }

          if (planOffer && planOffer.data && planOffer.data.data && planOffer.data.data.minimum_price) {
            planOfferPrice = planOffer.data.data.minimum_price;
          }

          //console.log("Plan details - planLineGroupsJsonData ->", planLineGroupsJsonData);
          //console.log("Plan details - planOfferPrice ->", planOfferPrice);

          this.setState({
            planOpts,
            staticDetails,
            dynamicFormDetails,
            formSchema,
            groups,
            planOfferPrice,
            tenantId: plan && plan.tenant_id ? plan.tenant_id : this.getTenantId,
            hasLoaded: true,
            updatePreview: false,
          });
        });
      })();
    }
  };

  isParentPlanNonEditable = () => {
    return true;
  };

  updatedPreview = (bool) => {
    this.setState({
      updatePreview: bool,
    });
  };

  componentDidMount() {
    const isPlanPreviewEnabled = sessionStorage.getItem('planPreview');
    //console.log('Plans - details - componentDidMount - isPlanPreviewEnabled', isPlanPreviewEnabled);
    //console.log('Plans - details - componentDidMount - this.state', this.state);
    //console.log('Plans - details - componentDidMount - this.props', this.props);

    if (isPlanPreviewEnabled) {
      this.setState({
        togglePreview: (isPlanPreviewEnabled === "true"),
        togglePinPreview: (isPlanPreviewEnabled === "true"),
        togglePreviewDisabled: (isPlanPreviewEnabled === "true"),
        togglePinPreviewIcon: (isPlanPreviewEnabled === "true") ? faLock : faLockOpen,
      });
    }

    const targetEl = document.getElementById("root");
    //console.log("clickButtonCollapseHandler - componentDidMount - targetEl ->", targetEl);
    //console.log("clickButtonCollapseHandler - componentDidMount - this.state.togglePinPreview ->", this.state.togglePinPreview);

    if (targetEl && isPlanPreviewEnabled === "true") {
      targetEl.style.setProperty("width", "calc(100% - 400px)");
    } else if (targetEl && isPlanPreviewEnabled === "false") {
      targetEl.style.removeProperty("width");
    }
  }

  /*componentDidUpdate(prevProps, prevState) {
    console.log('Plans - details - componentDidUpdate - prevProps ->', prevProps);
    console.log('Plans - details - componentDidUpdate - props ->', this.props);
    //console.log('Plans - details - componentDidUpdate - prevState ->', prevState);
    //console.log('Plans - details - componentDidUpdate - state ->', this.state);

    const { id } = this.props.match.params;

    if (prevProps.match.params.id !== id) {
        console.log('Plans - details - prevProps id did not match id', id);
    }
  }*/

  render() {
    //console.log("Plans - details - this.state ->", this.state);

    const { match, error, update, show, hide, user } = this.props ? this.props : {},
          { staticDetails, title, hasLoaded, togglePreview, togglePreviewDisabled, toggleDynamicFields, formSchema, plan, duplicatePlanFormOpen, delegatePlanFormOpen, updatePreview } = this.state,
          id = match && match.params && match.params.id ? match.params.id : '';

    //console.log("Plans - details - render - id ->", id);
    //console.log("Plans - details - render - plan ->", plan);
    //console.log("Plans - details - render - this.props ->", this.props);
    //console.log("Plans - details - render - staticDetails ->", staticDetails);
    //console.log("Plans - details - render - user ->", user);
    //console.log("Plans - details - render - id ->", id);
    //console.log("Plans - details - render - staticDetails ->", staticDetails);
    //console.log("Plans - details - render - plan ->", plan);
    //console.log("Plans - details - render - hasLoaded ->", hasLoaded);
    //console.log("Plans - details - render - this.dataLoaded ->", this.dataLoaded);

    return (
      <React.Fragment>
        <h1 className="text-bold mb-3">Plans</h1>
        <NavPlanTabs tabDataFor={this.state.controllerApi} />
        { !error ?
            <React.Fragment>
              <Row className="f2x-plan-details-container">
                <Col>
                  <div className="f2x-plan-details static">
                    <Details
                        title={title}
                        getApi={GetPlan}
                        updateApi={UpdatePlan}
                        id={id}
                        details={staticDetails}
                        dataLoaded={this.dataLoaded}
                        planPreview={true}
                        planPreviewDisabled={togglePreviewDisabled}
                        planPreviewAction={this.togglePlanPreview}
                        planTenantId={plan && plan.tenant_id ? plan.tenant_id : null}
                        duplicatePlanAction={this.toggleDuplicatePlan}
                        duplicatePlan={true}
                        delegatePlanAction={this.toggleDelegatePlan}
                        delegatePlan={true}
                        //dynamicFields={true}
                        //dynamicFieldsAction={this.togglePlanDynamicFields}
                        //dynamicFieldsVisible={toggleDynamicFields}
                        //dynamicDefaultSchema={formSchema}

                        updatePreview={this.updatedPreview}
                    />
                  </div>

                  { hasLoaded && staticDetails && 
                  <div className="mt-0">
                    <PlanLines
                      id={id}
                      planTypes={this.state.planTypeOpts}
                      planLineGroups={this.state.planLineGroupsOpts}
                      updatePreview={this.updatedPreview}
                      planTenantId={plan && plan.tenant_id ? plan.tenant_id : null}
                    />
                  </div>
                  }

                </Col>
              </Row>

              <Offcanvas show={togglePreview} placement="end" scroll={true} backdrop={false}>
                <Offcanvas.Header style={{ padding: "1rem 1.5rem", height: "69px" }}>
                  <button
                    type="button"
                    className="btn-close mr-auto"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                  </button>
                  <Button className="btn mb-1 text-truncate flex-shrink" color="gray" onClick={(e) => this.togglePlanPinPreview(e)}>
                    <FontAwesomeIcon color="grey" name="preview-lock-icon" className="align-middle mt-n1" icon={this.state.togglePinPreviewIcon} />
                  </Button>
                  <Button className="btn mb-1 ml-1 text-truncate flex-shrink" color="gray" onClick={(e) => this.updatedPreview(true)}>
                    <FontAwesomeIcon color="grey" name="preview-json-data-icon" className="align-middle" icon={faCircleNotch} />
                  </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="plan-preview">
                    <div className="plan-preview-item">
                      <PlanPreview
                          title={title}
                          description={plan && plan.description_long ? plan.description_long : ""}
                          details={staticDetails}
                          groups={this.state.groups}
                          contractPeriod={false}
                          wishDate={false}
                          termsConditions={false}
                          planOfferPrice={this.state.planOfferPrice}
                          getApi={GetPlan}
                          id={id}
                          togglePreviewCode={this.state.togglePreviewCode}
                          updatePreview={this.state.updatePreview}
                          updatedPreview={this.updatedPreview}
                          dataLoaded={this.dataLoaded}
                          tenantId={user && user.tenant_id ? user.tenant_id : null}
                          dispatch={this.props.dispatch}
                      />
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>

              {duplicatePlanFormOpen &&
              <DuplicatePlanForm
                hide={this.toggleDuplicatePlan}
                selectedData={plan ? plan : null}
              />}

              {delegatePlanFormOpen &&
              <DelegatePlanForm
                hide={this.toggleDelegatePlan}
                selectedData={plan ? plan : null}
              />}

            </React.Fragment> :
        <Unauthorized />
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({error, user, dispatch, loader}) => ({error, user, dispatch, loader});

export default connect(mapStateToProps)(withTranslation()(withRouter(PlanDetail)));
