import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    GetAPIwithCustomHeader = Api.getAPIwithCustomHeader,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI;

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        return await method ? method(endpoint, params) : await GetAPI(endpoint, params);
    })();
};

const GetRelations = (params) => {
    return asyncFunc(`relations`, params)
};

const GetRelationsSummary = (params) => {
    return asyncFunc(`relations/summary`, params)
};

const GetRelationList = (params) => {
    return asyncFunc(`relations/list`, params)
};

const GetRelationListByTenant = (tenant_id) => {
    return asyncFunc(`relations/summary?count=0&filter=tenant_id=${tenant_id}&offset=10`)
};

const GetRelationsTenant = (tenant_id) => {
    console.log("GetRelationsTenant - tenant_id ->", tenant_id);
    return asyncFunc(`relations/summary?filter=tenant_id=${tenant_id}&offset=20`, tenant_id, GetAPIwithCustomHeader)
    //return asyncFunc(`relations`, tenant_id, GetAPIwithCustomHeader)
};

const GetRelationBillingStylesList = () => {
    return asyncFunc(`relations/billing_styles`)
};

const GetRelation = (params, id) => {
    return asyncFunc(`relations/${id}`, params)
};

const GetRelationsDependencies = () => {
    return asyncFunc(`dependencies/relations`)
};

const AddRelation = (params) => {
    return asyncFunc(`relations`, params, PostAPI)
};

const UpdateRelation = (id, params) => {
    return asyncFunc(`relations/${id}`, params, UpdateAPI)
};

const AddRelationCS = (params) => {
    return asyncFunc(`relations/createcs`, params, PostAPI)
};

const GetRelPersons = (params, id) => {
    return asyncFunc(`relations/${id}/persons`, params)
};

const GetOptionPersons = (params, id) => {
    return asyncFunc(`relations/${id}/persons`, params)
};

const GetPersonDependencies = () => {
    return asyncFunc(`dependencies/persons`)
};

const AddPerson = (params) => {
    return asyncFunc(`persons`, params, PostAPI)
};

const UpdatePerson = (id, params, relation_id) => {
    return asyncFunc(`relations/${relation_id}/persons/${id}`, params, UpdateAPI)
};

const AddLanguage = (params) => {
    return asyncFunc(`languages`, params, PostAPI)
};

const UpdateLanguage = (id, params) => {
    return asyncFunc(`languages/${id}`, params, UpdateAPI)
};

const GetOptionLanguage = (id) => {
    return asyncFunc(`languages/${id}`)
};

const GetOptionLanguages = (params) => {
    return asyncFunc(`languages`, params)
};

const PersonCheckEmail = (params) => {
    return asyncFunc(`persons/checkemail`, params)
};

const LinkPerson = (params) => {
    return asyncFunc(`persons/linkperson`, params, PostAPI)
};

const GetAddress = (params, id) => {
    return asyncFunc(`relations/${id}/addresses`, params)
};

const GetRelAddress = (params, id) => {
    return asyncFunc(`relations/${id}/addresses/1`, params)
};

const GetOptionAddress = (params, id) => {
    return asyncFunc(`relations/${id}/addresses`, params)
};

const GetRelationTypes = () => {
    return asyncFunc(`relation_types`)
};

const GetAddressDependencies = () => {
    return asyncFunc(`dependencies/address`)
};

const AddAddress = (id, params) => {
    return asyncFunc(`addresses/${id}`, params, PostAPI)
};

const UpdateAddress = (id, params) => {
    return asyncFunc(`addresses/${id}`, params, UpdateAPI)
};

const GetCountries = () => {
    return asyncFunc(`countries/list`)
};

const GetCities = (id) => {
    return asyncFunc(`state/${id}/cities`)
};

const GetLocationById = (id) => {
    return asyncFunc(`locations/${id}`)
};

const GetLocationByParams = (zip_code, house_number, house_number_letter, house_number_suffix, room) => {
    let str = `zipcode=${zip_code}&house_number=${house_number}`;
    if (house_number_letter && house_number_letter !== "") {
        str += `&house_number_letter=${house_number_letter}`;
    }
    if (house_number_suffix && house_number_suffix !== "") {
        str += `&house_number_suffix=${house_number_suffix}`;
    }
    if (room && room !== "") {
        str += `&room=${room}`;
    }
    console.log("postcode_check - str ->", str);
    return asyncFunc(`postcode_check?${str}`)
};

const GetCountryCities = (id) => {
    return asyncFunc(`countries/${id}/cities`)
};

const GetSubscriptions = (params, id) => {
    return asyncFunc(`relations/${id}/subscriptions`, params)
};

const GetInvoices = (params, id) => {
    return asyncFunc(`relations/${id}/invoices`, params)
};

const GetBankAccounts = (params, id) => {
    return asyncFunc(`relations/${id}/bank-accounts`, params)
};

const AddBankAccount = (id, params) => {
    return asyncFunc(`relations/${id}/bank-accounts`, params, PostAPI)
};

const UpdateBankAccount = (id, id2, params) => {
    return asyncFunc(`relations/${id}/bank-accounts/${id2}`, params, UpdateAPI)
};

const NextMndtId = (id, params) => {
    return asyncFunc(`relations/${id}/bank-accounts/next-mndt-id`, params)
};

const GetRelationNotes = (id, params) => {
    return asyncFunc(`notes/${id}/relations`, params)
};

const AddRelationNotes = (id, params) => {
    return asyncFunc(`notes/${id}/relations`, params, PostAPI)
};

const UpdateRelationNotes = (id, params) => {
    console.log("Relation controller - UpdateRelationNotes - id ->", id);
    console.log("Relation controller - UpdateRelationNotes - params ->", params);

    return asyncFunc(`notes/${id}`, params, UpdateAPI)
};

const GetRelEmails = (id, params) => {
    return asyncFunc(`emails?count=1&offset=${params.offset}&page=1&filter=relation_id=${id}`, params)
};

const GetRelEmail = (id) => {
    return asyncFunc(`emails/${id}`)
};

// To Do: set the correct endpoint
const GetPayments = (id, params) => {
    return asyncFunc(`payments/${id}`, params)
};

export {
    // Relations
    GetRelations,
    GetRelationListByTenant,
    GetRelationsTenant,
    GetRelationsSummary,
    GetRelationList,
    GetRelation,
    GetRelationsDependencies,
    AddRelation,
    UpdateRelation,
    AddRelationCS,
    GetRelationBillingStylesList,
    GetRelationTypes,

    // Persons
    GetPersonDependencies,
    AddPerson,
    UpdatePerson,
    GetRelPersons,
    GetOptionPersons,
    PersonCheckEmail,
    LinkPerson,

    // Addresses
    GetAddress,
    GetRelAddress,
    GetAddressDependencies,
    AddAddress,
    UpdateAddress,
    GetCountries,
    GetCities,
    GetLocationByParams,
    GetLocationById,
    GetCountryCities,
    GetOptionAddress,

    // Emails
    GetRelEmails,
    GetRelEmail,

    // Languages
    AddLanguage,
    UpdateLanguage,
    GetOptionLanguage,
    GetOptionLanguages,

    // Subscriptions
    GetSubscriptions,

    // Invoices
    GetInvoices,

    // Bank Accounts
    AddBankAccount,
    GetBankAccounts,
    UpdateBankAccount,
    NextMndtId,

    // Payments
    GetPayments,

    // Notes
    GetRelationNotes,
    AddRelationNotes,
    UpdateRelationNotes
}