import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Form,
  Input, 
  CustomInput
} from 'reactstrap';
import {
  AddPlan, GetDependenciesPlans, GetPlanLineGroups, GetPlanOffer, GetPlans,
} from '../../controllers/plans';
import { loaderToggle } from "../../redux/actions/loaderActions";
import { validateDate } from "../../redux/actions/validateActions";
import Toast from "../../components/Toast";
import {withTranslation} from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';
import ReactQuill from 'react-quill';
import {
  GetContractPeriodList, GetOperators, GetPlanSubscriptionLineTypes,
} from '../../controllers/subscriptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class PlanForm extends React.Component {
  constructor(props) {
    super(props);

    const fetchingData = false;

    this.planStartRef = React.createRef();
    this.planEndRef = React.createRef();

    const user = props.user;
    const tenant_id = user && user.tenant_id ? user.tenant_id : sessionStorage.getItem('tenant') ? JSON.parse(sessionStorage.getItem('tenant')).id : null;

    this.state = {
      formType: 'Add',
      formName: 'plan',
      plan: {
        tenant_id: tenant_id
      }
    }
  }

  getLocale = (locale) => {
    const dateLocalesPath = {
      'nl': 'nl',
      'en': 'en-GB',
      'us': 'en-US'
    };

    //require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
  };

  handleCheckChange({ target: { name, checked } }) {
    let plan = Object.assign({}, this.state.plan);

    plan[name] =  checked;

    this.setState({ plan })
  }

  handleInputChange({ target: { name, value } }) {
    let plan = Object.assign({}, this.state.plan);

    plan[name] = value;

    this.setState({ plan })
  }

  handleSelectChange(name, data) {
    let plan = Object.assign({}, this.state.plan);

    plan[name] = data.value;

    this.setState({
      plan,
      [name]: data
    })
  }

  handleQuillChange(val) {
    console.log('handleQuillChange - val ->', val);

    let plan = Object.assign({}, this.state.plan);

    plan.description_long = val;
    
    this.setState({ plan })
  }

  handleDatePickerChange(name, date) {
    let plan = Object.assign({}, this.state.plan);
    const { newDate } = this.props.dispatch(validateDate(date));

    plan[name] = newDate;
    
    this.setState({ plan })
  }

  handleKeyDown = ({ which }, name) => {
      if (which === 9) {
          this.ref[name].setOpen(false)
      }
  };

  handleSubmit(e) {
    e.preventDefault();

    const { loader, dispatch, history, user } = this.props,
          { plan } = this.state,
          { description, plan_start, plan_stop, description_long, parent_plan, plan_type, default_json_data } = plan;

    const tenant_id = user && user.tenant_id ? user.tenant_id : sessionStorage.getItem('tenant') ? JSON.parse(sessionStorage.getItem('tenant')).id : null;
    console.log("Plan form - handleSubmit - tenant_id ->", tenant_id);

    //Validations
    if (!description) {
      return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
    }

    //create save data
    let planData = {};
    planData.tenant_id = tenant_id; //JSON.parse(sessionStorage.getItem('tenant')).id;
    planData.description = description;
    planData.plan_start = plan_start ? parseDateToSaveFormat(plan_start): null;
    planData.plan_stop = plan_stop ? parseDateToSaveFormat(plan_stop) : null;
    planData.description_long = description_long ? description_long : null;
    planData.parent_plan = parent_plan ? parent_plan : null;
    planData.plan_type = plan_type ? plan_type : null;

    if (default_json_data) {
      planData.wish_date = '{"minimum_days_delayed": 14, "suggested_selected_date": 14}';
      planData.contract_period = '[{"id": 1, "type": 1, "period": "1 year", "net_days": 365}, {"id": 2, "type": 1, "period": "2 years", "net_days": 730}, {"id": 3, "type": 1, "period": "3 years", "net_days": 1096}, {"id": 4, "type": 1, "period": "4 years", "net_days": 1460}, {"id": 5, "type": 1, "period": "5 years", "net_days": 1825}]';
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      (async () => {
        const { ok, data } = await AddPlan(planData);

        dispatch(loaderToggle(false));
        
        if (ok) {
          history.push({ pathname: `/plans/${ data.data.id }/details` })
        }
      })();
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;

    if (!this.fetchingData) {
      this.fetchingData = true;

      dispatch(loaderToggle(true));

      (async () => {
        await Promise.all([
          GetPlans(), GetPlanSubscriptionLineTypes(),
        ]).then((r) => {
          const [plans, planLineTypes] = r;

          let planOpts = [];
          if (plans && plans.data) {
            if (plans.data && plans.data.data) {
              plans.data.data.map(({description, id, plan_lines}) => {
                return planOpts.push({
                  label: description, value: id,
                });
              });

              this.setState({
                planOpts,
              })
            }
          }

          let planTypeOpts = [];
          if (planLineTypes && planLineTypes.data && planLineTypes.data.data) {
            planLineTypes.data.data.map(({id, line_type, description}) => {
              if (line_type === "MRC" || line_type === "QRC" || line_type ===
                  "NRC" || line_type === "YRC") {
                return planTypeOpts.push({
                  label: line_type, value: id,
                });
              }
            });

            this.setState({
              planTypeOpts,
            });
          }

          this.setState({
            hasLoaded: true
          });
          this.fetchingData = false;

        });
      })();

      dispatch(loaderToggle(false));

    }
  }

  render() {
    const { show, hide, loader, t } = this.props,
          { formType, formName, hasLoaded, planOpts, planTypeOpts, parent_plan, plan_type, plan } = this.state,
          { plan_start, plan_stop, description, description_long, default_json_data } = plan;

    console.log("plan - parent_plan ->", parent_plan);

    return (
      <React.Fragment>
        { (!loader || hasLoaded) && <Modal isOpen={ show } toggle={ hide } centered className="modal-xl">
          <Form onSubmit={ (e) => this.handleSubmit(e) }>
            <ModalHeader>{ formType } { formName }</ModalHeader>
            <ModalBody className="mt-3 mb-3">
              <Row className="form-row">
                <Col md={3} className="pb-2">
                  <span className="label">Description / Plan type:</span>
                </Col>
                <Col md={9}>
                  <Row className="form-row">
                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                      <Input
                          name="description"
                          placeholder="Description"
                          value={ description ? description : '' }
                          onChange={ (e) => this.handleInputChange(e) }
                      />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                      <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Plan type"
                          options={ planTypeOpts }
                          value={ plan_type ? plan_type : null }
                          onChange={ this.handleSelectChange.bind(this, 'plan_type') }
                          maxMenuHeight={300}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={3} className="pb-2">
                  <span className="label">Plan start / Plan end:</span>
                </Col>
                <Col md={9}>
                  <Row className="form-row">
                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                      <DatePicker
                          locale={this.getLocale()}
                          className="form-control"
                          wrapperClassName="w-100"
                          name="plan_start"
                          dateFormat={t("date_format_raw")}
                          autoComplete="off"
                          placeholderText="Plan start"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown={true}
                          selected={ plan_start ? new Date(plan_start) : null }
                          onChange={ this.handleDatePickerChange.bind(this, 'plan_start') }
                          ref={this.planStartRef}
                          onKeyDown={(e) => this.handleKeyDown(e, 'plan_start')}
                          maxDate={ plan_stop ? new Date(plan_stop) : null }
                      />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="pb-2">
                      <DatePicker
                          locale={this.getLocale()}
                          className="form-control"
                          wrapperClassName="w-100"
                          name="plan_stop"
                          dateFormat={t("date_format_raw")}
                          autoComplete="off"
                          placeholderText="Plan end"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown={true}
                          selected={ plan_stop ? new Date(plan_stop) : null }
                          onChange={ this.handleDatePickerChange.bind(this, 'plan_stop') }
                          ref={this.planEndRef}
                          onKeyDown={(e) => this.handleKeyDown(e, 'plan_stop')}
                          minDate={ plan_start ? new Date(plan_start) : null }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={3} className="pb-3">
                  <span className="label">Description long:</span>
                </Col>
                <Col md={9}>
                  <ReactQuill
                      name="description_long"
                      placeholder="Long description"
                      value={ description_long ? description_long : '' }
                      onChange={(e) => this.handleQuillChange(e)}
                  />
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={{ size: 'auto', offset: 3 }}>
                  <CustomInput
                      id="default_json_data"
                      type="checkbox"
                      label="Set default JSON data (Wish date & contract period)"
                      className="mt-2"
                      name="default_json_data"
                      defaultChecked={false}
                      onChange={ (e) => { this.handleCheckChange(e) } }
                  />
                </Col>
              </Row>

            </ModalBody>
            <ModalFooter className="justify-content-between">
              <span className="btn btn-danger" onClick={ hide }>
                <FontAwesomeIcon icon={faTimes} />
              </span>
              <Button color="primary">
                <FontAwesomeIcon icon={faSave} />
              </Button>
            </ModalFooter>
          </Form>
        </Modal> }
      </React.Fragment>
    )
  }
}

const mapStateToProps = store => ({
  loader: store.loader,
  user: store.user
});

export default connect(mapStateToProps)(withTranslation()(withRouter(PlanForm)));
