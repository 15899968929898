import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Button, Modal, ModalBody, Row } from "reactstrap";
import { ResendPassword } from "../../controllers/users";
import { loaderToggle } from "../../redux/actions/loaderActions";
import Toast from "../../components/Toast";
import Swal from "sweetalert2";
import {DeletePerson} from '../../controllers/persons';

axios.defaults.baseURL = process.env.REACT_APP_API;

class ResendEmailForm extends React.Component {
    componentDidMount() {
        const { dispatch, loader, hide, id } = this.props;

        Swal.fire({
            title: 'Please confirm!',
            text: 'Resend new password?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            reverseButtons: true
        }).then(res => {
            if (res.value) {
                if (!loader) {
                    dispatch(loaderToggle(true));

                    let resendData = Object.assign({});
                    resendData.relation_id = parseInt(id);
                    resendData.user_id = parseInt(this.props.selectedData.user.id);

                    (async() => {
                        const { ok } = await ResendPassword(resendData);
                        if (ok) {
                            dispatch(loaderToggle(false));
                            hide();
                            await Toast.fire({ title: 'New email sent!', icon: 'success' });
                        }
                    })();
                }
            } else {
                hide();
            }
            dispatch(loaderToggle(false));

        })
    }

    render() {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(ResendEmailForm);
