import React from "react";
import gif from "../../../../assets/images/maintenance.gif";
import io from 'socket.io-client';

class Maintenance extends React.Component {
  componentDidMount() {
    const socket = io(process.env.REACT_APP_CHAT_API)
    socket.on("maintenance", (data) => {
      if ('up' === data.type) {
        window.location.href = '/auth/sign-in'
      }
    })
  }
  render() {
    return (
      <div className="text-center holder-maintenance">
        <h1 className="header-maintenance">Maintenance in progress</h1>
        <img src={gif} className="img-maintenance" alt="Maintenance..." />
      </div>
    )
  }
}

export default Maintenance;
