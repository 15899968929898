import * as types from "../constants";

export function showSearchResults() {
  return {
    type: types.SOLR_MENU_VISIBILITY_SHOW
  };
}

export function hideSearchResults() {
  return {
    type: types.SOLR_MENU_VISIBILITY_HIDE
  };
}

export function toggleSearchResults() {
  return {
    type: types.SOLR_MENU_VISIBILITY_TOGGLE
  };
}

export function searchResultsUpdate() {
  return {
    type: types.SOLR_RESULT_UPDATE
  };
}
