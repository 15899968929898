import React, { useState, useEffect } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import TabHeader from './TabHeader';
import {
  useHistory,
  useParams,
  useRouteMatch,
  withRouter
} from 'react-router-dom';
import { simulateTabClick } from "../hooks/useSimulateTabClick";
import CONSTANTS from '../services/constants';
import {subscription} from '../redux/actions/subscriptionActions'
import {invoice} from '../redux/actions/invoiceActions'
import Page404 from '../pages/auth/Page404';
import {connect, useDispatch} from 'react-redux';
import {searchResultsUpdate} from '../redux/actions/solrSearchActions';

const Tabs = ({customHeader, activeTab, setActiveTab, tabs, path, relation, ...props}) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState( null);
  const [refreshTab, setRefreshTab] = useState( false);
  const [tabsObj, setTabsObj] = useState([]);
  const customNavItem = props.customNavItem && props.customNavItem.props && props.customNavItem.props.children ? props.customNavItem.props.children : '';
  const history = useHistory();
  const [activatedTab, setActivatedTab] = useState(activeTab);
  const tabsContents = CONSTANTS.MV_TAB_CONTENTS;
  const params = useParams();
  const {solrSearch, dispatch} = props;

  let relationId = relation && relation.id ? relation.id : null;
  let {url} = useRouteMatch();

  // use useEffect to access lifecycle methods, as componentDidMount etc. are not available on function components.
  useEffect(() => {
    return history.listen(location => {
      const locationPathName = location.pathname;

      if (history.action === 'PUSH') {
        if (location.pathname) {
          setLocationKeys([location.pathname]);
        }
      }

      let prevPath;
      const locationArr = location.pathname.split('/');

      if (history.action === 'POP') {
        if (currentActiveTab === activeTab) {
          setLocationKeys(([ _, ...pathnames ]) => pathnames);

          if (locationArr && locationArr[3] && locationArr[3] === "subscriptions" && !locationArr[4]) {
            setActivatedTab(null);
            setActivatedTab(4);
          }

          if (locationArr && locationArr[3] && locationArr[3] === "invoices" && !locationArr[4]) {
            setActivatedTab(null);
            setActivatedTab(5);
          }

        } else {
          setLocationKeys((pathnames) => [ location.pathname, ...pathnames ]);
          if (locationArr && locationArr[3] && (locationArr[3] === "details" || locationArr[3] === "addresses" || locationArr[3] === "persons" || locationArr[3] === "bank-accounts" || locationArr[3] === "emails")) {

            let newActiveTab;
            switch (locationArr[3]) {
              case "details":
                newActiveTab = 0;
                break;
              case "addresses":
                newActiveTab = 1;
                break;
              case "persons":
                newActiveTab = 2;
                break;
              case "bank-accounts":
                newActiveTab = 3;
                break;
              case "subscriptions":
                newActiveTab = 4;
                break;
              case "invoices":
                newActiveTab = 5;
                break;
              case "emails":
                newActiveTab = 6;
                break;
            }
            setActivatedTab(newActiveTab);

          } else if (locationArr && locationArr[3] && (locationArr[3] === "invoices" || locationArr[3] === "subscriptions")) {
            console.log("Tabs - locationKeys updated - history.action(POP -> backward) - history ->", history);
            console.log("Tabs - locationKeys updated - history.action(POP -> backward) - location.pathname ->", location.pathname);
          }
        }
      }
    })
  }, [locationKeys]);

  useEffect(() => {
    //console.log("Tabs - (MVDetails) - useEffect - activeTab ->", activeTab);
    //console.log("Tabs - (MVDetails) - useEffect - activatedTab ->", activatedTab); //setActivatedTab(tab);
    //console.log("Tabs - (MVDetails) - useEffect - tab ->", tab); //setActivatedTab(tab);

    setActivatedTab(activeTab);

  }, [activeTab]);

  const CustomHeader = () => {
    return customHeader(setActivatedTab, activatedTab);
  };

  const toggle = (e, tab) => {
    let tabPath;

    //console.log("Tabs - (MVDetails) - toggle - tab ->", tab);

    if (activatedTab !== tab) {
      setCurrentActiveTab(tab);

      const tabObj = CONSTANTS.RELATION_TABS.find(r => r.tab === tab);

      let pathFields = url.split('/');

      const { subscription, invoice } = props;

      if (tabObj && tabObj.value && (tabObj.value === 'details' || tabObj.value === 'addresses' || tabObj.value === 'persons' || tabObj.value === 'bank-accounts' || tabObj.value === 'emails')) {
        tabPath = `/${pathFields[1]}/${relationId}/${tabObj.value}`;
        history.push(tabPath);

      } else if (tabObj && tabObj.value && tabObj.value === 'invoices') {
        const {invoiceList} = invoice;

        let invoiceId = null;

        // check relation id against list - or load last invoice
        if (sessionStorage.getItem('lastVisitInvId') && invoiceList && invoiceList.length > 0 && invoiceList.some((item) => item.id === parseInt(sessionStorage.getItem('lastVisitInvId')) && item.relation_id === parseInt(params.relationId))) {
          invoiceId = parseInt(sessionStorage.getItem('lastVisitInvId'));
        } else {
          invoiceId = invoiceList && invoiceList.length > 0 && invoiceList.some((item) => item.relation_id === parseInt(params.relationId)) ? invoiceList[0].id : null;
          if (invoiceId) {
            sessionStorage.setItem('lastVisitInvId', invoiceId);
          } else {
            sessionStorage.removeItem('lastVisitInvId');
          }
        }
        tabPath = invoiceId ? `/relations/${relationId}/invoices/${invoiceId}` : `/relations/${relationId}/invoices`;
        history.push(tabPath);

      } else if (tabObj && tabObj.value && tabObj.value === 'subscriptions') {
        const {subscriptionList} = subscription;

        let subscrId = null;

        // check relation id against list - or load last subscr
        if (sessionStorage.getItem('lastVisitSubscrId') && subscriptionList && subscriptionList.length > 0 && subscriptionList.some((item) => item.id === parseInt(sessionStorage.getItem('lastVisitSubscrId')) && item.relation_id === parseInt(params.relationId))) {
          subscrId = parseInt(sessionStorage.getItem('lastVisitSubscrId'));
        } else {
          subscrId = subscriptionList && subscriptionList.length > 0 && subscriptionList.some((item) => item.relation_id === parseInt(params.relationId)) ? subscriptionList[0].id : null;

          if (subscrId) {
            sessionStorage.setItem('lastVisitSubscrId', subscrId);
          } else {
            sessionStorage.removeItem('lastVisitSubscrId');
          }
        }
        tabPath = subscrId ? `/relations/${relationId}/subscriptions/${subscrId}` : `/relations/${relationId}/subscriptions`;
        history.push(tabPath);

      } else {
        return <Page404 />
      }
    }

    setActiveTab(tab)
    setActivatedTab(tab);
  };

  //solrSearch
  useEffect(() => {
    if (solrSearch.solrSearchResultUpdate === true || refreshTab === true) {
      let sessionsRelationId = parseInt(sessionStorage.getItem('solrRelationId'));
      let lastVisitInvId = parseInt(sessionStorage.getItem('lastVisitInvId'));
      let lastVisitSubscrId = parseInt(sessionStorage.getItem('lastVisitSubscrId'));

      const { subscription, invoice, relation } = props;

      setActivatedTab(null);

      if (params && params.subscrId) {
        dispatch({type: 'SUBSCRIPTION', payload: null});
        dispatch({type: 'RELATION', payload: null});
        setActivatedTab(4);

      } else if (params && params.invoiceId) {
        dispatch({type: 'INVOICE', payload: null});
        dispatch({type: 'RELATION', payload: null});
        setActivatedTab(5);

      } else {
        setActivatedTab(0);
      }

      dispatch(searchResultsUpdate(false));

    } //else {
      //console.log("Tabs - reload URL for solrSearch -> false");
    //}
  }, [solrSearch.solrSearchResultUpdate, refreshTab]);

  return (
      <>
        { customHeader ?
            <CustomHeader className={`custom-tab-header`} title={`tab header mv`} />
            :
            <TabHeader className={`f2x-mv-tabs-list mb-4`} tabs={tabs} toggleTab={toggle} setActiveTab={setActivatedTab} activatedTab={activatedTab} customNavItem={customNavItem} />
        }
        <TabContent activeTab={activatedTab}>
          { tabs.map(({component}, i) => {
                return (
                    <TabPane key={i} tabId={i}>{ activatedTab === i && component }</TabPane>
                )
              }
          )}
        </TabContent>
      </>
  )
};

const mapStateToProps = ({solrSearch, relation, subscription, invoice}) => ({solrSearch, relation, subscription, invoice});

export default withRouter(connect(mapStateToProps)(Tabs));
