import React from "react";
import {connect} from "react-redux";
import {AddPlan, GetPlanLines, DuplicatePlan} from '../../controllers/plans';
import {withRouter} from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import {loaderToggle} from '../../redux/actions/loaderActions';
import {validateDate} from "../../redux/actions/validateActions";
import CONSTANTS from '../../services/constants';
import Select from "react-select";
import DuplicatePlanOptions from "./DuplicatePlanOptions";
import DatePicker, {registerLocale} from "react-datepicker";
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';
import {withTranslation} from 'react-i18next';
import {nl, enGB, enUS} from "date-fns/locale";

const CustomSwal = withReactContent(Swal);

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class DuplicatePlanForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: null
    }
  }

  duplicateOptions = (values) => {
    console.log("DuplicatePlanForm - DuplicatePlan - duplicateOptions - values ->", values);
    if (values) {
      this.setState({
        options: values
      });
    }
  };

  componentDidMount() {
    const {loader, selectedData, dispatch, update, hide, history, togglePlanLinesAfterDuplicate} = this.props;
    //const {start_date} = this.state;
    console.log("DuplicatePlanForm - DuplicatePlan - componentDidMount - selectedData ->", selectedData);
    console.log("DuplicatePlanForm - DuplicatePlan - componentDidMount - hide ->", hide);
    //console.log("DuplicatePlanForm - DuplicatePlan - componentDidMount - start_date ->", start_date);

    CustomSwal.fire({
      customClass: { container: 'has-cancel', },
      title: 'Duplicate plan?',
      text: 'Are you sure you want to duplicate this plan?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      html: <DuplicatePlanOptions dispatch={dispatch} duplicateOptions={this.duplicateOptions} />

    }).then(res => {
      console.log("DuplicatePlanForm - DuplicatePlan - res ->", res);
      if (res.value) {
        if (!loader) {
          (async () => {
            dispatch(loaderToggle(true));

            console.log("DuplicatePlanForm - DuplicatePlan - selectedData ->", selectedData);
            let params = {};

            const {options} = this.state;
            console.log("DuplicatePlanForm - DuplicatePlan - selectedData (extra) options ->", options);
            if (options && options.plan_start) {
              params.plan_start = parseDateToSaveFormat(options.plan_start);
            }

            if (selectedData && selectedData.id) {

              //let ok = false;
              //let data;
              
              console.log("DuplicatePlanForm - DuplicatePlan - selectedData (POST) params ->", params);
              const {ok, data} = await DuplicatePlan(selectedData.id, params);

              console.log("DuplicatePlanForm - DuplicatePlan - ok ->", ok);
              console.log("DuplicatePlanForm - DuplicatePlan - data ->", data);

              if (ok) {
                history.push({ pathname: `/plans/${ data.data.id }/details` });
                if (togglePlanLinesAfterDuplicate) {
                  this.props.togglePlanLinesAfterDuplicate();
                }
              }

              dispatch(loaderToggle(false));

            } else {
              dispatch(loaderToggle(false));
              await Swal.fire('Success!', 'Copy failed', 'error');
              hide();

            }
          })();
        }
      } else {
        dispatch(loaderToggle(false));
        hide();

      }
    })
  }

  render() {
    return (
        <React.Fragment>
        </React.Fragment>
    )
  }
}

const mapStateToProps = store => ({
  loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(DuplicatePlanForm)));
