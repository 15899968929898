import React, {useEffect, useState} from 'react';
import {GetSubscriptionLineStatus} from '../../../../controllers/subscriptions';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ModalWrapper from '../../../../components/ModalWrapper';
import ToastMulti from "../../../../components/ToastMulti";

const Statuses = ({...props}) => {
  let notificationsToastArr = [];
  const line_id = props.id;
  const table = props.table; // => required
 
  const [urgentMessageStyle, setUrgentMessageStyle] = useState(1); // 1 => Modal, 2 => Toast, 3 => Alert | setUrgentMessageStyle is not used - default => 1. Modal
  const [statusList, setStatusList] = useState([]);
  const [noList, setNoList] = useState(false);
  const [isLoadingStatusItems, setIsLoadingStatusItems] = useState(false);
  const [isLoadingStatusModalUrgent, setIsLoadingStatusModalUrgent] = useState(false);
  const [isOpenUrgentSubLineStatus, setIsOpenUrgentSubLineStatus] = useState(false);

  const toggleModalUrgentSubLineStatus = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoadingStatusModalUrgent(true);
    
    setTimeout(() => {
      setIsOpenUrgentSubLineStatus(!isOpenUrgentSubLineStatus);
      setIsLoadingStatusModalUrgent(false);

    }, 500);
  }

  useEffect(() => {
    // check for other urgent modals
    // count total modals with class modal-subscription-line-urgent-status
    let urgentModal = document.getElementsByClassName("modal-subscription-line-urgent-status");
    if (urgentModal) {
      console.log("toggleModalUrgentSubLineStatus - urgentModal.length ->", urgentModal.length);
      const {urgentModalStatus} = props;
      if (urgentModal.length <= 1) {
        let tableSubscriptionLines = document.getElementById(table);
        tableSubscriptionLines.style.pointerEvents = "";
        urgentModalStatus(false);
      }
    }
  }, [isLoadingStatusModalUrgent]);

  useEffect(() => {
    const {id, row} = props;

    (async () => {
      setIsLoadingStatusItems(true);

      const [subscriptionLineStatus] = await Promise.all([GetSubscriptionLineStatus(id)]);

      if (subscriptionLineStatus.ok) {
        const {data} = subscriptionLineStatus.data;
        if (data) {
          setStatusList(data);
        } else {
          setNoList(true);
        }
      }
      setIsLoadingStatusItems(false);

    })();
  }, []);

  useEffect(() => {
    const {urgentModalStatus} = props;
    
    // urgentMessageStyle 
    // to check set -> if (statusList && statusList.urgent === false)
    // default => if (statusList && statusList.urgent && statusList.urgent === true)

    if (urgentMessageStyle === 2) {
      if (statusList.urgent && statusList.urgent === true) {
        notificationsToastArr.push({
          title: `${statusList.icon} #${line_id} - ${statusList.message}`,
          icon: "none"
        });
      }
      // return messages to toast
      checkUrgentMessages(notificationsToastArr);
    } else {
      // return modal popup
      if (statusList.urgent && statusList.urgent === true) {
        urgentModalStatus(true);
        setIsOpenUrgentSubLineStatus(true);
      }
    }

  }, [statusList]);

  const checkUrgentMessages = (notificationsToastArr) => {
    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }
  }

  return (
    <>
      <div style={{textAlign:"center"}}>
        {isLoadingStatusItems && 
        <div className="loading dots"> </div>}
        {statusList && !isLoadingStatusItems &&
        <OverlayTrigger
          placement={`right`}
          transition={false}
          delay={200}
          overlay={
            <Tooltip
                className="tooltip"
                container="body"
                id={`tooltip-bottom-subscription-line-status-${line_id}`}
            >
              {statusList.iconHover}
            </Tooltip>}
        >
          <span id={`line-status-${line_id}`} data-desc={statusList.message} className={`subscription-line-statuses`}>{statusList.icon}</span>
        </OverlayTrigger>}
      </div>

      <ModalWrapper 
        title={false} 
        className="modal-sm modal-alert-dismiss modal-subscription-line-urgent-status" 
        show={isOpenUrgentSubLineStatus} 
        hide={(e) => toggleModalUrgentSubLineStatus(e)}
        backdrop={`static`}
        name={line_id}
        id={`sub-line-id-status-${line_id}`}
        container={`body`}
        disabled={isLoadingStatusModalUrgent}
      >
        <div><b>{`Subscriptionline #${line_id}`}</b></div>
        <div>{statusList.icon} <i>{`${statusList.message}`}</i></div>
      </ModalWrapper>
    </>
  )
};

export default Statuses;
