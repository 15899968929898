import ApiService from '../services/apiService';

const Api = new ApiService(),
  GetAPI = Api.getAPI,
  PostAPI = Api.postAPI,
  UpdateAPI = Api.updateAPI;

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    let data = await method ? method(endpoint, params) : GetAPI(endpoint, params);
    return data;
  })()
};

const GetUsers = (params) => {
  return asyncFunc(`users`, params)
};

const GetUsersApiCompatible = (params) => {
  return asyncFunc(`users/api_compatible`, params)
};

const GetAdminUsers = (params) => {
  return asyncFunc(`admin/users`, params)
};

const GetUser = (id) => {
  return asyncFunc(`users/${ id }`)
};

const AddUser = (params) => {
  return asyncFunc(`users`, params, PostAPI)
};

const UpdateUser = (params, id) => {
  return asyncFunc(`users/${id}`, params, UpdateAPI)
};

const DeleteUser = (id) => {
  return asyncFunc(`users/${ id }`, {_method: 'DELETE'}, PostAPI)
};

const ResendPassword = (params) => {
  return asyncFunc(`user/resend-code`, params, PostAPI)
};

export {
  GetUsers,
  GetUsersApiCompatible,
  GetAdminUsers,
  GetUser,
  AddUser,
  UpdateUser,
  DeleteUser,
  ResendPassword
}
