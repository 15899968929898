import React from 'react';
import {GetPlanLines} from '../../../controllers/plans';
import PlanLineForm from './Form';
import RemovePlanLineForm from './FormRemove';
import ParentTable from '../../../components/ParentTable';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Check} from 'react-feather';
//import SubLineForm from '../FormSubLine';
import AddSubPlanLineForm from './sub_plan_line/AddSubForm';
import {formatAmountLocale} from '../../../redux/actions/formatActions';
import {loaderToggle} from '../../../redux/actions/loaderActions';
import {GetRelationNotes} from '../../../controllers/relations';
import DataTable from '../../../components/DataTable';
import {GetBrand, SwitchTenant} from '../../../controllers/tenants';
import {brand} from '../../../redux/actions/brandActions';
import {clearSessionStorage} from '../../../services/misc';
import {updateTenant, updateUser} from '../../../redux/actions/userActions';
import {createSelector} from 'reselect';
import Prices from './Prices';

class PlanLines extends React.Component {
  constructor(props) {
    super(props);

    console.log("PlanLines - props ->", props);

    this.state = {
      parentRow: null,
      parentIds: {},
      childrenIds: {},
      page: 1,
      sizePerPage: 1000,
      data: [],
      language: props.language ? props.language : 'nl',

      columns: [{
        dataField: "id",
        hidden: true,
        headerStyle: () => {
          return { width: '5%' };
        },
      }, {
        dataField: "json_data",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      }, {
        dataField: "product_id",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      },{
        dataField: "child_of",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      }, {
        dataField: "description",
        text: "Description",
        formatter: this.nameFormatter,
        sort: false,
        headerStyle: () => {
          return { width: '33%' };
        },
        attrs: {
          'data-label': 'Description'
        }
      }, {
        dataField: "plan_line_group_description",
        text: "Plan line group",
        hidden: false,
        formatter: this.formatPlanLineGroup,
        headerStyle: () => {
          return { width: '12%' };
        },
        attrs: {
          'data-label': 'Plan line group'
        }
      }, {
        dataField: "type",
        text: "Type",
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'Type'
        }
      }, {
        dataField: "start",
        text: "Start",
        formatter: this.formatDate,
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'Start'
        }
      }, {
        dataField: "end",
        text: "End",
        formatter: this.formatDate,
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'End'
        }
      }, {
        dataField: "line_price",
        text: "Price",
        sort: false,
        classes: 'text-right',
        formatter: this.formatCurrency,
        headerStyle: () => {
          return { width: '13%', 'textAlign': 'right' };
        },
        attrs: this.colspanForLastRow
        /*attrs: {
          'data-label': 'Price'
        }*/
      }/*, {
        dataField: "null2",
        text: "",
        //formatter: this.validFormatter,
        classes: 'last-cell subscription-valid-from-formatter',
        attrs: this.hiddenColForLastRow,
        headerStyle: { width: '20%' }
      }*/]
    };
  }

  formatDate = (cell) => {
    console.log("formatDate - cell ->", cell);
    if (cell) {
      return <span className="date-format">{this.props.t("date_format",
          {date: new Date(cell)})}</span>
    }
    return <span>{String.fromCharCode(8212)}</span>
  };

  defaultFormatter = (cell) => {
    return (
        <React.Fragment>
          {cell === 1 &&
          <Check size={18} color="#47bac1" className="check" />
          }
        </React.Fragment>
    )
  };

  checkIfParent = (cell, row) => {
    const { parentIds } = this.state;
    console.log("checkIfParent - cell ->", cell);
    console.log("checkIfParent - parentIds ->", parentIds);
  };

  nameFormatter = (cell, row) => {

    console.log("nameFormatter - cell ->", cell);
    console.log("nameFormatter - row ->", row);

    let indent = 0;
    const { parentIds } = this.state;

    if (parentIds && parentIds.length > 0) {
      let dataIndentLevel = parentIds.map((item) => {
        if (row.child_of === item.parentId) {
          indent = item.level;
        }
      });
    }

    return (
      <div id={row.id} data-parent-id={row.child_of} className="text-truncate" style={{textIndent: `${indent}em`}}>
        {indent && indent > 0 ? <span className="arrow-icon">{cell}</span> : cell}
      </div>
    );
  };

  formatSingleCurrency = (cell) => {
    const {t} = this.props;
    if (cell) {
      let floatCell = cell ? cell : null;
      let floatStyle = floatCell && parseFloat(floatCell) < 0
          ? 'float-negative'
          : floatCell && parseFloat(floatCell) > 0
              ? 'float-positive'
              : 'float-null';
      return <span className={floatStyle ? floatStyle : 'float-zero'}>{t(
          "currency_format", {number: Number(parseFloat(cell))})}</span>
    } else {
      return <span className={'float-zero'}>{t("currency_format",
          {number: Number(0)})}</span>
    }
  };

  priceFormatter = (cell, row) => {
    //const {id, t, selectedData} = this.props;
    //const {data} = this.state;

    console.log("PlanLinesList - priceFormatter - cell ->", cell);
    //console.log("PlanLinesList - priceFormatter - cell ->", data);
    console.log("PlanLinesList - priceFormatter - row ->", row);
    //console.log("PlanLinesList - priceFormatter - id ->", id);
    //console.log("PlanLinesList - priceFormatter - rowIndex ->", rowIndex);

    //if (data && data[0] && data[0].id !== row.id) {
    //  return <Prices rowId={data[0].id} t={t} lang={this.props.i18n.language} />
    //} else {

      return <Prices rowId={row.id} t={this.props.t} lang={this.props.i18n.language}/>
    //}
  };

  formatCurrency = (cell, row) => {
    console.log("formatCurrency - cell ->", cell);
    console.log("formatCurrency - row ->", row);

    let plan_line_price_excl_vat = 0;
    let plan_line_price_incl_vat = 0;

    if (row && row.current_line_price && (row.current_line_price.price_excl_vat || row.current_line_price.price_incl_vat)) {
    //if (price_excl_vat || price_incl_vat) {
      if (row.current_line_price.price_excl_vat) {
        plan_line_price_excl_vat = row.current_line_price.price_excl_vat;
      }

      if (row.current_line_price.price_incl_vat) {
        plan_line_price_incl_vat = row.current_line_price.price_incl_vat;
      }
    }

    return this.formatTotalVatStr(plan_line_price_excl_vat, plan_line_price_incl_vat);
  };

  formatTotalVatStr = (plan_line_price_excl_vat, plan_line_price_incl_vat) => {
    const {t} = this.props;
    //return <span>{t("currency_format", {number: Number(plan_line_price_excl_vat)}) +' / '+ formatAmountLocale(plan_line_price_incl_vat, this.props.i18n.language)}</span>
    return <span>{t("currency_format", {number: Number(plan_line_price_excl_vat)}) +' / '+ t("currency_format", {number: Number(plan_line_price_incl_vat)})}</span>
  };

  formatPlanLineGroup = (cell) => {
    if (cell) {
      return <div className="text-truncate">{cell}</div>;
    }
    return <span>{String.fromCharCode(8212)}</span>
  };

  planLineGroupsBtn = () => {
    console.log("PlanLineGroupsBtn");
  };

  formatPlanLineType = (cell) => {
    if (this.props.planTypes && this.props.planTypes.length > 0) {
      let planType = this.props.planTypes.find(item => parseInt(item.value) === parseInt(cell));
      if (planType && planType.label) {
        return planType.label;
      }
    }
    return <span>{String.fromCharCode(8212)}</span>
  };

  switchTenant = (planTenantId) => {
    if (planTenantId) {
      const {dispatch, theme} = this.props;

      console.log("PlanLines list table - switchTenant - planTenantId ->", planTenantId);
      console.log("PlanLines list table - switchTenant - dispatch ->", dispatch);

      (async () => {
        const [switchTenant, getBrand] = await Promise.all([
          SwitchTenant(planTenantId),
          GetBrand(null, planTenantId)
        ]);

        if (switchTenant.ok && getBrand.ok) {
          let brandData = Object.assign({}, getBrand.data.data);

          if (brandData && brandData.theme) {
            brandData.theme = JSON.parse(brandData.theme)
          } else {
            brandData.theme = theme.currentTheme
          }

          if (brandData.theme) {
            dispatch(brand(brandData));
          }

          const tenant = switchTenant.data.data,
              tenant_id = switchTenant.data.data.id,
              tenant_name = switchTenant.data.data.name;

          clearSessionStorage();

          sessionStorage.setItem('tenant', JSON.stringify(tenant));
          sessionStorage.setItem('default_country_id', tenant.default_country_id ? tenant.default_country_id : 155); // default NL -> 155

          let newTenant = Object.assign({}, tenant);
          delete newTenant.parent;

          dispatch(updateTenant(tenant));
          dispatch(updateUser({tenant_id}));

          this.updatePlanLineDataTable();
        }
      })();
    }
  };

  componentDidMount() {
    this.setState({parentRow: 0});

    //console.log("PlanLines list table - componentDidMount - props ->", this.props);

    const {selectedData, planTenantId, user} = this.props;
    const {tenant_id} = user && user.user ? user.user : null;

    //console.log("PlanLines list table - componentDidMount - planTenantId ->", planTenantId);
    //console.log("PlanLines list table - componentDidMount - tenant_id ->", tenant_id);

    if (tenant_id && planTenantId && planTenantId !== tenant_id) {
      //console.log("PlanLines list table - componentDidMount - planTenantId !== tenant_id ->", tenant_id);
      //console.log("PlanLines list table - componentDidMount - tenant_id !== planTenantId ->", planTenantId);

      // tenant switch to planTenantId
      this.switchTenant(planTenantId);

    } else {
      // show default plan lines
      this.updatePlanLineDataTable();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {id} = this.props;
    if (prevProps && prevProps.id && prevProps.id !== id) {
      this.updatePlanLineDataTable();
    }
  }

  toggleOlderPrices = async ({ target: { checked } }) => {
    //console.log("toggleOlderPrices - e ->", e);
    //console.log("toggleOlderPrices - checked ->", checked);

    let columns = null;

    await this.setState({columns: columns});

    if (checked) {
      columns = [
      {
        dataField: "id",
        hidden: true,
        headerStyle: () => {
          return { width: '5%' };
        },
      }, {
        dataField: "json_data",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      }, {
        dataField: "product_id",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      },{
        dataField: "child_of",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      }, {
        dataField: "description", //5
        text: "Description",
        formatter: this.nameFormatter,
        sort: false,
        headerStyle: () => {
          return { width: '33%' };
        },
        attrs: {
          'data-label': 'Description'
        }
      }, {
        dataField: "plan_line_group_description", //6
        text: "Plan line group",
        hidden: false,
        formatter: this.formatPlanLineGroup,
        headerStyle: () => {
          return { width: '12%' };
        },
        attrs: {
          'data-label': 'Plan line group'
        }
      }, {
        dataField: "type", //7
        text: "Type",
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'Type'
        }
      }, {
        dataField: "start", //8
        text: "Start",
        formatter: this.formatDate,
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'Start'
        }
      }, {
        dataField: "end", //9
        text: "End",
        formatter: this.formatDate,
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'End'
        }
      }, {
        dataField: "current_line_price", //10 //line_price
        text: "Price | Valid from",
        sort: false,
        classes: 'text-right',
        formatter: this.priceFormatter,
        headerStyle: () => {
          return { width: '13%', 'textAlign': 'right' };
        },
        attrs: {
          'data-label': 'Price',

        }
      }];

      this.setState({columns: columns});

    } else {
      columns = [
      {
        dataField: "id",
        hidden: true,
        headerStyle: () => {
          return { width: '5%' };
        },
      }, {
        dataField: "json_data",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      }, {
        dataField: "product_id",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      },{
        dataField: "child_of",
        hidden: true,
        headerStyle: () => {
          return { width: '0' };
        },
      }, {
        dataField: "description", //5
        text: "Description",
        formatter: this.nameFormatter,
        sort: false,
        headerStyle: () => {
          return { width: '33%' };
        },
        attrs: {
          'data-label': 'Description'
        }
      }, {
        dataField: "plan_line_group_description", //6
        text: "Plan line group",
        hidden: false,
        formatter: this.formatPlanLineGroup,
        headerStyle: () => {
          return { width: '12%' };
        },
        attrs: {
          'data-label': 'Plan line group'
        }
      }, {
        dataField: "type", //7
        text: "Type",
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'Type'
        }
      }, {
        dataField: "start", //8
        text: "Start",
        formatter: this.formatDate,
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'Start'
        }
      }, {
        dataField: "end", //9
        text: "End",
        formatter: this.formatDate,
        sort: false,
        headerStyle: () => {
          return { width: '10%' };
        },
        attrs: {
          'data-label': 'End'
        }
      }, {
        dataField: "current_line_price", //10
        text: "Price",
        sort: false,
        classes: 'text-right',
        formatter: this.formatCurrency,
        headerStyle: () => {
          return { width: '13%', 'textAlign': 'right' };
        },
        attrs: this.colspanForLastRow
        /*attrs: {
          'data-label': 'Price'
        }*/
      }];

      this.setState({columns: columns});
    }
  };

  updatePlanLineDataTable = () => {
    this.getData();
  };

  getData = () => {
    const { dispatch, id, disableLoader } = this.props;

    console.log("PlanLinesTable - getData - noDataFound - disableLoader ->", disableLoader);

    let {selectedData} = this.props;

    let param = {};

    if (id) {
      param = {
        page: parseInt(this.state.page),
        offset: parseInt(this.state.sizePerPage),
      };

      (async () => {
        //if (!disableLoader) dispatch(loaderToggle(true));
        dispatch(loaderToggle(true));

        await GetPlanLines(param, id).then(res => {
          if (res.ok) {
            const {data} = res;
            console.log("Plan lines list - data ->", data);

            let parentIdsArr = [];
            if (data && data.data && data.data.length > 0) {
              let indent = 0;
              for (let i = 0; i < data.data.length; i++) {
                //console.log("Plan lines list - indent ->", indent);
                data.data[i].can_add_subline = true;

                //if (i > 0 && data.data[i].child_of && data.data[i].child_of === data.data[i - 1].id) {
                if (data.data[i].child_of !== null && data.data[i].child_of === data.data[i - 1].id) {
                  indent = indent + 1;
                  parentIdsArr.push({parentId: data.data[i].child_of, level: indent});

                  if (indent < 3) {
                    data.data[i].can_add_subline = true;
                  } else {
                    data.data[i].can_add_subline = false;
                  }
                } else {
                  indent = 0;
                }
              }
            }

            console.log("plan_lines - data ->", data);

            this.setState({
              data: data.data,
              totalSize: data.total,
              parentIds: parentIdsArr,
            });
          }
        }).catch(e => {
          console.log('Error', e);
        });

        //if (!disableLoader) dispatch(loaderToggle(false));
        dispatch(loaderToggle(false));

      })();
    }
  };

  render() {
    const { id, updatePreview } = this.props;
    const { data, totalSize, sizePerPage, page, columns, parentIds } = this.state;

    //console.log("Plan_lines list - data ->", data);

    return (
      <ParentTable
        id={id}
        keyField="id"
        remote
        table="Plan lines"
        data={data}
        rawData={data}
        isRawData={true}
        tabIndexCell={true}
        include={'lineType, product'}
        columns={columns}
        selectRow={`row-subscription-line-${id}`}
        headerCheckBox={{
          label: 'Show all prices',
          function: this.toggleOlderPrices
          //disabled: true
        }}
        rowAction="edit"
        rowActionForm={PlanLineForm}
        rowDropdownOpts={[
          {
            action: 'Add',
            label: 'Add sub plan line',
            form: AddSubPlanLineForm,
            condition: {
              data: 'can_add_subline'
            },
          }, {
            action: 'Edit',
            label: 'Edit plan line',
            form: PlanLineForm,
          }, {
            action: 'Remove',
            label: 'Remove',
            form: RemovePlanLineForm,
          },
        ]}
        headerDropdownOpts={[
          {
            label: 'Add',
            action: PlanLineForm
          },
        ]}
        wrapperClasses="table-plan-lines f2x-custom-table"
        //offLoader={false}
        striped={true}
        noDataIndication="No subscriptions found."
        noHover={false}
        noSearch={true}
        cacheSearch={false}
        filters={false}
        noTopBorder={true}
        classes="table-responsive"
        sizePerPage={100}
        updatePreview={updatePreview}
        updateTableRawData={this.updatePlanLineDataTable}
      />
    )
  }
}

const mapStateToProps = (user, dispatch, loader, brand, theme) => ({user, dispatch, loader, brand, theme});

export default withRouter(withTranslation()(connect(mapStateToProps)(PlanLines)));
