import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { Collapse } from 'react-bootstrap';
import ActivityLogs from './List';
import ChartActivityLogs from './ChartActivityLogs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSignal, faSlidersH} from '@fortawesome/free-solid-svg-icons';

class LogChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          chartVisibility: true,
        }
    }

    collapseChart = (e, visible) => {
      console.log("collapseChart - e ->", e);
      console.log("collapseChart - visible ->", visible);

      this.setState({
        chartVisibility: !visible,
      });

      let isVisible = "is_hidden";
      if (this.state.chartVisibility === false) {
        isVisible = "is_visible";
      }
      // set to sessionStorage
      sessionStorage.setItem("activityLogsChartVisible", isVisible);
    };

    componentDidMount() {
      let activityLogsChartVisible = sessionStorage.getItem("activityLogsChartVisible");
      if (activityLogsChartVisible) {
        this.setState({
          chartVisibility: activityLogsChartVisible === "is_hidden" ? false : true,
        });
      }
    }

  render() {
        return (
            <div className="wrapper-box-table-filter">
                <h1 className="text-bold mb-3">
                  Activity logs
                  <button
                    className="ml-2 btn"
                    onClick={(e) => this.collapseChart(e, this.state.chartVisibility)}
                    aria-controls="activity-logs-collapse"
                    aria-expanded={this.state.chartVisibility}
                  >
                    <FontAwesomeIcon icon={faSignal} size={`sm`} className={`mr-1`} color={`grey`} />
                    {this.state.chartVisibility === false ? "Show" : "Hide"}
                  </button>
                </h1>
                <Collapse in={this.state.chartVisibility}>
                    <div id="activity-logs-collapse">
                      <Card className={`mb-4 w-100 page-activity-logs`}>
                        <CardHeader>
                            <CardTitle tag="h5">Activity logs chart</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="chart w-100">
                                <ChartActivityLogs />
                            </div>
                        </CardBody>
                      </Card>
                    </div>
                </Collapse>
                <ActivityLogs />
            </div>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(LogChart)