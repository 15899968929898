import React, {useEffect, useState} from 'react';
import {GetSubscriptionLinePrices} from '../../../../controllers/subscriptions';
import {formatAmountLocale} from '../../../../redux/actions/formatActions';
import { Modal, ModalHeader, ModalBody, Row, Col, Table } from "reactstrap";

const Prices = (props) => {
  console.log("Prices - props ->", props);

  const [priceList, setPriceList] = useState([]);
  const [noList, setNoList] = useState(false);
  const [isLoadingPricesItems, setIsLoadingPricesItems] = useState(false);

  const t = props.t;
  const lang = props.lang;

  useEffect(() => {
    const {id, rowId} = props;

    setIsLoadingPricesItems(true);

    (async () => {
      const [subscriptionLinesPrices] = await Promise.all(
          [
            GetSubscriptionLinePrices(null, id, rowId)
          ]);

      console.log(`SubscriptionLines list - getLinePrices - id ->`, id);
      console.log(`SubscriptionLines list - getLinePrices - row_id ->`, rowId);

      if (subscriptionLinesPrices.ok) {
        const {data} = subscriptionLinesPrices.data; //.data;
        console.log(`SubscriptionLines list - getLinePrices - data ->`, data);

        //if (data && data.length > 0) {
        if (data) {
          setPriceList(data);
        } else {
          setNoList(true);
        }
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoadingPricesItems(false);
  }, [priceList]);

  console.log("Prices - priceList ->", priceList);

  return (
      priceList && priceList.length > 0 &&
        priceList.map((item, key) =>
        <div key={key}>
          <Row id={`line-price-${key}`} className={`subscr-prices`}>
            <Col>
              {/*<span className={`subscr-price-${key}`}>{t("currency_format", { number: Number(item.price_excl_vat) })}</span> / <span>{formatAmountLocale(item.price_incl_vat, lang)}</span>*/}
              <span className={`subscr-price-${key}`}>{t("currency_format", { number: Number(item.price_excl_vat) })}</span> / <span>{t("currency_format", { number: Number(item.price_incl_vat) })}</span>
            </Col>
            <Col style={{paddingLeft:"28px"}}>
              <span className={`subscr-date-${key}`}>
                <span>{t("date_format", { date: new Date(item.price_valid_from) })}</span>
              </span>
            </Col>
          </Row>
        </div>
      )
  );
};

export default Prices;
