import React, { useState, useEffect } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';
import {faAngleLeft,faAngleDown} from '@fortawesome/free-solid-svg-icons';

const CustomFilterInputComp = ({
   column,
   onFilter,
   handleFiltering,
   setClearedItemName,
   isClearFilter,
   value,
   setComparatorFilter,
   comparatorFilter,
   defaultComparator,
   enabledFilter,
   setEnabledFilter,
   table,
   customId = false,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [toggleDropdown, setToggleDropdown] = useState(defaultComparator);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputFiltering = (e) => {
    if (e && e.key && e.key === "Enter") {
      handleFiltering(e, column.dataField, false, toggleDropdown);
    }
  };

  const clearInputField = (e, column) => {
    e.preventDefault();
    setToggleDropdown(defaultComparator);
    setInputValue("");
    setEnabledFilter(true);
    setClearedItemName(column);
    handleFiltering(e, column, false, toggleDropdown);
  };

  const updateToggleDropdown = (e, comparator) => {
    if (comparator) {
      let tableStoredComparators = "qStr"+table+"ComparatorItems";
      let currentComperators = sessionStorage.getItem(tableStoredComparators);
      let comperatorObject = currentComperators ? JSON.parse(currentComperators) : {};
      // console.log("updateToggleDropdown: ", comperatorObject);
      comperatorObject[column.dataField] = comparator;
      let updatedComperators = JSON.stringify(comperatorObject);
      sessionStorage.setItem(tableStoredComparators, updatedComperators);
      // console.log("updateToggleDropdown: ", sessionStorage.getItem(tableStoredComparators));
      setToggleDropdown(comparator);
    }
  };

  const getStorageValue = () => {
    let name = column.dataField;
    let storedValue;
    let tableStorageFilterObjId = "qStr"+table+"FiltersObj";
    let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
    if (filtersObj) {
      let filtersObjParsed = JSON.parse(filtersObj);
      if (filtersObjParsed && filtersObjParsed.filter &&
          filtersObjParsed.filter.length > 0)
      {
        for (let i = 0; i < filtersObjParsed.filter.length; i++) {
          if (filtersObjParsed.filter[i] &&
              filtersObjParsed.filter[i][name])
          {
            storedValue = filtersObjParsed.filter[i][name];
            return storedValue;
          }
        }
      } else {
        if (filtersObjParsed && filtersObjParsed.filter) {
          let key;
          for (key in filtersObjParsed.filter) {
            if (filtersObjParsed.filter.hasOwnProperty(key) && key === name) {
              storedValue = filtersObjParsed.filter[name];
              return storedValue;
            }
          }
        }
      }
    }
    return false;
  };

  const getStoredComparator = () => {
    let tableStoredComparators = "qStr"+table+"ComparatorItems";
    let comparatorFilter = sessionStorage.getItem(tableStoredComparators);
    if (comparatorFilter) {
      let comparatorFilterParsed = JSON.parse(comparatorFilter);
      if (comparatorFilterParsed && comparatorFilterParsed[column.dataField]) {
        return comparatorFilterParsed[column.dataField];
      }
      /*if (comparatorFilterParsed) {
        let key;
        for (key in comparatorFilterParsed) {
          if (comparatorFilterParsed.hasOwnProperty(key) && key === column.dataField) {
            return comparatorFilterParsed[column.dataField];
          }
        }
      }*/
    } else {
      return defaultComparator ? defaultComparator : '>';
    }
    return false;
  };

  const setStoredComparator = () => {
    let tableStoredComparators = "qStr"+table+"ComparatorItems";
    const savedComparators = sessionStorage.getItem(tableStoredComparators);
    const savedComparatorsParsed = savedComparators ? JSON.parse(savedComparators) : {};
    //console.log("setStoredComparator - savedComparatorsParsed ->", savedComparatorsParsed);
    //console.log("setStoredComparator - toggleDropdown ->", toggleDropdown);
    //console.log("setStoredComparator - Object.entries(savedComparatorsParsed) ->", Object.entries(savedComparatorsParsed));

    if (toggleDropdown) {
      if (Object.entries(savedComparatorsParsed).length > 0) {
        Object.assign(savedComparatorsParsed, {[column.dataField]: toggleDropdown});  // single-value
      } else {
        savedComparatorsParsed[column.dataField] = toggleDropdown;
      }
      sessionStorage.setItem(tableStoredComparators, JSON.stringify(savedComparatorsParsed));
    }
  };

  useEffect(() => {
    if (!enabledFilter && value && value !== "") {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isClearFilter === true) {
      setInputValue("");
    }
  }, [isClearFilter]);

  useEffect(() => {
    let storedValue = getStorageValue();
    if (storedValue) {
      setInputValue(storedValue);
    }

    let comparatorItem = getStoredComparator();
    if (comparatorItem) {
        setToggleDropdown(comparatorItem);
    } else {
      setStoredComparator();
    }
  }, []);

  useEffect(() => {
    if (!inputValue || inputValue === "") {}
  }, []);

  useEffect(() => {
    if (toggleDropdown) {
      //(async () => {
        //console.log("CustomFilterInputComp - useEffect - toggleDropdown ->", toggleDropdown);
        //console.log("CustomFilterInputComp - useEffect - column.dataField ->", column.dataField);

        // id => id-input-${column.dataField}
        let elem = document.getElementById(`id-input-${column.dataField}`);
        //console.log("CustomFilterInputComp - useEffect - elem ->", elem);
        if (elem && elem.value) {
          //console.log("CustomFilterInputComp - useEffect - elem.value ->", elem.value);
          handleFiltering(elem, column.dataField, false, toggleDropdown);
        }
      //})();
    }
  }, [toggleDropdown]);

  return [
    <div key={`input-${column.dataField}`} style={{display: 'flex', flexDirection: 'column'}} className={`custom-filter-item`}>
      <InputGroup className={`custom-finance-select mt-0`}>
        <UncontrolledDropdown
            id={`menu-comp-filter-${column.dataField}`}
            className={`menu-comp-filter`}
        >
          <DropdownToggle
              id={`menu-comp-filter-toggle-${column.dataField}`}
              data-toggle="dropdown"
              className={`menu-comp-filter-toggler`}
              style={{fontSize: "12px"}}
              data-focus-id={`id-input-${column.dataField}`}
          >
            {toggleDropdown ? toggleDropdown : defaultComparator}
          </DropdownToggle>
          <DropdownMenu id={`menu-comp-${column.dataField}`} aria-labelledby={`menu-comp-filter-toggle`} style={{ maxWidth: "4rem", minWidth: "inherit", top: "38px" }}>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `>`)}>{`>`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `<`)}>{`<`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `>=`)}>{`>=`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `<=`)}>{`<=`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `=`)}>{`=`}</DropdownItem>
            <DropdownItem onClick={(e) => updateToggleDropdown(e, `%`)}>{`%`}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <Input type="text"
               className="f2x-fin-h-text mt-0 input-comp-filter"
               name={column.dataField}
               id={`id-input-${column.dataField}`}
               key={`key-input-${column.dataField}`}
               value={inputValue}
               placeholder={column.filter && column.filter.props && column.filter.props.placeholder ? column.filter.props.placeholder : 'Search...'}
               onKeyPress={handleInputFiltering}
               onChange={handleInputChange}
               style={{ paddingLeft: "30px" }}
        />
        <InputGroupAddon className="clear-btn clear-filter" addonType="append">
          <a href="/#" className="danger icon-delete" onClick={(e) => clearInputField(e, column.dataField)}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  ];
};

export default CustomFilterInputComp;
