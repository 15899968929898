import React from "react";
import {useHistory, withRouter} from 'react-router-dom';

/**
 * @return {null}
 */

export default function RedirectHandler({ ...props }) {
  const history = useHistory();
  console.log("RedirectHandler - props ->", props);
  if (props.selectedData && props.selectedData.id) {
    history.push(`/admin/users/${props.selectedData.id}/api-keys`);
    return props.selectedData.id;
  }
  return null;
}
