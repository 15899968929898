import ApiService from "../services/apiService";

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI;

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        let data = await method ? method(endpoint, params) : await GetAPI(
            endpoint, params);
        return data;
    })()
};

const GetPaymentConditions = (params, id) => {
    return asyncFunc(`/tenants/${id}/payment-conditions`, params);
};

const AddPaymentCondition = (id, params) => {
    return asyncFunc(`/tenants/${id}/payment-conditions`, params, PostAPI);
};

const UpdatePaymentCondition = (id, params) => {
    return asyncFunc(`/tenants/payment-conditions/${id}`, params, UpdateAPI);
};

export {
    GetPaymentConditions,
    AddPaymentCondition,
    UpdatePaymentCondition,
}
