import ApiService from '../services/apiService';

const Api = new ApiService(),
  GetAPI = Api.getAPI,
  PostAPI = Api.postAPI,
  UpdateAPI = Api.updateAPI,
  RemoveAPI = Api.removeAPI;

const apiIncludes = 'parent';

const asyncFunc = (endpoint, params, method) => {
  return (async () => {
    return await method ? method(endpoint, params) : await GetAPI(endpoint,
          params);
  })()
};

const GetPlans = (params) => {
  return asyncFunc(`plans`, params);
};

const GetAdminPlans = (params) => {
  return asyncFunc(`admin/plans`, params);
};

const GetPlanList = () => {
  return asyncFunc(`plans/list`);
};

const GetPlan = (params, id) => {
  return asyncFunc(`plans/${id}?include=${apiIncludes}`);
};

const GetSinglePlan = (params, id) => {
  return asyncFunc(`plans/${id}`);
};

const GetDependenciesPlans = (id) => {
  return asyncFunc(`dependencies/plans${ id ? `?plan=${ id }` : '' }`);
};

const AddPlan = (params) => {
  return asyncFunc(`plans`, params, PostAPI);
};

const UpdatePlan = (id, params) => {
  return asyncFunc(`plans/${id}`, params, UpdateAPI);
};

const DuplicatePlan = (id, params) => {
  return asyncFunc(`plans/${id}/duplicate`, params, PostAPI);
};

//id = plan_id | id2 = tenant_id
const DelegatePlan = (id, id2, params) => {
  return asyncFunc(`plans/${id}/delegate/${id2}`, params, PostAPI);
};

const RemovePlan = (id) => {
  return asyncFunc(`plans/${id}`, null, RemoveAPI);
};

const GetPlanLine = (params, id) => {
  if (id) {
    return asyncFunc(`plan_lines/${id}`, params);
  }
};

const GetPlanLines = (params, id) => {
  return asyncFunc(`plans/${id}/plan_lines`, params);
};

const GetDependenciesPlanLines = (id, id2) => {
  return asyncFunc(`dependencies/plan_lines${ id ? `?plan=${ id }` : '' }${ id2 ? `&plan_line=${ id2 }` : '' }`);
};

const AddPlanLine = (id, params) => { // used to be -> (id, params)
  return asyncFunc(`plan_lines/${id}`, params, PostAPI);
};

const AddSubPlanLine = (id, params) => {
  return asyncFunc(`plan_lines/${id}`, params, PostAPI);
};

const UpdatePlanLine = (id, params) => {
  return asyncFunc(`plan_lines/${id}`, params, UpdateAPI);
};

const RemovePlanLine = (id) => {
  return asyncFunc(`plan_lines/${id}`, null, RemoveAPI);
};

const GetPlanLinePrices = (plan_id, plan_line_id) => {
  return asyncFunc(`plans/${plan_id}/plan_lines/${plan_line_id}/plan_line_prices`);
};

const GetPlanLinePricesOpts = (plan_line_id) => {
  return asyncFunc(`plan_lines/${plan_line_id}/plan_line_prices`);
};

const GetSinglePlanLinePrices = (plan_line_id) => {
  return asyncFunc(`plan_lines/${plan_line_id}/`);
};

const GetSinglePlanLinePricesOpts = (plan_line_id) => {
  return asyncFunc(`plan_lines/${plan_line_id}/plan_line_prices`);
};

const GetPlanLineTermsConditions = (id) => {
  if (id) {
    return asyncFunc(`plan_lines/${id}/terms_conditions/`);
  }
};

const AddPlanLinePrices = (params, id) => {
  return asyncFunc(`plan_lines/${id}/plan_line_prices`, params, PostAPI);
};

const AddPlanLineSinglePrice = (params) => {
  return asyncFunc(`plan_line_prices`, params, PostAPI);
};

const UpdatePlanLinePrices = (params, id) => {
  return asyncFunc(`plan_line_prices/${id}`, params, UpdateAPI);
};

const RemovePlanLinePrice = (id) => {
  return asyncFunc(`plan_line_prices/${id}`, null, RemoveAPI);
};

const GetPlanLineGroups = (params) => {
  return asyncFunc(`plan_line_groups`, params);
};

const GetPlanLineGroup = (plan_line_group_id) => {
  return asyncFunc(`plan_line_groups/${plan_line_group_id}`);
};

const AddPlanLineGroup = (params) => {
  return asyncFunc(`plan_line_groups`, params, PostAPI);
};

const UpdatePlanLineGroup = (params, plan_line_group_id) => {
  return asyncFunc(`plan_line_groups/${plan_line_group_id}`, params, UpdateAPI);
};

const RemovePlanLineGroup = (plan_line_group_id) => {
  return asyncFunc(`plan_line_groups/${plan_line_group_id}`, null, RemoveAPI);
};

// Generates json of multiple Offers for given Microservice
const GetPlanOffers = (tenantId, microserviceId, params) => {
  return asyncFunc(`offers/generate/${tenantId}/microservice/${microserviceId}`, params);
};

const CreatePlanOfferOrder = (params) => {
  return asyncFunc(`availability-check/order`, params, PostAPI);
};

// public endpoint for creating offer(s)
const CreatePublicPlanOfferOrder = (params, apiKey) => {
  return asyncFunc(`public/availability-check/order?api_key=${apiKey}`, params, PostAPI);
};

// Generates json of a single Offer for a given Plan
const GetPlanOffer = (tenantId, planId, params) => {
  console.log("GetPlanOffer - tenantId ->", tenantId);
  console.log("GetPlanOffer - planId ->", planId);
  return asyncFunc(`offers/generate/${tenantId}/plan/${planId}`, params);
};

export {
  //Plans
  GetPlans,
  GetAdminPlans,
  GetPlanList,
  GetPlan,
  GetSinglePlan,
  GetDependenciesPlans,
  AddPlan,
  UpdatePlan,
  DuplicatePlan,
  DelegatePlan,
  RemovePlan,

  //Plan Lines
  GetPlanLine,
  GetPlanLines,
  GetDependenciesPlanLines,
  AddPlanLine,
  AddSubPlanLine,
  UpdatePlanLine,
  RemovePlanLine,
  GetSinglePlanLinePricesOpts,

  //Plan Line Prices
  GetPlanLinePrices,
  GetPlanLinePricesOpts,
  GetSinglePlanLinePrices,
  AddPlanLinePrices,
  AddPlanLineSinglePrice,
  UpdatePlanLinePrices,
  RemovePlanLinePrice,

  //Plan Line Groups
  GetPlanLineGroups,
  GetPlanLineGroup,
  AddPlanLineGroup,
  UpdatePlanLineGroup,
  RemovePlanLineGroup,

  //Plan Line Terms Conditions
  GetPlanLineTermsConditions,

  //Offers
  GetPlanOffers,
  GetPlanOffer,
  CreatePlanOfferOrder,
  CreatePublicPlanOfferOrder,
}