export const getTableObj = (type, {keyword, isLoadData, pageNum, page, searchText, data}) => {
  let text = searchText,
      pageSet,
      isLoad;

    // search bar has value
    if (searchText && type === 'search') {
      // empty search field
      if (searchText === '' && isLoadData) {
        text = '' // reset keyword
      }
      pageSet = 1; // reset page
      isLoad = true // load data
    } else {
      // table change type = sort/pagination/filter search bar empty field
      if (!keyword || searchText === '') { // reset keyword state
        text = ''; // reset keyword
        pageSet = 1; // reset page
        isLoad = true // load data
      } else {
        text = keyword;
        pageSet = 1; // reset page
        isLoad = true // load data
      }

      // sort on initial load - should not load data
      if (type === 'sort' && pageNum === null) {
        isLoad = false // load data
      } else {
        pageSet = page; // set current page
        isLoad = true // load data
      }
    }

    return {
      keyword: text,
      pageNum: pageSet,
      isLoadData: isLoad,
      data: data
    }
  
};