import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import {
  UpdateRelationNotes
} from '../controllers/relations';
import Toast from "../components/Toast";
import { Container, Row, Col, Button } from "reactstrap";
import DataTable from './DataTable';
import { loaderToggle } from '../redux/actions/loaderActions';
import { withRouter } from 'react-router-dom';
import { Edit2 } from "react-feather";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  convertLocalTime
} from '../redux/actions/formatActions';
import {
  GetRelationNotes
} from '../controllers/relations';

class NotesTable extends React.Component {
  hasMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      sizePerPage: 10,
      totalSize: 0,
      columns: [{
        text: "",
        dataField: "created_at",
        formatExtraData: "user",
        formatter: this.extraDataFormatter,
        headerStyle: () => {
          return { width: '30%', 'textAlign': 'left' };
        }
      }, {
        text: "",
        dataField: "text",
        formatter: this.addNoteFormatter,
        headerStyle: () => {
          return { width: '70%', 'textAlign': 'left' };
        }
      }],
      language: props.language ? props.language : 'nl',
      isToggledNote: false,
      isToggledUserNote: false,
      toggledNoteId: null,
      notes: { text: '', id: null },
      userNotes: { text: '', id: null, user_id: null, type: null, related_id: null }
    };

    this.toggleNote = this.toggleNote.bind(this);
  }

  toggleNote = () => {
    this.setState({ notes: { text: '' } });
    this.setState({ toggledNoteId: null });
    this.setState({ isToggledUserNote: false });
    this.setState({ isToggledNote: !this.state.isToggledNote });
  };

  toggleUserNote = async (id, user_id, text, related_id) => {
    await this.setState({
      columns: null
    });

    if (this.state.isToggledNote === true) {
      await this.setState({ isToggledNote: false });
    }
    await this.setState({ isToggledUserNote: false });
    await this.setState({ isToggledUserNote: !this.state.isToggledUserNote });
    await this.setState({ toggledNoteId: id });
    await this.setState({ userNotes: { id: id, text: text, user_id: user_id, type: 'relations', related_id: related_id } });

    this.toggleRenderUserQuill();
  };

  toggleRenderUserQuill = () => {
    if (this.state.isToggledUserNote) {
      this.setState({
        columns: [
          {
            text: "",
            dataField: "created_at",
            formatExtraData: "user",
            formatter: this.extraDataFormatter,
            headerStyle: () => {
              return { width: '30%', 'textAlign': 'left' };
            }
          }, {
            text: "",
            dataField: "text",
            formatter: this.addUserNoteFormatter,
            headerStyle: () => {
              return { width: '70%', 'textAlign': 'left' };
            }
          }
        ]
      });
    } else {
      this.setState({
        columns: [
          {
            text: "",
            dataField: "created_at",
            formatExtraData: "user",
            formatter: this.extraDataFormatter,
            classes: "notes-table-cell-left",
            headerStyle: () => {
              return { width: '30%', 'textAlign': 'left' };
            },
            attrs: {
              'data-label': 'Created@'
            }
          }, {
            text: "",
            dataField: "text",
            formatter: this.addNoteFormatter,
            classes: "notes-table-cell-right",
            headerStyle: () => {
              return { width: '70%', 'textAlign': 'left' };
            },
            attrs: {
              'data-label': 'Note'
            }
          }
        ]
      })
    }
    this.updateDataTable();
  };

  handleQuillChange = (value) => {
    let notes = Object.assign({}, this.state.notes);
    notes.text = value;
    this.setState({ notes });
  };

  getDotNotation = (path, obj, val) => {
    let thisPath = path.split('.');

    if (thisPath.length > 1) {
      const [bodyPath] = thisPath.slice(0, -1),
        [pathTail] = thisPath.slice(-1),
        thisObj = thisPath.slice(0, -1).reduce((item, i) => (item === undefined ? undefined : item[i]), obj);

      if (thisObj !== undefined && thisObj !== null) {
        if (val) {
          thisObj[pathTail] = val;
          obj[bodyPath] = thisObj;
          return obj;

        } else {
          return thisObj[pathTail];
        }
      }

    } else {
      return path.split('.').reduce((item, i) => item && item[i] ? item[i] : item[i] === 0 ? item[i].toString() : null, obj);

    }
  };

  extraDataFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { user, t } = this.props;
    const fullNameUserNoteCreator = row && row.user_fullname ? row.user_fullname : user && user.username ? user.username : null;
    const dt = convertLocalTime(cell);

    let editable = false;
    let user_id;
    let note_id;
    let note_text;
    let related_id;

    if (row.user_id && user.id && row.user_id === user.id) {
      editable = true;
      user_id = user.id;
      note_id = row.id;
      note_text = row.text;
      related_id = row.related_id;
    }

    return <React.Fragment>
      <span className="text-truncate">{cell && cell !== '' ? t("date_time_format", { date: new Date(dt) }) : String.fromCharCode(8212)}</span>
      <span className="text-truncate">{fullNameUserNoteCreator ? fullNameUserNoteCreator : String.fromCharCode(8212)}</span>
      {editable &&
        <OverlayTrigger
          key={`edit-customer-note-tooltip-overlay`}
          placement={`bottom`}
          transition={false}
          delay={200}
          overlay={
            <Tooltip
              className="tooltip"
              container="body"
              id={`tooltip-bottom-edit-customer-note`}
            >
              {`Edit`}
            </Tooltip>}
        >
          <Button className={`edit-note btn btn-gray mt-2 mb-2 text-truncate`} onClick={(e) => this.toggleUserNote(note_id, user_id, note_text, related_id)}>
            <Edit2 size={15} color="grey" />
          </Button>
        </OverlayTrigger>
      }
    </React.Fragment>
  };

  addNoteFormatter = (cell, row, rowIndex) => {
    const { user, t } = this.props;
    return (<div>{parse(cell)}</div>);
  };

  addUserNoteFormatter = (cell, row, rowIndex) => {
    const { user, t } = this.props;
    const { toggledNoteId, userNotes } = this.state;

    if (row.user_id && user.id && row.user_id === user.id && toggledNoteId === row.id) {
      return (<div className={`notes-editor-cell`}>{this.renderUserQuill(cell, row)}</div>);
    } else {
      return (<div>{parse(cell)}</div>);
    }
  };

  toggleNote2 = async () => {
    const data = Object.assign([], this.state.data);
    let notes = Object.assign({}, this.state.notes);

    await this.setState({ toggleNote: !this.state.toggleNote });

    if (this.state.toggleNote) {
      const { t } = this.props;
      const getDate = t("date_format", { date: new Date().toLocaleString() });

      data.unshift({
        id: "isNote",
        isNote: true,
        created_at: ' ',
        user: ' '
      });

      notes.added = getDate;

      this.setState({
        data,
        notes
      })
    } else {
      if (data && data[0] && data[0].isNote) {
        data.shift();
        let notes = Object.assign({}, this.state.notes);

        notes.text = '';

        this.setState({
          data,
          notes
        })
      }
    }
  };

  renderUserQuill = (cell, row) =>
    <Container fluid className="p-0 card mt-1 pt-3 mb-4">
      <div className="d-flex">
        <Col>
          <ReactQuill
            placeholder="Notes..."
            value={cell ? cell : this.state.userNotes.text}
            onChange={this.handleQuillChange}
          />
        </Col>
      </div>
      <div className="d-flex justify-content-between">
        <Col className="d-flex">
          <Button color="danger" onClick={this.toggleUserNote}
            className="my-3">
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button color="primary" onClick={(e) => this.handleUpdateNote(e)} className="ml-auto my-3">
            <FontAwesomeIcon icon={faSave} />
          </Button>
        </Col>
      </div>
    </Container>;

  renderQuill = (cell) =>
    <Container fluid className="p-0 card mt-1 pt-3 mb-4">
      <div className="d-flex">
        <Col>
          <ReactQuill
            placeholder="Notes..."
            value={cell ? cell : this.state.notes.text}
            onChange={this.handleQuillChange}
          />
        </Col>
      </div>
      <div className="d-flex justify-content-between">
        <Col className="d-flex">
          <Button color="danger" onClick={this.toggleNote} className="my-3">
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button color="primary" onClick={(e) => this.handleSaveNote(e)} className="ml-auto my-3">
            <FontAwesomeIcon icon={faSave} />
          </Button>
        </Col>
      </div>
    </Container>;

  handleUpdateNote = async (e) => {
    e.preventDefault();

    const { updateApi, dispatch, disableLoader } = this.props,
      { userNotes } = this.state,
      { id } = userNotes,
      { notes } = this.state,
      { text } = notes,
      stripHtml = text ? text.replace(/<[^>]*>?/gm, '') : null;

    console.log("NotesTable - userNotes ->", userNotes);

    const updatedUserNote = {};

    if (!stripHtml || (stripHtml && !/\S/.test(stripHtml))) {
      return Toast.fire({ title: 'The note can’t be empty.', icon: 'warning' })
    } else {
      updatedUserNote.id = userNotes.id;
      updatedUserNote.related_id = userNotes.related_id;
      updatedUserNote.type = userNotes.type;
      updatedUserNote.user_id = userNotes.user_id;
      updatedUserNote.text = text;
    }

    console.log("NotesTable - id ->", id);
    console.log("NotesTable - updatedUserNote ->", updatedUserNote);

    if (!disableLoader)
      dispatch(loaderToggle(true));

    const { ok, data } = await UpdateRelationNotes(id, updatedUserNote);
    await (() => {
      if (ok) {
        this.toggleUserNote();
        this.updateDataTable();
      }
    })();

    if (!disableLoader)
      dispatch(loaderToggle(false));
  };

  handleSaveNote = async (e) => {
    e.preventDefault();

    const { addApi, id, dispatch, disableLoader } = this.props,
      { notes } = this.state,
      { text } = notes,
      stripHtml = text ? text.replace(/<[^>]*>?/gm, '') : null;

    console.log("NotesTable - handleSaveNote - text ->", text);
    console.log("NotesTable - handleSaveNote - stripHtml ->", stripHtml);

    if (!stripHtml || (stripHtml && !/\S/.test(stripHtml))) {
      return Toast.fire({ title: 'The note can’t be empty.', icon: 'warning' })
    }

    if (!disableLoader)
      dispatch(loaderToggle(true));

    const { ok } = await addApi(id, notes);
    await (() => {
      if (ok) {
        this.toggleNote();
        this.updateDataTable();
      }
    })();

    if (!disableLoader)
      dispatch(loaderToggle(false));

  };

  setQueryParameters = (params) => {
    if (params) {
      let query = [],
        key,
        value;

      for (key in params) {
        if (!params.hasOwnProperty(key)) {
          continue;
        }
        if (key === 'page' || key === 'offset') {
          value = parseInt(params[key]);
        } else {
          value = params[key];
        }
        query.push(`${key}=${value}`);
      }

      localStorage.setItem('qStrNotes', query.join('&'));
    }
  };

  getQueryParameters = () => {
    let params = [];
    let queryString = localStorage.getItem('qStrNotes') && localStorage.getItem('qStrNotes').charAt(0) === '?'
      ? localStorage.getItem('qStrNotes').slice(1)
      : localStorage.getItem('qStrNotes');

    if (queryString) {
      queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
        params[key] = parseInt(value);
      });
    }
    return params;

  };

  loadParamData = () => {
    let params = this.getQueryParameters();
    if (params && params.page && params.offset) {

      this.setState({
        page: params.page,
        sizePerPage: params.offset
      });
    } else {
      let params = [];
      params.page = 1;
      params.offset = 10;

      this.setState({
        page: params.page,
        sizePerPage: params.offset
      });
    }
    return params;

  };

  getData = () => {
    const { dispatch, id, disableLoader } = this.props;

    let param = {};
    let params = this.loadParamData();

    if (!params) {
      param = {
        page: parseInt(this.state.page),
        offset: parseInt(this.state.sizePerPage)
      };
    } else {
      param = {
        page: params && params.page ? parseInt(params.page) : parseInt(this.state.page),
        offset: params && params.offset ? parseInt(params.offset) : parseInt(this.state.sizePerPage)
      };
    }

    (async () => {
      if (!disableLoader)
        dispatch(loaderToggle(true));

      await GetRelationNotes(id, param)
        .then(res => {
          if (res.ok) {
            const { data } = res;

            this.setState({
              data: data.data,
              totalSize: data.total
            });
          }
        })
        .catch(e => {
          console.log('Error', e);
        });

      if (!disableLoader)
        dispatch(loaderToggle(false));

    })();
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    let params = [];
    params['page'] = parseInt(page);
    params['offset'] = parseInt(sizePerPage);
    this.setQueryParameters(params);
    this.updateDataTable();
  };

  updateDataTable = () => {
    this.getData();
  };

  componentDidMount() {
    this.updateDataTable();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { i18n, id } = this.props;

    //console.log("NotesTable - componentDidUpdate - this.props ->", this.props);
    //console.log("NotesTable - componentDidUpdate - prevProps ->", prevProps);

    if (id && prevProps.id !== id) {
      this.getData();
    }

    if (this.state && this.state.language !== i18n.language) {
      (async () => {
        await this.setState({ language: i18n.language, data: null });
        this.updateDataTable();
      })();
    }
  }

  render() {
    const { data, page, sizePerPage, totalSize, columns, isToggledNote, isToggledUserNote } = this.state,
      { i18n } = this.props;

    return (
      <div className="p-0">
        <div className="customer-notes">
          <Row>
            <Col className="text-left">
              <h5 className="mb-0">Notes</h5>
            </Col>
            <Col className="text-right">{!isToggledNote &&
              <OverlayTrigger
                key={`add-customer-note-tooltip-overlay`}
                placement={`bottom`}
                transition={false}
                delay={200}
                overlay={
                  <Tooltip
                    className="tooltip"
                    container="body"
                    id={`tooltip-bottom-add-customer-note`}
                  >
                    {`Add`}
                  </Tooltip>}
              >
                <Button color="gray" className="btn" onClick={this.toggleNote}>
                  <FontAwesomeIcon color="gray" icon={faPlus} />
                </Button>
              </OverlayTrigger>
            }
            </Col>
          </Row>
        </div>
        <div className="body-customer-notes shadow-sm">
          {isToggledNote && this.renderQuill()}
          {data && columns &&
            <DataTable
              table="Notes"
              keyField="id"
              id="RelationNotes"
              isNotes={true}
              language={i18n && i18n.language ? i18n.language : 'nl'}
              data={data}
              page={page ? page : 1}
              sizePerPage={sizePerPage ? parseInt(sizePerPage) : 10}
              totalSize={totalSize ? totalSize : 10}
              onTableChange={this.handleTableChange}
              columns={columns}
              pagination={true}
              headerWrapperClasses="d-none"
              noHover={true}
            />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ relation, user, loader }) => ({ relation, user, loader });

export default connect(mapStateToProps)(withTranslation()(withRouter(NotesTable)));

