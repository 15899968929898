import React, { useState, useEffect } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';
import {getTableObj} from '../../services/tableService';
import {
  faArrowLeft,
  faArrowRight,
  faCaretLeft,
  faCaretRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const CustomFilterDate = ({
  column,
  onFilter,
  handleFiltering,
  setClearedItemName,
  isClearFilter,
  value,
  enabledFilter,
  setEnabledFilter,
  table,
  icon = false,
  customId = false,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);

  const clearInputField = (e, column) => {
    e.preventDefault();
    setDate(null);
    setSelectedValue(null);
    setEnabledFilter(true);
    setClearedItemName(column);
    handleFiltering(e, column, false);
  };

  const getStorageValue = () => {
    let name = column.dataField;
    let storedValue;
    let tableStorageFilterObjId = "qStr"+table+"FiltersObj";
    let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
    if (filtersObj) {
      let filtersObjParsed = JSON.parse(filtersObj);
      if (filtersObjParsed && filtersObjParsed.filter &&
          filtersObjParsed.filter.length > 0)
      {
        for (let i = 0; i < filtersObjParsed.filter.length; i++) {
          if (filtersObjParsed.filter[i] &&
              filtersObjParsed.filter[i][name]) {
            storedValue = filtersObjParsed.filter[i][name];
            return storedValue;
          }
        }
      } else {
        if (filtersObjParsed && filtersObjParsed.filter) {
          let key;
          for (key in filtersObjParsed.filter) {
            if (filtersObjParsed.filter.hasOwnProperty(key) && key === name) {
              storedValue = filtersObjParsed.filter[name];
              return storedValue;
            }
          }
        }
      }
    }
    return false;
  };

  useEffect(() => {
    handleFiltering(date, column.dataField);
  }, [date]);

  useEffect(() => {
    if (isClearFilter === true) {
      setDate("");
    }
  }, [isClearFilter]);

  useEffect(() => {
    //console.log("CustomFilterDate - useEffect (deps: value) -value ->", value);
    if (!enabledFilter && !selectedValue && value && value !== "") {
      let valueData = column.dataField === "hp_timestamp" ? parseInt(value) : new Date(value);
      if (valueData) {
        setSelectedValue(valueData);
      }
    }
  }, [value]);

  useEffect(() => {
    let storedValue = getStorageValue();
    if (storedValue) {
      setSelectedValue(parseInt(storedValue));
    }
  }, []);

  return [
    <div key={`select-date-${column.dataField}`} className={`custom-finance-select-container custom-filter-item mt-0`} style={{display: 'flex', flexDirection: 'column'}}>
      <InputGroup className={`custom-finance-select mt-0`}>
        {icon && icon === "left" && <span className="input-group-text" id="basic-add-on-left"><FontAwesomeIcon icon={faAngleLeft} /></span>}
        {icon && icon === "right" && <span className="input-group-text" id="basic-add-on-right"><FontAwesomeIcon icon={faAngleRight} /></span>}
        <DatePicker
            className="form-control f2x-h-text input-icon"
            name={column.dataField}
            dateFormat={t("date_format_raw")}
            autoComplete="off"
            placeholderText="Select date"
            selected={selectedValue ? selectedValue : date ? new Date(date) : null}
            onChange={(date) => {
              setDate(date ? new Date(date) : null)
            }}
            maxDate={new Date()}>
        </DatePicker>
        <InputGroupAddon className="clear-btn" addonType="append">
          <a href="/#" className="danger icon-delete" onClick={(e) => clearInputField(e, column.dataField)}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  ];
};

export default CustomFilterDate;
