import * as types from "../constants";

export function toggleSidebarMenuInvoices() {
  return {
    type: types.SIDEBAR_MENU_INVOICES_VISIBILITY_TOGGLE
  };
}

export function showSidebarMenuInvoices() {
  return {
    type: types.SIDEBAR_MENU_INVOICES_VISIBILITY_SHOW
  };
}

export function hideSidebarMenuInvoices() {
  return {
    type: types.SIDEBAR_MENU_INVOICES_VISIBILITY_HIDE
  };
}

export function toggleSidebarMenuSubscriptions() {
  return {
    type: types.SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_TOGGLE
  };
}

export function showSidebarMenuSubscriptions() {
  return {
    type: types.SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_SHOW
  };
}

export function hideSidebarMenuSubscriptions() {
  return {
    type: types.SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_HIDE
  };
}
