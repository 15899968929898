import React, { useState, useEffect, useRef } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';
import {getTableObj} from '../../services/tableService';

const CustomFilterDateRange = ({
   column,
   onFilter,
   handleFiltering,
   setClearedItemName,
   isClearFilter,
   value,
   enabledFilter,
   setEnabledFilter,
   table,
   customId = false,
}) => {
  const { t } = useTranslation();
  //console.log("CustomFilterDateRange - value ->", value);

  const dateRangeRef = useRef();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [defaultValStyling, setDefaultValStyling] = useState(``);
  const [dates, setDates] = useState([startDate, endDate]);
  const dateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  const onDateChange = (dates) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getStorageValue = () => {
    let name = column.dataField;
    let storedValue;
    let tableStorageFilterObjId = "qStr"+table+"FiltersObj";
    let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
    if (filtersObj) {
      let filtersObjParsed = JSON.parse(filtersObj);
      if (filtersObjParsed && filtersObjParsed.filter &&
          filtersObjParsed.filter.length > 0)
      {
        for (let i = 0; i < filtersObjParsed.filter.length; i++) {
          if (filtersObjParsed.filter[i] &&
              filtersObjParsed.filter[i][name])
          {
            storedValue = filtersObjParsed.filter[i][name];
            return storedValue;
          }
        }
      } else {
        if (filtersObjParsed && filtersObjParsed.filter) {
          let key;
          for (key in filtersObjParsed.filter) {
            if (filtersObjParsed.filter.hasOwnProperty(key) && key === name) {
              storedValue = filtersObjParsed.filter[name];
              return storedValue;
            }
          }
        }
      }
    }
    return false;
  };

  function handleDateChange(date) {
    // initial change start by setting the startDate
    if (!startDate && !endDate) {
      setStartDate(date ? new Date(date) : new Date().toLocaleDateString());
      // startDate has been set, set the end date
    } else if (startDate && !endDate) {
      setEndDate(date ? new Date(date) : new Date().toLocaleDateString());
    }
    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate(null);
    }
    setDates([startDate, endDate]);
  }

  const clearInputField = (e, column) => {
    e.preventDefault();
    setStartDate(null);
    setEndDate(null);
    setEnabledFilter(true);
    setClearedItemName(column);
    handleFiltering(e, column, false);
  };

  useEffect(() => {
    if (isClearFilter && isClearFilter === true) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [isClearFilter]);

  useEffect(() => {
    if (endDate !== "") {
      dateRangeRef.current.state.open = false;
      handleFiltering([startDate, endDate], column.dataField);
    }
  }, [endDate]);

  useEffect(() => {
    let storedValue = getStorageValue();
    //console.log("CustomFilterDateRange - useEffect (deps: value) - getStorageValue - storedValue ->", storedValue);
    if (storedValue && storedValue.length > 0) {
      setStartDate(storedValue[0]);
      setEndDate(storedValue[1]);
    }
  }, []);

  return [
    <div key={`select-date-${column.dataField}`} className={`custom-finance-select-container custom-filter-item mt-0`} style={{display: 'flex', flexDirection: 'column'}}>
      <InputGroup className={`custom-finance-select mt-0`}>
        <DatePicker
            ref={dateRangeRef}
            className={`form-control f2x-h-text ${startDate && !endDate ? t("date_format", { date: new Date(startDate).toLocaleDateString() }) + `` : startDate && endDate ? `` : ` default-selected-color` }`}
            id={`id-input-date-range-${column.dataField}`}
            name={`input-date-range-${column.dataField}`}
            value={ startDate && !endDate ? t("date_format", { date: new Date(startDate).toLocaleDateString() }) + " - " : startDate && endDate ? t("date_format", { date: new Date(startDate).toLocaleDateString() }) + " - " + t("date_format", { date: new Date(endDate).toLocaleDateString() }) : "" }
            selectsStart={true}
            selectsEnd={true}
            onChange={handleDateChange}
            startDate={startDate}
            dateFormat={t("date_range_format_raw")}
            placeholderText="From date - To date"
            endDate={endDate}
            inline={false}
            shouldCloseOnSelect={false}
            shouldCloseOnSelectEnd={true}
        />
        <InputGroupAddon className="clear-btn" addonType="append">
          <a href="/#" className="danger icon-delete" onClick={(e) => clearInputField(e, column.dataField)}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  ];
};

export default CustomFilterDateRange;
