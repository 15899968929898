import React from "react";
import { Check } from "react-feather";
import { GetPaymentConditions } from '../../../controllers/payment_conditions';
import PaymentConditionForm from "./Form";
import ParentTable from '../../../components/ParentTable';
import { Helmet } from "react-helmet";

class PaymentCondition extends React.Component {
  defaultFormatter = (cell) => {
    return (
      <React.Fragment>
        {cell === 1 &&
          <Check size={18} color="#5fc27e" className="check" />
        }
      </React.Fragment>
    )
  };

  render() {
    const { id, tabTitle } = this.props;

    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="PaymentConditions"
          id={id}
          keyField="id"
          classes="table-responsive"
          wrapperClasses="table-payment-condition f2x-custom-table"
          data={GetPaymentConditions}
          columns={[
            {
              dataField: "description",
              text: "Description",
              sort: false,
              headerStyle: () => {
                return { width: '15%' };
              },
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "net_days",
              text: "Net days",
              align: "center",
              headerStyle: () => {
                return { 'textAlign': 'center', width: '10%' }
              },
              sort: true,
              attrs: {
                'data-label': 'Net days'
              }
            },
            {
              dataField: "direct_debit",
              text: "Direct debit",
              formatter: this.defaultFormatter,
              headerStyle: () => {
                return { 'textAlign': 'center', width: '10%' }
              },
              align: "center",
              sort: true,
              attrs: {
                'data-label': 'Direct debit'
              }
            },
            {
              dataField: "pay_in_advance",
              text: "Pay in advance",
              formatter: this.defaultFormatter,
              align: "center",
              headerStyle: () => {
                return { 'textAlign': 'center', width: '15%' }
              },
              sort: true,
              attrs: {
                'data-label': 'Pay in advance'
              }
            },
            {
              dataField: "default",
              text: "Default",
              formatter: this.defaultFormatter,
              align: "center",
              headerStyle: () => {
                return { 'textAlign': 'center', width: '10%' }
              },
              sort: true,
              attrs: {
                'data-label': 'Default'
              }
            },
            {
              dataField: "status",
              text: "Status",
              formatter: this.defaultFormatter,
              align: "center",
              headerStyle: () => {
                return { 'textAlign': 'center', width: '10%' }
              },
              sort: true,
              attrs: {
                'data-label': 'Status'
              }
            },
            {
              dataField: "created_user.username",
              text: "Created by",
              sort: true,
              headerStyle: () => {
                return { width: '15%' };
              },
              attrs: {
                'data-label': 'Created by'
              }
            },
            {
              dataField: "updated_user.username",
              text: "Updated by",
              sort: true,
              headerStyle: () => {
                return { width: '15%' };
              },
              attrs: {
                'data-label': 'Updated by'
              }
            }
          ]}
          rowAction="edit"
          noTopBorder={true}
          rowActionForm={PaymentConditionForm}
          headerDropdownOpts={[{
            label: 'Add',
            action: PaymentConditionForm
          }]}
        />
      </React.Fragment>
    )
  }

}
export default PaymentCondition;
