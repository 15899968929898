import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import queryString from "query-string";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  CustomInput,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  InputGroup,
  InputGroupAddon,
  Spinner
} from 'reactstrap';
import { MoreHorizontal } from "react-feather";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import filterFactory from 'react-bootstrap-table2-filter';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const { SearchBar } = Search;

const NoDataIndication = () => (
  <Spinner color="primary" size={`small`} style={{ maxHeight: "18px", maxWidth: "18px" }} />
);

const NoDataIndicationText = (loader) => {
  // console.log("NoDataIndicationText - loader ->", loader);
  // if 'No data found.';
  if (loader) {
    return <div className="loading dots-sm" style={{ lineHeight: "22px", height: "18px", verticalAlign: "middle" }}> </div>;
  } else {
    return 'No data found.';
  }
}

class DataTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const { filters, i18n, keyword, filter, rowId, dataField, isLoadData, setIsLoadData, table, collapseFilter } = this.props;

    this.state = {
      filters: { keyword: "", filter: {} },
      selectedRow: null,
      language: i18n.language ? i18n.language : 'nl',
      keyword: '', // used for search=
      filter: [], // used for filter=
      sessionTableId: table ? table : null,
      searchSubmit: false,
      params: [],
      paramsStr: null,
      defaultSearchKeyword: "",
    };

    this.rowClasses = this.rowClasses.bind(this)
  }

  async filterHandler(e) {
    let { name, value } = e.target;

    let tableId = 'qStr' + this.props.table;
    let tableIdFilter = 'qStr' + this.props.table + 'Filter';
    let tableIdFilterStr = 'qStr' + this.props.table + 'FilterStr';
    let tableIdFiltersObj = 'qStr' + this.props.table + 'FiltersObj';

    let filterData = sessionStorage.getItem(tableIdFilter);
    let filterStrData = sessionStorage.getItem(tableIdFilterStr);
    let filtersObjData = sessionStorage.getItem(tableIdFiltersObj);
    let parsedFiltersObjData;

    //let filtersFilter;
    //if (tableId) {
    if (filtersObjData) {
      parsedFiltersObjData = JSON.parse(filtersObjData);
      this.setFilters(parsedFiltersObjData);
      //filtersFilter = parsedFiltersObjData.filter;
    }
    //}
    const history = this.props.history;

    let queryString;
    let query = [];
    let params = {};
    let paramsArr = [];
    let paramsPushArr = [];

    if (this.props.location.search && this.props.location.search.charAt(0) === '?') {
      queryString = this.props.location.search.slice(1);
      if (queryString) {
        queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, val) => {
          if (key === 'page') {
            query.push(`page=1`);
          } else if (key === 'offset' || key === 'count') {
            query.push(`${key}=${parseInt(val)}`);
          } else if (key === 'search') {
            query.push(`${key}=${value ? value.trim() : ""}`);
          } else if (filterStrData && filterStrData !== "" && key === 'filter') {
            query.push(filterStrData);
          } else {
            query.push(`${key}=${val.trim()}`);
          }
        });
      }
    }

    const { cacheSearch, filterSearch, noSearch } = this.props;

    if (!filterSearch && cacheSearch && tableId && query.length > 0) {
      sessionStorage.setItem(tableId, query.join('&'));
    }

    let qStr = query.join('&');

    if (value !== null || value !== undefined) {
      if (value === "") {
        await this.setState({
          keyword: "",
          //filter: null,
          filters: {
            ...this.state.filters,
            keyword: "",
            //filter: "",
          },
          params: query,
          paramsStr: qStr,
        });

        history.push({
          pathname: this.props.location.pathname,
          search: ``,
          state: {
            sort: '',
            page: 1,
            keyword: "",
            //filter: null,
          }
        });

      } else {
        // console.log("DataTable - filterHandler - this.state.filters ->", this.state.filters);

        if ((filterSearch || cacheSearch) && query.length > 0) {
          await this.setState({
            keyword: value.trim(),
            //filter: null,
            filters: {
              ...this.state.filters,
              keyword: value.trim(),
            },
            params: query,
            paramsStr: qStr,
          });

          history.push({
            pathname: this.props.location.pathname,
            search: decodeURI(`?${new URLSearchParams(qStr)}`), // search=
            state: {
              sort: '',
              page: 1,
              keyword: value.trim(),
              //filter: "",
            }
          });
        }
      }
    }
  }

  filterHandlerSubmit = async (e) => {
    if (e && e.key && e.key === "Enter") {
      const history = this.props.history;

      let paramsQueryString;
      if (this.props.location.search && this.props.location.search.charAt(0) === '?') {
        paramsQueryString = this.props.location.search.slice(1);
      }

      // console.log("DataTable - filterHandlerSubmit - this.props.location ->", this.props.location);
      // console.log("DataTable - filterHandlerSubmit - this.state.defaultSearchKeyword ->", this.state.defaultSearchKeyword);
      // console.log("DataTable - filterHandlerSubmit - this.state.keyword ->", this.state.keyword);
      // console.log("DataTable - filterHandlerSubmit - this.state.filters ->", this.state.filters);
      // console.log("DataTable - filterHandlerSubmit - this.state.params ->", this.state.params);

      if (this.state.defaultSearchKeyword && this.state.filters && this.state.filters.keyword === "") {
        await this.setState({
          filters: {
            ...this.state.filters,
            keyword: this.state.defaultSearchKeyword,
          }
        });
      }

      history.push({
        pathname: this.props.location.pathname,
        search: decodeURI(`?${new URLSearchParams(paramsQueryString ? paramsQueryString : this.state.paramsStr)}`), // search=
        state: {
          sort: '',
          page: 1,
          keyword: this.state.defaultSearchKeyword || this.state.keyword, // this.state.defaultSearchKeyword || keyword
          //filter: "",
        }
      });

      this.props.handleFilter(this.state.filters, this.state.params, this.state.paramsStr);
    }
  };

  getFilters(e) {
    e.preventDefault();
    this.props.handleFilter(this.state.filters);
  }

  clearFilters = async (e, props) => {
    if (e) {
      e.preventDefault();
    }

    props.onSearch("");

    const { rowAction, parent, history, table, sortItem, handleFilter, location, clearAllFilter } = this.props;

    let tableId = 'qStr' + this.props.table;
    let tableFilterId = 'qStr' + this.props.table + "Filter";
    let tableKeywordId = 'qStr' + this.props.table + "Keyword";
    let tableFilterStrId = 'qStr' + this.props.table + "FilterStr";
    let tableFilterObjId = 'qStr' + this.props.table + "FiltersObj";

    sessionStorage.removeItem(tableId);
    sessionStorage.removeItem(tableFilterId);
    sessionStorage.removeItem(tableKeywordId);
    sessionStorage.removeItem(tableFilterStrId);
    sessionStorage.removeItem(tableFilterObjId);

    let filtersObj = sessionStorage.getItem(tableFilterObjId);
    // console.log("DataTable - clearFilters - filtersObj ->", filtersObj);
    if (filtersObj) {
      let parsedFiltersObj = JSON.parse(filtersObj);
      if (parsedFiltersObj.filter && parsedFiltersObj.keyword) {
        let newTableFilterObj = {};
        newTableFilterObj.keyword = "";
        newTableFilterObj.filter = parsedFiltersObj.filter;
        this.setFilters(newTableFilterObj);
        sessionStorage.setItem(tableFilterObjId, JSON.stringify(newTableFilterObj));
      }
    }

    sessionStorage.setItem(tableKeywordId, "");

    let input = document.getElementById("table-search-form");
    input.value = '';

    let queryString;
    let params = {};
    if (this.props.location.search && this.props.location.search.charAt(0) === '?') {
      queryString = this.props.location.search.slice(1);
      if (queryString) {
        queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, val) => {
          if (key === 'page' || key === 'offset') { // || key === 'count') {
            params[key] = parseInt(val);
          } else if (key === 'filter') {
            params[key] = val;
          } else if (key === 'search') {
            params[key] = "";
          } else {
            params[key] = val;
          }
        });
      }
    }

    let newQueryStr = decodeURI(`?${new URLSearchParams(params)}`);

    history.replace({
      pathname: this.props.location.pathname,
      search: newQueryStr,
    });

    sessionStorage.setItem(tableKeywordId, "");
    handleFilter(this.state.filters, params, newQueryStr);
  };

  navigateToPath = (path, e, history) => {
    if (e.ctrlKey) {
      window.open(path, "_blank");
    } else {
      history.push(path);
    }
  };

  getParentPath = (parent, getPath) => {
    if (!parent && getPath.length > 3) {
      return getPath.slice(1, -1).join('/') + '/';
    }
    return '';
  };

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      const selection = window.getSelection().toString();
      if (selection.length <= 0) {
        e.stopPropagation();

        this.setState({ selectedRow: rowIndex });
        const { rowAction, parent, history, table } = this.props;
        const getPath = window.location.pathname.split('/');
        const parentPath = this.getParentPath(parent, getPath);

        if (rowAction === 'link') {
          if (['Subscriptions', 'Invoices', 'Relations'].includes(table)) {
            sessionStorage.removeItem('lastVisitSubscrId');
            sessionStorage.removeItem('lastVisitInvId');
          }

          let path;
          switch (table) {
            case 'PlansUser':
              path = `/plans/${row.id}/details`;
              break;
            case 'PlansAdmin':
              path = `/plans/${row.id}/details`;
              break;
            case 'Subscriptions':
              path = `/relations/${row.relation_id}/subscriptions/${row.id}`;
              break;
            case 'SubscriptionsLinesSummary':
              path = `/relations/${row.relation_id}/subscriptions/${row.subscription_id}`;
              break;
            case 'Invoices':
              path = `/relations/${row.relation_id}/invoices/${row.id}`;
              break;
            default:
              const formattedTable = table.replace(/\s+/g, '-').toLowerCase();
              path = `/${parentPath}${formattedTable}/${row.id}/details`;
          }
          this.navigateToPath(path, e, history);

        } else if (rowAction === 'edit') {
          this.props.toggleForm(e, row, rowIndex);
        }
      }
      return false;
    }
  };


  rowClasses = (row, rowIndex) => {
    if (rowIndex === this.state.selectedRow) {
      return 'selected-row'
    } else if (this.props.isNotes) {
      return 'notes-table-row grayed-block-1'
    }
  };

  rowStyle = (row) => {
    if (this.props.table === 'Subscriptions' && !isNaN(row.status)) {
      const bgColor = row.status === 2 ? 'rgb(244 68 85 / 10%)' : '';
      return { backgroundColor: bgColor }
    }
  };

  noDataFound = () => {
    const { loader, data, dispatch, table } = this.props;
    //console.log("noDataFound - loader ->", loader);
    //console.log("noDataFound - data ->", data);
    //console.log("noDataFound - dispatch ->", dispatch);
    //console.log("noDataFound - table ->", table);
    //console.log("noDataFound - this.props ->", this.props);

    // if 'No data found.';
    if (loader) {
      return <div className="loading dots-sm" style={{ lineHeight: "22px", height: "18px", verticalAlign: "middle" }}> </div>;
    } else {
      return 'No data found.';
    }
  };

  componentDidMount() {
    const { cacheSearch, filterSearch, noSearch, filters } = this.props;
    const tableId = 'qStr' + this.props.table;
    const tableSearchKeyword = 'qStr' + this.props.table + "Keyword";

    if (filterSearch && cacheSearch && tableSearchKeyword) {
      let searchedKeyword = sessionStorage.getItem(tableSearchKeyword);

      this.setState({ defaultSearchKeyword: searchedKeyword, keyword: searchedKeyword });

      let filters = Object.assign({});
      filters.keyword = searchedKeyword;
      this.setFilters(filters);

    } else if (this.props.location && this.props.location.search) {
      const searchParam = queryString.parse(this.props.location.search);

      this.setState({ defaultSearchKeyword: searchParam && searchParam.search ? searchParam.search : "" });

      let filters = Object.assign({});
      filters.keyword = searchParam && searchParam.search ? searchParam.search : "";
      filters.filter = searchParam && searchParam.filter ? searchParam.filter : {};
      this.setFilters(filters);
    }
  }

  setFilters(filters) {
    if (filters) {
      this.setState({
        filters: {
          ...this.state.filters,
          keyword: filters.keyword,
          filter: filters.filter,
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filters, enabledFilter, data, filterSearch, cacheSearch, collapseFilter } = this.props;

    if ((filterSearch || cacheSearch) && prevProps.filters !== filters) {
      this.setState({
        filters: {
          ...prevProps.filters,
          keyword: filters.keyword,
          filter: filters.filter,
        }
      });
    }

    if (prevProps.data !== data) {
      this.setState({ rowCount: null });
    }

    // for reloading filter(s)
    if (this.props.setIsLoadData) {
      this.setState({ isLoadData: false });
      this.props.setIsLoadData(false);
    }
  }

  handleDataChange = ({ dataSize }) => {
    this.setState({
      rowCount: dataSize,
      page: 1,
    });
  };

  render() {
    const {
      i18n,
      keyField,
      data,
      columns,
      page,
      sizePerPage,
      totalSize,
      tab,
      filters,
      filterSearch,
      bodyClasses,
      rowAction,
      headerDropdownOpts,
      onTableChange,
      noTopBorder,
      isNotes,
      headerWrapperClasses,
      noHover,
      headerCheckBox,
      wrapperClasses,
      noSearch,
      classes,
      table,
      expandRow,
      collapseFilter,
      basicSearch
    } = this.props;

    let hideSizePerPageDropDown = data.length < sizePerPage; 


    return (
      <React.Fragment>
        <ToolkitProvider
          keyField={keyField ? keyField : "id"}
          data={data}
          columns={columns}
          search
        >
          {
            toolkitprops => (
              <React.Fragment>
                {!isNotes && ((headerDropdownOpts && headerDropdownOpts.length > 0) || filters) &&
                  <div className={noTopBorder ? 'border-0 card-header card-header-primary' : ''}>
                    <Row className="align-items-center">
                      {basicSearch &&
                        <Col xs="6" sm="6" lg="6" md="auto" className='table-basic-search'>
                          <SearchBar {...toolkitprops.searchProps} />
                        </Col>
                      }
                      {filters && !noSearch && !basicSearch &&
                        <Col xs="6" sm="6" lg="6" md="auto">
                          <Form className="d-flex" onSubmit={(e) => this.getFilters(e)}>
                            <InputGroup className={`table-search-input`}>
                              {
                                Object.keys(filters).map((item, index) => {
                                  return item === 'keyword' ?
                                    <Input
                                      className={`table-search form-control form-control-md ${index >
                                        0 ? 'ml-1' : ''}`}
                                      id="table-search-form"
                                      type="text"
                                      name={item}
                                      defaultValue={this.state.defaultSearchKeyword}
                                      placeholder="Enter search term"
                                      onChange={(e) => this.filterHandler(e)}
                                      onKeyPress={(e) => this.filterHandlerSubmit(e)}
                                      key={index}
                                    /> : null
                                })}
                              <InputGroupAddon className="clear-btn clear-filter" addonType="append">
                                <a className="danger icon-delete" onClick={(e) => this.clearFilters(e, { ...toolkitprops.searchProps })}>
                                  <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
                                </a>
                              </InputGroupAddon>
                            </InputGroup>
                          </Form>
                        </Col>
                      }

                      {headerCheckBox &&
                        <Col>
                          <CustomInput
                            id="tableHeaderCheckbox"
                            type="checkbox"
                            name="tableHeaderCheckbox"
                            label={headerCheckBox.label}
                            onChange={(e) => headerCheckBox.function(e)}
                            disabled={headerCheckBox.disabled ? true : false}
                          />
                        </Col>
                      }

                      {headerDropdownOpts &&
                        <Col className="d-flex ml-auto header-opts" xs="6" sm="6" md="auto">
                          {headerDropdownOpts.map((opt, i) => {
                            return i < 3 ?
                              opt.tooltip ?
                                <OverlayTrigger
                                  key={`${opt.label}-${i}`}
                                  placement={`bottom-start`}
                                  transition={false}
                                  delay={200}
                                  overlay={
                                    <Tooltip id={`${opt.label}-id-${i}`}>
                                      {opt.label}
                                    </Tooltip>}
                                >
                                  <Button
                                    className={`btn btn text-truncate toggleHeaderActionBtn-${i} ${opt.toggleActive}`}
                                    id={`toggleHeaderActionBtn-${i}`}
                                    color="gray"
                                    onClick={(e) => this.props.toggleHeaderAction(opt, e, { ...toolkitprops.searchProps })}
                                    key={i}
                                    style={{ border: "1px solid #ced4da" }}
                                  >
                                    {opt.icon ? opt.icon : ""}
                                    {opt.label === "Add" ? <FontAwesomeIcon color="grey" icon={faPlus} /> : !opt.icon ? opt.label : ""}
                                  </Button>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                  key={`${opt.label}-${i}`}
                                  placement={`bottom-start`}
                                  transition={false}
                                  delay={200}
                                  overlay={
                                    <Tooltip id={`${opt.label}-id-${i}`}>
                                      {`Add`}
                                    </Tooltip>}
                                >
                                  <Button
                                    className={`btn btn text-truncate toggleHeaderActionBtn-${i}`}
                                    id={`toggleHeaderActionBtn-${i}`}
                                    color="gray"
                                    onClick={(e) => this.props.toggleHeaderAction(opt, e, { ...toolkitprops.searchProps })}
                                    key={i}
                                    style={{ border: "1px solid #ced4da" }}
                                  >
                                    {opt.icon ? opt.icon : ""}
                                    {opt.label === "Add" ? <FontAwesomeIcon color="grey" icon={faPlus} /> : !opt.icon ? opt.label : ""}
                                  </Button></OverlayTrigger> : null

                          })}
                          {headerDropdownOpts.length > 3 &&
                            <UncontrolledDropdown className="ml-auto">
                              <DropdownToggle nav className="px-3 py-1">
                                <MoreHorizontal size={18} />
                              </DropdownToggle>

                              <DropdownMenu right={true}>
                                {headerDropdownOpts.map((opt, i) => {
                                  return i > 2 ? <DropdownItem className="py-2*" onClick={(e) => this.props.toggleHeaderAction(opt, e, { ...this.props })} key={i}>{opt.label}</DropdownItem> : null
                                })}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          }
                        </Col>
                      }
                    </Row>
                  </div>
                }

                <Card className={'mt-1 mb-0 card-bordered-table'}>
                  <CardBody className={`action-${rowAction ? rowAction : ''}`}>
                    <BootstrapTable
                      {...toolkitprops.baseProps}
                      table={table}
                      tabIndexCell={true}
                      ref={el => (this.componentRef = el)}
                      id={table}
                      language={i18n && i18n.language ? i18n.language : 'nl'}
                      bootstrap4
                      sortMode="multiple"
                      hover={!noHover}
                      bordered={false}
                      wrapperClasses={`${table === 'Plan lines' ? ' table-plan-lines' : table === 'Activity logs' ? ' table-activity-logs' : ''}${wrapperClasses ? ` ${wrapperClasses}` : ''}${noHover ? ' no-hover' : ''}`}
                      remote={{
                        pagination: true,
                        filter: true,
                        sort: true,
                      }}
                      pagination={
                        paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          hidePageListOnlyOnePage: true,
                          hideSizePerPage: hideSizePerPageDropDown,
                          sizePerPageList: [10, 25, 50, 100, 200],
                          //onPageChange: this.handlePageChange
                        })
                      }
                      onDataSizeChange={this.handleDataChange}
                      onTableChange={onTableChange}
                      expandRow={expandRow}
                      rowEvents={rowAction ? this.rowEvents : null}
                      rowClasses={this.rowClasses}
                      headerWrapperClasses={headerWrapperClasses}
                      bodyClasses={bodyClasses}
                      noTopBorder={!!noTopBorder}
                      headerCheckBox={headerCheckBox ? headerCheckBox : null}
                      noHover={noHover}
                      notLoadedByDefault={false}
                      tab={tab}
                      loading={true}
                      noDataIndication={this.noDataFound}
                      //noDataIndication={() => <NoDataIndicationText loader={this.props.loader} />}
                      striped={true}
                      noSearch={noSearch}
                      classes={classes}
                      filters={filters ? filters : null}
                      filter={filterFactory()}
                      filterPosition="top"
                      filtersClasses={`table-filter-filters ${collapseFilter}`}
                    />
                  </CardBody>
                </Card>
              </React.Fragment>
            )
          }
        </ToolkitProvider>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ user, loader, keyword, dispatch }) => ({ user, loader, keyword, dispatch });

export default withRouter(withTranslation()(connect(mapStateToProps)(DataTable)));
