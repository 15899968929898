import React, {useState, useRef} from 'react';
import {connect} from "react-redux";
import {
  Button,
  Input,
} from 'reactstrap';
import {
  UpdateProductHierarchy,
} from '../../../controllers/products';
import {OverlayTrigger, Tooltip, Dropdown} from 'react-bootstrap';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate, validateBarcode } from "../../../redux/actions/validateActions";
import {
  formatNumber,
  parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import { Collapse } from "react-bootstrap";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import {
  PlusSquare,
  MinusSquare,
  MoreHorizontal,
  Trash2, CornerDownRight,
} from 'react-feather';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilter,
  faTrash,
  faSortAmountDown,
  faSortAmountUp,
  faSlidersH,
} from '@fortawesome/free-solid-svg-icons';
//import AsyncSelect from 'react-select';
import Swal from "sweetalert2";
import ReactJson from 'react-json-view';
import AddChildRelatedProduct from './AddChild';

const JsonDataRelatedProduct = ({ ...props }) => {
  const {
    productId, // main product id
    index,
    level,
    dispatch,
    loader,
    hide,
    update,
    debug,
    jsonData,
    itemRelatedProductId,
    subItemRelatedProductId,
    relationType,
    updateProductHierarchyRelation,
    jsonDataFormCollapse,
    updateRelatedChildProducts,
    setAddFormCollapse,
    setJsonDataFormCollapse,
    setJsonDataChildFormCollapse,
  } = props;

  const jsonDataRelatedProductRef = useRef(null);
  const collapsibleJsonDataChildId = subItemRelatedProductId ? "dropdown-item-json-data-" + productId + "-" + itemRelatedProductId +  "-" + subItemRelatedProductId + "-" + index : "dropdown-item-json-data-" + productId + "-" + itemRelatedProductId + "-" + index;
  const jsonDataChildFormId = subItemRelatedProductId ? productId + "_" + itemRelatedProductId + "_" + subItemRelatedProductId + "_" + index : productId + "_" + itemRelatedProductId + "_" + index;

  const [relationTextValue, setRelationTextValue] = useState(jsonData ? jsonData : "");
  const [jsonDataFormCollapsed, setJsonDataFormCollapsed] = useState(false);

  const isValidJSONString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleTextChange = (e) => {
    setRelationTextValue(e.target.value);
  };

  const updateJsonDataProductHierarchy = (e) => {
    let notificationsToastArr = [];
    let valid_selected_product_json_data;

    // check json input - relationTextValue
    if (relationTextValue && relationTextValue !== "") {
      if (isValidJSONString(relationTextValue)) {
        //cool we are valid, lets parse
        valid_selected_product_json_data = JSON.parse(relationTextValue);
      } else {
        notificationsToastArr.push(
            {title: 'JSON data is invalid.', icon: 'warn'});
      }
    }
    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    let productRelations = Object.assign({});
    productRelations.id = subItemRelatedProductId ? itemRelatedProductId : productId; // parent is the active product ID (activeProductId)
    productRelations.related_product_id = subItemRelatedProductId ? subItemRelatedProductId : itemRelatedProductId;
    productRelations.json_data = valid_selected_product_json_data ? valid_selected_product_json_data : "";

    if (!loader) {
      dispatch(loaderToggle(true));
      (async () => {
        if (productRelations.id && productRelations.related_product_id) {
          let res = await UpdateProductHierarchy(productRelations.id, productRelations.related_product_id, productRelations);
          if (res && res.ok) {
            await updateRelatedChildProducts();

            setRelationTextValue(productRelations.json_data);

            notificationsToastArr.push({
              title: res && res.data && res.data.message ? res.data.message : "Product hierarchy updated!",
              icon: "success",
            });
          } else {
            notificationsToastArr.push({
              title: "Error saving data",
              icon: "error",
            });
          }
          await handleOk(notificationsToastArr);

        }
      })();
    }
  };

  const handleOk = async (options) => {
    dispatch(loaderToggle(false));

    const { update } = props;

    if (options && options.length > 0) {
      update();
      return ToastMulti(options, "container-validation-id");
    }
    return false;
  };

  const getFormCollapseState = (formId) => {
    if (jsonDataFormCollapse && jsonDataFormCollapse.length > 0) {
      const formItem = jsonDataFormCollapse.find(
          item => item.collapsibleJsonDataChildId === formId);

      if (formItem) {
        return formItem.open;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setCollapseItems = () => {
    setJsonDataChildFormCollapse([]);
    setJsonDataFormCollapse([]);
  };

  return (
      <React.Fragment>
        { collapsibleJsonDataChildId &&
        <div className="product-hierarchy" ref={jsonDataRelatedProductRef} id={jsonDataChildFormId}>
          <Collapse in={getFormCollapseState(collapsibleJsonDataChildId)}>
            <div className="form-group row mt-1 mb-2 form-hierarchy">
              <div className="add-relation-hierarchy-wrapper shadow">
                <span style={{ display: debug, fontSize: "x-small" }}>
                  Form for ProductID {productId} |
                  ItemID {itemRelatedProductId} | SubItemID {subItemRelatedProductId ? subItemRelatedProductId : "Not set"} |
                  FormID = {jsonDataChildFormId}
                </span>
                <div className="form-group mb-0">
                  <Input
                      type="textarea"
                      id={`new_product_json_data-${jsonDataChildFormId}`}
                      name={`new_product_json_data-${jsonDataChildFormId}`}
                      placeholder={`Add JSON data`}
                      value={relationTextValue ? relationTextValue : ""}
                      onChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div className="form-group mb-0 d-flex justify-content-between">
                  <Button
                      type="button"
                      color="danger"
                      className="mt-1 cancel-json-data-btn btn btn-default btn-sm"
                      onClick={(e) => setCollapseItems()}
                  >
                    Cancel
                  </Button>
                  <Button
                      className="mt-1 submit-json-data-btn btn btn-default btn-sm"
                      onClick={(e) => updateJsonDataProductHierarchy(e)}
                  >
                    Update
                  </Button>
                </div>
              </div>
              <div className="icon-wrapper-children">
                <CornerDownRight
                    color={`grey`}
                    size="18"
                    className="hover-pointer mt-0 mb-1 ml-2 product-border-list-plus"
                />
              </div>
            </div>
          </Collapse>
        </div>}
      </React.Fragment>
  )
};

const mapStateToProps = ({ dispatch, loader }) => ({ dispatch, loader });
export default connect(mapStateToProps)(JsonDataRelatedProduct);
