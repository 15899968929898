import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  useHistory, useLocation, useParams, useRouteMatch, withRouter,
} from 'react-router-dom';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { mvLoaderToggle } from '../../../../redux/actions/mvLoaderActions';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import Toast from '../../../../components/Toast';
import { simulateTabClick } from "../../../../hooks/useSimulateTabClick";
import {
  hideSidebarMenuSubscriptions,
  toggleSidebarMenuSubscriptions,
} from '../../../../redux/actions/sidebarMenuActions';
import { createSelector } from 'reselect';

const SubscriptionList = ({
  data,
  subscrId,
  relationId,
  setData,
  selectedSubscr,
  setSelectedSubscr,
  updateDetails,
  isUpdated,
  subscriptionListUpdatedDetails,
  subscriptionList,
  subscriptionStatuses,
  mvloader,
  setToggleEdit,
  subscription,
  relation,
  dispatch,
  solrSearch,
  listFilterText,
  ...props
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const params = useParams();
  const { param } = params;

  console.log("listFilterText ->", listFilterText);

  const [locationKeysLoaded, setLocationKeysLoaded] = useState(true);
  const [active, setActive] = useState(selectedSubscr ? parseInt(selectedSubscr) : null);
  const [activeOnly, setActiveOnly] = useState(false);
  const [debugInfo, setDebugInfo] = useState(false);

  const relation_id = relation && relation.id ? relation.id : null;

  let lastVisitSubscrId = sessionStorage.getItem('lastVisitSubscrId');

  const setActivatedItem = (id) => {
    sessionStorage.removeItem('lastVisitSubscrId');
    dispatch({ type: 'SUBSCRIPTION', payload: null });

    sessionStorage.setItem('lastVisitSubscrId', id);

    dispatch(mvLoaderToggle(true));
    dispatch(hideSidebarMenuSubscriptions());

    if (selectedSubscr === id) {
      dispatch(mvLoaderToggle(false));
      return false;
    }
    setToggleEdit(false);

    setSelectedSubscr(id);
    setActive(id);
    dispatch(mvLoaderToggle(false));
  };

  useEffect(() => {
    console.log("SubscriptionListDetails (useEffect) - SubscriptionList - (dataLoaded) - isUpdated ->", isUpdated);
    console.log("SubscriptionListDetails (useEffect) - SubscriptionList - (dataLoaded) - props ->", props);
    console.log("SubscriptionListDetails (useEffect) - SubscriptionList - (dataLoaded) - data ->", data);
  }, [isUpdated]);

  useEffect(() => {
    if (selectedSubscr && relation && relation.subscriptionList && relation.subscriptionList.length > 0 && relation.subscriptionList.some(i => i.id === parseInt(selectedSubscr))) {
      setData(relation.subscriptionList);
      setActive(selectedSubscr);
      setToggleEdit(false);
    }
  }, [selectedSubscr]);

  useEffect(() => {
    const dataObj = data && data[0];

    history.push(`/relations/${relationId}/subscriptions/${lastVisitSubscrId
      ? lastVisitSubscrId + '/'
      : selectedSubscr ? selectedSubscr + '/' : (dataObj
        ? dataObj.id + '/'
        : '')}`);

  }, [active]);

  useEffect(() => {
    let mounted = true;
    return history.listen(location => {
      const locationPathName = location.pathname;
      let invItem, classId;

      if (location && location.pathname) {
        invItem = location.pathname.split("/").pop();
        classId = location.pathname.split("/").reverse()[1];
      }

      if (history.action === 'POP') {
        if (invItem && invItem === 'subscriptions' && classId) {
          let itemClassId = 'subscr-list-action-item-' + classId;
          let el = document.getElementById('f2x-ls-det-subscriptions').
            getElementsByClassName(itemClassId)[0];
          if (el) {
            simulateTabClick(el).
              then(r => {
                if (mounted) {
                  setLocationKeysLoaded(false);
                }

              });
          } else {
            console.log('Subscription could not be found with this combination of relation & invoice');
          }
        }
      } else {
        mounted = false;
      }
    });
  }, [locationKeysLoaded]);

  const ItemFormatter = ({ id, description, status, subscription_start, subscription_stop, price_excl_vat, price_incl_vat, isUpdated }) => {
    let subscription_status = subscriptionStatuses && subscriptionStatuses.filter((item) => parseInt(item.value) === parseInt(status))[0];

    return (<>
      <Row className="mb-1">
        <Col className="mv-text-trunc-35 mv-text-bold text-truncate">{description}</Col>
      </Row>
      <Row>
        <Col xs="5" className="text-truncate text-uppercase">
          {subscription_status && subscription_status.label ? subscription_status.label : ""}
        </Col>
        {price_excl_vat && price_excl_vat > 0 && price_incl_vat && price_incl_vat > 0
          ?
          <Col className="text-right">
            {t("currency_format", { number: Number(parseFloat(price_excl_vat)) })} / {t("currency_format", { number: Number(parseFloat(price_incl_vat)) })}
            {id && debugInfo === true ? <span style={{ display: "block" }}>#{id}<br />#{relation_id}</span> : ''}
          </Col>
          :
          <Col className="text-right">
            {t("currency_format", { number: parseFloat(price_excl_vat) === 0 || !price_excl_vat ? 0 : parseFloat(price_excl_vat) })} / {t("currency_format", { number: parseFloat(price_incl_vat) === 0 || !price_incl_vat ? 0 : parseFloat(price_incl_vat) })}
            {id && debugInfo === true ? <span style={{ display: "block" }}>{id}<br />#{relation_id}</span> : ''}
          </Col>
        }
      </Row>
      <Row>
        <Col className="col-auto">{t("date_format", { date: new Date(subscription_start) })}</Col>
        <Col className="text-right col-auto">
          {subscription_stop ? t("date_format", { date: new Date(subscription_stop) }) : ""}
        </Col>
      </Row>
    </>)
  };

  const focusItem = (el_id) => {
    let focusItem = document.getElementById(`subscr-list-item-${el_id}`);
    if (focusItem) {
      focusItem.focus();
    }
  }

  const listFormatter = (item, isActive, index) => {
    if (isActive) {
      return (
        <ListGroupItem
          key={item.id} id={`subscr-list-item-${item.id}`} tabIndex="-1" 
          className={`${isActive || item.id === parseInt(active) ? 'active' : ''} ${mvloader ? 'disabled' : ''} subscr-list-action-item-${item.id}`}
          action
        >
          <ItemFormatter {...item} isUpdated={isUpdated} data-focus={focusItem(item.id)} />
        </ListGroupItem>)
    } else {
      return (<ListGroupItem key={item.id} className={`subscr-list-action-item-${item.id}`} id={`subscr-list-item-${item.id}`} tabIndex="-1"
                             action onClick={(e) => setActivatedItem(item.id)}>
        <ItemFormatter {...item} isUpdated={isUpdated} />
      </ListGroupItem>)
    }
  };

  if (data.length > 0) {
    return (
      <>
        <ScrollBar className="f2x-ls-det-sb sb-invoices" options={{ suppressScrollX: true }}>
          <ListGroup className="f2x-ls-det" id="f2x-ls-det-subscriptions">
            {data && data.length > 0 && data.filter((f) => {
              const filterTextUpper = listFilterText.toUpperCase();
              const descriptionMatches = f.description && f.description.toUpperCase().includes(filterTextUpper);
              const subscriptionStartatches = f.subscription_start && f.subscription_start.includes(listFilterText);
              return descriptionMatches || subscriptionStartatches || listFilterText === '';
            }).map((r, i) => {
              const isActive = selectedSubscr
                ? parseInt(selectedSubscr) === r.id
                : i === 0;
              return listFormatter(r, isActive, i)
            })
            }
          </ListGroup>
        </ScrollBar>
      </>
    )
  } else {
    return (<div> </div>)
  }
};

const sidebarMenuSelector = createSelector(
  state => state.sidebarMenu,
  sidebarMenu => sidebarMenu
);

const mapStateToProps = ({ solrSearch, sidebarMenu, mvloader, dispatch, subscription, relation }) => ({ solrSearch, sidebarMenu, mvloader, dispatch, subscription, relation });

export default withRouter(connect(mapStateToProps)(SubscriptionList));
