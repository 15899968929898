import React, { useState, useEffect } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';

const CustomFilterInput = ({
    column,
    onFilter,
    handleFiltering,
    setClearedItemName,
    isClearFilter,
    value,
    enabledFilter,
    setEnabledFilter,
    table,
    customId = false,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputFiltering = (e) => {
    if (e && e.key && e.key === "Enter") {
      //console.log("CustomFilterInput - handleInputFiltering - e ->", e);
      handleFiltering(e, column.dataField);
    }
  };

  const clearInputField = (e, column) => {
    e.preventDefault();
    setInputValue("");
    setEnabledFilter(true);
    setClearedItemName(column);
    handleFiltering(e, column, false);
  };

  const getStorageValue = () => {
    let name = column.dataField;
    let storedValue;
    let tableStorageFilterObjId = "qStr"+table+"FiltersObj";
    let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
    if (filtersObj) {
      let filtersObjParsed = JSON.parse(filtersObj);
      if (filtersObjParsed && filtersObjParsed.filter &&
          filtersObjParsed.filter.length > 0)
      {
        for (let i = 0; i < filtersObjParsed.filter.length; i++) {
          if (filtersObjParsed.filter[i] &&
              filtersObjParsed.filter[i][name])
          {
              storedValue = filtersObjParsed.filter[i][name];
              return storedValue;
          }
        }
      } else {
        if (filtersObjParsed && filtersObjParsed.filter) {
          let key;
          for (key in filtersObjParsed.filter) {
            if (filtersObjParsed.filter.hasOwnProperty(key) && key === name) {
              storedValue = filtersObjParsed.filter[name];
              return storedValue;
            }
          }
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (!enabledFilter && value && value !== "") {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isClearFilter === true) {
      setInputValue("");
    }
  }, [isClearFilter]);

  useEffect(() => {
    let storedValue = getStorageValue();
    if (storedValue) {
      setInputValue(storedValue);
    }
  }, []);

  return [
      <div key={`input-${column.dataField}`} style={{display: 'flex', flexDirection: 'column'}} className={`custom-filter-item`}>
        <InputGroup className={`custom-finance-select mt-0`}>
          <Input type="text"
                 className="f2x-fin-h-text mt-0"
                 name={column.dataField}
                 id={`id-input-${column.dataField}`}
                 key={`key-input-${column.dataField}`}
                 value={inputValue}
                 placeholder={column.filter && column.filter.props && column.filter.props.placeholder ? column.filter.props.placeholder : 'Search...'}
                 onKeyPress={handleInputFiltering} // => {(e) => handleFiltering(e, column.dataField)} -> for live searching when typing
                 onChange={handleInputChange}
          />
          <InputGroupAddon className="clear-btn clear-filter" addonType="append">
            <a href="/#" className="danger icon-delete" onClick={(e) => clearInputField(e, column.dataField)}>
              <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
            </a>
          </InputGroupAddon>
        </InputGroup>
      </div>
  ];
};

export default CustomFilterInput;
