import ApiService from '../services/apiService';

const Api = new ApiService(),
    GetAPI = Api.getAPI;

const asyncFunc = (endpoint, params) => {
    return (async () => {
        return await GetAPI(endpoint, params)
    })();
};

const GetLogActivities = (params) => {
    return asyncFunc(`activity_logs`, params)
};

const GetLogActivitiesRecent = (params) => {
    return asyncFunc(`activity_logs/recent`, params)
};

const GetLogActivitiesChart = () => {
    return asyncFunc(`activity_logs/recent`)
};

export {
    GetLogActivities,
    GetLogActivitiesChart,
    GetLogActivitiesRecent
}