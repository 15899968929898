import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Input,
  Card,
  Container,
  InputGroup, InputGroupAddon,
} from 'reactstrap';
import {
  Popover,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import AvCheckerForm from '../../components/AvCheckerForm';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {withTranslation, useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import Select from 'react-select';
import CONSTANTS from '../../services/constants';
import ApiService from '../../services/apiService';
import {loaderToggle} from '../../redux/actions/loaderActions';
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';

const Api    = new ApiService(),
    GetAPI = Api.getAPI;

const AvCheck = ({ ...props }) => {
  let history = useHistory();
  const {t} = useTranslation();

  const [currentStep, setCurrentStep] = useState(1);

  const activateStep = (step) => {
    console.log("AvCheck - set activeStep to ->", step);
    setCurrentStep(step);
  };

  useEffect(() => {
    console.log("AvCheck - activeStep ->", currentStep);
  }, [currentStep]);

  const renderSteps = () => {
    return (
      <div className="d-none d-md-block steps-full">
        <div className="progress-bar-container">
          <div className="steps-container">
            <div className={`step-box${currentStep === 1 ? " active-step" : currentStep > 1 ? " done-step" : ""}`}>
              <div className="step-title">{t("offers.steps_title_1", null)}</div>
              <div className="step-number-box">
                <div className={`step-progress-line step-progress-line-right${currentStep > 1 ? " step-progress-line-done" : ""}`}> </div>
                <div className="step-number">
                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      {t("offers.steps_title_1", null)}
                    </Tooltip>}
                  >
                    <span>1</span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className={`step-box${currentStep === 2 ? " active-step" : currentStep > 2 ? " done-step" : ""}`}>
              <div className="step-title">{t("offers.steps_title_2", null)}</div>
              <div className="step-number-box">
                <div className={`step-progress-line step-progress-line-left${currentStep >= 2 ? " step-progress-line-done" : ""}`}> </div>
                <div className={`step-progress-line step-progress-line-right${currentStep > 2 ? " step-progress-line-done" : ""}`}> </div>
                <div className="step-number">
                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      {t("offers.steps_title_2", null)}
                    </Tooltip>}
                  >
                    <span>2</span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className={`step-box${currentStep === 3 ? " active-step" : currentStep > 3 ? " done-step" : ""}`}>
              <div className="step-title">{t("offers.steps_title_3", null)}</div>
              <div className="step-number-box">
                <div className={`step-progress-line step-progress-line-left${currentStep >= 3 ? " step-progress-line-done" : ""}`}> </div>
                <div className={`step-progress-line step-progress-line-right${currentStep > 3 ? " step-progress-line-done" : ""}`}> </div>
                <div className="step-number">
                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      {t("offers.steps_title_3", null)}
                    </Tooltip>}
                  >
                    <span>3</span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className={`step-box${currentStep === 4 ? " active-step" : currentStep > 4 ? " done-step" : ""}`}>
              <div className="step-title">{t("offers.steps_title_4", null)}</div>
              <div className="step-number-box">
                <div className={`step-progress-line step-progress-line-left${currentStep >= 4 ? " step-progress-line-done" : ""}`}> </div>
                <div className={`step-progress-line step-progress-line-right${currentStep > 4 ? " step-progress-line-done" : ""}`}> </div>
                <div className="step-number">
                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      {t("offers.steps_title_4", null)}
                    </Tooltip>}
                  >
                    <span>4</span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className={`step-box${currentStep === 5 ? " active-step" : ""}`}>
              <div className="step-title">{t("offers.steps_title_5", null)}</div>
              <div className="step-number-box">
                <div className={`step-progress-line step-progress-line-left${currentStep === 5 ? " step-progress-line-done" : ""}`}> </div>
                <div className="step-number">
                  <OverlayTrigger placement={`bottom`} transition={false} overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      {t("offers.steps_title_5", null)}
                    </Tooltip>}
                  >
                    <span>5</span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      <div className="row" style={{ paddingBottom: "4px" }}>
        <div className="col">
          <h1 className="text-bold" style={{ paddingBottom: 0, borderBottom: "none", marginBottom: 0 }}>{t("offers.h1_title", null)}</h1>
        </div>
        <div className="col">
          {renderSteps(1)}
        </div>
      </div>
      <div style={{ borderBottom: "1px solid #dee2e6", height: "1px" }} className="mb-3"> </div>
      <AvCheckerForm
        setActiveStep={activateStep}
        showJsonOutput={true}
      />
    </>
  );
};

const mapStateToProps = ({ tenants, user, dispatch }) => ({ tenants, user, dispatch });

export default withRouter(withTranslation()(connect(mapStateToProps)(AvCheck)));
