import { applyMiddleware, compose, createStore } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "../reducers/index";
import thunk from 'redux-thunk';

let enhancers = compose( applyMiddleware(thunk) );

if (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) {
  enhancers = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
          serialize: true,
          trace: true,
          traceLimit: 25
        })
  )
}

const store = createStore(
  rootReducer,
  enhancers
);

export default store;
