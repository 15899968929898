import React, {useEffect, useState} from "react";
import {ToastContainer, toast as toastNotifyMulti} from "react-toastify";
import {XCircle, Info, HelpCircle, CheckCircle} from "react-feather";
import {truncate} from "lodash";

// ToastMulti - show multiple alerts
function ToastMulti(data, container_id) {
  console.log("ToastMulti - data ->", data);

  return (
      <div>
        {data && data.length > 0 &&
        data.map((item) => {
          switch (item.icon) {
            case "success":
              toastNotifyMulti.success(item.title, {
                icon: <CheckCircle />,
              });
              break;
            case "error":
              toastNotifyMulti.error(item.title, {
                icon: <XCircle />,
              });
              break;
            case "warn":
              toastNotifyMulti.warning(item.title, {
                icon: <XCircle />,
              });
              break;
            case "warning":
              toastNotifyMulti.warning(item.title, {
                icon: <XCircle />,
              });
              break;
            case "none":
              toastNotifyMulti.loading(item.title, {
                icon: "",
                closeButton: true,
                closeOnClick: true,
                newestOnTop: true
              });
              break;
            default:
              toastNotifyMulti.info(item.title, {
                icon: <Info />,
              });
              break;
          }
        })}
        <ToastContainer
          containerId={container_id}
          enableMultiContainer
          position={toastNotifyMulti.POSITION.TOP_RIGHT}
        />
      </div>
  );
}

export default ToastMulti;
