import ApiService from "../services/apiService";

const Api = new ApiService(),
    GetAPI = Api.getAPI,
    PostAPI = Api.postAPI,
    UpdateAPI = Api.updateAPI

const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        let data = await method ? method(endpoint, params) : GetAPI(endpoint, params)
        return data
    })()
}

const GetMyFiscalYears = (params, id) => {
    return asyncFunc(`/myfiscalyears/${id}`, params)
}

const GetFiscalYear = (params, id) => {
    return asyncFunc(`/fiscal_years/${id}`, params)
}

const AddFiscalYear = (params) => {
    return asyncFunc(`/fiscal_years`, params, PostAPI)
}

const UpdateFiscalYear = (id, params) => {
    return asyncFunc(`/fiscal_years/${id}`, params, UpdateAPI)
}

export {
    GetMyFiscalYears,
    GetFiscalYear,
    AddFiscalYear,
    UpdateFiscalYear
}
