import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col, Card, Input } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import Toast from "../../components/Toast";
import moment from 'moment'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import { loaderToggle } from '../../redux/actions/loaderActions'
import CONSTANTS from '../../services/constants'
import { GetOrders } from '../../controllers/orders'
import CustomDataTable from '../../components/CustomDataTable'
import ParentTable from "../../components/ParentTable";
import {stringFilter} from "../../services/misc";
import {getTableObj} from "../../services/tableService";
import queryString from "query-string";

const regexp = /\bdropdown|\bsvg|\bdropdown-table-actions/gmi;

const Orders = ({dispatch}) => {
  let history = useHistory();

  const [pageNum, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(15);
  const [totalSize, setTotalSize] = useState(0);
  const [sort, setSort] = useState(0);
  const [date, setDate] = useState('');
  const [customer, setCustomer] = useState('');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [data, setData] = useState([]);
  //const [keyword, setKeyword] = useState(queryString.parse(history.location.search).searchKey);
  const [isLoadData, setLoadData] = useState(true);
  //const {searchKey} = queryString.parse(history.location.search);

  // handlers
  const handleFiltering = (e) => {
    if (e.key === "Enter") {
      const value = e.target && e.target.value;
      switch (e.target.name) {
        case 'customer_name':
          setCustomer(value);
          break;
        case 'address1':
          setAddress(value);
          break;
        default:
          break;
      }
    }
  };

  // action col
  const colActionFormatter = (cell, row) => {
    const isActionProcessed = row.status.name === "Processed";
    const pr = isActionProcessed ? 'pr-0' : '';
    return (
        <Row>
          {!isActionProcessed ?
              <Col className="pr-0 col-md-6">
                <div className="f2x-act-btn" onClick={(e) => handleAddCustomerSubscription(e, row)}>Add C+S</div>
              </Col>
              :
              null
          }
        </Row>
    )
  };

  const handleAddCustomerSubscription = (e, row) => {
    e.preventDefault();
    history.push(`/relations/new?order_id=${row.id}`)
  };

  const handleDateInputKeyDown = (e) => {
    if (e.key === 'Backspace') {
      e.target.value = '';
      setDate(null)
    }
    e.preventDefault()
  };

  // Table formatters
  const headerInputFormatter = (column) =>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className="ord-col-txt">{column.text}</span>
        <Input type="text" className="f2x-fin-h-text" name={column.dataField} onKeyPress={(e) => handleFiltering(e) }/>
      </div>;

  const headerSelectFormatter = (column) =>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className="ord-col-txt">{column.text}</span>
        <Select
            className="react-select-container custom-finance-select"
            classNamePrefix="react-select"
            id="orderStatus"
            placeholder="Select..."
            options={CONSTANTS.ORDERS_STATUSES}
            value={status}
            onChange={(data) => setStatus(data.value)}
        />
      </div>;

  const headerDateFormatter = (column) =>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className="ord-col-txt">{column.text}</span>
        <DatePicker
            className="form-control f2x-h-text"
            name="date"
            dateFormat="dd-MM-yyyy"
            autoComplete="off"
            placeholderText=""
            selected={date ? moment(date, 'DD-MM-YYYY')._d : null}
            onChange={(date) =>{ setDate(date ? moment(date).format('DD-MM-YYYY') : null ) }}
            maxDate={moment().toDate()}
            onKeyDown={e => handleDateInputKeyDown(e)}
        />
      </div>;

  // address formatter
  const addressFormatter = (cell, row) => {
    const {address1, address2} = row;

    return address1 || address2 ?
        <>
          <div>{address1}</div>
          <div>{address2}</div>
        </>
        :
        <span className="null-value">{String.fromCharCode(8212)}</span>
  };

  // status col //${invoiceStatusObj(cell).styleClass}
  const colStatusFormatter = (cell) => {
    const {name} = cell;
    return (
        <div className={`f2x-ord-custom-status ${name.toLowerCase()}`}>
          {name}
        </div>
    )
  };

  const columns = [{
    dataField: "date",
    text: 'Date',
    //filter: textFilter(),
    headerFormatter: headerDateFormatter,
  }, {
    dataField: "customer_name",
    text: 'Customer name',
    //filter: textFilter(),
    headerFormatter: headerInputFormatter,
  }, {
    dataField: "address1",
    text: 'Address',
    //filter: textFilter(),
    headerFormatter: headerInputFormatter,
    formatter: addressFormatter,
  }, {
    dataField: "package",
    text: 'Package',
  }, {
    dataField: "status",
    text: 'Status',
    //filter: textFilter(),
    headerFormatter: headerSelectFormatter,
    formatter: colStatusFormatter,
    headerStyle: () => {
      return { width: '15%'};
    }
  }, {
    dataField: "action",
    text: '',
    formatter: colActionFormatter,
    headerStyle: () => {
      return { width: '10%'};
    }
  }
  ];

  // indicates if table data will be shown; also, if user searched empty value on keypress;
  // this is another checking that the searchText is not explicitly set to reserved/special char `*`
  const loadData = (isLoad) => {
    console.log('load data invoice list');
    console.log(isLoad);
    console.log(isLoadData);
    console.log(pageNum);
    console.log(sort);

    if (!isLoadData || !pageNum) {
      setLoadData(isLoad)
    }
    setPage(pageNum ? pageNum : 1);
    setSort(sort ? sort : 1);

    let search = '';

    //queryString.parse(history.location.search);
    let qs = queryString.parse(history.location.search);
    console.log('queryString', qs);

    // cache search - default: search: `?${new URLSearchParams({searchKey: ''}).toString()}`
    history.push({
      search: '?search='+search,
      page: '&page='+pageNum+'&offset='+sizePerPage,
      //sort: '&sort='+sort
    });
    //setKeyword('')
  };

  const addParameterToURL = (param) => {

  }

  const handleTableChange = (type, { page, sizePerPage, sort }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setSort(sort);
  };

  useEffect(() => {
    // ?page=2&offset=15&sort=-description


    const param = {
      pageNum,
      offset: sizePerPage,
      'filter[customer]': customer,
      'filter[address]': address,
      'filter[date]': date,
      'filter[status]': status,
      'sort': '-date'
    };

    GetOrders(param)
        .then(r => {
          if (r.data) {
            const { data, total } = r.data;
              setData(data);
              setTotalSize(total)
          }
        })
        .catch(error => {
          console.log('Error fetching data: ', error)
        })
  }, [status, customer, date, address, pageNum, sizePerPage, sort]);

  return (
      <>
        <h1 className="text-bold mb-3">Orders</h1>
        <Card className="card-table-padding p-3">
          <CustomDataTable
              classes="custom-tbl-orders"
              data={data}
              keyField="id"
              remote
              search={false}
              pagination={true}
              columns={columns}
              filter={filterFactory()}
              onTableChange={handleTableChange}
              isLoadData={loadData}
              page={pageNum}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              hover
          />
        </Card>
      </>
  )
};

const mapStateToProps = ({ user }) => ({ user });

export default withRouter(connect(mapStateToProps)(Orders))
