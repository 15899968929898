import React from "react";
import { connect } from "react-redux";
import { createSelector } from 'reselect';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { UpdateUser } from "../../../controllers/users";
import { loaderToggle } from "../../../redux/actions/loaderActions";
import Toast from "../../../components/Toast";
import { isInvalidChars } from '../../../services/misc'

class Security extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      user: {
        password: '',
        password_confirmation: '',
        current_password: '',
        isCurrPasswordError: false,
      },
      isShowCurrPassword: false,
      isShowNewPassword: false,
    }
  }

  toggleCurrentPassword = () => {
    this.setState({isShowCurrPassword: !this.state.isShowCurrPassword})
  };

  toggleNewPassword = () => {
    this.setState({isShowNewPassword: !this.state.isShowNewPassword})
  };

  handleInputChange({ target: { name, value } }) {
    let user = Object.assign({}, this.state.user);
    user[name] = value;

    if(name === 'password') {
      user['isPasswordValid'] = !isInvalidChars(value);
      user['isPasswordValidlength'] = value.length >= 12
    }
    this.setState({ user })
  }

  handleSubmit(e) {    
    e.preventDefault();

    const { dispatch, loader } = this.props,
          user = Object.assign({}, this.state.user),
          { password, password_confirmation, isPasswordValid, isPasswordValidlength, current_password } = user;

    if (!current_password) {
      this.setState({user:{...user, isCurrPasswordError: true}});
      return Toast.fire({ title: 'Current password field is required.', icon: 'warning' })
    } else {
      this.setState({user:{...user, isCurrPasswordError: false}})
    }
    
    if (password && !isPasswordValidlength) {
      return Toast.fire({ title: 'The new password is too short. Please use at least 12 characters.', icon: 'warning' })
    }

    if (password && !isPasswordValid) {
      return Toast.fire({ title: 'The password is not strong enough. The password must contain at least 1 capital letter (A-Z), 1 lowercase letter (a-z), 1 digit (0-9) and 1 special character (! @ # $ % ^ & * ?)', icon: 'warning' })
    }

    if (password !== password_confirmation) {
      user['isPasswordConfirmValid'] = false;
      this.setState({ user });
      return Toast.fire({ title: 'Passwords do not match!', icon: 'error' })
    } else {
      user['isPasswordConfirmValid'] = true;
      this.setState({ user })
    }
    
    if (!loader) {
      dispatch(loaderToggle(true));

      (async () => {        
        const { ok, data } = await UpdateUser(user, this.state.id);

        dispatch(loaderToggle(false));

        if (ok) {
          Toast.fire({ title: 'Info has been updated!', icon: 'success' });
          let user = Object.assign({}, this.state.user);
          user.current_password = '';
          user.password = '';
          user.password_confirmation = '';
          user.isCurrPasswordError = false;
          this.setState({ user })
        } else {
          if (data)
            Toast.fire({ title: data.message, icon: 'error' })
        }
      })();
    }
  }

  componentDidMount() {
    const { id, username } = this.props.user;
    let user = Object.assign({}, this.state.user);
    user.username = username;
    
    this.setState({ 
      id, 
      user 
    })
  }

  render() {
    const { isShowCurrPassword, isShowNewPassword } = this.state;
    const { password, password_confirmation, current_password, isPasswordValid, isPasswordConfirmValid, isCurrPasswordError } = this.state.user;
    
    return (
      <React.Fragment>
        <div className={`f2x-rel-mv-detail`}>
          <Form className="f2x-rel-det-form f2x-security" onSubmit={(e) => this.handleSubmit(e)}>
            <Row className={`pt-0 mb-0`}>
              <Col xs="12">
                <div className="mb-20 relation-details-card">
                  <ListGroup className={`block-list list-group`} flush>
                    <h5 className="pb-0 list-group-sub-heading" style={{
                      paddingTop: "0",
                      lineHeight: "2.3",
                      fontWeight: "bolder",
                      margin: "0 2px 5px",
                      fontSize: "smaller",
                      textAlign: "left",
                      width: "auto",
                      display: "block",
                      opacity: "0.4"
                    }}
                    >
                      Security settings
                    </h5>
                    <ListGroupItem className={`d-flex align-items-center px-0 p-0`}>
                      <Col className="rel-dt-label col-md-4">Current password</Col>
                      <Col className="col-md-8 cur-inp-cont">
                        <div className="input-group">
                          <Input
                              type={isShowCurrPassword ? 'text' : 'password'}
                              name="current_password"
                              value={ current_password ? current_password : '' }
                              onChange={(e) => this.handleInputChange(e)}
                              className={ isCurrPasswordError ? 'f2x-pword-err' : '' }
                              aria-label="Users current password" aria-describedby="current_password"
                          />
                          <div className="input-group-append">
                            <button onClick={() => this.toggleCurrentPassword()} className="btn btn-gray" type="button" id="current_password">
                              <>
                                { isShowCurrPassword ?
                                    <FontAwesomeIcon icon={faEye}/> :
                                    <FontAwesomeIcon icon={faEyeSlash}/>
                                }
                              </>
                            </button>
                          </div>
                        </div>
                      </Col>
                    </ListGroupItem>
                    <ListGroupItem className={`d-flex align-items-center px-0 p-0`}>
                      <Col className="rel-dt-label col-md-4">New password</Col>
                      <Col className="col-md-8 cur-inp-cont">
                        <div className="input-group">
                          <Input
                              type={isShowNewPassword ? 'text' : 'password'}
                              name="password"
                              value={ password ? password : '' }
                              onChange={(e) => this.handleInputChange(e)}
                              className={ !isPasswordValid && password ? 'f2x-pword-err' : '' }
                          />
                          <div className="input-group-append">
                            <button onClick={() => this.toggleNewPassword()} className="btn btn-gray" type="button" id="password">
                              <>
                                { isShowNewPassword ?
                                    <FontAwesomeIcon icon={faEye}/> :
                                    <FontAwesomeIcon icon={faEyeSlash}/>
                                }
                              </>
                            </button>
                          </div>
                        </div>
                      </Col>
                    </ListGroupItem>
                    <ListGroupItem className={`d-flex align-items-center px-0 p-0`}>
                      <Col className="rel-dt-label col-md-4">Verify password</Col>
                      <Col className="col-md-8 cur-inp-cont">
                        <Input
                            type={isShowNewPassword ? 'text' : 'password'}
                            name="password_confirmation"
                            value={ password_confirmation ? password_confirmation : '' }
                            onChange={(e) => this.handleInputChange(e)}
                            className={ !isPasswordConfirmValid && password_confirmation ? 'f2x-pword-err' : '' }
                        />
                      </Col>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </Col>
            </Row>
            <Row className={`pt-0 mt-3`}>
              <Col xs="12" className="d-flex justify-content-between pb-3 save-cancel-buttons">
                <Col className="px-0 col text-right">
                  <Button type="submit" color="primary">Save</Button>
                </Col>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>    
    );
  }
}

const userSelector = createSelector(
    state => state.user,
    user => user
  ), 
  loaderSelector = createSelector(
    state => state.loader,
    loader => loader
  ),
  mapStateToProps = createSelector(
    userSelector,
    loaderSelector,
    (user, loader) => ({ user, loader })
  );
 
export default connect(mapStateToProps)(Security);