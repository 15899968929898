import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { loaderToggle } from '../redux/actions/loaderActions';
import {
  validateEmail, validateEmailWithValidator,
} from '../redux/actions/validateActions';
import CONSTANTS from '../services/constants';
import Toast from '../components/Toast';
import Swal from "sweetalert2";

const OptionPaid = ({...props}) => {

  const {status_invoice, row, updateStatusToPaid, dispatch} = props;
  const {id, relation_primary_person_email, email} = row;

  const handleAction = (e) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(loaderToggle(true));

    const { valid } = dispatch(validateEmailWithValidator(relation_primary_person_email ? relation_primary_person_email : email ? email : '')); // dispatch with function
    if (valid) {
      dispatch(loaderToggle(false));
      Swal.fire({
        customClass: { container: 'has-cancel' },
        title: 'Please confirm',
        text: 'Are you sure this invoice was paid?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then(res => {
        if (res.value) {
          updateStatusToPaid(id);
        }
      })
    } else {
      dispatch(loaderToggle(false));

      if (!valid) {
        Toast.fire({ title: "The email can’t be sent, because the relation's primary person has an invalid email address.", icon: 'error' })
      } else {
        Toast.fire({ title: "The email can’t be sent, because the relation has no primary person.", icon: 'error' })
      }
    }
  };

  return <DropdownItem key={`${id}-paid`} onClick={(e) => handleAction(e)}>{CONSTANTS.INVOICE_SET_PAID}</DropdownItem>

};

const mapStateToProps = ({ dispatch }) => ({ dispatch });

export default withRouter(connect(mapStateToProps)(OptionPaid));
