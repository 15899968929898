import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GetSearchResult } from '../controllers/search';
import { switchTenant } from "../hooks/useSwitchTenant";
import useComponentVisible from '../hooks/useComponentVisible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { hideSearchResults, toggleSearchResults, searchResultsUpdate } from '../redux/actions/solrSearchActions';
import { createSelector } from 'reselect';
import { useHistory, useLocation, withRouter, Link } from 'react-router-dom';
import { GetBrand, SwitchTenant } from '../controllers/tenants';
import { brand } from '../redux/actions/brandActions';
import { updateTenant, updateUser } from '../redux/actions/userActions';
import { relation as Relation } from '../redux/actions/relationActions'
import { subscription as Subscription } from '../redux/actions/subscriptionActions'
import { invoice as Invoice } from '../redux/actions/invoiceActions'
import {
  GetInvoices,
  GetRelation,
  GetRelationBillingStylesList,
  GetRelationsDependencies,
  GetSubscriptions,
} from '../controllers/relations';
import { clearSessionStorage } from '../services/misc';

const SolrSearchExpand = ({ ...props }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { t } = useTranslation();
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const showZeroResultScreen = false;

  let isQueryBusy = false; // is component busy?

  const { dispatch, user, theme, relation, history, context } = props;

  const [isSwitchingTenant, setIsSwitchingTenant] = useState(false);
  const [isQueryingResult, setIsQueryingResult] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoadingStatusResults, setIsLoadingStatusResults] = useState(false);
  const [currentRelationId, setCurrentRelationId] = useState(relation && relation.id ? relation.id : null);
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [searchMoreResults, setSearchMoreResults] = useState(null);
  const [itemStartNum, setItemStartNum] = useState(null);
  const [noResultsFound, setNoResultsFound] = useState(null);
  const [searchDropDownOpen, setSearchDropDownOpen] = useState(false);
  const [searchResultsTotal, setSearchResultsTotal] = useState(0);
  const [searchResultsStatistics, setSearchResultsStatistics] = useState(null);
  const [start, setStart] = useState(0);
  const [rows, setRows] = useState(10);
  const [parameters, setParameters] = useState({});
  const [tenantBrand, setTenantBrand] = useState({
    theme: {},
    logo: null,
    favicon: null
  });
  const [debugInfo, setDebugInfo] = useState(true);

  const jsonEscape = (str) => {
    return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
  };

  const searchResult = async (additional_parameters) => {
    if (!isQueryBusy) {
      isQueryBusy = true;

      let userInputValue;

      const substr = '@';
      const checkAddQuote = inputValue.indexOf(substr);
      //console.log("searchResult - find @ (substr in value input) ->", );
      //console.log("searchResult - userInputValue ->", inputValue);

      if (checkAddQuote >= 0) {
        userInputValue = '"' + inputValue + '"';
      }
      //console.log("searchResult - userInputValue (final) ->", userInputValue);
      //console.log("searchResult - inputValue (final) ->", inputValue);

      let data;
      await Promise.all([
        GetSearchResult(userInputValue ? userInputValue : inputValue, additional_parameters)
      ]).then((r) => {
        const [results] = r;
        if (results && results.ok) {
          data = results.data && results.data.data ? results.data.data : null;
          //console.log("solrSearch - data ->", data);
        }
      });

      isQueryBusy = false;
      return data;
    }
  };

  const solrSearch = (data) => {
    let additional_parameters = "";

    if (data) {
      additional_parameters = `&start=${data.start}&rows=${data.rows}`;
    } else {
      if (start > 0) {
        additional_parameters = `&start=${start}&rows=${rows}`;
      }
    }

    (async () => {
      let resultQueryData = await searchResult(additional_parameters);

      if (resultQueryData) {
        const stringifyJsonData = JSON.stringify(resultQueryData);
        const parseJsonData = JSON.parse(jsonEscape(stringifyJsonData));

        if (parseJsonData && parseJsonData.data) {
          const resultData = unescape(parseJsonData.data);
          const parseResultData = resultData ? JSON.parse(resultData) : null;

          if (parseResultData && parseResultData.responseHeader &&
            parseResultData.responseHeader.QTime) {
            setSearchResultsStatistics(
              { qTime: parseResultData.responseHeader.QTime })
          } else {
            setSearchResultsStatistics({ qTime: "" })
          }

          if (parseResultData && parseResultData.response &&
            parseResultData.response.docs &&
            parseResultData.response.docs.length > 0) {
            setSearchResults(parseResultData.response.docs);
            if (parseResultData.response.numFound) {
              setSearchResultsTotal(parseResultData.response.numFound);
            }

            setIsComponentVisible(true);
            dispatch(toggleSearchResults());
            document.body.style.overflow = "hidden";

          } else {
            if (showZeroResultScreen) {
              setSearchResults([]);
              setSearchResultsTotal(0);
              setIsComponentVisible(true);
              dispatch(toggleSearchResults());
              document.body.style.overflow = "hidden";
            } else {
              setSearchResults(false); 
              setSearchResultsTotal(0);
              setIsComponentVisible(false); 
            }
          }
        }
      }
    })();
  };

  const handleSearch = (e) => {
    e.stopPropagation();

    if (e.key === "Enter") {
      solrSearch();
    }
    return false;
  };

  const handleInputChange = (e) => {
    if (e.key === "Enter") {
      return false;
    }

    setInputValue(e.target.value);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();

    setSearchResults(null);
    solrSearch();
  };

  const clearInputField = (e, column) => {
    e.preventDefault();
    setInputValue("");
  };

  const showMoreSearchResults = async () => {
    let data = {
      start: start + rows,
      rows: rows + 10
    };

    setStart(data.start);
    setRows(data.rows);

    let additional_parameters = `&start=${data.start}&rows=${data.rows}`;

    // Introduce a delay to simulate slower fetching
    setTimeout(async () => {
      setIsLoadingStatusResults(true); // Stop showing loading indicator

      let resultQueryMoreData = await searchResult(additional_parameters);

      if (resultQueryMoreData) {
        const stringifyJsonData = JSON.stringify(resultQueryMoreData);
        const parseJsonData = JSON.parse(jsonEscape(stringifyJsonData));

        if (parseJsonData && parseJsonData.data) {
          const resultData = unescape(parseJsonData.data);
          const parseResultData = resultData ? JSON.parse(resultData) : null;

          if (parseResultData && parseResultData.response &&
            parseResultData.response.docs &&
            parseResultData.response.docs.length > 0) {

            setSearchResults(prevResults => [...prevResults, ...parseResultData.response.docs]);
            setSearchResultsTotal(parseResultData.response.numFound);
          }
        }
      }
      
      setIsFetching(false);
      setIsLoadingStatusResults(false); // Stop showing loading indicator

    }, 1000); // Delay of 1000 milliseconds (1 second)
  };

  const showLessSearchResults = (e) => {
    setStart(start > 1 ? start - 1 : 0);
    setRows(rows <= searchResultsTotal ? rows - 10 : searchResultsTotal);
  };

  const goToResult = (e, item, type) => {
    const { relation, dispatch } = props;

    document.body.style.overflow = "";

    dispatch({ type: 'RELATION', payload: null });
    dispatch({ type: 'INVOICE', payload: null });
    dispatch({ type: 'SUBSCRIPTION', payload: null });

    sessionStorage.removeItem('solrRelationId');
    sessionStorage.removeItem('lastVisitSubscrId');
    sessionStorage.removeItem('lastVisitInvId');

    const pathObj = item.url.split('/');
    const basePath = pathObj[1];
    const relationPathId = pathObj[2];

    if (item.tenant_id !== user.tenant_id) {
      //dow switch -> switchTenant(tenantData, user, location.pathname, dispatch);

      (async () => {
        const [switchTenant, getBrand] = await Promise.all([
          SwitchTenant(item.tenant_id),
          GetBrand(null, item.tenant_id)
        ]);

        if (switchTenant.ok && getBrand.ok) {
          let brandData = Object.assign({}, getBrand.data.data);

          if (brandData && brandData.theme) {
            brandData.theme = JSON.parse(brandData.theme)
          } else {
            brandData.theme = theme.currentTheme
          }

          if (brandData.theme) {
            dispatch(brand(brandData));
          }

          const tenant = switchTenant.data.data,
            tenant_id = switchTenant.data.data.id,
            tenant_name = switchTenant.data.data.name;

          const string = '^qStr';
          const regexp = new RegExp(string);

          const storedSessionVals = sessionStorage;
          let keys = Object.keys(sessionStorage);

          for (let i = 0; i < keys.length; i++) {
            if (regexp.test(keys[i])) {
              sessionStorage.removeItem(`${keys[i]}`);
            }
          }

          sessionStorage.setItem('solrRelationId', parseInt(relationPathId));
          sessionStorage.setItem('tenant', JSON.stringify(tenant));
          sessionStorage.setItem('default_country_id', tenant.default_country_id ? tenant.default_country_id : 155); // default NL -> 155

          let newTenant = Object.assign({}, tenant);
          delete newTenant.parent;

          dispatch(updateTenant(tenant));
          dispatch(updateUser({ tenant_id }));

          if (e.ctrlKey) {
            if (type === "invoices") {
              sessionStorage.setItem('lastVisitInvId', item.id.replace("inv_", ""));

            } else if (type === "subscriptions") {
              sessionStorage.setItem('lastVisitSubscrId', item.id.replace("sub_", ""));

            }

            window.open(`${baseUrl}${item.url}`, "_blank");
            return false;

          } else {
            if (type === "invoices") {
              sessionStorage.setItem('lastVisitInvId', item.id.replace("inv_", ""));

            } else if (type === "subscriptions") {
              sessionStorage.setItem('lastVisitSubscrId', item.id.replace("sub_", ""));

            }

            dispatch({ type: 'RELATION', payload: null });
            dispatch(searchResultsUpdate(true));
          }
        }
      })();

    } else {

      sessionStorage.setItem('solrRelationId', parseInt(relationPathId));

      if (e.ctrlKey) {
        if (type === "invoices") {
          sessionStorage.setItem('lastVisitInvId', item.id.replace("inv_", ""));

        } else if (type === "subscriptions") {
          sessionStorage.setItem('lastVisitSubscrId', item.id.replace("sub_", ""));

        }

        window.open(`${baseUrl}${item.url}`, "_blank");
        return false;

      } else {
        if (type === "invoices") {
          sessionStorage.setItem('lastVisitInvId', item.id.replace("inv_", ""));

        } else if (type === "subscriptions") {
          sessionStorage.setItem('lastVisitSubscrId', item.id.replace("sub_", ""));

        }
        dispatch(searchResultsUpdate(true));

      }
    }
  };

  // refresh invoice list relation
  const refreshRelation = (relationId, url, type) => {
    (async () => {
      const [relationDetails, relationDeps] = await Promise.all(
        [
          GetRelation(null, relationId),
          GetRelationsDependencies()
        ]);

      const details = {
        invoiceOutputOptions: [
          {
            value: 'email', label: 'Email'
          }, {
            value: 'paper', label: 'Paper'
          }
        ],
        subscriptionList: [],
        invoiceList: [],
      };

      if (relationDetails.ok) {
        const { data } = relationDetails.data;
        Object.assign(details, { ...data });
      }

      if (relationDeps.ok) {
        const { data } = relationDeps;
        Object.assign(details, {
          relation_types: data && data.relation_types
            ? data.relation_types
            : null
        });
        Object.assign(details, {
          payment_conditions: data && data.payment_conditions
            ? data.payment_conditions
            : null
        });
      }

      dispatch({ type: 'RELATION', payload: details });
    })();
  };

  // refresh subscription list relation
  const refreshRelationInvoices = (relationId) => {
    (async () => {
      const [invoiceList] = await Promise.all([
        GetInvoices(null, parseInt(relationId)),
      ]);

      // get list relation invoices - invId
      if (invoiceList.ok) {
        const { data } = invoiceList.data;

        dispatch({ type: 'INVOICE', payload: { invoiceList: data } });
        dispatch({ type: 'RELATION', payload: { invoiceList: data } });
      }
    })();
  };

  // refresh invoice list relation
  const refreshRelationSubscriptions = (relationId) => {
    (async () => {
      const [subscriptionList] = await Promise.all([
        GetSubscriptions(null, parseInt(relationId)),
      ]);

      // get list relation invoices - invId
      if (subscriptionList.ok) {
        const { data } = subscriptionList.data;

        dispatch({ type: 'SUBSCRIPTION', payload: { subscriptionList: data } });
        dispatch({ type: 'RELATION', payload: { subscriptionList: data } });
      }
    })();
  };

  const checkResultItemType = (item, i) => {
    if (item && item.id) {
      // split string to check for type
      let itemTypeArr = item.id.split("_");
      if (itemTypeArr && itemTypeArr[0]) {
        switch (itemTypeArr[0]) {
          case "rel":
            return (
              <li className="search-dropdown-content-list-item relation" key={`item-${i}`} data-item={`item-${i + 1}`}>
                <Link to={item.url} onClick={(e) => goToResult(e, item, "relations")}>
                  <div className="search-dropdown-content-list-item-hover d-flex flex-row">
                    <span key={`type-${i}`} className="badge-pill mr-1 mb-0 text-uppercase badge badge-success">REL</span>
                    <div key={`type-item-${i}`}>
                      {item.description && <span className="search-dropdown-content-list-item-span"><b>Description:</b> {item.description}</span>}
                      {item.number && <span className="search-dropdown-content-list-item-span"><b>Customer nr:</b> {item.number}</span>}
                      {item.person && <span className="search-dropdown-content-list-item-span"><b>Person:</b> {item.person}</span>}
                      {item.address && <span className="search-dropdown-content-list-item-span"><b>Address:</b> {item.address && item.address.split(",")[0] ? item.address.split(",")[0] : item.address}</span>}
                      {debugInfo && <div style={{ fontSize: "x-small", lineHeight: "2.0", color: "#333" }}>
                        <span>Tenant ID: {item.tenant_id}</span> - <span>Relation ID: {itemTypeArr[1] ? itemTypeArr[1] : item.id}</span> - <span>URL: {item.url}</span>
                      </div>}
                    </div>
                  </div>
                </Link>
              </li>
            );

          case "inv":
            return (
              <li className="search-dropdown-content-list-item invoice" key={`item-${i}`} data-item={`item-${i + 1}`}>
                <Link to={item.url} onClick={(e) => goToResult(e, item, "invoices")}>
                  <div className="search-dropdown-content-list-item-hover d-flex flex-row">
                    <span key={`type-${i}`} className="badge-pill mr-1 mb-0 text-uppercase badge badge-warning">INV</span>
                    <div key={`type-item-${i}`}>
                      {item.description && <span className="search-dropdown-content-list-item-span"><b>Description:</b> {item.description}</span>}
                      {item.number && <span className="search-dropdown-content-list-item-span"><b>Invoice nr:</b> {item.number}</span>}
                      {item.person && <span className="search-dropdown-content-list-item-span"><b>Person:</b> {item.person}</span>}
                      {item.address && <span className="search-dropdown-content-list-item-span"><b>Address:</b> {item.address && item.address.split(",")[0] ? item.address.split(",")[0] : item.address}</span>}
                      {debugInfo && <div style={{ fontSize: "x-small", lineHeight: "2.0", color: "#333" }}>
                        <span>Tenant ID: {item.tenant_id}</span> - <span>Invoice ID: {itemTypeArr[1] ? itemTypeArr[1] : item.id}</span> - <span>URL: {item.url}</span>
                      </div>}
                    </div>
                  </div>
                </Link>
              </li>
            );

          case "sub":
            return (
              <li className="search-dropdown-content-list-item subscription" key={`item-${i}`} data-item={`item-${i + 1}`}>
                <Link to={item.url} onClick={(e) => goToResult(e, item, "subscriptions")}>
                  <div className="search-dropdown-content-list-item-hover d-flex flex-row">
                    <span key={`type-${i}`} className="badge-pill mr-1 mb-0 text-uppercase badge badge-info">SUB</span>
                    <div key={`type-item-${i}`}>
                      {item.description && <span className="search-dropdown-content-list-item-span"><b>Description:</b> {item.description}</span>}
                      {item.person && <span className="search-dropdown-content-list-item-span"><b>Person:</b> {item.person}</span>}
                      {item.address && <span className="search-dropdown-content-list-item-span"><b>Address:</b> {item.address && item.address.split(",")[0] ? item.address.split(",")[0] : item.address}</span>}
                      {debugInfo && <div style={{ fontSize: "x-small", lineHeight: "2.0", color: "#333" }}>
                        <span>Tenant ID: {item.tenant_id}</span> - <span>Subscription ID: {itemTypeArr[1] ? itemTypeArr[1] : item.id}</span> - <span>URL: {item.url}</span>
                      </div>}
                    </div>
                  </div>
                </Link>
              </li>
            );
        }
      }
    }
  };

  const closeSearchResults = (e) => {
    e.preventDefault();

    setSearchResults(null);
    setRows(10);
    setStart(0);

    dispatch(hideSearchResults());
  };

  useEffect(() => {
    if (!isComponentVisible) {
      dispatch(hideSearchResults());
      document.body.style.overflow = "";
    }

  }, [isComponentVisible]);

  function fetchMoreListItems() {
    showMoreSearchResults();
  }

  useEffect(() => {
    const handleScroll = () => {
      // actions on scroll
      const dropdownEl = document.getElementById("my-dropdown");
      if (dropdownEl) {
        let scrollHeight = dropdownEl.scrollHeight;
        let scrollTop = dropdownEl.scrollTop;
        let clientHeight = dropdownEl.clientHeight;

        if ((scrollHeight - scrollTop === clientHeight) || (scrollHeight - scrollTop === clientHeight - 1)) {
          setIsLoadingStatusResults(true); // Stop showing loading indicator
          setIsFetching(true);
        } else {
          setIsLoadingStatusResults(false); // Stop showing loading indicator
        }
      } 
    };

    const el = document.getElementById("my-dropdown");
    if (el) {
      el.addEventListener('scroll', handleScroll);
      return () => {
        el.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (!isFetching) { 
      return;
    } else {
      fetchMoreListItems();
    }
  }, [isFetching]);

  return (
    <form className="" onSubmit={(e) => handleSearchClick(e)}>
      <div className="search-box">
        <input type="search"
          className="f2x-fin-h-text search-text expandright"
          name={`solr-search`}
          id={`id-input-solr-search searchright`}
          value={inputValue ? inputValue : ""}
          placeholder={'Search + enter'}
          onChange={handleInputChange}
          autoComplete="off"
        />

        <button className="icon-search search-btn" style={{ backgroundColor: "transparent", color: "#000" }}>
          <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon={faSearch} />
        </button>
      </div>

      <div className={`search-dropdown${props.solrSearch.searchDropDownState && props.solrSearch.searchDropDownState === true ? props.solrSearch.searchDropDownStateOpen : props.solrSearch.searchDropDownStateHidden}`} id={`solr-search`}>
        <div className="search-dropdown-content" id={`my-dropdown`} ref={ref}>
          {isComponentVisible && searchResults && searchResults.length === 0 &&
            (<ul className="search-dropdown-content-list">
              <li className="search-dropdown-content-list-item no-search-results">No results found</li>
            </ul>)}
          {isLoadingStatusResults && isComponentVisible && searchResults && searchResults.length > 0 && searchResultsTotal && searchResultsTotal >= 10 && rows <= searchResultsTotal &&
            <div className="search-dropdown-more mt-1">
              <div className="loading dots mt-3 mb-3"></div>
            </div>}
          {isComponentVisible && searchResults && searchResults.length > 0 &&
            (<ul className="search-dropdown-content-list" id="search-results">
              {/*!isLoadingStatusResults &&*/ searchResultsTotal && searchResultsStatistics.qTime &&
                <li className="search-results-statistics" key="0-statistics">
                  Found {searchResultsTotal} results
                  in {searchResultsStatistics.qTime}ms -
                  Showing {rows && rows <= searchResultsTotal ? rows + start : searchResultsTotal} of {searchResultsTotal} results

                  <button className="btn btn-danger btn-sm" onClick={(e) => closeSearchResults(e)} style={{ opacity: "0.6" }}>
                    <FontAwesomeIcon className="search-dropdown-close-icon" icon="times" />
                  </button>
                </li>}
              {searchResults.map((item, i) => {
                return checkResultItemType(item, i)
              })}
            </ul>)}
        </div>
      </div>
    </form>
  );
};

const solrSearchSelector = createSelector(
  state => state.solrSearch,
  solrSearch => solrSearch
);

const userSelector = createSelector(
  state => state.user,
  user => user
);

const brandSelector = createSelector(
  state => state.brand,
  brand => brand
);

const themeSelector = createSelector(
  state => state.theme,
  theme => theme
);

const relationSelector = createSelector(
  state => state.relation,
  relation => relation
);

const mapStateToProps = ({ solrSearch, dispatch, user, relation, brand, theme }) => ({ solrSearch, dispatch, user, relation, brand, theme });

export default withRouter(connect(mapStateToProps)(SolrSearchExpand));
