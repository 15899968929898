import React from 'react';

class ProcessedMessage extends React.Component {
    render() {
        let {...props} = this.props;
        return (<>
            <h4 className="pt-3 font-weight-bold">Processing CDR file</h4>
            <p>
                A job has been scheduled to process the CDR file. The job
                will soon be processed.

                When the processing has been finished, you will receive an
                email notification.
            </p>
            <p>
                When the processing has been finished, you will receive an
                email notification.
            </p>
        </>);
    }
}

export default ProcessedMessage;