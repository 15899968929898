import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Input,
  Card,
  Container,
  InputGroup, InputGroupAddon,
} from 'reactstrap';
import {
  Popover,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {Link, useHistory, withRouter} from 'react-router-dom';
import {withTranslation, useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import Select from 'react-select';
import filterFactory, {
  selectFilter, textFilter, dateFilter, customFilter,
} from 'react-bootstrap-table2-filter';
import CustomDataTable from '../../components/CustomDataTable';
import CustomFilterDate from '../../components/finance/CustomFilterDate';
import CustomFilterSelect from '../../components/finance/CustomFilterSelect';
import CustomFilterInput from '../../components/finance/CustomFilterInput';
import CONSTANTS from '../../services/constants';
import ApiService from '../../services/apiService';
import {invoiceStatusObj, stringFilter} from '../../services/misc';
import {loaderToggle} from '../../redux/actions/loaderActions';
import {getTableObj} from '../../services/tableService';
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';

const Api    = new ApiService(),
    GetAPI = Api.getAPI;

const FinanceInvoices = ({ user, loader, dispatch }) => {
  let history = useHistory();

  let customerFilter;
  let customerNoFilter;
  let invoiceFilter;
  let statusFilter;
  let datesFilter;

  const ref = useRef(null);
  const { t } = useTranslation();

  const [invoiceId, setInvoiceId] = useState('');
  const [status, setStatus] = useState(''); // value === status
  const [invoiceStatus, setInvoiceStatus] = useState(''); // status alternative === value
  const [data, setData] = useState([]);
  const [date, setDate] = useState(''); // datepicker
  const [customerNumber, setCustomerNumber] = useState('');
  const [customer, setCustomer] = useState('');
  const [invoice, setInvoice] = useState('');
  const [amount, setAmount] = useState(''); // price_total

  const [sort, setSort] = useState('date');
  const [pageNum, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [rowCount, setRowCount] = useState('');
  const [totalSize, setTotalSize] = useState(0);
  const [filters, setFilters] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [enabledFilter, setEnabledFilter] = useState(false);
  const [clearedItemName, setClearedItemName] = useState("");

  const [isLoadData, setLoadData] = useState(true);
  const [customerId, seCustomerId] = useState('');
  const [customerName, seCustomerName] = useState('');
  const [path, setPath] = useState('');

  const handleFiltering = (e, name) => {
    if ((name && e && (e.value || e.value === '')) || (e && e.key && e.key === "Enter") || (name === 'date' && (e !== '' || e === null || e === undefined))) {
      let value = '';
      switch (name) {
        case 'customer_number': // %
          let customer_number = e && e.target && e.target.value ? e.target.value : '';
          if (customer_number === '') {
            customer_number = null;
          }
          value = customer_number;
          setCustomerNumber(customer_number);
          break;
        case 'full_name': // %
          let full_name = e && e.target && e.target.value ? e.target.value : '';
          if (full_name === '') {
            full_name = null;
          }
          value = full_name;
          setCustomer(full_name);
          break;
        case 'value':
          value = e && e.value ? e.value : ''; // =
          if (value === '') {
            value = null;
          }
          setStatus(value);
          break;
        case 'invoice_no': // =
          let invoice_no = e && e.target && e.target.value ? e.target.value : '';
          if (invoice_no === '') {
            value = null;
          } else {
            value = invoice_no;
          }
          setInvoice(invoice_no);
          break;
        case 'date':
          if (e === '' || e === null) {
            value = null;
          } else {
            value = parseDateToSaveFormat(new Date(e));
          }
          setDate(value);
          break;
        default:
          break;
      }

      console.log('filters - filters[name] - value]', filters, filters[name]);
      if (name && filters && filters.indexOf(name) !== -1){
        console.log("Value name exists!", name)
      } else {
        console.log("Value does not exists!")
      }

      if (filters && filters[name]) {
        if (value && (value === '' || !value)) {
          delete filters[name];
        } else {
          if (filters[name] && false) {
            delete filters[name];
          } else {
            if (value) {
              filters[name] = value;
            }
          }
        }
      } else {
        if (value) {
          filters[name] = value;
        }
      }

      console.log('filters', filters);
      if (!value) {
        setFilters([]);
      } else {
        setFilters(filters);
      }
    }
  };

  const clearInput = (e, id) => {
    e.preventDefault();

    let input = document.getElementById(id);
    input.value = '';

    let elemName = input.name;

    handleFiltering(input, elemName);
  };

  const handleDateInputKeyDown = (e) => {
    if (e.key === 'Backspace') {
      e.target.value = '';
      setDate(null);
    }
    e.preventDefault();
  };

  const formatDate = (cell) => {
    if (cell)
      return <span className="date-format">{t("date_format", { date: new Date(cell) })}</span>
  };

  const hideOverflow = (e) => {
    console.log(e);
  };

  const colActionButtonsFormatter = (cell, row, rowIndex) => {
    const isActionPaid = invoiceStatusObj(row.value) && invoiceStatusObj(row.value).action === CONSTANTS.INVOICE_PAID;
    const pr = isActionPaid ? 'pr-0' : '';
    const pay_button_text = 'Invoice paid';
    const pay_button_popover = <div className={`popover-html-contents`}><p className="mb-0">{`Set status invoice to paid`}</p></div>;
    const invoice_reminder_status = <div className={`popover-html-contents`}><p className="mb-0">{invoiceStatusObj(row.value).action}</p></div>;
    const reminder_button_popover = 'Send reminder';

    return (
        <div ref={ref} className={`table-actions-buttons d-grid gap-2 d-md-flex justify-content-md-end`}>
          {!isActionPaid ?
              <OverlayTrigger
                  placement={`bottom`}
                  transition={false}
                  container={ref}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      {invoice_reminder_status}
                    </Tooltip>}
              >
                <button type="button" className="btn f2x-act-btn" onClick={(e) => handleReminderAction(e, row)}>
                  <span className={`d-inline-block text-truncate`}>{invoiceStatusObj(row.value).action}</span>
                </button>
              </OverlayTrigger> : null
          }
          <OverlayTrigger
              placement={`bottom`}
              transition={false}
              container={ref}
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  {pay_button_popover}
                </Tooltip>}
          >
            <button type="button" className={`btn f2x-act-btn pd`} onClick={(e) => handlePaidAction(e, row)}>
              <span className={`d-inline-block text-truncate`}>{ pay_button_text }</span>
            </button>
          </OverlayTrigger>
        </div>
    )
  };

  const colActionFormatter = (cell, row) => {
    const isActionPaid = invoiceStatusObj(row.value) && invoiceStatusObj(row.value).action === CONSTANTS.INVOICE_PAID;
    const pr = isActionPaid ? 'pr-0' : '';
    return (
        <div className={`table-row`}>
          <Container>
            <Row xs="4">
              <Col>
                {!isActionPaid ?
                    <div className="btn f2x-act-btn btn-block btn-sm" onClick={(e) => handleReminderAction(e, row)}>
                      <span>{invoiceStatusObj(row.value).action}</span>
                    </div>
                    :
                    null
                }
              </Col>
              <Col>
                <div className={`btn f2x-act-btn btn-sm btn-block pd`} onClick={(e) => handlePaidAction(e, row)}>
                  <span>Paid</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    )
  };

  const headerResetFormatter = () => {
    return <button className="btn btn-gray float-right" onClick={ handleClick }> Clear filters </button>
  };

  const customerNumberFormatter = (cell, row) => {
    return cell && row.relation_id ?
        <Link to={`relations/${row.relation_id}/details`} className="switch link-relation">{cell}</Link>
        :
        cell
  };

  const customerFormatter = (cell, row) => {
    return cell ?
        <>
          <Link to={`relations/${row.relation_id}/details`} className="switch link-relation">{cell}</Link><br />
          {row.name && row.name}
        </> :
        <span className="null-value">{String.fromCharCode(8212)}</span>
  };

  const invoiceFormatter = (cell, row) => {
    /*return cell ? <Link to={`/relations/${row.relation_id}/invoices/${row.sales_invoice_id}/`} className="switch link-relation">{cell}</Link> : <span className="null-value">{String.fromCharCode(8212)}</span>*/
    return cell ? <button onClick={(e) => goToInvoice(e,`/relations/${row.relation_id}/invoices/${row.sales_invoice_id}/`, row.sales_invoice_id)} className="px-0 pt-0 pb-0 switch link-relation btn btn-link">{cell}</button> : <span className="null-value">{String.fromCharCode(8212)}</span>
  };

  const goToInvoice = (e, url, sales_invoice_id) => {
    sessionStorage.setItem('lastVisitInvId', sales_invoice_id);
    history.push(url);
  };

  const amountFormatter = (cell) => <div className="text-right">{cell}</div>;

  const formatCurrency = (cell) => {
    if (cell) {
      let floatCell = cell ? cell : null;
      let floatStyle = floatCell && parseFloat(floatCell) < 0 ? 'float-negative' : floatCell && parseFloat(floatCell) > 0 ? 'float-positive' : 'float-null';
      return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(parseFloat(cell)) })}</span>
    } else {
      return <span className={'float-zero'}>{t("currency_format", { number: Number(0) })}</span>
    }
  };

  const colStatusFormatter = (cell) =>
      <div className={`badge-pill mr-1 mb-0 text-uppercase badge ${invoiceStatusObj(cell).styleClass}`}>
        {invoiceStatusObj(cell).label}
      </div>;

  const handleReminderAction = (e, v) => {
    e.preventDefault();
    dispatch(loaderToggle(true));
    GetAPI(`sales_invoices/${v['sales_invoice_id']}/send/reminder`)
    .then(r => {
      dispatch(loaderToggle(false));
      if (r.data) {
        const { data } = r.data;
        //setStatus(data['reminder_status']);
        setInvoiceId(v['sales_invoice_id'])
      }
    })
    .catch(error => {
      dispatch(loaderToggle(false));
      console.log('Error fetching data: ', error)
    });
  };

  const handlePaidAction = (e, v) => {
    e.preventDefault();
    dispatch(loaderToggle(true));
    GetAPI(`reminders/sales_invoices/${v['sales_invoice_id']}/paid`)
    .then(r => {
      dispatch(loaderToggle(false));
      if (r.data) {
        const { data } = r.data;
        //setStatus(data['reminder_status']);
        setInvoiceId(v['sales_invoice_id'])
      }
    })
    .catch(error => {
      dispatch(loaderToggle(false));
      console.log('Error fetching data: ', error)
    });
  };

  const handleDataChange = ({dataSize}) => {
    setRowCount(dataSize);
  };

  const handleTableChange = (type, {page, sizePerPage, sortField, sortOrder, searchText}) => {
    console.log('handleTableChange');

    const sortFieldParam = sortField === 'date' ? 'date' : sortField;
    const isSortDesc = sortField && stringFilter(sortOrder, 'desc');
    const sortParam = isSortDesc ? `-${sortFieldParam}` : sortFieldParam;
    const propsObj = getTableObj(type, {searchText, isLoadData, pageNum, page/*, searchText*/});

    setSizePerPage(sizePerPage ? sizePerPage : 10);
    setSort(sortParam);
    setPage(propsObj && propsObj.pageNum ? propsObj.pageNum : 1);
    setLoadData(propsObj && propsObj.isLoadData ? propsObj.isLoadData : true);

    let params = [];
    params['sort'] = sortParam;
    params['page'] = propsObj && propsObj.pageNum ? propsObj.pageNum : 1;
    params['filter'] = null;
    params['offset'] = sizePerPage ? sizePerPage : 10;

    setQueryParameters(params);
  };

  const loadData = (isLoad) => {
    console.log('loadData isLoad', isLoad);
    if (!isLoadData || !pageNum) {
      setLoadData(isLoad)
    }

    const invoicesListQuery = sessionStorage.getItem('financeListQueryPage');
    setPage(pageNum ? pageNum : 1);

    const params = getQueryParameters();
    if (params) {
      setQueryParameters(params);

      history.replace({
        state: {
          sort: sort ? sort : '',
          page: pageNum ? pageNum : 1,
          sizePerPage: sizePerPage ? sizePerPage : 10,
          filter: ''
        }
      });
    }
  };

  useEffect(() => {
    dispatch(loaderToggle(true));

    const queryParams = getQueryParameters();

    let queryFilter = [];
    for (let key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (key === 'invoice_no' || key === 'customer_number' || key === 'full_name' || key === 'date') {
          queryFilter.push(`${key}%${filters[key]}`);
        } else {
          queryFilter.push(`${key}=${filters[key]}`);
        }
      }
    }
    queryFilter.join(",");

    const param = {
      page: pageNum ? parseInt(pageNum) : 1,
      offset: sizePerPage ? parseInt(sizePerPage) : 10,
      sort: sort ? sort : '',
      filter: queryFilter && queryFilter.length > 0 ? queryFilter.toString() : ''
    };

    GetAPI('reminders/summary', param)
    .then(r => {
      dispatch(loaderToggle(false));
      if (r.data) {
        const { data, total } = r.data;
        setTotalSize(total);
        setData(data);
      }
    })
    .catch(error => {
      dispatch(loaderToggle(false));
      console.log('Error fetching data: ', error)
    })
  }, [invoiceId, status, user.tenant_id, sort, date, invoiceStatus, invoice, customer, customerNumber, amount, pageNum, sizePerPage, totalSize, isLoadData, dispatch]);

  const setQueryParameters = (params) => {
    if (!params) {
      params = [];
      params['sort'] = sort ? sort : '';
      params['page'] = pageNum ? parseInt(pageNum) : 1;
      params['offset'] = sizePerPage ? parseInt(sizePerPage) : 10;
      params['filter'] = '';
    }

    let query = [],
        key,
        value;

    for (key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      if (key === 'page' || key === 'offset') {
        value = parseInt(params[key]);
      } else {
        value = params[key];
      }
      query.push(`${key}=${value}`);
    }

    history.location.filter = query.join('&');
    sessionStorage.setItem('financeListQueryPage', query.join('&'));
    setLoadData(true);
  };

  const getQueryParameters = () => {
    let queryString;
    let params = [];
    if (!sessionStorage.getItem('financeListQueryPage') || sessionStorage.getItem('financeListQueryPage') === undefined) {
      queryString = history.location && history.location.filter &&
      history.location.filter.charAt(0) === '?'
          ? history.location.filter.slice(1)
          : history.location.filter;

    } else {
      queryString = sessionStorage.getItem('financeListQueryPage') && sessionStorage.getItem('financeListQueryPage').charAt(0) === '?'
          ? sessionStorage.getItem('financeListQueryPage').slice(1)
          : sessionStorage.getItem('financeListQueryPage');
    }

    if (queryString) {
      queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
        params[key] = value;
      });
    }

    return params;
  };

  const handleClick = () => {
    customerFilter = [];
    customerNoFilter = [];
    invoiceFilter = [];
    statusFilter = [];
    datesFilter = [];
  };

  return (
      <div className="pagination-details">
        <h1 className="text-bold mb-3">Finance</h1>
        <Card className="f2x-p-125">
          <CustomDataTable
              id="sales_invoice_reminders"
              table="sales_invoice_reminders"
              classes="custom-tbl-invoice finance-table react-bootstrap-table filters"
              data={data}
              keyField="sales_invoice_id"
              remote
              columns={[{
                dataField: "customer_number",
                text: 'Cust. no.',
                title: true,
                filter: customFilter({
                  type: 'text',
                  style: {display: 'inline-grid'},
                  getFilter: (filter) => {
                    console.log('customerNoFilter - getFilter - filter', filter);
                    customerNoFilter = filter;
                  }
                }),
                filterRenderer: (onFilter, column) =>
                    <CustomFilterInput onFilter={onFilter} column={column} handleFiltering={handleFiltering} clearInput={clearInput} />
                ,
                sort: true,
                formatter: customerNumberFormatter,
                headerStyle: () => {
                  return { width: '92px', paddingLeft: '10px', paddingRight: '8px' }
                },
                headerSortingClasses: (sort, opts) => {
                  console.log('headerSortingClasses - sort - opts ->', sort, opts);
                  return 'sorted-column'
                },
                attrs: {
                  'data-label': 'Cust. no.'
                }
              }, {
                dataField: "full_name",
                text: 'Customer',
                title: true,
                filter: customFilter({
                  type: 'text',
                  style: { display: 'inline-grid' },
                  getFilter: (filter) => {
                    console.log('customerFilter - getFilter - filter', filter);
                    customerFilter = filter;
                  }
                }),
                filterRenderer: (onFilter, column) =>
                    <CustomFilterInput onFilter={onFilter} column={column} handleFiltering={handleFiltering} clearInput={clearInput} />
                ,
                sort: true,
                headerStyle: () => {
                  return { width: '15%'}
                },
                headerSortingClasses: (sort, opts) => {
                  console.log('headerSortingClasses - sort - opts ->', sort, opts);
                  return 'sorted-column'
                },
                attrs: {
                  'data-label': 'Customer'
                }
              }, {
                dataField: "invoice_no",
                text: 'Invoice',
                title: true,
                formatter: invoiceFormatter,
                filter: customFilter({
                  type: 'text',
                  style: { display: 'inline-grid' },
                  getFilter: (filter) => {
                    console.log('invoiceFilter - getFilter - filter', filter);
                    invoiceFilter = filter;
                  }
                }),
                filterRenderer: (onFilter, column) =>
                    <CustomFilterInput onFilter={onFilter} column={column} handleFiltering={handleFiltering} clearInput={clearInput} />
                ,
                sort: true,
                headerSortingClasses: (sort, opts) => {
                  console.log('headerSortingClasses - sort - opts ->', sort, opts);
                  return 'sorted-column'
                },
                headerStyle: () => {
                  return { width: '15%'}
                },
                attrs: {
                  'data-label': 'Invoice'
                }
              }, {
                dataField: "value",
                text: 'Status',
                title: true,
                align: 'center',
                filter: customFilter({
                  type: 'select',
                  style: { display: 'inline-grid' },
                  getFilter: (filter) => {
                    console.log('statusFilter - getFilter - filter', filter);
                    statusFilter = filter;
                  }
                }),
                filterRenderer: (onFilter, column) =>
                    <CustomFilterSelect
                        onFilter={onFilter}
                        column={column}
                        handleFiltering={handleFiltering}
                        constantObj={CONSTANTS.FINANCE_INVOICES_STATUSES}
                        multiSelect={false}
                    />,
                sort: true,
                formatter: colStatusFormatter,
                headerStyle: () => {
                  return { width: '130px', overflow: 'inherit', textAlign: 'left' }
                },
                headerSortingClasses: (sort, opts) => {
                  console.log('headerSortingClasses - sort - opts ->', sort, opts);
                  return 'sorted-column'
                },
                attrs: {
                  'data-label': 'Status'
                }
              }, {
                dataField: "date",
                text: 'Date',
                title: true,
                sort: true,
                classes: 'header-date-style',
                headerStyle: () => {
                  return { width: '110px' };
                },
                filter: customFilter({
                  type: 'date',
                  style: { display: 'inline-grid' },
                  defaultValue: { date: new Date() },  //default value
                  getFilter: (filter) => {
                    console.log('datesFilter - getFilter - filter', filter);
                    datesFilter = filter;
                  }
                }),
                filterRenderer: (onFilter, column) =>
                    <CustomFilterDate onFilter={onFilter} column={column} handleFiltering={handleFiltering} />,
                formatter: formatDate,
                headerSortingClasses: (sort, opts) => {
                  console.log('headerSortingClasses - sort - opts ->', sort, opts);
                  return 'sorted-column'
                },
                attrs: {
                  'data-label': 'Date'
                }
              }, {
                dataField: "price_total",
                text: 'Amount',
                align: 'right',
                width: '100px',
                formatter: formatCurrency,
                headerStyle: () => {
                  return { textAlign: 'right', width: '100px' }
                },
                sort: true,
                headerSortingClasses: (sort, opts) => {
                  console.log('headerSortingClasses - sort - opts ->', sort, opts);
                  return 'sorted-column'
                },
                attrs: {
                  'data-label': 'Amount'
                }
              }, {
                dataField: "action",
                text: '',
                formatter: colActionButtonsFormatter,
                headerStyle: () => {
                  return { width: '25%', textAlign: 'right' }
                },
                attrs: {
                  'data-label': 'Actions'
                }
              }
              ]}
              page={pageNum ? parseInt(pageNum) : 1}
              sizePerPage={sizePerPage ? parseInt(sizePerPage) : 10}
              totalSize={totalSize ? parseInt(totalSize) : 10}
              sortItem={sort}
              filter={filters}
              onTableChange={handleTableChange}
          />
        </Card>
      </div>
  )
};

const mapStateToProps = ({ tenants, user }) => ({ tenants, user });

export default withRouter(withTranslation()(connect(mapStateToProps)(FinanceInvoices)));