import React, {useEffect, useState} from 'react';
import {useHistory, withRouter, useParams, useLocation} from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import {connect, useDispatch} from 'react-redux';
import {Check, MoreHorizontal, X, User, UserPlus} from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tabs from './Tabs';
import CONSTANTS from "../services/constants";
import {getActions} from 'sweetalert2';
import Toast from './Toast';
import {switchTenant} from '../hooks/useSwitchTenant';

// param MasterView used to be => ({tabs, relation, subscription, invoice, activeTab, ...props})
const MasterView = ({tabs, relation, subscription, invoice, activeTab, setActiveTab, lastLocation, user, relationId, relationSelected, isSwitchingTenant, ...props}) => {
  const history = useHistory();
  const params = useParams();
  const locations = useLocation();
  const dispatch = useDispatch();

  //console.log("Masterview - props ->", props);
  //console.log("Masterview - solrSearch - relationId ->", relationId);
  //console.log("Masterview - tabs ->", tabs);
  //console.log("Masterview - activeTab ->", activeTab);
  //console.log("Masterview - lastLocation ->", lastLocation);

  const [locationKeys, setLocationKeys] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const [customerUserIconBusiness, setCustomerUserIconBusiness] = useState(null);
  const [customerUserIconCustomer, setCustomerUserIconCustomer] = useState(null);
  const [path, setPath] = useState('');
  const [tabRoute, setTabRoute] = useState(true);

  useEffect(() => {
    if (isSwitchingTenant === true) {
      history.push(`${locations.pathname}`);
    }
  }, [isSwitchingTenant]);

  useEffect(() => {
    let prevLocation = false;
    return history.listen(location => {

      if (locationKeys.length === 0) {
        prevLocation = true;
      }

      const locationPathName = location.pathname;

      if (history.action === 'PUSH') {
        if (location.pathname) setLocationKeys([location.pathname]);
        prevLocation = true;
      }

      if (history.action === 'POP') {
        const itemTabPath = locationPathName.split("/").pop();
        const pathObj = locationPathName.split('/');
        const basePath = pathObj[1];
        const relId = pathObj[2];
        const relDataId = pathObj[3];

        if (relId || relDataId) {
          // check params data against relation invoicesList data
          if (itemTabPath === 'invoices') {
            if (relation && relation.id && relation.invoiceList &&
                relation.invoiceList.length > 0) {
              // check relation id
              if (parseInt(relId) !== parseInt(relation.id)) {
                sessionStorage.removeItem('lastVisitInvId');

                //console.log('MasterView - This invoice is not for relation id - so lets fallback to previous or first invoice');
                // show alert & navigate to last known invoice (or first in the list)
                //Toast.fire({
                //  title: 'Invoice could not be found with this combination of relation & invoice.',
                //  icon: 'warning'
                //}).then(r => console.log('Invoice could not be found with this combination of relation & invoice'));

                //setTabRoute(false);
                //history.push('/');
              }

              // check if
              if (relDataId) {
                let invoiceItem = relation.invoiceList.filter(
                    i => i.id === parseFloat(relDataId));

                if (!invoiceItem || invoiceItem.length === 0) {
                  sessionStorage.removeItem('lastVisitInvId');
                  //setTabRoute(false);
                  //history.push('/');
                }
              }
            }
          }
          // check params data against relation subscriptionsList data
          if (itemTabPath === 'subscriptions') {
            if (relation && relation.id && relation.subscriptionList &&
                relation.subscriptionList.length > 0) {
              if (parseInt(relId) !== parseInt(relation.id)) {
                sessionStorage.removeItem('lastVisitSubscrId');

                //console.log('MasterView - This subscription is not for relation id - so lets fallback to previous or first subscription');
                // show alert & navigate to last known invoice (or first in the list)
                //Toast.fire({
                //  title: 'Subscription could not be found with this combination of relation & subscription.',
                //  icon: 'warning'
                //}).then(r => console.log('Invoice could not be found with this combination of relation & invoice'));

                //setTabRoute(false);
                //history.push('/');
              }

              // check if
              if (relDataId) {
                let subscriptionItem = relation.subscriptionList.filter(
                    i => i.id === parseFloat(relDataId));

                if (!subscriptionItem || subscriptionItem.length === 0) {
                  sessionStorage.removeItem('lastVisitSubscrId');
                  //setTabRoute(false);
                  //history.push('/');
                }
              }
            }
          }
        }
      }
    })
  }, [ locationKeys ]);

  useEffect(() => {
    if (tabRoute) {
      /*const pathHash = window.location.hash;
      const prePath = pathHash.substring(pathHash.indexOf('#') + 1);
      const endIdx = prePath.lastIndexOf('/');
      const currentPath = prePath.substring(0, endIdx);*/

      /*const relation_ids = localStorage.getItem("relation_ids");
      console.log("Masterview - useEffect - relation ->", relation);
      console.log("Masterview - useEffect - relationSelected ->", relationSelected);
      console.log("Masterview - useEffect - relation_ids ->", relation_ids);
      console.log("Masterview - useEffect - user ->", user);

      if (relation_ids && relationSelected && relationSelected.id) {
        if (Array.isArray(relation_ids) && relation_ids.length > 1 && !relation_ids.includes(relationSelected.id)) {
          //history.push('/relations');
          //Toast.fire({title: 'Forbidden!', icon: 'info'}).then(r => console.log('Forbidden!'));
          //history.goBack();
          console.log("Masterview - useEffect - relation.id is not in relation_ids!!");
          switchTenant(relationSelected, user, locations, dispatch);

        } else {
          let relation_id = relation_ids;
          if (relationSelected.id !== relation_ids) {
            //history.push('/relations');
            //Toast.fire({title: 'Forbidden!', icon: 'info'}).then(r => console.log('Forbidden!'));
            //history.goBack();
            console.log("Masterview - useEffect - relation.id is not relation_id!!");
            switchTenant(relationSelected, user, locations, dispatch);
          }
        }
      }*/

      //if (relation && relation.relation && relation.relation.id && parseInt(relation.relation.id) === parseInt(relationId)) {
      if (relation && relation.relation) {
        setCustomerId(relation.relation.id ? relation.relation.id : '');
        setCustomerName(relation.relation.customer_name
            ? relation.relation.customer_name
            : '');
        setCustomerNumber(relation.relation.customer_number
            ? relation.relation.customer_number
            : relation && relation.customer_number
                ? relation.customer_number
                : '');

        const vat_no = relation.vat_no &&
        (relation.vat_no !== '' || relation.vat_no !== null)
            ? relation.vat_no
            : false;

        const company_name = relation.company_name && relation.company_name !==
        '' ? relation.company_name : false;

        if (relation['is_business'] && relation['is_business'] === 1) {
          setCustomerUserIconCustomer(null);
          setCustomerUserIconBusiness('user_plus');
          if (company_name) {
            setCustomerName(company_name);
          }
        } else {
          setCustomerUserIconBusiness(null);
          setCustomerUserIconCustomer('user');
        }
      }

      //setPath(currentPath);
    }
  },[relation, /*subscription, invoice,*/ activeTab, tabRoute/*, dispatch*/]);

  const customNavItem = <>
    <span className="pr-3 customer-heading">{ customerNumber && customerName ? `${customerNumber} | ${customerName}` : `${customerNumber}`}</span>
    <span className="customer-heading-icon">
    { customerUserIconBusiness ? <FontAwesomeIcon className="f2x-header-icon" icon="landmark" size="lg" /> : "" }
      { customerUserIconCustomer ? <FontAwesomeIcon className="f2x-header-icon" icon="user" size="lg" /> : "" }
    </span>
  </>;

  return (
      <Tabs relation={relation} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} path={path} customClassName="custom-mv-nav-item col" customNavItem={customNavItem} {...props} />
  )
};

const mapStateToProps = ({ relation, subscription, invoice, user }) => ({ relation, subscription, invoice, user });

export default withRouter(connect(mapStateToProps)(MasterView));
