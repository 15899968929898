import * as types from "../constants";

export default function reducer(
  state = {
    id: null,
    invId: null,
    relationId: null, //thomb
    addressOpt: null,
    personOpt: null,
    date: null,
    due_date: null,
    price_total: null,
    invoiceList: [],
    subscriptionList: [],
  }
  , actions) {
  const { payload } = actions;

  if (actions.type === types.INVOICE) {
    return {
      ...state,
      ...payload
    };
  } else {
    return state;
  }
}