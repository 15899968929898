import React, { useState, useEffect, createRef, useRef } from 'react';
import {loaderToggle} from '../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ApiService from '../../services/apiService';
import CONSTANTS from '../../services/constants';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../services/misc';
import {getTableObj} from '../../services/tableService';

const CustomFilterSelect = ({
    ...props
  }) => {
  //const filterSelectRef = useRef();
  //const inputFilterSelectRef = createRef();
  //console.log("CustomFilterSelect - props ->", props);

  const {
    column,
    onFilter,
    handleFiltering,
    constantObj,
    multiSelect,
    clearOnEmpty,
    isClearFilter,
    setClearedItemName,
    value,
    enabledFilter,
    setEnabledFilter,
    table,
  } = props;

  //console.log("CustomFilterSelect - constantObj ->", constantObj);

  const { t } = useTranslation();
  const [iStatus, setIStatus] = useState(null);
  const [isSelected, setIsSelected] = useState(value);
  const [isFilteredHistory, setIsFilteredHistory] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const inputCustomFilterSelectRef = createRef();

  const changeData = (data, triggeredAction) => {
    //console.log("CustomFilterSelect - changeData - data ->", data);
    //console.log("CustomFilterSelect - changeData - triggeredAction ->", triggeredAction);

    if (triggeredAction.option && triggeredAction.option.label && triggeredAction.option.label === "All") {
      setEnabledFilter(true);
      setIsFilteredHistory(true);
      setClearedItemName(column.dataField);
      setSelectedValue("");

    } else if (triggeredAction && triggeredAction.action === 'clear') {
      setEnabledFilter(true);
      setIsFilteredHistory(true);
      //setClearedItemName(column.dataField);
      setClearedItemName(null);
      //setSelectedValue([]);
      setSelectedValue(null);

    } else if (triggeredAction && triggeredAction.action === 'remove-value') {
      const update = Array.isArray(selectedValue) && selectedValue.length > 0 ? selectedValue.filter((item) => item.value !== triggeredAction.removedValue.value) : null;
      //console.log("CustomFilterSelect - handleFiltering - changeData - (current) update ->", update);

      setEnabledFilter(true);
      setIsFilteredHistory(true);

      if (update && update.length > 0) {
        setClearedItemName(column.dataField);
        setSelectedValue(update);
      } else if (update && update.length === 0) {
        //console.log("CustomFilterSelect - handleFiltering - changeData - (current) update length === 0 ->", update);
        //console.log("CustomFilterSelect - handleFiltering - changeData - (current) update length === 0 - value ->", value);
        //setEnabledFilter(true);
        setIsFilteredHistory(true);
        setClearedItemName(null);
        setSelectedValue(null);
        //clearOnEmpty();

      } else {
        //setEnabledFilter(true);
        //setIsFilteredHistory(false);
        setClearedItemName(column.dataField);
        //setSelectedValue("");
        //setClearedItemName(null);
        //setClearedItemName(null);
        setSelectedValue(null);
        //clearOnEmpty();
      }

      /*setEnabledFilter(true);
      setIsFilteredHistory(true);

      if (update && update.length > 0) {
        console.log("CustomFilterSelect - handleFiltering - changeData - (current) update is array ->", update);
        setClearedItemName(column.dataField);
        setSelectedValue(update);

      } else if (update && update.length === 0) {
        console.log("CustomFilterSelect - handleFiltering - changeData - (current) update length === 0 ->", update);
        setEnabledFilter(true);
        setIsFilteredHistory(true);
        setClearedItemName(null);
        setSelectedValue(null);
        clearOnEmpty();

      } else if (update) {
        console.log("CustomFilterSelect - handleFiltering - changeData - (current) update is string ->", update);
        setEnabledFilter(true);
        setIsFilteredHistory(false);
        setClearedItemName(column.dataField);
        setSelectedValue(update);

      } else {
        console.log("CustomFilterSelect - handleFiltering - changeData - (current) update === null ->", update);
        let triggeredAction = {};
        triggeredAction.action = 'clear';
        changeData(null, triggeredAction);

      }*/

    } else if (triggeredAction && triggeredAction.action === 'select-option') {
      setEnabledFilter(true);
      setIsFilteredHistory(true);

      let update;
      if (selectedValue && selectedValue.length > 0) {
        update = [...selectedValue, triggeredAction.option];
      } else {
        if (triggeredAction.option && triggeredAction.option !== "") {
          update = [triggeredAction.option];
        }
      }
      setSelectedValue(update ? update : "");
    }
  };

  const getStorageValue = () => {
    let name = column.dataField;
    let storedValue;
    let tableStorageFilterObjId = "qStr"+table+"FiltersObj";
    let filtersObj = sessionStorage.getItem(tableStorageFilterObjId);
    if (filtersObj) {
      let filtersObjParsed = JSON.parse(filtersObj);
      //console.log("CustomFilterSelect - getStorageValue - filtersObjParsed ->",filtersObjParsed);
      if (filtersObjParsed && filtersObjParsed.filter &&
          filtersObjParsed.filter.length > 0)
      {
        for (let i = 0; i < filtersObjParsed.filter.length; i++) {
          if (filtersObjParsed.filter[i] &&
              filtersObjParsed.filter[i][name]) {
              storedValue = filtersObjParsed.filter[i][name];
              return storedValue;
          }
        }
      } else {
        if (filtersObjParsed && filtersObjParsed.filter) {
          let key;
          for (key in filtersObjParsed.filter) {
            if (filtersObjParsed.filter.hasOwnProperty(key) && key === name) {
              storedValue = filtersObjParsed.filter[name];
              return storedValue;
            }
          }
        }
      }
    }
    return false;
  };

  useEffect(() => {
    handleFiltering(selectedValue ? selectedValue : null, column.dataField, multiSelect);
  }, [selectedValue]);

  useEffect(() => {
    if (!enabledFilter && !selectedValue && value && value.length > 0) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isClearFilter && isClearFilter === true) {
      setSelectedValue(null);
    }
  }, [isClearFilter]);

  useEffect(() => {
    let storedValue = getStorageValue();
    //console.log("CustomFilterInput - useEffect (deps: value) - getStorageValue - storedValue ->", storedValue);
    if (storedValue) {
      setSelectedValue(storedValue);
    }
  }, []);

  useEffect(() => {
    handleFiltering(selectedValue ? selectedValue : null, column.dataField, multiSelect);
  }, [selectedValue]);

  return (
      <div
          ref={inputCustomFilterSelectRef}
          key={`select-${column.dataField}`}
          className="custom-finance-select-container custom-filter-item"
          style={{display: 'flex', flexDirection: 'column'}}
      >
        <Select
            className="react-select-container custom-finance-select mt-0"
            classNamePrefix="react-select"
            name={column.dataField}
            id={`id-${column.dataField}`}
            key={`key-${column.dataField}`}
            placeholder="Select..."
            withoutEmptyOption={true}
            options={constantObj}
            hideSelectedOptions={true}
            value={selectedValue}
            onChange={(data, triggeredAction) => changeData(data, triggeredAction)}
            isMulti={multiSelect}
            isClearable={false}
        />
      </div>
  );
};

export default CustomFilterSelect;
