import * as types from "../constants";

export default function reducer(
  state = {
    id: null,
    subscrId: null,
    relationId: null,
    description: '',
    plan: '',
    startDate: null,
    endDate: null,
    wishDate: null,
    costCenter: null,
    status: '',
    contractPeriod: '',
    period: '',
    type: '',
    billingStart: null,
    nrcTotal: '',
    mrcTotal: '',
    qrcTotal: '',
    yrcTotal: '',
    depositTotal: '',
    persons: [],
    lineTypes: [],
    contractPeriods: [],
    statusList: [],
    addresses: [],
    subscrLines: {},
    subscrPrices: {},
    subscription_status: null, 
    costs: 0,
    price_excl_vat: 0,
    price_incl_vat: 0,
    invoiceList: [],
    subscriptionList: [],
    addressOpt: null, // added 16-3-2023 by thomb
    personOpt: null, // added 16-3-2023 by thomb
  }
  , actions) {
  const { payload } = actions;

  switch (actions.type) {
    case types.SUBSCRIPTION:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
}