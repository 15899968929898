import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TenantVatCodes } from '../../../controllers/vat_codes';
import VatCodeForm from "./Form";
import RemoveVatCodeForm from "./remove/Form";
import ParentTable from '../../../components/ParentTable';
import { Helmet } from "react-helmet";

class VatCodes extends React.Component {
  percentageFormatter = (cell) => {
    return (
      <React.Fragment>
        {cell} %
      </React.Fragment>
    )
  };

  formatDate = (cell) => {
    if (cell)
      return <span className="date-format">{this.props.t("date_format", { date: new Date(cell) })}</span>
  };

  render() {
    const { id, tabTitle } = this.props;
    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="table-vat-codes"
          id={id}
          noTopBorder={true}
          data={TenantVatCodes}
          columns={[
            {
              dataField: "description",
              text: "Description",
              sort: true,
              attrs: {
                'data-label': 'Description'
              }
            },
            {
              dataField: "vat_percentage",
              text: "VAT percentage",
              formatter: this.percentageFormatter,
              attrs: {
                'data-label': 'VAT percentage'
              }
            },
            {
              dataField: "active_from",
              text: "Active from",
              formatter: this.formatDate,
              attrs: {
                'data-label': 'Active from'
              }
            },
            {
              dataField: "active_to",
              text: "Active to",
              formatter: this.formatDate,
              attrs: {
                'data-label': 'Active to'
              }
            },
            {
              dataField: "account.description",
              text: "Account",
              sort: true,
              attrs: {
                'data-label': 'Account'
              }
            },
            {
              dataField: "in_use_label",
              text: "In use",
              attrs: {
                'data-label': 'In use'
              }
            }
          ]}
          rowAction="edit"
          rowActionForm={VatCodeForm}
          headerDropdownOpts={[{
            label: 'Add',
            action: VatCodeForm
          }]}
          rowDropdownOpts={
            [{
              action: 'Edit',
              label: 'Edit VAT code',
              form: VatCodeForm
            }, {
              action: 'Remove',
              label: 'Remove VAT code',
              form: RemoveVatCodeForm,
              condition: {
                data: 'removable'
              }
            }]
          }
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation('translations')(connect()(VatCodes)));
