import React from "react";
import {connect} from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Swal from "sweetalert2";
import MAC_ADDRESS from "is-mac-address";
import Toast from "../components/Toast";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    CustomInput,
    Button,
    Label
} from 'reactstrap';
import {loaderToggle} from "../redux/actions/loaderActions";
import {validateDate} from "../redux/actions/validateActions";
import ApiService from '../services/apiService';
import {validateEmail} from '../redux/actions/validateActions'
import CONSTANTS from '../services/constants'
import {invoiceStatusObj} from '../services/misc'
import {subscription} from '../redux/actions/subscriptionActions';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

const Api = new ApiService(),
    PostAPI = Api.postAPI,
    GetAPI = Api.getAPI,
    RemoveAPI = Api.removeAPI;

class ActionHandler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            params: {}
        }
    }

    handlePushClick = (href, target) => {
      if (href && !target) {
          this.props.history.push(href);
      }
    };

    handleInputChange({ target }) {
        let params = Object.assign({}, this.state.params),
            { name, value } = target;

        if (name === 'mac_address') {
            const r = /([a-f0-9]{2})([a-f0-9]{2})/i;

            value = value.toUpperCase().replace(/[^a-f0-9]/ig, "");

            while (r.test(value)) {
                value = value.replace(r, `$1:$2`)
            }

            value = value.slice(0, 17)
        }

        params[name] = value;

        this.setState({ params })
    }

    handleSelectChange(name, value) {
        let params = Object.assign({}, this.state.params);

        console.log("ActionHandler - handleSelectChange - name ->", name);
        console.log("ActionHandler - handleSelectChange - value ->", value);

        //if (name === "product") {
            params["type_element"] = "select";
            params["type_name"] = name;
            params[name] = value;
        //} else {
        //    params[name] = value;
        //}

        //console.log("ActionHandler - handleSelectChange - params ->", params);
        //return false;

        this.setState({ params })
    }

    handleDatePickerChange(name, date) {
        let params = Object.assign({}, this.state.params);
        const { newDate } = this.props.dispatch(validateDate(date));

        params[name] = newDate;

        this.setState({ params })
    }

    handleCheckChange({ target: { name, checked } }) {
        let params = Object.assign({}, this.state.params);

        params[name] = checked;

        this.setState({ params })
    }

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false)
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        console.log('ActionHandler handleSubmit - props ->', this.props);

        const { dispatch, loader, data: { obj: { data, label }}} = this.props,
              { params } = this.state;

        //Validations
        if (params.serial !== undefined && !params.serial) {
            return Toast.fire({ title: 'Serial field is required!', icon: 'warning' });
        }

        if (params.mac_address !== undefined && !params.mac_address) {
            return Toast.fire({ title: 'Mac address field is required!', icon: 'warning' });
        }

        if (params.mac_address !== undefined && !MAC_ADDRESS.isMACAddress(params.mac_address)) {
            return Toast.fire({ title: 'Mac address must be valid.!', icon: 'error' });
        }

        if (!loader) {
            dispatch(loaderToggle(true));

            const url = data && data.url ? data.url : data && data.data ? data.data : null;

            //console.log('data.url', url);
            //console.log('ActionHandler handleSubmit - url ->', url);
            //console.log('params', params);
            //console.log('ActionHandler handleSubmit - params ->', params);

            (async () => {
                if (params && params.type_element && params.type_element === "select" && params.type_name && params[params.type_name] && params[params.type_name].value) {
                    let newParamsObj = {};
                    newParamsObj[params.type_name] = params[params.type_name].value;
                    console.log('ActionHandler handleSubmit - newParamsObj ->', newParamsObj);

                    const {ok, data} = await PostAPI(url, newParamsObj);
                    if (ok) {
                        this.handleSuccess(label, true);
                    } else {
                        this.handleError();
                    }

                } else {
                    const {ok, data} = await PostAPI(url, params);
                    if (ok) {
                        this.handleSuccess(label, true);
                    } else {
                        this.handleError();
                    }

                }
                console.log('actionHandler PostApi result - data ->', data);

            })();
        }
    };

    renderElements = ({ type, name, options, placeholder }) => {
        const { params } = this.state;
        const { t } = this.props;

        return (
            type === 'select' ?
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={options}
                    value={params[name]}
                    placeholder={placeholder}
                    maxMenuHeight={300}
                    onChange={(e) => this.handleSelectChange(name, e)}
                /> :
                type === 'datepicker' ?
                    <DatePicker
                        className="form-control"
                        name={name}
                        //dateFormat="dd-MM-yyyy"
                        dateFormat={t("date_format_raw")}
                        autoComplete="off"
                        //selected={params[name] ? moment(params[name], 'DD-MM-YYYY')._d : null}
                        selected={params[name] ? new Date(params[name]) : null}
                        onChange={(e) => this.handleDatePickerChange(name, e)}
                        ref={name}
                        //onKeyDown={(e) => this.handleKeyDown(e, name)}
                        onKeyDown={(e) => e.preventDefault()}
                    /> :
                    type === 'checkbox' ?
                        <CustomInput
                            id={name}
                            type="checkbox"
                            name={name}
                            defaultChecked={!!(params[name] || parseInt(params[name]) === 1)}
                            onChange={(e) => this.handleCheckChange(e)}
                        /> :
                        <Input
                            id={name}
                            type={type}
                            name={name}
                            value={params[name]}
                            placeholder={placeholder}
                            onChange={(e) => this.handleInputChange(e)}
                        />
        )
    };

    handleActions = () => {
        const { data: {obj, selectedData}, /*update,*/ hide, dispatch, loader} = this.props,
              { action, data, label } = obj;

        switch (action) {
            case 'Link':
                window.open(data.url, '_blank');
                hide();
                break;

            case 'Notice':
                Swal.fire(data && data.label ? data.label : 'Notice!', data && data.msg ? data.msg : 'Something went wrong.', 'info')
                    .then(res => {
                        hide();
                    });
                break;

            case 'Confirm':
                Swal.fire({
                    customClass: { container: 'has-cancel', },
                    title: 'Please confirm',
                    text: data.msg,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then(res => {
                    if (res.value) {
                        if (!loader) {
                            (async () => {
                                dispatch(loaderToggle(true));

                                const method = data.method === 'DELETE' ? RemoveAPI : data.method === 'POST' ? PostAPI : GetAPI,
                                      getData = await method(data.url);

                                console.log('actionHandler RemoveAPI/PostAPI/GetAPI result - data ->', getData);

                                if (getData.ok) {
                                    this.handleSuccess(label, data.show_success_popup, getData);
                                } else {
                                    this.handleError();
                                }
                            })();
                        }
                    } else {
                        hide()
                    }
                });
                break;

            case 'Modal':
                let params = Object.assign({}, this.state.params);
                const { form } = data;

                form.map(({ data }) => {
                    return data.map(({ name, value, type }) => {
                        params[name] = value ? value : type === 'select' || type === 'datepicker' ? null : type === 'checkbox' || type === 'radio' ? false : '';

                        return this.setState({ params })
                    })
                });
                break;

            case 'LinkAction':
                //TODO: total refactor .....
                const { reminder, relation_primary_person_email } = selectedData;
                const { valid } = dispatch(validateEmail(relation_primary_person_email));

                if (relation_primary_person_email && valid) {
                    if (reminder && reminder.reminder_status === CONSTANTS.FINAL_NOTICE_SENT) {
                        Swal.fire({
                            customClass: { container: 'has-cancel' },
                            title: 'Please confirm',
                            text: "Has this invoice been sent to a collection agency?",
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Confirm'
                        }).then(res => {
                            if (res.value) {
                                dispatch(loaderToggle(true));
                                GetAPI(data.url)
                                    .then(r => {
                                        dispatch(loaderToggle(false));
                                        const { data } = r && r.data;

                                        Toast.fire({ title: invoiceStatusObj(data.reminder_status).successMsg, icon: 'success' });
                                        this.handleSuccess()
                                    })
                                    .catch(e => {
                                        console.log('Error processing reminder...', e);
                                        dispatch(loaderToggle(false));
                                        Toast.fire({ title: 'Error processing reminder...', icon: 'error' });
                                        this.handleError()
                                    })
                            }
                        })

                    } else {
                        GetAPI(data.url)
                            .then(r => {
                                dispatch(loaderToggle(false));
                                const { data } = r && r.data;
                                Toast.fire({ title: invoiceStatusObj(data.reminder_status).successMsg, icon: 'success' });
                                this.handleSuccess();

                            })
                            .catch(e => {
                                console.log('Error processing reminder...', e);
                                dispatch(loaderToggle(false));
                                Toast.fire({ title: 'Error processing reminder...', icon: 'error' });
                                this.handleError();

                            })
                    }
                } else {
                    if (relation_primary_person_email) {
                        Toast.fire({ title: "The email can’t be sent, because the relation's primary person has an invalid email address.", icon: 'error' })
                    } else {
                        Toast.fire({ title: "The email can’t be sent, because the relation has no primary person.", icon: 'error' })
                    }
                }
                break;

            default:
                hide();
                break;
        }
    };

    componentDidUpdate(prevProps) {
        console.log('ActionHandler - old props:', prevProps);
        console.log('ActionHandler - actual this props', this.props);
    }

    handleSuccess = (label, showPopup, getData) => {
        const { dispatch, update, hide, data } = this.props;

        console.log('Actionhandler handleSuccess getData', getData);
        console.log('Actionhandler handleSuccess label', label);
        console.log('Actionhandler handleSuccess data selectedData', data); // selectedData
        console.log('Actionhandler handleSuccess props', this.props);
        console.log('Actionhandler handleSuccess state', this.state);

        dispatch(loaderToggle(false));
        if (showPopup) {
            Swal.fire('Success!', `${label} successfully`, 'success').then(r => console.log(r))
        }

        const { newData } = dispatch(subscription(data));
        console.log('Actionhandler handleSuccess newData', newData);

        //dispatch(subscription(getData));
        //dispatch(
        update('sub_line_update');
        //update('didMount');

        hide();
    };

    handleError = () => {
        const { dispatch, hide } = this.props;

        dispatch(loaderToggle(false));
        hide();
    };

    componentDidMount() {
        this.handleActions();

        const { loader, dispatch, update } = this.props;
        console.log('actionHandler componentDidMount props', this.props);

    }

    render() {
        const { data: { obj }, hide } = this.props,
              { action, data, label } = obj;
        return (
            <React.Fragment>
                {   action === 'Modal' &&
                    <Modal className="form-action-handler" isOpen={true} toggle={hide} centered>
                        <Form onSubmit={(e) => this.handleSubmit(e)}>
                            <ModalHeader>
                                <span>{label}</span>
                            </ModalHeader>
                            <ModalBody key="0" className="mt-3 mb-3">
                                {
                                    data.form.map((row, rowIndex) => {
                                        return <Row key={rowIndex}>
                                            {console.log("ActionHandler - row ->", row)}
                                            {row.label && row.label !== "" && <Col md={5}>{row.label}:</Col>}
                                            {row.label && row.label === "" && row.placeholders && <Col md={5}>{row.placeholders}:</Col>}
                                            <Col md={row.label ? 7 : 12}>
                                                {row.data.map((item, itemIndex) => {
                                                    return <FormGroup className="row" key={`${item.name}-${itemIndex}`}>
                                                        <Col md={5} key={`col-label-${itemIndex}`}>
                                                            <Label className="mb-0" for={item.name}>{item.placeholder}:</Label>
                                                        </Col>
                                                        <Col md={7} key={`col-element-${itemIndex}`}>{this.renderElements(item)}</Col>
                                                    </FormGroup>
                                                })
                                                }
                                            </Col>
                                        </Row>
                                    })
                                }
                            </ModalBody>
                            <ModalFooter className="justify-content-between">
                                <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button color="primary">
                                    <FontAwesomeIcon icon={faSave} />
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}

//export default connect(store => ({loader: store.loader}))(ActionHandler);

//const mapStateToProps = (store => {loader: store.loader});

export default connect(store => ({loader: store.loader}))(withTranslation()(withRouter(ActionHandler)));
