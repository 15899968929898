import React from "react";

import { GetGroupList } from '../../../controllers/tenants';
import GroupForm from "./Form";
import ParentTable from '../../../components/ParentTable';
import { Helmet } from "react-helmet";

class Groups extends React.Component {
  render() {
    const { id, tabTitle } = this.props;
    return (
      <React.Fragment>
        {tabTitle &&
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
        }
        <ParentTable
          basicSearch={true}
          table="Groups"
          id={id}
          noTopBorder={true}
          data={GetGroupList}
          columns={[
            {
              dataField: "name",
              text: "Name",
              sort: true,
              attrs: {
                'data-label': 'Name'
              }
            },
            {
              dataField: "description",
              text: "Description",
              sort: true,
              attrs: {
                'data-label': 'Description'
              }
            }
          ]}
          //rowAction="edit"
          //rowActionForm={GroupForm}
          //headerDropdownOpts={[{
          //  label: 'Add',
          //  action: GroupForm
          //}]}
        />
      </React.Fragment>
    )
  }
}

export default Groups;
