import React, { useEffect, useState, useRef } from 'react';
import {GetTenantLists} from '../../controllers/tenants';
import TenantForm from "./Form";
import ParentTable from '../../components/ParentTable';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import CONSTANTS from '../../services/constants';
import {Badge} from 'reactstrap';
import {loaderToggle} from '../../redux/actions/loaderActions';
import {GetPlanLines} from '../../controllers/plans';

const Tenants = ({ ...props }) => {
  const [parentIds, setParentIds] = useState(null);
  const [data, setData] = useState(null);
  const [totalSize, setTotalSize] = useState(0);
  const [tenantArrList, setTenantArrList] = useState(null);

  const nameTenantFormatter = (cell, row) => {
    return (
        <div
          id={row.id}
          data-parent-id={row.parent_id}
        >
          {row.parent_id && row.parent_id > 0 ? <span className="arrow-icon">{cell}</span> : cell}
        </div>
    );
  };

  const useAccountingFormatter = (cell) => {
    if (cell || cell === 0) {
      return (
        <Badge
          color={cell === 0
            ? 'danger'
            : 'success'}
          className="badge-pill mr-1 mb-1 text-uppercase"
        >
          {cell === 0 ? `NO` : `YES`}
        </Badge>)
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  const originalNameFormatter = (cell, row) => {
    return (
        <div style={{textIndent: `${row.parent_id ? row.parent_id + 1 : 0}em`}}> {cell} </div>
    );
  };

  const nameFormatter = (cell, row) => {
    return (
        <div
          id={row.id}
          data-parent-id={row.parent_id ? row.parent_id : 0}
          data-depth={row.depth ? row.depth : 0}
          style={{textIndent: `${row.depth ? row.depth : 0}em`}}
        >
          {row.depth > 0 ? <span className="arrow-icon">{cell}</span> : cell}
        </div>
    );
  };

  const nameIdFormatter = (cell, row) => {
    return (
        <div
            id={row.id}
            data-parent-id={row.parent_id}
            style={{textIndent: `${row.parent_id ? (row.parent_id - 1) : 0}em`}}
        >
          {row.parent_id && row.parent_id > 0 ? <span className="arrow-icon">#{row.id} {cell}</span> : `#${row.id} ${cell}`}
        </div>
    );
  };

  const billingDayFormatter = (cell, row) => {
    if (cell) {
      let ord = 'th';
      if (cell % 10 === 1 && cell % 100 !== 11) {
        ord = 'st';
      } else if (cell % 10 === 2 && cell % 100 !== 12) {
        ord = 'nd';
      } else if (cell % 10 === 3 && cell % 100 !== 13) {
        ord = 'rd';
      }

      let month;
      if (row && row["billing_schedule"]) {
        month = CONSTANTS.MONTHS.find(r => r.id === row["billing_schedule"]);
      }

      return <div className="billing-day-format-cont"><span className="day-value"> {cell}{ord} </span></div>
    } else {
      return <div className="billing-day-format-cont"><span className="null-value">{String.fromCharCode(8212)}</span></div>
    }
  };

  return (
      <div className="no-pagination-details">
        <h1 className="text-bold mb-3">Tenants</h1>
        <ParentTable
            table="Tenants"
            keyField="id"
            totalSize={props.totalSize ? parseInt(props.totalSize) : 0}
            data={GetTenantLists}
            page={props.page ? parseInt(props.page) : 1}
            sizePerPage={props.sizePerPage ? parseInt(props.sizePerPage) : 50}
            include='parent'
            columns={[
              {
                dataField: "name",
                text: "Tenant",
                formatter: nameFormatter,
                classes: 'first-cell tenant-name',
                headerStyle: {cursor: 'default'},
                attrs: {
                  'data-label': 'Tenant'
                }
              }, {
                dataField: "use_accounting",
                text: "Use accounting",
                align: "center",
                formatter: useAccountingFormatter,
                headerStyle: () => {
                  return { 'textAlign': 'center' };
                },
                attrs: {
                  'data-label': 'Use accounting'
                }
              }, {
                dataField: "billing_day",
                text: "Invoice billing day",
                align: "center",
                classes: 'last-cell tenant-billing',
                formatter: billingDayFormatter,
                headerStyle: () => {
                  return { 'textAlign': 'center', cursor: 'default' };
                },
                attrs: {
                  'data-label': 'Invoice billing day'
                }
              }
            ]}
            rowAction="link"
            headerDropdownOpts={[{
              label: 'Add',
              action: TenantForm
            }]}
            noSearch={true}
            cacheSearch={false}
            filters={false}
            notLoadedByDefault={false}
            offLoader={false}
            striped={true}
            noDataIndication="No tenants found."
            noHover={false}
            pagination={false}
            noTopBorder={true}
            classes="table-responsive"
            wrapperClasses="table-tenant f2x-custom-table no-pagination"
          />
      </div>
  )
};

export default withRouter(connect()(Tenants));
