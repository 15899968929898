import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import DatePicker, {registerLocale} from 'react-datepicker';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Form, Input, CustomInput } from "reactstrap";
import { AddFiscalYear } from '../../../controllers/fiscal_years';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate } from "../../../redux/actions/validateActions";
import Toast from "../../../components/Toast";
import {parseDateToSaveFormat} from '../../../redux/actions/formatActions';
import {withTranslation} from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale"; // import all locales we need
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class FiscalYearForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formType: 'Add',
            formName: 'fiscal year',
            fiscal_year: {
                is_closed: false
            }
        }    
    }

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    handleInputChange({ target: { name, value }}) {
        let fiscal_year = Object.assign({}, this.state.fiscal_year);
        
        fiscal_year[name] =  value;

        this.setState({ fiscal_year })
    }

    handleDatePickerChange(name, date) {
        let fiscal_year = Object.assign({}, this.state.fiscal_year);
        const { newDate } = this.props.dispatch(validateDate(date));
        
        fiscal_year[name] =  newDate;
        
        this.setState({ fiscal_year })
    }

    handleCheckChange({ target: { name, checked } }) {  
        let fiscal_year = Object.assign({}, this.state.fiscal_year);
        
        fiscal_year[name] =  checked;

        this.setState({ fiscal_year })
    }

    handleSubmit(e) {
        e.preventDefault();

        let fiscal_year = Object.assign({}, this.state.fiscal_year);
        const { description, date_from, date_to } = fiscal_year,
            { id, loader, dispatch } = this.props;

        //Validations
        if (!description) {
            return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
        }

        if (!date_from) {
            return Toast.fire({ title: 'Date from field is required!', icon: 'warning' })
        }

        if (!date_to) {
            return Toast.fire({ title: 'Date to field is required!', icon: 'warning' })
        }

        fiscal_year.tenant_id = id;
        fiscal_year.date_from = parseDateToSaveFormat(date_from);
        fiscal_year.date_to = parseDateToSaveFormat(date_to);

        if (!loader) {
            dispatch(loaderToggle(true));

            (async () => {
                const { ok } = await AddFiscalYear(fiscal_year);

                this.handleOk(ok);
            })()
        }
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update()
        }
    };

    render() {
        const { show, hide, t } = this.props,
            { formType, formName, fiscal_year } = this.state,
            { description, date_from, date_to, is_closed } = fiscal_year ? fiscal_year : null;

        return (
            <Modal className="form-subscription-lines" isOpen={ show } toggle={ hide } centered>
                <Form onSubmit={ (e) => this.handleSubmit(e) }>
                    <ModalHeader>
                        <span>{ formType } { formName }</span>
                    </ModalHeader>
                    <ModalBody key="0" className="mt-3 mb-3">
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Description:</label>
                            </Col>
                            <Col md={9}>
                                <Input 
                                    name="description" 
                                    placeholder="Description" 
                                    value={ description ? description : '' } 
                                    onChange={ (e) => this.handleInputChange(e) } 
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Date from:</label>
                            </Col>
                            <Col md={9}>
                                <DatePicker
                                    locale={this.getLocale()}
                                    id="date_from"
                                    className="form-control"
                                    name="date_from"
                                    dateFormat={t("date_format_raw")}
                                    autoComplete="off"
                                    placeholderText="Date from"
                                    selected={date_from ? new Date(date_from) : null}
                                    showMonthDropdown
                                    showYearDropdown
                                    onChange={ this.handleDatePickerChange.bind(this, 'date_from') }
                                    ref="date_from"
                                    onKeyDown={(e) => e.preventDefault()}
                                    maxDate={date_to ? new Date(date_to) : null}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Date to:</label>
                            </Col>
                            <Col md={9}>
                                <DatePicker
                                    locale={this.getLocale()}
                                    id="date_to"
                                    className="form-control"
                                    name="date_to"
                                    dateFormat={t("date_format_raw")}
                                    autoComplete="off"
                                    placeholderText="Date to"
                                    selected={ date_to ? new Date(date_to) : null }
                                    showMonthDropdown
                                    showYearDropdown
                                    onChange={ this.handleDatePickerChange.bind(this, 'date_to') }
                                    ref="date_to"
                                    onKeyDown={(e) => e.preventDefault()}
                                    minDate={date_from ? new Date(date_from) : null}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={{ size: 'auto', offset: 3 }}>
                                <CustomInput 
                                    id="is_closed"
                                    type="checkbox"
                                    label="Closed"
                                    name="is_closed"
                                    className="mt-1"
                                    defaultChecked={ is_closed }
                                    onChange={ (e) => { this.handleCheckChange(e) } }
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-between mt-4">
                        <Button className="btn btn-danger" onClick={ hide } data-dismiss="modal">
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button color="primary">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = store => ({
    loader: store.loader
});

export default connect(mapStateToProps)(withTranslation()(withRouter(FiscalYearForm)));