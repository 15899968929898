import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, Input, Col, CustomInput, Row } from "reactstrap";
import { AddPaymentCondition, UpdatePaymentCondition } from '../../../controllers/payment_conditions';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import Toast from "../../../components/Toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

const statusOpt = [{
        value: 1,
        label: 'Active'
    }, {
        value: 0,
        label: 'Inactive'
    }];

class PaymentConditionForm extends React.Component {
    constructor(props) {
        super(props);

        const { selectedData } = this.props;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'payment condition',
            payment_condition: selectedData ? selectedData : { default: 1 },
            status: {
                value: 1,
                label: 'Active'
            }
        }
    }

    handleInputChange({ target: { name, value } }) {
        let payment_condition = Object.assign({}, this.state.payment_condition);

        payment_condition[name] = value;

        this.setState({ payment_condition })
    }

    handleSelectChange = (name, data) => {
        let payment_condition = Object.assign({}, this.state.payment_condition);

        payment_condition[name] = data.value;

        this.setState({
            payment_condition,
            [name]: data
        })
    };

    handleCheckChange({ target: { checked }}, name) {
        let payment_condition = Object.assign({}, this.state.payment_condition);

        payment_condition[name] = checked;

        this.setState({ payment_condition })
    }

    handleSubmit(e) {
        e.preventDefault();

        const { selectedData, loader, dispatch, id } = this.props;
        let payment_condition = Object.assign({}, this.state.payment_condition);

        // Validations
        if (!this.hasValue(payment_condition.description)) {
            return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
        }

        if (!this.hasValue(payment_condition.net_days)) {
            return Toast.fire({ title: 'Net days field is required!', icon: 'warning' })
        }

        if (!loader) {
            dispatch(loaderToggle(true));

            if (selectedData) {
                (async () => {                    
                    const { ok } = await UpdatePaymentCondition(selectedData.id, payment_condition);
                    this.handleOk(ok)
                })();
            } else {
                (async () => {
                    const { ok } = await AddPaymentCondition(id, payment_condition);
                    this.handleOk(ok)
                })();
            }
        }
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update()
        }
    };

    hasValue = (value) => {
        return value !== null && value !== undefined && value !== '' ? true : false
    };

    componentDidMount() {
        const { selectedData } = this.props;

        if (selectedData) {
            const getStatus = statusOpt.find(status => parseInt(status.value) === parseInt(selectedData.status));
            this.setState({ status: getStatus ? getStatus : null })
        }
    }

    render() {
        const { show, hide, loader } = this.props,
            { formType, formName, payment_condition, status } = this.state,
            { description, net_days, direct_debit, pay_in_advance } = payment_condition;

        return (
            <Modal isOpen={show} toggle={hide} centered>
                { !loader && <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <ModalHeader>{formType} {formName}</ModalHeader>
                    <ModalBody className="mt-3 mb-3">
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Description:</label>
                            </Col>
                            <Col md={9}>
                                <Input
                                    id="description"
                                    name="description"
                                    placeholder="Description"
                                    value={this.hasValue(description) ? description : ''}
                                    onChange={(e) => this.handleInputChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Net days:</label>
                            </Col>
                            <Col md={9}>
                                <Input
                                    id="net_days"
                                    name="net_days"
                                    type="number"
                                    placeholder="Net days"
                                    max={999999999}
                                    value={this.hasValue(net_days) ? net_days : ''}
                                    onChange={(e) => this.handleInputChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={3}>
                                <label className="col-form-label">Status:</label>
                            </Col>
                            <Col md={9}>
                                <Select
                                    id="status"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Status"
                                    options={statusOpt}
                                    value={this.hasValue(status) ? status : null}
                                    onChange={this.handleSelectChange.bind(this, 'status')}
                                    maxMenuHeight={300}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={{ size: 'auto', offset: 3 }}>
                                <CustomInput
                                    id="direct_debit"
                                    type="checkbox"
                                    name="direct_debit"
                                    className="mt-2"
                                    label="Direct debit"
                                    defaultChecked={direct_debit ? true : false}
                                    onChange={(e) => this.handleCheckChange(e, 'direct_debit')}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={{ size: 'auto', offset: 3 }}>
                                <CustomInput
                                    id="pay_in_advance"
                                    type="checkbox"
                                    name="pay_in_advance"
                                    className="mt-1"
                                    label="Pay in advance"
                                    defaultChecked={pay_in_advance ? true : false}
                                    onChange={(e) => this.handleCheckChange(e, 'pay_in_advance')}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2">
                            <Col md={{ size: 'auto', offset: 3 }}>
                                <CustomInput
                                    id="default"
                                    type="checkbox"
                                    name="default"
                                    className="mt-1"
                                    label="Default"
                                    defaultChecked={payment_condition.default ? true : false}
                                    onChange={(e) => this.handleCheckChange(e, 'default')}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <span className="btn btn-danger" onClick={hide}>
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                        <Button color="primary">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ModalFooter>
                </Form>
                }
            </Modal>
        )
    }
}

export default withRouter(connect(store => ({
    loader: store.loader
}))(PaymentConditionForm));
