import React, {useState} from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from "react-router-dom";
import { Card, Row, Spinner, Col, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import classnames from 'classnames';
import NavPlanTabs from '../NavPlanTabs';
import {withTranslation} from 'react-i18next';
import {GetTermsConditions} from '../../../controllers/terms_conditions';
import TermConditionForm from './Form';
import RemoveTermConditionForm from './FormRemove';
import ParentTable from '../../../components/ParentTable';
import GroupForm from '../../tenant/groups/Form';
import parse from 'html-react-parser';

const TermsConditions = ({ ...props }) => {

  const dataFormatter = (cell, row, rowIndex) => {
    return (<div>{parse(cell)}</div>);
  };

  return(
    <>
      <h1 className="text-bold mb-3">Terms and Conditions</h1>
      <NavPlanTabs />
      <ParentTable
          table="termsConditions"
          keyField="id"
          data={GetTermsConditions}
          include='parent'
          cacheSearch={true}
          columns={[
            {
              dataField: "description",
              text: "Description",
              sort: true,
              headerStyle: () => {
                  return { width: '25%'};
              },
              classes: "txt-overflow-hide terms-conditions-text",
              attrs: {
                  'data-label': 'Text'
              }
            },
            {
              dataField: "header",
              text: "Header",
              sort: true,
              headerStyle: () => {
                return { width: '40%'};
              },
              classes: "txt-overflow-hide",
              attrs: {
                'data-label': 'Header'
              }
            },
            {
              dataField: "sort",
              text: "Sort",
              sort: true,
              align: "left",
              headerStyle: () => {
                return { width: '15%', 'textAlign': 'left' };
              },
              attrs: {
                'data-label': 'Sort'
              }
            }
          ]}
          rowAction="edit"
          rowActionForm={ TermConditionForm }
          headerDropdownOpts={[{
            label: 'Add',
            action: TermConditionForm
          }]}
          rowDropdownOpts={[{
            action: 'Remove',
            label: 'Remove',
            form: RemoveTermConditionForm
          }]}
          notLoadedByDefault={false}
          offLoader={false}
          striped={true}
          noDataIndication="No terms and conditions found."
          noHover={false}
          noTopBorder={true}
          classes="table-responsive"
          wrapperClasses="table-plans f2x-custom-table"
      />
    </>
  )
};

export default withRouter(withTranslation()(connect()(TermsConditions)));
