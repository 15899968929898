import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Table } from "reactstrap";
import {useTranslation} from 'react-i18next';
import {GetCdrUsageCosts} from '../../../../controllers/invoices';
import {loaderToggle} from "../../../../redux/actions/loaderActions";
import {convertLocalTime} from '../../../../redux/actions/formatActions';
import {addDays} from "date-fns";
import ScrollBar from 'react-perfect-scrollbar';

const PhoneUsage = (props) => {
    console.log('phoneUsage', props);

    const [state, dispatch] = React.useReducer(
        (prevState, action) => {
            switch (action.type) {
                case 'CDR_USAGE_COSTS':
                    return {
                        ...prevState,
                        cdrUsageCosts: action.cdrUsageCosts,
                        loading: false
                    };

                case 'CDR_USAGE_COST_TOTAL':
                    return {
                        ...prevState,
                        total: action.total
                    };

                default:
                    return false;
            }
        },
        {
            cdrUsageCosts: [],
            total: "0,00",
            loading: true
        }
    );

    useEffect(() => {
        console.log('Phone cdrUsageCosts - props ->', props);

        if (props.id) {
            loaderToggle(true);
            const bootstrapAsync = async () => {
                const {data} = await GetCdrUsageCosts(
                    props.id), {items, total_cost} = data && data.data
                    ? data.data
                    : {};

                dispatch({type: 'CDR_USAGE_COSTS', cdrUsageCosts: items});
                dispatch({type: 'CDR_USAGE_COST_TOTAL', total: total_cost});
            };
            bootstrapAsync();
        }
    }, []);

    const { t } = useTranslation();

    const loadItems = () => {
        console.log('Phone cdrUsageCosts', state.cdrUsageCosts);
        if (state.cdrUsageCosts && state.cdrUsageCosts.length > 0) {
            return state.cdrUsageCosts && state.cdrUsageCosts.map((
                {datetime, caller, recipient, duration, cost},
                i) => {
                return <tr key={i}>
                    <td data-label="Date & time">{formatDateTime(datetime)}</td>
                    <td data-label="Caller">{caller}</td>
                    <td data-label="Recipient">{recipient}</td>
                    <td data-label="Duration">{duration ? duration : String.fromCharCode(8212)}</td>
                    <td data-label="Cost" className="text-right">{formatCurrency(cost)}</td>
                </tr>
            });
        } else {
            return <tr key="no-usage-costs">
                <td aria-colspan={5} colSpan={5} className="text-center text-no-usage-costs">No usage costs</td>
            </tr>
        }
    };

    const formatCurrency = (floatValue) => {
        if (floatValue) {
            let floatCell = floatValue ? floatValue : 0;
            let floatStyle = floatCell && parseFloat(floatCell) < 0 ? 'float-negative' : floatCell && parseFloat(floatCell) > 0 ? 'float-positive' : 'float-null';
            return <span className={floatStyle ? `${floatStyle} mr-2` : 'float-zero mr-2'}>{t("currency_format", { number: Number(parseFloat(floatValue)) })}</span>
        } else {
            return <span className="mr-2">{String.fromCharCode(8212)}</span>
        }
    };

    const formatDateTime = (dateTimeStr) => {
        if (dateTimeStr) {
            let dt = convertLocalTime(dateTimeStr);
            return <span className="date-format datetime">{t("date_time_format", { date: new Date(dt) })}</span>
        }
    };

    return (
        <Modal className="modal-phone-usage modal-xl" isOpen={true} toggle={props.hide} scrollable={true} centered>
            <ModalHeader>
                <Row className="align-items-start justify-content-start">
                    <Col xs="7"><h5 className="modal-title">Phone usage records (CDR)</h5></Col>
                    <Col xs="5" className="no-gutters justify-content-end">
                        <Col className="text-right">{formatCurrency(state.total)}</Col>
                    </Col>
                </Row>
            </ModalHeader>
            <ScrollBar className="f2x-ls-det-sb sb-invoices"
                       options={{ suppressScrollX: true }}>
                <ModalBody>
                    <div className="react-bootstrap-table">
                        <Table className="tbl-phone-cdr mb-3">
                            <thead>
                                <tr>
                                    <th style={{ 'width':'27%'}}>Date & time</th>
                                    <th style={{ 'width':'23.5%'}}>Caller</th>
                                    <th style={{ 'width':'23.5%'}}>Recipient</th>
                                    <th style={{ 'width':'13%'}}>Duration</th>
                                    <th style={{ 'width':'12%'}} className="text-right">Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.loading && <tr><td aria-colspan={5} colSpan={5} align="center">Loading ...</td></tr>}
                                {!state.loading && loadItems()}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
            </ScrollBar>
        </Modal>
    )
};

export default PhoneUsage;
