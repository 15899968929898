import React from "react";
import {withTranslation} from 'react-i18next';
import {GetInvoiceLines, GetInvoiceLineGadgets} from '../../../../controllers/invoices';
import InvoiceLineForm from "./Form";
import ParentTable from '../../../../components/ParentTable';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {mvLoaderToggle} from '../../../../redux/actions/mvLoaderActions';
import {loaderToggle} from '../../../../redux/actions/loaderActions';
import CONSTANTS from '../../../../services/constants';

const inv_product_types = CONSTANTS.INVOICE_PRODUCT_TYPES;

class InvoiceLines extends React.Component {
  constructor(props) {
    super(props);

    //console.log("MVDetails - Invoice details - render - InvoiceLines - constructor - props ->", props);

    this.state = {
      isLoadData: false,
      sizePerPage: 50,
      updatedListData: null,
      data: [],

      columns: [{
        dataField: "description",
        text: "Description",
        sort: false,
        classes: 'txt-overflow-hide',
        headerStyle: () => {
          return { width: '23%' }
        },
        attrs: {
          'data-label': 'Description',
        }
      }, {
        dataField: "sales_invoice_line_type",
        text: "Type",
        sort: false,
        formatter: this.formatType,
        headerStyle: () => {
          return { width: '9%' }
        },
        attrs: {
          'data-label': 'Type',
        }
      }, {
        dataField: "period",
        text: "Period",
        classes: 'txt-overflow-hide',
        sort: false,
        formatter: this.formatDate,
        headerStyle: () => {
          return { width: '20%' }
        },
        attrs: {
          'data-label': 'Period',
        }
      }, {
        dataField: "price_per_piece",
        text: "Unit price",
        sort: false,
        align: "right",
        formatter: this.formatCurrency,
        headerStyle: () => {
          return { 'textAlign': 'right', width: '14%' };
        },
        attrs: {
          'data-label': 'Unit price',
        }
      }, {
        dataField: "quantity",
        text: "Quantity",
        align: "center",
        sort: false,
        classes: 'inv-qty',
        formatter: this.checkType,
        headerStyle: () => {
          return { 'textAlign': 'center', width: '9%' };
        },
        attrs: {
          'data-label': 'Quantity',
        }
      }, {
        dataField: "price",
        text: "Price",
        sort: false,
        align: "right",
        formatter: this.formatCurrency,
        classes: 'txt-overflow-hide',
        headerStyle: () => {
          return {textAlign: 'right', width: '11%', minWidth: '100px'};
        },
        attrs: {
          'data-label': 'Price'
        }
      }, {
        dataField: "price_vat",
        text: "VAT",
        sort: false,
        align: "right",
        formatter: this.formatCurrency,
        classes: 'txt-overflow-hide',
        headerStyle: () => {
          return {textAlign: 'right', width: '11%', minWidth: '100px'};
        },
        attrs: {
          'data-label': 'VAT',
        }
      }, {
        dataField: "price_total",
        text: "Total",
        sort: false,
        align: "right",
        classes: 'txt-overflow-hide',
        formatter: this.formatCurrency,
        headerStyle: () => {
          return {textAlign: 'right', width: '11%', minWidth: '100px'};
        },
        attrs: {
          'data-label': 'Total',
        }
      }]
    }
  };

  updateDetails = (str) => {
    //return str === 'update' ? this.props.updateDetails(str) : false

    console.log("processUpdateApi - str ->", str);

    const {id, setIsUpdated, data} = this.props;
    //let res = str === 'inv_line_update' ? this.props.updateDetails(id) : false;
    let res = str === 'inv_line_update' ? this.props.updateDetails(id, "update", data) : false;

    //this.props.updateDetails(data.data.id, 'update', data.data);

    this.setState({ isInvoiceLineUpdated: true });
    setIsUpdated(true);
  };

  hasLoaded = (size) => {
    //console.log("MVDetails - Invoice details - render - InvoiceLines - hasLoaded - size ->", size);

    return this.props.tableLoaded(size);
  };

  dataLoaded = () => {
    //this.props.setIsUpdated(true);
    //this.props.setIsListUpdated(true);
    this.props.setIsListUpdated(true);
  };

  formatDate = (cell, row) => {
    const {t} = this.props;
    if (row && (row.invoice_start || row.invoice_stop)) {
      let invoice_start = row.invoice_start ? t("date_format", { date: new Date(row.invoice_start) }) : '';
      let upToStr = t("up_to_text");
      let invoice_stop = row.invoice_stop ? t("date_format", { date: new Date(row.invoice_stop) }) : false;
      if (invoice_stop) {
        return <span className="date-format">{invoice_start + ' ' + upToStr + ' ' + invoice_stop}</span>
      } else {
        return <span className="date-format">{invoice_start}</span>
      }

    } else {
      return <span>{String.fromCharCode(8212)}</span>
    }
  };

  formatType = (cell, row) => {
    if (cell && inv_product_types) {
      //inv_product_types
      const getLineType = inv_product_types.find(line => parseInt(line.id) === cell);
      console.log("getLineType ->", getLineType);
      if (getLineType && getLineType.type) {
        return getLineType.type;
      } else {
        //return cell;
        return <span>{String.fromCharCode(8212)}</span>
      }
    } else {
      return <span>{String.fromCharCode(8212)}</span>
    }
  };

  checkType = (cell, row) => {
    if (cell && row && row.sales_invoice_line_type && row.sales_invoice_line_type === 99) {
      return "";
    } else {
      return cell;
    }
  };

  formatCurrency = (cell, row) => {
    //console.log("MVDetails - Invoice details - render - InvoiceLines - formatCurrency - cell ->", cell);
    //console.log("MVDetails - Invoice details - render - InvoiceLines - formatCurrency - row ->", row);

    if (row && row.sales_invoice_line_type && row.sales_invoice_line_type === 99) {
      return "";

    } else {
      const {t} = this.props;
      let floatCell = cell ? cell : 0;
      let floatStyle = floatCell && parseFloat(floatCell) < 0
          ? 'float-negative'
          : floatCell && parseFloat(floatCell) > 0
              ? 'float-positive'
              : 'float-null';

      return <span className={floatStyle ? floatStyle : 'float-zero'}>{t(
          "currency_format", {number: Number(parseFloat(floatCell))})}</span>
    }
  };

  componentDidMount() {
    const { updatedListData } = this.state;
    if (!updatedListData) {
      this.updateInvoiceLineDataTable();
    }

  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, subscription, data, isUpdated, id, setIsUpdated, tableLoaded } = this.props;
    const { subscrLinesPrices, isSubscriptionLineUpdated, columns } = this.state;

    //console.log("Invoices ListDetails - invoiceLine isUpdated !!!!!!! ->", isUpdated);

    if (isUpdated) {
      this.getData();
      //setIsUpdated(true); //false
    }
  };

  updateInvoiceLineDataTable = () => {
    this.getData();
  };

  getData = () => {
    const { dispatch, id, loader } = this.props;
    const { sizePerPage } = this.state;

    let param = {
      page: 1,
      offset: sizePerPage
    };

    (async () => {
      //dispatch(mvLoaderToggle(true));
      //if (!loader) {
        dispatch(loaderToggle(true));
      //}

      await GetInvoiceLines(param, id)
      .then(res => {
        if (res.ok) {
          const { data } = res;

          this.setState({
            updatedListData: data.data,
            data: data.data
          });
        }
      })
      .catch(e => {
        console.log('Error', e);
      });

      //dispatch(mvLoaderToggle(false));
      //if (loader) {
        dispatch(loaderToggle(false));
      //}

    })();
  };

  render() {
    const { id, isUpdatable, noLoader, isLoadData, isUpdated, setIsUpdated/*, setData*/ } = this.props;
    const { data, columns, sizePerPage, updatedListData } = this.state;
    //console.log("MVDetails - Invoice details - render - InvoiceLines - render - props - id ->", id);
    //console.log("MVDetails - Invoice details - render - InvoiceLines - render - props - this.props ->", this.props);

    return (
      <React.Fragment>
        <ParentTable
          id={id}
          keyField={"id"}
          table="Invoice-lines"
          remote
          data={data}
          rawData={data}
          isLoadData={isLoadData ? isLoadData : false}
          columns={columns}
          headerDropdownOpts={isUpdatable ? [{
            label: 'Add',
            action: InvoiceLineForm
          }] : null}
          rowAction="edit"
          rowActionForm={InvoiceLineForm}
          asyncRowDropdownOpts={[{
            get: GetInvoiceLineGadgets,
            condition: {
              data: 'has_gadget'
            }
          }]}
          asyncRowDropDownStyle={{
            classes: 'action-inv-col',
            headerStyle: () => {
              return {width: '7%', minWidth: '100px'}
            }
          }}
          noSearch={true}
          cacheSearch={false}
          sizePerPage={sizePerPage} //50
          wrapperClasses="table-invoice-lines"
          updateDetails={this.props.updateDetails}
          hasLoaded={this.hasLoaded}
          dataLoaded={this.dataLoaded}
          updateTableRawData={this.updateInvoiceLineDataTable}
          notLoadedByDefault={false}
          noDataIndication="No invoice lines found."
          isRawData
          //noLoader={noLoader}
          //offLoader
          //noLoader={false}
          //offLoader={false}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({dispatch, invoice, relation, loader}) => ({dispatch, invoice, relation, loader});

export default withRouter(withTranslation()(connect(mapStateToProps)(InvoiceLines)));
//export default withRouter(withTranslation()(connect()(InvoiceLines)));
