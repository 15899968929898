import React from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {
    GetSubscriptionLines,
    GetSubscription,
    UpdateSubscription,
    GetAddresses,
    GetPlanSubscriptionLineTypes,
    GetPersons,
    GetContractPeriodList,
    GetStatusList,
    GetSubscriptionStatuses, 
    RemoveSubscription,
} from '../../../controllers/subscriptions';
import {GenerateSubscriptionInvoice} from "../../../controllers/invoices";
import {GetInvoices} from '../../../controllers/relations';
import JsonDataForm from "./json_data/Form";
import Details from "../../../components/Details";
import ModalWrapper from '../../../components/ModalWrapper'
import ActivityLogs from './ActivityLogs'
import SubscriptionLines from "./subscription_lines/List";
import Unauthorized from "../../auth/Unauthorized";
import {loaderToggle} from "../../../redux/actions/loaderActions";
import {addDays} from "date-fns";
import {withTranslation} from 'react-i18next';
import Swal from "sweetalert2";
import {mvLoaderToggleInv} from '../../../redux/actions/mvLoaderInvoiceActions';
import {Badge, Col, Row} from 'reactstrap';
import Loader from '../../../components/Loader';
import {GetOptionAddress, GetOptionPersons} from '../../../controllers/relations';
import Toast from '../../../components/Toast';
import {mvLoaderToggle} from '../../../redux/actions/mvLoaderActions';
import {simulateTabClick} from "../../../hooks/useSimulateTabClick";

const disabledChecker = (data) => {
    return !(data && data['json_datas'] && data['json_datas'].length > 0)
};

const disabledCreateInvoiceChecker = (data) => {
    return (data && data.status === 0) || (data && data.status === 99) // TO DO ask niels for terminated subscription delete is allowed
};

const disabledContractPeriodChecker = (data) => {
    return !disabledCreateInvoiceChecker(data)
};

const disabledIfInvoicedChecker = (data) => {
    return disabledIfTerminatedStatusChecker(data) || (data && data['is_invoiced'] === true)
};

const disabledIfInvoicedCheckerBillingStart = (data) => {
    return disabledIfTerminatedStatusChecker(data) || (data && data['is_invoiced']) || (data && data.subscription_start === null)
};

const disabledIfTerminatedStatusChecker = (data) => {
    return data && data.status === 99
};

const disabledIfRelatedChecker = (data) => {
    return disabledIfInvoicedChecker(data) && (data && data['is_invoiced']) || (data && data['json_datas'] && data['json_datas'].length > 0)
};

const formatSubscriptionStatus = (data) => {
    //console.log("formatSubscriptionStatus - data ->", data);

    const statusLabels = [];
    statusLabels[10] = 'NEW';
    statusLabels[20] = 'CONCEPT';
    statusLabels[30] = 'PROVISIONING';
    statusLabels[40] = 'PLANNED';
    statusLabels[50] = 'ONGOING';
    statusLabels[60] = 'CANCELLED';
    statusLabels[80] = 'DE-PROVISIONING';
    statusLabels[99] = 'TERMINATED';
};

class SubscriptionDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasLoaded: false,
            isOpen: false,
            isOpenLogs: false,
            dropdownItems: [{
                label: 'View details',
                function: 'toggleModal',
                disabled: disabledChecker
            }, {
                label: 'Delete',
                function: 'deleteSubscription',
                disabled: disabledIfRelatedChecker
            }, {
                label: 'Create concept invoice',
                function: 'generateSubscriptionInvoice',
                disabled: disabledCreateInvoiceChecker
            }, {
                label: 'View logs',
                function: 'toggleModalLogs',
                enabled: true
            }],
            isLoading: false,
            debugInfo: false //process.env.REACT_APP_ENV !== "production"
        };
    }

    triggerAction = (action, data, e) => {
        //console.log("SubscriptionDetails - triggerAction - action ->", action);
        //console.log("SubscriptionDetails - triggerAction - data ->", data);
        //console.log("SubscriptionDetails - triggerAction - e ->", e);

        if (action) {
            //setTimeout(() => {
                this[action](e);
                this.setState({actionData: data})
            //}, 1000);
        }
    };

    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };

    toggleModalLogs = () => {
        this.setState({ isOpenLogs: !this.state.isOpenLogs })
    };

    deleteSubscription = (e, id) => {
        const {dispatch, relation, subscription, history, setSubscriptionListUpdatedDetails, updateDetails} = this.props;
        const { data } = this.state;

        const subscriptionData = data;

        if (subscriptionData && (subscriptionData.is_invoiced === false || subscriptionData.is_invoiced === null) && subscriptionData.json_datas === null && subscriptionData.status === 20) {
            //console.log("subscription delete allowed");
            //console.log("subscriptionDetails", subscriptionDetails);

            // Ask to delete subscription
            Swal.fire({
                title: '',
                text: 'Are you sure you want to delete subscription?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            })
            .then((r) => {
                dispatch(loaderToggle(true));

                if (r.value) {
                    (async () => {
                        const { ok, data } = await RemoveSubscription(subscriptionData.id);
                        if (ok) {
                            let newDataSubscriptionList = [];
                            sessionStorage.removeItem('lastVisitSubscrId');

                            if (data.data && data.data.length === 0) {
                                dispatch({type: 'INVOICE', payload: {subscriptionList: []}});
                                dispatch({type: 'RELATION', payload: {subscriptionList: []}});

                                this.props.setData(null);

                                history.push(`/relations/${relation.id}/subscriptions`);

                            } else {

                                if (data.data && data.data.length > 0) {
                                    let subscrId = data.data[0].id;

                                    newDataSubscriptionList = data.data;

                                    dispatch({type: 'INVOICE', payload: {subscriptionList: newDataSubscriptionList}});
                                    dispatch({type: 'RELATION', payload: {subscriptionList: newDataSubscriptionList}});

                                    this.props.updateDetails(subscriptionData.id, 'delete', newDataSubscriptionList);

                                    history.push(`/relations/${relation.id}/subscriptions/${subscrId}`);

                                } else {
                                    this.props.updateDetails(subscriptionData.id, 'delete', []);

                                    dispatch({type: 'INVOICE', payload: {subscriptionList: []}});
                                    dispatch({type: 'RELATION', payload: {subscriptionList: []}});
                                }
                            }
                            dispatch(loaderToggle(false));

                        }
                        dispatch(loaderToggle(false));

                    })();

                    dispatch(loaderToggle(false));
                    Toast.fire({title: "Subscription deleted", icon: 'success'}).then(r => { console.log(r) });

                } else if (r.dismiss === Swal.DismissReason.cancel) {
                    dispatch(loaderToggle(false));

                } else {
                    dispatch(loaderToggle(false));
                }
            });
        } else {
            dispatch(loaderToggle(false));

            Toast.fire({title: "Subscription delete is not allowed", icon: 'warning'}).then(r => { console.log(r) });
        }
    };

    generateSubscriptionInvoice = (ev) => {
        //console.log("generateSubscriptionInvoice - ev ->", ev);
        //console.log("generateSubscriptionInvoice - this.props ->", this.props);

        const {relationId} = this.props;

        // ask confirmation
        Swal.fire({
            title: '',
            text: 'Generate concept invoice for this subscription?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then(r => {
            const {dispatch, history} = this.props;

            // let stop = null;
            if (r.value) {
                dispatch(loaderToggle(true));

                //history.push("/invoices");
                sessionStorage.removeItem('lastVisitInvId');
                
                dispatch({type: 'INVOICE', payload: {}});
                dispatch({type: 'INVOICE', payload: {invoiceList: []}});

                (async () => {
                    const {match} = this.props;
                    const id = match && match.params && match.params.subscrId
                        ? match.params.subscrId
                        : this.props.id;
                        
                    //console.log("generateSubscriptionInvoice - id ->", id);

                    const {data} = await GenerateSubscriptionInvoice(id);
                    //console.log("generateSubscriptionInvoice - success ->", success);
                    //console.log("generateSubscriptionInvoice - data ->", data);

                    //sessionStorage.removeItem('lastVisitInvId');

                    if (data && data.data) {
                        let url;
                        if (data.data.length > 1) {
                            url = `/relations/${relationId}/invoices/${data.data[1].id}/`;
                            //sessionStorage.setItem('lastVisitInvId', data.data[1].id);
                        } else if (data.data.length === 1) {
                            url = `/relations/${relationId}/invoices/${data.data[0].id}/`;
                            //sessionStorage.setItem('lastVisitInvId', data.data[0].id);
                        } else {
                            url = `/relations/${relationId}/invoices/`;
                            //sessionStorage.removeItem('lastVisitInvId');
                        }

                        //console.log("generateSubscriptionInvoice - url ->", url);
                        history.push(url);

                        //let el = document.getElementById("tab-item-invoices");
                        //await simulateTabClick(el);

                    } else {

                        console.log("generateSubscriptionInvoice (after) -> Error creating concept invoice from subscription");
                        //const invoicesData = await GetInvoices(null, parseInt(relationId));
                        //console.log("generateSubscriptionInvoice - invoicesData ->", invoicesData);
                        // Get last invoice ID 


                        //let el = document.getElementById("tab-item-invoices");
                        //setTimeout(() => {
                            //simulateTabClick(el);
                        //}, 500);
                    }
                })();
            }
            dispatch(loaderToggle(false));
        });
    };

    setDropdownItems = async (data) => {
        if (!data) {
            data = this.state.details;
        }

        const { dropdownItems } = this.state;

        await this.setState({
            dropdownItems
        });
    };

    formatPriceInclVatExclVat = async (data, type) => {
        let priceData;
        let incl_vat;
        let excl_vat;

        if (type) {
            switch (type) {
                case 'nrc':
                    incl_vat = type + "_total_inc_vat";
                    excl_vat = type + "_total";
                    priceData = data[excl_vat] + " / " + data[incl_vat];
                    break;
                case 'mrc':
                    incl_vat = type + "_total_inc_vat";
                    excl_vat = type + "_total";
                    priceData = data[excl_vat] + " / " + data[incl_vat];
                    break;
                case 'yrc':
                    incl_vat = type + "_total_inc_vat";
                    excl_vat = type + "_total";
                    priceData = data[excl_vat] + " / " + data[incl_vat];
                    break;
                case 'qrc':
                    incl_vat = type + "_total_inc_vat";
                    excl_vat = type + "_total";
                    priceData = data[excl_vat] + " / " + data[incl_vat];
                    break;
                case 'deposit':
                    incl_vat = type + "_total_inc_vat";
                    excl_vat = type + "_total";
                    priceData = data[excl_vat] + " / " + data[incl_vat];
                    break;
                default:
                    break;
            }

            return priceData;
        }
    };

    dataLoaded = (data, renderCount) => {
        const {dispatch, subscription, relation, subscriptionStatuses} = this.props;
        const {personOpt, addressOpt} = relation;
        const subscriptionData = data;

        if (data) {
            this.setState({
                data: subscriptionData,
            });

            if (!renderCount) {
                this.setDropdownItems(data).then(r => {
                    if (r) {
                        console.log("setDropdownItems on func dataLoaded - with !renderCount - r ->", r);
                    }
                });
            }

            let types = [], persons = [], addresses = [],
                provisioning_addresses = [], billing_addresses = [],
                contract_periods = [], status_opts = [];

            (async () => {
                const [line_type, person, address, contract_period/*, statuses*/] = await Promise.all([
                    GetPlanSubscriptionLineTypes(),
                    GetOptionPersons(null, relation.id),
                    GetOptionAddress(null, relation.id),
                    GetContractPeriodList(null, relation.id),
                ]);

                await (() => {
                    if (line_type.ok) {
                        line_type.data.data.map(({ line_type, id }) => {
                            if (line_type === 'YRC' || line_type === 'QRC' || line_type === 'MRC') {
                                return types.push({
                                    label: line_type,
                                    value: id
                                })
                            } else {
                                return false
                            }
                        })
                    }

                    if (person.ok) {
                        person.data.data.map(({ full_name, id }) => {
                            return persons.push({
                                label: full_name,
                                value: id,
                            })
                        })
                    }

                    if (address.ok) {
                        address.data.data.map(({ address_type, full_address, id, location_id }) => {
                            return addresses.push({
                                label: full_address,
                                value: id,
                                location_id: location_id
                            })
                        });
                    }

                    if (address.ok) {
                        address.data.data.map(({ address_type, full_address, id, location_id }) => {
                            if (address_type === 'Provisioning') {
                                return provisioning_addresses.push({
                                    label: full_address,
                                    value: id,
                                    location_id: location_id
                                })
                            } else if (address_type === 'Billing') {
                                return billing_addresses.push({
                                    label: full_address,
                                    value: id,
                                    location_id: location_id
                                })
                            } else {
                                return false
                            }
                        })
                    }

                    if (contract_period.ok) {
                        contract_period.data.data.map(({ id, period, net_days }) => {
                            return contract_periods.push({
                                label: period,
                                value: id
                            })
                        })
                    }

                    if (subscriptionStatuses) {
                        status_opts = subscriptionStatuses;
                    }

                    this.setState({
                        ...this.state,
                        ...{ hasLoaded: true }
                    });
                })();

                //this.setState({
                let groups = {
                    subscription: {
                        title: 'Subscription details',
                        details: [
                            {
                                label: 'Description',
                                data: 'description',
                                type: 'text',
                                style: 'text_style', // format as string
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }, {
                                label: 'Customer #',
                                data: 'relation.customer_number',
                                disabled: true,
                                style: 'text_style', // format as string
                                //type: 'link',
                                //link: '/relations/:var/details',
                                //var: 'relation.id',
                                //addClass: 'font-weight-bold',
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }, {
                                label: 'Plan',
                                data: 'plan.description',
                                type: 'text',
                                disabled: true,
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }, {
                                label: 'Status',
                                data: 'status', //'status',
                                type: 'select',
                                style: 'update_subscription_status_style',
                                opts: status_opts
                            }, {
                                label: 'Subscription start',
                                data: 'subscription_start',
                                type: 'datepicker',
                                maxDate: 'subscription_stop',
                                disabledIfTrue: disabledIfInvoicedChecker
                            }, {
                                label: 'Subscription end',
                                data: 'subscription_stop',
                                type: 'datepicker',
                                minDate: 'subscription_start'
                            }, {
                                label: 'Wish date',
                                data: 'wish_date',
                                minDate: 'raw:' + addDays(new Date(), 14),
                                type: 'datepicker',
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }, {
                                label: 'Cost center/ref',
                                data: 'cost_center',
                                type: 'input',
                                style: 'text_style' // format as string
                            }/*, {
                                label: null,
                                data: 'update_line_stop',
                                type: 'hidden',
                                addClass: 'd-none'
                            }, {
                                label: null,
                                data: 'update_line_start',
                                type: 'hidden',
                                addClass: 'd-none'
                            }*/
                        ]
                    }, finance: {
                        title: 'Finance',
                        details: [
                            {
                                label: 'Contract period',
                                data: 'contract_period_id', //contract_period_id
                                type: 'select',
                                opts: contract_periods,
                                //disabledIfTrue: disabledContractPeriodChecker
                            }, {
                                label: 'Subscription type',
                                data: 'type',
                                type: 'select',
                                opts: types,
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }, {
                                label: 'Billing start',
                                data: 'billing_start',
                                minDate: 'subscription_start',
                                type: 'datepicker',
                                disabledIfTrue: disabledIfInvoicedCheckerBillingStart,
                            }, {
                                label: 'NRC total',
                                data: 'nrc_total_inc_vat',
                                price_incl: 'nrc_total_inc_vat',
                                price_excl: 'nrc_total',
                                disabled: true
                            }, {
                                label: 'MRC total',
                                data: 'mrc_total_inc_vat',
                                price_incl: 'mrc_total_inc_vat',
                                price_excl: 'mrc_total',
                                disabled: true
                            }, {
                                label: 'QRC total',
                                data: 'qrc_total_inc_vat',
                                price_incl: 'qrc_total_inc_vat',
                                price_excl: 'qrc_total',
                                disabled: true
                            }, {
                                label: 'YRC total',
                                data: 'yrc_total_inc_vat',
                                price_incl: 'yrc_total_inc_vat',
                                price_excl: 'yrc_total',
                                disabled: true
                            }, {
                                label: 'Deposit total',
                                data: 'deposit_total_inc_vat',
                                price_incl: 'deposit_total_inc_vat',
                                price_excl: 'deposit_total',
                                disabled: true
                            }
                        ]
                    }, provisioning: {
                        title: 'Provisioning address',
                        details: [
                            {
                                label: 'Address',
                                data: 'provisioning_address_id',
                                type: 'select',
                                opts: provisioning_addresses,
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }, {
                                label: 'Person',
                                data: 'provisioning_person_id',
                                type: 'select',
                                opts: persons,
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }
                        ]
                    }, billing: {
                        title: 'Billing address',
                        details: [
                            {
                                label: 'Address',
                                data: 'billing_address_id',
                                type: 'select',
                                opts: billing_addresses && billing_addresses.length > 0 ? billing_addresses : addresses,
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }, {
                                label: 'Person',
                                data: 'billing_person_id',
                                type: 'select',
                                opts: persons,
                                disabledIfTrue: disabledIfTerminatedStatusChecker
                            }
                        ]
                    }
                };

                this.setState({
                    groups,
                    details: subscriptionData,
                    dropdownItems: this.state.dropdownItems
                });
            })();

        }
        dispatch(loaderToggle(false));
    };

    reload = (str) => {
        const { dispatch, subscriptionList, setData, dataDetails, updateDetails } = this.props;
        dispatch(loaderToggle(true));

        const dropdownItems = Object.assign([], this.state.dropdownItems);

        this.setState({
            dropdownItems,
            groups: null
        });

        if (!str) {
            (async () => {
                await this.setState({ size: undefined });
                this.setState({ size: null })
            })();
        }
        dispatch(loaderToggle(false));
    };

    tableLoaded = (size) => {
        (async () => {
            await this.setState({ size });
            await this.setDropdownItems();
        })();
    };

    subscriptionListUpdateDetails = (subscriptionDetailsUpdatedData) => {
        if (subscriptionDetailsUpdatedData) {
            this.props.setSubscriptionListUpdatedDetails(subscriptionDetailsUpdatedData);
        }
    };

    componentDidMount() {
        const { relation, relationId, setRelationId, data } = this.props;

        let { id, match, setSelectedSubscr } = this.props;
        let { dropdownItems } = this.state;

        if (id && match && match.params && match.params.subscrId && parseInt(id) !== parseInt(match.params.subscrId)) {
            setSelectedSubscr(parseInt(match.params.subscrId));
        }

        this.setState({
            size: null
            //dropdownItems: this.setDropdownItems()
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { id, relationId, deleteConceptEnabled, match, subscriptionList, updateDetails, setIsUpdated, isUpdated, setData, dataDetails, subscription, relation, setRelationId, setSelectedSubscr, user, dispatch, data } = this.props;

        if (id && this.props.match.params && this.props.match.params.subscrId && parseInt(this.props.match.params.subscrId) !== parseInt(id)) {
            setSelectedSubscr(parseInt(this.props.match.params.subscrId));
            setRelationId(parseInt(this.props.match.params.relationId));

            this.setState({size: null});

        } else {
            if (id && prevProps.id !== id) {
                (async () => {
                    await this.setState({
                        size: undefined, //dropdownItems: undefined,
                        finance: undefined,
                        details: undefined,
                        hasLoaded: false,
                    });

                    // this state update can also be placed in async after await
                    this.setState({size: null});
                })();
            }
        }
    }

    render() {
        const { size, groups, details, isOpen, actionData, isOpenLogs, newData, subscriptionPricesData, subscriptionLinesData, isStatusTerminated, dropdownItems, hasLoaded, isLoading, debugInfo } = this.state,
              { id, selectedSubscr, deleteConceptEnabled, refreshSubscriptionList, error, match, subscriptionList, updateDetails, setIsUpdated, isUpdated, setIsListUpdated, dataDetails, setData, user, data, dispatch, relation, relationId, setRelationId } = this.props,
              name = user.tenant && user.tenant.name ? user.tenant.name : '',
              path = match.path ? match.path : null;

        // for fade in transition add className to row (subscription-details-container) - panel-fade-in -> Row
        return (
            <React.Fragment>
                {!hasLoaded && <Loader customClass="custom-loader-pos"/>}
                {!error ?
                <Row style={{display:`${hasLoaded ? 'flex' : 'none'}`}} className={`${hasLoaded ? 'subscription-details-container' : 'subscription-details-container'}`}>
                    <Col className="det-invoice-cont">
                        {/*(size !== undefined && size !== null && dropdownItems) &&
                            size !== null &&*/
                        <Details
                            name="Subscription"
                            title={debugInfo && id ? `${name} Subscription #${id} / ${selectedSubscr} - Relation #${relationId} / #${relation.id}` : id ? `${name} Subscription #${id}` : ""}
                            getApi={GetSubscription}
                            updateApi={UpdateSubscription}
                            id={id} // id //selectedSubscr
                            setRelationId={setRelationId}
                            relationId={relationId}
                            groups={groups}
                            dropdownItems={dropdownItems}
                            triggerAction={this.triggerAction}
                            path={path ? path : ''}
                            data={data ? data : null}
                            dataLoaded={this.dataLoaded}
                            newData={newData ? newData : null}
                            setIsUpdated={setIsUpdated}
                            isUpdated={isUpdated}
                            tableFirst={false} // original is TRUE -> not working with subscriptions
                            noLoader
                            offLoader
                            //mvLoader={mvLoaderToggle} // mvLoaderToggleInv
                            updateDetails={updateDetails}
                            //setStatus={this.props.setStatus}
                            setData={setData}
                            subscriptionList={subscriptionList}
                            isLoading={isLoading}
                            refreshSubscriptionList={refreshSubscriptionList}
                        />}
                        {/*hasLoaded && groups && id &&
                         size !== undefined*/ hasLoaded && selectedSubscr &&
                        <SubscriptionLines
                            noLoader
                            id={id} // id //selectedSubscr
                            updateDetails={this.reload}
                            setData={setData}
                            isUpdatable={details !== undefined ? details['is_updatable'] : false}
                            isDisabled={isStatusTerminated}
                            //dataPrices={subscriptionPricesData}
                            //data={subscriptionLinesData}
                            tableLoaded={this.tableLoaded}
                            setIsUpdated={setIsUpdated}
                            setIsListUpdated={setIsListUpdated}
                            isUpdated={isUpdated}
                            size={size}
                            //groups={groups}
                            //details={details}
                            //dataDetails={dataDetails}

                            //updatePricesData={this.updatePricesData}
                        />}
                        {isOpen &&
                        <JsonDataForm
                            show={isOpen}
                            hide={this.toggleModal}
                            selectedData={actionData}
                        />
                        }
                        {isOpenLogs &&
                        <ModalWrapper title="Activity Log" className="f2x-modal-logs" show={isOpenLogs} hide={this.toggleModalLogs}>
                            <ActivityLogs
                                dispatch={dispatch}
                                id={id}
                            />
                        </ModalWrapper>
                        }
                    </Col>
                </Row> :
                <Unauthorized />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ dispatch, user, loader, error, subscription, invoice, relation }) => ({ dispatch, user, loader, error, subscription, invoice, relation });

export default connect(mapStateToProps)(withTranslation()(withRouter(SubscriptionDetail)));
