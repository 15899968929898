import axios from "axios";
import decode from 'jwt-decode';

export default class AuthService {
    isError = false;
    access_token;

    login(params) {
        // Get a token from api server using the fetch api
        return new Promise((resolve, reject) => {
            this.fetch(`auth/login`, params)
                .then(res => {
                    // Setting the token in localStorage
                    this.setToken(res.data.access_token, res.config.data);
                    // Setting the relation ID(s) in localStorage
                    localStorage.setItem('relation_ids', res.data.relation_ids && res.data.relation_ids ? res.data.relation_ids : []); // default NL -> 155
                    // Setting the defaults in sessionStorage
                    sessionStorage.setItem('hasError', false);
                    sessionStorage.setItem('default_country_id', res.data.tenant && res.data.tenant.default_country_id ? res.data.tenant.default_country_id : 155); // default NL -> 155
                    sessionStorage.removeItem('error');
                    this.isError = false;
                    resolve(res);
                })
                .catch(err => {
                    this.isError = true;
                    reject(err)
                })
        })
    }

    forgotPassword(params) {
        // Get a token from api server using the fetch api
        return new Promise((resolve, reject) => {
            this.fetch(`user/forgot-password`, params)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // Getting token from localStorage
        return !!token && !this.isTokenExpired(token) // Hand waving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000;
        } catch (err) {
            return false;
        }
    }

    setToken(idToken, data) {
        this._data = data;
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from sessionStorage
        return localStorage.getItem('id_token')
    }

    getEmail() {
        // Retrieves the user email from sessionStorage
        return sessionStorage.getItem('email')
    }

    logout() {
        // Clear localStorage/sessionStorage
        localStorage.clear();
        sessionStorage.clear();

        const targetEl = document.getElementById("root");
        if (targetEl) {
            targetEl.style.removeProperty("width");
        }

        // Redirect to Sign-in screen with redirectUrl
        const urlParams = new URLSearchParams(window.location.search);

        //console.log("AuthService - urlParams ->", urlParams);
        //console.log("AuthService - window.location ->", window.location);
        //console.log("AuthService - window.location.pathname ->", window.location.pathname);
        //console.log("AuthService - window.location.search ->", window.location.search);
        //console.log("AuthService - window.location.pathname ->", window.location.pathname);

        //return false;

        //if (!urlParams.get('redirectUrl') && !window.location.pathname.includes('redirectUrl')) {
        if (!urlParams.get('redirectUrl')) {
            window.location.href = `/auth/sign-in?redirectUrl=${window.location.pathname}`;
        }
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken())
    }

    fetch(url, user) {
        return new Promise((resolve, reject) => {
            axios.post(url, user)
                .then(this._checkStatus)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response)
                })
        })
    }

    _checkStatus(response) {
        // Raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error
        }
    }

    updateError(val, res) {
        sessionStorage.setItem('hasError', val);
        if (!val) {
            sessionStorage.removeItem('error')
        } else if (val) {
            const error = JSON.stringify({
                status: res && res.status ? res.status : '',
                statusText: res && res.statusText ? res.statusText : ''
            });

            sessionStorage.setItem('error', error)
        }
    }

    checkIsError() {
        return sessionStorage.getItem('hasError') === 'true'
    }
}
