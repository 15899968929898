import * as types from "../constants";

export function tenants(tenants) {
  return {
    type: types.TENANTS,
    payload: tenants
  };
}

export function addTenant(tenant) {
  return {
    type: types.ADD_TENANT,
    payload: tenant
  };
}