import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker, { registerLocale } from 'react-datepicker';
import hljs from "highlight.js/lib/core";
import ReactQuill, { Quill, Mixin, Toolbar } from "react-quill";
import NumberFormat from 'react-number-format';
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import useMyDropZone from "../../../hooks/useMyDropZone";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Form, Label, FormGroup, CustomInput, Input, Row, Col, Table, InputGroup, InputGroupAddon } from "reactstrap";
import { PlusSquare, XSquare, FileText } from "react-feather";
import { faSearchPlus, faFileAlt, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  GetTermCondition,
  AddTermCondition,
  UpdateTermCondition,
  GetTermConditionAttachments,
  DownloadSingleTermsConditionsAttachment,
} from '../../../controllers/terms_conditions';
import {
  GetAllAttachments,
  GetSingleAttachment,
  AddAttachments,
  UpdateAttachments,
  RemoveAttachments,
  AddAttachmentsWithContentType,
} from '../../../controllers/attachments';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import {
  validateBarcode,
  validateDate,
} from '../../../redux/actions/validateActions';
import {
  formatNumber, parseDateToSaveFormat,
} from '../../../redux/actions/formatActions';
import { enGB, enUS, nl } from 'date-fns/locale';
import { AddProduct, UpdateProduct } from '../../../controllers/products';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import ImageResize from "quill-image-resize-module-react";
import ZoomedHTMLScreen from "../../../components/ZoomedHTMLScreen";

Quill.register('modules/imageResize', ImageResize);

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  },
  clipboard: {
    matchVisual: false
  }
};

const TermConditionForm = ({ ...props }) => {
  console.log("TermConditionForm - props.selectedData ->", props.selectedData);
  const { show, hide, update, loader, selectedData, dispatch } = props;

  const [fileTouched, setFileTouched] = useState(false);
  const [files, setFiles] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [termsConditions, setTermsConditions] = useState(selectedData ? selectedData : {
    header: null,
    text: null,
    sort: 0,
    attachments: [],
  });
  const [formType, setFormType] = useState(selectedData ? 'Edit' : 'Add');
  const [formName, setFormName] = useState(selectedData && selectedData.description ? `terms and conditions | ${selectedData.description}` : 'terms and conditions');
  const [zoomModalOpenNested, setZoomModalOpenNested] = useState(false);
  const [zoomedHtml, setZoomedHtml] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    console.log("useMyDropZone - acceptedFiles ->", acceptedFiles);
    console.log("useMyDropZone - acceptedFiles[0] ->", acceptedFiles[0]);

    const formData = new FormData();
    const [file] = acceptedFiles;

    let options = {
      header: 'Content-type: multipart/form-data',
    };

    formData.append("attachment", acceptedFiles[0], acceptedFiles[0].name);
    formData.append("terms_conditions_id", selectedData.id);

    console.log("useMyDropZone - formData ->", formData);

    let notificationsToastArr = [];

    // Check supported files
    if (acceptedFiles && acceptedFiles.length > 0) {
      for (let i = 0; i < acceptedFiles.length; i++) {
        //console.log(acceptedFiles[i]);
        //console.log(acceptedFiles[i].type);
        //console.log(acceptedFiles[i].path);
        //console.log(acceptedFiles[i].name);

        if (
          acceptedFiles[i] && acceptedFiles[i].type !== "image/jpeg" &&
          acceptedFiles[i] && acceptedFiles[i].type !== "image/png" &&
          acceptedFiles[i] && acceptedFiles[i].type !== "text/plain" &&
          acceptedFiles[i] && acceptedFiles[i].type !== "application/msword" &&
          acceptedFiles[i] && acceptedFiles[i].type !== "application/pdf" &&
          acceptedFiles[i] && acceptedFiles[i].type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          notificationsToastArr.push({ title: `File type: ${acceptedFiles[i].type} is not allowed!`, icon: 'warn' });
        } else {
          setPreviewFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })));
        }
      }
    }

    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    } else {
      (async () => {
        const res = await AddAttachmentsWithContentType(formData);
        console.log(res);
        if (res && res.data && res.data.data) {
          await getTermsConditionsAttachments();
        }
      })();
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      'image/jpeg, image/png, text/plain, application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    let notificationsToastArr = [];

    if (!termsConditions.header) {
      notificationsToastArr.push({ title: 'Header field is required!', icon: 'warn' });
    }
    //if (!termsConditions.text) {
    //  notificationsToastArr.push({ title: 'Text field is required!', icon: 'warn' });
    //}
    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      if (selectedData) {
        (async () => {
          const { ok, data } = await UpdateTermCondition(selectedData.id, termsConditions);
          await handleOk(ok);
        })();
      } else {
        (async () => {
          const { ok, data } = await AddTermCondition(termsConditions);
          await handleOk(ok);
        })();
      }
    }
  };

  const handleOk = async (ok, options) => {
    dispatch(loaderToggle(false));

    if (ok) {
      update();
      if (options && options.length > 0) {
        return ToastMulti(options, "container-validation-id");
      } else {
        hide();
      }
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    console.log("TermConditionForm - termsConditions ->", termsConditions);
    let selectedData = Object.assign({}, termsConditions);
    selectedData[name] = value;

    setTermsConditions(selectedData);
  };

  const minify = (s) => {
    return s ? s
      .replace(/\>[\r\n ]+\</g, "><")  // Removes new lines and irrelevant spaces which might affect layout, and are better gone
      .replace(/(<.*?>)|\s+/g, (m, $1) => $1 ? $1 : ' ')
      .trim()
      : ""
  }

  const handleQuillChange = (value) => {
    let selectedData = Object.assign({}, termsConditions);
    selectedData.text = minify(value);

    setTermsConditions(selectedData);
  };

  const openZoomModal = (data, e) => {
    setZoomedHtml(data.text);
    setZoomModalOpenNested(true);
  };

  const closeZoomModal = () => {
    setZoomModalOpenNested(false);
    setZoomedHtml(null);
  };

  const handleZoomedHtmlChange = (e) => {
    setZoomedHtml(e);
  };

  const handleSave = (e) => {
    let selectedData = Object.assign({}, termsConditions);
    selectedData.text = zoomedHtml;

    setTermsConditions(selectedData);
    closeZoomModal();
  };

  const Preview = ({ meta }) => {
    const { name, percent, status } = meta;
    return (
      <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
        {name}, {Math.round(percent)}%, {status}
      </span>
    )
  };

  const downloadAttachment = (e, attachment_id, filename) => {
    e.preventDefault();
    console.log("downloadAttachment - attachment_id ->", attachment_id);
    console.log("downloadAttachment - filename ->", filename);
    console.log("downloadAttachment - e ->", e);

    const id = selectedData.id ? selectedData.id : null;
    if (id && attachment_id && filename) {
      (async () => {
        const { ok, data } = await DownloadSingleTermsConditionsAttachment(selectedData.id, attachment_id, filename);
        console.log("DownloadSingleTermsConditionsAttachment - data ->", data);

      })();
    }
  };

  const removeAttachment = (e, id) => {
    e.preventDefault();
    console.log("removeAttachment - id ->", id);

    if (id) {
      Swal.fire({
        customClass: {
          container: 'has-cancel',
        },
        title: 'Please confirm!',
        text: 'Are you sure you want to delete this attachment?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then(res => {
        if (res.value) {
          if (!loader) {
            dispatch(loaderToggle(true));

            (async () => {
              const { ok, data } = await RemoveAttachments(id);
              console.log("RemoveAttachments - data ->", data);
              if (data && data.data) {
                await getTermsConditionsAttachments();
                await Swal.fire('Success!', 'Deleted successfully', 'success');
              }
            })();

            dispatch(loaderToggle(false));

          }
        } else {
          dispatch(loaderToggle(false));

        }
      });
    }
  };

  const thumbs = () => {
    if (files) {
      console.log("thumbs - files ->", files);
      return files.map((file, index) => (
        <div key={index} className="attachments-uploaded-preview-item-container">
          <div className="attachments-uploaded-preview-item">
            <button className="attachments-uploaded-preview-item-remove-btn" key={`remove-${index}`} onClick={(e) => removeAttachment(e, file.id)}>
              <FontAwesomeIcon size={"sm"} color="red" className="attachments-uploaded-preview-item-remove" icon={faTimes} />
            </button>
            <button className="attachments-uploaded-preview-item-download-btn" key={`download-${index}`} onClick={(e) => downloadAttachment(e, file.id, file.filename)}>
              <FontAwesomeIcon size={"2x"} color="grey" className="attachments-uploaded-preview-item-icon" icon={faFileAlt} />
              <span style={{ fontSize: "smaller" }} className="attachments-uploaded-preview-container-item-filename">{file.filename}</span>
            </button>
          </div>
        </div>
      ));
    }
    return null;
  };

  const previewThumbs = () => {
    if (previewFiles) {
      console.log("thumbs - previewFiles ->", previewFiles);
      return previewFiles.map((file, index) => (
        <div key={index} className="attachments-uploaded-preview-item-container">
          <div key={file.name}>
            <div>
              <img
                src={file.preview}
                // Revoke data uri after image is loaded
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
              />
            </div>
          </div>
        </div>
      ));
    }
    return null;
  };

  const getTermsConditionsAttachments = () => {
    const id = selectedData.id ? selectedData.id : null;
    if (id) {
      (async () => {
        const { ok, data } = await GetTermConditionAttachments(id);
        console.log("GetSingleAttachment - data ->", data);
        if (data && data.data) {
          setFiles(data.data);

          return data.data;
        }
      })();
    }
  };

  const getAllTermsConditionsAttachments = () => {
    (async () => {
      const { ok, data } = await GetAllAttachments();
      console.log("GetSingleAttachment - data ->", data);
      if (data && data.data) {
        setFiles(data.data);

        return data.data;
      }
    })();
  };

  useEffect(() => {
    if (files && files.length > 0) {
      console.log("current attachments - files ->", files);
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }
  }, [files]);

  useEffect(() => {
    console.log("TermConditionForm - termsConditions ->", termsConditions);
    console.log("TermConditionForm - selectedData ->", selectedData);

    if (selectedData) {
      setTermsConditions(selectedData);

      const res = getTermsConditionsAttachments();
      if (res) {
        console.log("getTermsConditionsAttachments - res ->", res);
      }
    }
  }, []);

  useEffect(() => {
    if (previewFiles && previewFiles.length > 0) {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => previewFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }
  }, []);

  return (
    <React.Fragment>
      {!loader && <Modal isOpen={show} toggle={hide} centered>
        <Form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data">
          <ModalHeader className="modal-header-terms-conditions">
            <span className="d-flex justify-content-between">
              <span>{formType} {formName}</span>
            </span>
          </ModalHeader>
          <ModalBody className="mt-3 mb-3">
            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Description:</label>
              </Col>
              <Col md={10}>
                <Input
                  id="description"
                  name="description"
                  placeholder="Description"
                  value={termsConditions && termsConditions.description ? termsConditions.description : ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </div>
            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Header:</label>
              </Col>
              <Col md={10}>
                <Input
                  id="header"
                  name="header"
                  placeholder="Header"
                  value={termsConditions && termsConditions.header ? termsConditions.header : ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </div>
            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Text:</label>
              </Col>
              <Col md={10}>
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  placeholder='Long description'
                  value={termsConditions && termsConditions.text ? termsConditions.text : ""}
                  onChange={(e) => handleQuillChange(e)}
                />
                <div style={{ textAlign: "right", marginTop: "0.25rem" }}>
                  <Button color="primary" className="btn-sm" onClick={(e) => openZoomModal(termsConditions, e)}>
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </Button>
                </div>
              </Col>
            </div>
            {formType && formType === 'Edit' &&
              <div className="form-row mb-2">
                <Col md={2}>
                  <label className="col-form-label">Attachment:</label>
                </Col>
                <Col md={10}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop attachments here, or click to select files</p>
                    }

                    {previewThumbs}
                  </div>
                </Col>
              </div>}
            {formType && formType === 'Edit' && files &&
              <div className="form-row mb-2">
                <Col md={2}>
                  <label className="col-form-label">Uploaded:</label>
                </Col>
                <Col md={10}>
                  <div className="attachments-uploaded-preview-container-uploaded d-flex justify-content-center">
                    {files.length > 0 ? thumbs() : <p style={{ marginTop: "10px" }}>No attachments found.</p>}
                  </div>
                </Col>
              </div>}
            <div className="form-row mb-2">
              <Col md={2}>
                <label className="col-form-label">Sort:</label>
              </Col>
              <Col md={10}>
                <Input
                  id="sort"
                  name="sort"
                  placeholder="Sort"
                  value={termsConditions && termsConditions.sort ? termsConditions.sort : ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </Col>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <span className="btn btn-danger" onClick={hide}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <Button color="primary">
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </ModalFooter>
        </Form>
        <ZoomedHTMLScreen
          isOpen={zoomModalOpenNested}
          toggle={closeZoomModal}
          zoomedHtml={zoomedHtml}
          onChange={handleZoomedHtmlChange}
          onSave={handleSave}
        />
      </Modal>}
    </React.Fragment>
  );
};

const mapStateToProps = store => ({
  loader: store.loader
});

export default withRouter(withTranslation()(connect(mapStateToProps)(TermConditionForm)));
