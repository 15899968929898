import React from 'react';
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { loaderToggle } from '../../../../redux/actions/loaderActions';
import Toast from "../../../../components/Toast";
import {RemoveBillingRunDate} from '../../../../controllers/tenants';

class RemoveBillingRunForm extends React.Component {
  deleteRow = (rowIndex) => {
    if (rowIndex) {
      let table = document.querySelector('table');
      table.deleteRow((parseInt(rowIndex)+1));
    }
  };

  selectedRow = (rowIndex) => {
    if (rowIndex) {
      let table = document.querySelector('table');
      let table_body = table.children && table.children[1];
      table_body.rows[rowIndex].setAttribute("class","row-to-delete");
    }
  };

  unSelectRow = (rowIndex) => {
    if (rowIndex) {
      let table = document.querySelector('table');
      let table_body = table.children && table.children[1];
      //let rows = table_body.rows;
      table_body.rows[rowIndex].removeAttribute("class");
    }
  };

  componentDidMount() {
    const { loader, dispatch, hide, update, selectedData } = this.props;

    if (this.props.selectedDataRow) {
      this.selectedRow(this.props.selectedDataRow);
    }

    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this billing run?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(res => {
      if (res.value) {
        if (!loader) {
          (async () => {
            dispatch(loaderToggle(true));

            const { ok } = await RemoveBillingRunDate(selectedData.billing_run_id);
            if (ok) {
              dispatch(loaderToggle(false));

              //this.deleteRow(this.props.selectedDataRow);

              update();

              await Toast.fire({
                title: 'Billing run deleted successfully',
                icon: 'success'
              })

            } else {
              await Toast.fire({
                title: 'Deleted status is not available',
                icon: 'warning'
              })
            }
            hide();

          })();
        }
      } else {
        this.unSelectRow(this.props.selectedDataRow);
        update();
        hide();
      }
      dispatch(loaderToggle(false));

    })
  }

  render() {
    return (
        <React.Fragment>
        </React.Fragment>
    )
  }
}

export default connect(store => ({
  loader: store.loader
}))(RemoveBillingRunForm);