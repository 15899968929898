import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import {
    Card,
    Row,
    CardBody,
    ListGroup,
    ListGroupItem,
    Col,
    Button,
    Form,
    Input,
} from "reactstrap";
import ButtonSpinner from "../../components/ButtonSpinner";
import { GetCountries, GetCities } from "../../controllers/relations";
import { AddRelationCS } from "../../controllers/relations";
import { GetPlanList } from "../../controllers/plans";
import {
    GetContractPeriodList,
    GetNetworkOperatorList,
} from "../../controllers/subscriptions";
import { GetOrder } from "../../controllers/orders";
import { loaderToggle } from "../../redux/actions/loaderActions";
import {
    validateDate,
    validateEmail,
    validateIBAN,
} from "../../redux/actions/validateActions";
import Toast from "../../components/Toast";
import { nl, enGB, enUS } from "date-fns/locale";
import { parseDateToSaveFormat } from "../../redux/actions/formatActions"; // import all locales we need

registerLocale("en", enGB);
registerLocale("nl", nl);
registerLocale("us", enUS);

class OfferBank extends React.Component {
    constructor(props) {
        super(props);

        //console.log("Bank data - props ->", props);

        const { t } = props;

        this.state = {
            bank_account: {
                bic: "",
            },
            stepForm: props.stepForm,
            stepFormData: props.stepFormData,
        };
    }

    getLocale = () => {
        const dateLocalesPath = {
            nl: "nl",
            en: "en-GB",
            us: "en-US",
        };

        require(`date-fns/locale/${
            dateLocalesPath[this.props.i18n.language]
        }/index.js`);
    };

    handleInputChange({ target: { name, value } }) {
        //let relation = Object.assign({}, this.state.relation);
        let bank_account = Object.assign({}, this.state.bank_account);

        //relation[name] = value;
        bank_account[name] = value;

        //this.setState({ relation });
        this.setState({ bank_account });

        let stepFormData = Object.assign({}, this.state.stepFormData);
        stepFormData.bank_account = bank_account;
        stepFormData.relation.bank_account = bank_account;

        const { setStepFormData } = this.props;
        if (setStepFormData) {
            setStepFormData(stepFormData);
        }
    }

    handleDatePickerChange(name, date) {
        //let relation = Object.assign({}, this.state.relation);
        let bank_account = Object.assign({}, this.state.bank_account);
        const { newDate } = this.props.dispatch(validateDate(date));

        //relation[name] = newDate;
        bank_account[name] = newDate;

        //this.setState({ relation });
        this.setState({ bank_account });

        let stepFormData = Object.assign({}, this.state.stepFormData);
        stepFormData.bank_account = bank_account;

        const { setStepFormData } = this.props;
        if (setStepFormData) {
            setStepFormData(stepFormData);
        }
    }

    handleSelectChange = (name, data) => {
        //let relation = Object.assign({}, this.state.relation);
        let bank_account = Object.assign({}, this.state.bank_account);

        //relation[name] = data.value;
        bank_account[name] = data.value;

        /*this.setState({
            relation,
            [name]: data,
        });*/

        this.setState({
            bank_account,
            [name]: data,
        });
    };

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false); // refs
        }
    };

    componentDidMount() {
        const { stepFormData } = this.props;
        //console.log("Bank data - componentDidMount - stepFormData ->", stepFormData);

        let bank_account = Object.assign({}, this.state.bank_account);

        if (stepFormData && stepFormData.bank_account) {
            this.setState({
                bank_account: stepFormData.bank_account
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log("Bank data - componentDidUpdate - prevProps ->", prevProps);
        //console.log("Bank data - componentDidUpdate - prevState ->", prevState);
    }

    render() {
        const {
                isLoading,
                //relation,
                bank_account,
            } = this.state ? this.state : {},
            {
                iban,
                account_holder,
            } = bank_account ? bank_account : {}, //relation ? relation : {},
            { loader, t } = this.props,
            listGroupItemClass = "d-flex align-items-center px-0 py-0";

        return (
            <div className="offer-bank-information" id="offer-bank-information">
                <h5 className="pt-1 pb-2 pl-2 offer-sub-heading-bank">
                    Bank details
                </h5>
                <div className="offer-bank-information-data">
                    <Row>
                        <Col>
                            <span className="label">IBAN</span>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pb-1">
                            <Input
                                id="iban"
                                name="iban"
                                placeholder="IBAN bank account number"
                                value={iban ? iban : ""}
                                onChange={(e) => this.handleInputChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className="label">Account holder</span>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="pb-0 pt-1">
                            <Input
                                id="account_holder"
                                name="account_holder"
                                placeholder="Account holder"
                                value={account_holder ? account_holder : ""}
                                onChange={(e) => this.handleInputChange(e)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    loader: store.loader,
    user: store.user,
});

export default connect(mapStateToProps)(
    withTranslation()(withRouter(OfferBank))
);
