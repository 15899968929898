import React, {useEffect, useState, useRef} from 'react';
import { connect } from "react-redux";
import ReactDOMServer from 'react-dom/server';
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Row,
    Col,
    Form,
    Input,
    Label,
    CustomInput,
    UncontrolledCollapse,
} from 'reactstrap';
import { loaderToggle } from "../../../redux/actions/loaderActions";
import { validateDate } from "../../../redux/actions/validateActions";
import Toast from "../../../components/Toast";
import {useTranslation, withTranslation} from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../../redux/actions/formatActions';
import ReactQuill from 'react-quill';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {updateError} from '../../../redux/actions/errorActions';
import FormElementBuilder from '../../../components/form_builder/FormElementBuilder';
import OfferPersonal from '../../../components/offer/Personal';
import OfferBank from '../../../components/offer/Bank';
import {
    faCaretDown,
    faCaretUp,
    faCaretLeft,
    faArrowLeft,
    faArrowCircleDown,
    faArrowCircleUp,
    faAngleLeft,
    faAngleRight,
    faArrowCircleLeft,
} from '@fortawesome/free-solid-svg-icons';
import {DatePickerField} from "../../../components/form_builder/FormElements";
import {ErrorMessage} from "formik";

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const dateLocales = {
    'nl': nl,
    'en': enGB,
    'us': enUS
};

const dateLocalesPath = {
    'nl': 'nl',
    'en': 'en-GB',
    'us': 'en-US'
};

const RadioElement = ({...props}) => {
    console.log("RadioElement - props ->", props);

    const { selectedData, dynamicFieldData, setStepFormData } = props;
    console.log("RadioElement - setStepFormData ->", setStepFormData);

    const [elementKey, setElementKey] = useState("");
    const [options, setOptions] = useState([]);
    const [inlineStyle, setInlineStyle] = useState("");
    const [userInputData, setUserInputData] = useState(selectedData ? selectedData : {});

    const { t, i18n, i18next } = useTranslation();

    const getLocale = locale => require(`date-fns/locale/${dateLocalesPath[i18n.language]}/index.js`);

    const formatCurrency = (price) => {
        let floatCell = parseFloat(price ? price : 0);
        let floatStyle = floatCell && floatCell < 0 ? 'float-negative' : floatCell && floatCell > 0 ? 'float-positive' : 'float-null';
        return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(floatCell) })}</span>
    };

    const renderDatePickerField = (name, value) => {
        if (name) {
            return (
                <div className={`datepicker-dynamic-data-container mt-2 mb-1 ml-4`}>
                    <DatePicker
                        locale={getLocale()}
                        id={`${name}-offer-date`}
                        wrapperClassName="w-100"
                        className="form-control"
                        name="plan_stop"
                        dateFormat={t("date_format_raw")}
                        autoComplete="off"
                        selected={null}
                        onChange={(e) => handleDatePickerChange(e, name)}
                        //onChange={(e) => props.onChange(e, name, "datepicker")}
                        placeholderText="Select date"
                    />
                </div>
            )
        }
        return "";
    };

    const renderInputField = (name, value) => {
        if (name) {
            return (
                <div className={`input-dynamic-data-container mt-2 mb-1 ml-4`}>
                    <input
                        name={name}
                        className="input-dynamic-data form-control"
                        value={userInputData && userInputData[name] ? userInputData[name] : value ? value : ""}
                        //onChange={(e) => handleInputChange(e, name)}
                        onChange={(e) => handleInputChange(e, name)}
                    />
                </div>
            )
        }
        return "";
    };

    const handleDatePickerChange = (e, name) => {
        console.log("RadioElement - handleDatePickerChange - e ->", e);
        console.log("RadioElement - handleDatePickerChange - name ->", name);
    };

    const handleInputChange = ({ target: { name, value } }) => {
        console.log("RadioElement - handleInputChange - name ->", name);
        console.log("RadioElement - handleInputChange - value ->", value);

        let selectedData = Object.assign({}, userInputData);
        selectedData[name] = value;

        setUserInputData(selectedData);
        //props.dynamicFieldData(selectedData);
    };

    const renderDynamicData = (data) => {
        console.log("RadioElement - renderDynamicData - data ->", data);

        if (data) {
            // default input field
            if (data.element === "input") {
                return renderInputField(data.name, data.value);
            }
            // datepicker field
            else if (data.element === "datepicker") {
                return renderDatePickerField(data.name, data.value);
            }
        }
    };

    const radioChangeHandler = (e, name, data) => {
        console.log("RadioElement - radioChangeHandler - e ->", e);
        console.log("RadioElement - radioChangeHandler - e.target.value ->", e.target.value);
        console.log("RadioElement - radioChangeHandler - name ->", name);
        console.log("RadioElement - radioChangeHandler - name ->", data);
        console.log("RadioElement - radioChangeHandler - props ->", props);

        const { dynamicFieldData, setStepFormData, stepFormData } = props;

        let selectedData = Object.assign({}, stepFormData);
        selectedData[name] = e.target.value;
        //setStepFormData(selectedData);
        //dynamicFieldData(data, name);
    };

    useEffect(() => {
        console.log("RadioElement - useEffect - props ->", props);

        const { name, options, style, dynamicFieldData, setStepFormData } = props;

        if (name && options) {
            setElementKey(name);
            setOptions(options);
            setInlineStyle(style);
        }
    }, []);

    return (
        <div
            role="group"
            aria-labelledby={`${elementKey}-radio-group`}
            id={elementKey}
            className={`${inlineStyle}`}
        >
            {options.map((option, index) =>
                <div key={`${option.name}-${index}`} className="custom-radio custom-control custom-controlled-radio"
                     style={{paddingLeft: "3px"}}>
                    <CustomInput
                        className="mb-1"
                        id={`${option.name}-${index}`}
                        type="radio"
                        name={option.name}
                        label={option.label}
                        value={option.value}
                        style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1", maxWidth: "75%"}}
                        //onChange={(e) => radioChangeHandler(e, option.name, option.data)}
                        onChange={(e) => props.onChange(e, option.name, option.data)}
                    />

                    {option.price && (parseFloat(option.price) > 0)
                        ? <span
                            className="plan-offer-line-price"
                            style={{
                                position: "absolute",
                                right: 0,
                                maxWidth: "25%",
                                top: 0
                                }}
                           >
                            {formatCurrency(option.price)}
                           </span>
                        : option.price === false
                        ? ""
                        : <span
                                className="plan-offer-line-price"
                                style={{position: "absolute", right: 0, maxWidth: "25%", top: 0}}
                            >
                                GRATIS
                          </span>
                    }

                    {option.data
                        ? renderDynamicData(option.data)
                        : ""
                    }
                </div>
            )}
        </div>
    );
};

export default withRouter(connect()(RadioElement));