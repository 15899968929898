import React, { useState, useEffect } from 'react';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import JSONPretty from 'react-json-pretty';
import DatePicker from 'react-datepicker';
import {Badge, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../../services/apiService';
import CONSTANTS from '../../../services/constants';
import CustomDataTable from '../../../components/CustomDataTable';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {stringFilter} from '../../../services/misc';
import {getTableObj} from '../../../services/tableService';

const Api = new ApiService(),
      GetAPI = Api.getAPI;

const ActivityLogs = ({ id, dispatch }) => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(25);
  const [date, setDate] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState({label: 'All', value: ''});
  const [details, setDetails] = useState('');
  const [severity, setSeverity] = useState({label: 'All', value: ''});
  const [username, setUsername] = useState('');
  const [isLoadData, setLoadData] = useState(true);

  const handleFiltering = (e) => {
    if (e.key === "Enter") {
      let value = e.target && e.target.value ? e.target.value : '';
      let name = e.target && e.target.name ? e.target.name : null;

      switch (name) {
        case 'type':
          setType(value);
          break;
        case 'message':
          setMessage(value);
          break;
        case 'date':
          setDate(value);
          break;
        case 'details':
          setDetails(value);
          break;
        case 'severity':
          setSeverity(value);
          break;
        case 'username':
          setUsername(value);
          break;
        default:
          break;
      }
    }
  };

  const handleDateInputKeyDown = (e) => {
    e.preventDefault();
    if (e.key === 'Backspace') {
      e.target.value = '';
      setDate(null)
    }
  };

  const clearInput = (e, id) => {
    e.preventDefault();

    let input = document.getElementById(id);
    input.value = '';

    let elemName = input.name;
    if (elemName === 'message') {
      setMessage('');
    }
    if (elemName === 'details') {
      setDetails('');
    }
    if (elemName === 'username') {
      setUsername('');
    }
  };

  const headerInputFormatter = (column) => {
    return <div style={{ display: 'flex', flexDirection: 'column' }} className={`activity-logs-subscriptions mw-200`}>
      <span className="thead-label">{column.text}</span>
      <InputGroup className={`table-search-input`}>
        <Input type="text" className="f2x-h-text" name={column.dataField} onKeyPress={(e) => handleFiltering(e)} id={`id-input-activity-logs-${column.dataField}`} placeholder={`Search...`} />
        <InputGroupAddon className="clear-btn" addonType="append">
          <a href="/#" className="danger icon-delete" onClick={(e) => clearInput(e, `id-input-activity-logs-${column.dataField}`)}>
            <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
          </a>
        </InputGroupAddon>
      </InputGroup>
    </div>
  };

  const clearDateSelected = (e) => {
    e.preventDefault();
    setDate('');
  };

  const headerDateFormatter = (column) => {
    return (
        <div className={`activity-logs-subscriptions mw-200`} style={{display: 'flex', flexDirection: 'column'}}>
          <span className="thead-label">{column.text}</span>
          <InputGroup className={`table-search-input`}>
            <DatePicker
                className="form-control f2x-h-text max-width-200"
                name="date"
                dateFormat={t("date_format_raw")}
                autoComplete="off"
                placeholderText="Select date"
                selected={date ? new Date(date) : null}
                onChange={(date) => {
                  setDate(date ? new Date(date) : null)
                }}
                maxDate={new Date()}>
            </DatePicker>
            <InputGroupAddon className="clear-btn" addonType="append">
              <a href="/#" className="danger icon-delete" onClick={clearDateSelected.bind(this)}>
                <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" icon="times" />
              </a>
            </InputGroupAddon>
          </InputGroup>
        </div> )
  };

  const headerTypeFormatter = (column) => 
    <div className={`activity-logs-subscriptions`} style={{ display: 'flex', flexDirection: 'column' }}>
      <span className="thead-label">{column.text}</span>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        id="type"
        placeholder="( All )"
        options={CONSTANTS.SUBSCRIPTION_LOG_TYPES}
        value={type}
        onChange={(data) => setType(data)}
      />
    </div>;

  const headerSeverityFormatter = (column) => 
    <div className={`activity-logs-subscriptions`} style={{ display: 'flex', flexDirection: 'column' }}>
      <span className="thead-label">{column.text}</span>
      <Select
        id="severity"
        placeholder="Select..."
        options={CONSTANTS.SUBSCRIPTION_LOG_SEVERITY}
        value={severity}
        onChange={(data) => setSeverity(data)}
        className="react-select-container"
        classNamePrefix="react-select"
      />
    </div>;

  const severityFormatterAlter = (cell, row) => {
    const styleClass = `f2x-opt-${cell.toLowerCase()}`;
    return (
      <span className={`${styleClass} f2x-logs-sev`}>{cell}</span>
    )
  };

  const severityFormatter = (cell) => {
    return <Badge className={`badge-pill severity ${cell}`}>{cell}</Badge>
  };

  const timeFormatter = (cell) => {
    const res = cell ? cell.split(".") : null;
    if (res && res.length > 0) {
      return res[0];
    }
    return cell;
  };

  const headerTimeFormatter = (cell) => {
    if (cell && cell.dataField) {
      console.log("headerTimeFormatter - cell ->", cell);
      console.log("headerTimeFormatter - cell.dataField ->", cell.dataField);
      let cellData = cell.dataField.split(".");
      console.log("headerTimeFormatter - cellData ->", cellData);
      if (cellData && cellData[0]) {
        return <span className="thead-label">{cellData[0]}</span>;
      } else {
        return <span className="thead-label">{cell.dataField}</span>;
      }
    }
  };

  const formatDate = (cell) => {
    if (cell) {
      return <span className="date-format">{t("date_format",
          {date: new Date(cell)})}</span>
    } else {
      return <span className="null-value">{String.fromCharCode(8212)}</span>
    }
  };

  const validateJsonToJsonObj = (jsonStr) => {
    try {
      JSON.parse(jsonStr);
    } catch (e) {
      return false;
    }
    return <JSONPretty id="json-pretty" data={ JSON.parse(jsonStr) }> </JSONPretty>
  };

  const detailsExpand = {
    'renderer': (row) => {
      let parsed = validateJsonToJsonObj(row.details);
      if (parsed.props && parsed.props.data) {
        return (<ReactJson
            src={parsed.props.data}
            enableClipboard={false}
            style={{fontFamily: "inherit", backgroundColor: "white"}}
        />)
      }
      return '';
    }
  };

  const columns = [{
    dataField: "date",
    text: 'Date',
    formatter: formatDate,
    headerFormatter: headerDateFormatter,
    headerStyle: () => {
      return { width: '12%', minWidth: '140px'}
    },
    attrs: {
      'data-label': 'Date'
    }
  }, {
    dataField: "time",
    text: 'Time',
    headerFormatter: headerTimeFormatter,
    //formatter: timeFormatter,
    headerStyle: () => {
      return { width: '7%', minWidth: '100px'}
    },
    attrs: {
      'data-label': 'Time'
    }
  },   
  // Uncomment below to show Type
  // {
  //   dataField: "type",
  //   text: 'Type',
  //   headerFormatter: headerTypeFormatter,
  //   headerStyle: () => {
  //     return { width: '15%', minWidth: '100px'}
  //   },
  //   attrs: {
  //     'data-label': 'Type'
  //   }
  // },   
  {
    dataField: "message",
    text: 'Message',
    headerFormatter: headerInputFormatter,
    headerStyle: () => {
      return { width: '20%', minWidth: '200px'}
    },
    attrs: {
      'data-label': 'Message'
    }
  }, {
    dataField: "details",
    text: 'Details',
    headerFormatter: headerInputFormatter,
    classes: 'f2x-details-link ',
    headerStyle: () => {
      return { width: '25%'}
    },
    attrs: {
      'data-label': 'Details'
    }
  }, {
    dataField: "severity",
    text: 'Severity',
    formatter: severityFormatter,
    headerFormatter: headerSeverityFormatter,
    align: "center",
    headerStyle: () => {
      return { width: '15%', minWidth: '100px'}
    },
    attrs: {
      'data-label': 'Severity'
    }
  }, {
    dataField: "username",
    text: 'Username',
    headerFormatter: headerInputFormatter,
    classes: 'f2x-details-txt',
    headerStyle: () => {
      return { width: '15%', minWidth: '100px'}
    },
    attrs: {
      'data-label': 'Username'
    }
  }];

  const handleTableChange = (type, {page, sizePerPage, sortField, sortOrder, searchText}) => {
    console.log('handleTableChange');

    const pageNum = page;
    const sortFieldParam = sortField === 'date' ? 'date' : sortField;
    const isSortDesc = sortField && stringFilter(sortOrder, 'desc');
    const sortParam = isSortDesc ? `-${sortFieldParam}` : sortFieldParam;
    const propsObj = getTableObj(type, {searchText, isLoadData, pageNum, page});

    setSizePerPage(sizePerPage ? sizePerPage : 10);
    setPage(propsObj && propsObj.pageNum ? propsObj.pageNum : 1);
    setLoadData(propsObj && propsObj.isLoadData ? propsObj.isLoadData : true);

    let params = [];
    params['sort'] = sortParam;
    params['page'] = propsObj && propsObj.pageNum ? propsObj.pageNum : 1;
    params['filter'] = null;
    params['offset'] = sizePerPage ? sizePerPage : 10;

    setQueryParameters(params);
  };

  const setQueryParameters = (params) => {
    let query = [],
        key,
        value;

    for (key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      if (key === 'page' || key === 'offset') {
        value = parseInt(params[key]);
      } else {
        value = params[key];
      }
      query.push(`${key}=${value}`);
    }

    const tableId = 'qStrActivityLogsSubscription';
    localStorage.setItem(tableId, query.join('&'));
  };

  const getQueryParameters = () => {
    const tableId = 'qStrActivityLogsSubscription';

    let queryString;
    let param = {};

    if (localStorage.getItem(tableId)) {
      queryString = localStorage.getItem(tableId) && localStorage.getItem(tableId).charAt(0) === '?'
          ? localStorage.getItem(tableId).slice(1)
          : localStorage.getItem(tableId);

      if (queryString) {
        queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
          if (key === 'page' || key === 'offset') {
            param[key] = parseInt(value);
          } else {
            param[key] = value;
          }
        });
      }
    } else {
      param['page'] = page ? page : 1;
      param['offset'] = sizePerPage ? sizePerPage : 10;
    }

    return param;
  };

  useEffect(() => {
    let param_history = getQueryParameters();

    const param = {
      page: param_history && param_history['page'] ? param_history['page'] : page,
      offset: param_history && param_history['sizePerPage'] ? param_history['sizePerPage'] : sizePerPage,
      date: date ? t("search_date_format", {date: new Date(date)}) : '',
      type: type.value || '',
      details,
      severity: severity.value || '',
      username,
      message
    };

    setQueryParameters(param);

    dispatch(loaderToggle(true));
    GetAPI(`subscriptions/${id}/log_activities`, param)
      .then(r => {
        const { data, total } = r.data || {};

        console.log("log_activities - data ->", data);
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].time) {
              let timeData = data[i].time.split(".");
              console.log("log_activities - timeData ->", timeData);
              if (timeData && timeData[0]) {
                data[i].time = timeData[0];
              }
            }
          }
        }

        setData(data || []);
        setTotalSize(total || totalSize);
        dispatch(loaderToggle(false));
      })
      .catch(error => {
        dispatch(loaderToggle(false));
        console.log('Error fetching data: ', error) 
      })
  }, [id, page, sizePerPage, date, type, details, severity, username, message, totalSize, dispatch]);

  return (
    <CustomDataTable
      id="ActivityLogsSubscription"
      table="activity-logs-subscription"
      classes="custom-tbl-logs"
      data={ data }
      remote
      keyField="id"
      columns={ columns }
      onTableChange={handleTableChange}
      page={ page }
      sizePerPage={ sizePerPage }
      totalSize={ totalSize }
      expandRow={ detailsExpand }
    />
  )
};

export default connect()(withTranslation()(withRouter(ActivityLogs)));
