import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Alert,
  Button,
  Col,
  CustomInput,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  AddAddress,
  GetAddress,
  GetAddressDependencies,
  GetCountries,
  GetCountryCities,
  GetLocationByParams,
  UpdateAddress,
  GetLocationById,
} from '../../../controllers/relations';
import _ from 'lodash';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import { validateInt } from '../../../redux/actions/validateActions';
import Toast from '../../../components/Toast';
import { createSelector } from 'reselect';
import { faExclamationTriangle, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Label from 'reactstrap/es/Label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InitMap from '../../../components/mapbox/InitMap';

class AddressForm extends React.Component {
  constructor(props) {
    super(props);

    const fetchingData = false;

    const countries = GetCountries;
    console.log(countries);

    const { selectedData } = this.props;

    this.state = {
      formType: selectedData ? 'Edit' : 'Add',
      formName: 'address',
      defaultPrimary: !!(selectedData && selectedData.primary),
      address: selectedData ? selectedData : {},
      addressJsonData: false,
      locationAlert: false,
      locationAlertContent: [],
      locationAlertMessage: null,
      locationAlertIcon: false,
      addressIsChecked: false,
      checkbox_auto_address_enabled: 1,
      checkbox_auto_address_is_disabled: false,
      addressParams: {},
      addressParamsQstr: null,
      locationAlertVisible: true,

      popupInfo: null,
      userAddress: '',
      locationAddress: {},
      locationLat: null,
      locationLng: null,
    };

    this.toggle = this.toggle.bind(this);
    this.handlePrimaryCheckChange = this.handlePrimaryCheckChange.bind(this);
    this.handleUserAddressChange = this.handleUserAddressChange.bind(this);
  }

  handleUserAddressChange(event) {
    this.setState({ userAddress: event.target.value });
  }

  toggle() {
    this.props.hide();
  }

  validateZipcode = (zipCode) => {
    let regex = /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i;
    const res = regex.test(zipCode);
    console.log("validateZipCode - res ->", res);
    return res;
  };

  handleInputKeyDown = (e) => {
    console.log("Address - handleInputKeyDown - e ->", e);

    if (!this.addressIsChecked) { // keyCode 9 -> TAB
      e.preventDefault();
      console.log("Relation address - handleInputChange - value (e.key) ->", e.key);
      console.log("Relation address - handleInputChange - this.state.country_id ->", this.state.country_id);
      console.log("Relation address - handleInputChange - this.state.address ->", this.state.address);

      if (this.state.checkbox_auto_address_enabled === 1) {
        this.checkAddress();
      }
    }
  };

  handleInputChange({ target: { name, value } }) {
    console.log("Address - handleInputChange - name ->", name);
    console.log("Address - handleInputChange - value ->", value);
    console.log("Address - handleInputChange - this.state.country_id ->", this.state.country_id);

    let address = Object.assign({}, this.state.address);
    if (name && name === "house_number_letter") {
      address["house_letter"] = value.replace(/^\s+|\s+$|\s+(?=\s)/g, " ");
    }
    address[name] = value.replace(/^\s+|\s+$|\s+(?=\s)/g, " ");
    this.setState({ address });
  }

  handleSelectChange = (name, data) => {
    if (name === 'country_id') {
      this.setState({
        city_id: null,
        cityOpts: []
      });

      this.getCities(data.value);

      console.log("country_id - data.value ->", data.value);
      if (data.value !== 155) {
        this.setState({ checkbox_auto_address_is_disabled: true, checkbox_auto_address_enabled: 0 });
      } else {
        this.setState({ checkbox_auto_address_is_disabled: false, checkbox_auto_address_enabled: 1 });
      }
    }

    let address = Object.assign({}, this.state.address);

    address[name] = data.value;

    this.setState({
      address,
      [name]: data
    })
  };

  handlePrimaryCheckChange(e) {
    const name = e.target.name;
    const address = Object.assign({}, this.state.address);

    address[name] = !address[name] ? !address[name] : false;
    this.setState({ address });
  }

  handleCheckChange({ target: { name, checked, value } }) {
    let address = Object.assign({}, this.state.address);

    if (name !== 'primary') {
      let address_types = address.address_types ? address.address_types : [];

      if (checked) {
        address_types.push(value)
      } else {
        address_types = address_types.filter(type => type !== value)
      }

      address.address_types = address_types
    } else {
      address[name] = checked
    }

    this.setState({ address })
  }

  getCities(id) {
    const { dispatch } = this.props;

    dispatch(loaderToggle(true));

    (async () => {
      const { ok, data } = await GetCountryCities(id); // param: => id

      await (() => {
        if (ok) {
          const cities = data.data ? data.data : [];
          let cityOpts = [];

          cities.length > 0 && cities.map(({ name, id }) => {
            return cityOpts.push({
              label: name,
              value: id
            })
          });

          this.setState({ cityOpts });

          const { selectedData } = this.props;

          if (selectedData && selectedData.city_id) {
            const getCity = cityOpts.find(city => parseInt(city.value) === parseInt(selectedData.city_id));

            console.log("Relation address - getCities - getCity ->", getCity);

            this.setState({ city_id: getCity ? getCity : null })
          }
        }
      })();

      dispatch(loaderToggle(false));

    })();
  }

  handleSubmit(e) {
    e.preventDefault();
    
    const { selectedData, id, dispatch, loader } = this.props,
      { street1, house_number, zipcode, city_name, city_id, country_id, address_types, address_type_id } = this.state.address;

    console.log("Relation address - handleSubmit - this.state ->", this.state);
    console.log("Relation address - handleSubmit - (this.state.address) ->", this.state.address);
    console.log("Relation address - handleSubmit - (this.state.address) - city_name ->", city_name);

    //Validations
    if (!street1 || (street1 && street1.trim() === '')) {
      return Toast.fire({ title: 'Street field is required!', icon: 'warning' })
    }

    if (!house_number || (house_number && house_number.trim() === '')) {
      return Toast.fire({ title: 'House no. field is required!', icon: 'warning' })
    } else if (!validateInt(house_number)) {
      return Toast.fire({ title: 'House no. must be a number!', icon: 'warning' })
    }

    if (!zipcode || (zipcode && zipcode.trim() === '')) {
      return Toast.fire({ title: 'Zipcode field is required!', icon: 'warning' })
    }

    //if (!city_name || (!city_id && !city_id.label)) {
    //  return Toast.fire({ title: 'City name field is required!', icon: 'warning' })
    //}

    if (!city_id) {
      return Toast.fire({ title: 'City name is required!', icon: 'warning' })
    }

    if (!country_id) {
      return Toast.fire({ title: 'Country field is required!', icon: 'warning' })
    }

    if (!selectedData && (!address_types || (address_types && address_types.length === 0))) {
      return Toast.fire({ title: 'At least 1 type must be checked!', icon: 'warning' })
    }

    if (selectedData && !address_type_id) {
      return Toast.fire({ title: 'Type field is required!', icon: 'warning' })
    }

    console.log('address submit form id for setting relation_id', id, this.state.address);

    if (!loader) {
      dispatch(loaderToggle(true));
      let address = Object.assign({}, this.state.address);
      address.relation_id = parseInt(id);
      if (this.isObject(country_id)) {
        address.country_id = parseInt(country_id.value);
      }
      if (this.isObject(city_id)) {
        address.city_id = parseInt(city_id.value);
      }

      if (selectedData) {
        (async () => {
          const { ok } = await UpdateAddress(address.id, address);
          this.handleOk(ok, null, 'updated')
        })();
      } else {
        (async () => {
          address.relation_id = id;
          const { ok, data } = await AddAddress(id, address);
          console.log('Address form AddAddress data', data);
          this.handleOk(ok, data, 'added')
        })()
      }
    }
  }

  isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
  }

  handleOk = (ok, data, method) => {
    const { hide, update, dispatch } = this.props;

    dispatch(loaderToggle(false));

    if (data && data.data) {
      const getData = data.data,
        getType = this.state.addressTypeOpts.find(type => parseInt(type.value) === parseInt(getData.address_type_id));

      getData.address_type = {
        id: getData.address_type_id,
        type: getType ? getType.label : null
      }
    }

    if (ok) {
      hide();
      update('updateaddress');
    }
  };

  getAddresses = async (id) => {
    const { dispatch } = this.props;
    dispatch(loaderToggle(true));

    const { ok, data } = await GetAddress(null, id);
    dispatch(loaderToggle(false));

    if (ok) {
      this.setState({ data: data });
    }
  };

  handleAddressCheckChange = () => {
    console.log("checkbox_auto_address_enabled - current old state ->", this.state.checkbox_auto_address_enabled);

    if (this.state.checkbox_auto_address_enabled === 1) {
      this.setState({ checkbox_auto_address_enabled: 0 });
    } else {
      this.setState({ checkbox_auto_address_enabled: 1 });
    }
  };

  checkAddress = () => {
    if (this.state.country_id && this.state.country_id.value && this.state.country_id.value === 155 &&
      (this.state.address.zipcode || this.state.address.house_number)) {
      let params = {};
      params.zipcode = this.state.address && this.state.address.zipcode ? this.state.address.zipcode : "";
      params.house_number = this.state.address && this.state.address.house_number ? this.state.address.house_number : "";
      params.house_number_letter = this.state.address && this.state.address.house_number_letter ? this.state.address.house_number_letter : "";
      params.house_number_suffix = this.state.address && this.state.address.house_number_suffix ? this.state.address.house_number_suffix : "";
      params.room = this.state.address && this.state.address.room ? this.state.address.room : "";

      const location = this.checkLocation(params);
    }
  };

  checkLocation = (params) => {
    console.log("Address - checkLocation - paramObj ->", params);

    const { addressParams, address } = this.state;
    console.log("Address - checkLocation - addressParams ->", addressParams);
    console.log("Address - checkLocation - address ->", address);

    /*if (addressParams && params && _.isEqual(addressParams, params)) {
      console.log("Address - checkLocation - addressParams isEqual params(obj) - params ->", params);
      console.log("Address - checkLocation - addressParams isEqual params(obj) - addressParams ->", addressParams);
      return false;

    } else {*/
    //this.setState({addressParams: params});
    //}

    // Expects params: zipcode, house_number, house_number_suffix, house_letter, and room
    let queryString;
    if (params.zipcode && params.house_number) {
      if (this.validateZipcode(params.zipcode)) {
        queryString = Object.keys(params).
          map(key => key + '=' + params[key]).
          join('&');
      }
    }
    console.log("Address - checkLocation - queryString ->", queryString);
    console.log("Address - checkLocation - params ->", params);
    console.log("Address - checkLocation - this.state.addressParamsQstr ->", this.state.addressParamsQstr);
    console.log("Address - checkLocation - this.state.addressParams ->", this.state.addressParams);

    this.setState({ addressParamsQstr: queryString });

    const { dispatch } = this.props;

    if (queryString && !this.fetchingData && !this.addressIsChecked) { //&& address && !address.location_id
      (async () => {
        dispatch(loaderToggle(true));

        this.fetchingData = true;

        const { ok, data } = await GetLocationByParams(
          params.zipcode,
          params.house_number,
          params.house_number_letter,
          params.house_number_suffix,
          params.room
        );

        console.log("Relation address - checkLocation - ok ->", ok);
        console.log("Relation address - checkLocation - data ->", data);
        console.log("Relation address - checkLocation - address ->", address);

        if (ok && data && data.success && data.message && data.message === "Please select a location") {
          console.log("Relation address - checkLocation - data.message ->", data.message);
          console.log("Relation address - checkLocation - data.data ->", data.data);
          console.log("Relation address - checkLocation - data.data.length ->", data.data.length);

          // check if current parameters are in result array
          if (data.data && data.data.length === 1) {
            this.setSingleLocation(data.data, params);

          } else if (data.data && data.data.length > 1) { //&& address && !address.location_id
            
            if (!data.data.some(item => item.zipcode === params.zipcode && item.house_number === params.house_number && item.house_number_suffix === params.house_number_suffix && item.house_letter === params.house_number_letter)) {
              console.log("Relation address - checkLocation - params are same as returned data");
              // hide alert
              this.setState({
                locationAlert: false,
                locationAlertContent: [],
                locationAlertMessage: null,
                locationAlertIcon: false
              });

              return false;

            } else {
              // show alert
              this.setState({
                locationAlert: true,
                locationAlertContent: data.data,
                locationAlertMessage: data.message,
                locationAlertIcon: false,
                addressIsChecked: true
              });

              return false;

            }
            
            /*if (data.data.some(item => item.zipcode === params.zipcode && item.house_number === params.house_number && item.house_number_suffix === params.house_number_suffix && item.house_letter === params.house_number_letter)) {
              console.log("Relation address - checkLocation - params are same as returned data");
              // hide alert
              this.setState({
                locationAlert: false,
                locationAlertContent: [],
                locationAlertMessage: null,
                locationAlertIcon: false
              });

              return false;

            } else {
              // show alert
              this.setState({
                locationAlert: true,
                locationAlertContent: data.data,
                locationAlertMessage: data.message,
                locationAlertIcon: false,
                addressIsChecked: true
              });

              return false;

            }*/

          } else {
            console.log("No location data found!");
            return false;
          }

        } else if (ok && data && data.success && data.message && data.message === "Location found successfully") {
          console.log("Relation address - checkLocation - data.message ->", data.message);
          console.log("Relation address - checkLocation - data.data ->", data.data);

          this.setSingleLocation(data.data, params);

        } else {

          console.log("Relation address - checkLocation - not ok ->", "No location data found!");
          console.log("Relation address - checkLocation - not ok - ok ->", ok);
          console.log("Relation address - checkLocation - not ok - data ->", data);

          // clear location ID
          let address = Object.assign({}, this.state.address);
          address["location_id"] = null;

          this.setState({
            address,
            locationAlertIcon: true
          });
        }

      })();

      dispatch(loaderToggle(false));
      this.fetchingData = false;

    }

    dispatch(loaderToggle(false));
    return false;
    //}
  };

  setSingleLocation = (locationData, params) => {
    console.log("setSingleLocation - locationData ->", locationData);

    let city = {};
    city.label = locationData.city.name;
    city.value = locationData.city.id;

    let country = {};
    country.label = locationData.country.name;
    country.value = locationData.country.id;

    let address = Object.assign({}, this.state.address);
    address["street1"] = locationData.street1;
    address["house_number"] = locationData.house_number;
    address["house_number_letter"] = locationData.house_number_letter;
    address["house_letter"] = locationData.house_number_letter;
    address["house_number_suffix"] = locationData.house_number_suffix;
    address["zipcode"] = locationData.zipcode;
    address["city_name"] = locationData.city_name;
    address["room"] = locationData.room;
    address["city_id"] = city.value;
    address["city_name"] = city.label;
    address["country_id"] = country;
    address["location_id"] = locationData.id;

    console.log("Relation address - selected - location.data - address ->", address);

    this.setState({
      address,
      city_id: city,
      locationAlert: false,
      locationAlertContent: [],
      locationAlertMessage: null,
      addressIsChecked: true,
      addressParams: params,
      locationAlertIcon: false
    });
  };

  selectLocation = (e, i) => {
    e.preventDefault();
    e.stopPropagation();    
    const { locationAlertContent, address } = this.state;
    if (locationAlertContent && locationAlertContent[i]) {
      const locationData = locationAlertContent[i];
      const newAddress = {
        ...address,
        street1: locationData.street1,
        house_number: locationData.house_number,
        house_number_letter: locationData.house_letter,
        house_letter: locationData.house_letter,
        house_number_suffix: locationData.house_number_suffix,
        zipcode: locationData.zipcode,
        room: locationData.room,
        city_id: {
          label: locationData.city.name,
          value: locationData.city.id
        },
        country_id: {
          label: locationData.country.name,
          value: locationData.country.id
        },
        location_id: locationData.id
      };

      this.setState({
        address: newAddress,
        city_id: {
          label: locationData.city.name,
          value: locationData.city.id
        },
        locationAlert: false,
        locationAlertVisible: false,
        locationAlertContent: [],
        locationAlertMessage: null,
        addressIsChecked: false
      }, () => {
        console.log("State after selecting location:", this.state);
      });
    }
  };
  /*selectLocation = (e, i) => {
    e.preventDefault();
    console.log("selectLocation - e ->", e);

    this.setState({
      locationAlert: false,
      locationAlertContent: [],
      locationAlertMessage: null,
      addressIsChecked: false,
    });

    if (this.state.locationAlertContent && this.state.locationAlertContent[i]) {
      console.log("selectLocation - locationAlertContent[i] ->", this.state.locationAlertContent[i]);

      const locationData = this.state.locationAlertContent[i];
      console.log("selectLocation - selected - location.data - locationData ->", locationData);

      let city = {};
      city.label = locationData.city.name;
      city.value = locationData.city.id;

      let country = {};
      country.label = locationData.country.name;
      country.value = locationData.country.id;

      let address = Object.assign({}, this.state.address);
      address["street1"] = locationData.street1;
      address["house_number"] = locationData.house_number;
      address["house_number_letter"] = locationData.house_letter;
      address["house_number_suffix"] = locationData.house_number_suffix;
      address["zipcode"] = locationData.zipcode;
      address["room"] = locationData.room;
      address["city_name"] = locationData.city_name;
      address["city_id"] = city;
      address["country_id"] = country;
      address["location_id"] = locationData.id;

      console.log("Relation address - selected - location.data - address ->", address);

      this.setState({
        address,
        city_id: city,
        locationAlert: false,
        locationAlertContent: [],
        locationAlertMessage: null,
        addressIsChecked: false
      });
    } else {
      this.setState({
        locationAlert: false,
        locationAlertContent: [],
        locationAlertMessage: null,
        addressIsChecked: false
      });
    }
    return false;
  };*/

  clearAddress = (e) => {
    e.preventDefault();

    let address = Object.assign({});

    this.setState({
      address,
      city_id: null,
      locationAlertVisible: true,
      locationAlertContent: [],
      locationAlertMessage: null,
      addressIsChecked: false
    });
  };

  getLocationData = (id) => {
    (async () => {
      const { ok, data } = await GetLocationById(id);
      console.log("Relation address - GetLocationById - data ->", data);
      console.log("Relation address - GetLocationById - data.data ->", data.data);
      if (data) {
        this.setState({
          locationAddress: data.data
        });
      }
    })();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loaderToggle(true));

    (async () => {
      const { ok, data } = await GetAddressDependencies();

      dispatch(loaderToggle(false));

      if (ok) {
        const address_types = data.address_types.data ? data.address_types.data : [];
        let addressTypeOpts = [];

        address_types.length > 0 && address_types.map(({ type, id }) => {
          return addressTypeOpts.push({
            label: type,
            value: id
          })
        });

        this.setState({ addressTypeOpts });

        const { selectedData, user } = this.props;

        console.log("Relation address - selected - selectedData ->", selectedData);
        let address = Object.assign({}, this.state.address);

        if (selectedData) {
          this.setState({ "address": selectedData });
        }

        if (selectedData && selectedData.address_type_id) {
          const getType = addressTypeOpts.find(type => parseInt(type.value) === parseInt(selectedData.address_type_id));

          this.setState({ address_type_id: getType ? getType : null })
        }

        if (selectedData && selectedData.location_id) {
          this.getLocationData(selectedData.location_id);
        }

        dispatch(loaderToggle(true));

        console.log("addresses - selectedData ->", selectedData);

        await (async () => {
          const { ok, data } = await GetCountries();

          dispatch(loaderToggle(false));

          if (ok) {
            const countries = data.data ? data.data : [];
            let countryOpts = [];

            countries.length > 0 && countries.map(({ name, id }) => {
              return countryOpts.push({
                label: name,
                value: id
              })
            });

            this.setState({ countryOpts });

            if ((selectedData && selectedData.country_id) || user.tenant.default_country_id) {
              const getId = selectedData && selectedData.country_id ? selectedData.country_id : user.tenant.default_country_id,
                    getCountry = countryOpts.find(country => parseInt(country.value) === parseInt(getId));

              address.country_id = getCountry ? getCountry.value : null;

              this.setState({
                country_id: getCountry ? getCountry : null,
                address
              });

              this.getCities(getId)
            }
          }
        })();

        console.log("Relation address - (this.state) ->", this.state.address);

      }
    })();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // prevState
    // state
    const { locationAddress, address } = this.state;
    if (prevState.locationAddress !== locationAddress) {
      console.log("Relation address - componentDidUpdate - locationAddress = updated!!!!! - locationAddress ->", locationAddress);
      const { latitude, longitude } = locationAddress;

      this.setState({
        locationLat: latitude,
        locationLng: longitude,
      });
    }

    if (prevState.address !== address) {
      console.log("Relation address - componentDidUpdate - address = updated!!!!! - address ->", address);
    }
  }

  render() {
    const { show, hide, loader, selectedData } = this.props,
      { formType,
        formName,
        address,
        addressTypeOpts,
        address_type_id,
        countryOpts,
        country_id,
        cityOpts,
        city_id,
        defaultPrimary,
        locationAlert,
        locationAlertMessage,
        locationAlertContent,
        locationAlertIcon,
        locationAlertVisible,
        locationLat,
        locationLng,
        locationAddress,
        addressIsChecked,
        checkbox_auto_address_enabled,
        checkbox_auto_address_is_disabled } = this.state,
      { street1, city_name, house_number, house_number_letter, house_letter, house_number_suffix, room, zipcode, primary } = address;

    console.log("Relation address - coordinates - locationLat ->", locationLat);
    console.log("Relation address - coordinates - locationLng ->", locationLng);
    console.log("cityOpts ->", cityOpts);
    console.log("city_id ->", city_id);
    console.log("country_id ->", country_id);
    console.log("Relation address - locationAlertIcon ->", locationAlertIcon);
    console.log("Relation address - locationAlert ->", locationAlert);
    console.log("Relation address - locationAlertContent ->", locationAlertContent);
    console.log("Relation address - locationAlertMessage ->", locationAlertMessage);
    console.log("checkbox_auto_address_enabled - new current state ->", this.state.checkbox_auto_address_enabled);
    console.log("Address ->", address);
    console.log("Marker long", locationLng);
    console.log("Marker lat", locationLat);

    return (
      <Modal isOpen={show} toggle={hide} centered className="modal-xl">
        {(!loader || countryOpts) && <Form onSubmit={(e) => this.handleSubmit(e)}>
          <ModalHeader>
            {formType} {formName}
          </ModalHeader>
          <ModalBody className="mt-3 mb-3">

          {locationAlertVisible && addressIsChecked && locationAlert && locationAlertContent && locationAlertContent.length > 0 &&
            <Row>
              <Col>
                <Alert
                  color="success"
                  style={{ flexDirection: "column", color: "#fff", borderColor: "1px solid #0daa63", maxHeight: "300px", overflowY: "auto" }}
                  className="shadow"
                >
                  {locationAlertMessage}:<br />
                  {
                    locationAlertContent.map((item, i) => {
                      return (
                        <div
                          key={`location-alert-item-${i}`}
                          style={{ display: "block" }}
                        >
                          <a
                            className="alert-link"
                            onClick={(e) => this.selectLocation(e, i)}
                            rel="noreferrer"
                            key={`location-alert-item-link-${i}`}
                          >
                            <span className="ml-1">{item.street1} {item.house_number} {item.house_number_suffix ? item.house_number_suffix : ""} {item.house_letter ? item.house_letter : ""} {item.room ? item.room : ""}</span>
                            <span className="ml-1 mr-1">{item.city && item.city.name ? item.city.name : city_id && city_id.label ? city_id.label : ""}</span>
                          </a>
                          <br />
                        </div>
                      )
                    })
                  }
                </Alert>
              </Col>
            </Row>}

            <Row className="form-row">
              <Col md={3} className="pb-2">
                <span className="label">Street / House number:</span>
                {locationAlertIcon
                  ? (
                    <OverlayTrigger
                      placement={`right`} transition={false}
                      overlay={<Tooltip id={`tooltip-right`}>
                        <div className={`popover-html-contents`}>
                          <h3 className="white" color="white">Warning</h3>
                          <p>No valid Bach object found!</p>
                        </div>
                      </Tooltip>}
                    >
                      <span className="ml-2 billing-run-icon" id="no-valid-address" style={{ float: "right", marginTop: "3px" }}>
                        <FontAwesomeIcon className="f2x-mv-tabs-list-item-icon" color={`red`} size={`lg`} icon={faExclamationTriangle} />
                      </span>
                    </OverlayTrigger>
                  ) : ""}
              </Col>
              <Col md={6} className="pb-2">
                <Input
                  name="street1"
                  placeholder="Street"
                  value={street1 ? street1 : ''}
                  onChange={(e) => this.handleInputChange(e)}
                  onBlur={(e) => this.handleInputKeyDown(e)}
                //disabled={(country_id && country_id.value === 155) && (checkbox_auto_address_enabled === 1)}
                />
              </Col>
              <Col md={3} className="pb-1">
                <Input
                  name="house_number"
                  placeholder={checkbox_auto_address_enabled === 1 ? `House no.` : `House no.`}
                  value={house_number ? house_number : ''}
                  maxLength="10"
                  onChange={(e) => this.handleInputChange(e, "house_number")}
                  onBlur={(e) => this.handleInputKeyDown(e)}
                />
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={3} className="pb-2">
                <span className="label">House letter / Suffix / Room:</span>
              </Col>
              <Col md={9}>
                <Row className="form-row">
                  <Col md={4} className="pb-2">
                    <Input
                      name="house_number_letter"
                      placeholder={checkbox_auto_address_enabled === 1 ? `House letter` : `House letter`}
                      //value={house_number_letter ? house_number_letter : ''}
                      value={house_letter ? house_letter : house_number_letter ? house_number_letter : ''}
                      maxLength="10"
                      onChange={(e) => this.handleInputChange(e, "house_number_letter")}
                      onBlur={(e) => this.handleInputKeyDown(e)}
                    />
                  </Col>
                  <Col md={4} className="pb-2">
                    <Input
                      name="house_number_suffix"
                      placeholder={checkbox_auto_address_enabled === 1 ? `Suffix` : `Suffix`}
                      value={house_number_suffix ? house_number_suffix : ''}
                      maxLength="10"
                      onChange={(e) => this.handleInputChange(e, "house_number_suffix")}
                      onBlur={(e) => this.handleInputKeyDown(e)}
                    />
                  </Col>
                  <Col md={4} className="pb-2">
                    <Input
                      name="room"
                      placeholder={checkbox_auto_address_enabled === 1 ? `Room no.` : `Room no.`}
                      value={room ? room : ''}
                      onChange={(e) => this.handleInputChange(e, "room")}
                      onBlur={(e) => this.handleInputKeyDown(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="form-row">
              <Col md={3} className="pb-2">
                <span className="label">Zip code / City / Country:</span>
              </Col>
              <Col md={9}>
                <Row className="form-row">
                  <Col md={4} className="pb-2">
                    <Input
                      name="zipcode"
                      placeholder={checkbox_auto_address_enabled === 1 ? `Zip code` : `Zip code`}
                      value={zipcode ? zipcode : ''}
                      onChange={(e) => this.handleInputChange(e, "zipcode")}
                      onBlur={(e) => this.handleInputKeyDown(e)}
                    />
                  </Col>
                  <Col md={4} className="pb-2">
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder={country_id && !loader ? 'City' : 'Select country first'}
                      options={cityOpts}
                      value={city_id ? city_id : null}
                      onChange={this.handleSelectChange.bind(this, 'city_id')}
                      maxMenuHeight={300}
                      onBlur={(e) => this.handleInputKeyDown(e)}
                    //isDisabled={(country_id && country_id.value === 155) && (checkbox_auto_address_enabled === 1)}
                    />
                  </Col>
                  <Col md={4} className="pb-2">
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder='Country'
                      options={countryOpts}
                      value={country_id ? country_id : null}
                      onChange={this.handleSelectChange.bind(this, 'country_id')}
                      maxMenuHeight={300}
                      onBlur={(e) => this.handleInputKeyDown(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {selectedData
              ?
              <Row className="form-row">
                <Col md={3} className="pb-2">
                  <span className="label">Address type:</span>
                </Col>
                <Col md={9}>
                  <Row>
                    <Col md={6} className="pb-2">
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder='Type'
                        options={addressTypeOpts}
                        value={address_type_id ? address_type_id : null}
                        onChange={this.handleSelectChange.bind(this, 'address_type_id')}
                        maxMenuHeight={300}
                      />
                    </Col>
                    <Col md={6} className="pt-1">
                      <CustomInput
                        id="primary"
                        type="checkbox"
                        name="primary"
                        label="Primary address"
                        defaultChecked={primary}
                        onChange={(e) => this.handleCheckChange(e)}
                        disabled={defaultPrimary}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              :
              <React.Fragment>
                <Row className="form-row">
                  <Col md={3} className="pb-2 pt-1">
                    <span className="label">Save as type:</span>
                  </Col>
                  <Col md={9} className="pb-2">
                    <CustomInput
                      id="billing"
                      type="checkbox"
                      name="billing"
                      label="Billing address"
                      value="3"
                      className="mt-1"
                      onChange={(e) => this.handleCheckChange(e)}
                    />
                    <CustomInput
                      id="contact"
                      type="checkbox"
                      name="contact"
                      label="Contact"
                      className="mt-1"
                      value="1"
                      onChange={(e) => this.handleCheckChange(e)}
                    />
                    <CustomInput
                      id="provisioning"
                      type="checkbox"
                      name="provisioning"
                      label="Provisioning"
                      className="mt-1"
                      value="2"
                      onChange={(e) => this.handleCheckChange(e)}
                    />
                    <CustomInput
                      id="shipping"
                      type="checkbox"
                      name="shipping"
                      className="mt-1"
                      label="Shipping"
                      value="4"
                      onChange={(e) => this.handleCheckChange(e)}
                    />
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md={3} className="pb-2 pt-1">
                    <span className="label">Is primary address:</span>
                  </Col>
                  <Col className="pb-2 pt-1">
                    {this.state.formType !== 'edit' &&
                      <div className="custom-checkbox custom-control custom-controlled-checkbox">
                        <Input className="mt-1"
                          type="checkbox"
                          color={`primary`}
                          name="primary"
                          id="primary"
                          //checked={this.state.person.primary && this.state.person.primary === true || this.state.person.primary === 1 ? true : false}
                          defaultChecked={primary && primary !== '0'}
                          onChange={(e) => this.handlePrimaryCheckChange(e)}
                        />
                        <Label className="custom-controlled-checkbox-label" for="primary" check>Save as primary address</Label>
                      </div>
                    }
                    {this.state.formType === 'edit' ?
                      <CustomInput
                        id="primary"
                        type="checkbox"
                        className="mt-1"
                        name="primary"
                        label="Primary address"
                        defaultChecked={primary && primary !== '0'}
                        onChange={(e) => this.handleCheckChange(e)}
                      /> : ''}
                  </Col>
                </Row>
                <Row className="form-row" style={{ display: "none" }}>
                  <Col md={3} className="pb-2 pt-1">
                    <span className="label">Auto check address:</span>
                  </Col>
                  <Col className="pb-2">
                    <CustomInput
                      id="checkbox_auto_address_enabled"
                      type="checkbox"
                      className="mt-1"
                      name="checkbox_auto_address_enabled"
                      label="API location address"
                      checked={checkbox_auto_address_enabled === 1}
                      onChange={(e) => this.handleAddressCheckChange(e)}
                      disabled={checkbox_auto_address_is_disabled}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            }

            {selectedData && selectedData.location_id && locationLat !== null && locationLng !== null ? (
            <Row className="form-row">
              <Col md={3} className="pb-2">
                <span className="label">Map:</span>
              </Col>
              <Col md={9} className="pb-2">
              
                <>
                  <hr />
                  <InitMap 
                    lat={locationLat}
                    lng={locationLng}
                    selectedData={selectedData}
                    locationAddress={locationAddress}
                    mapZoom={11}
                  />
                </>
              </Col>
            </Row>
            ) : (
              <p style={{fontStyle: "italic", display: "none"}}>Can't show map because location_id = null</p>
            )}
              
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <span className="btn btn-danger" onClick={hide}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
            {addressIsChecked &&
              <Button color="secondary" onClick={(e) => this.clearAddress(e)}>Clear</Button>}
            <Button color="primary" type="submit">
            <FontAwesomeIcon icon={faSave} />
            </Button>
          </ModalFooter>
        </Form>}
      </Modal>
    )
  }
}

const userSelector = createSelector(
  state => state.user,
  user => user
);

const loaderSelector = createSelector(
  state => state.loader,
  loader => loader
);

const mapStateToProps = createSelector(
  userSelector,
  loaderSelector,
  (user, loader) => ({
    user,
    loader
  })
);

export default connect(mapStateToProps)(AddressForm);
