import React from "react";
import {Link, useRoutes, withRouter} from 'react-router-dom';

class NotFound extends React.Component {
  render() {
    return (<div>
      <h1>404 Page not found</h1>
      <p>
        It looks like you're lost, this page doesn't exist.
      </p>
    </div>);
  }
}

export default withRouter(NotFound);
