import React from "react";
import { connect } from "react-redux";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Row,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Button,
    CustomInput
} from "reactstrap";

import dragula from "react-dragula";
import { MoreHorizontal } from "react-feather";


class Lane extends React.Component {
  handleContainerLoaded = container => {
    if (container) {
      this.props.onContainerLoaded(container);
    }
  };

  render() {
    const {status, children } = this.props;
    return (
      <Card>
        <CardHeader>
          <div className="card-actions float-right">
            <UncontrolledDropdown>
              <DropdownToggle tag="a">
                <MoreHorizontal />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <CardTitle tag="h5">{status}</CardTitle>
        </CardHeader>
        <CardBody className="p-3">
          <div ref={this.handleContainerLoaded}>{children}</div>
          <Button color="primary" block>
            Add task
          </Button>
        </CardBody>
      </Card>
    );
  }
}

const Task = ({ id, checked, text, name }) => (
  <Card className="mb-3 bg-light cursor-grab">
    <CardBody className="p-3">
      <div className="float-right mr-n2">
        <CustomInput
          type="checkbox"
          id={"exampleCustomCheckbox" + id}
          defaultChecked={checked ? true : false}
        />
      </div>
      <p>{text}</p>
      <div className="float-right mt-n1">
        {name}
      </div>
      <Button color="primary" size="sm">
        View
      </Button>
    </CardBody>
  </Card>
);

// const TaskCard = [
//   {
//     status: 'Upcoming1',
//     text: 'test1',
//     avatar: 'name1'
//   }, {
//     status: 'In Progress',
//     text: 'test2',
//     avatar: 'name2'
//   }, {
//     status: 'On Hold',
//     text: 'test3',
//     avatar: 'name3'
//   }, {
//     status: 'Completed',
//     text: 'test4',
//     avatar: 'name4'
//   }
// ]

// const TaskCard = {
//   card1: [
//     {status: 'Upcoming', text: 'test1'},
//     {status: 'In Progress', text: 'test2'},
//     {status: 'On Hold', text: 'test3'},
//     {status: 'Completed', text: 'test4'}
//   ],
//   card2: [
//     {status: 'Upcoming', text: 'test1'},
//     {status: 'In Progress', text: 'test2'},
//   ]
// }

class Ticket extends React.Component {
  constructor(props) {
    super(props);
    this.containers = [];

    this.state = {
      TaskCard: [
        {status: 'Upcoming', text: 'test1', name: 'name1'}, 
        {status: 'In coming', text: 'test2', name: 'name2'}, 
        {status: 'On hold', text: 'test3', name: 'name3'}, 
        {status: 'Completed', text: 'test4', name: 'name4'}
      ]
    }
  }

  onContainerReady = container => {
    this.containers.push(container);
  };

  componentDidMount() {
    dragula(this.containers);
  }

  render() {
    const { TaskCard } = this.state;
    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Tickets</h1>

        <Row>
          {/* { Object.keys(TaskCard).map((key, y) =>
            <Col lg="6" xl="3" key={y}>
              <Lane status={key.status} onContainerLoaded={this.onContainerReady}>
                { TaskCard[key].map((item, itemIdx) =>
                  <Task key={itemIdx}
                    text={item.text}
                  />
                )}
              </Lane>
            </Col>
          )} */}

          { TaskCard.map((card, key) =>
            <Col lg="6" xl="3" key={key}>
              <Lane status={card.status} onContainerLoaded={this.onContainerReady}>
                <Task 
                id={card.name}
                text={card.text} 
                name={card.name} 
                />
              </Lane>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

export default connect(store => ({
    loader: store.loader
}))(Ticket);