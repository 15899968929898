import React, {useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { loaderToggle } from '../redux/actions/loaderActions';
import {
  validateEmail, validateEmailWithValidator,
} from '../redux/actions/validateActions';
import CONSTANTS from '../services/constants';
import { invoiceStatusObj } from '../services/misc';
import Toast from '../components/Toast';
import Swal from "sweetalert2";

const OptionReminderStatus = ({show, hide, row, updateStatus, dispatch, hasPaidOpt}) => {

  //console.log("OptionReminderStatus - row ->", row);
  //console.log("OptionReminderStatus - invoiceStatusObj ->", invoiceStatusObj);

  const { reminder, id, relation_primary_person_email, email } = row;
  //const reminderAction = reminder ? invoiceStatusObj(reminder.reminder_status).action : 'Send reminder';
  const reminderAction = row && row.sales_invoice_reminder ? invoiceStatusObj(row.sales_invoice_reminder).action : 'Send reminder';

  const handleReminderAction = (e) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(loaderToggle(true));

    const { valid } = dispatch(validateEmailWithValidator(relation_primary_person_email ? relation_primary_person_email : email ? email : ''));
    if (valid) {
      if (reminder && reminder.reminder_status === CONSTANTS.FINAL_NOTICE_SENT) {
        dispatch(loaderToggle(false));
        Swal.fire({
          customClass: { container: 'has-cancel' },
          title: 'Please confirm',
          text: 'Has this invoice been sent to a collection agency?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then(res => {
          if (res.value) {
            updateStatus(id);
          }
        });

      } else {
        dispatch(loaderToggle(false));
        Swal.fire({
          customClass: { container: 'has-cancel' },
          title: 'Please confirm',
          text: 'Are you sure you want to send a reminder?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }).then(res => {
          if (res.value) {
            updateStatus(id);
          }
        });
      }

    } else {
      dispatch(loaderToggle(false));

      if (!valid) {
        Toast.fire({ title: "The email can’t be sent, because the relation's primary person has an invalid email address.", icon: 'error' })
      } else {
        Toast.fire({ title: "The email can’t be sent, because the relation has no primary person.", icon: 'error' })
      }
    }
  };

  return <DropdownItem tabIndex="0" key={`${id}-status`} onClick={(e) => handleReminderAction(e)}>{reminderAction}</DropdownItem>
};

const mapStateToProps = ({ dispatch }) => ({ dispatch });

export default withRouter(connect(mapStateToProps)(OptionReminderStatus));