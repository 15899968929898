import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Form, FormGroup, Input, Row, CustomInput } from "reactstrap";
import { AddGroup, UpdateGroup, GetGroupDependencies} from '../../../controllers/tenants';
import { loaderToggle } from '../../../redux/actions/loaderActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

class GroupForm extends React.Component {
    constructor(props) {
        super(props);

        const { selectedData } = this.props;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'group',
            group: selectedData ? selectedData : {}
        }
    }

    handleInputChange({ target: { name, value } }) {
        let group = Object.assign({}, this.state.group);

        group[name] = value;

        this.setState({ group })
    }

    handleRadioChange = ({ target: { name, value } }) => {
        let group = Object.assign({}, this.state.group),
            roles = Object.assign({}, group.roles);

        roles[name] = { value };
        group.roles = roles;

        this.setState({ group })
    };

    handleSubmit(e) {
        e.preventDefault();
        
        let group = Object.assign({}, this.state.group);
        const { id, selectedData, dispatch, loader } = this.props;

        if (!loader) {
            dispatch(loaderToggle(true));

            if (selectedData) {
                (async () => {    
                    const { ok } = await UpdateGroup(group, id, selectedData.id);

                    this.handleOk(ok)
                })()
            } else {
                (async () => {
                    const { ok } = await AddGroup(group, id);

                    this.handleOk(ok)
                })()
            }
        }
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update();
        }
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(loaderToggle(true));

        (async () => {
            const { ok, data } = await GetGroupDependencies();

            if (ok) {
                const roles = data.data.roles;
                let roleNames = [];

                roles.length > 0 && roles.map(({ slug, id }) => {
                    return roleNames.push({
                        label: slug,
                        value: id
                    })
                });

                this.setState({ roleNames })
            }

            dispatch(loaderToggle(false))
        })()
    }

    render() {
        const { show, hide, loader } = this.props,
              { formType, formName, roleNames, group } = this.state,
              { name, description, roles } = group;

        return (
            <React.Fragment>
                <Modal className="form-subscription-lines" isOpen={ show } toggle={ hide } centered>
                    { !loader && <Form onSubmit={ (e) => this.handleSubmit(e) }>
                        <ModalHeader>{ formType } { formName }</ModalHeader>
                        <ModalBody className="mt-3 mb-3">
                            <Row className="pb-2">
                                <Col md={3}>
                                    <label className="col-form-label">Group name:</label>
                                </Col>
                                <Col md={9}>
                                    <Input 
                                        id="name"
                                        name="name" 
                                        value= { name ? name : '' }
                                        placeholder="Group Name" 
                                        onChange={ (e) => this.handleInputChange(e) }
                                    />
                                </Col>
                            </Row>
                            <Row className="pb-4">
                                <Col md={3}>
                                    <label className="col-form-label">Description:</label>
                                </Col>
                                <Col md={9}>
                                    <Input 
                                        id="description"
                                        name="description" 
                                        value= { description ? description : '' }
                                        placeholder="Description" 
                                        onChange={ (e) => this.handleInputChange(e) }
                                    />
                                </Col>
                            </Row>
                            { roleNames && roleNames.length > 0 && roleNames.map(({ label }, i) => {
                                return (
                                    <FormGroup className="row pb-2 mb-0" key={ i }>
                                        <Col md="3 mb-0">
                                            <label className="col-form-label capitalized">{ label }:</label>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <CustomInput 
                                                        id={ `${ label }01` }
                                                        name={ label }
                                                        value="01"
                                                        type="radio"
                                                        className="mt-1"
                                                        label="Read"
                                                        aria-label={ label }
                                                        defaultChecked={roles && roles[label] && roles[label]["value"] === "01" ? true : false }
                                                        onChange={ (e) => this.handleRadioChange(e) }
                                                    />
                                                </Col>
                                                <Col>
                                                    <CustomInput 
                                                        id={ `${ label }11` }
                                                        name={ label }
                                                        value="11"
                                                        type="radio"
                                                        className="mt-1"
                                                        label="Write/read"
                                                        aria-label={ label }
                                                        defaultChecked={roles && roles[label] && roles[label]["value"] === "11" ? true : false }
                                                        onChange={ (e) => this.handleRadioChange(e) }
                                                    />
                                                </Col>
                                                <Col>
                                                    <CustomInput 
                                                        id={ `${ label }00` }
                                                        name={ label }
                                                        value="00"
                                                        type="radio"
                                                        className="mt-1"
                                                        label="No access"
                                                        aria-label={ label }
                                                        defaultChecked={roles && roles[label] && roles[label]["value"] === "00" ? true : false }
                                                        onChange={ (e) => this.handleRadioChange(e) }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>                                
                                    </FormGroup>
                                )
                            }) }
                        </ModalBody>
                        <ModalFooter className="justify-content-between">
                            <span className="btn btn-danger" onClick={ hide }>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                            <Button color="primary">
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        </ModalFooter>
                    </Form> }
                </Modal >
            </React.Fragment>
        )
    }
}

export default connect(store => ({
    loader: store.loader
}))(GroupForm);