import React, {useEffect, useState, useRef} from 'react';
import { connect } from "react-redux";
import ReactDOMServer from 'react-dom/server';
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker, {registerLocale} from "react-datepicker";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Row,
    Col,
    Form,
    Input,
    Label,
    CustomInput,
    UncontrolledCollapse,
} from 'reactstrap';
import { loaderToggle } from "../../redux/actions/loaderActions";
import { validateDate } from "../../redux/actions/validateActions";
import Toast from "../../components/Toast";
import {useTranslation, withTranslation} from 'react-i18next';
import { nl, enGB, enUS } from "date-fns/locale";
import {parseDateToSaveFormat} from '../../redux/actions/formatActions';
import ReactQuill from 'react-quill';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {updateError} from '../../redux/actions/errorActions';
import FormElementBuilder from '../../components/form_builder/FormElementBuilder';
import OfferPersonal from '../../components/offer/Personal';
import OfferBank from '../../components/offer/Bank';
import {
    faCaretDown,
    faCaretUp,
    faCaretLeft,
    faArrowLeft,
    faArrowCircleDown,
    faArrowCircleUp,
    faAngleLeft,
    faAngleRight,
    faArrowCircleLeft,
} from '@fortawesome/free-solid-svg-icons';
import {DatePickerField} from "../../components/form_builder/FormElements";
import {ErrorMessage} from "formik";
import moment from 'moment';
import {addDays} from "date-fns";

registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

const dateLocales = {
    'nl': nl,
    'en': enGB,
    'us': enUS
};

const dateLocalesPath = {
    'nl': 'nl',
    'en': 'en-GB',
    'us': 'en-US'
};

const RadioElement = ({...props}) => {
    const { selectedData, dynamicFieldData, dispatch, setStepFormData, subscription, stepForm, stepFormData, pricesIncludedVat } = props;

    const [elementKey, setElementKey] = useState("");
    const [options, setOptions] = useState([]);
    const [inlineStyle, setInlineStyle] = useState("");
    const [groupId, setGroupId] = useState(null);
    const [groupName, setGroupName] = useState(null);
    const [parent, setParent] = useState(null);
    const [lineData, setLineData] = useState({});
    const [customWishDateEnabled, setCustomWishDateEnabled] = useState( null);
    const [radioSelected, setRadioSelected] = useState([]);
    const [readOnlyDatePicker, setReadOnlyDatePicker] = useState( false);
    const { t, i18n, i18next } = useTranslation();

    const getLocale = locale => require(`date-fns/locale/${dateLocalesPath[i18n.language]}/index.js`);

    const formatCurrency = (price_excl, price_incl) => {
        let price;
        if (pricesIncludedVat) {
            price = price_incl;
        } else {
            price = price_excl;
        }

        let floatCell = parseFloat(price ? price : 0);
        let floatStyle = floatCell && floatCell < 0 ? 'float-negative' : floatCell && floatCell > 0 ? 'float-positive' : 'float-null';
        return <span className={floatStyle ? floatStyle : 'float-zero'}>{t("currency_format", { number: Number(floatCell) })}</span>
    };

    const renderDatePickerField = (data, name, value, style, style_dependency_value) => {
        if (!style) style = data.style ? data.style : "";
        if (!style_dependency_value) style_dependency_value = data.style_dependency_value ? data.style_dependency_value : "";

        const { selectedData, dynamicFieldData, setStepFormData, stepFormData, dispatch, handleDynamicFieldData } = props;
        const { subscription } = stepFormData;

        let isDisabled = false;
        if (
            stepFormData && stepFormData.subscription && stepFormData.subscription[name] &&
            style_dependency_value && stepFormData.subscription[name] === style_dependency_value) {
            isDisabled = false;
        } else if (!!(style && style === "disabled")) {
            isDisabled = true;
        }

        if (data && data.name) {
            let minDate = null;
            if (data.json_data) {
                const configData = JSON.parse(data.json_data);
                if (configData && configData["minimum_days_delayed"]) {
                    minDate = parseInt(configData["minimum_days_delayed"]);
                }
            }

            let isReadOnly = false;
            if (
                stepFormData && stepFormData.subscription && stepFormData.subscription[name] &&
                style_dependency_value && stepFormData.subscription[name] === style_dependency_value) {
                isReadOnly = false;
            } else if (!!(style && style === "disabled")) {
                isReadOnly = true;
            }

            return (
                <div id={`radio_element_${name}`} className={`datepicker-dynamic-data-container mt-2 mb-1 ml-4`}>
                    <DatePicker
                        locale={getLocale()}
                        name={data.name}
                        id={`${data.name}-id`}
                        wrapperClassName="w-100"
                        className="form-control"
                        dateFormat={t("date_format_raw")}
                        autoComplete="off"
                        //selected={
                        //    subscription && subscription[data.name]
                        //       ? moment(subscription[data.name], "DD-MM-YYYY")._d
                        //        : ""
                        //}
                        value={subscription && subscription[data.name] ? new Date(subscription && subscription[data.name]) : null}
                        selected={subscription && subscription[data.name] ? new Date(subscription && subscription[data.name]) : null}
                        onChange={(e) => handleDatePickerChange(e, data.name, "datepicker")}
                        placeholderText={data.placeholder ? data.placeholder : `Select date`}
                        minDate={ minDate ? addDays(new Date(), minDate) : ""}
                        //isClearable={true}
                        readOnly={isReadOnly}
                    />
                </div>
            )
        }
        return "";
    };

    const renderInputField = (data, name, value, style, style_dependency_value, group_name) => {
        const { selectedData, dynamicFieldData, setStepFormData, stepFormData, dispatch, handleDynamicFieldData } = props;
        const { subscription } = stepFormData;

        console.log("renderInputField - data ->", data);
        console.log("renderInputField - name ->", name);
        console.log("renderInputField - group_name ->", group_name);
        console.log("renderInputField - style_dependency_value ->", style_dependency_value);
        console.log("renderInputField - subscription ->", subscription);
        //console.log("renderInputField - subscription[group_name][name] ->", subscription[group_name][name]);

        let isDisabled = false;

        if (group_name) {
            if (stepFormData && stepFormData.subscription &&
                stepFormData.subscription[group_name] &&
                stepFormData.subscription[group_name][name] &&
                stepFormData.subscription[group_name][name].value &&
                style_dependency_value &&
                stepFormData.subscription[group_name][name].value === style_dependency_value) {
                isDisabled = false;
            } else if (!!(style && style === "disabled")) {
                isDisabled = true;
            }

        } else {
            if (stepFormData && stepFormData.subscription &&
                stepFormData.subscription[name] &&
                stepFormData.subscription[name].value &&
                style_dependency_value &&
                stepFormData.subscription[name].value === style_dependency_value) {
                isDisabled = false;
            } else if (!!(style && style === "disabled")) {
                isDisabled = true;
            }
        }

        if (data && data.name) {
            return (
                <div className={`input-dynamic-data-container mt-2 mb-1 ml-4`}>
                    <input
                        name={data.name}
                        id={`${data.name}-id`}
                        className="input-dynamic-data form-control"
                        disabled={isDisabled}
                        value={subscription && subscription[group_name] && subscription[group_name][name] && subscription[group_name][name].input_value ? subscription[group_name][name].input_value : ""}
                        onChange={(e) => handleDynamicFieldData(e, name, "input", data, null, group_name)}
                    />
                </div>
            )
        }
        return "";
    };

    const handleDatePickerChange = (e, name, type) => {
        const { selectedData, dynamicFieldData, setStepFormData, stepFormData, dispatch, handleDynamicFieldData } = props;
        handleDynamicFieldData(e, name, "datepicker")
    };

    const renderDynamicData = (data, name, radioParentValue, style = null, style_dependency_value = null, group_name = null) => {
        if (data) {
            // default input field
            if (data.element === "input") {
                return renderInputField(data, name, radioParentValue, style, style_dependency_value, group_name);
            }
            // datepicker field
            else if (data.element === "datepicker") {
                return renderDatePickerField(data, name, radioParentValue, style, style_dependency_value, group_name);
            }
        }
    };

    const radioChangeHandler = (e, name, data, optionValue, dynamicData, option, group_id, group_name, line_data, parent) => {

        console.log("radioChangeHandler - handleDynamicFieldData - subscription ->", subscription);
        //console.log("radioChangeHandler - group_id ->", group_id);
        //console.log("radioChangeHandler - group_name ->", group_name);
        //console.log("radioChangeHandler - e ->", e);
        //console.log("radioChangeHandler - name ->", name);
        //console.log("radioChangeHandler - data ->", data);
        console.log("radioChangeHandler - optionValue ->", optionValue);
        console.log("radioChangeHandler - option ->", option);
        console.log("radioChangeHandler - lineData ->", lineData);
        console.log("radioChangeHandler - line_data (param) ->", line_data);
        //console.log("radioChangeHandler - group_id ->", group_id);
        //console.log("radioChangeHandler - dynamicData ->", dynamicData);

        const { handleDynamicFieldData, setStepFormData, stepFormData, handleStaticFieldData, setAdditionalCostsData, additionalCostsData } = props;

        console.log("radioChangeHandler - handleDynamicFieldData - stepFormData ->", stepFormData);
        //console.log("radioChangeHandler - handleDynamicFieldData ->", handleDynamicFieldData);

        if (handleStaticFieldData && name === "wish_date") {
            handleStaticFieldData(e, name, "radio", optionValue);

        } else {
            if (handleDynamicFieldData) {
                console.log("radioChangeHandler is handled by its parent in handleDynamicFieldData");
                console.log("radioChangeHandler - handleDynamicFieldData - type = radio check for child elements - group_name = true - subscription ->", subscription);

                handleDynamicFieldData(e, name, "radio", option, group_id, group_name, line_data, parent); // data - name - type

            } else if (handleStaticFieldData) {
                handleStaticFieldData(e, name, "radio", optionValue);

            } else {
                let stepFormData = Object.assign({}, stepFormData);

                if (option && option["price_incl"] && option["price_excl"]) {
                    let additionalCostsDataObj = Object.assign({}, additionalCostsData);
                    additionalCostsDataObj[name] = {
                        total_price_excl: option.price_excl,
                        total_price_incl: option.price_incl,
                        group_id: group_id
                    };
                    setAdditionalCostsData(additionalCostsDataObj);
                }

                subscription[name] = optionValue; //data;
                stepFormData.subscription = subscription;
                setStepFormData(stepFormData);
            }
        }
    };

    useEffect(() => {
        const {
            name,
            options,
            style,
            dynamicFieldData,
            setStepFormData,
            stepFormData,
            jsonData,
            customWishDateDisabled, // => only used with wish_date
            defaultChecked,
            parentContainerId, // => targetKey (should be same as name)
            buttonGroupCollapseState, // => buttonGroupCollapseState[targetKey] === true -> inLineStyle = not-hidden | buttonGroupCollapseState[targetKey] === false -> inLineStyle = hidden
            typeElement, // => button || radio
            groupId, // main group ID
            groupName, // main group name
            lineData,
            parent,
        } = props;

        //console.log("RadioElement - name ->", name);
        //console.log("RadioElement - groupId ->", groupId);
        //console.log("RadioElement - groupName ->", groupName);
        console.log("RenderPlanLinesInGroupButton - RadioElement - lineData ->", lineData);
        console.log("RenderPlanLinesInGroupButton - RadioElement - options ->", options);
        console.log("RenderPlanLinesInGroupButton - RadioElement - parent ->", parent);
        console.log("RenderPlanLinesInGroupButton - RadioElement - defaultChecked ->", defaultChecked);

        const { subscription } = stepFormData;

        if (stepFormData && stepFormData.subscription && stepFormData.subscription["wish_date"] && stepFormData.subscription["wish_date"] !== "anders") {
            setCustomWishDateEnabled(true);
        }
        if (groupId) {
            setGroupId(groupId);
        }
        if (groupName) {
            setGroupName(groupName);
        }
        if (lineData) {
            setLineData(lineData);
        }
        if (name && options) {
            setElementKey(name);
            setOptions(options);

            if (typeElement && typeElement === "button" && parentContainerId && buttonGroupCollapseState && buttonGroupCollapseState[parentContainerId]) {
                setInlineStyle("not-hidden");
            } else {
                setInlineStyle(style);
            }
        }
        if (parent && parent.id) {
            setParent({id: parent.id, name: parent.name})
        }
    }, []);

    useEffect(() => {
        const { style, inLineStyle } = props;

        if (inLineStyle) {
            setInlineStyle(inLineStyle);
        }

    }, [stepFormData]);

    const isDefaultChecked = (option, groupName, parent) => {
        console.log("RenderPlanLinesInGroupButton - RadioElement - isDefaultChecked - stepFormData.subscription ->", stepFormData.subscription);
        //console.log("RenderPlanLinesInGroupButton - RadioElement - isDefaultChecked - parent ->", parent);
        console.log("RenderPlanLinesInGroupButton - RadioElement - isDefaultChecked - option ->", option);
        console.log("RenderPlanLinesInGroupButton - RadioElement - isDefaultChecked - groupName ->", groupName);
        //console.log("RenderPlanLinesInGroupButton - RadioElement - isDefaultChecked - stepFormData.subscription[groupName] ->", stepFormData.subscription[groupName]);
        //console.log("RenderPlanLinesInGroupButton - RadioElement - isDefaultChecked - stepFormData.subscription[option.name] ->", stepFormData.subscription[option.name]);
        //if (stepFormData.subscription[groupName] && stepFormData.subscription[groupName][option.parent_name]) {
        //    console.log("RenderPlanLinesInGroupButton - RadioElement - isDefaultChecked - stepFormData.subscription[groupName][option.parent_name] ->", stepFormData.subscription[groupName][option.parent_name]);
        //}

        if (parent && parent.name) {
            if (stepFormData &&
                stepFormData.subscription &&
                stepFormData.subscription[parent.name] &&
                stepFormData.subscription[parent.name][option.name] &&
                stepFormData.subscription[parent.name][option.name].value &&
                stepFormData.subscription[parent.name][option.name].value === option.value) {
                return true;
            } else {
                return false;
            }
        } else if (groupName) {
            if (stepFormData && stepFormData.subscription &&
                stepFormData.subscription[groupName] &&
                stepFormData.subscription[groupName][option.name] &&
                stepFormData.subscription[groupName][option.name] === option.value) {
                return true;
            } else {
                return false;
            }
        } else {
            if (stepFormData && stepFormData.subscription &&
                stepFormData.subscription[option.name] &&
                stepFormData.subscription[option.name] === option.value) {
                return true;
            } else {
                return false;
            }
        }
    };

    return (
        <div
            role="group"
            aria-labelledby={`${elementKey}-radio-group`}
            id={elementKey}
            className={`${inlineStyle}`}
        >
            {options.map((option, index) =>
                <div key={`${option.name}-${index}`} className="custom-radio custom-control custom-controlled-radio" style={{paddingLeft: "3px"}}>
                    <CustomInput
                        className="mb-1"
                        id={`${option.name}-${index}`}
                        type="radio"
                        name={option.name}
                        label={option.label}
                        value={option.value}
                        defaultChecked={
                            isDefaultChecked(option, groupName, parent)
                        }
                        data-is-checked={
                            isDefaultChecked(option, groupName, parent)
                        }
                        style={{marginLeft: "0", display: "block", opacity: "0", zIndex: "1", maxWidth: "75%"}}
                        onChange={(e) => radioChangeHandler(e, option.name, "radio", option.value, option.data, option, groupId, groupName, lineData, parent)}
                    />

                    {option.price_excl && option.price_incl
                        ? <span
                            className="plan-offer-line-price"
                            style={{
                                position: "absolute",
                                right: 0,
                                maxWidth: "25%",
                                top: 0
                                }}
                           >
                            {formatCurrency(option.price_excl, option.price_incl)}
                           </span>
                        : option.price === false
                        ? ""
                        : <span
                                className="plan-offer-line-price"
                                style={{position: "absolute", right: 0, maxWidth: "25%", top: 0}}
                            >
                                GRATIS
                          </span>
                    }

                    {option.data
                        ? renderDynamicData(
                            option.data,
                            option.name,
                            option.value,
                            option.style ? option.style : null,
                            option["style_dependency_value"] ? option["style_dependency_value"] : null,
                            groupName
                        )
                        : ""
                    }
                </div>
            )}
        </div>
    );
};

export default withRouter(connect()(RadioElement));
