import React, {useEffect, useState} from 'react';
import {GetSubscription} from '../../../../controllers/subscriptions';
import {
  Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, Row, Col, Button, ModalFooter,
} from 'reactstrap';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import classnames from 'classnames';
import ReactJson from 'react-json-view';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch, faTimes} from '@fortawesome/free-solid-svg-icons';

const JsonDataForm = ({ show, hide, selectedData }) => {
  let tabStart = 0;

  const [refreshData, setRefreshData] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(selectedData && selectedData.id ? selectedData.id : null);
  const [detailsList, setDetailsList] = useState([]);
  const [noDetailsList, setNoDetailsList] = useState(false);
  const [isLoadingStatusItems, setIsLoadingStatusItems] = useState(false);
  const [activeTab, setActiveTab] = useState('tab-0'); 
  const [modalTitle, setModalTitle] = useState("Provisioning information"); // Title = Provisioning information


  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const refreshJsonDetailsData = (e) => {
    e.preventDefault();
    setRefreshData(true);
  }

  useEffect(() => {
    console.log("Provisioning information - get JSON data from selectedData ->", selectedData);
    if (selectedData.json_datas) {
      setDetailsList(selectedData.json_datas);
    } else {
      setNoDetailsList(true);
    }
  }, []);

  useEffect(() => {
    if (refreshData && subscriptionId) {
      (async () => {
        setIsLoadingStatusItems(true);

        const [subscriptionData] = await Promise.all([GetSubscription(null, subscriptionId)]);
  
        if (subscriptionData.ok) {
          const {data} = subscriptionData.data;
          console.log(`Provisioning information - refresh subscriptionJsonDatas - data ->`, data);
  
          if (data && data.json_datas) {
            setDetailsList(data.json_datas);
          } else {
            setNoDetailsList(true);
          }
        }

        setRefreshData(false);
        setTimeout(() => {
          setIsLoadingStatusItems(false);
        }, 2000);

      })();
    }
  }, [refreshData]);

  /*
    toggle={hide} for close button in modal
  */

  return (
      <Modal isOpen={show} toggle={hide} centered className="modal-lg">
        <ModalHeader className="titlew-100 modal-header">
          <div className='justify-content-between align-items-middle row'>
            <div className='col'>{modalTitle}</div>
            <div className='dropdown'>
              <OverlayTrigger placement={`left`} transition={false} overlay={
                <Tooltip id={`tooltip-right-refresh`}>
                  <div className={`popover-html-contents`}>
                    <span>{`Refresh information`}</span>
                  </div>
                </Tooltip>}
              >
                <Button color="white" className="mb-1 btn btn ml-1 mr-3" style={{ background: "transparent", border: "none" }}
                        onClick={(e) => refreshJsonDetailsData(e)} disabled={isLoadingStatusItems}>
                  <FontAwesomeIcon color="white" icon={faCircleNotch} /><span className='ml-1' style={{display:"inline", color:"white"}}>Refresh</span>
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            {detailsList && detailsList.length > 0 && detailsList.map((json_data, index) => {
              return (
                <NavItem key={index} className="mr-1">
                  <NavLink
                    className={classnames({active: activeTab === 'tab-'+ index})}
                    onClick={() => {toggle('tab-' + index)}}
                    disabled={isLoadingStatusItems}
                  >
                    {json_data && json_data.backend_api ? json_data.backend_api : index}
                  </NavLink>
                </NavItem>)
            })}
          </Nav>
          <TabContent activeTab={activeTab} id="tab-contents">
            {detailsList && detailsList.length > 0 && detailsList.map((json_data, index) => {
              return (
                <TabPane key={index} id={index +'_'+ tabStart++} tabId={'tab-' + index} style={isLoadingStatusItems ? {opacity: "0.3"} : {opacity: "1"}}>
                  <Row className="align-items-end">
                    <Col md={12}>
                      <div className="px-3 mt-4 mb-3 tab-json-data">
                        <ul className="tree-view-data">
                          <ReactJson
                              src={json_data.json_data}
                              enableClipboard={false}
                              style={{ fontFamily: "inherit", backgroundColor: "transparent" }}
                          />
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </TabPane>)
            })}
          </TabContent>   
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </ModalFooter>
      </Modal>
  )
};

export default JsonDataForm;
