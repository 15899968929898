import ApiService from '../services/apiService';

const Api = new ApiService(),
      GetAPI = Api.getAPI,
      GetAPIWithError = Api.getAPIWithError;
    
const asyncFunc = (endpoint, params, method) => {
    return (async () => {
        return await method ? method(endpoint, params) : GetAPI(endpoint, params);
    })();
};

const GetAddressAvailability = (params) => {
    return asyncFunc(`l2fiber/availability`, params);
};

const Addresses = (params) => {
    return asyncFunc(`l2fiber/addresses`, params);
};

const CheckAddressConnectionRegistration = (params) => {
    return asyncFunc(`l2fiber/connection`, params);
};

const RegisterAddressConnection = (params) => {
    return asyncFunc(`l2fiber/connection`, params);
};

const ActivateAddressOnt = (data) => {
    return asyncFunc(`l2fiber/ont`, data, GetAPIWithError);
};

const AddressTermination = (params) => {
    return asyncFunc(`l2fiber/terminate`, params, GetAPIWithError);
};

export {
    GetAddressAvailability,
    Addresses,
    CheckAddressConnectionRegistration,
    RegisterAddressConnection,
    ActivateAddressOnt,
    AddressTermination,
}