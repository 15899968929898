import React, { useState, useEffect } from 'react';
import {Row, Col, Button, Input, FormGroup} from 'reactstrap';
import {Edit2, PlusSquare, Save, Trash} from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormBuilder from "./form_builder/FormBuilder";
import {
  faUnlink, faLink, faCalendarAlt, faLock, faLockOpen, faTimes, faPlus, faTrash,
} from '@fortawesome/free-solid-svg-icons';

const DynamicTextField = ({ ...props }) => {
  const defaultSchema = props.defaultSchema;
  const [fields, setFields] = useState([]); // defaultSchema ? defaultSchema : {}

  console.log("DynamicTextField - defaultSchema ->", defaultSchema);

  function handleChangeText(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }

  function handleChangeName(i, event) {
    const values = [...fields];
    values[i].name = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ name: null, value: null });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  useEffect(() => {
    if (defaultSchema) {
      console.log("DynamicTextField - useEffect - defaultSchema ->", defaultSchema);

      let fieldsArr = [];
      for (let key of Object.keys(defaultSchema)){
        fieldsArr.push({ name: key, value: { [key]: defaultSchema[key] }});
      }
      setFields(fieldsArr);
    }
  }, [defaultSchema]);

  useEffect(() => {
    if (fields) {
      console.log("DynamicTextField - useEffect - fields ->", fields);
      // update dynamicData
      props.dynamicData(fields);
    }
  }, [fields]);

  return (
      <>
        <Col xs={12} sm={12} md={12} className="mb-1">
          <button className="btn btn-gray text-truncate" color="gray" type="button" onClick={() => handleAdd()} disabled={fields.length > 9}>
            Add field
          </button>
        </Col>
        <Col xs={8} sm={8} md={8}>
          <ul className="block-list list-group list-group-flush">
            {console.log("fields ->", fields)}
            {fields.length === 0 && <p className="text-center mb-0" color={`grey`}>{`No fields added`}</p>}
            {fields.map((field, idx) => {
              return (
                  <li key={`text-${field}-${idx}`} data-key={`${idx}`} className="d-flex align-items-start pt-1 pb-1 list-group-item">
                    <div className="form-label col-3 col-md-3 col-lg-3 pl-2">
                      <Input
                          type="text"
                          name={`field-label-${idx}`}
                          placeholder="Enter label name"
                          value={field.name || ""}
                          onChange={e => handleChangeName(idx, e)}
                      />
                    </div>
                    <div className="form-label-content col-8 col-md-8 col-lg-8 pl-0">
                      <Input
                          name={`field-json-data-${idx}`}
                          style={{ minHeight: "200px" }}
                          type="textarea"
                          placeholder="Enter JSON data"
                          value={field.value ? JSON.stringify(field.value[[field.name]], undefined, 4) : ""}
                          onChange={e => handleChangeText(idx, e)}
                      />
                    </div>
                    <div className="form-rendered-content col-1 col-md-1 col-lg-1 pl-0">
                      <Trash
                          size="24"
                          className="p-1 hover-pointer"
                          title="Remove"
                          color="grey"
                          name="dynamic-field-remove-icon"
                          icon={faTrash}
                          style={{ width: "25px", position: "absolute", top: "5px", right: "10px" }}
                          type="button"
                          onClick={() => handleRemove(idx)}
                      />
                    </div>
                  </li>
              );
            })}
          </ul>
        </Col>
        <Col xs={4} sm={4} md={4}>
          <ul className="block-list list-group list-group-flush">
            <li className="d-flex align-items-start pt-1 pb-1 list-group-item">
              <FormBuilder formSchema={defaultSchema} />
            </li>
          </ul>
        </Col>
      </>
  );
};

export default DynamicTextField;
