import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Form, Input } from "reactstrap";
import { AddEntry, UpdateEntry } from '../../../../controllers/entries';
import { GetAccountsLists }  from '../../../../controllers/accounts';
import { loaderToggle } from "../../../../redux/actions/loaderActions";
import { validateDate } from "../../../../redux/actions/validateActions";
import { parseDateToSaveFormat, reverseFormat,} from '../../../../redux/actions/formatActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

class JournalForm extends React.Component {
    constructor(props) {
        super(props);

        const { selectedData } = this.props;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'Journal',
            entry: selectedData ? selectedData : {}
        }
    }

    handleInputChange({ target: { name, value }}) {
        let entry = Object.assign({}, this.state.entry);

        entry[name] = value;

        this.setState({ entry })
    }

    handleSelectChange(name, data) {
        let entry = Object.assign({}, this.state.entry);

        entry[name] = data.value;

        this.setState({ 
            entry, 
            [name]: data
        })
    }

    handleDatePickerChange(name, date) {
        let entry = Object.assign({}, this.state.entry);
        const { newDate } = this.props.dispatch(validateDate(date));
        
        entry[name] =  newDate;
        
        this.setState({ entry })
    }

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false)
        }
    };

    handleSubmit(e) {
        e.preventDefault();
        let entry = Object.assign({}, this.state.entry, {
            'date': parseDateToSaveFormat(this.state.entry.date),
            'credit': reverseFormat(this.state.entry.rounded_credit),
            'debit': reverseFormat(this.state.entry.rounded_debit),
            'vat_amount': reverseFormat(this.state.entry.rounded_vat_amount)
            });
        const { id, selectedData, loader, dispatch } = this.props;

        entry.journal_id = id;

        if (!loader) {
            dispatch(loaderToggle(true));
        
            if (selectedData) {
                (async () => {                    
                    const { ok } = await UpdateEntry(selectedData.id, entry);

                    this.handleOk(ok)
                })()
            } else {
                (async () => {
                    const { ok } = await AddEntry(entry);

                    this.handleOk(ok)
                })()
            }
        }
    }

    handleOk = (ok) => {
        const { hide, update, dispatch } = this.props;

        dispatch(loaderToggle(false));

        if (ok) {
            hide();
            update()
        }
    };

    componentDidMount() {
        const { match: { params: { id } }, selectedData, dispatch } = this.props;
        dispatch(loaderToggle(true));
        (async () => {
            const { ok, data } = await GetAccountsLists(id);

            if (ok) {
                let accounts = [];
                
                data.data.map(({ description, id }) => {
                    return accounts.push({
                        label: description,
                        value: id
                    })
                });

                this.setState({ accounts });
                
                if (selectedData) {
                    const getAccount = accounts.find(account => account.value === selectedData.account_id);
                    this.setState({ account_id: getAccount ? getAccount : null })
                }
            }
            
            dispatch(loaderToggle(false));
            this.setState({ hasLoaded: true })
        })()
    }

    render() {
        const { show, hide, loader } = this.props,
            { entry, accounts, account_id, hasLoaded } = this.state,
            { description, date, rounded_credit, rounded_debit, rounded_vat_amount } = entry;

        return (
            <React.Fragment>
                { (!loader || hasLoaded) && <Modal className="form-subscription-lines" isOpen={ show } toggle={ hide } centered>
                    <Form onSubmit={ (e) => this.handleSubmit(e) }>
                        <ModalHeader>
                            <span>{this.state.formType} Entry</span>
                        </ModalHeader>
                        <ModalBody key="0" className="mt-3 mb-3">
                            <Row className="pb-2">
                                <Col md={3}>
                                    <label className="col-form-label">Account:</label>
                                </Col>
                                <Col md={9}>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={ accounts }
                                        value={ account_id ? account_id : null } 
                                        onChange={ this.handleSelectChange.bind(this, 'account_id') }
                                        maxMenuHeight={300}
                                    />
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col md={3}>
                                    <label className="col-form-label">Description:</label>
                                </Col>
                                <Col md={9}>
                                    <Input 
                                        name="description" 
                                        placeholder="Description" 
                                        value={ description ? description : '' } 
                                        onChange={ (e) => this.handleInputChange(e)} 
                                    />
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col md={3}>
                                    <label className="col-form-label">Date:</label>
                                </Col>
                                <Col md={9}>
                                    <DatePicker
                                        id="date"
                                        className="form-control"
                                        name="date"
                                        dateFormat="P"
                                        autoComplete="off"
                                        placeholderText="Date from"
                                        selected={ date ? new Date(date) : null }
                                        //selected={ date ? moment(date, 'DD-MM-YYYY')._d : null }
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={ this.handleDatePickerChange.bind(this, 'date') }
                                        ref="date"
                                        onKeyDown={(e) => this.handleKeyDown(e, 'date')}
                                    />
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col md={3}>
                                    <label className="col-form-label">Credit:</label>
                                </Col>
                                <Col md={9}>
                                    <Input 
                                        name="rounded_credit" 
                                        placeholder="Credit" 
                                        value={ rounded_credit ? rounded_credit : '' } 
                                        onChange={ (e) => this.handleInputChange(e) } 
                                    />
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col md={3}>
                                    <label className="col-form-label">Debit:</label>
                                </Col>
                                <Col md={9}>
                                    <Input 
                                        name="rounded_debit" 
                                        placeholder="Debit" 
                                        value={ rounded_debit ? rounded_debit : '' } 
                                        onChange={ (e) => this.handleInputChange(e) } 
                                    />
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col md={3}>
                                    <label className="col-form-label">VAT amount:</label>
                                </Col>
                                <Col md={9}>
                                    <Input 
                                        name="rounded_vat_amount" 
                                        placeholder="VAT amount" 
                                        value={ rounded_vat_amount ? rounded_vat_amount : '' } 
                                        onChange={ (e) => this.handleInputChange(e) } 
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter className="justify-content-between">
                            <Button className="btn btn-danger" onClick={ hide } data-dismiss="modal">
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                            <Button color="primary">
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal> }
            </React.Fragment>            
        )
    }
}

export default connect(store => ({
    loader: store.loader
  }))(withRouter(JournalForm))
