import React from "react";
import { GetJournal, UpdateJournal } from '../../../controllers/journals';
import Entries from "./entries/List";
import DetailsPage from "../../../layouts/DetailsPage";
import Details from "../../../components/Details";

class JournalDetail extends React.Component {
  constructor(props) {
    super(props);

    const tenant_name_title = localStorage.getItem('selected_tenant_title_name');

    const { jid } = this.props.match.params;

    this.state = {
      tabs: [{
        title: 'Details',
        tenant_name: tenant_name_title,
        component: <Details
          getApi={GetJournal}
          updateApi={UpdateJournal}
          id={jid}
          icon={`question-circle`}
          details={[{
            label: 'Journal no',
            data: 'journal_no',
            type: 'text',
            disabled: true
          },{
            label: 'Invoice no',
            data: 'sales_invoice.invoice_no',
            type: 'text',
            disabled: true
          },{
            label: 'Customer no',
            data: 'sales_invoice.relation_customer_number',
            type: 'text',
            disabled: true
          },{
            label: 'Customer email',
            data: 'sales_invoice.relation_email',
            type: 'text',
            disabled: true
          },{
            label: 'Description',
            data: 'description',
            type: 'text'    
          },{
            label: 'Date',
            data: 'date',
            type: 'datepicker'
          },{
            label: 'Outstanding balance',
            data: 'outstanding_balance',
            type: 'text',
            disabled: true
          }]} />
          
      }, {
        title: 'Entries',
        tenant_name: tenant_name_title,
        component: <Entries journal_id={jid} icon={`archive`} />
      }]
    }
  }

  render() {
    const { tabs } = this.state;
    
    return (
      <React.Fragment>
        { tabs &&
          <DetailsPage
            name="Journal"
            tabs={tabs}
          />
        }
      </React.Fragment>
    )
  }
}

export default JournalDetail;
