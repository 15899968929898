
const CONSTANTS = {
  relationDetailsTabs: {
    details: 0,
    addresses: 1,
    persons: 2,
    'bank-accounts': 3,
    subscriptions: 4,
    invoices: 5,
    emails: 6 //, payments: 7,
  },

  invoiceStatuses: [
      { label: '1st reminder', value: 'first_reminder_sent', action: 'Send 2nd reminder', successMsg: 'Reminder was sent successfully.', styleClass: 'f2x-status-1' },
      { label: '2nd reminder', value: 'second_reminder_sent', action: 'Send warning', successMsg: 'Second reminder was sent successfully.', styleClass: 'f2x-status-2' },
      { label: 'Warning', value: 'warning_sent', action: 'Send final notice', successMsg: 'Warning was sent successfully.', styleClass: 'f2x-status-3' },
      { label: 'Final notice', value: 'final_notice_sent', action: 'To collection agency', successMsg: 'Final notice was sent successfully.', styleClass: 'f2x-status-4' },
      { label: 'Coll. agency', value: 'sent_to_collection_agency', action: 'Paid', successMsg: 'Sent to collection agency successfully', styleClass: 'f2x-status-5' }
  ],
  invoiceStatus: [
    { id: 0, value: 'concept', text: "CONCEPT" },
    { id: 2, value: 'processing', text: "PROCESSING" },
    { id: 20, value: 'open', text: "OPEN" },
    { id: 50, value: 'paid', text: "PAID" }
  ],
  subscriptionStatus: [
     { id: 10, value: 'new', text: "NEW", badge: "warning" }, /* new */
     { id: 20, value: 'concept', text: "CONCEPT", badge: "info" }, /* was 0 */
     { id: 30, value: 'provisioning', text: "PROVISIONING", badge: "default" }, /* new */
     { id: 40, value: 'planned', text: "PLANNED", badge: "default" }, /* new */
     { id: 50, value: 'ongoing', text: "ONGOING", badge: "success" }, /* was 1 */
     { id: 60, value: 'cancelled', text: "CANCELLED", badge: "warning" }, /* cancelled */
     { id: 80, value: 'de-provisioning', text: "DE-PROVISIONING", badge: "default" }, /* new */
     { id: 99, value: 'terminated', text: "TERMINATED", badge: "danger" } /* was 2 */
  ],

  INVOICE_PAID: 'Paid',
  INVOICE_SET_PAID: 'Set paid',
  INVOICE_SET_OPEN: 'Set open',
  FIRST_REMINDER_SENT: 'first_reminder_sent',
  FINAL_NOTICE_SENT: 'final_notice_sent',
  COLL_AGENCY: 'sent_to_collection_agency',

  INVOICE_STATUSES_LABELED: [
    {label: 'All', value: ''},
    { label: 'Concept', value: 'Concept' },
    { label: 'Ongoing', value: 'Ongoing' },
    { label: 'Terminated', value: 'Terminated' },
    { label: 'Paid', value: 'Paid' },
  ],
  SUBSCRIPTION_STATUSES: [
    { label: 'Concept', value: 20 },
    { label: 'Ongoing', value: 50 },
    { label: 'Terminated', value: 99 },
    { label: 'De-provisioning', value: 80 },
    { label: 'New', value: 10 },
    { label: 'Planned', value: 40 },
    { label: 'Provisioning', value: 30 },
  ],
  SUBSCRIPTION_LOG_TYPES: [ 
    {label: 'All', value: ''},
    {label: 'Brightblue', value: 'BrightBlue'}, 
    {label: 'M7', value: 'M7'}, 
    {label: 'Layer23', value: 'Layer23'} 
  ],
  SUBSCRIPTION_LOG_SEVERITY: [
    {label: 'Info', value: 'info'}, 
    {label: 'Warning', value: 'warn', styleClass: 'f2x-opt-warning'},
    {label: 'Error', value: 'err', styleClass: 'f2x-opt-err'},
    {label: 'Critical', value: 'crit', styleClass: 'f2x-opt-crit'},
    {label: 'Emergency', value: 'emerg', styleClass: 'f2x-opt-emerg'},
    {label: 'Alert', value: 'alert', styleClass: 'f2x-opt-alert'},
    {label: 'Notice', value: 'notice', styleClass: 'f2x-opt-notice'},
    {label: 'Debug', value: 'debug', styleClass: 'f2x-opt-debug'}
  ],
  SUBSCRIPTION_LOG_SEVERITY_EXT: [
    {label: 'Critical', value: 'crit', styleClass: 'f2x-opt-crit'},
    {label: 'Emergency', value: 'emerg', styleClass: 'f2x-opt-emerg'},
    {label: 'Alert', value: 'alert', styleClass: 'f2x-opt-alert'},
    {label: 'Notice', value: 'notice', styleClass: 'f2x-opt-notice'},
    {label: 'Debug', value: 'debug', styleClass: 'f2x-opt-debug'}
  ],
  SUBSCRIPTION_LOG_SEVERITY_DEF: [
    {label: 'All', value: ''},
    {label: 'Info', value: 'info'},
    {label: 'Warning', value: 'warn', styleClass: 'f2x-opt-warning'},
    {label: 'Error', value: 'err', styleClass: 'f2x-opt-err'}
  ],
  SUBSCRIPTION_PLAN_TYPES: [
    {id: 1, 'type': 'Free'},
    {id: 2, 'type': 'NRC'},
    {id: 3, 'type': 'MRC'},
    {id: 4, 'type': 'QRC'},
    {id: 5, 'type': 'YRC'},
    {id: 6, 'type': 'Deposit'},
    {id: 7, 'type': 'Discount'},
    {id: 8, 'type': 'VUC'}
  ],
  INVOICE_PRODUCT_TYPES: [
    {id: 2, 'type': 'NRC'},
    {id: 3, 'type': 'MRC'},
    {id: 4, 'type': 'QRC'},
    {id: 5, 'type': 'YRC'},
    {id: 6, 'type': 'Deposit'},
    {id: 99, 'type': 'TXT'}
  ],
  PRODUCT_TYPES: [
    {id: 1, 'type': 'Free'},
    {id: 2, 'type': 'NRC'},
    {id: 3, 'type': 'MRC'},
    {id: 4, 'type': 'QRC'},
    {id: 5, 'type': 'YRC'},
    {id: 6, 'type': 'Deposit'}
  ],

  PROVISIONING: [
    {label: 'Ready to provision'},
    {label: 'Provisioning in progress'},
    {label: 'Failed provisioning'},
    {label: 'Successfully provisioned'}
  ],
  PROVISIONING_READY: {id: '1', status: 'new', lineProps: [{status: 'inactive', action: 'Start provisioning', landingTab: '2'}, {status: 'migation_tbc', action: 'Start migration', landingTab: '2'}]},
  PROVISIONING_PROGRESS: {id: '2', status: 'provisioning', lineProps: [{status: 'check_pending', action: 'Abort', landingTab: '1'}, {status: 'order_pending', action: 'Abort', landingTab: '1'}, {status: 'migration_confirmed', action: 'Cancel migration', landingTab: '1'}, {status: 'migration_pending', action: 'Cancel migration'}]},
  PROVISIONING_FAILED: {id: '3', status: 'failed', lineProps: [{status: 'error', action: 'Retry'}, {status: 'check_error', action: 'Retry'}, {status: 'order_error', action: 'Retry'}, {status: 'migration_error', action: 'Retry'}, {status: 'cancel_error', action: 'Retry cancel'}, {status: 'rejected', action: 'Retry'}]},
  PROVISIONING_SUCCESS: {id: '4', status: 'active'},
  ORDERS_STATUSES: [
    {label: 'All', value: ''},
    {label: 'New', value: 'new'},
    {label: 'Processed', value: 'processed'},
  ],
  RELATION_TABS: [
    {value: 'details', tab: 0},
    {value: 'addresses', tab: 1},
    {value: 'persons', tab: 2},
    {value: 'bank-accounts', tab: 3},
    {value: 'subscriptions', tab: 4},
    {value: 'invoices', tab: 5}, //{value: 'payments', tab: 7} // not used (yet)
    {value: 'emails', tab: 6}
  ],

  MV_TAB_CONTENTS: [{
    "title": "Details",
    "key": 0,
    "name": "details",
  },
    {
      "title": "Addresses",
      "key": 1,
      "name": "addresses",
    },
    {
      "title": "Persons",
      "key": 2,
      "name": "persons",
    },
    {
      "title": "Bank accounts",
      "key": 3,
      "name": "bank-accounts",
    },
    {
      "title": "Subscriptions",
      "key": 4,
      "name": "subscriptions",
    },
    {
      "title": "Invoices",
      "key": 5,
      "name": "invoices",
    },
    {
      "title": "Emails",
      "key": 6,
      "name": "emails",
    }
  ],

  RELATION_STATUSES: [
    {value: 1, label: 'ACTIVE'},
    {value: 0, label: 'INACTIVE'},
  ],
  RELATION_BUSINESS_STATUSES: [
    {value: 1, label: 'Yes'},
    {value: 0, label: 'No'},
  ],
  TERMINATED_STATUS: 2,
  SUBSCR_LINE_MIGR_PENDING_STATUS: 'migration_pending',
  FINANCE_INVOICES_STATUSES: [
    { label: "All", value: "" },
    { label: "1st reminder", value: "first_reminder_sent" },
    { label: "2nd reminder", value: "second_reminder_sent" },
    { label: "Warning", value: "warning_sent" },
    { label: "Final notice", value: "final_notice_sent" },
    { label: "Coll. agency", value: "sent_to_collection_agency" },
  ],

  SIDEBAR_POSITION: [
    { LEFT: "left" },
    { RIGHT: "right" },
  ],
  SIDEBAR_BEHAVIOR: [
    { STICKY: "sticky" },
    { FIXED: "fixed" },
    { COMPACT: "compact" },
  ],
  LAYOUT: [
    { FLUID: "fluid" },
    { BOXED: "boxed" },
  ],

  MONTHS: [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ]
};

export default CONSTANTS
