import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Form, Input} from "reactstrap";
import Select from "react-select";
import CodeMirror from '@uiw/react-codemirror';
import {UpdateProvisioningTemplates, CreateProvisioningTemplates} from '../../../controllers/provisioning';
import {loaderToggle} from "../../../redux/actions/loaderActions";
import Toast from "../../../components/Toast";
import ToastMulti from "../../../components/ToastMulti";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faSave} from '@fortawesome/free-solid-svg-icons';
import {GetProductsList} from '../../../controllers/tenants';

const ProvisioningTemplateForm = ({ ...props }) => {
    console.log("ProvisioningTemplateForm - props ->", props);
    const {show, hide, selectedData} = props;

    const formType = selectedData ? 'Edit' : 'Add';
    const formName = "provisioning template";

    const [product, setProduct] = useState('');
    const [functionStr, setFunctionStr] = useState('');
    const [nameStr, setNameStr] = useState('');
    const [template, setTemplate] = useState('');
    const [productsList, setProductsList] = useState([]);
    
    const handleSubmit = (e) => {
        e.preventDefault();

        const { selectedData, loader, dispatch, user } = props;

        console.log("handleSubmit - product ->", product);

        let params = {};
        let product_id = product && product[0] && product[0].value ? product[0].value : product && product.value ? product.value : null;
        let tenant_id = selectedData && selectedData.tenant_id ? selectedData.tenant_id : user && user.tenant_id ? user.tenant_id : null;

        console.log("handleSubmit - tenant_id ->", tenant_id);
        console.log("handleSubmit - product_id ->", product_id);

        //Validations
        let notificationsToastArr = [];
        if (!product_id) {
            notificationsToastArr.push({
                title: "Product field is required!",
                icon: "warn",
            });
            //return Toast.fire({ title: 'Product field is required!', icon: 'warning' })
        } else {
            params.product_id = product_id;
        }
        if (!nameStr) {
            notificationsToastArr.push({
                title: "Name field is required!",
                icon: "warn",
            });
            //return Toast.fire({ title: 'Name field is required!', icon: 'warning' })
        } else {
            params.name = nameStr;
        }
        if (!functionStr) {
            notificationsToastArr.push({
                title: "Function field is required!",
                icon: "warn",
            });
            //return Toast.fire({ title: 'Function field is required!', icon: 'warning' })
        } else {
            params.function = functionStr;
        }
        if (!template) {
            notificationsToastArr.push({
                title: "Template field is required!",
                icon: "warn",
            });
            //return Toast.fire({ title: 'Template field is required!', icon: 'warning' })
        } else {
            params.template = template;
        }
        console.log("handleSubmit - params ->", params);

        if (!tenant_id) {
            notificationsToastArr.push({
                title: "Tenant ID is required!",
                icon: "warn",
            });
            //return Toast.fire({ title: 'Tenant field is required!', icon: 'warning' })
        } else {
            params.tenant_id = tenant_id;
        }
        
        if (notificationsToastArr.length > 0) {
            return ToastMulti(notificationsToastArr, "container-validation-id");
        }

        if (!loader) {
            dispatch(loaderToggle(true));
      
            if (selectedData && selectedData.id) {
              (async () => {
                const { ok, data } = await UpdateProvisioningTemplates(selectedData.id, params);
                handleOk(ok, data);
              })()
            } else {
              (async () => {
                const {ok, data} = await CreateProvisioningTemplates(params);
                handleOk(ok, data);
              })();
            }
        }
    };

    const handleOk = (ok, data) => {
        const { dispatch, update, hide } = props;
        dispatch(loaderToggle(false));

        if (ok) {
            update("prov_template_update");
            if (data && data.message) {
                console.log("handleOk - data ->", data);
                Toast.fire({ title: data.message, icon: 'success' })
            }
        }
        hide();
    }

    const handleInputChange = (e, name) => {
        console.log("ProvisioningTemplateForm - handleInputChange - e ->", e);
        console.log("ProvisioningTemplateForm - handleInputChange - name ->", name);

        if (name === "name") {
            setNameStr(e.target.value);
        }

        if (name === "function") {
            setFunctionStr(e.target.value);
        }
    };

    const handleSelectChange = (e, name) => {
        console.log("ProvisioningTemplateForm - handleSelectChange - e ->", e);
        console.log("ProvisioningTemplateForm - handleSelectChange - name ->", name);
        setProduct(e);
    };

    const handleTextareaChange = (e) => {
        console.log("ProvisioningTemplateForm - handleTextareaChange - e ->", e);
        setTemplate(e);
    };

    const getProductsList = () => {
        (async () => {
            let products = [];
            const { ok, data } = await GetProductsList()
            if (ok && data) {
                console.log('ProvisioningTemplateForm - GetProductsList - data ->', data);
                data.data.map(({description, id, vat_code}) => {
                    return products.push({
                        label: description,
                        value: id,
                        vat_code
                    })
                });
            }
            setProductsList(products);
        })();
    };

    useEffect(() => {
        getProductsList();

        if (selectedData) {
            console.log("ProvisioningTemplateForm - useEffect - selectedData ->", selectedData);
            // set selected product
            if (selectedData.product_id && selectedData.product_description) {
                let selected_product = [];
                selected_product.push({ label: selectedData.product_description, value: selectedData.product_id });
                setProduct(selected_product);
            }
            // set function
            if (selectedData.function) {
                setFunctionStr(selectedData.function);
            }
            // set name
            if (selectedData.name) {
                setNameStr(selectedData.name);
            }
            // set template
            if (selectedData.template) {
                //setTemplate(JSON.parse(selectedData.template));
                //setTemplate(JSON.stringify(selectedData.template));
                let parsedJsonTemplate = JSON.parse(selectedData.template);
                console.log("ProvisioningTemplateForm - useEffect - parsedJsonTemplate ->", parsedJsonTemplate);
                let strJsonTemplate = JSON.stringify(parsedJsonTemplate);
                console.log("ProvisioningTemplateForm - useEffect - strJsonTemplate ->", strJsonTemplate);
                
                let template = JSON.stringify(parsedJsonTemplate, undefined, 2);

                setTemplate(template);
            }
        }

      }, []);

    return (
        <Modal className="form-provisioning-template modal-xl" isOpen={show} toggle={hide} centered>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <ModalHeader>
                    <span>{formType} {formName}</span>
                </ModalHeader>
                <ModalBody key="0" className="mt-3 mb-3">
                    <Row className="pb-2">
                        <Col md={3}>
                            <label className="col-form-label">Name:</label>
                        </Col>
                        <Col md={9}>
                            <Input
                                name="name"
                                placeholder="Name"
                                value={nameStr ? nameStr : ''}
                                onChange={(e) => handleInputChange(e, "name")}
                            />
                        </Col>
                    </Row>
                    <Row className="pb-2">
                        <Col md={3}>
                            <label className="col-form-label">Product:</label>
                        </Col>
                        <Col md={9}>
                            <Select
                                id="product"
                                name="product"
                                options={productsList}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder="Product"
                                value={product}
                                onChange={(e) => handleSelectChange(e, 'product')}
                                maxMenuHeight={300}
                            />
                        </Col>
                    </Row>
                    <Row className="pb-2">
                        <Col md={3}>
                            <label className="col-form-label">Function:</label>
                        </Col>
                        <Col md={9}>
                            <Input
                                name="function"
                                placeholder="Function"
                                value={functionStr ? functionStr : ''}
                                onChange={(e) => handleInputChange(e, "function")}
                            />
                        </Col>
                    </Row>
                    <Row className="pb-2">
                        <Col md={3}>
                            <label className="col-form-label">Template:</label>
                        </Col>
                        <Col md={9}>
                            <div className="code-mirror-container">
                                <CodeMirror                    
                                    value={template}                    
                                    style={{ minHeight: "220px", border: "1px solid #ced4da", fontSize: "11px", resize: "vertical" }}
                                    height="240px"
                                    name="template"
                                    onChange={(e) => handleTextareaChange(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="justify-content-between mt-4">
                    <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button color="primary">
                        <FontAwesomeIcon icon={faSave} />
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = ({dispatch, loader, user, tenants}) => ({dispatch, loader, user, tenants});

export default withRouter(connect(mapStateToProps)(ProvisioningTemplateForm));
