import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import Select from "react-select";
import {
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { GetUser, UpdateUser } from "../../../controllers/users";
import { GetPersons, UpdatePerson } from "../../../controllers/persons";
import { updateUser as updateStateUser } from "../../../redux/actions/userActions";
import { loaderToggle } from "../../../redux/actions/loaderActions";
import ToastMulti from "../../../components/ToastMulti";
import Toast from "../../../components/Toast";

const genderOpts = [
      { value: "M", label: "Male" },
      { value: "F", label: "Female" },
      { value: "O", label: "Other" },
    ],
    titleOpts = [
      { value: "Ms", label: "Ms" },
      { value: "Mr", label: "Mr" },
      { value: "Mrs", label: "Mrs" },
      { value: "Dr", label: "Dr" },
      { value: "Atty", label: "Atty" },
      { value: "Engr", label: "Engr" },
      { value: "Dhr", label: "Dhr" },
      { value: "Mevr", label: "Mevr" },
    ];

const PublicInfo = ({ ...props }) => {
  const { dispatch, loader, user, person } = props;
  console.log("PublicInfo - props ->", props);

  const [userData, setUserData] = useState(user ? user : {});
  const [personData, setPersonData] = useState(person ? person : {});

  const handleInputChange = ({ target: { name, value } }) => {
    console.log("handleInputChange - name ->", name);
    console.log("handleInputChange - value ->", value);
    let person = Object.assign({}, personData);
    person[name] = value;
    setPersonData(person);
  };

  const handleSelectChange = (e, name) => {
    console.log("handleSelectChange - e - name ->", e, name);
    let person = Object.assign({}, personData);
    person[name] = e.value;
    setPersonData(person);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("handleSubmit - personData ->", personData);
    const { first_name, last_name, gender, title } = personData;

    let notificationsToastArr = [];
    if (!title) {
      notificationsToastArr.push({
        title: "Title field is required!",
        icon: "warn",
      });
    }
    if (!gender) {
      notificationsToastArr.push({
        title: "Gender field is required!",
        icon: "warn",
      });
    }
    /*if (!first_name) {
      notificationsToastArr.push({
        title: "First name field is required!",
        icon: "warn",
      });
    }
    if (!last_name) {
      notificationsToastArr.push({
        title: "Last name is required!",
        icon: "warn",
      });
    }*/
    if (notificationsToastArr.length > 0) {
      return ToastMulti(notificationsToastArr, "container-validation-id");
    }

    if (!loader) {
      dispatch(loaderToggle(true));

      // create array for showing multiple toast alerts
      let toastContainerPersonUpdate = [];
      // Update user data - email (username)
      (async () => {
        const { ok, data } = await UpdatePerson(personData.id, personData);
        console.log("UpdatePerson - ok - data ->", ok, data);

        if (ok) {
          toastContainerPersonUpdate.push({
            title: "Public info has been updated!",
            icon: "success",
          });
        } else {
          toastContainerPersonUpdate.push({
            title: "Public info not updated!",
            icon: "error",
          });
        }
        await handleOk(toastContainerPersonUpdate);
      })();

      // create array for showing multiple toast alerts
      let toastContainerUserUpdate = [];
      // Update user data - email (username)
      (async () => {
        const { ok, data } = await UpdateUser(user, user.id);
        console.log("UpdateUser - ok - data ->", ok, data);

        if (ok) {
          // dispatch(updateStateUser({ username: data.data.username }));
          toastContainerUserUpdate.push({
            title: "User data has been updated!",
            icon: "success",
          });
        } else {
          toastContainerUserUpdate.push({
            title: "User data not updated!",
            icon: "error",
          });
        }
        await handleOk(toastContainerUserUpdate);
      })();
    }
  };

  const handleOk = async (options) => {
    dispatch(loaderToggle(false));

    if (options && options.length > 0) {
      return ToastMulti(options, "container-validation-id");
    }
    return false;
  };

  useEffect(() => {
    if (user) {
      setUserData(user);

      let { email, id, person_id, tenant, username } = user;

      if (person_id) {
        (async () => {
          await Promise.all([GetPersons(person_id)]).then((r) => {
            const [data] = r;
            if (data && data.data && data.data.data) {
              const person = [];
              person.push(data.data.data);
              setPersonData(person[0]);
            }
          });
        })();
      }
    }
  }, []);

  const setTitleOpt = (title) => {
    console.log("setTitleOpt - title ->", title);
    if (title) {
      let selectedTitle = titleOpts.filter((i) => i.value === title);
      return selectedTitle;
    }
    return false;
  };

  const setGenderOpt = (gender) => {
    console.log("setGenderOpt - gender ->", gender);
    if (title) {
      let selectedGender = genderOpts.filter((i) => i.value === gender);
      return selectedGender;
    }
    return false;
  };

  const { title, gender, first_name, middle_name, last_name } = personData;
  console.log(
      "personData - details ->",
      title,
      gender,
      first_name,
      middle_name,
      last_name
  );

  return (
      <React.Fragment>
        <div className={`f2x-rel-mv-detail`}>
          <Form className="f2x-rel-det-form" onSubmit={(e) => handleSubmit(e)}>
            <Row className={`pt-0 mb-0`}>
              <Col>
                <div className="relation-details-card" style={{minWidth: "180px"}}>
                  <ListGroup className={`block-list list-group`} flush>
                    <h5 className="pb-0 list-group-sub-heading" style={{
                      paddingTop: "0",
                      lineHeight: "2.3",
                      fontWeight: "bolder",
                      margin: "0 2px 5px",
                      fontSize: "smaller",
                      textAlign: "left",
                      width: "auto",
                      display: "block",
                      opacity: "0.4"
                    }}
                    >
                      Public settings
                    </h5>
                    <ListGroupItem
                        className={`d-flex align-items-center px-0 p-0`}
                    >
                      <Col className="rel-dt-label col-md-4">Title</Col>
                      <Col className="col-md-8">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={titleOpts}
                            value={
                              personData && personData.title
                                  ? setTitleOpt(personData.title)
                                  : null
                            }
                            onChange={(e) => handleSelectChange(e, "title")}
                            maxMenuHeight={300}
                        />
                      </Col>
                    </ListGroupItem>
                    <ListGroupItem
                        className={`d-flex align-items-center px-0 p-0`}
                    >
                      <Col className="rel-dt-label col-md-4">Username</Col>
                      <Col className="col-md-8 d-flex align-items-center">
                        <Input
                            type="text"
                            id="username"
                            name="username"
                            value={
                              userData && userData.username ? userData.username : ""
                            }
                            onChange={(e) => handleInputChange(e)}
                        />
                      </Col>
                    </ListGroupItem>
                    <ListGroupItem
                        className={`d-flex align-items-center px-0 p-0`}
                    >
                      <Col className="rel-dt-label col-md-4">Gender</Col>
                      <Col className="col-md-8">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={genderOpts}
                            value={
                              personData && personData.gender
                                  ? setGenderOpt(personData.gender)
                                  : null
                            }
                            onChange={(e) => handleSelectChange(e, "gender")}
                            maxMenuHeight={300}
                        />
                      </Col>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end pt-0 pb-0 px-0 mt-4 save-cancel-buttons">
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </React.Fragment>
  );
};

const userSelector = createSelector(
(state) => state.user,
(user) => user
),
loaderSelector = createSelector(
    (state) => state.loader,
    (loader) => loader
),
mapStateToProps = createSelector(
    userSelector,
    loaderSelector,
    (user, loader) => ({ user, loader })
);

export default connect(mapStateToProps)(PublicInfo);
