// components/pdfviewer.js
import {
    faChevronLeft,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFViewer(props) {
    console.log("PDFViewer - props ->", props);

    let data = props.file;
    console.log("PDFViewer - data ->", data);

    const file = useMemo(() => ({ data }), [data]);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1); // start on first page
    const [loading, setLoading] = useState(true);
    const [pageWidth, setPageWidth] = useState(0);

    function onDocumentLoadSuccess({nextNumPages}) {
        setNumPages(nextNumPages);
    }

    function onPageLoadSuccess() {
        setPageWidth(window.innerWidth);
        setLoading(false);
    }

    const options = {
        cMapUrl: "cmaps/",
        cMapPacked: true,
        standardFontDataUrl: "standard_fonts/",
    };

    // Go to next page
    function goToNextPage() {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }

    function goToPreviousPage() {
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }

    return (
        <>
        <Nav pageNumber={pageNumber} numPages={numPages} />
        <div
            hidden={loading}
            style={{ height: "calc(100vh - 64px)" }}
            className="flex items-center"
        >
            <div
            className={`flex items-center justify-between w-full absolute z-10 px-2`}
            >
            <button
                onClick={goToPreviousPage}
                disabled={pageNumber <= 1}
                className="relative h-[calc(100vh - 64px)] px-2 py-24 text-gray-400 hover:text-gray-50 focus:z-20"
            >
                <span className="sr-only">Previous</span>
                <FontAwesomeIcon color="gray" icon={faChevronLeft} className="h-10 w-10" aria-hidden="true" />
            </button>
            <button
                onClick={goToNextPage}
                disabled={pageNumber >= numPages}
                className="relative h-[calc(100vh - 64px)] px-2 py-24 text-gray-400 hover:text-gray-50 focus:z-20"
            >
                <span className="sr-only">Next</span>
                <FontAwesomeIcon color="gray" icon={faChevronRight} className="h-10 w-10" aria-hidden="true" />
            </button>
            </div>

            <div className="h-full flex justify-center mx-auto">
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                renderMode="canvas"
                className=""
            >
                <Page
                className=""
                key={pageNumber}
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onLoadSuccess={onPageLoadSuccess}
                onRenderError={() => setLoading(false)}
                width={Math.max(pageWidth * 0.8, 390)}
                />
            </Document>
            </div>
        </div>
        </>
    );
}


function Nav({pageNumber, numPages}) {
  return (
    <nav className="bg-black">
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <p className="text-2xl font-bold tracking-tighter text-white">
                Papermark
              </p>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium">
              <span>{pageNumber}</span>
              <span className="text-gray-400"> / {numPages}</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
