import React from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import Select from "react-select";
import DatePicker, {registerLocale} from 'react-datepicker';
import {format as formatDate, isDate} from "date-fns";
import NumberFormat from 'react-number-format';
import {FormGroup, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button, Input, Table, Form, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {MoreHorizontal} from "react-feather";
import {GetPlanSubscriptionLineTypes} from "../../../../controllers/subscriptions";
import {AddInvoiceLine, UpdateInvoiceLine, RemoveInvoiceLine, GetInvoiceLineTypes} from "../../../../controllers/invoices";
import {GetProducts, GetProductsList} from '../../../../controllers/tenants';
import {GetProductWithPrice} from '../../../../controllers/products';
import {GetSingleVatCode} from '../../../../controllers/vat_codes';
import {loaderToggle} from "../../../../redux/actions/loaderActions";
import {validateDate} from "../../../../redux/actions/validateActions";
import {formatNumber, parseDateToSaveFormat} from '../../../../redux/actions/formatActions';
import {invoice} from '../../../../redux/actions/invoiceActions';
import Toast from "../../../../components/Toast";
import {nl, enGB, enUS} from "date-fns/locale";
import {withTranslation} from 'react-i18next';
import Swal from "sweetalert2"; // import all locales we need
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
registerLocale('en', enGB);
registerLocale('nl', nl);
registerLocale('us', enUS);

class InvoiceLineForm extends React.Component {
    constructor(props) {
        super(props);

        const { selectedData, t } = this.props;

        this.state = {
            formType: selectedData ? 'Edit' : 'Add',
            formName: 'sales invoice line',
            disableLineType: false,
            onEdit: !selectedData,
            mb: selectedData ? 'mb-0' : 'mb-5px',
            product: null, // was {}
            sales_invoice_line: selectedData,
            vat_code_description: null,
            pricesInputDisabled: false
        }
    }

    extendSelectedProduct = (id, select_data, name) => {
        const {user} = this.props;

        let tenant_id = user.tenant_id ? user.tenant_id : null;
        console.log('extendSelectedProduct - tenant_id ->', tenant_id);
        const tenant = sessionStorage.getItem('tenant');
        if (tenant && !tenant_id) {
            const tenantData = JSON.parse(tenant);
            tenant_id = tenantData.id;
        }

        (async () => {
            const { ok, data } = await GetProductWithPrice(id, tenant_id, null); // product_id, tenant_id, params
            if (ok) {
                console.log('handleSelectChange - GetProductWithPrice - data ->', data);
                console.log('handleSelectChange - GetProductWithPrice - data.data ->', data.data);
                if (data && data.data && data.data[0]) {
                    let details_product_id = data.data[0].id ? data.data[0].id : null;
                    let details_product_type_id = data.data[0].product_type_id ? data.data[0].product_type_id : null;
                    let details_product_price = data.data[0].price ? data.data[0].price : null;
                    let details_product_vat_percentage = data.data[0].vat_code && data.data[0].vat_code.vat_percentage ? data.data[0].vat_code.vat_percentage : 0;
                    let details_price_vat = details_product_price ? parseFloat(details_product_price * details_product_vat_percentage) : 0;

                    let details_formatted_incl_price_per_piece = details_product_price ? parseFloat(details_product_price + details_price_vat) : 0;
                    let details_formatted_incl_price = 0;

                    console.log('handleSelectChange - details_product_type_id ->', details_product_type_id);
                    console.log('handleSelectChange - details_product_price ->', details_product_price);
                    console.log('handleSelectChange - details_price_vat ->', details_price_vat);
                    console.log('handleSelectChange - details_formatted_incl_price_per_piece ->', details_formatted_incl_price_per_piece);
                    console.log('handleSelectChange - details_product_vat_percentage ->', details_product_vat_percentage);

                    let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);

                    let current_quantity = sales_invoice_line.quantity ? sales_invoice_line.quantity : 1;
                    console.log('handleSelectChange - current_quantity ->', current_quantity);
                    if (current_quantity && typeof current_quantity === 'string' && current_quantity.includes(',')) {
                        current_quantity = current_quantity.replace(/\./g, "").replace(",", ".");
                    }
                    console.log('handleSelectChange - current_quantity ->', current_quantity);

                    sales_invoice_line.sales_invoice_line_type = details_product_type_id ? this.populateLineType(details_product_type_id) : null;
                    sales_invoice_line.price = details_product_price ? details_product_price * current_quantity : 0;
                    sales_invoice_line.price_per_piece = details_product_price ? details_product_price : 0;
                    sales_invoice_line.price_total = details_formatted_incl_price_per_piece * current_quantity;
                    sales_invoice_line.price_vat = details_formatted_incl_price_per_piece && details_product_price ? (details_formatted_incl_price_per_piece * current_quantity) - (details_product_price * current_quantity) : null;
                    sales_invoice_line.product_id = details_product_id ? details_product_id : null;
                    sales_invoice_line.quantity = current_quantity;
                    sales_invoice_line.description = data.data[0].description ? data.data[0].description : "";
                    sales_invoice_line.excl_price_per_piece = 0;
                    sales_invoice_line.incl_price_per_piece = 0;


                    await this.setState({
                        sales_invoice_line,
                        price_vat: details_formatted_incl_price_per_piece && details_product_price ? (details_formatted_incl_price_per_piece * current_quantity) - (details_product_price * current_quantity) : null,
                        formatted_quantity: current_quantity,
                        formatted_excl_price_per_piece: details_product_price ? details_product_price : 0,
                        formatted_excl_price: details_product_price ? details_product_price : 0,
                        formatted_incl_price_per_piece: details_formatted_incl_price_per_piece,
                        formatted_incl_price: details_formatted_incl_price,
                        incl_price: 0,
                        excl_price: 0,
                        incl_price_per_piece: 0,
                        excl_price_per_piece: 0,
                        [name]: select_data
                        //product: data.value
                        //price: 0,
                        //price_per_piece: 0,
                        //price_total: 0,
                        //price_vat: 0,
                        //product_id: 1,
                        //quantity: 1
                    });

                    //let prices_data = {};
                    //prices_data.value = details_product_price ? details_product_price : 0;
                    //prices_data.floatValue = details_product_price ? details_product_price : 0;

                    //let quantity_data = {};
                    //quantity_data.value = 0;
                    //quantity_data.floatValue = 0;
                    //formatted_excl_price_per_piece
                    //excl_price

                    //this.priceFormatter('excl_price_per_piece', prices_data); // formatted_excl_price
                    //this.priceFormatter('quantity', quantity_data);
                    //this.priceFormatter('excl_price', prices_data);
                }
            }
        })();
    };

    handleSelectChange = (name, data) => {
        let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);

        console.log('handleSelectChange - sales_invoice_line ->', sales_invoice_line);
        console.log('handleSelectChange - data ->', data);

        sales_invoice_line[name] = data.value;

        if (name === 'sales_invoice_line_type') {
            // disable - product - prices if value === 99 -
            if (data.value === 99) {
                this.setState({
                    pricesInputDisabled: true,
                });
            } else {
                this.setState({
                    pricesInputDisabled: false,
                });
            }

        } else if (name === 'product') {
            const { label, value, vat_code, product_type_id, price } = data;

            console.log('handleSelectChange - sales_invoice_line ->', sales_invoice_line);
            console.log('handleSelectChange - vat_code ->', vat_code);
            console.log('handleSelectChange - data ->', data);

            if (value && !product_type_id && !price) {
                //this.setState({
                //    [name]: data
                //});
                sales_invoice_line["price_vat"] = 0;

                this.setState({
                    price_vat: 0,
                    sales_invoice_line
                });

                return this.extendSelectedProduct(value, data, name);

            } else {
                sales_invoice_line.description = label;
                sales_invoice_line.product_id = value;
                sales_invoice_line.vat_code = vat_code;
                sales_invoice_line.vat_percentage = vat_code && vat_code.vat_percentage ? vat_code.vat_percentage : null;

                //this.setState({ sales_invoice_line });

                if (vat_code) {
                    (async () => {
                        await (() => {
                            this.setState({ vat_code });

                            //if (vat_code.vat_percentage) {
                            //    this.reCalculatePrices(parseFloat(vat_code.vat_percentage), price);
                            //}

                            if (product_type_id) {
                                this.populateLineType(parseInt(product_type_id))
                            } else {
                                this.setState({ disableLineType: false })
                            }
                        })();

                        if (vat_code.vat_percentage) {
                            this.reCalculatePrices(parseFloat(vat_code.vat_percentage))
                        }
                    })();
                }
            }
        }

        this.setState({
            sales_invoice_line,
            [name]: data
        });
    };

    handleInput({ target: { name, value } }) {
        let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);

        sales_invoice_line[name] = value;

        this.setState({ sales_invoice_line })
    }

    handleDatePickerChange(name, date) {
        let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);
        const { newDate } = this.props.dispatch(validateDate(date));

        sales_invoice_line[name] = newDate;

        this.setState({ sales_invoice_line })
    }

    priceFormatter = (name, data) => {
        const { dispatch } = this.props;
        const { product } = this.state;

        console.log("priceFormatter - name ->", name);
        console.log("priceFormatter - data ->", data);
        console.log("priceFormatter - this.state.sales_invoice_line ->", this.state.sales_invoice_line);

        (async () => {
            const { newVal, value } = dispatch(formatNumber(name, data.floatValue, data.value)),
                pre = name.split('_')[0],
                revPre = pre !== 'quantity' ? pre === 'excl' ? 'incl' : 'excl' : null,
                rawName = name.replace(`${pre}_`, ''),
                formattedName = `formatted_${name}`,
                formattedRevName = `formatted_${revPre}_${rawName}`,
                revName = `${revPre}_${rawName}`;

            let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line),
                getVat = sales_invoice_line.vat_percentage ? parseFloat(sales_invoice_line.vat_percentage) : 0;

            console.log("priceFormatter - sales_invoice_line ->", sales_invoice_line);
            console.log("priceFormatter - getVat ->", getVat);
            console.log("priceFormatter - pre ->", pre);
            console.log("priceFormatter - revPre ->", revPre);
            console.log("priceFormatter - rawName ->", rawName);
            console.log("priceFormatter - formattedName ->", formattedName);
            console.log("priceFormatter - formattedRevName ->", formattedRevName);
            console.log("priceFormatter - newVal ->", newVal);

            await (() => {
                if (revPre) {
                    if (newVal !== null || newVal === 0 || newVal < 0) {
                        const calculated = pre === 'excl' ? parseFloat(newVal) + (parseFloat(newVal) * getVat) : parseFloat(newVal) / (1 + getVat),
                            checkCalculated = this.hasValue(calculated, "calculated") ? calculated : '0',
                            getValue = pre === 'excl' ? value : checkCalculated;

                        console.log("priceFormatter - calculated ->", calculated);
                        console.log("priceFormatter - checkCalculated ->", checkCalculated);
                        console.log("priceFormatter - getValue ->", getValue);

                        this.setState({
                            [formattedRevName]: checkCalculated,
                            [revName]: checkCalculated
                        });

                        sales_invoice_line[rawName] = getValue;

                    } else {
                        this.setState({
                            [formattedRevName]: null,
                            [revName]: null
                        });

                        sales_invoice_line[rawName] = null;

                    }
                } else {
                    sales_invoice_line[rawName] = value;


                }

                console.log("priceFormatter - newVal ->", newVal);
                console.log("priceFormatter - name ->", name);
                console.log("priceFormatter - formattedName ->", formattedName);

                this.setState({
                    [formattedName]: newVal !== 0 || newVal !== null || true ? newVal : '0',
                    [name]: value,
                    sales_invoice_line
                });
            })();

            await (() => {
                console.log("priceFormatter - rawName ->", rawName);
                console.log("priceFormatter - this.state ->", this.state);
                console.log("priceFormatter - rawName (before -> calculatePrice) ->", rawName);

                if (rawName !== 'price') {
                    this.calculatePrice(sales_invoice_line);
                } else {
                    this.calculateUnitPrice(sales_invoice_line);
                }

                //this.calculateTotalVAT(sales_invoice_line);
            })();

            this.calculateTotalVAT(sales_invoice_line);

        })()
    };

    populatePrices = () => {
        let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);

        const { price_per_piece, quantity, price } = sales_invoice_line;

        this.priceFormatter(
            'excl_price_per_piece', {
                value: price_per_piece && this.hasValue(price_per_piece) ? price_per_piece : null,
                floatValue: price_per_piece && this.hasValue(price_per_piece) ? parseFloat(price_per_piece) : null
            });

        this.priceFormatter(
            'quantity', {
                value: quantity && this.hasValue(quantity) ? quantity : null,
                floatValue: quantity && this.hasValue(quantity) ? parseFloat(quantity) : null
            });

        this.priceFormatter(
            'excl_price', {
                value: price && this.hasValue(price) ? price : null,
                floatValue: price && this.hasValue(price) ? parseFloat(price) : null
            })
    };

    reCalculatePrices = (vat_percentage, ) => {
        console.log("reCalculatePrices (calculatePrice) - vat_percentage ->", vat_percentage);
        console.log("reCalculatePrices (calculatePrice) - this.state ->", this.state);

        (async () => {
            await (() => {
                let { incl_price_per_piece, incl_price } = this.state;
                if (incl_price_per_piece || incl_price_per_piece === 0) {
                    this.setState({
                        formatted_excl_price_per_piece: parseFloat(incl_price_per_piece) / (1 + vat_percentage),
                        excl_price_per_piece: parseFloat(incl_price_per_piece) / (1 + vat_percentage)
                    })
                }

                if (incl_price || incl_price === 0) {
                    this.setState({
                        formatted_excl_price: parseFloat(incl_price) / (1 + vat_percentage),
                        excl_price: parseFloat(incl_price) / (1 + vat_percentage)
                    })
                }
            })();

            this.calculateTotalVAT();

        })();
    };

    calculatePrice = (data) => {
        console.log("InvoiceLines form - calculatePrice - this.state ->", this.state);
        console.log("InvoiceLines form - calculatePrice - data ->", data);

        let { excl_price_per_piece, incl_price_per_piece, quantity } = this.state;
        console.log("InvoiceLines form - calculatePrice - excl_price_per_piece ->", excl_price_per_piece);
        console.log("InvoiceLines form - calculatePrice - incl_price_per_piece ->", incl_price_per_piece);
        console.log("InvoiceLines form - calculatePrice - quantity ->", quantity);

        if (!excl_price_per_piece) {
            //excl_price_per_piece = data && data.price ? data.price : 0;
            excl_price_per_piece = data && data.price_per_piece ? data.price_per_piece : 0; //data && data.price ? data.price : 0;
        }
        if (!incl_price_per_piece) {
            //incl_price_per_piece = data && data.price_total ? data.price_total : 0;

            let total_inc_single = data && data.price_per_piece && data.price_vat ? parseFloat(data.price_per_piece + data.price_vat) : 0;

            incl_price_per_piece = total_inc_single; //data && data.price_total ? data.price_total : 0;
        }
        if (!quantity) {
            quantity = data && data.quantity ? data.quantity : 0;
        }

        console.log("InvoiceLines form - calculatePrice - excl_price_per_piece ->", excl_price_per_piece);
        console.log("InvoiceLines form - calculatePrice - quantity ->", quantity);

        const excl_price = this.hasValue(excl_price_per_piece) && this.hasValue(quantity) ? parseFloat(excl_price_per_piece) * parseFloat(quantity) : null,
            incl_price = this.hasValue(incl_price_per_piece) && this.hasValue(quantity) ? parseFloat(incl_price_per_piece) * parseFloat(quantity) : null,
            formatted_excl_price = excl_price,
            formatted_incl_price = incl_price;

        console.log("InvoiceLines form - calculatePrice - excl_price ->", excl_price);

        let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);
        sales_invoice_line.price = excl_price;
        sales_invoice_line.price_total = incl_price;

        this.setState({
            excl_price,
            incl_price,
            formatted_excl_price,
            formatted_incl_price,
            sales_invoice_line
        });
    };

    calculateUnitPrice = (data) => {
        //let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);
        console.log("calculateUnitPrice - data ->", data);
        console.log("calculateUnitPrice - this.state ->", this.state);

        let { excl_price, incl_price, quantity } = this.state;
        console.log("calculateUnitPrice - excl_price ->", excl_price);
        console.log("calculateUnitPrice - incl_price ->", incl_price);
        console.log("calculateUnitPrice - quantity ->", quantity);

        if (!excl_price) {
            excl_price = data && data.price ? data.price : 0;
        }
        if (!incl_price) {
            incl_price = data && data.price_total ? data.price_total : 0;
        }
        if (!quantity) {
            quantity = data && data.quantity ? data.quantity : 0;
        }

        const excl_price_per_piece = this.hasValue(excl_price) && this.hasValue(quantity) ? parseFloat(excl_price) === 0 && parseFloat(quantity) === 0 ? 0 : parseFloat(excl_price) / parseFloat(quantity) : null;
        //const excl_price_per_piece = parseFloat(excl_price) / parseFloat(quantity);
        const incl_price_per_piece = this.hasValue(incl_price) && this.hasValue(quantity) ? parseFloat(incl_price) === 0 && parseFloat(quantity) === 0 ? 0 : parseFloat(incl_price) / parseFloat(quantity) : null;
        //const incl_price_per_piece = parseFloat(incl_price) / parseFloat(quantity);
        const formatted_excl_price_per_piece = excl_price_per_piece;
        const formatted_incl_price_per_piece = incl_price_per_piece;

        let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);
        sales_invoice_line.price_per_piece = parseFloat(excl_price_per_piece);
        sales_invoice_line.price_total = incl_price;
        sales_invoice_line.excl_price_per_piece = parseFloat(excl_price_per_piece);
        sales_invoice_line.incl_price_per_piece = parseFloat(incl_price_per_piece);

        this.setState({
            excl_price_per_piece,
            incl_price_per_piece,
            formatted_excl_price_per_piece,
            formatted_incl_price_per_piece,
            sales_invoice_line
        });
    };

    calculatePriceVat = (price_vat) => {
        console.log('sales_invoice_line - calculatePriceVat - price_vat ->', price_vat);

        const string = '^qStr';
        const regexp = new RegExp(string);

        let strToFloatPriceVat;
        if (price_vat && typeof price_vat === 'string' && price_vat.includes(',')) {
            strToFloatPriceVat = price_vat ? parseFloat(
                price_vat.replace(/\./g, "").replace(",", ".")) : 0;
        } else {
            strToFloatPriceVat = price_vat ? price_vat : 0;
        }

        console.log("sales_invoice_line - Converted value = " +
            strToFloatPriceVat + " Type of " + strToFloatPriceVat + " = "
            +typeof strToFloatPriceVat);

        const {t} = this.props;

        return strToFloatPriceVat ? t("currency_format", { number: Number(parseFloat(strToFloatPriceVat)) }) : String.fromCharCode(8212);
    };

    calculateTotalVAT = (data) => {
        console.log("calculateTotalVAT - data ->", data);
        console.log("calculateTotalVAT - this.state ->", this.state);

        let { incl_price, excl_price, formatted_quantity, formatted_excl_price_per_piece, formatted_incl_price_per_piece } = this.state;

        console.log("calculateTotalVAT - formatted_quantity ->", formatted_quantity);
        console.log("calculateTotalVAT - incl_price ->", incl_price);
        console.log("calculateTotalVAT - excl_price ->", excl_price);

        if (formatted_quantity === null) {
            this.setState({
                price_vat: 0,
            });

        } else if (formatted_quantity === 0) {
            this.setState({
                price_vat: parseFloat(formatted_incl_price_per_piece) - parseFloat(formatted_excl_price_per_piece),
            });

        } else {

            if (!excl_price) {
                excl_price = data && data.price ? data.price : 0;
            }
            if (!incl_price) {
                incl_price = data && data.price_total ? data.price_total : 0;
            }

            console.log("calculateTotalVAT - excl_price ->", excl_price);
            console.log("calculateTotalVAT - incl_price ->", incl_price);

            const inclPrice = this.hasValue(incl_price) || incl_price === 0 ? parseFloat(incl_price) : null;
            const exclPrice = this.hasValue(excl_price) || excl_price === 0 ? parseFloat(excl_price) : null;

            console.log("calculateTotalVAT - inclPrice ->", inclPrice);
            console.log("calculateTotalVAT - exclPrice ->", exclPrice);

            const difference = inclPrice && exclPrice ? (inclPrice - exclPrice) : null;
            const formattedDifference = this.hasValue(difference) ? parseFloat(difference).toFixed(5).replace('.', ',') : null;

            let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);
            sales_invoice_line.price_vat = difference;

            this.setState({
                price_vat: formattedDifference,
                //sales_invoice_line
            });

        }
    };

    eventFocus({ target: { name } }) {
        this.toggleOnFocus(`${name}_onFocus`, true)
    }

    eventBlur({ target: { name } }) {
        this.toggleOnFocus(`${name}_onFocus`, false)
    }

    toggleOnFocus(name, bool) {
        this.setState({ [name]: bool })
    }

    populateLineType = (id) => {
        console.log("InvoiceLine form - populateLineType - this.state.line_types ->", this.state.line_types);
        const getLineType = this.state.line_types.find(line => parseInt(line.value) === id);
        if (getLineType) {
            if (getLineType === 99) {
                this.setState({
                    pricesInputDisabled: true,
                });
            } else {
                this.setState({
                    pricesInputDisabled: false,
                });
            }

            this.setState({
                sales_invoice_line_type: getLineType,
                disableLineType: true
            })
        }
    };

    handleKeyDown = ({ which }, name) => {
        if (which === 9) {
            this.ref[name].setOpen(false)
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        console.log("InvoiceLines form - handleSubmit - this.state ->", this.state);

        const { selectedData, id, dispatch, loader, setData } = this.props ? this.props : {},
            sales_invoice_line = Object.assign({}, this.state.sales_invoice_line),
            { product_id, product, sales_invoice_line_type } = this.state ? this.state : {},
            { description } = sales_invoice_line ? sales_invoice_line : {};
        let  { invoice_start, price_per_piece, quantity, price } = sales_invoice_line ? sales_invoice_line : {};

        const line_type_validation = sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99 ? true : false;

        //Validations
        if (!line_type_validation && !product) {
            return Toast.fire({ title: 'Product field is required!', icon: 'warning' })
        }

        if (!description) {
            return Toast.fire({ title: 'Description field is required!', icon: 'warning' })
        }

        if (!sales_invoice_line_type) {
            return Toast.fire({ title: 'Line type field is required!', icon: 'warning' })
        }

        if (!invoice_start) {
            return Toast.fire({ title: 'Period start field is required!', icon: 'warning' })
        }

        if (!line_type_validation && !this.hasValue(price_per_piece)) {
            return Toast.fire({ title: 'Unit price field is required!', icon: 'warning' })
        }

        if (!line_type_validation && !this.hasValue(quantity)) {
            return Toast.fire({ title: 'Quantity field is required!', icon: 'warning' })
        }

        if (!line_type_validation && !this.hasValue(price)) {
            return Toast.fire({ title: 'Price field is required!', icon: 'warning' })
        }

        if (!loader) {
            dispatch(loaderToggle(true));

            let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);

            // set dates in sales_invoice_line to save format
            if (sales_invoice_line && sales_invoice_line.invoice_start) {
                sales_invoice_line.invoice_start = parseDateToSaveFormat(sales_invoice_line.invoice_start);
            }
            // set invoice_stop to save format
            if (sales_invoice_line && sales_invoice_line.invoice_stop) {
                sales_invoice_line.invoice_stop = parseDateToSaveFormat(sales_invoice_line.invoice_stop);
            }

            if (sales_invoice_line && sales_invoice_line.price_per_piece && false) {
                sales_invoice_line.price_per_piece = 0;
                //sales_invoice_line.price_per_unit = 0;
            }
            if (sales_invoice_line && sales_invoice_line.price_per_piece) {
                sales_invoice_line.price_per_piece = parseFloat(sales_invoice_line.price_per_piece);
                //sales_invoice_line.price_per_unit = parseFloat(sales_invoice_line.price_per_piece);
            }

            if (sales_invoice_line && sales_invoice_line.price && false) {
                sales_invoice_line.price = 0;
            }

            sales_invoice_line.sales_invoice_line_type = sales_invoice_line_type.value;

            console.log("InvoiceLines form - handleSubmit - Add - Update - sales_invoice_line ->", sales_invoice_line);

            if (selectedData) {
                (async () => {
                    const { ok, data } = await UpdateInvoiceLine(selectedData.id, sales_invoice_line);
                    this.handleOk(ok, data && data.data ? data.data : null);

                })()
            } else {
                sales_invoice_line.sales_invoice_id = id;

                (async () => {
                    const { ok, data } = await AddInvoiceLine(sales_invoice_line);
                    this.handleOk(ok, data && data.data ? data.data : null);

                })();
            }
        }
    };

    handleOk = (ok, data) => {
        const { hide, update, dispatch } = this.props;
        const { formType } = this.state;

        console.log("InvoiceLines form - (processUpdateApi) handleOk - data (returned) ->", data);
        console.log("InvoiceLines form - (processUpdateApi) handleOk - this.props (returned) ->", this.props);
        console.log("InvoiceLines form - (processUpdateApi) handleOk - this.state (returned) ->", this.state);

        dispatch(loaderToggle(false));

        if (ok) {
            //dispatch(invoice(data));
            //dispatch({ type: 'INVOICE', payload: data && data.sales_invoice ? data.sales_invoice : data });

            hide();
            //update("update");
            //update();

            if (formType === "Add") {
                //dispatch({ type: 'INVOICE', payload: data && data.sales_invoice ? data.sales_invoice : data });

                update('inv_line_update', data && data.sales_invoice ? data.sales_invoice : data, "add"); //handling data reloading
            } else {

                //dispatch({ type: 'INVOICE', payload: data && data.sales_invoice ? data.sales_invoice : data });

                update('inv_line_update', data && data.sales_invoice ? data.sales_invoice : data, "update"); //handling data reloading
            }
        }
    };

    hasValue = (value, name = false) => {
        console.log("invoicesLines - hasValue - name ->", name);
        console.log("invoicesLines - hasValue - value ->", value);

        return value !== null && value !== undefined && value !== '';
        /*if (Math.sign(value) || Math.sign(value) === 0 || Math.sign(value) < 0) {
            return true;
        } else {
            return false;
        }*/
    };

    stopBubble(e) {
        e.stopPropagation()
    }

    toggleEdit(e) {
        e.stopPropagation();

        this.setState({ onEdit: !this.state.onEdit })
    }

    setIcon = (fa_icon, custom_title) => {
        console.log("setIcon - fa_icon ->", fa_icon);
        console.log("setIcon - custom_title ->", custom_title);

        let html_icon = icon(fa_icon, {title: custom_title ? custom_title : ''}).html;
        console.log("setIcon - html_icon ->", html_icon);

       return html_icon;
    };

    deleteInvoiceLine(e) {
        e.stopPropagation();

        /*const confirmation = window.confirm('Are you sure you want to delete this sales invoice line?'), // to do - change this to swal message
            { dispatch, loader, hide, update, selectedData } = this.props ? this.props : null;

        if (!loader && confirmation) {
            dispatch(loaderToggle(true));

            (async () => {
                const { ok } = await RemoveInvoiceLine(selectedData.id);

                dispatch(loaderToggle(false));

                if (ok) {
                    hide();
                    update('update');
                }
            })();
        }*/

        const { dispatch, loader, hide, update, selectedData } = this.props ? this.props : null;

        Swal.fire({
            title: 'Please confirm!',
            text: 'Are you sure you want to delete this invoice line?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: "Cancel", //this.setIcon(faTimes, "No cancel action"), 
            confirmButtonText: "Confirm", //this.setIcon(faSave, "Yes confirm action"), 
            reverseButtons: true
        }).then(res => {
            if (res.value) {
                if (!loader) {
                    dispatch(loaderToggle(true));

                    (async () => {
                        const { ok, data } = await RemoveInvoiceLine(selectedData.id);
                        console.log("processUpdateApi - ListDetails (action === 'update') - RemoveInvoiceLine - ok ->", ok);
                        console.log("processUpdateApi - ListDetails (action === 'update') - RemoveInvoiceLine - data ->", data);
                        console.log("processUpdateApi - ListDetails (action === 'update') - RemoveInvoiceLine - selectedData ->", selectedData);

                        if (ok) {
                            dispatch(loaderToggle(false));

                            /*let table = document.querySelector('table');
                            console.log('invoiceLines remove form - table', table);
                            console.log('invoiceLines remove form - selectedDataRow', this.props.selectedDataRow);
                            if (table && this.props.selectedDataRow) {
                                table.deleteRow(this.props.selectedDataRow);
                            }*/

                            //update();
                            update('inv_line_update', selectedData, 'delete'); //handling data reloading
                            hide();

                            await Toast.fire({
                                title: 'Invoice line deleted successfully',
                                icon: 'success'
                            });

                        } else {
                            update();
                            hide();

                            await Toast.fire({
                                title: 'Deleted status is not available',
                                icon: 'warning'
                            });
                        }

                        dispatch(loaderToggle(false));

                    })();
                }
            } else {
                hide();
            }
        });
    }

    isObject(objValue) {
        return objValue && typeof objValue === 'object' && objValue.constructor === Object;
    }

    componentDidMount() {
        const { selectedData, dispatch } = this.props,
            sales_invoice_line = selectedData ? selectedData : null;

        let salesInvoiceLineObj = Object.assign({}, sales_invoice_line);

        dispatch(loaderToggle(true));

        (async () => {
            const [all_products, line_type, vat_code] = await Promise.all([
                GetProductsList(),
                GetInvoiceLineTypes(),
                sales_invoice_line && sales_invoice_line.vat_code_id ? GetSingleVatCode(sales_invoice_line.vat_code_id) : null
            ]);

            console.log("sales_invoice_line - componentDidMount - vat_code ->", vat_code);

            let products = [];
            let line_types = [];
            let selected_vat_code_obj;

            await (() => {
                if (vat_code && vat_code.ok) {
                    selected_vat_code_obj = vat_code.data.data;

                    this.setState({ vat_code_description: selected_vat_code_obj.description });
                }

                if (all_products && all_products.ok) {
                    const data = all_products.data.data;
                    data && data.map(({ description, id, vat_code, product_type_id, price }) => {
                        return products.push({
                            label: description,
                            value: id,
                            vat_code,
                            product_type_id,
                            price: price
                        })
                    });

                    this.setState({ products })
                }

                if (line_type && line_type.ok) {
                    const data = line_type.data.data;
                    data && data.map(({ line_type, id }) => {
                        return line_types.push({
                            label: line_type,
                            value: id
                        })
                    });

                    this.setState({ line_types })
                }

                this.setState({
                    sales_invoice_line: salesInvoiceLineObj
                });

                //console.log("sales_invoice_line - componentDidMount - selectedData ->", selectedData);
                console.log("sales_invoice_line - componentDidMount - salesInvoiceLineObj ->", salesInvoiceLineObj);
                console.log("sales_invoice_line - componentDidMount - this.state.sales_invoice_line ->", this.state.sales_invoice_line);

                if (selectedData) {
                    let product =  products && products.length > 0 ? products.find(item => parseInt(item.value) === parseInt(salesInvoiceLineObj.product_id)) : null;
                    console.log("sales_invoice_line - componentDidMount - product ->", product);
                    if (this.isObject(product)) {
                        console.log("sales_invoice_line - componentDidMount - product isObject ->", "YES");
                        if (Object.keys(product).length === 0) {
                            console.log("sales_invoice_line - componentDidMount - product isObject is empty ->", "YES");
                            product = null;
                        }
                    }

                    salesInvoiceLineObj.product = product ? product : null;
                    //salesInvoiceLineObj.vat_code_description = selected_vat_code_obj && selected_vat_code_obj.description ? selected_vat_code_obj.description : null;

                    let sales_invoice_line_type = line_types && line_types.length > 0 ? line_types.find(item => parseInt(item.value) === parseInt(salesInvoiceLineObj.sales_invoice_line_type)) : null;
                    if (product && product.product_type_id) {
                        sales_invoice_line_type = line_types && line_types.length > 0 ? line_types.find(item => parseInt(item.value) === parseInt(product.product_type_id)) : null;
                    }

                    console.log("sales_invoice_line - componentDidMount - sales_invoice_line_type ->", sales_invoice_line_type);
                    console.log("sales_invoice_line - componentDidMount - salesInvoiceLineObj ->", salesInvoiceLineObj);
                    console.log("sales_invoice_line - componentDidMount - product ->", product);

                    this.setState({
                        sales_invoice_line: salesInvoiceLineObj,
                        product_id: salesInvoiceLineObj.product_id,
                        product: product ? product : null,
                        vat_code: product && product.vat_code ? product.vat_code : {},
                        sales_invoice_line_type: sales_invoice_line_type ? sales_invoice_line_type : {},
                        disableLineType: !!(product && product.product_type_id && sales_invoice_line_type),
                    });

                    this.populatePrices();

                } else {
                    let sales_invoice_line = Object.assign({}, this.state.sales_invoice_line);
                    sales_invoice_line.invoice_start = new Date();

                    this.setState({ sales_invoice_line });
                }
            })();

            this.setState({ hasLoaded: true });

        })();

        dispatch(loaderToggle(false));

    }

    getLocale = (locale) => {
        const dateLocalesPath = {
            'nl': 'nl',
            'en': 'en-GB',
            'us': 'en-US'
        };

        require(`date-fns/locale/${dateLocalesPath[this.props.i18n.language]}/index.js`);
    };

    render() {
        console.log("sales_invoice_line - render - state ->", this.state);

        const {
            formType,
            formName,
            products,
            line_types,
            quantity,
            price_per_piece,
            price_vat,
            /*sales_invoice_line_type,
            vat_code,
            formatted_excl_price_per_piece,
            excl_price_per_piece_onFocus,
            formatted_quantity,
            formatted_excl_price,
            excl_price_onFocus,
            formatted_incl_price_per_piece,
            price_per_piece,
            incl_price_per_piece_onFocus,
            formatted_incl_price,
            incl_price_onFocus,
            price_vat,
            description,
            invoice_start,
            invoice_stop,*/
            excl_price_per_piece,
            incl_price_per_piece,
            formatted_excl_price_per_piece,
            formatted_incl_price_per_piece,
            incl_price_onFocus,
            incl_price_per_piece_onFocus,
            formatted_incl_price,
            formatted_excl_price,
            excl_price_onFocus,
            excl_price_per_piece_onFocus,
            formatted_quantity,
            hasLoaded,
            disableLineType,
            onEdit,
            mb,
            //product_id,
            product,
            sales_invoice_line_type,
            vat_code,
            sales_invoice_line,
            vat_code_description,
            pricesInputDisabled,
        } = this.state;

        console.log("sales_invoice_line - render - vat_code_description ->", vat_code_description);
        console.log("sales_invoice_line - render - product ->", product);
        console.log("sales_invoice_line - render - sales_invoice_line ->", sales_invoice_line);

        const {
            product_id,
            //formatted_excl_price_per_piece,
            //formatted_incl_price_per_piece,
            //excl_price_per_piece_onFocus,
            //formatted_quantity,
            //quantity,
            //price_per_piece,
            //price_vat,
            description,
            invoice_start,
            invoice_stop,
            vat_percentage,
            vat_code_id,

            price,
            price_total
        } = sales_invoice_line ? sales_invoice_line : {};

        const { show, hide, selectedData, loader } = this.props,
            showActions = selectedData && (selectedData.invoice_status === 0 || selectedData.invoice_status === null),
            showSubmit = !selectedData || showActions;

        const {t} = this.props;

        //console.log("sales_invoice_line - render - sales_invoice_line ->", this.state.sales_invoice_line);
        console.log("sales_invoice_line - render - vat_code_description ->", vat_code_description);
        console.log("sales_invoice_line - render - product_id ->", product_id);
        console.log("sales_invoice_line - render - sales_invoice_line_type ->", sales_invoice_line_type);
        console.log("sales_invoice_line - render - product ->", product);
        console.log("sales_invoice_line - render - products ->", products);
        console.log("sales_invoice_line - render - this.state ->", this.state);
        console.log("sales_invoice_line - render (calculatePrice) - this.state ->", this.state);

        return (
            <Modal className="form-invoice-lines mw-800" isOpen={show} toggle={hide} centered>
                {(!loader || hasLoaded) && <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <ModalHeader className="titlew-100">
                        <Row className="justify-content-between align-items-middle">
                            <Col><span className="fl-uppercase">{showSubmit ? `${formType} ` : ''} {formName}</span></Col>
                            {showActions && <UncontrolledDropdown>
                                <DropdownToggle nav className="px-3 py-0 mr-2" onClick={(e) => { this.stopBubble(e) }}>
                                    <MoreHorizontal color="#fff" size={18} />
                                </DropdownToggle>

                                <DropdownMenu right={true}>
                                    <DropdownItem className="py-2" onClick={(e) => this.toggleEdit(e)}>{onEdit ? 'Cancel' : 'Edit'}</DropdownItem>
                                    <DropdownItem className="py-2" onClick={(e) => this.deleteInvoiceLine(e)}>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                        </Row>
                        <Row> </Row>
                    </ModalHeader>
                    <ModalBody className="modal-body-invoice-lines">
                        <Row className="align-items-end px-3">
                            <Col md="12 invoice-lines-container-rows">
                                <FormGroup className={`row align-items-center mb-0 h-41px`}>
                                    <Col xs="5" style={{maxWidth:'150.6px', marginBottom:'0'}}>
                                        <Label className="mb-0" for="product_id">Product:</Label>
                                    </Col>
                                    <Col xs="7">
                                        {onEdit
                                            ? <Select
                                                //id="product_id"
                                                id="product"
                                                //name="product_id"
                                                name="product"
                                                options={products}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                placeholder="Product"
                                                value={pricesInputDisabled ? "" : product ? product : ""}
                                                onChange={this.handleSelectChange.bind(this, 'product')}
                                                maxMenuHeight={300}
                                                isDisabled={pricesInputDisabled}
                                            />
                                            : product && product.label
                                                ? product.label
                                                : String.fromCharCode(8212)
                                        }
                                    </Col>
                                </FormGroup>
                                <FormGroup className={`row align-items-center mb-0 h-41px`}>
                                    <Col xs="5" style={{maxWidth:'150.6px', marginBottom:'0'}}>
                                        <Label className="mb-0" for="description">Description:</Label>
                                    </Col>
                                    <Col xs="7">
                                        {onEdit
                                            ? <Input
                                                id="description"
                                                name="description"
                                                placeholder="Description"
                                                value={description ? description : ''}
                                                onChange={(e) => this.handleInput(e)}
                                            />
                                            : description ? description : String.fromCharCode(8212)
                                        }
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md="12" className="invoice-lines-container-rows">
                                <FormGroup className={`row align-items-center mb-0 h-41px`}>
                                    <Col xs="5" style={{maxWidth:'150.6px', marginBottom:'0'}}>
                                        <Label className="mb-0" for="sales_invoice_line_type">Line type:</Label>
                                    </Col>
                                    <Col xs="7">
                                        {onEdit
                                            ? <Select
                                                id="sales_invoice_line_type"
                                                name="sales_invoice_line_type"
                                                options={line_types}
                                                placeholder="Line type"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={sales_invoice_line_type ? sales_invoice_line_type : ""}
                                                onChange={this.handleSelectChange.bind(this, 'sales_invoice_line_type')}
                                                maxMenuHeight={300}
                                                isDisabled={disableLineType}
                                            />
                                            : sales_invoice_line_type && sales_invoice_line_type.label
                                                ? sales_invoice_line_type.label
                                                : String.fromCharCode(8212)
                                        }
                                    </Col>
                                </FormGroup>
                                <FormGroup className={`row align-items-center mb-0 h-41px`}>
                                    <Col xs="5" style={{maxWidth:'150.6px', marginBottom:'0'}}>
                                        <Label className="mb-0" for="invoice_start">Period start:</Label>
                                    </Col>
                                    <Col xs="7">
                                        {onEdit
                                            ? <DatePicker
                                                locale={this.getLocale()}
                                                id="invoice_start"
                                                wrapperClassName="w-100"
                                                className="form-control"
                                                name="invoice_start"
                                                dateFormat={t("date_format_raw")}
                                                autoComplete="off"
                                                placeholderText="Period start"
                                                selected={invoice_start ? new Date(invoice_start) : null}
                                                onChange={this.handleDatePickerChange.bind(this, 'invoice_start')}
                                                ref="invoice_start"
                                                onKeyDown={(e) => this.handleKeyDown(e, 'invoice_start')}
                                                maxDate={invoice_stop ? new Date(invoice_stop) : null}
                                            />
                                            : invoice_start ? t("date_format", { date: new Date(invoice_start)}) : String.fromCharCode(8212)
                                        }
                                    </Col>
                                </FormGroup>
                                <FormGroup className={`row align-items-center mb-0 h-41px`}>
                                    <Col xs="5" style={{maxWidth:'150.6px', marginBottom:'0'}}>
                                        <Label className="mb-0" for="invoice_stop">Period end:</Label>
                                    </Col>
                                    <Col xs="7">
                                        {onEdit
                                            ? <DatePicker
                                                locale={this.getLocale()}
                                                id="invoice_stop"
                                                wrapperClassName="w-100"
                                                className="form-control"
                                                name="invoice_stop"
                                                dateFormat={t("date_format_raw")}
                                                autoComplete="off"
                                                placeholderText="Period end"
                                                selected={invoice_stop ? new Date(invoice_stop) : null}
                                                onChange={this.handleDatePickerChange.bind(this, 'invoice_stop')}
                                                //ref="invoice_stop"
                                                onKeyDown={(e) => this.handleKeyDown(e, 'invoice_stop')}
                                                minDate={invoice_start ? new Date(invoice_start) : null}
                                            />
                                            : invoice_stop ? t("date_format", { date: new Date(invoice_stop)}) : String.fromCharCode(8212)
                                        }
                                    </Col>
                                </FormGroup>
                                <FormGroup className={`row align-items-center mb-0 h-41px`}>
                                    <Col xs="5" style={{maxWidth:'150.6px', marginBottom:'0'}}>
                                        <Label className="mb-0" for="vat_code">VAT code:</Label>
                                    </Col>
                                    <Col xs="7">
                                        {/*vat_code && vat_code.description
                                            ? vat_code.description
                                            : vat_code && vat_code.vat_percentage
                                                ? `${parseFloat(vat_code.vat_percentage) * 100} %`
                                                : String.fromCharCode(8212)*/}
                                        {vat_code_description
                                            ? vat_code_description
                                            : vat_percentage
                                                ? `${parseFloat(vat_percentage) * 100} %`
                                                : String.fromCharCode(8212)}
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs="12">
                                <Table className="mb-0 table-invoice-line-prices-edit">
                                    <thead>
                                    <tr>
                                        <th style={{ width: '20%' }}> </th>
                                        <th data-label="Unit price" style={{ width: '20%' }}>Unit price</th>
                                        <th data-label="Quantity" style={{ width: '20%' }}>Quantity</th>
                                        <th data-label="Price" style={{ width: '20%' }}>Price</th>
                                        <th data-label="VAT Price" style={{ width: '20%' }}>VAT price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="label-invoice-lines">Price excl. VAT</td>
                                        {!onEdit
                                            ? <React.Fragment>
                                                <td data-label="Unit price">
                                                    {sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99 ? String.fromCharCode(8212) : this.hasValue(formatted_excl_price_per_piece) ? t("currency_format", { number: Number(parseFloat(formatted_excl_price_per_piece)) }) : String.fromCharCode(8212)}</td>
                                                <td data-label="Quantity">
                                                    {sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99 ? String.fromCharCode(8212) : this.hasValue(formatted_quantity) ? parseFloat(formatted_quantity).toFixed(2).replace('.', ',') : String.fromCharCode(8212)}</td>
                                                {/*<td data-label="Price">{this.hasValue(formatted_excl_price) ? t("currency_format", { number: Number(parseFloat(formatted_excl_price)) }) : String.fromCharCode(8212)}</td>*/}
                                                <td data-label="Price">
                                                    {sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99 ? String.fromCharCode(8212) : this.hasValue(price) ? t("currency_format", { number: Number(parseFloat(price)) }) : String.fromCharCode(8212)}</td>
                                            </React.Fragment>
                                            : <React.Fragment>
                                                <td data-label="Unit price">
                                                    <NumberFormat
                                                        className="form-control"
                                                        name="excl_price_per_piece"
                                                        id="excl_price_per_piece"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        thousandsGroupStyle="thousand"
                                                        decimalScale={2}
                                                        value={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99) ? "" : formatted_excl_price_per_piece && formatted_excl_price_per_piece === null ? 0 : this.hasValue(formatted_excl_price_per_piece) ? formatted_excl_price_per_piece : null}
                                                        displayType="input"
                                                        allowNegative={true}
                                                        onValueChange={excl_price_per_piece_onFocus ? (e) => this.priceFormatter('excl_price_per_piece', e) : () => { }}
                                                        onFocus={(e) => this.eventFocus(e)}
                                                        onBlur={(e) => this.eventBlur(e)}
                                                        allowLeadingZeros={true}
                                                        disabled={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99)}
                                                    />
                                                </td>
                                                <td data-label="Quantity">
                                                    <NumberFormat
                                                        className="form-control"
                                                        name="quantity"
                                                        id="quantity"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        thousandsGroupStyle="thousand"
                                                        decimalScale={2}
                                                        value={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99) ? "" : this.hasValue(formatted_quantity) ? formatted_quantity : null}
                                                        displayType="input"
                                                        allowNegative={true}
                                                        onValueChange={(e) => this.priceFormatter('quantity', e)}
                                                        onFocus={(e) => this.eventFocus(e)}
                                                        onBlur={(e) => this.eventBlur(e)}
                                                        allowLeadingZeros={true}
                                                        disabled={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99)}
                                                    />
                                                </td>
                                                <td data-label="Price">
                                                    <NumberFormat
                                                        className="form-control"
                                                        name="excl_price"
                                                        id="excl_price"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        thousandsGroupStyle="thousand"
                                                        decimalScale={2}
                                                        //value={pricesInputDisabled ? "" : formatted_excl_price && formatted_excl_price === null ? 0 : this.hasValue(formatted_excl_price) ? formatted_excl_price : null}
                                                        value={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99) ? "" : price && price === null ? 0 : this.hasValue(price) ? price : null}
                                                        displayType="input"
                                                        allowNegative={true}
                                                        onValueChange={excl_price_onFocus ? (e) => this.priceFormatter('excl_price', e) : () => { }}
                                                        onFocus={(e) => this.eventFocus(e)}
                                                        onBlur={(e) => this.eventBlur(e)}
                                                        allowLeadingZeros={true}
                                                        disabled={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99)}
                                                    />
                                                </td>
                                            </React.Fragment>
                                        }
                                        <td data-label="VAT Price">
                                            {/*pricesInputDisabled ? "" : this.hasValue(price_vat) ? t("currency_format", { number: Number(parseFloat(price_vat)) }) : String.fromCharCode(8212)*/}
                                            {pricesInputDisabled ? "" : this.calculatePriceVat(price_vat)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label-invoice-lines">Price incl. VAT</td>
                                        {!onEdit
                                            ? <React.Fragment>
                                                <td data-label="Unit price">
                                                    {sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99 ? String.fromCharCode(8212) : this.hasValue(formatted_incl_price_per_piece, "formatted_incl_price_per_piece") ? t("currency_format", { number: Number(parseFloat(formatted_incl_price_per_piece)) }) : String.fromCharCode(8212)}</td>
                                                <td className="empty-cell"> </td>
                                                {/*<td data-label="Price">{this.hasValue(formatted_incl_price, "formatted_incl_price") ? t("currency_format", { number: Number(parseFloat(formatted_incl_price)) }) : String.fromCharCode(8212)}</td>*/}
                                                <td data-label="Price">
                                                    {sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99 ? String.fromCharCode(8212) : this.hasValue(price_total, "formatted_incl_price") ? t("currency_format", { number: Number(parseFloat(price_total)) }) : String.fromCharCode(8212)}</td>
                                            </React.Fragment>
                                            : <React.Fragment>
                                                <td data-label="Unit price">
                                                    <NumberFormat
                                                        className="form-control"
                                                        name="incl_price_per_piece"
                                                        id="incl_price_per_piece"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        thousandsGroupStyle="thousand"
                                                        decimalScale={2}
                                                        value={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99) ? "" : formatted_incl_price_per_piece && formatted_incl_price_per_piece === null ? 0 : this.hasValue(formatted_incl_price_per_piece, "formatted_incl_price_per_piece") ? formatted_incl_price_per_piece : null}
                                                        displayType="input"
                                                        allowNegative={true}
                                                        onValueChange={incl_price_per_piece_onFocus ? (e) => this.priceFormatter('incl_price_per_piece', e) : () => { }}
                                                        onFocus={(e) => this.eventFocus(e)}
                                                        onBlur={(e) => this.eventBlur(e)}
                                                        allowLeadingZeros={true}
                                                        disabled={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99)}
                                                    />

                                                </td>
                                                <td className="empty-cell"> </td>
                                                <td data-label="VAT Price">
                                                    <NumberFormat
                                                        className="form-control"
                                                        name="incl_price"
                                                        id="incl_price"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        thousandsGroupStyle="thousand"
                                                        decimalScale={2}
                                                        //value={pricesInputDisabled ? "" : formatted_incl_price && formatted_incl_price === null ? 0 : this.hasValue(formatted_incl_price, "formatted_incl_price") ? formatted_incl_price : null}
                                                        value={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99) ? "" : price_total && price_total === null ? 0 : this.hasValue(price_total, "formatted_incl_price") ? price_total : null}
                                                        displayType="input"
                                                        allowNegative={true}
                                                        onValueChange={incl_price_onFocus ? (e) => this.priceFormatter('incl_price', e) : () => { }}
                                                        onFocus={(e) => this.eventFocus(e)}
                                                        onBlur={(e) => this.eventBlur(e)}
                                                        allowLeadingZeros={true}
                                                        disabled={pricesInputDisabled || (sales_invoice_line_type && sales_invoice_line_type.value && sales_invoice_line_type.value === 99)}
                                                    />
                                                </td>
                                            </React.Fragment>}
                                        <td className="empty-cell"> </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-between">
                        <Button className="btn btn-danger" onClick={hide} data-dismiss="modal">
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        {showSubmit &&
                        <Button color="primary">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                        }
                    </ModalFooter>
                </Form>}
            </Modal>
        )
    }
}

const mapStateToProps = ({ dispatch, loader, user }) => ({ dispatch, loader, user });

export default connect(mapStateToProps)(withTranslation()(withRouter(InvoiceLineForm)));
