import * as types from "../constants";

const initialState = {
  isSidebarMenuInvoicesOpen: false,
  isSidebarMenuSubscriptionsOpen: false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SIDEBAR_MENU_INVOICES_VISIBILITY_TOGGLE:
      return {
        ...state,
        isSidebarMenuInvoicesOpen: !state.isSidebarMenuInvoicesOpen
      };
    case types.SIDEBAR_MENU_INVOICES_VISIBILITY_SHOW:
      return {
        ...state,
        isSidebarMenuInvoicesOpen: true
      };
    case types.SIDEBAR_MENU_INVOICES_VISIBILITY_HIDE:
      return {
        ...state,
        isSidebarMenuInvoicesOpen: false
      };

    case types.SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_TOGGLE:
      return {
        ...state,
        isSidebarMenuSubscriptionsOpen: !state.isSidebarMenuSubscriptionsOpen
      };
    case types.SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_SHOW:
      return {
        ...state,
        isSidebarMenuSubscriptionsOpen: true
      };
    case types.SIDEBAR_MENU_SUBSCRIPTIONS_VISIBILITY_HIDE:
      return {
        ...state,
        isSidebarMenuSubscriptionsOpen: false
      };

    default:
      return state;
  }
}
