import React, { useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import Form from 'react-formal';
import * as Yup from 'yup';

import { loaderToggle } from "../redux/actions/loaderActions";
import Toast from "../components/Toast";

const supportedFile = ['.csv', 'text/csv'];
const defaultMixed = Yup.mixed().default('');

// field validation
const cdrForm = Yup.object().shape({
    file: defaultMixed
        .required('Please upload a file.')
});

const FileUpload = ({ MessageComponent, ...props }) => {
    const [file, setFile] = useState(null);
    const [fileTouched, setFileTouched] = useState(false);
    const [error, setError] = useState({});

    //const toBase64 = (file) => new Promise((resolve, reject) => {
      //  const reader = new FileReader();
      //  reader.readAsDataURL(file);
      //  reader.onload = () => resolve(reader.result);
      //  reader.onerror = (error) => reject(error)
    //});

    const submitForm = (formData) => {
        setFileTouched(true);
        if (!props.loader) {
            props.dispatch(loaderToggle(true));

            let cdr = formData.file;
            let reader = new FileReader();

            reader.readAsDataURL(cdr);
            reader.onload = async (e) => {
                console.log(reader.result);
                console.log(e.target.result);

                const params = {
                    cdr: e.target.result,
                    filename: formData.file.name
                };

                console.log('cdr', params);

                // API call
                props.controller(params).then(r => {
                    console.log(r);
                    const {ok} = r;
                    if (ok) {
                        Toast.fire({
                            title: 'CDR file has been uploaded.',
                            icon: 'success'
                        });
                    }
                    setFile(null);
                    props.dispatch(loaderToggle(false));
                    setFileTouched(false);

                }).catch(e => {
                    console.log(e);
                    setFile(null);
                    props.dispatch(loaderToggle(false));
                    setFileTouched(false);
                })
            }
        }
    };

    return (
        <Form
            schema={cdrForm}
            value={file}
            onChange={e => { setFile(e) }}
            errors={error}
            onError={errors => {
                if (errors && errors.file) {
                    setError({ file: errors.file[0] })
                } else {
                    if (!file)
                        setError({ file: { message: 'Please upload a file.' } });
                    else
                        setError({})
                }
            }}
            onSubmit={submitForm}
        >
            <Row>
                <Col md={2}>
                    <label htmlFor="file">{props.label}</label>
                </Col>
                <Col md={5} className="">
                    <div className="input-group">
                        <div className="custom-file">
                            <Input type="text" value={file ? file.file.name : 'browse...'} readOnly className="custom-file-inp custom-file-label" />
                            <Form.Field name="file" type="file" className="fx-file-inp custom-file-input" accept={supportedFile} />
                        </div>
                    </div>
                </Col>
                <Col>
                    <Form.Submit type="submit" className="col-md-5 btn-primary btn">{props.btnLabel}</Form.Submit>
                </Col>
            </Row>
            <Row>
                <Col>
                    {error && error.file ?
                        <div className="pt-4 mt-4 border-top">
                            <Form.Message for="file" className="error" />
                        </div>
                        :
                        null
                    }
                    {!error.file && fileTouched ?
                        <div className="mt-4 border-top">
                            <MessageComponent />
                        </div>
                        :
                        null
                    }
                </Col>
            </Row>
        </Form>
    )
};

export default FileUpload;
