import React, { useState } from 'react';
import { Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const FilterSearch = ({
  listFilterText,
  handleFilterTextInputChange,
  inputId,
  inputName,
  setListFilterText,
  setFilteredEmails,
  emailList
}) => {

  const [expanded, setExpanded] = useState(false);

  const handleButtonClick = (e, inputId) => {
    e.preventDefault();

    // Toggle the expanded state
    setExpanded(exp => !exp);

    // Check if the expanded state is about to be set to false
    if (expanded) {
      // Clear the input text and reset the filtered emails to show all emails          

      if (setFilteredEmails) {
        setFilteredEmails(emailList); // Assuming emailList contains all emails
      }

      if (setListFilterText) {
        setListFilterText("");
      }
    }

    // Focus the input only if we are expanding it
    if (!expanded) {
      const inputSearchText = document.getElementById(inputId);

      setTimeout(() => {
        //const inputSearchText = document.getElementById(inputId);
        if (inputSearchText) {
          inputSearchText.focus();
        }
      }, 0);
    }
  }

  return (
    <>
      <Input
        id={inputId}
        name={inputName}
        className={`form-control filter-list-search ${expanded ? "expanded" : ""}`}
        placeholder='Search'
        value={listFilterText ? listFilterText : ''}
        autoComplete="off"
        onChange={handleFilterTextInputChange}
      />

      <OverlayTrigger
        key={`search-tooltip-overlay`}
        placement={`bottom`}
        transition={false}
        delay={200}
        overlay={
          <Tooltip
            className="tooltip"
            container="body"
            id={`tooltip-bottom-search`}
          >
            {`Search`}
          </Tooltip>}
      >
        <Button color="gray" className="mb-1 btn filter-search-btn"
          onClick={(e) => handleButtonClick(e, inputId)}>
          <FontAwesomeIcon color="gray" icon={faSearch} />
        </Button>
      </OverlayTrigger>


    </>
  );
};

export default FilterSearch;